import { Link, TextViewer, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { CustomerConsoleAppUrlHelper, useTheme } from "../../../../../../../../../../../../../../../../../../../common";

export function RunScanIacItem() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodePipelineItems.codePipeline.addOrEdit.hooks.useDefinition.hooks.useGeneralCodePipeline.runScanIacItem",
            () => ({
                description: "To scan your IaC, add the following step to your pipeline (adjust as needed):",
                flags: {
                    envFile: "**--env-file**: The path in which the **environment.variables** file you created in the previous step is located",
                    volume: "**--volume**: Change the left side (before the \":\") to the path in which the code is located; keep the right side as is"
                },
                note1: {
                    link: "Learn more",
                    text: "It's recommended to run the scan within a Git repository. If for some reason this isn't possible, additional variables must be included. {{docsLink}}"
                },
                note2: {
                    link: "product documentation",
                    text: "Once the scan is triggered, you should start seeing scan results under **IaC**. Please refer to the {{docsLink}} for more details"
                }
            }));

    const theme = useTheme();
    return (
        <Stack spacing={2}>
            <Typography sx={{ maxWidth: theme.spacing(60) }}>
                {localization.description()}
            </Typography>
            <TextViewer
                copyToClipboard={true}
                format="shell"
                text="docker run --workdir /tmp/tenable --volume /home/user/code:/tmp/tenable --env-file environment.variables --pull=always tenable/cloud-security-scanner:latest iac scan"/>
            <Stack sx={{ maxWidth: theme.spacing(80) }}>
                <Typography>
                    {localization.flags.envFile()}
                </Typography>
                <Typography>
                    {localization.flags.volume()}
                </Typography>
            </Stack>
            <Typography sx={{ maxWidth: theme.spacing(65) }}>
                {localization.note1.text({
                    docsLink:
                        <Link
                            sx={{ textDecorationLine: "underline" }}
                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsConnectYourCICDPipelineAddDifferentPipelineAdditionalVariablesForNonGitRepositoriesRelativeUrl()}
                            variant="external">
                            {localization.note1.link()}
                        </Link>
                })}
            </Typography>
            <Typography sx={{ maxWidth: theme.spacing(65) }}>
                {localization.note2.text({
                    docsLink:
                        <Link
                            sx={{ textDecorationLine: "underline" }}
                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsConnectYourCICDPipelineAddDifferentPipelineRelativeUrl()}
                            variant="external">
                            {localization.note2.link()}
                        </Link>
                })}
            </Typography>
        </Stack>);
}