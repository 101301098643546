import { DataTableColumn, EnumValuesFilter, optionalTableCell, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAzureCommonResourceGroupResourceDefinition } from ".";
import { EntityTableDefinition } from "../..";
import { useCommonNetworkAccessResourceDefinition } from "..";
import { Contract, ItemSelectionHelper, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAzureNetworkLoadBalancerSkuTranslator, useAzureNetworkLoadBalancerTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useAzureNetworkLoadBalancerDefinition(definitionData: DefinitionData) {
    const commonNetworkAccessResourceDefinition = useCommonNetworkAccessResourceDefinition();
    const commonResourceGroupResourceDefinition = useAzureCommonResourceGroupResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const networkLoadBalancerModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.AzureNetworkLoadBalancerModelFilters>;

    const networkLoadBalancerSkuTranslator = useAzureNetworkLoadBalancerSkuTranslator();
    const networkLoadBalancerTypeTranslator = useAzureNetworkLoadBalancerTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureNetworkLoadBalancerDefinition",
            () => ({
                columns: {
                    publicIpAddresses: "Public IP Addresses",
                    sku: "SKU",
                    type: "Load Balancer Type"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            commonResourceGroupResourceDefinition.columns.tenantColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureNetworkLoadBalancerModel) => ({
                            [localization.columns.type()]: networkLoadBalancerTypeTranslator((item.entity as Contract.AzureNetworkLoadBalancer).type)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AzureNetworkLoadBalancerType}
                                enumTypeTranslator={networkLoadBalancerTypeTranslator}
                                placeholder={localization.columns.type()}
                                sorted={false}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureNetworkLoadBalancerType}
                itemProperty={(item: Contract.EntityModel) => networkLoadBalancerTypeTranslator((item.entity as Contract.AzureNetworkLoadBalancer).type)}
                key={Contract.EntityModelProperty.AzureNetworkLoadBalancerType}
                title={localization.columns.type()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureNetworkLoadBalancerModel) => ({
                            [localization.columns.sku()]: networkLoadBalancerSkuTranslator((item.entity as Contract.AzureNetworkLoadBalancer).sku)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AzureNetworkLoadBalancerSku}
                                enumTypeTranslator={networkLoadBalancerSkuTranslator}
                                placeholder={localization.columns.sku()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureNetworkLoadBalancerSku}
                key={Contract.EntityModelProperty.AzureNetworkLoadBalancerSku}
                render={optionalTableCell<Contract.AzureNetworkLoadBalancerModel>(item => networkLoadBalancerSkuTranslator((item.entity as Contract.AzureNetworkLoadBalancer).sku))}
                title={localization.columns.sku()}/>,
            commonNetworkAccessResourceDefinition.columns.accessLevelColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureNetworkLoadBalancerModel>(
                        Contract.TypeNames.AzureNetworkPublicIpAddress,
                        item => (item.entity as Contract.AzureNetworkLoadBalancer).publicIpAddressIds,
                        localization.columns.publicIpAddresses(),
                        { getValue: entityModel => (entityModel.entity as Contract.AzureNetworkPublicIpAddress).ipAddress ?? "" })
                }
                id="publicIpAddresses"
                key="publicIpAddresses"/>,
            commonNetworkAccessResourceDefinition.columns.inboundExternalAccessScopeColumn,
            commonNetworkAccessResourceDefinition.columns.destinationNetworkScopesColumn(networkLoadBalancerModelFiltersPromise),
            commonResourceGroupResourceDefinition.columns.creationTimeColumn,
            commonResourceGroupResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceGroupResourceDefinition.columns.regionColumn,
            commonResourceGroupResourceDefinition.columns.regionLocationColumn,
            commonResourceGroupResourceDefinition.columns.resourceGroupColumn,
            commonResourceGroupResourceDefinition.columns.tagsColumn,
            commonResourceGroupResourceDefinition.columns.attributesColumn,
            commonResourceGroupResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureNetworkLoadBalancerRequest(
                new Contract.EntityControllerGetEntityModelPageAzureNetworkLoadBalancerRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureResourceGroupResourceResourceGroup]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureNetworkLoadBalancerSku]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureNetworkLoadBalancerType])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestNetworkAccess(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundAccessType]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalAccessScope]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalDestinationNetworkScopes]))));
}