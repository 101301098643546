﻿import { InlineItems, useLocalization } from "@infrastructure";
import React from "react";

type InlineKubernetesContainerNamesProps = {
    containerNames: string[];
};

export function InlineKubernetesContainerNames({ containerNames }: InlineKubernetesContainerNamesProps) {
    const localization =
        useLocalization(
            "common.inlineKubernetesContainerNames",
            () => ({
                containerNames: [
                    "1 container",
                    "{{count | NumberFormatter.humanize}} containers"
                ],
                inline: "container {{containerName}}"
            }));
    return (
        <InlineItems
            items={containerNames}
            namePluralizer={localization.containerNames}
            variant="itemOrItemCountAndType">
            {(containerName, inline) =>
                inline
                    ? localization.inline({ containerName })
                    : containerName}
        </InlineItems>);
}