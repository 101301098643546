import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useAwsLambdaFunctionConfigurationAccessTypeTranslator() {
    const localization =
        useLocalization(
            "tenants.aws.hooks.useAwsLambdaFunctionConfigurationAccessTypeTranslator",
            () => ({
                [Contract.TypeNames.AwsLambdaFunctionConfigurationModelAccessType]: {
                    [Contract.AwsLambdaFunctionConfigurationModelAccessType.ApiGatewayApi]: "API Gateway",
                    [Contract.AwsLambdaFunctionConfigurationModelAccessType.FunctionUrl]: "Function URL",
                    [Contract.AwsLambdaFunctionConfigurationModelAccessType.PolicyDocument]: "Resource Policy"
                }
            }));

    return (accessType: Contract.AwsLambdaFunctionConfigurationModelAccessType) => localization[Contract.TypeNames.AwsLambdaFunctionConfigurationModelAccessType][accessType]();
}