import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, entityModelStore, InlineEntities } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetEntitiesRiskContext } from "../../../useGetEntitiesRiskContext";

export function useGetAwsEc2InstancesRiskContext() {
    return useMemo(
        () => useAwsEc2InstancesRiskContext,
        []);
}

function useAwsEc2InstancesRiskContext(instanceIdsOrModels: string[] | Contract.AwsEc2InstanceModel[]) {
    const instanceModels =
        entityModelStore.useGet(
            _.isString(instanceIdsOrModels[0])
                ? instanceIdsOrModels as string[]
                : undefined) ?? instanceIdsOrModels as Contract.EntityModel[];
    const instancesRiskContext =
        useGetEntitiesRiskContext()(
            instanceModels,
            Contract.TypeNames.AwsEc2Instance);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsEc2InstancesRiskContext",
            () => ({
                runningInstances: {
                    empty: "All EC2 instances are not running",
                    some: [
                        "{{instances}} has state of **running**",
                        "{{instances}} have state of **running**"
                    ]
                },
                usedInstances: {
                    empty: "None of the associated EC2 instances used IMDSv1 in the last month",
                    some: [
                        "{{instances}} has used IMDSv1 in the past 1 month",
                        "{{instances}} have used IMDSv1 in the past 1 month"
                    ]
                }
            }));
    const [runningInstanceModels, usedInstanceModels] =
        useMemo(
            () => {
                const runningInstanceModels =
                    _.filter(
                        instanceModels,
                        instanceModel => (instanceModel.entity as Contract.AwsEc2Instance).status === Contract.AwsEc2InstanceStatus.Running);
                const usedInstanceModels =
                    _.filter(
                        instanceModels,
                        instanceModel => (instanceModel.entity as Contract.AwsEc2Instance).metadataServiceVersionV1Used);
                return [runningInstanceModels, usedInstanceModels];
            },
            [instanceModels]);

    return {
        ...instancesRiskContext,
        runningInstances:
            _.isEmpty(instanceModels)
                ? undefined
                : _.isEmpty(runningInstanceModels)
                    ? new RiskDefinitionContextItem(localization.runningInstances.empty())
                    : new RiskDefinitionContextItem(
                        localization.runningInstances.some(
                            runningInstanceModels.length,
                            {
                                instances:
                                    <InlineEntities
                                        entityIdsOrModels={runningInstanceModels}
                                        entityTypeName={Contract.TypeNames.AwsEc2Instance}
                                        variant="itemCountAndType"/>
                            })),
        usedInstances:
            _.isEmpty(instanceModels)
                ? undefined
                : new RiskDefinitionContextItem(
                    _.isEmpty(usedInstanceModels)
                        ? localization.usedInstances.empty()
                        : localization.usedInstances.some(
                            usedInstanceModels.length,
                            {
                                instances:
                                    <InlineEntities
                                        entityIdsOrModels={usedInstanceModels}
                                        entityTypeName={Contract.TypeNames.AwsEc2Instance}
                                        variant="itemCountAndType"/>
                            }))
    };
}