import { ConfirmButton, useSelectionActionsContext } from "@infrastructure";
import _ from "lodash";
import React, { ReactNode } from "react";

export type SelectionActionButtonProps = {
    children: ReactNode;
    disabled?: boolean;
    message?: string;
    onClick?: () => void;
    startIcon: ReactNode;
    tooltip?: string;
};

export function SelectionActionButton({ children, disabled, message, onClick, startIcon, tooltip }: SelectionActionButtonProps) {
    const { actionExecuting, itemIds, loadedItems } = useSelectionActionsContext();
    return (
        <ConfirmButton
            containerSx={{
                alignItems: "center",
                display: "flex",
                height: "100%"
            }}
            disableConfirm={
                _.isNil(message) ||
                loadedItems.length === itemIds.length}
            disabled={
                disabled ||
                actionExecuting}
            icon={startIcon}
            message={message ?? ""}
            sx={{ whiteSpace: "nowrap" }}
            tooltip={tooltip}
            variant="text"
            onClick={onClick}>
            {children}
        </ConfirmButton>);
}