﻿import { Link, useLocalization } from "@infrastructure";
import React, { useCallback } from "react";
import { useEnabledTranslator } from "../..";
import { Contract, CustomerConsoleAppUrlHelper, RiskPoliciesType } from "../../../../../../../common";
import { EnabledRadioGroup } from "../components";
import { RiskPolicyConfigurationDefinitionData } from "../useConfigurationDefinition";

export function useAwsIamRootUserMfaDisabledRiskPolicyConfigurationDefinition(riskPoliciesType: RiskPoliciesType): RiskPolicyConfigurationDefinitionData {
    const enabledTranslator = useEnabledTranslator();
    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useConfigurationDefinition.hooks.useAwsIamRootUserMfaDisabledRiskPolicyConfigurationDefinition",
            () => ({
                description: {
                    links: {
                        rootUserDocumentationLink: "AWS best practices"
                    },
                    text: "Choose whether or not to create findings for unused member account root users without MFA. By default, Tenable Cloud Security follows {{rootUserDocumentationLink}}, and creates findings for all management account root users without MFA and active member account root users without MFA."
                },
                title: "Member Account Root User"
            }));

    const getEnabled =
        useCallback(
            (riskPolicyConfiguration: Contract.RiskPolicyConfiguration) =>
                (riskPolicyConfiguration as Contract.AwsIamRootUserMfaDisabledRiskPolicyConfiguration).includeUnusedRootUser,
            []);

    return {
        generalAdditionalItems: [{
            configurationElement:
                <EnabledRadioGroup
                    description={
                        localization.description.text({
                            rootUserDocumentationLink:
                                <Link
                                    urlOrGetUrl={"https://docs.aws.amazon.com/IAM/latest/UserGuide/id_root-user.html#id_root-user-access-management"}
                                    variant="external">
                                    {localization.description.links.rootUserDocumentationLink()}
                                </Link>
                        })}
                    documentationUrl={CustomerConsoleAppUrlHelper.getDocsViewAndManagePoliciesRelativeUrl()}
                    getEnabled={getEnabled}
                    key={`${Contract.AwsIamRootUserMfaDisabledRiskPolicyConfiguration}-unusedRootUserEnabled`}
                    riskPoliciesType={riskPoliciesType}
                    setEnabled={
                        (riskPolicyConfiguration, includeUnusedRootUser) => {
                            (riskPolicyConfiguration as Contract.AwsIamRootUserMfaDisabledRiskPolicyConfiguration).includeUnusedRootUser = includeUnusedRootUser;
                        }}
                    title={localization.title()}/>,
            viewItem: {
                getValue: riskPolicyConfiguration => enabledTranslator(getEnabled(riskPolicyConfiguration)),
                title: localization.title()
            }
        }]
    };
}