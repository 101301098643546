import { DataTable, DataTableColumn, DataTableColumnRenderProps, DataTableFetchItemsResult, DataTableSort, DataTableSortDirection, DataTableSortType, EmptyMessageText, EnumValuesFilter, InlineItems, Link, Optional, optionalTableCell, PagedValuesFilter, PagedValuesFilterValuePage, SearchList, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useRef } from "react";
import { CodeController, Contract, InlineVulnerability, ItemSelectionHelper, Severity, SeverityFilter, StorageHelper, useSeverityTranslator, useTheme, VulnerabilityResolutionVersion } from "../../../../../../../../../../../common";
import { useAttackVectorTranslator } from "../../../../../../../hooks";
import { ExploitableValueFilter } from "../../../../../../ExploitableValueFilter";

type VulnerabilitiesProps = {
    scan: Contract.CodeContainerImageScan;
};

export function Vulnerabilities({ scan }: VulnerabilitiesProps) {
    const fetchVulnerabilities =
        async (filterMap: _.Dictionary<any>, sort: Optional<DataTableSort>, skip: number, limit: number) => {
            const { vulnerabilityPage } =
                await CodeController.getContainerImageScanVulnerabilityPage(
                    new Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequest(
                        new Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestFilters(
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty.AttackVector]),
                            ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty.Exploitable]),
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty.PackageDisplayName]),
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty.PackageFilePath]),
                            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty.RawId]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty.Severity]),
                            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty.VprSeverity])),
                        limit,
                        scan.id,
                        skip,
                        new Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestSort(
                            _.isNil(sort) || sort.direction === DataTableSortDirection.Descending
                                ? Contract.SortDirection.Descending
                                : Contract.SortDirection.Ascending,
                            _.isNil(sort)
                                ? Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty.Severity
                                : sort.columnId as Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty)));

            return new DataTableFetchItemsResult(
                { count: vulnerabilityPage.count! },
                vulnerabilityPage.items,
                !vulnerabilityPage.hasMore);
        };

    const getScanVulnerabilityFilterItemPage =
        async (property: Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty, searchText: Optional<string>, skip: number, limit: number, data: CodeScanVulnerabilityFilterItemPageData) => {
            const { vulnerabilityFilterItemPage } =
                await CodeController.getContainerImageScanVulnerabilityFilterItemPage(
                    new Contract.CodeControllerGetContainerImageScanVulnerabilityFilterItemPageRequest(
                        limit,
                        property,
                        scan.id,
                        searchText,
                        skip)) as Contract.CodeControllerGetContainerImageScanVulnerabilityFilterItemPageResponse;
            const vulnerabilityFilterItemCount = vulnerabilityFilterItemPage.count ?? data.count;

            return new PagedValuesFilterValuePage(
                vulnerabilityFilterItemCount,
                vulnerabilityFilterItemPage.emptyValue,
                vulnerabilityFilterItemPage.items,
                () => ({ count: vulnerabilityFilterItemCount }));
        };

    const attackVectorTranslator = useAttackVectorTranslator();
    const severityTranslator = useSeverityTranslator();
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.code.scans.profile.vulnerabilities",
            () => ({
                columns: {
                    attackVector: {
                        filterPlaceholder: "Attack Vector",
                        title: "Attack Vector"
                    },
                    cvssScore: "CVSS Score",
                    exploitable: {
                        false: "No",
                        title: "Known Exploit",
                        true: "Yes"
                    },
                    packageDisplayNames: {
                        empty: "No Matching Software",
                        title: "Software"
                    },
                    packageFilePaths: {
                        empty: "No Matching Paths",
                        title: "Paths"
                    },
                    rawId: "ID",
                    resolutionVersions: "Fixed By",
                    severity: "Severity",
                    vpr: {
                        helpText: "Vulnerability Priority Rating (VPR) is a proprietary Tenable score which can help you evaluate the likelihood of exploit. {{learnMoreLink}}",
                        learnMoreLink: "Learn more."
                    },
                    vprScore: "VPR Score",
                    vprSeverity: "VPR Severity"
                },
                empty: {
                    withFilter: "No Matching Vulnerabilities",
                    withoutFilter: "No Vulnerabilities"
                },
                title: "Vulnerabilities"
            }));

    const filterMapRef = useRef<Dictionary<any>>();
    const sortRef = useRef<DataTableSort>();
    const theme = useTheme();
    return (
        <DataTable
            columnOptions={{
                orderOptions: {
                    enabled: true,
                    persistenceStorageItem: StorageHelper.customerWorkloadAnalysisCodeScansProfileColumnOrder
                },
                resizable: true,
                selectorOptions: {
                    enabled: true,
                    persistenceStorageItem: StorageHelper.customerWorkloadAnalysisCodeScansProfileColumnSelector
                }
            }}
            emptyMessageOptions={{
                emptyMessageText:
                    new EmptyMessageText(
                        localization.empty.withoutFilter(),
                        localization.empty.withFilter())
            }}
            exportOptions={{ fileNamePrefix: localization.title() }}
            fetchItems={fetchVulnerabilities}
            filtersOptions={{
                onChanged: filterMap => filterMapRef.current = filterMap,
                sx: { padding: theme.spacing(0, 0, 1.5, 0) }
            }}
            getItemId={(item: Contract.CodeContainerImageVulnerability) => item.id}
            onSortChanged={sort => sortRef.current = sort}>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.CodeContainerImageVulnerability) => ({
                            [localization.columns.rawId()]: item.rawId
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    (searchText, skip, limit, data) =>
                                        getScanVulnerabilityFilterItemPage(
                                            Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty.RawId,
                                            searchText,
                                            skip,
                                            limit,
                                            data)}
                                placeholder={localization.columns.rawId()}/>
                    }
                }}
                id={Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty.RawId}
                key={Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty.RawId}
                orderable={false}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.CodeContainerImageVulnerability>) =>
                        <InlineVulnerability rawId={item.rawId}/>}
                selectorOptions={{ disabled: true }}
                title={localization.columns.rawId()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.CodeContainerImageVulnerability) => ({
                            [localization.columns.severity()]:
                                _.isNil(item.severity)
                                    ? ""
                                    : severityTranslator(item.severity)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element: <SeverityFilter placeholder={localization.columns.severity()}/>
                    }
                }}
                id={Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty.Severity}
                key={Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty.Severity}
                render={
                    ({ item: vulnerability }: DataTableColumnRenderProps<Contract.CodeContainerImageVulnerability>) =>
                        <Severity severity={vulnerability.severity}/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.severity()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.CodeContainerImageVulnerability) => ({
                            [localization.columns.cvssScore()]:
                                item.cvssScore === 0
                                    ? ""
                                    : item.cvssScore
                        })
                }}
                id={Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty.CvssScore}
                itemProperty={
                    (vulnerability: Contract.CodeContainerImageVulnerability) =>
                        _.isNil(vulnerability.cvssScore) || vulnerability.cvssScore === 0
                            ? "-"
                            : vulnerability.cvssScore}
                key={Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty.CvssScore}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.cvssScore()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.CodeContainerImageVulnerability) => ({
                            [localization.columns.vprSeverity()]:
                                _.isNil(item.vprSeverity)
                                    ? ""
                                    : severityTranslator(item.vprSeverity)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <SeverityFilter
                                information={false}
                                placeholder={localization.columns.vprSeverity()}/>
                    }
                }}
                id={Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty.VprSeverity}
                key={Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty.VprSeverity}
                message={
                    localization.columns.vpr.helpText({
                        learnMoreLink:
                            <Link
                                urlOrGetUrl="https://docs.tenable.com/vulnerability-management/Content/Explore/Findings/RiskMetrics.htm#Vulnerability-Priority-Rating-"
                                variant="external">
                                {localization.columns.vpr.learnMoreLink()}
                            </Link>
                    })}
                messageLevel="info"
                render={
                    ({ item: vulnerability }: DataTableColumnRenderProps<Contract.CodeContainerImageVulnerability>) =>
                        <Severity severity={vulnerability.vprSeverity}/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.vprSeverity()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.CodeContainerImageVulnerability) => ({
                            [localization.columns.vprScore()]:
                                _.isNil(item.vprScore)
                                    ? ""
                                    : item.vprScore
                        })
                }}
                id={Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty.VprScore}
                itemProperty={
                    (vulnerability: Contract.CodeContainerImageVulnerability) =>
                        _.isNil(vulnerability.vprScore)
                            ? "-"
                            : vulnerability.vprScore}
                key={Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty.VprScore}
                message={
                    localization.columns.vpr.helpText({
                        learnMoreLink:
                            <Link
                                urlOrGetUrl="https://docs.tenable.com/vulnerability-management/Content/Explore/Findings/RiskMetrics.htm#Vulnerability-Priority-Rating-"
                                variant="external">
                                {localization.columns.vpr.learnMoreLink()}
                            </Link>
                    })}
                messageLevel="info"
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.vprScore()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.CodeContainerImageVulnerability) => ({
                            [localization.columns.attackVector.title()]:
                                _.isNil(item.attackVector)
                                    ? ""
                                    : attackVectorTranslator(item.attackVector)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                emptyValueOptions={{ enabled: true }}
                                enumType={Contract.VulnerabilityAttackVector}
                                enumTypeTranslator={attackVectorTranslator}
                                placeholder={localization.columns.attackVector.filterPlaceholder()}/>
                    }
                }}
                id={Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty.AttackVector}
                key={Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty.AttackVector}
                render={
                    optionalTableCell<Contract.CodeContainerImageVulnerability>(
                        vulnerability =>
                            _.isNil(vulnerability.attackVector)
                                ? undefined
                                : attackVectorTranslator(vulnerability.attackVector))}
                sortOptions={{ enabled: false }}
                title={localization.columns.attackVector.title()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.CodeContainerImageVulnerability) => ({
                            [localization.columns.exploitable.title()]:
                                item.exploitable
                                    ? localization.columns.exploitable.true()
                                    : localization.columns.exploitable.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element: <ExploitableValueFilter localization={localization.columns.exploitable}/>
                    }
                }}
                id={Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty.Exploitable}
                itemProperty={
                    (vulnerability: Contract.CodeContainerImageVulnerability) =>
                        vulnerability.exploitable
                            ? localization.columns.exploitable.true()
                            : localization.columns.exploitable.false()}
                key={Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty.Exploitable}
                sortOptions={{ enabled: false }}
                title={localization.columns.exploitable.title()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.CodeContainerImageVulnerability) => ({
                            [localization.columns.packageDisplayNames.title()]: item.packageDisplayNames.join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <PagedValuesFilter
                                emptyValueOptions={{ enabled: false }}
                                getValuePage={
                                    (searchText, skip, limit, data) =>
                                        getScanVulnerabilityFilterItemPage(
                                            Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty.PackageDisplayName,
                                            searchText,
                                            skip,
                                            limit,
                                            data)}
                                placeholder={localization.columns.packageDisplayNames.title()}/>
                    }
                }}
                id={Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty.PackageDisplayName}
                key={Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty.PackageDisplayName}
                render={
                    optionalTableCell<Contract.CodeContainerImageVulnerability>(
                        vulnerability =>
                            _.isEmpty(vulnerability.packageDisplayNames)
                                ? undefined
                                : <InlineItems
                                    items={vulnerability.packageDisplayNames}
                                    itemsPopover={
                                        items =>
                                            <SearchList
                                                emptyMessageText={new EmptyMessageText(localization.columns.packageDisplayNames.empty())}
                                                itemOptions={{ spacing: 1 }}
                                                items={items}
                                                sx={{ padding: theme.spacing(0, 1.5) }}
                                                variant="dropdown"/>}
                                    variant="itemPlusItemCount">
                                    {packageDisplayName =>
                                        <Typography noWrap={true}>
                                            {packageDisplayName}
                                        </Typography>}
                                </InlineItems>)}
                title={localization.columns.packageDisplayNames.title()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.CodeContainerImageVulnerability) => ({
                            [localization.columns.packageFilePaths.title()]: item.packageFilePaths.join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    (searchText, skip, limit, data) =>
                                        getScanVulnerabilityFilterItemPage(
                                            Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty.PackageFilePath,
                                            searchText,
                                            skip,
                                            limit,
                                            data)}
                                placeholder={localization.columns.packageFilePaths.title()}/>
                    }
                }}
                id={Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty.PackageFilePath}
                key={Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty.PackageFilePath}
                render={
                    optionalTableCell<Contract.CodeContainerImageVulnerability>(
                        vulnerability =>
                            _.isEmpty(vulnerability.packageFilePaths)
                                ? undefined
                                : <InlineItems
                                    items={vulnerability.packageFilePaths}
                                    itemsPopover={
                                        items =>
                                            <SearchList
                                                emptyMessageText={new EmptyMessageText(localization.columns.packageFilePaths.empty())}
                                                itemOptions={{ spacing: 1 }}
                                                items={items}
                                                sx={{ padding: theme.spacing(0, 1.5) }}
                                                variant="dropdown"/>}
                                    variant="itemPlusItemCount">
                                    {packageFilePath =>
                                        <Typography noWrap={true}>
                                            {packageFilePath}
                                        </Typography>}
                                </InlineItems>)}
                sortOptions={{ enabled: false }}
                title={localization.columns.packageFilePaths.title()}/>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.CodeContainerImageVulnerability) => ({
                            [localization.columns.resolutionVersions()]: item.resolutionVersions.join("\n")
                        })
                }}
                id={Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty.ResolutionVersions}
                key={Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequestProperty.ResolutionVersions}
                render={
                    optionalTableCell<Contract.CodeContainerImageVulnerability>(
                        vulnerabilityModel =>
                            _.isEmpty(vulnerabilityModel.resolutionVersions)
                                ? undefined
                                : <InlineItems
                                    items={vulnerabilityModel.resolutionVersions}
                                    variant="itemPlusItemCount">
                                    {(resolutionVersion: string) =>
                                        <VulnerabilityResolutionVersion resolutionVersion={resolutionVersion}/>}
                                </InlineItems>)}
                sortOptions={{ enabled: false }}
                title={localization.columns.resolutionVersions()}/>
        </DataTable>);
}

type CodeScanVulnerabilityFilterItemPageData = {
    count: number;
    itemNextPageSearchCursor?: Contract.ElasticsearchIndexSearchCursor;
};