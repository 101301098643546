import { Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetNetworkAccessResourceRiskContext } from "../../../useGetNetworkAccessResourceRiskContext";
import { useGetAzureResourceRiskContext } from "./useGetAzureResourceRiskContext";

export function useGetAzureDocumentDbDatabaseAccountRiskContext() {
    return useMemo(
        () => useAzureDocumentDbDatabaseAccountRiskContext,
        []);
}

function useAzureDocumentDbDatabaseAccountRiskContext(databaseAccountModel: Contract.AzureDocumentDbDatabaseAccountModel) {
    const resourceRiskContext = useGetAzureResourceRiskContext()(databaseAccountModel);
    const networkAccessResourceRiskContext = useGetNetworkAccessResourceRiskContext()(databaseAccountModel);
    const databaseAccount = databaseAccountModel.entity as Contract.AzureDocumentDbDatabaseAccount;
    const databaseNetwork = databaseAccountModel.entityNetwork as Optional<Contract.AzureDocumentDbDatabaseAccountStateNetwork>;

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.contexts.useGetAzureDocumentDbDatabaseAccountRiskContext",
            () => ({
                allowAnyAzureInternalIpAddress: "**Accept connections from within public Azure datacenters** is enabled allowing unrestricted network inbound access to your CosmosDB account from all Azure resources, including resources from **any** subscription"
            }));

    return {
        ...resourceRiskContext,
        ...networkAccessResourceRiskContext,
        allowAnyAzureInternalIpAddress:
            databaseAccount.allowAnyAzureInternalIpAddress
                ? new RiskDefinitionContextItem(localization.allowAnyAzureInternalIpAddress())
                : undefined,
        wideRangeInboundSubnets:
            databaseAccount.allNetworkAccess ||
            _.isNil(databaseNetwork)
                ? undefined
                : resourceRiskContext.getWideRangeInboundSubnetsContextItem(databaseNetwork.wideRangeFirewallRuleSubnets)
    };
}