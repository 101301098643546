import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../../common";
import { useGetOciComputeInstancesRiskContext, useGetOciContainerEngineClusterNodePoolRiskContext } from "../../../contexts";

export function useOciComputeInstanceMetadataServiceVersionRiskClusterNodePoolDefinition(riskModel: Contract.OciComputeInstanceMetadataServiceVersionRiskModel) {
    const risk = riskModel.risk as Contract.OciComputeInstanceMetadataServiceVersionRisk;
    const clusterNodePoolModel = entityModelStore.useGet(risk.entityId) as Contract.OciContainerEngineClusterNodePoolModel;
    const clusterNodePool = clusterNodePoolModel.entity as Contract.OciContainerEngineClusterNodePool;

    const getOciContainerEngineClusterNodePoolRiskContext = useGetOciContainerEngineClusterNodePoolRiskContext();
    const getOciComputeInstancesRiskContext = useGetOciComputeInstancesRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.compliance.useOciComputeInstanceMetadataServiceVersionRiskDefinition.hooks.useOciComputeInstanceMetadataServiceVersionRiskClusterNodePoolDefinition",
            () => ({
                description: "{{clusterNodePool}} is not enforcing Instance Metadata Service Version 2 (IMDSv2)",
                sections: {
                    resolution: {
                        step1: {
                            link: "InstanceMetadataRequests metric",
                            text: "Verify that the instance does not use the IMDSv1 endpoints before you disable requests to IMDSv1. If the instance still relies on IMDSv1 when you disable requests to it, you might lose some functionality. To identify whether IMDSv1 is being used, use the {{instanceMetadataServiceVersion1RequestMetricDocumentationLink}}."
                        },
                        step2: {
                            link: "To use the CLI to disable IMDSv1",
                            text: "Disabling IMDSv1 is not supported via the OCI console. {{containerEngineClusterNodePoolInstanceMetadataServiceVersion1DocumentationLink}} run the following command: oci ce node-pool update --node-pool-id {{clusterNodePoolOcid}} --node-metadata='{\"areLegacyImdsEndpointsDisabled\" : \"true\"}'"
                        },
                        step3: "Changes will only apply to new worker nodes. If you want the changes to take effect immediately, consider creating a new node pool with the necessary settings and delete the old insecure node pool. Alternatively, delete all existing nodes."
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            clusterNodePool:
                <Entity
                    entityIdOrModel={clusterNodePoolModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            localization.sections.resolution.step1.text({
                instanceMetadataServiceVersion1RequestMetricDocumentationLink:
                    <Link
                        urlOrGetUrl={riskModel.instanceMetadataServiceVersion1RequestMetricDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step1.link()}
                    </Link>
            }),
            localization.sections.resolution.step2.text({
                clusterNodePoolOcid: clusterNodePool.ocid,
                containerEngineClusterNodePoolInstanceMetadataServiceVersion1DocumentationLink:
                    <Link
                        urlOrGetUrl={riskModel.containerEngineClusterNodePoolInstanceMetadataServiceVersion1DocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step2.link()}
                    </Link>
            }),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const clusterNodePoolContextItems = getOciContainerEngineClusterNodePoolRiskContext(clusterNodePoolModel);
            const instancesContextItems = getOciComputeInstancesRiskContext(clusterNodePoolModel.instanceIdReferences);
            return [
                clusterNodePoolContextItems.generalInformation,
                clusterNodePoolContextItems.instances,
                instancesContextItems.runningInstances,
                instancesContextItems.sensitive,
                clusterNodePoolContextItems.sensitive,
                clusterNodePoolContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}