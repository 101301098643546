import _ from "lodash";
import { useRef } from "react";

export function useDeepDependency(dependencies: any[]) {
    const deepDependencyRef = useRef(new Date().toISOString());
    const dependenciesRef = useRef(dependencies);

    if (!_.isEqual(dependencies, dependenciesRef.current)) {
        dependenciesRef.current = dependencies;
        deepDependencyRef.current = new Date().toISOString();
    }

    return deepDependencyRef.current;
}