import { useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, useTheme } from "../../../../..";
import { EntityIcon, InlineEntities } from "../../../..";
import { useRiskResolutionAutomationContext } from "../../../utilities";
import { AwsUpsertPolicyChangeInfo } from "./AwsUpsertPolicyChangeInfo";

type AwsUpsertPrincipalPolicyChangeProps = {
    title?: string;
    type: "inline" | "managed";
    updatedRawDocument: string;
    updateRawDocument: (rawDocument: string) => void;
};

export function AwsUpsertPrincipalPolicyChangeInfo({ title, type, updatedRawDocument, updateRawDocument }: AwsUpsertPrincipalPolicyChangeProps) {
    const { riskedEntityModel } = useRiskResolutionAutomationContext();
    const policyTypeName =
        type === "managed"
            ? Contract.TypeNames.AwsIamManagedPolicy
            : Contract.TypeNames.AwsIamInlinePolicy;

    const theme = useTheme();
    const localization =
        useLocalization(
            "common.riskResolutionAutomation.changeInfo.awsUpsertPrincipalPolicyChangeInfo",
            () => ({
                fields: {
                    principals: "Applied to",
                    type: {
                        title: "Policy Type",
                        value: {
                            inline: "Inline",
                            managed: "Managed"
                        }
                    }
                }
            }));
    return (
        <AwsUpsertPolicyChangeInfo
            subTitleElement={
                <Stack spacing={2}>
                    <Stack>
                        <Typography variant="h5">
                            {localization.fields.type.title()}
                        </Typography>
                        <Typography noWrap={true}>
                            {localization.fields.type.value[type]()}
                        </Typography>
                    </Stack>
                    <Stack>
                        <Typography variant="h5">
                            {localization.fields.principals()}
                        </Typography>
                        {_.isEmpty(riskedEntityModel)
                            ? undefined
                            : <Typography noWrap={true}>
                                <InlineEntities
                                    entityIdsOrModels={riskedEntityModel}
                                    entityLinkOptions={{ disabledHighlight: false }}
                                    entityTypeName={riskedEntityModel.entity.typeName}
                                    variant="itemOrItemCountAndType"/>
                            </Typography>}
                    </Stack>
                </Stack>}
            titleElement={
                _.isNil(title)
                    ? undefined
                    : <Stack
                        alignItems="center"
                        direction="row"
                        spacing={2}
                        sx={{
                            borderBottom: theme.border.alternate,
                            padding: theme.spacing(2)
                        }}>
                        {!_.isNil(policyTypeName) && (
                            <EntityIcon
                                entityTypeName={policyTypeName}
                                sx={{ fontSize: "30px" }}/>)}
                        <Typography variant="h2">
                            {title}
                        </Typography>
                    </Stack>}
            updatedRawDocument={updatedRawDocument}
            updateRawDocument={updateRawDocument}/>);
}