﻿import { Link, Message, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, CustomerConsoleAppUrlHelper, InfoItem } from "../../../../../../../../../common";
import { KubernetesClusterApiConnectivityStatus } from "../../../../KubernetesClusterApiConnectivityStatus";

export function useCommonKubernetesClusterInfoElements(clusterModel: Contract.IKubernetesClusterModel) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.useCommonKubernetesClusterInfoElements",
            () => ({
                info: {
                    items: {
                        apiConnectivityStatus: {
                            info: {
                                link: "documentation",
                                title: "Indicates whether Tenable Cloud Security can connect to the Kubernetes API. If the network is disconnected, or there are missing permissions, follow the onboarding instructions in the {{link}}"
                            },
                            title: "Resource Sync Status"
                        },
                        resourceUpdateTime: "Resource Sync Time"
                    }
                }
            }));
    return {
        createApiConnectivityStatusInfoItemElement:
            (documentationKubernetesOnboardClusterUrl: string) =>
                <InfoItem
                    key="apiConnectivityStatus"
                    title={localization.info.items.apiConnectivityStatus.title()}
                    titleInfoElement={
                        <Message
                            level="info"
                            title={
                                localization.info.items.apiConnectivityStatus.info.title({
                                    link:
                                        <Link
                                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsKubernetesRelativeUrl()}
                                            variant="external">
                                            {localization.info.items.apiConnectivityStatus.info.link()}
                                        </Link>
                                })}
                            variant="minimal"/>}
                    value={
                        <KubernetesClusterApiConnectivityStatus
                            apiConnectivityStatus={clusterModel.apiConnectivityStatus}
                            connector={clusterModel.helm.connector}
                            documentationKubernetesOnboardClusterUrl={documentationKubernetesOnboardClusterUrl}/>}/>,
        resourceUpdateTimeInfoItemElement:
            <InfoItem
                key="resourceUpdateTime"
                title={localization.info.items.resourceUpdateTime()}
                value={
                    _.isNil(clusterModel.resourceUpdateTime)
                        ? undefined
                        : TimeFormatter.humanizePastDuration(clusterModel.resourceUpdateTime)}/>
    };
}