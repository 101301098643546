import { DataTable, DataTableColumn, EmptyMessageText, Optional, optionalTableCell, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, IntRangeHelper, NetworkScopeFormatter, NetworkScopeHelper } from "../../../common";

type OciNetworkingSecurityListRulesTableProps = {
    getHighlightColor?: (rule: Contract.OciNetworkingSecurityListRule, opacity?: number) => Optional<string>;
    inbound: boolean;
    rules: Contract.OciNetworkingSecurityListRule[];
};

export function OciNetworkingSecurityListRulesTable({ getHighlightColor, inbound, rules }: OciNetworkingSecurityListRulesTableProps) {

    const localization =
        useLocalization(
            "tenants.oci.ociNetworkingSecurityListRulesTable",
            () => ({
                columns: {
                    description: "Description",
                    endpoint: {
                        title: {
                            inbound: "Source",
                            outbound: "Destination"
                        }
                    },
                    icmp: {
                        all: "All",
                        title: "Type and Code"
                    },
                    portRange:
                        {
                            all: "All",
                            title: {
                                destination: "Destination Port Range",
                                source: "Source Port Range"
                            }
                        },
                    protocol: "IP Protocol",
                    stateless: {
                        false: "No",
                        title: "Stateless",
                        true: "Yes"
                    }
                },
                empty: "No Rules"
            }));

    return (
        <DataTable
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={() =>
                _.orderBy(
                    rules,
                    rule => !_.isNil(getHighlightColor?.(rule)),
                    "desc"
                )}
            getItemId={(rule: Contract.OciNetworkingSecurityListRule) => _.indexOf(rules, rule).toString()}
            rowOptions={{
                getHighlightColor: (rule: Contract.OciNetworkingSecurityListRule) => getHighlightColor?.(rule),
                getSx:
                    (rule: Contract.OciNetworkingSecurityListRule) => ({
                        backgroundColor: getHighlightColor?.(rule, 0.1)
                    })
            }}
            sortOptions={{ enabled: false }}
            variant="card">
            <DataTableColumn
                id={OciNetworkingSecurityListRulesTableColumnId.Stateless}
                itemProperty={(rule: Contract.OciNetworkingSecurityListRule) =>
                    rule.stateless
                        ? localization.columns.stateless.true()
                        : localization.columns.stateless.false()
                }
                title={localization.columns.stateless.title()}/>
            <DataTableColumn
                id={OciNetworkingSecurityListRulesTableColumnId.Endpoint}
                itemProperty={(rule: Contract.OciNetworkingSecurityListRule) =>
                    _.isNil(rule.subnet)
                        ? rule.serviceCidrLabel
                        : rule.subnet}
                title={
                    inbound
                        ? localization.columns.endpoint.title.inbound()
                        : localization.columns.endpoint.title.outbound()}/>
            <DataTableColumn
                id={OciNetworkingSecurityListRulesTableColumnId.Protocol}
                itemProperty={(rule: Contract.OciNetworkingSecurityListRule) => NetworkScopeFormatter.protocolRange(rule.protocolRange, Contract.TenantType.Oci)}
                title={localization.columns.protocol()}/>
            <DataTableColumn
                id={OciNetworkingSecurityListRulesTableColumnId.SourcePortRange}
                render={
                    optionalTableCell<Contract.OciNetworkingSecurityListRule>(
                        rule =>
                            _.isNil(rule.sourcePortRange)
                                ? undefined
                                : <Typography noWrap={true}>
                                    {NetworkScopeHelper.isAll(rule.sourcePortRange)
                                        ? localization.columns.portRange.all()
                                        : IntRangeHelper.format(rule.sourcePortRange)}
                                </Typography>)}
                title={localization.columns.portRange.title.source()}/>
            <DataTableColumn
                id={OciNetworkingSecurityListRulesTableColumnId.DestinationPortRange}
                render={
                    optionalTableCell<Contract.OciNetworkingSecurityListRule>(
                        rule =>
                            _.isNil(rule.destinationPortRange)
                                ? undefined
                                : <Typography noWrap={true}>
                                    {NetworkScopeHelper.isAll(rule.destinationPortRange)
                                        ? localization.columns.portRange.all()
                                        : IntRangeHelper.format(rule.destinationPortRange)}
                                </Typography>)}
                title={localization.columns.portRange.title.destination()}/>
            <DataTableColumn
                id={OciNetworkingSecurityListRulesTableColumnId.Icmp}
                render={
                    optionalTableCell<Contract.OciNetworkingSecurityListRule>(
                        rule =>
                            _.isNil(rule.icmp)
                                ? undefined
                                : `${rule.icmp.type ?? localization.columns.icmp.all()}, ${rule.icmp.code ?? localization.columns.icmp.all()}`)}
                title={localization.columns.icmp.title()}/>
            <DataTableColumn
                id={OciNetworkingSecurityListRulesTableColumnId.Description}
                render={
                    optionalTableCell<Contract.OciNetworkingSecurityListRule>(
                        rule =>
                            _.isEmpty(rule.description)
                                ? undefined
                                : rule.description)}
                title={localization.columns.description()}/>
        </DataTable>);
}

enum OciNetworkingSecurityListRulesTableColumnId {
    Description = "description",
    DestinationPortRange = "destinationPortRange",
    Endpoint = "endpoint",
    Icmp = "icmp",
    Protocol = "protocol",
    SourcePortRange = "sourcePortRange",
    Stateless = "stateless"
}