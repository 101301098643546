import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useGetAadDirectoryEntityRiskContext } from "./useGetAadDirectoryEntityRiskContext";

export function useGetAadDirectoryUserRiskContext() {
    return useMemo(
        () => useAadDirectoryUserRiskContext,
        []);
}

function useAadDirectoryUserRiskContext(userModel: Contract.AadDirectoryUserModel) {
    return useGetAadDirectoryEntityRiskContext()(userModel);
}