﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity } from "../../../../../../../../../../../common";

export function useUnmanagedKubernetesClusterGeneralInformationStep(resourceModel: Contract.EntityModel) {
    const unmanagedKubernetesCluster = _.as<Contract.IUnmanagedKubernetesCluster>(resourceModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.useResourceGeneralInformationStep.hooks.useUnmanagedKubernetesClusterGeneralInformationStep",
            () => ({
                creationTime: "{{resource}} was created on {{creationTime | TimeFormatter.shortDate}}"
            }));
    return localization.creationTime({
        creationTime: unmanagedKubernetesCluster.data.creationTime,
        resource:
            <Entity
                entityIdOrModel={resourceModel}
                entityTypeNameTranslatorOptions={{ variant: "title" }}
                variant="typeText"/>
    });
}