﻿import { Message, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, InfoItem, useActivityTranslator, usePrincipalModelAccess } from "../../../../../../../common";

type GciIdentityActivityInfoItemProps = {
    identityModel: Contract.EntityModel;
};

export function GciIdentityActivityInfoItem({ identityModel }: GciIdentityActivityInfoItemProps) {
    const identityModelAccess = usePrincipalModelAccess<Contract.PrincipalModelAccess>(identityModel.id);

    const activityTranslator = useActivityTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.gciIdentityActivityInfoItem",
            () => ({
                helpText: "The last time an activity was seen in GCP for this identity. This is highly dependent on the logging configuration. Activity that was not logged will not be taken into account.",
                title: "GCP Activity Time"
            }));

    return (
        <InfoItem
            title={localization.title()}
            titleInfoElement={
                <Message
                    level="info"
                    title={localization.helpText()}
                    variant="minimal"/>}
            value={activityTranslator(identityModelAccess.activity)}/>);
}