import { Loading } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, entitySearchableReferenceStore, useAccessContext, useTheme } from "../../../..";
import { AccessGraphHelper, AccessGraphPermissionEdgeData } from "../../utilities";
import { DestinationEntities, PermissionActions, Services, SourceEntities } from "./components";

type EntitiesPermissionActionsProps = {
    accessGraphId: string;
    accessVariant: Contract.EntityAccessVariant;
    excessivePermissionsEnabled: boolean;
    permissionEdgeIdToDataMap: Dictionary<AccessGraphPermissionEdgeData>;
    permissionPaths: Contract.AccessGraphPermissionPath[];
    resourceServiceModelMap: Dictionary<Contract.EntityModel>;
    variant: "graph" | "list";
};

export function EntitiesPermissionActions({ accessGraphId, accessVariant, excessivePermissionsEnabled, permissionEdgeIdToDataMap, permissionPaths, resourceServiceModelMap, variant }: EntitiesPermissionActionsProps) {
    const { definition } = useAccessContext();

    const [destinationEntityIds, serviceIds, sourceEntityIds] =
        useMemo(
            () => {
                const destinationEntityIds = new Set<string>();
                const serviceIds = new Set<string>();
                const sourceEntityIds = new Set<string>();
                for (const permissionEdgeData of _.values(permissionEdgeIdToDataMap)) {
                    for (const destinationEntityId of permissionEdgeData.destinationEntityIds) {
                        destinationEntityIds.add(destinationEntityId);
                    }

                    serviceIds.add(permissionEdgeData.destinationEntitiesServiceId);

                    for (const sourceEntityId of permissionEdgeData.sourceEntityIds) {
                        sourceEntityIds.add(sourceEntityId);
                    }
                }
                return [Array.from(destinationEntityIds), Array.from(serviceIds), Array.from(sourceEntityIds)];
            },
            [permissionEdgeIdToDataMap]);

    const entitySearchableReferences =
        entitySearchableReferenceStore.useGet(
            _.concat(
                sourceEntityIds,
                serviceIds,
                destinationEntityIds));
    const entityIdToSearchableReferenceMap =
        useMemo(
            () =>
                _.keyBy(
                    entitySearchableReferences,
                    entitySearchableReference => entitySearchableReference.id),
            [entitySearchableReferences]);

    const { showDestinationEntities, showServices, showSourceEntities } =
        useMemo(
            () => {
                const { showDestinationEntities, showServices, showSourceEntities } =
                    AccessGraphHelper.getColumnsData(
                        accessVariant,
                        sourceEntityIds.length,
                        _.every(
                            destinationEntityIds,
                            entityId => !_.isNil(resourceServiceModelMap[entityId])),
                        variant);
                return {
                    showDestinationEntities,
                    showServices,
                    showSourceEntities
                };
            },
            []);

    const [selectedSourceEntityId, setSelectedSourceEntityId] =
        useState(
            showSourceEntities
                ? undefined
                : sourceEntityIds[0]);
    const [selectedServiceId, setSelectedServiceId] =
        useState(
            showServices
                ? undefined
                : serviceIds[0]);
    const [selectedDestinationEntityId, setSelectedDestinationEntityId] =
        useState(
            showDestinationEntities
                ? undefined
                : destinationEntityIds[0]);

    const theme = useTheme();
    return (
        <Stack
            direction="row"
            sx={{ height: "100%" }}>
            {showSourceEntities &&
                <Box
                    sx={{
                        borderRight: theme.border.primary,
                        flex: 1,
                        flexBasis: 0,
                        minWidth: 0
                    }}>
                    <SourceEntities
                        entityIdToSearchableReferenceMap={entityIdToSearchableReferenceMap}
                        excessivePermissionsEnabled={excessivePermissionsEnabled}
                        permissionEdgeIdToDataMap={permissionEdgeIdToDataMap}
                        resourceServiceModelMap={resourceServiceModelMap}
                        selectedSourceEntityId={selectedSourceEntityId}
                        onSelectedSourceEntityChanged={
                            sourceEntityId => {
                                if (sourceEntityId != selectedSourceEntityId) {
                                    setSelectedSourceEntityId(sourceEntityId);

                                    if (showServices) {
                                        setSelectedServiceId(undefined);
                                    }

                                    if (showDestinationEntities) {
                                        setSelectedDestinationEntityId(undefined);
                                    }
                                }
                            }}/>
                </Box>}
            {showServices &&
                <Box
                    sx={{
                        borderRight: theme.border.primary,
                        flex: 1,
                        flexBasis: 0,
                        minWidth: 0
                    }}>
                    {_.isNil(selectedSourceEntityId)
                        ? <Loading loading={true}/>
                        : <Services
                            entityIdToSearchableReferenceMap={entityIdToSearchableReferenceMap}
                            excessivePermissionsEnabled={excessivePermissionsEnabled}
                            key={selectedSourceEntityId}
                            permissionEdgeIdToDataMap={permissionEdgeIdToDataMap}
                            resourceServiceModelMap={resourceServiceModelMap}
                            selectedServiceId={selectedServiceId}
                            sourceEntityId={selectedSourceEntityId}
                            onSelectedServiceChanged={
                                serviceId => {
                                    if (serviceId != selectedServiceId) {
                                        setSelectedServiceId(serviceId);
                                        setSelectedDestinationEntityId(
                                            showDestinationEntities
                                                ? undefined
                                                : serviceId);
                                    }
                                }}/>}
                </Box>}
            {showDestinationEntities &&
                <Box
                    sx={{
                        borderRight: theme.border.primary,
                        flex: 1,
                        flexBasis: 0,
                        minWidth: 0
                    }}>
                    {_.isNil(selectedSourceEntityId) || _.isNil(selectedServiceId)
                        ? <Loading loading={true}/>
                        : <DestinationEntities
                            entityIdToSearchableReferenceMap={entityIdToSearchableReferenceMap}
                            excessivePermissionsEnabled={excessivePermissionsEnabled}
                            key={`${selectedSourceEntityId}.${selectedServiceId}`}
                            permissionEdgeIdToDataMap={permissionEdgeIdToDataMap}
                            resourceServiceModelMap={resourceServiceModelMap}
                            selectedDestinationEntityId={selectedDestinationEntityId}
                            serviceId={selectedServiceId}
                            sourceEntityId={selectedSourceEntityId}
                            onSelectedEntityChanged={setSelectedDestinationEntityId}/>}
                </Box>}
            <Box
                sx={{
                    flex: 1,
                    flexBasis: 0,
                    minWidth: 0
                }}>
                <Loading
                    loading={
                        _.isNil(selectedSourceEntityId) ||
                        _.isNil(selectedServiceId) ||
                        _.isNil(selectedDestinationEntityId)}>
                    {!_.isNil(selectedSourceEntityId) &&
                        !_.isNil(selectedServiceId) &&
                        !_.isNil(selectedDestinationEntityId) &&
                        <PermissionActions
                            accessGraphId={accessGraphId}
                            excessivePermissionsEnabled={excessivePermissionsEnabled}
                            key={`${selectedSourceEntityId}.${selectedServiceId}.${selectedDestinationEntityId}`}
                            permissionEdgeIdToDataMap={permissionEdgeIdToDataMap}
                            selectedDestinationEntityId={selectedDestinationEntityId}
                            selectedPermissionPaths={permissionPaths}
                            selectedSourceEntityId={selectedSourceEntityId}
                            serviceName={definition.entitiesPermissionActions.getServiceName(resourceServiceModelMap[selectedServiceId])}/>}
                </Loading>
            </Box>
        </Stack>);
}