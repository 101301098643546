import { Dictionary } from "lodash";
import { EntityTableDefinition } from "../../..";
import { Contract, ItemSelectionHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useGciCommonEntityDefinition } from "./useGciCommonEntityDefinition";

export function useGciDefaultEntityDefinition(definitionData: DefinitionData) {
    const commonEntityDefinition = useGciCommonEntityDefinition(definitionData);
    return new EntityTableDefinition(
        [
            commonEntityDefinition.columns.tenantColumn,
            commonEntityDefinition.columns.attributesColumn,
            commonEntityDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonEntityDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonEntityDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGciDefaultDirectoryEntityRequest(
                new Contract.EntityControllerGetEntityModelPageGciDefaultDirectoryEntityRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    definitionData.entityTypeEntitiesViewName),
                limit,
                skip,
                requestSort));
}