import { OperationStore } from "@infrastructure";
import { Contract, RiskController } from "../controllers";

export const kubernetesAdmissionControllerRiskPolicyItemsOperationStore =
    new OperationStore(
        async (scopeId: string) => {
            const [getKubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToLastViolationEventTimeMapResponse, getRiskPoliciesResponse, getRiskPolicySummaryResponse] =
                await Promise.all([
                    RiskController.getKubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToLastViolationEventTimeMap(new Contract.RiskControllerGetKubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToLastViolationEventTimeMapRequest(scopeId)),
                    RiskController.getRiskPolicies(new Contract.RiskControllerGetKubernetesAdmissionControllerRiskPoliciesRequest(scopeId)),
                    RiskController.getRiskPolicySummary(new Contract.RiskControllerGetKubernetesAdmissionControllerRiskPolicySummaryRequest(scopeId))
                ]);

            return {
                ...getKubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToLastViolationEventTimeMapResponse,
                ...getRiskPoliciesResponse,
                ...getRiskPolicySummaryResponse
            };
        });