import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useGcpCommonScopeResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { useGcpComputeFirewallPolicyScopeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useGcpComputeFirewallPolicyDefinition(definitionData: DefinitionData) {
    const commonScopeResourceDefinition = useGcpCommonScopeResourceDefinition(definitionData);

    const computeFirewallPolicyScopeTranslator = useGcpComputeFirewallPolicyScopeTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpComputeFirewallPolicyDefinition",
            () => ({
                columns: {
                    attachedResourceIdReferences: "Associations",
                    rulesCount: "Firewall Rules Count",
                    scope: "Firewall Policy Type",
                    vpcIds: "VPC Networks"
                }
            }));
    return new EntityTableDefinition(
        [
            commonScopeResourceDefinition.columns.scopeColumn,
            commonScopeResourceDefinition.columns.tenantColumn,
            commonScopeResourceDefinition.columns.creationTimeColumn,
            commonScopeResourceDefinition.columns.creatorIdentityCsvColumn,
            commonScopeResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            commonScopeResourceDefinition.columns.accessLevelColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpComputeFirewallPolicyModel) => ({
                            [localization.columns.scope()]: computeFirewallPolicyScopeTranslator((item.entity as Contract.GcpComputeFirewallPolicy).scope)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.GcpComputeFirewallPolicyScope}
                                enumTypeTranslator={computeFirewallPolicyScopeTranslator}
                                placeholder={localization.columns.scope()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeFirewallPolicyScope}
                itemProperty={(item: Contract.EntityModel) => computeFirewallPolicyScopeTranslator((item.entity as Contract.GcpComputeFirewallPolicy).scope)}
                key={Contract.EntityModelProperty.GcpComputeFirewallPolicyScope}
                title={localization.columns.scope()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpComputeFirewallPolicyModel) => ({
                            [localization.columns.attachedResourceIdReferences()]:
                                entityTypeNameTranslator(
                                    Contract.TypeNames.GcpScopeResource,
                                    {
                                        count: item.attachedResourceIdReferences.length,
                                        includeCount: true,
                                        includeServiceName: false
                                    })
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpComputeFirewallPolicyAttachedResources)}
                                placeholder={localization.columns.attachedResourceIdReferences()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeFirewallPolicyAttachedResources}
                key={Contract.EntityModelProperty.GcpComputeFirewallPolicyAttachedResources}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpComputeFirewallPolicyModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.attachedResourceIdReferences}
                            entityTypeName={Contract.TypeNames.GcpScopeResource}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.attachedResourceIdReferences()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpComputeFirewallPolicyModel) => ({
                            [localization.columns.vpcIds()]:
                                entityTypeNameTranslator(
                                    Contract.TypeNames.GcpComputeVpc,
                                    {
                                        count: item.vpcIds.length,
                                        includeCount: true,
                                        includeServiceName: false
                                    })
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpComputeFirewallPolicyVpcs)}
                                placeholder={localization.columns.vpcIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeFirewallPolicyVpcs}
                key={Contract.EntityModelProperty.GcpComputeFirewallPolicyVpcs}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpComputeFirewallPolicyModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.vpcIds}
                            entityTypeName={Contract.TypeNames.GcpComputeVpc}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.vpcIds()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpComputeFirewallPolicyModel) => ({
                            [localization.columns.rulesCount()]: (item.entity as Contract.GcpComputeFirewallPolicy).rules.length
                        })
                }}
                id={Contract.EntityModelProperty.GcpComputeFirewallPolicyRulesCount}
                itemProperty={(item: Contract.GcpComputeFirewallPolicyModel) => (item.entity as Contract.GcpComputeFirewallPolicy).rules.length}
                key={Contract.EntityModelProperty.GcpComputeFirewallPolicyRulesCount}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.rulesCount()}/>,
            commonScopeResourceDefinition.columns.regionColumn,
            commonScopeResourceDefinition.columns.regionLocationColumn,
            commonScopeResourceDefinition.columns.tagsColumn,
            commonScopeResourceDefinition.columns.attributesColumn,
            commonScopeResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpComputeFirewallPolicyRequest(
                new Contract.EntityControllerGetEntityModelPageGcpComputeFirewallPolicyRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceAccessLevel]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeFirewallPolicyAttachedResources]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeFirewallPolicyScope]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeFirewallPolicyVpcs])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}