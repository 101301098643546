import { DataTableColumn, EnumValuesFilter, optionalTableCell, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, InlineEntities, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAwsEmrClusterStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { EntityTableDefinition } from "../../useDefinition";
import { useAwsCommonNetworkedResourceDefinition } from "./useAwsCommonNetworkedResourceDefinition";
import { useAwsCommonResourceDefinition } from "./useAwsCommonResourceDefinition";

export function useAwsEmrClusterDefinition(definitionData: DefinitionData) {
    const commonNetworkedResourceDefinition = useAwsCommonNetworkedResourceDefinition(definitionData);
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);

    const emrClusterStatusTranslator = useAwsEmrClusterStatusTranslator();
    const entitiesExportOptions = useEntitiesExportOptions();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsEmrClusterDefinition",
            () => ({
                columns: {
                    dnsName: "DNS Name",
                    instanceIds: "Nodes",
                    status: "State"
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsEmrClusterModel) => ({
                            [localization.columns.status()]: emrClusterStatusTranslator((item.entity as Contract.AwsEmrCluster).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AwsEmrClusterStatus}
                                enumTypeTranslator={emrClusterStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsEmrClusterStatus}
                itemProperty={(item: Contract.AwsEmrClusterModel) => emrClusterStatusTranslator((item.entity as Contract.AwsEmrCluster).status)}
                key={Contract.EntityModelProperty.AwsEmrClusterStatus}
                title={localization.columns.status()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsEmrClusterModel>(
                        Contract.TypeNames.AwsEc2Instance,
                        item =>
                            _((item.entity as Contract.AwsEmrCluster).instanceCollectionTypeToInstanceReferencesMap).
                                values().
                                flatMap().
                                map(instanceReference => instanceReference.idReference).
                                value(),
                        localization.columns.instanceIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsEmrClusterInstances)}
                                placeholder={localization.columns.instanceIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsEmrClusterInstances}
                key={Contract.EntityModelProperty.AwsEmrClusterInstances}
                render={
                    optionalTableCell<Contract.AwsEmrClusterModel>(
                        item =>
                            _.isEmpty((item.entity as Contract.AwsEmrCluster).instanceCollectionTypeToInstanceReferencesMap)
                                ? undefined
                                : <InlineEntities
                                    entityIdsOrModels={
                                        _((item.entity as Contract.AwsEmrCluster).instanceCollectionTypeToInstanceReferencesMap).
                                            values().
                                            flatMap().
                                            map(instanceReference => instanceReference.idReference).
                                            value()}
                                    entityTypeName={Contract.TypeNames.AwsEc2Instance}
                                    entityVariant="iconText"
                                    variant="itemPlusItemCount"/>)}
                title={localization.columns.instanceIds()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsEmrClusterModel) => ({
                            [localization.columns.dnsName()]: (item.entity as Contract.AwsEmrCluster).dnsName
                        })
                }}
                id="dnsName"
                key="dnsName"/>,
            commonNetworkedResourceDefinition.columns.accessLevelColumn,
            commonNetworkedResourceDefinition.columns.inboundExternalAccessScopeColumn,
            commonNetworkedResourceDefinition.columns.getVpcsColumn(),
            commonNetworkedResourceDefinition.columns.securityGroupsColumn,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsEmrClusterRequest(
                new Contract.EntityControllerGetEntityModelPageAwsEmrClusterRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEmrClusterInstances]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEmrClusterStatus])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestNetworkAccess(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundAccessType]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalAccessScope]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalDestinationNetworkScopes])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestNetworked(
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsNetworkedResourceSecurityGroups]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsNetworkedResourceVpcs]))));
}