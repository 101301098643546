import { map } from "@infrastructure";
import { Contract, RegionModelHelper, tenantModelStore } from "../../../../../../../../../../../../../../../common";
import { GcpConsoleUrlBuilder } from "../../../../../../../../../../../../../../../tenants";
import { EntityExternalConsoleLinkDefinition } from "../useDefinition";

export function useGcpFunctionsFunctionDefinition(entityModel: Contract.EntityModel, page: Contract.GcpConsolePage) {
    const functionEntity = entityModel.entity as Contract.GcpFunctionsFunction;
    const tenantModel = tenantModelStore.useGet(functionEntity.tenantId);

    const getUrl =
        map(
            page,
            {
                [Contract.GcpConsolePage.Resource]: () => GcpConsoleUrlBuilder.GetFunctionsFunctionUrl,
                [Contract.GcpConsolePage.Permissions]: () => GcpConsoleUrlBuilder.GetFunctionsFunctionPermissionsUrl,
                [Contract.GcpConsolePage.Variables]: () => GcpConsoleUrlBuilder.GetFunctionsFunctionVariablesUrl
            });

    return new EntityExternalConsoleLinkDefinition(
        getUrl(
            RegionModelHelper.get(functionEntity.regionId!).systemName,
            functionEntity.name,
            (tenantModel.configuration as Contract.GcpTenantConfiguration).rawShortNameId));
}