﻿import { useLocalization } from "@infrastructure";
import { Contract } from "..";

export function useCloudProviderTenantTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useCloudProviderTenantTranslator",
            () => ({
                [Contract.TypeNames.TenantType]: {
                    [Contract.TenantType.Aws]: [
                        "account",
                        "accounts"
                    ],
                    [Contract.TenantType.Azure]: [
                        "subscription",
                        "subscriptions"
                    ],
                    [Contract.TenantType.Gcp]: [
                        "Project",
                        "projects"
                    ],
                    [Contract.TenantType.Oci]: [
                        "compartment",
                        "compartments"
                    ],
                    [Contract.TenantType.Op]: [
                        "on-premises account",
                        "on-premises accounts"
                    ]
                }
            }));

    return (tenantCount: number, tenantType: Contract.TenantType.Aws | Contract.TenantType.Azure | Contract.TenantType.Gcp | Contract.TenantType.Oci | Contract.TenantType.Op) =>
        localization[Contract.TypeNames.TenantType][tenantType](tenantCount);
}