import { DataTableColumn, EmptyMessageText, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, ItemTable } from "../../../../../../../../../common";

type AadDirectoryServicePrincipalApplicationMicrosoftGraphPermissionsProps = {
    applicationRoleDefinitions: Contract.AadDirectoryApplicationRoleDefinition[];
    csvExportFilePrefixes: string[];
};

export function AadDirectoryServicePrincipalMicrosoftGraphPermissions({ applicationRoleDefinitions, csvExportFilePrefixes }: AadDirectoryServicePrincipalApplicationMicrosoftGraphPermissionsProps) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.aadDirectoryServicePrincipalMicrosoftGraphPermissions",
            () => ({
                columns: {
                    name: "Permission Name",
                    value: "Description"
                },
                empty: "No Microsoft Entra ID Graph API permissions"
            }));

    return (
        <ItemTable
            columnIdToGetItemValueMap={{
                [TableColumnId.Name]: item => item.name,
                [TableColumnId.Value]: item => item.value
            }}
            csvExportFilePrefixes={csvExportFilePrefixes}
            defaultSortColumnIdOrIds={TableColumnId.Name}
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            getCsvItem={
                item => ({
                    "Description": item.name,
                    "Permission Name": item.value
                })}
            getItemId={item => item.rawId}
            items={applicationRoleDefinitions}>
            {() =>
                [
                    <DataTableColumn
                        id={TableColumnId.Name}
                        itemProperty={(item: Contract.AadDirectoryApplicationRoleDefinition) => item.value}
                        key={TableColumnId.Name}
                        title={localization.columns.name()}/>,
                    <DataTableColumn
                        id={TableColumnId.Value}
                        itemProperty={(item: Contract.AadDirectoryApplicationRoleDefinition) => item.name}
                        key={TableColumnId.Value}
                        title={localization.columns.value()}/>
                ]}
        </ItemTable>);
}

enum TableColumnId {
    Name = "name",
    Value = "value"
}