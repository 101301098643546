import { Optional, useLocalization } from "@infrastructure";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, RadioGroup, RadioGroupItem, ScopeHelper, ScopeLabel, scopeNodeModelStore, SeveritySelector, useScopeNavigationViewContext, useSeverityTranslator } from "../../../../../../../../../../common";
import { useInheritedScopeId, useUpdateConfiguration } from "../../../../hooks";

enum SevereVulnerabilityMinSeverityValueType {
    Inherit = "Inherit",
    Scope = "Scope"
}

export function SevereVulnerabilityMinSeverity() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const severityTranslator = useSeverityTranslator();

    const inheritedScopeId = useInheritedScopeId(workloadAnalysis => workloadAnalysis.severeVulnerabilityMinSeverity);

    const { workloadAnalysis } = scopeNodeModel.configuration.scopeSections;
    const inheritedSevereVulnerabilityMinSeverity =
        useMemo(
            () => {
                if (_.isNil(inheritedScopeId)) {
                    return undefined;
                }

                return scopeNodeMap[inheritedScopeId].scopeNodeModel.configuration.scopeSections.workloadAnalysis.severeVulnerabilityMinSeverity;
            },
            [scopeNodeModel]);

    function getSevereVulnerabilityMinSeverityValueType(severity?: Contract.Severity) {
        return _.isNil(severity)
            ? SevereVulnerabilityMinSeverityValueType.Inherit
            : SevereVulnerabilityMinSeverityValueType.Scope;
    }

    const inheritEnable = scopeNodeModel.configuration.id !== ScopeHelper.customerId;

    const [severity, setSeverity] = useState(workloadAnalysis.severeVulnerabilityMinSeverity ?? Contract.Severity.Critical);

    const [loading, setLoading] = useState(false);
    const updateConfiguration = useUpdateConfiguration();

    async function updateSevereVulnerabilityMinSeverity(severeVulnerabilityMinSeverity?: Contract.Severity, severeVulnerabilityMinSeverityValueType: SevereVulnerabilityMinSeverityValueType = SevereVulnerabilityMinSeverityValueType.Scope) {
        setLoading(true);
        await updateConfiguration({
            severeVulnerabilityMinSeverity:
                severeVulnerabilityMinSeverityValueType === SevereVulnerabilityMinSeverityValueType.Inherit
                    ? undefined
                    : severeVulnerabilityMinSeverity
        });

        setSeverity(severeVulnerabilityMinSeverity ?? Contract.Severity.Critical);
        setLoading(false);
    }

    const localization =
        useLocalization(
            "views.customer.configuration.workloadAnalysis.vulnerabilities.severeVulnerabilityMinSeverity",
            () => ({
                description: "Set the minimum severity for creating findings. By default, findings are created for critical vulnerabilities.",
                options: {
                    inherit: {
                        entity: "({{severity}})",
                        title: "Inherit"
                    },
                    severity: {
                        title: "Severity"
                    }
                },
                title: "Minimum Vulnerability Severity for Findings"
            }));

    const theme = useTheme();
    const items =
        useMemo(
            () =>
                _<RadioGroupItem<SevereVulnerabilityMinSeverityValueType>>([]).
                    concatIf(
                        inheritEnable,
                        {
                            label:
                            <ScopeLabel
                                inheritedScopeId={inheritedScopeId}
                                text={localization.options.inherit.title()}
                                translatedInheritedValue={severityTranslator(inheritedSevereVulnerabilityMinSeverity)}/>,
                            value: SevereVulnerabilityMinSeverityValueType.Inherit
                        }).
                    concat([
                        {
                            children:
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={1}
                                sx={{ width: theme.spacing(3) }}>
                                <Box>
                                    <SeveritySelector
                                        justify="left"
                                        selectedSeverity={severity}
                                        sx={{ borderRadius: theme.spacing(0.75) }}
                                        variant="outlined"
                                        onSelectedSeverityChanged={updateSevereVulnerabilityMinSeverity}/>
                                </Box>
                            </Stack>,
                            label: localization.options.severity.title(),
                            layout: "horizontal",
                            value: SevereVulnerabilityMinSeverityValueType.Scope
                        }]).
                    value(),
            [inheritEnable, inheritedSevereVulnerabilityMinSeverity, inheritedScopeId, loading, severity]);

    const severeVulnerabilityMinSeverity = severity as Optional<Contract.Severity>;
    return (
        <RadioGroup
            description={
                <Typography
                    sx={{
                        paddingTop: theme.spacing(1),
                        whiteSpace: "pre-wrap"
                    }}>
                    {localization.description()}
                </Typography>}
            items={items}
            loading={loading}
            selectedValue={getSevereVulnerabilityMinSeverityValueType(workloadAnalysis.severeVulnerabilityMinSeverity)}
            title={localization.title()}
            onChange={
                async (value: SevereVulnerabilityMinSeverityValueType) => {
                    await updateSevereVulnerabilityMinSeverity(
                        severeVulnerabilityMinSeverity,
                        value);
                }}/>);
}