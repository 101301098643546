import { CheckboxField, Message, useExecuteOperation, useInputValidation, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { FormControl, FormHelperText, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useAddOrEditContext, useSetAddOrEditContext } from "../..";
import { ConfigurationController, Contract, InlineTenants, tenantModelStore, useTheme } from "../../../../../../../../../../../../../../../common";
import { AwsRegionSystemNamesSelector } from "../../../../../../../../../../../../../../../tenants";
import { FolderEnabled } from "../../../../../../../../../components";

export function OrganizationDetailsItem() {
    const { executing, setLoaded, setValid, useNextEffect } = useOrderedWizardContext();
    const addOrEditContext = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();

    const tenantModels = tenantModelStore.useGetPermittedAwsTenants();

    const [{ partitionTypeToRegionSystemNamesMap }] =
        useExecuteOperation(
            OrganizationDetailsItem,
            ConfigurationController.getAwsRegions);

    useEffect(
        () => {
            setLoaded();
        },
        []);

    const [enabled, setEnabled] = useState(addOrEditContext.enabled ?? true);
    const [folderEnabled, setFolderEnabled] = useState(addOrEditContext.folderEnabled ?? true);
    const [name, setName] = useState(addOrEditContext.name ?? "");
    const [regionSystemNames, setRegionSystemNames] = useState(addOrEditContext.regionSystemNames);
    const [roleName, setRoleName] = useState(addOrEditContext.roleName);
    const [trailNamePattern, setTrailNamePattern] = useState(addOrEditContext.trailNamePattern);

    const organizationExistingTenantIds =
        useMemo(
            () =>
                _(tenantModels).
                    filter(
                        tenantModel =>
                            addOrEditContext.masterTenantModel!.configuration.id === tenantModel.state.organization?.masterTenantRawId &&
                            addOrEditContext.masterTenantModel!.configuration.id !== tenantModel.configuration.id &&
                            roleName == tenantModel.roleName).
                    map(tenantModel => tenantModel.configuration.id).
                    value(),
            [roleName, tenantModels]);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.aws.addOrEdit.organizationDetailsItem",
            () => ({
                actions: {
                    next: {
                        error: "Failed to get Organization Root, Please verify **Management account** permissions to **Organizations** service"
                    }
                },
                fields: {
                    organizationName: {
                        error: {
                            required: "Organization name cannot be empty"
                        },
                        title: "Organization Name"
                    },
                    regionSystemNames: {
                        error: {
                            empty: "Active Regions cannot be empty"
                        },
                        some: [
                            "1 Region",
                            "{{count | NumberFormatter.humanize}} Regions"
                        ],
                        subtitle: "Select the specific regions used by your organization, as unselected regions will be ignored. Enable all regions not disabled or blocked by SCPs for scanning. If you expand to new regions later, edit your account to add them",
                        title: "Active Regions"
                    },
                    roleName: {
                        error: {
                            required: "Role name cannot be empty"
                        },
                        title: "Role Name"
                    },
                    settings: {
                        enabled: {
                            info: "Uncheck this option to prevent new accounts that you add in AWS from being automatically onboarded to Tenable.",
                            title: "Automatically onboard new accounts"
                        },
                        folderEnabled: {
                            info: "Mirror your account's structure as folders within Tenable, preserving its hierarchy and keeping the view synchronized.",
                            title: "Automatically update folder structure"
                        },
                        title: "Onboarding Settings"
                    },
                    trailName: {
                        info: "Select the name of the CloudTrail to be used in all member accounts, assuming you are using a default name for member accounts. If that is not the case, you may still configure CloudTrail for individual accounts, after onboarding",
                        title: "CloudTrail Name (Optional)"
                    }
                },
                organizationExistingTenants: "{{organizationExistingTenants}} were onboarded with an IAM Role with the same name. To make sure deploying the StackSet completes successfully on the next step, please select a different IAM Role name. After deploying the StackSet, you will be able to remove the existing roles safely"
            }));

    useNextEffect(
        async () => {
            try {
                const { rootRawId } =
                    await ConfigurationController.getAwsOrganizationOnboardingInfoRoot(
                        new Contract.ConfigurationControllerGetAwsOrganizationOnboardingInfoRootRequest(
                            addOrEditContext.masterTenantModel!.configuration.id));
                setAddOrEditContext(
                    addOrEditContext => ({
                        ...addOrEditContext,
                        rootRawId
                    }));
            } catch {
                return localization.actions.next.error();
            }

            return undefined;
        },
        []);

    const [organizationNameValidationController, organizationNameValidationMessage] =
        useInputValidation(
            () => {
                const validationName = name?.trim();
                if (_.isEmpty(validationName)) {
                    return localization.fields.organizationName.error.required();
                }

                return undefined;
            },
            [name]);

    const [roleNameValidationController, roleNameValidationMessage] =
        useInputValidation(
            () => {
                const validationName = roleName?.trim();
                if (_.isEmpty(validationName)) {
                    return localization.fields.roleName.error.required();
                }

                return undefined;
            },
            [roleName]);

    const [regionSystemNamesValidationController, regionSystemNamesValidationMessage] =
        useInputValidation(
            () => {
                if (!_.isNil(regionSystemNames) && _.isEmpty(regionSystemNames)) {
                    return localization.fields.regionSystemNames.error.empty();
                }

                return undefined;
            },
            [regionSystemNames]);

    useEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    enabled,
                    folderEnabled,
                    name: name?.trim(),
                    regionSystemNames,
                    roleName,
                    trailNamePattern
                }));
            setValid(
                organizationNameValidationController.isValid() &&
                regionSystemNamesValidationController.isValid() &&
                roleNameValidationController.isValid());
        },
        [enabled, folderEnabled, name, regionSystemNames, roleName, trailNamePattern]);

    const theme = useTheme();
    const partitionRegionSystemNames = partitionTypeToRegionSystemNamesMap[addOrEditContext.masterTenantModel!.configuration.partitionType];
    return (
        <Stack
            justifyContent="space-between"
            spacing={3}
            sx={{
                height: "100%",
                maxWidth: theme.spacing(80)
            }}>
            <Stack
                spacing={3}
                sx={{ paddingBottom: theme.spacing(3) }}>
                <Stack spacing={1}>
                    <Typography variant="h4">
                        {localization.fields.organizationName.title()}
                    </Typography>
                    <FormControl
                        fullWidth={true}
                        variant="standard">
                        <TextField
                            autoFocus={true}
                            disabled={executing}
                            value={name}
                            variant="outlined"
                            onChange={event => setName(event.target.value)}/>
                        {!_.isNil(organizationNameValidationMessage) && (
                            <FormHelperText error={true}>{organizationNameValidationMessage}</FormHelperText>)}
                    </FormControl>
                </Stack>
                <Stack spacing={1}>
                    <Typography variant="h4">
                        {localization.fields.roleName.title()}
                    </Typography>
                    <FormControl
                        fullWidth={true}
                        variant="standard">
                        <TextField
                            disabled={executing}
                            value={roleName}
                            variant="outlined"
                            onChange={event => setRoleName(event.target.value)}/>
                        {!_.isNil(roleNameValidationMessage) && (
                            <FormHelperText error={true}>{roleNameValidationMessage}</FormHelperText>)}
                    </FormControl>
                </Stack>
                <Stack spacing={1}>
                    <Stack
                        direction="row"
                        justifyItems="center"
                        spacing={1}>
                        <Typography variant="h4">
                            {localization.fields.trailName.title()}
                        </Typography>
                        <Message
                            level="info"
                            title={localization.fields.trailName.info()}
                            variant="minimal"/>
                    </Stack>
                    <FormControl
                        fullWidth={true}
                        variant="standard">
                        <TextField
                            disabled={executing}
                            value={trailNamePattern}
                            variant="outlined"
                            onChange={event => setTrailNamePattern(event.target.value)}/>
                    </FormControl>
                </Stack>
                <Stack spacing={1}>
                    <Typography variant="h4">
                        {localization.fields.regionSystemNames.title()}
                    </Typography>
                    <Typography>
                        {localization.fields.regionSystemNames.subtitle()}
                    </Typography>
                    <AwsRegionSystemNamesSelector
                        disabled={executing}
                        label={localization.fields.regionSystemNames.title()}
                        regionSystemNames={partitionRegionSystemNames}
                        selectedRegionSystemNames={
                            _.isNil(regionSystemNames)
                                ? partitionRegionSystemNames
                                : regionSystemNames}
                        onSelectedRegionSystemNamesChanged={
                            regionSystemNames =>
                                setRegionSystemNames(
                                    regionSystemNames.length === partitionRegionSystemNames.length
                                        ? undefined
                                        : regionSystemNames)}/>
                    {!_.isNil(regionSystemNamesValidationMessage) && (
                        <FormHelperText error={true}>{regionSystemNamesValidationMessage}</FormHelperText>)}
                </Stack>
                <Stack spacing={1}>
                    <Typography variant="h4">
                        {localization.fields.settings.title()}
                    </Typography>
                    {!_.isNil(addOrEditContext.organizationConfiguration) &&
                        <CheckboxField
                            checked={enabled}
                            onChange={() => setEnabled(!enabled)}>
                            <Stack
                                direction="row"
                                justifyItems="center"
                                spacing={1}>
                                <Typography>
                                    {localization.fields.settings.enabled.title()}
                                </Typography>
                                <Message
                                    level="info"
                                    title={localization.fields.settings.enabled.info()}
                                    variant="minimal"/>
                            </Stack>
                        </CheckboxField>}
                    <FolderEnabled
                        folderEnabled={folderEnabled}
                        info={localization.fields.settings.folderEnabled.info()}
                        setFolderEnabled={setFolderEnabled}
                        title={localization.fields.settings.folderEnabled.title()}/>
                </Stack>
            </Stack>
            {_.isNil(addOrEditContext.organizationConfiguration) &&
                !_.isEmpty(organizationExistingTenantIds) &&
                <Message
                    level="infoWarning"
                    title={
                        localization.organizationExistingTenants({
                            organizationExistingTenants:
                                <InlineTenants
                                    sx={{ color: "unset" }}
                                    tenantIds={organizationExistingTenantIds}
                                    tenantNameTranslatorOptions={{ includeRawId: true }}
                                    tenantVariant="iconText"
                                    variant="itemCountAndType"/>
                        })}/>}
        </Stack>);
}