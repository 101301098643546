import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, tenantModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsResourceRiskContext } from "../contexts";

export function useAwsIamRootUserMfaDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsIamRootUserMfaDisabledRisk;
    const rootUserModel = entityModelStore.useGet(risk.entityId) as Contract.AwsIamRootUserModel;
    const rootUser = rootUserModel.entity as Contract.AwsIamRootUser;
    const tenantConfiguration = tenantModelStore.useGet(riskModel.tenantId).configuration as Contract.AwsTenantConfiguration;

    const getAwsResourceRiskContext = useGetAwsResourceRiskContext();
    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsIamRootUserMfaDisabledRiskDefinition",
            () => ({
                description: "{{rootUser}} multi-factor authentication is not enabled",
                sections: {
                    context: {
                        passwordUsageTimeExists: "The root user password was last used on {{passwordUsageTime | TimeFormatter.shortDate}}",
                        passwordUsageTimeNotExists: "The root user has never been used, either via a password or access keys"
                    },
                    resolution: {
                        step1: "Click on <Root_Account_Name> at the top right and choose **My Security Credentials** from the drop-down list",
                        step2: "Choose **Multi-factor authentication**",
                        step3: "Click **Activate MFA**",
                        step4: "Complete the **Manage MFA Device** wizard"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            rootUser:
                <Entity
                    entityIdOrModel={rootUserModel}
                    variant="text"/>
        }),
        () =>
            [
                consoleSignInStepTranslator(
                    Contract.AwsConsoleView.Iam,
                    AwsConsoleUrlBuilder.getIamUrl(tenantConfiguration.partitionType)),
                localization.sections.resolution.step1(),
                localization.sections.resolution.step2(),
                localization.sections.resolution.step3(),
                localization.sections.resolution.step4()
            ],
        riskModel,
        () => {
            const resourceRiskContext = getAwsResourceRiskContext(rootUserModel);
            return [
                resourceRiskContext.generalInformation,
                new RiskDefinitionContextItem(
                    _.isNil(rootUser.passwordUsageTime)
                        ? localization.sections.context.passwordUsageTimeNotExists()
                        : localization.sections.context.passwordUsageTimeExists({ passwordUsageTime: rootUser.passwordUsageTime })),
                resourceRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}