﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function SingaporeIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_60203_5262)">
                <rect
                    fill="white"
                    height="15.9638"
                    rx="2"
                    width="24"
                    y="4"/>
                <path
                    d="M0 5.99829C0 4.89372 0.895431 3.99829 2 3.99829H22C23.1046 3.99829 24 4.89372 24 5.99829V17.9621C24 19.0667 23.1046 19.9621 22 19.9621H2C0.89543 19.9621 0 19.0667 0 17.9621V5.99829Z"
                    fill="white"/>
                <path
                    clipRule="evenodd"
                    d="M22 4.99829H2C1.44771 4.99829 1 5.44601 1 5.99829V17.9621C1 18.5144 1.44772 18.9621 2 18.9621H22C22.5523 18.9621 23 18.5144 23 17.9621V5.99829C23 5.44601 22.5523 4.99829 22 4.99829ZM2 3.99829C0.895431 3.99829 0 4.89372 0 5.99829V17.9621C0 19.0667 0.89543 19.9621 2 19.9621H22C23.1046 19.9621 24 19.0667 24 17.9621V5.99829C24 4.89372 23.1046 3.99829 22 3.99829H2Z"
                    fill="#BAC5D3"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M0 3.99829H24V11.9802H0V3.99829Z"
                    fill="#DF0000"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M4.6208 5.4795C4.05898 5.60024 3.55581 5.91059 3.19576 6.35845C2.83571 6.8063 2.64071 7.3644 2.64349 7.93904C2.64628 8.51367 2.84668 9.06985 3.21104 9.5142C3.57541 9.95855 4.08157 10.264 4.64452 10.3793C4.32202 10.4726 3.98427 10.5014 3.65064 10.4639C3.31701 10.4264 2.99406 10.3234 2.70032 10.1608C2.40658 9.99822 2.14782 9.77926 1.93889 9.51646C1.72996 9.25366 1.57496 8.9522 1.48279 8.62937C1.29972 7.98044 1.38103 7.28543 1.70895 6.69628C2.03687 6.10713 2.58472 5.67179 3.23272 5.48543C3.70727 5.349 4.14624 5.34603 4.62376 5.4795H4.6208Z"
                    fill="white"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M4.23513 7.54966L4.38047 7.99456L3.99489 7.72169L3.61524 8.00049L3.75465 7.54966L3.375 7.27382L3.84659 7.26789L3.99489 6.823L4.14319 7.26789H4.61181L4.23513 7.54966ZM4.75418 9.09197L4.90248 9.53983L4.5169 9.26696L4.13429 9.5428L4.27666 9.09494L3.89701 8.81614L4.3686 8.81317L4.51394 8.36531L4.66224 8.8102H5.13679L4.75418 9.09197ZM6.48928 9.08011L6.63461 9.53094L6.24904 9.2551L5.86939 9.53094L6.00879 9.08307L5.62915 8.80724L6.10074 8.80131L6.24904 8.35641L6.39734 8.80131H6.86596L6.48928 9.08011ZM7.00536 7.54966L7.1507 7.99752L6.76512 7.72465L6.38547 8.00049L6.52784 7.55263L6.14523 7.27382L6.61978 7.27086L6.76215 6.823L6.91045 7.26789H7.38501L7.00536 7.54966ZM5.63211 6.53233L5.78041 6.98316L5.39483 6.70732L5.01222 6.98612L5.15459 6.5353L4.77494 6.25649L5.24357 6.25353L5.39187 5.80566L5.54017 6.25056H6.01472L5.63211 6.53233Z"
                    fill="white"
                    fillRule="evenodd"/>
            </g>
            <defs>
                <clipPath id="clip0_60203_5262">
                    <rect
                        fill="white"
                        height="15.9638"
                        rx="2"
                        width="24"
                        y="4"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}