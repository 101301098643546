import { useLocalization } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import _ from "lodash";
import React from "react";
import { ProfileTopbarInfoItem, ServiceNowIcon, useServiceNowIncidentTranslator, useTheme } from "../../../../../../../../../../common";
import { ServiceNowIncident } from "../../../../../../../../../../common/controllers/types.generated";

type ProfileTopbarServiceNowInfoItemProps = {
    incidents: ServiceNowIncident[];
};

export function ProfileTopbarServiceNowInfoItem({ incidents }: ProfileTopbarServiceNowInfoItemProps) {
    const serviceNowIncidentTranslator = useServiceNowIncidentTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.profile.hooks.useProfileDefinition.hooks.profileTopbarServiceNowInfoItem",
            () => ({
                incidents: [
                    "1 ServiceNow Incident",
                    "{{count | NumberFormatter.humanize}} ServiceNow Incidents"
                ]
            }));

    const theme = useTheme();
    return (
        <ProfileTopbarInfoItem
            icon={<ServiceNowIcon/>}
            text={localization.incidents(_.size(incidents))}
            titleOrGetTitle={
                async () => (
                    <Stack
                        spacing={1}
                        sx={{
                            maxHeight: theme.spacing(60),
                            overflow: "auto",
                            padding: theme.spacing(1)
                        }}>
                        {_.map(
                            incidents,
                            incident =>
                                <Box key={incident.id}>
                                    {serviceNowIncidentTranslator(incident, "link")}
                                </Box>)}
                    </Stack>)}/>);
}