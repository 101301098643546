import { useLocalization } from "@infrastructure";
import React, { useMemo } from "react";
import { Contract, entityModelStore, InlineWorkloadAnalysisPackages, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetWorkloadResourceOperatingSystemUnpatchedRiskContext } from "./useGetWorkloadResourceOperatingSystemUnpatchedRiskContext";

export function useGetVirtualMachineOperatingSystemUnpatchedRiskContext() {
    return useMemo(
        () => useVirtualMachineOperatingSystemUnpatchedRiskContext,
        []);
}

function useVirtualMachineOperatingSystemUnpatchedRiskContext(risk: Contract.VirtualMachineOperatingSystemUnpatchedRisk) {
    const entityModel = entityModelStore.useGet(risk.entityId);

    const resourceOperatingSystemUnpatchedRiskContext = useGetWorkloadResourceOperatingSystemUnpatchedRiskContext()(risk);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.workloadAnalysis.hooks.contexts.useGetVirtualMachineOperatingSystemUnpatchedRiskContext",
            () => ({
                unpatchedPackages: "The {{translatedEntityTypeName}} contains {{packages}} that have critical or high vulnerabilities with known fixes"
            }));

    return {
        ...resourceOperatingSystemUnpatchedRiskContext,
        unpatchedPackages:
            new RiskDefinitionContextItem(
                localization.unpatchedPackages({
                    packages:
                        <InlineWorkloadAnalysisPackages
                            packageNames={risk.vulnerabilityPackageNames}
                            packageScope={risk.packageScope}/>,
                    translatedEntityTypeName:
                        entityTypeNameTranslator(
                            entityModel.entity.typeName,
                            { includeServiceName: false })
                }))
    };
}