import { DeleteIcon, useChangeEffect, useLocalization, useRemoveQueryParameters } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, ObjectTypeMetadataModelHelper, useTheme } from "../../../..";
import { UdmQueryHelper } from "../../utilities";
import { Badge, RuleGroup, UdmObjectFilterSelector, UdmObjectJoinSelector, UdmObjectTypeNameSelector } from "./components";

type QueryBuilderProps = {
    onQueryChanged: (query: Contract.UdmQueryBase) => void;
    onQueryJoinRemove?: () => void;
    parentObjectTypeName?: string;
    query: Contract.UdmQueryBase;
    queryLevel: number;
};

export function UdmQueryBuilder({ onQueryChanged, onQueryJoinRemove, parentObjectTypeName, query: initialQuery, queryLevel }: QueryBuilderProps) {
    const [query, setQuery] = useState(initialQuery);
    useChangeEffect(
        () => {
            onQueryChanged(query);
        },
        [query]);

    const [selectedObjectTypeName, setSelectedObjectTypeName] =
        useState(
            () =>
                UdmQueryHelper.isQueryJoin(query)
                    ? ObjectTypeMetadataModelHelper.getProperty((query as Contract.UdmQueryJoin).propertyId)?.relation?.objectTypeName
                    : (query as Contract.UdmQuery).objectTypeName);

    const removeQueryParameters = useRemoveQueryParameters();
    useChangeEffect(
        () => {
            if (_.isEmpty(selectedObjectTypeName)) {
                if (queryLevel === 0) {
                    setQuery(UdmQueryHelper.createQuery());
                    removeQueryParameters("query");
                } else {
                    onQueryJoinRemove?.();
                }
            } else if (_.isNil(parentObjectTypeName)) {
                setQuery({
                    ...UdmQueryHelper.createQuery(),
                    objectTypeName: selectedObjectTypeName,
                    options: {
                        propertyIds:
                            _(ObjectTypeMetadataModelHelper.get(selectedObjectTypeName!).udmProperties!).
                                filter(property => !_.isNil(property?.options.priorityIndex)).
                                map(property => property.id).
                                value()
                    }
                });
            } else {
                setQuery(
                    UdmQueryHelper.createQueryJoin(
                        _.find(
                            ObjectTypeMetadataModelHelper.get(parentObjectTypeName).udmProperties,
                            udmProperty => udmProperty.relation?.objectTypeName === selectedObjectTypeName)!));
            }
        },
        [selectedObjectTypeName]);

    function addRule(rule: Contract.UdmQueryRuleBase) {
        setQuery(
            query => ({
                ...query,
                ruleGroup: UdmQueryHelper.addRuleToRuleGroup(query.ruleGroup, rule)
            }));
    }

    function addJoinRule(property?: Contract.UdmObjectProperty) {
        setQuery(
            query => ({
                ...query,
                join: UdmQueryHelper.createQueryJoin(property)
            }));
    }

    const variant =
        useMemo(
            () => UdmQueryHelper.getRuleGroupVariant(query.ruleGroup),
            [query]);

    const localization =
        useLocalization(
            "common.udmObjectTable.udmQueryBuilder",
            () => ({
                find: "Find",
                join: "Join"
            }));

    const theme = useTheme();
    return (
        <Stack spacing={1}>
            <Stack
                direction={
                    variant === "multiline"
                        ? "column"
                        : "row"}
                spacing={1}
                sx={{ transition: "all 0.3s ease-in-out" }}>
                <Stack
                    direction="row"
                    spacing={0.5}>
                    <Box>
                        <Badge
                            sx={{ color: theme.palette.text.primary }}
                            text={
                                queryLevel === 0
                                    ? localization.find()
                                    : localization.join()}/>
                    </Box>
                    <UdmObjectTypeNameSelector
                        level={queryLevel}
                        parentObjectTypeName={parentObjectTypeName}
                        selectedObjectTypeName={selectedObjectTypeName}
                        onSelectedObjectTypeNameChanged={selectedObjectTypeName => setSelectedObjectTypeName(selectedObjectTypeName)}/>
                    {_.isEmpty(selectedObjectTypeName) && !_.isEmpty(parentObjectTypeName) &&
                        <Stack justifyContent="center">
                            <Box
                                sx={{
                                    "&:hover": {
                                        background: theme.palette.action.hover
                                    },
                                    borderRadius: theme.spacing(0.75),
                                    cursor: "pointer",
                                    fontSize: "14px",
                                    padding: theme.spacing(0.5)
                                }}
                                onClick={onQueryJoinRemove}>
                                <DeleteIcon sx={{ color: theme.palette.text.primary }}/>
                            </Box>
                        </Stack>}
                    {!_.isEmpty(selectedObjectTypeName) && (
                        _.isEmpty(query.ruleGroup.rules) ||
                            _.size(query.ruleGroup.rules) === 1 && query.ruleGroup.rules[0].typeName === Contract.TypeNames.UdmQueryRelationRuleGroup) &&
                            <Stack justifyContent="center">
                                <UdmObjectFilterSelector
                                    enableJoinOperator={_.isNil(query.join)}
                                    objectTypeName={selectedObjectTypeName!}
                                    parentObjectTypeName={parentObjectTypeName ?? selectedObjectTypeName}
                                    onJoinAdded={addJoinRule}
                                    onOperatorAdded={
                                        operator =>
                                            setQuery(
                                                query => ({
                                                    ...query,
                                                    ruleGroup: {
                                                        ...UdmQueryHelper.createEmptyRuleGroup(Contract.TypeNames.UdmQueryRuleGroup),
                                                        operator,
                                                        rules: [
                                                            query.ruleGroup,
                                                            UdmQueryHelper.createEmptyRuleGroup(Contract.TypeNames.UdmQueryRuleGroup)
                                                        ]
                                                    }
                                                }))}
                                    onRuleAdd={addRule}/>
                            </Stack>}
                    {!_.isEmpty(selectedObjectTypeName) &&
                        _.size(query.ruleGroup.rules) > 1 &&
                        <Stack justifyContent="center">
                            <UdmObjectJoinSelector
                                disabled={!_.isNil(query.join)}
                                objectTypeName={selectedObjectTypeName!}
                                onRelationRuleAdd={addJoinRule}/>
                        </Stack>}
                </Stack>
                {_.size(query.ruleGroup.rules) > 0 &&
                    <RuleGroup
                        enableRelationAdd={variant === "line"}
                        objectTypeName={selectedObjectTypeName}
                        parentObjectTypeName={parentObjectTypeName ?? selectedObjectTypeName}
                        root={true}
                        ruleGroup={query.ruleGroup as Contract.UdmQueryRuleGroup}
                        onJoinOperatorSelected={addJoinRule}
                        onRelationRuleAdd={addJoinRule}
                        onRuleGroupChanged={
                            ruleGroup => {
                                setQuery(
                                    query => ({
                                        ...query,
                                        ruleGroup
                                    }));
                            }}
                        onRuleGroupCleared={
                            () =>
                                setQuery(
                                    query => ({
                                        ...query,
                                        ruleGroup: UdmQueryHelper.createEmptyRuleGroup(Contract.TypeNames.UdmQueryRuleGroup)
                                    }))}/>}
            </Stack>
            {!_.isNil(query.join) &&
                <UdmQueryBuilder
                    parentObjectTypeName={selectedObjectTypeName}
                    query={query.join}
                    queryLevel={queryLevel + 1}
                    onQueryChanged={
                        (joinQuery: Contract.UdmQueryBase) => {
                            setQuery(
                                query => ({
                                    ...query,
                                    join: joinQuery as Contract.UdmQueryJoin
                                }));
                        }}
                    onQueryJoinRemove={
                        () => {
                            setQuery(
                                query => ({
                                    ...query,
                                    join: undefined
                                }));
                        }}/>}
        </Stack>);
}