import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function GroupIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M8.61553 5C6.8312 5 5.38472 6.44648 5.38472 8.23081C5.38472 10.0151 6.8312 11.4616 8.61553 11.4616C10.3999 11.4616 11.8463 10.0151 11.8463 8.23081C11.8463 6.44648 10.3999 5 8.61553 5ZM3.38472 8.23081C3.38472 5.34192 5.72663 3 8.61553 3C11.5044 3 13.8463 5.34192 13.8463 8.23081C13.8463 11.1197 11.5044 13.4616 8.61553 13.4616C5.72663 13.4616 3.38472 11.1197 3.38472 8.23081Z"/>
            <path d="M6.07697 16.847C3.82532 16.847 2 18.6723 2 20.9239C2 21.4762 1.55228 21.9239 1 21.9239C0.447715 21.9239 0 21.4762 0 20.9239C0 17.5677 2.72075 14.847 6.07697 14.847H11.154C14.5102 14.847 17.2309 17.5677 17.2309 20.9239C17.2309 21.4762 16.7832 21.9239 16.2309 21.9239C15.6786 21.9239 15.2309 21.4762 15.2309 20.9239C15.2309 18.6723 13.4056 16.847 11.154 16.847H6.07697Z"/>
            <path d="M15.2508 3.88505C15.3608 3.34382 15.8887 2.99421 16.4299 3.10417C18.8175 3.58925 20.6147 5.69871 20.6147 8.23019C20.6147 10.7591 18.8211 12.8669 16.4371 13.3547C15.896 13.4655 15.3676 13.1166 15.2569 12.5755C15.1462 12.0344 15.495 11.5061 16.0361 11.3953C17.5079 11.0942 18.6147 9.7903 18.6147 8.23019C18.6147 6.6685 17.5057 5.3636 16.0317 5.06413C15.4905 4.95417 15.1409 4.42628 15.2508 3.88505Z"/>
            <path d="M16.9227 15.847C16.9227 15.2947 17.3704 14.847 17.9227 14.847C21.2789 14.847 23.9997 17.5677 23.9997 20.9239C23.9997 21.4762 23.552 21.9239 22.9997 21.9239C22.4474 21.9239 21.9997 21.4762 21.9997 20.9239C21.9997 18.6723 20.1744 16.847 17.9227 16.847C17.3704 16.847 16.9227 16.3993 16.9227 15.847Z"/>
        </SvgIcon>);
}