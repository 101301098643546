import { DataTableColumn, DataTableSortType, EmptyMessageText, EnumValuesFilter, TextValuesFilter, TimeFormatter, TimeRangeFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, EntityFilter, entityModelStore, InfoCard, ItemTable, TimeRangeHelper } from "../../../../../../../../../../../../common";
import { useAwsEc2VpcBlockPublicAccessPolicyNetworkDirectionTranslator } from "../../../../../../../../hooks";

type ExclusionsInfoCardProps = {
    resourceIdToExclusionMap: Dictionary<Contract.AwsEc2VpcBlockPublicAccessPolicyExclusion>;
};

export function ExclusionsInfoCard({ resourceIdToExclusionMap }: ExclusionsInfoCardProps) {
    const resourceModels = entityModelStore.useGet(_.keys(resourceIdToExclusionMap)) as Contract.AwsEc2ResourceModel[];
    const resourceIdToResourceModelMap =
        useMemo(
            () =>
                _.keyBy(
                    resourceModels,
                    resourceModel => resourceModel.id),
            []);

    const items =
        useMemo(
            () =>
                _.keys(resourceIdToResourceModelMap).
                    map(
                        resourceId =>
                            new ExclusionsInfoCardItem(
                                resourceIdToExclusionMap[resourceId],
                                resourceIdToResourceModelMap[resourceId])),
            [resourceIdToResourceModelMap]);

    const vpcBlockPublicAccessPolicyNetworkDirectionTranslator = useAwsEc2VpcBlockPublicAccessPolicyNetworkDirectionTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsEc2VpcBlockPublicAccessPolicyDefinition.exclusionsInfoCard",
            () => ({
                columns: {
                    allowNetworkDirection: "Internet Gateway Allow Direction",
                    creationTime: "Creation Time",
                    name: "Name",
                    resource: "Associated Resource"
                },
                empty: "No exclusions",
                title: "Exclusions"
            }));
    return (
        <InfoCard title={localization.title()}>
            <ItemTable
                columnIdToGetItemValueMap={{
                    [ExclusionsInfoCardTableColumnId.AllowNetworkDirection]: item => item.exclusion.allowNetworkDirection,
                    [ExclusionsInfoCardTableColumnId.CreationTime]: item => TimeFormatter.monthDayAndYear(item.exclusion.creationTime),
                    [ExclusionsInfoCardTableColumnId.Name]: item => item.exclusion.name,
                    [ExclusionsInfoCardTableColumnId.Resource]: {
                        getFilterValue: item => item.resourceModel.id,
                        getSortValue: item => resourceIdToResourceModelMap[item.resourceModel.id].entity.displayName
                    }
                }}
                defaultSortColumnIdOrIds={ExclusionsInfoCardTableColumnId.Resource}
                emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                getCsvItem={
                    item => ({
                        /* eslint-disable sort-keys-fix/sort-keys-fix */
                        "Name": item.exclusion.name,
                        "Internet Gateway Allow Direction": item.exclusion.allowNetworkDirection,
                        "Associated Resource": item.resourceModel,
                        "Creation Time": TimeFormatter.iso8601String(item.exclusion.creationTime)
                        /* eslint-enable sort-keys-fix/sort-keys-fix */
                    })}
                getItemId={item => item.id}
                items={items}>
                {columnIdToItemValuesMap =>
                    [
                        <DataTableColumn
                            cellMaxWidth="medium"
                            filterOptions={{
                                itemOrItems: {
                                    element:
                                        <TextValuesFilter
                                            emptyValue={true}
                                            placeholder={localization.columns.name()}
                                            values={columnIdToItemValuesMap[ExclusionsInfoCardTableColumnId.Name]}/>
                                }
                            }}
                            id={ExclusionsInfoCardTableColumnId.Name}
                            itemProperty={(record: ExclusionsInfoCardItem) => record.exclusion.name}
                            key={ExclusionsInfoCardTableColumnId.Name}
                            title={localization.columns.name()}/>,
                        <DataTableColumn
                            filterOptions={{
                                itemOrItems: {
                                    element:
                                        <EnumValuesFilter
                                            enumType={Contract.AwsEc2VpcBlockPublicAccessPolicyNetworkDirection}
                                            enumTypeTranslator={vpcBlockPublicAccessPolicyNetworkDirectionTranslator}
                                            exclude={[
                                                Contract.AwsEc2VpcBlockPublicAccessPolicyNetworkDirection.Inbound,
                                                Contract.AwsEc2VpcBlockPublicAccessPolicyNetworkDirection.None]}
                                            placeholder={localization.columns.allowNetworkDirection()}/>
                                }
                            }}
                            id={ExclusionsInfoCardTableColumnId.AllowNetworkDirection}
                            itemProperty={(item: ExclusionsInfoCardItem) => vpcBlockPublicAccessPolicyNetworkDirectionTranslator(item.exclusion.allowNetworkDirection)}
                            key={ExclusionsInfoCardTableColumnId.AllowNetworkDirection}
                            title={localization.columns.allowNetworkDirection()}/>,
                        <DataTableColumn
                            cellMaxWidth="medium"
                            filterOptions={{
                                itemOrItems: {
                                    element:
                                        <EntityFilter
                                            entityIdsOrSearchableReferences={columnIdToItemValuesMap[ExclusionsInfoCardTableColumnId.Resource]}
                                            placeholder={localization.columns.resource()}/>
                                }
                            }}
                            id={ExclusionsInfoCardTableColumnId.Resource}
                            itemProperty=
                                {(item: ExclusionsInfoCardItem) =>
                                    <Entity
                                        entityIdOrModel={item.resourceModel}
                                        variant="iconTextTenant"/>}
                            key={ExclusionsInfoCardTableColumnId.Resource}
                            title={localization.columns.resource()}/>,
                        <DataTableColumn
                            cellMaxWidth="medium"
                            filterOptions={{
                                itemOrItems: {
                                    element:
                                        <TimeRangeFilter
                                            placeholder={localization.columns.creationTime()}
                                            timeRange={TimeRangeHelper.getTimesFilterRange(columnIdToItemValuesMap[ExclusionsInfoCardTableColumnId.CreationTime])}/>
                                }
                            }}
                            id={ExclusionsInfoCardTableColumnId.CreationTime}
                            itemProperty={(record: ExclusionsInfoCardItem) => TimeFormatter.monthDayAndYear(record.exclusion.creationTime)}
                            key={ExclusionsInfoCardTableColumnId.CreationTime}
                            sortOptions={{ type: DataTableSortType.Date }}
                            title={localization.columns.creationTime()}/>
                    ]}
            </ItemTable>
        </InfoCard>);
}

class ExclusionsInfoCardItem {
    constructor(
        public exclusion: Contract.AwsEc2VpcBlockPublicAccessPolicyExclusion,
        public resourceModel: Contract.AwsEc2ResourceModel) {
    }
}

enum ExclusionsInfoCardTableColumnId {
    AllowNetworkDirection = "allowNetworkDirection",
    CreationTime = "creationTime",
    Name = "name",
    Resource = "resource"
}