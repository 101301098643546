import { NoneIcon, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { UdmObjectPropertyItemProps } from "../..";

export function InfraBoolean({ item }: UdmObjectPropertyItemProps) {
    const localization =
        useLocalization(
            "common.udmObjectTable.udmObjectPropertyItem.infraBoolean",
            () => ({
                false: "False",
                true: "True"
            }));
    return _.isNil(item)
        ? <NoneIcon sx={{ fontSize: "18px" }}/>
        : <Typography
            noWrap={true}
            sx={{ width: "max-content" }}>
            {item
                ? localization.true()
                : localization.false()}
        </Typography>;
}