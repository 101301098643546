import { Action0, CheckboxField, useLocalization } from "@infrastructure";
import { Stack, Typography, useTheme } from "@mui/material";
import React, { ReactNode, useState } from "react";

type ValuesFilterListItemProps = {
    checked: boolean;
    children: ReactNode;
    onCheckboxClick: Action0;
    onOnlyClick: Action0;
};

export function ValuesFilterListItem({ checked, children, onCheckboxClick, onOnlyClick }: ValuesFilterListItemProps) {
    const [hover, setHover] = useState(false);
    const localization =
        useLocalization(
            "infrastructure.filters.valuesFilterListItem",
            () => ({
                only: "Only"
            }));

    const theme = useTheme();
    return (
        <Stack
            direction="row"
            spacing={2}
            sx={{
                "&:hover": {
                    background: theme.palette.action.hover
                },
                alignItems: "center",
                cursor: "pointer",
                justifyContent: "space-between",
                padding: theme.spacing(1, 3, 1, 1),
                width: "100%"
            }}
            onClick={
                event => {
                    onCheckboxClick();
                    event.preventDefault();
                    event.stopPropagation();
                }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
            <CheckboxField
                checked={checked}
                sx={{
                    maxWidth: theme.spacing(120),
                    minWidth: theme.spacing(25)
                }}
                onChange={
                    event => {
                        onCheckboxClick();
                        event.preventDefault();
                        event.stopPropagation();
                    }}>
                {children}
            </CheckboxField>
            <Typography
                sx={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    visibility:
                        hover
                            ? "visible"
                            : "hidden"
                }}
                onClick={
                    event => {
                        event.preventDefault();
                        event.stopPropagation();
                        onOnlyClick();
                    }}>
                {localization.only()}
            </Typography>
        </Stack>);
}