import { IconText, Link, useLocalization } from "@infrastructure";
import { Box, Divider, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { CodeTenantLink, Contract, tenantModelStore, useTheme } from "../..";
import { CodeBranchIcon, CodeConsoleUrlHelper } from "../../../tenants";
import { Item } from "./components";

type CodeResourcesProp = {
    resourceData: Contract.CodeResourceData;
};

export function CodeResource({ resourceData }: CodeResourcesProp) {
    const localization =
        useLocalization(
            "common.codeResource",
            () => ({
                branch: "Branch",
                filePath: "File Path",
                name: "Name",
                systemCreationTime: "Triggered on {{systemCreationTime | TimeFormatter.mediumDateShortTime}}"
            }));
    const tenantModel = tenantModelStore.useGet(resourceData.tenantId) as Contract.CodeTenantModel;

    const theme = useTheme();
    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                flex: 1,
                height: "100%",
                overflowY: "auto",
                padding: theme.spacing(2)
            }}>
            <Stack
                alignItems="center"
                direction="row"
                spacing={2}
                sx={{
                    border: theme.border.primary,
                    borderRadius: theme.spacing(0.75),
                    padding: theme.spacing(2, 0)
                }}>
                <Stack
                    alignItems="center"
                    direction="row"
                    sx={{
                        flex: 1,
                        overflow: "hidden",
                        paddingLeft: theme.spacing(1.5)
                    }}>
                    <CodeTenantLink tenantConfiguration={tenantModel.configuration}/>
                    <Divider
                        flexItem={true}
                        orientation="vertical"
                        sx={{ margin: theme.spacing(0, 1) }}/>
                    <IconText
                        icon={<CodeBranchIcon/>}
                        text={
                            <Link
                                noWrap={true}
                                sx={{ maxWidth: "inherit" }}
                                urlOrGetUrl={
                                    CodeConsoleUrlHelper.getCodeBranchUrl(
                                        resourceData.commit.branchName!,
                                        tenantModel.configuration.codeTenantType,
                                        tenantModel.configuration.url)}
                                variant="external">
                                {resourceData.commit.branchName}
                            </Link>}
                        textSx={{ maxWidth: "100%" }}
                        titleOrGetTitle={localization.branch()}/>
                </Stack>
                <Typography sx={{ paddingRight: theme.spacing(2.5) }}>
                    {localization.systemCreationTime({ systemCreationTime: resourceData.systemCreationTime })}
                </Typography>
            </Stack>
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                sx={{
                    padding: theme.spacing(0, 2),
                    paddingLeft: theme.spacing(5.25)
                }}>
                <Typography
                    sx={{
                        flex: 1,
                        textAlign: "start"
                    }}
                    variant="h5">
                    {localization.name()}
                </Typography>
                <Typography
                    sx={{
                        flex: 1,
                        textAlign: "start"
                    }}
                    variant="h5">
                    {localization.filePath()}
                </Typography>
            </Stack>
            <Box>
                {_.map(
                    resourceData.blockDatas,
                    blockData =>
                        <Item
                            blockData={blockData}
                            commitHash={resourceData.commit.hash}
                            key={blockData.block.id}
                            tenantId={resourceData.tenantId}/>)}
            </Box>
        </Stack>);
}