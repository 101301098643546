import { useOciCommonResourceInfoItemElements } from ".";
import { Contract } from "../../../../../../../../../../common";

export function useOciDefaultResourceInfoItemElements(resourceModel: Contract.OciResourceModel) {
    const commonResourceInfoItemElements = useOciCommonResourceInfoItemElements(resourceModel);
    return [
        commonResourceInfoItemElements.typeNameInfoElement,
        commonResourceInfoItemElements.organizationInfoItemElement,
        ...(resourceModel.typeName === Contract.TypeNames.OciTenantEntityModel
            ? [commonResourceInfoItemElements.tenantIdInfoItemElement]
            : [
                commonResourceInfoItemElements.tenantInfoItemElement,
                commonResourceInfoItemElements.ocidInfoItemElement
            ]),
        commonResourceInfoItemElements.regionInfoItemElement,
        commonResourceInfoItemElements.regionLocationInfoItemElement,
        commonResourceInfoItemElements.tagsInfoItemElement,
        commonResourceInfoItemElements.creationTimeInfoItemElement,
        commonResourceInfoItemElements.createdByInfoItemElement,
        commonResourceInfoItemElements.syncTimeInfoItemElement
    ];
}