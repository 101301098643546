﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../../../../../../../../../../../common";

export function useAzureDevOpsOrganizationManagerErrorTranslator() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeOrganizationItems.azureDevOps.addOrEditAzureDevOps.hooks.useAzureDevOpsOrganizationManagerErrorTranslator",
            () => ({
                [Contract.TypeNames.AzureDevOpsOrganizationManagerError]: {
                    [Contract.AzureDevOpsOrganizationManagerError.CommonErrorAadTenantNotFound]: "Microsoft Entra ID tenant was not found",
                    [Contract.AzureDevOpsOrganizationManagerError.CommonErrorAccessTokenInvalid]: "Your access to the Entra ID tenant is not authorized. Reauthenticate and try again",
                    [Contract.AzureDevOpsOrganizationManagerError.CommonErrorApplicationUnauthorized]: "Tenable's Azure DevOps Application is not authorized",
                    [Contract.AzureDevOpsOrganizationManagerError.CommonErrorOrganizationNotFound]: "Azure DevOps organization was not found by Tenable's Application",
                    [Contract.AzureDevOpsOrganizationManagerError.InsertOrganizationErrorOrganizationExists]: "Azure DevOps organization already exists"
                }
            }));
    return (error: Contract.AzureDevOpsOrganizationManagerError) =>
        localization[Contract.TypeNames.AzureDevOpsOrganizationManagerError][error]();
}