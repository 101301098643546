﻿import { ValuesFilterSelection } from "@infrastructure";
import { Dictionary } from "lodash";
import { Contract, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../common";
import { RisksItemsFilterId } from "../Filters";


export class FiltersHelper {
    public static toRequestCloudRiskFiltersFromFilterMap(filterMap: Dictionary<any>, status: Contract.RiskStatus, timeReference?: string, relatedEntityId?: string): Contract.RiskControllerCloudRiskModelFilters {
        return new Contract.RiskControllerCloudRiskModelFilters(
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[RisksItemsFilterId[Contract.RiskFilterId.RiskPolicyCategory]]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[RisksItemsFilterId[Contract.RiskFilterId.ComplianceIdOrSectionType]]),
            TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(timeReference, filterMap[RisksItemsFilterId[Contract.RiskFilterId.RiskIgnoreExpirationDate]]),
            TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(timeReference, filterMap[RisksItemsFilterId[Contract.RiskFilterId.OpenStatusUpdateTime]]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[RisksItemsFilterId[Contract.RiskFilterId.RiskPolicyConfigurationTypeNameOrId]]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[RisksItemsFilterId[Contract.RiskFilterId.RiskSeverity]]),
            filterMap[RisksItemsFilterId[Contract.RiskFilterId.RiskStarred]],
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(new ValuesFilterSelection(false, [status])),
            TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(timeReference, filterMap[RisksItemsFilterId[Contract.RiskFilterId.StatusUpdateTime]]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[RisksItemsFilterId[Contract.RiskFilterId.RiskSubStatus]]),
            TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(timeReference, filterMap[RisksItemsFilterId[Contract.RiskFilterId.SystemCreationTime]]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[RisksItemsFilterId[Contract.RiskFilterId.TenantId]]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[RisksItemsFilterId[Contract.RiskFilterId.TicketingServiceType]]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[RisksItemsFilterId[Contract.RiskFilterId.RiskedEntityAttribute]]),
            relatedEntityId,
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[RisksItemsFilterId[Contract.RiskFilterId.RiskedEntityEnvironment]]),
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[RisksItemsFilterId[Contract.RiskFilterId.RiskedEntityId]]),
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[RisksItemsFilterId[Contract.RiskFilterId.RiskedEntityOwner]]));
    }

    public static toRequestCodeRiskFiltersFromFilterMap(filterMap: Dictionary<any>, status: Contract.RiskStatus, timeReference?: string): Contract.RiskControllerCodeRiskModelFilters {
        return new Contract.RiskControllerCodeRiskModelFilters(
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[RisksItemsFilterId[Contract.RiskFilterId.RiskPolicyCategory]]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[RisksItemsFilterId[Contract.RiskFilterId.ComplianceIdOrSectionType]]),
            TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(timeReference, filterMap[RisksItemsFilterId[Contract.RiskFilterId.RiskIgnoreExpirationDate]]),
            TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(timeReference, filterMap[RisksItemsFilterId[Contract.RiskFilterId.OpenStatusUpdateTime]]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[RisksItemsFilterId[Contract.RiskFilterId.RiskPolicyConfigurationTypeNameOrId]]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[RisksItemsFilterId[Contract.RiskFilterId.RiskSeverity]]),
            filterMap[RisksItemsFilterId[Contract.RiskFilterId.RiskStarred]],
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(new ValuesFilterSelection(false, [status])),
            TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(timeReference, filterMap[RisksItemsFilterId[Contract.RiskFilterId.StatusUpdateTime]]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[RisksItemsFilterId[Contract.RiskFilterId.RiskSubStatus]]),
            TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(timeReference, filterMap[RisksItemsFilterId[Contract.RiskFilterId.SystemCreationTime]]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[RisksItemsFilterId[Contract.RiskFilterId.TenantId]]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[RisksItemsFilterId[Contract.RiskFilterId.TicketingServiceType]]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[RisksItemsFilterId[Contract.RiskFilterId.CodeRiskCloudTenantType]]),
            ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[RisksItemsFilterId[Contract.RiskFilterId.CodeRiskCodeType]]),
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[RisksItemsFilterId[Contract.RiskFilterId.CodeRiskFileRelativePath]]),
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[RisksItemsFilterId[Contract.RiskFilterId.CodeRiskResourceName]]),
            ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[RisksItemsFilterId[Contract.RiskFilterId.CodeRiskResourceTypeSystemName]]));
    }

    public static toFilterMapFromRequestCloudFilters(filters: Contract.RiskControllerCloudRiskModelFilters): Dictionary<any> {
        return {
            [RisksItemsFilterId[Contract.RiskFilterId.RiskPolicyCategory]]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.categorySelection),
            [RisksItemsFilterId[Contract.RiskFilterId.OpenStatusUpdateTime]]: TimeRangeHelper.toTimeRangeFilterSelectionFromTimeRangeSelection(filters.openStatusUpdateTimeRangeSelection),
            [RisksItemsFilterId[Contract.RiskFilterId.ComplianceIdOrSectionType]]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.complianceIdentifierSelection),
            [RisksItemsFilterId[Contract.RiskFilterId.RiskedEntityAttribute]]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.entityAttributeValueSelection),
            [RisksItemsFilterId[Contract.RiskFilterId.RiskIgnoreExpirationDate]]: TimeRangeHelper.toTimeRangeFilterSelectionFromTimeRangeSelection(filters.ignoreExpirationTimeRangeSelection),
            [RisksItemsFilterId[Contract.RiskFilterId.RiskedEntityEnvironment]]: ItemSelectionHelper.toPagedValuesFilterSelectionFromItemSelection(filters.riskedEntityEnvironmentValueSelection),
            [RisksItemsFilterId[Contract.RiskFilterId.RiskedEntityId]]: ItemSelectionHelper.toPagedValuesFilterSelectionFromItemSelection(filters.riskedEntityIdSelection),
            [RisksItemsFilterId[Contract.RiskFilterId.RiskedEntityOwner]]: ItemSelectionHelper.toPagedValuesFilterSelectionFromItemSelection(filters.riskedEntityOwnerValueSelection),
            [RisksItemsFilterId[Contract.RiskFilterId.RiskPolicyConfigurationTypeNameOrId]]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.riskPolicyIdentifierSelection),
            [RisksItemsFilterId[Contract.RiskFilterId.RiskSeverity]]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.severitySelection),
            [RisksItemsFilterId[Contract.RiskFilterId.RiskStarred]]: filters.starred,
            [RisksItemsFilterId[Contract.RiskFilterId.StatusUpdateTime]]: TimeRangeHelper.toTimeRangeFilterSelectionFromTimeRangeSelection(filters.statusUpdateTimeRangeSelection),
            [RisksItemsFilterId[Contract.RiskFilterId.RiskSubStatus]]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.subStatusSelection),
            [RisksItemsFilterId[Contract.RiskFilterId.SystemCreationTime]]: TimeRangeHelper.toTimeRangeFilterSelectionFromTimeRangeSelection(filters.systemCreationTimeRangeSelection),
            [RisksItemsFilterId[Contract.RiskFilterId.TenantId]]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.tenantIdSelection),
            [RisksItemsFilterId[Contract.RiskFilterId.TicketingServiceType]]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.ticketingServiceTypeSelection)
        };
    }

    public static toFilterMapFromRequestCodeFilters(filters: Contract.RiskControllerCodeRiskModelFilters): Dictionary<any> {
        return {
            [RisksItemsFilterId[Contract.RiskFilterId.RiskPolicyCategory]]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.categorySelection),
            [RisksItemsFilterId[Contract.RiskFilterId.ComplianceIdOrSectionType]]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.complianceIdentifierSelection),
            [RisksItemsFilterId[Contract.RiskFilterId.RiskIgnoreExpirationDate]]: TimeRangeHelper.toTimeRangeFilterSelectionFromTimeRangeSelection(filters.ignoreExpirationTimeRangeSelection),
            [RisksItemsFilterId[Contract.RiskFilterId.RiskPolicyConfigurationTypeNameOrId]]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.riskPolicyIdentifierSelection),
            [RisksItemsFilterId[Contract.RiskFilterId.RiskSeverity]]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.severitySelection),
            [RisksItemsFilterId[Contract.RiskFilterId.RiskStarred]]: filters.starred,
            [RisksItemsFilterId[Contract.RiskFilterId.StatusUpdateTime]]: TimeRangeHelper.toTimeRangeFilterSelectionFromTimeRangeSelection(filters.statusUpdateTimeRangeSelection),
            [RisksItemsFilterId[Contract.RiskFilterId.RiskSubStatus]]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.subStatusSelection),
            [RisksItemsFilterId[Contract.RiskFilterId.SystemCreationTime]]: TimeRangeHelper.toTimeRangeFilterSelectionFromTimeRangeSelection(filters.systemCreationTimeRangeSelection),
            [RisksItemsFilterId[Contract.RiskFilterId.TenantId]]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.tenantIdSelection),
            [RisksItemsFilterId[Contract.RiskFilterId.TicketingServiceType]]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.ticketingServiceTypeSelection),
            [RisksItemsFilterId[Contract.RiskFilterId.CodeRiskCloudTenantType]]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.cloudTenantTypeSelection),
            [RisksItemsFilterId[Contract.RiskFilterId.CodeRiskCloudTenantType]]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.codeTypeSelection),
            [RisksItemsFilterId[Contract.RiskFilterId.CodeRiskCloudTenantType]]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.fileRelativePathSelection),
            [RisksItemsFilterId[Contract.RiskFilterId.CodeRiskCloudTenantType]]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.resourceNameSelection),
            [RisksItemsFilterId[Contract.RiskFilterId.CodeRiskCloudTenantType]]: ItemSelectionHelper.toValuesFilterSelectionFromItemSelection(filters.resourceTypeSystemNameSelection)
        };
    }
}