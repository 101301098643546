import { Dictionary } from "lodash";
import React from "react";
import { Contract } from "../../..";
import { AccessGraphPermissionEdgeData } from "../utilities";
import { EntitiesPermissionActions } from "./EntitiesPermissionActions";

type ListProps = {
    accessGraph: Contract.AccessGraph;
    accessGraphId: string;
    excessivePermissionsEnabled: boolean;
    permissionEdgeIdToDataMap: Dictionary<AccessGraphPermissionEdgeData>;
    resourceServiceModelMap: Dictionary<Contract.EntityModel>;
};

export function List({ accessGraph, accessGraphId, excessivePermissionsEnabled, permissionEdgeIdToDataMap, resourceServiceModelMap }: ListProps) {
    return (
        <EntitiesPermissionActions
            accessGraphId={accessGraphId}
            accessVariant={accessGraph.variant}
            excessivePermissionsEnabled={excessivePermissionsEnabled}
            permissionEdgeIdToDataMap={permissionEdgeIdToDataMap}
            permissionPaths={accessGraph.permissionPaths}
            resourceServiceModelMap={resourceServiceModelMap}
            variant="list"/>);
}