import { UnexpectedError, useLocalization } from "@infrastructure";
import { Contract, TypeHelper } from "../../../../../../../../../common";

export function useDataAnalysisResourceSegmentTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.profile.dataAnalysisResourceOverview.hooks.useDataAnalysisResourceSegmentTranslator",
            () => ({
                objects: "Objects",
                tables: "Tables"
            }));

    return (dataAnalysisResourceModel: Contract.IDataAnalysisResourceModel) => {
        if (TypeHelper.extendOrImplement(dataAnalysisResourceModel.entity.typeName, Contract.TypeNames.IObjectStore)) {
            return localization.objects();
        }

        switch (dataAnalysisResourceModel.typeName) {
            case Contract.TypeNames.AwsRdsClusterModel:
            case Contract.TypeNames.AwsRdsDatabaseInstanceModel:
            case Contract.TypeNames.AzureSqlServerModel:
            case Contract.TypeNames.GcpBigQueryDatasetModel:
            case Contract.TypeNames.GcpSqlInstanceModel:
                return localization.tables();
            case Contract.TypeNames.AwsDynamoDbTableModel:
                return undefined;
            default:
                throw new UnexpectedError("useDataAnalysisResourceSegmentTranslator", dataAnalysisResourceModel.typeName);
        }
    };
}