﻿import { useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { InfoItem, InlinePermissionActions, NoneDefinition } from "../../../../../../../../../../../../common";

type PermissionUsageInfoItemProps = {
    gcpSpecialGroupsPermissionActions?: string[];
    permissionActions?: string[];
    title: string;
};

export function PermissionUsageInfoItem({ gcpSpecialGroupsPermissionActions, permissionActions, title }: PermissionUsageInfoItemProps) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpIamRoleBindingDefinition.permissionUsageInfoItem",
            () => ({
                permissionActionExists: {
                    gcpSpecialGroupsPermissionActionExists: "{{permissionActions}}, Additional {{gcpSpecialGroupsPermissionsActions}} Via GCP Special Groups",
                    gcpSpecialGroupsPermissionActionNotExist: "{{permissionActions}}"
                },
                permissionActionNotExist: {
                    gcpSpecialGroupsPermissionActionExists: "{{gcpSpecialGroupsPermissionsActions}} Via GCP Special Groups"
                }
            }));

    const permissionActionExists =
        !_.isNil(permissionActions) &&
        !_.isEmpty(permissionActions);
    const gcpSpecialGroupsPermissionActionExists =
        !_.isNil(gcpSpecialGroupsPermissionActions) &&
        !_.isEmpty(gcpSpecialGroupsPermissionActions);
    return (
        <InfoItem
            title={title}
            value={
                <Typography>
                    {permissionActionExists
                        ? gcpSpecialGroupsPermissionActionExists
                            ? localization.permissionActionExists.gcpSpecialGroupsPermissionActionExists({
                                gcpSpecialGroupsPermissionsActions:
                                    <InlinePermissionActions
                                        permissionActions={gcpSpecialGroupsPermissionActions}
                                        variant="itemCountAndType"/>,
                                permissionActions:
                                    <InlinePermissionActions
                                        permissionActions={permissionActions}
                                        variant="itemCountAndType"/>
                            })
                            : localization.permissionActionExists.gcpSpecialGroupsPermissionActionNotExist({
                                permissionActions:
                                    <InlinePermissionActions
                                        permissionActions={permissionActions}
                                        variant="itemCountAndType"/>
                            })
                        : gcpSpecialGroupsPermissionActionExists
                            ? localization.permissionActionNotExist.gcpSpecialGroupsPermissionActionExists({
                                gcpSpecialGroupsPermissionsActions:
                                    <InlinePermissionActions
                                        permissionActions={gcpSpecialGroupsPermissionActions}
                                        variant="itemCountAndType"/>
                            })
                            : <NoneDefinition/>}
                </Typography>
            }/>);
}