﻿import { Link, Step, Steps, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetGcpContainerClusterRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../components";

export function useGcpContainerClusterKubernetesSecretEncryptionDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpContainerClusterKubernetesSecretEncryptionDisabledRisk;
    const clusterKubernetesSecretEncryptionDisabledRiskModel = riskModel as Contract.GcpContainerClusterKubernetesSecretEncryptionDisabledRiskModel;
    const clusterModel = entityModelStore.useGet(risk.entityId) as Contract.GcpContainerClusterModel;

    const getGcpContainerClusterRiskContext = useGetGcpContainerClusterRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpContainerClusterKubernetesSecretEncryptionDisabledRiskDefinition",
            () => ({
                description: "{{cluster}} is not configured to encrypt Kubernetes Secrets using Cloud KMS",
                sections: {
                    resolution: {
                        step1: {
                            step1: {
                                link: "Cloud KMS console",
                                text: "In GCP Portal, open the {{cloudKmsConsoleLink}}"
                            },
                            step2: "Click **CREATE KEY RING**",
                            step3: "Enter a **Key ring name** and the region where the keys will be stored",
                            step4: "Click **CREATE**",
                            step5: "In the **Name and protection level** section, enter a **Key name** and click **CONTINUE**",
                            step6: "In the **Versions** section, enter an appropriate **key rotation period**",
                            step7: "Click **CREATE**",
                            title: "Create a key:"
                        },
                        step2: {
                            step1: "Under **Security - Application-layer secrets encryption**, click the pencil button",
                            step2: "Check **Encrypt secrets at the application layer**",
                            step3: "Choose the desired Key",
                            step4: "Click **Save Changes**",
                            title: "To enable on an existing cluster:"
                        },
                        step3: {
                            link: "relevant GKE documentation",
                            text: "For more information, refer to the {{containerClusterSecretApplicationLayerEncryptionLink}}"
                        }
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={risk.entityId}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            new Step(
                localization.sections.resolution.step1.title(),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {localization.sections.resolution.step1.step1.text({
                                cloudKmsConsoleLink:
                                    <Link
                                        urlOrGetUrl={clusterKubernetesSecretEncryptionDisabledRiskModel.cloudKmsConsoleDocumentationUrl}
                                        variant="external">
                                        {localization.sections.resolution.step1.step1.link()}
                                    </Link>
                            })}
                            {localization.sections.resolution.step1.step2()}
                            {localization.sections.resolution.step1.step3()}
                            {localization.sections.resolution.step1.step4()}
                            {localization.sections.resolution.step1.step5()}
                            {localization.sections.resolution.step1.step6()}
                            {localization.sections.resolution.step1.step7()}
                        </Steps>
                }),
            new Step(
                localization.sections.resolution.step2.title(),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            <EntityExternalConsoleLink
                                entityId={risk.entityId}
                                page={Contract.GcpConsolePage.Resource}/>
                            {localization.sections.resolution.step2.step1()}
                            {localization.sections.resolution.step2.step2()}
                            {localization.sections.resolution.step2.step3()}
                            {localization.sections.resolution.step2.step4()}
                        </Steps>
                }),
            localization.sections.resolution.step3.text({
                containerClusterSecretApplicationLayerEncryptionLink:
                    <Link
                        urlOrGetUrl={clusterKubernetesSecretEncryptionDisabledRiskModel.clusterSecretApplicationLayerEncryptionDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step3.link()}
                    </Link>
            })
        ],
        riskModel,
        () => {
            const clusterRiskContext = getGcpContainerClusterRiskContext(clusterModel);
            return [
                clusterRiskContext.generalInformation,
                clusterRiskContext.sensitive,
                clusterRiskContext.vpc,
                clusterRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}