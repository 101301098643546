import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, Optional, useLocalization } from "@infrastructure";
import { alpha, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { AwsEc2NetworkRulePortRangeCell, AwsEc2NetworkRuleTypeCell } from ".";
import { Contract, Entity, entityModelStore, NetworkScopeFormatter, useTheme } from "../../../common";

type AwsEc2SecurityGroupRulesTableProps = {
    getHighlightColor?: (rule: Contract.AwsEc2SecurityGroupRule, opacity?: number) => Optional<string>;
    inbound: boolean;
    referencedSecurityGroupId?: string;
    rules: Contract.AwsEc2SecurityGroupRule[];
};

export function AwsEc2SecurityGroupRulesTable({ getHighlightColor, inbound, referencedSecurityGroupId, rules }: AwsEc2SecurityGroupRulesTableProps) {
    const securityGroupModels =
        entityModelStore.useGet(
            _(rules).
                map(rule => rule.securityGroupId!).
                filter().
                value());
    const items =
        useMemo(
            () => {
                const securityGroupModelMap =
                    _(securityGroupModels).
                        filter().
                        keyBy(securityGroupModel => securityGroupModel.entity.id).
                        value();

                return _(rules).
                    orderBy(
                        rule => !_.isNil(getHighlightColor?.(rule)),
                        "desc").
                    map(
                        rule =>
                            new AwsEc2SecurityGroupRulesTableItem(
                                rule,
                                _.isNil(rule.securityGroupId)
                                    ? undefined
                                    : securityGroupModelMap[rule.securityGroupId])).
                    value();
            },
            [rules]);

    const localization =
        useLocalization(
            "tenants.aws.awsEc2SecurityGroupRulesTable",
            () => ({
                columns: {
                    portRange: "Port Range",
                    protocol: "Protocol",
                    sourceOrDestination: {
                        title: {
                            inbound: "Source",
                            outbound: "Destination"
                        }
                    },
                    type: "Type"
                },
                empty: "No Rules"
            }));

    const theme = useTheme();
    return (
        <DataTable
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={() => items}
            getItemId={
                (item: AwsEc2SecurityGroupRulesTableItem) =>
                    _(items).
                        indexOf(item).
                        toString()}
            rowOptions={{
                getHighlightColor: (item: AwsEc2SecurityGroupRulesTableItem) => getHighlightColor?.(item.rule),
                getSx:
                    (item: AwsEc2SecurityGroupRulesTableItem) => ({
                        backgroundColor:
                            getHighlightColor?.(item.rule, 0.1) ?? (
                                !_.isNil(referencedSecurityGroupId) &&
                                referencedSecurityGroupId == item.rule.securityGroupId
                                    ? alpha(theme.palette.primary.main, 0.1)
                                    : undefined)
                    })
            }}
            sortOptions={{ enabled: false }}
            variant="card">
            <DataTableColumn
                id={AwsEc2SecurityGroupRulesTableColumnId.Type}
                render={
                    ({ item }: DataTableColumnRenderProps<AwsEc2SecurityGroupRulesTableItem>) =>
                        <AwsEc2NetworkRuleTypeCell item={item.rule}/>}
                title={localization.columns.type()}/>
            <DataTableColumn
                id={AwsEc2SecurityGroupRulesTableColumnId.Protocol}
                itemProperty={(item: AwsEc2SecurityGroupRulesTableItem) => NetworkScopeFormatter.protocolRange(item.rule.protocolRange, Contract.TenantType.Aws)}
                title={localization.columns.protocol()}/>
            <DataTableColumn
                id={AwsEc2SecurityGroupRulesTableColumnId.PortRange}
                render={
                    ({ item }: DataTableColumnRenderProps<AwsEc2SecurityGroupRulesTableItem>) =>
                        <AwsEc2NetworkRulePortRangeCell item={item.rule}/>}
                title={localization.columns.portRange()}/>
            <DataTableColumn
                id={AwsEc2SecurityGroupRulesTableColumnId.SourceOrDestination}
                render={
                    ({ item }: DataTableColumnRenderProps<AwsEc2SecurityGroupRulesTableItem>) =>
                        _.isNil(item.rule.securityGroupRawId)
                            ? <Typography noWrap={true}>
                                {!_.isNil(item.rule.prefixListRawId)
                                    ? item.rule.prefixListRawId
                                    : item.rule.subnet}
                            </Typography>
                            : _.isNil(item.securityGroupModel)
                                ? <Typography noWrap={true}>
                                    {item.rule.securityGroupRawId}
                                </Typography>
                                : <Entity
                                    entityIdOrModel={item.securityGroupModel}
                                    variant="iconTextTenant"/>}
                title={
                    inbound
                        ? localization.columns.sourceOrDestination.title.inbound()
                        : localization.columns.sourceOrDestination.title.outbound()}/>
        </DataTable>);
}

class AwsEc2SecurityGroupRulesTableItem {
    constructor(
        public rule: Contract.AwsEc2SecurityGroupRule,
        public securityGroupModel?: Contract.EntityModel) {
    }
}

enum AwsEc2SecurityGroupRulesTableColumnId {
    PortRange = "portRange",
    Protocol = "protocol",
    SourceOrDestination = "sourceOrDestination",
    Type = "type"
}