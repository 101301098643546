import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, DeferredFilter, EnumValuesFilter, optionalTableCell, TimeFormatter, TimeRangeFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useGcpCommonScopeResourceDefinition, useGcpCommonServiceAccountOriginatorScopeResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { useCommonNetworkAccessResourceDefinition, useCommonVirtualMachineDiskResourceDefinition, useCommonVirtualMachineResourceDefinition } from "..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useGcpComputeInstanceStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useGcpComputeInstanceDefinition(definitionData: DefinitionData) {
    const commonServiceAccountOriginatorScopeResourceDefinition = useGcpCommonServiceAccountOriginatorScopeResourceDefinition(definitionData);
    const commonNetworkAccessResourceDefinition = useCommonNetworkAccessResourceDefinition();
    const commonScopeResourceDefinition = useGcpCommonScopeResourceDefinition(definitionData);
    const commonVirtualMachineDiskResourceDefinition = useCommonVirtualMachineDiskResourceDefinition(definitionData);
    const commonVirtualMachineResourceDefinition = useCommonVirtualMachineResourceDefinition();
    const entitiesExportOptions = useEntitiesExportOptions();
    const instanceModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.GcpComputeInstanceModelFilters>;

    const computeInstanceStatusTranslator = useGcpComputeInstanceStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpComputeInstanceDefinition",
            () => ({
                columns: {
                    privateIpAddresses: "Private IP Addresses",
                    publicIpAddresses: "Public IP Addresses",
                    status: "Status",
                    stopTime: "Stop Time",
                    targetPools: "Load Balancer Target Pools",
                    vpcIdReferences: "VPC Networks"
                }
            }));

    return new EntityTableDefinition(
        [
            commonScopeResourceDefinition.columns.rawIdColumn,
            commonScopeResourceDefinition.columns.tenantColumn,
            commonScopeResourceDefinition.columns.creationTimeColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpComputeInstanceModel) => ({
                            [localization.columns.status()]: computeInstanceStatusTranslator((item.entity as Contract.GcpComputeInstance).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.GcpComputeInstanceStatus}
                                enumTypeTranslator={computeInstanceStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeInstanceStatus}
                itemProperty={(item: Contract.EntityModel) => computeInstanceStatusTranslator((item.entity as Contract.GcpComputeInstance).status)}
                key={Contract.EntityModelProperty.GcpComputeInstanceStatus}
                title={localization.columns.status()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpComputeInstanceModel) => ({
                            [localization.columns.stopTime()]:
                                _.isNil(item.stopTime)
                                    ? ""
                                    : TimeFormatter.iso8601String(item.stopTime)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={instanceModelFiltersPromise}
                                title={localization.columns.stopTime()}>
                                {filters =>
                                    <TimeRangeFilter
                                        emptyValue={filters.stopTimeRange.emptyValue}
                                        placeholder={localization.columns.stopTime()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.stopTimeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeInstanceStopTime}
                key={Contract.EntityModelProperty.GcpComputeInstanceStopTime}
                render={
                    optionalTableCell<Contract.GcpComputeInstanceModel>(
                        item =>
                            _.isNil(item.stopTime)
                                ? undefined
                                : TimeFormatter.monthDayAndYear(item.stopTime))}
                selectorOptions={{ default: false }}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.stopTime()}/>,
            commonScopeResourceDefinition.columns.creatorIdentityCsvColumn,
            commonScopeResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            commonVirtualMachineDiskResourceDefinition.columns.operatingSystemColumn,
            commonVirtualMachineDiskResourceDefinition.columns.operatingSystemTypeColumn,
            commonNetworkAccessResourceDefinition.columns.accessLevelColumn,
            commonNetworkAccessResourceDefinition.columns.inboundExternalAccessScopeColumn,
            commonNetworkAccessResourceDefinition.columns.destinationNetworkScopesColumn(instanceModelFiltersPromise),
            commonVirtualMachineDiskResourceDefinition.columns.vulnerabilitiesColumn,
            commonVirtualMachineDiskResourceDefinition.columns.workloadAnalysisStatusColumn,
            commonVirtualMachineDiskResourceDefinition.columns.scanTimeColumn,
            commonVirtualMachineResourceDefinition.columns.scanSource,
            commonScopeResourceDefinition.columns.accessLevelColumn,
            commonScopeResourceDefinition.columns.updateTimeColumn,
            commonServiceAccountOriginatorScopeResourceDefinition.columns.serviceAccountColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpComputeInstanceModel) => ({
                            [localization.columns.publicIpAddresses()]: _.join((item.entity as Contract.GcpComputeInstance).publicIpAddresses, "\n"),
                            [localization.columns.privateIpAddresses()]: _.join((item.entity as Contract.GcpComputeInstance).privateIpAddresses, "\n")
                        })
                }}
                id="ipAddresses"
                key="ipAddresses"/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpComputeInstanceModel>(
                        Contract.TypeNames.GcpComputeVpc,
                        item => item.vpcIdReferences,
                        localization.columns.vpcIdReferences())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpComputeInstanceVpcs)}
                                placeholder={localization.columns.vpcIdReferences()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeInstanceVpcs}
                key={Contract.EntityModelProperty.GcpComputeInstanceVpcs}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpComputeInstanceModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.vpcIdReferences}
                            entityTypeName={Contract.TypeNames.GcpComputeVpc}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.vpcIdReferences()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpComputeInstanceModel>(
                        Contract.TypeNames.GcpComputeTargetPool,
                        item => (item as Contract.GcpComputeInstanceModel).targetPoolIds,
                        localization.columns.targetPools())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpComputeInstanceTargetPools)}
                                placeholder={localization.columns.targetPools()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeInstanceTargetPools}
                key={Contract.EntityModelProperty.GcpComputeInstanceTargetPools}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpComputeInstanceModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.targetPoolIds}
                            entityTypeName={Contract.TypeNames.GcpComputeTargetPool}
                            entityVariant="iconText"/>}
                selectorOptions={{ default: false }}
                title={localization.columns.targetPools()}/>,
            commonScopeResourceDefinition.columns.regionColumn,
            commonScopeResourceDefinition.columns.regionLocationColumn,
            commonScopeResourceDefinition.columns.tagsColumn,
            commonScopeResourceDefinition.columns.attributesColumn,
            commonScopeResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpComputeInstanceRequest(
                new Contract.EntityControllerGetEntityModelPageGcpComputeInstanceRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceAccessLevel]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeInstanceStatus]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpComputeInstanceStopTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeInstanceTargetPools]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeInstanceVpcs])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestNetworkAccess(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundAccessType]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalAccessScope]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalDestinationNetworkScopes])),
                new Contract.EntityControllerGetEntityModelPageGcpScopeResourceRequestServiceAccount(ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpServiceAccountOriginatorScopeResourceServiceAccount])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestVirtualMachineDiskResource(
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.VirtualMachineOperatingSystemDisplayName]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.VirtualMachineOperatingSystemType]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.VirtualMachineScanTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.VirtualMachineVulnerabilities]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.VirtualMachineVulnerabilitySeverities]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.VirtualMachineScanStatus])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestVirtualMachineResource(ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.VirtualMachineKubernetesClusterSensor]))));
}