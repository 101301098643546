import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { useRiskAuditEventDetailsItems } from "./useRiskAuditEventDetailsItems";

export function useRiskAuditEventDefinition(context: AuditEventDefinitionContext) {
    const riskAuditEventDetailsItems = useRiskAuditEventDetailsItems(context);
    return new AuditEventDefinition(
        _.filter([
            riskAuditEventDetailsItems.riskPolicyTypeItem,
            riskAuditEventDetailsItems.riskIdItem,
            riskAuditEventDetailsItems.riskDeletedItem,
            riskAuditEventDetailsItems.riskedEntitiesItem,
            riskAuditEventDetailsItems.riskLinkItem,
            riskAuditEventDetailsItems.errorMessageItem
        ]) as AuditEventDefinitionDetailItem[]);
}