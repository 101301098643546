import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAuditEventTypeNameTranslator() {
    const localization =
        useLocalization(
            "views.customer.auditEvents.hooks.useAuditEventTypeNameTranslator",
            () => ({
                [Contract.TypeNames.AuditEvent]: {
                    [Contract.TypeNames.AadTenantCreationAuditEvent]: "Add Microsoft Entra ID tenant",
                    [Contract.TypeNames.AadTenantDeletionAuditEvent]: "Remove Microsoft Entra ID tenant",
                    [Contract.TypeNames.AadTenantUpdateAuditEvent]: "Update Microsoft Entra ID tenant",
                    [Contract.TypeNames.ApiKeyCreationAuditEvent]: "Add API token",
                    [Contract.TypeNames.ApiKeyDeletionAuditEvent]: "Remove API token",
                    [Contract.TypeNames.AuditEventAutomationRuleCreationAuditEvent]: "Create audit automation rule",
                    [Contract.TypeNames.AuditEventAutomationRuleDeletionAuditEvent]: "Delete audit automation rule",
                    [Contract.TypeNames.AuditEventAutomationRuleUpdateAuditEvent]: "Update audit automation rule",
                    [Contract.TypeNames.AutomaticCustomEntityAttributeDefinitionTemplateCreationAuditEvent]: "Create automatic label",
                    [Contract.TypeNames.AutomaticCustomEntityAttributeDefinitionTemplateDeletionAuditEvent]: "Delete automatic label",
                    [Contract.TypeNames.AutomaticCustomEntityAttributeDefinitionTemplateUpdateAuditEvent]: "Update automatic label",
                    [Contract.TypeNames.AwsOrganizationCreationAuditEvent]: "Add AWS organization",
                    [Contract.TypeNames.AwsOrganizationDeletionAuditEvent]: "Remove AWS organization",
                    [Contract.TypeNames.AwsOrganizationUpdateAuditEvent]: "Update AWS organization",
                    [Contract.TypeNames.AwsPermissionPresetCreationAuditEvent]: "Create JIT role",
                    [Contract.TypeNames.AwsPermissionPresetDeletionAuditEvent]: "Delete JIT role",
                    [Contract.TypeNames.AwsPermissionPresetUpdateAuditEvent]: "Update JIT role",
                    [Contract.TypeNames.AwsTenantCreationAuditEvent]: "Add AWS account",
                    [Contract.TypeNames.AwsTenantDeletionAuditEvent]: "Remove AWS account",
                    [Contract.TypeNames.AwsTenantUpdateAuditEvent]: "Update AWS account details",
                    [Contract.TypeNames.AzureOrganizationCreationAuditEvent]: "Add Azure organization",
                    [Contract.TypeNames.AzureOrganizationDeletionAuditEvent]: "Remove Azure organization",
                    [Contract.TypeNames.AzureOrganizationUpdateAuditEvent]: "Update Azure organization",
                    [Contract.TypeNames.AzureTenantCreationAuditEvent]: "Add Azure subscription",
                    [Contract.TypeNames.AzureTenantDeletionAuditEvent]: "Remove Azure subscription",
                    [Contract.TypeNames.AzureTenantUpdateAuditEvent]: "Update Azure subscription details",
                    [Contract.TypeNames.AzureWorkloadAnalysisUpdateAuditEvent]: "Update workload protection Azure resource group",
                    [Contract.TypeNames.BuiltInComplianceEnabledUpdateAuditEvent]: "Update compliance",
                    [Contract.TypeNames.CiOrganizationCreationAuditEvent]: "Add container image registry organization",
                    [Contract.TypeNames.CiOrganizationDeletionAuditEvent]: "Remove container image registry organization",
                    [Contract.TypeNames.CiOrganizationUpdateAuditEvent]: "Update container image registry organization",
                    [Contract.TypeNames.CiRegistryIntegrationCreationAuditEvent]: "Add container image registry integration",
                    [Contract.TypeNames.CiRegistryIntegrationDeletionAuditEvent]: "Remove container image registry integration",
                    [Contract.TypeNames.CiRegistryIntegrationUpdateAuditEvent]: "Update container image registry integration",
                    [Contract.TypeNames.CiTenantCreationAuditEvent]: "Add container image registry",
                    [Contract.TypeNames.CiTenantDeletionAuditEvent]: "Remove container image registry",
                    [Contract.TypeNames.CiTenantUpdateAuditEvent]: "Update container image registry",
                    [Contract.TypeNames.CodeOrganizationCreationAuditEvent]: "Add Code Organization",
                    [Contract.TypeNames.CodeOrganizationDeletionAuditEvent]: "Remove Code Organization",
                    [Contract.TypeNames.CodeOrganizationUpdateAuditEvent]: "Update Code Organization",
                    [Contract.TypeNames.CodePipelineCreationAuditEvent]: "Add CI/CD",
                    [Contract.TypeNames.CodePipelineDeletionAuditEvent]: "Remove CI/CD",
                    [Contract.TypeNames.CodePipelineUpdateAuditEvent]: "Update CI/CD",
                    [Contract.TypeNames.CodeServerCreationAuditEvent]: "Add Code Server",
                    [Contract.TypeNames.CodeServerDeletionAuditEvent]: "Remove Code Server",
                    [Contract.TypeNames.CodeServerUpdateAuditEvent]: "Update Code Server",
                    [Contract.TypeNames.CodeUpdateAuditEvent]: "Update IaC",
                    [Contract.TypeNames.CustomComplianceCreationAuditEvent]: "Create compliance",
                    [Contract.TypeNames.CustomComplianceDeletionAuditEvent]: "Delete compliance",
                    [Contract.TypeNames.CustomComplianceUpdateAuditEvent]: "Update compliance",
                    [Contract.TypeNames.CustomerConsoleSignInAuditEvent]: "User sign in",
                    [Contract.TypeNames.CustomerConsoleSignOutAuditEvent]: "User sign out",
                    [Contract.TypeNames.DataAnalysisResourcePriorityScanRequestUpdateAuditEvent]: "Request on-demand data scan",
                    [Contract.TypeNames.DataAnalysisUpdateAuditEvent]: "Update data analysis",
                    [Contract.TypeNames.DatadogOrganizationCreationAuditEvent]: "Add Datadog Organization",
                    [Contract.TypeNames.DatadogOrganizationDeletionAuditEvent]: "Remove Datadog Organization",
                    [Contract.TypeNames.DatadogOrganizationUpdateAuditEvent]: "Update Datadog Organization details",
                    [Contract.TypeNames.EndpointConnectorCreationAuditEvent]: "Add endpoint connector",
                    [Contract.TypeNames.EndpointConnectorDeletionAuditEvent]: "Remove endpoint connector",
                    [Contract.TypeNames.EntityManualCustomAttributeDefinitionUpdateAuditEvent]: "Update resource labels",
                    [Contract.TypeNames.EntityPropertyDefinitionCreationAuditEvent]: "Create field",
                    [Contract.TypeNames.EntityPropertyDefinitionDeletionAuditEvent]: "Delete field",
                    [Contract.TypeNames.EntityPropertyDefinitionUpdateAuditEvent]: "Update field",
                    [Contract.TypeNames.EntitySensitivityUpdateAuditEvent]: "Update resource sensitivity",
                    [Contract.TypeNames.FolderCreationAuditEvent]: "Add folder",
                    [Contract.TypeNames.FolderDeletionAuditEvent]: "Delete folder",
                    [Contract.TypeNames.FolderUpdateAuditEvent]: "Update folder details",
                    [Contract.TypeNames.GciTenantCreationAuditEvent]: "Add Google workspace",
                    [Contract.TypeNames.GciTenantDeletionAuditEvent]: "Remove Google workspace",
                    [Contract.TypeNames.GciTenantUpdateAuditEvent]: "Update Google workspace details",
                    [Contract.TypeNames.GcpOrganizationCreationAuditEvent]: "Add Google organization",
                    [Contract.TypeNames.GcpOrganizationDeletionAuditEvent]: "Remove Google organization",
                    [Contract.TypeNames.GcpOrganizationUpdateAuditEvent]: "Update Google organization",
                    [Contract.TypeNames.GcpTenantCreationAuditEvent]: "Add Google project",
                    [Contract.TypeNames.GcpTenantDeletionAuditEvent]: "Remove Google project",
                    [Contract.TypeNames.GcpTenantUpdateAuditEvent]: "Update Google project details",
                    [Contract.TypeNames.GeneralCodeTenantDeletionAuditEvent]: "Remove Code repository",
                    [Contract.TypeNames.GeneralCodeTenantUpdateAuditEvent]: "Update Code repository",
                    [Contract.TypeNames.GitTenantCreationAuditEvent]: "Add Code repository",
                    [Contract.TypeNames.GitTenantDeletionAuditEvent]: "Remove Code repository",
                    [Contract.TypeNames.GitTenantUpdateAuditEvent]: "Update Code repository",
                    [Contract.TypeNames.GroupRoleAssignmentCreationAuditEvent]: "Add group role assignment",
                    [Contract.TypeNames.GroupRoleAssignmentDeletionAuditEvent]: "Remove group role assignment",
                    [Contract.TypeNames.GroupRoleAssignmentUpdateAuditEvent]: "Update group role assignment",
                    [Contract.TypeNames.InternalSubnetCreationAuditEvent]: "Add known IP address range",
                    [Contract.TypeNames.InternalSubnetDeletionAuditEvent]: "Delete known IP address range",
                    [Contract.TypeNames.InternalSubnetUpdateAuditEvent]: "Update known IP address range",
                    [Contract.TypeNames.JiraInstanceCreationAuditEvent]: "Add Jira site",
                    [Contract.TypeNames.JiraInstanceDeletionAuditEvent]: "Remove Jira site",
                    [Contract.TypeNames.JiraInstanceUpdateAuditEvent]: "Update Jira site details",
                    [Contract.TypeNames.JiraProjectCreationAuditEvent]: "Add Jira project",
                    [Contract.TypeNames.JiraProjectDeletionAuditEvent]: "Remove Jira project",
                    [Contract.TypeNames.JiraProjectUpdateAuditEvent]: "Update Jira project",
                    [Contract.TypeNames.KubernetesClusterConnectorCreationAuditEvent]: "Add Kubernetes cluster connector",
                    [Contract.TypeNames.KubernetesClusterConnectorDeletionAuditEvent]: "Remove Kubernetes cluster connector",
                    [Contract.TypeNames.KubernetesClusterConnectorUpdateAuditEvent]: "Update Kubernetes cluster connector",
                    [Contract.TypeNames.MailUpdateAuditEvent]: "Update notification mail address list",
                    [Contract.TypeNames.ManualCustomEntityAttributeDefinitionCreationAuditEvent]: "Create manual label",
                    [Contract.TypeNames.ManualCustomEntityAttributeDefinitionDeletionAuditEvent]: "Delete manual label",
                    [Contract.TypeNames.ManualCustomEntityAttributeDefinitionUpdateAuditEvent]: "Update manual label",
                    [Contract.TypeNames.OciOrganizationCreationAuditEvent]: "Add OCI tenancy",
                    [Contract.TypeNames.OciOrganizationDeletionAuditEvent]: "Remove OCI tenancy",
                    [Contract.TypeNames.OciOrganizationUpdateAuditEvent]: "Update OCI tenancy",
                    [Contract.TypeNames.OciTenantCreationAuditEvent]: "Add OCI compartment",
                    [Contract.TypeNames.OciTenantDeletionAuditEvent]: "Remove OCI compartment",
                    [Contract.TypeNames.OciTenantUpdateAuditEvent]: "Update OCI compartment",
                    [Contract.TypeNames.OktaTenantCreationAuditEvent]: "Add Okta organization",
                    [Contract.TypeNames.OktaTenantDeletionAuditEvent]: "Remove Okta organization",
                    [Contract.TypeNames.OktaTenantUpdateAuditEvent]: "Update Okta organization details",
                    [Contract.TypeNames.OneLoginTenantCreationAuditEvent]: "Add OneLogin organization",
                    [Contract.TypeNames.OneLoginTenantDeletionAuditEvent]: "Remove OneLogin organization",
                    [Contract.TypeNames.OneLoginTenantUpdateAuditEvent]: "Update OneLogin organization details",
                    [Contract.TypeNames.OpTenantCreationAuditEvent]: "Add On-Premises account",
                    [Contract.TypeNames.OpTenantDeletionAuditEvent]: "Remove On-Premises account",
                    [Contract.TypeNames.OpTenantUpdateAuditEvent]: "Update On-Premises account",
                    [Contract.TypeNames.PermissionEligibilityCreationAuditEvent]: "Create JIT eligibility",
                    [Contract.TypeNames.PermissionEligibilityDeletionAuditEvent]: "Delete JIT eligibility",
                    [Contract.TypeNames.PermissionEligibilityUpdateAuditEvent]: "Update JIT eligibility",
                    [Contract.TypeNames.PermissionRequestActivationAuditEvent]: "JIT access granted",
                    [Contract.TypeNames.PermissionRequestActivationFailureAuditEvent]: "JIT access request activation failed",
                    [Contract.TypeNames.PermissionRequestApprovalAuditEvent]: "JIT access request approved",
                    [Contract.TypeNames.PermissionRequestCancellationAuditEvent]: "JIT access request cancelled",
                    [Contract.TypeNames.PermissionRequestCreationAuditEvent]: "JIT access requested",
                    [Contract.TypeNames.PermissionRequestExpirationAuditEvent]: "JIT access expired",
                    [Contract.TypeNames.PermissionRequestRejectionAuditEvent]: "JIT access request denied",
                    [Contract.TypeNames.PermissionRequestRequestExpirationAuditEvent]: "JIT access request timed-out",
                    [Contract.TypeNames.PermissionRequestRevocationAuditEvent]: "JIT access revoked",
                    [Contract.TypeNames.PingIdentityTenantCreationAuditEvent]: "Add Ping Identity environment",
                    [Contract.TypeNames.PingIdentityTenantDeletionAuditEvent]: "Remove Ping Identity environment",
                    [Contract.TypeNames.PingIdentityTenantUpdateAuditEvent]: "Update Ping Identity environment details",
                    [Contract.TypeNames.ProjectCreationAuditEvent]: "Add project",
                    [Contract.TypeNames.ProjectDeletionAuditEvent]: "Remove project",
                    [Contract.TypeNames.ProjectUpdateAuditEvent]: "Update project details",
                    [Contract.TypeNames.QRadarServerCreationAuditEvent]: "Add QRadar server",
                    [Contract.TypeNames.QRadarServerDeletionAuditEvent]: "Remove QRadar server",
                    [Contract.TypeNames.QRadarServerUpdateAuditEvent]: "Update QRadar server details",
                    [Contract.TypeNames.ReportScheduleCreationAuditEvent]: "Create scheduled report",
                    [Contract.TypeNames.ReportScheduleDeletionAuditEvent]: "Delete scheduled report",
                    [Contract.TypeNames.ReportScheduleUpdateAuditEvent]: "Update scheduled report details",
                    [Contract.TypeNames.RiskAutomationRuleCreationAuditEvent]: "Create policy automation rule",
                    [Contract.TypeNames.RiskAutomationRuleDeletionAuditEvent]: "Delete policy automation rule",
                    [Contract.TypeNames.RiskAutomationRuleUpdateAuditEvent]: "Update policy automation rule",
                    [Contract.TypeNames.RiskCodeResolutionAuditEvent]: "Create Pull request",
                    [Contract.TypeNames.RiskCreationAuditEvent]: "Create finding",
                    [Contract.TypeNames.RiskJiraIssueCreationAuditEvent]: "Create Jira issue",
                    [Contract.TypeNames.RiskJiraIssueUpdateAuditEvent]: "Update Jira issue",
                    [Contract.TypeNames.RiskNoteCreationAuditEvent]: "Create finding note",
                    [Contract.TypeNames.RiskNoteDeletionAuditEvent]: "Delete finding note",
                    [Contract.TypeNames.RiskNoteUpdateAuditEvent]: "Update finding note",
                    [Contract.TypeNames.RiskPolicyUpdateAuditEvent]: "Update policy",
                    [Contract.TypeNames.RiskPolicyCreationAuditEvent]: "Create policy",
                    [Contract.TypeNames.RiskPolicyDeletionAuditEvent]: "Delete policy",
                    [Contract.TypeNames.RiskResolutionAuditEvent]: "Remediate finding",
                    [Contract.TypeNames.RiskSendMailAuditEvent]: "Share findings",
                    [Contract.TypeNames.RiskSendWebhookAuditEvent]: "Send findings",
                    [Contract.TypeNames.RiskServiceNowIncidentCreationAuditEvent]: "Create ServiceNow incident",
                    [Contract.TypeNames.RiskServiceNowIncidentUpdateAuditEvent]: "Update ServiceNow incident",
                    [Contract.TypeNames.RiskSeverityUpdateAuditEvent]: "Update finding severity",
                    [Contract.TypeNames.RiskStarredUpdateAuditEvent]: "Star findings",
                    [Contract.TypeNames.RiskStatusUpdateAuditEvent]: "Update finding status",
                    [Contract.TypeNames.RiskStatusUpdateNoteDeletionAuditEvent]: "Delete finding status note",
                    [Contract.TypeNames.RiskStatusUpdateNoteUpdateAuditEvent]: "Update finding status note",
                    [Contract.TypeNames.ScopeSettingCreationAuditEvent]: "Create scope setting configuration",
                    [Contract.TypeNames.ScopeSettingDeletionAuditEvent]: "Delete scope setting configuration",
                    [Contract.TypeNames.ScopeSettingUpdateAuditEvent]: "Update scope setting configuration",
                    [Contract.TypeNames.ServiceNowInstanceCreationAuditEvent]: "Add ServiceNow instance",
                    [Contract.TypeNames.ServiceNowInstanceDeletionAuditEvent]: "Remove ServiceNow instance",
                    [Contract.TypeNames.ServiceNowInstanceUpdateAuditEvent]: "Update ServiceNow instance details",
                    [Contract.TypeNames.SlackWorkspaceCreationAuditEvent]: "Add Slack workspace",
                    [Contract.TypeNames.SlackWorkspaceDeletionAuditEvent]: "Remove Slack workspace",
                    [Contract.TypeNames.SlackWorkspaceUpdateAuditEvent]: "Update Slack workspace",
                    [Contract.TypeNames.SplunkEventCollectorCreationAuditEvent]: "Add Splunk Event Collector",
                    [Contract.TypeNames.SplunkEventCollectorDeletionAuditEvent]: "Remove Splunk Event Collector",
                    [Contract.TypeNames.SplunkEventCollectorUpdateAuditEvent]: "Update Splunk Event Collector details",
                    [Contract.TypeNames.SqsQueueCreationAuditEvent]: "Add SQS queue",
                    [Contract.TypeNames.SqsQueueDeletionAuditEvent]: "Remove SQS queue",
                    [Contract.TypeNames.SqsQueueUpdateAuditEvent]: "Update SQS queue details",
                    [Contract.TypeNames.TeamsOrganizationCreationAuditEvent]: "Add Teams organization",
                    [Contract.TypeNames.TeamsOrganizationDeletionAuditEvent]: "Remove Teams organization",
                    [Contract.TypeNames.UserRoleAssignmentCreationAuditEvent]: "Add user role assignment",
                    [Contract.TypeNames.UserRoleAssignmentDeletionAuditEvent]: "Remove user role assignment",
                    [Contract.TypeNames.UserRoleAssignmentUpdateAuditEvent]: "Update user role assignment",
                    [Contract.TypeNames.VirtualMachinePriorityScanRequestUpdateAuditEvent]: "Request on-demand virtual machine scan",
                    [Contract.TypeNames.WebhookEndpointCreationAuditEvent]: "Add webhook endpoint",
                    [Contract.TypeNames.WebhookEndpointDeletionAuditEvent]: "Remove webhook endpoint",
                    [Contract.TypeNames.WebhookEndpointUpdateAuditEvent]: "Update webhook endpoint details",
                    [Contract.TypeNames.WorkloadAnalysisUpdateAuditEvent]: "Update workload protection"
                }
            }));

    return (typeName: string): string =>
        localization[Contract.TypeNames.AuditEvent].translate(typeName);
}