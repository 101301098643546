import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../../utilities";
import { ConsoleAccessSection, ContextSection } from "./components";

export function useAwsIamUserMfaDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsIamUserMfaDisabledRisk;
    const userModel = entityModelStore.useGet(risk.entityId) as Contract.AwsIamUserModel;
    const user = userModel.entity as Contract.AwsIamUser;

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsIamUserMfaDisabledRiskDefinition",
            () => ({
                description: "Multi-factor authentication is not enabled for the {{user}} with console access",
                sections: {
                    consoleAccess: "Console Access",
                    resolution: {
                        step1: "Under **Assigned MFA device** click **Manage**",
                        step2: "Complete the **Manage MFA device** wizard"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            user:
                <Entity
                    entityIdOrModel={userModel}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Iam,
                AwsConsoleUrlBuilder.getIamUserSecurityCredentialsUrl(user)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2()
        ],
        riskModel,
        undefined,
        {
            contextSectionElement:
                <ContextSection
                    risk={risk}
                    userModel={userModel}/>,
            sections: [
                new RiskDefinitionSection(
                    <ConsoleAccessSection password={risk.userPassword}/>,
                    localization.sections.consoleAccess())
            ]
        });
}