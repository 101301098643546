import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, NumberFormatter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonEc2ResourceDefinition, useAwsCommonEncryptedResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAwsResourceProductCodeTypeTranslator } from "../../../../../../../../../../tenants";
import { DefinitionData } from "../../../../Table";

export function useAwsEc2VolumeDefinition(definitionData: DefinitionData) {
    const commonEc2ResourceDefinition = useAwsCommonEc2ResourceDefinition(definitionData);
    const commonEncryptedResourceDefinition = useAwsCommonEncryptedResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const resourceProductCodeTypeTranslator = useAwsResourceProductCodeTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsEc2VolumeDefinition",
            () => ({
                columns: {
                    instanceIds: "Instances",
                    instanceTerminationDeletion: {
                        false: "No",
                        title: "Delete On Termination",
                        true: "Yes"
                    },
                    productCodeId: "Product Code Id",
                    productCodeType: "Product Code Type",
                    size: "Size"
                }
            }));

    function getInstanceTerminationDeletionTranslation(volumeModel: Contract.EntityModel) {
        return (volumeModel.entity as Contract.AwsEc2Volume).instanceTerminationDeletion
            ? localization.columns.instanceTerminationDeletion.true()
            : localization.columns.instanceTerminationDeletion.false();
    }

    return new EntityTableDefinition(
        _.filter([
            commonEc2ResourceDefinition.columns.rawIdColumn,
            commonEc2ResourceDefinition.columns.tenantColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsEc2VolumeModel) => ({
                            [localization.columns.size()]: NumberFormatter.storage((item.entity as Contract.AwsEc2Volume).size)
                        })
                }}
                id={Contract.EntityModelProperty.AwsEc2VolumeSize}
                itemProperty={(item: Contract.AwsResourceModel) => NumberFormatter.storage((item.entity as Contract.AwsEc2Volume).size)}
                key={Contract.EntityModelProperty.AwsEc2VolumeSize}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.size()}/>,
            commonEc2ResourceDefinition.columns.creationTimeColumn,
            commonEc2ResourceDefinition.columns.creatorIdentityCsvColumn,
            commonEc2ResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsEc2VolumeModel) => ({
                            [localization.columns.instanceTerminationDeletion.title()]: getInstanceTerminationDeletionTranslation(item)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.instanceTerminationDeletion.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.instanceTerminationDeletion.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.instanceTerminationDeletion.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AwsEc2VolumeInstanceTerminationDeletion}
                itemProperty={getInstanceTerminationDeletionTranslation}
                key={Contract.EntityModelProperty.AwsEc2VolumeInstanceTerminationDeletion}
                title={localization.columns.instanceTerminationDeletion.title()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsEc2VolumeModel>(
                        Contract.TypeNames.AwsEc2Instance,
                        item => (item as Contract.AwsEc2VolumeModel).instanceIds,
                        localization.columns.instanceIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsEc2VolumeInstances)}
                                placeholder={localization.columns.instanceIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsEc2VolumeInstances}
                key={Contract.EntityModelProperty.AwsEc2VolumeInstances}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsEc2VolumeModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.instanceIds}
                            entityTypeName={Contract.TypeNames.AwsEc2Instance}
                            entityVariant="iconText"/>}
                title={localization.columns.instanceIds()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsEc2VolumeModel) => ({
                            [localization.columns.productCodeType()]:
                                _.isNil((item.entity as Contract.AwsEc2Volume).productCode)
                                    ? ""
                                    : resourceProductCodeTypeTranslator((item.entity as Contract.AwsEc2Volume).productCode!.type)
                        })
                }}
                id="productCodeType"
                key="productCodeType"/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsEc2VolumeModel) => ({
                            [localization.columns.productCodeId()]:
                                _.isNil((item.entity as Contract.AwsEc2Volume).productCode)
                                    ? ""
                                    : (item.entity as Contract.AwsEc2Volume).productCode!.id
                        })
                }}
                id="productCodeId"
                key="productCodeId"/>,
            commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
            commonEncryptedResourceDefinition.columns.kmsEncryptionKeyColumn,
            commonEc2ResourceDefinition.columns.regionColumn,
            commonEc2ResourceDefinition.columns.regionLocationColumn,
            commonEc2ResourceDefinition.columns.tagsColumn,
            commonEc2ResourceDefinition.columns.attributesColumn,
            commonEc2ResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonEc2ResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonEc2ResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsEc2VolumeRequest(
                new Contract.EntityControllerGetEntityModelPageAwsEc2VolumeRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2ResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2VolumeInstances]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2VolumeInstanceTerminationDeletion])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceKmsEncryptionKeys]))));
}