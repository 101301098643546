import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useOciCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { useOciComputeInstancePoolStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useOciComputeInstancePoolDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useOciCommonResourceDefinition(definitionData);

    const entitiesExportOptions = useEntitiesExportOptions();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const instancePoolStatusTranslator = useOciComputeInstancePoolStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciComputeInstancePoolDefinition",
            () => ({
                columns: {
                    status: "Status"
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciComputeInstancePoolModel) => ({
                            [localization.columns.status()]: instancePoolStatusTranslator((item.entity as Contract.OciComputeInstancePool).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.OciComputeInstancePoolStatus}
                                enumTypeTranslator={instancePoolStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciComputeInstancePoolStatus}
                itemProperty={(item: Contract.OciComputeInstancePoolModel) => instancePoolStatusTranslator((item.entity as Contract.OciComputeInstancePool).status)}
                key={Contract.EntityModelProperty.OciComputeInstancePoolStatus}
                title={localization.columns.status()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciComputeInstancePoolModel) => ({
                            [entityTypeNameTranslator(Contract.TypeNames.OciComputeInstanceConfiguration)]: (item.entity as Contract.OciComputeInstancePool).instanceConfigurationOcid
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciComputeInstancePoolInstanceConfiguration)}
                                placeholder={entityTypeNameTranslator(Contract.TypeNames.OciComputeInstanceConfiguration)}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciComputeInstancePoolInstanceConfiguration}
                key={Contract.EntityModelProperty.OciComputeInstancePoolInstanceConfiguration}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OciComputeInstancePoolModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.instanceConfigurationIdReference}
                            entityTypeName={Contract.TypeNames.OciComputeInstanceConfiguration}/>}
                title={entityTypeNameTranslator(Contract.TypeNames.OciComputeInstanceConfiguration)}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.OciComputeInstancePoolModel>(
                        Contract.TypeNames.OciComputeInstance,
                        item => item.instanceIdReferences,
                        entityTypeNameTranslator(
                            Contract.TypeNames.OciComputeInstance,
                            { count: 0 }))}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciComputeInstancePoolInstances)}
                                placeholder={
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.OciComputeInstance,
                                        { count: 0 })}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciComputeInstancePoolInstances}
                key={Contract.EntityModelProperty.OciComputeInstancePoolInstances}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OciComputeInstancePoolModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.instanceIdReferences}
                            entityTypeName={Contract.TypeNames.OciComputeInstance}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={
                    entityTypeNameTranslator(
                        Contract.TypeNames.OciComputeInstance,
                        { count: 0 })}/>,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOciComputeInstancePoolRequest(
                new Contract.EntityControllerGetEntityModelPageOciComputeInstancePoolRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciComputeInstancePoolInstanceConfiguration]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciComputeInstancePoolInstances]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciComputeInstancePoolStatus])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}