import { Dialog, makeContextProvider } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { AddOrEdit, Table } from "./components";

export class KubernetesClusterConnectorsContext {
    constructor(
        public addOrEditOpen: boolean | Contract.ScopeSystemEntityModel) {
    }
}

export const [useKubernetesClusterConnectorsContext, useSetKubernetesClusterConnectorsContext, useKubernetesClusterConnectorsContextProvider] = makeContextProvider<KubernetesClusterConnectorsContext>();

export function KubernetesClusterConnectors() {
    const [context, setContext, ContextProvider] = useKubernetesClusterConnectorsContextProvider(() => new KubernetesClusterConnectorsContext(false));

    return (
        <ContextProvider>
            {context.addOrEditOpen !== false &&
                <Dialog
                    size="small"
                    variant="editor"
                    onClose={
                        () =>
                            setContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: false
                                })
                            )}>
                    <AddOrEdit/>
                </Dialog>}
            <Table/>
        </ContextProvider>);
}