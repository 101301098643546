import { UnexpectedError } from "@infrastructure";
import { Dictionary } from "lodash";
import { ReactNode, useMemo } from "react";
import { CustomRiskPolicyActions, RiskPoliciesType, RiskPoliciesView, RiskPolicyItem } from "../../../../../../../../common";
import { ConfigurationViewItem } from "../../../../../Configuration";
import { useCloudRiskPolicyDefinition, useCodeRiskPolicyDefinition, useKubernetesAdmissionControllerRiskPolicyDefinition } from "./hooks";

export function useDefinition(type: RiskPoliciesType, item: RiskPolicyItem, scopeId: string, view = RiskPoliciesView.BuiltIn): RiskPolicyConfigurationDefinition {
    const useDefinition =
        useMemo(
            () => {
                switch (type) {
                    case RiskPoliciesType.Cloud:
                        return useCloudRiskPolicyDefinition;
                    case RiskPoliciesType.Code:
                        return useCodeRiskPolicyDefinition;
                    case RiskPoliciesType.KubernetesAdmissionController:
                        return useKubernetesAdmissionControllerRiskPolicyDefinition;
                    default:
                        throw new UnexpectedError("useDefinition.type", type);
                }
            },
            [type]);

    return useDefinition(view, item, scopeId);
}

export interface RiskPolicyConfigurationDefinition {
    configurationCategoryTabToViewItemMap: Dictionary<ConfigurationViewItem>;
    customRiskPolicyActionsRef?: React.MutableRefObject<CustomRiskPolicyActions | undefined> | never;
    generalAdditionalItems: ReactNode[];
}