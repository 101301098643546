import { Sx } from "@infrastructure";
import { Grid2, Stack, SxProps, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { useTheme } from "../../../common";

type RegionItemProps = {
    displayName: string;
    icon: ReactNode;
    onClick: () => void;
    sx?: SxProps;
};

export function RegionItem({ displayName, icon, onClick, sx }: RegionItemProps) {
    const theme = useTheme();
    return (
        <Grid2 size={{ xs:1 }}>
            <Stack
                spacing={3}
                sx={
                    Sx.combine(
                        {
                            "&:hover": {
                                transform: "translateY(-5px)"
                            },
                            alignItems: "center",
                            background: theme.palette.background.paper,
                            border: theme.border.primary,
                            borderRadius: theme.spacing(0.75),
                            cursor: "pointer",
                            height: "145px",
                            justifyContent: "center",
                            transition: "0.3s",
                            width: "145px"
                        },
                        sx)}
                onClick={onClick}>
                {icon}
                <Typography
                    textAlign="center"
                    variant="h4">
                    {displayName}
                </Typography>
            </Stack>
        </Grid2>);
}