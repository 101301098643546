import cidr from "ip-cidr";
import { BigInteger } from "jsbn";
import _ from "lodash";

export class SubnetHelper {
    public static getIpCount(subnet: string): BigInteger {
        const { endIp, startIp } = SubnetHelper.getIpRange(subnet);
        return endIp.subtract(startIp).
            add(new BigInteger("1"));
    }

    public static getIpRange(subnet: string): { endIp: BigInteger; startIp: BigInteger } {
        const subnetCidr = new cidr(subnet);
        const [startIp, endIp] = subnetCidr.toRange({ type: "bigInteger" } as any) as [BigInteger, BigInteger];
        return { endIp, startIp };
    }

    public static getType(subnets: string[]): SubnetHelperType {
        let ipV4 = false;
        let ipV6 = false;

        for (const subnet of subnets) {
            if (subnet === "0.0.0.0/0") {
                ipV4 = true;
            } else if (subnet === "::/0") {
                ipV6 = true;
            } else {
                return SubnetHelperType.Custom;
            }

            if (ipV4 && ipV6) {
                return SubnetHelperType.Any;
            }
        }

        return ipV4
            ? SubnetHelperType.AnyIpV4
            : SubnetHelperType.AnyIpV6;
    }

    public static isSensitive(subnets: string[]) {
        const sensitiveCountThreshold = new BigInteger("65535");
        return _.some(
            subnets,
            subnet => SubnetHelper.getIpCount(subnet).compareTo(sensitiveCountThreshold) > 0);
    }

    public static isValid(subnet: string) {
        return new cidr(subnet).isValid();
    }
}

export enum SubnetHelperType {
    Any = "any",
    AnyIpV4 = "anyIpV4",
    AnyIpV6 = "anyIpV6",
    Custom = "custom"
}