﻿import { Message, Optional, useChangeEffect, useInputValidation, useLocalization } from "@infrastructure";
import { FormHelperText, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useRef } from "react";
import { PagedEntityMultiSelect, PagedEntityMultiSelectPage } from "../../../../../../../../../../common";
import { useAddOrEditContext, useSetAddOrEditContext } from "../../../../AddOrEdit";
import { GroupMembershipEligibilityData } from "../../utilities";

type GroupsProps = {
    getModelPage: (principalTenantId: string) => ((searchText: Optional<string>, skip: number, data?: any) => Promise<PagedEntityMultiSelectPage>);
};

export function Groups({ getModelPage }: GroupsProps) {
    const { add, permissionEligibilityData, upsertPermissionEligibilityExecuting } = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();
    const principalTenantId = permissionEligibilityData.principalTenantId;
    const groupIds = (permissionEligibilityData as GroupMembershipEligibilityData).groupIds;
    const groupIdsResetRef = useRef(add);

    const localization =
        useLocalization(
            "views.user.permissionEligibilities.addOrEdit.hooks.useDefinition.groupMembershipEligibility.groups",
            () => ({
                error: {
                    empty: "You must select at least one group"
                },
                placeholder: "Groups",
                title: "Groups",
                tooltip: "Groups that the selected users and groups can request to be part of."
            }));

    useChangeEffect(
        () => {
            groupIdsResetRef.current = true;
        },
        [principalTenantId]);

    const [groupIdsValidationController, groupIdsValidationMessage] =
        useInputValidation(
            () => {
                if (groupIdsResetRef.current) {
                    return undefined;
                }

                if (_.isEmpty(groupIds)) {
                    return localization.error.empty();
                }

                return undefined;
            },
            [groupIds]);

    useEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    fieldNameToValidMap: {
                        ...addOrEditContext.fieldNameToValidMap,
                        groups:
                            !groupIdsResetRef.current &&
                            groupIdsValidationController.isValid()
                    }
                }));
        },
        [groupIds]);

    return (
        <Stack spacing={1.5}>
            <Stack
                direction="row"
                spacing={1}>
                <Typography variant="h4">
                    {localization.title()}
                </Typography>
                <Message
                    level="info"
                    title={localization.tooltip()}
                    variant="minimal"/>
            </Stack>
            <PagedEntityMultiSelect
                disabled={
                    _.isNil(principalTenantId) ||
                    upsertPermissionEligibilityExecuting}
                fullWidth={true}
                getEntityModelPage={getModelPage(principalTenantId!)}
                placeholder={localization.placeholder()}
                selectedEntityIds={groupIds}
                onSelectedEntityIdsChanged={
                    groupIds => {
                        groupIdsResetRef.current = false;
                        setAddOrEditContext(
                            addOrEditContext => {
                                (addOrEditContext.permissionEligibilityData as GroupMembershipEligibilityData).groupIds = groupIds;
                                return { ...addOrEditContext };
                            });
                    }}/>
            {!_.isNil(groupIdsValidationMessage) && (
                <FormHelperText error={true}>
                    {groupIdsValidationMessage}
                </FormHelperText>)}
        </Stack>);
}