﻿import { ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React, { memo } from "react";
import { Contract, LicensingHelper, useIdentityRoleTranslator } from "../../common";

const IdentityRoleFilterMemo = memo(IdentityRoleFilter);
export { IdentityRoleFilterMemo as IdentityRoleFilter };

type IdentityRoleFilterProps = {
    placeholder: string;
};

function IdentityRoleFilter({ placeholder }: IdentityRoleFilterProps) {
    const identityRoleTranslator = useIdentityRoleTranslator();
    return (
        <ValuesFilter placeholder={placeholder}>
            {_(
                [
                    Contract.IdentityRole.Administrator,
                    Contract.IdentityRole.Collaborator,
                    Contract.IdentityRole.Owner,
                    Contract.IdentityRole.Viewer
                ]).
                concatIf(
                    LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.PermissionManagement),
                    Contract.IdentityRole.PermissionManagementAdministrator).
                map(
                    identityRole =>
                        <ValuesFilterItem
                            key={identityRole}
                            title={identityRoleTranslator(identityRole)}
                            value={identityRole}/>).
                value()}
        </ValuesFilter>);
}