import { DataTableActions, useChangeEffect } from "@infrastructure";
import _ from "lodash";
import React, { Fragment, useEffect, useMemo, useRef } from "react";
import { RiskPolicySelectItem, RiskPolicyTableProps } from "../RiskPolicyTable";
import { Table } from "./Table";

export type ViewProps =
    Pick<RiskPolicyTableProps, | "onSelectChange" | "registerClearSelected" | "selectedRiskPoliciesIds" | "riskPolicyIds"> & {
        items: RiskPolicySelectItem[];
    };

export function View({ items, onSelectChange, registerClearSelected, riskPolicyIds, selectedRiskPoliciesIds }: ViewProps) {
    const dataTableActionsRef = useRef<DataTableActions>();

    const selectedItems =
        useMemo(
            () =>
                _.filter(
                    items,
                    policy =>
                        _.includes(
                            riskPolicyIds,
                            policy.id)),
            [items, riskPolicyIds]);

    useEffect(
        () => {
            if(!_.isNil(selectedRiskPoliciesIds)) {
                dataTableActionsRef.current?.setSelectedItemIds(selectedRiskPoliciesIds);
            }
        },
        []);

    useEffect(
        () => {
            const unregister =
                registerClearSelected?.(
                    () => {
                        dataTableActionsRef.current?.setSelectedItemIds([]);
                    });
            return unregister;
        },
        []);

    useChangeEffect(
        () => {
            dataTableActionsRef.current?.reload({ refreshColumns: true });
        },
        [selectedItems]);

    return (
        _.size(selectedItems) === 0
            ? <Fragment/>
            : <Table
                dataTableOptions={{
                    actionsRef: dataTableActionsRef,
                    variant: "card"
                }}
                items={selectedItems}
                variant="view"
                onSelectChange={onSelectChange}/>);
}