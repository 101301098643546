import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { RiskTypeGroups } from "../../../../../../../../../../../../common";
import { GroupedDefinition } from "../../useDefinition";
import { useRiskPolicyTypeDefinition, useRiskTenantDefinition } from "./hooks";


export function useCloudDefinition(groupingType: RiskTypeGroups): GroupedDefinition {
    const useDefinition =
        useMemo(
            () => {
                switch (groupingType) {
                    case RiskTypeGroups.RiskPolicyType:
                        return useRiskPolicyTypeDefinition;
                    case RiskTypeGroups.Tenant:
                        return useRiskTenantDefinition;
                    default:
                        throw new UnexpectedError("groupingType", groupingType);
                }
            },
            [groupingType]);

    return useDefinition();
}