import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function PrincipalsIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M11.9999 3C9.79076 3 7.9999 4.79086 7.9999 7C7.9999 9.20914 9.79076 11 11.9999 11C14.209 11 15.9999 9.20914 15.9999 7C15.9999 4.79086 14.209 3 11.9999 3ZM5.9999 7C5.9999 3.68629 8.68619 1 11.9999 1C15.3136 1 17.9999 3.68629 17.9999 7C17.9999 10.3137 15.3136 13 11.9999 13C8.68619 13 5.9999 10.3137 5.9999 7Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M8.99999 16.9992C6.23857 16.9992 4 19.2378 4 21.9992C4 22.5515 3.55228 22.9992 3 22.9992C2.44772 22.9992 2 22.5515 2 21.9992C2 18.1332 5.134 14.9992 8.99999 14.9992H15C18.866 14.9992 22 18.1332 22 21.9992C22 22.5515 21.5523 22.9992 21 22.9992C20.4477 22.9992 20 22.5515 20 21.9992C20 19.2378 17.7614 16.9992 15 16.9992H8.99999Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}