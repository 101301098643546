import { FormLayout, makeContextProvider, OrderedWizard, OrderedWizardItem, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useSetSqsContext, useSqsContext } from "../..";
import { Contract } from "../../../../../../../../../../../../../../common";
import { FinishItem, QueueInfoItem } from "./components";

export class AddOrEditContext {
    public queueName: string;
    public queueRoleArn?: string;
    public queueUrl: string;

    constructor(public queueModel?: Contract.ScopeSystemEntityModel) {
        this.queueName = (queueModel?.configuration as Contract.SqsQueueConfiguration)?.name ?? "";
        this.queueRoleArn = (queueModel?.configuration as Contract.SqsQueueConfiguration)?.roleArn;
        this.queueUrl = (queueModel?.configuration as Contract.SqsQueueConfiguration)?.url ?? "";
    }
}

export const [useAddOrEditContext, useSetAddOrEditContext, useAddOrEditContextProvider] = makeContextProvider<AddOrEditContext>();

export function AddOrEdit() {
    const { addOrEditOpen } = useSqsContext();
    const setSqsContext = useSetSqsContext();
    const [, , ContextProvider] =
        useAddOrEditContextProvider(
            () =>
                new AddOrEditContext(
                    _.isBoolean(addOrEditOpen)
                        ? undefined
                        : addOrEditOpen));

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useObservabilityItems.sqs.addOrEdit",
            () => ({
                steps: {
                    queueInfo: "Provide SQS Queue Info"
                },
                title: {
                    add: "Add SQS Queue",
                    edit: "Edit SQS Queue"
                }
            }));
    return (
        <ContextProvider>
            <FormLayout
                disableContentPadding={true}
                titleOptions={{
                    text:
                        _.isBoolean(addOrEditOpen)
                            ? localization.title.add()
                            : localization.title.edit()
                }}>
                <OrderedWizard
                    finishItemElement={<FinishItem/>}
                    onClose={
                        () =>
                            setSqsContext(
                                sqsContext => ({
                                    ...sqsContext,
                                    addOrEditOpen: false
                                }))}>
                    <OrderedWizardItem
                        deferredLoading={true}
                        title={localization.steps.queueInfo()}>
                        <QueueInfoItem/>
                    </OrderedWizardItem>
                </OrderedWizard>
            </FormLayout>
        </ContextProvider>);
}