import { Action1, defined, getChildElements, Resizer } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import _ from "lodash";
import React, { Children, Fragment, FunctionComponentElement, ReactNode, useEffect, useLayoutEffect, useRef, useState } from "react";
import { ContentPage } from ".";
import { useTheme } from "../../../../../common";

export type ContentPagesProps = {
    children: ReactNode;
    number: number;
    onPageCountChanged?: Action1<number>;
};

export function ContentPages({ children, number, onPageCountChanged }: ContentPagesProps) {
    const items = getChildElements(Children.toArray(children), ContentPagesItem);

    const gridElementsRef = useRef<any[]>([]);
    const [itemChunks, setItemChunks] = useState<FunctionComponentElement<ContentPagesItemProps>[][]>([]);
    const theme = useTheme();
    const [fontsLoaded, setFontsLoaded] = useState(false);
    const [contentReady, setContentReady] = useState(false);

    useLayoutEffect(
        () => {
            window.document?.fonts.ready.then(() => setFontsLoaded(true));
        },
        []);

    useEffect(() => updateItemChunks(), [fontsLoaded]);

    function updateItemChunks() {
        if (!fontsLoaded) {
            return;
        }

        const gridElements = _.filter(gridElementsRef.current);
        if (_.isEmpty(gridElements)) {
            return;
        }

        const itemHeights =
            _.map(
                gridElements,
                gridElement => gridElement.getBoundingClientRect().height);

        const itemChunks: FunctionComponentElement<ContentPagesItemProps>[][] = [];
        let itemChunk: FunctionComponentElement<ContentPagesItemProps>[] = [];
        let itemChunkHeight = 0;
        _.each(
            items,
            (item, itemIndex) => {
                if (itemChunkHeight + itemHeights[itemIndex] + (item.props.paddingTop ?? 0) > 950) {
                    itemChunks.push(itemChunk);
                    itemChunk = [];
                    itemChunkHeight = 0;
                }

                itemChunk.push(item);
                itemChunkHeight += itemHeights[itemIndex] + (item.props.paddingTop ?? 0);
            });

        if (!_.isEmpty(itemChunk)) {
            itemChunks.push(itemChunk);
        }

        setItemChunks(itemChunks);
        onPageCountChanged?.(itemChunks.length);
        setContentReady(true);
    }

    return (
        <Fragment>
            {!contentReady &&
                <Stack
                    style={{
                        padding: theme.spacing(5, 3, 3),
                        position: "relative",
                        width: "21.15cm"
                    }}>
                    <Resizer onSize={() => updateItemChunks()}/>
                    {_.map(
                        items,
                        (item, itemIndex) =>
                            <Box
                                key={itemIndex}
                                ref={gridElement => gridElementsRef.current[itemIndex] = gridElement}>
                                {_.isFunction(item.props.children)
                                    ? item.props.children({
                                        pageFirstItem: false,
                                        pageLastItem: false
                                    })
                                    : item.props.children}
                            </Box>)}
                </Stack>}
            {!_.isEmpty(itemChunks) && (
                _.map(
                    itemChunks,
                    (itemChunk, itemChunkIndex) =>
                        <ContentPage
                            key={itemChunkIndex}
                            number={number + itemChunkIndex}
                            spacing={0}>
                            {_.map(
                                itemChunk,
                                (item, itemIndex) =>
                                    <Box
                                        key={itemIndex}
                                        style={{
                                            paddingTop:
                                                itemIndex != 0
                                                    ? item.props.paddingTop
                                                    : 0
                                        }}>
                                        {_.isFunction(item.props.children)
                                            ? item.props.children({
                                                pageFirstItem: itemIndex === 0,
                                                pageLastItem: itemIndex === itemChunk.length - 1
                                            })
                                            : item.props.children}
                                    </Box>)}
                        </ContentPage>))}
        </Fragment>);
}

type ContentPagesItemRenderData = {
    pageFirstItem: boolean;
    pageLastItem: boolean;
};

type ContentPagesItemProps = {
    children: ReactNode | ((data: ContentPagesItemRenderData) => ReactNode);
    paddingTop?: number;
};

export function ContentPagesItem(props: ContentPagesItemProps) {
    defined(props);
    return <Fragment/>;
}