import { useExecuteOperation } from "@infrastructure";
import { Function0 } from "lodash";
import { ComponentType } from "react";
import { makeContextProvider } from ".";

export function makeOperationContextProvider<TOperationContext>(): [
    Function0<TOperationContext>,
    Function0<Function0<Promise<void>>>,
    (key: any | [any, any], operation: Function0<Promise<TOperationContext>>) => [TOperationContext, ComponentType<any>]
] {
    const [useContext, , useContextProvider] = makeContextProvider<OperationContextProvider<TOperationContext>>();

    const useOperationContext = () => useContext().operationResult;

    const useRefreshOperation = () => useContext().refreshOperation;

    const useOperationContextProvider =
        (key: any | [any, any], operation: Function0<Promise<TOperationContext>>): [TOperationContext, ComponentType<any>] => {
            const [operationResult, refreshOperation] = useExecuteOperation(key, operation);
            const [, , ContextProvider] =
                useContextProvider(
                    () => ({
                        operationResult,
                        refreshOperation
                    }),
                    [operationResult]);

            return [operationResult, ContextProvider];
        };

    return [useOperationContext, useRefreshOperation, useOperationContextProvider];
}

type OperationContextProvider<TOperationResult> = {
    operationResult: TOperationResult;
    refreshOperation: Function0<Promise<void>>;
};