import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { Contract, entityModelStore, tenantModelStore, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetRiskPolicyConfigurationPasswordRequirementContextItem } from "../../../useGetRiskPolicyConfigurationPasswordRequirementContextItem";
import { useGetAwsTenantEntityRiskContext } from "../contexts";

export function useAwsIamPasswordReusePolicyRiskDefinition(riskModel: Contract.RiskModel) {
    const tenantConfiguration = tenantModelStore.useGet(riskModel.tenantId).configuration as Contract.AwsTenantConfiguration;
    const risk = riskModel.risk as Contract.AwsIamPasswordReusePolicyRisk;
    const tenantEntityModel = entityModelStore.useGet(risk.entityId) as Contract.AwsTenantEntityModel;
    const tenantEntity = tenantEntityModel.entity as Contract.AwsTenantEntity;

    const getAwsTenantEntityRiskContext = useGetAwsTenantEntityRiskContext();
    const getRiskPolicyConfigurationPasswordRequirementContextItem = useGetRiskPolicyConfigurationPasswordRequirementContextItem();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsIamPasswordReusePolicyRiskDefinition",
            () => ({
                contextItems: {
                    passwordPolicyPreventReusePreviousPasswordCount: {
                        entity: {
                            empty: "The {{translatedTenantEntityTypeName}}'s password reuse policy is not set",
                            text: "The {{translatedTenantEntityTypeName}}'s password reuse policy is currently set to prevent the reuse of {{passwordPolicyPreventReusePreviousPasswordCount}} previous passwords"
                        },
                        riskPolicyConfiguration: "required minimum reuse count of **{{value}} passwords**"
                    }
                },
                description: "Password reuse policy should prevent the reuse of {{passwordPolicyPreventReusePreviousPasswordCount}} previous passwords",
                sections: {
                    resolution: {
                        step1: "In the left navigation pane, select **Account Settings**",
                        step2: "In the **Password policy** section, click **Edit**",
                        step3: "Under **Other requirements**, check the **Prevent password reuse** option and set the value to at least **{{passwordPolicyPreventReusePreviousPasswordCount}}**",
                        step4: "Click **Save Changes**"
                    }
                }
            }));
    const translatedTenantEntityTypeName =
        entityTypeNameTranslator(
            tenantEntityModel.entity.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({ passwordPolicyPreventReusePreviousPasswordCount: risk.passwordPolicyPreventReusePreviousPasswordCount }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Iam,
                AwsConsoleUrlBuilder.getIamUrl(tenantConfiguration.partitionType)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3({ passwordPolicyPreventReusePreviousPasswordCount: risk.passwordPolicyPreventReusePreviousPasswordCount }),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const tenantEntityRiskContext = getAwsTenantEntityRiskContext(tenantEntityModel);
            return [
                tenantEntityRiskContext.generalInformation,
                getRiskPolicyConfigurationPasswordRequirementContextItem(localization.contextItems.passwordPolicyPreventReusePreviousPasswordCount.riskPolicyConfiguration({ value: risk.passwordPolicyPreventReusePreviousPasswordCount })),
                new RiskDefinitionContextItem(
                    _.isNil(tenantEntity.iam?.passwordPolicy?.preventReusePreviousPasswordCount)
                        ? localization.contextItems.passwordPolicyPreventReusePreviousPasswordCount.entity.empty({ translatedTenantEntityTypeName })
                        : localization.contextItems.passwordPolicyPreventReusePreviousPasswordCount.entity.text({
                            passwordPolicyPreventReusePreviousPasswordCount: tenantEntity.iam!.passwordPolicy!.preventReusePreviousPasswordCount,
                            translatedTenantEntityTypeName
                        })),
                tenantEntityRiskContext.sensitive,
                tenantEntityRiskContext.accountStatus,
                tenantEntityRiskContext.path,
                tenantEntityRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}