import { useLocalization } from "@infrastructure";
import React from "react";
import { useAwsCommonExcessivePermissionResourceRiskDefinition } from ".";
import { Contract, entityModelStore } from "../../../../../../../../../../../../common";
import { RiskDefinitionSection, RiskDefinitionSectionGroup } from "../../../../../../utilities";
import { PolicySection } from "../../components";
import { useGetAwsEcrRepositoryRiskContext } from "../contexts";

export function useAwsExcessivePermissionRepositoryRiskDefinition(riskModel: Contract.RiskModel) {
    const excessivePermissionRepositoryRiskModel = riskModel as Contract.AwsExcessivePermissionRepositoryRiskModel;
    const repositoryModel = entityModelStore.useGet(excessivePermissionRepositoryRiskModel.risk.entityId) as Contract.AwsEcrRepositoryModel;
    const repository = repositoryModel.entity as Contract.AwsEcrRepository;

    const getAwsEcrRepositoryRiskContext = useGetAwsEcrRepositoryRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.access.useAwsExcessivePermissionRepositoryRiskDefinition",
            () => ({
                sections: {
                    policyDocument: "Repository Policy"
                }
            }));
    return useAwsCommonExcessivePermissionResourceRiskDefinition(
        () => {
            const repositoryRiskContext = getAwsEcrRepositoryRiskContext(repositoryModel);
            return [
                repositoryRiskContext.generalInformation,
                repositoryRiskContext.containerImages,
                repositoryRiskContext.criticalVulnerabilityContainerImages,
                repositoryRiskContext.sensitive
            ];
        },
        riskModel,
        [
            new RiskDefinitionSectionGroup(
                [
                    new RiskDefinitionSection(
                        <PolicySection policyDocument={repository.policyDocument}/>,
                        localization.sections.policyDocument(),
                        {
                            expandable: true
                        })
                ])
        ]);
}