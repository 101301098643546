import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useGcpSqlInstancePostgreSqlLogLevelTranslator } from "../../../../../../../../../Entities/hooks";
import { EntityExternalConsoleLink } from "../../components";
import { useGetGcpSqlInstanceRiskContext } from "../contexts";

export function useGcpSqlInstancePostgreSqlLogMinLevelRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpSqlInstancePostgreSqlLogMinLevelRisk;
    const instanceModel = entityModelStore.useGet(risk.entityId) as Contract.GcpSqlInstanceModel;
    const instance = instanceModel.entity as Contract.GcpSqlInstance;

    const getGcpSqlInstanceRiskContext = useGetGcpSqlInstanceRiskContext();
    const postgreSqlLogLevelTranslator = useGcpSqlInstancePostgreSqlLogLevelTranslator();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpSqlInstancePostgreSqlLogMinLevelRiskDefinition",
            () => ({
                description: "{{instance}} ‘log_min_messages’ flag is set to {{logMinLevel}}",
                sections: {
                    resolution: {
                        step1: "Under **Flags**, set the flag **log_min_messages** to **Warning** or stricter and click on **Done**",
                        step2: "Click **SAVE** at the bottom to confirm"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            instance:
                <Entity
                    entityIdOrModel={instanceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            logMinLevel: postgreSqlLogLevelTranslator(instance.postgreSqlLogMinLevel!)
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.Edit}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2()
        ],
        riskModel,
        () => {
            const instanceRiskContext = getGcpSqlInstanceRiskContext(instanceModel);
            return [
                instanceRiskContext.generalInformation,
                instanceRiskContext.sensitive,
                instanceRiskContext.inboundExternalAccessScope,
                instanceRiskContext.status,
                instanceRiskContext.databaseVersion,
                instanceRiskContext.getFlagContextItem(
                    "log_min_error_statement",
                    postgreSqlLogLevelTranslator(instance.postgreSqlLogMinLevel!)),
                instanceRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}