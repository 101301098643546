import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function JupiterOneIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58343_2643)">
                <path
                    clipRule="evenodd"
                    d="M2 11.92C2 12.16 2.02 12.42 2.04 12.66H5C5.96 12.66 6.94 12.66 7.84 12.42C9.16 12.08 10.34 11.32 11.56 10.52L11.6 10.5C12 10.2 12.44 9.98 12.92 9.84C13.52 9.68 14.14 9.62 14.76 9.62H21.6C21.38 8.68 21.04 7.78 20.54 6.96H3.32C2.46 8.46 2 10.18 2 11.92ZM19.46 18.36C15.9 22.52 9.64 23.04 5.48 19.46C5.08 19.12 4.72 18.74 4.38 18.36H19.46ZM18.3 14.08C18.3 15.04 17.52 15.82 16.56 15.82C15.6 15.82 14.82 15.04 14.82 14.08C14.82 13.12 15.6 12.34 16.56 12.34C17.52 12.34 18.3 13.14 18.3 14.08ZM21.8 11.1H14.74C14.26 11.08 13.76 11.14 13.32 11.24C12.98 11.36 12.68 11.52 12.4 11.72L12.36 11.74C11.1 12.56 9.78 13.42 8.22 13.84C7.24 14.1 6.26 14.12 5.3 14.12H2.26C2.48 15.08 2.84 16 3.34 16.86H20.52C21.4 15.36 21.86 13.64 21.84 11.9C21.84 11.66 21.82 11.42 21.8 11.16V11.1ZM5.48 4.38C9.66 0.82 15.9 1.32 19.46 5.48H4.38C4.72 5.08 5.08 4.7 5.48 4.38ZM12 24C18.62 24 24 18.62 24 12C24 5.38 18.62 0 12 0C5.38 0 0 5.38 0 12C0 18.62 5.38 24 12 24Z"
                    fill="#00BFA5"
                    fillRule="evenodd"/>
            </g>
            <defs>
                <clipPath id="clip0_58343_2643">
                    <rect
                        fill="white"
                        height="24"
                        width="23.9962"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}