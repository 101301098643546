import { NumberFormatter, useLocalization } from "@infrastructure";
import React from "react";
import { useGcpCommonEncryptedResourceInfoElements, useGcpDefaultResourceInfoItemElements, useGcpDefaultScopeResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useGcpComputeSnapshotCreationTypeTranslator, useGcpComputeSnapshotStatusTranslator, useGcpComputeSnapshotTypeTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { GcpScopeResourceRoleBindings } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useGcpComputeSnapshotDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const commonEncryptedResourceInfoElements = useGcpCommonEncryptedResourceInfoElements(scopeResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultScopeResourceInfoItemElements = useGcpDefaultScopeResourceInfoItemElements(scopeResourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const snapshotModel = scopeResourceModel as Contract.GcpComputeSnapshotModel;
    const snapshot = snapshotModel.entity as Contract.GcpComputeSnapshot;

    const computeSnapshotCreationTypeTranslator = useGcpComputeSnapshotCreationTypeTranslator();
    const computeSnapshotStatusTranslator = useGcpComputeSnapshotStatusTranslator();
    const computeSnapshotTypeTranslator = useGcpComputeSnapshotTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpComputeSnapshotDefinition",
            () => ({
                info: {
                    items: {
                        creationType: "Creation Type",
                        instanceIdReferences: "VM Instances",
                        size: "Size",
                        sourceDiskIdReference: "Source",
                        status: "Status",
                        type: "Type"
                    }
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GcpScopeResourceRoleBindings
                    csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                    scopeResourceModel={scopeResourceModel}/>,
                localization.tabs.resourceRoleBindings(),
                "resourceRoleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...defaultScopeResourceInfoItemElements,
                    commonEncryptedResourceInfoElements.encryptionExists,
                    commonEncryptedResourceInfoElements.kmsEncryptionKeys,
                    <InfoItem
                        key="status"
                        location="all"
                        title={localization.info.items.status()}
                        value={computeSnapshotStatusTranslator(snapshot.status)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={snapshotModel.sourceDiskIdReference}
                        entityTypeName={Contract.TypeNames.GcpComputeDisk}
                        key="sourceDiskIdReference"
                        location="all"
                        title={localization.info.items.sourceDiskIdReference()}/>,
                    <InfoItem
                        key="type"
                        location="profile"
                        title={localization.info.items.type()}
                        value={computeSnapshotTypeTranslator(snapshot.type)}/>,
                    <InfoItem
                        key="size"
                        location="profile"
                        title={localization.info.items.size()}
                        value={NumberFormatter.storage(snapshot.size)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={snapshotModel.instanceIdReferences}
                        entityTypeName={Contract.TypeNames.GcpComputeInstance}
                        key="instanceIdReferences"
                        location="all"
                        title={localization.info.items.instanceIdReferences()}/>,
                    <InfoItem
                        key="creationType"
                        location="profile"
                        title={localization.info.items.creationType()}
                        value={computeSnapshotCreationTypeTranslator(snapshot.creationType)}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}