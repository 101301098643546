import { useCallback, useLayoutEffect, useRef } from "react";
import { Optional } from "../types";

export function useEffectEvent<Callback extends (...args: any[]) => any>(callback: Optional<Callback>) {
    const callbackRef = useRef(callback);

    useLayoutEffect(
        () => {
            callbackRef.current = callback;
        });

    return useCallback(
        ((...args) => callbackRef.current?.(...args)) as Callback,
        []);
}