import { Divider, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { DataTableColumnRenderProps, IconText, map, TimeFormatter, useLocalization } from "@infrastructure";
import { ActivePermissionRequestIcon, Contract, PendingPermissionRequestIcon, PermissionManagementHelper, PermissionRequestCloseReasonIcon, usePermissionRequestCloseReasonTranslator, useTheme } from "../../../../../../../../../../../common";
import { usePermissionRequestActivationFailureDataTranslator } from "../../../../../../../../../hooks";
import { useTableContext } from "../../../../../Table";

export function StatusCell({ item }: DataTableColumnRenderProps<Contract.PermissionRequestModel>) {
    const { entityModelMap } = useTableContext();

    const permissionRequestCloseReasonTranslator = usePermissionRequestCloseReasonTranslator();
    const permissionRequestActivationFailureDataTranslator = usePermissionRequestActivationFailureDataTranslator();
    const localization =
        useLocalization(
            "views.user.granteeUserPermissionRequests.table.hooks.useDefinition.hooks.useCommonColumns.statusCell",
            () => ({
                permissionRequestStatus: {
                    active: "Active",
                    pendingActivation: "Pending activation",
                    pendingApproval: "Pending approval"
                }
            }));
    const theme = useTheme();
    return (
        <IconText
            icon={
                item.permissionRequest.status === Contract.PermissionRequestStatus.Closed
                    ? <PermissionRequestCloseReasonIcon closeReason={item.permissionRequest.closeReason!}/>
                    : item.permissionRequest.status === Contract.PermissionRequestStatus.Active
                        ? <ActivePermissionRequestIcon/>
                        : <PendingPermissionRequestIcon/>}
            iconSx={{
                color:
                    map(
                        item.permissionRequest.status,
                        {
                            [Contract.PermissionRequestStatus.Active]: () =>
                                _.isNil(item.activationFailureData)
                                    ? PermissionManagementHelper.approvedPermissionRequestColor
                                    : theme.palette.warning.main,
                            [Contract.PermissionRequestStatus.Closed]: () =>
                                PermissionManagementHelper.getPermissionRequestCloseReasonColor(item.permissionRequest.closeReason!),
                            [Contract.PermissionRequestStatus.PendingActivation]: () =>
                                _.isNil(item.activationFailureData)
                                    ? PermissionManagementHelper.pendingPermissionRequestColor
                                    : theme.palette.warning.main,
                            [Contract.PermissionRequestStatus.PendingApproval]: () =>
                                PermissionManagementHelper.pendingPermissionRequestColor
                        }),
                fontSize: "18px"
            }}
            text={
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}
                    sx={{ overflow: "hidden" }}>
                    <Typography noWrap={true}>
                        {item.permissionRequest.status === Contract.PermissionRequestStatus.Closed
                            ? permissionRequestCloseReasonTranslator(item.permissionRequest.closeReason!)
                            : item.permissionRequest.status === Contract.PermissionRequestStatus.Active
                                ? localization.permissionRequestStatus.active()
                                : item.permissionRequest.status === Contract.PermissionRequestStatus.PendingActivation
                                    ? localization.permissionRequestStatus.pendingActivation()
                                    : localization.permissionRequestStatus.pendingApproval()}
                    </Typography>
                    <Divider
                        flexItem={true}
                        orientation="vertical"/>
                    <Typography
                        noWrap={true}
                        sx={{
                            color: theme.palette.text.secondary,
                            fontSize: "10px"
                        }}>
                        {TimeFormatter.longDateTime(
                            item.permissionRequest.closeReason === Contract.PermissionRequestCloseReason.Expired ||
                            item.permissionRequest.closeReason === Contract.PermissionRequestCloseReason.Revoked
                                ? item.permissionRequest.expirationTime!
                                : item.permissionRequest.statusUpdateTime)}
                    </Typography>
                </Stack>}
            titleOrGetTitle={
                (item.permissionRequest.status === Contract.PermissionRequestStatus.PendingActivation ||
                    item.permissionRequest.status === Contract.PermissionRequestStatus.Active ||
                    item.permissionRequest.closeReason === Contract.PermissionRequestCloseReason.ActivationFailed ||
                    item.permissionRequest.closeReason === Contract.PermissionRequestCloseReason.Expired ||
                    item.permissionRequest.closeReason === Contract.PermissionRequestCloseReason.Revoked) &&
                !_.isNil(item.activationFailureData)
                    ? <Typography sx={{ whiteSpace: "pre-wrap" }}>
                        {permissionRequestActivationFailureDataTranslator(
                            item.activationFailureData,
                            entityModelMap)}
                    </Typography>
                    : undefined}
            tooltipVariant="icon"/>);
}