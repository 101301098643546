import { Dictionary } from "lodash";
import { useAwsCommonKubernetesResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { useCommonKubernetesIamResourceDefinition } from "..";
import { Contract, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAwsKubernetesClusterRoleDefinition(definitionData: DefinitionData) {
    const commonKubernetesIamResourceDefinition = useCommonKubernetesIamResourceDefinition(definitionData);
    const commonKubernetesResourceDefinition = useAwsCommonKubernetesResourceDefinition(definitionData);

    return new EntityTableDefinition(
        [
            commonKubernetesResourceDefinition.columns.clusterColumn,
            commonKubernetesResourceDefinition.columns.tenantColumn,
            commonKubernetesResourceDefinition.columns.creationTimeColumn,
            commonKubernetesIamResourceDefinition.columns.getPrincipalIdReferencesColumn(Contract.EntityModelProperty.KubernetesRolePrincipals),
            commonKubernetesIamResourceDefinition.columns.getRoleBindingIdsColumn(Contract.EntityModelProperty.KubernetesRoleRoleBindings),
            commonKubernetesResourceDefinition.columns.tagsColumn,
            commonKubernetesResourceDefinition.columns.attributesColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsKubernetesClusterRoleRequest(
                new Contract.EntityControllerGetEntityModelPageAwsKubernetesClusterRoleRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestKubernetesResource(ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesResourceCluster])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestKubernetesRole(ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.KubernetesRolePrincipals]))));
}