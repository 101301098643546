import { FormLayout, makeContextProvider, OrderedWizard, OrderedWizardItem, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useSetSplunkContext, useSplunkContext } from "../..";
import { Contract } from "../../../../../../../../../../../../../../common";
import { EventCollectorInfoItem, EventCollectorSetupItem, FinishItem } from "./components";

export class AddOrEditContext {
    public eventCollectorEndpointConnectorId?: string;
    public eventCollectorName: string;
    public eventCollectorServerCertificateValidationEnabled: boolean;
    public eventCollectorToken?: string;
    public eventCollectorUrl?: string;

    constructor(public eventCollectorModel?: Contract.ScopeSystemEntityModel) {
        const configuration = eventCollectorModel?.configuration as Contract.SplunkEventCollectorConfiguration;

        this.eventCollectorEndpointConnectorId = configuration?.endpointConnectorId;
        this.eventCollectorName = configuration?.name ?? "";
        this.eventCollectorServerCertificateValidationEnabled = configuration?.serverCertificateValidationEnabled ?? true;
        this.eventCollectorToken =
            _.isNil(eventCollectorModel)
                ? ""
                : undefined;
        this.eventCollectorUrl = configuration?.url;
    }
}

export const [useAddOrEditContext, useSetAddOrEditContext, useAddOrEditContextProvider] = makeContextProvider<AddOrEditContext>();

export function AddOrEdit() {
    const { addOrEditOpen } = useSplunkContext();
    const setSplunkContext = useSetSplunkContext();
    const [{ eventCollectorModel }, , ContextProvider] =
        useAddOrEditContextProvider(
            () =>
                new AddOrEditContext(
                    _.isBoolean(addOrEditOpen)
                        ? undefined
                        : addOrEditOpen));

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useObservabilityItems.splunk.addOrEdit",
            () => ({
                steps: {
                    eventCollectorInfo: "Provide Splunk HTTP Event Collector Info",
                    eventCollectorSetup: "Set up Splunk HTTP Event Collector (HEC)"
                },
                title: {
                    add: "Add Splunk Instance",
                    edit: "Edit Splunk Instance"
                }
            }));
    return (
        <ContextProvider>
            <FormLayout
                disableContentPadding={true}
                titleOptions={{
                    text:
                        _.isBoolean(addOrEditOpen)
                            ? localization.title.add()
                            : localization.title.edit()
                }}>
                <OrderedWizard
                    finishItemElement={<FinishItem/>}
                    startItemIndex={
                        _.isNil(eventCollectorModel)
                            ? 0
                            : 1}
                    onClose={
                        () =>
                            setSplunkContext(
                                splunkContext => ({
                                    ...splunkContext,
                                    addOrEditOpen: false
                                }))}>
                    <OrderedWizardItem title={localization.steps.eventCollectorSetup()}>
                        <EventCollectorSetupItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem
                        deferredLoading={true}
                        title={localization.steps.eventCollectorInfo()}>
                        <EventCollectorInfoItem/>
                    </OrderedWizardItem>
                </OrderedWizard>
            </FormLayout>
        </ContextProvider>);
}