﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../controllers";
import { Entity } from "../../../../../../Entity";

export function useAwsDeleteSecurityGroupReferencingSecurityGroupRulesChangeDefinition(change: Contract.AwsChange, entityLinkDisabled?: boolean) {
    const referencedSecurityGroupChange = change as Contract.AwsDeleteSecurityGroupReferencingSecurityGroupRulesChange;
    const localization =
        useLocalization(
            "common.change.hooks.useDefinition.hooks.useAwsDefinition.hooks.useAwsDeleteSecurityGroupReferencingSecurityGroupRulesChangeDefinition",
            () => ({
                title: "Delete the referencing rules from the {{securityGroup}}"
            }));
    return {
        title:
            localization.title({
                securityGroup:
                    <Entity
                        entityIdOrModel={referencedSecurityGroupChange.resourceId}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: entityLinkDisabled }}
                        variant="typeText"/>
            })
    };
}