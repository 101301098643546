import { DataTableColumn, EnumValuesFilter, optionalTableCell, PagedValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { useAadTenantEntityExternalUserDefaultRoleTranslator, useAadTenantEntityExternalUserInvitationAllowedRolesTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { useAadCommonDirectoryEntityDefinition } from "./useAadCommonDirectoryEntityDefinition";

export function useAadTenantEntityDefinition(definitionData: DefinitionData) {
    const commonDirectoryEntityDefinition = useAadCommonDirectoryEntityDefinition(definitionData);

    const externalUserDefaultRoleTranslator = useAadTenantEntityExternalUserDefaultRoleTranslator();
    const externalUserInvitationAllowedRolesTranslator = useAadTenantEntityExternalUserInvitationAllowedRolesTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aad.useAadTenantEntityDefinition",
            () => ({
                columns: {
                    defaultUserRoleApplicationCreationEnabled: {
                        false: "Disabled",
                        title: "User Can Register Applications",
                        true: "Enabled"
                    },
                    defaultUserRoleTenantCreationEnabled: {
                        false: "Yes",
                        title: "Restrict Non-admin Users From Creating Tenants",
                        true: "No"
                    },
                    externalUserDefaultRole: "Guest Users Access Restrictions",
                    externalUserInvitationAllowedRoles: "Guest Invite Restrictions",
                    primaryDomainName: "Primary Domain Name"
                }
            }));

    const getDefaultUserRoleApplicationCreationEnabledTranslation =
        (item: Contract.AadTenantEntityModel) => {
            const defaultUserRoleApplicationCreationEnabled = (item.entity as Contract.AadTenantEntity).defaultUserRoleApplicationCreationEnabled;
            return _.isNil(defaultUserRoleApplicationCreationEnabled)
                ? undefined
                : defaultUserRoleApplicationCreationEnabled
                    ? localization.columns.defaultUserRoleApplicationCreationEnabled.true()
                    : localization.columns.defaultUserRoleApplicationCreationEnabled.false();
        };

    const getDefaultUserRoleTenantCreationEnabledTranslation =
        (item: Contract.AadTenantEntityModel) => {
            const defaultUserRoleTenantCreationEnabled = (item.entity as Contract.AadTenantEntity).defaultUserRoleTenantCreationEnabled;
            return _.isNil(defaultUserRoleTenantCreationEnabled)
                ? undefined
                : defaultUserRoleTenantCreationEnabled
                    ? localization.columns.defaultUserRoleTenantCreationEnabled.true()
                    : localization.columns.defaultUserRoleTenantCreationEnabled.false();
        };

    return new EntityTableDefinition(
        _.filter([
            commonDirectoryEntityDefinition.columns.tenantColumn,
            commonDirectoryEntityDefinition.columns.creationTimeColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AadTenantEntityModel) => ({
                            [localization.columns.externalUserInvitationAllowedRoles()]:
                                _.isNil((item.entity as Contract.AadTenantEntity).externalUserInvitationAllowedRoles)
                                    ? ""
                                    : externalUserInvitationAllowedRolesTranslator((item.entity as Contract.AadTenantEntity).externalUserInvitationAllowedRoles!)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                emptyValueOptions={{ enabled: true }}
                                enumType={Contract.AadTenantEntityExternalUserInvitationAllowedRoles}
                                enumTypeTranslator={externalUserInvitationAllowedRolesTranslator}
                                placeholder={localization.columns.externalUserInvitationAllowedRoles()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AadTenantEntityExternalUserInvitationAllowedRoles}
                key={Contract.EntityModelProperty.AadTenantEntityExternalUserInvitationAllowedRoles}
                render={
                    optionalTableCell<Contract.AadTenantEntityModel>(
                        item =>
                            _.isNil((item.entity as Contract.AadTenantEntity).externalUserInvitationAllowedRoles)
                                ? undefined
                                : externalUserInvitationAllowedRolesTranslator((item.entity as Contract.AadTenantEntity).externalUserInvitationAllowedRoles!))}
                title={localization.columns.externalUserInvitationAllowedRoles()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AadTenantEntityModel) => ({
                            [localization.columns.externalUserDefaultRole()]:
                                _.isNil((item.entity as Contract.AadTenantEntity).externalUserDefaultRole)
                                    ? ""
                                    : externalUserDefaultRoleTranslator((item.entity as Contract.AadTenantEntity).externalUserDefaultRole!)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                emptyValueOptions={{ enabled: true }}
                                enumType={Contract.AadTenantEntityExternalUserDefaultRole}
                                enumTypeTranslator={externalUserDefaultRoleTranslator}
                                placeholder={localization.columns.externalUserDefaultRole()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AadTenantEntityExternalUserDefaultRole}
                key={Contract.EntityModelProperty.AadTenantEntityExternalUserDefaultRole}
                render={
                    optionalTableCell<Contract.AadTenantEntityModel>(
                        item =>
                            _.isNil((item.entity as Contract.AadTenantEntity).externalUserDefaultRole)
                                ? undefined
                                : externalUserDefaultRoleTranslator((item.entity as Contract.AadTenantEntity).externalUserDefaultRole!))}
                title={localization.columns.externalUserDefaultRole()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AadTenantEntityModel) => ({
                            [localization.columns.primaryDomainName()]: (item.entity as Contract.AadTenantEntity).primaryDomainName
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AadTenantEntityPrimaryDomainName)}
                                placeholder={localization.columns.primaryDomainName()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AadTenantEntityPrimaryDomainName}
                itemProperty={(item: Contract.AadTenantEntityModel) => (item.entity as Contract.AadTenantEntity).primaryDomainName}
                key={Contract.EntityModelProperty.AadTenantEntityPrimaryDomainName}
                title={localization.columns.primaryDomainName()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem: (item: Contract.AadTenantEntityModel) => ({
                        [localization.columns.defaultUserRoleApplicationCreationEnabled.title()]:
                            _.isNil(getDefaultUserRoleApplicationCreationEnabledTranslation(item))
                                ? ""
                                : getDefaultUserRoleApplicationCreationEnabledTranslation(item)
                    })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                emptyValueOptions={{ enabled: true }}
                                placeholder={localization.columns.defaultUserRoleApplicationCreationEnabled.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.defaultUserRoleApplicationCreationEnabled.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.defaultUserRoleApplicationCreationEnabled.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AadTenantEntityDefaultUserRoleApplicationCreationEnabled}
                key={Contract.EntityModelProperty.AadTenantEntityDefaultUserRoleApplicationCreationEnabled}
                render={
                    optionalTableCell<Contract.AadTenantEntityModel>(
                        getDefaultUserRoleApplicationCreationEnabledTranslation)}
                title={localization.columns.defaultUserRoleApplicationCreationEnabled.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem: (item: Contract.AadTenantEntityModel) => ({
                        [localization.columns.defaultUserRoleTenantCreationEnabled.title()]:
                            _.isNil(getDefaultUserRoleTenantCreationEnabledTranslation(item))
                                ? ""
                                : getDefaultUserRoleTenantCreationEnabledTranslation(item)
                    })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                emptyValueOptions={{ enabled: true }}
                                placeholder={localization.columns.defaultUserRoleTenantCreationEnabled.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.defaultUserRoleTenantCreationEnabled.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.defaultUserRoleTenantCreationEnabled.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AadTenantEntityDefaultUserRoleTenantCreationEnabled}
                key={Contract.EntityModelProperty.AadTenantEntityDefaultUserRoleTenantCreationEnabled}
                render={
                    optionalTableCell<Contract.AadTenantEntityModel>(
                        getDefaultUserRoleTenantCreationEnabledTranslation)}
                title={localization.columns.defaultUserRoleTenantCreationEnabled.title()}/>,
            commonDirectoryEntityDefinition.columns.attributesColumn,
            commonDirectoryEntityDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonDirectoryEntityDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonDirectoryEntityDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAadTenantEntityRequest(
                new Contract.EntityControllerGetEntityModelPageAadTenantEntityRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.AadDirectoryEntityCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadTenantEntityDefaultUserRoleApplicationCreationEnabled]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadTenantEntityDefaultUserRoleTenantCreationEnabled]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadTenantEntityExternalUserDefaultRole]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadTenantEntityExternalUserInvitationAllowedRoles]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadTenantEntityPrimaryDomainName])),
                limit,
                skip,
                requestSort));
}