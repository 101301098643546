import { Link, NotValidIcon, Optional, SuccessIcon, UnexpectedError, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React, { ReactElement } from "react";
import { Contract, CustomerConsoleAppUrlHelper, PermissionManagementHelper, ScopeHelper, TenantHelper, TypeHelper, useGetSelectedScopeHasData, useTheme } from "../../../../../../../common";

export function useGetPermissionEligibilityStatusData(
    activePrincipalTenantModels: Contract.TenantModel[],
    principalTenantIds: string[],
    scopeActiveTenantModels: Contract.TenantModel[]) {
    const getSelectedScopeHasData = useGetSelectedScopeHasData();
    const localization =
        useLocalization(
            "views.user.permissionEligibilities.table.hooks.useGetPermissionEligibilityStatusData",
            () => ({
                info: {
                    approverPrincipalsNotExists: {
                        many: "All approvers of one or more levels no longer exist.",
                        single: "All approvers no longer exist."
                    },
                    granteePrincipalsNotExists: "All requesters no longer exist.",
                    missingGroups: {
                        all: "All groups no longer exist",
                        some: "Some groups no longer exist"
                    },
                    missingOneLoginDirectoryRole: {
                        all: "All roles no longer exist",
                        some: "Some roles longer exist"
                    },
                    partialApproverPrincipalIds: "Some approvers no longer exist.",
                    partialGranteePrincipalIds: "Some requesters no longer exist.",
                    permissionManagementNotEnabled: {
                        links: {
                            documentation: "documentation"
                        },
                        text: "To continue using this eligibility for the current scope, grant Tenable Cloud Security JIT permissions on the relevant organization. See the {{documentationLink}} for details."
                    },
                    permissions: {
                        awsCustomerManagedPolicyExists: "Permission set has customer managed policies",
                        awsInlinePolicyDocumentLength: "Inline policy is too long",
                        awsNonAttachablePolicies: "Some AWS managed policies are not attachable",
                        awsPermissionsBoundaryExists: "Permission set has permissions boundary",
                        awsServiceLinkedRolePolicies: "Some AWS managed policies are service linked",
                        missingPermitters: {
                            [Contract.TypeNames.PermissionEligibilityConfiguration]: {
                                [Contract.TypeNames.AwsSsoPermissionSetAssignmentEligibilityConfiguration]: {
                                    [Contract.TypeNames.AwsSsoPermissionSetAssignmentEligibilityConfigurationPolicyPermissions]: {
                                        all: "All AWS managed policies no longer exist",
                                        some: "Some AWS managed policies no longer exist"
                                    },
                                    [Contract.TypeNames.AwsSsoPermissionSetAssignmentEligibilityConfigurationPermissionSetPermissions]: "Permission set is missing"
                                },
                                [Contract.TypeNames.AzureRoleAssignmentEligibilityConfiguration]: {
                                    all: "All role definitions no longer exist",
                                    some: "Some role definitions no longer exist"
                                },
                                [Contract.TypeNames.GcpRoleBindingEligibilityConfiguration]: {
                                    all: "All roles no longer exist",
                                    some: "Some roles no longer exist"
                                }
                            }
                        }
                    },
                    principalTenantNotEnabled: "The principal account is not enabled for JIT",
                    principalTenantNotExists: "The principal account is not configured in Tenable Cloud Security.",
                    tenantNotInOrganization: "The account does not belong to an organization."
                },
                title: {
                    missingGroups: "Missing groups",
                    missingOneLoginDirectoryRolesGroups: "Missing roles",
                    permissionManagementNotEnabled: "Not available",
                    permissions: {
                        invalidAwsManagedPolicies: "Invalid AWS managed policies",
                        invalidPermissionSet: "Invalid permission set",
                        missingPermitters: "Missing permissions"
                    },
                    principalNotExists: "Missing principal",
                    principalTenantNotExists: "Principal account doesn't exist",
                    syncingPrincipals: "Identities are syncing...",
                    valid: "Valid"
                }
            }));

    const theme = useTheme();
    return (awsPermitterModelMap: Dictionary<Contract.AwsEntityModel>, scopeNodeModel: Contract.ScopeNodeModel, permissionEligibilityModel: Contract.PermissionEligibilityModel): EligibilityStatusData => {
        const permissionEligibilityConfiguration = permissionEligibilityModel.configuration as Contract.PermissionEligibilityConfiguration;
        const principalTenantModel =
            _.find(
                activePrincipalTenantModels,
                activePrincipalTenantModel => activePrincipalTenantModel.configuration.id === permissionEligibilityConfiguration.principalTenantId);
        const tenantType = ScopeHelper.getTenantType(scopeNodeModel)!;
        let iconElement = <SuccessIcon style={{ color: theme.palette.success.main }}/>;
        let infos: Optional<string[]>;
        let title = localization.title.valid();
        if (
            scopeNodeModel.type === Contract.ScopeType.CloudProviderTenant &&
            _.isEmpty(principalTenantIds)) {
            iconElement = <NotValidIcon style={{ color: theme.palette.error.main }}/>;
            infos = [localization.info.tenantNotInOrganization()];
            title = localization.title.principalTenantNotExists();
        } else if (_.isNil(principalTenantModel)) {
            iconElement = <NotValidIcon style={{ color: theme.palette.error.main }}/>;
            infos = [localization.info.principalTenantNotExists()];
            title = localization.title.principalTenantNotExists();
        } else if (
            TenantHelper.isCloudProviderTenantType(tenantType) &&
            _.every(
                scopeActiveTenantModels,
                scopeActiveTenantModel => !(scopeActiveTenantModel as Contract.CloudProviderTenantModel).state.permissionManagementEnabled) ||
            TenantHelper.isIdentityProviderTenantType(tenantType) &&
            !(principalTenantModel.state as Contract.IdentityProviderTenantState).permissionManagementEnabled) {
            iconElement = <NotValidIcon style={{ color: theme.palette.error.main }}/>;
            infos =
                [
                    localization.info.permissionManagementNotEnabled.text({
                        documentationLink:
                            <Link
                                urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsGrantTenableJitPermissionsRelativeUrl(tenantType)}
                                variant="external">
                                {localization.info.permissionManagementNotEnabled.links.documentation()}
                            </Link>
                    })];
            title = localization.title.permissionManagementNotEnabled();
        } else if (!getSelectedScopeHasData([principalTenantModel])) {
            iconElement = <NotValidIcon style={{ color: theme.palette.warning.main }}/>;
            title = localization.title.syncingPrincipals();
        } else if (_.isEmpty(permissionEligibilityModel.existingGranteePrincipalIds)) {
            iconElement = <NotValidIcon style={{ color: theme.palette.error.main }}/>;
            infos = [localization.info.granteePrincipalsNotExists()];
            title = localization.title.principalNotExists();
        } else if (
            !_.isNil(permissionEligibilityConfiguration.approval) &&
            _.some(
                permissionEligibilityModel.levelToExistingApproverPrincipalIdsMap,
                existingApproverPrincipalIds => _.isEmpty(existingApproverPrincipalIds))) {
            iconElement = <NotValidIcon style={{ color: theme.palette.error.main }}/>;
            infos =
                [
                    _.size(permissionEligibilityModel.levelToExistingApproverPrincipalIdsMap) === 1
                        ? localization.info.approverPrincipalsNotExists.single()
                        : localization.info.approverPrincipalsNotExists.many()];
            title = localization.title.principalNotExists();
        } else if (
            permissionEligibilityModel.existingGranteePrincipalIds.length !==
            permissionEligibilityModel.granteePrincipalIdReferences.length) {
            iconElement = <NotValidIcon style={{ color: theme.palette.warning.main }}/>;
            infos = [localization.info.partialGranteePrincipalIds()];
            title = localization.title.principalNotExists();
        } else if (
            _.size(permissionEligibilityModel.approverPrincipalIdReferences) !==
            _(permissionEligibilityModel.levelToExistingApproverPrincipalIdsMap).
                values().
                flatMap().
                uniq().
                size()) {
            iconElement = <NotValidIcon style={{ color: theme.palette.warning.main }}/>;
            infos = [localization.info.partialApproverPrincipalIds()];
            title = localization.title.principalNotExists();
        } else if (permissionEligibilityModel.configuration.typeName === Contract.TypeNames.AwsSsoPermissionSetAssignmentEligibilityConfiguration) {
            const awsSsoPermissionSetAssignmentEligibilityModel = permissionEligibilityModel as Contract.AwsSsoPermissionSetAssignmentEligibilityModel;
            switch (awsSsoPermissionSetAssignmentEligibilityModel.configuration.permissions.typeName) {
                case Contract.TypeNames.AwsSsoPermissionSetAssignmentEligibilityConfigurationPermissionSetPermissions: {
                    const { permissionSetExists, permissionSetIdReference } = awsSsoPermissionSetAssignmentEligibilityModel.permissions as Contract.AwsSsoPermissionSetAssignmentEligibilityModelPermissionSetPermissions;
                    if (!permissionSetExists) {
                        iconElement = <NotValidIcon style={{ color: theme.palette.error.main }}/>;
                        infos = [localization.info.permissions.missingPermitters[Contract.TypeNames.PermissionEligibilityConfiguration][Contract.TypeNames.AwsSsoPermissionSetAssignmentEligibilityConfiguration][Contract.TypeNames.AwsSsoPermissionSetAssignmentEligibilityConfigurationPermissionSetPermissions]()];
                        title = localization.title.permissions.missingPermitters();
                    } else {
                        const ssoPermissionSet = awsPermitterModelMap[permissionSetIdReference].entity as Contract.AwsSsoPermissionSet;
                        const permissionSetMisconfigurationInfos =
                            _<string>([]).
                                concatIf(
                                    PermissionManagementHelper.getAwsIamPolicyDocumentCharacterCount(ssoPermissionSet.inlinePolicyDocument) > PermissionManagementHelper.awsInlinePolicyDocumentMaxLength,
                                    localization.info.permissions.awsInlinePolicyDocumentLength()).
                                concatIf(
                                    ssoPermissionSet.customerManagedPolicyExists,
                                    localization.info.permissions.awsCustomerManagedPolicyExists()).
                                concatIf(
                                    ssoPermissionSet.permissionsBoundaryExists,
                                    localization.info.permissions.awsPermissionsBoundaryExists()).
                                value();
                        if (!_.isEmpty(permissionSetMisconfigurationInfos)) {
                            iconElement = <NotValidIcon style={{ color: theme.palette.error.main }}/>;
                            infos = permissionSetMisconfigurationInfos;
                            title = localization.title.permissions.invalidPermissionSet();
                        }
                    }

                    break;
                }
                case Contract.TypeNames.AwsSsoPermissionSetAssignmentEligibilityConfigurationPolicyPermissions: {
                    const { awsManagedPolicyIdReferences, existingAwsManagedPolicyIds } = awsSsoPermissionSetAssignmentEligibilityModel.permissions as Contract.AwsSsoPermissionSetAssignmentEligibilityModelPolicyPermissions;
                    const { inlinePolicyDocument } = awsSsoPermissionSetAssignmentEligibilityModel.configuration.permissions as Contract.AwsSsoPermissionSetAssignmentEligibilityConfigurationPolicyPermissions;
                    if (existingAwsManagedPolicyIds.length !== awsManagedPolicyIdReferences.length) {
                        title = localization.title.permissions.missingPermitters();
                        if (_.isEmpty(existingAwsManagedPolicyIds)) {
                            iconElement =
                                _.isNil(inlinePolicyDocument)
                                    ? <NotValidIcon style={{ color: theme.palette.error.main }}/>
                                    : <NotValidIcon style={{ color: theme.palette.warning.main }}/>;
                            infos = [localization.info.permissions.missingPermitters[Contract.TypeNames.PermissionEligibilityConfiguration][Contract.TypeNames.AwsSsoPermissionSetAssignmentEligibilityConfiguration][Contract.TypeNames.AwsSsoPermissionSetAssignmentEligibilityConfigurationPolicyPermissions].all()];
                        } else {
                            iconElement = <NotValidIcon style={{ color: theme.palette.warning.main }}/>;
                            infos = [localization.info.permissions.missingPermitters[Contract.TypeNames.PermissionEligibilityConfiguration][Contract.TypeNames.AwsSsoPermissionSetAssignmentEligibilityConfiguration][Contract.TypeNames.AwsSsoPermissionSetAssignmentEligibilityConfigurationPolicyPermissions].some()];
                        }
                    } else {
                        const awsManagedPolicies =
                            _.map(
                                awsManagedPolicyIdReferences,
                                awsManagedPolicyIdReference => awsPermitterModelMap[awsManagedPolicyIdReference].entity as Contract.AwsIamManagedPolicy);

                        if (_.some(
                            awsManagedPolicies,
                            awsManagedPolicy => !awsManagedPolicy.principalAttachable)) {
                            iconElement = <NotValidIcon style={{ color: theme.palette.error.main }}/>;
                            infos = [localization.info.permissions.awsNonAttachablePolicies()];
                            title = localization.title.permissions.invalidAwsManagedPolicies();
                        } else if (_.some(
                            awsManagedPolicies,
                            awsManagedPolicy => awsManagedPolicy.serviceLinkedRolePolicy)) {
                            iconElement = <NotValidIcon style={{ color: theme.palette.error.main }}/>;
                            infos = [localization.info.permissions.awsServiceLinkedRolePolicies()];
                            title = localization.title.permissions.invalidAwsManagedPolicies();
                        }
                    }
                    break;
                }
                default:
                    throw new UnexpectedError("awsSsoPermissionSetAssignmentEligibilityModel.configuration.permissions.typeName", awsSsoPermissionSetAssignmentEligibilityModel.configuration.permissions.typeName);
            }
        } else if (permissionEligibilityModel.configuration.typeName === Contract.TypeNames.AzureRoleAssignmentEligibilityConfiguration) {
            const { existingRoleDefinitionIds, roleDefinitionIdReferences } = permissionEligibilityModel as Contract.AzureRoleAssignmentEligibilityModel;
            if (existingRoleDefinitionIds.length !== roleDefinitionIdReferences.length) {
                title = localization.title.permissions.missingPermitters();
                if (_.isEmpty(existingRoleDefinitionIds)) {
                    iconElement = <NotValidIcon style={{ color: theme.palette.error.main }}/>;
                    infos = [localization.info.permissions.missingPermitters[Contract.TypeNames.PermissionEligibilityConfiguration][Contract.TypeNames.AzureRoleAssignmentEligibilityConfiguration].all()];
                } else {
                    iconElement = <NotValidIcon style={{ color: theme.palette.warning.main }}/>;
                    infos = [localization.info.permissions.missingPermitters[Contract.TypeNames.PermissionEligibilityConfiguration][Contract.TypeNames.AzureRoleAssignmentEligibilityConfiguration].some()];
                }
            }
        } else if (permissionEligibilityModel.configuration.typeName === Contract.TypeNames.GcpRoleBindingEligibilityConfiguration) {
            const { existingRoleIds, roleIdReferences } = permissionEligibilityModel as Contract.GcpRoleBindingEligibilityModel;
            if (existingRoleIds.length !== roleIdReferences.length) {
                title = localization.title.permissions.missingPermitters();
                if (_.isEmpty(existingRoleIds)) {
                    iconElement = <NotValidIcon style={{ color: theme.palette.error.main }}/>;
                    infos = [localization.info.permissions.missingPermitters[Contract.TypeNames.PermissionEligibilityConfiguration][Contract.TypeNames.GcpRoleBindingEligibilityConfiguration].all()];
                } else {
                    iconElement = <NotValidIcon style={{ color: theme.palette.warning.main }}/>;
                    infos = [localization.info.permissions.missingPermitters[Contract.TypeNames.PermissionEligibilityConfiguration][Contract.TypeNames.GcpRoleBindingEligibilityConfiguration].some()];
                }
            }
        } else if (TypeHelper.extendOrImplement(permissionEligibilityModel.configuration.typeName, Contract.TypeNames.GroupMembershipEligibilityConfiguration)) {
            const { existingGroupIds, groupIdReferences } = permissionEligibilityModel as Contract.GroupMembershipEligibilityModel;
            if (existingGroupIds.length !== groupIdReferences.length) {
                title = localization.title.missingGroups();
                if (_.isEmpty(existingGroupIds)) {
                    iconElement = <NotValidIcon style={{ color: theme.palette.error.main }}/>;
                    infos = [localization.info.missingGroups.all()];
                } else {
                    iconElement = <NotValidIcon style={{ color: theme.palette.warning.main }}/>;
                    infos = [localization.info.missingGroups.some()];
                }
            }
        } else if (permissionEligibilityModel.configuration.typeName === Contract.TypeNames.OneLoginDirectoryRoleAssignmentEligibilityConfiguration) {
            const { existingRoleIds, roleIdReferences } = permissionEligibilityModel as Contract.OneLoginDirectoryRoleAssignmentEligibilityModel;
            if (existingRoleIds.length !== roleIdReferences.length) {
                title = localization.title.missingOneLoginDirectoryRolesGroups();
                if (_.isEmpty(existingRoleIds)) {
                    iconElement = <NotValidIcon style={{ color: theme.palette.error.main }}/>;
                    infos = [localization.info.missingOneLoginDirectoryRole.all()];
                } else {
                    iconElement = <NotValidIcon style={{ color: theme.palette.warning.main }}/>;
                    infos = [localization.info.missingOneLoginDirectoryRole.some()];
                }
            }
        }
        return { iconElement, infos, title };
    };
}

export type EligibilityStatusData = {
    iconElement: ReactElement;
    infos?: string[];
    title: string;
};