import { useMemo } from "react";
import { useOciAutoscalingAutoscalingConfigurationDefinition, useOciBlockStorageBlockVolumeDefinition, useOciBlockStorageBootVolumeDefinition, useOciComputeImageDefinition, useOciComputeInstanceConfigurationDefinition, useOciComputeInstanceDefinition, useOciComputeInstancePoolDefinition, useOciContainerEngineClusterDefinition, useOciContainerEngineClusterNodePoolDefinition, useOciDefaultResourceDefinition, useOciFssFileSystemDefinition, useOciFssMountTargetDefinition, useOciFssMountTargetExportDefinition, useOciIamDomainDefinition, useOciIamDynamicResourceGroupDefinition, useOciIamGroupDefinition, useOciIamPolicyDefinition, useOciIamUserApiKeyDefinition, useOciIamUserAuthTokenDefinition, useOciIamUserDatabasePasswordDefinition, useOciIamUserDefinition, useOciIamUserSecretKeyDefinition, useOciKmsVaultDefinition, useOciKmsVaultKeyDefinition, useOciKmsVaultSecretDefinition, useOciLoadBalancingLoadBalancerDefinition, useOciLoggingLogGroupDefinition, useOciNetworkingInternetGatewayDefinition, useOciNetworkingLocalPeeringGatewayDefinition, useOciNetworkingNatGatewayDefinition, useOciNetworkingNetworkLoadBalancerDefinition, useOciNetworkingNetworkSecurityGroupDefinition, useOciNetworkingRouteTableDefinition, useOciNetworkingSecurityListDefinition, useOciNetworkingServiceGatewayDefinition, useOciNetworkingSubnetDefinition, useOciNetworkingVcnDefinition, useOciNetworkingVirtualNetworkInterfaceCardDefinition, useOciObjectStorageBucketDefinition, useOciTenantEntityDefinition } from ".";
import { DefinitionOptions } from "../../..";
import { Contract } from "../../../../../../../../../../common";

export function useOciResourceDefinition(entityModel: Contract.EntityModel, options?: DefinitionOptions) {
    const useDefinition =
        useMemo(
            () => {
                switch (entityModel.entity.typeName) {
                    case Contract.TypeNames.OciAutoscalingAutoscalingConfiguration:
                        return useOciAutoscalingAutoscalingConfigurationDefinition;
                    case Contract.TypeNames.OciBlockStorageBlockVolume:
                        return useOciBlockStorageBlockVolumeDefinition;
                    case Contract.TypeNames.OciBlockStorageBootVolume:
                        return useOciBlockStorageBootVolumeDefinition;
                    case Contract.TypeNames.OciComputeImage:
                        return useOciComputeImageDefinition;
                    case Contract.TypeNames.OciComputeInstance:
                        return useOciComputeInstanceDefinition;
                    case Contract.TypeNames.OciComputeInstanceConfiguration:
                        return useOciComputeInstanceConfigurationDefinition;
                    case Contract.TypeNames.OciComputeInstancePool:
                        return useOciComputeInstancePoolDefinition;
                    case Contract.TypeNames.OciContainerEngineCluster:
                        return useOciContainerEngineClusterDefinition;
                    case Contract.TypeNames.OciContainerEngineClusterNodePool:
                        return useOciContainerEngineClusterNodePoolDefinition;
                    case Contract.TypeNames.OciFssFileSystem:
                        return useOciFssFileSystemDefinition;
                    case Contract.TypeNames.OciFssMountTarget:
                        return useOciFssMountTargetDefinition;
                    case Contract.TypeNames.OciFssMountTargetExport:
                        return useOciFssMountTargetExportDefinition;
                    case Contract.TypeNames.OciIamDomain:
                        return useOciIamDomainDefinition;
                    case Contract.TypeNames.OciIamDynamicResourceGroup:
                        return useOciIamDynamicResourceGroupDefinition;
                    case Contract.TypeNames.OciIamGroup:
                        return useOciIamGroupDefinition;
                    case Contract.TypeNames.OciIamPolicy:
                        return useOciIamPolicyDefinition;
                    case Contract.TypeNames.OciIamUser:
                        return useOciIamUserDefinition;
                    case Contract.TypeNames.OciIamUserApiKey:
                        return useOciIamUserApiKeyDefinition;
                    case Contract.TypeNames.OciIamUserAuthToken:
                        return useOciIamUserAuthTokenDefinition;
                    case Contract.TypeNames.OciIamUserDatabasePassword:
                        return useOciIamUserDatabasePasswordDefinition;
                    case Contract.TypeNames.OciIamUserSecretKey:
                        return useOciIamUserSecretKeyDefinition;
                    case Contract.TypeNames.OciKmsVault:
                        return useOciKmsVaultDefinition;
                    case Contract.TypeNames.OciKmsVaultKey:
                        return useOciKmsVaultKeyDefinition;
                    case Contract.TypeNames.OciKmsVaultSecret:
                        return useOciKmsVaultSecretDefinition;
                    case Contract.TypeNames.OciLoadBalancingLoadBalancer:
                        return useOciLoadBalancingLoadBalancerDefinition;
                    case Contract.TypeNames.OciLoggingLogGroup:
                        return useOciLoggingLogGroupDefinition;
                    case Contract.TypeNames.OciNetworkingInternetGateway:
                        return useOciNetworkingInternetGatewayDefinition;
                    case Contract.TypeNames.OciNetworkingLocalPeeringGateway:
                        return useOciNetworkingLocalPeeringGatewayDefinition;
                    case Contract.TypeNames.OciNetworkingNatGateway:
                        return useOciNetworkingNatGatewayDefinition;
                    case Contract.TypeNames.OciNetworkingNetworkLoadBalancer:
                        return useOciNetworkingNetworkLoadBalancerDefinition;
                    case Contract.TypeNames.OciNetworkingNetworkSecurityGroup:
                        return useOciNetworkingNetworkSecurityGroupDefinition;
                    case Contract.TypeNames.OciNetworkingRouteTable:
                        return useOciNetworkingRouteTableDefinition;
                    case Contract.TypeNames.OciNetworkingSecurityList:
                        return useOciNetworkingSecurityListDefinition;
                    case Contract.TypeNames.OciNetworkingServiceGateway:
                        return useOciNetworkingServiceGatewayDefinition;
                    case Contract.TypeNames.OciNetworkingSubnet:
                        return useOciNetworkingSubnetDefinition;
                    case Contract.TypeNames.OciNetworkingVcn:
                        return useOciNetworkingVcnDefinition;
                    case Contract.TypeNames.OciNetworkingVirtualNetworkInterfaceCard:
                        return useOciNetworkingVirtualNetworkInterfaceCardDefinition;
                    case Contract.TypeNames.OciObjectStorageBucket:
                        return useOciObjectStorageBucketDefinition;
                    case Contract.TypeNames.OciTenantEntity:
                        return useOciTenantEntityDefinition;
                    default:
                        return useOciDefaultResourceDefinition;
                }
            },
            []);

    return useDefinition(entityModel as Contract.OciResourceModel, options);
}