import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAwsRdsSnapshotEncryptionDisabledRiskResolutionSteps, useGetAwsRdsSnapshotEncryptionDisabledRiskSourceResourceRiskContext } from ".";
import { useGetAwsRdsDatabaseInstanceRiskContext } from "../../..";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";

export function useAwsRdsSnapshotEncryptionDisabledRiskDatabaseInstanceDefinition(riskModel: Contract.AwsRdsSnapshotEncryptionDisabledRiskModel) {
    const risk = riskModel.risk;
    const databaseInstanceModel = entityModelStore.useGet(risk.entityId) as Contract.AwsRdsDatabaseInstanceModel;

    const getAwsRdsDatabaseInstanceRiskContext = useGetAwsRdsDatabaseInstanceRiskContext();
    const getAwsRdsSnapshotEncryptionDisabledRiskSourceResourceRiskContext = useGetAwsRdsSnapshotEncryptionDisabledRiskSourceResourceRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsRdsSnapshotEncryptionDisabledRiskDefinition.hooks.useAwsRdsSnapshotEncryptionDisabledRiskDatabaseInstanceDefinition",
            () => ({
                description: {
                    many: "{{databaseInstance}} has {{snapshots}} that are not configured with KMS encryption",
                    none: "{{databaseInstance}} is not encrypted using KMS",
                    single: "{{databaseInstance}} has {{snapshots}} that is not configured with KMS encryption"
                }
            }));

    const resolutionSteps = useAwsRdsSnapshotEncryptionDisabledRiskResolutionSteps(databaseInstanceModel);

    return useCommonSectionsAndDescriptionDefinition(
        (_.isEmpty(risk.aggregatedEntityIds)
            ? localization.description.none
            : risk.aggregatedEntityIds.length === 1
                ? localization.description.single
                : localization.description.many)({
            databaseInstance:
                <Entity
                    entityIdOrModel={databaseInstanceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            snapshots:
                <InlineEntities
                    entityIdsOrModels={risk.aggregatedEntityIds}
                    entityTypeName={Contract.TypeNames.AwsRdsSnapshot}
                    variant="itemCountAndType"/>
        }),
        () => resolutionSteps,
        riskModel,
        () => {
            const databaseInstanceContextItems = getAwsRdsDatabaseInstanceRiskContext(databaseInstanceModel);
            const snapshotEncryptionDisabledRiskSourceResourceDefinition = getAwsRdsSnapshotEncryptionDisabledRiskSourceResourceRiskContext(riskModel);
            return [
                databaseInstanceContextItems.generalInformation,
                snapshotEncryptionDisabledRiskSourceResourceDefinition.automatic,
                snapshotEncryptionDisabledRiskSourceResourceDefinition.awsBackup,
                snapshotEncryptionDisabledRiskSourceResourceDefinition.manual,
                databaseInstanceContextItems.engine,
                databaseInstanceContextItems.storageSize,
                databaseInstanceContextItems.hasInboundExternalWideRange,
                snapshotEncryptionDisabledRiskSourceResourceDefinition.internal,
                snapshotEncryptionDisabledRiskSourceResourceDefinition.snapshotIdsEmpty,
                databaseInstanceContextItems.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}