import { map } from "@infrastructure";
import { Contract, RegionModelHelper, tenantModelStore } from "../../../../../../../../../../../../../../../common";
import { GcpConsoleUrlBuilder } from "../../../../../../../../../../../../../../../tenants";
import { EntityExternalConsoleLinkDefinition } from "../useDefinition";

export function useGcpNotebooksInstanceDefinition(entityModel: Contract.EntityModel, page: Contract.GcpConsolePage) {
    const instance = entityModel.entity as Contract.GcpNotebooksInstance;
    const tenantModel = tenantModelStore.useGet(instance.tenantId);

    const getUrl =
        map(
            page,
            {
                [Contract.GcpConsolePage.Resource]: () => GcpConsoleUrlBuilder.GetNotebooksInstanceUrl
            });

    return new EntityExternalConsoleLinkDefinition(
        getUrl?.(
            RegionModelHelper.get(instance.regionId!).systemName,
            instance.name,
            (tenantModel.configuration as Contract.GcpTenantConfiguration).rawShortNameId));
}