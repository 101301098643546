import { useSortable } from "@dnd-kit/sortable";
import { CSS as DndCSS } from "@dnd-kit/utilities";
import { Action0, Action1, ActionMenuItem, AddIcon, CollapsedIcon, ConfirmDialog, DeleteIcon, Dialog, EditIcon, GrabIcon, IconText, Menu, Tooltip, useLocalization } from "@infrastructure";
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from "@mui/material";
import _, { uniqueId } from "lodash";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useCustomComplianceContext, useSetCustomComplianceContext } from "../..";
import { ComplianceHelper, Contract, CustomCompliance, useTheme } from "../../../../../../../../common";
import { AddOrEditDetails } from "../AddOrEditDetails";
import { AddRiskPolicies, Sections } from "./components";

type SectionProps = {
    onDelete?: Action0;
    onSectionChanged: Action1<CustomCompliance>;
    path: string[];
    root?: boolean;
    section: CustomCompliance;
};

export function Section({ onDelete, onSectionChanged, path: parentPath, root = false, section }: SectionProps) {
    const [editSection, setEditSection] = useState<DialogType>();
    const setCustomComplianceContext = useSetCustomComplianceContext();
    const { lastCreatedSectionId } = useCustomComplianceContext();
    const [expanded, setExpanded] = useState(lastCreatedSectionId === ComplianceHelper.getCustomComplianceId(section) || root);
    const [hover, setHover] = useState(false);
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);

    const path =
        useMemo(
            () =>
                root
                    ? []
                    : [...parentPath, ComplianceHelper.getCustomComplianceId(section)],
            [parentPath, section]);
    const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({ id: ComplianceHelper.getCustomComplianceId(section) });

    useEffect(
        () => {
            if (isDragging) {
                setExpanded(false);
            }
        },
        [isDragging]);

    const localization =
        useLocalization(
            "views.customer.compliance.addOrEdit.section",
            () => ({
                add: "Add",
                count: {
                    policies: [
                        "1 policy",
                        "{{count | NumberFormatter.humanize}} policies"
                    ],
                    sections: [
                        "1 section",
                        "{{count | NumberFormatter.humanize}} sections"
                    ]
                },
                delete: "Are you sure you want to delete this section",
                menu: {
                    policies: {
                        add: "Add Policies",
                        edit: "Edit Policies"
                    },
                    section: {
                        add: "Add Section"
                    }
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            {...attributes}
            {...listeners}
            ref={setNodeRef}
            style={{ transform: DndCSS.Translate.toString(transform) }}
            sx={{
                maxHeight: "100%",
                overflow: "hidden",
                transition,
                zIndex:
                    isDragging
                        ? theme.zIndex.tooltip
                        : undefined
            }}>
            {showConfirmDeleteDialog &&
                <ConfirmDialog
                    message={localization.delete()}
                    onClose={
                        confirm => {
                            if (confirm) {
                                onDelete?.();
                            }
                            setShowConfirmDeleteDialog(false);
                        }}/>}
            {!_.isNil(editSection) &&
                <Dialog
                    size={
                        editSection === "policies"
                            ? "medium"
                            : "small"}
                    variant="editor"
                    onClose={() => setEditSection(undefined)}>
                    {editSection === "policies"
                        ? <AddRiskPolicies
                            riskPolicyIds={_.map(section.riskPolicies, riskPolicy => riskPolicy.identifier)}
                            onSave={
                                (policies: string[]) => {
                                    onSectionChanged({
                                        ...section,
                                        riskPolicies:
                                            _.map(policies, riskPolicyIdentifier =>
                                                new Contract.ConfigurationControllerUpsertCustomComplianceRequestRiskPolicy(
                                                    Contract.RiskPolicyTypeMetadataAnalysisGroupType.AllEntities,
                                                    riskPolicyIdentifier
                                                ))
                                    });
                                    setExpanded(true);
                                    setEditSection(undefined);
                                }}/>
                        : <AddOrEditDetails
                            section={
                                editSection === "add"
                                    ? undefined
                                    : section}
                            onSave={
                                ({ description, name }) => {
                                    if (editSection === "edit") {
                                        onSectionChanged({ ...section, description, name });
                                    } else {
                                        const clientId = uniqueId();
                                        onSectionChanged({
                                            ...section,
                                            sections: [
                                                ...(section.sections ?? []),
                                                {
                                                    clientId,
                                                    description,
                                                    id: undefined,
                                                    name,
                                                    riskPolicies: [],
                                                    sections: []
                                                }]
                                        });
                                        setExpanded(true);
                                        setCustomComplianceContext(
                                            context => ({
                                                ...context,
                                                lastCreatedSectionId: clientId
                                            }));
                                    }
                                    setEditSection(undefined);
                                }}/>}
                </Dialog>}
            <Accordion
                expanded={expanded}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden"
                }}
                TransitionProps={{ unmountOnExit: true }}
                onChange={(_event, expanded) => !root && setExpanded(expanded)}>
                <Tooltip
                    disableInteractive={true}
                    placement="bottom-start"
                    sx={{ maxWidth: "500px" }}
                    titleOrGetTitle={
                        _.isEmpty(section.description)
                            ? undefined
                            : section.description}>
                    <AccordionSummary
                        expandIcon={
                            root
                                ? undefined
                                : <CollapsedIcon/>}
                        sx={{
                            ...(root && {
                                "& .MuiAccordionSummary-content": {
                                    "&.Mui-expanded": {
                                        marginLeft: 0,
                                        maxWidth: "100%"
                                    }
                                },
                                "&.MuiButtonBase-root": {
                                    cursor: theme.important("default")
                                },
                                "&:hover": {
                                    backgroundColor: "transparent"
                                },
                                maxWidth: "100%",
                                paddingLeft: 0
                            })
                        }}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}>
                        <Stack
                            alignItems="center"
                            direction="row"
                            justifyContent="space-between"
                            spacing={2}
                            sx={{ width: "100%" }}>
                            <Typography
                                noWrap={true}
                                sx={{ fontSize: "14px" }}>
                                {section?.name}
                            </Typography>
                            {hover || root
                                ? <Stack
                                    direction="row"
                                    spacing={1}
                                    sx={{ fontSize: theme.spacing(2) }}
                                    onClick={event => event.stopPropagation()}>
                                    <Menu
                                        itemsOrGetItems={[
                                            new ActionMenuItem(
                                                () => setEditSection("add"),
                                                localization.menu.section.add(),
                                                {
                                                    disabled: path.length > 2
                                                }),
                                            new ActionMenuItem(
                                                () => setEditSection("policies"),
                                                _.isEmpty(section.riskPolicies)
                                                    ? localization.menu.policies.add()
                                                    : localization.menu.policies.edit()
                                            )
                                        ]}>
                                        <AddIcon
                                            sx={{
                                                ":hover": {
                                                    color: theme.palette.link.hover
                                                }
                                            }}/>
                                    </Menu>
                                    <EditIcon
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => setEditSection("edit")}/>
                                    {!root &&
                                        <Fragment>
                                            <DeleteIcon onClick={() => setShowConfirmDeleteDialog(true)}/>
                                            <GrabIcon sx={{ cursor: "grab" }}/>
                                        </Fragment>}
                                </Stack>
                                : <Stack
                                    direction="row"
                                    spacing={1}
                                    sx={{ whiteSpace: "nowrap" }}>
                                    {!_.isEmpty(section.sections) &&
                                        <Typography>
                                            {localization.count.sections(section.sections!.length)}
                                        </Typography>}
                                    {!_.isEmpty(section.riskPolicies) &&
                                        <Typography>
                                            {localization.count.policies(section.riskPolicies!.length)}
                                        </Typography>}
                                </Stack>}
                        </Stack>
                    </AccordionSummary>
                </Tooltip>
                <AccordionDetails
                    sx={{
                        "&.MuiAccordionDetails-root": {
                            marginLeft:
                                root
                                    ? 0
                                    : theme.spacing(0.5)
                        },
                        padding:
                            root
                                ? 0
                                : undefined
                    }}>
                    <Stack sx={{ overflow: "hidden" }}>
                        {_.isEmpty(section.sections) && _.isEmpty(section.riskPolicies)
                            ? <Menu
                                itemsOrGetItems={[
                                    new ActionMenuItem(
                                        () => setEditSection("add"),
                                        localization.menu.section.add(),
                                        {
                                            disabled: path.length > 2
                                        }),
                                    new ActionMenuItem(
                                        () => setEditSection("policies"),
                                        localization.menu.policies.add())
                                ]}
                                variant="bottomCenter">
                                <Stack
                                    sx={{
                                        alignItems: "center",
                                        border: theme.border.primary,
                                        borderRadius: theme.spacing(0.75),
                                        marginLeft:
                                            root
                                                ? undefined
                                                : theme.spacing(1.5),
                                        padding: theme.spacing(1.5),
                                        width: "100%"
                                    }}>
                                    <IconText
                                        icon={<AddIcon/>}
                                        text={localization.add()}/>
                                </Stack>
                            </Menu>
                            : <Sections
                                complianceSection={section}
                                path={path}
                                onSectionsChanged={section => onSectionChanged(section)}/>}
                    </Stack>
                </AccordionDetails>
            </Accordion>
        </Stack>);
}

type DialogType = "add" | "edit" | "policies";