import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpComputeSnapshotTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpComputeSnapshotTypeTranslator",
            () => ({
                [Contract.TypeNames.GcpComputeSnapshotType]: {
                    [Contract.GcpComputeSnapshotType.Archive]: "Archive",
                    [Contract.GcpComputeSnapshotType.Standard]: "Standard"
                }
            }));
    return (type: Contract.GcpComputeSnapshotType) =>
        localization[Contract.TypeNames.GcpComputeSnapshotType][type]();
}