﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract, useSeverityTranslator } from "../../../../../../../../../common";

export function useCodeUpdateAuditEventDefinition(context: AuditEventDefinitionContext) {
    const severityTranslator = useSeverityTranslator();

    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useCodeUpdateAuditEventDefinition",
            () => ({
                failureRiskPolicyMinSeverity: {
                    disabled: "Disabled",
                    inherit: "Inherit",
                    title: "Set minimum severity to fail scan"
                }
            }));
    const codeUpdateAuditEvent = context.auditEventModel.auditEvent as Contract.CodeUpdateAuditEvent;
    return new AuditEventDefinition(
        _.filter([
            new AuditEventDefinitionDetailItem(
                localization.failureRiskPolicyMinSeverity.title(),
                _.isNil(codeUpdateAuditEvent.failureRiskPolicyMinSeverityEnabled)
                    ? localization.failureRiskPolicyMinSeverity.inherit()
                    : codeUpdateAuditEvent.failureRiskPolicyMinSeverityEnabled
                        ? severityTranslator(codeUpdateAuditEvent.failureRiskPolicyMinSeverity)
                        : localization.failureRiskPolicyMinSeverity.disabled())]));
}