﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, entityModelStore, InlineEntities, tenantModelStore, useEntityTypeNameTranslator } from "../../../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem, RiskDefinitionSection } from "../../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsEc2VolumesRiskContext, useGetAwsEmrClustersRiskContext } from "../../../../contexts";
import { EmrClusterTable } from "./components";


export function useAwsEc2VolumeEncryptionDisabledRiskEmrClustersDefinition(riskModel: Contract.RiskModel) {
    const volumeEncryptionDisabledRiskModel = riskModel as Contract.AwsEc2VolumeEncryptionDisabledRiskModel;
    const risk = volumeEncryptionDisabledRiskModel.risk;
    const riskData = risk.data as Contract.AwsEc2VolumeEncryptionDisabledRiskEmrClustersData;
    const clusterModels = entityModelStore.useGet(riskData.clusterIds) as Contract.AwsEmrClusterModel[];
    const tenantConfiguration = tenantModelStore.useGet(riskModel.tenantId).configuration as Contract.AwsTenantConfiguration;
    const volumeModels = entityModelStore.useGet(risk.aggregatedEntityIds) as Contract.AwsEc2VolumeModel[];

    const getAwsEc2VolumesRiskContext = useGetAwsEc2VolumesRiskContext();
    const getAwsEmrClustersRiskContext = useGetAwsEmrClustersRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2VolumeEncryptionDisabledRiskDefinition.hooks.useAwsEc2VolumeEncryptionDisabledRiskEmrClustersDefinition",
            () => ({
                contextItems: {
                    notAttached: "The {{translatedSecurityConfigurationTypeName}} was not attached to {{emrClusters}} with {{volumes}}"
                },
                description: "{{translatedSecurityConfigurationTypeName}} was not attached to EMR clusters with volumes",
                sections: {
                    clusters: "Clusters",
                    resolution: {
                        step1: "Click on the **Create** button",
                        step2: "Check **Turn on at-rest encryption for local disk**",
                        step3: "Select a KMS key and click on **Create**",
                        step4: "Use the new security configuration when launching new EMR clusters"
                    }
                },
                volumes: [
                    "1 unencrypted volume",
                    "{{count | NumberFormatter.humanize}} unencrypted volumes"
                ]
            }));
    const translatedSecurityConfigurationTypeName =
        entityTypeNameTranslator(
            Contract.TypeNames.AwsEmrSecurityConfiguration,
            {
                includeServiceName: true,
                variant: "title"
            });

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({ translatedSecurityConfigurationTypeName }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Emr,
                AwsConsoleUrlBuilder.getEmrSecurityConfigurationUrl(
                    clusterModels[0].entity as Contract.AwsEmrCluster,
                    tenantConfiguration.partitionType)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const clustersContextItems = getAwsEmrClustersRiskContext(clusterModels);
            const volumesContextItems = getAwsEc2VolumesRiskContext(volumeModels);
            return [
                new RiskDefinitionContextItem(
                    localization.contextItems.notAttached({
                        emrClusters:
                            <InlineEntities
                                entityIdsOrModels={riskData.clusterIds}
                                entityTypeName={Contract.TypeNames.AwsEmrCluster}
                                variant="itemCountAndType"/>,
                        translatedSecurityConfigurationTypeName,
                        volumes:
                            <InlineEntities
                                entityIdsOrModels={risk.aggregatedEntityIds}
                                entityTypeName={Contract.TypeNames.AwsEc2Volume}
                                namePluralizer={localization.volumes}
                                variant="itemCountAndType"/>
                    })),
                clustersContextItems.sensitive,
                volumesContextItems.sensitive
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <EmrClusterTable
                        clusterModels={clusterModels}
                        risk={risk}/>,
                    localization.sections.clusters(),
                    {
                        expandable: true
                    })
            ]
        });
}