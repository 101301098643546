import { useLocalization } from "@infrastructure";
import { Contract } from "../../..";

export function useGetPermissionEligibilityUpdateAuditEventCommonChanges() {
    const localization =
        useLocalization(
            "common.hooks.usePermissionEligibilityUpdateAuditEventChangesTranslator.hooks.useGetPermissionEligibilityUpdateAuditEventCommonChanges",
            () => ({
                approvalRequired: "Requires Approval",
                expirationTimeFrame: "Maximum Duration",
                granteePrincipals: "Requesters",
                levelToApproverPrincipalsMap: "Approvers",
                name: "Name",
                principalTenantId: "Organization",
                reasonRequired: "Justification Required"
            }));

    return (permissionEligibilityUpdateAuditEventPermissionEligibilityChanges: Contract.PermissionEligibilityUpdateAuditEventPermissionEligibilityChanges) => ({
        approval:
            permissionEligibilityUpdateAuditEventPermissionEligibilityChanges.approvalRequiredChanged
                ? localization.approvalRequired()
                : permissionEligibilityUpdateAuditEventPermissionEligibilityChanges.levelToApproverPrincipalsMapChanged
                    ? localization.levelToApproverPrincipalsMap()
                    : undefined,
        expirationTimeFrame:
            permissionEligibilityUpdateAuditEventPermissionEligibilityChanges.expirationTimeFrameChanged
                ? localization.expirationTimeFrame()
                : undefined,
        granteePrincipals:
            permissionEligibilityUpdateAuditEventPermissionEligibilityChanges.granteePrincipalsChanged
                ? localization.granteePrincipals()
                : undefined,
        name:
            permissionEligibilityUpdateAuditEventPermissionEligibilityChanges.nameChanged
                ? localization.name()
                : undefined,
        principalTenantId:
            permissionEligibilityUpdateAuditEventPermissionEligibilityChanges.principalTenantChanged
                ? localization.principalTenantId()
                : undefined,
        reasonRequired:
            permissionEligibilityUpdateAuditEventPermissionEligibilityChanges.reasonRequiredChanged
                ? localization.reasonRequired()
                : undefined
    });
}