import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { Contract } from "../../../common";

export function useAwsCloudTrailTrailEventSelectorTypeTranslator() {
    const localization =
        useLocalization(
            "tenants.aws.hooks.useAwsCloudTrailTrailEventSelectorTypeTranslator",
            () => ({
                empty: "Not enabled",
                [Contract.TypeNames.AwsCloudTrailTrailEventSelectorType]: {
                    [Contract.AwsCloudTrailTrailEventSelectorType.All]: "Enabled (Read/Write)",
                    [Contract.AwsCloudTrailTrailEventSelectorType.Read]: "Enabled (Read)",
                    [Contract.AwsCloudTrailTrailEventSelectorType.Write]: "Enabled (Write)"
                }
            }));
    return (eventSelectorType?: Contract.AwsCloudTrailTrailEventSelectorType) =>
        _.isNil(eventSelectorType)
            ? localization.empty()
            : localization[Contract.TypeNames.AwsCloudTrailTrailEventSelectorType][eventSelectorType]();
}