//@ts-nocheck
import Mustache from "mustache";
import { Contract } from "../../../common";

export class GcpConsoleUrlBuilder {
    private static readonly _apiKeysKeysUrlTemplate = "https://console.cloud.google.com/apis/credentials?project={{tenantRawShortNameId}}";
    private static readonly _apiKeysKeyUrlTemplate = "https://console.cloud.google.com/apis/credentials/key/{{rawId}}?project={{tenantRawShortNameId}}";
    private static readonly _appEngineApplicationServiceUrlTemplate = "https://console.cloud.google.com/appengine/versions?project={{applicationName}}&serviceId={{serviceName}}";
    private static readonly _appEngineApplicationUrlTemplate = "https://console.cloud.google.com/appengine?project={{tenantRawShortNameId}}";
    private static readonly _artifactRegistriesUrlTemplate = "https://console.cloud.google.com/artifacts?project={{tenantRawShortNameId}}";
    private static readonly _artifactRegistryUrlTemplate = "https://console.cloud.google.com/artifacts/{{type}}/{{tenantRawShortNameId}}/{{location}}/{{name}}";
    private static readonly _artifactRepositoryUrlTemplate = "https://console.cloud.google.com/artifacts/{{registryType}}/{{tenantRawShortNameId}}/{{location}}/{{registryName}}/{{name}}";
    private static readonly _bigQueryDatasetTableUrlTemplate = "https://console.cloud.google.com/bigquery?p={{tenantRawShortNameId}}&d={{datasetRawShortId}}&t={{rawShortId}}&page=table";
    private static readonly _bigQueryDatasetUrlTemplate = "https://console.cloud.google.com/bigquery?p={{tenantRawShortNameId}}&d={{rawShortId}}&page=dataset";
    private static readonly _bigTableClusterUrlTemplate = "https://console.cloud.google.com/bigtable/instances?project={{tenantRawShortNameId}}";
    private static readonly _bigTableInstanceUrlTemplate = "https://console.cloud.google.com/bigtable/instances/{{rawShortId}}/overview?project={{tenantRawShortNameId}}";
    private static readonly _cloudBuildBuildTriggerUrlTemplate = "https://console.cloud.google.com/cloud-build/triggers;region={{location}}/edit/{{rawId}}?project={{tenantRawShortNameId}}";
    private static readonly _cloudRunServicesUrlTemplate = "https://console.cloud.google.com/run?project={{tenantRawShortNameId}}";
    private static readonly _cloudRunServiceUrlTemplate = "https://console.cloud.google.com/run/detail/{{location}}/{{name}}/revisions?project={{tenantRawShortNameId}}";
    private static readonly _computeBackendBucketUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/advanced/backendBuckets/details/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeBackendServiceGlobalUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/advanced/backendServices/details/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeBackendServiceRegionUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/advanced/regionBackendServices/details/{{location}}/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeDiskRegionUrlTemplate = "https://console.cloud.google.com/compute/disksDetail/regions/{{location}}/disks/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeDiskZoneUrlTemplate = "https://console.cloud.google.com/compute/disksDetail/zones/{{location}}/disks/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeFirewallPolicyNonTenantResourceManagerResourceUrlTemplate = "https://console.cloud.google.com/networking/firewalls/policies/details/{{rawShortId}}?{{parentType}}={{parentRawShortId}}";
    private static readonly _computeFirewallPolicyTenantGlobalUrlTemplate = "https://console.cloud.google.com/networking/firewalls/networkPolicies/details/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeFirewallPolicyTenantRegionUrlTemplate = "https://console.cloud.google.com/networking/firewalls/networkPolicies/details/{{location}}/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeFirewallsUrlTemplate = "https://console.cloud.google.com/networking/firewalls/list?project={{tenantRawShortNameId}}";
    private static readonly _computeForwardingRuleGlobalUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/advanced/globalForwardingRules/details/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeForwardingRuleRegionUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/advanced/forwardingRules/details/regions/{{location}}/forwardingRules/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeImagesUrlTemplate = "https://console.cloud.google.com/compute/images?project={{tenantRawShortNameId}}";
    private static readonly _computeImageUrlTemplate = "https://console.cloud.google.com/compute/imagesDetail/projects/{{tenantRawShortNameId}}/global/images/{{name}}";
    private static readonly _computeInstanceGroupUrlTemplate = "https://console.cloud.google.com/compute/instanceGroups/details/{{location}}/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeInstancesUrlTemplate = "https://console.cloud.google.com/compute/instances?project={{tenantRawShortNameId}}";
    private static readonly _computeInstanceTemplatesUrlTemplate = "https://console.cloud.google.com/compute/instanceTemplates/list?project={{tenantRawShortNameId}}";
    private static readonly _computeInstanceTemplateUrlTemplate = "https://console.cloud.google.com/compute/instanceTemplates/details/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeInstanceUrlTemplate = "https://console.cloud.google.com/compute/instancesDetail/zones/{{location}}/instances/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeLoadBalancerEditPrefixUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/edit/";
    private static readonly _computeLoadBalancerHttpClassicProxyEditUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/edit/http/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeLoadBalancerHttpClassicProxyUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/details/http/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeLoadBalancerHttpInternalProxyEditUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/edit/internalRegionalHttp/{{location}}/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeLoadBalancerHttpInternalProxyUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/details/internalRegionalHttp/{{location}}/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeLoadBalancerHttpProxyEditGlobalUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/edit/httpAdvanced/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeLoadBalancerHttpProxyEditRegionUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/edit/regionalHttp/{{location}}/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeLoadBalancerHttpProxyGlobalUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/details/httpAdvanced/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeLoadBalancerHttpProxyRegionUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/details/regionalHttp/{{location}}/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeLoadBalancerNetworkBackendServiceEditUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/edit/networkBackendService/{{location}}/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeLoadBalancerNetworkBackendServiceUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/details/networkBackendService/{{location}}/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeLoadBalancerNetworkInternalEditUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/edit/internal/{{location}}/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeLoadBalancerNetworkInternalUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/details/internal/{{location}}/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeLoadBalancerNetworkTargetPoolEditUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/edit/network/{{location}}/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeLoadBalancerNetworkTargetPoolUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/details/network/{{location}}/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeLoadBalancerPrefixUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/details/";
    private static readonly _computeLoadBalancerResourcePrefixUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/advanced/";
    private static readonly _computeLoadBalancerSslTcpProxyEditGlobalUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/edit/proxy/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeLoadBalancerSslTcpProxyEditRegionUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/edit/regionalTcpProxy//{{location}}/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeLoadBalancerSslTcpProxyGlobalUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/details/proxy/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeLoadBalancerSslTcpProxyRegionUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/details/regionalTcpProxy/{{location}}/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeLoadBalancerTcpInternalEditUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/edit/internalTcpProxy/{{location}}/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeLoadBalancerTcpInternalProxyUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/details/internalTcpProxy/{{location}}/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeLoadBalancingPrefixUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/";
    private static readonly _computeNetworkEndpointGroupGlobalUrlTemplate = "https://console.cloud.google.com/compute/networkendpointgroups/global/{{name}}/details?project={{tenantRawShortNameId}}";
    private static readonly _computeNetworkEndpointGroupRegionUrlTemplate = "https://console.cloud.google.com/compute/networkendpointgroups/details/region/{{location}}/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeNetworkEndpointGroupZoneUrlTemplate = "https://console.cloud.google.com/compute/networkendpointgroups/{{location}}/{{name}}/details?project={{tenantRawShortNameId}}";
    private static readonly _computeSnapshotUrlTemplate = "https://console.cloud.google.com/compute/snapshotsDetail/projects/{{tenantRawShortNameId}}/global/snapshots/{{name}}";
    private static readonly _computeSslCertificateGlobalUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/advanced/sslCertificates/details/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeSslCertificateRegionUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/advanced/regionSslCertificates/details/regions/{{location}}/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeSslPolicyEditUrlTemplate = "https://console.cloud.google.com/net-security/sslpolicies/edit/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeSslPolicyUrlTemplate = "https://console.cloud.google.com/net-security/sslpolicies/details/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeSubnetUrlTemplate = "https://console.cloud.google.com/networking/subnetworks/details/{{location}}/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeTargetHttpProxyUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/advanced/targetHttpProxies/details/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeTargetHttpsProxyUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/advanced/targetHttpsProxies/details/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeTargetPoolUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/advanced/targetPools/details/regions/{{location}}/targetPools/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeTargetSslProxyUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/advanced/targetSslProxies/details/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeTargetTcpProxyUrlTemplate = "https://console.cloud.google.com/net-services/loadbalancing/advanced/targetTcpProxies/details/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeTenantMetadataUrlTemplate = "https://console.cloud.google.com/compute/metadata?project={{tenantRawShortNameId}}";
    private static readonly _computeVpcFirewallRulesUrlTemplate = "https://console.cloud.google.com/networking/networks/details/{{name}}?project={{tenantRawShortNameId}}&pageTab=FIREWALL_POLICIES";
    private static readonly _computeVpcPeeringConnectionUrlTemplate = "https://console.cloud.google.com/networking/peering/details/{{requesterVpcName}}/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _computeVpcSubnetsUrlTemplate = "https://console.cloud.google.com/networking/networks/details/{{name}}?project={{tenantRawShortNameId}}&pageTab=SUBNETS";
    private static readonly _computeVpcUrlTemplate = "https://console.cloud.google.com/networking/networks/details/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _containerClusterUrlTemplate = "https://console.cloud.google.com/kubernetes/clusters/details/{{location}}/{{name}}/details?project={{tenantRawShortNameId}}";
    private static readonly _containerNodePoolUrlTemplate = "https://console.cloud.google.com/kubernetes/nodepool/{{location}}/{{clusterName}}/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _dataprocClusterUrlTemplate = "https://console.cloud.google.com/dataproc/clusters/{{name}}/monitoring?region={{location}}&project={{tenantRawShortNameId}}";
    private static readonly _dnsManagedZoneUrlTemplate = "https://console.cloud.google.com/net-services/dns/zones/{{name}}/details?project={{tenantRawShortNameId}}";
    private static readonly _fileInstanceUrlTemplate = "https://console.cloud.google.com/firestore/data?project={{tenantRawShortNameId}}";
    private static readonly _functionsFunctionPermissionsUrlTemplate = "https://console.cloud.google.com/functions/details/{{location}}/{{name}}?project={{tenantRawShortNameId}}&tab=permissions";
    private static readonly _functionsFunctionUrlTemplate = "https://console.cloud.google.com/functions/details/{{location}}/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _functionsFunctionVariablesUrlTemplate = "https://console.cloud.google.com/functions/details/{{location}}/{{name}}?project={{tenantRawShortNameId}}&tab=variables";
    private static readonly _iamRoleUrlTemplate = "https://console.cloud.google.com/iam-admin/roles/details/{{urlRawId}}";
    private static readonly _iamServiceAccountKeysUrlTemplate = "https://console.cloud.google.com/iam-admin/serviceaccounts/details/{{uniqueId}}/keys?project={{tenantRawShortNameId}}";
    private static readonly _iamServiceAccountPermissionsUrlTemplate = "https://console.cloud.google.com/iam-admin/serviceaccounts/details/{{uniqueId}}/permissions?project={{tenantRawShortNameId}}";
    private static readonly _iamServiceAccountPrefixUrlTemplate = "https://console.cloud.google.com/iam-admin/serviceaccounts/details/{{uniqueId}}";
    private static readonly _iamServiceAccountsUrlTemplate = "https://console.cloud.google.com/iam-admin/serviceaccounts?project={{tenantRawShortNameId}}";
    private static readonly _iamServiceAccountUrlTemplate = "https://console.cloud.google.com/iam-admin/serviceaccounts/details/{{uniqueId}}?project={{tenantRawShortNameId}}";
    private static readonly _iamWorkforcePoolUrlTemplate = "https://console.cloud.google.com/iam-admin/workforce-identity-pools/pool/{{rawShortId}}?organizationId={{organizationRawShortId}}";
    private static readonly _iamWorkloadIdentityPoolUrlTemplate = "https://console.cloud.google.com/iam-admin/workload-identity-pools/pool/{{rawShortId}}?project={{tenantRawShortNameId}}";
    private static readonly _kmsKeyRingKeyPermissionsUrlTemplate = "https://console.cloud.google.com/security/kms/key/manage/{{location}}/{{keyRingName}}/{{name}};tab=permissions?project={{tenantRawShortNameId}}";
    private static readonly _kmsKeyRingKeyUrlTemplate = "https://console.cloud.google.com/security/kms/key/manage/{{location}}/{{keyRingName}}/{{name}};tab=overview?project={{tenantRawShortNameId}}";
    private static readonly _kmsKeyRingsUrlTemplate = "https://console.cloud.google.com/security/kms/keyrings?project={{tenantRawShortNameId}}";
    private static readonly _kmsKeyRingUrlTemplate = "https://console.cloud.google.com/security/kms/keyring/manage/{{location}}/{{name}}/key?project={{tenantRawShortNameId}}";
    private static readonly _loggingLogBucketUrlTemplate = "https://console.cloud.google.com/logs/storage?{{parentType}}={{parentRawShortId}}";
    private static readonly _loggingLogSinkUrlTemplate = "https://console.cloud.google.com/logs/router?{{parentType}}={{parentRawShortId}}";
    private static readonly _notebooksInstanceUrlTemplate = "https://console.cloud.google.com/vertex-ai/workbench/locations/{{location}}/instances/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _pubSubSubscriptionUrlTemplate = "https://console.cloud.google.com/cloudpubsub/subscription/detail/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _pubSubTopicUrlTemplate = "https://console.cloud.google.com/cloudpubsub/topic/detail/{{name}}?project={{tenantRawShortNameId}}";
    private static readonly _resourceManagerFolderPermissionsUrlTemplate = "https://console.cloud.google.com/iam-admin/iam?folder={{rawShortId}}";
    private static readonly _resourceManagerOrganizationPermissionsUrlTemplate = "https://console.cloud.google.com/iam-admin/iam?organizationId={{rawShortId}}";
    private static readonly _secretManagerSecretEditUrlTemplate = "https://console.cloud.google.com/security/secret-manager/secret/{{name}}/edit?project={{tenantRawShortNameId}}";
    private static readonly _secretManagerSecretUrlTemplate = "https://console.cloud.google.com/security/secret-manager/secret/{{name}}/overview?project={{tenantRawShortNumberId}}";
    private static readonly _spannerInstanceDatabaseUrlTemplate = "https://console.cloud.google.com/spanner/instances/{{instanceName}}/databases/{{databaseName}}";
    private static readonly _spannerInstanceUrlTemplate = "https://console.cloud.google.com/spanner/instances/{{name}}/databases";
    private static readonly _sqlInstanceBackupsUrlTemplate = "https://console.cloud.google.com/sql/instances/{{name}}/backups?project={{tenantRawShortNameId}}";
    private static readonly _sqlInstanceConnectionsUrlTemplate = "https://console.cloud.google.com/sql/instances/{{name}}/connections/networking?project={{tenantRawShortNameId}}";
    private static readonly _sqlInstanceEditUrlTemplate = "https://console.cloud.google.com/sql/instances/{{name}}/edit?project={{tenantRawShortNameId}}";
    private static readonly _sqlInstanceSecurityUrlTemplate = "https://console.cloud.google.com/sql/instances/{{name}}/connections/security?project={{tenantRawShortNameId}}";
    private static readonly _sqlInstanceUrlTemplate = "https://console.cloud.google.com/sql/instances/{{name}}/overview?project={{tenantRawShortNameId}}";
    private static readonly _storageBucketDetailsUrlTemplate = "https://console.cloud.google.com/storage/browser/{{name}};tab=objects";
    private static readonly _storageBucketObjectUrlTemplate = "https://console.cloud.google.com/storage/browser/_details/{{bucketName}}/{{objectName}};tab=live_object";
    private static readonly _storageBucketPermissionsUrlTemplate = "https://console.cloud.google.com/storage/browser/{{name}};tab=permissions";
    private static readonly _tenantEntityApiLibraryUrlTemplate = "https://console.cloud.google.com/apis/library?project={{tenantRawShortNameId}}";
    private static readonly _tenantEntityAuditLogsUrlTemplate = "https://console.cloud.google.com/iam-admin/audit/allservices?project={{tenantRawShortNameId}}";
    private static readonly _tenantEntityLogMetricsUrlTemplate = "https://console.cloud.google.com/logs/metrics?project={{tenantRawShortNameId}}";
    private static readonly _tenantEntityPermissionsUrlTemplate = "https://console.cloud.google.com/iam-admin/iam?project={{tenantRawShortNameId}}";
    private static readonly _tenantEntityUrlTemplate = "https://console.cloud.google.com/welcome?project={{tenantRawShortNameId}}";

public static GetApiKeysKeysUrl(tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._apiKeysKeysUrlTemplate,
            {
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetApiKeysKeyUrl(

        rawId: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._apiKeysKeyUrlTemplate,
            {
                "rawId": rawId,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetAppEngineApplicationServiceUrl(

        applicationName: string,
        serviceName: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._appEngineApplicationServiceUrlTemplate,
            {
                "applicationName": applicationName,
                "serviceName": serviceName
            });
    }

public static GetAppEngineApplicationUrl(tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._appEngineApplicationUrlTemplate,
            {
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetArtifactRegistriesUrl(tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._artifactRegistriesUrlTemplate,
            {
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetArtifactRegistryUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string,
        type: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._artifactRegistryUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId,
                "type": type
            });
    }

public static GetArtifactRepositoryUrl(

        location: string,
        name: string,
        registryName: string,
        registryType: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._artifactRepositoryUrlTemplate,
            {
                "location": location,
                "name": name,
                "registryName": registryName,
                "registryType": registryType,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetBigQueryDatasetTableUrl(

        datasetRawShortId: string,
        rawShortId: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._bigQueryDatasetTableUrlTemplate,
            {
                "datasetRawShortId": datasetRawShortId,
                "rawShortId": rawShortId,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetBigQueryDatasetUrl(

        rawShortId: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._bigQueryDatasetUrlTemplate,
            {
                "rawShortId": rawShortId,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetBigTableClusterUrl(tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._bigTableClusterUrlTemplate,
            {
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetBigTableInstanceUrl(

        rawShortId: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._bigTableInstanceUrlTemplate,
            {
                "rawShortId": rawShortId,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetCloudBuildBuildTriggerUrl(

        location: string,
        rawId: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._cloudBuildBuildTriggerUrlTemplate,
            {
                "location": location,
                "rawId": rawId,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetCloudRunServicesUrl(tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._cloudRunServicesUrlTemplate,
            {
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetCloudRunServiceUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._cloudRunServiceUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeBackendBucketUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeBackendBucketUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeBackendServiceGlobalUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeBackendServiceGlobalUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeBackendServiceRegionUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeBackendServiceRegionUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeDiskRegionUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeDiskRegionUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeDiskZoneUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeDiskZoneUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeFirewallPolicyNonTenantResourceManagerResourceUrl(

        parentRawShortId: string,
        parentType: string,
        rawShortId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeFirewallPolicyNonTenantResourceManagerResourceUrlTemplate,
            {
                "parentRawShortId": parentRawShortId,
                "parentType": parentType,
                "rawShortId": rawShortId
            });
    }

public static GetComputeFirewallPolicyTenantGlobalUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeFirewallPolicyTenantGlobalUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeFirewallPolicyTenantRegionUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeFirewallPolicyTenantRegionUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeFirewallsUrl(tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeFirewallsUrlTemplate,
            {
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeForwardingRuleGlobalUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeForwardingRuleGlobalUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeForwardingRuleRegionUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeForwardingRuleRegionUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeImagesUrl(tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeImagesUrlTemplate,
            {
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeImageUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeImageUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeInstanceGroupUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeInstanceGroupUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeInstancesUrl(tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeInstancesUrlTemplate,
            {
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeInstanceTemplatesUrl(tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeInstanceTemplatesUrlTemplate,
            {
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeInstanceTemplateUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeInstanceTemplateUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeInstanceUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeInstanceUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeLoadBalancerEditPrefixUrl() {
        return GcpConsoleUrlBuilder._computeLoadBalancerEditPrefixUrlTemplate;
    }

public static GetComputeLoadBalancerHttpClassicProxyEditUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeLoadBalancerHttpClassicProxyEditUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeLoadBalancerHttpClassicProxyUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeLoadBalancerHttpClassicProxyUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeLoadBalancerHttpInternalProxyEditUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeLoadBalancerHttpInternalProxyEditUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeLoadBalancerHttpInternalProxyUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeLoadBalancerHttpInternalProxyUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeLoadBalancerHttpProxyEditGlobalUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeLoadBalancerHttpProxyEditGlobalUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeLoadBalancerHttpProxyEditRegionUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeLoadBalancerHttpProxyEditRegionUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeLoadBalancerHttpProxyGlobalUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeLoadBalancerHttpProxyGlobalUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeLoadBalancerHttpProxyRegionUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeLoadBalancerHttpProxyRegionUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeLoadBalancerNetworkBackendServiceEditUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeLoadBalancerNetworkBackendServiceEditUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeLoadBalancerNetworkBackendServiceUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeLoadBalancerNetworkBackendServiceUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeLoadBalancerNetworkInternalEditUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeLoadBalancerNetworkInternalEditUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeLoadBalancerNetworkInternalUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeLoadBalancerNetworkInternalUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeLoadBalancerNetworkTargetPoolEditUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeLoadBalancerNetworkTargetPoolEditUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeLoadBalancerNetworkTargetPoolUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeLoadBalancerNetworkTargetPoolUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeLoadBalancerPrefixUrl() {
        return GcpConsoleUrlBuilder._computeLoadBalancerPrefixUrlTemplate;
    }

public static GetComputeLoadBalancerResourcePrefixUrl() {
        return GcpConsoleUrlBuilder._computeLoadBalancerResourcePrefixUrlTemplate;
    }

public static GetComputeLoadBalancerSslTcpProxyEditGlobalUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeLoadBalancerSslTcpProxyEditGlobalUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeLoadBalancerSslTcpProxyEditRegionUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeLoadBalancerSslTcpProxyEditRegionUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeLoadBalancerSslTcpProxyGlobalUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeLoadBalancerSslTcpProxyGlobalUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeLoadBalancerSslTcpProxyRegionUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeLoadBalancerSslTcpProxyRegionUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeLoadBalancerTcpInternalEditUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeLoadBalancerTcpInternalEditUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeLoadBalancerTcpInternalProxyUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeLoadBalancerTcpInternalProxyUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeLoadBalancingPrefixUrl() {
        return GcpConsoleUrlBuilder._computeLoadBalancingPrefixUrlTemplate;
    }

public static GetComputeNetworkEndpointGroupGlobalUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeNetworkEndpointGroupGlobalUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeNetworkEndpointGroupRegionUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeNetworkEndpointGroupRegionUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeNetworkEndpointGroupZoneUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeNetworkEndpointGroupZoneUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeSnapshotUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeSnapshotUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeSslCertificateGlobalUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeSslCertificateGlobalUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeSslCertificateRegionUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeSslCertificateRegionUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeSslPolicyEditUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeSslPolicyEditUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeSslPolicyUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeSslPolicyUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeSubnetUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeSubnetUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeTargetHttpProxyUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeTargetHttpProxyUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeTargetHttpsProxyUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeTargetHttpsProxyUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeTargetPoolUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeTargetPoolUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeTargetSslProxyUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeTargetSslProxyUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeTargetTcpProxyUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeTargetTcpProxyUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeTenantMetadataUrl(tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeTenantMetadataUrlTemplate,
            {
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeVpcFirewallRulesUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeVpcFirewallRulesUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeVpcPeeringConnectionUrl(

        name: string,
        requesterVpcName: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeVpcPeeringConnectionUrlTemplate,
            {
                "name": name,
                "requesterVpcName": requesterVpcName,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeVpcSubnetsUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeVpcSubnetsUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetComputeVpcUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._computeVpcUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetContainerClusterUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._containerClusterUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetContainerNodePoolUrl(

        clusterName: string,
        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._containerNodePoolUrlTemplate,
            {
                "clusterName": clusterName,
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetDataprocClusterUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._dataprocClusterUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetDnsManagedZoneUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._dnsManagedZoneUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetFileInstanceUrl(tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._fileInstanceUrlTemplate,
            {
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetFunctionsFunctionPermissionsUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._functionsFunctionPermissionsUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetFunctionsFunctionUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._functionsFunctionUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetFunctionsFunctionVariablesUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._functionsFunctionVariablesUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetIamRoleUrl(urlRawId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._iamRoleUrlTemplate,
            {
                "urlRawId": urlRawId
            });
    }

public static GetIamServiceAccountKeysUrl(

        tenantRawShortNameId: string,
        uniqueId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._iamServiceAccountKeysUrlTemplate,
            {
                "tenantRawShortNameId": tenantRawShortNameId,
                "uniqueId": uniqueId
            });
    }

public static GetIamServiceAccountPermissionsUrl(

        tenantRawShortNameId: string,
        uniqueId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._iamServiceAccountPermissionsUrlTemplate,
            {
                "tenantRawShortNameId": tenantRawShortNameId,
                "uniqueId": uniqueId
            });
    }

public static GetIamServiceAccountPrefixUrl(uniqueId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._iamServiceAccountPrefixUrlTemplate,
            {
                "uniqueId": uniqueId
            });
    }

public static GetIamServiceAccountsUrl(tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._iamServiceAccountsUrlTemplate,
            {
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetIamServiceAccountUrl(

        tenantRawShortNameId: string,
        uniqueId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._iamServiceAccountUrlTemplate,
            {
                "tenantRawShortNameId": tenantRawShortNameId,
                "uniqueId": uniqueId
            });
    }

public static GetIamWorkforcePoolUrl(

        organizationRawShortId: string,
        rawShortId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._iamWorkforcePoolUrlTemplate,
            {
                "organizationRawShortId": organizationRawShortId,
                "rawShortId": rawShortId
            });
    }

public static GetIamWorkloadIdentityPoolUrl(

        rawShortId: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._iamWorkloadIdentityPoolUrlTemplate,
            {
                "rawShortId": rawShortId,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetKmsKeyRingKeyPermissionsUrl(

        keyRingName: string,
        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._kmsKeyRingKeyPermissionsUrlTemplate,
            {
                "keyRingName": keyRingName,
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetKmsKeyRingKeyUrl(

        keyRingName: string,
        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._kmsKeyRingKeyUrlTemplate,
            {
                "keyRingName": keyRingName,
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetKmsKeyRingsUrl(tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._kmsKeyRingsUrlTemplate,
            {
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetKmsKeyRingUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._kmsKeyRingUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetLoggingLogBucketUrl(

        parentRawShortId: string,
        parentType: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._loggingLogBucketUrlTemplate,
            {
                "parentRawShortId": parentRawShortId,
                "parentType": parentType
            });
    }

public static GetLoggingLogSinkUrl(

        parentRawShortId: string,
        parentType: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._loggingLogSinkUrlTemplate,
            {
                "parentRawShortId": parentRawShortId,
                "parentType": parentType
            });
    }

public static GetNotebooksInstanceUrl(

        location: string,
        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._notebooksInstanceUrlTemplate,
            {
                "location": location,
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetPubSubSubscriptionUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._pubSubSubscriptionUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetPubSubTopicUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._pubSubTopicUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetResourceManagerFolderPermissionsUrl(rawShortId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._resourceManagerFolderPermissionsUrlTemplate,
            {
                "rawShortId": rawShortId
            });
    }

public static GetResourceManagerOrganizationPermissionsUrl(rawShortId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._resourceManagerOrganizationPermissionsUrlTemplate,
            {
                "rawShortId": rawShortId
            });
    }

public static GetSecretManagerSecretEditUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._secretManagerSecretEditUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetSecretManagerSecretUrl(

        name: string,
        tenantRawShortNumberId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._secretManagerSecretUrlTemplate,
            {
                "name": name,
                "tenantRawShortNumberId": tenantRawShortNumberId
            });
    }

public static GetSpannerInstanceDatabaseUrl(

        databaseName: string,
        instanceName: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._spannerInstanceDatabaseUrlTemplate,
            {
                "databaseName": databaseName,
                "instanceName": instanceName
            });
    }

public static GetSpannerInstanceUrl(name: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._spannerInstanceUrlTemplate,
            {
                "name": name
            });
    }

public static GetSqlInstanceBackupsUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._sqlInstanceBackupsUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetSqlInstanceConnectionsUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._sqlInstanceConnectionsUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetSqlInstanceEditUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._sqlInstanceEditUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetSqlInstanceSecurityUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._sqlInstanceSecurityUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetSqlInstanceUrl(

        name: string,
        tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._sqlInstanceUrlTemplate,
            {
                "name": name,
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetStorageBucketDetailsUrl(name: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._storageBucketDetailsUrlTemplate,
            {
                "name": name
            });
    }

public static GetStorageBucketObjectUrl(

        bucketName: string,
        objectName: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._storageBucketObjectUrlTemplate,
            {
                "bucketName": bucketName,
                "objectName": objectName
            });
    }

public static GetStorageBucketPermissionsUrl(name: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._storageBucketPermissionsUrlTemplate,
            {
                "name": name
            });
    }

public static GetTenantEntityApiLibraryUrl(tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._tenantEntityApiLibraryUrlTemplate,
            {
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetTenantEntityAuditLogsUrl(tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._tenantEntityAuditLogsUrlTemplate,
            {
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetTenantEntityLogMetricsUrl(tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._tenantEntityLogMetricsUrlTemplate,
            {
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetTenantEntityPermissionsUrl(tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._tenantEntityPermissionsUrlTemplate,
            {
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }

public static GetTenantEntityUrl(tenantRawShortNameId: string) {
        return Mustache.render(
            GcpConsoleUrlBuilder._tenantEntityUrlTemplate,
            {
                "tenantRawShortNameId": tenantRawShortNameId
            });
    }
}