import { CollapsedIcon, IconText, Link, TextViewer } from "@infrastructure";
import { Accordion, AccordionDetails, AccordionSummary, Divider, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { CodeConsoleUrlHelper, CodeTypeIcon, useCodeTypeTranslator } from "../..";
import { CodeTenantLink, Contract, tenantModelStore, useTheme } from "../../../common";

type SnippetProps = {
    codeType: Contract.CodeType;
    snippet: Contract.CodeRiskSnippet;
    tenantId: string;
};

export function Snippet({ codeType, snippet, tenantId }: SnippetProps) {
    const tenantConfiguration = tenantModelStore.useGet(tenantId).configuration as Contract.CodeTenantConfiguration;

    const codeTypeTranslator = useCodeTypeTranslator();

    const theme = useTheme();
    return (
        <Accordion defaultExpanded={true}>
            <AccordionSummary expandIcon={<CollapsedIcon/>}>
                <Stack
                    alignItems="center"
                    flexDirection="row"
                    sx={{ maxWidth: "100%" }}>
                    <CodeTenantLink tenantConfiguration={tenantConfiguration}/>
                    <Divider
                        flexItem={true}
                        orientation="vertical"
                        sx={{ margin: theme.spacing(0, 1) }}/>
                    <IconText
                        containerSx={{ paddingRight: 0 }}
                        icon={
                            <CodeTypeIcon
                                codeType={codeType}
                                sx={{
                                    color: theme.palette.text.secondary,
                                    fontSize: "18px"
                                }}/>}
                        spacing={0.5}
                        text={
                            <Link
                                noWrap={true}
                                sx={{
                                    direction: "ltr",
                                    maxWidth: "100%",
                                    "unicode-bidi": "embed"
                                }}
                                urlOrGetUrl={
                                    CodeConsoleUrlHelper.getCodeFileLineUrl(
                                        codeType,
                                        snippet.commit.hash,
                                        snippet.fileRelativePath,
                                        _.first(snippet.textBlock.highlightedLines)!,
                                        snippet.tenantEntityData.type,
                                        snippet.tenantEntityData.url)}
                                variant="external">
                                <Typography noWrap={true}>
                                    {snippet.fileRelativePath}
                                </Typography>
                            </Link>}
                        titleOrGetTitle={codeTypeTranslator(codeType)}/>
                </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: theme.spacing(2) }}>
                <TextViewer
                    format={
                        codeType === Contract.CodeType.Terraform
                            ? "hcl"
                            : "json"}
                    height={theme.spacing(30)}
                    highlightedLines={snippet.textBlock.highlightedLines}
                    startLine={snippet.textBlock.startLine}
                    text={snippet.textBlock.text}/>
            </AccordionDetails>
        </Accordion>);
}