﻿import { Message, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, InfoItem, useActivityTranslator, usePrincipalModelAccess } from "../../../../../../../common";

type AadDirectoryPrincipalActivityInfoItemProps = {
    principalModel: Contract.EntityModel;
};

export function AadDirectoryPrincipalActivityInfoItem({ principalModel }: AadDirectoryPrincipalActivityInfoItemProps) {
    const principalModelAccess = usePrincipalModelAccess<Contract.PrincipalModelAccess>(principalModel.id);

    const activityTranslator = useActivityTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.aadDirectoryPrincipalActivityInfoItem",
            () => ({
                helpText: "The last time a management write activity was seen in Azure for this identity, since read-only activity isn't logged in Azure.",
                title: "Azure Activity Time"
            }));

    return (
        <InfoItem
            title={localization.title()}
            titleInfoElement={
                <Message
                    level="info"
                    title={localization.helpText()}
                    variant="minimal"/>}
            value={activityTranslator(principalModelAccess.activity)}/>);
}