import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { useTheme } from "../../../../../../../common";

export function ExportIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon fill="none" height="24" viewBox="0 0 24 24" width="24" {...props}>
            <path
                d="M8.94367 6.2008C8.61774 5.82048 8.61774 5.23236 8.94367 4.85204L10.9485 2.51302C11.5349 1.82899 12.5932 1.82899 13.1795 2.51303L15.1845 4.85212C15.5103 5.23242 15.5103 5.82042 15.1843 6.20092C14.8176 6.62858 14.1851 6.62858 13.8183 6.20083L12.9912 5.23676L12.9916 16.0048C12.9916 16.5 12.6502 16.9337 12.1758 16.9931L12.064 17C11.5324 17 11.1365 16.5381 11.1365 16.0048L11.1364 5.23676L10.3097 6.20088C9.97127 6.59569 9.40616 6.62606 9.03278 6.29193L8.94367 6.2008Z"
                fill={theme.palette.text.primary}/>
            <path
                clipRule="evenodd"
                d="M4 13C4.55228 13 5 13.4477 5 14V19C5 19.5523 5.44771 20 6 20H18C18.5523 20 19 19.5523 19 19V14C19 13.4477 19.4477 13 20 13C20.5523 13 21 13.4477 21 14V19C21 20.6569 19.6569 22 18 22H6C4.34315 22 3 20.6569 3 19V14C3 13.4477 3.44772 13 4 13Z"
                fill={theme.palette.text.primary}
                fillRule="evenodd"/>
        </SvgIcon>);
}