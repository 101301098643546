import React from "react";
import { Profile, ProfileActions } from "../../../../WorkloadAnalysis/components";
import { SideViewItem } from "../../../SideView";
import { SideViewDefinition } from "../useDefinition";

export function useMaliciousFileDefinition(item: SideViewItem): SideViewDefinition {
    return {
        getActionsElement:
            () =>
                <ProfileActions
                    contentSha256String={item.id}
                    key={`${item.id}-actions`}/>,
        getViewElement:
            () =>
                <Profile
                    contentSha256String={item.id}
                    key={`${item.id}-profile`}/>
    };
}