﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsDocDbClusterModelTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsDocDbClusterModelTypeTranslator",
            () => ({
                [Contract.TypeNames.AwsDocDbClusterModelType]: {
                    [Contract.AwsDocDbClusterModelType.Elastic]: "Elastic",
                    [Contract.AwsDocDbClusterModelType.Instance]: "Instance Based"
                }
            }));
    return (type: Contract.AwsDocDbClusterModelType) =>
        localization[Contract.TypeNames.AwsDocDbClusterModelType][type]();
}