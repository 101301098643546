﻿import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useGetEntitiesRiskContext } from "../../../useGetEntitiesRiskContext";

export function useGetGcpComputeLoadBalancersRiskContext() {
    return useMemo(
        () => useGcpComputeLoadBalancersRiskContext,
        []);
}

function useGcpComputeLoadBalancersRiskContext(loadBalancerIdsOrModels: string[] | Contract.GcpComputeLoadBalancerModel[]) {
    return useGetEntitiesRiskContext()(
        loadBalancerIdsOrModels,
        Contract.TypeNames.GcpComputeLoadBalancer);
}