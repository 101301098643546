import { Box, Stack, Tab, Tabs } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { map, useLocalization } from "@infrastructure";
import { useAddOrEditContext } from "../..";
import { useTheme } from "../../../../../../../../../../../../../../../../common";
import { Arm, Manual, PowerShell } from "./components";

export function RoleDefinitionsItem() {
    const addOrEditContext = useAddOrEditContext();

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.azure.addOrEdit.roleDefinitionsItem",
            () => ({
                onboardingType: {
                    [OnboardingType.Arm]: "ARM (Azure Portal)",
                    [OnboardingType.Manual]: "Manual (Azure Portal)",
                    [OnboardingType.PowerShell]: "PowerShell (Azure CLI)"
                }
            }));

    const onboardingTypes =
        useMemo(
            () =>
                _<OnboardingType>([]).
                    concatIf(
                        _.isNil(addOrEditContext.organizationConfiguration),
                        OnboardingType.Arm,
                        OnboardingType.PowerShell).
                    concat(OnboardingType.Manual).
                    value(),
            []);

    const [selectedOnboardingType, setSelectedOnboardingType] = useState(() => _.first(onboardingTypes));
    const theme = useTheme();
    return (
        <Stack
            spacing={2}
            sx={{ height: "100%" }}>
            <Tabs
                indicatorColor="primary"
                value={selectedOnboardingType}
                variant="standard"
                onChange={(_, value) => setSelectedOnboardingType(value)}>
                {_.map(
                    onboardingTypes,
                    onboardingType =>
                        <Tab
                            key={onboardingType}
                            label={localization.onboardingType[onboardingType]()}
                            sx={{ padding: theme.spacing(1, 2) }}
                            value={onboardingType}/>)}
            </Tabs>
            <Stack
                key={selectedOnboardingType}
                sx={{ overflow: "hidden auto" }}>
                <Box sx={{ maxWidth: theme.spacing(80) }}>
                    {map(
                        selectedOnboardingType,
                        {
                            [OnboardingType.Arm]: () => <Arm/>,
                            [OnboardingType.Manual]: () => <Manual/>,
                            [OnboardingType.PowerShell]: () => <PowerShell/>
                        })}
                </Box>
            </Stack>
        </Stack>);
}

enum OnboardingType {
    Arm = "arm",
    Manual = "manual",
    PowerShell = "powerShell"
}