import { TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, InfoItem, tenantModelStore } from "../../../../../../../../../../common";
import { AwsTenantHelper } from "../../../../../../../../../../tenants";
import { EntitiesInfoCard, Info } from "../../../../components";
import { AwsIamPolicyRevisions, AwsIamPolicyRevisionsRevision } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAwsDefaultResourceInfoItemElements } from "./useAwsDefaultResourceInfoItemElements";

export function useAwsIamManagedPolicyDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const managedPolicy = resourceModel.entity as Contract.AwsIamManagedPolicy;
    const managedPolicyModel = resourceModel as Contract.AwsIamManagedPolicyModel;
    const tenantModelMap = tenantModelStore.useGetAwsTenantMap();

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsIamManagedPolicyDefinition",
            () => ({
                info: {
                    cards: {
                        permissionAttached: "Applied to as permission policy",
                        permissionBoundaryAttached: "Applied to as permission boundary policy"
                    },
                    items: {
                        awsManaged: {
                            false: "Customer Managed",
                            title: "Management",
                            true: "AWS Managed"
                        },
                        usageTime: {
                            empty: "Never",
                            title: "Usage Time"
                        }
                    }
                },
                tabs: {
                    policyRevisions: "Versions"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <AwsIamPolicyRevisions
                    defaultRevisionIndex={_.toInteger(managedPolicy.defaultRevisionId.substring(1))}
                    revisions={
                        _.map(
                            managedPolicy.revisionMap,
                            revision =>
                                new AwsIamPolicyRevisionsRevision(
                                    _.toInteger(revision.id.substring(1)),
                                    revision.document,
                                    revision.creationTime))}/>,
                localization.tabs.policyRevisions(),
                "policyRevisions")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="awsManaged"
                        title={localization.info.items.awsManaged.title()}
                        value={
                            managedPolicy.awsManaged
                                ? localization.info.items.awsManaged.true()
                                : localization.info.items.awsManaged.false()}/>,
                    AwsTenantHelper.isAccessAdvisorEnabled(resourceModel.entity, tenantModelMap) &&
                    managedPolicyModel.management !== Contract.AwsIamPrincipalPolicyModelManagement.AwsManaged
                        ? <InfoItem
                            key="usageTime"
                            title={localization.info.items.usageTime.title()}
                            value={
                                _.isNil(managedPolicyModel.permissionUsageTime)
                                    ? localization.info.items.usageTime.empty()
                                    : TimeFormatter.profileFormatDateTime(managedPolicyModel.permissionUsageTime)}/>
                        : undefined
                ]}
                options={options?.infoOptions}>
                <EntitiesInfoCard
                    entityIdsOrModels={managedPolicyModel.permissionBoundaryAttachedIdentityIds}
                    entityTypeName={Contract.TypeNames.AwsIamPrincipal}
                    title={localization.info.cards.permissionBoundaryAttached()}/>
                <EntitiesInfoCard
                    entityIdsOrModels={managedPolicyModel.permissionAttachedPrincipalIds}
                    entityTypeName={Contract.TypeNames.AwsIamPrincipal}
                    title={localization.info.cards.permissionAttached()}/>
            </Info>,
        sensitive: false
    });
}