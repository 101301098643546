import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function OpenIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M12.0035 7C12.5533 7 12.999 7.44772 12.999 8V13C12.999 13.5523 12.5533 14 12.0035 14C11.4537 14 11.008 13.5523 11.008 13V8C11.008 7.44772 11.4537 7 12.0035 7ZM11 16C11 15.4477 11.4457 15 11.9955 15H12.0045C12.5543 15 13 15.4477 13 16C13 16.5523 12.5543 17 12.0045 17H11.9955C11.4457 17 11 16.5523 11 16Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M1 12C1 5.92472 5.92472 1 12 1C18.0753 1 23 5.92472 23 12C23 18.0753 18.0753 23 12 23C5.92472 23 1 18.0753 1 12ZM12 3C7.02928 3 3 7.02928 3 12C3 16.9707 7.02928 21 12 21C16.9707 21 21 16.9707 21 12C21 7.02928 16.9707 3 12 3Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}