import { Steps, useLocalization, useLocalizeList } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { RiskContentProps } from "../../../..";
import { Contract, Entity, entityModelStore, InlineEntities, InlinePermissionActions, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { InlineAwsIamGroupUsers } from "../../../../../../../../../../../../tenants";
import { useAwsCommonAccessPrincipalRiskDefinition } from "./useAwsCommonAccessPrincipalRiskDefinition";

export function useAwsSeverePermissionPrincipalRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsSeverePermissionPrincipalRisk;
    const principalModel = entityModelStore.useGet(risk.entityId);
    const principal = principalModel.entity as Contract.AwsIamPrincipal;
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.access.useAwsSeverePermissionPrincipalRiskDefinition",
            () => ({
                description: {
                    roleOriginatorResourcesPart: ", linked to {{roleOriginatorResources}},",
                    [Contract.TypeNames.AwsIamGroup]: {
                        [Contract.TypeNames.PrincipalSeverePermissionActionRiskType]: {
                            [Contract.PrincipalSeverePermissionActionRiskType.AdministratorPrincipal]: [
                                "{{groupUsers}} was recently granted admin privileges through {{principal}}",
                                "{{groupUsers}} were recently granted admin privileges through {{principal}}"
                            ],
                            [Contract.PrincipalSeverePermissionActionRiskType.SeverePermissionActionPrincipal]: [
                                "{{groupUsers}} was recently granted privileges that allow it to escalate to an admin through {{principal}}",
                                "{{groupUsers}} were recently granted privileges that allow them to escalate to admin through {{principal}}"
                            ]
                        }
                    },
                    [Contract.TypeNames.AwsIamIdentity]: {
                        [Contract.TypeNames.PrincipalSeverePermissionActionRiskType]: {
                            [Contract.PrincipalSeverePermissionActionRiskType.AdministratorPrincipal]: "{{principal}}{{roleOriginatorResourcesPart}} was recently granted admin privileges",
                            [Contract.PrincipalSeverePermissionActionRiskType.SeverePermissionActionPrincipal]: "{{principal}}{{roleOriginatorResourcesPart}} was recently granted privileges that allow it to escalate to an admin"
                        }
                    }
                }
            }));

    return useAwsCommonAccessPrincipalRiskDefinition(
        Contract.EntityAccessScope.Full,
        <ContextSection riskModel={riskModel}/>,
        principal.typeName === Contract.TypeNames.AwsIamGroup
            ? localization.description[Contract.TypeNames.AwsIamGroup][Contract.TypeNames.PrincipalSeverePermissionActionRiskType][risk.type](
                risk.groupUserIds.length,
                {
                    groupUsers:
                        _.isEmpty(risk.groupUserIds)
                            ? undefined
                            : <InlineEntities
                                entityIdsOrModels={risk.groupUserIds}
                                entityTypeName={Contract.TypeNames.AwsIamIdentity}
                                entityVariant="iconTextTypeTenant"
                                variant="itemCountAndType"/>,
                    principal:
                        <Entity
                            entityIdOrModel={principalModel}
                            variant="typeText"/>
                })
            : localization.description[Contract.TypeNames.AwsIamIdentity][Contract.TypeNames.PrincipalSeverePermissionActionRiskType][risk.type]({
                principal:
                    <Entity
                        entityIdOrModel={principalModel}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="typeText"/>,
                roleOriginatorResourcesPart:
                    _.isEmpty(risk.roleMostIndirectOriginatorResourceIds)
                        ? ""
                        : <Typography component="span">
                            {localization.description.roleOriginatorResourcesPart({
                                roleOriginatorResources:
                                    <InlineEntities
                                        entityIdsOrModels={risk.roleMostIndirectOriginatorResourceIds}
                                        entityTypeName={Contract.TypeNames.IAwsOriginatorWorkloadResource}
                                        variant="itemAndTypeOrItemCountAndType"/>
                            })}
                        </Typography>
            }),
        riskModel);
}

function ContextSection({ riskModel }: RiskContentProps) {
    const severePermissionPrincipalRiskModel = riskModel as Contract.AwsSeverePermissionPrincipalRiskModel;
    const principalModel = entityModelStore.useGet(severePermissionPrincipalRiskModel.risk.entityId) as Contract.AwsIamPrincipalModel;
    const severePermissionActionPermittingEntityModels = entityModelStore.useGet(severePermissionPrincipalRiskModel.risk.severePermissionActionPermittingEntityIds);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.access.useAwsSeverePermissionPrincipalRiskDefinition.contextSection",
            () => ({
                [Contract.TypeNames.AwsSeverePermissionPrincipalRiskModelInfo]: {
                    [Contract.AwsSeverePermissionPrincipalRiskModelInfo.GroupUsers]: "{{translatedPrincipalTypeName}} {{principal}} has {{groupUsers}}",
                    [Contract.AwsSeverePermissionPrincipalRiskModelInfo.IdentityCreationTime]: "{{translatedPrincipalTypeName}} {{principal}} was created on: {{creationTime | TimeFormatter.longDate}}",
                    [Contract.AwsSeverePermissionPrincipalRiskModelInfo.RoleMostIndirectOriginatorResources]: "{{principal}} is linked to {{roleOriginatorResources}}",
                    [Contract.AwsSeverePermissionPrincipalRiskModelInfo.SeverePermissionActionPermittingEntities]: {
                        [Contract.PrincipalSeverePermissionActionRiskType.AdministratorPrincipal]: "Admin privileges granted through: {{severePermissionActionPermittingEntities}}",
                        [Contract.PrincipalSeverePermissionActionRiskType.SeverePermissionActionPrincipal]: "New privileges granted through: {{severePermissionActionPermittingEntities}}"
                    },
                    [Contract.AwsSeverePermissionPrincipalRiskModelInfo.SeverePermissionActions]: {
                        [Contract.PrincipalSeverePermissionActionRiskType.AdministratorPrincipal]: "Admin privileges are: {{severePermissionActions}}",
                        [Contract.PrincipalSeverePermissionActionRiskType.SeverePermissionActionPrincipal]: "New privileges are: {{severePermissionActions}}"
                    },
                    [Contract.AwsSeverePermissionPrincipalRiskModelInfo.SeverePermissionActionStartTime]: {
                        [Contract.PrincipalSeverePermissionActionRiskType.AdministratorPrincipal]: "Admin privileges granted on: {{severePermissionActionStartTime | TimeFormatter.longDate}}",
                        [Contract.PrincipalSeverePermissionActionRiskType.SeverePermissionActionPrincipal]: "New privileges granted on: {{severePermissionActionStartTime | TimeFormatter.longDate}}"
                    }
                }
            }));

    const createInfoProps =
        () => ({
            creationTime: principalModel.creationTime,
            groupUsers:
                _.isEmpty(severePermissionPrincipalRiskModel.risk.groupUserIds)
                    ? undefined
                    : <InlineAwsIamGroupUsers userIds={severePermissionPrincipalRiskModel.risk.groupUserIds}/>,
            principal:
                <Entity
                    entityIdOrModel={principalModel}
                    variant="text"/>,
            roleOriginatorResources:
                _.isEmpty(severePermissionPrincipalRiskModel.risk.roleMostIndirectOriginatorResourceIds)
                    ? undefined
                    : <InlineEntities
                        entityIdsOrModels={severePermissionPrincipalRiskModel.risk.roleMostIndirectOriginatorResourceIds}
                        entityTypeName={Contract.TypeNames.IAwsOriginatorWorkloadResource}
                        variant="itemAndTypeOrItemCountAndType"/>,
            severePermissionActionPermittingEntities:
                localizeList(
                    _(severePermissionActionPermittingEntityModels).
                        groupBy(severePermissionActionPermittingEntityModel => severePermissionActionPermittingEntityModel.entity.typeName).
                        map(
                            (severePermissionActionPermittingEntityModels, severePermissionActionPermittingEntityTypeName) =>
                                <InlineEntities
                                    entityIdsOrModels={severePermissionActionPermittingEntityModels}
                                    entityTypeName={severePermissionActionPermittingEntityTypeName}
                                    key={severePermissionActionPermittingEntityTypeName}
                                    variant="itemCountAndType"/>).
                        value()),
            severePermissionActions:
                <InlinePermissionActions
                    permissionActions={severePermissionPrincipalRiskModel.risk.severePermissionActions}
                    variant="itemOrItemCountAndType"/>,
            severePermissionActionStartTime: severePermissionPrincipalRiskModel.risk.severePermissionActionStartTime,
            translatedPrincipalTypeName:
                entityTypeNameTranslator(
                    principalModel.entity.typeName,
                    {
                        includeServiceName: false
                    })
        });
    return (
        <Steps>
            {_.map(
                severePermissionPrincipalRiskModel.infos,
                info => {
                    switch (info) {
                        case Contract.AwsSeverePermissionPrincipalRiskModelInfo.GroupUsers:
                        case Contract.AwsSeverePermissionPrincipalRiskModelInfo.IdentityCreationTime:
                        case Contract.AwsSeverePermissionPrincipalRiskModelInfo.RoleMostIndirectOriginatorResources:
                            return localization[Contract.TypeNames.AwsSeverePermissionPrincipalRiskModelInfo][info](createInfoProps());
                        case Contract.AwsSeverePermissionPrincipalRiskModelInfo.SeverePermissionActionPermittingEntities:
                        case Contract.AwsSeverePermissionPrincipalRiskModelInfo.SeverePermissionActions:
                        case Contract.AwsSeverePermissionPrincipalRiskModelInfo.SeverePermissionActionStartTime:
                            return localization[Contract.TypeNames.AwsSeverePermissionPrincipalRiskModelInfo][info][severePermissionPrincipalRiskModel.risk.type](createInfoProps());
                    }
                })}
        </Steps>);
}