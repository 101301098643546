import { Link, Message, useExecuteOperation, useLocalization } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, EntityController, InfoCard, InfoItem, useEntityTypeNameTranslator, useTheme } from "../../../../../../../../../../common";
import { AzureKeyVaultVaultAccessPoliciesTable } from "../../../../../../../../../../tenants";
import { EntitiesInfoItem, EntityTypeNameGroupsInfoCard, Info } from "../../../../components";
import { AzureFirewallInfoCard, AzureResourceRoleAssignmentResources, NetworkingInfoCard } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAzureDefaultResourceInfoItemElements } from "./useAzureDefaultResourceInfoItemElements";

export function useAzureKeyVaultVaultDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);
    const vault = resourceGroupResourceModel.entity as Contract.AzureKeyVaultVault;
    const vaultModel = resourceGroupResourceModel as Contract.AzureKeyVaultVaultModel;
    const [{ certificateIds, keyIds, secretIds }] =
        useExecuteOperation(
            [useAzureKeyVaultVaultDefinition, vaultModel.id],
            async () => await EntityController.getAzureKeyVaultVaultObjectIds(new Contract.EntityControllerGetAzureKeyVaultVaultObjectIdsRequest(vault.id)));

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureKeyVaultVaultDefinition",
            () => ({
                info: {
                    cards: {
                        accessPolices: {
                            title: "Access Policies"
                        },
                        firewallRules: {
                            title: "Firewall Rules"
                        },
                        vaultObjectIds: {
                            certificates: "Some certificates might not be displayed",
                            emptyMessage: "No Objects",
                            title: "Objects",
                            warningMessage: "Since this key vault uses vault access policy as its permission model, Tenable Cloud Security can't access vault certificates. To grant Tenable Cloud Security access, switch the model to RBAC."
                        }
                    },
                    items: {
                        eventEnabled: {
                            false: "Disabled",
                            title: "Audit Event",
                            true: "Enabled"
                        },
                        publicNetworkAccess: {
                            false: "Disabled",
                            title: "Public Network Access",
                            true: {
                                allNetworks: "All Networks",
                                someNetworks: "Selected Networks"
                            }
                        },
                        rbacEnabled: {
                            false: "Vault access policy",
                            title: "Permission Model",
                            true: "Azure role-based access control"
                        },
                        softDelete: {
                            purgeProtection: {
                                false: "Disabled",
                                title: "Purge Protection",
                                true: "Enabled"
                            },
                            retentionTimeFrame: {
                                disabled: "Disabled",
                                text: "{{softDeleteRetentionTimeFrame | TimeSpanFormatter.humanizeDays}}",
                                title: "Soft Delete Retention Period"
                            }
                        },
                        url: "Vault URI"
                    }
                },
                tabs: {
                    resourceRoleAssignments: "Resource Role Assignments"
                }
            }));

    const theme = useTheme();
    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AzureResourceRoleAssignmentResources
                    csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                    scopeResourceModel={resourceGroupResourceModel}/>,
                localization.tabs.resourceRoleAssignments(),
                "resourceRoleAssignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="url"
                        location="all"
                        title={localization.info.items.url()}
                        value={
                            <Link
                                urlOrGetUrl={vault.url}
                                variant="external"/>}/>,
                    <InfoItem
                        key="eventEnabled"
                        title={localization.info.items.eventEnabled.title()}
                        value={
                            vaultModel.eventEnabled
                                ? localization.info.items.eventEnabled.true()
                                : localization.info.items.eventEnabled.false()}/>,
                    <InfoItem
                        key="rbacEnabled"
                        location="all"
                        title={localization.info.items.rbacEnabled.title()}
                        value={
                            vault.rbacEnabled
                                ? localization.info.items.rbacEnabled.true()
                                : localization.info.items.rbacEnabled.false()}/>,
                    <InfoItem
                        key="softDeleteRetentionTimeFrame"
                        title={localization.info.items.softDelete.retentionTimeFrame.title()}
                        value={
                            _.isNil(vault.softDelete)
                                ? localization.info.items.softDelete.retentionTimeFrame.disabled()
                                : localization.info.items.softDelete.retentionTimeFrame.text({ softDeleteRetentionTimeFrame: vault.softDelete.retentionTimeFrame })}/>,
                    <InfoItem
                        key="softDeletePurgeProtection"
                        title={localization.info.items.softDelete.purgeProtection.title()}
                        value={
                            _.isNil(vault.softDelete)
                                ? undefined
                                : vault.softDelete.purgeProtection
                                    ? localization.info.items.softDelete.purgeProtection.true()
                                    : localization.info.items.softDelete.purgeProtection.false()}/>,
                    <InfoItem
                        key="publicNetworkAccess"
                        title={localization.info.items.publicNetworkAccess.title()}
                        value={
                            vault.publicNetworkAccess
                                ? vault.allNetworkAccess
                                    ? localization.info.items.publicNetworkAccess.true.allNetworks()
                                    : localization.info.items.publicNetworkAccess.true.someNetworks()
                                : localization.info.items.publicNetworkAccess.false()}/>
                ]}
                options={options?.infoOptions}>
                <EntityTypeNameGroupsInfoCard
                    emptyMessage={localization.info.cards.vaultObjectIds.emptyMessage()}
                    entityTypeNameToIdsMap={{
                        [Contract.TypeNames.AzureKeyVaultVaultKey]: keyIds,
                        [Contract.TypeNames.AzureKeyVaultVaultSecret]: secretIds,
                        [Contract.TypeNames.AzureKeyVaultVaultCertificate]: certificateIds
                    }}
                    title={
                        <Stack
                            alignItems="center"
                            direction="row">
                            <Typography
                                noWrap={true}
                                variant="h3">
                                {localization.info.cards.vaultObjectIds.title()}
                            </Typography>
                            {!vault.rbacEnabled &&
                                <Fragment>
                                    <Box sx={{ marginLeft: theme.spacing(1) }}>
                                        <Message
                                            level="warning"
                                            title={localization.info.cards.vaultObjectIds.warningMessage()}
                                            variant="minimal"/>
                                    </Box>
                                    <Typography sx={{ marginLeft: theme.spacing(0.5) }}>
                                        {localization.info.cards.vaultObjectIds.certificates()}
                                    </Typography>
                                </Fragment>}
                        </Stack>}/>
                {!vault.rbacEnabled && (
                    <InfoCard title={localization.info.cards.accessPolices.title()}>
                        <AzureKeyVaultVaultAccessPoliciesTable
                            accessPolicies={vault.accessPolicies}
                            variant="card"/>
                    </InfoCard>)}
                {vault.publicNetworkAccess &&
                    !vault.allNetworkAccess &&
                    <AzureFirewallInfoCard
                        networkResourcePathToIdReferencesMap={vaultModel.networkResourcePathToIdReferencesMap}
                        subnets={vault.firewallRuleSubnets}
                        virtualNetworkPathToFirewallSubnetRulesMap={vault.virtualNetworkPathToFirewallSubnetRulesMap}/>}
                <NetworkingInfoCard>
                    <EntitiesInfoItem
                        entityIdsOrModels={vaultModel.privateEndpointIdReferences}
                        entityTypeName={Contract.TypeNames.AzureNetworkPrivateEndpoint}
                        key={Contract.TypeNames.AzureNetworkPrivateEndpoint}
                        title={entityTypeNameTranslator(
                            Contract.TypeNames.AzureNetworkPrivateEndpoint,
                            { count: 0 })}/>
                </NetworkingInfoCard>
            </Info>
    });
}