﻿import { useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Scope, useTheme } from "..";

export enum ScopeConfigurationValueType {
    Disabled = "disabled",
    Enabled = "enabled",
    Inherit = "inherit"
}

export function getScopeConfigurationValueType(enabled?: boolean) {
    return _.isNil(enabled)
        ? ScopeConfigurationValueType.Inherit
        : enabled
            ? ScopeConfigurationValueType.Enabled
            : ScopeConfigurationValueType.Disabled;
}

export function getScopeConfigurationValueTypeValue(value: ScopeConfigurationValueType) {
    return value === ScopeConfigurationValueType.Inherit
        ? undefined
        : value === ScopeConfigurationValueType.Enabled;
}

export type ScopeLabelProps = {
    inheritedScopeId?: string;
    text: string;
    translatedInheritedValue?: string;
};

export function ScopeLabel({ inheritedScopeId, text, translatedInheritedValue }: ScopeLabelProps) {
    const localization =
        useLocalization(
            "common.scopeLabel",
            () => ({
                inherit: "({{translatedInheritedValue}})"
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row">
            <Typography>
                {text}
            </Typography>
            {_.isNil(inheritedScopeId)
                ? undefined
                : <Stack
                    alignItems="center"
                    direction="row"
                    spacing={0.5}
                    sx={{
                        color: theme.palette.grey[500],
                        marginLeft: theme.spacing(1)
                    }}>
                    <Scope scopeId={inheritedScopeId!}/>
                    {translatedInheritedValue &&
                        <Typography
                            noWrap={true}
                            sx={{
                                display: "flex",
                                flex: 1
                            }}>
                            {localization.inherit({ translatedInheritedValue })}
                        </Typography>}
                </Stack>}
        </Stack>);
}