import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, NumberFormatter, optionalTableCell, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAzureCommonEncryptedResourceDefinition, useAzureCommonResourceGroupResourceDefinition } from ".";
import { EntityTableDefinition } from "../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAzureSqlServerDatabaseStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useAzureSqlServerDatabaseDefinition(definitionData: DefinitionData) {
    const commonEncryptedResourceDefinition = useAzureCommonEncryptedResourceDefinition(definitionData);
    const commonResourceGroupResourceDefinition = useAzureCommonResourceGroupResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const sqlServerDatabaseStatusTranslator = useAzureSqlServerDatabaseStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureSqlServerDatabaseDefinition",
            () => ({
                columns: {
                    server: "SQL Server",
                    status: "Status",
                    storageSize: "Size"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            commonResourceGroupResourceDefinition.columns.tenantColumn,
            commonResourceGroupResourceDefinition.columns.creationTimeColumn,
            commonResourceGroupResourceDefinition.columns.creatorIdentityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureSqlServerDatabaseModel) => ({
                            [localization.columns.storageSize()]:
                                _.isNil((item.entity as Contract.AzureSqlServerDatabase).storageSize)
                                    ? ""
                                    : NumberFormatter.storage((item.entity as Contract.AzureSqlServerDatabase).storageSize!)
                        })
                }}
                id={Contract.EntityModelProperty.AzureSqlServerDatabaseStorageSize}
                key={Contract.EntityModelProperty.AzureSqlServerDatabaseStorageSize}
                render={optionalTableCell<Contract.AzureSqlServerDatabaseModel>(
                    item =>
                        _.isNil((item.entity as Contract.AzureSqlServerDatabase).storageSize)
                            ? undefined
                            : NumberFormatter.storage((item.entity as Contract.AzureSqlServerDatabase).storageSize!))}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.storageSize()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureSqlServerDatabaseModel>(
                        Contract.TypeNames.AzureSqlServer,
                        item => [(item.entity as Contract.AzureSqlServerDatabase).serverId],
                        localization.columns.server())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureSqlServerDatabaseServer)}
                                placeholder={localization.columns.server()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureSqlServerDatabaseServer}
                key={Contract.EntityModelProperty.AzureSqlServerDatabaseServer}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureSqlServerDatabaseModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={(item.entity as Contract.AzureSqlServerDatabase).serverId}
                            entityTypeName={Contract.TypeNames.AzureSqlServer}
                            entityVariant="iconText"/>}
                title={localization.columns.server()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureSqlServerDatabaseModel) => ({
                            [localization.columns.status()]: sqlServerDatabaseStatusTranslator((item.entity as Contract.AzureSqlServerDatabase).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AzureSqlServerDatabaseStatus}
                                enumTypeTranslator={sqlServerDatabaseStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureSqlServerDatabaseStatus}
                itemProperty={(item: Contract.AzureSqlServerDatabaseModel) => sqlServerDatabaseStatusTranslator((item.entity as Contract.AzureSqlServerDatabase).status)}
                key={Contract.EntityModelProperty.AzureSqlServerDatabaseStatus}
                title={localization.columns.status()}/>,
            commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
            commonEncryptedResourceDefinition.columns.vaultKeyColumn,
            commonResourceGroupResourceDefinition.columns.regionColumn,
            commonResourceGroupResourceDefinition.columns.regionLocationColumn,
            commonResourceGroupResourceDefinition.columns.resourceGroupColumn,
            commonResourceGroupResourceDefinition.columns.tagsColumn,
            commonResourceGroupResourceDefinition.columns.attributesColumn,
            commonResourceGroupResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureSqlServerDatabaseRequest(
                new Contract.EntityControllerGetEntityModelPageAzureSqlServerDatabaseRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureResourceGroupResourceResourceGroup]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureSqlServerDatabaseServer]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureSqlServerDatabaseStatus])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAzureResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureEncryptedResourceVaultKeys]))));
}