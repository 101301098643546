import { DialogMenuItem, Message, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, entityModelStore, InfoItem, useActivityTranslator, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { GenerateAzureIdentityNonexcessiveRoleDefinitionDialog, useAzureManagedIdentityManagedIdentityModelTypeTranslator } from "../../../../../../../../../../tenants";
import { EntitiesInfoCard, EntitiesInfoItem, Info } from "../../../../components";
import { GenerateEntityNonexcessivePermitterIcon } from "../../../../icons";
import { AadDirectoryPrincipalDirectoryRoleAssignmentResources, AzureAuthorizationRoleAssignmentResources, AzureResourceRoleAssignmentResources } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAzureDefaultResourceInfoItemElements } from "./useAzureDefaultResourceInfoItemElements";

export function useAzureManagedIdentityManagedIdentity(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);
    const managedIdentityModel = resourceGroupResourceModel as Contract.AzureManagedIdentityManagedIdentityModel;
    const servicePrincipalModel = entityModelStore.useGet((managedIdentityModel.entity as Contract.AzureManagedIdentityManagedIdentity).servicePrincipalId) as Contract.AadDirectoryManagedIdentityServicePrincipalModel;
    const servicePrincipal = servicePrincipalModel.entity as Contract.AadDirectoryManagedIdentityServicePrincipal;

    const activityTranslator = useActivityTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const managedIdentityManagedIdentityModelTypeTranslator = useAzureManagedIdentityManagedIdentityModelTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureManagedIdentityManagedIdentityDefinition",
            () => ({
                info: {
                    cards: {
                        groupIds: "Group Memberships"
                    },
                    items: {
                        managedIdentityType: "Type",
                        originatorResourceIds: "Assigned to",
                        rawPrincipalId: "Object ID",
                        servicePrincipalActivityTime: {
                            helpText: "The last time a management write activity was seen in Azure for this identity, since read-only activity isn't logged in Azure.",
                            title: "Activity Time"
                        },
                        servicePrincipalId: "Application",
                        servicePrincipalSignInTime: {
                            empty: "Not seen",
                            title: "Sign-in Time"
                        }
                    }
                },
                tabs: {
                    resourceRoleAssignments: "Resource Role Assignments",
                    servicePrincipalAzureRoleAssignments: "Identity Azure Role Assignments",
                    servicePrincipalDirectoryRoleAssignments: "Identity Microsoft Entra ID Administrative Role Assignments"
                },
                topbar: {
                    menu: {
                        generate: "Generate Custom Role"
                    }
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs:
            _<EntityProfileDefinitionTab>([]).
                concatIf(
                    managedIdentityModel.type === Contract.AzureManagedIdentityManagedIdentityModelType.User,
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Iam,
                        <AzureResourceRoleAssignmentResources
                            csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                            scopeResourceModel={resourceGroupResourceModel}/>,
                        localization.tabs.resourceRoleAssignments(),
                        "resourceRoleAssignments")).
                concatIf(
                    !servicePrincipalModel.unknown,
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Iam,
                        <AadDirectoryPrincipalDirectoryRoleAssignmentResources
                            csvExportFilePrefix={localization.tabs.servicePrincipalDirectoryRoleAssignments()}
                            principalModel={servicePrincipalModel}/>,
                        localization.tabs.servicePrincipalDirectoryRoleAssignments(),
                        "servicePrincipalDirectoryRoleAssignments")).
                concat(
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Iam,
                        <AzureAuthorizationRoleAssignmentResources
                            csvExportFilePrefixes={[
                                entityTypeNameTranslator(servicePrincipalModel.entity.typeName, { includeServiceName: false }),
                                servicePrincipalModel.entity.displayName,
                                localization.tabs.servicePrincipalAzureRoleAssignments()
                            ]}
                            principalOrResourceId={servicePrincipalModel.entity.id}
                            roleAssignmentResourceIds={managedIdentityModel.servicePrincipalRoleAssignmentResourceIds}
                            variant="roleAndScopeAndPrincipalAndRoleManagementPolicyAssignment"/>,
                        localization.tabs.servicePrincipalAzureRoleAssignments(),
                        "servicePrincipalAzureRoleAssignments")).
                value(),
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="entityTypeName"
                        title={localization.info.items.managedIdentityType()}
                        value={managedIdentityManagedIdentityModelTypeTranslator(managedIdentityModel.type)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={servicePrincipalModel.id}
                        entityTypeName={Contract.TypeNames.AadDirectoryManagedIdentityServicePrincipal}
                        key="servicePrincipalId"
                        title={localization.info.items.servicePrincipalId()}/>,
                    <InfoItem
                        emptyValue={localization.info.items.servicePrincipalSignInTime.empty()}
                        key="signInTime"
                        title={localization.info.items.servicePrincipalSignInTime.title()}
                        value={
                            _.isNil(managedIdentityModel?.servicePrincipalSignInTime)
                                ? undefined
                                : TimeFormatter.profileFormatDateTime(managedIdentityModel.servicePrincipalSignInTime)}/>,
                    <InfoItem
                        key="servicePrincipalActivityTime"
                        title={localization.info.items.servicePrincipalActivityTime.title()}
                        titleInfoElement={
                            <Message
                                level="info"
                                title={localization.info.items.servicePrincipalActivityTime.helpText()}
                                variant="minimal"/>}
                        value={activityTranslator(managedIdentityModel.servicePrincipalActivity)}/>,
                    <InfoItem
                        key="rawPrincipalId"
                        title={localization.info.items.rawPrincipalId()}
                        value={
                            _.isNil(servicePrincipalModel.unknown)
                                ? undefined
                                : servicePrincipal.rawId}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={managedIdentityModel.originatorResourceIds}
                        entityTypeName={Contract.TypeNames.AzureResource}
                        key="originatorResourceIds"
                        location="all"
                        title={localization.info.items.originatorResourceIds()}/>
                ]}
                options={options?.infoOptions}>
                {!servicePrincipalModel.unknown &&
                    <EntitiesInfoCard
                        entityIdsOrModels={servicePrincipalModel.groupIds}
                        entityTypeName={Contract.TypeNames.AadDirectoryGroup}
                        title={localization.info.cards.groupIds()}/>}
            </Info>,
        topbarMenuItems:
            !servicePrincipalModel.unknown &&
            _.isNil(servicePrincipalModel.systemDeletionTime)
                ? [
                    new DialogMenuItem(
                        onClose =>
                            <GenerateAzureIdentityNonexcessiveRoleDefinitionDialog
                                identityModel={servicePrincipalModel}
                                onClose={onClose}/>,
                        localization.topbar.menu.generate(),
                        { icon: <GenerateEntityNonexcessivePermitterIcon/> })
                ]
                : undefined
    });
}