import { DataTableColumn, useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../../../../../common";
import { Table } from "../../../../../../components";
import { StatusCell } from "./StatusCell";

export function AwsCloudWatchRequirementsTable() {
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsCloudWatchAlarmNotExistRiskDefinition.awsCloudWatchRequirementsTable",
            () => ({
                columns: {
                    requirement: {
                        title: "Requirement",
                        [Contract.TypeNames.AwsCloudWatchAlarmNotExistRiskRequirement]: {
                            [Contract.AwsCloudWatchAlarmNotExistRiskRequirement.CloudTrailValidTrailExists]: "Valid CloudTrail trail (active, multi-regional, capture management events)",
                            [Contract.AwsCloudWatchAlarmNotExistRiskRequirement.CloudWatchAlarmExists]: "CloudWatch alarm",
                            [Contract.AwsCloudWatchAlarmNotExistRiskRequirement.CloudWatchLogGroupExists]: "CloudTrail trail sends events to CloudWatch log group",
                            [Contract.AwsCloudWatchAlarmNotExistRiskRequirement.CloudWatchLogGroupMetricFilterExists]: "Cloudwatch log group metric filter",
                            [Contract.AwsCloudWatchAlarmNotExistRiskRequirement.SnsTopicForAlarmExists]: "SNS topic receives CloudWatch alarm notifications"
                        }
                    },
                    status: "Status"
                }
            }));
    return (
        <Table
            fetchItems={
                () =>
                    [
                        Contract.AwsCloudWatchAlarmNotExistRiskRequirement.CloudTrailValidTrailExists,
                        Contract.AwsCloudWatchAlarmNotExistRiskRequirement.CloudWatchLogGroupExists,
                        Contract.AwsCloudWatchAlarmNotExistRiskRequirement.CloudWatchLogGroupMetricFilterExists,
                        Contract.AwsCloudWatchAlarmNotExistRiskRequirement.CloudWatchAlarmExists,
                        Contract.AwsCloudWatchAlarmNotExistRiskRequirement.SnsTopicForAlarmExists
                    ]}
            getItemId={(item: Contract.AwsCloudWatchAlarmNotExistRiskRequirement) => item}>
            <DataTableColumn
                id={AwsCloudWatchRequirementsTableColumnId.Requirement}
                itemProperty={(item: Contract.AwsCloudWatchAlarmNotExistRiskRequirement) => localization.columns.requirement[Contract.TypeNames.AwsCloudWatchAlarmNotExistRiskRequirement][item]()}
                title={localization.columns.requirement.title()}/>
            <DataTableColumn
                id={AwsCloudWatchRequirementsTableColumnId.Status}
                render={StatusCell}
                title={localization.columns.status()}/>
        </Table>);
}

enum AwsCloudWatchRequirementsTableColumnId {
    Requirement = "Requirement",
    Status = "status"
}