﻿import { useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, InlineEntities } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAadDirectoryEntityRiskContext } from "./useGetAadDirectoryEntityRiskContext";

export function useGetAadDirectoryApplicationRiskContext() {
    return useMemo(
        () => useAadDirectoryApplicationRiskContext,
        []);
}

function useAadDirectoryApplicationRiskContext(applicationModel: Contract.AadDirectoryApplicationModel) {
    const application = applicationModel.entity as Contract.AadDirectoryApplication;
    const getDirectoryEntityRiskContext = useGetAadDirectoryEntityRiskContext();

    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aad.hooks.contexts.useGetAadDirectoryApplicationRiskContext",
            () => ({
                applicationObjects: "The app registration contains {{objects}}",
                ownerPrincipals: {
                    owners: [
                        "1 owner",
                        "{{count | NumberFormatter.humanize}} owners"
                    ],
                    text: "{{application}} has {{ownerPrincipals}}"
                },
                servicePrincipals: "The app registration is linked to {{servicePrincipals}}"
            }));

    return {
        ...getDirectoryEntityRiskContext(applicationModel),
        applicationObjects:
            _.isEmpty(applicationModel.certificateIds) && _.isEmpty(applicationModel.secretIds)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.applicationObjects({
                        objects: localizeList(
                            _.filter([
                                _.isEmpty(applicationModel.certificateIds)
                                    ? undefined
                                    : <InlineEntities
                                        entityIdsOrModels={applicationModel.certificateIds}
                                        entityTypeName={Contract.TypeNames.AadDirectoryApplicationCertificate}
                                        variant="itemCountAndType"/>,
                                _.isEmpty(applicationModel.secretIds)
                                    ? undefined
                                    : <InlineEntities
                                        entityIdsOrModels={applicationModel.secretIds}
                                        entityTypeName={Contract.TypeNames.AadDirectoryApplicationSecret}
                                        variant="itemCountAndType"/>]))
                    })),
        ownerPrincipals:
            _.isEmpty(application.ownerPrincipalIds)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.ownerPrincipals.text({
                        application:
                            <Entity
                                entityIdOrModel={applicationModel.id}
                                variant="text"/>,
                        ownerPrincipals:
                            <InlineEntities
                                entityIdsOrModels={application.ownerPrincipalIds}
                                entityTypeName={Contract.TypeNames.AadDirectoryIdentity}
                                namePluralizer={localization.ownerPrincipals.owners}
                                variant="itemCountAndType"/>
                    })),
        servicePrincipals:
            _.isEmpty(applicationModel.servicePrincipalIds)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.servicePrincipals({
                        servicePrincipals:
                            <InlineEntities
                                entityIdsOrModels={applicationModel.servicePrincipalIds}
                                entityTypeName={Contract.TypeNames.AadDirectoryApplicationServicePrincipal}
                                variant="itemCountAndType"/>
                    }))
    };
}