import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAwsCommonKubernetesNamespaceResourceInfoItemElements, useAwsCommonKubernetesWorkloadResourceInfoItemElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { Info } from "../../../../components";
import { KubernetesContainers, KubernetesLabelSelectorInfoItem, KubernetesTolerationsInfoCard, KubernetesYamlInfoCard } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useAwsKubernetesReplicaSetDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonKubernetesNamespaceResourceInfoItemElements = useAwsCommonKubernetesNamespaceResourceInfoItemElements(resourceModel);
    const commonKubernetesWorkloadResourceInfoItemElements = useAwsCommonKubernetesWorkloadResourceInfoItemElements(resourceModel as Contract.AwsKubernetesReplicaSetModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const replicaSet = resourceModel.entity as Contract.AwsKubernetesReplicaSet;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsKubernetesReplicaSetDefinition",
            () => ({
                info: {
                    replicaCount: "Desired Replicas"
                },
                tabs: {
                    containers: "Containers",
                    yaml: "YAML"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs:
            _<EntityProfileDefinitionTab>([]).
                concatIf(
                    !_.isEmpty(replicaSet.data.podTemplateSpec.podSpec.containers),
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Overview,
                        <KubernetesContainers workloadResourceModel={resourceModel as Contract.AwsKubernetesWorkloadResourceModel}/>,
                        localization.tabs.containers(),
                        "containers")).
                concat(
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Overview,
                        <KubernetesYamlInfoCard rawYaml={replicaSet.data.rawYaml!}/>,
                        localization.tabs.yaml(),
                        "yaml")).
                value(),
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <KubernetesLabelSelectorInfoItem
                        key="podLabelSelector"
                        labelSelector={replicaSet.data.podLabelSelector}/>,
                    <InfoItem
                        key="replicaCount"
                        title={localization.info.replicaCount()}
                        value={replicaSet.data.replicaCount}/>,
                    commonKubernetesNamespaceResourceInfoItemElements.clusterInfoItemElement,
                    commonKubernetesNamespaceResourceInfoItemElements.namespaceInfoItemElement,
                    commonKubernetesWorkloadResourceInfoItemElements.containerImagesInfoItemElement,
                    commonKubernetesWorkloadResourceInfoItemElements.containerImageVulnerabilitiesInfoItemElement,
                    commonKubernetesWorkloadResourceInfoItemElements.controllerResourceInfoItemElement,
                    commonKubernetesWorkloadResourceInfoItemElements.nodeGroupsInfoItemElement,
                    commonKubernetesWorkloadResourceInfoItemElements.fargateProfileInfoItemElement,
                    commonKubernetesWorkloadResourceInfoItemElements.serviceAccountInfoItemElement,
                    commonKubernetesWorkloadResourceInfoItemElements.awsRoleInfoItemElement,
                    commonKubernetesNamespaceResourceInfoItemElements.annotationsInfoItemElement
                ]}
                options={options?.infoOptions}>
                {!_.isEmpty(replicaSet.data.podTemplateSpec.podSpec.tolerations) &&
                    <KubernetesTolerationsInfoCard tolerations={replicaSet.data.podTemplateSpec.podSpec.tolerations}/>}
            </Info>
    });
}