import { Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, SpecificCustomRiskPolicyProps } from "../../../..";
import { Edit, View } from "./components";

export function AwsEc2SecurityGroupAllowedInboundRuleRiskPolicy({ editOptions, riskPolicyConfiguration, sx }: SpecificCustomRiskPolicyProps) {
    const localization =
        useLocalization(
            "common.customRiskPolicy.awsEc2SecurityGroupInboundRuleRiskPolicy.awsEc2SecurityGroupAllowedInboundRuleRiskPolicy",
            () => ({
                template: "*capitalize*{{securityGroups}}** should allow inbound internet access only from {{sourceIpAddresses}} to {{networkScopes}}"
            }));

    return _.isNil(editOptions)
        ? <View
            riskPolicyConfiguration={riskPolicyConfiguration as Optional<Contract.AwsEc2SecurityGroupAllowedInboundRuleRiskPolicyConfiguration>}
            sx={sx}
            templateTranslator={localization.template}/>
        : <Edit
            actionsRef={editOptions.actionsRef}
            createRequest={
                (allEntities,
                    anyIpV4AddressSubnet,
                    description,
                    entityBuiltInAttributeTypeNames,
                    entityCustomAttributeDefinitionIds,
                    entityIds,
                    entityTags,
                    exactSubnets,
                    inboundAccessScope,
                    name,
                    networkScopes,
                    overlapSubnets,
                    scopeId,
                    severity) =>
                    new Contract.RiskControllerInsertAwsEc2SecurityGroupAllowedInboundRuleRiskPolicyRequest(
                        description,
                        name,
                        scopeId,
                        severity,
                        allEntities,
                        anyIpV4AddressSubnet,
                        exactSubnets,
                        inboundAccessScope,
                        networkScopes,
                        overlapSubnets,
                        entityBuiltInAttributeTypeNames,
                        entityCustomAttributeDefinitionIds,
                        entityIds,
                        entityTags)}
            riskPolicyConfiguration={riskPolicyConfiguration as Optional<Contract.AwsEc2SecurityGroupAllowedInboundRuleRiskPolicyConfiguration>}
            templateTranslator={localization.template}
            onValidChange={editOptions.onValidChange}/>;
}

export function AwsEc2SecurityGroupNotAllowedInboundRuleRiskPolicy({ editOptions, riskPolicyConfiguration, sx }: SpecificCustomRiskPolicyProps) {
    const localization =
        useLocalization(
            "common.customRiskPolicy.awsEc2SecurityGroupInboundRuleRiskPolicy.awsEc2SecurityGroupNotAllowedInboundRuleRiskPolicy",
            () => ({
                template: "*capitalize*{{securityGroups}}** should not allow inbound internet access from {{sourceIpAddresses}} to {{networkScopes}}"
            }));

    return _.isNil(editOptions)
        ? <View
            riskPolicyConfiguration={riskPolicyConfiguration as Optional<Contract.AwsEc2SecurityGroupNotAllowedInboundRuleRiskPolicyConfiguration>}
            sx={sx}
            templateTranslator={localization.template}/>
        : <Edit
            actionsRef={editOptions.actionsRef}
            createRequest={
                (allEntities,
                    anyIpV4AddressSubnet,
                    description,
                    entityBuiltInAttributeTypeNames,
                    entityCustomAttributeDefinitionIds,
                    entityIds,
                    entityTags,
                    exactSubnets,
                    inboundAccessScope,
                    name,
                    networkScopes,
                    overlapSubnets,
                    scopeId,
                    severity) =>
                    new Contract.RiskControllerInsertAwsEc2SecurityGroupNotAllowedInboundRuleRiskPolicyRequest(
                        description,
                        name,
                        scopeId,
                        severity,
                        allEntities,
                        anyIpV4AddressSubnet,
                        exactSubnets,
                        inboundAccessScope,
                        networkScopes,
                        overlapSubnets,
                        entityBuiltInAttributeTypeNames,
                        entityCustomAttributeDefinitionIds,
                        entityIds,
                        entityTags)}
            riskPolicyConfiguration={riskPolicyConfiguration as Optional<Contract.AwsEc2SecurityGroupNotAllowedInboundRuleRiskPolicyConfiguration>}
            templateTranslator={localization.template}
            onValidChange={editOptions.onValidChange}/>;
}