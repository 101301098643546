﻿import { Dialog, FormLayout, ItemSelector, Message, TimeFormatter, TimeHelper, TimeSpanFormatter, TimeSpanHelper, useLocalization } from "@infrastructure";
import { Box, Button, CircularProgress, Divider, ListItemButton, Stack, TextField, Typography } from "@mui/material";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import _ from "lodash";
import moment from "moment/moment";
import React, { useState } from "react";
import { Contract, RadioGroup, useRiskPolicyConfigurationExclusionDataReasonTranslator, useTheme } from "../../../../../common";

type ExcludeDialogProps = {
    action: string;
    description: string;
    exclusionExpirationDate?: string;
    exclusionReason?: Contract.RiskPolicyConfigurationExclusionDataReason;
    executeAsync: (reason: Contract.RiskPolicyConfigurationExclusionDataReason, expirationDate?: string, message?: string) => Promise<void>;
    onClose: (canceled?: boolean) => void;
    title: string;
};

export function ExcludeDialog({ action, description, exclusionExpirationDate, exclusionReason, executeAsync, onClose, title }: ExcludeDialogProps) {
    const tomorrow =
        TimeHelper.
            now().
            add(1, "days").
            startOf("day");
    const [date, setDate] =
        useState(
            _.isNil(exclusionExpirationDate)
                ? tomorrow
                : moment(exclusionExpirationDate));
    const [duration, setDuration] =
        useState<DurationType>(
            _.isNil(exclusionExpirationDate)
                ? DurationType.Permanent
                : DurationType.Until);
    const [error, setError] = useState(false);
    const [executing, setExecuting] = useState(false);
    const [message, setMessage] = useState<string>();
    const [reason, setReason] = useState(exclusionReason ?? undefined);
    const riskPolicyConfigurationExclusionDataReasonTranslator = useRiskPolicyConfigurationExclusionDataReasonTranslator();

    const localization =
        useLocalization(
            "views.customer.risks.excludeDialog",
            () => ({
                error: "Failed to {{action}}",
                fields: {
                    duration: {
                        permanent: "Permanent",
                        title: "Duration",
                        until: "Until {{date}}"
                    },
                    message: {
                        placeholder: "Write a comment...",
                        title: "Comment"
                    },
                    reason: {
                        description: {
                            [Contract.RiskPolicyConfigurationExclusionDataReason.ByDesign]: "This finding represents an expected configuration as it was intentionally designed.",
                            [Contract.RiskPolicyConfigurationExclusionDataReason.Exception]: "This finding represents an issue that will be addressed at a later stage.",
                            [Contract.RiskPolicyConfigurationExclusionDataReason.FalsePositive]: "This finding was incorrectly identified as an issue."
                        },
                        empty: "Select a reason",
                        title: "Reason"
                    }
                },
                save: "{{action}}"
            }));

    async function execute() {
        setExecuting(true);
        setError(false);

        try {
            await executeAsync(
                reason!,
                duration === DurationType.Permanent
                    ? undefined
                    : date.
                        startOf("day").
                        toISOString(),
                message);
        } catch {
            setError(true);
        }
        setExecuting(false);
    }

    const theme = useTheme();
    return (
        <Dialog
            variant="editor"
            onClose={() => onClose(true)}>
            <FormLayout
                disableContentPadding={true}
                footerOptions={{
                    border: true,
                    contentElement:
                        <Stack
                            alignItems="center"
                            direction="row"
                            justifyContent="flex-end"
                            spacing={1}>
                            {error && (
                                <Message
                                    level="error"
                                    title={localization.error({ action })}/>)}
                            {executing && (
                                <CircularProgress
                                    size={theme.spacing(2)}
                                    variant="indeterminate"/>)}
                            <Button
                                disabled={
                                    executing ||
                                    _.isNil(reason) || (
                                        reason === exclusionReason && (
                                            duration === DurationType.Permanent &&
                                            _.isNil(exclusionExpirationDate) ||
                                            duration === DurationType.Until &&
                                            date.diff(moment(exclusionExpirationDate), "days") === 0))}
                                onClick={execute}>
                                {localization.save({ action })}
                            </Button>
                        </Stack>
                }}
                titleOptions={{
                    subtitle: description,
                    text: title
                }}>
                <Stack
                    flex={1}
                    padding={theme.spacing(2, 3)}
                    spacing={4}>
                    <Stack
                        paddingTop={theme.spacing(2)}
                        spacing={1}>
                        <Typography variant="h5">
                            {localization.fields.reason.title()}
                        </Typography>
                        <ItemSelector
                            disabled={executing}
                            fullWidth={true}
                            items={[
                                Contract.RiskPolicyConfigurationExclusionDataReason.Exception,
                                Contract.RiskPolicyConfigurationExclusionDataReason.ByDesign,
                                Contract.RiskPolicyConfigurationExclusionDataReason.FalsePositive]}
                            placeholder={localization.fields.reason.empty()}
                            selectedItem={reason}
                            sorted={false}
                            onSelectedItemChanged={setReason}>
                            {(exclusionDataReason?: Contract.RiskPolicyConfigurationExclusionDataReason) =>
                                _.isNil(exclusionDataReason)
                                    ? "-"
                                    : <Typography>
                                        {riskPolicyConfigurationExclusionDataReasonTranslator(exclusionDataReason)}
                                    </Typography>}
                        </ItemSelector>
                        {!_.isNil(reason) && (
                            <Box
                                sx={{
                                    backgroundColor: theme.palette.input.filledBackground,
                                    borderRadius: 2,
                                    display: "flex",
                                    marginBottom: theme.spacing(2),
                                    padding: theme.spacing(1.5)
                                }}>
                                <Typography paddingLeft={theme.spacing(0.5)}>
                                    {localization.fields.reason.description[reason]()}
                                </Typography>
                            </Box>)}
                    </Stack>
                    <Stack
                        spacing={2}>
                        <Typography variant="h5">
                            {localization.fields.duration.title()}
                        </Typography>
                        <RadioGroup
                            disabled={executing}
                            items={[
                                {
                                    label: localization.fields.duration.permanent(),
                                    value: DurationType.Permanent
                                },
                                {
                                    children:
                                        <DatePicker
                                            date={date}
                                            minDate={tomorrow}
                                            setDate={date => setDate(date)}/>,
                                    label:
                                        <Stack direction="row">
                                            {localization.fields.duration.until({
                                                date:
                                                    <Typography
                                                        sx={{
                                                            color: theme.palette.text.secondary,
                                                            paddingLeft: theme.spacing(1)
                                                        }}>
                                                        {TimeFormatter.humanizeWeekDayAndMediumDate(date)}
                                                    </Typography>
                                            })}
                                        </Stack>,
                                    sx: { paddingLeft: theme.spacing(0.75) },
                                    value: DurationType.Until
                                }
                            ]}
                            selectedValue={duration}
                            onChange={event => setDuration(event)}/>
                    </Stack>
                    <Stack
                        spacing={2}>
                        <Typography variant="h5">
                            {localization.fields.message.title()}
                        </Typography>
                        <TextField
                            disabled={executing}
                            fullWidth={true}
                            label={localization.fields.message.placeholder()}
                            multiline={true}
                            rows={4}
                            value={message}
                            variant="outlined"
                            onChange={event => setMessage(event.target.value)}/>
                    </Stack>
                </Stack>
            </FormLayout>
        </Dialog>);
}

type DatePickerProps = {
    date: moment.Moment;
    minDate: moment.Moment;
    setDate: (date: moment.Moment) => void;
};

function DatePicker({ date, minDate, setDate }: DatePickerProps) {
    const theme = useTheme();
    return (
        <Stack
            direction="row">
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                dateLibInstance={moment}>
                <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    maxDate={
                        TimeHelper.
                            now().
                            add(10, "years")}
                    minDate={minDate}
                    slots={{
                        actionBar: React.Fragment
                    }}
                    value={date}
                    onChange={date => setDate(date!)}/>
            </LocalizationProvider>
            <Divider
                flexItem={true}
                orientation="vertical"/>
            <Stack
                spacing={1}
                sx={{
                    flex: 1,
                    paddingTop: theme.spacing(1.5)
                }}>
                {_.map(
                    [1, 7, 30, 90],
                    dateInterval => {
                        const dateIntervalDays =
                            TimeHelper.
                                now().
                                add(dateInterval, "days");
                        return (
                            <ListItemButton
                                key={dateInterval}
                                selected={date === dateIntervalDays}
                                sx={{ flex: 0 }}
                                onClick={() => setDate(dateIntervalDays)}>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    spacing={1}
                                    sx={{ width: "100%" }}>
                                    <Typography sx={{ whiteSpace: "nowrap" }}>
                                        {TimeSpanFormatter.day(TimeSpanHelper.fromDays(dateInterval))}
                                    </Typography>
                                    <Typography
                                        noWrap={true}
                                        sx={{ color: theme.palette.text.secondary }}>
                                        {TimeFormatter.humanizeWeekDayAndMediumDate(TimeHelper.getLocalTimeFromNow(TimeSpanHelper.fromDays(dateInterval)))}
                                    </Typography>
                                </Stack>
                            </ListItemButton>);
                    })}
            </Stack>
        </Stack>);
}

enum DurationType {
    Permanent = "permanent",
    Until = "until"
}