import { Link, TextViewer, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTheme } from "../../../../../../../../../../../../../../../../../../../common";
import { useAddOrEditContext } from "../../../../../AddOrEdit";

export function SyncIacActionItem() {
    const { apiRootUrl } = useAddOrEditContext();
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodePipelineItems.codePipeline.addOrEdit.hooks.useDefinition.hooks.useGitHubWorkflow.syncIacActionItem",
            () => ({
                description: {
                    link: "GitHub Workflow",
                    text: "To sync your Cloud to Code, and remediate cloud findings at source, add the following action to your {{gitHubWorkflowLink}} (adjust as needed):\n**This action should be added after the 'terraform apply' step.**"
                },
                note: {
                    link: "Tenable Cloud Security Action documentation",
                    text: "Once the action is triggered, you should start seeing resources/findings tagged with the IaC label in Tenable Cloud Security. Relevant resources will have an IaC tab in the Inventory view, showing the relevant code snippet. Please refer to the {{gitHubActionLink}} for more details"
                },
                recommendation: {
                    text: "If you are using the 'Setup Terraform' GitHub action in your workflow, Tenable strongly recommends that you disable the stdout Terraform wrapper to prevent issues when running the pipeline. See the suggested code below:"
                }
            }));

    const theme = useTheme();
    return (
        <Stack spacing={4}>
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
                {localization.description.text({
                    gitHubWorkflowLink:
                        <Link
                            sx={{ textDecorationLine: "underline" }}
                            urlOrGetUrl="https://docs.github.com/en/actions/using-workflows/about-workflows"
                            variant="external">
                            {localization.description.link()}
                        </Link>
                })}
            </Typography>
            <TextViewer
                copyToClipboard={true}
                format="yaml"
                text={
                    _.join(
                        [
                            "steps:",
                            "  - uses: actions/checkout@v3",
                            "  - name: Pull remote terraform state",
                            "    run: terraform state pull > terraform.tfstate",
                            "    shell: bash",
                            "  - name: Sync IaC with Tenable Cloud Security",
                            "    uses: tenable/cloud-security-actions/iac/sync/terraform@v1",
                            "    with:",
                            "      api-token: ${{ secrets.TENABLE_API_TOKEN }}",
                            `      api-url: ${apiRootUrl}`
                        ],
                        "\n")}/>
            <Typography>
                {localization.recommendation.text()}
            </Typography>
            <TextViewer
                copyToClipboard={true}
                format="yaml"
                text={
                    _.join(
                        [
                            "- name: Setup Terraform",
                            "  uses: hashicorp/setup-terraform@v2.0.3",
                            "  with:",
                            "    terraform_wrapper: false"
                        ],
                        "\n")}/>
            <Typography sx={{ maxWidth: theme.spacing(65) }}>
                {localization.note.text({
                    gitHubActionLink:
                        <Link
                            sx={{ textDecorationLine: "underline" }}
                            urlOrGetUrl="https://github.com/tenable/cloud-security-actions/tree/main/iac/sync/terraform"
                            variant="external">
                            {localization.note.link()}
                        </Link>
                })}
            </Typography>
        </Stack>);
}