import { ItemWithValidation, Mails, useChangeEffect, useLocalization } from "@infrastructure";
import { FormControl } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useState } from "react";
import { Contract } from "../../../controllers";
import { DeliveryProps } from "../Delivery";
import { Message } from "./Message";

export function Mail({ delivery, disabled, iconVisible, messageOptions, onChange }: DeliveryProps) {
    const [mails, setMails] =
        useState(
            _.map(
                (delivery.destinations ?? []) as Contract.MailDeliveryMail[],
                ({ mail }) => mail));
    const [message, setMessage] = useState(delivery?.message);

    useChangeEffect(
        () =>
            onChange({
                ...(delivery as Contract.MailDelivery),
                destinations:
                    _.map(
                        mails,
                        mail => ({
                            mail,
                            typeName: Contract.TypeNames.MailDeliveryMail
                        })) as Contract.MailDeliveryMail[],
                message,
                valid: !_.isEmpty(mails)
            } as ItemWithValidation<Contract.MailDelivery>),
        [mails, message],
        500);

    const localization =
        useLocalization(
            "common.delivery.mail",
            () => ({
                error: {
                    invalidFormat: "Not a valid mail address",
                    required: "User mail cannot be empty"
                },
                mail: "To"
            }));

    return (
        <Fragment>
            <FormControl
                fullWidth={true}
                variant="standard">
                <Mails
                    disabled={disabled}
                    iconVisible={iconVisible}
                    mails={mails}
                    placeholder={localization.mail()}
                    onChange={setMails}/>
            </FormControl>
            <Message
                disabled={disabled}
                message={message}
                options={messageOptions}
                setMessage={setMessage}/>
        </Fragment>);
}