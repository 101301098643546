import { AnalyticsContext, AnalyticsEventActionType, Dialog, NavigationView, NavigationViewDivider, NavigationViewItem, NumberFormatter, StringHelper, UnexpectedError, useRoute, useTrackAnalyticsEvent } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Report, Table } from ".";
import { useEntitiesOperationContext } from "../..";
import { Contract, CustomerConsoleAppUrlHelper, EntityTypeMetadataModelHelper, StorageHelper, TenantHelper, useEntityTypeNameTranslator, useObjectTypeCategoryTranslator, useTheme } from "../../../../../common";
import { entitiesObjectCategories } from "../utilities";

export type ReportData = {
    entityTypeName?: string;
    filterMap?: Dictionary<any>;
    open: boolean;
};

export function View() {
    const { entityTypeEntitiesViewName: routeEntityTypeEntitiesViewName, tenantType } = useRoute(`${CustomerConsoleAppUrlHelper.getEntitiesRelativeUrl()}/{tenantType}/{entityTypeEntitiesViewName}`);
    if (!_.isNil(routeEntityTypeEntitiesViewName) &&
        tenantType !== EntityTypeMetadataModelHelper.get(routeEntityTypeEntitiesViewName).tenantType) {
        throw new UnexpectedError("tenantType", tenantType);
    }

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const objectTypeCategoryTranslator = useObjectTypeCategoryTranslator();

    const [reportData, setReportData] = useState<ReportData>({ open: false });

    const theme = useTheme();
    const { entityTypeEntitiesViewNameToCountMap } = useEntitiesOperationContext();
    const [defaultEntityTypeEntitiesViewName, entityTypeItems] =
        useMemo(
            () => {
                const entityTypeCategoryToDatasMap =
                    _(entityTypeEntitiesViewNameToCountMap).
                        map(
                            (entityCount, entityTypeEntitiesViewName) => ({
                                entityCount,
                                entityTypeEntitiesViewName,
                                translatedEntityTypeName:
                                    entityTypeNameTranslator(
                                        entityTypeEntitiesViewName,
                                        {
                                            count: 0,
                                            includeServiceName:
                                                !TenantHelper.isIdentityProviderTenantType(tenantType as Contract.TenantType) &&
                                                EntityTypeMetadataModelHelper.get(entityTypeEntitiesViewName).category !== Contract.ObjectTypeCategory.EntityKubernetes
                                        })
                            })).
                        groupBy(({ entityTypeEntitiesViewName }) => EntityTypeMetadataModelHelper.get(entityTypeEntitiesViewName).category ?? "*").
                        mapValues(
                            entityTypeDatas =>
                                _.orderBy(
                                    entityTypeDatas,
                                    entityTypeData => StringHelper.getSortValue(entityTypeData.translatedEntityTypeName),
                                    "asc")).
                        value();
                const entityTypeCategories =
                    _.filter(
                        entitiesObjectCategories,
                        entityTypeCategory => !_.isEmpty(entityTypeCategoryToDatasMap[entityTypeCategory]));
                const entityTypeItems =
                    _(entityTypeCategories).
                        map<NavigationViewDivider | NavigationViewItem>(
                            entityTypeCategory =>
                                new NavigationViewItem(
                                    entityTypeCategory,
                                    objectTypeCategoryTranslator(entityTypeCategory),
                                    <Typography
                                        noWrap={true}
                                        sx={{
                                            color: theme.palette.text.primary,
                                            fontWeight: 500
                                        }}>
                                        {objectTypeCategoryTranslator(entityTypeCategory)}
                                    </Typography>,
                                    {
                                        items:
                                            _.map(
                                                entityTypeCategoryToDatasMap[entityTypeCategory],
                                                entityTypeData =>
                                                    new NavigationViewItem(
                                                        entityTypeData.entityTypeEntitiesViewName,
                                                        entityTypeData.translatedEntityTypeName,
                                                        <EntityType
                                                            entityCount={entityTypeData.entityCount}
                                                            translatedName={entityTypeData.translatedEntityTypeName}/>,
                                                        {
                                                            path: CustomerConsoleAppUrlHelper.getEntitiesRelativeUrl(entityTypeData.entityTypeEntitiesViewName)
                                                        }))
                                    })).
                        concatIf(
                            !_.isEmpty(entityTypeCategories) &&
                            !_.isEmpty(entityTypeCategoryToDatasMap["*"]),
                            new NavigationViewDivider()).
                        concat(
                            _.map(
                                entityTypeCategoryToDatasMap["*"],
                                serviceTypeData =>
                                    new NavigationViewItem(
                                        serviceTypeData.entityTypeEntitiesViewName,
                                        serviceTypeData.translatedEntityTypeName,
                                        <Typography
                                            noWrap={true}
                                            sx={{
                                                color: theme.palette.text.primary,
                                                fontWeight: 500
                                            }}>
                                            <EntityType
                                                entityCount={serviceTypeData.entityCount}
                                                translatedName={serviceTypeData.translatedEntityTypeName}/>
                                        </Typography>,
                                        {
                                            path: CustomerConsoleAppUrlHelper.getEntitiesRelativeUrl(serviceTypeData.entityTypeEntitiesViewName)
                                        }))).
                        value();
                const defaultEntityTypeEntitiesViewName =
                    _(entityTypeItems).
                        filter(entityTypeItem => entityTypeItem instanceof NavigationViewItem).
                        map(entityTypeItem => (entityTypeItem as NavigationViewItem).options?.items?.[0].id ?? (entityTypeItem as NavigationViewItem).id).
                        head();
                return [defaultEntityTypeEntitiesViewName, entityTypeItems];
            },
            [entityTypeEntitiesViewNameToCountMap, theme]);

    function getStorageEntityTypeEntitiesViewName() {
        const storageEntityTypeEntitiesViewName =
            StorageHelper.
                customerEntitiesViewSelectedTypeName(tenantType).
                getValue();

        return _.isNil(storageEntityTypeEntitiesViewName) || _.isNil(entityTypeEntitiesViewNameToCountMap[storageEntityTypeEntitiesViewName])
            ? undefined
            : storageEntityTypeEntitiesViewName;
    }

    const entityTypeEntitiesViewName =
        routeEntityTypeEntitiesViewName ??
        getStorageEntityTypeEntitiesViewName() ??
        defaultEntityTypeEntitiesViewName;

    useTrackAnalyticsEvent(
        AnalyticsEventActionType.PageContentView,
        {
            "Entity Type":
                entityTypeNameTranslator(
                    entityTypeEntitiesViewName,
                    {
                        count: 0,
                        includeServiceName: true
                    })
        },
        [entityTypeEntitiesViewName, tenantType]);

    useEffect(
        () => {
            if (!_.isNil(entityTypeEntitiesViewName) &&
                !_.isNil(entityTypeEntitiesViewNameToCountMap[entityTypeEntitiesViewName])) {
                StorageHelper.
                    customerEntitiesViewSelectedTypeName(tenantType).
                    setValue(entityTypeEntitiesViewName);
            }
        },
        [entityTypeEntitiesViewName, tenantType]);

    return (
        <AnalyticsContext context={entityTypeEntitiesViewName}>
            {reportData.open &&
                <Dialog
                    variant="editor"
                    onClose={() => setReportData({ open: false })}>
                    <Report
                        reportData={reportData}
                        setReportData={setReportData}
                        tenantType={tenantType as Contract.TenantType}/>
                </Dialog>}
            <NavigationView
                items={entityTypeItems}
                itemsCount={_.size(entityTypeEntitiesViewNameToCountMap)}
                search={true}
                selectedItemId={entityTypeEntitiesViewName}
                variant="accordion">
                <Table
                    entityTypeEntitiesViewName={entityTypeEntitiesViewName}
                    key={entityTypeEntitiesViewName}
                    setReportData={setReportData}/>
            </NavigationView>
        </AnalyticsContext>);
}

type EntityTypeProps = {
    entityCount: number;
    translatedName: string;
};

function EntityType({ entityCount, translatedName }: EntityTypeProps) {
    return (
        <Stack
            alignItems="center"
            direction="row">
            <Typography
                noWrap={true}
                sx={{
                    flex: 1,
                    fontWeight: "unset"
                }}>
                {translatedName}
            </Typography>
            <Typography>
                {NumberFormatter.humanize(entityCount)}
            </Typography>
        </Stack>);
}