import { TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useOktaDirectoryUserStatusTranslator } from "../../../../../../hooks";
import { EntitiesInfoCard, Info } from "../../../../components";
import { useOktaCommonDirectoryEntityInfoItemElements } from "./useOktaCommonDirectoryEntityInfoItemElements";

export function useOktaDirectoryUserDefinition(directoryEntityModel: Contract.OktaDirectoryEntityModel, options?: DefinitionOptions) {
    const defaultResourceInfoItemElements = useOktaCommonDirectoryEntityInfoItemElements(directoryEntityModel);
    const user = directoryEntityModel.entity as Contract.OktaDirectoryUser;
    const userModel = directoryEntityModel as Contract.OktaDirectoryUserModel;
    const userProfile = directoryEntityModel.entityProfile as Contract.OktaDirectoryUserProfile;

    const directoryUserStatusTranslator = useOktaDirectoryUserStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.okta.useOktaDirectoryUserDefinition",
            () => ({
                info: {
                    cards: {
                        groups: "Groups"
                    },
                    items: {
                        activityTime: {
                            empty: "Not seen",
                            title: "AWS Last Activity"
                        },
                        passwordUpdateTime: {
                            empty: "Never",
                            title: "Password Update Time"
                        },
                        rawMail: "Mail",
                        status: "Status"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="rawMail"
                        location="all"
                        title={localization.info.items.rawMail()}
                        value={user.rawMail}/>,
                    <InfoItem
                        key="activityTime"
                        location="all"
                        title={localization.info.items.activityTime.title()}
                        value={
                            _.isNil(userProfile?.activityTime)
                                ? localization.info.items.activityTime.empty()
                                : TimeFormatter.profileFormatDateTime(userProfile.activityTime)}/>,
                    <InfoItem
                        key="passwordUpdateTime"
                        title={localization.info.items.passwordUpdateTime.title()}
                        value={
                            _.isNil(user.passwordUpdateTime)
                                ? localization.info.items.passwordUpdateTime.empty()
                                : TimeFormatter.profileFormatDateTime(user.passwordUpdateTime)}/>,
                    <InfoItem
                        key="status"
                        location="all"
                        title={localization.info.items.status()}
                        value={directoryUserStatusTranslator(user.status)}/>
                ]}
                options={options?.infoOptions}>
                <EntitiesInfoCard
                    entityIdsOrModels={userModel.groupIds}
                    entityTypeName={Contract.TypeNames.OktaDirectoryGroup}
                    title={localization.info.cards.groups()}/>
            </Info>,
        sensitive: false
    });
}