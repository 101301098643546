﻿import { ItemSelector, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { Contract } from "../../../../../../../controllers";
import { EntityMultiSelectProperty, useMultiSelectorContext, useSetMultiSelectorContext } from "../../../EntityMultiSelect";

type ProvisionedProps = {
    disabled?: boolean;
};

export function AwsSsoPermissionSetProvisioned({ disabled }: ProvisionedProps) {
    const { propertyIdToConditionMap } = useMultiSelectorContext();
    const condition = propertyIdToConditionMap[EntityMultiSelectProperty.AwsSsoPermissionSetProvisioned] as Contract.AwsSsoPermissionSetProvisionedMatchCondition;
    const setMultiSelectorContext = useSetMultiSelectorContext();
    const localization =
        useLocalization(
            "common.customRiskPolicy.entityMultiSelect.condition.awsSsoPermissionSetProvisioned",
            () => ({
                provisioned: {
                    false: "Not provisioned only",
                    true: "Provisioned only"
                },
                title: "Provisioned status"
            }));

    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Typography>
                {localization.title()}
            </Typography>
            <ItemSelector
                dense={true}
                disabled={disabled}
                items={[true, false]}
                selectedItem={condition.provisioned}
                onSelectedItemChanged={
                    provisioned =>
                        setMultiSelectorContext(
                            context => ({
                                propertyIdToConditionMap: {
                                    ...context.propertyIdToConditionMap,
                                    [EntityMultiSelectProperty.AwsSsoPermissionSetProvisioned]: new Contract.AwsSsoPermissionSetProvisionedMatchCondition(provisioned)
                                }
                            }))}>
                {provisioned =>
                    provisioned
                        ? localization.provisioned.true()
                        : localization.provisioned.false()}
            </ItemSelector>
        </Stack>);
}