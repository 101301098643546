import { AddIcon, DataTable, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableSort, DataTableSortDirection, EmptyMessageText, map, Optional, StringHelper, useChangeEffect, useLocalization } from "@infrastructure";
import { Button } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useRef } from "react";
import { useSetAadTenantsContext } from "../..";
import { Contract, StatusCell, tenantModelStore } from "../../../../../../../../../../../../../../common";
import { ActionsCell } from "./components";
import { useAadTenantModelStatusTranslator } from "./hooks";

export function Table() {
    const tenantModels = tenantModelStore.useGetPermittedAadTenants();
    const setAadTenantsContext = useSetAadTenantsContext();

    const tenantModelStatusTranslator = useAadTenantModelStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.aadTenants.table",
            () => ({
                actions: {
                    add: "Add Microsoft Entra ID"
                },
                columns: {
                    name: "Name",
                    primaryDomainName: "Primary Domain",
                    rawId: "ID",
                    status: "Status"
                },
                empty: "No Microsoft Entra Ids"
            }));

    function getTenantModels(_filterMap: Dictionary<any>, sort: Optional<DataTableSort>) {
        return _.orderBy(
            tenantModels,
            tenantModel =>
                map<string, any>(
                    sort?.columnId ?? TableColumnId.Name,
                    {
                        [TableColumnId.Name]: () => StringHelper.getSortValue(tenantModel.configuration.name),
                        [TableColumnId.PrimaryDomainName]: () => StringHelper.getSortValue((tenantModel.configuration as Contract.AadTenantConfiguration).primaryDomainName),
                        [TableColumnId.RawId]: () => StringHelper.getSortValue((tenantModel.configuration as Contract.AadTenantConfiguration).rawId),
                        [TableColumnId.Status]: () => StringHelper.getSortValue(tenantModelStatusTranslator(tenantModel.status))
                    }),
            sort?.direction === DataTableSortDirection.Descending
                ? "desc"
                : "asc");
    }

    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => {
            dataTableActionsRef.current!.reset();
        },
        [tenantModels]);

    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={getTenantModels}
            getItemId={(item: Contract.AadTenantModel) => item.configuration.id}>
            <DataTableAction>
                <Button
                    size="small"
                    startIcon={<AddIcon/>}
                    onClick={() =>
                        setAadTenantsContext(
                            aadTenantsContext => ({
                                ...aadTenantsContext,
                                addOrEditOpen: true
                            }))}>
                    {localization.actions.add()}
                </Button>
            </DataTableAction>
            <DataTableColumn
                id={TableColumnId.Name}
                itemProperty={(item: Contract.AadTenantModel) => item.configuration.name}
                title={localization.columns.name()}/>
            <DataTableColumn
                id={TableColumnId.RawId}
                itemProperty={(item: Contract.AadTenantModel) => (item.configuration as Contract.AadTenantConfiguration).rawId}
                title={localization.columns.rawId()}/>
            <DataTableColumn
                id={TableColumnId.PrimaryDomainName}
                itemProperty={(item: Contract.AadTenantModel) => (item.configuration as Contract.AadTenantConfiguration).primaryDomainName}
                title={localization.columns.primaryDomainName()}/>
            <DataTableColumn
                id={TableColumnId.Status}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AadTenantModel>) =>
                        <StatusCell
                            statusSeverity={item.statusSeverity}
                            title={tenantModelStatusTranslator(item.status)}/>}
                title={localization.columns.status()}/>
            <DataTableColumn
                id={TableColumnId.Actions}
                render={ActionsCell}/>
        </DataTable>);
}

enum TableColumnId {
    Actions = "actions",
    Name = "name",
    PrimaryDomainName = "primaryDomainName",
    RawId = "rawId",
    Status = "status"
}