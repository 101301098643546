import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract } from "../../../../../../../../../../common";
import { EntitiesInfoCard, EntitiesInfoItem, Info } from "../../../../components";
import { useOktaCommonDirectoryEntityInfoItemElements } from "./useOktaCommonDirectoryEntityInfoItemElements";

export function useOktaDirectoryGroupDefinition(directoryEntityModel: Contract.OktaDirectoryEntityModel, options?: DefinitionOptions) {
    const defaultResourceInfoItemElements = useOktaCommonDirectoryEntityInfoItemElements(directoryEntityModel);
    const group = directoryEntityModel.entity as Contract.OktaDirectoryGroup;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.okta.useOktaDirectoryGroupDefinition",
            () => ({
                info: {
                    users: "Users"
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <EntitiesInfoItem
                        entityIdsOrModels={group.userIds}
                        entityTypeName={Contract.TypeNames.OktaDirectoryUser}
                        key="users"
                        location="miniGlance"
                        title={localization.info.users()}/>
                ]}
                options={options?.infoOptions}>
                <EntitiesInfoCard
                    entityIdsOrModels={group.userIds}
                    entityTypeName={Contract.TypeNames.OktaDirectoryUser}
                    title={localization.info.users()}/>
            </Info>,
        sensitive: false
    });
}