import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../components";
import { useGetAzureStorageStorageAccountRiskContext } from "../contexts";

export function useAzureStorageStorageAccountInsecureTlsProtocolRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureStorageStorageAccountInsecureTlsProtocolRisk;
    const storageAccountModel = entityModelStore.useGet(risk.entityId) as Contract.AzureStorageStorageAccountModel;

    const getAzureStorageStorageAccountRiskContext = useGetAzureStorageStorageAccountRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureStorageStorageAccountInsecureTlsProtocolRiskDefinition",
            () => ({
                description: "{{storageAccount}} allows insecure communication protocols such as TLS 1.0 and 1.1",
                sections: {
                    resolution: {
                        step1: "Before enforcing minimum TLS version, verify that the clients of the storage account support the new version",
                        step2: "Under the **Settings** menu, click the **Configuration** section",
                        step3: "Under the **Minimum TLS version** section, select **Version 1.2**",
                        step4: "Click **Save** to confirm"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            storageAccount:
                <Entity
                    entityIdOrModel={storageAccountModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.Overview}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const storageAccountRiskContext = getAzureStorageStorageAccountRiskContext(storageAccountModel);
            return [
                storageAccountRiskContext.generalInformation,
                storageAccountRiskContext.inboundExternalAccessScope,
                storageAccountRiskContext.childResources,
                storageAccountRiskContext.tlsMinVersion,
                storageAccountRiskContext.sensitive,
                storageAccountRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}