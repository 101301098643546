import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useAzureTenantModelStatusTranslator() {
    const localization =
        useLocalization(
            "tenants.azure.hooks.useAzureTenantModelStatusTranslator",
            () => ({
                [Contract.TypeNames.AzureTenantModelStatus]: {
                    [Contract.AzureTenantModelStatus.AccessDenied]: "Access denied",
                    [Contract.AzureTenantModelStatus.AadTenantIssue]: "Microsoft Entra ID is not connected",
                    [Contract.AzureTenantModelStatus.AadTenantMismatch]: "Microsoft Entra ID is not connected",
                    [Contract.AzureTenantModelStatus.Deleting]: "Deleting, this may take a while...",
                    [Contract.AzureTenantModelStatus.ServicePrincipalDataAnalysisAssignedRoleDefinitionMismatch]: "Connected with issues",
                    [Contract.AzureTenantModelStatus.ServicePrincipalMandatoryAssignedRoleDefinitionMismatch]: "Missing permissions",
                    [Contract.AzureTenantModelStatus.ServicePrincipalOptionalAssignedRoleDefinitionMismatch]: "Missing permissions",
                    [Contract.AzureTenantModelStatus.ServicePrincipalUnauthorized]: "Failed to connect",
                    [Contract.AzureTenantModelStatus.ServicePrincipalWorkloadAnalysisAssignedRoleDefinitionMismatch]: "Connected with issues",
                    [Contract.AzureTenantModelStatus.TenantBlocked]: "Subscription is blocked due to lack of usage",
                    [Contract.AzureTenantModelStatus.TenantDeletePending]: "Subscription is pending deletion",
                    [Contract.AzureTenantModelStatus.TenantNotEnabled]: "Subscription is not enabled",
                    [Contract.AzureTenantModelStatus.TenantNotExist]: "Failed to connect",
                    [Contract.AzureTenantModelStatus.Valid]: "Connected",
                    [Contract.AzureTenantModelStatus.ValidWithIssues]: "Connected with issues"
                }
            }));
    return (status: Contract.AzureTenantModelStatus) => localization[Contract.TypeNames.AzureTenantModelStatus][status]();
}