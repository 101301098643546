import { ItemSelector, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Dispatch, SetStateAction } from "react";
import { Contract, useComplianceTranslator } from "../../../../../../../../../../../common";

export type ComplianceReportItemProps = {
    allComplianceIdentifiers: string[];
    complianceIdentifier?: string;
    customComplianceConfigurationMap: _.Dictionary<Contract.CustomComplianceConfiguration>;
    setComplianceIdentifier: Dispatch<SetStateAction<string | undefined>>;
};

export function ComplianceSelector({ allComplianceIdentifiers, complianceIdentifier, customComplianceConfigurationMap, setComplianceIdentifier }: ComplianceReportItemProps) {
    const complianceTranslator = useComplianceTranslator();
    const localization =
        useLocalization(
            "views.customer.reports.addOrEdit.hooks.useItems.hooks.useGetComplianceReportItem.complianceSelector",
            () => ({
                compliance: {
                    placeholder: "Type",
                    type: {
                        builtIn: "Built-in",
                        custom: "Custom",
                        title: "Compliance Type"
                    }
                }
            }));

    return (
        <Stack spacing={1}>
            <Typography variant="h4">
                {localization.compliance.type.title()}
            </Typography>
            <ItemSelector
                fullWidth={true}
                getItemText={item => complianceTranslator(item.id ?? item).title}
                items={allComplianceIdentifiers}
                placeholder={localization.compliance.placeholder()}
                selectedItem={complianceIdentifier}
                sorted={false}
                onSelectedItemChanged={complianceIdentifier => setComplianceIdentifier(complianceIdentifier)}>
                {complianceIdentifier =>
                    <Stack
                        alignItems="center"
                        direction="row"
                        sx={{ width: "100%" }}>
                        <Typography
                            noWrap={true}
                            sx={{ flex: 1 }}>
                            {complianceTranslator(complianceIdentifier).title}
                        </Typography>
                        <Typography
                            noWrap={true}
                            variant="subtitle1">
                            {_.has(customComplianceConfigurationMap, complianceIdentifier)
                                ? localization.compliance.type.custom()
                                : localization.compliance.type.builtIn()}
                        </Typography>
                    </Stack>}
            </ItemSelector>
        </Stack>);
}