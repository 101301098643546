﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsFirehoseDeliveryStreamStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsFirehoseDeliveryStreamStatusTranslator",
            () => ({
                [Contract.TypeNames.AwsFirehoseDeliveryStreamStatus]: {
                    [Contract.AwsFirehoseDeliveryStreamStatus.Active]: "Active",
                    [Contract.AwsFirehoseDeliveryStreamStatus.Creating]: "Creating",
                    [Contract.AwsFirehoseDeliveryStreamStatus.CreatingFailed]: "Creating Failed",
                    [Contract.AwsFirehoseDeliveryStreamStatus.Deleting]: "Deleting",
                    [Contract.AwsFirehoseDeliveryStreamStatus.DeletingFailed]: "Deleting Failed",
                    [Contract.AwsFirehoseDeliveryStreamStatus.Suspended]: "Suspended"
                }
            }));
    return (status: Contract.AwsFirehoseDeliveryStreamStatus) =>
        localization[Contract.TypeNames.AwsFirehoseDeliveryStreamStatus][status]();
}