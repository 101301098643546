import * as Contract from "../controllers/contract";

export const cloudProviderTenantTypes = [
    Contract.TenantType.Aws,
    Contract.TenantType.Azure,
    Contract.TenantType.Gcp,
    Contract.TenantType.Oci,
    Contract.TenantType.Op
] as const;

export const entitiesTenantTypes = [
    Contract.TenantType.Aad,
    Contract.TenantType.Aws,
    Contract.TenantType.Azure,
    Contract.TenantType.Ci,
    Contract.TenantType.Gci,
    Contract.TenantType.Gcp,
    Contract.TenantType.Oci,
    Contract.TenantType.Okta,
    Contract.TenantType.OneLogin,
    Contract.TenantType.Op,
    Contract.TenantType.PingIdentity
];

export const identityProviderTenantTypes = [
    Contract.TenantType.Aad,
    Contract.TenantType.Gci,
    Contract.TenantType.Okta,
    Contract.TenantType.OneLogin,
    Contract.TenantType.PingIdentity
];

export const tenantsTenantTypes = [
    Contract.TenantType.Aws,
    Contract.TenantType.Azure,
    Contract.TenantType.Ci,
    Contract.TenantType.Code,
    Contract.TenantType.Gcp,
    Contract.TenantType.Oci,
    Contract.TenantType.Op
] as const;

export const permissionManagementTenantTypes = [
    Contract.TenantType.Aad,
    Contract.TenantType.Aws,
    Contract.TenantType.Azure,
    Contract.TenantType.Gci,
    Contract.TenantType.Gcp,
    Contract.TenantType.Okta,
    Contract.TenantType.OneLogin,
    Contract.TenantType.PingIdentity
] as const;