import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAwsCommonEc2ResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, entityModelStore, InfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../components";
import { AwsEc2RouteTableRoutesInfoCard, AwsEc2SubnetsInfoCard } from "../../components";

export function useAwsEc2RouteTableDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonEc2ResourceInfoItemElements = useAwsCommonEc2ResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const routeTable = resourceModel.entity as Contract.AwsEc2RouteTable;
    const routeTableModel = resourceModel as Contract.AwsEc2RouteTableModel;

    const vpcEndpointModels =
        _.filter(
            entityModelStore.useGet(
                _(routeTable.routes).
                    map(route => route.targetResourceId!).
                    filter().
                    value()),
            entityModel => entityModel.typeName === Contract.TypeNames.AwsEc2VpcEndpointModel);
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsEc2RouteTableDefinition",
            () => ({
                info: {
                    cards: {
                        routes: "Routes"
                    },
                    items: {
                        defaultRouteTable: {
                            false: "No",
                            title: "Main Route Table",
                            true: "Yes"
                        },
                        vpc: "VPC",
                        vpcEndpoints: "VPC Endpoints"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonEc2ResourceInfoItemElements,
                    <EntitiesInfoItem
                        entityIdsOrModels={routeTable.vpcId}
                        entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                        key="vpc"
                        title={localization.info.items.vpc()}/>,
                    <InfoItem
                        key="defaultRouteTable"
                        title={localization.info.items.defaultRouteTable.title()}
                        value={
                            routeTable.defaultRouteTable
                                ? localization.info.items.defaultRouteTable.true()
                                : localization.info.items.defaultRouteTable.false()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={vpcEndpointModels}
                        entityTypeName={Contract.TypeNames.AwsEc2VpcEndpoint}
                        key="vpcEndpoints"
                        title={localization.info.items.vpcEndpoints()}/>
                ]}
                options={options?.infoOptions}>
                <AwsEc2RouteTableRoutesInfoCard
                    routeTableId={routeTable.id}
                    title={localization.info.cards.routes()}/>
                <AwsEc2SubnetsInfoCard subnetIds={routeTableModel.subnetIds}/>
            </Info>
    });
}