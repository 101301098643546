import { CheckboxField, FormLayout, Message, useChangeEffect, useInputValidation, useLocalization } from "@infrastructure";
import { Box, Button, CircularProgress, FormControl, FormHelperText, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { useSetWebhookContext, useWebhookContext } from "../..";
import { ConfigurationController, Contract, ScopeHelper, scopeSystemEntityModelStore, UrlHelper, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../../../../../../common";
import { HttpHeaders, HttpHeadersItem } from "./components";

export function AddOrEdit() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const endpointConfigurations =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeNodeModel.configuration.id],
            scopeSystemEntityModelStore.useGetWebhook());
    const { addOrEditOpen } = useWebhookContext();
    const setWebhookContext = useSetWebhookContext();
    const endpointModel =
        _.isBoolean(addOrEditOpen)
            ? undefined
            : addOrEditOpen;
    const [httpHeaderItems, setHttpHeaderItems] =
        useState(
            _.map(
                (endpointModel?.configuration as Contract.WebhookEndpointConfiguration)?.httpHeaders,
                endpointHttpHeader =>
                    new HttpHeadersItem(
                        new Contract.WebhookManagerHttpHeader(
                            endpointHttpHeader.encrypted,
                            endpointHttpHeader.id,
                            endpointHttpHeader.name,
                            endpointHttpHeader.value)
                    ))
            ?? []);
    const [httpHeadersValid, setHttpHeadersValid] = useState(true);
    const [name, setName] = useState((endpointModel?.configuration as Contract.WebhookEndpointConfiguration)?.name ?? "");
    const [serverCertificateValidationEnabled, setServerCertificateValidationEnabled] = useState((endpointModel?.configuration as Contract.WebhookEndpointConfiguration)?.serverCertificateValidationEnabled ?? true);
    const [url, setUrl] = useState((endpointModel?.configuration as Contract.WebhookEndpointConfiguration)?.url ?? "");
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useWebhooksItems.webhook.addOrEdit",
            () => ({
                actions: {
                    save: {
                        error: {
                            add: "Failed to add",
                            edit: "Failed to save"
                        },
                        title: "Save"
                    }
                },
                fields: {
                    httpHeaders: "HTTP Headers",
                    name: {
                        error: {
                            required: "Name cannot be empty"
                        },
                        title: "Name"
                    },
                    serverCertificateValidationEnabled: "Accept any server SSL certificates",
                    url: {
                        error: {
                            exists: "Endpoint URL already exists",
                            invalidFormat: "Invalid URL",
                            required: "Endpoint URL cannot be empty"
                        },
                        title: "Webhook URL"
                    }
                },
                subtitle: "Webhook Info",
                title: "Add webhook"
            }));

    const [nameValidationController, nameValidationMessage] =
        useInputValidation(
            () => {
                const validationName = name.trim().
                    toLowerCase();
                if (_.isEmpty(validationName)) {
                    return localization.fields.name.error.required();
                }

                return undefined;
            },
            [name]);

    const [urlValidationController, urlValidationMessage] =
        useInputValidation(
            () => {
                const validationUrl = url.trim();
                if (_.isEmpty(validationUrl)) {
                    return localization.fields.url.error.required();
                }

                const urlData = UrlHelper.getUrlData(validationUrl);
                if (_.isNil(urlData)) {
                    return localization.fields.url.error.invalidFormat();
                }

                if (_(endpointConfigurations).
                    filter(currentEndpointModel =>
                        currentEndpointModel.configuration.id != endpointModel?.configuration.id &&
                        currentEndpointModel.configuration.scopeId == scopeNodeModel.configuration.id).
                    map(currentEndpointModel => (currentEndpointModel.configuration as Contract.WebhookEndpointConfiguration).url).
                    includes(validationUrl)) {
                    return localization.fields.url.error.exists();
                }

                return undefined;
            },
            [url]);

    const [urlHttpsProtocol, setUrlHttpsProtocol] = useState(() => UrlHelper.getUrlData(url)?.protocolType === "https");
    useChangeEffect(
        () => {
            if (urlValidationController.isValid()) {
                setUrlHttpsProtocol(UrlHelper.getUrlData(url)?.protocolType === "https");
            }
        },
        [url]);

    const [saveEndpointExecuting, setSaveEndpointExecuting] = useState(false);
    const [saveEndpointError, setSaveEndpointError] = useState(false);

    async function save() {
        setSaveEndpointExecuting(true);
        setSaveEndpointError(false);

        try {
            const { scopeSystemEntityModel } =
                _.isNil(endpointModel)
                    ? await ConfigurationController.insertWebhookEndpoint(
                        new Contract.ConfigurationControllerInsertWebhookEndpointRequest(
                            _.map(
                                httpHeaderItems,
                                httpHeaderItem => httpHeaderItem.httpHeader),
                            name,
                            scopeNodeModel.configuration.id,
                            urlHttpsProtocol && serverCertificateValidationEnabled,
                            url))
                    : await ConfigurationController.updateWebhookEndpoint(
                        new Contract.ConfigurationControllerUpdateWebhookEndpointRequest(
                            _.map(
                                httpHeaderItems,
                                httpHeaderItem => httpHeaderItem.httpHeader),
                            endpointModel!.configuration.id,
                            name,
                            urlHttpsProtocol && serverCertificateValidationEnabled,
                            url));

            await scopeSystemEntityModelStore.notify(scopeSystemEntityModel);

            setWebhookContext(
                webhookContext => ({
                    ...webhookContext,
                    addOrEditOpen: false
                }));
        } catch {
            setSaveEndpointError(true);
        }

        setSaveEndpointExecuting(false);
    }

    const [valid, setValid] = useState(!_.isNil(endpointModel));
    useChangeEffect(
        () => {
            setValid(
                nameValidationController.isValid() &&
                urlValidationController.isValid() &&
                httpHeadersValid);
        },
        [httpHeaderItems, name, url]);

    const theme = useTheme();
    return (
        <FormLayout
            disableContentPadding={true}
            footerOptions={{
                border: true,
                contentElement:
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1}>
                        {saveEndpointError &&
                            <Message
                                level="error"
                                title={
                                    _.isNil(endpointModel)
                                        ? localization.actions.save.error.add()
                                        : localization.actions.save.error.edit()}/>}
                        {saveEndpointExecuting &&
                            <CircularProgress
                                size={theme.spacing(2)}
                                variant="indeterminate"/>}
                        <Button
                            disabled={!valid}
                            onClick={() => save()}>
                            {localization.actions.save.title()}
                        </Button>
                    </Stack>
            }}
            titleOptions={{ text: localization.title() }}>
            <Stack
                spacing={8}
                sx={{
                    height: "100%",
                    padding: theme.spacing(3)
                }}>
                <Stack spacing={2}>
                    <Typography variant="h4">
                        {localization.subtitle()}
                    </Typography>
                    <Box sx={{ width: theme.spacing(61) }}>
                        <FormControl
                            fullWidth={true}
                            variant="standard">
                            <TextField
                                label={localization.fields.name.title()}
                                value={name}
                                variant="outlined"
                                onChange={event => setName(event.target.value)}/>
                            {!_.isNil(nameValidationMessage) &&
                                <FormHelperText error={true}>{nameValidationMessage}</FormHelperText>}
                        </FormControl>
                    </Box>
                    <Box sx={{ width: theme.spacing(61) }}>
                        <FormControl
                            fullWidth={true}
                            variant="standard">
                            <TextField
                                label={localization.fields.url.title()}
                                value={url}
                                variant="outlined"
                                onChange={event => setUrl(event.target.value)}/>
                            {!_.isNil(urlValidationMessage) &&
                                <FormHelperText error={true}>{urlValidationMessage}</FormHelperText>}
                        </FormControl>
                    </Box>
                    <CheckboxField
                        checked={urlHttpsProtocol && !serverCertificateValidationEnabled}
                        disabled={!urlHttpsProtocol}
                        onChange={() => setServerCertificateValidationEnabled(!serverCertificateValidationEnabled)}>
                        {localization.fields.serverCertificateValidationEnabled()}
                    </CheckboxField>
                </Stack>
                <Stack spacing={2}>
                    <Typography variant="h4">
                        {localization.fields.httpHeaders()}
                    </Typography>
                    <HttpHeaders
                        items={httpHeaderItems}
                        onItemsChanged={items => setHttpHeaderItems(items)}
                        onValidChanged={valid => setHttpHeadersValid(valid)}/>
                </Stack>
            </Stack>
        </FormLayout>);
}