﻿import { Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetGcpContainerClusterRiskContext } from "../../..";
import { Contract, Entity, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";

export function useGcpContainerClusterInstanceInboundExternalRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpContainerClusterInstanceInboundExternalRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId) as Contract.GcpContainerClusterModel;
    const clusterInboundExternalComputeInstanceRiskModel = riskModel as Contract.GcpContainerClusterInstanceInboundExternalRiskModel;

    const getGcpContainerClusterRiskContext = useGetGcpContainerClusterRiskContext();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpContainerClusterInstanceInboundExternalRiskDefinition",
            () => ({
                contextItems: {
                    instanceDatas: {
                        withInboundExternalWideRangeInstances: {
                            many: [
                                "The {{translatedClusterTypeName}} has {{instances}} with a public IP address. {{inboundExternalWideRangeInstances}} of them is accessible directly from a wide range of public IP addresses",
                                "The {{translatedClusterTypeName}} has {{instances}} with a public IP address. {{inboundExternalWideRangeInstances}} of them are accessible directly from a wide range of public IP addresses"
                            ],
                            single: "The {{translatedClusterTypeName}} has {{instances}} that is accessible directly from a wide range of public IP addresses"
                        },
                        withoutInboundExternalWideRangeInstances: "The {{translatedClusterTypeName}} has {{instances}} with a public IP address"
                    },
                    nodePublicIpAddressExistsNodePools: {
                        any: "The {{translatedClusterTypeName}} has {{nodePublicIpAddressExistsNodePools}} configured to assign public IPs to nodes",
                        none: "The {{translatedClusterTypeName}} is configured to create nodes with public IP addresses"
                    }
                },
                description: "{{cluster}} contains nodes with public IPs",
                sections: {
                    resolution: {
                        step1: "Note: To remediate this finding, you'll need to recreate your GKE cluster with private nodes",
                        step2: "In GCP Portal, open the **Kubernetes Engine** menu",
                        step3: "Click on the **Clusters** tab and then click **Create**",
                        step4: "Configure **Cluster basics** (mode, name, region, zone, and version)",
                        step5: "Click **Networking** on the left-hand side",
                        step6: "Under the **IPv4 network access** section, select **Private cluster**",
                        step7: "Configure the rest of the settings based on your needs, and then click **Create**. You now have a GKE cluster with private nodes",
                        step8: {
                            link: "GCP documentation",
                            text: "If you want to provide outbound internet access for certain private nodes, enable Cloud NAT by following the {{containerClusterPublicNatDocumentationUrl}}"
                        }
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={risk.entityId}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6(),
            localization.sections.resolution.step7(),
            localization.sections.resolution.step8.text({
                containerClusterPublicNatDocumentationUrl:
                    <Link
                        urlOrGetUrl={clusterInboundExternalComputeInstanceRiskModel.containerClusterPublicNatDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step8.link()}
                    </Link>
            })
        ],
        riskModel,
        () => {
            const clusterRiskContext = getGcpContainerClusterRiskContext(clusterModel);

            const inboundExternalWideRangeInstanceIds =
                _(risk.instanceDatas).
                    filter(instanceData => instanceData.inboundExternalWideRange).
                    map(instanceData => instanceData.id).
                    value();
            const instancesElement =
                <InlineEntities
                    entityIdsOrModels={
                        _.map(
                            risk.instanceDatas,
                            instanceData => instanceData.id)}
                    entityTypeName={Contract.TypeNames.GcpComputeInstance}
                    variant="itemCountAndType"/>;
            const translatedClusterTypeName =
                entityTypeNameTranslator(
                    clusterModel.entity.typeName,
                    {
                        includeServiceName: false,
                        variant: "text"
                    });

            return [
                clusterRiskContext.generalInformation,
                clusterRiskContext.sensitive,
                clusterRiskContext.vpc,
                new RiskDefinitionContextItem(
                    _.isEmpty(risk.nodePublicIpAddressExistsNodePoolIds)
                        ? localization.contextItems.nodePublicIpAddressExistsNodePools.none({ translatedClusterTypeName })
                        : localization.contextItems.nodePublicIpAddressExistsNodePools.any({
                            nodePublicIpAddressExistsNodePools:
                                <InlineEntities
                                    entityIdsOrModels={risk.nodePublicIpAddressExistsNodePoolIds}
                                    entityTypeName={Contract.TypeNames.GcpContainerClusterNodePool}
                                    variant="itemCountAndType"/>,
                            translatedClusterTypeName
                        })),
                _.isEmpty(risk.instanceDatas)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        _.isEmpty(inboundExternalWideRangeInstanceIds)
                            ? localization.contextItems.instanceDatas.withoutInboundExternalWideRangeInstances({
                                instances: instancesElement,
                                translatedClusterTypeName
                            })
                            : _.size(risk.instanceDatas) > 1
                                ? localization.contextItems.instanceDatas.withInboundExternalWideRangeInstances.many(
                                    _.size(inboundExternalWideRangeInstanceIds),
                                    {
                                        inboundExternalWideRangeInstances:
                                            <InlineEntities
                                                entityIdsOrModels={inboundExternalWideRangeInstanceIds}
                                                entityTypeName={Contract.TypeNames.GcpComputeInstance}
                                                variant="itemCount"/>,
                                        instances: instancesElement,
                                        translatedClusterTypeName
                                    })
                                : localization.contextItems.instanceDatas.withInboundExternalWideRangeInstances.single({
                                    instances: instancesElement,
                                    translatedClusterTypeName
                                })),
                clusterRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}