import { NoneIcon } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { UdmObjectPropertyItemProps } from "../..";
import { InlineResourceTags } from "../../../../InlineResourceTags";

export function CommonEntityTags({ item }: UdmObjectPropertyItemProps) {
    const tags = _.concat(item);
    return (
        _.isEmpty(tags)
            ? <NoneIcon sx={{ fontSize: "18px" }}/>
            : <InlineResourceTags tags={tags}/>);
}