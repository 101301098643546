import { Link, Step, Steps, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities, tenantModelStore } from "../../../../../../../../../../../../common";
import { AadConsoleUrlBuilder } from "../../../../../../../../../../../../tenants/aad/utilities/aadConsoleUrlBuilder.generated";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useOpenRiskedEntityRisksStep } from "../../../useOpenRiskedEntityRisksStep";

export function useAzureGuestUserAdministratorRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureGuestUserAdministratorRisk;
    const userModel = entityModelStore.useGet(risk.entityId) as Contract.AadDirectoryUserModel;
    const user = userModel.entity as Contract.AadDirectoryUser;
    const aadTenantConfiguration = tenantModelStore.useGet(userModel.tenantId).configuration as Contract.AadTenantConfiguration;
    const roleAssignmentResourceModels = entityModelStore.useGet(risk.roleAssignmentResourceIds);
    const roleAssignmentResources =
        _.map(
            roleAssignmentResourceModels,
            roleAssignmentResourceModel => roleAssignmentResourceModel.entity as Contract.AzureAuthorizationRoleAssignment);
    const roleAssignmentResourcesGroupIds =
        _(roleAssignmentResources).
            filter(
                roleAssignmentResource =>
                    roleAssignmentResource.principalReference.id != user.id &&
                    !_.isNil(roleAssignmentResource.principalReference.id)).
            map(roleAssignmentResource => roleAssignmentResource.principalReference.id!).
            value();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureGuestUserAdministratorRiskDefinition",
            () => ({
                description: {
                    external: "{{user}} is an external user and also has an administrative role",
                    guest: "{{user}} is a guest user and also has an administrative role"
                },
                sections: {
                    resolution: {
                        step1: {
                            link: "Azure role assignments page",
                            text: "In Azure Portal, open the {{roleAssignmentsLink}} of {{user}} and select {{tenantEntity}}"
                        },
                        step2: "For each role assignment click on the resource name",
                        step3: "Click **Access control (IAM)**",
                        step4: "Click the **Role assignments** tab",
                        step5: {
                            step1: "Select the checkbox next to the user entry with the relevant administrative role assignments",
                            step2: "Click **Remove** button and then click **Yes** to confirm",
                            title: "Search for the {{user}}"
                        },
                        step6: {
                            groups: [
                                "1 group",
                                "{{count | NumberFormatter.humanize}} groups"
                            ],
                            step1: "Click on the group name",
                            step2: "Click on Members under the **Manage** section, then search for {{user}}",
                            step3: "Click **Remove** button and then click **Yes** to confirm",
                            title: "Search for the {{groups}}"
                        }
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        (user.authenticationType === Contract.AadDirectoryUserAuthenticationType.External
            ? localization.description.external
            : localization.description.guest)({
            user:
                <Entity
                    entityIdOrModel={userModel}
                    entityTypeNameTranslatorOptions={{
                        includeServiceName: true,
                        variant: "title"
                    }}
                    variant="typeText"/>
        }),
        () => [
            localization.sections.resolution.step1.text({
                roleAssignmentsLink:
                    <Link
                        urlOrGetUrl={AadConsoleUrlBuilder.GetUserRoleAssignmentUrl(
                            aadTenantConfiguration.partitionType,
                            user.rawId)}
                        variant="external">
                        {localization.sections.resolution.step1.link()}
                    </Link>,
                tenantEntity:
                    <Entity
                        entityIdOrModel={risk.tenantId}
                        linkOptions={{ disabled: true }}
                        variant="typeText"/>,
                user:
                    <Entity
                        entityIdOrModel={userModel}
                        linkOptions={{ disabled: true }}
                        variant="typeText"/>
            }),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            _.some(
                roleAssignmentResources,
                roleAssignmentResource => roleAssignmentResource.principalReference.id === user.id)
                ? new Step(
                    localization.sections.resolution.step5.title({
                        user:
                            <Entity
                                entityIdOrModel={userModel}
                                variant="typeText"/>
                    }),
                    {
                        contentElement:
                            <Steps variant="plainNumbers">
                                {localization.sections.resolution.step5.step1()}
                                {localization.sections.resolution.step5.step2()}
                            </Steps>
                    })
                : undefined,
            _.isEmpty(roleAssignmentResourcesGroupIds)
                ? undefined
                : new Step(
                    localization.sections.resolution.step6.title({
                        groups:
                            <InlineEntities
                                entityIdsOrModels={roleAssignmentResourcesGroupIds}
                                entityTypeName={Contract.TypeNames.AadDirectoryGroup}
                                namePluralizer={localization.sections.resolution.step6.groups}
                                variant="itemCountAndType"/>
                    }),
                    {
                        contentElement:
                            <Steps variant="plainNumbers">
                                {localization.sections.resolution.step6.step1()}
                                {localization.sections.resolution.step6.step2({
                                    user:
                                        <Entity
                                            entityIdOrModel={userModel}
                                            variant="text"/>
                                })}
                                {localization.sections.resolution.step6.step3()}
                            </Steps>
                    })
        ],
        riskModel,
        undefined,
        {
            contextSectionElement:
                <ContextSection
                    risk={risk}
                    userModel={userModel}/>
        });
}


type ContextSectionProps = {
    risk: Contract.AzureGuestUserAdministratorRisk;
    userModel: Contract.AadDirectoryUserModel;
};

function ContextSection({ risk, userModel }: ContextSectionProps) {
    const openRiskedEntityRisksStep = useOpenRiskedEntityRisksStep(userModel, risk.id);
    const user = userModel.entity as Contract.AadDirectoryUser;

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureGuestUserAdministratorRiskDefinition.ContextSection",
            () => ({
                info: "User {{user}} was created on {{creationTime | TimeFormatter.shortDate}}",
                lastSignIn: {
                    never: "{{user}} has never signed in to Microsoft Entra ID",
                    text: "{{user}} last sign in was {{signInTime | TimeFormatter.shortDate}}"
                },
                roleAssignmentResources: "{{user}} has {{roleAssignmentResourceIds}} granting it administrative privileges"
            }));

    return <Steps variant="bullets">
        {_.filter([
            _.isNil(user.creationTime)
                ? undefined
                : localization.info({
                    creationTime: user.creationTime,
                    user:
                        <Entity
                            entityIdOrModel={userModel}
                            variant="text"/>
                }),
            _.isNil((userModel.entityProfile as Contract.AadDirectoryUserProfile)?.signInTime)
                ? localization.lastSignIn.never({
                    user:
                        <Entity
                            entityIdOrModel={userModel}
                            variant="text"/>
                })
                : localization.lastSignIn.text({
                    signInTime:
                        TimeFormatter.shortDate((userModel.entityProfile as Contract.AadDirectoryUserProfile)?.signInTime),
                    user:
                        <Entity
                            entityIdOrModel={userModel}
                            variant="text"/>
                }),
            localization.roleAssignmentResources({
                roleAssignmentResourceIds:
                    <InlineEntities
                        entityIdsOrModels={risk.roleAssignmentResourceIds}
                        entityTypeName={Contract.TypeNames.AzureAuthorizationRoleAssignmentResource}
                        entityTypeNameTranslatorOptions={{
                            includeServiceName: false,
                            variant: "text"
                        }}
                        variant="itemCountAndType"/>,
                user:
                    <Entity
                        entityIdOrModel={userModel}
                        variant="text"/>
            }),
            openRiskedEntityRisksStep
        ])}
    </Steps>;
}