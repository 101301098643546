﻿import { optionalTableCell, useUncaptureValue } from "@infrastructure";
import _ from "lodash";
import { ReactNode, useMemo } from "react";
import { Contract, ScopeHelper, tenantModelStore } from "../../../../../../../common";

export function useRenderTenant<TTenantModel extends Contract.TenantModel>() {
    const tenantModels = tenantModelStore.useGetAll();
    const tenantModelMap =
        useMemo(
            () =>
                _.keyBy(
                    tenantModels,
                    tenantModel => tenantModel.configuration.id),
            [tenantModels]);

    const uncaptureTenantModelMap = useUncaptureValue(tenantModelMap);
    const renderTenantCell =
        (render: (tenantModel: TTenantModel) => ReactNode) =>
            optionalTableCell<Contract.ScopeNodeModel>(
                scopeNodeModel =>
                    uncaptureTenantModelMap(
                        tenantModelMap =>
                            ScopeHelper.isTenant(scopeNodeModel) &&
                            !_.isNil(tenantModelMap[scopeNodeModel.configuration.id])
                                ? render(tenantModelMap[scopeNodeModel.configuration.id] as TTenantModel)
                                : undefined));
    const renderTenantCsvItem =
        (render: (tenantModel: TTenantModel) => string | undefined, scopeNodeModel: Contract.ScopeNodeModel) =>
            ScopeHelper.isTenant(scopeNodeModel) &&
            !_.isNil(tenantModelMap[scopeNodeModel.configuration.id])
                ? render(tenantModelMap[scopeNodeModel.configuration.id] as TTenantModel) ?? ""
                : "";
    return { renderTenantCell, renderTenantCsvItem };
}