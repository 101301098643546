import { Action0, CollapsedIcon, IconText, Loading, makeContextProvider } from "@infrastructure";
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from "@mui/material";
import React, { ReactElement, ReactNode, useState } from "react";
import { useTheme } from "../../../common";

type PermissionRequestAccordionProps = {
    children: ReactNode;
    icon: ReactElement;
    permissionRequestCount: number;
    title: string;
};

export class PermissionRequestAccordionContext {
    constructor(public close: Action0) {
    }
}

export const [usePermissionRequestAccordionContext, , usePermissionRequestAccordionContextProvider] = makeContextProvider<PermissionRequestAccordionContext>();

export function PermissionRequestAccordion({ children, icon, permissionRequestCount, title }: PermissionRequestAccordionProps) {
    const [expanded, setExpanded] = useState(true);
    const [, , ContextProvider] = usePermissionRequestAccordionContextProvider(() => new PermissionRequestAccordionContext(() => setExpanded(false)));

    const theme = useTheme();
    return (
        <ContextProvider>
            <Accordion
                expanded={expanded}
                onChange={(_event, expanded) => setExpanded(expanded)}>
                <AccordionSummary expandIcon={<CollapsedIcon/>}>
                    <Stack
                        alignItems="center"
                        direction="row"
                        sx={{ width: "100%" }}>
                        <IconText
                            containerSx={{
                                flex: 1,
                                paddingRight: 0
                            }}
                            icon={icon}
                            iconSx={{ fontSize: "20px" }}
                            text={
                                <Typography variant="h4">
                                    {title}
                                </Typography>}/>
                        <Typography
                            sx={{
                                border: theme.border.primary,
                                borderRadius: theme.spacing(0.75),
                                fontWeight: 500,
                                padding: theme.spacing(0.5, 1)
                            }}>
                            {permissionRequestCount}
                        </Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails sx={{ border: 0 }}>
                    <Loading>
                        {children}
                    </Loading>
                </AccordionDetails>
            </Accordion>
        </ContextProvider>);
}