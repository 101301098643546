import _, { Function0 } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { EmptyMessage, Loading, useLocalization } from "@infrastructure";
import { Contract, TimeFrameSelector, useComplianceSectionDatas, useSelectedScopeId, WidgetDefinition } from "../../../../../../../..";
import { SummaryDashboardContext, useDashboardContext } from "../../../../../../Dashboard";
import { Analyzing, Items } from "./components";

export function useGetComplianceDefinition(): () => WidgetDefinition {
    const [timeFrame, setTimeFrame] = useState<Contract.TimeFrame>(Contract.TimeFrame.Medium);

    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetComplianceDefinition",
            () => ({
                empty: "No Compliance",
                helpText: "Ensure compliance with common industry standards and security best practices by viewing a snapshot of your compliance posture over a set period of time (last 7, 30, or 90 days). The percentages shown reflect how compliant you are with relevant policy requirements for each standard, along with the percentage of change during the given period. Click on a standard to see policy violations that prevent full compliance.",
                title: "Compliance"
            }));

    return useCallback<Function0<WidgetDefinition>>(
        (): WidgetDefinition => ({
            element:
                <Core
                    emptyText={localization.empty()}
                    timeFrame={timeFrame}/>,
            options: {
                helpText: localization.helpText(),
                title: localization.title(),
                topElement:
                <TimeFrameSelector
                    timeFrame={timeFrame}
                    onTimeFrameChanged={setTimeFrame}/>,
                variant: "scrollable"
            }
        }),
        [localization, timeFrame]);
}

type CoreProps = {
    emptyText: string;
    timeFrame: Contract.TimeFrame;
};

function Core({ emptyText, timeFrame }: CoreProps) {
    const { analyzing } = useDashboardContext<SummaryDashboardContext>();

    return (
        <Loading>
            {analyzing
                ? <Analyzing/>
                : <Compliance
                    emptyText={emptyText}
                    timeFrame={timeFrame}/>}
        </Loading>);
}

function Compliance({ emptyText, timeFrame }: CoreProps) {
    const { selectedScopeId } = useSelectedScopeId();
    const { customComplianceSectionDatas, enabledBuiltInComplianceSectionDatas } =
        useComplianceSectionDatas(
            selectedScopeId,
            { timeFrameData: true });
    const complianceSectionDatas =
        useMemo(
            () =>
                _.concat(
                    enabledBuiltInComplianceSectionDatas,
                    customComplianceSectionDatas),
            [enabledBuiltInComplianceSectionDatas, customComplianceSectionDatas]);

    return (
        _.isEmpty(complianceSectionDatas)
            ? <EmptyMessage
                message={emptyText}
                verticalCenter={true}/>
            : <Items
                complianceSectionDatas={complianceSectionDatas}
                timeFrame={timeFrame}/>);
}