import { NumberFormatter, Shadows } from "@infrastructure";
import { Button, Divider, Skeleton, Stack, SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React, { ForwardedRef, forwardRef, ReactEventHandler, ReactNode, useRef } from "react";
import { useTheme } from "../themes";

type OutlineLabelButtonProps = {
    active?: boolean;
    className?: string;
    count?: number;
    countLoading?: boolean;
    disabled?: boolean;
    icon?: ReactNode;
    label: string;
    onClick?: ReactEventHandler<HTMLButtonElement>;
    sx?: SxProps;
};

const OutlineLabelButtonForwardRef = forwardRef<HTMLButtonElement, OutlineLabelButtonProps>(OutlineLabelButton);
export { OutlineLabelButtonForwardRef as OutlineLabelButton };

function OutlineLabelButton({ active, className, count, countLoading, disabled, icon, label, onClick, sx }: OutlineLabelButtonProps, ref: ForwardedRef<HTMLButtonElement>) {
    const countElementRef = useRef<HTMLSpanElement>(null);
    const interactive = !_.isNil(onClick);
    const inactive = active === false;
    const theme = useTheme();
    return (
        <Button
            className={className}
            disabled={disabled}
            ref={ref}
            sx={{
                "&:disabled": {
                    backgroundColor: "transparent",
                    border: theme.border.primary,
                    boxShadow: "none",
                    color: theme.palette.text.disabled
                },
                "&:hover": {
                    border:
                        inactive
                            ? theme.border.hoverFocus
                            : undefined,
                    boxShadow: theme.shadows[Shadows.Card],
                    color: theme.palette.text.primary
                },
                border:
                    interactive
                        ? active
                            ? theme.border.hoverFocus
                            : theme.border.primary
                        : theme.border.hoverFocus,
                color:
                    disabled && !active
                        ? theme.palette.text.disabled
                        : inactive
                            ? theme.palette.text.secondary
                            : theme.palette.text.primary,
                cursor:
                    interactive
                        ? "pointer"
                        : "default",
                height: theme.px(22),
                minHeight: "auto",
                minWidth: "auto",
                opacity:
                    inactive
                        ? 0.6
                        : undefined,
                padding: theme.spacing(0.5, 0.75),
                pointerEvents:
                    interactive
                        ? "auto"
                        : "none",
                ...sx
            }}
            variant="outlined"
            onClick={onClick}>
            <Stack
                alignItems="center"
                columnGap={0.5}
                direction="row">
                {icon}
                <Typography
                    noWrap={true}
                    sx={{
                        color: "inherit",
                        lineHeight: 1
                    }}>
                    {label}
                </Typography>
                {!_.isNil(count) &&
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={0.5}
                        sx={{
                            height: "100%",
                            overflow: "hidden",
                            position: "relative"
                        }}>
                        <Divider
                            flexItem={true}
                            orientation="vertical"/>
                        <Skeleton
                            animation="wave"
                            sx={{
                                height: 15,
                                position:
                                    countLoading
                                        ? "static"
                                        : "absolute",
                                visibility:
                                    countLoading
                                        ? "visible"
                                        : "hidden",
                                width: countElementRef.current?.clientWidth || 15
                            }}/>
                        <Typography
                            component="span"
                            noWrap={true}
                            ref={countElementRef}
                            sx={{
                                color: "inherit",
                                lineHeight: 1,
                                minWidth: 6,
                                position:
                                    countLoading
                                        ? "absolute"
                                        : "static",
                                visibility:
                                    countLoading
                                        ? "hidden"
                                        : "visible"
                            }}>
                            {NumberFormatter.humanize(count)}
                        </Typography>
                    </Stack>}
            </Stack>
        </Button>);
}