﻿import { Dropdown, StringHelper, useLocalization } from "@infrastructure";
import { Box, List, ListItem, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, useTheme } from "../../../../../../../../../../../common";
import { AwsActionHelper } from "../../../../../../../../../../../tenants";

export type PublicS3BucketAccessControlListSectionProps = {
    accessControlList: Contract.AwsS3AccessControlList;
};

export function PublicS3BucketAccessControlListSection({ accessControlList }: PublicS3BucketAccessControlListSectionProps) {
    const limitPermissionsCount = 3;
    const publicAccessPermissionActions =
        _(accessControlList.publicAccessPermissionActions).
            map(
                publicAccessPermissionAction => ({
                    publicAccessPermissionAction,
                    publicAccessPermissionActionName: AwsActionHelper.getName(publicAccessPermissionAction)
                })).
            orderBy(({ publicAccessPermissionActionName }) => StringHelper.getSortValue(publicAccessPermissionActionName)).
            map(
                ({ publicAccessPermissionAction, publicAccessPermissionActionName }) =>
                    <Typography
                        key={publicAccessPermissionAction}
                        noWrap={true}>
                        {publicAccessPermissionActionName}
                    </Typography>).
            value();

    const theme = useTheme();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.publicS3BucketAccessControlListSection",
            () => ({
                fields: {
                    logDeliveryGroupAccess: {
                        false: "Not enabled",
                        title: "**S3 Log Delivery Group**",
                        true: "Enabled"
                    },
                    otherTenantAccessCanonicalIds: {
                        disabled: "Not enabled",
                        enabled: [
                            "Enabled for 1 account",
                            "Enabled for {{count | NumberFormatter.humanize}} accounts"
                        ],
                        title: "**Access for other AWS accounts**"
                    },
                    publicAccessPermissionActions: {
                        disabled: "Not enabled",
                        title: "**Public Access**"
                    },
                    tenantAccess: {
                        false: "Not enabled",
                        title: "**Access for bucket owner**",
                        true: "Enabled"
                    }
                },
                limitPermissionsAdditional: "+{{additionalCount | NumberFormatter.humanize}}"
            }));
    return (
        <Stack spacing={3}>
            <Box>
                <Typography variant="h5">
                    {localization.fields.tenantAccess.title()}
                </Typography>
                {accessControlList.tenantAccess
                    ? localization.fields.tenantAccess.true()
                    : localization.fields.tenantAccess.false()}
            </Box>
            <Box>
                <Typography variant="h5">
                    {localization.fields.otherTenantAccessCanonicalIds.title()}
                </Typography>
                {_.isEmpty(accessControlList.otherTenantAccessCanonicalIds)
                    ? localization.fields.otherTenantAccessCanonicalIds.disabled()
                    : localization.fields.otherTenantAccessCanonicalIds.enabled(accessControlList.otherTenantAccessCanonicalIds.length)}
            </Box>
            <Box>
                <Typography variant="h5">
                    {localization.fields.publicAccessPermissionActions.title()}
                </Typography>
                {publicAccessPermissionActions.length == 0
                    ? localization.fields.publicAccessPermissionActions.disabled()
                    : <Typography
                        sx={{
                            color: theme.palette.error.main,
                            fontWeight: 600
                        }}>
                        {_(publicAccessPermissionActions).
                            take(limitPermissionsCount).
                            value()}
                        {publicAccessPermissionActions.length > limitPermissionsCount && (
                            <Dropdown
                                popoverElement={
                                    <List>
                                        {_(publicAccessPermissionActions).
                                            drop(limitPermissionsCount).
                                            map(
                                                (permission, permissionIndex) =>
                                                    <ListItem key={permissionIndex}>
                                                        <Typography
                                                            sx={{
                                                                color: theme.palette.error.main,
                                                                display: "unset",
                                                                fontWeight: 600
                                                            }}>
                                                            {permission}
                                                        </Typography>
                                                    </ListItem>).
                                            value()}
                                    </List>
                                }
                                popoverElementContainerSx={{ padding: 0 }}>
                                <Typography sx={{ textDecoration: "underline" }}>
                                    {localization.limitPermissionsAdditional({ additionalCount: publicAccessPermissionActions.length - limitPermissionsCount })}
                                </Typography>
                            </Dropdown>)}
                    </Typography>}
            </Box>
            <Box>
                <Typography variant="h5">
                    {localization.fields.logDeliveryGroupAccess.title()}
                </Typography>
                {accessControlList.logDeliveryGroupAccess
                    ? localization.fields.logDeliveryGroupAccess.true()
                    : localization.fields.logDeliveryGroupAccess.false()}
            </Box>
        </Stack>);
}