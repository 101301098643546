import _ from "lodash";
import React from "react";
import { DialogMenuItem, useLocalization } from "@infrastructure";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../..";
import { Contract, InfoItem, tenantModelStore, useEntityTypeNameTranslator, usePrincipalModelAccess } from "../../../../../../../../../../common";
import { Vendor } from "../../../../../../../../../../common/components/Vendor";
import { GenerateAzureIdentityNonexcessiveRoleDefinitionDialog } from "../../../../../../../../../../tenants";
import { EntitiesInfoCard, EntitiesInfoItem, Info, PrincipalSecurityStatsStat } from "../../../../components";
import { GenerateEntityNonexcessivePermitterIcon } from "../../../../icons";
import { AadDirectoryPrincipalAzureRoleAssignmentResources, AadDirectoryPrincipalDirectoryRoleAssignmentResources, AadDirectoryServicePrincipalApplicationRoleAssignments } from "../../components";
import { AadDirectoryServicePrincipalMicrosoftGraphPermissions } from "../../components/AadDirectoryServicePrincipalMicrosoftGraphPermissions";
import { useAadCommonDirectoryServicePrincipalInfoItemElements } from "./useAadCommonDirectoryServicePrincipalInfoItemElements";
import { useAadDefaultDirectoryEntityInfoItemElements } from "./useAadDefaultDirectoryEntityInfoItemElements";

export function useAadDirectoryApplicationServicePrincipalDefinition(directoryEntityModel: Contract.AadDirectoryEntityModel, options?: DefinitionOptions) {
    const activeCloudProviderTenantTypes = tenantModelStore.useGetActiveCloudProviderTenantTypes();
    const applicationServicePrincipal = directoryEntityModel.entity as Contract.AadDirectoryApplicationServicePrincipal;
    const applicationServicePrincipalModel = directoryEntityModel as Contract.AadDirectoryApplicationServicePrincipalModel;
    const applicationServicePrincipalModelAccess = usePrincipalModelAccess<Contract.PrincipalModelAccess>(applicationServicePrincipalModel.id);
    const commonDirectoryServicePrincipalInfoItemElements = useAadCommonDirectoryServicePrincipalInfoItemElements(applicationServicePrincipalModel);
    const defaultResourceInfoItemElements = useAadDefaultDirectoryEntityInfoItemElements(directoryEntityModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aad.useAadDirectoryApplicationServicePrincipalDefinition",
            () => ({
                info: {
                    cards: {
                        groupIds: "Group Memberships"
                    },
                    items: {
                        applicationId: "App Registration",
                        vendors: "Vendor"
                    }
                },
                tabs: {
                    aadAdministrativeRoleAssignments: "Microsoft Entra ID Administrative Role Assignments",
                    applicationRoleDefinitions: "Users and Groups",
                    azureRoleAssignments: "Azure Role Assignments",
                    graphApiPermissions: "Graph API Permissions"
                },
                topbar: {
                    menu: {
                        generate: "Generate Custom Role"
                    }
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs:
            _<EntityProfileDefinitionTab>([]).
                concat(
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Iam,
                        <AadDirectoryServicePrincipalApplicationRoleAssignments
                            applicationRoleDefinitions={applicationServicePrincipal.applicationRoleDefinitions}
                            assignedPrincipalIdToApplicationRoleDefinitionRawIdsMap={applicationServicePrincipalModel.assignedPrincipalIdToApplicationRoleDefinitionRawIdsMap}
                            csvExportFilePrefixes={[
                                entityTypeNameTranslator(directoryEntityModel.entity.typeName, { includeServiceName: false }),
                                directoryEntityModel.entity.displayName,
                                localization.tabs.applicationRoleDefinitions()
                            ]}/>,
                        localization.tabs.applicationRoleDefinitions(),
                        "applicationRoleDefinitions")).
                concat(
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Iam,
                        <AadDirectoryPrincipalDirectoryRoleAssignmentResources
                            csvExportFilePrefix={localization.tabs.aadAdministrativeRoleAssignments()}
                            principalModel={applicationServicePrincipalModel}/>,
                        localization.tabs.aadAdministrativeRoleAssignments(),
                        "aadAdministrativeRoleAssignments")).
                concatIf(
                    _.includes(activeCloudProviderTenantTypes, Contract.TenantType.Azure),
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Iam,
                        <AadDirectoryPrincipalAzureRoleAssignmentResources
                            csvExportFilePrefix={localization.tabs.azureRoleAssignments()}
                            principalModel={applicationServicePrincipalModel}/>,
                        localization.tabs.azureRoleAssignments(),
                        "azureRoleAssignments")).
                concatIf(
                    applicationServicePrincipal.type == Contract.AadDirectoryServicePrincipalType.Enterprise,
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Iam,
                        <AadDirectoryServicePrincipalMicrosoftGraphPermissions
                            applicationRoleDefinitions={applicationServicePrincipalModel.microsoftGraphRoleAssignmentRoleDefinitions}
                            csvExportFilePrefixes={[
                                entityTypeNameTranslator(directoryEntityModel.entity.typeName, { includeServiceName: false }),
                                directoryEntityModel.entity.displayName,
                                localization.tabs.graphApiPermissions()
                            ]}/>,
                        localization.tabs.graphApiPermissions(),
                        "graphApiPermissions")).
                value(),
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    commonDirectoryServicePrincipalInfoItemElements.tagsInfoItemElement,
                    commonDirectoryServicePrincipalInfoItemElements.enabledInfoItemElement,
                    commonDirectoryServicePrincipalInfoItemElements.signInTimeInfoItemElement,
                    commonDirectoryServicePrincipalInfoItemElements.activityTimeInfoItemElement,
                    commonDirectoryServicePrincipalInfoItemElements.applicationRawIdInfoItemElement,
                    commonDirectoryServicePrincipalInfoItemElements.typeInfoItemElement,
                    commonDirectoryServicePrincipalInfoItemElements.homepageRawUrlInfoItemElement,
                    commonDirectoryServicePrincipalInfoItemElements.assignmentRequiredInfoItemElement,
                    commonDirectoryServicePrincipalInfoItemElements.visibleInfoItemElement,
                    commonDirectoryServicePrincipalInfoItemElements.ownerPrincipalIdsInfoItemElement,
                    <EntitiesInfoItem
                        entityIdsOrModels={applicationServicePrincipalModel.applicationId}
                        entityTypeName={Contract.TypeNames.AadDirectoryApplication}
                        key="applicationId"
                        title={localization.info.items.applicationId()}/>,
                    <InfoItem
                        key="vendors"
                        location="all"
                        title={localization.info.items.vendors()}>
                        {_.isEmpty(applicationServicePrincipal.vendors)
                            ? undefined
                            : <Vendor vendorType={applicationServicePrincipal.vendors[0]}/>}
                    </InfoItem>
                ]}
                options={options?.infoOptions}>
                <EntitiesInfoCard
                    entityIdsOrModels={applicationServicePrincipalModel.groupIds}
                    entityTypeName={Contract.TypeNames.AadDirectoryGroup}
                    title={localization.info.cards.groupIds()}/>
            </Info>,
        sensitive: false,
        statElements:
            _.filter([
                _.isNil(applicationServicePrincipalModelAccess?.permissions)
                    ? undefined
                    : <PrincipalSecurityStatsStat
                        analyzingReason={applicationServicePrincipalModel.analyzing?.reason}
                        analyzingTenants={
                            _.isNil(applicationServicePrincipalModel.analyzing?.tenantIds)
                                ? undefined
                                : {
                                    ids: applicationServicePrincipalModel.analyzing!.tenantIds,
                                    typeName: Contract.TypeNames.AzureTenantEntity
                                }}
                        securityStats={applicationServicePrincipalModelAccess.permissions!.excessivePermissionActionStats}
                        typeName={Contract.TypeNames.AadDirectoryApplicationServicePrincipal}/>
            ]),
        topbarMenuItems:
            _.isNil(applicationServicePrincipalModel.systemDeletionTime)
                ? [
                    new DialogMenuItem(
                        onClose =>
                            <GenerateAzureIdentityNonexcessiveRoleDefinitionDialog
                                identityModel={applicationServicePrincipalModel}
                                onClose={onClose}/>,
                        localization.topbar.menu.generate(),
                        { icon: <GenerateEntityNonexcessivePermitterIcon/> })
                ]
                : undefined
    });
}