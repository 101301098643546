﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoCard, InfoItem } from "../../../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../../../components";

type ClusterNetworkingInfoCardProps = {
    network: Contract.GcpContainerClusterNetwork;
    subnetId: string;
    url?: string;
    vpcId: string;
};

export function ClusterNetworkingInfoCard({ network, subnetId, url, vpcId }: ClusterNetworkingInfoCardProps) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpContainerClusterDefinition.clusterNetworkingInfoCard",
            () => ({
                sections: {
                    network: {
                        aliasSubnet: {
                            false: "Disabled",
                            title: "VPC-native traffic routing",
                            true: "Enabled"
                        },
                        authorizedSubnetDatas: {
                            false: "Disabled",
                            title: "Control Plane Authorized Networks",
                            true: "Enabled"
                        },
                        inboundExternalGcpRange: {
                            false: "Disabled",
                            title: "Allow access through Google Cloud public IP addresses",
                            true: "Enabled"
                        },
                        podSubnet: "Cluster IP Address Range",
                        privateIpAddress: "Private Endpoint",
                        privateSubnet: "Control Plane IP Address Range",
                        publicIpAddressEnabled: {
                            false: "Disabled",
                            title: "Control Plane External IP Address",
                            true: "Enabled"
                        },
                        serviceSubnet: "Services IP Address Range",
                        url: "Public Endpoint"
                    },
                    subnetId: "Subnet",
                    vpcId: "VPC Network"
                },
                title: "Network"
            }));

    return (
        <InfoCard title={localization.title()}>
            <InfoCard columns={true}>
                <EntitiesInfoItem
                    entityIdsOrModels={vpcId}
                    entityTypeName={Contract.TypeNames.GcpComputeVpc}
                    title={localization.sections.vpcId()}/>
                <EntitiesInfoItem
                    entityIdsOrModels={subnetId}
                    entityTypeName={Contract.TypeNames.GcpComputeSubnet}
                    title={localization.sections.subnetId()}/>
                <InfoItem
                    title={localization.sections.network.publicIpAddressEnabled.title()}
                    value={
                        _.isNil(network.publicIpAddress)
                            ? localization.sections.network.publicIpAddressEnabled.false()
                            : localization.sections.network.publicIpAddressEnabled.true()}/>
                <InfoItem
                    key="networkAuthorizedSubnetDatas"
                    title={localization.sections.network.authorizedSubnetDatas.title()}
                    value={
                        _.isNil(network.authorizedSubnetDatas)
                            ? localization.sections.network.authorizedSubnetDatas.false()
                            : localization.sections.network.authorizedSubnetDatas.true()}/>
                <InfoItem
                    key="networkInboundExternalGcpRange"
                    title={localization.sections.network.inboundExternalGcpRange.title()}
                    value={
                        _.isNil(network.authorizedSubnetDatas)
                            ? undefined
                            : network.inboundExternalGcpRange
                                ? localization.sections.network.inboundExternalGcpRange.true()
                                : localization.sections.network.inboundExternalGcpRange.false()}/>
                <InfoItem
                    title={localization.sections.network.url()}
                    value={
                        _.isNil(url)
                            ? undefined
                            : url}/>
                <InfoItem
                    title={localization.sections.network.privateIpAddress()}
                    value={
                        _.isNil(network.privateIpAddress)
                            ? undefined
                            : network.privateIpAddress}/>
                <InfoItem
                    title={localization.sections.network.privateSubnet()}
                    value={
                        _.isNil(network.privateSubnet)
                            ? undefined
                            : network.privateSubnet}/>
                <InfoItem
                    title={localization.sections.network.aliasSubnet.title()}
                    value={
                        network.aliasSubnet
                            ? localization.sections.network.aliasSubnet.true()
                            : localization.sections.network.aliasSubnet.false()}/>
                <InfoItem
                    title={localization.sections.network.podSubnet()}
                    value={
                        _.isNil(network?.podSubnet)
                            ? undefined
                            : network.podSubnet}/>
                <InfoItem
                    title={localization.sections.network.serviceSubnet()}
                    value={
                        _.isNil(network?.serviceSubnet)
                            ? undefined
                            : network.serviceSubnet}/>
            </InfoCard>
        </InfoCard>);
}