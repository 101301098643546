import { useLocalization } from "@infrastructure";
import React from "react";
import { EntitySnapshotDefinition } from "../..";
import { Contract } from "../../../../../../../../../../../../../common";
import { PolicyTable } from "../../../components";

export function useAwsIamRoleDefinition(entitySnapshot: Contract.EntitySnapshot) {
    const roleSnapshot = entitySnapshot as Contract.AwsIamRoleSnapshot;
    const localization =
        useLocalization(
            "views.customer.entities.profile.snapshots.snapshot.hooks.useDefinition.hooks.useAwsIamRoleDefinition",
            () => ({
                description: "The following contains the complete set of Identity-Based Policies that were attached to this role at the specified time, after the specified changes were made."
            }));
    return new EntitySnapshotDefinition(
        localization.description(),
        [
            <PolicyTable
                entitySnapshot={entitySnapshot}
                key="policyTable"
                permissionBoundaryPolicySnapshotId={roleSnapshot.permissionBoundaryPolicySnapshotId}
                policySnapshotIds={roleSnapshot.policySnapshotIds}/>
        ]);
}