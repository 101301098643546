
import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function PrivilegeEscalationIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_59698_4734)">
                <path d="M9.90014 23.8C7.70014 23.8 6.50014 23.8 5.30014 22.9C4.10014 22 3.60014 20.7001 2.70014 18.1L0.700141 12.5C0.100141 10.9 -0.0998587 10.2 0.000141261 9.50005C0.200141 8.70005 0.800141 8.10005 1.60014 7.80005C2.30014 7.60005 3.00014 7.90005 4.40014 8.50005C5.30014 8.90005 5.80014 9.10005 6.00014 9.10005C6.30014 9.10005 6.60014 9.00005 6.80014 8.70005C7.00014 8.50005 7.30014 7.90005 7.70014 7.00005L8.80014 4.50005C9.90014 2.10005 10.5001 0.800049 11.9001 0.800049C13.3001 0.800049 13.9001 2.10005 14.9001 4.50005L16.0001 7.00005C16.4001 7.90005 16.7001 8.50005 16.9001 8.70005C17.1001 8.90005 17.4001 9.00005 17.7001 9.10005C17.9001 9.10005 18.5001 8.90005 19.3001 8.50005C20.7001 7.90005 21.4001 7.60005 22.1001 7.80005C22.9001 8.00005 23.5001 8.70005 23.7001 9.50005C23.9001 10.2 23.7001 10.9 23.1001 12.4L21.1001 18.1C20.2001 20.7001 19.7001 22 18.5001 22.9C17.6001 23.6 16.6001 23.7 15.1001 23.8C14.6001 23.8 14.1001 23.4 14.1001 22.8C14.1001 22.2 14.5001 21.8 15.1001 21.8C16.4001 21.8 16.9001 21.6 17.4001 21.3C18.1001 20.8 18.5001 19.7001 19.3001 17.5L21.3001 11.9C21.6001 11.1 21.9001 10.3 21.9001 10.1C21.9001 10.1 21.9001 9.90005 21.7001 9.90005C21.6001 9.90005 20.9001 10.2 20.2001 10.5C19.0001 11 18.4001 11.3 17.7001 11.3C16.9001 11.3 16.2001 11 15.6001 10.4C15.1001 9.90005 14.8001 9.20005 14.2001 8.10005L13.1001 5.60005C12.6001 4.60005 12.2001 3.50005 11.9001 3.20005C11.6001 3.60005 11.1001 4.60005 10.7001 5.60005L9.60014 8.10005C9.10014 9.30005 8.80014 10 8.20014 10.4C7.60014 10.9 6.80014 11.3 6.10014 11.3C5.40014 11.3 4.70014 11 3.60014 10.5C2.90014 10.2 2.20014 9.90005 2.00014 9.90005C2.00014 9.90005 2.00014 9.90005 2.00014 10.1C2.00014 10.3 2.30014 11.1 2.60014 11.9L4.60014 17.5C5.40014 19.7001 5.80014 20.8 6.50014 21.3C7.10014 21.7 8.00014 21.8 9.90014 21.8C10.5001 21.8 10.9001 22.2 10.9001 22.8C10.9001 23.4 10.5001 23.8 9.90014 23.8Z"/>
                <path d="M9.24445 16.2008C8.91852 15.8205 8.91852 15.2324 9.24445 14.852L11.2493 12.513C11.8357 11.829 12.894 11.829 13.4803 12.513L15.4853 14.8521C15.811 15.2324 15.811 15.8204 15.4851 16.2009C15.1184 16.6286 14.4859 16.6286 14.1191 16.2008L13.292 15.2368L13.2924 23.0048C13.2924 23.5 12.951 23.9337 12.4766 23.9931L12.3648 24C11.8332 24 11.4373 23.5381 11.4373 23.0048L11.4372 15.2368L10.6105 16.2009C10.272 16.5957 9.70694 16.6261 9.33356 16.2919L9.24445 16.2008Z"/>
            </g>
            <defs>
                <clipPath id="clip0_59698_4734">
                    <rect
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}