import { useActions, useInputValidation, useLocalization } from "@infrastructure";
import { FormHelperText, Stack } from "@mui/material";
import _ from "lodash";
import React, { Ref, useEffect, useMemo, useState } from "react";
import { automaticCustomEntityAttributeDefinitionTemplateModelStore, ConfigurationController, Contract, ResourceTagPatternItem, ResourceTagPatternsSelector, TextInput } from "../../../../../../../../../../common";
import { resourceTagsAreEqual } from "../../../../utilities";
import { useCustomEntityAttributesContext } from "../../../CustomEntityAttributeDefinitions";
import { AddCustomEntityAttributeActions, ConfigurationValidation } from "../AddOrEdit";

type AutomaticCustomEntityAttributeResourceTagPatternsDefinitionTemplateProps = {
    actionsRef: Ref<AddCustomEntityAttributeActions>;
    disabled: boolean;
    onValidChange: (validation: ConfigurationValidation) => void;
    readOnly: boolean;
};

export function AutomaticCustomEntityAttributeResourceTagPatternsDefinitionTemplate({ actionsRef, disabled, onValidChange, readOnly }: AutomaticCustomEntityAttributeResourceTagPatternsDefinitionTemplateProps) {
    const configuration = useCustomEntityAttributesContext().item?.configuration as Contract.AutomaticCustomEntityAttributeDefinitionTemplateConfiguration | undefined;
    const [name, setName] =
        useState(
            !_.isNil(configuration) &&
            configuration.type == Contract.AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType.ResourceTagPattern
                ? configuration.name!
                : "");
    const [resourceTagPatternItems, setResourceTagPatternItems] =
        useState<Contract.ResourceTagPattern[]>(
            !_.isNil(configuration) &&
            configuration.type == Contract.AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType.ResourceTagPattern
                ? configuration.resourceTagPatterns
                : []);
    const [resourceTagPatternItemsValid, setResourceTagPatternItemsValid] = useState(false);

    useActions<AddCustomEntityAttributeActions>(
        actionsRef,
        {
            reset() {
                setName("");
                setResourceTagPatternItems([]);
                nameValidationController.clear();
                setResourceTagPatternItemsValid(false);
            },
            async upsertCustomEntityAttribute(colorIndex, scopeId) {
                const { automaticCustomEntityAttributeDefinitionTemplateModel } =
                    await ConfigurationController.upsertAutomaticCustomEntityAttributeDefinitionTemplate(
                        new Contract.ConfigurationControllerUpsertAutomaticCustomEntityAttributeResourceTagPatternsDefinitionTemplateRequest(
                            colorIndex,
                            configuration?.id,
                            scopeId,
                            name,
                            resourceTagPatternItems));

                await automaticCustomEntityAttributeDefinitionTemplateModelStore.notify(automaticCustomEntityAttributeDefinitionTemplateModel);
            }
        });
    const localization =
        useLocalization(
            "views.customer.configuration.entities.customEntityAttributeDefinitions.addOrEdit.automaticCustomEntityAttributeResourceTagPatternsDefinitionTemplate",
            () => ({
                name: {
                    error: {
                        required: "Name cannot be empty"
                    },
                    helpText: "To create dynamic label names, use the {key} and/or {value} placeholders in the name. Otherwise, enter a static name to use a single label name.",
                    title: "Name"
                }
            }));

    const [nameValidationController, nameValidationMessage] =
        useInputValidation(
            () => {
                const validationName = name.trim();
                if (_.isEmpty(validationName)) {
                    return localization.name.error.required();
                }

                return undefined;
            },
            [name]);

    useEffect(
        () =>
            onValidChange(
                {
                    changed:
                        !_.isNil(configuration) && (
                            name != configuration.name ||
                            !resourceTagsAreEqual(configuration.resourceTagPatterns, resourceTagPatternItems)),
                    valid: nameValidationController.isValid() && resourceTagPatternItemsValid
                }),
        [name, resourceTagPatternItems]);

    const initialItems =
        useMemo(
            () =>
                !_.isNil(configuration) &&
                configuration.type == Contract.AutomaticCustomEntityAttributeDefinitionTemplateConfigurationType.ResourceTagPattern
                    ? _.map(
                        configuration?.resourceTagPatterns,
                        resourceTagPattern => ResourceTagPatternItem.create(resourceTagPattern))
                    : [],
            [configuration]);
    return (
        <Stack
            spacing={2}
            sx={{ height: "100%" }}>
            <TextInput
                disabled={disabled}
                info={localization.name.helpText()}
                placeholder={localization.name.title()}
                readOnly={readOnly}
                text={name}
                onChange={setName}/>
            {!_.isNil(nameValidationMessage) && (
                <FormHelperText error={true}>{nameValidationMessage}</FormHelperText>)}
            <ResourceTagPatternsSelector
                initialItems={initialItems}
                readOnly={readOnly}
                onItemsChanged={items => setResourceTagPatternItems(items)}
                onValidChanged={valid => setResourceTagPatternItemsValid(valid)}/>
        </Stack>);
}