﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";

export function useKubernetesClusterConnectorAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useKubernetesClusterConnectorAuditEventDefinition",
            () => ({
                name: "Name",
                propertyChanges: "Updated properties"
            }));

    const auditEvent = context.auditEventModel.auditEvent as Contract.KubernetesClusterConnectorAuditEvent;
    return new AuditEventDefinition(
        _.filter([
            new AuditEventDefinitionDetailItem(
                localization.name(),
                auditEvent.name),
            auditEvent.typeName === Contract.TypeNames.KubernetesClusterConnectorUpdateAuditEvent &&
            (context.auditEventModel.auditEvent as Contract.KubernetesClusterConnectorUpdateAuditEvent).nameChanged
                ? new AuditEventDefinitionDetailItem(
                    localization.propertyChanges(),
                    localization.name())
                : undefined!
        ]));
}