import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, EntitiesCell, entityModelStore, InfoCard, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";

type RoutesInfoCardProps = {
    routeIds: string[];
};

export function RoutesInfoCard({ routeIds }: RoutesInfoCardProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const routeModels = entityModelStore.useGet(routeIds);
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpComputeVpcDefinition.routesInfoCard",
            () => ({
                columns: {
                    description: "Description",
                    destinationSubnet: "Destination IP ranges",
                    name: "Name",
                    nextHop: "Next hop",
                    priority: "Priority"
                },
                empty: "No {{translatedEntityTypeName}}",
                title: "Routes"
            }));

    return (
        <InfoCard title={localization.title()}>
            <DataTable
                emptyMessageOptions={{
                    emptyMessageText:
                        new EmptyMessageText(
                            localization.empty({
                                translatedEntityTypeName:
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.GcpComputeRoute,
                                        { count: 0 })
                            }))
                }}
                fetchItems={
                    () =>
                        _.orderBy(
                            routeModels,
                            item => (item.entity as Contract.GcpComputeRoute).priority)}
                getItemId={(item: Contract.GcpComputeRouteModel) => item.id}
                sortOptions={{ enabled: false }}
                variant="card">
                <DataTableColumn
                    id={RouteColumnId.Name}
                    itemProperty={(item: Contract.GcpComputeRouteModel) => (item.entity as Contract.GcpComputeRoute).name}
                    title={localization.columns.name()}/>
                <DataTableColumn
                    id={RouteColumnId.Description}
                    itemProperty={(item: Contract.GcpComputeRouteModel) => (item.entity as Contract.GcpComputeRoute).description}
                    title={localization.columns.description()}/>
                <DataTableColumn
                    id={RouteColumnId.DestinationSubnet}
                    itemProperty={(item: Contract.GcpComputeRouteModel) => (item.entity as Contract.GcpComputeRoute).destinationSubnet}
                    title={localization.columns.destinationSubnet()}/>
                <DataTableColumn
                    id={RouteColumnId.Priority}
                    itemProperty={(item: Contract.GcpComputeRouteModel) => (item.entity as Contract.GcpComputeRoute).priority}
                    title={localization.columns.priority()}/>
                <DataTableColumn
                    id={RouteColumnId.NextHop}
                    key={RouteColumnId.NextHop}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.GcpComputeRouteModel>) => {
                            const route = item.entity as Contract.GcpComputeRoute;
                            return !_.isNil(item.nextHopResourceId)
                                ? <EntitiesCell
                                    entityIdsOrModels={item.nextHopResourceId}
                                    entityTypeName={Contract.TypeNames.GcpResource}
                                    entityVariant="iconTextTenant"/>
                                : <Typography noWrap={true}>{
                                    _.find([
                                        route.nextHop.url,
                                        route.nextHop.ipAddress,
                                        route.nextHop.peeringRawShortId])}
                                </Typography>;
                        }}
                    title={localization.columns.nextHop()}/>;
            </DataTable>
        </InfoCard>);
}

enum RouteColumnId {
    Description = "description",
    DestinationSubnet = "destinationSubnet",
    Name = "name",
    NextHop = "nextHop",
    Priority = "priority"
}