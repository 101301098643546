import { DataTableColumn, DataTableColumnRenderProps, EnumValuesFilter, optionalTableCell, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAzureCommonResourceGroupResourceDefinition } from ".";
import { EntityTableDefinition } from "../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAzureNetworkPrivateEndpointConnectionStatusTranslator } from "../../../../../../hooks/useAzureNetworkPrivateEndpointConnectionStatusTranslator";
import { DefinitionData } from "../../../../Table";

export function useAzureNetworkPrivateEndpointDefinition(definitionData: DefinitionData) {
    const commonResourceGroupResourceDefinition = useAzureCommonResourceGroupResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const privateEndpointConnectionStatusTranslator = useAzureNetworkPrivateEndpointConnectionStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureNetworkPrivateEndpointDefinition",
            () => ({
                columns: {
                    attachedResourceId: "Attached To",
                    connectionDescription: "Connection Description",
                    connectionStatus: "Connection State"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            commonResourceGroupResourceDefinition.columns.tenantColumn,
            commonResourceGroupResourceDefinition.columns.creationTimeColumn,
            commonResourceGroupResourceDefinition.columns.creatorIdentityCsvColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureNetworkPrivateEndpointModel>(
                        Contract.TypeNames.AzureResourceGroupResource,
                        item => [item.attachedResourceIdReference],
                        localization.columns.attachedResourceId())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureNetworkPrivateEndpointAttachedResource)}
                                placeholder={localization.columns.attachedResourceId()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureNetworkPrivateEndpointAttachedResource}
                key={Contract.EntityModelProperty.AzureNetworkPrivateEndpointAttachedResource}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureNetworkPrivateEndpointModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.attachedResourceIdReference}
                            entityTypeName={Contract.TypeNames.AzureResourceGroupResource}
                            entityVariant="iconText"/>}
                title={localization.columns.attachedResourceId()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureNetworkPrivateEndpointModel) => ({
                            [localization.columns.connectionStatus()]: privateEndpointConnectionStatusTranslator((item.entity as Contract.AzureNetworkPrivateEndpoint).connectionStatus)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AzureNetworkPrivateEndpointConnectionStatus}
                                enumTypeTranslator={privateEndpointConnectionStatusTranslator}
                                placeholder={localization.columns.connectionStatus()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureNetworkPrivateEndpointConnectionStatus}
                itemProperty={(item: Contract.AzureNetworkPrivateEndpointModel) => privateEndpointConnectionStatusTranslator((item.entity as Contract.AzureNetworkPrivateEndpoint).connectionStatus)}
                key={Contract.EntityModelProperty.AzureNetworkPrivateEndpointConnectionStatus}
                title={localization.columns.connectionStatus()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureNetworkPrivateEndpointModel) => ({
                            [localization.columns.connectionDescription()]: (item.entity as Contract.AzureNetworkPrivateEndpoint).connectionDescription
                        })
                }}
                id={Contract.EntityModelProperty.AzureNetworkPrivateEndpointDescription}
                key={Contract.EntityModelProperty.AzureNetworkPrivateEndpointDescription}
                render={optionalTableCell<Contract.AzureNetworkPrivateEndpointModel>(item =>
                    _.isEmpty((item.entity as Contract.AzureNetworkPrivateEndpoint).connectionDescription)
                        ? undefined
                        : (item.entity as Contract.AzureNetworkPrivateEndpoint).connectionDescription)}
                title={localization.columns.connectionDescription()}/>,
            commonResourceGroupResourceDefinition.columns.regionColumn,
            commonResourceGroupResourceDefinition.columns.resourceGroupColumn,
            commonResourceGroupResourceDefinition.columns.tagsColumn,
            commonResourceGroupResourceDefinition.columns.attributesColumn,
            commonResourceGroupResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureNetworkPrivateEndpointRequest(
                new Contract.EntityControllerGetEntityModelPageAzureNetworkPrivateEndpointRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureResourceGroupResourceResourceGroup]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureNetworkPrivateEndpointAttachedResource]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureNetworkPrivateEndpointConnectionStatus])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}