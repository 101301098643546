import { DataTableColumnRenderProps } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract } from "../../../../../../../../common";
import { usePermissionRequestAuditEventTypeNameTranslator } from "../../hooks";
import { ActivationFailureData } from "./components";

export function TypeNameCell({ item }: DataTableColumnRenderProps<Contract.PermissionRequestAuditEventModel>) {
    const permissionRequestAuditEventTypeNameTranslator = usePermissionRequestAuditEventTypeNameTranslator();
    const permissionRequestAuditEventModelActivationFailureData =
        item.auditEvent.typeName === Contract.TypeNames.PermissionRequestActivationFailureAuditEvent ||
        item.auditEvent.typeName === Contract.TypeNames.PermissionRequestExpirationAuditEvent ||
        item.auditEvent.typeName === Contract.TypeNames.PermissionRequestRevocationAuditEvent
            ? (item as Contract.PermissionRequestActivationFailureAuditEventModel | Contract.PermissionRequestExpirationAuditEventModel | Contract.PermissionRequestRevocationAuditEventModel).activationFailureData
            : undefined;
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Typography noWrap={true}>
                {permissionRequestAuditEventTypeNameTranslator(item.auditEvent.typeName)}
            </Typography>
            {!_.isNil(permissionRequestAuditEventModelActivationFailureData) &&
                <ActivationFailureData permissionRequestAuditEventModelActivationFailureData={permissionRequestAuditEventModelActivationFailureData}/>}
        </Stack>);
}