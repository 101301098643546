import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../../utilities";
import { useGetAzureManagedIdentityManagedIdentityRiskContext } from "../../contexts";
import { useGetResolutionChangeSteps } from "./hooks";

export function useAzureManagedIdentityManagedIdentityInactiveRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureManagedIdentityManagedIdentityInactiveRisk;
    const managedIdentityModel = entityModelStore.useGet(risk.entityId) as Contract.AzureManagedIdentityManagedIdentityModel;
    const servicePrincipalModel = entityModelStore.useGet((managedIdentityModel.entity as Contract.AzureManagedIdentityManagedIdentity).servicePrincipalId) as Contract.AadDirectoryServicePrincipalModel;

    const getAzureManagedIdentityManagedIdentityRiskContext = useGetAzureManagedIdentityManagedIdentityRiskContext();
    const getResolutionChangeSteps = useGetResolutionChangeSteps();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureManagedIdentityManagedIdentityInactiveRiskDefinition",
            () => ({
                description: "{{managedIdentity}} is inactive and should be deleted",
                sections: {
                    context: {
                        [Contract.TypeNames.AzureManagedIdentityManagedIdentityInactiveRiskModelInfoType]: {
                            [Contract.AzureManagedIdentityManagedIdentityInactiveRiskModelInfoType.SignInNever]: "{{servicePrincipal}} was not active since it was created",
                            [Contract.AzureManagedIdentityManagedIdentityInactiveRiskModelInfoType.SignInTime]: "{{servicePrincipal}} was last active {{servicePrincipalSignInTime | TimeFormatter.humanizePastDuration}}"
                        }
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            managedIdentity:
                <Entity
                    entityIdOrModel={managedIdentityModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => getResolutionChangeSteps(managedIdentityModel, risk),
        riskModel,
        () => {
            const managedIdentityContextItems = getAzureManagedIdentityManagedIdentityRiskContext(managedIdentityModel);
            return [
                managedIdentityContextItems.generalInformation,
                managedIdentityContextItems.originatorResources,
                new RiskDefinitionContextItem(localization.sections.context[Contract.TypeNames.AzureManagedIdentityManagedIdentityInactiveRiskModelInfoType][(riskModel as Contract.AzureManagedIdentityManagedIdentityInactiveRiskModel).infoType](
                    {
                        servicePrincipal:
                            <Entity
                                entityIdOrModel={servicePrincipalModel}
                                entityTypeNameTranslatorOptions={{ variant: "title" }}
                                variant="typeText"/>,
                        servicePrincipalSignInTime: risk.servicePrincipalSignInTime
                    })),
                managedIdentityContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        }
    )
    ;
}