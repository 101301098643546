import { ApiError, Link, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { ConfigurationController, Contract, CustomerConsoleAppUrlHelper, RadioGroup, RadioGroupItem, scopeSystemEntityModelStore, useTheme } from "../../../../../../../../../../../../../../../../common";
import { useJiraContext } from "../../../../Jira";
import { SyncOptionType, useAddOrEditProjectContext, useSetAddOrEditProjectContext } from "../../AddOrEditProject";
import { CustomView } from "./components";

export function SyncIssueStatusesItem() {
    const { instanceModel } = useJiraContext();
    const { issueTypeNameToRiskStatusToJiraStatusErrorMap, issueTypeNameToRiskStatusToJiraStatusMap, issueTypeNameToStatusesMap, rawId: jiraProjectRawId, severityToPriorityMap, syncIssuePrioritiesItemOptionType, syncIssueStatusesItemOptionType } = useAddOrEditProjectContext();
    const setAddOrEditProjectContext = useSetAddOrEditProjectContext();
    const { executing, setValid, useNextEffect } = useOrderedWizardContext();

    const [syncOptionType, setSyncOptionType] = useState(syncIssueStatusesItemOptionType);

    const [issueTypeToSyncOptionTypeMap, setIssueTypeToSyncOptionTypeMap] =
        useState<_.Dictionary<SyncOptionType>>(
            _.mapValues(
                issueTypeNameToRiskStatusToJiraStatusMap,
                (statuses, issueTypeName) =>
                    _.some(_.get(issueTypeNameToRiskStatusToJiraStatusMap, issueTypeName))
                        ? SyncOptionType.Custom
                        : SyncOptionType.None));

    useEffect(
        () => {
            setValid(
                syncOptionType === SyncOptionType.None
                    ? true
                    : _.every(issueTypeNameToRiskStatusToJiraStatusMap,
                        ((riskStatusToJiraStatus, issueTypeName) => {
                            if (issueTypeToSyncOptionTypeMap[issueTypeName] === SyncOptionType.None) {
                                return true;
                            }
                            return _.every(riskStatuses, riskStatus => !_.isNil(riskStatusToJiraStatus[riskStatus]));
                        })) &&
                    (_.isEmpty(issueTypeNameToRiskStatusToJiraStatusErrorMap) ||
                        _(issueTypeNameToRiskStatusToJiraStatusErrorMap).
                            keys().
                            every(errorIssueTypeName => _.isNil(issueTypeNameToStatusesMap[errorIssueTypeName]))));
        },
        [issueTypeNameToRiskStatusToJiraStatusMap, issueTypeToSyncOptionTypeMap, syncOptionType, issueTypeNameToRiskStatusToJiraStatusErrorMap]);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useTicketingItems.jira.addOrEditProject.syncIssueStatusesItem",
            () => ({
                actions: {
                    save: {
                        error: {
                            [Contract.ConfigurationControllerUpsertJiraProjectError.InstanceError]: "Instance has an error",
                            [Contract.ConfigurationControllerUpsertJiraProjectError.InvalidConfiguration]: "Invalid Configurations",
                            [Contract.ConfigurationControllerUpsertJiraProjectError.InvalidId]: "Project doesn't exist",
                            [Contract.ConfigurationControllerUpsertJiraProjectError.MissingPermissions]: "Missing permissions",
                            [Contract.ConfigurationControllerUpsertJiraProjectError.MissingProductPermissions]: "Missing product permissions",
                            [Contract.ConfigurationControllerUpsertJiraProjectError.UnsupportedProduct]: "Unsupported project product",
                            [Contract.ConfigurationControllerUpsertJiraProjectError.UnsupportedProject]: "Unsupported project type",
                            default: "Something went wrong, please try again or contact support"
                        }
                    }
                },
                description: "This allows you to align the finding status with the corresponding Jira issue status.",
                learnMore: "Learn more",
                options: {
                    custom: "Sync issues using custom mapping",
                    none: "Do not sync"
                },
                title: "Sync Issue Statuses"
            }));

    useNextEffect(
        async () => {
            try {
                const { scopeSystemEntityModel } =
                    await ConfigurationController.upsertJiraProject(
                        new Contract.ConfigurationControllerUpsertJiraProjectRequest(
                            instanceModel!.configuration.id,
                            syncIssueStatusesItemOptionType === SyncOptionType.None
                                ? undefined
                                : _(issueTypeNameToRiskStatusToJiraStatusMap).
                                    omitBy((value, issueTypeName) => _.isNil(issueTypeNameToStatusesMap[issueTypeName])).
                                    mapValues((riskStatusToJiraStatus, issueTypeName) => {
                                        if (issueTypeToSyncOptionTypeMap[issueTypeName] === SyncOptionType.Custom) {
                                            return _.mapValues(
                                                riskStatusToJiraStatus,
                                                jiraStatus => jiraStatus!.rawId);
                                        }
                                        return {};
                                    }).
                                    value(),
                            jiraProjectRawId!,
                            syncIssuePrioritiesItemOptionType === SyncOptionType.None
                                ? undefined
                                : _.mapValues(
                                    severityToPriorityMap,
                                    priority => priority!.rawId)
                        ));

                await scopeSystemEntityModelStore.notify(scopeSystemEntityModel);
            } catch (error) {
                return error instanceof ApiError && error.statusCode === 400
                    ? localization.actions.save.error[error.error as Contract.ConfigurationControllerUpsertJiraProjectError]()
                    : localization.actions.save.error.default();
            }
        },
        [issueTypeNameToRiskStatusToJiraStatusMap, instanceModel, syncIssuePrioritiesItemOptionType, syncIssueStatusesItemOptionType, jiraProjectRawId, issueTypeToSyncOptionTypeMap]);

    const theme = useTheme();
    const radioGroupItems =
        useMemo(
            (): RadioGroupItem<SyncOptionType>[] => [
                {
                    label: localization.options.none(),
                    value: SyncOptionType.None
                },
                {
                    children:
                        <CustomView
                            issueTypeToSyncOptionTypeMap={issueTypeToSyncOptionTypeMap}
                            onIssueTypeSyncOptionChange={
                                (selectedIssueType, syncOption) =>
                                    setIssueTypeToSyncOptionTypeMap(
                                        currentState =>
                                            ({
                                                ...currentState,
                                                [selectedIssueType]: syncOption
                                            }))}/>,
                    label: localization.options.custom(),
                    value: SyncOptionType.Custom
                }
            ],
            [issueTypeToSyncOptionTypeMap]);

    useEffect(
        () => {
            setAddOrEditProjectContext(
                context => ({
                    ...context,
                    syncIssueStatusesItemOptionType: syncOptionType
                }));
        },
        [syncOptionType]);

    return (
        <Box sx={{ maxWidth: theme.spacing(82) }}>
            <Stack spacing={2}>
                <Typography variant="h6">
                    {localization.title()}
                </Typography>
                <Stack spacing={1}>
                    <Typography>{localization.description()}</Typography>
                    <Link
                        urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsTicketingIntegrationAddJiraProject()}
                        variant="external">
                        {localization.learnMore()}
                    </Link>
                </Stack>
                <RadioGroup
                    disabled={executing}
                    items={radioGroupItems}
                    selectedValue={syncOptionType}
                    onChange={value => setSyncOptionType(value as SyncOptionType)}/>
            </Stack>
        </Box>);
}

export const riskStatuses = [Contract.RiskStatus.Open, Contract.RiskStatus.Ignored, Contract.RiskStatus.Closed];