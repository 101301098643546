import { ActionButton, CloseIcon, Dropdown, DropdownIcon, Loading, useChangeEffect, useLocalization } from "@infrastructure";
import { Button, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { useTheme } from "../../../../../..";
import { UdmObject } from "../UdmObject";
import { Popup } from "./components";

type UdmObjectTypeNameSelectorProps = {
    disabled?: boolean;
    level: number;
    onSelectedObjectTypeNameChanged: (selectedObjectTypeName?: string) => void;
    parentObjectTypeName?: string;
    selectedObjectTypeName?: string;
};

export function UdmObjectTypeNameSelector({ disabled, level, onSelectedObjectTypeNameChanged, parentObjectTypeName, selectedObjectTypeName: initialSelectedObjectTypeName }: UdmObjectTypeNameSelectorProps) {
    const [selectedObjectTypeName, setSelectedObjectTypeName] = useState(initialSelectedObjectTypeName);
    const [open, setOpen] = useState(level > 0 && _.isEmpty(initialSelectedObjectTypeName));

    useChangeEffect(
        () => {
            onSelectedObjectTypeNameChanged(selectedObjectTypeName);
            setOpen(false);
        },
        [selectedObjectTypeName]);

    const localization =
        useLocalization(
            "common.udmObjectTable.udmQueryBuilder.udmObjectTypeNameSelector",
            () => ({
                add: "Resource",
                filter: {
                    group: "Add sub filter",
                    single: "Add filter"
                },
                find: "Find",
                title: "Explore"
            }));

    const theme = useTheme();
    return (
        <Dropdown
            disabled={disabled}
            justify="left"
            open={open}
            popoverElement={
                () =>
                    <Stack
                        alignItems="center"
                        sx={{
                            height: theme.spacing(60),
                            minWidth: 500
                        }}>
                        <Loading>
                            <Popup
                                relatedObjectTypeName={parentObjectTypeName}
                                selectedObjectTypeName={selectedObjectTypeName}
                                onSelectedObjectTypeNameChanged={setSelectedObjectTypeName}/>
                        </Loading>
                    </Stack>}
            popoverElementContainerSx={{
                height: theme.spacing(60),
                minWidth: "500px",
                overflow: "hidden",
                padding: 0
            }}
            sx={{ color: theme.palette.text.primary }}
            onOpen={() => setOpen(true)}>
            {_.isEmpty(selectedObjectTypeName)
                ? <Button
                    sx={{
                        "&:hover": {
                            border: theme.border.hoverFocus
                        },
                        border: theme.border.primary,
                        minHeight: theme.spacing(3.5),
                        padding: theme.spacing(0, 0.5, 0, 1)
                    }}
                    variant="outlined">
                    <Stack
                        direction="row"
                        spacing={1}>
                        <Typography sx={{ color: theme.palette.text.secondary }}>
                            {localization.add()}
                        </Typography>
                        <DropdownIcon sx={{ fontSize: "18px" }}/>
                    </Stack>
                </Button>
                : <Button
                    sx={{
                        "&:hover": {
                            background:
                                level > 0
                                    ? theme.palette.udm(level as (0 | 1 | 2 | 3 | 4))
                                    : undefined,
                            border: theme.border.hoverFocus
                        },
                        background:
                            level > 0
                                ? theme.palette.udm(level as (0 | 1 | 2 | 3 | 4))
                                : undefined,
                        border:
                            level > 0
                                ? `1px solid ${theme.palette.udm(level as (0 | 1 | 2 | 3 | 4))}`
                                : theme.border.primary,
                        minHeight: theme.spacing(3.5),
                        padding: theme.spacing(0, 0.5, 0, 1),
                        width: "fit-content"
                    }}
                    variant="outlined">
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={0.5}>
                        {selectedObjectTypeName &&
                            <UdmObject objectTypeName={selectedObjectTypeName}/>}
                        <ActionButton
                            size="medium"
                            sx={{
                                height: "20px",
                                width: "20px"
                            }}
                            variant="icon"
                            onClick={
                                event => {
                                    setSelectedObjectTypeName(undefined);
                                    event?.stopPropagation();
                                }}>
                            <CloseIcon sx={{ fontSize: "14px" }}/>
                        </ActionButton>
                    </Stack>
                </Button>}
        </Dropdown>);
}