import { Link, TextViewer, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { CustomerConsoleAppUrlHelper } from "../../../../../../../../../../../../../../../../../../../common";
import { useAddOrEditContext } from "../../../../../AddOrEdit";

export function ScanContainerImageStageItem() {
    const { apiRootUrl } = useAddOrEditContext();

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodePipelineItems.codePipeline.addOrEdit.hooks.useDefinition.hooks.useGitLabPipeline.scanContainerImageStageItem",
            () => ({
                description: {
                    link: ".gitlab-ci.yml",
                    text: "Add the following component and stage to your {{gitLabCIYaml}} file:\n"
                },
                note: {
                    link: "product documentation",
                    text: "Once the scan is triggered, you should start seeing scan results under **Workload Protection -> CI/CD Scans**. Please refer to the {{documentationLink}} for more details"
                },
                pipeline: {
                    name: "<place your image name here>"
                }
            }));

    return (
        <Stack spacing={4}>
            <Typography>
                {localization.description.text({
                    gitLabCIYaml:
                        <Link
                            sx={{ textDecorationLine: "underline" }}
                            urlOrGetUrl="https://docs.gitlab.com/ee/ci/yaml/"
                            variant="external">
                            {localization.description.link()}
                        </Link>
                })}
            </Typography>
            <TextViewer
                copyToClipboard={true}
                format="yaml"
                text={
                    _.join(
                        [
                            "include:",
                            "  - component: $CI_SERVER_FQDN/tenb/cloud-security/components/container-image-scan@1",
                            "    inputs:",
                            "      api-token: $TENABLE_API_TOKEN",
                            `      api-url: ${apiRootUrl}`,
                            `      name: ${localization.pipeline.name()}`,
                            "      stage: tenable-cloud-security",
                            "",
                            "stages:",
                            "  - tenable-cloud-security"
                        ],
                        "\n")}/>
            <Typography>
                {localization.note.text({
                    documentationLink:
                        <Link
                            sx={{ textDecorationLine: "underline" }}
                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsContainerImageCICDScanningAddGitlabPipelineRelativeUrl()}
                            variant="external">
                            {localization.note.link()}
                        </Link>
                })}
            </Typography>
        </Stack>);
}