import { InlineItems, InlineItemsVariant, Optional, useLocalization } from "@infrastructure";
import { SxProps } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Scope, ScopeTree, TenantVariant } from ".";
import { Contract, Tenant, tenantModelStore, useTheme } from "..";
import { TenantNameTranslatorOptions, useTenantNameTranslator } from "../hooks";

type InlineTenantsProps = {
    popoverVariant?: "list" | "tree";
    sx?: SxProps;
    tenantIds: string | string[];
    tenantNameTranslatorOptions?: TenantNameTranslatorOptions;
    tenantType?: Optional<Contract.TenantType>;
    tenantVariant?: TenantVariant;
    title?: boolean;
    variant?: InlineItemsVariant | "itemAndTypeOrItemCountAndType";
};

export function InlineTenants({ popoverVariant = "list", sx, tenantIds, tenantNameTranslatorOptions, tenantType, tenantVariant = "text", title = false, variant = "itemOrItemCountAndType" }: InlineTenantsProps) {
    const tenantNameTranslator = useTenantNameTranslator();
    const localization =
        useLocalization(
            "common.inlineTenants",
            () => ({
                text: {
                    codeTenants: [
                        "1 Code Repository",
                        "{{count | NumberFormatter.humanize}} code repositories"
                    ],
                    registries: [
                        "1 Registry",
                        "{{count | NumberFormatter.humanize}} container registries"
                    ],
                    tenant: "account {{account}}",
                    tenants: [
                        "1 account",
                        "{{count | NumberFormatter.humanize}} accounts"
                    ]
                },
                title: {
                    codeTenants: [
                        "1 Code Repository",
                        "{{count | NumberFormatter.humanize}} Code Repositories"
                    ],
                    registries: [
                        "1 Registry",
                        "{{count | NumberFormatter.humanize}} Container Registries"
                    ],
                    tenant: "Account {{account}}",
                    tenants: [
                        "1 Account",
                        "{{count | NumberFormatter.humanize}} Accounts"
                    ]
                }
            }));

    const activeTenantModels = tenantModelStore.useGetActiveTenants();
    const orderedTenantIds =
        useMemo(
            () => {
                const activeTenantMap =
                    _.keyBy(
                        activeTenantModels,
                        activeTenantModel => activeTenantModel.configuration.id);
                return _(tenantIds).
                    concat().
                    orderBy(
                        [
                            tenantId => !_.isNil(activeTenantMap[tenantId]),
                            tenantId => tenantNameTranslator(tenantId, tenantNameTranslatorOptions)
                        ],
                        [
                            "desc",
                            "asc"
                        ]).
                    value();
            },
            [activeTenantModels, tenantIds]);

    const theme = useTheme();

    const getNamePluralizer =
        () => {
            const textOrTitleLocalization =
                localization[
                    title
                        ? "title"
                        : "text"];
            switch (tenantType) {
                case Contract.TenantType.Ci:
                    return textOrTitleLocalization.registries;
                case Contract.TenantType.Code:
                    return textOrTitleLocalization.codeTenants;
                default:
                    return textOrTitleLocalization.tenants;
            }
        };

    return (
        <InlineItems
            anchorOrigin={{
                horizontal: "center",
                vertical: "top"
            }}
            items={orderedTenantIds}
            itemsPopover={
                popoverVariant === "list"
                    ? undefined
                    : tenantIds =>
                        <ScopeTree scopeIds={tenantIds}>
                            {scopeNodeModelItem =>
                                <Scope
                                    colored={false}
                                    scopeId={scopeNodeModelItem.value.configuration.id}
                                    sx={{
                                        color: theme.palette.text.primary,
                                        maxWidth: theme.spacing(80),
                                        minWidth: theme.spacing(40)
                                    }}
                                    variant="treeNode"/>}
                        </ScopeTree>}
            namePluralizer={getNamePluralizer()}
            sx={sx}
            variant={
                variant === "itemAndTypeOrItemCountAndType"
                    ? "itemOrItemCountAndType"
                    : variant}>
            {(tenantId: string, inline: boolean) =>
                inline &&
                orderedTenantIds.length === 1 &&
                variant === "itemAndTypeOrItemCountAndType"
                    ? localization[
                        title
                            ? "title"
                            : "text"].
                        tenant({
                            account:
                                <Tenant
                                    sx={{ fontWeight: 600 }}
                                    tenantId={tenantId}
                                    tenantNameTranslatorOptions={tenantNameTranslatorOptions}
                                    variant={tenantVariant}/>
                        })
                    : <Tenant
                        tenantId={tenantId}
                        tenantNameTranslatorOptions={tenantNameTranslatorOptions}
                        variant={tenantVariant}/>
            }
        </InlineItems>);
}