import { TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoCard, Info } from "../../../../components";
import { usePingIdentityCommonDirectoryEntityInfoItemElements } from "./usePingIdentityCommonDirectoryEntityInfoItemElements";

export function usePingIdentityDirectoryUserDefinition(directoryEntityModel: Contract.PingIdentityDirectoryEntityModel, options?: DefinitionOptions) {
    const defaultResourceInfoItemElements = usePingIdentityCommonDirectoryEntityInfoItemElements(directoryEntityModel);
    const userModel = directoryEntityModel as Contract.PingIdentityDirectoryUserModel;
    const user = userModel.entity as Contract.PingIdentityDirectoryUser;
    const userProfile = userModel.entityProfile as Contract.PingIdentityDirectoryUserProfile;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.pingIdentity.usePingIdentityDirectoryUserDefinition",
            () => ({
                info: {
                    cards: {
                        groups: "Groups"
                    },
                    items: {
                        activityTime: {
                            empty: "Not seen",
                            title: "AWS Last Activity"
                        },
                        firstName: "First Name",
                        lastName: "Last Name",
                        mail: "Mail",
                        mfaEnabled: {
                            false: "Disabled",
                            title: "MFA",
                            true: "Enabled"
                        },
                        signInTime: {
                            empty: "Never",
                            title: "Sign-in Time"
                        },
                        status: "Status",
                        title: "Title",
                        updatedTime: {
                            empty: "Never",
                            title: "Update Time"
                        },
                        userName: "User Name"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="updatedTime"
                        title={localization.info.items.updatedTime.title()}
                        value={
                            _.isNil(user?.updateTime)
                                ? localization.info.items.updatedTime.empty()
                                : TimeFormatter.profileFormatDateTime(user.updateTime)}/>,
                    <InfoItem
                        key="mail"
                        location="all"
                        title={localization.info.items.mail()}
                        value={user.mail}/>,
                    <InfoItem
                        key="userName"
                        title={localization.info.items.userName()}
                        value={user.userName}/>,
                    <InfoItem
                        key="firstName"
                        location="all"
                        title={localization.info.items.firstName()}
                        value={user.firstName}/>,
                    <InfoItem
                        key="lastName"
                        location="all"
                        title={localization.info.items.lastName()}
                        value={user.lastName}/>,
                    <InfoItem
                        key="title"
                        location="all"
                        title={localization.info.items.title()}
                        value={user.title}/>,
                    <InfoItem
                        key="mfaEnabled"
                        location="all"
                        title={localization.info.items.mfaEnabled.title()}
                        value={
                            _.isNil(user.mfaEnabled)
                                ? undefined
                                : user.mfaEnabled
                                    ? localization.info.items.mfaEnabled.true()
                                    : localization.info.items.mfaEnabled.false()}/>,
                    <InfoItem
                        key="signInTime"
                        title={localization.info.items.signInTime.title()}
                        value={
                            _.isNil(user.signInTime)
                                ? localization.info.items.signInTime.empty()
                                : TimeFormatter.profileFormatDateTime(user.signInTime)}/>,
                    <InfoItem
                        key="activityTime"
                        location="all"
                        title={localization.info.items.activityTime.title()}
                        value={
                            _.isNil(userProfile?.activityTime)
                                ? localization.info.items.activityTime.empty()
                                : TimeFormatter.profileFormatDateTime(userProfile.activityTime)}/>
                ]}
                options={options?.infoOptions}>
                <EntitiesInfoCard
                    entityIdsOrModels={userModel.groupIds}
                    entityTypeName={Contract.TypeNames.PingIdentityDirectoryGroup}
                    title={localization.info.cards.groups()}/>
            </Info>,
        sensitive: false
    });
}