import { useExecuteOperation, useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import React, { ReactNode, useMemo } from "react";
import { Contract, InlineRisks, RiskController, useEntityTypeNameTranslator } from "../../../../../../../../../common";

export function useOpenRiskedEntityRisksStep(entityModel: Contract.EntityModel, riskId: string) {
    const [{ riskModels }] =
        useExecuteOperation(
            [useOpenRiskedEntityRisksStep, entityModel.id],
            () => RiskController.getRiskModels(new Contract.RiskControllerGetRiskModelsRequest(entityModel.risks.risks.openRiskedEntityRiskIds)));

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.useOpenRiskedEntityRisksStep",
            () => ({
                risks: [
                    "1 other finding",
                    "{{count | NumberFormatter.humanize}} other findings"
                ],
                severities: {
                    critical: "{{count | NumberFormatter.humanize}} with critical severity",
                    high: "{{count | NumberFormatter.humanize}} with high severity"
                },
                text: {
                    allCritical: "The {{translatedEntityTypeName}} has a total of {{risks}} with critical severity",
                    allHigh: "The {{translatedEntityTypeName}} has a total of {{risks}} with high severity",
                    noneCriticalAndHigh: "The {{translatedEntityTypeName}} has a total of {{risks}}, none with high severity",
                    someCriticalOrHigh: "The {{translatedEntityTypeName}} has a total of {{risks}}, {{severities}}"
                }
            }));
    return useMemo(
        () => {
            const otherRiskModels =
                _.filter(
                    riskModels,
                    riskModel => riskModel.id !== riskId);
            if (_.isEmpty(otherRiskModels)) {
                return undefined;
            }

            const otherCriticalSeverityRiskModels =
                _.filter(
                    otherRiskModels,
                    riskModel => riskModel.risk.severity === Contract.Severity.Critical);
            const otherHighSeverityRiskModels =
                _.filter(
                    otherRiskModels,
                    riskModel => riskModel.risk.severity === Contract.Severity.High);
            const textTranslator =
                _.isEmpty(otherCriticalSeverityRiskModels) && _.isEmpty(otherHighSeverityRiskModels)
                    ? localization.text.noneCriticalAndHigh
                    : otherCriticalSeverityRiskModels.length === otherRiskModels.length
                        ? localization.text.allCritical
                        : otherHighSeverityRiskModels.length === otherRiskModels.length
                            ? localization.text.allHigh
                            : localization.text.someCriticalOrHigh;
            return textTranslator({
                risks:
                    <InlineRisks
                        namePluralizer={localization.risks}
                        riskIdsOrModels={otherRiskModels}
                        variant="itemCountAndType"/>,
                severities:
                    localizeList(
                        _<ReactNode>([]).
                            concatIf(
                                !_.isEmpty(otherCriticalSeverityRiskModels),
                                <InlineRisks
                                    namePluralizer={count => localization.severities.critical({ count })}
                                    riskIdsOrModels={otherCriticalSeverityRiskModels}
                                    variant="itemCountAndType"/>).
                            concatIf(
                                !_.isEmpty(otherHighSeverityRiskModels),
                                <InlineRisks
                                    namePluralizer={count => localization.severities.high({ count })}
                                    riskIdsOrModels={otherHighSeverityRiskModels}
                                    variant="itemCountAndType"/>).
                            value()),
                translatedEntityTypeName:
                    entityTypeNameTranslator(
                        entityModel.entity.typeName,
                        {
                            includeServiceName: false,
                            variant: "text"
                        })
            });
        },
        [entityModel]);
}