import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { RiskPolicyItem, useRiskPolicyTranslator } from "../../../../../../../../../../../common";
import { TenantType } from "../../../../../../../../../../../common/controllers/types.generated";
import { useGetRiskPolicyItemBuiltInComplianceTypes } from "../../../../../../../../Compliance/hooks";
import { RiskPolicyInfoItems } from "../../../useRiskPolicyInfoItems";
import { useCommonCloudRiskPolicyInfoItems } from "./useCommonCloudRiskPolicyInfoItems";

export function useCloudRiskPolicyBuiltInInfoItems(scopeId: string, item: RiskPolicyItem, tenantIds?: string[]): RiskPolicyInfoItems {
    const commonCloudRiskPolicyInfoItems = useCommonCloudRiskPolicyInfoItems(item, scopeId);
    const getRiskPolicyItemBuiltInComplianceTypes = useGetRiskPolicyItemBuiltInComplianceTypes();

    const riskPolicyTranslator = useRiskPolicyTranslator();
    const localization =
        useLocalization(
            "views.customer.riskPolicies.profile.hooks.useRiskPolicyInfoItems.hooks.cloud.hooks.useCloudRiskPolicyBuiltInInfoItems",
            () => ({
                configuration: "Configuration",
                subtitle: "Policy"
            }));

    const { description, title } =
        useMemo(
            () => riskPolicyTranslator(item.riskPolicyConfigurationTypeNameOrId),
            [item.riskPolicyConfigurationTypeNameOrId]);

    return useMemo(
        () => ({
            additionalInfoCard: [
                {
                    items: [
                        commonCloudRiskPolicyInfoItems.integration,
                        commonCloudRiskPolicyInfoItems.getCompliances(getRiskPolicyItemBuiltInComplianceTypes(item))
                    ]
                },
                {
                    items: [
                        commonCloudRiskPolicyInfoItems.status,
                        commonCloudRiskPolicyInfoItems.getConfigurationDelay(),
                        ...commonCloudRiskPolicyInfoItems.getAdditionalInfoItems(),
                        commonCloudRiskPolicyInfoItems.configurationExcludedResources
                    ],
                    title: localization.configuration()
                }
            ],
            description: commonCloudRiskPolicyInfoItems.getDescription(description),
            general: [
                commonCloudRiskPolicyInfoItems.getTenantId(_.filter(item.tenantTypes, tenantType => tenantType != TenantType.Code)),
                commonCloudRiskPolicyInfoItems.category,
                commonCloudRiskPolicyInfoItems.getSeverity(item.riskPolicyTypeMetadata.severity),
                commonCloudRiskPolicyInfoItems.getRisks(tenantIds),
                commonCloudRiskPolicyInfoItems.analyzedEntityCount,
                commonCloudRiskPolicyInfoItems.failedEntities,
                commonCloudRiskPolicyInfoItems.excludedEntities
            ],
            topbar: {
                subtitle: localization.subtitle(),
                title
            }
        }),
        [scopeId, item]);
}