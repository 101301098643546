﻿import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { useOpContainerImageDefinition, useOpKubernetesResourceDefinition, useOpVirtualMachineDefinition } from ".";
import { Contract, TypeHelper } from "../../../../../../../../../../common";
import { DefinitionOptions } from "../../useDefinition";

export function useOpResourceDefinition(entityModel: Contract.EntityModel, options?: DefinitionOptions) {
    const useDefinition =
        useMemo(
            () => {
                switch (entityModel.entity.typeName) {
                    case Contract.TypeNames.OpContainerImage:
                        return useOpContainerImageDefinition;
                    case Contract.TypeNames.OpVirtualMachine:
                        return useOpVirtualMachineDefinition;
                    default:
                        if (TypeHelper.extendOrImplement(entityModel.entity.typeName, Contract.TypeNames.OpKubernetesResource)) {
                            return useOpKubernetesResourceDefinition;
                        }

                        throw new UnexpectedError(entityModel.entity.typeName);
                }
            },
            []);

    return useDefinition(entityModel as Contract.OpResourceModel, options);
}