import { ActionButton, DataTableClassName, ExpandIcon, ExpandPanel, Sx, TextViewerClassName } from "@infrastructure";
import { Box, Card, Stack, SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTheme } from "../../../../../../../../../../../common";
import { RiskDefinitionSection } from "../../../../../../../hooks/useDefinition/utilities";

type SectionProps = {
    riskId: string;
    section: RiskDefinitionSection;
    sx?: SxProps;
};

export function Section({ section, sx }: SectionProps) {
    const theme = useTheme();
    return (
        <Card
            sx={{
                boxShadow: "none",
                height: "100%",
                padding: theme.spacing(2, 0)
            }}>
            <ExpandPanel>
                {(expanded: boolean, setExpanded: () => void) =>
                    <Stack
                        sx={
                            Sx.combine(
                                {
                                    [`.${DataTableClassName}`]: {
                                        maxHeight:
                                            expanded
                                                ? undefined
                                                : theme.spacing(60)
                                    },
                                    [`.${TextViewerClassName}`]: {
                                        height:
                                            expanded
                                                ? theme.important("100%")
                                                : theme.spacing(60)
                                    },
                                    height:
                                        expanded
                                            ? "100%"
                                            : undefined,
                                    width: "100%"
                                },
                                sx)}>
                        <Stack sx={{ marginBottom: theme.spacing(1) }}>
                            <Stack
                                alignItems="center"
                                direction="row"
                                justifyContent="space-between"
                                spacing={1}
                                sx={{
                                    flex: 1,
                                    padding:
                                        expanded
                                            ? theme.spacing(2, 5, 2, 3)
                                            : undefined
                                }}>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        fontWeight: 500
                                    }}
                                    variant="h4">
                                    {section.title}
                                </Typography>
                                {!expanded &&
                                    section.options?.expandable &&
                                    <ActionButton
                                        size="large"
                                        onClick={() => setExpanded()}>
                                        <ExpandIcon/>
                                    </ActionButton>}
                                {!_.isNil(section.options?.actionsElement) &&
                                    <Box sx={{ flex: 1 }}>
                                        {section.options!.actionsElement}
                                    </Box>}
                            </Stack>
                        </Stack>
                        <Box
                            sx={{
                                flex: 1,
                                overflow:
                                    expanded
                                        ? "auto"
                                        : undefined,
                                padding:
                                    expanded
                                        ? theme.spacing(0, 5, 3, 3)
                                        : undefined,
                                width: "100%"
                            }}>
                            {section.contentElement}
                        </Box>
                    </Stack>}
            </ExpandPanel>
        </Card>);
}