﻿import { useLocalization } from "@infrastructure";
import React, { useCallback } from "react";
import { useEnabledTranslator } from "../..";
import { Contract, CustomerConsoleAppUrlHelper, RiskPoliciesType, useEntityTypeNameTranslator } from "../../../../../../../common";
import { EnabledRadioGroup } from "../components";
import { RiskPolicyConfigurationDefinitionData } from "../useConfigurationDefinition";

export function useGcpCommonExcessivePermissionPrincipalRiskPolicyConfigurationDefinition(principalTypeName: string, riskPoliciesType: RiskPoliciesType): RiskPolicyConfigurationDefinitionData {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const enabledTranslator = useEnabledTranslator();

    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useConfigurationDefinition.hooks.useGcpCommonExcessivePermissionPrincipalRiskPolicyConfigurationDefinition",
            () => ({
                description: "Choose whether or not to recommend removing {{translatedPluralPrincipalTypeName}} from groups in remediation steps when the {{translatedPrincipalTypeName}} is inactive.",
                title: "Remove Inactive Principals from Groups"
            }));

    const getEnabled =
        useCallback(
            (riskPolicyConfiguration: Contract.RiskPolicyConfiguration) =>
                (riskPolicyConfiguration as Contract.GcpExcessivePermissionPrincipalRiskPolicyConfiguration).inactivePrincipalDeleteGroupMembershipEnabled,
            []);

    return {
        generalAdditionalItems: [{
            configurationElement:
                <EnabledRadioGroup
                    description={
                        localization.description({
                            translatedPluralPrincipalTypeName:
                                entityTypeNameTranslator(
                                    principalTypeName,
                                    {
                                        count: 0,
                                        includeServiceName: false,
                                        variant: "text"
                                    }),
                            translatedPrincipalTypeName:
                                entityTypeNameTranslator(
                                    principalTypeName,
                                    {
                                        includeServiceName: false,
                                        variant: "text"
                                    })
                        })}
                    documentationUrl={CustomerConsoleAppUrlHelper.getDocsViewAndManagePoliciesRelativeUrl()}
                    getEnabled={getEnabled}
                    key={`${Contract.GcpExcessivePermissionPrincipalRiskPolicyConfiguration}-inactivePrincipalDeleteGroupMembershipEnabled`}
                    riskPoliciesType={riskPoliciesType}
                    setEnabled={
                        (riskPolicyConfiguration, inactivePrincipalDeleteGroupMembershipEnabled) => {
                            (riskPolicyConfiguration as Contract.GcpExcessivePermissionPrincipalRiskPolicyConfiguration).inactivePrincipalDeleteGroupMembershipEnabled = inactivePrincipalDeleteGroupMembershipEnabled;
                        }}
                    title={localization.title()}/>,
            viewItem: {
                getValue: riskPolicyConfiguration => enabledTranslator(getEnabled(riskPolicyConfiguration)),
                title: localization.title()
            }
        }]
    };
}