import { InlineItems, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore, InlineEntities, NetworkScopeFormatter, useTheme } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, AwsEc2SecurityGroupRulesTable, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem, RiskDefinitionSection } from "../../../../../../utilities";
import { useGetAwsEc2SecurityGroupRiskContext } from "../contexts";

export function useAwsEc2SecurityGroupInboundRuleSubnetAnyExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const securityGroupInboundRuleSubnetAnyExistsRiskModel = riskModel as Contract.AwsEc2SecurityGroupInboundRuleSubnetAnyExistsRiskModel;
    const securityGroupInboundRuleSubnetAnyExistsRisk = riskModel.risk as Contract.AwsEc2SecurityGroupInboundRuleSubnetAnyExistsRisk;
    const securityGroupModel = entityModelStore.useGet(securityGroupInboundRuleSubnetAnyExistsRisk.entityId) as Contract.AwsEc2SecurityGroupModel;
    const securityGroup = securityGroupModel.entity as Contract.AwsEc2SecurityGroup;
    const publicPorts =
        _.map(
            securityGroupInboundRuleSubnetAnyExistsRiskModel.unrestrictedDestinationNetworkScopes,
            unrestrictedNetworkScope => NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(unrestrictedNetworkScope));
    const getAwsEc2SecurityGroupRiskContext = useGetAwsEc2SecurityGroupRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2SecurityGroupInboundRuleSubnetAnyExistsRiskDefinition",
            () => ({
                attachedResources: {
                    attached: "The security group is attached to {{resources}}",
                    notAttached: "The security group is not attached to any resource"
                },
                description: "{{securityGroup}} allows inbound network traffic from any IP address on {{publicPorts}}",
                directWideRangeInboundExternalAttachedResources: "The security group is allowing direct public inbound network access to {{resources}} on {{publicPorts}}",
                lambdaFunctionConfigurationOnlyAttached: "The security group is only attached to lambda functions and affect outbound traffic",
                ports: {
                    allPorts: "all ports",
                    pluralizer: [
                        "1 port range",
                        "{{count | NumberFormatter.humanize}} port ranges"
                    ]
                },
                sections: {
                    inboundRules: "Inbound Rules",
                    resolution: {
                        step1: "In the **Inbound rules** tab, click **Edit inbound rules**",
                        step2: "For the relevant inbound rule, edit the address range to restrict it, or delete it by clicking **Delete**",
                        step3: "Click **Save rules** to confirm"
                    }
                }
            }));

    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            publicPorts:
                securityGroupInboundRuleSubnetAnyExistsRiskModel.allPorts
                    ? localization.ports.allPorts()
                    : <InlineItems
                        items={publicPorts}
                        namePluralizer={localization.ports.pluralizer}
                        variant="itemCountAndType"/>,
            securityGroup:
                <Entity
                    entityIdOrModel={securityGroupModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Ec2,
                AwsConsoleUrlBuilder.getEc2SecurityGroupsSecurityGroupUrl(securityGroup)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        securityGroupInboundRuleSubnetAnyExistsRiskModel,
        () => {
            const securityGroupRiskContext = getAwsEc2SecurityGroupRiskContext(securityGroupModel);
            return [
                securityGroupRiskContext.generalInformation,
                securityGroupRiskContext.vpc,
                securityGroupRiskContext.sensitive,
                new RiskDefinitionContextItem(
                    _.isEmpty(securityGroupInboundRuleSubnetAnyExistsRisk.attachedResourceIds)
                        ? localization.attachedResources.notAttached()
                        : localization.attachedResources.attached({
                            resources:
                                <InlineEntities
                                    entityIdsOrModels={securityGroupInboundRuleSubnetAnyExistsRisk.attachedResourceIds}
                                    entityTypeName={Contract.TypeNames.AwsResource}
                                    variant="itemCountAndType"/>
                        })),
                _.isEmpty(securityGroupInboundRuleSubnetAnyExistsRisk.directWideRangeInboundExternalAttachedResourceIds)
                    ? undefined
                    : new RiskDefinitionContextItem(localization.directWideRangeInboundExternalAttachedResources({
                        publicPorts:
                            securityGroupInboundRuleSubnetAnyExistsRiskModel.allPorts
                                ? localization.ports.allPorts()
                                : <InlineItems
                                    items={publicPorts}
                                    namePluralizer={localization.ports.pluralizer}
                                    variant="itemCountAndType"/>,
                        resources:
                            <InlineEntities
                                entityIdsOrModels={securityGroupInboundRuleSubnetAnyExistsRisk.directWideRangeInboundExternalAttachedResourceIds}
                                entityTypeName={Contract.TypeNames.AwsResource}
                                variant="itemCountAndType"/>
                    })),
                securityGroupInboundRuleSubnetAnyExistsRisk.lambdaFunctionConfigurationOnlyAttached
                    ? new RiskDefinitionContextItem(localization.lambdaFunctionConfigurationOnlyAttached())
                    : undefined,
                securityGroupRiskContext.getOpenRiskedEntityRisksContextItem(securityGroupInboundRuleSubnetAnyExistsRisk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <AwsEc2SecurityGroupRulesTable
                        getHighlightColor={
                            (rule, opacity) =>
                                _.some(
                                    securityGroupInboundRuleSubnetAnyExistsRisk.unrestrictedInboundRules,
                                    unrestrictedInboundRule =>
                                        _.isEqual(
                                            unrestrictedInboundRule,
                                            rule))
                                    ? opacity
                                        ? theme.palette.opacity(theme.palette.severity(securityGroupInboundRuleSubnetAnyExistsRisk.severity), opacity)
                                        : theme.palette.severity(securityGroupInboundRuleSubnetAnyExistsRisk.severity)
                                    : undefined
                        }
                        inbound={true}
                        rules={securityGroup.inboundRules}/>,
                    localization.sections.inboundRules(),
                    {
                        expandable: true
                    })
            ]
        });
}