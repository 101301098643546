﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsEsDomainEngineTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsEsDomainEngineTypeTranslator",
            () => ({
                [Contract.TypeNames.AwsEsDomainEngineType]: {
                    [Contract.AwsEsDomainEngineType.Elasticsearch]: "Elasticsearch",
                    [Contract.AwsEsDomainEngineType.OpenSearch]: "OpenSearch"
                }
            }));
    return (engineType: Contract.AwsEsDomainEngineType) =>
        localization[Contract.TypeNames.AwsEsDomainEngineType][engineType]();
}