﻿import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useRef, useState } from "react";
import { Link, TimeSpanHelper, useChangeEffect, useLocalization } from "@infrastructure";
import { CustomerConsoleAppUrlHelper, RiskPoliciesType, RiskPolicyTypeMetadataHelper, useTheme } from "../../../../../../../../../../common";
import { useEntityCreationTimeAnalysisDelayTimeFrame, useRiskPoliciesItemConfiguration } from "../../../../../../hooks";
import { useConfigurationContext, useSetConfigurationContext } from "../../../../Configuration";
import { EntityCreationTimeAnalysisDelayTimeFrameItem } from "./components";

interface EntityCreationTimeAnalysisDelayTimeFrameProps {
    riskPoliciesType: RiskPoliciesType;
}

export function EntityCreationTimeAnalysisDelayTimeFrame({ riskPoliciesType }: EntityCreationTimeAnalysisDelayTimeFrameProps) {
    const { item, riskPolicyConfiguration, scopeId } = useConfigurationContext();
    const { inheritDisabled } = useRiskPoliciesItemConfiguration(riskPoliciesType, item, scopeId);
    const setConfigurationContext = useSetConfigurationContext();
    const riskPolicyTypeMetadata = RiskPolicyTypeMetadataHelper.get(riskPolicyConfiguration.typeName);

    const { defaultCustomEntityCreationTimeAnalysisDelayTimeFrame, defaultSelectedEntityCreationTimeAnalysisDelayTimeFrame, inheritedScopeId, inheritedTranslatedDelayTimeFrame, userSecurityAdministrationRead } = useEntityCreationTimeAnalysisDelayTimeFrame(item, riskPolicyConfiguration);

    const [selectedEntityCreationTimeAnalysisDelayTimeFrame, setSelectedEntityCreationTimeAnalysisDelayTimeFrame] = useState(defaultSelectedEntityCreationTimeAnalysisDelayTimeFrame);

    const customEntityCreationTimeAnalysisDelayTimeFrameRef = useRef(defaultCustomEntityCreationTimeAnalysisDelayTimeFrame);

    useChangeEffect(
        () => {
            riskPolicyConfiguration.entityCreationTimeAnalysisDelayTimeFrame =
                _.isNil(selectedEntityCreationTimeAnalysisDelayTimeFrame)
                    ? undefined
                    : TimeSpanHelper.fromDays(selectedEntityCreationTimeAnalysisDelayTimeFrame);

            setConfigurationContext(
                configurationContext => ({
                    ...configurationContext,
                    dirty: true,
                    riskPolicyConfiguration
                }));
        },
        [selectedEntityCreationTimeAnalysisDelayTimeFrame]);

    const localization =
        useLocalization(
            "views.customer.riskPolicies.configuration.general.entityCreationTimeAnalysisDelayTimeFrame",
            () => ({
                description: {
                    documentationLink: "Learn more",
                    text: "Postpone the creation of findings by a specified amount of time for new resources only, when policy rules are violated."
                },
                inheritedValue: "{{scope}} ({{inheritedValue}})",
                options: {
                    inherit: "Inherit",
                    many: "Custom",
                    zero: "Immediately"
                },
                title: "Delay"
            }));

    const disabled = !userSecurityAdministrationRead || !riskPolicyTypeMetadata.riskPolicyEntityCreationTimeAnalysisDelayTimeFrameChangeable;

    function onClick(entityCreationTimeAnalysisDelayTimeFrame?: number) {
        if (!disabled) {
            setSelectedEntityCreationTimeAnalysisDelayTimeFrame(entityCreationTimeAnalysisDelayTimeFrame);
        }
    }

    const theme = useTheme();
    return (
        <Stack>
            <Typography variant="h4">
                {localization.title()}
            </Typography>
            <Stack sx={{ marginTop: theme.spacing(1) }}>
                <Typography>
                    {localization.description.text()}
                </Typography>
                <Typography>
                    <Link
                        urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsViewAndManagePoliciesRelativeUrl()}
                        variant="external">
                        {localization.description.documentationLink()}
                    </Link>
                </Typography>
                {!inheritDisabled &&
                    <EntityCreationTimeAnalysisDelayTimeFrameItem
                        checked={_.isNil(selectedEntityCreationTimeAnalysisDelayTimeFrame)}
                        disabled={disabled}
                        inheritedScopeId={inheritedScopeId}
                        title={localization.options.inherit()}
                        translatedInheritedEntityCreationTimeAnalysisDelayTimeFrameTitle={inheritedTranslatedDelayTimeFrame}
                        onClick={() => onClick(undefined)}/>}
                <EntityCreationTimeAnalysisDelayTimeFrameItem
                    checked={selectedEntityCreationTimeAnalysisDelayTimeFrame === 0}
                    disabled={disabled}
                    title={localization.options.zero()}
                    onClick={() => onClick(0)}/>
                <EntityCreationTimeAnalysisDelayTimeFrameItem
                    checked={
                        !_.isNil(selectedEntityCreationTimeAnalysisDelayTimeFrame) &&
                        selectedEntityCreationTimeAnalysisDelayTimeFrame > 0}
                    disabled={disabled}
                    title={localization.options.many()}
                    value={customEntityCreationTimeAnalysisDelayTimeFrameRef.current}
                    onChange={
                        entityCreationTimeAnalysisDelayTimeFrame => {
                            customEntityCreationTimeAnalysisDelayTimeFrameRef.current = entityCreationTimeAnalysisDelayTimeFrame;
                            setSelectedEntityCreationTimeAnalysisDelayTimeFrame(entityCreationTimeAnalysisDelayTimeFrame);
                        }}
                    onClick={() => onClick(customEntityCreationTimeAnalysisDelayTimeFrameRef.current)}/>
            </Stack>
        </Stack>);
}