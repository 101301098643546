import { TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useOneLoginDirectoryUserProvisioningStateTranslator, useOneLoginDirectoryUserStatusTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { useOneLoginCommonDirectoryEntityInfoItemElements } from "./useOneLoginCommonDirectoryEntityInfoItemElements";

export function useOneLoginDirectoryUserDefinition(directoryEntityModel: Contract.OneLoginDirectoryEntityModel, options?: DefinitionOptions) {
    const defaultResourceInfoItemElements = useOneLoginCommonDirectoryEntityInfoItemElements(directoryEntityModel);
    const user = directoryEntityModel.entity as Contract.OneLoginDirectoryUser;
    const userModel = directoryEntityModel as Contract.OneLoginDirectoryUserModel;
    const userProfile = directoryEntityModel.entityProfile as Contract.OneLoginDirectoryUserProfile;

    const directoryUserProvisioningStateTranslator = useOneLoginDirectoryUserProvisioningStateTranslator();
    const directoryUserStatusTranslator = useOneLoginDirectoryUserStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oneLogin.useOneLoginDirectoryUserDefinition",
            () => ({
                info: {
                    items: {
                        activityTime: {
                            empty: "Not seen",
                            title: "AWS Activity Time"
                        },
                        company: "Company",
                        creationTime: "Creation Time",
                        department: "Department",
                        firstName: "First Name",
                        group: "Group",
                        lastName: "Last Name",
                        manager: "Manager",
                        passwordUpdateTime: {
                            empty: "Never",
                            title: "Password Update Time"
                        },
                        privileges: "Privileges",
                        provisioningState: "State",
                        rawMail: "Mail",
                        roles: "Roles",
                        status: "Status",
                        title: "Title",
                        userName: "User Name"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="creationTime"
                        location="all"
                        title={localization.info.items.creationTime()}
                        value={TimeFormatter.profileFormatDateTime(user.creationTime)}/>,
                    <InfoItem
                        key="rawMail"
                        location="all"
                        title={localization.info.items.rawMail()}
                        value={user.rawMail}/>,
                    <InfoItem
                        key="activityTime"
                        location="all"
                        title={localization.info.items.activityTime.title()}
                        value={
                            _.isNil(userProfile?.activityTime)
                                ? localization.info.items.activityTime.empty()
                                : TimeFormatter.profileFormatDateTime(userProfile.activityTime)}/>,
                    <InfoItem
                        key="passwordUpdateTime"
                        title={localization.info.items.passwordUpdateTime.title()}
                        value={
                            _.isNil(user.passwordUpdateTime)
                                ? localization.info.items.passwordUpdateTime.empty()
                                : TimeFormatter.profileFormatDateTime(user.passwordUpdateTime)}/>,
                    <InfoItem
                        key="status"
                        location="all"
                        title={localization.info.items.status()}
                        value={directoryUserStatusTranslator(user.status)}/>,
                    <InfoItem
                        key="provisioningState"
                        title={localization.info.items.provisioningState()}
                        value={directoryUserProvisioningStateTranslator(user.provisioningState)}/>,
                    <InfoItem
                        key="userName"
                        title={localization.info.items.userName()}
                        value={user.userName}/>,
                    <InfoItem
                        key="firstName"
                        location="all"
                        title={localization.info.items.firstName()}
                        value={user.firstName}/>,
                    <InfoItem
                        key="lastName"
                        location="all"
                        title={localization.info.items.lastName()}
                        value={user.lastName}/>,
                    <InfoItem
                        key="title"
                        location="all"
                        title={localization.info.items.title()}
                        value={user.title}/>,
                    <InfoItem
                        key="company"
                        location="all"
                        title={localization.info.items.company()}
                        value={user.company}/>,
                    <InfoItem
                        key="department"
                        location="all"
                        title={localization.info.items.department()}
                        value={user.department}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={userModel.managerId}
                        entityTypeName={Contract.TypeNames.OneLoginDirectoryUser}
                        key="managerId"
                        location="all"
                        title={localization.info.items.manager()}/>,
                    <EntitiesInfoItem
                        emptyValue={userModel.groupId}
                        entityIdsOrModels={userModel.groupId}
                        entityTypeName={Contract.TypeNames.OneLoginDirectoryGroup}
                        key="group"
                        title={localization.info.items.group()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={userModel.roleIds}
                        entityTypeName={Contract.TypeNames.OneLoginDirectoryRole}
                        key="sourceVolume"
                        title={localization.info.items.roles()}/>
                ]}
                options={options?.infoOptions}>
            </Info>,
        sensitive: false
    });
}