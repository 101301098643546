import { Step, Steps, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsRdsDatabaseInstanceRiskContext } from "../contexts";

export function useAwsRdsDatabaseInstanceCloudWatchLoggingDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsRdsDatabaseInstanceCloudWatchLoggingDisabledRisk;
    const databaseInstanceModel = entityModelStore.useGet(risk.entityId) as Contract.AwsRdsDatabaseInstanceModel;
    const databaseInstance = databaseInstanceModel.entity as Contract.AwsRdsDatabaseInstance;

    const getAwsRdsDatabaseInstanceRiskContext = useGetAwsRdsDatabaseInstanceRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsRdsDatabaseInstanceCloudWatchLoggingDisabledRiskDefinition",
            () => ({
                description: "{{databaseInstance}} does not publish logs to CloudWatch Logs",
                sections: {
                    resolution: {
                        step1: "Click **Modify**",
                        step2: {
                            step1: "Oracle: Alert, Audit, Trace, Listener logs",
                            step2: "PostgreSQL: Postgresql, Upgrade logs",
                            step3: "MySQL: Audit, Error, General, SlowQuery logs",
                            step4: "MariaDB: Audit, Error, General, SlowQuery logs",
                            step5: "SQL Server: Error, Agent logs",
                            step6: "Aurora: Audit, Error, General, SlowQuery logs",
                            step7: "Aurora-PostgreSQL: Postgresql, Upgrade logs",
                            step8: "Aurora-MySQL: Audit, Error, General, SlowQuery logs",
                            title: "Go to **Additional monitoring settings** and under **Log exports**, select the log types to publish according to your DB engine:"
                        },
                        step3: "Continue and save changes"
                    }
                }
            }));

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            databaseInstance:
                <Entity
                    entityIdOrModel={databaseInstanceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Rds,
                AwsConsoleUrlBuilder.getRdsDatabaseInstanceUrl(databaseInstance)),
            localization.sections.resolution.step1(),
            new Step(
                localization.sections.resolution.step2.title(),
                {
                    contentElement:
                        <Steps>
                            {localization.sections.resolution.step2.step1()}
                            {localization.sections.resolution.step2.step2()}
                            {localization.sections.resolution.step2.step3()}
                            {localization.sections.resolution.step2.step4()}
                            {localization.sections.resolution.step2.step5()}
                            {localization.sections.resolution.step2.step6()}
                            {localization.sections.resolution.step2.step7()}
                            {localization.sections.resolution.step2.step8()}
                        </Steps>
                }),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const databaseInstanceRiskContext = getAwsRdsDatabaseInstanceRiskContext(databaseInstanceModel);
            return [
                databaseInstanceRiskContext.generalInformation,
                databaseInstanceRiskContext.storageSize,
                databaseInstanceRiskContext.highestDataSensitivity,
                databaseInstanceRiskContext.sensitive,
                databaseInstanceRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}