import { parse } from "json2csv";
import _, { Dictionary } from "lodash";
import { ExportFileNameOptions, ReportContentType, useGetExportFileName } from "./useGetExportFileName";

export function useExportCsv<T>(
    fileNameOptions: ExportFileNameOptions,
    getItems: () => Promise<T[]>) {
    const getExportFileName = useGetExportFileName(ReportContentType.Csv);
    const exportFileName = getExportFileName(fileNameOptions);

    return async () => {
        const items = await getItems();
        const csvBlobUrl =
            window.URL.createObjectURL(
                new Blob(
                    [
                        parse(
                            items,
                            {
                                fields:
                                    _(items).
                                        flatMap(item => _.keys(item as Dictionary<any>)).
                                        uniq().
                                        value(),
                                transforms: [
                                    item =>
                                        _.mapValues(
                                            item,
                                            itemValue =>
                                                _.isString(itemValue)
                                                    ? itemValue.
                                                        // eslint-disable-next-line no-control-regex
                                                        replace(/\x00/g, "").
                                                        replace(/^([=\-@+].+)/, (match: string) => `'${match}`)
                                                    : itemValue)
                                ]
                            })
                    ],
                    { type: "text/csv" }));

        const aElement = document.createElement("a");
        aElement.href = csvBlobUrl;
        aElement.target = "_blank";
        aElement.download = exportFileName;
        document.body.appendChild(aElement);
        aElement.click();
        document.body.removeChild(aElement);
        window.URL.revokeObjectURL(csvBlobUrl);
    };
}