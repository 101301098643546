import { Link, TextViewer, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTheme } from "../../../../../../../../../../../../../../../../../../../common";
import { useAddOrEditContext } from "../../../../../AddOrEdit";

export function ScanContainerImageActionItem() {
    const { apiRootUrl } = useAddOrEditContext();
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodePipelineItems.codePipeline.addOrEdit.hooks.useDefinition.hooks.useGitHubWorkflow.scanContainerImageActionItem",
            () => ({
                description: {
                    link: "GitHub Workflow",
                    text: "To scan your container images, add the following step to your {{gitHubWorkflowLink}} (adjust as needed):"
                },
                note: {
                    link: "Tenable Cloud Security Action documentation",
                    text: "Once the action is triggered, you should start seeing scan results under **Workload Protection -> CI/CD Scans**. Please refer to the {{gitHubActionLink}} for more details"
                }
            }));

    const theme = useTheme();
    return (
        <Stack spacing={4}>
            <Typography>
                {localization.description.text({
                    gitHubWorkflowLink:
                        <Link
                            sx={{ textDecorationLine: "underline" }}
                            urlOrGetUrl="https://docs.github.com/en/actions/using-workflows/about-workflows"
                            variant="external">
                            {localization.description.link()}
                        </Link>
                })}
            </Typography>
            <TextViewer
                copyToClipboard={true}
                format="yaml"
                text={
                    _.join(
                        [
                            "    steps:",
                            "    - uses: actions/checkout@v3",
                            "    - name: Scan container image with Tenable Cloud Security",
                            "      uses: tenable/cloud-security-actions/container-image/scan@v1",
                            "      with:",
                            "        api-token: ${{ secrets.TENABLE_API_TOKEN }}",
                            `        api-url: ${apiRootUrl}`,
                            "        name: <place your image name here>"
                        ],
                        "\n")}/>
            <Typography sx={{ maxWidth: theme.spacing(75) }}>
                {localization.note.text({
                    gitHubActionLink:
                        <Link
                            sx={{ textDecorationLine: "underline" }}
                            urlOrGetUrl="https://github.com/tenable/cloud-security-actions/tree/main/container-image/scan"
                            variant="external">
                            {localization.note.link()}
                        </Link>
                })}
            </Typography>
        </Stack>);
}