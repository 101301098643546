﻿import { InlineItems, Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InlineEntities, NetworkScopeFormatter, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";

export function useGetAwsInboundExternalResourceRiskContext() {
    return useMemo(
        () => useAwsInboundExternalResourceRiskContext,
        []);
}

export function useAwsInboundExternalResourceRiskContext(entityModel: Contract.EntityModel, riskModel: Contract.AwsInboundExternalResourceRiskModel) {
    const entityNetwork = entityModel.entityNetwork as Optional<Contract.NetworkAccessResourceStateNetwork>;
    const risk = riskModel.risk;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsInboundExternalResourceRiskContext",
            () => ({
                inboundAccess: {
                    [Contract.NetworkInboundAccessType.ExternalDirect]: "The {{translatedResourceTypeName}} is exposing {{ports}} to {{subnets}} directly via {{securityGroups}}",
                    [Contract.NetworkInboundAccessType.ExternalIndirect]: "The {{translatedResourceTypeName}} is exposing {{ports}} to {{subnets}} indirectly via {{gateways}} and {{securityGroups}}",
                    ports: [
                        "1 port",
                        "{{count | NumberFormatter.humanize}} ports"
                    ],
                    sourceSubnets: [
                        "1 IP address range",
                        "{{count | NumberFormatter.humanize}} IP address ranges"
                    ]
                }
            }));

    const destinationNetworkScopes =
        useMemo(
            () =>
                _.map(
                    riskModel.destinationNetworkScopes,
                    destinationNetworkScope => NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(destinationNetworkScope)),
            [riskModel]);
    return {
        inboundAccess:
            _.isNil(entityNetwork)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.inboundAccess[entityNetwork.inboundAccessType as Exclude<Contract.NetworkInboundAccessType, Contract.NetworkInboundAccessType.Internal>]({
                        gateways:
                            <InlineEntities
                                entityIdsOrModels={risk.gatewayResourceIds}
                                entityTypeName={Contract.TypeNames.AwsElbLoadBalancer}
                                variant="itemAndTypeOrItemCountAndType"/>,
                        ports:
                            <InlineItems
                                items={destinationNetworkScopes}
                                namePluralizer={localization.inboundAccess.ports}
                                variant="itemCountAndType"/>,
                        securityGroups:
                            <InlineEntities
                                entityIdsOrModels={risk.securityGroupIds}
                                entityTypeName={Contract.TypeNames.AwsEc2SecurityGroup}
                                variant="itemAndTypeOrItemCountAndType"/>,
                        subnets:
                            <InlineItems
                                items={riskModel.sourceSubnets}
                                namePluralizer={localization.inboundAccess.sourceSubnets}
                                variant="itemCountAndType"/>,
                        translatedResourceTypeName:
                            entityTypeNameTranslator(
                                riskModel.riskedEntityTypeName,
                                {
                                    includeServiceName: false,
                                    variant: "text"
                                })
                    }))
    };

}