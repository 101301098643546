import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../../../components";
import { useGetGcpComputeInstanceRiskContext } from "../../../contexts";

export function useGcpComputeInstanceMemoryEncryptionNotEnabledRiskInstanceDefinition(riskModel: Contract.GcpComputeInstanceMemoryEncryptionNotEnabledRiskModel) {
    const risk = riskModel.risk as Contract.GcpComputeInstanceMemoryEncryptionNotEnabledRisk;
    const instanceModel = entityModelStore.useGet(risk.entityId) as Contract.GcpComputeInstanceModel;

    const getGcpComputeInstanceRiskContext = useGetGcpComputeInstanceRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpComputeInstanceMemoryEncryptionNotEnabledRiskDefinition.hooks.useGcpComputeInstanceMemoryEncryptionNotEnabledRiskInstanceDefinition",
            () => ({
                description: "{{instance}} Confidential Computing is not enabled",
                sections: {
                    resolution: {
                        step1: "Click the **CREATE SIMILAR** button",
                        step2: "In the **Confidential VM service** section, click **Enable**, and then confirm your selection in the dialog box",
                        step3: "Click **Create**",
                        step4: "Delete the old, unsecured VM instance"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            instance:
                <Entity
                    entityIdOrModel={instanceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={riskModel.risk.entityId}
                key={riskModel.risk.entityId}
                page={Contract.GcpConsolePage.Resource}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const instanceContextItems = getGcpComputeInstanceRiskContext(instanceModel);
            return [
                instanceContextItems.generalInformation,
                instanceContextItems.sensitive,
                instanceContextItems.status,
                instanceContextItems.inboundExternalAccessScope,
                instanceContextItems.vpcsAndSubnets,
                instanceContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}