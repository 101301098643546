import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, InlineItems, PagedValuesFilter, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, NetworkedResourcesCell, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useCommonNetworkAccessResourceDefinition } from "../useCommonNetworkAccessResourceDefinition";
import { useAzureCommonResourceGroupResourceDefinition } from "./useAzureCommonResourceGroupResourceDefinition";

export function useAzureNetworkVirtualNetworkSubnetDefinition(definitionData: DefinitionData) {
    const commonNetworkAccessResourceDefinition = useCommonNetworkAccessResourceDefinition();
    const commonResourceGroupResourceDefinition = useAzureCommonResourceGroupResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureNetworkVirtualNetworkSubnetDefinition",
            () => ({
                columns: {
                    networkedResourceIds: "Attached Resources",
                    networkSecurityGroup: "Network Security Group",
                    subnets: "Address Space",
                    virtualNetwork: "Virtual Network"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            commonResourceGroupResourceDefinition.columns.tenantColumn,
            commonResourceGroupResourceDefinition.columns.creationTimeColumn,
            commonResourceGroupResourceDefinition.columns.creatorIdentityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureNetworkVirtualNetworkSubnetModel) => ({
                            [localization.columns.subnets()]: (item.entity as Contract.AzureNetworkVirtualNetworkSubnet).subnets.join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureNetworkVirtualNetworkSubnetSubnets)}
                                placeholder={localization.columns.subnets()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureNetworkVirtualNetworkSubnetSubnets}
                key={Contract.EntityModelProperty.AzureNetworkVirtualNetworkSubnetSubnets}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureNetworkVirtualNetworkSubnetModel>) =>
                        <Typography noWrap={true}>
                            <InlineItems
                                items={(item.entity as Contract.AzureNetworkVirtualNetworkSubnet).subnets}
                                variant="itemPlusItemCount"/>
                        </Typography>}
                title={localization.columns.subnets()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureNetworkVirtualNetworkSubnetModel>(
                        Contract.TypeNames.INetworkedResource,
                        item =>
                            _(item.networkedResourceTypeNameToIdsMap).
                                values().
                                flatMap().
                                value(),
                        localization.columns.networkedResourceIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureNetworkVirtualNetworkSubnetNetworkedResources)}
                                placeholder={localization.columns.networkedResourceIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureNetworkVirtualNetworkSubnetNetworkedResources}
                key={Contract.EntityModelProperty.AzureNetworkVirtualNetworkSubnetNetworkedResources}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureNetworkVirtualNetworkSubnetModel>) =>
                        <NetworkedResourcesCell item={item.networkedResourceTypeNameToIdsMap}/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.networkedResourceIds()}/>,
            commonNetworkAccessResourceDefinition.columns.accessLevelColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureNetworkVirtualNetworkSubnetModel>(
                        Contract.TypeNames.AzureNetworkVirtualNetwork,
                        item => [(item.entity as Contract.AzureNetworkVirtualNetworkSubnet).virtualNetworkId],
                        localization.columns.virtualNetwork())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureNetworkVirtualNetworkSubnetVirtualNetwork)}
                                placeholder={localization.columns.virtualNetwork()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureNetworkVirtualNetworkSubnetVirtualNetwork}
                key={Contract.EntityModelProperty.AzureNetworkVirtualNetworkSubnetVirtualNetwork}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureResourceGroupResourceModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={(item.entity as Contract.AzureNetworkVirtualNetworkSubnet).virtualNetworkId}
                            entityTypeName={Contract.TypeNames.AzureNetworkVirtualNetwork}/>}
                title={localization.columns.virtualNetwork()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureNetworkVirtualNetworkSubnetModel>(
                        Contract.TypeNames.AzureNetworkNetworkSecurityGroup,
                        item => [(item.entity as Contract.AzureNetworkVirtualNetworkSubnet).networkSecurityGroupId],
                        localization.columns.networkSecurityGroup())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureNetworkVirtualNetworkSubnetNetworkSecurityGroup)}
                                placeholder={localization.columns.networkSecurityGroup()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureNetworkVirtualNetworkSubnetNetworkSecurityGroup}
                key={Contract.EntityModelProperty.AzureNetworkVirtualNetworkSubnetNetworkSecurityGroup}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureResourceGroupResourceModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={(item.entity as Contract.AzureNetworkVirtualNetworkSubnet).networkSecurityGroupId}
                            entityTypeName={Contract.TypeNames.AzureNetworkNetworkSecurityGroup}/>}
                title={localization.columns.networkSecurityGroup()}/>,
            commonResourceGroupResourceDefinition.columns.regionColumn,
            commonResourceGroupResourceDefinition.columns.regionLocationColumn,
            commonResourceGroupResourceDefinition.columns.resourceGroupColumn,
            commonResourceGroupResourceDefinition.columns.tagsColumn,
            commonResourceGroupResourceDefinition.columns.attributesColumn,
            commonResourceGroupResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureNetworkVirtualNetworkSubnetRequest(
                new Contract.EntityControllerGetEntityModelPageAzureNetworkVirtualNetworkSubnetRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureResourceGroupResourceResourceGroup]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureNetworkVirtualNetworkSubnetNetworkedResources]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureNetworkVirtualNetworkSubnetNetworkSecurityGroup]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureNetworkVirtualNetworkSubnetSubnets]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureNetworkVirtualNetworkSubnetVirtualNetwork])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestNetworkAccess(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundAccessType]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalAccessScope]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalDestinationNetworkScopes]))));
}