import { Optional, UnexpectedError } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { useCallback } from "react";
import { FiltersHelper } from "../../../..";
import { Contract, RiskType } from "../../../../../../../../../common";

export function useGetGenerateReportRequestDefinition(riskType: RiskType, status: Contract.RiskStatus, additionalColumnResourceTagKeys?: string[], filtersMap: Dictionary<any> = {}) {
    return useCallback(
        (name: Optional<string>) => {
            const filtered = !_.isEmpty(filtersMap);
            switch (riskType) {
                case RiskType.Cloud:
                    return new Contract.ReportControllerCloudRisksReportRequestDefinition(
                        name,
                        undefined,
                        Contract.TypeNames.ReportControllerCloudRisksReportRequestDefinition,
                        additionalColumnResourceTagKeys,
                        filtered,
                        FiltersHelper.toRequestCloudRiskFiltersFromFilterMap(filtersMap, status),
                        undefined,
                        undefined,
                        status);
                case RiskType.Code:
                    return new Contract.ReportControllerCodeRisksReportRequestDefinition(
                        name,
                        undefined,
                        Contract.TypeNames.ReportControllerCodeRisksReportRequestDefinition,
                        undefined,
                        filtered,
                        FiltersHelper.toRequestCodeRiskFiltersFromFilterMap(filtersMap, status),
                        undefined,
                        undefined,
                        status);
                default:
                    throw new UnexpectedError("riskType", riskType);
            }
        },
        [additionalColumnResourceTagKeys, filtersMap, riskType, status]);
}