import { Steps, UnexpectedError, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { ChangeStep, RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsSsoPermissionSetRiskContext } from "../contexts";

export function useAwsSsoPermissionSetUnusedRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsSsoPermissionSetUnusedRisk;
    const permissionSetModel = entityModelStore.useGet(risk.entityId) as Contract.AwsSsoPermissionSetModel;

    const getAwsSsoPermissionSetRiskContext = useGetAwsSsoPermissionSetRiskContext();
    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsSsoPermissionSetUnusedRiskDefinition",
            () => ({
                description: "{{permissionSet}} is unused and should be deleted",
                sections: {
                    context: {
                        [Contract.TypeNames.AwsSsoPermissionSetUnusedRiskModelInfo]: {
                            [Contract.AwsSsoPermissionSetUnusedRiskModelInfo.Assignments]: "{{permissionSet}} is assigned to {{assignedPrincipalIds}} on {{accountIds}}",
                            [Contract.AwsSsoPermissionSetUnusedRiskModelInfo.Unassigned]: "{{permissionSet}} is not assigned to any identity on any account",
                            [Contract.AwsSsoPermissionSetUnusedRiskModelInfo.UsedEver]: "None of the assignees used this permission set for more than {{activityTime | TimeFormatter.humanizeDuration}}",
                            [Contract.AwsSsoPermissionSetUnusedRiskModelInfo.UsedNever]: "None of the assignees used this permission set since it was created {{creationTime | TimeFormatter.humanizePastDuration}}"
                        }
                    },
                    resolution: {
                        delete: "Click **Delete permission set** and confirm the deletion"
                    }
                }
            }));

    function getDeletePermissionSetChangeStep(deletePermissionSetChange: Contract.AwsDeletePermissionSetChange) {
        return new ChangeStep(
            deletePermissionSetChange,
            {
                contentElement:
                    <Steps variant="plainNumbers">
                        {[
                            consoleSignInStepTranslator(
                                Contract.AwsConsoleView.Sso,
                                AwsConsoleUrlBuilder.getSsoPermissionSetUrl(permissionSetModel.entity as Contract.AwsSsoPermissionSet)),
                            localization.sections.resolution.delete()
                        ]}
                    </Steps>
            });
    }

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            permissionSet:
                <Entity
                    entityIdOrModel={permissionSetModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () =>
            _.map(
                risk.resolutionChanges,
                resolutionChange => {
                    switch (resolutionChange.typeName) {
                        case Contract.TypeNames.AwsDeletePermissionSetChange:
                            return getDeletePermissionSetChangeStep(resolutionChange as Contract.AwsDeletePermissionSetChange);
                        case Contract.TypeNames.AwsDeletePermissionSetAccountAssignmentsChange:
                            return new ChangeStep(resolutionChange as Contract.AwsDeletePermissionSetAccountAssignmentsChange);
                        default:
                            throw new UnexpectedError("change.typeName", resolutionChange.typeName);
                    }
                }),
        riskModel,
        () => {
            const permissionSetRiskContext = getAwsSsoPermissionSetRiskContext(permissionSetModel);
            return [
                permissionSetRiskContext.generalInformation,
                ..._.map(
                    (riskModel as Contract.AwsSsoPermissionSetUnusedRiskModel).infos,
                    info => {
                        switch (info) {
                            case Contract.AwsSsoPermissionSetUnusedRiskModelInfo.Assignments:
                                return new RiskDefinitionContextItem(
                                    localization.sections.context[Contract.TypeNames.AwsSsoPermissionSetUnusedRiskModelInfo][info]({
                                        accountIds:
                                            <InlineEntities
                                                entityIdsOrModels={risk.accountIds}
                                                entityTypeName={Contract.TypeNames.AwsOrganizationsAccount}
                                                variant="itemAndTypeOrItemCountAndType"/>,
                                        assignedPrincipalIds:
                                            <InlineEntities
                                                entityIdsOrModels={risk.assignedPrincipalIds}
                                                entityTypeName={Contract.TypeNames.AwsSsoPrincipal}
                                                variant="itemAndTypeOrItemCountAndType"/>,
                                        permissionSet:
                                            <Entity
                                                entityIdOrModel={permissionSetModel}
                                                entityTypeNameTranslatorOptions={{ variant: "title" }}
                                                variant="typeText"/>
                                    }));
                            case Contract.AwsSsoPermissionSetUnusedRiskModelInfo.Unassigned:
                                return new RiskDefinitionContextItem(localization.sections.context[Contract.TypeNames.AwsSsoPermissionSetUnusedRiskModelInfo][info]({
                                    permissionSet:
                                        <Entity
                                            entityIdOrModel={permissionSetModel}
                                            entityTypeNameTranslatorOptions={{ variant: "title" }}
                                            variant="typeText"/>
                                }));
                            case Contract.AwsSsoPermissionSetUnusedRiskModelInfo.UsedEver:
                                return new RiskDefinitionContextItem(localization.sections.context[Contract.TypeNames.AwsSsoPermissionSetUnusedRiskModelInfo][info]({ activityTime: risk.activityTime }));
                            case Contract.AwsSsoPermissionSetUnusedRiskModelInfo.UsedNever:
                                return new RiskDefinitionContextItem(localization.sections.context[Contract.TypeNames.AwsSsoPermissionSetUnusedRiskModelInfo][info]({ creationTime: permissionSetModel.creationTime }));
                        }
                    }),
                permissionSetRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)];
        });
}