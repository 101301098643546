import { Loading, useLocalization } from "@infrastructure";
import { Box, Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useMemo } from "react";
import { Access, Contract, CustomerConsoleAppUrlHelper, entityModelStore, EntityPropertyHelper, RiskTypeMetadataModelHelper, useRiskPolicyTranslator, useTheme } from "../../../../../../../../../../../../common";
import { RiskView } from "../../../../../../../../utilities";
import { AccessRiskDefinitionOptions, RiskDefinitionSection, RiskDefinitionSectionCategory } from "../../../../../../utilities";
import { Resolve } from "../../../../components";
import { useResourceOwnerRiskDefinitionSection } from "../../../useResourceOwnerRiskDefinitionSection";

type AwsCommonAccessPrincipalRiskDefinitionOptions =
    AccessRiskDefinitionOptions & {
        accessGraphActionsElement?: ReactNode;
        accessGraphTitle?: string;
        descriptionSection?: RiskDefinitionSection;
    };

export function useAwsCommonAccessPrincipalRiskDefinition(
    accessScope: Contract.EntityAccessScope,
    contextSectionContentElement: ReactNode,
    description: string,
    riskModel: Contract.RiskModel,
    options?: AwsCommonAccessPrincipalRiskDefinitionOptions) {
    const accessPrincipalRisk = riskModel.risk as Contract.AwsAccessPrincipalRisk;
    const riskPolicyTranslator = useRiskPolicyTranslator();
    const principalModel = entityModelStore.useGet(accessPrincipalRisk.entityId);
    const resourceOwnerRiskDefinitionSection = useResourceOwnerRiskDefinitionSection(riskModel);
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.access.useAwsCommonAccessPrincipalRiskDefinition",
            () => ({
                sections: {
                    accessGraph: "Permissions and usage",
                    context: "Context",
                    policyDescription: "Policy",
                    resolution: "Remediation steps"
                }
            }));

    const theme = useTheme();
    return useMemo(
        () => ({
            description,
            sections:
                _.filter([
                    new RiskDefinitionSection(
                        <Typography sx={{ whiteSpace: "pre-wrap" }}>
                            {riskPolicyTranslator(RiskTypeMetadataModelHelper.get(accessPrincipalRisk.typeName).policyConfigurationTypeName).description}
                        </Typography>,
                        localization.sections.policyDescription(),
                        {
                            resolvedRiskVisible: true
                        }),
                    _.isNil(options?.descriptionSection)
                        ? undefined!
                        : options!.descriptionSection,
                    new RiskDefinitionSection(
                        contextSectionContentElement,
                        localization.sections.context()),
                    EntityPropertyHelper.showRiskResourceOwner(riskModel)
                        ? resourceOwnerRiskDefinitionSection
                        : undefined!,
                    !principalModel.entity.systemDeleted
                        ? new RiskDefinitionSection(
                            <Box
                                sx={{
                                    minHeight: theme.spacing(20),
                                    paddingTop: theme.spacing(1),
                                    width: "100%"
                                }}>
                                <Loading>
                                    <Access
                                        baseUrl={CustomerConsoleAppUrlHelper.getRiskProfileHashUrl(accessPrincipalRisk.id, { category: RiskDefinitionSectionCategory.Overview })}
                                        entityId={principalModel.entity.id}
                                        scope={accessScope}
                                        tenantType={Contract.TenantType.Aws}
                                        variant="risk"/>
                                </Loading>
                            </Box>,
                            options?.accessGraphTitle ?? localization.sections.accessGraph(),
                            {
                                actionsElement: options?.accessGraphActionsElement,
                                profilePageOnly: true
                            })
                        : undefined!,
                    options?.resolutionSectionDefinitionComponent == undefined
                        ? undefined!
                        : new RiskDefinitionSection(
                            <options.resolutionSectionDefinitionComponent riskModel={riskModel}/>,
                            localization.sections.resolution(),
                            {
                                actionsElement:
                                    accessPrincipalRisk.typeName === Contract.TypeNames.AwsExcessivePermissionRoleGroupRisk
                                        ? undefined!
                                        : <Box
                                            alignItems="center"
                                            justifyContent="flex-end"
                                            sx={{ display: "flex", width: "100%" }}>
                                            <Resolve/>
                                        </Box>,
                                categoryView: {
                                    category: RiskDefinitionSectionCategory.Resolution,
                                    view: RiskView.ResolutionConsole
                                }
                            })
                ])
        }),
        [accessScope, contextSectionContentElement, description, riskModel, options, resourceOwnerRiskDefinitionSection, principalModel]);
}