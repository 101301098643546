import { DataTableColumn, DataTableColumnRenderProps, EnumValuesFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAzureComputeVirtualMachineResourceDefinition } from ".";
import { EntityTableDefinition } from "../..";
import { useCommonNetworkAccessResourceDefinition, useCommonVirtualMachineDiskResourceDefinition, useCommonVirtualMachineResourceDefinition } from "..";
import { Contract, ElasticsearchItemPageHelper, Entity, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAzureComputeVirtualMachineStatusTranslator } from "../../../../../../../../../../tenants";
import { DefinitionData } from "../../../../Table";

export function useAzureComputeVirtualMachineScaleSetVirtualMachineDefinition(definitionData: DefinitionData) {
    const commonNetworkAccessResourceDefinition = useCommonNetworkAccessResourceDefinition();
    const commonVirtualMachineDiskResourceDefinition = useCommonVirtualMachineDiskResourceDefinition(definitionData);
    const commonVirtualMachineResourceDefinition = useCommonVirtualMachineResourceDefinition();
    const computeVirtualMachineResourceDefinition = useAzureComputeVirtualMachineResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const virtualMachineScaleSetVirtualMachineModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.AzureComputeVirtualMachineScaleSetVirtualMachineModelFilters>;

    const computeVirtualMachineStatusTranslator = useAzureComputeVirtualMachineStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureComputeVirtualMachineScaleSetVirtualMachineDefinition",
            () => ({
                columns: {
                    status: "Status",
                    virtualMachineScaleSet: "Virtual Machine Scale Set"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            computeVirtualMachineResourceDefinition.columns.rawIdColumn,
            computeVirtualMachineResourceDefinition.columns.tenantColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureResourceGroupResourceModel) => ({
                            [localization.columns.status()]: computeVirtualMachineStatusTranslator((item.entity as Contract.AzureComputeVirtualMachineScaleSetVirtualMachine).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AzureComputeVirtualMachineStatus}
                                enumTypeTranslator={computeVirtualMachineStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureComputeVirtualMachineScaleSetVirtualMachineStatus}
                itemProperty={(item: Contract.EntityModel) => computeVirtualMachineStatusTranslator((item.entity as Contract.AzureComputeVirtualMachineScaleSetVirtualMachine).status)}
                key={Contract.EntityModelProperty.AzureComputeVirtualMachineScaleSetVirtualMachineStatus}
                title={localization.columns.status()}/>,
            commonVirtualMachineDiskResourceDefinition.columns.operatingSystemColumn,
            commonVirtualMachineDiskResourceDefinition.columns.operatingSystemTypeColumn,
            commonNetworkAccessResourceDefinition.columns.accessLevelColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureComputeVirtualMachineScaleSetVirtualMachineModel>(
                        Contract.TypeNames.AzureComputeVirtualMachineScaleSet,
                        item => [(item.entity as Contract.AzureComputeVirtualMachineScaleSetVirtualMachine).virtualMachineScaleSetId],
                        localization.columns.virtualMachineScaleSet())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureComputeVirtualMachineScaleSetVirtualMachineVirtualMachineScaleSet)}
                                placeholder={localization.columns.virtualMachineScaleSet()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureComputeVirtualMachineScaleSetVirtualMachineVirtualMachineScaleSet}
                key={Contract.EntityModelProperty.AzureComputeVirtualMachineScaleSetVirtualMachineVirtualMachineScaleSet}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureComputeVirtualMachineScaleSetVirtualMachineModel>) =>
                        <Entity
                            entityIdOrModel={(item.entity as Contract.AzureComputeVirtualMachineScaleSetVirtualMachine).virtualMachineScaleSetId!}
                            variant="iconText"/>}
                title={localization.columns.virtualMachineScaleSet()}/>,
            computeVirtualMachineResourceDefinition.columns.privateIpAddressesColumn,
            computeVirtualMachineResourceDefinition.columns.publicIpAddressesColumn,
            commonNetworkAccessResourceDefinition.columns.inboundExternalAccessScopeColumn,
            commonNetworkAccessResourceDefinition.columns.destinationNetworkScopesColumn(virtualMachineScaleSetVirtualMachineModelFiltersPromise),
            computeVirtualMachineResourceDefinition.columns.creatorIdentityCsvColumn,
            computeVirtualMachineResourceDefinition.columns.virtualNetworkColumn,
            computeVirtualMachineResourceDefinition.columns.loadBalancersColumn,
            computeVirtualMachineResourceDefinition.columns.applicationGatewaysColumn,
            computeVirtualMachineResourceDefinition.columns.regionColumn,
            computeVirtualMachineResourceDefinition.columns.regionLocationColumn,
            computeVirtualMachineResourceDefinition.columns.resourceGroupColumn,
            commonVirtualMachineDiskResourceDefinition.columns.vulnerabilitiesColumn,
            commonVirtualMachineDiskResourceDefinition.columns.workloadAnalysisStatusColumn,
            commonVirtualMachineDiskResourceDefinition.columns.scanTimeColumn,
            commonVirtualMachineResourceDefinition.columns.scanSource,
            computeVirtualMachineResourceDefinition.columns.tagsColumn,
            computeVirtualMachineResourceDefinition.columns.attributesColumn,
            computeVirtualMachineResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            computeVirtualMachineResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            computeVirtualMachineResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureComputeVirtualMachineScaleSetVirtualMachineRequest(
                new Contract.EntityControllerGetEntityModelPageAzureComputeVirtualMachineScaleSetVirtualMachineRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureResourceGroupResourceResourceGroup]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureComputeVirtualMachineResourceApplicationGateways]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureComputeVirtualMachineResourceLoadBalancers]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureComputeVirtualMachineResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureComputeVirtualMachineResourceVirtualNetwork]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureComputeVirtualMachineScaleSetVirtualMachineStatus]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureComputeVirtualMachineScaleSetVirtualMachineVirtualMachineScaleSet])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestNetworkAccess(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundAccessType]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalAccessScope]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalDestinationNetworkScopes])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestVirtualMachineDiskResource(
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.VirtualMachineOperatingSystemDisplayName]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.VirtualMachineOperatingSystemType]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.VirtualMachineScanTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.VirtualMachineVulnerabilities]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.VirtualMachineVulnerabilitySeverities]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.VirtualMachineScanStatus])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestVirtualMachineResource(ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.VirtualMachineKubernetesClusterSensor]))));
}