import { browserHashHistory, browserHistory, makeContextProvider, UnexpectedError } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";

export class RouteContext {
    constructor(
        public type: RouteType) {
    }
}

export enum RouteType {
    Hash = "hash",
    Url = "url"
}

export const [useRouteContext, , useRouteContextProvider] = makeContextProvider<RouteContext>();

type RouteResult =
    Dictionary<string> & {
        additionalParts?: string[];
        match: boolean;
    };

export const hashRouteFreezeContext = createContext<boolean | null>(null);

type HashRouteFreezeProviderProps = {
    children: ReactNode;
    freeze: boolean;
};

export function HashRouteFreezeProvider({ children, freeze }: HashRouteFreezeProviderProps) {
    return (
        <hashRouteFreezeContext.Provider value={freeze}>
            {children}
        </hashRouteFreezeContext.Provider>);
}

export function useRoute(templatePath: string): RouteResult {
    const { type } = useRouteContext();
    const [, setRefresh] = useState({});
    useEffect(
        () => {
            const unregister =
                browserHistory.listen(
                    () => {
                        setRefresh({});
                    });

            return () => {
                unregister();
            };
        },
        []);

    const useRoute =
        useMemo(
            () => {
                switch (type) {
                    case RouteType.Hash:
                        return useHashRoute;
                    case RouteType.Url:
                        return useUrlRoute;
                    default:
                        throw new UnexpectedError("type", type);
                }
            },
            []);

    return useRoute(templatePath);
}

function useHashRoute(templatePath: string): RouteResult {
    const routeResult =
        useMemo(
            () =>
                getRouteResult(
                    RouteType.Hash,
                    templatePath),
            [browserHashHistory.location.pathname]);

    const freeze = useContext(hashRouteFreezeContext);
    const [previousRouteResult, setPreviousRouteResult] = useState(routeResult);
    if (!freeze &&
        !_.isEqual(previousRouteResult, routeResult)) {
        setPreviousRouteResult(routeResult);
    }

    return freeze
        ? previousRouteResult
        : routeResult;
}

function useUrlRoute(templatePath: string): RouteResult {
    return useMemo(
        () =>
            getRouteResult(
                RouteType.Url,
                templatePath),
        [browserHistory.location.pathname]);
}

export function getRouteResult(type: RouteType, templatePath: string) {
    const routePathParts =
        type === RouteType.Url
            ? window.location.pathname.split("/")
            : _.split(_.split(window.location.hash.substring(1), "?")[0], "/");

    let locationPathPartIndex = -1;
    const templatePathParts = templatePath.split("/");
    const routeResult = { match: true } as RouteResult;
    for (const templatePathPart of templatePathParts) {
        locationPathPartIndex++;

        if (templatePathPart.startsWith("{")) {
            const parameterName = templatePathPart.substring(1, templatePathPart.length - 1);
            if (!_.isEmpty(routePathParts[locationPathPartIndex])) {
                routeResult[parameterName] = decodeURIComponent(routePathParts[locationPathPartIndex]);
            } else if (routePathParts.length === 1 && routePathParts[0] === "") {
                routeResult.match = false;
            }
        } else if (templatePathPart !== routePathParts[locationPathPartIndex]) {
            routeResult.match = false;
        }
    }

    locationPathPartIndex++;
    if (locationPathPartIndex < routePathParts.length - 1) {
        routeResult.additionalParts = routePathParts.slice(locationPathPartIndex);
    }

    return routeResult;
}

export function setHashRoute(
    templatePath: string,
    parameterMap?: Dictionary<string>,
    options?: SetRouteOptions) {
    options =
        _.merge(
            {
                appendBrowserHistory: true,
                preserveSearchString: false
            },
            options);

    let routePath = buildRoute(templatePath, parameterMap);
    if (_.includes(routePath, "#")) {
        routePath = _.last(_.split(routePath, "#")) || "";
    }

    const search =
        !options.appendBrowserHistory ||
        options.preserveSearchString
            ? browserHashHistory.location.search
            : "";

    if (options.appendBrowserHistory) {
        browserHashHistory.push({
            pathname: routePath,
            search
        });
    } else {
        browserHashHistory.replace({
            pathname: routePath,
            search
        });
    }
}

export function setUrlRoute(
    templatePath: string,
    parameterMap?: Dictionary<string>,
    options?: SetRouteOptions) {
    options =
        _.merge(
            {
                appendBrowserHistory: true,
                preserveSearchString: false
            },
            options);

    let routePath = buildRoute(templatePath, parameterMap);
    if (_.startsWith(routePath, "#")) {
        routePath = `${window.location.pathname}${window.location.search}${routePath}`;
    } else {
        routePath =
            options.preserveSearchString || !options.appendBrowserHistory
                ? `${routePath}${browserHistory.location.search}`
                : routePath;
    }

    if (options.appendBrowserHistory) {
        browserHistory.push(routePath);
    } else {
        browserHistory.replace(routePath);
    }
}

export function useSetRoute() {
    const { type } = useRouteContext();
    return useMemo(
        () => {
            switch (type) {
                case RouteType.Hash:
                    return setHashRoute;
                case RouteType.Url:
                    return setUrlRoute;
                default:
                    throw new UnexpectedError("type", type);
            }
        },
        [type]);
}

type SetRouteOptions = {
    appendBrowserHistory?: boolean;
    preserveSearchString?: boolean;
};

export function buildRoute(
    templatePath: string,
    parameterMap?: Dictionary<string>) {
    _.each(
        parameterMap ?? {},
        (parameterValue, parameterName) => templatePath = templatePath.replace(`{${parameterName}}`, parameterValue));
    return templatePath;
}