import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, InlineItems, optionalTableCell, useFormatRelativeTime, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, EntitiesCell, Entity, entityModelStore, InfoCard, LicensingHelper, UserHelper } from "../../../../../../../../../../../../common";
import { VulnerabilitiesChartCell, WorkloadAnalysisScanStatusCell } from "../../../../../../../../../WorkloadAnalysis";
import { PriorityScanActionCell } from "../../../../../../../../../WorkloadAnalysis/components/PriorityScanActionCell";

type VirtualMachinesInfoCardProps = {
    virtualMachineScaleSetModel: Contract.AzureComputeVirtualMachineScaleSetModel;
};

export function VirtualMachinesInfoCard({ virtualMachineScaleSetModel }: VirtualMachinesInfoCardProps) {
    const formatRelativeTime = useFormatRelativeTime();
    const virtualMachineModels = entityModelStore.useGet(virtualMachineScaleSetModel.virtualMachineIds) as Contract.AzureComputeVirtualMachineScaleSetVirtualMachineModel[];
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureComputeVirtualMachineScaleSetDefinition.virtualMachinesInfoCard",
            () => ({
                columns: {
                    networkInterfaces: "Network Interfaces",
                    privateIpAddresses: "Private IP Addresses",
                    publicIpAddresses: "Public IP Addresses",
                    scanTime: "Scan Time",
                    status: "Status",
                    virtualMachine: "Virtual Machine",
                    vulnerabilities: "Vulnerabilities",
                    workloadAnalysisStatus: "Scan Status"
                },
                empty: "No Instances",
                title: "Instances"
            }));
    return (
        <InfoCard title={localization.title()}>
            <DataTable
                emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                fetchItems={() => virtualMachineModels}
                getItemId={(item: Contract.AzureComputeVirtualMachineScaleSetVirtualMachineModel) => item.id}
                sortOptions={{ enabled: false }}
                variant="card">
                <DataTableColumn
                    id={VirtualMachinesInfoCardTableColumnId.VirtualMachine}
                    key={VirtualMachinesInfoCardTableColumnId.VirtualMachine}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AzureComputeVirtualMachineScaleSetVirtualMachineModel>) =>
                            <Entity
                                entityIdOrModel={item}
                                variant="iconTextTenant"/>}
                    title={localization.columns.virtualMachine()}/>
                <DataTableColumn
                    id={VirtualMachinesInfoCardTableColumnId.Status}
                    itemProperty={(item: Contract.AzureComputeVirtualMachineScaleSetVirtualMachineModel) => _.as<Contract.IAzureComputeVirtualMachine>(item.entity).status}
                    key={VirtualMachinesInfoCardTableColumnId.Status}
                    title={localization.columns.status()}/>
                <DataTableColumn
                    id={VirtualMachinesInfoCardTableColumnId.NetworkInterfaces}
                    key={VirtualMachinesInfoCardTableColumnId.NetworkInterfaces}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AzureComputeVirtualMachineScaleSetVirtualMachineModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={_.as<Contract.IAzureComputeVirtualMachine>(item.entity).networkInterfaceIds}
                                entityTypeName={Contract.TypeNames.AzureComputeVirtualMachineScaleSetVirtualMachineNetworkInterface}
                                entityVariant="iconTextTenant"/>}
                    title={localization.columns.networkInterfaces()}/>
                <DataTableColumn
                    id={VirtualMachinesInfoCardTableColumnId.PrivateIpAddresses}
                    key={VirtualMachinesInfoCardTableColumnId.PrivateIpAddresses}
                    render={
                        optionalTableCell<Contract.AzureComputeVirtualMachineScaleSetVirtualMachineModel>(
                            item =>
                                _.isEmpty(_.as<Contract.IAzureComputeVirtualMachine>(item.entity).privateIpAddresses)
                                    ? undefined
                                    : <InlineItems
                                        items={_.as<Contract.IAzureComputeVirtualMachine>(item.entity).privateIpAddresses}
                                        variant="itemPlusItemCount"/>)}
                    title={localization.columns.privateIpAddresses()}/>
                <DataTableColumn
                    id={VirtualMachinesInfoCardTableColumnId.PublicIpAddresses}
                    key={VirtualMachinesInfoCardTableColumnId.PublicIpAddresses}
                    render={
                        optionalTableCell<Contract.AzureComputeVirtualMachineScaleSetVirtualMachineModel>(
                            item => {
                                const publicIpAddressIds = _.as<Contract.IAzureComputeVirtualMachine>(item.entity).publicIpAddressIds;
                                if (_.isEmpty(publicIpAddressIds)) {
                                    return undefined;
                                }

                                const publicIpAddressModels = entityModelStore.useGet(publicIpAddressIds) as Contract.AzureComputeVirtualMachineScaleSetVirtualMachineModel[];
                                const publicIpAddresses =
                                    _(publicIpAddressModels).
                                        map(publicIpAddressModel => _.as<Contract.IAzurePublicIpAddress>(publicIpAddressModel.entity).ipAddress).
                                        filter().
                                        value();

                                return _.isEmpty(publicIpAddresses)
                                    ? undefined
                                    : <InlineItems
                                        items={publicIpAddresses}
                                        variant="itemPlusItemCount"/>;
                            })}
                    title={localization.columns.publicIpAddresses()}/>
                {LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp)
                    ? <DataTableColumn
                        cellMinWidth={240}
                        id={VirtualMachinesInfoCardTableColumnId.Vulnerabilities}
                        key={VirtualMachinesInfoCardTableColumnId.Vulnerabilities}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.AzureComputeVirtualMachineScaleSetVirtualMachineModel>) =>
                                <VulnerabilitiesChartCell
                                    vulnerabilities={item.vulnerabilities}
                                    workloadResourceEntityId={item.id}/>}
                        title={localization.columns.vulnerabilities()}/>
                    : undefined},
                {LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp)
                    ? <DataTableColumn
                        id={VirtualMachinesInfoCardTableColumnId.WorkloadAnalysisStatus}
                        key={VirtualMachinesInfoCardTableColumnId.WorkloadAnalysisStatus}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.AzureComputeVirtualMachineScaleSetVirtualMachineModel>) =>
                                <WorkloadAnalysisScanStatusCell
                                    azureKeyVaultId={item.diskEncryptionVaultIdReference}
                                    resourceType={Contract.WorkloadAnalysisWorkloadResourceType.VirtualMachine}
                                    workloadAnalysisError={item.workloadAnalysisError}
                                    workloadAnalysisStatus={item.workloadAnalysisStatus}/>}
                        title={localization.columns.workloadAnalysisStatus()}/>
                    : undefined},
                {LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp)
                    ? <DataTableColumn
                        id={VirtualMachinesInfoCardTableColumnId.ScanTime}
                        key={VirtualMachinesInfoCardTableColumnId.ScanTime}
                        render={
                            optionalTableCell<Contract.AzureComputeVirtualMachineScaleSetVirtualMachineModel>(
                                item =>
                                    _.isNil(item.scanTime)
                                        ? undefined
                                        : formatRelativeTime(item.scanTime))}
                        title={localization.columns.scanTime()}/>
                    : undefined},
                {LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp) &&
                    UserHelper.hasAnyScopePermissions(virtualMachineScaleSetModel.entity.scopeIds, Contract.IdentityPermission.SecurityWrite) &&
                    <DataTableColumn
                        id={VirtualMachinesInfoCardTableColumnId.Actions}
                        key={VirtualMachinesInfoCardTableColumnId.Actions}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.AzureComputeVirtualMachineScaleSetVirtualMachineModel>) =>
                                <PriorityScanActionCell virtualMachineId={item.id}/>}/>}
            </DataTable>
        </InfoCard>);
}

enum VirtualMachinesInfoCardTableColumnId {
    Actions = "actions",
    NetworkInterfaces = "NetworkInterfaces",
    PrivateIpAddresses = "PrivateIpAddresses",
    PublicIpAddresses = "PublicIpAddresses",
    ScanTime = "scanTime",
    Status = "status",
    VirtualMachine = "virtualMachine",
    Vulnerabilities = "vulnerabilities",
    WorkloadAnalysisStatus = "workloadAnalysisStatus"
}