import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { RiskTypeGroups } from "../../../../../../../../../../../../common";
import { GroupedDefinition } from "../../useDefinition";
import { useRiskCodeTenantDefinition, useRiskPolicyDefinition } from "./hooks";

export function useCodeDefinition(groupingType: RiskTypeGroups): GroupedDefinition {
    const useDefinition =
        useMemo(
            () => {
                switch (groupingType) {
                    case RiskTypeGroups.RiskPolicyType:
                        return useRiskPolicyDefinition;
                    case RiskTypeGroups.Repository:
                        return useRiskCodeTenantDefinition;
                    default:
                        throw new UnexpectedError("groupingType", groupingType);
                }
            },
            [groupingType]);

    return useDefinition();
}