import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useGetAwsEc2ImageRiskContext } from "../contexts";

export function useAwsEc2ImagePublicAccessExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsEc2ImagePublicAccessExistsRisk;
    const imageModel = entityModelStore.useGet(risk.entityId) as Contract.AwsEc2ImageModel;
    const image = imageModel.entity as Contract.AwsEc2Image;

    const getAwsEc2ImageRiskContext = useGetAwsEc2ImageRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2ImagePublicAccessExistsRiskDefinition",
            () => ({
                description: "{{image}} is publicly accessible via image permissions",
                sections: {
                    resolution: {
                        step1: "Before changing the permissions, verify that identities can access the image without public access level",
                        step2: "Change the access to **Private** and optionally add explicit AWS account numbers to share the image with",
                        step3: "Click on **Save changes** to confirm"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description(
            {
                image:
                    <Entity
                        entityIdOrModel={imageModel}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="typeText"/>
            }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Ec2,
                AwsConsoleUrlBuilder.getEc2ImageEditPermissionsUrl(image)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const imageRiskContext = getAwsEc2ImageRiskContext(imageModel);
            return [
                imageRiskContext.generalInformation,
                imageRiskContext.sensitive,
                imageRiskContext.instances,
                imageRiskContext.sensitiveInstances,
                imageRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}