import { DataTableRowProps, DataTableSelectionCell, Sx } from "@infrastructure";
import { Box, TableCell, TableRow, useTheme } from "@mui/material";
import _ from "lodash";
import React, { memo } from "react";

const DataTableRowPlaceholderMemo = memo(DataTableRowPlaceholder);
export { DataTableRowPlaceholderMemo as DataTableRowPlaceholder };

type DataTableRowPlaceholderProps = Pick<DataTableRowProps, "columns" | "itemId" | "itemSelectionEnabled" | "selectedVisibleColumnIds" | "stickyColumnId">;

function DataTableRowPlaceholder({ columns, itemId, itemSelectionEnabled, selectedVisibleColumnIds, stickyColumnId }: DataTableRowPlaceholderProps) {
    const theme = useTheme();
    return (
        <TableRow id={itemId}>
            {itemSelectionEnabled &&
                <TableCell
                    sx={{
                        background: theme.palette.background.paper,
                        cursor: "default",
                        left: 0,
                        paddingLeft: theme.spacing(2),
                        position: "sticky",
                        width: "40px"
                    }}>
                    <DataTableSelectionCell
                        disabled={true}
                        itemId={itemId}/>
                </TableCell>}
            {_.map(
                columns,
                column =>
                    <TableCell
                        key={column.props.id}
                        sx={
                            Sx.combine(
                                {
                                    "&:first-of-type": {
                                        paddingLeft:
                                            !itemSelectionEnabled
                                                ? theme.spacing(3)
                                                : undefined
                                    },
                                    background: theme.palette.background.paper,
                                    display:
                                        column.props.selectorOptions?.disabled ||
                                        _.includes(
                                            selectedVisibleColumnIds,
                                            column.props.id)
                                            ? undefined
                                            : "none",
                                    overflow: "hidden",
                                    ...(stickyColumnId && stickyColumnId === column.props.id && {
                                        left:
                                            itemSelectionEnabled
                                                ? 58
                                                : 0,
                                        position: "sticky"
                                    })
                                },
                                column.props.cellSx)}>
                        <Box
                            sx={{
                                backgroundColor: theme.palette.table.placeholder,
                                borderRadius: theme.spacing(1.5),
                                height: theme.spacing(1.5),
                                width: "70%"
                            }}/>
                    </TableCell>)}
        </TableRow>);
}