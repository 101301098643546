import _, { Dictionary } from "lodash";
import React from "react";
import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, DeferredFilter, Optional, optionalTableCell, PagedValuesFilter, TimeFormatter, TimeRangeFilter, useLocalization, ValueFilter, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, FeatureHelper, InlineResourceTags, ItemSelectionHelper, PagedEntityFilter, PagedEntityResourceTagFilter, ResourceTagHelper, TimeRangeHelper, useActivityTranslator, useEntitiesExportOptions, useVendorNameTranslator } from "../../../../../../../../../../common";
import { Vendor } from "../../../../../../../../../../common/components/Vendor";
import { useAadDirectoryServicePrincipalTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { useAadCommonDirectoryPrincipalDefinition } from "./useAadCommonDirectoryPrincipalDefinition";

export function useAadDirectoryServicePrincipalDefinition(definitionData: DefinitionData) {
    const identityModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.AadDirectoryIdentityModelFilters>;
    const commonDirectoryPrincipalDefinition = useAadCommonDirectoryPrincipalDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const activityTranslator = useActivityTranslator();
    const directoryServicePrincipalTypeTranslator = useAadDirectoryServicePrincipalTypeTranslator();
    const vendorNameTranslator = useVendorNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aad.useAadDirectoryServicePrincipalDefinition",
            () => ({
                columns: {
                    activityTime: {
                        helpText: "The last time a management write activity was seen in Azure for this identity, since read-only activity isn't logged in Azure.",
                        title: "Azure Activity Time"
                    },
                    assignedPrincipalIds: "Users And Groups",
                    enabled: {
                        false: "Disabled",
                        title: "Status",
                        true: "Enabled"
                    },
                    homepageRawUrl: "Homepage URL",
                    signInTime: {
                        empty: "Not seen",
                        title: "Sign-in Time"
                    },
                    tags: "Tags",
                    type: "Application Type",
                    vendor: "3*superscript*rd** Party",
                    vendors: "Vendor"
                }
            }));

    function translateEnabled(servicePrincipalModel: Contract.EntityModel) {
        return (servicePrincipalModel.entity as Contract.AadDirectoryServicePrincipal).enabled
            ? localization.columns.enabled.true()
            : localization.columns.enabled.false();
    }

    return new EntityTableDefinition(
        _.filter([
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <ValueFilter
                                items={[
                                    {
                                        title: localization.columns.vendor(),
                                        value: true
                                    }
                                ]}
                                placeholder={localization.columns.vendor()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AadDirectoryServicePrincipalVendor}
                key={Contract.EntityModelProperty.AadDirectoryServicePrincipalVendor}
                title={localization.columns.vendor()}/>,
            commonDirectoryPrincipalDefinition.columns.tenantColumn,
            commonDirectoryPrincipalDefinition.columns.creationTimeColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AadDirectoryServicePrincipalModel) => ({
                            [localization.columns.enabled.title()]: translateEnabled(item)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.enabled.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.enabled.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.enabled.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AadDirectoryIdentityEnabled}
                itemProperty={translateEnabled}
                key={Contract.EntityModelProperty.AadDirectoryIdentityEnabled}
                title={localization.columns.enabled.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AadDirectoryServicePrincipalModel) => ({
                            [localization.columns.type()]: directoryServicePrincipalTypeTranslator((item.entity as Contract.AadDirectoryServicePrincipal).type)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.type()}>
                                {_(Contract.AadDirectoryServicePrincipalType).
                                    values().
                                    sortBy(type => type === Contract.AadDirectoryServicePrincipalType.Other).
                                    map(
                                        type =>
                                            <ValuesFilterItem
                                                key={type}
                                                title={directoryServicePrincipalTypeTranslator(type)}
                                                value={type}/>).
                                    value()}
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AadDirectoryServicePrincipalType}
                itemProperty={(item: Contract.AadDirectoryServicePrincipalModel) => directoryServicePrincipalTypeTranslator((item.entity as Contract.AadDirectoryServicePrincipal).type)}
                key={Contract.EntityModelProperty.AadDirectoryServicePrincipalType}
                title={localization.columns.type()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AadDirectoryServicePrincipalModel) => {
                            const servicePrincipalProfile = item.entityProfile as Contract.AadDirectoryServicePrincipalProfile;
                            return {
                                [localization.columns.signInTime.title()]:
                                    (item.entity as Contract.AadDirectoryServicePrincipal).signInEventsDisabled
                                        ? ""
                                        : _.isNil(servicePrincipalProfile?.signInTime)
                                            ? localization.columns.signInTime.empty()
                                            : TimeFormatter.iso8601String(servicePrincipalProfile.signInTime)
                            };
                        }
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={identityModelFiltersPromise}
                                title={localization.columns.signInTime.title()}>
                                {filters =>
                                    <TimeRangeFilter
                                        emptyValue={filters.signInTimeRange.emptyValue}
                                        placeholder={localization.columns.signInTime.title()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.signInTimeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AadDirectoryIdentitySignInTime}
                key={Contract.EntityModelProperty.AadDirectoryIdentitySignInTime}
                render={
                    optionalTableCell<Contract.AadDirectoryServicePrincipalModel>(
                        item => {
                            const servicePrincipalProfile = item.entityProfile as Contract.AadDirectoryServicePrincipalProfile;
                            return (item.entity as Contract.AadDirectoryServicePrincipal).signInEventsDisabled
                                ? undefined
                                : _.isNil(servicePrincipalProfile?.signInTime)
                                    ? localization.columns.signInTime.empty()
                                    : TimeFormatter.monthDayAndYear(servicePrincipalProfile.signInTime);
                        })
                }
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.signInTime.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AadDirectoryServicePrincipalModel) => ({
                            [localization.columns.activityTime.title()]: activityTranslator(item.access?.activity) ?? ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={identityModelFiltersPromise}
                                title={localization.columns.activityTime.title()}>
                                {filters =>
                                    <TimeRangeFilter
                                        emptyValue={filters.activityTimeRange.emptyValue}
                                        placeholder={localization.columns.activityTime.title()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.activityTimeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AadDirectoryIdentityActivityTime}
                key={Contract.EntityModelProperty.AadDirectoryIdentityActivityTime}
                message={localization.columns.activityTime.helpText()}
                messageLevel="info"
                render={
                    optionalTableCell<Contract.AadDirectoryServicePrincipalModel>(
                        servicePrincipalModel =>
                            activityTranslator(servicePrincipalModel.access!.activity))}
                title={localization.columns.activityTime.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AadDirectoryServicePrincipalModel) => ({
                            [localization.columns.homepageRawUrl()]: (item.entity as Contract.AadDirectoryServicePrincipal).homepageRawUrl ?? ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AadDirectoryServicePrincipalHomepageRawUrl)}
                                placeholder={localization.columns.homepageRawUrl()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AadDirectoryServicePrincipalHomepageRawUrl}
                key={Contract.EntityModelProperty.AadDirectoryServicePrincipalHomepageRawUrl}
                render={optionalTableCell<Contract.AadDirectoryServicePrincipalModel>(item => (item.entity as Contract.AadDirectoryServicePrincipal).homepageRawUrl)}
                title={localization.columns.homepageRawUrl()}/>,
            commonDirectoryPrincipalDefinition.columns.azureRoleAssignmentResourcesColumn,
            commonDirectoryPrincipalDefinition.columns.azureRoleDefinitionsColumn,
            commonDirectoryPrincipalDefinition.columns.containedAzureRoleAssignmentResourcesColumn,
            commonDirectoryPrincipalDefinition.columns.directoryRoleAssignmentResourcesColumn,
            commonDirectoryPrincipalDefinition.columns.directoryRoleDefinitionsColumn,
            // Migration: 1.161 | Delete FeatureHelper usage, keep true flow
            FeatureHelper.enabled(Contract.FeatureName.GenerateCustomerEntityModelsCompleted)
                ? <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AadDirectoryServicePrincipalModel) => ({
                                [localization.columns.vendors()]:
                                    item.typeName === Contract.TypeNames.AadDirectoryApplicationServicePrincipalModel &&
                                    !_.isEmpty((item.entity as Contract.AadDirectoryApplicationServicePrincipal).vendors)
                                        ? vendorNameTranslator((item.entity as Contract.AadDirectoryApplicationServicePrincipal).vendors[0])
                                        : ""
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <DeferredFilter
                                    promiseOrGetPromise={identityModelFiltersPromise}
                                    title={localization.columns.vendors()}>
                                    {filters =>
                                        <ValuesFilter
                                            emptyValueOptions={{ enabled: filters.vendorItems.emptyValue }}
                                            placeholder={localization.columns.vendors()}>
                                            {_.map(
                                                filters.vendorItems.items,
                                                vendor =>
                                                    <ValuesFilterItem
                                                        key={vendor}
                                                        title={vendorNameTranslator(vendor)}
                                                        value={vendor}/>)}
                                        </ValuesFilter>}
                                </DeferredFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AadDirectoryApplicationServicePrincipalVendors}
                    key={Contract.EntityModelProperty.AadDirectoryApplicationServicePrincipalVendors}
                    render={
                        optionalTableCell<Contract.AadDirectoryApplicationServicePrincipalModel>(
                            (item: Contract.AadDirectoryApplicationServicePrincipalModel) =>
                                _.isEmpty((item.entity as Contract.AadDirectoryApplicationServicePrincipal).vendors)
                                    ? undefined
                                    : <Vendor vendorType={(item.entity as Contract.AadDirectoryApplicationServicePrincipal).vendors[0]}/>)}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.vendors()}/>
                : undefined,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AadDirectoryServicePrincipalModel>(
                        Contract.TypeNames.AadDirectoryPrincipal,
                        item => _.keys(item.assignedPrincipalIdToApplicationRoleDefinitionRawIdsMap),
                        localization.columns.assignedPrincipalIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AadDirectoryServicePrincipalAssignedPrincipals)}
                                placeholder={localization.columns.assignedPrincipalIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AadDirectoryServicePrincipalAssignedPrincipals}
                key={Contract.EntityModelProperty.AadDirectoryServicePrincipalAssignedPrincipals}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AadDirectoryServicePrincipalModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={_.keys(item.assignedPrincipalIdToApplicationRoleDefinitionRawIdsMap)}
                            entityTypeName={Contract.TypeNames.AadDirectoryPrincipal}
                            entityVariant="iconText"/>}
                title={localization.columns.assignedPrincipalIds()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AadDirectoryServicePrincipalModel) => ({
                            [localization.columns.tags()]:
                                _((item.entity as Contract.AadDirectoryServicePrincipal).tags).
                                    map(ResourceTagHelper.getDisplayName).
                                    join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityResourceTagFilter
                                entityTypeName={definitionData.entityTypeEntitiesViewName}
                                placeholder={localization.columns.tags()}/>
                    }
                }}
                id={Contract.EntityModelProperty.ResourceTags}
                key={Contract.EntityModelProperty.ResourceTags}
                render={
                    optionalTableCell<Contract.AadDirectoryServicePrincipalModel>(
                        (item: Contract.AadDirectoryServicePrincipalModel) =>
                            _.isEmpty((item.entity as Contract.AadDirectoryServicePrincipal).tags)
                                ? undefined
                                : <InlineResourceTags tags={(item.entity as Contract.AadDirectoryServicePrincipal).tags}/>)}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.tags()}/>,
            commonDirectoryPrincipalDefinition.columns.attributesColumn,
            commonDirectoryPrincipalDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonDirectoryPrincipalDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonDirectoryPrincipalDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAadDirectoryServicePrincipalRequest(
                new Contract.EntityControllerGetEntityModelPageAadDirectoryServicePrincipalRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.AadDirectoryEntityCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryPrincipalAzureRoleDefinitions]),
                    filterMap[Contract.EntityModelProperty.AadDirectoryPrincipalContainedAzureRoleAssignmentResources] as Optional<boolean>,
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryPrincipalDirectoryRoleDefinitions]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.AadDirectoryIdentityActivityTime]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryIdentityEnabled]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.AadDirectoryIdentitySignInTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryServicePrincipalAssignedPrincipals]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryServicePrincipalHomepageRawUrl]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryServicePrincipalType]),
                    filterMap[Contract.EntityModelProperty.AadDirectoryServicePrincipalVendor] as Optional<boolean>,
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryApplicationServicePrincipalVendors])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}