import _, { Dictionary } from "lodash";
import React, { ReactNode, Ref, useMemo, useRef, useState } from "react";
import { DataTable, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableFetchItemsResult, DataTableSort, DataTableSortDirection, DataTableSortType, DeferredFilter, EmptyMessageText, EnumValuesFilter, InlineItems, makeContextProvider, NoneIcon, Optional, optionalTableCell, StorageItem, TimeRangeFilter, useActions, useChangeEffect, useExecuteOperation, useLocalization, useOperation, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { usePrincipalsContext } from "../../../..";
import { AccessController, ActionRiskCategoryFilter, Contract, CustomerConsoleAppUrlHelper, ElasticsearchItemPageHelper, Entity, EntityAttributeFilter, EntityController, entityModelStore, EntityTypeFilter, EntityTypeMetadataModelHelper, InlineEntities, PagedEntityFilter, PagedEntityFilterEntityIdPage, RiskController, RiskHelper, ScopeTenantFilter, Severity, SeverityFilter, StorageHelper, TenantCell, tenantModelStore, TimeRangeHelper, useActionRiskCategoryTranslator, useActivityTranslator, useEntitiesExportOptions, useGetEntityAttributesCsvItem, usePrincipalRiskCategoryTranslator, useRiskPolicyTitleTranslator, useSeverityTranslator, useTenantTypeTranslator, useTheme, useVendorNameTranslator } from "../../../../../../../../../../common";
import { Vendor } from "../../../../../../../../../../common/components/Vendor";
import { useIdentityPermissionsTranslator } from "../../../../../../../../hooks";
import { AccessView } from "../../../../../../hooks";
import { ActionRiskCategoriesCell, ActionsCell, Entities, EntityAttributesCell, RisksCell } from "./components";

export class TableContext {
    constructor(
        public reloadPrincipalModels: () => Promise<void>,
        public riskResolutionEnabled: Optional<boolean>) {
    }
}

export const [useTableContext, , useTableContextProvider] = makeContextProvider<TableContext>();

type TableProps = {
    actionsRef?: Ref<Optional<TableActions>>;
    children?: (context: TableContext) => ReactNode;
    filtersVisibilityStorageItem?: StorageItem;
    getPrincipalIdToIdentityPermissionsMap?: (filterMap: Dictionary<any>) => Promise<Dictionary<Contract.IdentityPermission[]>>;
    getPrincipalModelPage: (filterMap: Dictionary<any>, limit: number, skip: number, sort: Contract.AccessControllerGetPrincipalModelPageRequestSort) => Promise<TableGetPrincipalModelPageResult>;
    principalRiskCategory?: Contract.PrincipalRiskCategory;
};

export type TableActions = {
    getFiltersTime: () => Optional<string>;
    getSelectedPrincipalIds: () => string[];
    getSelectedPrincipalModel: () => Optional<Contract.IPrincipalModel>;
};

type TableGetPrincipalModelPageResult = {
    onApplyData?: () => void;
    principalIdToIdentityPermissionsMap?: Dictionary<Contract.IdentityPermission[]>;
    principalModelPage: Contract.ItemPage<Contract.EntityModel>;
    principalRiskTenantPermission?: boolean;
};

export function Table({ actionsRef, children, filtersVisibilityStorageItem, getPrincipalIdToIdentityPermissionsMap, getPrincipalModelPage, principalRiskCategory }: TableProps) {
    const { view } = usePrincipalsContext();

    const [principalFiltersPromise] =
        useOperation(
            [Table, principalRiskCategory],
            async () => {
                const { principalModelFilters } =
                    await AccessController.getPrincipalModelFilters(
                        view === AccessView.PrincipalExcessivePermissions
                            ? new Contract.AccessControllerGetPrincipalModelExcessivePermissionFiltersRequest(principalRiskCategory!)
                            : new Contract.AccessControllerGetPrincipalModelPermissionFiltersRequest());
                return principalModelFilters;
            });

    const [riskResolutionEnabled] =
        useExecuteOperation(
            [Table, principalRiskCategory],
            async () => {
                const { riskResolutionEnabled } =
                    view === AccessView.PrincipalExcessivePermissions
                        ? await AccessController.getPrincipalExcessivePermissionRiskResolutionEnabled(new Contract.AccessControllerGetPrincipalExcessivePermissionRiskResolutionEnabledRequest(principalRiskCategory!))
                        : { riskResolutionEnabled: false };
                return riskResolutionEnabled;
            });

    const tenantModels = tenantModelStore.useGetAll();
    const tenantModelMap =
        useMemo(
            () =>
                _.keyBy(
                    tenantModels,
                    tenantModel => tenantModel.configuration.id),
            [tenantModels]);
    const azureTenantModels = tenantModelStore.useGetFilteredActiveCloudProviderTenants([Contract.TenantType.Azure]);
    const gcpTenantModels = tenantModelStore.useGetFilteredActiveCloudProviderTenants([Contract.TenantType.Gcp]);
    const entitiesExportOptions = useEntitiesExportOptions();
    const getAttributesCsvItem = useGetEntityAttributesCsvItem();

    const [principalRiskTenantPermission, setPrincipalRiskTenantPermission] = useState(false);
    const fetchPrincipalModels =
        async (filterMap: Dictionary<any>, sort: Optional<DataTableSort>, skip: number, limit: number) => {
            const { onApplyData, principalIdToIdentityPermissionsMap, principalModelPage, principalRiskTenantPermission } =
                await getPrincipalModelPage(
                    filterMap,
                    limit,
                    skip,
                    new Contract.AccessControllerGetPrincipalModelPageRequestSort(
                        sort?.direction === DataTableSortDirection.Ascending
                            ? Contract.SortDirection.Ascending
                            : Contract.SortDirection.Descending,
                        _.isNil(sort)
                            ? Contract.AccessControllerGetPrincipalModelPageRequestProperty.Severity
                            : sort.columnId as Contract.AccessControllerGetPrincipalModelPageRequestProperty));

            await entityModelStore.notify(principalModelPage.items);

            return new DataTableFetchItemsResult(
                { count: principalModelPage.count! },
                principalModelPage.items,
                !principalModelPage.hasMore,
                {
                    itemIdToPermissionsMap: principalIdToIdentityPermissionsMap,
                    onAppendData:
                        () => {
                            if (!_.isNil(principalRiskTenantPermission)) {
                                setPrincipalRiskTenantPermission(principalRiskTenantPermission);
                            }

                            onApplyData?.();
                        }
                });
        };

    const dataTableActionsRef = useRef<DataTableActions>();
    const [context, , ContextProvider] =
        useTableContextProvider(
            () =>
                new TableContext(
                    () => dataTableActionsRef.current!.reload(),
                    riskResolutionEnabled),
            [riskResolutionEnabled]);

    useActions(
        actionsRef,
        {
            getFiltersTime:
                () => dataTableActionsRef.current?.getFiltersTime(),
            getSelectedPrincipalIds:
                () => dataTableActionsRef.current!.getSelectedItemIds(),
            getSelectedPrincipalModel:
                () => {
                    const selectionItemIds = dataTableActionsRef.current!.getSelectedItemIds();
                    if (selectionItemIds.length === 1) {
                        const loadedItems = dataTableActionsRef.current!.getLoadedItems();
                        return _.find(
                            loadedItems,
                            loadedItem => (loadedItem as Contract.IPrincipalModel).id === selectionItemIds[0]);
                    } else {
                        return undefined;
                    }
                }
        });

    useChangeEffect(
        () => {
            dataTableActionsRef.current!.reset();
        },
        [principalFiltersPromise]);

    const actionRiskCategoryTranslator = useActionRiskCategoryTranslator();
    const activityTranslator = useActivityTranslator();
    const identityPermissionsTranslator = useIdentityPermissionsTranslator();
    const principalRiskCategoryTranslator = usePrincipalRiskCategoryTranslator();
    const riskPolicyTitleTranslator = useRiskPolicyTitleTranslator();
    const severityTranslator = useSeverityTranslator();
    const tenantTypeTranslator = useTenantTypeTranslator();
    const vendorNameTranslator = useVendorNameTranslator();
    const localization =
        useLocalization(
            "views.customer.access.principals.hooks.useDefinition.table",
            () => ({
                columns: {
                    actionResourceIds: {
                        csvExportTitle: "Accessible Resources",
                        title: "Resources"
                    },
                    actionRiskCategories: {
                        csvExportTitle: "Permission Categories",
                        groupTitle: {
                            [AccessView.PrincipalExcessivePermissions]: "Excessive Permissions",
                            [AccessView.PrincipalPermissions]: "Permissions"
                        },
                        title: "Permission Categories"
                    },
                    actionServiceIds: {
                        csvExportTitle: "Accessible Services",
                        title: "Services"
                    },
                    activityTime: {
                        helpText: "The last time an activity was seen for this identity. This value is highly dependent on what each cloud provider logs for each account. For example, in Azure, read-only activity isn't logged, and in GCP, it depends on which logs are enabled.",
                        title: {
                            principalRiskCategory: {
                                [Contract.PrincipalRiskCategory.UnusedAwsPermissionSet]: "Usage Time",
                                [Contract.PrincipalRiskCategory.ExcessivePermissionAwsPermissionSet]: "Usage Time"
                            },
                            text: "Activity Time"
                        }
                    },
                    awsPermissionSetAccountIds: "Accounts",
                    awsPermissionSetPrincipalIds: "Principals",
                    entityAttributes: "Labels",
                    identity: "Identity",
                    identityAccount: "Identity Account",
                    inactive: {
                        false: "Active",
                        title: "Activity Status",
                        true: "Inactive"
                    },
                    memberPrincipalIds: "Members",
                    openRiskedEntityRiskCount: "Findings",
                    serviceIdentityOriginatorEntityIds: "Originators",
                    serviceIdentityOriginatorEntityTenantIds: "Originator Account",
                    serviceIdentityOriginatorEntityTypeNames: "Originator Type",
                    severity: {
                        [AccessView.PrincipalExcessivePermissions]: "IAM Finding Severity",
                        [AccessView.PrincipalPermissions]: "Permission Severity"
                    },
                    tenantType: "Platform",
                    ticketingServiceType: {
                        placeholder: "Ticket",
                        [Contract.TypeNames.TicketingServiceType]: {
                            [Contract.TicketingServiceType.Jira]: "Jira issue",
                            [Contract.TicketingServiceType.ServiceNow]: "ServiceNow incident"
                        }
                    },
                    typeName: "Type",
                    vendors: "Vendors"
                },
                csvExportTitle: {
                    [AccessView.PrincipalExcessivePermissions]: "ExcessivePermissions",
                    [AccessView.PrincipalPermissions]: "IdentityIntelligence"
                },
                empty: {
                    all: "Identities",
                    withFilter: "No Matching {{translatedPrincipalRiskCategory}}",
                    withoutFilter: "No {{translatedPrincipalRiskCategory}}"
                }
            }));

    async function getExportData(itemIdToExportColumnElementIdToExportDataMap: Dictionary<Dictionary<ColumnExportData>>): Promise<ExportData> {
        const relatedEntityIds =
            _(itemIdToExportColumnElementIdToExportDataMap).
                values().
                flatMap(
                    exportColumnElementIdToExportDataMap =>
                        _(exportColumnElementIdToExportDataMap).
                            values().
                            flatMap(exportData => exportData.relatedEntityIds).
                            value()).
                uniq().
                filter().
                value();
        const relatedRiskIds =
            _(itemIdToExportColumnElementIdToExportDataMap).
                values().
                flatMap(
                    exportColumnElementIdToExportDataMap =>
                        _(exportColumnElementIdToExportDataMap).
                            values().
                            flatMap(exportData => exportData.relatedRiskIds).
                            value()).
                uniq().
                filter().
                value();

        const relatedEntities = await entityModelStore.get(relatedEntityIds);
        const riskModels =
            _(await Promise.all(
                _(relatedRiskIds).
                    chunk(1000).
                    map(relatedRiskIdBatch => RiskController.getRiskModels(new Contract.RiskControllerGetRiskModelsRequest(relatedRiskIdBatch))).
                    value())).
                map(getRiskModelResponse => getRiskModelResponse.riskModels).
                flatMap().
                value();

        return {
            relatedEntityModelMap:
                _.keyBy(
                    relatedEntities,
                    relatedEntity => relatedEntity.id),
            relatedRiskModelMap:
                _.keyBy(
                    riskModels,
                    riskModel => riskModel.id)
        };
    }

    const getPrincipalFilterEntityItemPage =
        (property: Contract.AccessControllerGetPrincipalModelPageRequestProperty) =>
            async (searchText: Optional<string>, skip: number, data?: number) => {
                const { principalFilterItemPage: filterEntityItemPage } =
                    await AccessController.getPrincipalFilterItemPage(
                        view === AccessView.PrincipalExcessivePermissions
                            ? new Contract.AccessControllerGetPrincipalExcessivePermissionFilterItemPageRequest(
                                50,
                                property,
                                searchText,
                                skip,
                                principalRiskCategory!)
                            : new Contract.AccessControllerGetPrincipalPermissionFilterItemPageRequest(
                                50,
                                property,
                                searchText,
                                skip)) as Contract.AccessControllerGetPrincipalFilterItemPageResponse<string>;

                const filterEntityModelCount = filterEntityItemPage?.count ?? data ?? 0;

                return new PagedEntityFilterEntityIdPage(
                    filterEntityModelCount,
                    filterEntityItemPage?.emptyValue,
                    filterEntityItemPage.items,
                    () => filterEntityModelCount);
            };

    const getPrincipalModelPermissions =
        (principalModel: Contract.IPrincipalModel) =>
            view === AccessView.PrincipalExcessivePermissions
                ? principalModel.access!.permissions!.excessivePermissions!
                : principalModel.access!.permissions!.permissions;

    const translatedPrincipalRiskCategory =
        _.isNil(principalRiskCategory)
            ? localization.empty.all()
            : principalRiskCategoryTranslator(principalRiskCategory);

    const theme = useTheme();
    return (
        <ContextProvider>
            <DataTable
                actionsRef={dataTableActionsRef}
                cellMaxWidth={
                    view === AccessView.PrincipalPermissions
                        ? "small"
                        : undefined}
                columnOptions={{
                    orderOptions: {
                        enabled: true,
                        persistenceStorageItem: StorageHelper.customerAccessPrincipalsColumnOrder(view)
                    },
                    resizable: true,
                    selectorOptions: {
                        enabled: true,
                        persistenceStorageItem: StorageHelper.customerAccessPrincipalsColumnSelector(view)
                    }
                }}
                emptyMessageOptions={{
                    emptyMessageText:
                        new EmptyMessageText(
                            localization.empty.withoutFilter({ translatedPrincipalRiskCategory }),
                            localization.empty.withFilter({ translatedPrincipalRiskCategory }))
                }}
                exportOptions={{
                    fileNamePrefix: localization.csvExportTitle[view](),
                    getData: getExportData
                }}
                fetchItems={fetchPrincipalModels}
                filtersOptions={{
                    persist: {
                        visibilityStorageItem: filtersVisibilityStorageItem
                    }
                }}
                getItemId={(item: Contract.IPrincipalModel) => item.id}
                selectionOptions={{
                    getItemIdToPermissionsMap: getPrincipalIdToIdentityPermissionsMap,
                    permissionsTranslator: permissions => identityPermissionsTranslator(permissions as Contract.IdentityPermission[]),
                    visible: principalRiskTenantPermission
                }}
                virtualizationEnabled={true}>
                {children?.(context)}
                <DataTableColumn
                    cellSx={{ width: theme.spacing(10) }}
                    exportOptions={{
                        getItem:
                            (item: Contract.IPrincipalModel) => ({
                                [localization.columns.tenantType()]: tenantTypeTranslator(EntityTypeMetadataModelHelper.get(item.entity.typeName).tenantType)
                            })
                    }}
                    id={Contract.AccessControllerGetPrincipalModelPageRequestProperty.TenantType}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.IPrincipalModel>) =>
                            <TenantCell
                                sx={{
                                    color: theme.palette.text.primary,
                                    marginLeft: theme.spacing(0.75)
                                }}
                                tenantId={item.tenantId}
                                variant="icon"/>}
                    selectorOptions={{ disabled: true }}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.tenantType()}/>
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.IPrincipalModel) => ({
                                [localization.columns.identityAccount()]: tenantModelMap[item.tenantId]?.configuration.displayReference ?? ""
                            })
                    }}
                    id="identityAccount"/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.IPrincipalModel) => ({
                                [localization.columns.identity()]: item.entity.displayReference
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilter(
                                            async (itemNextPageSearchCursor, searchText, limit) => {
                                                const { entityIdPage } =
                                                    await EntityController.searchEntityIds(
                                                        view === AccessView.PrincipalExcessivePermissions
                                                            ? new Contract.EntityControllerSearchEntityIdsPrincipalExcessivePermissionRequest(
                                                                true,
                                                                limit,
                                                                itemNextPageSearchCursor,
                                                                searchText,
                                                                principalRiskCategory!)
                                                            : new Contract.EntityControllerSearchEntityIdsPrincipalPermissionRequest(
                                                                true,
                                                                limit,
                                                                itemNextPageSearchCursor,
                                                                searchText));
                                                return entityIdPage;
                                            })}
                                    placeholder={localization.columns.identity()}/>
                        }
                    }}
                    id={Contract.AccessControllerGetPrincipalModelPageRequestProperty.Id}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.EntityModel>) =>
                            <Entity
                                entityIdOrModel={item}
                                variant="iconTextTypeTenant"/>}
                    selectorOptions={{ disabled: true }}
                    title={localization.columns.identity()}/>
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <DeferredFilter
                                    promiseOrGetPromise={principalFiltersPromise}
                                    title={localization.columns.identityAccount()}>
                                    {filters =>
                                        <ScopeTenantFilter
                                            placeholder={localization.columns.identityAccount()}
                                            tenantIds={filters.tenantIdItems.items}/>}
                                </DeferredFilter>
                        }
                    }}
                    id={Contract.AccessControllerGetPrincipalModelPageRequestProperty.TenantId}
                    title={localization.columns.identityAccount()}/>
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <DeferredFilter
                                    promiseOrGetPromise={principalFiltersPromise}
                                    title={localization.columns.typeName()}>
                                    {filters =>
                                        <EntityTypeFilter
                                            emptyValue={filters.typeNameItems.emptyValue}
                                            entityTypeNames={filters.typeNameItems.items}
                                            placeholder={localization.columns.typeName()}/>}
                                </DeferredFilter>
                        }
                    }}
                    id={Contract.AccessControllerGetPrincipalModelPageRequestProperty.TypeNames}
                    title={localization.columns.typeName()}/>
                {(principalRiskCategory === Contract.PrincipalRiskCategory.ExcessivePermissionAwsPermissionSet ||
                    principalRiskCategory === Contract.PrincipalRiskCategory.UnusedAwsPermissionSet) && (
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <PagedEntityFilter
                                        getEntityIdPage={getPrincipalFilterEntityItemPage(Contract.AccessControllerGetPrincipalModelPageRequestProperty.AwsPermissionSetAccounts)}
                                        placeholder={localization.columns.awsPermissionSetAccountIds()}/>
                            }
                        }}
                        id={Contract.AccessControllerGetPrincipalModelPageRequestProperty.AwsPermissionSetAccounts}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.AwsSsoPermissionSetModel>) => {
                                const accountIds =
                                    _(item.principalIdToAccountIdsMap).
                                        values().
                                        flatMap().
                                        uniq().
                                        value();
                                return _.isEmpty(accountIds)
                                    ? <NoneIcon sx={{ fontSize: "18px" }}/>
                                    : <InlineEntities
                                        entityIdsOrModels={accountIds}
                                        entityTypeName={Contract.TypeNames.AwsOrganizationsAccount}
                                        entityVariant="iconText"
                                        variant="itemPlusItemCount"/>;
                            }}
                        sortOptions={{ enabled: false }}
                        title={localization.columns.awsPermissionSetAccountIds()}/>)}
                {(principalRiskCategory === Contract.PrincipalRiskCategory.ExcessivePermissionAwsPermissionSet ||
                    principalRiskCategory === Contract.PrincipalRiskCategory.UnusedAwsPermissionSet) && (
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <PagedEntityFilter
                                        getEntityIdPage={getPrincipalFilterEntityItemPage(Contract.AccessControllerGetPrincipalModelPageRequestProperty.AwsPermissionSetPrincipals)}
                                        placeholder={localization.columns.awsPermissionSetPrincipalIds()}/>
                            }
                        }}
                        id={Contract.AccessControllerGetPrincipalModelPageRequestProperty.AwsPermissionSetPrincipals}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.AwsSsoPermissionSetModel>) =>
                                _.isEmpty(item.principalIdToAccountIdsMap)
                                    ? <NoneIcon sx={{ fontSize: "18px" }}/>
                                    : <InlineEntities
                                        entityIdsOrModels={_.keys(item.principalIdToAccountIdsMap)}
                                        entityTypeName={Contract.TypeNames.AwsSsoPrincipal}
                                        entityVariant="iconText"
                                        variant="itemPlusItemCount"/>}
                        sortOptions={{ enabled: false }}
                        title={localization.columns.awsPermissionSetPrincipalIds()}/>)}
                {principalRiskCategory === Contract.PrincipalRiskCategory.ExcessivePermissionGroup && (
                    <DataTableColumn
                        id={Contract.AccessControllerGetPrincipalModelPageRequestProperty.GroupMemberIds}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.IGroupModel>) =>
                                _.isEmpty(item.memberPrincipalIds)
                                    ? <NoneIcon sx={{ fontSize: "18px" }}/>
                                    : <InlineEntities
                                        entityIdsOrModels={item.memberPrincipalIds}
                                        entityTypeName={Contract.TypeNames.Entity}
                                        entityVariant="iconText"
                                        variant="itemPlusItemCount"/>}
                        sortOptions={{ enabled: false }}
                        title={localization.columns.memberPrincipalIds()}/>)}
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: view === AccessView.PrincipalPermissions,
                            element:
                                <DeferredFilter
                                    promiseOrGetPromise={principalFiltersPromise}
                                    title={localization.columns.serviceIdentityOriginatorEntityTypeNames()}>
                                    {filters =>
                                        <EntityTypeFilter
                                            emptyValue={filters.serviceIdentityOriginatorEntityTypeNameItems.emptyValue}
                                            entityTypeNames={filters.serviceIdentityOriginatorEntityTypeNameItems.items}
                                            placeholder={localization.columns.serviceIdentityOriginatorEntityTypeNames()}/>}
                                </DeferredFilter>
                        }
                    }}
                    id={Contract.AccessControllerGetPrincipalModelPageRequestProperty.ServiceIdentityOriginatorEntityTypeNames}
                    title={localization.columns.serviceIdentityOriginatorEntityTypeNames()}/>
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.IPrincipalModel>(
                            Contract.TypeNames.Entity,
                            principalModel => _.as<Contract.ServiceIdentityModelAccess>(principalModel.access!).originatorEntityIds,
                            localization.columns.serviceIdentityOriginatorEntityIds(),
                            {
                                getData:
                                    entityIds => ({
                                        relatedEntityIds: entityIds
                                    })
                            })
                    }
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={getPrincipalFilterEntityItemPage(Contract.AccessControllerGetPrincipalModelPageRequestProperty.ServiceIdentityOriginatorEntities)}
                                    placeholder={localization.columns.serviceIdentityOriginatorEntityIds()}/>
                        }
                    }}
                    id={Contract.AccessControllerGetPrincipalModelPageRequestProperty.ServiceIdentityOriginatorEntities}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.IPrincipalModel>) =>
                            _.isEmpty(_.as<Contract.ServiceIdentityModelAccess>(item.access!).originatorEntityIds)
                                ? <NoneIcon sx={{ fontSize: "18px" }}/>
                                : <InlineEntities
                                    entityIdsOrModels={_.as<Contract.ServiceIdentityModelAccess>(item.access!).originatorEntityIds}
                                    entityTypeName={Contract.TypeNames.Entity}
                                    entityVariant="iconTextTypeTenant"
                                    variant="itemPlusItemCount"/>}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.serviceIdentityOriginatorEntityIds()}/>
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <DeferredFilter
                                    promiseOrGetPromise={principalFiltersPromise}
                                    title={localization.columns.serviceIdentityOriginatorEntityTenantIds()}>
                                    {filters =>
                                        <ScopeTenantFilter
                                            placeholder={localization.columns.serviceIdentityOriginatorEntityTenantIds()}
                                            tenantIds={filters.serviceIdentityOriginatorEntityTenantIdItems.items}/>}
                                </DeferredFilter>
                        }
                    }}
                    id={Contract.AccessControllerGetPrincipalModelPageRequestProperty.ServiceIdentityOriginatorEntityTenantId}
                    title={localization.columns.serviceIdentityOriginatorEntityTenantIds()}/>,
                {(view === AccessView.PrincipalPermissions ||
                    principalRiskCategory === Contract.PrincipalRiskCategory.ExcessivePermissionAwsPermissionSet ||
                    principalRiskCategory === Contract.PrincipalRiskCategory.ExcessivePermissionServiceIdentity ||
                    principalRiskCategory === Contract.PrincipalRiskCategory.ExcessivePermissionUser ||
                    principalRiskCategory === Contract.PrincipalRiskCategory.InactiveServiceIdentity ||
                    principalRiskCategory === Contract.PrincipalRiskCategory.InactiveUser ||
                    principalRiskCategory === Contract.PrincipalRiskCategory.UnusedAwsPermissionSet) && (
                    <DataTableColumn
                        cellSx={{ width: theme.spacing(15) }}
                        exportOptions={{
                            getItem:
                                (principalModel: Contract.IPrincipalModel) => ({
                                    [principalRiskCategory! in localization.columns.activityTime.title.principalRiskCategory
                                        ? localization.columns.activityTime.title.principalRiskCategory.translate(principalRiskCategory!)
                                        : localization.columns.activityTime.title.text()]:
                                    activityTranslator(principalModel.access!.activity) ?? ""
                                })
                        }}
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <DeferredFilter
                                        promiseOrGetPromise={principalFiltersPromise}
                                        title={
                                            principalRiskCategory! in localization.columns.activityTime.title.principalRiskCategory
                                                ? localization.columns.activityTime.title.principalRiskCategory.translate(principalRiskCategory!)
                                                : localization.columns.activityTime.title.text()}>
                                        {filters =>
                                            <TimeRangeFilter
                                                emptyValue={filters.activityTimeRange.emptyValue}
                                                placeholder={
                                                    principalRiskCategory! in localization.columns.activityTime.title.principalRiskCategory
                                                        ? localization.columns.activityTime.title.principalRiskCategory.translate(principalRiskCategory!)
                                                        : localization.columns.activityTime.title.text()}
                                                timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.activityTimeRange.timeRange)}/>}
                                    </DeferredFilter>
                            }
                        }}
                        id={Contract.AccessControllerGetPrincipalModelPageRequestProperty.ActivityTime}
                        message={
                            view === AccessView.PrincipalPermissions &&
                            (!_.isEmpty(_.concat(azureTenantModels, gcpTenantModels)))
                                ? localization.columns.activityTime.helpText()
                                : undefined}
                        messageLevel="info"
                        render={optionalTableCell<Contract.IPrincipalModel>(principalModel => activityTranslator(principalModel.access!.activity))}
                        sortOptions={{ type: DataTableSortType.Date }}
                        title={
                            principalRiskCategory! in localization.columns.activityTime.title.principalRiskCategory
                                ? localization.columns.activityTime.title.principalRiskCategory.translate(principalRiskCategory!)
                                : localization.columns.activityTime.title.text()}/>)}
                {(view === AccessView.PrincipalExcessivePermissions) && (
                    <DataTableColumn
                        exportOptions={{
                            getItem:
                                (principalModel: Contract.IPrincipalModel) => ({
                                    [localization.columns.inactive.title()]:
                                        principalModel.inactive
                                            ? localization.columns.inactive.true()
                                            : localization.columns.inactive.false()
                                })
                        }}
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <ValuesFilter
                                        placeholder={localization.columns.inactive.title()}
                                        sorted={false}>
                                        <ValuesFilterItem
                                            title={localization.columns.inactive.false()}
                                            value={false}/>
                                        <ValuesFilterItem
                                            title={localization.columns.inactive.true()}
                                            value={true}/>
                                    </ValuesFilter>
                            }
                        }}
                        id={Contract.AccessControllerGetPrincipalModelPageRequestProperty.Inactive}
                        itemProperty={
                            (principalModel: Contract.IPrincipalModel) =>
                                principalModel.inactive
                                    ? localization.columns.inactive.true()
                                    : localization.columns.inactive.false()}
                        sortOptions={{ enabled: false }}
                        title={localization.columns.inactive.title()}/>)}
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.EntityModel) => ({
                                [localization.columns.severity[view]()]:
                                    _.isNil(getPrincipalModelPermissions(_.as<Contract.IPrincipalModel>(item)).severity)
                                        ? ""
                                        : severityTranslator(getPrincipalModelPermissions(_.as<Contract.IPrincipalModel>(item)).severity!)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            default: view === AccessView.PrincipalPermissions,
                            element:
                                <SeverityFilter
                                    emptyValueOptions={{ enabled: false }}
                                    information={false}
                                    placeholder={localization.columns.severity[view]()}/>
                        }
                    }}
                    id={Contract.AccessControllerGetPrincipalModelPageRequestProperty.Severity}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.IPrincipalModel>) =>
                            <Severity severity={getPrincipalModelPermissions(item).severity}/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.severity[view]()}/>
                <DataTableColumn
                    cellMinWidth={220}
                    exportOptions={{
                        getItem:
                            (principalModel: Contract.IPrincipalModel) => ({
                                [localization.columns.actionRiskCategories.csvExportTitle()]:
                                    _(view === AccessView.PrincipalExcessivePermissions
                                        ? principalModel.access!.permissions!.excessivePermissions!.actionRiskCategoryToSeverityMap
                                        : principalModel.access!.permissions!.permissions.actionRiskCategoryToSeverityMap).
                                        keys().
                                        map(actionRiskCategory => actionRiskCategoryTranslator(actionRiskCategory as Contract.ActionRiskCategory).title).
                                        orderBy().
                                        value().
                                        join("\n")
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            default: view === AccessView.PrincipalPermissions,
                            element: <ActionRiskCategoryFilter placeholder={localization.columns.actionRiskCategories.title()}/>
                        }
                    }}
                    id={Contract.AccessControllerGetPrincipalModelPageRequestProperty.ActionRiskCategories}
                    render={ActionRiskCategoriesCell}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.actionRiskCategories.title()}/>
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.EntityModel) => ({
                                [localization.columns.actionServiceIds.csvExportTitle()]: getPrincipalModelPermissions(_.as<Contract.IPrincipalModel>(item)).actionServiceCount
                            })
                    }}
                    id={Contract.AccessControllerGetPrincipalModelPageRequestProperty.ActionServiceCount}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.IPrincipalModel>) =>
                            <Entities
                                count={getPrincipalModelPermissions(item).actionServiceCount}
                                principalId={item.id}
                                property={Contract.AccessControllerGetPrincipalModelPageRequestProperty.ActionServiceCount}
                                view={view}/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.actionServiceIds.title()}/>
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.EntityModel) => ({
                                [localization.columns.actionResourceIds.csvExportTitle()]: getPrincipalModelPermissions(_.as<Contract.IPrincipalModel>(item)).actionResourceCount
                            })
                    }}
                    id={Contract.AccessControllerGetPrincipalModelPageRequestProperty.ActionResourceCount}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.IPrincipalModel>) =>
                            <Entities
                                count={getPrincipalModelPermissions(item).actionResourceCount}
                                principalId={item.id}
                                property={Contract.AccessControllerGetPrincipalModelPageRequestProperty.ActionResourceCount}
                                view={view}/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.actionResourceIds.title()}/>
                {view === AccessView.PrincipalPermissions &&
                    <DataTableColumn
                        exportOptions={{
                            getItem:
                                (item: Contract.IVendorServiceIdentityModel) => ({
                                    [localization.columns.vendors()]:
                                        _.join(
                                            _.map(
                                                (item.entity as Contract.IEntity as Contract.IVendorServiceIdentity).vendors,
                                                vendor => vendorNameTranslator(vendor)),
                                            "\n")
                                })
                        }}
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <DeferredFilter
                                        promiseOrGetPromise={principalFiltersPromise}
                                        title={localization.columns.vendors()}>
                                        {filters =>
                                            <ValuesFilter
                                                emptyValueOptions={{ enabled: filters.vendorItems.emptyValue }}
                                                placeholder={localization.columns.vendors()}>
                                                {_.map(
                                                    filters.vendorItems.items,
                                                    vendor =>
                                                        <ValuesFilterItem
                                                            key={vendor}
                                                            title={vendorNameTranslator(vendor)}
                                                            value={vendor}/>)}
                                            </ValuesFilter>}
                                    </DeferredFilter>
                            }
                        }}
                        id={Contract.AccessControllerGetPrincipalModelPageRequestProperty.Vendors}
                        render={
                            ({ item }: DataTableColumnRenderProps<Contract.IVendorServiceIdentityModel>) =>
                                _.isEmpty((item.entity as unknown as Contract.IVendorServiceIdentity).vendors)
                                    ? <NoneIcon sx={{ fontSize: "18px" }}/>
                                    : <InlineItems
                                        items={
                                            _.map(
                                                (item.entity as unknown as Contract.IVendorServiceIdentity).vendors,
                                                vendor => Vendor({ vendorType: vendor }))}
                                        variant="itemPlusItemCount"/>}
                        sortOptions={{ type: DataTableSortType.Numeric }}
                        title={localization.columns.vendors()}/>}
                {view === AccessView.PrincipalPermissions &&
                    <DataTableColumn
                        exportOptions={{
                            getData:
                                principalModel => ({
                                    relatedRiskIds: principalModel.risks.risks.openRiskedEntityRiskIds
                                }),
                            getItem:
                                (principalModel: Contract.EntityModel, exportData: ExportData) => ({
                                    [localization.columns.openRiskedEntityRiskCount()]:
                                        _(principalModel.risks.risks.openRiskedEntityRiskIds).
                                            map(riskId => `${riskPolicyTitleTranslator(RiskHelper.getBuiltInRiskPolicyConfigurationTypeNameOrCustomRiskPolicyId(exportData.relatedRiskModelMap[riskId].risk))}, ${severityTranslator(exportData.relatedRiskModelMap[riskId].risk.severity)}`).
                                            join("\n")
                                })
                        }}
                        id={Contract.AccessControllerGetPrincipalModelPageRequestProperty.OpenRiskedEntityRiskCount}
                        render={RisksCell}
                        sortOptions={{ type: DataTableSortType.Numeric }}
                        title={localization.columns.openRiskedEntityRiskCount()}/>}
                <DataTableColumn
                    disableAction={true}
                    exportOptions={{
                        getItem:
                            (item: Contract.EntityModel) => getAttributesCsvItem(item.attributes.attributes)
                    }}
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <DeferredFilter
                                    promiseOrGetPromise={principalFiltersPromise}
                                    title={localization.columns.entityAttributes()}>
                                    {filters =>
                                        <EntityAttributeFilter
                                            emptyValue={filters.attributeValueItems.emptyValue}
                                            entityAttributeValues={filters.attributeValueItems.items}
                                            placeholder={localization.columns.entityAttributes()}/>}
                                </DeferredFilter>
                        }
                    }}
                    id={Contract.AccessControllerGetPrincipalModelPageRequestProperty.EntityAttributes}
                    render={EntityAttributesCell}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.entityAttributes()}/>
                <DataTableColumn
                    disableAction={true}
                    id="Actions"
                    orderable={false}
                    render={ActionsCell}
                    resizable={false}
                    selectorOptions={{ disabled: true }}/>
                {view === AccessView.PrincipalExcessivePermissions && (
                    <DataTableColumn
                        filterOptions={{
                            itemOrItems: {
                                element:
                                    <EnumValuesFilter
                                        emptyValueOptions={{ enabled: true }}
                                        enumType={Contract.TicketingServiceType}
                                        enumTypeTranslator={(ticketingServiceType: Contract.TicketingServiceType) => localization.columns.ticketingServiceType[Contract.TypeNames.TicketingServiceType][ticketingServiceType]()}
                                        placeholder={localization.columns.ticketingServiceType.placeholder()}/>
                            }
                        }}
                        id={Contract.TypeNames.TicketingServiceType}
                        title={localization.columns.ticketingServiceType.placeholder()}/>)}
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.EntityModel) => ({
                                URL: CustomerConsoleAppUrlHelper.getEntityProfileUrl(item)
                            })
                    }}
                    id="url"/>
            </DataTable>
        </ContextProvider>);
}

type ExportData = {
    relatedEntityModelMap: Dictionary<Contract.EntityModel>;
    relatedRiskModelMap: Dictionary<Contract.RiskModel>;
};

type ColumnExportData = {
    relatedEntityIds: string[];
    relatedRiskIds: string[];
};