import { Action0, Message, useLocalization } from "@infrastructure";
import { Function0 } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { ProfileActions as BaseProfileActions, RiskPolicyTypeMetadataHelper, RiskType, RiskTypeMetadataModelHelper } from "../../../../../common";
import { CustomerConsoleAppSideViewType, RiskModel } from "../../../../../common/controllers/types.generated";
import { useSideViewContext } from "../../SideView";
import { RiskDefinitionSectionCategory } from "../hooks/useDefinition/utilities";
import { Actions } from "./Actions";
import { useProfileDefinition } from "./Profile/hooks";
import { StarAction } from "./StarAction";

type ProfileActionsProps = {
    executeGetRiskModel: Function0<Promise<void>>;
    interactive?: boolean;
    riskModel: RiskModel;
    riskType: RiskType;
    triggerAuditEventsChange?: Action0;
};

export function ProfileActions({ executeGetRiskModel, interactive = true, riskModel, riskType, triggerAuditEventsChange }: ProfileActionsProps) {
    const { triggerDataChange } = useSideViewContext();
    const [updateError, setUpdateError] = useState(false);
    const { urlHelper } = useProfileDefinition(riskType, riskModel);
    const riskModels =
        useMemo(
            () => [riskModel],
            [riskModel]);

    const triggerChange =
        useCallback(
            async () => {
                await executeGetRiskModel();
                triggerDataChange(CustomerConsoleAppSideViewType.Risk);
            },
            [executeGetRiskModel]);

    const localization =
        useLocalization(
            "views.customer.risks.profileActions",
            () => ({
                error: "Failed to update starred"
            }));
    return (
        <BaseProfileActions
            getLink={
                () =>
                    urlHelper.getProfileUrl(
                        riskModel.id,
                        { category: RiskDefinitionSectionCategory.Overview })!}
            menu={
                interactive &&
                    RiskPolicyTypeMetadataHelper.isLicensed(RiskTypeMetadataModelHelper.get(riskModel.risk.typeName).policyConfigurationTypeName)
                    ? <Actions
                        reloadRiskModel={executeGetRiskModel}
                        riskModel={riskModel}
                        riskType={riskType}
                        triggerAuditEventsChange={triggerAuditEventsChange}
                        triggerChange={triggerChange}/>
                    : undefined}
            message={
                updateError
                    ? <Message
                        level="error"
                        sx={{ fontSize: "18px" }}
                        title={localization.error()}
                        variant="minimal"/>
                    : undefined}>
            <StarAction
                riskModels={riskModels}
                onError={() => setUpdateError(true)}
                onStart={() => setUpdateError(false)}
                onToggle={triggerChange}/>
        </BaseProfileActions>);
}