import { Optional, PagedValuesFilterSelection, ValuesFilterSelection } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { Contract, ResourceTagHelper } from "..";

export class ItemSelectionHelper {
    public static toBooleanFromValuesFilterSelection(
        valuesFilterSelection: Optional<ValuesFilterSelection<boolean>>): Optional<boolean> {
        return _.isNil(valuesFilterSelection) || valuesFilterSelection.values.length != 1
            ? undefined
            : valuesFilterSelection.values[0];
    }

    public static toItemSelectionFromPagedValuesFilterSelection(pagedValuesFilterSelection: Optional<PagedValuesFilterSelection<any>>): Optional<Contract.PagedItemSelection<any>> {
        return pagedValuesFilterSelection &&
            new Contract.PagedItemSelection<any>(
                pagedValuesFilterSelection.emptyValue,
                pagedValuesFilterSelection.values,
                pagedValuesFilterSelection.type === "include"
                    ? Contract.PagedItemSelectionType.Include
                    : Contract.PagedItemSelectionType.Exclude);
    }

    public static toItemSelectionFromPropertyFilterMap(propertyFilterMap: Dictionary<PagedValuesFilterSelection<any>>): Optional<Dictionary<Contract.PagedItemSelection<string>>> {
        return _.mapValues(
            propertyFilterMap,
            pagedValuesFilterSelection =>
                new Contract.PagedItemSelection<any>(
                    pagedValuesFilterSelection.emptyValue,
                    pagedValuesFilterSelection.values,
                    pagedValuesFilterSelection.type === "include"
                        ? Contract.PagedItemSelectionType.Include
                        : Contract.PagedItemSelectionType.Exclude));
    }

    public static toItemSelectionFromValues<TValue>(values: Optional<TValue[]>, emptyValue = false) {
        return values &&
            new Contract.ItemSelection<TValue>(
                emptyValue,
                values);
    }

    public static toItemSelectionFromValuesFilterSelection<TValue>(
        valuesFilterSelection: Optional<ValuesFilterSelection<TValue>>): Optional<Contract.ItemSelection<TValue>> {
        return valuesFilterSelection &&
            new Contract.ItemSelection<TValue>(
                valuesFilterSelection.emptyValue,
                valuesFilterSelection.values);
    }

    public static toPagedValuesFilterSelectionFromItemSelection(pagedValuesFilterSelection: Optional<Contract.PagedItemSelection<any>>): Optional<PagedValuesFilterSelection<any>> {
        return pagedValuesFilterSelection &&
            new PagedValuesFilterSelection<any>(
                pagedValuesFilterSelection.emptyValue,
                pagedValuesFilterSelection.type === Contract.PagedItemSelectionType.Include
                    ? "include"
                    : "exclude",
                pagedValuesFilterSelection.items);
    }

    public static toPagedValuesFilterSelectionFromResourceTagItemSelection(resourceTagItemSelection: Optional<Contract.PagedItemSelection<Contract.ResourceTag>>): Optional<PagedValuesFilterSelection<string>> {
        return resourceTagItemSelection &&
            new PagedValuesFilterSelection<string>(
                resourceTagItemSelection.emptyValue,
                resourceTagItemSelection.type === Contract.PagedItemSelectionType.Include
                    ? "include"
                    : "exclude",
                _.map(
                    resourceTagItemSelection.items,
                    item => JSON.stringify(item)));
    }

    public static toResourceTagItemSelectionFromPagedValuesFilterSelection(pagedValuesFilterSelection: Optional<PagedValuesFilterSelection<string>>): Optional<Contract.PagedItemSelection<Contract.ResourceTag>> {
        return pagedValuesFilterSelection &&
            new Contract.PagedItemSelection<Contract.ResourceTag>(
                pagedValuesFilterSelection.emptyValue,
                ResourceTagHelper.parse(pagedValuesFilterSelection.values) ?? [],
                pagedValuesFilterSelection.type === "include"
                    ? Contract.PagedItemSelectionType.Include
                    : Contract.PagedItemSelectionType.Exclude);
    }

    public static toValuesFilterSelectionFromBoolean(booleanValue: Optional<boolean>): Optional<ValuesFilterSelection<boolean>> {
        return _.isNil(booleanValue)
            ? undefined
            : new ValuesFilterSelection<boolean>(
                false,
                [booleanValue]);
    }

    public static toValuesFilterSelectionFromItemSelection<TValue>(
        itemSelection: Optional<Contract.ItemSelection<TValue>>): Optional<ValuesFilterSelection<TValue>> {
        return itemSelection &&
            new ValuesFilterSelection(
                itemSelection.emptyValue,
                itemSelection.items);
    }
}