import React from "react";
import { IconText } from "@infrastructure";
import { Contract, useVendorNameTranslator, VendorIcon } from "..";

type VendorProps = {
    vendorType: Contract.Vendor;
};

export function Vendor({ vendorType }: VendorProps) {
    const vendorNameTranslator = useVendorNameTranslator();
    return (
        <IconText
            icon={
                <VendorIcon
                    sx={{ fontSize: "20px" }}
                    vendorType={vendorType}/>
            }
            text={vendorNameTranslator(vendorType)}/>);
}