import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsAutoScalingAutoScalingGroupsRiskContext, useGetAwsAutoScalingLaunchConfigurationRiskContext, useGetAwsEc2InstancesRiskContext } from "../../../contexts";
import { useGetAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskContext } from "./useGetAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskContext";

export function useAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskLaunchConfigurationDefinition(riskModel: Contract.AwsEc2InstanceMetadataServiceTokenHopMaxCountRiskModel) {
    const risk = riskModel.risk as Contract.AwsEc2InstanceMetadataServiceTokenHopMaxCountRisk;
    const launchConfigurationModel = entityModelStore.useGet(risk.entityId) as Contract.AwsAutoScalingLaunchConfigurationModel;
    const launchConfiguration = (launchConfigurationModel.entity) as Contract.AwsAutoScalingLaunchConfiguration;

    const getAwsAutoScalingAutoScalingGroupsRiskContext = useGetAwsAutoScalingAutoScalingGroupsRiskContext();
    const getAwsAutoScalingLaunchConfigurationRiskContext = useGetAwsAutoScalingLaunchConfigurationRiskContext();
    const getAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskContext = useGetAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskContext();
    const getAwsEc2InstancesRiskContext = useGetAwsEc2InstancesRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskDefinition.hooks.useAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskLaunchConfigurationDefinition",
            () => ({
                description: "{{launchConfiguration}} metadata response hop limit is greater than {{maxTokenHopMaxCount}}",
                sections: {
                    resolution: {
                        step1: "Setting the hop limit to {{maxTokenHopMaxCount}} can restrict network communications beyond the local network, potentially affecting applications that rely on multiple hops. Carefully evaluate your specific networking requirements, and any potential impact before implementing this change.",
                        step2: "Select the launch configuration **{{launchConfigurationName}}** and choose **Actions**, **Copy launch configuration**",
                        step3: "In the **Additional Configuration** section, click on **Advanced Details**",
                        step4: "Under **Metadata response hop limit**, set the value to **{{maxTokenHopMaxCount}}**",
                        step5: "Click on the **Create launch configuration** button",
                        step6: "Update the **{{autoScalingGroups}}** to use the new launch configuration",
                        step7: "On the Launch Configuration page, select the old configuration and click **Actions, delete launch configuration**",
                        step8: "Wait until all instances are relaunched with the updated metadata hop limit"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            launchConfiguration:
                <Entity
                    entityIdOrModel={launchConfigurationModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            maxTokenHopMaxCount: risk.maxTokenHopMaxCount
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Ec2,
                AwsConsoleUrlBuilder.getAutoScalingGroupLaunchConfigurationUrl(launchConfiguration)),
            localization.sections.resolution.step1({ maxTokenHopMaxCount: risk.maxTokenHopMaxCount }),
            localization.sections.resolution.step2({ launchConfigurationName: launchConfiguration.displayName }),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4({ maxTokenHopMaxCount: risk.maxTokenHopMaxCount }),
            localization.sections.resolution.step5(),
            !_.isEmpty(riskModel.risk.autoScalingGroupIds)
                ? localization.sections.resolution.step6({
                    autoScalingGroups:
                        <InlineEntities
                            entityIdsOrModels={riskModel.risk.autoScalingGroupIds}
                            entityTypeName={Contract.TypeNames.AwsAutoScalingAutoScalingGroup}
                            variant="itemCountAndType"/>
                })
                : undefined,
            localization.sections.resolution.step7(),
            localization.sections.resolution.step8()
        ],
        riskModel,
        () => {
            const autoScalingGroupsContextItems = getAwsAutoScalingAutoScalingGroupsRiskContext(riskModel.risk.autoScalingGroupIds!);
            const instanceMetadataServiceTokenHopMaxCountRiskContextItems = getAwsEc2InstanceMetadataServiceTokenHopMaxCountRiskContext(riskModel);
            const instancesContextItems = getAwsEc2InstancesRiskContext(riskModel.risk.aggregatedEntityIds);
            const launchConfigurationContextItems = getAwsAutoScalingLaunchConfigurationRiskContext(launchConfigurationModel);
            return [
                launchConfigurationContextItems.generalInformation,
                launchConfigurationContextItems.sensitive,
                launchConfigurationContextItems.getAutoScalingGroupsContextItem(riskModel.risk.autoScalingGroupIds),
                autoScalingGroupsContextItems.sensitive,
                instanceMetadataServiceTokenHopMaxCountRiskContextItems.instances,
                instanceMetadataServiceTokenHopMaxCountRiskContextItems.permissionActions,
                instanceMetadataServiceTokenHopMaxCountRiskContextItems.instancePublicAccess,
                instancesContextItems.runningInstances,
                instancesContextItems.sensitive,
                launchConfigurationContextItems.getOpenRiskedEntityRisksContextItem(riskModel.id)];
        });
}