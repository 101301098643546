import _ from "lodash";
import React from "react";
import { CustomerIcon } from "..";
import { Contract } from "../../controllers";
import { BuiltInComplianceSectionIcon } from "./icons";

type ComplianceSectionIconProps = {
    complianceIdentifier: string;
    size: string;
};

export function ComplianceSectionIcon({ complianceIdentifier, size }: ComplianceSectionIconProps) {
    return _.includes(Contract.BuiltInComplianceSectionType, complianceIdentifier)
        ? <BuiltInComplianceSectionIcon
            sectionType={complianceIdentifier as Contract.BuiltInComplianceSectionType}
            sx={{ fontSize: size }}/>
        : <CustomerIcon size={size}/>;
}