import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract, DnsEndpointHelper } from "../../../../../../../../../common";
import { useCodeTenantTypeTranslator } from "../../../../../../../../../tenants";

export function useCodeServerAuditEventDefinition(context: AuditEventDefinitionContext) {
    const tenantTypeTranslator = useCodeTenantTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useCodeServerAuditEventDefinition",
            () => ({
                serverEndpoint: "Endpoint",
                serverEndpointConnectorName: "Endpoint connector",
                serverName: "Name",
                serverType: "Provider"
            }));

    const codeServerAuditEvent = context.auditEventModel.auditEvent as Contract.CodeServerAuditEvent;
    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>([]).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.serverName(),
                    codeServerAuditEvent.serverName)).
            concatIf(
                !_.isNil(codeServerAuditEvent.serverEndpoint),
                () =>
                    new AuditEventDefinitionDetailItem(
                        localization.serverEndpoint(),
                        DnsEndpointHelper.getHttpsEndpointString(codeServerAuditEvent.serverEndpoint!))).
            concatIf(
                !_.isNil(codeServerAuditEvent.serverEndpointConnectorName),
                new AuditEventDefinitionDetailItem(
                    localization.serverEndpointConnectorName(),
                    codeServerAuditEvent.serverEndpointConnectorName!)).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.serverType(),
                    tenantTypeTranslator(codeServerAuditEvent.serverType))).
            value());
}