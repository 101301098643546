import { Link, Step, Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, tenantModelStore } from "../../../../../../../../../../../../common";
import { AadConsoleUrlBuilder } from "../../../../../../../../../../../../tenants/aad/utilities/aadConsoleUrlBuilder.generated";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAadDirectoryUserRiskContext } from "../contexts";

export function useAadDirectoryUserInactiveRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AadDirectoryUserInactiveRisk;
    const userModel = entityModelStore.useGet(risk.entityId) as Contract.AadDirectoryUserModel;
    const user = userModel.entity as Contract.AadDirectoryUser;
    const tenantConfiguration = tenantModelStore.useGet(riskModel.tenantId).configuration as Contract.AadTenantConfiguration;

    const getAadDirectoryUserRiskContext = useGetAadDirectoryUserRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aad.hooks.compliance.useAadDirectoryUserInactiveRiskDefinition",
            () => ({
                description: "{{user}} is inactive and should be disabled or deleted",
                sections: {
                    context: {
                        [Contract.TypeNames.AadDirectoryIdentityInactiveRiskModelInfoType]: {
                            [Contract.AadDirectoryIdentityInactiveRiskModelInfoType.SignInNever]: "The user was not active since it was created",
                            [Contract.AadDirectoryIdentityInactiveRiskModelInfoType.SignInTime]: "The user was last active {{signInTime | TimeFormatter.humanizePastDuration}}"
                        }
                    },
                    resolution: {
                        step1: {
                            link: "Overview page",
                            text: "In Azure Portal, open the {{overviewLink}} of {{user}}"
                        },
                        step2: {
                            [Contract.TypeNames.AadDirectoryIdentityInactiveRiskResolutionType]: {
                                [Contract.AadDirectoryIdentityInactiveRiskResolutionType.Delete]: "Click **Delete** at the top and then click **Delete**",
                                [Contract.AadDirectoryIdentityInactiveRiskResolutionType.DeleteDisable]: "To delete the user, click **Delete** at the top and then click **Delete**"
                            }
                        },
                        step3: "To disable the user, click **Edit** on **Account status**, uncheck **Account enabled** and then click **Save**",
                        title: {
                            [Contract.TypeNames.AadDirectoryIdentityInactiveRiskResolutionType]: {
                                [Contract.AadDirectoryIdentityInactiveRiskResolutionType.Delete]: "Delete {{user}}",
                                [Contract.AadDirectoryIdentityInactiveRiskResolutionType.DeleteDisable]: "Delete or disable {{user}}"
                            }
                        }
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            user:
                <Entity
                    entityIdOrModel={userModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            new Step(
                localization.sections.resolution.title[Contract.TypeNames.AadDirectoryIdentityInactiveRiskResolutionType][risk.resolutionType]({
                    user:
                        <Entity
                            entityIdOrModel={userModel}
                            glanceOptions={{ disabled: true }}
                            linkOptions={{ disabled: true }}
                            variant="typeText"/>
                }),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {_.filter([
                                localization.sections.resolution.step1.text({
                                    overviewLink:
                                        <Link
                                            urlOrGetUrl={AadConsoleUrlBuilder.GetUserUrl(
                                                tenantConfiguration.partitionType,
                                                user.rawId)}
                                            variant="external">
                                            {localization.sections.resolution.step1.link()}
                                        </Link>,
                                    user:
                                        <Entity
                                            entityIdOrModel={userModel}
                                            linkOptions={{ disabled: true }}
                                            variant="typeText"/>
                                }),
                                localization.sections.resolution.step2[Contract.TypeNames.AadDirectoryIdentityInactiveRiskResolutionType][risk.resolutionType](),
                                risk.resolutionType === Contract.AadDirectoryIdentityInactiveRiskResolutionType.DeleteDisable
                                    ? localization.sections.resolution.step3()
                                    : undefined
                            ])}
                        </Steps>
                })
        ],
        riskModel,
        () => {
            const userRiskContext = getAadDirectoryUserRiskContext(userModel);
            return [
                userRiskContext.generalInformation,
                new RiskDefinitionContextItem(localization.sections.context[Contract.TypeNames.AadDirectoryIdentityInactiveRiskModelInfoType][(riskModel as Contract.AadDirectoryIdentityInactiveRiskModel).infoType]({ signInTime: risk.signInTime })),
                userRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}