import { useLocalization, WizardItem } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../..";
import { RiskResolutionAutomationContext } from "../../../../utilities";
import { AzureEditItem, AzureSummaryItem } from "./components";

export class AzureRiskResolutionAutomationContext extends RiskResolutionAutomationContext {
    public isChangeResolvableChangeable: (changeModel: Contract.ChangeModel) => boolean;

    constructor(
        changeModels: Contract.ChangeModel[],
        riskedEntityModel: Contract.EntityModel) {
        super(
            changeModels,
            riskedEntityModel);
        this.isChangeResolvableChangeable = changeModel => changeModel.changeable && this.isChangeResolvable(changeModel);
    }
}

export function useAzureDefinition(
    changeModels: Contract.ChangeModel[],
    riskedEntityModel: Contract.EntityModel) {
    const localization =
        useLocalization(
            "common.riskResolutionAutomation.hooks.useDefinition.hooks.useAzureDefinition",
            () => ({
                items: {
                    edit: "The following steps are required in order to perform the remediation. Select a step to view more details about it",
                    summary: "The following steps will be applied once you click the **Apply** button"
                }
            }));

    return {
        createContext:
            () =>
                new AzureRiskResolutionAutomationContext(
                    changeModels,
                    riskedEntityModel),
        wizardItems: [
            <WizardItem
                disablePadding={true}
                key="edit-item"
                subtitle={localization.items.edit()}>
                <AzureEditItem/>
            </WizardItem>,
            <WizardItem
                disablePadding={true}
                key="summary-item"
                subtitle={localization.items.summary()}>
                <AzureSummaryItem/>
            </WizardItem>
        ]
    };
}