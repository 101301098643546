import { AnalyticsEventActionType, ApiError, CheckboxField, Dialog, FormLayout, Link, map, Message, Optional, useLocalization, useTrackAnalytics } from "@infrastructure";
import { Error as ErrorIcon } from "@mui/icons-material";
import { Box, Button, CircularProgress, Stack, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useState } from "react";
import { AuthenticationItem, AuthenticationType, useAuthenticationContext, useSetAuthenticationContext } from "../..";
import { ConfigurationController, Contract, UrlHelper } from "../../../../../../../../common";
import { AuthenticationHelper } from "../../utilities";
import { OpenIdConnect, Saml, SamlConfiguration, SelectIdentityProvider } from "./components";
export function AddOrEdit() {
    const setAuthenticationContext = useSetAuthenticationContext();
    const { connectSupportError } = useAuthenticationContext();
    return (
        <Dialog
            variant="editor"
            onClose={
                () => {
                    setAuthenticationContext(
                        context => ({
                            ...context,
                            addOrEditOpen: false
                        }));
                }}>
            {connectSupportError
                ? <ConnectSupport/>
                : <Core/>}
        </Dialog>);
}

function Core() {
    const { aadIdentityProviderEnabled, addOrEditOpen, consoleAppTypeToSamlIdentityProviderMap, executeGetAuthenticationConfiguration, gciIdentityProviderEnabled } = useAuthenticationContext();
    const setAuthenticationContext = useSetAuthenticationContext();
    const trackAnalytics = useTrackAnalytics();

    const [executing, setExecuting] = useState(false);
    const [valid, setValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const localization =
        useLocalization(
            "views.customer.configuration.authentication.addOrEdit.core",
            () => ({
                actions: {
                    validate: {
                        error: {
                            general: "Something went wrong",
                            url: "URL must start with 'https://'",
                            [Contract.ConfigurationControllerValidateAuthenticationSamlIdentityProviderError.InvalidMetadata]: "Invalid Metadata"
                        }
                    }
                },
                dialog: {
                    actions: {
                        add: "Add",
                        back: "Back",
                        cancel: "Cancel",
                        save: "Save"
                    },
                    microsoft: {
                        consent: "I confirm that I granted access",
                        fail: "Failed to granted access"
                    },
                    title: {
                        add: "Add Identity Provider",
                        edit: "Edit Identity Provider"
                    }
                }
            }));

    const [samlConfiguration, setSamlConfiguration] =
        useState<SamlConfiguration>(
            () => {
                const [consoleAppType, samlIdentityProvider] =
                    _.isBoolean(addOrEditOpen)
                        ? [undefined, undefined]
                        : [addOrEditOpen.applications[0], consoleAppTypeToSamlIdentityProviderMap[addOrEditOpen.applications[0]]];

                return {
                    consoleAppType,
                    metadataBytes: samlIdentityProvider?.metadataBytes ?? "",
                    metadataUrl: samlIdentityProvider?.metadataUrl ?? "",
                    urlMode: _.isEmpty(samlIdentityProvider?.metadataBytes)
                };
            });

    const [selectedIdentityProvider, setSelectedIdentityProvider] =
        useState<Optional<AuthenticationItem>>(
            () =>
                _.isBoolean(addOrEditOpen)
                    ? undefined
                    : addOrEditOpen);

    async function validateAuthenticationIdentityProvider(authenticationType: AuthenticationType) {
        if (authenticationType === AuthenticationType.Oidc) {
            return true;
        }

        if (samlConfiguration.urlMode &&
            !_.startsWith(samlConfiguration?.metadataUrl, "https://")) {
            setErrorMessage(localization.actions.validate.error.url());
            return false;
        }

        try {
            const { samlIdentityProvider } =
                await ConfigurationController.validateAuthenticationSamlIdentityProvider(
                    new Contract.ConfigurationControllerValidateAuthenticationSamlIdentityProviderRequest(
                        samlConfiguration?.urlMode
                            ? undefined
                            : samlConfiguration.metadataBytes,
                        samlConfiguration?.urlMode
                            ? samlConfiguration?.metadataUrl
                            : undefined));

            return samlIdentityProvider;
        } catch (error) {
            const errorMessage =
                error instanceof ApiError &&
                    error.error in localization.actions.validate.error &&
                    error.statusCode === 400
                    ? localization.actions.validate.error[error.error as Contract.ConfigurationControllerValidateAuthenticationSamlIdentityProviderError]()
                    : localization.actions.validate.error.general();
            setErrorMessage(errorMessage);
        }

        return false;
    }

    async function updateAuthenticationConfiguration() {
        if (!selectedIdentityProvider) {
            return;
        }

        setExecuting(true);
        const authenticationRequest = { aadIdentityProviderEnabled, consoleAppTypeToSamlIdentityProviderMap, gciIdentityProviderEnabled };
        const validateConfiguration = await validateAuthenticationIdentityProvider(selectedIdentityProvider.authenticationType);
        if (!validateConfiguration) {
            setExecuting(false);
            return;
        }

        const [key, value] =
            selectedIdentityProvider.authenticationType === AuthenticationType.Oidc
                ? [selectedIdentityProvider.fieldKey, true]
                : [`${selectedIdentityProvider.fieldKey}.${selectedIdentityProvider.applications[0]}`, validateConfiguration];
        _.set(
            authenticationRequest,
            key,
            value);

        try {
            await ConfigurationController.updateAuthenticationConfiguration(
                new Contract.ConfigurationControllerUpdateAuthenticationConfigurationRequest(
                    authenticationRequest.aadIdentityProviderEnabled,
                    authenticationRequest.consoleAppTypeToSamlIdentityProviderMap,
                    authenticationRequest.gciIdentityProviderEnabled));
            await executeGetAuthenticationConfiguration();
            setSelectedIdentityProvider(undefined);
            trackAnalytics(
                _.isBoolean(addOrEditOpen)
                    ? AnalyticsEventActionType.AuthenticationAdd
                    : AnalyticsEventActionType.AuthenticationEdit,
                AuthenticationHelper.getTrackAnalyticsPropertyNameToValueMap(selectedIdentityProvider, "Success"));
        } catch (error) {
            setAuthenticationContext(
                context => ({
                    ...context,
                    connectSupportError: true
                }));
            trackAnalytics(
                _.isBoolean(addOrEditOpen)
                    ? AnalyticsEventActionType.AuthenticationAdd
                    : AnalyticsEventActionType.AuthenticationEdit,
                AuthenticationHelper.getTrackAnalyticsPropertyNameToValueMap(selectedIdentityProvider, "Error"));
        }

        setExecuting(false);
    }

    const theme = useTheme();
    return (
        <FormLayout
            footerOptions={{
                contentElement:
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="space-between">
                        {selectedIdentityProvider?.identityProviderType === Contract.TenantType.Azure &&
                            <CheckboxField
                                checked={valid}
                                onChange={() => setValid(!valid)}>
                                <Stack
                                    direction="row"
                                    justifyItems="center"
                                    spacing={1}>
                                    <Typography>
                                        {localization.dialog.microsoft.consent()}
                                    </Typography>
                                </Stack>
                            </CheckboxField>}
                        <Box>
                            {!_.isEmpty(errorMessage) &&
                                <Message
                                    level="error"
                                    title={errorMessage}/>}
                        </Box>
                        {!_.isNil(selectedIdentityProvider) &&
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={1}>
                                {executing &&
                                    <CircularProgress
                                        size={theme.spacing(3)}
                                        variant="indeterminate"/>}
                                {_.isBoolean(addOrEditOpen)
                                    ? <Button
                                        variant="outlined"
                                        onClick={
                                            () => {
                                                setSelectedIdentityProvider(undefined);
                                                setSamlConfiguration({
                                                    consoleAppType: undefined,
                                                    metadataBytes: "",
                                                    metadataUrl: "",
                                                    urlMode: true
                                                });
                                            }}>
                                        {localization.dialog.actions.back()}
                                    </Button>
                                    : <Button
                                        variant="outlined"
                                        onClick={
                                            () => {
                                                setAuthenticationContext(
                                                    context => ({
                                                        ...context,
                                                        addOrEditOpen: false
                                                    }));
                                            }}>
                                        {localization.dialog.actions.cancel()}
                                    </Button>
                                }
                                <Button
                                    disabled={!valid || executing || _.isNil(selectedIdentityProvider)}
                                    onClick={updateAuthenticationConfiguration}>
                                    {!_.isBoolean(addOrEditOpen)
                                        ? localization.dialog.actions.save()
                                        : localization.dialog.actions.add()}
                                </Button>
                            </Stack>}
                    </Stack>
            }}
            titleOptions={{
                text:
                    _.isBoolean(addOrEditOpen)
                        ? localization.dialog.title.add()
                        : localization.dialog.title.edit()
            }}>
            {_.isNil(selectedIdentityProvider) &&
                <SelectIdentityProvider
                    onItemClick={
                        (item: AuthenticationItem) => {
                            setSelectedIdentityProvider(item);
                            setValid(item.identityProviderType === Contract.TenantType.Gcp);
                        }}/>}
            {map(
                selectedIdentityProvider?.authenticationType,
                {
                    [AuthenticationType.Oidc]: () => <OpenIdConnect selectedItem={selectedIdentityProvider!}/>,
                    [AuthenticationType.Saml]:
                        () =>
                            <Saml
                                configuration={samlConfiguration}
                                onConfigurationChange={
                                    (config: SamlConfiguration) => {
                                        setErrorMessage("");
                                        setSamlConfiguration(config);
                                        if (samlConfiguration.consoleAppType) {
                                            setSelectedIdentityProvider({
                                                ...selectedIdentityProvider!,
                                                applications: [samlConfiguration.consoleAppType]
                                            });
                                        }
                                    }}
                                onValidChange={setValid}/>
                },
                () => <Fragment/>)}
        </FormLayout>);
}

function ConnectSupport() {
    const localization =
        useLocalization(
            "views.customer.configuration.authentication.addOrEdit.connectSupport",
            () => ({
                contactSupport: "Contact support",
                text: "{{contactSupportLink}} for help to complete the setup",
                title: "We encountered an issue while configuring your Single Sign-On"
            }));

    const theme = useTheme();
    return (
        <FormLayout
            footerOptions={{ contentElement: null }}>
            <Stack
                alignItems="center"
                justifyContent="center"
                spacing={1}>
                <ErrorIcon
                    sx={{
                        color: theme.palette.toast.error,
                        fontSize: "60px",
                        marginBlock: theme.spacing(3)
                    }}/>
                <Typography
                    sx={{
                        textAlign: "center",
                        textWrap: "pretty"
                    }}
                    variant="h1">
                    {localization.title()}
                </Typography>
                <Typography
                    sx={{
                        fontSize: "16px",
                        textAlign: "center"
                    }}>
                    {localization.text({
                        contactSupportLink:
                            <Link
                                sx={{ fontSize: "16px" }}
                                urlOrGetUrl={UrlHelper.supportUrl}
                                variant="external">
                                {localization.contactSupport()}
                            </Link>
                    })}
                </Typography>
            </Stack>
        </FormLayout>);
}