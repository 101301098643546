import { map } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { Contract, DagHelper, EntityPropertyPatternHelper, RiskPolicyTypeMetadataHelper, TypeHelper } from "../../../../../common";

export class ExclusionHelper {
    public static getExclusionEntityTypeNameToCountMap(
        parentRiskPolicyModels: Contract.RiskPolicyModel[],
        riskPolicyModel: Contract.RiskPolicyModel,
        riskPolicyConfigurationTypeName: string,
        tenantRiskPolicyModels: Contract.RiskPolicyModel[]) {
        const exclusionEntityTypeNameToIdCountMap =
            _(tenantRiskPolicyModels).
                flatMap(tenantRiskPolicyModel => _.toPairs(tenantRiskPolicyModel.riskPolicyConfiguration.entityTypeNameToExclusionsMap)).
                filter(
                    ([entityTypeName]) =>
                        !_.includes(
                            RiskPolicyTypeMetadataHelper.getExclusionAnyScopeEntityTypeNames(riskPolicyConfigurationTypeName),
                            entityTypeName)).
                concat(
                    _(parentRiskPolicyModels).
                        concat(riskPolicyModel).
                        flatMap(riskPolicyModel => _.toPairs(riskPolicyModel.riskPolicyConfiguration.entityTypeNameToExclusionsMap)).
                        value()).
                groupBy(([entityTypeName]) => entityTypeName).
                mapValues(
                    entityTypeNameAndExclusionGroup =>
                        _(entityTypeNameAndExclusionGroup).
                            flatMap(([, entityExclusion]) => _.keys(entityExclusion.entityIdToDataMap)).
                            uniq().
                            size()).
                value();

        const exclusionEntityTypeNameToPropertyPatternCountMap =
            _(parentRiskPolicyModels).
                concat(riskPolicyModel).
                flatMap(riskPolicyModel => _.toPairs(riskPolicyModel.riskPolicyConfiguration.entityTypeNameToExclusionsMap)).
                groupBy(([entityTypeName]) => entityTypeName).
                mapValues(
                    entityTypeNameAndExclusionGroup =>
                        _(entityTypeNameAndExclusionGroup).
                            flatMap(([, entityExclusion]) => entityExclusion.entityPropertyPatterns).
                            map(propertyPattern => EntityPropertyPatternHelper.getExclusionEntityPropertyPatternKey(propertyPattern)).
                            uniq().
                            size()).
                value();

        return [exclusionEntityTypeNameToIdCountMap, exclusionEntityTypeNameToPropertyPatternCountMap];
    }

    public static getRiskPolicyConfigurationExclusionDataReason(riskSubStatus: Contract.RiskSubStatus) {
        return map(
            riskSubStatus,
            {
                [Contract.RiskSubStatus.IgnoredByDesign]: () => Contract.RiskPolicyConfigurationExclusionDataReason.ByDesign,
                [Contract.RiskSubStatus.IgnoredException]: () => Contract.RiskPolicyConfigurationExclusionDataReason.Exception,
                [Contract.RiskSubStatus.IgnoredFalsePositive]: () => Contract.RiskPolicyConfigurationExclusionDataReason.FalsePositive
            });
    }

    public static getExclusionEntityTypeNameToIdsMap(
        riskItems: Contract.RiskItem[],
        riskTypeMetadataModel: Contract.RiskTypeMetadataModel): Dictionary<string[]> {
        const entityReferences =
            _.flatMap(
                riskItems,
                item => DagHelper.getDagEntityReferences(item.entityExclusionDag));
        return _(entityReferences).
            map(
                entityReference => ({
                    entityReference,
                    exclusionEntityTypeName:
                        _.find(
                            riskTypeMetadataModel.exclusionEntityTypeNames,
                            exclusionEntityTypeName => TypeHelper.extendOrImplement(entityReference.typeName, exclusionEntityTypeName))!
                })).
            filter(({ exclusionEntityTypeName }) => !_.isNil(exclusionEntityTypeName)).
            groupBy(({ exclusionEntityTypeName }) => exclusionEntityTypeName).
            mapValues(
                entityDatas =>
                    _.map(
                        entityDatas,
                        ({ entityReference }) => entityReference.id)).
            defaults(
                _(riskTypeMetadataModel.exclusionEntityTypeNames).
                    keyBy().
                    mapValues(() => [] as string[]).
                    value()).
            value();
    }
}