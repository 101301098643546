import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAwsCommonEncryptedResourceInfoElements } from "./useAwsCommonEncryptedResourceInfoElements";
import { useAwsDefaultResourceInfoItemElements } from "./useAwsDefaultResourceInfoItemElements";

export function useAwsAthenaWorkgroupDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const workgroup = resourceModel.entity as Contract.AwsAthenaWorkgroup;
    const workgroupModel = resourceModel as Contract.AwsAthenaWorkgroupModel;
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsAthenaWorkgroupDefinition",
            () => ({
                info: {
                    items: {
                        enforceWorkgroupConfiguration: {
                            false: "Disabled",
                            title: "Override Client-Side Settings",
                            true: "Enabled"
                        },
                        engineVersionName: "Analytics Engine",
                        outputBucket: "Query Result S3 Bucket",
                        publishCloudWatchMetrics: {
                            false: "Disabled",
                            title: "Query Metrics Logging",
                            true: "Enabled"
                        },
                        state: {
                            false: "Disabled",
                            title: "State",
                            true: "Enabled"
                        }
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="state"
                        title={localization.info.items.state.title()}
                        value={
                            workgroup.enabled
                                ? localization.info.items.state.true()
                                : localization.info.items.state.false()}/>,
                    <InfoItem
                        key="analyticsEngine"
                        location="all"
                        title={localization.info.items.engineVersionName()}
                        value={workgroup.engineVersionName}/>,
                    <InfoItem
                        key="enforce"
                        title={localization.info.items.enforceWorkgroupConfiguration.title()}
                        value={
                            workgroup.enforceWorkgroupConfiguration
                                ? localization.info.items.enforceWorkgroupConfiguration.true()
                                : localization.info.items.enforceWorkgroupConfiguration.false()}/>,
                    <InfoItem
                        key="publishCloudWatchMetrics"
                        title={localization.info.items.publishCloudWatchMetrics.title()}
                        value={
                            workgroup.loggingEnabled
                                ? localization.info.items.publishCloudWatchMetrics.true()
                                : localization.info.items.publishCloudWatchMetrics.false()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={workgroupModel.outputBucketIdReference}
                        entityTypeName={Contract.TypeNames.AwsS3Bucket}
                        key="s3Bucket"
                        title={localization.info.items.outputBucket()}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}