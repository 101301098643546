import { useLocalization } from "@infrastructure";
import { SxProps } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, InfoCard, InfoItem, VerticalTopItemsInfoItem } from "../../..";

type ThreatAndImpactFactorsInfoCardProps = {
    sx?: SxProps;
    variant?: "minimal" | "full";
    vulnerability: Contract.Vulnerability;
};

export function ThreatAndImpactFactorsInfoCard({ variant = "full", vulnerability }: ThreatAndImpactFactorsInfoCardProps) {
    const localization =
        useLocalization(
            "common.vulnerability.threatAndImpactFactorsInfoCard",
            () => ({
                parameters: {
                    attackComplexity: {
                        title: "Attack Complexity",
                        values: {
                            [Contract.VulnerabilityAttackComplexity.High]: "High",
                            [Contract.VulnerabilityAttackComplexity.Low]: "Low"
                        }
                    },
                    attackVector: {
                        title: "Attack Vector",
                        values: {
                            [Contract.VulnerabilityAttackVector.Physical]: "Physical",
                            [Contract.VulnerabilityAttackVector.Network]: "Network",
                            [Contract.VulnerabilityAttackVector.Local]: "Local",
                            [Contract.VulnerabilityAttackVector.Adjacent]: "Adjacent"
                        }
                    },
                    cisaKevData: "CISA KEV Exploit",
                    exploitable: "Known Exploit",
                    exploitMaturity: {
                        title: "Exploit Maturity",
                        values: {
                            [Contract.VulnerabilityExploitMaturity.Functional]: "Functional",
                            [Contract.VulnerabilityExploitMaturity.High]: "High",
                            [Contract.VulnerabilityExploitMaturity.Poc]: "PoC",
                            [Contract.VulnerabilityExploitMaturity.Unproven]: "Unproven"
                        }
                    },
                    proofOfConcept: "Proof of Concept",
                    remotelyExploitable: "Remotely Exploitable",
                    values: {
                        false: "No",
                        true: "Yes"
                    },
                    vprMetrics: {
                        age: {
                            title: "Age of Vulnerability",
                            values: {
                                [Contract.VulnerabilityAge.Between0And7Days]: "0 - 7 Days",
                                [Contract.VulnerabilityAge.Between7And30Days]: "7 - 30 Days",
                                [Contract.VulnerabilityAge.Between30And60Days]: "30 - 60 Days",
                                [Contract.VulnerabilityAge.Between60And180Days]: "60 - 180 Days",
                                [Contract.VulnerabilityAge.Between180And365Days]: "180 - 365 Days",
                                [Contract.VulnerabilityAge.Between365And730Days]: "365 - 730 Days",
                                [Contract.VulnerabilityAge.MoreThan730Days]: "730+ Days",
                                [Contract.VulnerabilityAge.NotYetPublished]: "Not Yet Published"
                            }
                        },
                        cvss3ImpactScore: "CVSSv3 Impact Score",
                        last28DaysThreatIntensity: {
                            title: "Threat Intensity",
                            values: {
                                [Contract.VulnerabilityThreatIntensityLast28Days.High]: "High",
                                [Contract.VulnerabilityThreatIntensityLast28Days.Low]: "Low",
                                [Contract.VulnerabilityThreatIntensityLast28Days.Medium]: "Medium",
                                [Contract.VulnerabilityThreatIntensityLast28Days.VeryHigh]: "Very High",
                                [Contract.VulnerabilityThreatIntensityLast28Days.VeryLow]: "Very Low"

                            }
                        },
                        last28DaysThreatSources: {
                            title: "Threat Sources",
                            values: {
                                [Contract.VulnerabilityThreatSourcesLast28Days.CodeRepoAndPasteBins]: "Code Repo And Paste Bins",
                                [Contract.VulnerabilityThreatSourcesLast28Days.DarkWebAndUnderground]: "Dark Web And Underground",
                                [Contract.VulnerabilityThreatSourcesLast28Days.HackerForum]: "Hacker Forum",
                                [Contract.VulnerabilityThreatSourcesLast28Days.MainstreamMedia]: "Mainstream Media",
                                [Contract.VulnerabilityThreatSourcesLast28Days.NoRecordedEvents]: "No Recorded Events",
                                [Contract.VulnerabilityThreatSourcesLast28Days.Others]: "Others",
                                [Contract.VulnerabilityThreatSourcesLast28Days.SecurityResearch]: "Security Research",
                                [Contract.VulnerabilityThreatSourcesLast28Days.SocialMedia]: "Social Media",
                                [Contract.VulnerabilityThreatSourcesLast28Days.TenableResearchAnalystsDecreasedVpr]: "Tenable Research Analysts Decreased Vpr",
                                [Contract.VulnerabilityThreatSourcesLast28Days.TenableResearchAnalystsIncreasedVpr]: "Tenable Research Analysts Increased Vpr"
                            }
                        },
                        productCoverage: {
                            title: "Product Coverage",
                            values: {
                                [Contract.VulnerabilityProductCoverage.High]: "High",
                                [Contract.VulnerabilityProductCoverage.Low]: "Low",
                                [Contract.VulnerabilityProductCoverage.Medium]: "Medium",
                                [Contract.VulnerabilityProductCoverage.VeryHigh]: "Very High",
                                [Contract.VulnerabilityProductCoverage.Unknown]: "Unknown"
                            }
                        },
                        threatRecency: {
                            title: "Threat Recency",
                            values: {
                                [Contract.VulnerabilityThreatRecency.Between0And7Days]: "0 - 7 Days",
                                [Contract.VulnerabilityThreatRecency.Between7And30Days]: "7 - 30 Days",
                                [Contract.VulnerabilityThreatRecency.Between30And120Days]: "30 - 120 Days",
                                [Contract.VulnerabilityThreatRecency.Between120And365Days]: "120 - 365 Days",
                                [Contract.VulnerabilityThreatRecency.MoreThan365Days]: "365+ Days",
                                [Contract.VulnerabilityThreatRecency.NoRecordedEvents]: "No Recorded Events"
                            }
                        }
                    },
                    zeroDay: "Zero Day"
                }
            }));

    return (
        <InfoCard columns={variant === "full"}>
            {vulnerability.attackVector &&
                <InfoItem
                    title={localization.parameters.attackVector.title()}
                    value={localization.parameters.attackVector.values[vulnerability.attackVector]()}/>}
            {vulnerability.attackComplexity &&
                <InfoItem
                    title={localization.parameters.attackComplexity.title()}
                    value={localization.parameters.attackComplexity.values[vulnerability.attackComplexity]()}/>}
            <InfoItem
                title={localization.parameters.exploitable()}
                value={localization.parameters.values[`${vulnerability.exploitable}`]()}/>
            {variant === "full" &&
                <InfoItem
                    title={localization.parameters.remotelyExploitable()}
                    value={localization.parameters.values[`${vulnerability.remotelyExploitable}`]()}/>}
            {variant === "full" &&
            !_.isNil(vulnerability.proofOfConceptAvailable) &&
                <InfoItem
                    title={localization.parameters.proofOfConcept()}
                    value={localization.parameters.values[`${vulnerability.proofOfConceptAvailable}`]()}/>}
            {variant === "full" &&
                <InfoItem
                    title={localization.parameters.vprMetrics.cvss3ImpactScore()}
                    value={String(vulnerability.vprMetrics?.cvss3ImpactScore)}/>}
            {variant === "full" &&
            vulnerability.exploitMaturity &&
                <InfoItem
                    title={localization.parameters.exploitMaturity.title()}
                    value={localization.parameters.exploitMaturity.values[vulnerability.exploitMaturity]()}/>}
            {variant === "full" &&
            vulnerability.vprMetrics?.age &&
                <InfoItem
                    title={localization.parameters.vprMetrics.age.title()}
                    value={(localization.parameters.vprMetrics.age.values[vulnerability.vprMetrics?.age])()}/>}
            {variant === "full" &&
            vulnerability.vprMetrics?.productCoverage &&
                <InfoItem
                    title={localization.parameters.vprMetrics.productCoverage.title()}
                    value={localization.parameters.vprMetrics.productCoverage.values[vulnerability.vprMetrics?.productCoverage]()}/>}
            {variant === "full" &&
            vulnerability.vprMetrics?.last28DaysThreatIntensity &&
                <InfoItem
                    title={localization.parameters.vprMetrics.last28DaysThreatIntensity.title()}
                    value={localization.parameters.vprMetrics.last28DaysThreatIntensity.values[vulnerability.vprMetrics?.last28DaysThreatIntensity]()}/>}
            { variant === "full" &&
            !_.isEmpty(vulnerability.vprMetrics?.last28DaysThreatSources) &&
                <VerticalTopItemsInfoItem
                    items={
                        _.map(
                            vulnerability.vprMetrics?.last28DaysThreatSources,
                            last28DaysThreatSource => localization.parameters.vprMetrics.last28DaysThreatSources.values[last28DaysThreatSource]())}
                    title={localization.parameters.vprMetrics.last28DaysThreatSources.title()}/>}
            {variant === "full"
            && vulnerability.vprMetrics?.threatRecency &&
                <InfoItem
                    title={localization.parameters.vprMetrics.threatRecency.title()}
                    value={localization.parameters.vprMetrics.threatRecency.values[vulnerability.vprMetrics?.threatRecency]()}/>}
            <InfoItem
                title={localization.parameters.cisaKevData()}
                value={localization.parameters.values[`${!!vulnerability.cisaKevData}`]()}/>
            <InfoItem
                title={localization.parameters.zeroDay()}
                value={localization.parameters.values[`${vulnerability.zeroDay}`]()}/>
        </InfoCard>);
}