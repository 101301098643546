import { UnexpectedError } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { Contract, RiskPolicyTypeMetadataHelper } from "../../../../../../../../../../../../../../../../../../../../common";
import { useAwsBehaviorDefinition, useAzureBehaviorDefinition, useGcpBehaviorDefinition } from "./hooks";

export function useBehaviorDefinition(riskPolicyConfigurationTypeName: string) {
    const useDefinition =
        useMemo(
            () => {
                const tenantTypes = RiskPolicyTypeMetadataHelper.getTenantTypes(riskPolicyConfigurationTypeName);
                if (tenantTypes.length != 1) {
                    throw new UnexpectedError("riskPolicyConfigurationTypeName", riskPolicyConfigurationTypeName);
                }

                switch (_.first(tenantTypes)) {
                    case Contract.TenantType.Aws:
                        return useAwsBehaviorDefinition;
                    case Contract.TenantType.Azure:
                        return useAzureBehaviorDefinition;
                    case Contract.TenantType.Gcp:
                        return useGcpBehaviorDefinition;
                    default:
                        throw new UnexpectedError("riskPolicyConfigurationTypeName", riskPolicyConfigurationTypeName);
                }
            },
            []);

    return useDefinition(riskPolicyConfigurationTypeName);
}