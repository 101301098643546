import { DeleteIcon, ItemSelector, Optional, useLocalization } from "@infrastructure";
import { CheckCircle, CloudUpload } from "@mui/icons-material";
import { Box, Button, FormControl, IconButton, InputBase, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { Contract, RadioGroup, UserHelper, useTheme } from "../../../../../../../../../common";
import { useAuthenticationContext } from "../../../Authentication";

type SamlProps = {
    configuration: SamlConfiguration;
    onConfigurationChange: (config: SamlConfiguration) => void;
    onValidChange: (allow: boolean) => void;
};

export type SamlConfiguration = {
    consoleAppType?: Contract.ConsoleAppType.Customer | Contract.ConsoleAppType.User;
    metadataBytes: string;
    metadataUrl: string;
    urlMode: boolean;
};

export function Saml({ configuration, onConfigurationChange, onValidChange }: SamlProps) {
    const { addOrEditOpen, consoleAppTypeToSamlIdentityProviderMap } = useAuthenticationContext();
    const uploadFileButtonRef = useRef<HTMLInputElement>(null);
    const localization =
        useLocalization(
            "views.customer.configuration.authentication.addOrEdit.saml",
            () => ({
                fields: {
                    file: {
                        edit: "Edit",
                        success: "File uploaded",
                        text: "File",
                        upload: "Upload file"
                    },
                    selector: {
                        customer: "Cloud Security",
                        placeholder: "Choose Application",
                        user: "JIT"
                    },
                    url: {
                        placeholder: "https://example.com/app/sso/saml/metadata",
                        text: "URL"
                    }
                },
                title: "Enable Login via SAML"
            }));

    const items =
        useMemo(
            (): SamlItemOptions[] =>
                [
                    {
                        consoleAppType: Contract.ConsoleAppType.Customer,
                        text: localization.fields.selector.customer()
                    },
                    {
                        consoleAppType: Contract.ConsoleAppType.User,
                        text: localization.fields.selector.user()
                    }
                ],
            []);

    const [consoleAppType, setConsoleAppType] =
        useState<Optional<SamlItemOptions>>(
            () =>
                _.isBoolean(addOrEditOpen)
                    ? undefined
                    : _.find(
                        items,
                        ({ consoleAppType }) => consoleAppType === addOrEditOpen.applications[0]));

    const theme = useTheme();
    return (
        <Stack spacing={3}>
            <Typography variant="h4">
                {localization.title()}
            </Typography>
            <ItemSelector
                disabled={!_.isBoolean(addOrEditOpen)}
                fullWidth={true}
                getItemDisabled={
                    (item: SamlItemOptions) => {
                        if (item.consoleAppType === Contract.ConsoleAppType.User &&
                            !_.includes(UserHelper.enabledConsoleAppTypes, Contract.ConsoleAppType.User)) {
                            return true;
                        }

                        return !_.isNil(consoleAppTypeToSamlIdentityProviderMap[item.consoleAppType]);
                    }}
                items={items}
                placeholder={localization.fields.selector.placeholder()}
                selectedItem={consoleAppType}
                sorted={false}
                onSelectedItemChanged={
                    (value: SamlItemOptions) => {
                        setConsoleAppType(value);
                        onConfigurationChange({
                            consoleAppType: value.consoleAppType,
                            metadataBytes: "",
                            metadataUrl: "",
                            urlMode: true
                        });
                        onValidChange(false);
                    }}>
                {({ text }) => text}
            </ItemSelector>
            <RadioGroup
                disabled={!configuration.consoleAppType}
                items={[
                    {
                        children:
                            <TextField
                                disabled={!configuration.consoleAppType}
                                placeholder={localization.fields.url.placeholder()}
                                sx={{ width: 300 }}
                                type="url"
                                value={configuration.metadataUrl}
                                onChange={
                                    event => {
                                        const metadataUrl = event.target.value;
                                        onConfigurationChange({
                                            ...configuration,
                                            metadataUrl,
                                            urlMode: true
                                        });
                                        onValidChange(!!metadataUrl);
                                    }}>
                            </TextField>,
                        label: localization.fields.url.text(),
                        value: true.toString()
                    },
                    {
                        children:
                            <Box>
                                {configuration.metadataBytes
                                    ? <Stack
                                        alignItems="center"
                                        direction="row"
                                        spacing={1}>
                                        <CheckCircle
                                            sx={{
                                                fill: theme.palette.success.main,
                                                fontSize: "16px"
                                            }}/>
                                        <Typography>
                                            {localization.fields.file.success()}
                                        </Typography>
                                        <IconButton
                                            size="small"
                                            onClick={
                                                () => {
                                                    onConfigurationChange({
                                                        ...configuration,
                                                        metadataBytes: ""
                                                    });
                                                    onValidChange(false);
                                                }}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </Stack>
                                    : <Button
                                        disabled={!configuration.consoleAppType}
                                        startIcon={<CloudUpload/>}
                                        variant="outlined"
                                        onClick={
                                            () => {
                                                if (configuration.urlMode) {
                                                    onConfigurationChange({
                                                        ...configuration,
                                                        urlMode: false
                                                    });
                                                }
                                                onValidChange(false);
                                                uploadFileButtonRef.current?.click();
                                            }}>
                                        {localization.fields.file.upload()}
                                    </Button>}
                            </Box>,
                        label: localization.fields.file.text(),
                        value: false.toString()
                    }
                ]}
                selectedValue={configuration.urlMode.toString()}
                onChange={
                    value => {
                        onConfigurationChange({
                            ...configuration,
                            urlMode: value === "true"
                        });
                    }}/>
            <FormControl>
                <InputBase
                    inputRef={uploadFileButtonRef}
                    slotProps={{ input: { accept: ".xml" } }}
                    sx={{ display: "none" }}
                    type="file"
                    onChange={
                        (event: React.ChangeEvent<HTMLInputElement>) => {
                            const metadataFilePath = event.target.files?.item(0);
                            event.target.value = "";
                            if (!metadataFilePath) {
                                return;
                            }
                            const fileReader = new FileReader();
                            fileReader.readAsDataURL(metadataFilePath);
                            fileReader.onloadend =
                                () => {
                                    const [, metadataBytes] = `${fileReader.result}`.split("xml;base64,");
                                    onConfigurationChange({
                                        ...configuration,
                                        metadataBytes,
                                        urlMode: false
                                    });
                                    onValidChange(true);
                                };
                        }}/>
            </FormControl>
        </Stack>);
}

type SamlItemOptions = {
    consoleAppType: Contract.ConsoleAppType.Customer | Contract.ConsoleAppType.User;
    text: string;
};