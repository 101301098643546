import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function DatabricksIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M23.9143 17.7714V13.5429L23.4571 13.2571L12.4857 19.2571L2.08571 13.5429V11.0857L12.4857 16.7429L23.9714 10.5143V6.34286L23.5143 6.05714L12.4857 12.1143L2.48571 6.62857L12.4857 1.14286L20.5429 5.54286L21.1714 5.2V4.74286L12.4857 0L1 6.22857V6.85714L12.4857 13.1429L22.8857 7.42857V9.94286L12.4857 15.6571L1.45714 9.6L1 9.88571V14.1143L12.4857 20.3429L22.8857 14.6857V17.1429L12.4857 22.8571L1.45714 16.8571L1 17.1429V17.7714L12.4857 24L23.9143 17.7714Z"
                fill="#FF3621"/>
        </SvgIcon>);
}