import { DataTableColumn, DataTableColumnRenderProps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, PagedEntityFilter } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useGcpCommonServiceAccountOriginatorScopeResourceDefinition(definitionData: DefinitionData) {
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpCommonServiceAccountOriginatorScopeResourceDefinition",
            () => ({
                columns: {
                    serviceAccount: "Service Account"
                }
            }));

    return {
        columns: {
            serviceAccountColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.GcpScopeResourceModel) => ({
                                [localization.columns.serviceAccount()]:
                                    _.isNil(_.as<Contract.IGcpServiceAccountOriginatorScopeResource>(item.entity).serviceAccountData.serviceAccountMail)
                                        ? ""
                                        : _.as<Contract.IGcpServiceAccountOriginatorScopeResource>(item.entity).serviceAccountData.serviceAccountMail
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.GcpServiceAccountOriginatorScopeResourceServiceAccount)}
                                    placeholder={localization.columns.serviceAccount()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.GcpServiceAccountOriginatorScopeResourceServiceAccount}
                    key={Contract.EntityModelProperty.GcpServiceAccountOriginatorScopeResourceServiceAccount}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.IGcpServiceAccountOriginatorScopeResourceModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.serviceAccountIdReference}
                                entityTypeName={Contract.TypeNames.GcpIamServiceAccount}/>}
                    title={localization.columns.serviceAccount()}/>
        }
    };
}