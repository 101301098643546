﻿import { DataTableColumn, optionalTableCell, PagedValuesFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useAadDirectoryApplicationObjectDefinition } from "./useAadDirectoryApplicationObjectDefinition";

export function useAadDirectoryApplicationCertificateDefinition(definitionData: DefinitionData) {
    const commonDirectoryApplicationObjectDefinition = useAadDirectoryApplicationObjectDefinition(definitionData);

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aad.useAadDirectoryApplicationCertificateDefinition",
            () => ({
                columns: {
                    thumbprint: "Thumbprint"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            commonDirectoryApplicationObjectDefinition.columns.creationTimeColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AadDirectoryApplicationCertificateModel) => ({
                            [localization.columns.thumbprint()]: (item.entity as Contract.AadDirectoryApplicationCertificate).thumbprint ?? ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AadDirectoryApplicationCertificateThumbprint)}
                                placeholder={localization.columns.thumbprint()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AadDirectoryApplicationCertificateThumbprint}
                key={Contract.EntityModelProperty.AadDirectoryApplicationCertificateThumbprint}
                render={optionalTableCell<Contract.AadDirectoryApplicationObjectModel>(item => (item.entity as Contract.AadDirectoryApplicationCertificate).thumbprint)}
                title={localization.columns.thumbprint()}/>,
            commonDirectoryApplicationObjectDefinition.columns.tenantColumn,
            commonDirectoryApplicationObjectDefinition.columns.application,
            commonDirectoryApplicationObjectDefinition.columns.applicationOwnerPrincipal,
            commonDirectoryApplicationObjectDefinition.columns.expirationTime,
            commonDirectoryApplicationObjectDefinition.columns.expirationStatus,
            commonDirectoryApplicationObjectDefinition.columns.attributesColumn,
            commonDirectoryApplicationObjectDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonDirectoryApplicationObjectDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonDirectoryApplicationObjectDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAadDirectoryApplicationCertificateRequest(
                new Contract.EntityControllerGetEntityModelPageAadDirectoryApplicationCertificateRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.AadDirectoryEntityCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryApplicationObjectApplication]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryApplicationObjectApplicationOwnerPrincipals]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryApplicationObjectExpirationStatus]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.AadDirectoryApplicationObjectExpirationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryApplicationCertificateThumbprint])),
                limit,
                skip,
                requestSort));
}