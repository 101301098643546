import { InlineItems, Optional, useExecuteOperation, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React, { useMemo } from "react";
import { useEntityRiskContext } from "../../..";
import { Contract, entityModelStore, InlineVulnerability, useEntityTypeNameTranslator, WorkloadController } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useResourceGeneralInformationStep } from "../../../useResourceGeneralInformationStep";
import { VulnerabilitiesInlineItems } from "../../components";

export function useGetWorkloadAnalysisRiskContext() {
    return useMemo(
        () => useWorkloadAnalysisRiskContext,
        []);
}

export function useWorkloadAnalysisRiskContext(risk: Contract.WorkloadAnalysisRisk, vulnerabilitySeverityToRawIdsMap?: Dictionary<string[]>) {
    const riskEntityModel = entityModelStore.useGet(risk.entityId);
    const riskEntityRiskContext = useEntityRiskContext(riskEntityModel);
    const resourceGeneralInformationStep = useResourceGeneralInformationStep(riskEntityModel);

    [vulnerabilitySeverityToRawIdsMap] =
        useExecuteOperation(
            [useWorkloadAnalysisRiskContext, risk.id],
            async () => {
                if (!_.isNil(vulnerabilitySeverityToRawIdsMap)) {
                    return vulnerabilitySeverityToRawIdsMap;
                }

                const getWorkloadResourceRiskVulnerabilityRawIdsResponse =
                    await WorkloadController.getWorkloadResourceRiskVulnerabilityRawIds(
                        new Contract.WorkloadControllerGetWorkloadResourceRiskVulnerabilityRawIdsRequest(
                            risk.id,
                            undefined));
                return getWorkloadResourceRiskVulnerabilityRawIdsResponse.vulnerabilitySeverityToRawIdsMap;
            });
    const vulnerabilityRawIds = _.flatMap(vulnerabilitySeverityToRawIdsMap);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.workloadAnalysis.hooks.contexts.useGetWorkloadAnalysisRiskContext",
            () => ({
                vulnerabilities: {
                    knownExploit: " ({{exploitableVulnerabilityCount}} with a known exploit)",
                    resolvableOperatingSystemPackageVulnerabilitiesSuffix: {
                        [Contract.TypeNames.OperatingSystemType]: {
                            [Contract.OperatingSystemType.Linux]: " with known fixes that are related to OS packages",
                            [Contract.OperatingSystemType.Windows]: " with known fixes that are related to the operating system"
                        }
                    },
                    resolvableVulnerabilitiesSuffix: " with known fixes",
                    vulnerabilities: [
                        " 1 vulnerability",
                        " {{count | NumberFormatter.humanize}} vulnerabilities"
                    ],
                    vulnerabilitiesLine: "The {{translatedEntityTypeName}} contains {{vulnerabilities}}{{knownExploit}}"
                }
            }));

    const translatedRiskEntityTypeName =
        entityTypeNameTranslator(
            riskEntityModel.entity.typeName,
            { includeServiceName: false });

    const vulnerabilitySeverityToExploitableCountMap =
        {
            [Contract.Severity.Critical]: _.size(
                _.filter(
                    risk.criticalVulnerabilities ?? [],
                    criticalVulnerability => criticalVulnerability.exploitable)),
            [Contract.Severity.High]: _.size(
                _.filter(
                    risk.highVulnerabilities ?? [],
                    highVulnerability => highVulnerability.exploitable)),
            [Contract.Severity.Medium]: 0,
            [Contract.Severity.Low]: 0,
            [Contract.Severity.Information]: 0
        };

    const getSeverityVulnerabilities =
        (severity: Contract.Severity, translatedEntityTypeName: Optional<string> = undefined) =>
            _.isEmpty(vulnerabilitySeverityToRawIdsMap![severity])
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.vulnerabilities.vulnerabilitiesLine({
                        knownExploit:
                            vulnerabilitySeverityToExploitableCountMap[severity] == 0
                                ? ""
                                : localization.vulnerabilities.knownExploit({ exploitableVulnerabilityCount: vulnerabilitySeverityToExploitableCountMap[severity] }),
                        translatedEntityTypeName: translatedEntityTypeName ?? translatedRiskEntityTypeName,
                        vulnerabilities:
                            <VulnerabilitiesInlineItems
                                severity={severity}
                                severityVulnerabilityRawIds={vulnerabilitySeverityToRawIdsMap![severity]}/>
                    }));
    return {
        ...riskEntityRiskContext,
        generalInformation:
            _.isNil(resourceGeneralInformationStep)
                ? undefined
                : new RiskDefinitionContextItem(resourceGeneralInformationStep),
        getSeverityVulnerabilities,
        getVulnerabilitiesProps: (
            operatingSystemType: Optional<Contract.OperatingSystemType>,
            displayResolvableOperatingSystemPackageVulnerabilities: boolean = false,
            displayResolvableVulnerabilities: boolean = false) =>
            _.isEmpty(vulnerabilityRawIds)
                ? undefined
                : ({
                    resolvableVulnerabilitiesSuffix:
                        displayResolvableVulnerabilities
                            ? displayResolvableOperatingSystemPackageVulnerabilities && !_.isNil(operatingSystemType)
                                ? localization.vulnerabilities.resolvableOperatingSystemPackageVulnerabilitiesSuffix[Contract.TypeNames.OperatingSystemType][operatingSystemType!]()
                                : localization.vulnerabilities.resolvableVulnerabilitiesSuffix()
                            : "",
                    vulnerabilities:
                        <InlineItems
                            items={
                                _(vulnerabilityRawIds).
                                    orderBy().
                                    map(
                                        vulnerabilityRawId =>
                                            <InlineVulnerability
                                                key={vulnerabilityRawId}
                                                rawId={vulnerabilityRawId}/>).
                                    value()}
                            namePluralizer={localization.vulnerabilities.vulnerabilities}
                            variant="itemCountAndType"/>
                }),
        severityVulnerabilities:
            _(Contract.Severity).
                values().
                reverse().
                map(severity => getSeverityVulnerabilities(severity)).
                filter().
                value()
    };
}