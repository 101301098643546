import { Box } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Link, Message, ToggleList, ToggleListItem, useChangeEffect, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { Contract, CustomerConsoleAppUrlHelper, FeatureHelper, LicensingHelper, useTheme } from "../../../../../../../../../../../../common";
import { useAddOrEditContext, useSetAddOrEditContext } from "../../index";
import { TerraformStateBucketMessage, TrailBucket } from "./components";

export function PermissionsItem() {
    const { executing, setValid } = useOrderedWizardContext();
    const addOrEditContext = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();

    const [permissionTypes, setPermissionTypes] = useState(addOrEditContext.tenantPermissionTypes);
    const [trailBucketEnabled, setTrailBucketEnabled] = useState(addOrEditContext.tenantTrailBucketEnabled);
    const [terraformStateBucketEnabled, setTerraformStateBucketEnabled] = useState(addOrEditContext.tenantTerraformStateBucketEnabled);

    const [trailBucketValid, setTrailBucketValid] = useState(true);
    useEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    tenantPermissionTypes: permissionTypes,
                    tenantTerraformStateBucketEnabled: terraformStateBucketEnabled,
                    tenantTrailBucketEnabled: trailBucketEnabled
                }));

            setValid(
                trailBucketEnabled
                    ? trailBucketValid
                    : _.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.Read));
        },
        [terraformStateBucketEnabled, trailBucketEnabled, trailBucketValid, permissionTypes]);

    useChangeEffect(
        () => {
            if (!_.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.Read)) {
                setPermissionTypes([]);
            }
        },
        [permissionTypes]);

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAwsDefinition.addOrEdit.permissionsItem",
            () => ({
                audit: {
                    error: "Your account will not be monitored, and assets will not be visible in the inventory or scanned for misconfigurations. It is recommended to monitor all accounts"
                },
                fields: {
                    permissions: {
                        containerImageRepositoryAnalysis: {
                            subtitle: "Scan container registries for vulnerabilities and misconfigurations. For more information about required permissions, see the {{docsLink}}",
                            title: "ECR Scanning (Workload Protection)"
                        },
                        dataAnalysis: {
                            subtitle: "Scan and classify cloud resources. For more information about enabling data protection and required permissions, see the {{docsLink}}",
                            title: "Data Resources Scanning (Data Protection)"
                        },
                        docsLink: "documentation",
                        instanceAnalysis: {
                            subtitle: "Scan EC2 Instances for vulnerabilities and misconfigurations. For more information about required permissions, see the {{docsLink}}",
                            title: "EC2 Instance Scanning (Workload Protection)"
                        },
                        instanceAndImageAnalysis: {
                            subtitle: "Scan EC2 instances and AMI (private and public machine images) for vulnerabilities and misconfigurations. For more information about required permissions, see the {{docsLink}}",
                            title: "EC2 Instance and AMI Scanning (Workload Protection)"
                        },
                        instanceAndInternalImageAnalysis: {
                            subtitle: "Scan EC2 instances and internal AMIs for vulnerabilities and misconfigurations. For more information about required permissions, see the {{docsLink}}",
                            title: "EC2 Instance and Internal AMI Scanning (Workload Protection)"
                        },
                        permissionManagement: {
                            subtitle: "Grant users access to cloud accounts for a predetermined period of time and on an as-needed basis. If the account you are onboarding is the management account, or has permissions to manage AWS Identity Center, additional permissions are required. For more information about required permissions, see the {{docsLink}}",
                            title: "Just-In-Time Access (AWS Identity Center)"
                        },
                        read: {
                            subtitle: "Gain full visibility for all cloud assets, including information about permissions, account usage, and security configurations. Additional permissions may be required to read the CloudTrail for the onboarded account. For more information about required permissions, see the {{docsLink}}",
                            title: "Monitoring (read-only)"
                        },
                        terraformStateBucket: {
                            subtitle: "To analyze Terraform resources, permissions are required to read from S3 buckets storing the state data. For more information about required permissions, see the {{docsLink}}",
                            title: "Terraform State (S3)"
                        },
                        title: "Choose which features to enable, keeping in mind that each feature requires different permissions. You can modify your selection after the initial onboarding",
                        trailBucket: {
                            subtitle: "To analyze cloud activity logs, permissions are required to read from the S3 bucket storing CloudTrail data. If the bucket belongs to the account you are onboarding, select this option. If the bucket belongs to a different account, you will need to onboard it separately after completing this wizard. For more information about required permissions, see the {{docsLink}}",
                            title: "CloudTrail (S3)"
                        },
                        write: {
                            subtitle: "Allow Tenable to make changes in your environment. This lets you automatically remediate findings with one click. For more information about required permissions, see the {{docsLink}}",
                            title: "Remediation (read-write)"
                        }
                    }
                }
            }));

    const workloadAnalysisLocalization =
        !FeatureHelper.enabled(Contract.FeatureName.AwsEc2MachineImageAnalysisEnabled)
            ? localization.fields.permissions.instanceAnalysis
            : FeatureHelper.enabled(Contract.FeatureName.AwsEc2ExternalMachineImageAnalysisEnabled)
                ? localization.fields.permissions.instanceAndImageAnalysis
                : localization.fields.permissions.instanceAndInternalImageAnalysis;

    const theme = useTheme();
    return (
        <ToggleList
            selectedValues={
                _<ToggleListValue>(permissionTypes).
                    concatIf(
                        trailBucketEnabled,
                        "trailBucket").
                    concatIf(
                        terraformStateBucketEnabled,
                        "terraformStateBucket").
                    value()}
            sx={{ maxWidth: theme.spacing(80) }}
            title={localization.fields.permissions.title()}
            onSelectedValuesChanged={
                selectedValues => {
                    setTrailBucketEnabled(_.includes(selectedValues, "trailBucket"));
                    setTerraformStateBucketEnabled(_.includes(selectedValues, "terraformStateBucket"));
                    setPermissionTypes(_.intersection(selectedValues, _.values(Contract.CloudProviderTenantPermissionType)) as Contract.CloudProviderTenantPermissionType[]);
                }}>
            <ToggleListItem
                contentElement={
                    _.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.Read)
                        ? undefined
                        : <Box sx={{ paddingLeft: theme.spacing(5) }}>
                            <Message
                                level="warning"
                                title={localization.audit.error()}
                                variant="standard"/>
                        </Box>}
                disabled={
                    !_.isNil(addOrEditContext.tenantModel) &&
                    _.includes(addOrEditContext.tenantModel.configuration.permissionTypes, Contract.CloudProviderTenantPermissionType.Read)}
                subtitle={
                    localization.fields.permissions.read.subtitle({
                        docsLink:
                            <Link
                                urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsOnboardAwsTenableReadonlyPolicyRelativeUrl()}
                                variant="external">
                                {localization.fields.permissions.docsLink()}
                            </Link>
                    })}
                title={localization.fields.permissions.read.title()}
                value={Contract.CloudProviderTenantPermissionType.Read}/>
            <ToggleListItem
                disabled={!_.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.Read)}
                subtitle={
                    localization.fields.permissions.write.subtitle({
                        docsLink:
                            <Link
                                urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsOnboardAwsTenableManagementPolicyRelativeUrl()}
                                variant="external">
                                {localization.fields.permissions.docsLink()}
                            </Link>
                    })}
                title={localization.fields.permissions.write.title()}
                value={Contract.CloudProviderTenantPermissionType.Write}/>
            {LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm) &&
                <ToggleListItem
                    disabled={!_.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.Read)}
                    subtitle={
                        localization.fields.permissions.dataAnalysis.subtitle({
                            docsLink:
                                <Link
                                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsOnboardAwsDataProtectionPolicyRelativeUrl()}
                                    variant="external">
                                    {localization.fields.permissions.docsLink()}
                                </Link>
                        })}
                    title={localization.fields.permissions.dataAnalysis.title()}
                    value={Contract.CloudProviderTenantPermissionType.DataAnalysis}/>}
            {LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp) &&
                <ToggleListItem
                    subtitle={
                        workloadAnalysisLocalization.subtitle({
                            docsLink:
                                <Link
                                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsOnboardAwsWorkloadProtectionPolicyRelativeUrl()}
                                    variant="external">
                                    {localization.fields.permissions.docsLink()}
                                </Link>
                        })
                    }
                    title={localization.fields.permissions.instanceAnalysis.title()}
                    value={Contract.CloudProviderTenantPermissionType.WorkloadAnalysis}/>}
            {LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp) &&
                <ToggleListItem
                    disabled={!_.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.Read)}
                    subtitle={
                        localization.fields.permissions.containerImageRepositoryAnalysis.subtitle({
                            docsLink:
                                <Link
                                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsOnboardAwsWorkloadProtectionPolicyRelativeUrl()}
                                    variant="external">
                                    {localization.fields.permissions.docsLink()}
                                </Link>
                        })
                    }
                    title={localization.fields.permissions.containerImageRepositoryAnalysis.title()}
                    value={Contract.CloudProviderTenantPermissionType.ContainerImageRepositoryAnalysis}/>}
            {LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.PermissionManagement) &&
                <ToggleListItem
                    disabled={!_.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.Read)}
                    subtitle={
                        localization.fields.permissions.permissionManagement.subtitle({
                            docsLink:
                                <Link
                                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsOnboardAwsJitPolicyRelativeUrl()}
                                    variant="external">
                                    {localization.fields.permissions.docsLink()}
                                </Link>
                        })}
                    title={localization.fields.permissions.permissionManagement.title()}
                    value={Contract.CloudProviderTenantPermissionType.PermissionManagement}/>}
            <ToggleListItem
                contentElement={
                    trailBucketEnabled
                        ? <TrailBucket
                            executing={executing}
                            onValidChanged={setTrailBucketValid}/>
                        : undefined}
                subtitle={
                    localization.fields.permissions.trailBucket.subtitle({
                        docsLink:
                            <Link
                                urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsOnboardAwsCloudtrailLogsRelativeUrl()}
                                variant="external">
                                {localization.fields.permissions.docsLink()}
                            </Link>
                    })}
                title={localization.fields.permissions.trailBucket.title()}
                value="trailBucket"/>
            {!_.isEmpty(addOrEditContext.tenantModel?.state.codeAnalysis.terraformStateBucketNameToEncryptionKeyArnsMap) &&
                <ToggleListItem
                    contentElement={
                        terraformStateBucketEnabled
                            ? <TerraformStateBucketMessage/>
                            : undefined}
                    subtitle={
                        localization.fields.permissions.terraformStateBucket.subtitle({
                            docsLink:
                                <Link
                                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsTraceIssuesBackToCodeRelativeUrl()}
                                    variant="external">
                                    {localization.fields.permissions.docsLink()}
                                </Link>
                        })}
                    title={localization.fields.permissions.terraformStateBucket.title()}
                    value="terraformStateBucket"/>}
        </ToggleList>);
}

type ToggleListValue = Contract.CloudProviderTenantPermissionType | "terraformStateBucket" | "trailBucket";