import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, PagedValuesFilter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../../..";
import { useAwsCommonAccessResourceDefinition, useAwsCommonEncryptedResourceDefinition, useAwsCommonResourceDefinition } from "..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, SeverityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../../common";
import { useGetAwsEcrRepositoryContainerImageVulnerabilitiesCsvItem } from "../../../../../../../hooks";
import { DefinitionData } from "../../../../../Table";
import { AwsEcrRepositoryVulnerabilitiesCell } from "./components";

export function useAwsEcrRepositoryDefinition(definitionData: DefinitionData) {
    const commonAccessResourceDefinition = useAwsCommonAccessResourceDefinition(definitionData);
    const commonEncryptedResourceDefinition = useAwsCommonEncryptedResourceDefinition(definitionData);
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const getAwsEcrRepositoryContainerImageVulnerabilitiesCsvItem = useGetAwsEcrRepositoryContainerImageVulnerabilitiesCsvItem();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsEcrRepositoryDefinition",
            () => ({
                columns: {
                    containerImageResources: "Used By",
                    containerImageVulnerabilities: "Vulnerabilities",
                    containerImageVulnerabilitySeverities: "Vulnerability Severity",
                    rawUrl: "URI"
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            commonAccessResourceDefinition.columns.accessLevelColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsEcrRepositoryModel) => ({
                            [localization.columns.rawUrl()]: (item.entity as Contract.AwsEcrRepository).rawUrl
                        })
                }}
                id="rawUri"
                key="rawUri"/>,
            <DataTableColumn
                cellMinWidth={110}
                exportOptions={{
                    getItem:
                        (item: Contract.AwsEcrRepositoryModel) => ({
                            [localization.columns.containerImageVulnerabilities()]:
                                getAwsEcrRepositoryContainerImageVulnerabilitiesCsvItem(
                                    item.containerImageScanStatus,
                                    item.containerImageVulnerabilities)
                        })
                }}
                filterOptions={{
                    itemOrItems: [
                        {
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsEcrRepositoryContainerImageVulnerabilities)}
                                    placeholder={localization.columns.containerImageVulnerabilities()}/>,
                            id: Contract.EntityModelProperty.AwsEcrRepositoryContainerImageVulnerabilities,
                            title: localization.columns.containerImageVulnerabilities()
                        },
                        {
                            element:
                                <SeverityFilter placeholder={localization.columns.containerImageVulnerabilitySeverities()}/>,
                            id: Contract.EntityModelProperty.AwsEcrRepositoryContainerImageVulnerabilitySeverities,
                            title: localization.columns.containerImageVulnerabilitySeverities()
                        }
                    ]
                }}
                id={Contract.EntityModelProperty.AwsEcrRepositoryContainerImageVulnerabilities}
                key={Contract.EntityModelProperty.AwsEcrRepositoryContainerImageVulnerabilities}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsEcrRepositoryModel>) =>
                        <AwsEcrRepositoryVulnerabilitiesCell
                            containerImageScanStatus={item.containerImageScanStatus}
                            scanningConfiguration={(item.entity as Contract.AwsEcrRepository).scanningConfiguration}
                            vulnerabilities={item.containerImageVulnerabilities}/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.containerImageVulnerabilities()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsEcrRepositoryModel>(
                        Contract.TypeNames.IContainerImageResource,
                        item => item.containerImageResourceIds,
                        localization.columns.containerImageResources())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsEcrRepositoryContainerImageResources)}
                                placeholder={localization.columns.containerImageResources()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsEcrRepositoryContainerImageResources}
                key={Contract.EntityModelProperty.AwsEcrRepositoryContainerImageResources}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsEcrRepositoryModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.containerImageResourceIds}
                            entityTypeName={Contract.TypeNames.IContainerImageResource}
                            entityVariant="iconText"/>}
                title={localization.columns.containerImageResources()}/>,
            commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
            commonEncryptedResourceDefinition.columns.kmsEncryptionKeyColumn,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsEcrRepositoryRequest(
                new Contract.EntityControllerGetEntityModelPageAwsEcrRepositoryRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEcrRepositoryContainerImageResources]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEcrRepositoryContainerImageVulnerabilities]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEcrRepositoryContainerImageVulnerabilitySeverities])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestAccess(ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsResourceAccessLevel])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceKmsEncryptionKeys]))));
}