import _ from "lodash";
import React, { memo } from "react";
import { ValuesFilter, ValuesFilterItem, ValuesFilterProps } from ".";
import { Optional } from "../../../types";

const TextValuesFilterMemo = memo(TextValuesFilter);
export { TextValuesFilterMemo as TextValuesFilter };

type TextValuesFilterProps =
    Omit<ValuesFilterProps, "children"> & {
        emptyValue?: boolean;
        values: string[] | Optional<string>[];
    };

function TextValuesFilter({ emptyValue, onValuePageLoading, placeholder, sorted = true, values }: TextValuesFilterProps) {
    return (
        <ValuesFilter
            emptyValueOptions={
                _.isNil(emptyValue)
                    ? undefined
                    : { enabled: emptyValue }}
            placeholder={placeholder}
            sorted={sorted}
            onValuePageLoading={onValuePageLoading}>
            {_.map(
                values,
                value =>
                    <ValuesFilterItem
                        key={value}
                        value={value}/>)}
        </ValuesFilter>);
}