import { NumberFormatter, TimeSpanFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../components";
import { useAwsCommonCloudWatchLoggingResourceInfoElements } from "./useAwsCommonCloudWatchLoggingResourceInfoElements";

export function useAwsCommonRdsClusterResourceInfoElements(
    clusterResourceModel: Contract.AwsRdsClusterResourceModel,
    databaseInstanceTypeName: string,
    globalClusterTypeName: string,
    snapshotTypeName: string) {
    const clusterResource = clusterResourceModel.entity as Contract.AwsRdsClusterResource;
    const commonCloudWatchLoggingResourceInfoElements = useAwsCommonCloudWatchLoggingResourceInfoElements(clusterResourceModel);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsCommonRdsClusterResourceInfoElements",
            () => ({
                info: {
                    items: {
                        availabilityZones: "Availability Zones",
                        backupRetentionTimeFrame: "Automatic Backup Retention Period",
                        deleteProtection: {
                            disabled: "Disabled",
                            enabled: "Enabled",
                            title: "Delete Protection"
                        },
                        engineVersion: "Engine Version",
                        status: "Status",
                        storageSize: "Size"
                    }
                }
            }));

    return {
        ...commonCloudWatchLoggingResourceInfoElements,
        availabilityZones:
            <VerticalTopItemsInfoItem
                items={
                    _.map(
                        clusterResource.availabilityZones,
                        availabilityZone => availabilityZone.name)}
                key="availabilityZones"
                title={localization.info.items.availabilityZones()}/>,
        backupRetentionTimeFrame:
            <InfoItem
                key="backupRetentionTimeFrame"
                title={localization.info.items.backupRetentionTimeFrame()}
                value={TimeSpanFormatter.day(clusterResource.backupRetentionTimeFrame)}/>,
        deleteProtection:
            <InfoItem
                key="deleteProtection"
                title={localization.info.items.deleteProtection.title()}
                value={
                    clusterResource.deleteProtection
                        ? localization.info.items.deleteProtection.enabled()
                        : localization.info.items.deleteProtection.disabled()}/>,
        engineVersion:
            <InfoItem
                key="engineVersion"
                title={localization.info.items.engineVersion()}
                value={clusterResource.engineVersion}/>,
        globalCluster:
            <EntitiesInfoItem
                entityIdsOrModels={clusterResource.globalClusterId}
                entityTypeName={globalClusterTypeName}
                key="globalCluster"/>,
        instances:
            <EntitiesInfoItem
                entityIdsOrModels={clusterResourceModel.instanceIds}
                entityTypeName={databaseInstanceTypeName}
                key="instances"/>,
        snapshots:
            <EntitiesInfoItem
                entityIdsOrModels={clusterResourceModel.snapshotIds}
                entityTypeName={snapshotTypeName}
                key="snapshots"/>,
        status:
            <InfoItem
                key="status"
                title={localization.info.items.status()}
                value={clusterResource.status}/>,
        storageSize:
            <InfoItem
                key="storageSize"
                title={localization.info.items.storageSize()}
                value={NumberFormatter.storage(clusterResource.storageSize)}/>
    };
}