import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../../../controllers";

export function useEntityMatchConditionCollectionOperatorTranslator() {
    const localization =
        useLocalization(
            "common.customRiskPolicy.entityMultiSelect.condition.hooks.useEntityMatchConditionCollectionOperatorTranslator",
            () => ({
                [Contract.EntityMatchConditionCollectionOperator.Overlap]: "any",
                [Contract.EntityMatchConditionCollectionOperator.Subset]: "all"
            }));
    return (type: Contract.EntityMatchConditionCollectionOperator) => localization[type]();
}