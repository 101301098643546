import { useLocalization } from "@infrastructure";
import React from "react";
import { useOciCommonIamResourceInfoItemElements } from ".";
import { Contract } from "../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../components";

export function useOciCommonIamPrincipalInfoItemElements(principalModel: Contract.OciIamPrincipalModel) {
    const commonIamResourceInfoItemElements = useOciCommonIamResourceInfoItemElements(principalModel);
    const principal = principalModel.entity as Contract.OciIamPrincipal;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciCommonIamPrincipalInfoItemElements",
            () => ({
                info: {
                    items: {
                        domain: "Domain"
                    }
                }
            }));
    return {
        ...commonIamResourceInfoItemElements,
        domainIdInfoItemElement:
            <EntitiesInfoItem
                entityIdsOrModels={principal.domainId}
                entityTypeName={Contract.TypeNames.OciIamDomain}
                key="domainId"
                location="all"
                title={localization.info.items.domain()}/>
    };
}