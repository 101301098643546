import _ from "lodash";
import { useMemo } from "react";
import { Contract, RiskType } from "../../../../../../../../../../../../../../../../../common";
import { useTableCommonCodeColumns } from "../../../../../../../../../../../hooks";
import { TableDefinition } from "../../../useDefinition";
import { useCommonColumns } from "../../useCommonColumns";

type CommonCodeTableDefinition = Pick<TableDefinition, "getRiskModelPageRequest" | "getRisksReportRequestDefinition">;

export function useCommonCodeTableDefinition({ getRiskModelPageRequest, getRisksReportRequestDefinition }: CommonCodeTableDefinition): TableDefinition {
    const commonColumns = useCommonColumns();
    const commonCodeColumns = useTableCommonCodeColumns(false);

    return useMemo(
        () => ({
            columns:
                _.filter([
                    commonColumns.statusUpdateTimeColumn,
                    commonColumns.severityColumn,
                    commonColumns.riskPolicyIdColumn,
                    commonCodeColumns.repository,
                    commonCodeColumns.file,
                    commonCodeColumns.resource,
                    commonColumns.statusColumn(false),
                    commonColumns.subStatusColumn(false),
                    commonColumns.openStatusUpdateTimeColumn,
                    commonColumns.systemCreationTimeColumn,
                    commonColumns.compliancesColumn,
                    commonColumns.actionsCell(RiskType.Code)
                ]),
            getRiskIdToScopeIdentityPermissionsMapRequest: requestFilters => new Contract.RiskControllerGetCodeRiskIdToScopeIdentityPermissionsMapRequest(requestFilters),
            getRiskModelPageRequest,
            getRisksReportRequestDefinition
        }),
        [commonCodeColumns, commonColumns, getRiskModelPageRequest]);
}