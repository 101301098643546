import { useLocalization } from "@infrastructure";
import React from "react";
import { InfoCard } from "../../../../../../../../../../../../common";
import { GcpComputeFirewallRulesTable } from "../../../../../../../../../../../../tenants";

type FirewallRulesInfoCardProps = {
    firewallRuleIds: string[];
};

export function FirewallRulesInfoCard({ firewallRuleIds }: FirewallRulesInfoCardProps) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpComputeVpcDefinition.firewallRulesInfoCard",
            () => ({
                title: "Firewall Rules"
            }));
    return (
        <InfoCard title={localization.title()}>
            <GcpComputeFirewallRulesTable firewallRuleIds={firewallRuleIds}/>
        </InfoCard>);
}