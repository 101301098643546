import { CopyToClipboardTextField, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "../../../../../../../../../common";

type SuccessProps = {
    token: string;
};

export function Success({ token }: SuccessProps) {
    const localization =
        useLocalization(
            "views.customer.configuration.apiKeys.add.success",
            () => ({
                description: "Before closing this window be sure to copy the token value as, for security reasons, you will not be able to view it again",
                fields: {
                    token: "Token Value"
                },
                subtitle: "Token created successfully!"
            }));
    const theme = useTheme();
    return (
        <Stack spacing={3}>
            <Typography
                sx={{ color: theme.palette.success.main }}
                variant="h4">
                {localization.subtitle()}
            </Typography>
            <Typography sx={{ fontSize: "13px" }}>
                {localization.description()}
            </Typography>
            <Stack spacing={1}>
                <Typography sx={{ fontSize: "14px" }}>
                    {localization.fields.token()}
                </Typography>
                <CopyToClipboardTextField value={token}/>
            </Stack>
        </Stack>);
}