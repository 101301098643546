import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../common";

export function useOciConsoleSignInStepTranslator() {
    const localization =
        useLocalization(
            "tenants.oci.hooks.useOciConsoleSignInStepTranslator",
            () => ({
                signInStep: "Sign in to the {{link}}",
                view: {
                    [Contract.OciConsoleView.BlockStorage]: "Block Storage console",
                    [Contract.OciConsoleView.Compute]: "Compute console",
                    [Contract.OciConsoleView.ContainerEngine]: "OKE console",
                    [Contract.OciConsoleView.Fss]: "File Storage console",
                    [Contract.OciConsoleView.NetworkCommandCenter]: "Network Command Center console",
                    [Contract.OciConsoleView.Networking]: "Networking console",
                    [Contract.OciConsoleView.Kms]: "Key Management & Secret Management console",
                    [Contract.OciConsoleView.ObjectStorage]: "Object Storage console",
                    [Contract.OciConsoleView.PasswordPolicy]: "Domain Password Policy page",
                    [Contract.OciConsoleView.User]: "User console"
                }
            }));
    return (view: Contract.OciConsoleView, url: string) =>
        localization.signInStep({
            link:
                <Link
                    urlOrGetUrl={url}
                    variant="external">
                    {localization.view[view]()}
                </Link>
        });
}