import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, DeferredFilter, Optional, optionalTableCell, TimeRangeFilter, useLocalization, ValueFilter } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useActivityTranslator, useEntitiesExportOptions, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { GcpRoleBindingHelper } from "../../../../../../../../../../tenants";
import { DefinitionData } from "../../../../Table";
import { useCommonEntityDefinition } from "../useCommonEntityDefinition";

export function useGcpIamPartialServiceAccountDefinition(definitionData: DefinitionData) {
    const partialServiceAccountModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.GcpIamPartialServiceAccountModelFilters>;
    const activityTranslator = useActivityTranslator();
    const commonEntityDefinition = useCommonEntityDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpIamPartialServiceAccountDefinition",
            () => ({
                columns: {
                    activityTime: {
                        helpText: "The last time an activity was seen in GCP for this identity. This is highly dependent on the logging configuration. Activity that was not logged will not be taken into account.",
                        title: "Activity Time"
                    },
                    containedRoleBindingIds: {
                        helpText: "These role bindings are \"covered\" by another role binding at a higher scope level or one that is assigned via group membership.",
                        title: "Covered GCP Role Bindings"
                    },
                    groupIds: "Group Memberships",
                    roleBindingIds: "GCP Role Bindings",
                    roleIds: "GCP Roles"
                }
            }));

    return new EntityTableDefinition(
        [
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpIamPartialServiceAccountModel) => ({
                            [localization.columns.activityTime.title()]: activityTranslator(item.access?.activity) ?? ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={partialServiceAccountModelFiltersPromise}
                                title={localization.columns.activityTime.title()}>
                                {filters =>
                                    <TimeRangeFilter
                                        emptyValue={filters.activityTimeRange.emptyValue}
                                        placeholder={localization.columns.activityTime.title()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.activityTimeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.GcpIamPartialServiceAccountActivityTime}
                key={Contract.EntityModelProperty.GcpIamPartialServiceAccountActivityTime}
                message={localization.columns.activityTime.helpText()}
                messageLevel="info"
                render={
                    optionalTableCell<Contract.GcpIamPartialServiceAccountModel>(
                        partialServiceAccountModel =>
                            activityTranslator(partialServiceAccountModel.access!.activity))}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.activityTime.title()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpIamPartialServiceAccountModel>(
                        Contract.TypeNames.GciDirectoryGroup,
                        item => item.groupIds,
                        localization.columns.groupIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpIamPartialServiceAccountGroups)}
                                placeholder={localization.columns.groupIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpIamPartialServiceAccountGroups}
                key={Contract.EntityModelProperty.GcpIamPartialServiceAccountGroups}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpIamPartialServiceAccountModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.groupIds}
                            entityTypeName={Contract.TypeNames.GciDirectoryGroup}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.groupIds()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpIamPartialServiceAccountModel>(
                        Contract.TypeNames.GcpIamRoleBinding,
                        item => item.roleBindingIds,
                        localization.columns.roleBindingIds(),
                        { getValue: entityModel => GcpRoleBindingHelper.getDisplayName(entityTypeNameTranslator, entityModel as Contract.GcpIamRoleBindingModel) })
                }
                id={Contract.EntityModelProperty.GciPrincipalGcpRoleBindings}
                key={Contract.EntityModelProperty.GciPrincipalGcpRoleBindings}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpIamPartialServiceAccountModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.roleBindingIds}
                            entityTypeName={Contract.TypeNames.GcpIamRoleBinding}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.roleBindingIds()}/>,
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpIamPartialServiceAccountRoles)}
                                placeholder={localization.columns.roleIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpIamPartialServiceAccountRoles}
                key={Contract.EntityModelProperty.GcpIamPartialServiceAccountRoles}
                title={localization.columns.roleIds()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpIamPartialServiceAccountModel>(
                        Contract.TypeNames.GcpIamRoleBinding,
                        item => item.containedRoleBindingIds,
                        localization.columns.containedRoleBindingIds.title(),
                        { getValue: entityModel => GcpRoleBindingHelper.getDisplayName(entityTypeNameTranslator, entityModel as Contract.GcpIamRoleBindingModel) })
                }
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValueFilter
                                items={[
                                    {
                                        value: true
                                    }
                                ]}
                                placeholder={localization.columns.containedRoleBindingIds.title()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GciPrincipalContainedGcpRoleBindings}
                key={Contract.EntityModelProperty.GciPrincipalContainedGcpRoleBindings}
                message={localization.columns.containedRoleBindingIds.helpText()}
                messageLevel="info"
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpIamPartialServiceAccountModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.containedRoleBindingIds}
                            entityTypeName={Contract.TypeNames.GcpIamRoleBinding}
                            entityVariant="iconText"/>}
                selectorOptions={{ default: false }}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.containedRoleBindingIds.title()}/>,
            commonEntityDefinition.columns.attributesColumn,
            commonEntityDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonEntityDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonEntityDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpIamPartialServiceAccountRequest(
                new Contract.EntityControllerGetEntityModelPageGcpIamPartialServiceAccountRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpIamPartialServiceAccountActivityTime]),
                    filterMap[Contract.EntityModelProperty.GciPrincipalContainedGcpRoleBindings] as Optional<boolean>,
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpIamPartialServiceAccountGroups]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpIamPartialServiceAccountRoles])),
                limit,
                skip,
                requestSort));
}