import { useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";

export function useManualCustomEntityAttributeDefinitionAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useManualCustomEntityAttributeDefinitionAuditEventDefinition",
            () => ({
                color: "Color",
                id: "Id",
                name: "Name",
                propertyChanges: "Updated properties",
                type: {
                    title: "Type",
                    value: "Manual"
                }
            }));

    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>([]).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.name(),
                    (context.auditEventModel.auditEvent as Contract.ManualCustomEntityAttributeDefinitionAuditEvent).name),
                new AuditEventDefinitionDetailItem(
                    localization.type.title(),
                    localization.type.value())).
            concatIf(
                context.auditEventModel.auditEvent.typeName === Contract.TypeNames.ManualCustomEntityAttributeDefinitionUpdateAuditEvent,
                () => {
                    const properties =
                        _.filter([
                            (context.auditEventModel.auditEvent as Contract.ManualCustomEntityAttributeDefinitionUpdateAuditEvent).nameChanged
                                ? localization.name()
                                : undefined,
                            (context.auditEventModel.auditEvent as Contract.ManualCustomEntityAttributeDefinitionUpdateAuditEvent).colorChanged
                                ? localization.color()
                                : undefined]);
                    return (
                        _<AuditEventDefinitionDetailItem>([]).
                            concatIf(
                                !_.isEmpty(properties),
                                new AuditEventDefinitionDetailItem(
                                    localization.propertyChanges(),
                                    localizeList(properties)!)).
                            value());
                }).
            value());
}