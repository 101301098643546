import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, optionalTableCell, PagedValuesFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TenantEntityParentPathCell, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAwsOrganizationsAccountOrganizationJoinMethodTranslator, useAwsOrganizationsAccountStatusTranslator } from "../../../../../../hooks";
import { DefinitionData, ExportData } from "../../../../Table";
import { useAwsCommonResourceDefinition } from "./useAwsCommonResourceDefinition";

export function useAwsTenantEntityDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const accountOrganizationJoinMethodTranslator = useAwsOrganizationsAccountOrganizationJoinMethodTranslator();
    const accountStatusTranslator = useAwsOrganizationsAccountStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsTenantEntityDefinition",
            () => ({
                columns: {
                    accountStatus: "Status",
                    creationTime: "Joined Time",
                    organizationJoinMethod: "Joined Method",
                    parentEntityPath: "Path",
                    resourceControlPolicyIds: "Resource Control Policies",
                    rootUserMail: "Admin Email",
                    serviceControlPolicyIds: "Service Control Policies",
                    tags: "Tags"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            commonResourceDefinition.columns.tenantColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsTenantEntityModel) => ({
                            [localization.columns.accountStatus()]:
                                _.isNil(item.account)
                                    ? ""
                                    : accountStatusTranslator(item.account!.status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AwsOrganizationsAccountStatus}
                                enumTypeTranslator={accountStatusTranslator}
                                placeholder={localization.columns.accountStatus()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsTenantEntityAccountStatus}
                key={Contract.EntityModelProperty.AwsTenantEntityAccountStatus}
                render={
                    optionalTableCell<Contract.AwsTenantEntityModel>(
                        item =>
                            _.isNil(item.account)
                                ? undefined
                                : accountStatusTranslator(item.account!.status))}
                title={localization.columns.accountStatus()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsTenantEntityModel) => ({
                            [localization.columns.rootUserMail()]:
                                _.isNil(item.account)
                                    ? ""
                                    : item.account!.rootUserMail
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsTenantEntityRootUserMail)}
                                placeholder={localization.columns.rootUserMail()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsTenantEntityRootUserMail}
                key={Contract.EntityModelProperty.AwsTenantEntityRootUserMail}
                render={
                    optionalTableCell<Contract.AwsTenantEntityModel>(
                        item =>
                            _.isNil(item.account)
                                ? undefined
                                : item.account!.rootUserMail)}
                title={localization.columns.rootUserMail()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsTenantEntityModel) => ({
                            [localization.columns.organizationJoinMethod()]:
                                _.isNil(item.account)
                                    ? undefined
                                    : accountOrganizationJoinMethodTranslator(item.account!.organizationJoinMethod)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AwsOrganizationsAccountOrganizationJoinMethod}
                                enumTypeTranslator={accountOrganizationJoinMethodTranslator}
                                placeholder={localization.columns.organizationJoinMethod()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsTenantEntityAccountOrganizationJoinMethod}
                key={Contract.EntityModelProperty.AwsTenantEntityAccountOrganizationJoinMethod}
                render={
                    optionalTableCell<Contract.AwsTenantEntityModel>(
                        item =>
                            _.isNil(item.account)
                                ? undefined
                                : accountOrganizationJoinMethodTranslator(item.account!.organizationJoinMethod))}
                selectorOptions={{ default: false }}
                title={localization.columns.organizationJoinMethod()}/>,
            commonResourceDefinition.getCreationTimeColumn(localization.columns.creationTime()),
            commonResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsTenantEntityModel>(
                        Contract.TypeNames.AwsOrganizationsPolicy,
                        item => item.serviceControlPolicyIds,
                        localization.columns.serviceControlPolicyIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsTenantEntityServiceControlPolicyIds)}
                                placeholder={localization.columns.serviceControlPolicyIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsTenantEntityServiceControlPolicyIds}
                key={Contract.EntityModelProperty.AwsTenantEntityServiceControlPolicyIds}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsTenantEntityModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.serviceControlPolicyIds}
                            entityTypeName={Contract.TypeNames.AwsOrganizationsPolicy}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.serviceControlPolicyIds()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsTenantEntityModel>(
                        Contract.TypeNames.AwsOrganizationsPolicy,
                        item => item.resourceControlPolicyIds,
                        localization.columns.resourceControlPolicyIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsTenantEntityResourceControlPolicyIds)}
                                placeholder={localization.columns.resourceControlPolicyIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsTenantEntityResourceControlPolicyIds}
                key={Contract.EntityModelProperty.AwsTenantEntityResourceControlPolicyIds}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsTenantEntityModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.resourceControlPolicyIds}
                            entityTypeName={Contract.TypeNames.AwsOrganizationsPolicy}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.resourceControlPolicyIds()}/>,
            <DataTableColumn
                exportOptions={{
                    getData: (item: Contract.AwsTenantEntityModel) => [item.account?.parentEntityId],
                    getItem:
                        (item: Contract.AwsTenantEntityModel, exportData: ExportData) => ({
                            [localization.columns.parentEntityPath()]:
                                _.isNil(item.account?.parentEntityId) ||
                                _.isNil(exportData.relatedEntityModelMap[item.account!.parentEntityId!]?.consoleUrl)
                                    ? item.parentEntityPath
                                    : `${item.parentEntityPath} (${exportData.relatedEntityModelMap[item.account!.parentEntityId].consoleUrl})`
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsTenantEntityParentPath)}
                                placeholder={localization.columns.parentEntityPath()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsTenantEntityParentPath}
                key={Contract.EntityModelProperty.AwsTenantEntityParentPath}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsTenantEntityModel>) =>
                        <TenantEntityParentPathCell
                            parentEntityId={item.account?.parentEntityId}
                            parentEntityPath={item.parentEntityPath}/>}
                title={localization.columns.parentEntityPath()}/>,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsTenantEntityRequest(
                new Contract.EntityControllerGetEntityModelPageAwsTenantEntityRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsTenantEntityAccountOrganizationJoinMethod]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsTenantEntityAccountStatus]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsTenantEntityParentPath]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsTenantEntityResourceControlPolicyIds]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsTenantEntityRootUserMail]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsTenantEntityServiceControlPolicyIds])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}