﻿import { OrderedWizardItem, useLocalization } from "@infrastructure";
import React, { useMemo } from "react";
import { ScannerCommand, ScannerItem } from "../../components";
import { CredentialItem, ScanContainerImageStageItem, ScanIacStageItem, SyncIacStageItem } from "./components";

export function useJenkinsPipeline() {
    const commands =
        useMemo(
            () =>
                [
                    {
                        contentElement: <ScanContainerImageStageItem/>,
                        type: ScannerCommand.ContainerImageScan
                    },
                    {
                        contentElement: <ScanIacStageItem/>,
                        type: ScannerCommand.IacScan
                    },
                    {
                        contentElement: <SyncIacStageItem/>,
                        type: ScannerCommand.IacSync
                    }
                ],
            []);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodePipelineItems.codePipeline.addOrEdit.hooks.useDefinition.hooks.useJenkinsPipeline",
            () => ({
                steps: {
                    credential: "Add Jenkins Credential (Recommended)",
                    environmentVariables: "Add Environment Variables",
                    scanner: "Add Stages"
                },
                title: "Add the relevant stage to your Jenkins Pipeline:"
            }));
    return {
        apiKeyItem: true,
        orderedWizardItems: [
            <OrderedWizardItem
                key="credential"
                title={localization.steps.credential()}>
                <CredentialItem/>
            </OrderedWizardItem>,
            <OrderedWizardItem
                key="scannerItem"
                title={localization.steps.scanner()}>
                <ScannerItem
                    commands={commands}
                    title={localization.title()}/>
            </OrderedWizardItem>
        ]
    };
}