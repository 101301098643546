import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, TenantIcon, tenantModelStore } from "../../../../../../../../../../common";
import { IntegrationItem, IntegrationItemCategory } from "../../../../utilities";
import { AadTenants, GciTenants, OktaTenants, OneLoginTenants, PingIdentityTenants } from "./components";

export function useIdentityProviderTenantItems(): IntegrationItem[] {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems",
            () => ({
                description: "{{count | NumberFormatter.humanize}} configured",
                [Contract.TypeNames.TenantType]: {
                    [Contract.TenantType.Aad]:  "Microsoft Entra ID",
                    [Contract.TenantType.Gci]:  "Google Workspace",
                    [Contract.TenantType.PingIdentity]:  "Ping Identity",
                    [Contract.TenantType.Okta]:  "Okta",
                    [Contract.TenantType.OneLogin]:  "OneLogin"
                }
            }));

    const aadTenantModels = tenantModelStore.useGetPermittedAadTenants();
    const gciTenantModels = tenantModelStore.useGetPermittedGciTenants();
    const oktaTenantModels = tenantModelStore.useGetPermittedOktaTenants();
    const oneLoginTenantModels = tenantModelStore.useGetPermittedOneLoginTenants();
    const pingIdentityTenantModels = tenantModelStore.useGetPermittedPingIdentityTenants();

    return [
        new IntegrationItem(
            IntegrationItemCategory.IdentityProviderTenants,
            aadTenantModels,
            [localization.description({ count: aadTenantModels.length })],
            <TenantIcon tenantType={Contract.TenantType.Aad}/>,
            localization[Contract.TypeNames.TenantType][Contract.TenantType.Aad](),
            Contract.TenantType.Aad,
            <AadTenants/>),
        new IntegrationItem(
            IntegrationItemCategory.IdentityProviderTenants,
            gciTenantModels,
            [localization.description({ count: gciTenantModels.length })],
            <TenantIcon tenantType={Contract.TenantType.Gci}/>,
            localization[Contract.TypeNames.TenantType][Contract.TenantType.Gci](),
            Contract.TenantType.Gci,
            <GciTenants/>),
        new IntegrationItem(
            IntegrationItemCategory.IdentityProviderTenants,
            oktaTenantModels,
            [localization.description({ count: oktaTenantModels.length })],
            <TenantIcon tenantType={Contract.TenantType.Okta}/>,
            localization[Contract.TypeNames.TenantType][Contract.TenantType.Okta](),
            Contract.TenantType.Okta,
            <OktaTenants/>),
        new IntegrationItem(
            IntegrationItemCategory.IdentityProviderTenants,
            pingIdentityTenantModels,
            [localization.description({ count: pingIdentityTenantModels.length })],
            <TenantIcon tenantType={Contract.TenantType.PingIdentity}/>,
            localization[Contract.TypeNames.TenantType][Contract.TenantType.PingIdentity](),
            Contract.TenantType.PingIdentity,
            <PingIdentityTenants/>),
        new IntegrationItem(
            IntegrationItemCategory.IdentityProviderTenants,
            oneLoginTenantModels,
            [localization.description({ count: oneLoginTenantModels.length })],
            <TenantIcon tenantType={Contract.TenantType.OneLogin}/>,
            localization[Contract.TypeNames.TenantType][Contract.TenantType.OneLogin](),
            Contract.TenantType.OneLogin,
            <OneLoginTenants/>)
    ];
}