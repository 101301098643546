﻿import { FormLayout, makeContextProvider, OrderedWizard, OrderedWizardItem, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useGitLabServerContext, useSetGitLabServerContext } from "../..";
import { Contract } from "../../../../../../../../../../../../../../common";
import { FinishItem, ServerDetailsItem } from "./components";

export class AddOrEditContext {
    constructor(public serverModel?: Contract.CodeServerModel) {
    }
}

export const [useAddOrEditContext, useSetAddOrEditContext, useAddOrEditContextProvider] = makeContextProvider<AddOrEditContext>();

export function AddOrEditGitLabServer() {
    const { addOrEditOpen } = useGitLabServerContext();
    const GitLabServerContext = useSetGitLabServerContext();

    const [, , ContextProvider] =
        useAddOrEditContextProvider(
            () =>
                new AddOrEditContext(
                    _.isBoolean(addOrEditOpen)
                        ? undefined
                        : addOrEditOpen));

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeServerItems.gitLabServer.addOrEditGitLabServer",
            () => ({
                steps: {
                    serverDetails: "Configure Server Details"
                },
                title: {
                    add: "Add GitLab Self-Managed Server",
                    edit: "Edit GitLab Self-Managed Server"
                }
            }));

    return (
        <ContextProvider>
            <FormLayout
                disableContentPadding={true}
                titleOptions={{
                    text:
                        _.isBoolean(addOrEditOpen)
                            ? localization.title.add()
                            : localization.title.edit()
                }}>
                <OrderedWizard
                    finishItemElement={<FinishItem/>}
                    onClose={
                        () =>
                            GitLabServerContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: false
                                }))}>
                    <OrderedWizardItem title={localization.steps.serverDetails()}>
                        <ServerDetailsItem/>
                    </OrderedWizardItem>
                </OrderedWizard>
            </FormLayout>
        </ContextProvider>);
}