import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function LinkIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_59326_1040)">
                <path
                    clipRule="evenodd"
                    d="M17.7039 12.8505L6.29235 12.8505C5.74006 12.8505 5.29235 12.4028 5.29235 11.8505C5.29235 11.2982 5.74006 10.8505 6.29235 10.8505L17.7039 10.8505C18.2562 10.8505 18.7039 11.2982 18.7039 11.8505C18.7039 12.4027 18.2562 12.8505 17.7039 12.8505Z"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M5.88923 5L9.76027 5C10.3126 5 10.7603 5.44772 10.7603 6C10.7603 6.55229 10.3126 7 9.76027 7L5.89248 7C4.92513 7.00677 3.95433 7.4683 3.20644 8.36266C2.45435 9.26205 2.00665 10.5119 2.00004 11.8467C2.00665 13.1815 2.45435 14.4314 3.20644 15.3308C3.95435 16.2251 4.92517 16.6867 5.89254 16.6934H9.76027C10.3126 16.6934 10.7603 17.1411 10.7603 17.6934C10.7603 18.2457 10.3126 18.6934 9.76027 18.6934H5.88314C4.26209 18.6835 2.75453 17.908 1.67219 16.6137C0.59413 15.3246 0.00752461 13.611 3.62396e-05 11.851L0 11.8425C0.00748837 10.0824 0.594129 8.36886 1.67219 7.07967C2.75453 5.78537 4.26209 5.00988 5.88314 5.00002L5.88923 5Z"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M13.2432 5.99937C13.2438 5.44709 13.692 4.99985 14.2443 5.00045L18.1169 5.00461C19.738 5.01447 21.2455 5.78997 22.3279 7.08427C23.4059 8.37346 23.9925 10.087 24 11.8471L24 11.8556C23.9926 13.6156 23.4059 15.3292 22.3279 16.6183C21.2455 17.9126 19.738 18.6881 18.1169 18.698L18.1108 18.698L14.2398 18.698C13.6875 18.698 13.2398 18.2503 13.2398 17.698C13.2398 17.1457 13.6875 16.698 14.2398 16.698L18.1076 16.698C19.075 16.6912 20.0457 16.2297 20.7936 15.3354C21.5457 14.436 21.9934 13.1861 22 11.8513C21.9934 10.5165 21.5457 9.26665 20.7936 8.36726C20.0456 7.47275 19.0746 7.01122 18.1071 7.0046L14.2422 7.00045C13.6899 6.99985 13.2427 6.55166 13.2432 5.99937Z"
                    fillRule="evenodd"/>
            </g>
            <defs>
                <clipPath id="clip0_59326_1040">
                    <rect
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}