import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { getScopeConfigurationValueType, getScopeConfigurationValueTypeValue, RadioGroup, RadioGroupItem, ScopeConfigurationValueType, ScopeHelper, ScopeLabel, scopeNodeModelStore, useScopeNavigationViewContext } from "../../../../../../../../../../common";
import { useInheritedScopeId, useUpdateConfiguration } from "../../../../hooks";

export function ContainerImageRepositoryEnabled() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const inheritDisabled = scopeNodeModel.configuration.id === ScopeHelper.customerId;
    const inheritedScopeId = useInheritedScopeId(workloadAnalysis => workloadAnalysis.containerImageRepositoryEnabled);

    const localization =
        useLocalization(
            "views.customer.configuration.workloadAnalysis.containerImageRepositories.containerImageRepositoryEnabled",
            () => ({
                options: {
                    false: "Disabled",
                    inherit: "Inherit",
                    true: "Enabled"
                },
                title: "Container Registry Scanning"
            }));

    const items =
        useMemo(
            () => {
                const inheritedContainerImageRepositoryEnabled =
                    _.isNil(inheritedScopeId)
                        ? undefined
                        : scopeNodeMap[inheritedScopeId!].scopeNodeModel.configuration.scopeSections.workloadAnalysis.containerImageRepositoryEnabled;

                return _<RadioGroupItem<ScopeConfigurationValueType>>([]).
                    concatIf(
                        !inheritDisabled,
                        {
                            label:
                                <ScopeLabel
                                    inheritedScopeId={inheritedScopeId}
                                    text={localization.options.inherit()}
                                    translatedInheritedValue={
                                        inheritedContainerImageRepositoryEnabled
                                            ? localization.options.true()
                                            : localization.options.false()}/>,
                            value: ScopeConfigurationValueType.Inherit
                        }).
                    concat(
                        {
                            label: <ScopeLabel text={localization.options.true()}/>,
                            value: ScopeConfigurationValueType.Enabled
                        },
                        {
                            label: <ScopeLabel text={localization.options.false()}/>,
                            value: ScopeConfigurationValueType.Disabled
                        }).
                    value();
            },
            [inheritDisabled, inheritedScopeId]);

    const updateConfiguration = useUpdateConfiguration();

    return (
        <RadioGroup
            items={items}
            selectedValue={getScopeConfigurationValueType(scopeNodeModel.configuration.scopeSections.workloadAnalysis.containerImageRepositoryEnabled)}
            title={localization.title()}
            onChange={
                async (value: ScopeConfigurationValueType) => {
                    const containerImageRepositoryEnabled = getScopeConfigurationValueTypeValue(value);
                    await updateConfiguration({ containerImageRepositoryEnabled });
                }}/>);
}