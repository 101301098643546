import React from "react";
import { CustomerConsoleAppUrlHelper, ProfileActions as BaseProfileActions, UserHelper, useTheme } from "../../../../../../common";
import { IdentityPermission } from "../../../../../../common/controllers/types.generated";
import { Actions } from "./components";

type ProfileActionsProps = {
    contentSha256String: string;
};

export function ProfileActions({ contentSha256String }: ProfileActionsProps) {
    const theme = useTheme();
    return (
        <BaseProfileActions
            getLink={() => CustomerConsoleAppUrlHelper.getWorkloadAnalysisMaliciousFileProfileUrl(contentSha256String)!}
            menu={
                UserHelper.hasAnyScopePermissions(undefined, IdentityPermission.SecurityWrite)
                    ? <Actions
                        contentSha256String={contentSha256String}
                        sx={{ marginLeft: `${theme.spacing(1)} !important` }}/>
                    : undefined}/>);
}