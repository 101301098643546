import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useOciConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useGetRiskPolicyConfigurationRotationTimeFrameContextItem } from "../../../useGetRiskPolicyConfigurationRotationTimeFrameContextItem";
import { useGetOciIamUserSecretKeyRiskContext } from "../contexts";

export function useOciIamUserSecretKeyNotRotatedRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.OciIamUserSecretKeyNotRotatedRisk;
    const userSecretKeyModel = entityModelStore.useGet(risk.entityId) as Contract.OciIamUserSecretKeyModel;

    const getOciIamUserSecretKeyRiskContext = useGetOciIamUserSecretKeyRiskContext();
    const getRiskPolicyConfigurationRotationTimeFrameContextItem = useGetRiskPolicyConfigurationRotationTimeFrameContextItem();
    const consoleSignInStepTranslator = useOciConsoleSignInStepTranslator();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.compliance.useOciIamUserSecretKeyNotRotatedRiskDefinition",
            () => ({
                description: "{{userSecretKey}} was not rotated for more than {{userSecretKeyRotationTimeFrame | TimeSpanFormatter.humanizeDays}}",
                sections: {
                    resolution: {
                        step1: "Regenerating user customer secret keys may break existing client connectivity, as the client will try to connect with older user customer secret keys. Therefore, it is better to create a new user customer secret key and delete the old one after you migrate your application to use the new user customer secret key.",
                        step2: "Click **Generate secret key** and then **Generate secret key**",
                        step3: "Before deleting the old user customer secret key, migrate your applications (if any) to use the newly created user customer secret key",
                        step4: "In the **Customer secret keys** page, select the 3-dot menu next to the old user customer secret key and click **Delete**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        (localization.description({
            userSecretKey:
                <Entity
                    entityIdOrModel={userSecretKeyModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            userSecretKeyRotationTimeFrame: risk.credentialRotationTimeFrame
        })),
        () => [
            consoleSignInStepTranslator(
                Contract.OciConsoleView.User,
                userSecretKeyModel.consoleUrl!),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const keyRiskContext = getOciIamUserSecretKeyRiskContext(userSecretKeyModel);
            return [
                keyRiskContext.generalInformation,
                getRiskPolicyConfigurationRotationTimeFrameContextItem(risk.credentialRotationTimeFrame),
                keyRiskContext.creationTime,
                keyRiskContext.belongsTo,
                keyRiskContext.sensitive
            ];
        });
}