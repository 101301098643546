import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, InlineItems, NumberFormatter, optionalTableCell, UnexpectedError, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InfoCard } from "../../../../../../../../../../../../common";

export type DatabasesInfoCardProps = {
    databaseAccountResourceIds: string[];
    type: Contract.AzureDocumentDbDatabaseAccountType;
};

export function DatabaseAccountResourceInfoCard({ databaseAccountResourceIds, type }: DatabasesInfoCardProps) {
    const databaseAccountResourceModels = entityModelStore.useGet(databaseAccountResourceIds);

    function GetChildResourceNames(databaseAccountResource: Contract.AzureDocumentDbDatabaseAccountResource, type: Contract.AzureDocumentDbDatabaseAccountType) {
        switch (type) {
            case Contract.AzureDocumentDbDatabaseAccountType.Cassandra:
                return (databaseAccountResource as Contract.AzureDocumentDbDatabaseAccountCassandraKeyspace).tableNames;
            case Contract.AzureDocumentDbDatabaseAccountType.Gremlin:
                return (databaseAccountResource as Contract.AzureDocumentDbDatabaseAccountGremlinDatabase).graphNames;
            case Contract.AzureDocumentDbDatabaseAccountType.MongoDb:
                return (databaseAccountResource as Contract.AzureDocumentDbDatabaseAccountMongoDbDatabase).collectionNames;
            case Contract.AzureDocumentDbDatabaseAccountType.Sql:
                return (databaseAccountResource as Contract.AzureDocumentDbDatabaseAccountSqlDatabase).containerNames;
            default:
                throw new UnexpectedError("type", type);
        }
    }

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureDocumentDbDatabaseAccountDefinition.databaseAccountResourceInfoCard",
            () => ({
                columns: {
                    childResources: {
                        [Contract.TypeNames.AzureDocumentDbDatabaseAccountType]: {
                            [Contract.AzureDocumentDbDatabaseAccountType.Cassandra]: "Tables",
                            [Contract.AzureDocumentDbDatabaseAccountType.Gremlin]: "Graphs",
                            [Contract.AzureDocumentDbDatabaseAccountType.MongoDb]: "Collections",
                            [Contract.AzureDocumentDbDatabaseAccountType.Sql]: "Containers"
                        }
                    },
                    resource: {
                        [Contract.TypeNames.AzureDocumentDbDatabaseAccountType]: {
                            [Contract.AzureDocumentDbDatabaseAccountType.Cassandra]: "Keyspace",
                            [Contract.AzureDocumentDbDatabaseAccountType.Gremlin]: "Database",
                            [Contract.AzureDocumentDbDatabaseAccountType.MongoDb]: "Database",
                            [Contract.AzureDocumentDbDatabaseAccountType.Sql]: "Database",
                            [Contract.AzureDocumentDbDatabaseAccountType.Table]: "Table"
                        }
                    },
                    throughput: "Throughput (RU/s)"
                },
                empty: {
                    [Contract.TypeNames.AzureDocumentDbDatabaseAccountType]: {
                        [Contract.AzureDocumentDbDatabaseAccountType.Cassandra]: "No Keyspaces",
                        [Contract.AzureDocumentDbDatabaseAccountType.Gremlin]: "No Databases",
                        [Contract.AzureDocumentDbDatabaseAccountType.MongoDb]: "No Databases",
                        [Contract.AzureDocumentDbDatabaseAccountType.Sql]: "No Databases",
                        [Contract.AzureDocumentDbDatabaseAccountType.Table]: "No Tables"
                    }
                },
                title: {
                    [Contract.TypeNames.AzureDocumentDbDatabaseAccountType]: {
                        [Contract.AzureDocumentDbDatabaseAccountType.Cassandra]: "Keyspaces",
                        [Contract.AzureDocumentDbDatabaseAccountType.Gremlin]: "Databases",
                        [Contract.AzureDocumentDbDatabaseAccountType.MongoDb]: "Databases",
                        [Contract.AzureDocumentDbDatabaseAccountType.Sql]: "Databases",
                        [Contract.AzureDocumentDbDatabaseAccountType.Table]: "Tables"
                    }
                }
            }));
    return (
        <InfoCard title={localization.title[Contract.TypeNames.AzureDocumentDbDatabaseAccountType][type]()}>
            <DataTable
                emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty[Contract.TypeNames.AzureDocumentDbDatabaseAccountType][type]()) }}
                fetchItems={() => databaseAccountResourceModels}
                getItemId={(item: Contract.AzureResourceGroupResourceModel) => item.id}
                sortOptions={{ enabled: false }}
                variant="card">
                <DataTableColumn
                    id="resource"
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AzureResourceGroupResourceModel>) =>
                            <Entity
                                entityIdOrModel={item}
                                variant="iconTextTenant"/>}
                    title={localization.columns.resource[Contract.TypeNames.AzureDocumentDbDatabaseAccountType][type]()}/>
                {type != Contract.AzureDocumentDbDatabaseAccountType.Table && (
                    <DataTableColumn
                        id="childResources"
                        render={
                            optionalTableCell<Contract.AzureResourceGroupResourceModel>(
                                item => {
                                    const childResourceNames = GetChildResourceNames(item.entity as Contract.AzureDocumentDbDatabaseAccountResource, type);
                                    return _.isEmpty(childResourceNames)
                                        ? undefined
                                        : <InlineItems
                                            items={childResourceNames}
                                            variant="itemPlusItemCount"/>;
                                })}
                        title={localization.columns.childResources[Contract.TypeNames.AzureDocumentDbDatabaseAccountType][type]()}/>)}
                <DataTableColumn
                    id="throughput"
                    render={
                        optionalTableCell<Contract.AzureResourceGroupResourceModel>(
                            item => {
                                const throughput = (item.entity as Contract.AzureDocumentDbDatabaseAccountResource).throughput;
                                return _.isNil(throughput)
                                    ? undefined
                                    : NumberFormatter.humanize(throughput!);
                            })}
                    title={localization.columns.throughput()}/>
            </DataTable>
        </InfoCard>);
}