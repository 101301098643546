import _ from "lodash";
import React, { useLayoutEffect, useRef } from "react";
import ResizeObserver from "react-resize-observer";

type BreakpointResizerProps = {
    onSize?: (breakpointX: number, breakpointY?: number) => void;
} & ({
    breakpointXs: number[];
    breakpointYs?: number[];
} | {
    breakpointXs?: number[];
    breakpointYs: number[];
});

export function BreakpointResizer({ breakpointXs = [], breakpointYs = [], onSize }: BreakpointResizerProps) {
    const resizeObserverRef = useRef<any>(null);
    const resizerMountedRef = useRef(false);
    const previousWidth = useRef(0);
    const previousHeight = useRef(0);
    const processing = useRef(false);

    useLayoutEffect(
        () => {
            resizerMountedRef.current = true;

            const initialWidth = resizeObserverRef.current!._lastRect.width;
            const breakpoint =
                getBreakpointFromSize(
                    breakpointXs,
                    initialWidth);
            onSize?.(breakpoint);

            previousWidth.current = initialWidth;
        },
        []);

    return (
        <ResizeObserver
            ref={resizeObserverRef}
            onResize={
                rect => {
                    if (resizerMountedRef.current && !processing.current && rect.width !== previousWidth.current) {
                        const currentBreakpointX =
                            getBreakpointFromSize(
                                breakpointXs,
                                rect.width);
                        const previousBreakpointX =
                            getBreakpointFromSize(
                                breakpointXs,
                                previousWidth.current);

                        const currentBreakpointY =
                            getBreakpointFromSize(
                                breakpointYs,
                                rect.height);
                        const previousBreakpointY =
                            getBreakpointFromSize(
                                breakpointYs,
                                previousHeight.current);

                        if (!_.isEmpty(breakpointXs) &&
                            previousBreakpointX !== currentBreakpointX ||
                            !_.isEmpty(breakpointYs) &&
                            previousBreakpointY !== currentBreakpointY) {
                            processing.current = true;
                            onSize?.(currentBreakpointX, currentBreakpointY);
                            processing.current = false;
                        }

                        previousWidth.current = rect.width;
                        previousHeight.current = rect.height;
                    }
                }}/>);
}

function getBreakpointFromSize(breakpoints: number[], size: number) {
    return _.findLast(
        breakpoints,
        breakpoint => size > breakpoint) ?? 0;
}