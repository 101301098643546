import _ from "lodash";
import { useCallback } from "react";
import { Contract } from "../../../../../../../../../../../../../common";

export function useGetWorkloadAnalysisRiskResolutionPatches() {
    return useCallback(
        (risk: Contract.WorkloadAnalysisRisk) =>
            _(risk.criticalVulnerabilities).
                concat(risk.highVulnerabilities).
                flatMap(vulnerability => vulnerability.newestResolutionPatches ?? []).
                uniq().
                value(),
        []);
}