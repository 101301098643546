import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useGetAwsElasticFileSystemFileSystemRiskContext } from "../contexts";

export function useAwsElasticFileSystemFileSystemEncryptionDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsElasticFileSystemFileSystemEncryptionDisabledRisk;
    const fileSystemModel = entityModelStore.useGet(risk.entityId) as Contract.AwsElasticFileSystemFileSystemModel;

    const getAwsElasticFileSystemFileSystemRiskContext = useGetAwsElasticFileSystemFileSystemRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsElasticFileSystemFileSystemEncryptionDisabledRiskDefinition",
            () => ({
                description: "{{fileSystem}} is not configured with encryption using KMS",
                sections: {
                    resolution: {
                        step1: "If an EFS file system has been created without data at rest encryption enabled then you must create another EFS file system with the correct configuration and transfer the data",
                        step2: "Click **Create File System** on the top right",
                        step3: "Click **Create**",
                        step4: "Make sure that all data has been transferred and all resources have been migrated to use the newly created file system before deleting the old file system",
                        step5: "Delete the old file system"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            fileSystem:
                <Entity
                    entityIdOrModel={fileSystemModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.ElasticFileSystem,
                AwsConsoleUrlBuilder.getEfsFileSystemUrl(fileSystemModel.entity as Contract.AwsElasticFileSystemFileSystem)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5({
                fileSystem:
                    <Entity
                        entityIdOrModel={fileSystemModel}
                        entityTypeNameTranslatorOptions={{ variant: "text" }}
                        variant="text"/>
            })
        ],
        riskModel,
        () => {
            const fileSystemRiskContext = getAwsElasticFileSystemFileSystemRiskContext(fileSystemModel);
            return [
                fileSystemRiskContext.generalInformation,
                fileSystemRiskContext.sensitive,
                fileSystemRiskContext.size,
                fileSystemRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}