import { CircularProgress } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Id, toast, ToastOptions, UpdateOptions } from "react-toastify";
import { Toast, ToastProps } from "../components";

export const autoClose = 5000;

export class ToastHelper {
    public static toast(toastProps: ToastProps, options?: ToastOptions) {
        return toast(
            <Toast {...toastProps}/>,
            {
                ...(_.isNil(options)
                    ? {}
                    : options),
                progressClassName:
                    _.isNil(toastProps.level)
                        ? undefined
                        : `Toast-progressClassName-${toastProps.level}`
            });
    }

    public static update(id: Id, options?: UpdateOptions) {
        toast.update(id, options);
    }

    public static async toastPromise(errorToastProps: ToastProps, successToastProps: ToastProps, toastProps: Pick<ToastProps, "title" | "content">, promise: () => Promise<any>) {
        const toastId = toast.loading(
            <Toast 
                {...toastProps}
                icon={<CircularProgress size="16px"/>}/>,
            { icon: false });

        try {
            await promise();
            ToastHelper.update(
                toastId,
                {
                    autoClose,
                    isLoading: false,
                    progressClassName: "Toast-progressClassName-success",
                    render: <Toast {...successToastProps}/>
                });
        } catch (error) {
            ToastHelper.update(
                toastId,
                {
                    autoClose,
                    isLoading: false,
                    progressClassName: "Toast-progressClassName-error",
                    render: <Toast {...errorToastProps}/>
                });

            throw error;
        }
    }
}