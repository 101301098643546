﻿import _ from "lodash";
import { Contract, LicensingHelper } from "../../../../../../../common";

export class SystemKubernetesClusterHelper {
    public static getHelmStatus(systemKubernetesClusterModel?: Contract.SystemKubernetesClusterModel) {
        if (_.isNil(systemKubernetesClusterModel)) {
            return SystemKubernetesClusterHelmStatus.NotInstalled;
        }

        if (systemKubernetesClusterModel.helm.legacyVersion) {
            return SystemKubernetesClusterHelmStatus.Outdated;
        }

        const cnappEnterpriseLicense = LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.CnappEnterprise);
        if (cnappEnterpriseLicense && (
            systemKubernetesClusterModel.helm.admissionController.status !== Contract.SystemKubernetesClusterModelHelmComponentStatus.NotInstalled ||
            systemKubernetesClusterModel.helm.sensor.status !== Contract.SystemKubernetesClusterModelHelmComponentStatus.NotInstalled)) {
            return SystemKubernetesClusterHelmStatus.Installed;
        }

        return systemKubernetesClusterModel.helm.connector.status !== Contract.SystemKubernetesClusterModelHelmComponentStatus.NotInstalled
            ? SystemKubernetesClusterHelmStatus.Installed
            : SystemKubernetesClusterHelmStatus.NotInstalled;
    }
}

export enum SystemKubernetesClusterHelmStatus {
    Installed = "installed",
    NotInstalled = "notInstalled",
    Outdated = "outdated"
}