import React from "react";
import { CustomerConsoleAppUrlHelper, ProfileActions as BaseProfileActions, RiskPoliciesType, RiskPoliciesView, RiskPolicyItem } from "../../../../../common";
import { useCommonRiskPolicyInfoItems } from "./Profile/hooks/useRiskPolicyInfoItems/hooks/useCommonRiskPolicyInfoItems";

type ProfileActionsProps = {
    riskPoliciesType: RiskPoliciesType;
    riskPolicy: RiskPolicyItem;
    scopeId: string;
};

export function ProfileActions({ riskPoliciesType, riskPolicy, scopeId }: ProfileActionsProps) {
    const { getActions } = useCommonRiskPolicyInfoItems(riskPolicy, scopeId);
    return (
        <BaseProfileActions
            getLink={
                () =>
                    CustomerConsoleAppUrlHelper.getRiskPoliciesProfileUrl(
                        riskPolicy.riskPolicyConfigurationTypeNameOrId,
                        scopeId,
                        riskPolicy.custom
                            ? RiskPoliciesView.BuiltIn
                            : RiskPoliciesView.Custom)!}
            key="copyLink"
            menu={getActions(riskPoliciesType)}/>);
}