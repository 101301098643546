﻿import { InlineItems, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../../common";
import { useGcpComputeSslPolicyFeatureProfileTranslator } from "../../../../../../../../../../../Entities/hooks";
import { RiskDefinitionContextItem } from "../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../../../components";
import { useGetGcpComputeSslPolicyRiskContext } from "../../../contexts";

export function useGcpComputeLoadBalancerInsecureSslPolicyRiskSslPolicyDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpComputeLoadBalancerInsecureSslPolicyRisk;
    const sslPolicyModel = entityModelStore.useGet(risk.entityId) as Contract.GcpComputeSslPolicyModel;
    const loadBalancerModels = entityModelStore.useGet(sslPolicyModel.loadBalancerIds) as Contract.GcpComputeLoadBalancerModel[];
    const sensitiveLoadBalancerModels =
        useMemo(
            () =>
                _.filter(
                    loadBalancerModels,
                    loadBalancerModel => loadBalancerModel.entityConfiguration?.sensitive === true),
            [loadBalancerModels]);

    const getGcpComputeSslPolicyRiskContext = useGetGcpComputeSslPolicyRiskContext();

    const computeSslPolicyFeatureProfileTranslator = useGcpComputeSslPolicyFeatureProfileTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpComputeLoadBalancerInsecureSslPolicyRiskDefinition.hooks.useGcpComputeLoadBalancerInsecureSslPolicyRiskSslPolicyDefinition",
            () => ({
                contextItems: {
                    featureProfile: {
                        title: "The SSL policy has {{featureProfile}} profile with {{weakCipherFeatures}}",
                        weakCipherFeatures: {
                            empty: "no weak cipher features",
                            value: [
                                "1 weak cipher feature",
                                "{{count | NumberFormatter.humanize}} weak cipher features"
                            ]
                        }
                    },
                    sensitive: [
                        "The {{loadBalancers}} is marked as sensitive",
                        "The {{loadBalancers}} are marked as sensitive"]
                },
                description: {
                    withLoadBalancers: "{{sslPolicy}} is used by {{loadBalancers}} and allows insecure communication protocols such as TLS 1.0 and 1.1",
                    withoutLoadBalancers: "{{sslPolicy}} allows insecure communication protocols such as TLS 1.0 and 1.1"
                },
                sections: {
                    resolution: {
                        step1: "Before enforcing minimum TLS version 1.2 and removing weak cipher features, verify that your client applications support it",
                        step2: "Change the minimum TLS version to 1.2",
                        step3: "Change the profile to Modern, Restricted, or a custom profile without any weak cipher",
                        step4: "Click on **Save**"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        (_.isEmpty(sslPolicyModel.loadBalancerIds)
            ? localization.description.withoutLoadBalancers
            : localization.description.withLoadBalancers)({
            loadBalancers:
                <InlineEntities
                    entityIdsOrModels={sslPolicyModel.loadBalancerIds}
                    entityTypeName={Contract.TypeNames.GcpComputeLoadBalancer}
                    variant="itemCountAndType"/>,
            sslPolicy:
                <Entity
                    entityIdOrModel={sslPolicyModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.Edit}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const sslPolicyContextItems = getGcpComputeSslPolicyRiskContext(sslPolicyModel);
            return [
                sslPolicyContextItems.generalInformation,
                sslPolicyContextItems.sensitive,
                _.isEmpty(sensitiveLoadBalancerModels)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.sensitive(
                            sensitiveLoadBalancerModels.length,
                            {
                                loadBalancers:
                                    <InlineEntities
                                        entityIdsOrModels={sensitiveLoadBalancerModels}
                                        entityTypeName={Contract.TypeNames.GcpComputeLoadBalancer}
                                        variant="itemCountAndType"/>
                            })),
                new RiskDefinitionContextItem(
                    localization.contextItems.featureProfile.title({
                        featureProfile: computeSslPolicyFeatureProfileTranslator((sslPolicyModel.entity as Contract.GcpComputeSslPolicy).featureProfile),
                        weakCipherFeatures:
                            _.isEmpty(risk.weakCipherFeatures)
                                ? localization.contextItems.featureProfile.weakCipherFeatures.empty()
                                : <InlineItems
                                    items={risk.weakCipherFeatures}
                                    namePluralizer={localization.contextItems.featureProfile.weakCipherFeatures.value}
                                    variant="itemCountAndType"/>
                    })),
                sslPolicyContextItems.tlsMinVersion,
                sslPolicyContextItems.loadBalancers,
                sslPolicyContextItems.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}