﻿import { Message, useChangeEffect, useInputValidation, useLocalization } from "@infrastructure";
import { FormHelperText, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useRef } from "react";
import { Contract, ElasticsearchItemPageHelper, EntityController, PagedEntityMultiSelect } from "../../../../../../../../../../../common";
import { useAddOrEditContext, useSetAddOrEditContext } from "../../../../../AddOrEdit";
import { OneLoginDirectoryRoleAssignmentEligibilityData } from "../utilities";

export function Roles() {
    const { add, permissionEligibilityData, upsertPermissionEligibilityExecuting } = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();
    const principalTenantId = permissionEligibilityData.principalTenantId;
    const { roleIds } = permissionEligibilityData as OneLoginDirectoryRoleAssignmentEligibilityData;
    const roleIdsResetRef = useRef(add);

    const localization =
        useLocalization(
            "views.user.permissionEligibilities.addOrEdit.hooks.useDefinition.hooks.useOneLoginDefinition.roles",
            () => ({
                error: {
                    empty: "You must select at least one role"
                },
                title: "Roles",
                tooltip: "Roles that the selected users can request."
            }));

    useChangeEffect(
        () => {
            roleIdsResetRef.current = true;
        },
        [principalTenantId]);

    const [roleIdsValidationController, roleIdsValidationMessage] =
        useInputValidation(
            () => {
                if (roleIdsResetRef.current) {
                    return undefined;
                }

                if (_.isEmpty(roleIds)) {
                    return localization.error.empty();
                }

                return undefined;
            },
            [roleIds]);

    useEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    fieldNameToValidMap: {
                        ...addOrEditContext.fieldNameToValidMap,
                        roles:
                            !roleIdsResetRef.current &&
                            roleIdsValidationController.isValid()
                    }
                }));
        },
        [roleIds]);

    return (
        <Stack spacing={1.5}>
            <Stack
                direction="row"
                spacing={1}>
                <Typography variant="h4">
                    {localization.title()}
                </Typography>
                <Message
                    level="info"
                    title={localization.tooltip()}
                    variant="minimal"/>
            </Stack>
            <PagedEntityMultiSelect
                disabled={
                    _.isNil(principalTenantId) ||
                    upsertPermissionEligibilityExecuting}
                fullWidth={true}
                getEntityModelPage={
                    ElasticsearchItemPageHelper.makePagedEntitySelector(
                        async (itemNextPageSearchCursor, searchText) => {
                            const { entityModelPage } =
                                await EntityController.searchEntityModels(
                                    new Contract.EntityControllerSearchEntityModelsTypeRequest(
                                        false,
                                        15,
                                        itemNextPageSearchCursor,
                                        principalTenantId,
                                        searchText,
                                        false,
                                        Contract.TypeNames.OneLoginDirectoryRole));
                            return entityModelPage;
                        })}
                placeholder={localization.title()}
                selectedEntityIds={roleIds}
                onSelectedEntityIdsChanged={
                    roleIds => {
                        roleIdsResetRef.current = false;
                        setAddOrEditContext(
                            addOrEditContext => {
                                (addOrEditContext.permissionEligibilityData as OneLoginDirectoryRoleAssignmentEligibilityData).roleIds = roleIds;
                                return { ...addOrEditContext };
                            });
                    }}/>
            {!_.isNil(roleIdsValidationMessage) && (
                <FormHelperText error={true}>
                    {roleIdsValidationMessage}
                </FormHelperText>)}
        </Stack>);
}