import { TimeFormatter, useExecuteOperation, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, EntityController, InfoItem, tenantModelStore } from "../../../../../../../../../../common";
import { AwsTenantHelper } from "../../../../../../../../../../tenants";
import { EntitiesInfoCard, Info } from "../../../../components";
import { AwsIamPolicyRevisions, AwsIamPolicyRevisionsRevision } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAwsDefaultResourceInfoItemElements } from "./useAwsDefaultResourceInfoItemElements";

export function useAwsIamInlinePolicyDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const inlinePolicy = resourceModel.entity as Contract.AwsIamInlinePolicy;
    const inlinePolicyModel = resourceModel as Contract.AwsIamPrincipalPolicyModel;
    const tenantModelMap = tenantModelStore.useGetAwsTenantMap();
    const [{ entitySnapshots }] =
        useExecuteOperation(
            [useAwsIamInlinePolicyDefinition, inlinePolicy.id],
            () =>
                EntityController.getEntitySnapshots(
                    new Contract.EntityControllerGetEntitySnapshotsRequest(
                        inlinePolicy.id,
                        undefined)));
    const revisions =
        useMemo(
            () =>
                _.isEmpty(entitySnapshots)
                    ? [new AwsIamPolicyRevisionsRevision(1, inlinePolicy.document)]
                    : _(entitySnapshots as Contract.AwsIamInlinePolicySnapshot[]).
                        slice().
                        reverse().
                        map(
                            (inlinePolicySnapshot, inlinePolicySnapshotIndex) =>
                                new AwsIamPolicyRevisionsRevision(
                                    inlinePolicySnapshotIndex + 1,
                                    inlinePolicySnapshot.policyDocument,
                                    inlinePolicySnapshotIndex === 0
                                        ? undefined
                                        : inlinePolicySnapshot.systemCreationTime)).
                        value(),
            [entitySnapshots]);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsIamInlinePolicyDefinition",
            () => ({
                info: {
                    cards: {
                        permissionAttachedPrincipalIds: "Applied to"
                    },
                    items: {
                        type: {
                            inline: "Inline",
                            title: "Policy Type"
                        },
                        usageTime: {
                            empty: "Never",
                            title: "Usage Time"
                        }
                    }
                },
                tabs: {
                    policyRevisions: "Versions"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <AwsIamPolicyRevisions
                    defaultRevisionIndex={revisions.length}
                    revisions={revisions}/>,
                localization.tabs.policyRevisions(),
                "policyRevisions")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="type"
                        title={localization.info.items.type.title()}
                        value={localization.info.items.type.inline()}/>,
                    AwsTenantHelper.isAccessAdvisorEnabled(resourceModel.entity, tenantModelMap)
                        ? <InfoItem
                            key="usageTime"
                            title={localization.info.items.usageTime.title()}
                            value={
                                _.isNil(inlinePolicyModel.permissionUsageTime)
                                    ? localization.info.items.usageTime.empty()
                                    : TimeFormatter.profileFormatDateTime(inlinePolicyModel.permissionUsageTime)}/>
                        : undefined
                ]}
                options={options?.infoOptions}>
                <EntitiesInfoCard
                    entityIdsOrModels={(resourceModel as Contract.AwsIamPrincipalPolicyModel).permissionAttachedPrincipalIds}
                    entityTypeName={Contract.TypeNames.AwsIamPrincipal}
                    title={localization.info.cards.permissionAttachedPrincipalIds()}/>
            </Info>,
        sensitive: false
    });
}