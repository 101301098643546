﻿import { map, SuccessIcon } from "@infrastructure";
import { Error as ErrorIcon } from "@mui/icons-material";
import React from "react";
import { Contract, useTheme } from "../../../common";

type AadDirectoryApplicationObjectExpirationStatusIconProps = {
    expirationStatus: Contract.AadDirectoryApplicationObjectExpirationStatus;
};

export function AadDirectoryApplicationObjectExpirationStatusIcon({ expirationStatus }: AadDirectoryApplicationObjectExpirationStatusIconProps) {
    const theme = useTheme();
    return map(
        expirationStatus,
        {
            [Contract.AadDirectoryApplicationObjectExpirationStatus.Expired]: () =>
                <ErrorIcon
                    sx={{
                        color: theme.palette.error.main,
                        fontSize: "18px"
                    }}/>,
            [Contract.AadDirectoryApplicationObjectExpirationStatus.ExpiringSoon]: () =>
                <ErrorIcon
                    sx={{
                        color: theme.palette.warning.light,
                        fontSize: "18px"
                    }}/>,
            [Contract.AadDirectoryApplicationObjectExpirationStatus.Valid]: () =>
                <SuccessIcon
                    sx={{
                        color: theme.palette.success.main,
                        fontSize: "18px"
                    }}/>
        });
}