import { Action1, DataTable, DataTableActions } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { Contract, StorageHelper } from "../../../../../../../../../../../common";
import { useCommonVirtualMachineDefinition } from "../../../../../../../../WorkloadAnalysis/hooks";

type VirtualMachineVulnerabilityReportFiltersProps = {
    dataTableActionsRef: React.MutableRefObject<DataTableActions | undefined>;
    filterMap: Dictionary<unknown>;
    setFilterMap: Action1<Dictionary<string>>;
};

export function VirtualMachineVulnerabilityReportFilters({ dataTableActionsRef, filterMap, setFilterMap }: VirtualMachineVulnerabilityReportFiltersProps) {
    const commonVirtualMachineDefinition = useCommonVirtualMachineDefinition();
    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            filtersOptions={{
                initial: {
                    state: filterMap
                },
                onChanged: setFilterMap,
                persist: {
                    visibilityStorageItem: StorageHelper.customerVulnerabilityReportVirtualMachineFilters
                }
            }}
            getItemId={(workloadResourceModel: Contract.WorkloadResourceModel) => workloadResourceModel.id}
            variant="filtersOnly">
            {commonVirtualMachineDefinition.columns.virtualMachineId}
            {commonVirtualMachineDefinition.columns.operatingSystemDisplayName}
            {commonVirtualMachineDefinition.columns.operatingSystemType}
            {commonVirtualMachineDefinition.columns.packageDisplayName}
            {commonVirtualMachineDefinition.columns.vulnerabilities}
            {commonVirtualMachineDefinition.columns.vulnerabilityResolutionPatches}
            {commonVirtualMachineDefinition.columns.networkInboundAccessType}
            {commonVirtualMachineDefinition.columns.networkInboundExternalAccessScope}
            {commonVirtualMachineDefinition.columns.inboundExternalDestinationNetworkScopes}
            {commonVirtualMachineDefinition.columns.identityPermissionActionSeverity}
            {commonVirtualMachineDefinition.columns.virtualMachineStatus}
            {commonVirtualMachineDefinition.columns.vulnerabilityExploitable}
            {commonVirtualMachineDefinition.columns.scanTime}
            {commonVirtualMachineDefinition.columns.kubernetesClusterSensor}
            {commonVirtualMachineDefinition.columns.attributes}
            {commonVirtualMachineDefinition.columns.workloadAnalysisStatus}
            {commonVirtualMachineDefinition.columns.workloadResourceTypeName}
            {commonVirtualMachineDefinition.columns.fixableVulnerability}
            {commonVirtualMachineDefinition.columns.resourceTags}
        </DataTable>);
}