import { Dictionary } from "lodash";
import { Contract } from "..";

export class IdentityRoleHelper {
    private static _identityRoleToCustomerConsoleAppPermissionsMap: Dictionary<Contract.IdentityPermission[]>;

    public static initialize(identityRoleToCustomerConsoleAppPermissionsMap: Dictionary<Contract.IdentityPermission[]>) {
        IdentityRoleHelper._identityRoleToCustomerConsoleAppPermissionsMap = identityRoleToCustomerConsoleAppPermissionsMap;
    }

    public static getIdentityRoleCustomerConsoleAppPermissions(identityRole: Contract.IdentityRole) {
        return IdentityRoleHelper._identityRoleToCustomerConsoleAppPermissionsMap![identityRole];
    }
}