﻿import { InlineItems, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetCommonKubernetesClusterRiskContext } from "../../..";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";

export function useKubernetesClusterNodeUnrestrictedAccessFileRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.KubernetesClusterNodeUnrestrictedAccessFileRisk;
    const kubernetesClusterNodeUnrestrictedAccessFileRiskModel = riskModel as Contract.KubernetesClusterNodeUnrestrictedAccessFileRiskModel;
    const clusterModel = entityModelStore.useGet(risk.entityId);

    const getKubernetesClusterRiskContext = useGetCommonKubernetesClusterRiskContext(clusterModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.kubernetes.hooks.clusters.useKubernetesClusterNodeUnrestrictedAccessFileRiskDefinition",
            () => ({
                contextItems: {
                    [Contract.TypeNames.KubernetesNodeFileDataType]: {
                        [Contract.KubernetesNodeFileDataType.KubeletConfiguration]: {
                            [Contract.TypeNames.KubernetesClusterNodeUnrestrictedAccessFileRiskNodeDataFileUnrestrictedAccessType]: {
                                [Contract.KubernetesClusterNodeUnrestrictedAccessFileRiskNodeDataFileUnrestrictedAccessType.Ownership]: "The cluster has {{nodes}} in which the kubelet configuration file ownership is misconfigured at {{filePaths}}",
                                [Contract.KubernetesClusterNodeUnrestrictedAccessFileRiskNodeDataFileUnrestrictedAccessType.Permissions]: "The cluster has {{nodes}} in which the kubelet configuration file permissions are misconfigured at {{filePaths}}"
                            }
                        },
                        [Contract.KubernetesNodeFileDataType.KubeletKubernetesConfiguration]: {
                            [Contract.TypeNames.KubernetesClusterNodeUnrestrictedAccessFileRiskNodeDataFileUnrestrictedAccessType]: {
                                [Contract.KubernetesClusterNodeUnrestrictedAccessFileRiskNodeDataFileUnrestrictedAccessType.Ownership]: "The cluster has {{nodes}} in which the kubelet kubeconfig file ownership is misconfigured at {{filePaths}}",
                                [Contract.KubernetesClusterNodeUnrestrictedAccessFileRiskNodeDataFileUnrestrictedAccessType.Permissions]: "The cluster has {{nodes}} in which the kubelet kubeconfig file permissions are misconfigured at {{filePaths}}"
                            }
                        },
                        [Contract.KubernetesNodeFileDataType.KubeProxyKubernetesConfiguration]: {
                            [Contract.TypeNames.KubernetesClusterNodeUnrestrictedAccessFileRiskNodeDataFileUnrestrictedAccessType]: {
                                [Contract.KubernetesClusterNodeUnrestrictedAccessFileRiskNodeDataFileUnrestrictedAccessType.Ownership]: "The cluster has {{nodes}} in which the kube proxy kubeconfig file ownership is misconfigured at {{filePaths}}",
                                [Contract.KubernetesClusterNodeUnrestrictedAccessFileRiskNodeDataFileUnrestrictedAccessType.Permissions]: "The cluster has {{nodes}} in which the kube proxy kubeconfig file permissions are misconfigured at {{filePaths}}"
                            }
                        }
                    }
                },
                description: "{{cluster}} worker node configuration files are not restrictive",
                filePaths: [
                    "1 file path",
                    "{{count | NumberFormatter.humanize}} file paths"
                ],
                resolutionSection: {
                    [Contract.TypeNames.KubernetesNodeFileDataType]: {
                        [Contract.KubernetesNodeFileDataType.KubeletConfiguration]: {
                            [Contract.TypeNames.KubernetesClusterNodeUnrestrictedAccessFileRiskNodeDataFileUnrestrictedAccessType]: {
                                [Contract.KubernetesClusterNodeUnrestrictedAccessFileRiskNodeDataFileUnrestrictedAccessType.Ownership]: "For the following {{nodes}}, ensure that the kubelet configuration file ownership is set to root:root",
                                [Contract.KubernetesClusterNodeUnrestrictedAccessFileRiskNodeDataFileUnrestrictedAccessType.Permissions]: "For the following {{nodes}}, ensure that the kubelet configuration file permissions are set to 644 or more restrictive"
                            }
                        },
                        [Contract.KubernetesNodeFileDataType.KubeletKubernetesConfiguration]: {
                            [Contract.TypeNames.KubernetesClusterNodeUnrestrictedAccessFileRiskNodeDataFileUnrestrictedAccessType]: {
                                [Contract.KubernetesClusterNodeUnrestrictedAccessFileRiskNodeDataFileUnrestrictedAccessType.Ownership]: "For the following {{nodes}}, ensure that the kubelet kubeconfig file ownership is set to root:root",
                                [Contract.KubernetesClusterNodeUnrestrictedAccessFileRiskNodeDataFileUnrestrictedAccessType.Permissions]: "For the following {{nodes}}, ensure that the kubelet kubeconfig file permissions are set to 644 or more restrictive"
                            }
                        },
                        [Contract.KubernetesNodeFileDataType.KubeProxyKubernetesConfiguration]: {
                            [Contract.TypeNames.KubernetesClusterNodeUnrestrictedAccessFileRiskNodeDataFileUnrestrictedAccessType]: {
                                [Contract.KubernetesClusterNodeUnrestrictedAccessFileRiskNodeDataFileUnrestrictedAccessType.Ownership]: "For the following {{nodes}}, ensure that the proxy kubeconfig file ownership is set to root:root",
                                [Contract.KubernetesClusterNodeUnrestrictedAccessFileRiskNodeDataFileUnrestrictedAccessType.Permissions]: "For the following {{nodes}}, ensure that the proxy kubeconfig file permissions are set to 644 or more restrictive"
                            }
                        }
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{
                        includeServiceName: true,
                        variant: "title"
                    }}
                    variant="typeText"/>
        }),
        () =>
            _.map(
                kubernetesClusterNodeUnrestrictedAccessFileRiskModel.unrestrictedAccessItems,
                unrestrictedAccessItem =>
                    localization.resolutionSection
                        [Contract.TypeNames.KubernetesNodeFileDataType]
                        [unrestrictedAccessItem.fileType]
                        [Contract.TypeNames.KubernetesClusterNodeUnrestrictedAccessFileRiskNodeDataFileUnrestrictedAccessType]
                        [unrestrictedAccessItem.unrestrictedAccessType]({
                            nodes:
                            <InlineEntities
                                entityIdsOrModels={unrestrictedAccessItem.virtualMachineIds}
                                entityTypeName={Contract.TypeNames.IKubernetesNode}
                                variant="itemCountAndType"/>
                        })),
        riskModel,
        () => {
            const clusterRiskContext = getKubernetesClusterRiskContext(clusterModel);

            return [
                clusterRiskContext.generalInformation,
                clusterRiskContext.virtualNetwork,
                ..._.map(
                    kubernetesClusterNodeUnrestrictedAccessFileRiskModel.unrestrictedAccessItems,
                    unrestrictedAccessItem =>
                        new RiskDefinitionContextItem(
                            localization.contextItems
                                [Contract.TypeNames.KubernetesNodeFileDataType]
                                [unrestrictedAccessItem.fileType]
                                [Contract.TypeNames.KubernetesClusterNodeUnrestrictedAccessFileRiskNodeDataFileUnrestrictedAccessType]
                                [unrestrictedAccessItem.unrestrictedAccessType]({
                                    filePaths:
                                    <InlineItems
                                        items={unrestrictedAccessItem.filePaths}
                                        namePluralizer={localization.filePaths}
                                        variant="itemCountAndType">
                                        {filePath => _.truncate(filePath, { length: 40 })}
                                    </InlineItems>,
                                    nodes:
                                    <InlineEntities
                                        entityIdsOrModels={unrestrictedAccessItem.virtualMachineIds}
                                        entityTypeName={Contract.TypeNames.IKubernetesNode}
                                        variant="itemCountAndType"/>
                                }))),
                clusterRiskContext.sensitive,
                clusterRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}