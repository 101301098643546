﻿import { Loading, useLocalization } from "@infrastructure";
import { Box } from "@mui/material";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../..";
import { Contract, CustomerConsoleAppUrlHelper, Entity, Network, useTheme } from "../../../../../../../../../../../../../../../common";
import { ToolbarToggleFilterId } from "../../../../../../../../../../../../../../../common/components/Network/components";
import { RiskDefinitionSection } from "../../../../../../../../../utilities";
import { useRemediationSteps } from "../useRemediationSteps";
import { ContextSection } from "./components";

export function useAwsInboundExternalEc2InstanceRiskInstanceDefinition(riskModel: Contract.AwsInboundExternalEc2InstanceRiskModel) {
    const remediationSteps = useRemediationSteps(riskModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsInboundExternalEc2InstanceRiskDefinition.hooks.useAwsInboundExternalEc2InstanceRiskInstanceDefinition",
            () => ({
                description: "{{instance}} is accessible directly from a wide range of public IP addresses",
                networkGraphSection: "Network"
            }));
    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            instance:
                <Entity
                    entityIdOrModel={riskModel.risk.entityId}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => remediationSteps,
        riskModel,
        undefined,
        {
            contextSectionElement: <ContextSection riskModel={riskModel}/>,
            sections: [
                new RiskDefinitionSection(
                    <Box sx={{ minHeight: theme.spacing(20) }}>
                        <Loading>
                            <Network
                                baseUrl={CustomerConsoleAppUrlHelper.getRiskProfileRelativeUrl(riskModel.risk.id)}
                                entityId={riskModel.risk.entityId}
                                initialFilterMap={{ [ToolbarToggleFilterId.WideRangeSourceSubnet]: true }}
                                tenantType={Contract.TenantType.Aws}
                                variant="risk"/>
                        </Loading>
                    </Box>,
                    localization.networkGraphSection(),
                    {
                        profilePageOnly: true
                    })
            ]
        });
}