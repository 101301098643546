﻿import { TimeFormatter, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { AadDirectoryApplicationExpirationDateCell } from "../../../../../../../../../../tenants";
import { EntitiesInfoItem, Info } from "../../../../components";
import { DefinitionOptions, EntityProfileDefinition } from "../../useDefinition";
import { useAadDefaultDirectoryEntityInfoItemElements } from "./useAadDefaultDirectoryEntityInfoItemElements";

export function useAadDirectoryApplicationSecretDefinition(directoryEntityModel: Contract.AadDirectoryEntityModel, options?: DefinitionOptions) {
    const defaultDirectoryEntityInfoItemElements = useAadDefaultDirectoryEntityInfoItemElements(directoryEntityModel as Contract.AadDirectoryPrincipalModel);
    const secretModel = directoryEntityModel as Contract.AadDirectoryApplicationSecretModel;
    const secret = secretModel.entity as Contract.AadDirectoryApplicationSecret;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aad.useAadDirectoryApplicationSecretDefinition",
            () => ({
                info: {
                    items: {
                        application: "App Registration",
                        applicationOwnerPrincipal: "App Registration Owners",
                        description: "Description",
                        expirationStatus: "Expiry status",
                        expirationTime: "Expiration Time"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultDirectoryEntityInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="description"
                        title={localization.info.items.description()}
                        value={secret.description}/>,
                    <InfoItem
                        key="expirationTime"
                        title={localization.info.items.expirationTime()}
                        value={TimeFormatter.profileFormatDateTime(secret.expirationTime)}/>,
                    <InfoItem
                        key="expirationStatus"
                        title={localization.info.items.expirationStatus()}
                        value={
                            <AadDirectoryApplicationExpirationDateCell
                                expirationDate={secret.expirationTime}
                                expirationStatus={secret.expirationStatus}
                                variant="status"/>}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={secret.applicationId}
                        entityTypeName={Contract.TypeNames.AadDirectoryApplication}
                        key="applicationId"
                        title={localization.info.items.application()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={secretModel.applicationOwnerPrincipalIds}
                        entityTypeName={Contract.TypeNames.AadDirectoryIdentity}
                        key="applicationOwnerPrincipal"
                        title={localization.info.items.applicationOwnerPrincipal()}/>
                ]}
                options={options?.infoOptions}>
            </Info>,
        sensitive: false
    });
}