﻿import _ from "lodash";
import React from "react";
import { FormLayout, makeContextProvider, OrderedWizard, OrderedWizardItem, useExecuteOperation, useLocalization } from "@infrastructure";
import { ConfigurationController, Contract } from "../../../../../../../../../../../../../../common";
import { useGitHubContext, useSetGitHubContext } from "../../GitHub";
import { AuthenticateGitHubUserItem, ChooseOrganizationItem, FinishItem, InstallAppItem, OrganizationMemberSelectionItem } from "./components";
import { ChooseServerItem } from "./components/ChooseServerItem";

export class AddOrEditContext {
    public memberSelection?: Contract.OrganizationMemberSelection;
    public organizationNames?: string[];
    public tenantPaths?: string[];
    public updatedApplicationClientId?: string;
    public updatedFolderEnabled?: boolean;
    public updatedFolderScopeNodeModel?: Contract.ScopeNodeModel;
    public updatedOrganizationName?: string;
    public updatedSyncEnabled?: boolean;

    constructor(
        public applicationName: string,
        public defaultServerConfiguration: Contract.GitHubServerConfiguration,
        public callbackError?: Contract.CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsGitHubOrganizationsRelativeUrlError,
        public oAuthAccessToken?: string,
        public organizationModel?: Contract.CodeOrganizationModel,
        public updatedServerId?: string) {
        this.memberSelection = organizationModel?.configuration.memberSelection;
        this.updatedFolderEnabled = organizationModel?.configuration.folderEnabled;
    }
}

export const [useAddOrEditContext, useSetAddOrEditContext, useAddOrEditContextProvider] = makeContextProvider<AddOrEditContext>();

export function AddOrEditGitHub() {
    const { addOrEditOpen, callbackError, oAuthAccessToken, serverId } = useGitHubContext();
    const setGitHubContext = useSetGitHubContext();

    const [{ applicationName, defaultServerConfiguration }] =
        useExecuteOperation(
            AddOrEditGitHub,
            ConfigurationController.getGitHubOrganizationOnboardingApplicationInfo);

    const [, , ContextProvider] =
        useAddOrEditContextProvider(
            () =>
                new AddOrEditContext(
                    applicationName,
                    defaultServerConfiguration,
                    callbackError,
                    oAuthAccessToken,
                    _.isBoolean(addOrEditOpen)
                        ? undefined
                        : addOrEditOpen,
                    !_.isBoolean(addOrEditOpen)
                        ? addOrEditOpen.configuration.serverId
                        : serverId));

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeOrganizationItems.gitHub.addOrEditGitHub",
            () => ({
                steps: {
                    authenticateGitHubUser: "Authenticate with GitHub",
                    chooseOrganization: "Select Organization",
                    chooseServer: "Select GitHub Server",
                    installApp: "Install the Tenable Cloud Security App",
                    tenants: "Select Repositories"
                },
                title: {
                    add: "Add GitHub Organization",
                    edit: "Edit GitHub Organization"
                }
            }));

    return (
        <ContextProvider>
            <FormLayout
                disableContentPadding={true}
                titleOptions={{
                    text:
                        _.isBoolean(addOrEditOpen)
                            ? localization.title.add()
                            : localization.title.edit()
                }}>
                <OrderedWizard
                    finishItemElement={<FinishItem/>}
                    startItemIndex={
                        !_.isBoolean(addOrEditOpen)
                            ? 3
                            : !_.isNil(oAuthAccessToken)
                                ? 2
                                : !_.isNil(callbackError)
                                    ? 1
                                    : 0}
                    onClose={
                        () =>
                            setGitHubContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: false
                                }))}>
                    <OrderedWizardItem title={localization.steps.chooseServer()}>
                        <ChooseServerItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem title={localization.steps.authenticateGitHubUser()}>
                        <AuthenticateGitHubUserItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem title={localization.steps.installApp()}>
                        <InstallAppItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem title={localization.steps.chooseOrganization()}>
                        <ChooseOrganizationItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem
                        deferredLoading={true}
                        title={localization.steps.tenants()}>
                        <OrganizationMemberSelectionItem/>
                    </OrderedWizardItem>
                </OrderedWizard>
            </FormLayout>
        </ContextProvider>);
}