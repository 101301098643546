import { useLocalization } from "@infrastructure";
import React from "react";
import { useAwsCommonKubernetesResourceInfoItemElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract } from "../../../../../../../../../../common";
import { KubernetesResourceDataHelper } from "../../../../../../utilities";
import { EntitiesInfoItem, Info } from "../../../../components";
import { KubernetesYamlInfoCard } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useAwsKubernetesClusterRoleBindingDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const clusterRoleBinding = resourceModel.entity as Contract.AwsKubernetesClusterRoleBinding;
    const clusterRoleBindingModel = resourceModel as Contract.AwsKubernetesClusterRoleBindingModel;
    const commonKubernetesResourceInfoItemElements = useAwsCommonKubernetesResourceInfoItemElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsKubernetesClusterRoleBindingDefinition",
            () => ({
                info: {
                    principalIdReferences: "Principals",
                    roleIdReference: "Cluster Role"
                },
                tabs: {
                    yaml: "YAML"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <KubernetesYamlInfoCard rawYaml={clusterRoleBinding.data.rawYaml!}/>,
                localization.tabs.yaml(),
                "yaml")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    commonKubernetesResourceInfoItemElements.annotationsInfoItemElement,
                    commonKubernetesResourceInfoItemElements.clusterInfoItemElement,
                    <EntitiesInfoItem
                        entityIdsOrModels={clusterRoleBindingModel.principalIdReferences}
                        entityTypeName={Contract.TypeNames.IKubernetesPrincipal}
                        key="principalIdReferences"
                        title={localization.info.principalIdReferences()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={clusterRoleBindingModel.roleIdReference}
                        entityTypeName={Contract.TypeNames.AwsKubernetesClusterRole}
                        key="roleIdReference"
                        title={localization.info.roleIdReference()}/>
                ]}
                options={options?.infoOptions}>
            </Info>,
        sensitive: KubernetesResourceDataHelper.entitySensitiveToggle(clusterRoleBinding.data)
    });
}