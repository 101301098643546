import _ from "lodash";
import { Contract, LicensingHelper } from "../../../../../common";

export class TenantModelHelper {
    public static getTenantModelPermissions(tenantModel?: Contract.AwsTenantModel | Contract.AzureTenantModel | Contract.GcpTenantModel) {
        const statePermissionTypes = tenantModel?.state.permissionTypes;
        const configurationPermissionTypes =
            tenantModel?.configuration.permissionTypes ??
            _([Contract.CloudProviderTenantPermissionType.Read]).
                concatIf(
                    LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.PermissionManagement),
                    Contract.CloudProviderTenantPermissionType.PermissionManagement).
                concatIf(
                    LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp),
                    Contract.CloudProviderTenantPermissionType.WorkloadAnalysis).
                value();
        return {
            configurationPermissionTypes,
            statePermissionTypes
        };
    }
}