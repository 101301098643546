import _, { Dictionary } from "lodash";
import { DataTableColumn, DataTableSortType, optionalTableCell, TimeSpanFormatter, useLocalization } from "@infrastructure";
import { useAwsCommonCloudWatchLoggingResourceDefinition, useAwsCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, FeatureHelper, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAwsElasticBeanstalkEnvironmentDefinition(definitionData: DefinitionData) {
    const commonCloudWatchLoggingResourceDefinition = useAwsCommonCloudWatchLoggingResourceDefinition(definitionData);
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsElasticBeanstalkEnvironmentDefinition",
            () => ({
                columns: {
                    cloudWatchLogRetentionTimeFrame: "CloudWatch Log Retention Period"
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            // Migration: 1.161 | Delete FeatureHelper
            FeatureHelper.enabled(Contract.FeatureName.GenerateCustomerDocumentModelsCompleted) &&
            commonCloudWatchLoggingResourceDefinition.columns.cloudWatchLoggingEnabledColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsElasticBeanstalkEnvironmentModel) => ({
                            [localization.columns.cloudWatchLogRetentionTimeFrame()]: TimeSpanFormatter.day((item.entity as Contract.AwsElasticBeanstalkEnvironment).cloudWatchLogRetentionTimeFrame)
                        })
                }}
                id={Contract.EntityModelProperty.AwsElasticBeanstalkEnvironmentCloudWatchLogRetentionTimeFrame}
                key={Contract.EntityModelProperty.AwsElasticBeanstalkEnvironmentCloudWatchLogRetentionTimeFrame}
                render={
                    optionalTableCell<Contract.EntityModel>(
                        item =>
                            _.isNil((item.entity as Contract.AwsElasticBeanstalkEnvironment).cloudWatchLogRetentionTimeFrame)
                                ? undefined
                                : TimeSpanFormatter.day((item.entity as Contract.AwsElasticBeanstalkEnvironment).cloudWatchLogRetentionTimeFrame)
                    )}
                selectorOptions={{ default: false }}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.cloudWatchLogRetentionTimeFrame()}/>,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsElasticBeanstalkEnvironmentRequest(
                new Contract.EntityControllerGetEntityModelPageAwsElasticBeanstalkEnvironmentRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestCloudWatchLogging(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsCloudWatchResourceCloudWatchLoggingEnabled]),
                    undefined)));
}