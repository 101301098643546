import { AnalyticsEventActionType, IconText, Loading, useLocalization, useTrackAnalyticsEvent, VerticalFillGrid, VerticalTabView } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useMemo } from "react";
import { Contract, CustomerConsoleAppUrlHelper, ScopeHelper, ScopeNavigationView, TenantHelper, useLayoutOptions, useScopeNavigationViewContext, useTheme } from "../../../../common";
import { Icon } from "../../../../common/components/Scope/icons";
import { CustomerView } from "../../hooks";
import { Authentication, DataAnalysis, Empty, Features, Information, Licensing, Orchestration, SupportPrincipals, TenantConfiguration, WorkloadAnalysis } from "./components";

export function Administration() {
    const configurationTenantTypes =
        useMemo(
            () => TenantHelper.ConfigurationTenantTypes,
            []);
    const localization =
        useLocalization(
            "views.customer.administration",
            () => ({
                title: "Administration"
            }));
    useLayoutOptions({ view: { title: localization.title() } });
    useTrackAnalyticsEvent(AnalyticsEventActionType.PageView);
    const theme = useTheme();
    return (
        <ScopeNavigationView
            hideNonPermittedScopes={true}
            identityPermissions={[Contract.IdentityPermission.SecurityAdministrationWrite]}
            layout={"global"}
            templatePath={CustomerConsoleAppUrlHelper.getTemplatePath(CustomerView.Administration)}
            tenantTypes={configurationTenantTypes}>
            {(scopeNodeModel: Contract.ScopeNodeModel) =>
                <VerticalFillGrid sx={{ overflow: "hidden" }}>
                    <Stack
                        sx={{
                            borderBottom: theme.border.primary,
                            padding: theme.spacing(2),
                            width: "100%"
                        }}>
                        <IconText
                            icon={
                                <Icon
                                    scopeNodeModel={scopeNodeModel}
                                    sx={{ fontSize: "30px" }}/>}
                            text={
                                <Stack spacing={1}>
                                    <Typography variant="h2">
                                        {scopeNodeModel.configuration.name}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: theme.palette.text.secondary,
                                            fontWeight: 500
                                        }}
                                        variant="h4">
                                        {scopeNodeModel.configuration.id}
                                    </Typography>
                                </Stack>}/>
                    </Stack>
                    <View/>
                </VerticalFillGrid>}
        </ScopeNavigationView>);
}

function View() {
    const { scopeNodeModel, useViewRoute } = useScopeNavigationViewContext();
    const localization =
        useLocalization(
            "views.customer.administration.view",
            () => ({
                tabs: {
                    administrationView: {
                        [AdministrationView.Authentication]: "SSO",
                        [AdministrationView.DataAnalysis]: "Data Protection",
                        [AdministrationView.Empty]: "No Settings",
                        [AdministrationView.Features]: "Features",
                        [AdministrationView.Information]: "Overview",
                        [AdministrationView.Licensing]: "Licensing",
                        [AdministrationView.Orchestration]: "Orchestration",
                        [AdministrationView.SupportPrincipals]: "Support Principals",
                        [AdministrationView.TenantConfiguration]: "Settings",
                        [AdministrationView.WorkloadAnalysis]: "Workload Protection"
                    }
                }
            }));

    const orderedAdministrationViewItem: { [key in AdministrationView]: AdministrationViewItem } =
        useMemo(
            () => ({
                [AdministrationView.Information]: {
                    component: () => <Information/>,
                    disabled: scopeNodeModel.type !== Contract.ScopeType.Customer
                },
                [AdministrationView.Licensing]: {
                    component: () => <Licensing/>,
                    disabled: scopeNodeModel.type !== Contract.ScopeType.Customer
                },
                [AdministrationView.Authentication]: {
                    component: () => <Authentication/>,
                    disabled: scopeNodeModel.type !== Contract.ScopeType.Customer
                },
                [AdministrationView.SupportPrincipals]: {
                    component: () => <SupportPrincipals/>,
                    disabled: scopeNodeModel.type !== Contract.ScopeType.Customer
                },
                [AdministrationView.Features]: {
                    component: () => <Features/>,
                    disabled: scopeNodeModel.type !== Contract.ScopeType.Customer
                },
                [AdministrationView.DataAnalysis]: {
                    component: () => <DataAnalysis/>,
                    disabled:
                        !(ScopeHelper.isFolder(scopeNodeModel) && _.includes(TenantHelper.DataAnalysisTenantTypes, ScopeHelper.getTenantType(scopeNodeModel))) &&
                        !(_.includes([Contract.ScopeType.Customer, Contract.ScopeType.CloudProviderTenant], scopeNodeModel.type))
                },
                [AdministrationView.WorkloadAnalysis]: {
                    component: () => <WorkloadAnalysis/>,
                    disabled:
                        !(ScopeHelper.isFolder(scopeNodeModel) && _.includes(TenantHelper.WorkloadAnalysisTenantTypes, ScopeHelper.getTenantType(scopeNodeModel))) &&
                        !(_.includes([Contract.ScopeType.Customer, Contract.ScopeType.CloudProviderTenant], scopeNodeModel.type))
                },
                [AdministrationView.Orchestration]: {
                    component: () => <Orchestration/>,
                    disabled: scopeNodeModel.type !== Contract.ScopeType.Customer
                },
                [AdministrationView.TenantConfiguration]: {
                    component: () => <TenantConfiguration/>,
                    disabled:
                        !_.includes(
                            [
                                Contract.ScopeType.CiTenant,
                                Contract.ScopeType.CloudProviderTenant,
                                Contract.ScopeType.CodeTenant,
                                Contract.ScopeType.IdentityProviderTenant
                            ],
                            scopeNodeModel.type)
                },
                [AdministrationView.Empty]: {
                    component: () => <Empty/>,
                    disabled:
                        _.includes(
                            [
                                Contract.ScopeType.CiTenant,
                                Contract.ScopeType.CloudProviderTenant,
                                Contract.ScopeType.CodeTenant,
                                Contract.ScopeType.Customer,
                                Contract.ScopeType.IdentityProviderTenant
                            ],
                            scopeNodeModel.type) ||
                        (ScopeHelper.isFolder(scopeNodeModel) && _.includes(TenantHelper.WorkloadAnalysisTenantTypes, ScopeHelper.getTenantType(scopeNodeModel)))
                }
            }),
            [scopeNodeModel]);

    const [activeConfigurationViews, items] =
        useMemo(
            () => {
                const activeConfigurationViews =
                    _(orderedAdministrationViewItem).
                        omitBy(orderedConfigurationView => orderedConfigurationView.disabled).
                        keys().
                        as<AdministrationView>().
                        value();

                const items =
                    _.map(
                        activeConfigurationViews,
                        activeConfigurationView =>
                            ({
                                title: localization.tabs.administrationView[activeConfigurationView as AdministrationView](),
                                view: activeConfigurationView
                            }));

                return [activeConfigurationViews, items];
            },
            [orderedAdministrationViewItem]);

    const [view, setView] = useViewRoute("{view}", activeConfigurationViews);
    const theme = useTheme();
    return (
        <VerticalFillGrid key={scopeNodeModel.configuration.id}>
            <VerticalTabView
                collapseEnabled={false}
                items={items}
                selectedView={view}
                tabsSx={{ paddingTop: theme.spacing(0.75) }}
                onSelectedViewChanged={view => setView(view as AdministrationView)}>
                <Stack
                    sx={{
                        height: "100%",
                        overflow: "auto"
                    }}>
                    <Loading>
                        <Box
                            sx={{
                                height: "100%",
                                overflow: "auto"
                            }}>
                            {orderedAdministrationViewItem[view].component()}
                        </Box>
                    </Loading>
                </Stack>
            </VerticalTabView>
        </VerticalFillGrid>);
}

export enum AdministrationView {
    Authentication = "authentication",
    DataAnalysis = "dataAnalysis",
    Empty = "empty",
    Features = "features",
    Information = "information",
    Licensing = "licensing",
    Orchestration = "orchestration",
    SupportPrincipals = "supportPrincipals",
    TenantConfiguration = "tenantConfiguration",
    WorkloadAnalysis = "workloadAnalysis"
}

type AdministrationViewItem = {
    component: () => ReactNode;
    disabled?: boolean;
};