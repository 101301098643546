﻿import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract } from "../../../../../..";
import { useGcpCreateRoleBindingChangeDefinition, useGcpDeleteRoleBindingChangeDefinition, useGcpDeleteScopeResourcePrincipalRoleBindingsChangeDefinition, useGcpDeleteServiceAccountChangeDefinition, useGcpDisableServiceAccountChangeDefinition } from "./hooks";

export function useGcpDefinition(change: Contract.GcpChange, entityLinkDisabled?: boolean) {
    const useDefinition =
        useMemo(
            () => {
                switch (change.typeName) {
                    case Contract.TypeNames.GcpCreateRoleBindingChange:
                        return useGcpCreateRoleBindingChangeDefinition;
                    case Contract.TypeNames.GcpDeleteRoleBindingChange:
                        return useGcpDeleteRoleBindingChangeDefinition;
                    case Contract.TypeNames.GcpDeleteScopeResourcePrincipalRoleBindingsChange:
                        return useGcpDeleteScopeResourcePrincipalRoleBindingsChangeDefinition;
                    case Contract.TypeNames.GcpDeleteServiceAccountChange:
                        return useGcpDeleteServiceAccountChangeDefinition;
                    case Contract.TypeNames.GcpDisableServiceAccountChange:
                        return useGcpDisableServiceAccountChangeDefinition;
                    default:
                        throw new UnexpectedError("change.typeName", change.typeName);
                }
            },
            []);

    return useDefinition(change, entityLinkDisabled);
}