﻿import { Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, SpecificCustomRiskPolicyProps } from "../../../..";
import { Edit, View } from "./components";

export function AwsSsoPermissionSetManagementRiskPolicy({ editOptions, riskPolicyConfiguration, sx }: SpecificCustomRiskPolicyProps) {
    const localization =
        useLocalization(
            "common.customRiskPolicy.awsSsoPermissionSetManagementRiskPolicy",
            () => ({
                template: "Monitor when {{permissionsSets}} are created or updated"
            }));
    return _.isNil(editOptions)
        ? <View
            riskPolicyConfiguration={riskPolicyConfiguration as Optional<Contract.AwsSsoPermissionSetManagementRiskPolicyConfiguration>}
            sx={sx}
            templateTranslator={localization.template}/>
        : <Edit
            actionsRef={editOptions.actionsRef}
            riskPolicyConfiguration={riskPolicyConfiguration as Optional<Contract.AwsSsoPermissionSetManagementRiskPolicyConfiguration>}
            templateTranslator={localization.template}
            onValidChange={editOptions.onValidChange}/>;
}