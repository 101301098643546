import { SuccessIcon, useLocalization } from "@infrastructure";
import { Button, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { ConsoleApiUrlHelper, Contract, ExternalLinkIcon, useTheme } from "../../../../../../../../../../../../../common";

type PermissionsCellProps = {
    item: Contract.AadTenantModel;
    partitionTypeToApplicationRawIdMap: _.Dictionary<string>;
};

export function PermissionsCell({ item, partitionTypeToApplicationRawIdMap }: PermissionsCellProps) {
    const localization =
        useLocalization(
            "views.customer.configuration.permissionManagement.identityProviderTenantTable.hooks.useDefinition.hooks.useAadDefinition.permissionsCell",
            () => ({
                permissionsExist: "Granted",
                permissionsNotExist: "Grant Permissions"
            }));
    const theme = useTheme();
    return item.state.permissionManagementEnabled
        ? <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <SuccessIcon
                sx={{
                    color: theme.palette.success.main,
                    fontSize: "18px"
                }}/>
            <Typography>
                {localization.permissionsExist()}
            </Typography>
        </Stack>
        : <Button
            size="small"
            onClick={
                () =>
                    location.assign(
                        ConsoleApiUrlHelper.getPermissionManagementAadConsentUrl(
                            partitionTypeToApplicationRawIdMap[item.configuration.partitionType],
                            item.configuration.partitionType,
                            item.configuration.rawId))}>
            <Stack
                alignItems="center"
                direction="row">
                <ExternalLinkIcon
                    sx={{
                        fontSize: "16px",
                        marginRight: theme.spacing(0.5)
                    }}/>
                <Typography sx={{ fontWeight: 600 }}>
                    {localization.permissionsNotExist()}
                </Typography>
            </Stack>
        </Button>;
}