import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, DeferredFilter, optionalTableCell, PagedValuesFilter, TimeFormatter, TimeRangeFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useOciCommonIamPrincipalDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useOciIamUserDefinition(definitionData: DefinitionData) {
    const userModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.OciIamUserModelFilters>;
    const commonIamPrincipalDefinition = useOciCommonIamPrincipalDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciIamUserDefinition",
            () => ({
                columns: {
                    apiKeys: "User API Keys",
                    authTokens: "User Auth Tokens",
                    customerSecretKeys: "User Customer Secret Keys",
                    databasePasswords: "User Database Passwords",
                    groups: "Group Memberships",
                    mail: "Mail",
                    mfaEnrolled: {
                        false: "Not enrolled",
                        title: "MFA",
                        true: "Enrolled"
                    },
                    signInTime: {
                        empty: "Not seen",
                        title: "Sign-in Time"
                    }
                }
            }));

    function translateMfaEnrolled(userModel: Contract.OciIamUserModel) {
        return (userModel.entity as Contract.OciIamUser).mfaEnrolled
            ? localization.columns.mfaEnrolled.true()
            : localization.columns.mfaEnrolled.false();
    }

    function translateSignInTime(userModel: Contract.OciIamUserModel) {
        const user = userModel.entity as Contract.OciIamUser;
        return _.isNil(user.signInTime)
            ? localization.columns.signInTime.empty()
            : TimeFormatter.monthDayAndYear(user.signInTime);
    }

    const additionalColumnElements = [
        commonIamPrincipalDefinition.columns.tenantColumn,
        commonIamPrincipalDefinition.columns.domainIdColumn,
        <DataTableColumn
            exportOptions={{
                getItem:
                    (item: Contract.OciIamUserModel) => ({
                        [localization.columns.mail()]: (item.entity as Contract.OciIamUser).mail
                    })
            }}
            filterOptions={{
                itemOrItems: {
                    element:
                        <PagedValuesFilter
                            getValuePage={
                                ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                    definitionData.entityTypeEntitiesViewName,
                                    Contract.EntityModelProperty.OciIamUserMail)}
                            placeholder={localization.columns.mail()}/>
                }
            }}
            id={Contract.EntityModelProperty.OciIamUserMail}
            key={Contract.EntityModelProperty.OciIamUserMail}
            render={optionalTableCell<Contract.OciIamUserModel>(item => (item.entity as Contract.OciIamUser).mail)}
            title={localization.columns.mail()}/>,
        commonIamPrincipalDefinition.columns.creationTimeColumn,
        commonIamPrincipalDefinition.columns.creatorCsvColumn,
        commonIamPrincipalDefinition.columns.enabledColumn,
        <DataTableColumn
            exportOptions={{
                getItem:
                    (item: Contract.OciIamUserModel) => ({
                        [localization.columns.mfaEnrolled.title()]: translateMfaEnrolled(item)
                    })
            }}
            filterOptions={{
                itemOrItems: {
                    element:
                        <ValuesFilter
                            placeholder={localization.columns.mfaEnrolled.title()}
                            sorted={false}>
                            <ValuesFilterItem
                                title={localization.columns.mfaEnrolled.true()}
                                value={true}/>
                            <ValuesFilterItem
                                title={localization.columns.mfaEnrolled.false()}
                                value={false}/>
                        </ValuesFilter>
                }
            }}
            id={Contract.EntityModelProperty.OciIamUserMfaEnrolled}
            itemProperty={translateMfaEnrolled}
            key={Contract.EntityModelProperty.OciIamUserMfaEnrolled}
            title={localization.columns.mfaEnrolled.title()}/>,
        <DataTableColumn
            exportOptions={{
                getItem:
                    (item: Contract.OciIamUserModel) => ({
                        [localization.columns.signInTime.title()]:
                            _.isNil((item.entity as Contract.OciIamUser).signInTime)
                                ? localization.columns.signInTime.empty()
                                : TimeFormatter.iso8601String((item.entity as Contract.OciIamUser).signInTime)
                    })
            }}
            filterOptions={{
                itemOrItems: {
                    element:
                        <DeferredFilter
                            promiseOrGetPromise={userModelFiltersPromise}
                            title={localization.columns.signInTime.title()}>
                            {filters =>
                                <TimeRangeFilter
                                    emptyValue={filters.signInTimeRange.emptyValue}
                                    placeholder={localization.columns.signInTime.title()}
                                    timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.signInTimeRange.timeRange)}/>}
                        </DeferredFilter>
                }
            }}
            id={Contract.EntityModelProperty.OciIamUserSignInTime}
            itemProperty={translateSignInTime}
            key={Contract.EntityModelProperty.OciIamUserSignInTime}
            sortOptions={{ type: DataTableSortType.Date }}
            title={localization.columns.signInTime.title()}/>,
        <DataTableColumn
            exportOptions={
                entitiesExportOptions<Contract.OciIamUserModel>(
                    Contract.TypeNames.OciIamGroup,
                    item => item.groupIds,
                    localization.columns.groups())}
            filterOptions={{
                itemOrItems: {
                    element:
                        <PagedEntityFilter
                            getEntityIdPage={
                                ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                    definitionData.entityTypeEntitiesViewName,
                                    Contract.EntityModelProperty.OciIamUserGroups)}
                            placeholder={localization.columns.groups()}/>
                }
            }}
            id={Contract.EntityModelProperty.OciIamUserGroups}
            key={Contract.EntityModelProperty.OciIamUserGroups}
            render={
                ({ item }: DataTableColumnRenderProps<Contract.OciIamUserModel>) =>
                    <EntitiesCell
                        entityIdsOrModels={item.groupIds}
                        entityTypeName={Contract.TypeNames.OciIamGroup}
                        entityVariant="iconText"/>}
            sortOptions={{ type: DataTableSortType.Numeric }}
            title={localization.columns.groups()}/>,
        <DataTableColumn
            exportOptions={
                entitiesExportOptions<Contract.OciIamUserModel>(
                    Contract.TypeNames.OciIamUserApiKey,
                    item => item.apiKeyIds,
                    localization.columns.apiKeys())}
            filterOptions={{
                itemOrItems: {
                    element:
                        <PagedEntityFilter
                            getEntityIdPage={
                                ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                    definitionData.entityTypeEntitiesViewName,
                                    Contract.EntityModelProperty.OciIamUserUserApiKeys)}
                            placeholder={localization.columns.apiKeys()}/>
                }
            }}
            id={Contract.EntityModelProperty.OciIamUserUserApiKeys}
            key={Contract.EntityModelProperty.OciIamUserUserApiKeys}
            render={
                ({ item }: DataTableColumnRenderProps<Contract.OciIamUserModel>) =>
                    <EntitiesCell
                        entityIdsOrModels={item.apiKeyIds}
                        entityTypeName={Contract.TypeNames.OciIamUserApiKey}
                        entityVariant="iconText"/>}
            title={localization.columns.apiKeys()}/>,
        <DataTableColumn
            exportOptions={
                entitiesExportOptions<Contract.OciIamUserModel>(
                    Contract.TypeNames.OciIamUserAuthToken,
                    item => item.authTokenIds,
                    localization.columns.authTokens())}
            filterOptions={{
                itemOrItems: {
                    element:
                        <PagedEntityFilter
                            getEntityIdPage={
                                ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                    definitionData.entityTypeEntitiesViewName,
                                    Contract.EntityModelProperty.OciIamUserUserAuthTokens)}
                            placeholder={localization.columns.authTokens()}/>
                }
            }}
            id={Contract.EntityModelProperty.OciIamUserUserAuthTokens}
            key={Contract.EntityModelProperty.OciIamUserUserAuthTokens}
            render={
                ({ item }: DataTableColumnRenderProps<Contract.OciIamUserModel>) =>
                    <EntitiesCell
                        entityIdsOrModels={item.authTokenIds}
                        entityTypeName={Contract.TypeNames.OciIamUserAuthToken}
                        entityVariant="iconText"/>}
            title={localization.columns.authTokens()}/>,
        <DataTableColumn
            exportOptions={
                entitiesExportOptions<Contract.OciIamUserModel>(
                    Contract.TypeNames.OciIamUserSecretKey,
                    item => item.secretKeyIds,
                    localization.columns.customerSecretKeys())}
            filterOptions={{
                itemOrItems: {
                    element:
                        <PagedEntityFilter
                            getEntityIdPage={
                                ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                    definitionData.entityTypeEntitiesViewName,
                                    Contract.EntityModelProperty.OciIamUserUserSecretKeys)}
                            placeholder={localization.columns.customerSecretKeys()}/>
                }
            }}
            id={Contract.EntityModelProperty.OciIamUserUserSecretKeys}
            key={Contract.EntityModelProperty.OciIamUserUserSecretKeys}
            render={
                ({ item }: DataTableColumnRenderProps<Contract.OciIamUserModel>) =>
                    <EntitiesCell
                        entityIdsOrModels={item.secretKeyIds}
                        entityTypeName={Contract.TypeNames.OciIamUserSecretKey}
                        entityVariant="iconText"/>}
            title={localization.columns.customerSecretKeys()}/>,
        <DataTableColumn
            exportOptions={
                entitiesExportOptions<Contract.OciIamUserModel>(
                    Contract.TypeNames.OciIamUserDatabasePassword,
                    item => item.databasePasswordIds,
                    localization.columns.databasePasswords())}
            filterOptions={{
                itemOrItems: {
                    element:
                        <PagedEntityFilter
                            getEntityIdPage={
                                ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                    definitionData.entityTypeEntitiesViewName,
                                    Contract.EntityModelProperty.OciIamUserUserDatabasePasswords)}
                            placeholder={localization.columns.databasePasswords()}/>
                }
            }}
            id={Contract.EntityModelProperty.OciIamUserUserDatabasePasswords}
            key={Contract.EntityModelProperty.OciIamUserUserDatabasePasswords}
            render={
                ({ item }: DataTableColumnRenderProps<Contract.OciIamUserModel>) =>
                    <EntitiesCell
                        entityIdsOrModels={item.databasePasswordIds}
                        entityTypeName={Contract.TypeNames.OciIamUserDatabasePassword}
                        entityVariant="iconText"/>}
            title={localization.columns.databasePasswords()}/>,
        commonIamPrincipalDefinition.columns.tagsColumn,
        commonIamPrincipalDefinition.columns.attributesColumn,
        commonIamPrincipalDefinition.columns.openRiskedEntityRisksCsvColumn,
        commonIamPrincipalDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
        commonIamPrincipalDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
    ];
    return new EntityTableDefinition(
        _.filter(additionalColumnElements),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOciIamUserRequest(
                new Contract.EntityControllerGetEntityModelPageOciIamUserRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    undefined,
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciIamResourceEnabled]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciIamPrincipalDomain]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciIamUserGroups]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciIamUserMail]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciIamUserMfaEnrolled]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.OciIamUserSignInTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciIamUserUserApiKeys]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciIamUserUserAuthTokens]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciIamUserUserDatabasePasswords]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciIamUserUserSecretKeys])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}