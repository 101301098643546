import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, InfoCard } from "../../../../../../../../../../../../common";
import { GcpFunctionsFunctionEnvironmentVariableTable } from "../../../../../../../../../../../../tenants";

type EnvironmentVariablesInfoCardProps = {
    environmentVariables: Contract.GcpFunctionsFunctionEnvironmentVariable[];
};

export function EnvironmentVariablesInfoCard({ environmentVariables }: EnvironmentVariablesInfoCardProps) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpFunctionsFunctionDefinition.environmentVariablesInfoCard",
            () => ({
                columns: {
                    key: "Key",
                    type: {
                        title: "Type",
                        [Contract.TypeNames.GcpFunctionsFunctionEnvironmentVariableType]: {
                            [Contract.GcpFunctionsFunctionEnvironmentVariableType.Build]: "Build",
                            [Contract.GcpFunctionsFunctionEnvironmentVariableType.Runtime]: "Runtime"
                        }
                    },
                    value: "Value"
                },
                empty: "No Environment Variables",
                title: "Environment Variables"
            }));
    return (
        <InfoCard title={localization.title()}>
            <GcpFunctionsFunctionEnvironmentVariableTable environmentVariables={environmentVariables}/>
        </InfoCard>);
}