import { map, useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import { Contract } from "../../../../../common";

export function useResourcesTranslator() {
    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.system.exportPermissionRequestMail.hooks.useResourcesTranslator",
            () => ({
                [Contract.PermissionRequestMailPermissionRequestType.GroupMembership]: {
                    withCount: "groups **{{resourceNames}}** and **{{additionalResourceCountNumber | NumberFormatter.humanize}} more**",
                    withoutCount: [
                        "group **{{resourceNames}}**",
                        "groups **{{resourceNames}}**"
                    ]
                },
                [Contract.PermissionRequestMailPermissionRequestType.OneLoginDirectoryRoleAssignment]: {
                    withCount: "roles **{{resourceNames}}** and **{{additionalResourceCountNumber | NumberFormatter.humanize}} more**",
                    withoutCount: [
                        "role **{{resourceNames}}**",
                        "roles **{{resourceNames}}**"
                    ]
                },
                [Contract.PermissionRequestMailPermissionRequestType.PermissionAssignment]: {
                    tenant: "{{tenantName}} ({{tenantDisplayReference}})",
                    withCount: "accounts **{{resourceNames}}** and **{{additionalResourceCountNumber | NumberFormatter.humanize}} more**",
                    withoutCount: [
                        "account **{{resourceNames}}**",
                        "accounts **{{resourceNames}}**"
                    ]
                }
            }));

    return (permissionRequestType: Contract.PermissionRequestMailPermissionRequestType, additionalGroupCount?: string, additionalRoleCount?: string, additionalTenantCount?: string, groupNames?: string | string[], roleNames?: string | string[], tenantDisplayReferences?: string | string[], tenantNames?: string | string[]) => {
        const [additionalResourceCountNumber, resourceNames] =
            map(
                permissionRequestType,
                {
                    [Contract.PermissionRequestMailPermissionRequestType.GroupMembership]: () =>
                        [
                            _.parseInt(additionalGroupCount!),
                            _.concat(groupNames)
                        ],
                    [Contract.PermissionRequestMailPermissionRequestType.OneLoginDirectoryRoleAssignment]: () =>
                        [
                            _.parseInt(additionalRoleCount!),
                            _.concat(roleNames)
                        ],
                    [Contract.PermissionRequestMailPermissionRequestType.PermissionAssignment]: () =>
                        [
                            _.parseInt(additionalTenantCount!),
                            _.map(
                                _.zip(_.concat(tenantDisplayReferences), _.concat(tenantNames)),
                                ([tenantDisplayReference, tenantName]) => localization[Contract.PermissionRequestMailPermissionRequestType.PermissionAssignment].tenant({ tenantDisplayReference, tenantName }))
                        ]
                });

        return additionalResourceCountNumber === 0
            ? localization[permissionRequestType].withoutCount(
                resourceNames.length,
                { resourceNames: localizeList(resourceNames, false) })
            : localization[permissionRequestType].withCount(
                {
                    additionalResourceCountNumber,
                    resourceNames: localizeList(resourceNames, false)
                });
    };
}