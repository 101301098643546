import { useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";

export function useServiceNowInstanceAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useServiceNowInstanceAuditEventDefinition",
            () => ({
                propertyChanges: "Updated properties",
                serviceNowInstanceName: "Name",
                serviceNowInstanceUrl: "Instance URL",
                serviceNowUserName: "User name",
                serviceNowUserPassword: "Password"
            }));


    const serviceNowInstanceAuditEvent = context.auditEventModel.auditEvent as Contract.ServiceNowInstanceAuditEvent;
    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>([]).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.serviceNowInstanceUrl(),
                    serviceNowInstanceAuditEvent.serviceNowInstanceUrl),
                new AuditEventDefinitionDetailItem(
                    localization.serviceNowInstanceName(),
                    serviceNowInstanceAuditEvent.serviceNowInstanceName),
                new AuditEventDefinitionDetailItem(
                    localization.serviceNowUserName(),
                    serviceNowInstanceAuditEvent.serviceNowInstanceUserName)).
            concatIf(
                serviceNowInstanceAuditEvent.typeName === Contract.TypeNames.ServiceNowInstanceUpdateAuditEvent,
                () => {
                    const properties =
                        _.filter([
                            (serviceNowInstanceAuditEvent as Contract.ServiceNowInstanceUpdateAuditEvent).serviceNowInstanceNameChanged
                                ? localization.serviceNowInstanceName()
                                : undefined,
                            (serviceNowInstanceAuditEvent as Contract.ServiceNowInstanceUpdateAuditEvent).serviceNowUserNameChanged
                                ? localization.serviceNowUserName()
                                : undefined,
                            (serviceNowInstanceAuditEvent as Contract.ServiceNowInstanceUpdateAuditEvent).serviceNowUserPasswordChanged
                                ? localization.serviceNowUserPassword()
                                : undefined
                        ]);
                    return (
                        _<AuditEventDefinitionDetailItem>([]).
                            concatIf(
                                !_.isEmpty(properties),
                                new AuditEventDefinitionDetailItem(
                                    localization.propertyChanges(),
                                    localizeList(properties)!)).
                            value());
                }).
            value());
}