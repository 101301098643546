﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetGcpEntityRiskContext } from "./useGetGcpEntityRiskContext";

export function useGetGcpArtifactRegistryRiskContext() {
    return useMemo(
        () => useGcpArtifactRegistryRiskContext,
        []);
}

function useGcpArtifactRegistryRiskContext(registryModel: Contract.GcpArtifactRegistryModel) {
    const entityRiskContext = useGetGcpEntityRiskContext()(registryModel);
    const registry = registryModel.entity as Contract.GcpArtifactRegistry;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.contexts.useGetGcpArtifactRegistryRiskContext",
            () => ({
                repositories: {
                    none: "The {{translatedRegistryTypeName}} is empty",
                    some: {
                        pluralizer: [
                            " with 1 artifact",
                            " with {{count | NumberFormatter.humanize}} artifacts"
                        ],
                        text: "The {{translatedRegistryTypeName}} contains {{repositories}}{{artifactPart}}"
                    }
                }
            }));
    const translatedRegistryTypeName =
        entityTypeNameTranslator(
            registry.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...entityRiskContext,
        repositories:
            new RiskDefinitionContextItem(
                _.isEmpty(registryModel.repositoryIds)
                    ? localization.repositories.none({ translatedRegistryTypeName })
                    : localization.repositories.some.text({
                        artifactPart:
                            registry.artifactCount === 0
                                ? ""
                                : localization.repositories.some.pluralizer(registry.artifactCount),
                        repositories:
                            <InlineEntities
                                entityIdsOrModels={registryModel.repositoryIds}
                                entityTypeName={Contract.TypeNames.IGcpArtifactRepository}
                                variant="itemCountAndType"/>,
                        translatedRegistryTypeName
                    }))
    };
}