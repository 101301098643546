import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { RiskPoliciesView } from "../../../../../../../../common";
import { useCloudRiskPoliciesBuiltInDefinition, useCloudRiskPoliciesCustomDefinition } from "./hooks";

export function useCloudRiskPoliciesDefinition(scopeId: string, view: RiskPoliciesView) {
    const useDefinition =
        useMemo(
            () => {
                switch (view) {
                    case RiskPoliciesView.BuiltIn:
                        return useCloudRiskPoliciesBuiltInDefinition;
                    case RiskPoliciesView.Custom:
                        return useCloudRiskPoliciesCustomDefinition;
                    default:
                        throw new UnexpectedError("useCloudRiskPoliciesDefinition.view", view);
                }
            },
            [scopeId, view]);

    return useDefinition(scopeId);
}