import { DataTable, DataTableColumn, EmptyMessageText, InlineItems, optionalTableCell, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract } from "../../../common";

type OciFssMountTargetExportOptionsTableProps = {
    exportOptions: Contract.OciFssMountTargetExportOption[];
};

export function OciFssMountTargetExportOptionsTable({ exportOptions }: OciFssMountTargetExportOptionsTableProps) {

    const localization =
        useLocalization(
            "tenants.oci.ociFssMountTargetExportOptionsTable",
            () => ({
                columns: {
                    access: {
                        title: "Access",
                        [Contract.TypeNames.OciFssMountTargetExportOptionAccess]: {
                            [Contract.OciFssMountTargetExportOptionAccess.ReadOnly]: "Read only",
                            [Contract.OciFssMountTargetExportOptionAccess.ReadWrite]: "Read/Write"
                        }
                    },
                    anonymousAccess: {
                        false: "Allowed",
                        title: "Anonymous Access",
                        true: "Not Allowed"
                    },
                    authTypes: {
                        title: "Allowed Authentication Options",
                        [Contract.TypeNames.OciFssMountTargetExportOptionAuthType]: {
                            [Contract.OciFssMountTargetExportOptionAuthType.Sys]: "SYS",
                            [Contract.OciFssMountTargetExportOptionAuthType.Krb5]: "KRB5",
                            [Contract.OciFssMountTargetExportOptionAuthType.Krb5I]: "KRB5I",
                            [Contract.OciFssMountTargetExportOptionAuthType.Krb5P]: "KRB5P"
                        }
                    },
                    identitySquash: {
                        title: "Squash",
                        [Contract.TypeNames.OciFssMountTargetExportOptionIdentitySquash]: {
                            [Contract.OciFssMountTargetExportOptionIdentitySquash.All]: "All",
                            [Contract.OciFssMountTargetExportOptionIdentitySquash.None]: "None",
                            [Contract.OciFssMountTargetExportOptionIdentitySquash.Root]: "Root"
                        }
                    },
                    requirePrivilegedSourcePort: {
                        false: "Any",
                        title: "Ports",
                        true: "Privileged"
                    },
                    sourceSubnet: "Source",
                    squashGid: "Squash GID",
                    squashUid: "Squash UID"
                },
                empty: "No Export Options"
            }));

    return (
        <DataTable
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={() => exportOptions}
            getItemId={(exportOption: Contract.OciFssMountTargetExportOption) => _.indexOf(exportOptions, exportOption).toString()}
            sortOptions={{ enabled: false }}
            variant="card">
            <DataTableColumn
                id={OciFssMountTargetExportOptionsTableColumnId.SourceSubnet}
                itemProperty={(exportOption: Contract.OciFssMountTargetExportOption) => exportOption.sourceSubnet}
                title={localization.columns.sourceSubnet()}/>
            <DataTableColumn
                id={OciFssMountTargetExportOptionsTableColumnId.RequirePrivilegedSourcePort}
                itemProperty={
                    (exportOption: Contract.OciFssMountTargetExportOption) =>
                        exportOption.requirePrivilegedSourcePort
                            ? localization.columns.requirePrivilegedSourcePort.true()
                            : localization.columns.requirePrivilegedSourcePort.false()
                }
                title={localization.columns.requirePrivilegedSourcePort.title()}/>
            <DataTableColumn
                id={OciFssMountTargetExportOptionsTableColumnId.Access}
                itemProperty={
                    (exportOption: Contract.OciFssMountTargetExportOption) =>
                        localization.columns.access[Contract.TypeNames.OciFssMountTargetExportOptionAccess][exportOption.access]()
                }
                title={localization.columns.access.title()}/>
            <DataTableColumn
                id={OciFssMountTargetExportOptionsTableColumnId.AnonymousAccess}
                itemProperty={
                    (exportOption: Contract.OciFssMountTargetExportOption) =>
                        exportOption.anonymousAccess
                            ? localization.columns.anonymousAccess.true()
                            : localization.columns.anonymousAccess.false()
                }
                title={localization.columns.anonymousAccess.title()}/>
            <DataTableColumn
                id={OciFssMountTargetExportOptionsTableColumnId.AuthTypes}
                render={
                    optionalTableCell<Contract.OciFssMountTargetExportOption>(
                        (exportOption: Contract.OciFssMountTargetExportOption) =>
                            _.isEmpty(exportOption.authTypes)
                                ? undefined
                                : <InlineItems
                                    items={
                                        exportOption.authTypes.
                                            map(authType => localization.columns.authTypes[Contract.TypeNames.OciFssMountTargetExportOptionAuthType][authType]()).
                                            sort()}
                                    variant="itemPlusItemCount"/>)}
                title={localization.columns.authTypes.title()}/>
            <DataTableColumn
                id={OciFssMountTargetExportOptionsTableColumnId.IdentitySquash}
                itemProperty={
                    (exportOption: Contract.OciFssMountTargetExportOption) =>
                        localization.columns.identitySquash[Contract.TypeNames.OciFssMountTargetExportOptionIdentitySquash][exportOption.identitySquash]()
                }
                title={localization.columns.identitySquash.title()}/>
            <DataTableColumn
                id={OciFssMountTargetExportOptionsTableColumnId.SquashUid}
                itemProperty={(exportOption: Contract.OciFssMountTargetExportOption) => exportOption.squashUid}
                title={localization.columns.squashUid()}/>,
            <DataTableColumn
                id={OciFssMountTargetExportOptionsTableColumnId.SquashGid}
                itemProperty={(exportOption: Contract.OciFssMountTargetExportOption) => exportOption.squashGid}
                title={localization.columns.squashGid()}/>
        </DataTable>);
}

enum OciFssMountTargetExportOptionsTableColumnId {
    Access = "access",
    AnonymousAccess = "anonymousAccess",
    AuthTypes = "authTypes",
    IdentitySquash = "identitySquash",
    RequirePrivilegedSourcePort = "requirePrivilegedSourcePort",
    SourceSubnet = "sourceSubnet",
    SquashGid = "squashGid",
    SquashUid = "squashUid"
}