import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonEntityNetworkInfoItemElements, useCustomEntityPropertyInfoItemElements } from "../..";
import { useAzureCommonEncryptedResourceInfoElements, useAzureCommonManagedIdentityOriginatorResourceInfoElements, useAzureDefaultResourceInfoItemElements } from "..";
import { Contract, InfoItem, useEntityTypeNameTranslator } from "../../../../../../../../../../../common";
import { useAzureDocumentDbDatabaseAccountTypeTranslator } from "../../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../../components";
import { AzureFirewallInfoCard, AzureResourceRoleAssignmentResources, NetworkingInfoCard } from "../../../components";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../../useDefinition";
import { DatabaseAccountResourceInfoCard } from "./components";

export function useAzureDocumentDbDatabaseAccountDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const commonEncryptedResourceInfoElements = useAzureCommonEncryptedResourceInfoElements(resourceGroupResourceModel);
    const commonEntityNetworkInfoItemElements = useCommonEntityNetworkInfoItemElements(resourceGroupResourceModel);
    const commonManagedIdentityOriginatorResourceInfoElements = useAzureCommonManagedIdentityOriginatorResourceInfoElements(resourceGroupResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const databaseAccount = resourceGroupResourceModel.entity as Contract.AzureDocumentDbDatabaseAccount;
    const databaseAccountModel = resourceGroupResourceModel as Contract.AzureDocumentDbDatabaseAccountModel;
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);

    const documentDbDatabaseAccountTypeTranslator = useAzureDocumentDbDatabaseAccountTypeTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureDocumentDbDatabaseAccountDefinition",
            () => ({
                info: {
                    cards: {
                        firewallRules: {
                            title: "Firewall Rules"
                        }
                    },
                    items: {
                        allowAnyAzureInternalIpAddress: {
                            false: "Disabled",
                            title: "Accept connections from Azure datacenters",
                            true: "Enabled"
                        },
                        backupType: {
                            title: "Backup Mode",
                            [Contract.TypeNames.AzureDocumentDbDatabaseAccountBackupType]: {
                                [Contract.AzureDocumentDbDatabaseAccountBackupType.Continuous]: "Continuous",
                                [Contract.AzureDocumentDbDatabaseAccountBackupType.Periodic]: "Periodic"
                            }
                        },
                        endpointUrl: "Endpoint",
                        managementKeyAccess: {
                            false: "Disabled",
                            title: "Key Based Metadata Access",
                            true: "Enabled"
                        },
                        publicNetworkAccess: {
                            false: "Disabled",
                            title: "Public Network Access",
                            true: {
                                allNetworks: "All Networks",
                                someNetworks: "Selected Networks"
                            }
                        },
                        sharedKeyAccess: {
                            false: "Disabled",
                            title: "Local Authentication",
                            true: "Enabled"
                        },
                        type: "API Type"
                    }
                },
                tabs: {
                    resourceRoleAssignments: "Resource Role Assignments"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AzureResourceRoleAssignmentResources
                    csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                    scopeResourceModel={resourceGroupResourceModel}/>,
                localization.tabs.resourceRoleAssignments(),
                "resourceRoleAssignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonManagedIdentityOriginatorResourceInfoElements,
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="type"
                        title={localization.info.items.type()}
                        value={documentDbDatabaseAccountTypeTranslator(databaseAccount.type)}/>,
                    <InfoItem
                        key="endpointUrl"
                        title={localization.info.items.endpointUrl()}
                        value={
                            <Link
                                urlOrGetUrl={databaseAccount.endpointUrl}
                                variant="external"/>}/>,
                    <InfoItem
                        key="publicNetworkAccess"
                        title={localization.info.items.publicNetworkAccess.title()}
                        value={
                            databaseAccount.publicNetworkAccess
                                ? databaseAccount.allNetworkAccess
                                    ? localization.info.items.publicNetworkAccess.true.allNetworks()
                                    : localization.info.items.publicNetworkAccess.true.someNetworks()
                                : localization.info.items.publicNetworkAccess.false()}/>,
                    <InfoItem
                        key="backupType"
                        title={localization.info.items.backupType.title()}
                        value={localization.info.items.backupType[Contract.TypeNames.AzureDocumentDbDatabaseAccountBackupType][databaseAccount.backupType]()}/>,
                    <InfoItem
                        key="allowAnyAzureInternalIpAddress"
                        title={localization.info.items.allowAnyAzureInternalIpAddress.title()}
                        value={
                            databaseAccount.allowAnyAzureInternalIpAddress
                                ? localization.info.items.allowAnyAzureInternalIpAddress.true()
                                : localization.info.items.allowAnyAzureInternalIpAddress.false()}/>,
                    <InfoItem
                        key="sharedKeyAccess"
                        title={localization.info.items.sharedKeyAccess.title()}
                        value={
                            databaseAccount.sharedKeyAccess
                                ? localization.info.items.sharedKeyAccess.true()
                                : localization.info.items.sharedKeyAccess.false()}/>,
                    <InfoItem
                        key="managementKeyAccess"
                        title={localization.info.items.managementKeyAccess.title()}
                        value={
                            databaseAccount.managementKeyAccess
                                ? localization.info.items.managementKeyAccess.true()
                                : localization.info.items.managementKeyAccess.false()}/>
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {commonEntityNetworkInfoItemElements.inboundAccessType}
                    {commonEntityNetworkInfoItemElements.inboundExternalAccessScope}
                    <EntitiesInfoItem
                        entityIdsOrModels={databaseAccountModel.privateEndpointIdReferences}
                        entityTypeName={Contract.TypeNames.AzureNetworkPrivateEndpoint}
                        key={Contract.TypeNames.AzureNetworkPrivateEndpoint}
                        title={entityTypeNameTranslator(
                            Contract.TypeNames.AzureNetworkPrivateEndpoint,
                            { count: 0 })}/>
                </NetworkingInfoCard>
                {databaseAccount.publicNetworkAccess &&
                    !databaseAccount.allNetworkAccess &&
                    <AzureFirewallInfoCard
                        networkResourcePathToIdReferencesMap={databaseAccountModel.networkResourcePathToIdReferencesMap}
                        subnets={databaseAccount.firewallRuleSubnets}
                        virtualNetworkPathToFirewallSubnetRulesMap={databaseAccount.virtualNetworkPathToFirewallSubnetRulesMap}/>}
                <DatabaseAccountResourceInfoCard
                    databaseAccountResourceIds={databaseAccountModel.databaseAccountResourceIds}
                    type={databaseAccount.type}/>
            </Info>
    });
}