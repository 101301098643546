import { Optional, UnexpectedError } from "@infrastructure";
import { Dictionary } from "lodash";
import { ReactNode, useMemo } from "react";
import { Contract, RiskType } from "../../../../../../../../../../../../../../common";
import { useCloudDefinition, useCodeDefinition } from "./hooks";

export function useDefinition(riskGroup: Contract.RiskControllerGetRiskGroupsResponseRiskGroup, riskType: RiskType): TableDefinition {
    const useDefinition =
        useMemo(
            () => {
                switch (riskType) {
                    case RiskType.Cloud:
                        return useCloudDefinition;
                    case RiskType.Code:
                        return useCodeDefinition;
                    default:
                        throw new UnexpectedError(riskType);
                }
            },
            []);
    return useDefinition(riskGroup);
}

export interface TableDefinition {
    columns: ReactNode[];
    getRiskIdToScopeIdentityPermissionsMapRequest: (requestFilters: Contract.RiskControllerRiskModelFilters) => Contract.RiskControllerGetCloudRiskIdToScopeIdentityPermissionsMapRequest;
    getRiskModelPageRequest: (limit: number, requestFilters: Contract.RiskControllerRiskModelFilters, skip: number, sort: Optional<Contract.RiskControllerRiskModelSort>, filterMap: Dictionary<any>, filtersTime: Optional<string>) => Contract.RiskControllerGetRiskModelPageRequest;
    getRisksReportRequestDefinition?: (filterMap: Dictionary<any>, filtersTime: Optional<string>, requestFilters: Contract.RiskControllerRiskModelFilters, riskStatus: Contract.RiskStatus, sort: Optional<Contract.RiskControllerRiskModelSort>) => Contract.ReportControllerRisksReportRequestDefinition;
}