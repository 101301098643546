import { DataTableColumnRenderProps } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../..";
import { riskPolicyModelStore } from "../../../../../stores";
import { CustomRiskPolicy } from "../../../../CustomRiskPolicy";
import { ItemItem } from "../Item";

export function CustomRiskPolicyCell({ item }: DataTableColumnRenderProps<ItemItem>) {
    const riskPolicyModel = riskPolicyModelStore.useGet(item.id);
    return (
        <CustomRiskPolicy
            riskPolicyConfiguration={riskPolicyModel.riskPolicyConfiguration as Contract.CustomRiskPolicyConfiguration}
            riskPolicyConfigurationTypeName={riskPolicyModel.riskPolicyConfiguration.typeName}
            scopeId={riskPolicyModel.riskPolicyConfiguration.scopeId}/>);
}