import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, DeferredFilter, Optional, optionalTableCell, TimeRangeFilter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useActivityTranslator, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useGciCommonDirectoryPartialPrincipalDefinition } from "./useGciCommonDirectoryPartialPrincipalDefinition";

export function useGciDirectoryPartialUserDefinition(definitionData: DefinitionData) {
    const partialUserModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.GciDirectoryPartialUserModelFilters>;
    const commonDirectoryPartialPrincipalDefinition = useGciCommonDirectoryPartialPrincipalDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const activityTranslator = useActivityTranslator();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gci.useGciDirectoryPartialUserDefinition",
            () => ({
                columns: {
                    activityTime: {
                        helpText: "The last time an activity was seen in GCP for this identity. This is highly dependent on the logging configuration. Unlogged activity will not be taken into account.",
                        title: "Activity Time"
                    },
                    groupIds: "Group Memberships"
                }
            }));
    return new EntityTableDefinition(
        [
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GciDirectoryPartialUserModel) => ({
                            [localization.columns.activityTime.title()]: activityTranslator(item.access?.activity) ?? ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={partialUserModelFiltersPromise}
                                title={localization.columns.activityTime.title()}>
                                {filters =>
                                    <TimeRangeFilter
                                        emptyValue={filters.activityTimeRange.emptyValue}
                                        placeholder={localization.columns.activityTime.title()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.activityTimeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.GciDirectoryPartialUserActivityTime}
                key={Contract.EntityModelProperty.GciDirectoryPartialUserActivityTime}
                message={localization.columns.activityTime.helpText()}
                messageLevel="info"
                render={
                    optionalTableCell<Contract.GciDirectoryPartialUserModel>(
                        partialUserModel =>
                            activityTranslator(partialUserModel.access!.activity))}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.activityTime.title()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GciDirectoryPartialUserModel>(
                        Contract.TypeNames.GciDirectoryGroup,
                        item => item.groupIds,
                        localization.columns.groupIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GciDirectoryPartialPrincipalGroups)}
                                placeholder={localization.columns.groupIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GciDirectoryPartialPrincipalGroups}
                key={Contract.EntityModelProperty.GciDirectoryPartialPrincipalGroups}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GciDirectoryPartialUserModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.groupIds}
                            entityTypeName={Contract.TypeNames.GciDirectoryGroup}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.groupIds()}/>,
            commonDirectoryPartialPrincipalDefinition.columns.gcpRoleBindingIdsColumn,
            commonDirectoryPartialPrincipalDefinition.columns.gcpRoleIdsFilterColumn,
            commonDirectoryPartialPrincipalDefinition.columns.containedGcpRoleBindingIdsColumn,
            commonDirectoryPartialPrincipalDefinition.columns.attributesColumn,
            commonDirectoryPartialPrincipalDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonDirectoryPartialPrincipalDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonDirectoryPartialPrincipalDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGciDirectoryPartialUserRequest(
                new Contract.EntityControllerGetEntityModelPageGciDirectoryPartialUserRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    filterMap[Contract.EntityModelProperty.GciPrincipalContainedGcpRoleBindings] as Optional<boolean>,
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GciDirectoryPartialPrincipalRoles]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GciDirectoryPartialUserActivityTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GciDirectoryPartialPrincipalGroups])),
                limit,
                skip,
                requestSort));
}