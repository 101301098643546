﻿import { useLocalization } from "@infrastructure";
import React, { useMemo } from "react";
import { Contract, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetKubernetesClusterRiskContext } from "../../../useGetKubernetesClusterRiskContext";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsEksClusterRiskContext() {
    return useMemo(
        () => useAwsEksClusterRiskContext,
        []);
}

function useAwsEksClusterRiskContext(clusterModel: Contract.AwsEksClusterModel) {
    const cluster = clusterModel.entity as Contract.AwsEksCluster;
    const kubernetesClusterRiskContext = useGetKubernetesClusterRiskContext()(clusterModel);
    const resourceRiskContext = useGetAwsResourceRiskContext()(clusterModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsEksClusterRiskContext",
            () => ({
                vpc: "The {{translatedClusterTypeName}} is part of {{vpc}}"
            }));

    return {
        ...kubernetesClusterRiskContext,
        ...resourceRiskContext,
        vpc:
            new RiskDefinitionContextItem(
                localization.vpc({
                    translatedClusterTypeName:
                        entityTypeNameTranslator(
                            cluster.typeName,
                            {
                                includeServiceName: false,
                                variant: "text"
                            }),
                    vpc:
                        <InlineEntities
                            entityIdsOrModels={cluster.vpcIds}
                            entityTypeName={Contract.TypeNames.GcpComputeVpc}
                            variant="itemAndTypeOrItemCountAndType"/>
                })),
        wideRangeInboundSubnetsContextItem: resourceRiskContext.getWideRangeInboundSubnetsContextItem(clusterModel.wideRangeAuthorizedSubnets)
    };
}