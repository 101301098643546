﻿import { DataTableColumn, optionalTableCell, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, AwsEc2DeviceTable, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem, RiskDefinitionSection } from "../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsAutoScalingLaunchConfigurationRiskContext, useGetAwsEc2InstancesRiskContext, useGetAwsEc2VolumesRiskContext } from "../../../contexts";

export function useAwsEc2VolumeEncryptionDisabledRiskLaunchConfigurationDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsEc2VolumeEncryptionDisabledRisk;
    const riskData = risk.data as Contract.AwsEc2VolumeEncryptionDisabledRiskAutoScalingLaunchConfigurationData;
    const instanceModels = entityModelStore.useGet(riskData.instanceIds) as Contract.AwsEc2InstanceModel[];
    const launchConfigurationModel = entityModelStore.useGet(risk.entityId) as Contract.AwsAutoScalingLaunchConfigurationModel;
    const launchConfiguration = launchConfigurationModel.entity as Contract.AwsAutoScalingLaunchConfiguration;
    const volumeModels = entityModelStore.useGet(risk.aggregatedEntityIds) as Contract.AwsEc2VolumeModel[];

    const getAwsAutoScalingLaunchConfigurationRiskContext = useGetAwsAutoScalingLaunchConfigurationRiskContext();
    const getAwsEc2InstancesRiskContext = useGetAwsEc2InstancesRiskContext();
    const getAwsEc2VolumesRiskContext = useGetAwsEc2VolumesRiskContext();

    const deviceNameToVolumeModelsMap =
        useMemo(
            () => {
                const instanceRawIds =
                    _.map(
                        instanceModels,
                        instanceModel => (instanceModel.entity as Contract.AwsEc2Instance).rawId);

                return _(launchConfiguration.devices).
                    keyBy(device => device.name).
                    mapValues(
                        device =>
                            _.filter(
                                volumeModels,
                                volumeModel => {
                                    const volume = volumeModel.entity as Contract.AwsEc2Volume;
                                    return volume.sourceSnapshotRawId === device.snapshotRawId &&
                                        !_.isEmpty(
                                            _(instanceRawIds).
                                                intersection(_.keys(volume.instanceRawIdToDeviceNameMap)).
                                                filter(instanceRawId => volume.instanceRawIdToDeviceNameMap[instanceRawId] === device.name).
                                                value());
                                })).
                    value();
            },
            [launchConfiguration, volumeModels]);

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2VolumeEncryptionDisabledRiskDefinition.hooks.useAwsEc2VolumeEncryptionDisabledRiskLaunchConfigurationDefinition",
            () => ({
                contextItems: {
                    launchDetails: {
                        title: {
                            none: "The {{translatedLaunchConfigurationTypeName}} has unencrypted device mapping configuration",
                            volume: "The {{translatedLaunchConfigurationTypeName}} was used to launch {{instances}} with {{volumes}}"
                        },
                        volumes: [
                            "1 unencrypted volume",
                            "{{count | NumberFormatter.humanize}} unencrypted volumes"
                        ]
                    }
                },
                description: {
                    none: "{{launchConfiguration}} has unencrypted device mapping configuration",
                    volume: "{{launchConfiguration}} was used to launch auto scaling groups with unencrypted volumes"
                },
                deviceTable: {
                    volumes: "Volumes"
                },
                sections: {
                    resolution: {
                        step1: "Select the launch configuration and choose **Actions, Copy launch configuration**.",
                        step2: "Replace the existing image with an image that contains encrypted block device mappings",
                        step3: "Click **Create launch configuration**",
                        step4: "Before replacing the launch configuration, verify that all identities used to launch the instances have decrypt permissions",
                        step5: "Update auto scaling groups to use the new secured launch configuration",
                        step6: "Click on **Delete launch configuration** to delete the unsecured version",
                        step7: "Wait until all unencrypted instances are relaunched with the encrypted launch configuration version"
                    },
                    title: "Launch Configuration"
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        (_.isEmpty(risk.aggregatedEntityIds)
            ? localization.description.none
            : localization.description.volume)({
            launchConfiguration:
                <Entity
                    entityIdOrModel={launchConfigurationModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Ec2,
                AwsConsoleUrlBuilder.getAutoScalingGroupLaunchConfigurationUrl(launchConfiguration)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6(),
            localization.sections.resolution.step7()
        ],
        riskModel,
        () => {
            const instancesContextItems = getAwsEc2InstancesRiskContext(instanceModels);
            const launchConfigurationContextItems = getAwsAutoScalingLaunchConfigurationRiskContext(launchConfigurationModel);
            const volumesContextItems = getAwsEc2VolumesRiskContext(volumeModels);
            return [
                launchConfigurationContextItems.generalInformation,
                launchConfigurationContextItems.sensitive,
                launchConfigurationContextItems.getAutoScalingGroupsContextItem(riskData.autoScalingGroupIds),
                new RiskDefinitionContextItem(
                    (_.isEmpty(risk.aggregatedEntityIds)
                        ? localization.contextItems.launchDetails.title.none
                        : localization.contextItems.launchDetails.title.volume)({
                        instances:
                            <InlineEntities
                                entityIdsOrModels={riskData.instanceIds}
                                entityTypeName={Contract.TypeNames.AwsEc2Instance}
                                variant="itemCountAndType"/>,
                        launchConfigurationModel:
                            <Entity
                                entityIdOrModel={launchConfigurationModel}
                                variant="text"/>,
                        translatedLaunchConfigurationTypeName:
                            entityTypeNameTranslator(
                                launchConfigurationModel.entity.typeName,
                                {
                                    includeServiceName: false,
                                    variant: "text"
                                }),
                        volumes:
                            <InlineEntities
                                entityIdsOrModels={risk.aggregatedEntityIds}
                                entityTypeName={Contract.TypeNames.AwsEc2Volume}
                                namePluralizer={localization.contextItems.launchDetails.volumes}
                                variant="itemCountAndType"/>
                    })),
                instancesContextItems.sensitive,
                volumesContextItems.sensitive,
                launchConfigurationContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    _.isEmpty(risk.aggregatedEntityIds)
                        ? <AwsEc2DeviceTable
                            devices={
                                _.filter(
                                    launchConfiguration.devices,
                                    device => device.encryptionEnabled === false)}
                            snapshotRawIdToIdMap={launchConfigurationModel.snapshotRawIdToIdMap}/>
                        : <AwsEc2DeviceTable
                            additionalColumns={[
                                <DataTableColumn
                                    id={localization.deviceTable.volumes()}
                                    key={localization.deviceTable.volumes()}
                                    render={
                                        optionalTableCell<Contract.AwsEc2Device>(
                                            item =>
                                                _.isEmpty(deviceNameToVolumeModelsMap[item.name])
                                                    ? undefined
                                                    : <InlineEntities
                                                        entityIdsOrModels={deviceNameToVolumeModelsMap[item.name]}
                                                        entityTypeName={Contract.TypeNames.AwsEc2Volume}
                                                        variant="itemOrItemCountAndType"/>)}
                                    title={localization.deviceTable.volumes()}/>
                            ]}
                            devices={launchConfiguration.devices}
                            snapshotRawIdToIdMap={launchConfigurationModel.snapshotRawIdToIdMap}/>,
                    localization.sections.title())
            ]
        });
}