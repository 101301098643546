import { Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, SpecificCustomRiskPolicyProps } from "../../../..";
import { Edit, View } from "./components";


export function GcpPrincipalNotAllowedResourcePermissionRiskPolicy({ editOptions, riskPolicyConfiguration, sx }: SpecificCustomRiskPolicyProps) {
    const localization =
        useLocalization(
            "common.customRiskPolicy.gcpPrincipalNotAllowedResourcePermissionRiskPolicy",
            () => ({
                template: "*capitalize*{{principals}}** should not have {{actions}} on {{resources}}"
            }));

    return _.isNil(editOptions)
        ? <View
            riskPolicyConfiguration={riskPolicyConfiguration as Optional<Contract.GcpPrincipalNotAllowedResourcePermissionRiskPolicyConfiguration>}
            sx={sx}
            templateTranslator={localization.template}/>
        : <Edit
            actionsRef={editOptions.actionsRef}
            riskPolicyConfiguration={riskPolicyConfiguration as Optional<Contract.GcpPrincipalNotAllowedResourcePermissionRiskPolicyConfiguration>}
            templateTranslator={localization.template}
            onValidChange={editOptions.onValidChange}/>;
}