import { CollapsedIcon, CopyToClipboardActionButton, TextViewer } from "@infrastructure";
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "../themes";

type TenantRoleItemAccordion = {
    expanded: boolean;
    onChange: (expanded: boolean) => void;
    rawText: string;
    title: string;
    tooltip: string;
};

export function TenantRoleItemAccordion({ expanded, onChange, rawText, title, tooltip }: TenantRoleItemAccordion) {
    const theme = useTheme();
    return (
        <Accordion
            expanded={expanded}
            onChange={
                (_event, expanded) => onChange(expanded)}>
            <AccordionSummary expandIcon={<CollapsedIcon/>}>
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                    sx={{ width: "100%" }}>
                    <Typography sx={{ fontWeight: 600 }}>
                        {title}
                    </Typography>
                    <CopyToClipboardActionButton
                        getValue={() => rawText}
                        size="normal"
                        tooltip={tooltip}/>
                </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: theme.spacing(0) }}>
                <TextViewer
                    format="json"
                    height={theme.spacing(50)}
                    options={{ renderLineHighlight: "none" }}
                    sx={{
                        border: "none",
                        borderRadius: "none",
                        padding: theme.spacing(0)
                    }}
                    text={rawText}/>
            </AccordionDetails>
        </Accordion>);
}