import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpSqlInstanceStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpSqlInstanceStatusTranslator",
            () => ({
                [Contract.TypeNames.GcpSqlInstanceStatus]: {
                    [Contract.GcpSqlInstanceStatus.Failed]: "Failed",
                    [Contract.GcpSqlInstanceStatus.Maintenance]: "Maintenance",
                    [Contract.GcpSqlInstanceStatus.OnlineMaintenance]: "Online Maintenance",
                    [Contract.GcpSqlInstanceStatus.PendingCreate]: "Pending Create",
                    [Contract.GcpSqlInstanceStatus.PendingDelete]: "Pending Delete",
                    [Contract.GcpSqlInstanceStatus.Runnable]: "Runnable",
                    [Contract.GcpSqlInstanceStatus.Suspended]: "Suspended"
                }
            }));
    return (status: Contract.GcpSqlInstanceStatus) =>
        localization[Contract.TypeNames.GcpSqlInstanceStatus][status]();
}