import { AnalyticsEventActionType, useLocalization, useTrackAnalyticsEvent } from "@infrastructure";
import React from "react";
import { Contract, RiskPoliciesType, RiskPolicyItem, useLayoutOptions } from "../../../../common";
import { View } from "./components";

export function RiskPolicies() {
    const localization =
        useLocalization(
            "views.customer.riskPolicies",
            () => ({
                title: "Policies"
            }));
    useLayoutOptions({ view: { title: localization.title() } });
    useTrackAnalyticsEvent(AnalyticsEventActionType.PageView);

    return <View type={RiskPoliciesType.Cloud}/>;
}

export class GroupedItem {
    public id: string;

    constructor(
        public riskPolicyCategory: Contract.RiskPolicyCategory,
        public tableItems: RiskPolicyItem[]) {
        this.id = riskPolicyCategory;
    }
}

export enum RiskPoliciesFilterId {
    ComplianceIdsOrSectionTypes = "complianceIdsOrSectionTypes",
    Id = "id",
    Result = "result",
    Severity = "Severity",
    Status = "status",
    TenantType = "tenantType"
}