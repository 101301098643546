import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function OneIdentityIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M19.4858 21.3288C16.1562 23.2898 12.0299 23.3103 8.67984 21.4105C5.32978 19.5108 3.22577 15.936 3.20534 12.0753H2.00014C2.02057 16.365 4.34927 20.3074 8.08745 22.4319C11.8256 24.5563 16.3809 24.5155 20.0987 22.3502L19.4858 21.3288Z"
                fill="#04AADA"/>
            <path
                d="M8.53685 2.69917C11.8665 0.738161 15.9928 0.717733 19.3429 2.61747L19.9353 1.57568C16.1971 -0.548755 11.6214 -0.528328 7.92404 1.65739C4.22671 3.82267 1.97971 7.80598 2.00014 12.0957H3.20534C3.16449 8.23495 5.20721 4.63976 8.53685 2.69917Z"
                fill="#04AADA"/>
            <path
                d="M14.0114 2.39277C17.4431 2.39277 20.6093 4.23122 22.3252 7.19317L21.2834 7.78556C19.7923 5.1913 17.0142 3.57755 14.0114 3.57755V2.39277Z"
                fill="#04AADA"/>
            <path
                d="M22.3252 16.8144L21.2834 16.222C19.7718 18.7958 17.0142 20.4096 14.0114 20.4096C11.0085 20.4096 8.23044 18.7958 6.73926 16.2016L5.69747 16.794C7.41336 19.7559 10.5796 21.5944 14.0114 21.5944C17.4431 21.6148 20.6093 19.7763 22.3252 16.8144Z"
                fill="#04AADA"/>
            <path
                d="M6.71883 7.80598L5.67704 7.21359C3.96115 10.1755 3.96115 13.8524 5.67704 16.8144L6.71883 16.222C5.22764 13.6073 5.22764 10.4002 6.71883 7.80598Z"
                fill="#04AADA"/>
            <path
                d="M14.0114 4.80318C16.5852 4.80318 18.9547 6.1718 20.2417 8.39837C21.5286 10.6249 21.5286 13.3622 20.2417 15.6092C18.9547 17.8562 16.5852 19.2044 14.0114 19.2044C11.4375 19.2044 9.06796 17.8358 7.78104 15.6092C6.49413 13.3826 6.49413 10.6454 7.78104 8.39837C9.04753 6.1718 11.4375 4.80318 14.0114 4.80318ZM10.3753 9.90999C9.6195 11.1969 9.6195 12.8107 10.3753 14.118C11.1311 15.4049 12.5202 16.222 14.0114 16.222C15.5025 16.222 16.912 15.4253 17.6474 14.118C18.4032 12.8311 18.4032 11.2173 17.6474 9.90999C16.8916 8.62307 15.5025 7.80598 14.0114 7.80598C12.4997 7.80598 11.1107 8.60264 10.3753 9.90999Z"
                fill="#04AADA"/>
        </SvgIcon>);
}