import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore, RegionModelHelper, tenantModelStore } from "../../../../../../../../../../../../common";
import { OciConsoleUrlBuilder, useOciConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetRiskPolicyConfigurationPasswordRequirementContextItem } from "../../../useGetRiskPolicyConfigurationPasswordRequirementContextItem";
import { useGetOciIamDomainRiskContext } from "../contexts";

export function useOciIamDomainPasswordExpirationPolicyRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.OciIamDomainPasswordExpirationPolicyRisk;
    const domainModel = entityModelStore.useGet(risk.entityId) as Contract.OciIamDomainModel;
    const domain = domainModel.entity as Contract.OciIamDomain;

    const getOciIamDomainRiskContext = useGetOciIamDomainRiskContext();
    const consoleSignInStepTranslator = useOciConsoleSignInStepTranslator();
    const getRiskPolicyConfigurationPasswordRequirementContextItem = useGetRiskPolicyConfigurationPasswordRequirementContextItem();

    const tenantModel = tenantModelStore.useGet(domainModel.entity.tenantId);
    const ociTenantModel = tenantModelStore.useGet((tenantModel.configuration as Contract.OciTenantConfiguration).ocid);
    const ociTenantConfiguration = ociTenantModel.configuration as Contract.OciTenantConfiguration;

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.compliance.useOciIamDomainPasswordExpirationPolicyRiskDefinition",
            () => ({
                contextItems: {
                    passwordPolicyExpirationIntervalDays: {
                        riskPolicyConfiguration: "minimum password requirement of **{{value}} days**"
                    }
                },
                description: "{{domain}} password expiration policy requires passwords to expire after {{passwordPolicyExpirationIntervalDays}} days",
                sections: {
                    resolution: {
                        step1: "Click **Add**",
                        step2: "Set **Expires after (days)** to **{{passwordPolicyExpirationIntervalDays}}** or greater",
                        step3: "Click **Add**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            domain:
                <Entity
                    entityIdOrModel={domainModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            passwordPolicyExpirationIntervalDays: risk.passwordPolicyExpirationIntervalDays
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.OciConsoleView.PasswordPolicy,
                OciConsoleUrlBuilder.GetIamDomainPasswordPolicyUrl(
                    ociTenantConfiguration.realmId,
                    domain.ocid!,
                    RegionModelHelper.get(domain.regionId!).systemName)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2({
                passwordPolicyExpirationIntervalDays: risk.passwordPolicyExpirationIntervalDays
            }),
            localization.sections.resolution.step3()],
        riskModel,
        () => {
            const domainRiskContext = getOciIamDomainRiskContext(domainModel);
            return [
                domainRiskContext.generalInformation,
                getRiskPolicyConfigurationPasswordRequirementContextItem(localization.contextItems.passwordPolicyExpirationIntervalDays.riskPolicyConfiguration({ value: risk.passwordPolicyExpirationIntervalDays })),
                domainRiskContext.status,
                domainRiskContext.sensitive,
                domainRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}