﻿import { Link, Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../../useCommonSectionsAndDescriptionDefinition";
import { ContextSection, DetailsSection } from "./components";

export function useAwsEc2InstanceUserDataSecretExistsRiskLaunchTemplateDefinition(riskModel: Contract.AwsEc2InstanceUserDataSecretExistsRiskModel) {
    const launchTemplateModel = entityModelStore.useGet(riskModel.risk.entityId) as Contract.AwsEc2LaunchTemplateModel;
    const launchTemplate = (launchTemplateModel.entity) as Contract.AwsEc2LaunchTemplate;
    const autoScalingGroupsInfo = riskModel.risk.infos.find(info => info.typeName == Contract.TypeNames.AwsEc2InstanceUserDataSecretExistsRiskAutoScalingGroupsInfo) as Optional<Contract.AwsEc2InstanceUserDataSecretExistsRiskAutoScalingGroupsInfo>;

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceUserDataSecretExistsRiskDefinition.hooks.useAwsEc2InstanceUserDataSecretExistsRiskLaunchTemplateDefinition",
            () => ({
                description: "{{launchTemplate}} is exposing secrets in clear text via user data",
                sections: {
                    details: "Launch Template Revisions",
                    resolution: {
                        step1: "Select the launch template **{{launchTemplateName}}** and choose **Actions**, **Modify template (Create new version)**",
                        step2: "Expand the **Advanced Details** section",
                        step3: {
                            link: "Secrets Manager",
                            text: "In the **User data**, migrate your secrets to {{secretsManagerLink}}"
                        },
                        step4: "Click on the **Create template version** button",
                        step5: "Set the new version as the default by clicking on **Actions > Set default version**",
                        step6: "Make sure the {{autoScalingGroups}} are using the latest version",
                        step7: "Make sure the {{instances}} are using the latest version",
                        step8: "Wait until all instances are relaunched with the updated metadata version",
                        step9: "On the Launch Template page, click Versions, and delete insecure versions by clicking **Delete template version**"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            launchTemplate:
                <Entity
                    entityIdOrModel={riskModel.risk.entityId}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () =>
            [
                consoleSignInStepTranslator(
                    Contract.AwsConsoleView.Ec2,
                    AwsConsoleUrlBuilder.getEc2LaunchTemplateUrl(launchTemplate)),
                localization.sections.resolution.step1({ launchTemplateName: launchTemplate.displayName }),
                localization.sections.resolution.step2(),
                localization.sections.resolution.step3.text({
                    secretsManagerLink:
                        <Link
                            urlOrGetUrl={riskModel.secretsManagerDocumentationUrl}
                            variant="external">
                            {localization.sections.resolution.step3.link()}
                        </Link>
                }),
                localization.sections.resolution.step4(),
                localization.sections.resolution.step5(),
                !_.isNil(autoScalingGroupsInfo) &&
                autoScalingGroupsInfo.autoScalingGroupIds.length > 0
                    ? localization.sections.resolution.step6({
                        autoScalingGroups:
                            <InlineEntities
                                entityIdsOrModels={autoScalingGroupsInfo!.autoScalingGroupIds}
                                entityTypeName={Contract.TypeNames.AwsAutoScalingAutoScalingGroup}
                                variant="itemCountAndType"/>
                    })
                    : undefined,
                riskModel.risk.aggregatedEntityIds.length > 0
                    ? localization.sections.resolution.step7({
                        instances:
                            <InlineEntities
                                entityIdsOrModels={riskModel.risk.aggregatedEntityIds}
                                entityTypeName={Contract.TypeNames.AwsEc2Instance}
                                variant="itemCountAndType"/>
                    })
                    : undefined,
                localization.sections.resolution.step8(),
                localization.sections.resolution.step9()
            ],
        riskModel,
        undefined,
        {
            contextSectionElement: <ContextSection riskModel={riskModel}/>,
            sections:
                _<RiskDefinitionSection>([]).
                    concat(
                        new RiskDefinitionSection(
                            <DetailsSection riskModel={riskModel}/>,
                            localization.sections.details())).
                    value()
        });
}