import { map } from "@infrastructure";
import { Box, Button, SxProps, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { Fragment, ReactNode, useState } from "react";
import { ActionButton, ActionButtonProps, ConfirmDialog, ConfirmDialogProps, Tooltip } from ".";

type ConfirmButtonProps =
    Omit<ConfirmDialogProps, "open" | "onClose"> & {
        children: ReactNode;
        containerSx?: SxProps;
        disableConfirm?: boolean;
        disabled?: boolean;
        icon?: ReactNode;
        onClick?: (buttonTitle?: string) => void;
        sx?: SxProps;
        tooltip?: string;
        variant: "action" | "actionOutlined" | "contained" | "outlined" | "text";
    } & Pick<ActionButtonProps, "size">;

export function ConfirmButton({ children, containerSx, disableConfirm = false, disabled = false, icon, onClick, size, sx, tooltip, variant, ...props }: ConfirmButtonProps) {
    const [open, setOpen] = useState(false);

    function confirm() {
        if (disableConfirm) {
            onClick?.();
        } else {
            setOpen(true);
        }
    }

    const theme = useTheme();
    return (
        <Fragment>
            {open &&
                <ConfirmDialog
                    onClose={
                        result => {
                            if (result && !_.isNil(onClick)) {
                                onClick(
                                    result === true
                                        ? undefined
                                        : result);
                            }

                            setOpen(false);
                        }}
                    {...props}/>}
            <Tooltip titleOrGetTitle={tooltip}>
                <Box
                    sx={{
                        margin:
                            variant === "text"
                                ? theme.spacing(0, 0.25)
                                : undefined,
                        ...containerSx
                    }}>
                    {map(
                        variant,
                        {
                            "action":
                                () =>
                                    <ActionButton
                                        disabled={disabled}
                                        size={size}
                                        sx={sx}
                                        onClick={() => confirm()}>
                                        {children}
                                    </ActionButton>,
                            "actionOutlined":
                                () =>
                                    <ActionButton
                                        disabled={disabled}
                                        size={size}
                                        sx={sx}
                                        variant="outlined"
                                        onClick={() => confirm()}>
                                        {children}
                                    </ActionButton>,
                            "contained":
                                () =>
                                    <Button
                                        disabled={disabled}
                                        size={size}
                                        startIcon={icon}
                                        sx={sx}
                                        onClick={() => confirm()}>
                                        {children}
                                    </Button>,
                            "outlined":
                                () =>
                                    <Button
                                        disabled={disabled}
                                        size={size}
                                        startIcon={icon}
                                        sx={sx}
                                        variant="outlined"
                                        onClick={() => confirm()}>
                                        {children}
                                    </Button>,
                            "text":
                                () =>
                                    <Button
                                        disabled={disabled}
                                        size={size}
                                        startIcon={icon}
                                        sx={{
                                            "& .MuiButton-startIcon": {
                                                marginLeft: 0,
                                                marginRight: theme.spacing(0.75)
                                            },
                                            "& .MuiButton-startIcon > :nth-of-type(1)": {
                                                fontSize: "16px"
                                            },
                                            "&.Mui-disabled": {
                                                backgroundColor: "unset",
                                                color: theme.palette.text.disabled
                                            },
                                            ":hover": {
                                                backgroundColor: theme.palette.action.alternateHover,
                                                textDecoration: "none"
                                            },
                                            padding: theme.spacing(0.5, 1.5),
                                            width: "100%"
                                        }}
                                        variant="text"
                                        onClick={() => confirm()}>
                                        <Typography
                                            noWrap={true}
                                            sx={{ fontWeight: 500 }}>
                                            {children}
                                        </Typography>
                                    </Button>
                        })}
                </Box>
            </Tooltip>
        </Fragment>);
}