import { ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { memo } from "react";
import { Tenant, TenantVariant } from ".";
import { useTenantNameTranslator } from "..";

const TenantFilterMemo = memo(TenantFilter);
export { TenantFilterMemo as TenantFilter };

type TenantFilterProps = {
    colored?: boolean;
    emptyValueEnabled?: boolean;
    placeholder: string;
    tenantIds: string[];
    variant?: Exclude<TenantVariant, "icon">;
};

export type TenantOptions = {
    variant?: TenantVariant;
};

function TenantFilter({ colored, emptyValueEnabled, placeholder, tenantIds, variant }: TenantFilterProps) {
    const tenantNameTranslator = useTenantNameTranslator();
    return (
        <ValuesFilter
            emptyValueOptions={{
                enabled: emptyValueEnabled,
                variant
            }}
            placeholder={placeholder}>
            {_.map(
                tenantIds,
                tenantId =>
                    <ValuesFilterItem
                        key={tenantId}
                        title={tenantNameTranslator(tenantId)}
                        value={tenantId}>
                        {() =>
                            <Typography noWrap={true}>
                                <Tenant
                                    colored={colored}
                                    tenantId={tenantId}
                                    variant={variant ?? "text"}/>
                            </Typography>}
                    </ValuesFilterItem>)}
        </ValuesFilter>);
}