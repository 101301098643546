import { Box } from "@mui/system";
import _ from "lodash";
import React, { ReactElement } from "react";
import { CheckboxField, Dropdown as InfrastructureDropdown, DropdownAnalyticsOptions, FilterField, ItemSelectorField, map, SelectionView, useLocalization } from "@infrastructure";
import { Contract, tenantModelStore, useScopeNameTranslator, useTheme } from "../..";
import { Scope, ScopeTree, TreeItem } from "..";
import { Dropdown } from "./components";

export type TenantMultiSelectProps = {
    analyticsOptions?: DropdownAnalyticsOptions;
    dense?: boolean;
    disabled?: boolean;
    displayAllAsEmpty?: boolean;
    dropdownIcon?: ReactElement;
    dropdownTitle?: boolean;
    emptyValue?: string;
    fullWidth?: boolean;
    onClose?: () => void;
    onSelectedTenantIdsChanged: (selectedTenantIds: string[]) => void;
    open?: boolean;
    placeholder?: string;
    readonly?: boolean;
    rootFolderId?: string;
    selectedTenantIds: string[];
    tenantIds: string[];
    variant: TenantMultiSelectVariant;
};

export type TenantMultiSelectVariant = "filter" | "globalFilter" | "itemSelector";

export function TenantMultiSelect({ analyticsOptions, dense, disabled = false, displayAllAsEmpty = true, dropdownIcon, dropdownTitle = false, emptyValue, fullWidth, onClose, onSelectedTenantIdsChanged, open, placeholder, readonly = false, rootFolderId, selectedTenantIds, tenantIds, variant }: TenantMultiSelectProps) {
    const tenantModels = tenantModelStore.useGet(tenantIds);
    const scopeNameTranslator = useScopeNameTranslator();

    function toggle(scopeNodeModelItem: TreeItem<Contract.ScopeNodeModel>) {
        const scopeIds =
            _.map(
                scopeNodeModelItem.leaves(),
                scopeNodeModelItem => scopeNodeModelItem.value.configuration.id);
        const itemChecked =
            _.every(
                scopeIds,
                scopeId => _.includes(selectedTenantIds, scopeId));

        onSelectedTenantIdsChanged(
            itemChecked
                ? _.difference(selectedTenantIds, scopeIds)
                : _.union(selectedTenantIds, scopeIds));
    }

    const localization =
        useLocalization(
            "common.tenantMultiSelect",
            () => ({
                placeholder: "Accounts"
            }));

    const theme = useTheme();
    const isTenantIdsEmpty = _.isEmpty(tenantIds);
    return (
        <InfrastructureDropdown
            analyticsOptions={analyticsOptions}
            disabled={disabled || isTenantIdsEmpty}
            fullWidth={fullWidth}
            open={open && !isTenantIdsEmpty}
            popoverElement={
                <ScopeTree
                    containerSx={
                        fullWidth
                            ? undefined
                            : {
                                maxWidth: theme.spacing(80),
                                minWidth: theme.spacing(40)
                            }}
                    itemHover={false}
                    rootFolderId={rootFolderId}
                    scopeIds={tenantIds}
                    onScopeClick={
                        scopeNodeModelItem => {
                            if (scopeNodeModelItem.value.type === Contract.ScopeType.CloudProviderTenant) {
                                toggle(scopeNodeModelItem);
                            }
                        }}>
                    {
                        scopeNodeModelItem => {
                            const scopeNodeModels = scopeNodeModelItem.leaves();
                            const itemChecked =
                                _.every(
                                    scopeNodeModels,
                                    scopeNodeModelItem => _.includes(selectedTenantIds, scopeNodeModelItem.value.configuration.id));

                            return <CheckboxField
                                checked={itemChecked}
                                disabled={readonly}
                                indeterminate={
                                    !itemChecked &&
                                    _.some(
                                        scopeNodeModelItem.leaves(),
                                        scopeNodeModelItem => _.includes(selectedTenantIds, scopeNodeModelItem.value.configuration.id))}
                                key={scopeNodeModelItem.value.configuration.id}
                                onChange={() => toggle(scopeNodeModelItem)}
                                onClick={event => event.stopPropagation()}>
                                <Scope
                                    scopeId={scopeNodeModelItem.value.configuration.id}
                                    scopeNameTranslatorOptions={{
                                        tenantNameTranslatorOptions: {
                                            includeRawId:
                                                scopeNodeModelItem.value.configuration.typeName === Contract.TypeNames.AwsTenantConfiguration ||
                                                scopeNodeModelItem.value.configuration.typeName === Contract.TypeNames.GcpTenantConfiguration
                                        }
                                    }}
                                    sx={{ color: theme.palette.text.primary }}
                                    variant="treeNode"/>
                            </CheckboxField>;
                        }}
                </ScopeTree>}
            popoverElementContainerSx={{ padding: 0 }}
            title={
                variant === "filter"
                    ? undefined
                    : dropdownTitle
                        ? placeholder ?? localization.placeholder()
                        : undefined}
            onClose={
                () => {
                    onClose?.();
                }}>
            {map(
                variant,
                {
                    "filter": () =>
                        <FilterField
                            emptyValue={selectedTenantIds.length === 0}
                            focused={open}
                            placeholder={emptyValue ?? placeholder ?? localization.placeholder()}
                            selection={
                                <SelectionView
                                    empty={selectedTenantIds.length === 0}
                                    label={placeholder ?? localization.placeholder()}
                                    renderValue={
                                        (inline, value) =>
                                            <Box
                                                sx={{
                                                    maxWidth: theme.spacing(25),
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis"
                                                }}>
                                                {scopeNameTranslator(
                                                    value,
                                                    {
                                                        tenantNameTranslatorOptions: {
                                                            includeRawId: true
                                                        }
                                                    })}
                                            </Box>}
                                    selectedValues={selectedTenantIds}
                                    totalCount={tenantModels.length}/>}/>,
                    "globalFilter": () =>
                        <Dropdown
                            disabled={disabled || _.isEmpty(tenantModels)}
                            includeRawId={true}
                            selectedTenantIds={selectedTenantIds}
                            summarized={true}
                            tenantModels={tenantModels}/>,
                    "itemSelector": () =>
                        <ItemSelectorField
                            dense={dense}
                            disabled={disabled || _.isEmpty(tenantModels)}
                            emptyValue={emptyValue}
                            icon={dropdownIcon}
                            placeholder={placeholder ?? localization.placeholder()}
                            selectionCount={
                                !selectedTenantIds ||
                                displayAllAsEmpty &&
                                selectedTenantIds.length === tenantIds.length
                                    ? 0
                                    : selectedTenantIds.length}/>
                })}
        </InfrastructureDropdown>);
}