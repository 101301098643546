﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../../common";
import { RiskDefinitionSection } from "../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { ResourcesExternalConsoleLink } from "../../../../components";
import { useGetGcpComputeImageRiskContext, useGetGcpScopeResourcePublicAccessExistsRiskContext } from "../../../contexts";
import { ScopeResourceRoleBindingTableSection } from "../components";

export function useGcpComputeImagePublicAccessExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpComputeImagePublicAccessExistsRisk;
    const imageModel = entityModelStore.useGet(risk.entityId) as Contract.GcpComputeImageModel;

    const getGcpComputeImageRiskContext = useGetGcpComputeImageRiskContext();
    const getGcpScopeResourcePublicAccessExistsRiskContext = useGetGcpScopeResourcePublicAccessExistsRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.publicAccess.hooks.useGcpComputeImagePublicAccessExistsRiskDefinition",
            () => ({
                description: "{{image}} is publicly accessible via IAM roles",
                sections: {
                    resolution: {
                        step1: "In the list of images, select the image **{{image}}** and go to the PERMISSIONS tab in the right pane",
                        step2: "Before changing the permissions, verify that identities can access the image without public access",
                        step3: "Using the filter, search for **allAuthenticatedUsers** and then delete the role binding"
                    },
                    roleBindingIds: "Role Bindings"
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            image:
                <Entity
                    entityIdOrModel={imageModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <ResourcesExternalConsoleLink
                key={risk.tenantId}
                tenantId={risk.tenantId}
                typeName={imageModel.entity.typeName}/>,
            localization.sections.resolution.step1({
                image:
                    <Entity
                        entityIdOrModel={imageModel}
                        variant="text"/>
            }),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const imageRiskContext = getGcpComputeImageRiskContext(imageModel);
            const scopeResourcePublicAccessExistsRiskContext = getGcpScopeResourcePublicAccessExistsRiskContext(risk);
            return [
                imageRiskContext.generalInformation,
                imageRiskContext.sensitive,
                imageRiskContext.encryption,
                scopeResourcePublicAccessExistsRiskContext.permissionActions,
                imageRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <ScopeResourceRoleBindingTableSection
                        csvExportFilePrefixes={[localization.sections.roleBindingIds()]}
                        risk={risk}/>,
                    localization.sections.roleBindingIds())
            ]
        });
}