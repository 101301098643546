import _ from "lodash";
import React from "react";
import { NumberFormatter, TimeFormatter, useFormatRelativeTime, useLocalization } from "@infrastructure";
import { Contract, InfoCard, InfoItem } from "../../../../../../../../../common";
import { useDataAnalysisResourceSegmentTranslator } from "../hooks";

type PropertiesProps = {
    dataAnalysisResourceModel: Contract.IDataAnalysisResourceModel;
};

export function Properties({ dataAnalysisResourceModel }: PropertiesProps) {
    const formatRelativeTime = useFormatRelativeTime();

    const dataAnalysisResourceSegmentTranslator = useDataAnalysisResourceSegmentTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.dataAnalysisResourceOverview.properties",
            () => ({
                items: {
                    lastModified: "Data Last Updated Time",
                    scannedDataSegmentCount: "Scanned {{dataSegmentsTitle}}",
                    scanTime: "Scan Time",
                    size: "Data Size",
                    sizeSensitiveItems: "Sensitive {{dataSegmentsTitle}}"
                },
                title: "Resource Details"
            }));

    const dataSegmentsTitle = dataAnalysisResourceSegmentTranslator(dataAnalysisResourceModel);
    const scannedDataSegmentCount =
        _.isNil(dataAnalysisResourceModel.scannedDataSegmentCount) &&
            dataAnalysisResourceModel.dataAnalysisStatus === Contract.DataAnalysisResourceModelDataAnalysisStatus.Scanned
            ? 0
            : dataAnalysisResourceModel.scannedDataSegmentCount;

    return (
        <InfoCard
            columns={true}
            itemContainerSx={{ padding: 0 }}>
            <InfoItem
                copyToClipboardOptions={{ copyValue: TimeFormatter.profileFormatDateTime(dataAnalysisResourceModel.scanTime) }}
                title={localization.items.scanTime()}
                value={
                    _.isNil(dataAnalysisResourceModel.scanTime)
                        ? undefined
                        : formatRelativeTime(
                            dataAnalysisResourceModel.scanTime,
                            TimeFormatter.profileFormatDateTime)}/>
            <InfoItem
                title={localization.items.lastModified()}
                value={
                    _.isNil(dataAnalysisResourceModel.dataLastModified)
                        ? undefined
                        : TimeFormatter.profileFormatDateTime(dataAnalysisResourceModel.dataLastModified)}/>
            {!_.isNil(dataSegmentsTitle) &&
                !_.isNil(scannedDataSegmentCount) &&
                <InfoItem
                    title={localization.items.scannedDataSegmentCount({ dataSegmentsTitle })}
                    value={NumberFormatter.humanize(scannedDataSegmentCount)}/>}
            {!_.isNil(dataSegmentsTitle) &&
                !_.isNil(dataAnalysisResourceModel.sensitiveDataSegmentCount) &&
                <InfoItem
                    title={localization.items.sizeSensitiveItems({ dataSegmentsTitle })}
                    value={NumberFormatter.humanize(dataAnalysisResourceModel.sensitiveDataSegmentCount)}/>}
            {!_.isNil(dataAnalysisResourceModel.storageSize) &&
                <InfoItem
                    title={localization.items.size()}
                    value={NumberFormatter.storage(Number(dataAnalysisResourceModel.storageSize))}/>}
        </InfoCard>);
}