import { useLocalization } from "@infrastructure";
import { Contract } from "..";

export function useExclusionEntityPropertyPatternTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useExclusionEntityPropertyPatternTranslator",
            () => ({
                [Contract.TypeNames.RiskPolicyConfigurationEntityExclusionEntityPropertyPattern]: {
                    [Contract.TypeNames.RiskPolicyConfigurationEntityExclusionAwsResourceArnPattern]: "ARN",
                    [Contract.TypeNames.RiskPolicyConfigurationEntityExclusionEntityNamePattern]: "Name",
                    [Contract.TypeNames.RiskPolicyConfigurationEntityExclusionResourceTagPattern]: "Tag"
                }
            }));
    return (entityPropertyPatternTypeName: string) =>
        localization[Contract.TypeNames.RiskPolicyConfigurationEntityExclusionEntityPropertyPattern].translate(entityPropertyPatternTypeName);
}