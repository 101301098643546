import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function AuditEventsIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M5 7C5 6.44772 5.44771 6 6 6H14C14.5523 6 15 6.44772 15 7C15 7.55228 14.5523 8 14 8H6C5.44771 8 5 7.55228 5 7ZM5 11C5 10.4477 5.44771 10 6 10H10C10.5523 10 11 10.4477 11 11C11 11.5523 10.5523 12 10 12H6C5.44771 12 5 11.5523 5 11ZM15.4285 15.0586C14.7915 15.0586 14.1799 15.3088 13.7257 15.7554C13.2714 16.202 13.0107 16.8091 12.9999 17.4461C12.9943 17.7685 13.0531 18.0888 13.1727 18.3883C13.2923 18.6878 13.4703 18.9604 13.6963 19.1904C13.9224 19.4203 14.192 19.603 14.4894 19.7277C14.7868 19.8523 15.106 19.9166 15.4285 19.9166C15.751 19.9166 16.0702 19.8523 16.3676 19.7277C16.665 19.603 16.9346 19.4203 17.1607 19.1904C17.3867 18.9604 17.5647 18.6878 17.6843 18.3883C17.8039 18.0888 17.8627 17.7685 17.8571 17.4461C17.8463 16.8091 17.5856 16.202 17.1313 15.7554C16.6771 15.3088 16.0655 15.0586 15.4285 15.0586ZM12.3236 14.3292C13.1519 13.5149 14.267 13.0586 15.4285 13.0586C16.59 13.0586 17.7051 13.5149 18.5334 14.3292C19.3617 15.1434 19.837 16.2505 19.8569 17.4119C19.8669 17.9998 19.7598 18.5838 19.5417 19.1299C19.4494 19.361 19.3381 19.5834 19.209 19.7948L20.7071 21.2929C21.0976 21.6834 21.0976 22.3166 20.7071 22.7071C20.3166 23.0976 19.6834 23.0976 19.2929 22.7071L17.8085 21.2227C17.5968 21.3576 17.3735 21.4746 17.1409 21.5721C16.5986 21.7995 16.0165 21.9166 15.4285 21.9166C14.8405 21.9166 14.2584 21.7995 13.7161 21.5721C13.1739 21.3448 12.6823 21.0118 12.2701 20.5925C11.8579 20.1732 11.5333 19.676 11.3153 19.1299C11.0972 18.5838 10.9901 17.9998 11.0001 17.4119C11.02 16.2505 11.4953 15.1434 12.3236 14.3292Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M9.92682 1L10.0647 1C11.8921 0.999979 15.3644 0.999962 16.522 1.15582C17.7221 1.31738 18.7322 1.6627 19.5348 2.46459C20.3378 3.26692 20.6832 4.27755 20.8446 5.47771C21.0001 6.63368 21 8.10351 21 9.92683V11C21 11.5523 20.5523 12 20 12C19.4477 12 19 11.5523 19 11V10C19 8.08622 18.9979 6.7514 18.8624 5.7443C18.7308 4.76595 18.4902 4.24808 18.1212 3.87941C17.7518 3.51031 17.2334 3.26962 16.2552 3.13794C15.2483 3.00237 11.9136 3 10 3C8.08622 3 6.7514 3.00213 5.7443 3.13758C4.76595 3.26916 4.24808 3.50982 3.87941 3.87881C3.51031 4.24823 3.26962 4.76662 3.13794 5.7448C3.00237 6.75174 3 8.08638 3 10V14C3 15.9138 3.00213 17.2486 3.13758 18.2557C3.26916 19.2341 3.50982 19.7519 3.87881 20.1206C4.24823 20.4897 4.76662 20.7304 5.7448 20.8621C6.75174 20.9976 8.08638 21 10 21C10.5523 21 11 21.4477 11 22C11 22.5523 10.5523 23 10 23H9.93535C8.10793 23 6.63564 23 5.47795 22.8442C4.27789 22.6826 3.26778 22.3373 2.4652 21.5354C1.66218 20.7331 1.31684 19.7225 1.15543 18.5223C0.999951 17.3663 0.999973 15.8965 1 14.0732L1 9.93534C0.999979 8.10792 0.999962 6.63564 1.15582 5.47795C1.31738 4.27789 1.6627 3.26778 2.46459 2.4652C3.26692 1.66218 4.27755 1.31684 5.47771 1.15543C6.63368 0.999951 8.1035 0.999973 9.92682 1Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}