﻿import { DataTableColumn, DeferredFilter, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, ScopeTenantFilter } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { TenantCell, useCommonEntityDefinition } from "../useCommonEntityDefinition";

export function useCiCommonEntityDefinition(definitionData: DefinitionData) {
    const commonEntityDefinition = useCommonEntityDefinition(definitionData);

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.ci.useCiCommonEntityDefinition",
            () => ({
                columns: {
                    registry: "Registry"
                }
            }));
    return {
        columns: {
            registryColumn:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <DeferredFilter
                                    promiseOrGetPromise={definitionData.entityModelFiltersPromise}
                                    title={localization.columns.registry()}>
                                    {filters =>
                                        <ScopeTenantFilter
                                            placeholder={localization.columns.registry()}
                                            tenantIds={filters.tenantIdItems.items}/>}
                                </DeferredFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.TenantId}
                    key={Contract.EntityModelProperty.TenantId}
                    render={TenantCell}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.registry()}/>,
            ...commonEntityDefinition.columns
        }
    };
}