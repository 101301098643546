import { Message, Optional, useLocalization, useWindowEventEffect } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, DistributionChart, DistributionChartItem, useTheme } from "../..";
import { Item, ItemTooltip } from "./components";

type RiskPolicyCategoryDistributionChartProps = {
    getPolicyCategoryUrl?: (riskPolicyCategory: Contract.RiskPolicyCategory, severities: Contract.Severity[]) => string;
    report?: boolean;
    severityToRiskPolicyCategoryToCountMap: Optional<Dictionary<Dictionary<number>>>;
};

export function RiskPolicyCategoryDistributionChart({ getPolicyCategoryUrl, report = false, severityToRiskPolicyCategoryToCountMap }: RiskPolicyCategoryDistributionChartProps) {
    const theme = useTheme();
    const [riskPolicyCategory, setRiskPolicyCategory] = useState<Contract.RiskPolicyCategory>();
    const [wide, setWide] =
        useState(
            window.innerWidth >= theme.breakpoints.get("xl") ||
            window.innerWidth < theme.breakpoints.get("lg"));

    useWindowEventEffect(
        "resize",
        () => {
            setWide(
                window.innerWidth >= theme.breakpoints.get("xl") ||
                window.innerWidth < theme.breakpoints.get("lg"));
        });

    const riskPolicyCategoryItems =
        useMemo(
            () =>
                _({}).
                    mergeWith(
                        ..._.values(severityToRiskPolicyCategoryToCountMap),
                        (count: Optional<number>, otherCount: number) =>
                            _.isNil(count)
                                ? otherCount
                                : count + otherCount).
                    toPairs().
                    groupBy(([riskPolicyCategory, _count]) => riskPolicyCategory).
                    map(
                        (riskPolicyCategoryAndCountList, riskPolicyCategory) => ({
                            category: riskPolicyCategory,
                            count:
                                _.sumBy(
                                    riskPolicyCategoryAndCountList,
                                    ([_category, count]) => count)
                        })).
                    orderBy(
                        riskPolicyCategoryData => riskPolicyCategoryData.count,
                        "desc").
                    map(
                        (riskPolicyCategoryData, riskDataIndex) =>
                            new RiskPolicyCategoryItem(
                                theme.palette.risk.category[riskDataIndex],
                                riskPolicyCategoryData.count,
                                riskPolicyCategoryData.category as Contract.RiskPolicyCategory)).
                    value(),
            [severityToRiskPolicyCategoryToCountMap]);

    const localization =
        useLocalization(
            "common.riskPolicyCategoryDistributionChart",
            () => ({
                helpText: "View a breakdown of findings by category. Each category represents a logical grouping of policies that triggered findings. Drill down into a specific category to see all findings triggered by policies in that category.",
                title: "Categories"
            }));

    return (
        <Stack
            alignItems={
                report
                    ? "center"
                    : undefined}
            className="riskPolicyCategory"
            direction="row"
            spacing={
                report
                    ? 0
                    : 3}
            sx={
                report
                    ? {
                        border: theme.border.primary,
                        borderRadius: theme.spacing(0.75),
                        padding: theme.spacing(2.5, 1, 1, 1.25)
                    }
                    : undefined}>
            <Stack
                spacing={3.5}
                sx={{ flex: 1 }}>
                {!report &&
                    <Stack
                        direction="row"
                        spacing={1}>
                        <Typography
                            sx={{ lineHeight: 1 }}
                            variant="h3">
                            {localization.title()}
                        </Typography>
                        <Message
                            level="info"
                            title={
                                <Typography
                                    sx={{
                                        fontWeight: "initial",
                                        padding: theme.spacing(1),
                                        whiteSpace: "pre-wrap"
                                    }}>
                                    {localization.helpText()}
                                </Typography>}
                            variant="minimal"/>
                    </Stack>}
                <Stack
                    flexWrap="wrap"
                    sx={{
                        height:
                            theme.spacing(
                                report
                                    ? 40
                                    : 30),
                        width: "fit-content"
                    }}>
                    {_(riskPolicyCategoryItems).
                        filter(
                            (_riskPolicyCategoryItem, riskPolicyCategoryItemIndex) =>
                                report ||
                                wide ||
                                riskPolicyCategoryItemIndex < 8).
                        map(
                            riskPolicyCategoryItem =>
                                <Box
                                    className={riskPolicyCategoryItem.riskPolicyCategory}
                                    key={riskPolicyCategoryItem.riskPolicyCategory}
                                    sx={{
                                        padding:
                                            report
                                                ? theme.spacing(0.75)
                                                : theme.spacing(0, 1.5, 1.5, 0)
                                    }}
                                    onMouseEnter={() => setRiskPolicyCategory(riskPolicyCategoryItem.riskPolicyCategory)}
                                    onMouseLeave={() => setRiskPolicyCategory(undefined)}>
                                    <Item
                                        highlight={riskPolicyCategoryItem.riskPolicyCategory === riskPolicyCategory}
                                        item={riskPolicyCategoryItem}
                                        report={report}
                                        urlOrGetUrl={
                                            getPolicyCategoryUrl?.(
                                                riskPolicyCategoryItem.riskPolicyCategory,
                                                _.keys(severityToRiskPolicyCategoryToCountMap) as Contract.Severity[])}/>
                                </Box>).
                        value()}
                </Stack>
            </Stack>
            <DistributionChart
                activeItemId={riskPolicyCategory}
                disableShadows={true}
                items={
                    _.map(
                        riskPolicyCategoryItems,
                        riskPolicyCategoryItem =>
                            new DistributionChartItem(
                                riskPolicyCategoryItem.color,
                                riskPolicyCategoryItem.riskPolicyCategory,
                                <ItemTooltip item={riskPolicyCategoryItem}/>,
                                riskPolicyCategoryItem.riskCount,
                                report
                                    ? undefined
                                    : getPolicyCategoryUrl?.(
                                        riskPolicyCategoryItem.riskPolicyCategory,
                                        _.keys(severityToRiskPolicyCategoryToCountMap) as Contract.Severity[])))}
                size="large"
                variant="pie"
                onActiveItemIdChanged={activeRiskPolicyCategory => setRiskPolicyCategory(activeRiskPolicyCategory as Contract.RiskPolicyCategory)}/>
        </Stack>);
}

export class RiskPolicyCategoryItem {
    constructor(
        public color: string,
        public riskCount: number,
        public riskPolicyCategory: Contract.RiskPolicyCategory) {
    }
}