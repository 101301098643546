import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { EntityExternalConsoleLink } from "../../components";
import { useGetAzureMySqlServerRiskContext } from "../contexts";

export function useAzureMySqlFlexibleServerInsecureTlsProtocolRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureMySqlFlexibleServerInsecureTlsProtocolRisk;
    const serverModel = entityModelStore.useGet(risk.entityId) as Contract.AzureMySqlFlexibleServerModel;

    const getAzureMySqlFlexibleServerRiskContext = useGetAzureMySqlServerRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureMySqlFlexibleServerInsecureTlsProtocolRiskDefinition",
            () => ({
                description: "{{server}} parameter tls_version does not meet minimum requirements",
                sections: {
                    resolution: {
                        step1: "Before enforcing minimum TLS version, verify that applications use the right TLS version to access the database",
                        step2: "Set the **tls_version** server parameter to **TLSv1.2** (or higher)",
                        step3: "Uncheck anything lower than TLSv1.2",
                        step4: "Click **Save**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            server:
                <Entity
                    entityIdOrModel={serverModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={`${risk.entityId}-${Contract.AzureConsoleEntityPage.ActiveDirectoryAdmins}`}
                page={Contract.AzureConsoleEntityPage.ServerConfigurations}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const serverRiskContext = getAzureMySqlFlexibleServerRiskContext(serverModel);
            return [
                serverRiskContext.generalInformation,
                serverRiskContext.inboundExternalAccessScope,
                serverRiskContext.sensitive,
                serverRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}