import { SvgIcon, SvgIconProps } from "@mui/material";
import React, { forwardRef, memo, Ref } from "react";

const ServiceNowIconForwardRef = memo(forwardRef(ServiceNowIcon));
export { ServiceNowIconForwardRef as ServiceNowIcon };

function ServiceNowIcon(props: SvgIconProps, ref: Ref<SVGSVGElement>) {
    return (
        <SvgIcon
            ref={ref}
            {...props}>
            <path
                clipRule="evenodd"
                d="M12.0732 1C9.66008 1.00536 7.30364 1.73215 5.30669 3.08699C3.30974 4.44183 1.76351 6.36282 0.866672 8.60315C-0.030168 10.8435 -0.23665 13.3008 0.273756 15.6594C0.784162 18.0179 1.98814 20.17 3.731 21.8391C4.14283 22.2323 4.68199 22.4648 5.2506 22.4943C5.8192 22.5239 6.37957 22.3487 6.82999 22.0004C8.31852 20.8963 10.1227 20.3003 11.9759 20.3003C13.8292 20.3003 15.6333 20.8963 17.1219 22.0004C17.5764 22.3601 18.1463 22.5422 18.7252 22.5125C19.3041 22.4828 19.8524 22.2435 20.2677 21.8391C22.0019 20.1788 23.203 18.0401 23.7182 15.6952C24.2333 13.3503 24.0393 10.9051 23.1607 8.67084C22.2821 6.43653 20.7587 4.5141 18.7843 3.14812C16.8099 1.78215 14.4738 1.0344 12.0732 1ZM12.0057 19.1031C11.2077 19.129 10.4127 18.9915 9.6697 18.6992C8.92666 18.4068 8.25117 17.9658 7.68468 17.403C7.11819 16.8403 6.67264 16.1678 6.37536 15.4267C6.07807 14.6856 5.93533 13.8916 5.95587 13.0934C5.92884 12.2868 6.06437 11.483 6.35439 10.7298C6.64441 9.97667 7.08299 9.28957 7.64403 8.70941C8.20507 8.12926 8.8771 7.66791 9.62011 7.35283C10.3631 7.03775 11.1619 6.87538 11.969 6.87538C12.7761 6.87538 13.5749 7.03775 14.3179 7.35283C15.0609 7.66791 15.7329 8.12926 16.294 8.70941C16.855 9.28957 17.2936 9.97667 17.5836 10.7298C17.8736 11.483 18.0091 12.2868 17.9821 13.0934C18.0034 13.8884 17.8625 14.6794 17.568 15.4181C17.2735 16.1569 16.8316 16.8279 16.2692 17.3902C15.7069 17.9526 15.0359 18.3945 14.2971 18.689C13.5584 18.9835 12.7674 19.1244 11.9724 19.1031"
                fill="#81B5A1"
                fillRule="evenodd"/>
        </SvgIcon>);
}