﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpNotebooksInstanceStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpNotebooksInstanceStatusTranslator",
            () => ({
                [Contract.TypeNames.GcpNotebooksInstanceStatus]: {
                    [Contract.GcpNotebooksInstanceStatus.Active]: "Active",
                    [Contract.GcpNotebooksInstanceStatus.Deleted]: "Deleted",
                    [Contract.GcpNotebooksInstanceStatus.Initializing]: "Initializing",
                    [Contract.GcpNotebooksInstanceStatus.Provisioning]: "Provisioning",
                    [Contract.GcpNotebooksInstanceStatus.Starting]: "Starting",
                    [Contract.GcpNotebooksInstanceStatus.Stopped]: "Stopped",
                    [Contract.GcpNotebooksInstanceStatus.Stopping]: "Stopping",
                    [Contract.GcpNotebooksInstanceStatus.Suspended]: "Suspended",
                    [Contract.GcpNotebooksInstanceStatus.Suspending]: "Suspending",
                    [Contract.GcpNotebooksInstanceStatus.Unspecified]: "Unspecified",
                    [Contract.GcpNotebooksInstanceStatus.Upgrading]: "Upgrading"
                }
            }));
    return (status: Contract.GcpNotebooksInstanceStatus) =>
        localization[Contract.TypeNames.GcpNotebooksInstanceStatus][status]();
}