//@ts-nocheck
import Mustache from "mustache";
import { Contract } from "../../../common";
import { AzureConsoleHelper } from "../../azure/utilities/azureConsoleHelper";

export class AadConsoleUrlBuilder {
    private static readonly _applicationCredentialsUrlTemplate = "https://{{domainName}}/#blade/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/Credentials/appId/{{applicationRawId}}";
    private static readonly _applicationUrlTemplate = "https://{{domainName}}/#blade/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/Overview/appId/{{applicationRawId}}";
    private static readonly _directoryRoleDefinitionUrlTemplate = "https://{{domainName}}/#blade/Microsoft_AAD_IAM/RoleMenuBlade/RoleMembers/objectId/{{rawId}}/roleName/{{name}}/roleTemplateId/{{rawId}}/adminUnitObjectId//customRole/{{type}}/resourceScope/%2F";
    private static readonly _directoryRoleManagementPolicyAssignmentUrlTemplate = "https://{{domainName}}/#view/Microsoft_Azure_PIMCommon/RoleSettingsView/policyId/{{policyRawId}}/roleName/{{roleName}}";
    private static readonly _groupGroupMembershipsUrlTemplate = "https://{{domainName}}/#blade/Microsoft_AAD_IAM/GroupDetailsMenuBlade/GroupMemberships/groupId/{{rawId}}";
    private static readonly _groupMembersUrlTemplate = "https://{{domainName}}/#blade/Microsoft_AAD_IAM/GroupDetailsMenuBlade/Members/groupId/{{rawId}}";
    private static readonly _groupUrlTemplate = "https://{{domainName}}/#blade/Microsoft_AAD_IAM/GroupDetailsMenuBlade/Overview/groupId/{{rawId}}";
    private static readonly _servicePrincipalPropertiesUrlTemplate = "https://{{domainName}}/#blade/Microsoft_AAD_IAM/ManagedAppMenuBlade/Properties/objectId/{{rawId}}/appId/{{applicationRawId}}";
    private static readonly _servicePrincipalUrlTemplate = "https://{{domainName}}/#blade/Microsoft_AAD_IAM/ManagedAppMenuBlade/Overview/objectId/{{rawId}}/appId/{{applicationRawId}}";
    private static readonly _tenantExternalCollaborationSettingsUrlTemplate = "https://{{domainName}}/#view/Microsoft_AAD_IAM/CompanyRelationshipsMenuBlade/~/Settings";
    private static readonly _tenantUrlTemplate = "https://{{domainName}}/#view/Microsoft_AAD_IAM/ActiveDirectoryMenuBlade";
    private static readonly _tenantUserSettingsUrlTemplate = "https://{{domainName}}/#view/Microsoft_AAD_UsersAndTenants/UserManagementMenuBlade/~/UserSettings";
    private static readonly _userGroupMembershipsUrlTemplate = "https://{{domainName}}/#blade/Microsoft_AAD_IAM/UserDetailsMenuBlade/Groups/userId/{{rawId}}";
    private static readonly _userRoleAssignmentUrlTemplate = "https://{{domainName}}/#blade/Microsoft_AAD_IAM/UserDetailsMenuBlade/SubscriptionResources/userId/{{rawId}}";
    private static readonly _userUrlTemplate = "https://{{domainName}}/#blade/Microsoft_AAD_IAM/UserDetailsMenuBlade/Profile/userId/{{rawId}}";

public static GetApplicationCredentialsUrl(

        applicationRawId: string,
        aadPartitionType: Contract.AadPartitionType) {
        return Mustache.render(
            AadConsoleUrlBuilder._applicationCredentialsUrlTemplate,
            {
                "applicationRawId": applicationRawId,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType)
            });
    }

public static GetApplicationUrl(

        applicationRawId: string,
        aadPartitionType: Contract.AadPartitionType) {
        return Mustache.render(
            AadConsoleUrlBuilder._applicationUrlTemplate,
            {
                "applicationRawId": applicationRawId,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType)
            });
    }

public static GetDirectoryRoleDefinitionUrl(

        aadPartitionType: Contract.AadPartitionType,
        name: string,
        rawId: string,
        type: string) {
        return Mustache.render(
            AadConsoleUrlBuilder._directoryRoleDefinitionUrlTemplate,
            {
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "name": name,
                "rawId": rawId,
                "type": type
            });
    }

public static GetDirectoryRoleManagementPolicyAssignmentUrl(

        aadPartitionType: Contract.AadPartitionType,
        policyRawId: string,
        roleName: string) {
        return Mustache.render(
            AadConsoleUrlBuilder._directoryRoleManagementPolicyAssignmentUrlTemplate,
            {
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "policyRawId": policyRawId,
                "roleName": roleName
            });
    }

public static GetGroupGroupMembershipsUrl(

        aadPartitionType: Contract.AadPartitionType,
        rawId: string) {
        return Mustache.render(
            AadConsoleUrlBuilder._groupGroupMembershipsUrlTemplate,
            {
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "rawId": rawId
            });
    }

public static GetGroupMembersUrl(

        aadPartitionType: Contract.AadPartitionType,
        rawId: string) {
        return Mustache.render(
            AadConsoleUrlBuilder._groupMembersUrlTemplate,
            {
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "rawId": rawId
            });
    }

public static GetGroupUrl(

        aadPartitionType: Contract.AadPartitionType,
        rawId: string) {
        return Mustache.render(
            AadConsoleUrlBuilder._groupUrlTemplate,
            {
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "rawId": rawId
            });
    }

public static GetServicePrincipalPropertiesUrl(

        applicationRawId: string,
        aadPartitionType: Contract.AadPartitionType,
        rawId: string) {
        return Mustache.render(
            AadConsoleUrlBuilder._servicePrincipalPropertiesUrlTemplate,
            {
                "applicationRawId": applicationRawId,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "rawId": rawId
            });
    }

public static GetServicePrincipalUrl(

        applicationRawId: string,
        aadPartitionType: Contract.AadPartitionType,
        rawId: string) {
        return Mustache.render(
            AadConsoleUrlBuilder._servicePrincipalUrlTemplate,
            {
                "applicationRawId": applicationRawId,
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "rawId": rawId
            });
    }

public static GetTenantExternalCollaborationSettingsUrl(aadPartitionType: Contract.AadPartitionType) {
        return Mustache.render(
            AadConsoleUrlBuilder._tenantExternalCollaborationSettingsUrlTemplate,
            {
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType)
            });
    }

public static GetTenantUrl(aadPartitionType: Contract.AadPartitionType) {
        return Mustache.render(
            AadConsoleUrlBuilder._tenantUrlTemplate,
            {
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType)
            });
    }

public static GetTenantUserSettingsUrl(aadPartitionType: Contract.AadPartitionType) {
        return Mustache.render(
            AadConsoleUrlBuilder._tenantUserSettingsUrlTemplate,
            {
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType)
            });
    }

public static GetUserGroupMembershipsUrl(

        aadPartitionType: Contract.AadPartitionType,
        rawId: string) {
        return Mustache.render(
            AadConsoleUrlBuilder._userGroupMembershipsUrlTemplate,
            {
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "rawId": rawId
            });
    }

public static GetUserRoleAssignmentUrl(

        aadPartitionType: Contract.AadPartitionType,
        rawId: string) {
        return Mustache.render(
            AadConsoleUrlBuilder._userRoleAssignmentUrlTemplate,
            {
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "rawId": rawId
            });
    }

public static GetUserUrl(

        aadPartitionType: Contract.AadPartitionType,
        rawId: string) {
        return Mustache.render(
            AadConsoleUrlBuilder._userUrlTemplate,
            {
                "domainName": AzureConsoleHelper.getDomainName(aadPartitionType),
                "rawId": rawId
            });
    }
}