import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function SlackIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M9.1732 2C8.0917 2.0008 7.21643 2.87671 7.21723 3.95604C7.21643 5.03538 8.0925 5.91129 9.17399 5.91209H11.1308V3.95684C11.1316 2.87751 10.2555 2.0016 9.1732 2C9.174 2 9.174 2 9.1732 2ZM9.1732 7.21718H3.95676C2.87527 7.21798 1.9992 8.09389 2 9.17322C1.9984 10.2526 2.87447 11.1285 3.95596 11.1301H9.1732C10.2547 11.1293 11.1308 10.2534 11.13 9.17402C11.1308 8.09389 10.2547 7.21798 9.1732 7.21718Z"
                fill="#36C5F0"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M22.0001 9.17322C22.0009 8.09389 21.1249 7.21798 20.0434 7.21718C18.9619 7.21798 18.0858 8.09389 18.0866 9.17322V11.1301H20.0434C21.1249 11.1293 22.0009 10.2534 22.0001 9.17322ZM16.7829 9.17322V3.95604C16.7837 2.87751 15.9084 2.0016 14.8269 2C13.7455 2.0008 12.8694 2.87671 12.8702 3.95604V9.17322C12.8686 10.2526 13.7447 11.1285 14.8261 11.1301C15.9076 11.1293 16.7837 10.2534 16.7829 9.17322Z"
                fill="#2EB67D"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M14.8264 22C15.9079 21.9992 16.784 21.1233 16.7832 20.044C16.784 18.9646 15.9079 18.0887 14.8264 18.0879H12.8697V20.044C12.8689 21.1225 13.7449 21.9984 14.8264 22ZM14.8264 16.782H20.0436C21.1251 16.7812 22.0012 15.9053 22.0004 14.826C22.002 13.7466 21.1259 12.8707 20.0444 12.8691H14.8272C13.7457 12.8699 12.8697 13.7458 12.8705 14.8252C12.8697 15.9053 13.7449 16.7812 14.8264 16.782Z"
                fill="#ECB22E"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M2.00106 14.8252C2.00026 15.9045 2.87633 16.7804 3.95782 16.7812C5.03932 16.7804 5.91538 15.9045 5.91458 14.8252V12.8691H3.95782C2.87633 12.8699 2.00026 13.7458 2.00106 14.8252ZM7.21829 14.8252V20.0424C7.21669 21.1217 8.09276 21.9976 9.17426 21.9992C10.2557 21.9984 11.1318 21.1225 11.131 20.0432V14.8268C11.1326 13.7474 10.2565 12.8715 9.17505 12.8699C8.09276 12.8699 7.21749 13.7458 7.21829 14.8252Z"
                fill="#E01E5A"
                fillRule="evenodd"/>
        </SvgIcon>);
}