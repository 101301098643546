import { IconNodeContent } from "@infrastructure";
import React from "react";
import { Contract, getEntityNodeContentSize } from "../../../../../../..";
import { AzurePermitterIcon, AzureResourcePermitterType, useAzurePermitterTranslator } from "../../../../../../../../tenants";

type ResourcePermitterNoteContentProps = {
    permitterType: Contract.AzurePermitterType;
    variant: "large" | "small";
};

export function ResourcePermitterNodeContent({ permitterType, variant }: ResourcePermitterNoteContentProps) {
    const permitterTranslator = useAzurePermitterTranslator();
    return (
        <IconNodeContent
            iconOptions={{
                icon: <AzurePermitterIcon permitterType={permitterType}/>,
                shape: "rectangle"
            }}
            size={getEntityNodeContentSize("bottom", variant)}
            titleElementOrOptions={{ title: permitterTranslator(new AzureResourcePermitterType(permitterType)) }}/>);
}