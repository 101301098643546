import { DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, EntitiesCell, EntityFilter, entityModelStore, ItemTable, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";

type MemberPrincipalsProps = {
    group: Contract.GciDirectoryGroup;
};

export function MemberPrincipals({ group }: MemberPrincipalsProps) {
    const items =
        useMemo(
            () =>
                _.flatMap(
                    group.memberPrincipalRoleToIdsMap,
                    (memberPrincipalIds, memberPrincipalRole) =>
                        _.map(
                            memberPrincipalIds,
                            memberPrincipalId =>
                                new MemberPrincipalsTableItem(
                                    memberPrincipalId,
                                    memberPrincipalRole as Contract.GciDirectoryGroupMemberPrincipalRole))),
            [group]);

    const memberPrincipalModels =
        entityModelStore.useGet(
            _.map(
                items,
                item => item.id));
    const memberPrincipalModelMap =
        useMemo(
            () =>
                _.keyBy(
                    memberPrincipalModels,
                    memberPrincipalModel => memberPrincipalModel.entity.id),
            [memberPrincipalModels]);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gci.useGciDirectoryGroupDefinition.memberPrincipals",
            () => ({
                columns: {
                    member: "Member",
                    role: {
                        title: "Role",
                        [Contract.TypeNames.GciDirectoryGroupMemberPrincipalRole]: {
                            [Contract.GciDirectoryGroupMemberPrincipalRole.Manager]: "Manager",
                            [Contract.GciDirectoryGroupMemberPrincipalRole.Member]: "Member",
                            [Contract.GciDirectoryGroupMemberPrincipalRole.Owner]: "Owner"
                        }
                    }
                },
                empty: "No Members",
                title: "Members"
            }));

    return (
        <ItemTable
            columnIdToGetItemValueMap={{
                [MemberPrincipalsColumnId.Member]: {
                    getFilterValue: item => item.id,
                    getSortValue: item => memberPrincipalModelMap[item.id].entity.displayName
                },
                [MemberPrincipalsColumnId.Role]: {
                    getFilterValue: item => item.role,
                    getSortValue: item => localization.columns.role[Contract.TypeNames.GciDirectoryGroupMemberPrincipalRole][item.role]()
                }
            }}
            csvExportFilePrefixes={[entityTypeNameTranslator(group.typeName, { includeServiceName: false }), group.displayName, localization.title()]}
            defaultSortColumnIdOrIds={MemberPrincipalsColumnId.Member}
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            getCsvItem={
                item => ({
                    "Member": memberPrincipalModelMap[item.id].entity.displayReference,
                    "Role": localization.columns.role[Contract.TypeNames.GciDirectoryGroupMemberPrincipalRole][item.role]()
                })}
            getItemId={item => item.id}
            items={items}>
            {columnIdToItemValuesMap => [
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EntityFilter
                                    entityIdsOrSearchableReferences={columnIdToItemValuesMap[MemberPrincipalsColumnId.Member]}
                                    placeholder={localization.columns.member()}/>
                        }
                    }}
                    id={MemberPrincipalsColumnId.Member}
                    key={MemberPrincipalsColumnId.Member}
                    render={
                        ({ item }: DataTableColumnRenderProps<MemberPrincipalsTableItem>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.id}
                                entityTypeName={Contract.TypeNames.GciDirectoryPrincipal}
                                entityVariant="iconTextTypeTenant"/>}
                    title={localization.columns.member()}/>,
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter placeholder={localization.columns.role.title()}>
                                    {_.map(
                                        columnIdToItemValuesMap[MemberPrincipalsColumnId.Role],
                                        role =>
                                            <ValuesFilterItem
                                                key={role}
                                                title={localization.columns.role[Contract.TypeNames.GciDirectoryGroupMemberPrincipalRole][role as Contract.GciDirectoryGroupMemberPrincipalRole]()}
                                                value={role}/>)}
                                </ValuesFilter>
                        }
                    }}
                    id={MemberPrincipalsColumnId.Role}
                    itemProperty={(item: MemberPrincipalsTableItem) => localization.columns.role[Contract.TypeNames.GciDirectoryGroupMemberPrincipalRole][item.role]()}
                    key={MemberPrincipalsColumnId.Role}
                    title={localization.columns.role.title()}/>
            ]}
        </ItemTable>);
}

enum MemberPrincipalsColumnId {
    Member = "member",
    Role = "role"
}

class MemberPrincipalsTableItem {
    constructor(
        public id: string,
        public role: Contract.GciDirectoryGroupMemberPrincipalRole) {
    }
}