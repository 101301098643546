import { useLocalization } from "@infrastructure";
import { Contract } from "..";

export function useRiskControllerUpdateRiskPolicyConfigurationErrorTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useRiskControllerUpdateRiskPolicyConfigurationErrorTranslator",
            () => ({
                [Contract.TypeNames.RiskControllerUpdateRiskPolicyConfigurationError]: {
                    [Contract.RiskControllerUpdateRiskPolicyConfigurationError.Conflict]: "This policy has been updated. Refresh the page to view the latest changes before making further edits."
                }
            }));

    return (error: Contract.RiskControllerUpdateRiskPolicyConfigurationError) => localization[Contract.TypeNames.RiskControllerUpdateRiskPolicyConfigurationError][error]();
}