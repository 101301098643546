﻿import { ActionMenuItem, DataTableColumnRenderProps, DeleteIcon, EditIcon, Menu, Message, useLocalization } from "@infrastructure";
import { CircularProgress, Stack } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useSetCodePipelineContext } from "../../..";
import { ConfigurationController, Contract, scopeSystemEntityModelStore, useTheme } from "../../../../../../../../../../../../../../../common";

export function ActionsCell({ item }: DataTableColumnRenderProps<Contract.CodePipelineModel>) {
    const setCodePipelineContext = useSetCodePipelineContext();

    const [deletePipelineActionExecuting, setDeletePipelineActionExecuting] = useState(false);
    const [deletePipelineActionError, setDeletePipelineActionError] = useState(false);

    async function deletePipeline() {
        setDeletePipelineActionExecuting(true);
        setDeletePipelineActionError(false);

        try {
            await ConfigurationController.deleteCodePipeline(new Contract.ConfigurationControllerDeleteCodePipelineRequest(item.configuration.id));
            await scopeSystemEntityModelStore.notify();
        } catch {
            setDeletePipelineActionError(true);
        }

        setDeletePipelineActionExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodePipelineItems.codePipeline.table.actionsCell",
            () => ({
                actions: {
                    delete: {
                        error: "Failed to delete",
                        prompt: "Warning: Deleting the integration {{pipelineName}} will remove any CI/CD scan results associated with the token. Are you sure you want to continue?",
                        title: "Delete"
                    },
                    edit: "Edit"
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            {deletePipelineActionExecuting && (
                <CircularProgress
                    size={theme.spacing(2)}
                    variant="indeterminate"/>)}
            {deletePipelineActionError && (
                <Message
                    level="error"
                    title={localization.actions.delete.error()}
                    variant="minimal"/>)}
            {!item.configuration.systemDeleted && (
                <Fragment>
                    <Menu
                        itemsOrGetItems={[
                            new ActionMenuItem(
                                () =>
                                    setCodePipelineContext(
                                        codePipelineContext => ({
                                            ...codePipelineContext,
                                            addOrEditOpen: item.configuration as Contract.CodePipelineConfiguration
                                        })),
                                localization.actions.edit(),
                                {
                                    disabled: deletePipelineActionExecuting,
                                    icon: <EditIcon/>
                                }),
                            new ActionMenuItem(
                                () => deletePipeline(),
                                localization.actions.delete.title(),
                                {
                                    confirmOptions: {
                                        message: localization.actions.delete.prompt({ pipelineName: (item.configuration as Contract.CodePipelineConfiguration).name })
                                    },
                                    disabled: deletePipelineActionExecuting,
                                    icon: <DeleteIcon/>
                                })
                        ]}/>
                </Fragment>)}
        </Stack>);
}