﻿import { CollapsedIcon, Optional, useActions, useLocalization } from "@infrastructure";
import { Check } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from "@mui/material";
import { TOptions } from "i18next";
import _ from "lodash";
import React, { Ref, useEffect, useState } from "react";
import { Contract, useTheme } from "../../../../..";
import { CustomRiskPolicyActions } from "../../../..";
import { DestinationNetworkScopes, InlineDestinationNetworkScopes, InlineSourceIpAddressSelection } from "../..";
import { SourceIpAddressSelector, SourceIpAddressSelectorAnyIpV4AddressSubnetSelection, SourceIpAddressSelectorExactSubnetSelection, SourceIpAddressSelectorInboundAccessScopeSelection, SourceIpAddressSelectorOverlapSubnetSelection, SourceIpAddressSelectorSelection } from "../../SourceIpAddressSelector";

type EditProps = {
    actionsRef: Ref<Optional<CustomRiskPolicyActions>>;
    onValidChange: (valid: boolean) => void;
    riskPolicyConfiguration?: Contract.GcpEntityInboundExternalPortRiskPolicyConfiguration;
    templateTranslator: (options?: TOptions) => string;
};

export function Edit({ actionsRef, onValidChange, riskPolicyConfiguration, templateTranslator }: EditProps) {
    const [destinationNetworkScopes, setDestinationNetworkScopes] = useState(riskPolicyConfiguration?.destinationNetworkScopes ?? []);
    const [destinationNetworkScopesValid, setDestinationNetworkScopesValid] = useState(false);

    const [sourceIpAddressesSelectorSelection, setSourceIpAddressesSelectorSelection] =
        useState<SourceIpAddressSelectorSelection | undefined>(
            !_.isNil(riskPolicyConfiguration?.inboundAccessScope)
                ? new SourceIpAddressSelectorInboundAccessScopeSelection(riskPolicyConfiguration!.inboundAccessScope)
                : riskPolicyConfiguration?.anyIpV4AddressSubnet === true
                    ? new SourceIpAddressSelectorAnyIpV4AddressSubnetSelection()
                    : !_.isNil(riskPolicyConfiguration?.exactSubnets)
                        ? SourceIpAddressSelectorExactSubnetSelection.fromSubnets(riskPolicyConfiguration!.exactSubnets)
                        : !_.isNil(riskPolicyConfiguration?.overlapSubnets)
                            ? SourceIpAddressSelectorOverlapSubnetSelection.fromSubnets(riskPolicyConfiguration!.overlapSubnets)
                            : undefined);
    const [sourceIpAddressesValid, setSourceIpAddressesValid] = useState(false);

    const [view, setView] = useState<EditView | undefined>(EditView.SourceIpAddresses);

    useActions(
        actionsRef,
        {
            createRequest:
                (description: Optional<string>, name: string, scopeId: string, severity: Contract.Severity) => {
                    const { anyIpV4AddressSubnet, exactSubnets, inboundAccessScope, overlapSubnets } = sourceIpAddressesSelectorSelection!.getInlineSelectionData();
                    return new Contract.RiskControllerInsertGcpEntityInboundExternalPortRiskPolicyRequest(
                        description,
                        name,
                        scopeId,
                        severity,
                        anyIpV4AddressSubnet ?? false,
                        destinationNetworkScopes,
                        exactSubnets,
                        inboundAccessScope,
                        overlapSubnets);
                }
        });

    useEffect(
        () => {
            const sourceIpAddressesValid = sourceIpAddressesSelectorSelection?.valid() ?? true;
            setSourceIpAddressesValid(sourceIpAddressesValid);
            onValidChange(destinationNetworkScopesValid && sourceIpAddressesValid);
        },
        [destinationNetworkScopesValid, sourceIpAddressesSelectorSelection]);

    const localization =
        useLocalization(
            "common.customRiskPolicy.gcpEntityInboundExternalPortRiskPolicy.edit",
            () => ({
                destinationNetworkScopes: "Ports",
                prompt: "Select the required source IPv4 addresses and ports",
                sourceIpAddresses: "Source IPv4 Addresses"
            }));
    const theme = useTheme();
    return (
        <Stack spacing={2}>
            <Typography variant="h5">
                {localization.prompt()}
            </Typography>
            <Stack spacing={1}>
                <Typography
                    sx={{
                        backgroundColor: theme.palette.background.alternate,
                        borderRadius: theme.spacing(0.75),
                        padding: theme.spacing(2)
                    }}>
                    {templateTranslator({
                        destinationNetworkScopes:
                            <InlineDestinationNetworkScopes destinationNetworkScopes={destinationNetworkScopes}/>,
                        sourceIpAddresses:
                            <InlineSourceIpAddressSelection selection={sourceIpAddressesSelectorSelection}/>
                    })}
                </Typography>
                <Box>
                    <Accordion
                        expanded={view === EditView.SourceIpAddresses}
                        onChange={
                            (_event, expanded) =>
                                setView(
                                    expanded
                                        ? EditView.SourceIpAddresses
                                        : undefined)}>
                        <AccordionSummary expandIcon={<CollapsedIcon/>}>
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={2}
                                sx={{ width: "100%" }}>
                                <Typography
                                    sx={{ flex: 1 }}
                                    variant="h5">
                                    {localization.sourceIpAddresses()}
                                </Typography>
                                {sourceIpAddressesValid &&
                                    <Check
                                        sx={{
                                            color: theme.palette.success.main,
                                            fontSize: "18px"
                                        }}/>}
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <SourceIpAddressSelector
                                selection={sourceIpAddressesSelectorSelection}
                                onSelectionChanged={setSourceIpAddressesSelectorSelection}/>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={view === EditView.DestinationNetworkScopes}
                        onChange={
                            (_event, expanded) =>
                                setView(
                                    expanded
                                        ? EditView.DestinationNetworkScopes
                                        : undefined)}>
                        <AccordionSummary expandIcon={<CollapsedIcon/>}>
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={2}
                                sx={{ width: "100%" }}>
                                <Typography
                                    sx={{ flex: 1 }}
                                    variant="h5">
                                    {localization.destinationNetworkScopes()}
                                </Typography>
                                {destinationNetworkScopesValid &&
                                    <Check
                                        sx={{
                                            color: theme.palette.success.main,
                                            fontSize: "18px"
                                        }}/>}
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <DestinationNetworkScopes
                                existingDestinationNetworkScopes={destinationNetworkScopes}
                                onDestinationNetworkScopesChanged={setDestinationNetworkScopes}
                                onValidChanged={setDestinationNetworkScopesValid}/>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Stack>
        </Stack>);
}

enum EditView {
    DestinationNetworkScopes = "destinationNetworkScopes",
    SourceIpAddresses = "sourceIpAddresses"
}