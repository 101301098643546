﻿import { useMemo } from "react";
import { RiskDefinitionContextItem } from "../../../utilities";
import { useRiskPolicyConfigurationInactivityTimeFrameStep } from "./useRiskPolicyConfigurationInactivityTimeFrameStep";

export function useGetRiskPolicyConfigurationInactivityTimeFrameContextItem() {
    return useMemo(
        () => useRiskPolicyConfigurationInactivityTimeFrameContextItem,
        []);
}

function useRiskPolicyConfigurationInactivityTimeFrameContextItem(inactivityTimeFrame: string) {
    return new RiskDefinitionContextItem(useRiskPolicyConfigurationInactivityTimeFrameStep(inactivityTimeFrame));
}