import { Steps, useLocalization, useSetOrderedWizardContext } from "@infrastructure";
import React, { useEffect } from "react";
import { InstructionsImageUrl } from "./images";

export function RoleItem() {
    const setOrderedWizardContext = useSetOrderedWizardContext();
    useEffect(
        () => {
            setOrderedWizardContext(
                orderedWizardContext => ({
                    ...orderedWizardContext,
                    sideElement: <img src={InstructionsImageUrl}/>
                }));
        },
        []);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useTicketingItems.serviceNow.addOrEdit.roleItem",
            () => ({
                steps: {
                    step1: "Click on the created user",
                    step2: "Select \"**Roles**\" tab and click \"**Edit**\"",
                    step3: "Select \"**itil**\" role, click \"**Add**\" and then \"**Save**\""
                }
            }));
    return (
        <Steps variant="letters">
            {localization.steps.step1()}
            {localization.steps.step2()}
            {localization.steps.step3()}
        </Steps>);
}