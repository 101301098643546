﻿import { Link, useChangeEffect, useLocalization } from "@infrastructure";
import { Stack } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Contract, RiskPoliciesType, TextInput } from "../../../../../../../common";
import { ConfigurationCategory, useConfigurationContext, useSetConfigurationContext } from "../../../components";
import { useGetGeneralInheritToggleInheritedValues } from "../../useGetGeneralInheritToggleInheritedValues";
import { ListParameter, Parameter, Parameters, RawResourceTypeNameMultiSelect } from "../components";
import { RiskPolicyConfigurationDefinitionData } from "../useConfigurationDefinition";

const MaxKeyValueLength = 256;

export function useKubernetesAdmissionControllerRequiredLabelsRiskPolicyConfigurationDefinition(): RiskPolicyConfigurationDefinitionData {
    const { item, riskPolicyConfiguration } = useConfigurationContext();
    const setConfigurationContext = useSetConfigurationContext();

    const {
        inherited,
        overridablePropertyNameToDataMap: {
            rawResourceTypeNames: rawResourceTypeNamesData,
            requiredLabelKeyToValueAllowedRegexMap: requiredLabelParameterValuesData
        }
    } = useGetGeneralInheritToggleInheritedValues(
        RiskPoliciesType.KubernetesAdmissionController,
        riskPolicyConfiguration,
        item,
        undefined,
        riskPolicyConfiguration => {
            const kubernetesAdmissionControllerRequiredLabelsRiskPolicyConfiguration = riskPolicyConfiguration as Contract.KubernetesAdmissionControllerRequiredLabelsRiskPolicyConfiguration;
            return ({
                rawResourceTypeNames: kubernetesAdmissionControllerRequiredLabelsRiskPolicyConfiguration.rawResourceTypeNames,
                requiredLabelKeyToValueAllowedRegexMap:
                    _.isEmpty(kubernetesAdmissionControllerRequiredLabelsRiskPolicyConfiguration.requiredLabelKeyToValueAllowedRegexMap)
                        ? undefined
                        : _.map(
                            kubernetesAdmissionControllerRequiredLabelsRiskPolicyConfiguration.requiredLabelKeyToValueAllowedRegexMap,
                            (requiredLabelValueAllowedRegex, requiredLabelKey) =>
                                new RequiredLabelParameterValue(
                                    requiredLabelKey,
                                    requiredLabelValueAllowedRegex))
            });
        });

    const [rawResourceTypeNames, setRawResourceTypeNames] = useState(rawResourceTypeNamesData.value);
    const [requiredLabelParameterValues, setRequiredLabelParameterValues] = useState(requiredLabelParameterValuesData.value);
    const [requiredLabelParameterValuesValid, setRequiredLabelParameterValuesValid] = useState(true);

    useEffect(
        () => {
            if (inherited) {
                setRawResourceTypeNames(undefined);
                setRequiredLabelParameterValues(undefined);
                setRequiredLabelParameterValuesValid(true);
            }
        },
        [inherited]);

    useChangeEffect(
        () =>
            setConfigurationContext(
                configurationContext => ({
                    ...configurationContext,
                    additionalTabIdToSectionMap: {
                        ...configurationContext.additionalTabIdToSectionMap,
                        [ConfigurationCategory.KubernetesAdmissionControllerParameters]: {
                            valid:
                                !configurationContext.riskPolicyConfiguration.enabled ||
                                !_.isEmpty(rawResourceTypeNames) &&
                                !_.isEmpty(requiredLabelParameterValues) &&
                                requiredLabelParameterValuesValid
                        }
                    },
                    dirty: true,
                    riskPolicyConfiguration: {
                        ...configurationContext.riskPolicyConfiguration,
                        rawResourceTypeNames,
                        requiredLabelKeyToValueAllowedRegexMap:
                            _.isNil(requiredLabelParameterValues)
                                ? undefined
                                : _(requiredLabelParameterValues).
                                    keyBy(({ key }) => key!).
                                    mapValues(({ value }) => value).
                                    value()
                    }
                })),
        [rawResourceTypeNames, requiredLabelParameterValues, requiredLabelParameterValuesValid, riskPolicyConfiguration.enabled]);

    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useConfigurationDefinition.hooks.useKubernetesAdmissionControllerRequiredLabelsRiskPolicyConfigurationDefinition",
            () => ({
                helpText: {
                    links: {
                        regexDocumentationLink: "the documentation"
                    },
                    text: "Supported pattern operators include: * ? |\nSee {{regexDocumentationLink}} for details."
                },
                rawResourceTypeNames: {
                    description: "Resource types:"
                },
                requiredLabelKeyToValueAllowedRegexMap: {
                    description: "Required labels:",
                    key: "Key",
                    value: "Value"
                }
            }));

    return useMemo(
        () => ({
            additionalTabs: [
                {
                    element:
                            <Parameters
                                helpText={
                                    localization.helpText.text({
                                        regexDocumentationLink:
                                            <Link
                                                urlOrGetUrl="https://github.com/google/re2/wiki/Syntax"
                                                variant="external">
                                                {localization.helpText.links.regexDocumentationLink()}
                                            </Link>
                                    })}
                                inherited={inherited}>
                                {disabled =>
                                    <Stack spacing={2}>
                                        <Parameter description={localization.rawResourceTypeNames.description()}>
                                            <RawResourceTypeNameMultiSelect
                                                readonly={disabled}
                                                selectedRawResourceTypeNames={
                                                    inherited
                                                        ? rawResourceTypeNamesData.inheritedValue
                                                        : rawResourceTypeNames ?? []}
                                                onSelectedRawResourceTypeNamesChanged={setRawResourceTypeNames}/>
                                        </Parameter>
                                        <ListParameter<RequiredLabelParameterValue>
                                            description={localization.requiredLabelKeyToValueAllowedRegexMap.description()}
                                            disabled={disabled}
                                            getValueKey={requiredLabelParameterValue => requiredLabelParameterValue.key!}
                                            inherited={inherited}
                                            inheritedValues={
                                                inherited
                                                    ? requiredLabelParameterValuesData.inheritedValue
                                                    : undefined}
                                            isParameterValueValid={
                                                requiredLabelParameterValue =>
                                                    !_.isEmpty(requiredLabelParameterValue?.key) ||
                                                    _.isEmpty(requiredLabelParameterValue?.value)}
                                            values={requiredLabelParameterValues}
                                            onValuesChanged={
                                                (requiredLabelParameterValues, requiredLabelParameterValuesValid) => {
                                                    setRequiredLabelParameterValues(
                                                        _.isEmpty(requiredLabelParameterValues)
                                                            ? undefined
                                                            : requiredLabelParameterValues);
                                                    setRequiredLabelParameterValuesValid(requiredLabelParameterValuesValid);
                                                }}>
                                            {(onValueChanged, readOnly, requiredLabelParameterValue) =>
                                                <Stack
                                                    direction="row"
                                                    spacing={1}
                                                    sx={{
                                                        alignItems: "center",
                                                        height: "100%",
                                                        width: "70%"
                                                    }}>
                                                    <TextInput
                                                        disabled={readOnly}
                                                        formSx={{ width: "100%" }}
                                                        placeholder={localization.requiredLabelKeyToValueAllowedRegexMap.key()}
                                                        readOnly={readOnly}
                                                        text={requiredLabelParameterValue?.key ?? ""}
                                                        onChange={
                                                            key =>
                                                                onValueChanged(
                                                                    new RequiredLabelParameterValue(
                                                                        key.substring(0, MaxKeyValueLength),
                                                                        requiredLabelParameterValue?.value ?? null
                                                                    ))}/>
                                                    <TextInput
                                                        disabled={readOnly}
                                                        formSx={{ width: "100%" }}
                                                        placeholder={localization.requiredLabelKeyToValueAllowedRegexMap.value()}
                                                        readOnly={readOnly}
                                                        text={requiredLabelParameterValue?.value ?? ""}
                                                        onChange={
                                                            value =>
                                                                onValueChanged(
                                                                    new RequiredLabelParameterValue(
                                                                        requiredLabelParameterValue?.key,
                                                                        value.substring(0, MaxKeyValueLength)
                                                                    ))}/>
                                                </Stack>}
                                        </ListParameter>
                                    </Stack>
                                }
                            </Parameters>,
                    id: ConfigurationCategory.KubernetesAdmissionControllerParameters
                }]
        }
        ),
        [inherited, rawResourceTypeNames, rawResourceTypeNamesData, requiredLabelParameterValuesData, requiredLabelParameterValuesData]);
}

export class RequiredLabelParameterValue {
    public key?: string;
    public value?: string | null;

    public constructor(
        key?: string,
        value?: string | null) {
        this.key = key;
        this.value = value;
    }
}