import * as d3 from "d3";

export class DirectedGraphZoom {
    private behavior: d3.ZoomBehavior<Element, any>;

    constructor(
        private svgElement: SVGSVGElement,
        graphElement: SVGGElement) {
        graphElement.classList.add("graph");
        this.behavior =
            d3.
                zoom().
                filter(
                    event =>
                        event.type !== "wheel" ||
                        event.ctrlKey).
                wheelDelta((event: WheelEvent) => -event.deltaY * 0.002).
                scaleExtent([0.1, 1000]);
        const graphElementSelection =
            d3.
                select(svgElement).
                call(
                    this.behavior.on(
                        "zoom",
                        event => graphElementSelection.attr("transform", event.transform)) as any).
                select(".graph") as d3.Selection<d3.BaseType, unknown, null, any>;
    }

    public fitToView =
        () => {
            d3.
                select(this.svgElement).
                transition().
                duration(750).
                call(
                    this.behavior.transform as any,
                    d3.
                        zoomIdentity.
                        scale(1).
                        translate(0, 0));
        };

    public zoomIn =
        () => {
            d3.
                select(this.svgElement).
                transition().
                duration(750).
                call(this.behavior.scaleBy as any, 1.5);
        };

    public zoomOut =
        () => {
            d3.
                select(this.svgElement).
                transition().
                duration(750).
                call(this.behavior.scaleBy as any, 0.67);
        };
}