import { useLocalization } from "@infrastructure";
import React from "react";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract, Entity } from "../../../../../../../../../common";

export function useDataAnalysisResourcePriorityScanRequestUpdateAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useDataAnalysisResourcePriorityScanRequestUpdateAuditEventDefinition",
            () => ({
                title: "Resource"
            }));

    const auditEvent = context.auditEventModel.auditEvent as Contract.DataAnalysisResourcePriorityScanRequestUpdateAuditEvent;
    return new AuditEventDefinition([
        new AuditEventDefinitionDetailItem(
            localization.title(),
            auditEvent.dataAnalysisResourceId,
            <Entity
                entityIdOrModel={auditEvent.dataAnalysisResourceId}
                variant="iconTextTypeTenant"/>)
    ] as AuditEventDefinitionDetailItem[]);
}