import { Link, ToggleList, ToggleListItem, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAddOrEditContext, useSetAddOrEditContext } from "../..";
import { Contract, CustomerConsoleAppUrlHelper, LicensingHelper, useTheme } from "../../../../../../../../../../../../../../../common";

export function PermissionsItem() {
    const { permissionTypes, sinkPubSubSubscriptionEnabled } = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.gcp.addOrEdit.permissionsItem",
            () => ({
                dataAnalysis: {
                    subtitle: "Scan and classify cloud resources. For more information about enabling data protection and required permissions, see the {{docsLink}}",
                    title: "Data Resources Scanning (Data Protection)"
                },
                docsLink: "documentation",
                permissionManagement: {
                    subtitle: "Grant users access to cloud accounts for a predetermined period of time and on an as-needed basis.",
                    title: "Just-In-Time Access (User Access Administrator)"
                },
                read: {
                    subtitle: "Gain full visibility for all cloud assets, including information about permissions, project usage, and security configurations.",
                    title: "Monitoring (read-only)"
                },
                sinkPubSubSubscription: {
                    subtitle: "View activity log data for your GCP resources in Tenable (recommended).",
                    title: "Audit logs"
                },
                title: {
                    docsLink: "documentation",
                    text: "Choose which features to enable, keeping in mind that each feature requires different permissions. You can modify your selection after the initial onboarding. For more information about required permissions, see the {{docsLink}}"
                },
                workloadAnalysis: {
                    subtitle: "Scan VM instances and container image registries for vulnerabilities and misconfigurations.",
                    title: "Workload protection"
                },
                write: {
                    subtitle: "Allow Tenable to make changes in your environment. This lets you automatically remediate findings with one click.",
                    title: "Remediation (read-write)"
                }
            }));

    const theme = useTheme();
    return (
        <ToggleList
            selectedValues={
                _<ToggleListValue>(permissionTypes).
                    concatIf(
                        sinkPubSubSubscriptionEnabled,
                        "sinkPubSubSubscription").
                    value()}
            sx={{ maxWidth: theme.spacing(80) }}
            title={
                localization.title.text({
                    docsLink:
                        <Link
                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsOnboardGcpRelativeUrl()}
                            variant="external">
                            {localization.title.docsLink()}
                        </Link>
                })}
            onSelectedValuesChanged={
                selectedValues => {
                    setAddOrEditContext(
                        addOrEditContext => ({
                            ...addOrEditContext,
                            permissionTypes: _.intersection(selectedValues, _.values(Contract.CloudProviderTenantPermissionType)) as Contract.CloudProviderTenantPermissionType[],
                            sinkPubSubSubscriptionEnabled: _.includes(selectedValues, "sinkPubSubSubscription")
                        }));
                }}>
            <ToggleListItem
                disabled={true}
                subtitle={localization.read.subtitle()}
                title={localization.read.title()}
                value={Contract.CloudProviderTenantPermissionType.Read}/>
            {LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm) &&
                <ToggleListItem
                    subtitle={
                        localization.dataAnalysis.subtitle({
                            docsLink:
                                <Link
                                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsOnboardGcpDataProtectionPolicyRelativeUrl()}
                                    variant="external">
                                    {localization.docsLink()}
                                </Link>
                        })}
                    title={localization.dataAnalysis.title()}
                    value={Contract.CloudProviderTenantPermissionType.DataAnalysis}/>}
            {LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp) &&
                <ToggleListItem
                    subtitle={localization.workloadAnalysis.subtitle()}
                    title={localization.workloadAnalysis.title()}
                    value={Contract.CloudProviderTenantPermissionType.WorkloadAnalysis}/>}
            <ToggleListItem
                subtitle={localization.write.subtitle()}
                title={localization.write.title()}
                value={Contract.CloudProviderTenantPermissionType.Write}/>
            {LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.PermissionManagement) &&
                <ToggleListItem
                    subtitle={localization.permissionManagement.subtitle()}
                    title={localization.permissionManagement.title()}
                    value={Contract.CloudProviderTenantPermissionType.PermissionManagement}/>}
            <ToggleListItem
                subtitle={localization.sinkPubSubSubscription.subtitle()}
                title={localization.sinkPubSubSubscription.title()}
                value="sinkPubSubSubscription"/>
        </ToggleList>);
}

type ToggleListValue = Contract.CloudProviderTenantPermissionType | "sinkPubSubSubscription";