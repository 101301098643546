﻿import { useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useCallback, useMemo, useRef } from "react";
import { Contract, CustomerConsoleAppUrlHelper, RiskPoliciesType, RiskPolicyTypeMetadataHelper, scopeNodeModelStore, useTheme } from "../../../../../../../../../common";
import { useGetInheritedOverridableValue } from "../../../../../hooks";
import { EnabledRadioGroupBase } from "../../../../EnabledRadioGroupBase";
import { useConfigurationContext, useSetConfigurationContext } from "../../../Configuration";

type EnabledRadioGroupProps = {
    riskPoliciesType: RiskPoliciesType;
};

export function EnabledRadioGroup({ riskPoliciesType }: EnabledRadioGroupProps) {
    const { item, riskPolicyConfiguration } = useConfigurationContext();
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const setConfigurationContext = useSetConfigurationContext();
    const getRiskPoliciesTypeEnabled =
        useCallback(
            (riskPolicyConfiguration: Contract.RiskPolicyConfiguration, riskPoliciesType: RiskPoliciesType) =>
                riskPoliciesType === RiskPoliciesType.Code
                    ? riskPolicyConfiguration.codeEnabled
                    : riskPolicyConfiguration.enabled,
            []);

    const { inherited, inheritedValue: inheritedEnabled, value: enabled } =
        useGetInheritedOverridableValue(
            riskPolicyConfiguration => getRiskPoliciesTypeEnabled(riskPolicyConfiguration, riskPoliciesType),
            riskPoliciesType,
            riskPolicyConfiguration,
            item);
    const enabledValue =
        inherited
            ? inheritedEnabled!
            : enabled!;
    const initialEnabledRef = useRef(enabledValue);

    const localization =
        useLocalization(
            "views.customer.riskPolicies.configuration.general.enabledRadioGroup",
            () => ({
                alert: "Disabling this policy will delete all associated findings. This action is irreversible.",
                description: "Choose whether to create findings when policy rules are violated.",
                title: "Status"
            }));

    const [awsDisabledPartitionType] =
        useMemo(
            () => {
                const riskPolicyTypeMetadata = RiskPolicyTypeMetadataHelper.get(riskPolicyConfiguration.typeName);
                const scopeNodeModel = scopeNodeMap[riskPolicyConfiguration.scopeId].scopeNodeModel;
                const awsDisabledPartitionType =
                    scopeNodeModel.type === Contract.ScopeType.CloudProviderTenant &&
                    scopeNodeModel.configuration.typeName === Contract.TypeNames.AwsTenantConfiguration &&
                    _.includes(
                        riskPolicyTypeMetadata.disabledAwsTenantPartitionTypes,
                        (scopeNodeModel.configuration as Contract.AwsTenantConfiguration).partitionType);

                return [awsDisabledPartitionType];
            },
            [riskPolicyConfiguration, scopeNodeMap]);

    const theme = useTheme();
    return (
        <EnabledRadioGroupBase
            description={localization.description()}
            disabled={awsDisabledPartitionType}
            documentationUrl={CustomerConsoleAppUrlHelper.getDocsViewAndManagePoliciesRelativeUrl()}
            getEnabled={riskPolicyConfiguration => getRiskPoliciesTypeEnabled(riskPolicyConfiguration, riskPoliciesType)}
            riskPoliciesType={riskPoliciesType}
            setEnabled={
                (riskPolicyConfiguration, enabled) =>
                    setConfigurationContext(
                        configurationContext => ({
                            ...configurationContext,
                            dirty: true,
                            riskPolicyConfiguration: {
                                ...riskPolicyConfiguration,
                                ...(riskPoliciesType === RiskPoliciesType.Code
                                    ? { codeEnabled: enabled }
                                    : { enabled })
                            }
                        }))}
            sx={{ marginBottom: 0 }}
            title={localization.title()}>
            {initialEnabledRef.current &&
                !enabledValue &&
                riskPoliciesType !== RiskPoliciesType.KubernetesAdmissionController &&
                <Typography sx={{ color: theme.palette.warning.main }}>
                    {localization.alert()}
                </Typography>}
        </EnabledRadioGroupBase>);
}