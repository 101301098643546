﻿import { CollapsedIcon, Link, TextViewer } from "@infrastructure";
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from "@mui/material";
import React from "react";
import { Contract, tenantModelStore, useTheme } from "../../..";
import { CodeConsoleUrlHelper } from "../../../../tenants";
import { useDefinition } from "./hooks";

type ItemProp = {
    blockData: Contract.CodeResourceDataBlockData;
    commitHash: string;
    tenantId: string;
};

export function Item({ blockData, commitHash, tenantId }: ItemProp) {
    const tenantConfiguration = tenantModelStore.useGet(tenantId).configuration as Contract.CodeTenantConfiguration;
    const definition = useDefinition(blockData.block);

    const theme = useTheme();
    return (
        <Accordion>
            <AccordionSummary expandIcon={<CollapsedIcon/>}>
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={2}
                    sx={{ width: "100%" }}>
                    <Box
                        sx={{
                            flex: 1,
                            overflow: "hidden"
                        }}>
                        {definition.title}
                    </Box>
                    <Typography
                        noWrap={true}
                        sx={{
                            flex: 1,
                            textAlign: "start"
                        }}>
                        <Link
                            sx={{
                                direction: "ltr",
                                "unicode-bidi": "embed"
                            }}
                            urlOrGetUrl={
                                CodeConsoleUrlHelper.getCodeFileUrl(
                                    Contract.CodeType.TerraformPlan,
                                    commitHash,
                                    blockData.fileRelativePath,
                                    tenantConfiguration.codeTenantType,
                                    tenantConfiguration.url)}
                            variant="external">
                            {blockData.fileRelativePath}
                        </Link>
                    </Typography>
                </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: theme.spacing(2) }}>
                <TextViewer
                    format="hcl"
                    highlightedLines={blockData.snippet.highlightedLines}
                    startLine={blockData.snippet.startLine}
                    text={blockData.snippet.text}/>
            </AccordionDetails>
        </Accordion>);
}