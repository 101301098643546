﻿import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsEcsTaskDefinitionRiskContext() {
    return useMemo(
        () => useAwsEcsTaskDefinitionRiskContext,
        []);
}

function useAwsEcsTaskDefinitionRiskContext(taskDefinitionModel: Contract.AwsEcsTaskDefinitionModel) {
    return useGetAwsResourceRiskContext()(taskDefinitionModel);
}