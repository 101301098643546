import { useLocalization, useSetRoute, WelcomeView } from "@infrastructure";
import { Button, Stack } from "@mui/material";
import React from "react";
import { Contract, CustomerConsoleAppUrlHelper, TenantIcon, useTheme } from "../../../../../../../../../common";

export function Welcome() {
    const setRoute = useSetRoute();

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useOciDefinition.welcome",
            () => ({
                button: "Add Tenancy",
                title: "Connect your OCI Environment"
            }));

    const theme = useTheme();
    return (
        <WelcomeView title={localization.title()}>
            <Stack
                spacing={4}
                sx={{ width: "100%" }}>
                <Button
                    startIcon={<TenantIcon tenantType={Contract.TenantType.Oci}/>}
                    sx={{ padding: theme.spacing(1, 3) }}
                    variant="outlined"
                    onClick={() => setRoute(CustomerConsoleAppUrlHelper.getConfigurationIntegrationsCloudProviderTenantOrganizationsOciRelativeUrl(true))}>
                    {localization.button()}
                </Button>
            </Stack>
        </WelcomeView>);
}