import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function PermissionsIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_57243_2450)">
                <path d="M5.23 23.9999H2.01C1.47 23.9999 0.97 23.7899 0.59 23.4099C0.21 23.0299 0 22.5299 0 21.9999V18.7699C0 18.4899 0.05 18.2099 0.16 17.9499C0.27 17.6899 0.43 17.4499 0.63 17.2499L6.92 10.9499C6.47 9.26991 6.53 7.46991 7.11 5.81991C7.76 3.96991 9.03 2.36991 10.69 1.32991C12.35 0.289907 14.33 -0.170093 16.29 0.0499071C18.24 0.269907 20.07 1.14991 21.46 2.53991C22.85 3.92991 23.73 5.75991 23.95 7.70991C23.9918 8.08637 24.0094 8.46338 24.0028 8.83866C23.9934 9.38371 23.117 9.74392 22.6138 9.53411C22.1078 9.3231 22.0281 8.97595 22.0073 8.42808C22.001 8.26221 21.9886 8.09606 21.97 7.92991C21.8 6.42991 21.12 5.01991 20.05 3.94991C18.98 2.87991 17.57 2.19991 16.07 2.02991C14.57 1.85991 13.04 2.20991 11.76 3.00991C10.48 3.80991 9.51 5.03991 9.01 6.46991C8.51 7.89991 8.51 9.45991 9.01 10.8899L9.22 11.4799L2.01 18.7599V21.9799H5.24C5.24 21.9799 5.32 21.9799 5.35 21.9399L6.16 21.1299C6.16 21.1299 6.22 21.0299 6.22 20.9699V18.7499H8.25C8.36 18.7499 8.46 18.7099 8.54 18.6299C8.62 18.5499 8.66 18.4499 8.66 18.3399V16.3099H12.72C13.27 16.3099 13.72 16.7599 13.72 17.3099C13.72 17.8599 13.27 18.3099 12.72 18.3099L10.66 18.3399C10.66 18.9799 10.41 19.5899 9.96 20.0399C9.51 20.4899 8.89 20.7499 8.26 20.7499H8.23V20.9699C8.23 21.5599 8 22.1099 7.59 22.5299L6.77 23.3499C6.36 23.7499 5.82 23.9699 5.25 23.9699L5.23 23.9999Z"/>
                <path d="M17.6733 20.0419C17.0518 20.0419 16.5277 19.5362 16.5877 18.9176C16.6143 18.6431 16.6586 18.3712 16.732 18.1061C16.8547 17.6773 17.0329 17.331 17.2665 17.0671C17.5001 16.8032 17.7812 16.5641 18.1099 16.3497C18.3554 16.1847 18.5751 16.0136 18.7691 15.8363C18.9631 15.6591 19.1175 15.4632 19.2324 15.2488C19.3472 15.0303 19.4046 14.787 19.4046 14.519C19.4046 14.2345 19.3393 13.9851 19.2086 13.7707C19.078 13.5563 18.9018 13.3914 18.68 13.2759C18.4623 13.1605 18.2207 13.1028 17.9555 13.1028C17.6981 13.1028 17.4546 13.1625 17.2249 13.2821C16.9953 13.3976 16.8072 13.5707 16.6607 13.8016C16.3911 14.219 16.0293 14.6489 15.5324 14.6489H15.2474C14.5634 14.6489 13.984 14.0728 14.1871 13.4196C14.2803 13.1198 14.408 12.8493 14.5702 12.608C14.9305 12.0679 15.4076 11.6659 16.0015 11.402C16.5954 11.134 17.2507 11 17.9673 11C18.7553 11 19.4521 11.1361 20.0579 11.4082C20.6637 11.6762 21.1388 12.0658 21.4833 12.5771C21.8278 13.0883 22 13.7047 22 14.4263C22 14.9087 21.9228 15.3375 21.7684 15.7127C21.6179 16.0837 21.4061 16.4136 21.1329 16.7022C20.8597 16.9867 20.537 17.2444 20.1648 17.4753C19.852 17.669 19.5947 17.8711 19.3927 18.0814C19.1948 18.2916 19.0463 18.5349 18.9473 18.8111C18.9342 18.8491 18.9223 18.8874 18.9114 18.9258C18.742 19.5238 18.2949 20.0419 17.6733 20.0419ZM17.7238 24C17.3279 24 16.9894 23.8557 16.7082 23.5671C16.4311 23.2743 16.2945 22.9239 16.2984 22.5157C16.2945 22.1116 16.4311 21.7653 16.7082 21.4767C16.9894 21.1881 17.3279 21.0438 17.7238 21.0438C18.1 21.0438 18.4306 21.1881 18.7157 21.4767C19.0007 21.7653 19.1453 22.1116 19.1492 22.5157C19.1453 22.7878 19.076 23.0373 18.9414 23.264C18.8107 23.4867 18.6385 23.666 18.4246 23.8021C18.2108 23.934 17.9772 24 17.7238 24Z"/>
                <path d="M16.2499 10.5599C15.6899 10.5599 15.1499 10.3999 14.6899 10.0899C14.2299 9.77988 13.8699 9.33988 13.6599 8.82988C13.4499 8.31988 13.3899 7.74988 13.4999 7.20988C13.6099 6.66988 13.8799 6.16988 14.2699 5.76988C14.6599 5.37988 15.1599 5.10988 15.7099 4.99988C16.2499 4.88988 16.8199 4.94988 17.3299 5.15988C17.8399 5.36988 18.2799 5.72988 18.5899 6.18988C18.8999 6.64988 19.0599 7.18988 19.0599 7.74988C19.0599 8.48988 18.7599 9.20988 18.2399 9.73988C17.7099 10.2699 16.9999 10.5599 16.2499 10.5599ZM16.2499 6.48988C15.9999 6.48988 15.7499 6.55988 15.5399 6.70988C15.3299 6.84988 15.1699 7.04988 15.0699 7.27988C14.9699 7.50988 14.9499 7.76988 14.9999 8.01988C15.0499 8.26988 15.1699 8.48988 15.3499 8.66988C15.5299 8.84988 15.7599 8.96988 15.9999 9.01988C16.2499 9.06988 16.4999 9.03988 16.7399 8.94988C16.9699 8.84988 17.1699 8.68988 17.3099 8.47988C17.4499 8.26988 17.5299 8.01988 17.5299 7.76988C17.5299 7.59988 17.4999 7.43988 17.4299 7.27988C17.3699 7.11988 17.2699 6.97988 17.1499 6.86988C17.0299 6.74988 16.8899 6.65988 16.7399 6.58988C16.5899 6.52988 16.4199 6.48988 16.2499 6.48988Z"/>
            </g>
            <defs>
                <clipPath id="clip0_57243_2450">
                    <rect
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}