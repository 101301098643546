import { Optional, useLocalization } from "@infrastructure";
import { Contract, entityModelStore, useEntityTypeNameTranslator } from "../../../../../../../../../../../../../common";
import { useGetVirtualMachineTerminationResolutionStep } from "./useGetVirtualMachineTerminationResolutionStep";
import { useGetWorkloadResourceOperatingSystemReleaseHistory } from "./useGetWorkloadResourceOperatingSystemReleaseHistory";
import { useWorkloadResourceOperatingSystemRiskRemediationStep } from "./useWorkloadResourceOperatingSystemRiskRemediationStep";

type VirtualMachineOperatingSystemRisk = Contract.VirtualMachineWorkloadAnalysisRisk & Contract.IWorkloadResourceOperatingSystemRisk;

export function useGetVirtualMachineOperatingSystemResolutionSteps(
    risk: VirtualMachineOperatingSystemRisk,
    operatingSystemReleaseNotesUrl: Optional<string>,
    getRiskResolutionPatches?: () => Optional<string[]>) {
    const workloadResourceOperatingSystemRiskRemediationStep = useWorkloadResourceOperatingSystemRiskRemediationStep();
    const getVirtualMachineTerminationResolutionStep = useGetVirtualMachineTerminationResolutionStep(risk);
    const getWorkloadResourceOperatingSystemReleaseHistory = useGetWorkloadResourceOperatingSystemReleaseHistory();
    const entityTypenameTranslator = useEntityTypeNameTranslator();
    const riskedEntityModel = entityModelStore.useGet(risk.entityId)!;
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.workloadAnalysis.hooks.useWorkloadAnalysisDefinition.hooks.useGetVirtualMachineOperatingSystemResolutionSteps",
            () => ({
                create: {
                    withoutUrl: "Create a new image in which the operating system is based on the latest version.",
                    withUrl: "Create a new image in which the operating system is based on the latest version. {{urlPart}}"
                },
                update: "Update this {{riskedEntityTypeName}} to use the new image"
            }));

    if (!risk.riskedVirtualMachineImage) {
        return () => [
            workloadResourceOperatingSystemRiskRemediationStep(
                operatingSystemReleaseNotesUrl,
                risk.operatingSystemType,
                risk.windowsUpdateDocumentationUrl,
                getRiskResolutionPatches && getRiskResolutionPatches())
        ];
    }

    return () =>
        [
            getVirtualMachineTerminationResolutionStep(),
            operatingSystemReleaseNotesUrl
                ? localization.create.withUrl({ urlPart: getWorkloadResourceOperatingSystemReleaseHistory(operatingSystemReleaseNotesUrl) })
                : localization.create.withoutUrl(),
            localization.update({
                riskedEntityTypeName: entityTypenameTranslator(riskedEntityModel.entity.typeName, {
                    variant: "text"
                })
            })
        ];
}