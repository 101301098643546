import { useLocalization } from "@infrastructure";
import { Contract } from "../../..";

export function useGcpAssetGenericResourceTypeNameLocalization() {
    return useLocalization(
        "common.hooks.useEntityTypeNameTranslator.hooks.useGcpAssetGenericResourceTypeNameLocalization",
        () => ({
                typeNames: {
                    [Contract.TypeNames.GcpAccessApprovalSettingsGenericResource]: {
                        text: {
                            withServiceName: [
                                "access approval accessapprovalsettings",
                                "access approval accessapprovalsettings"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Access Approval AccessApprovalSettings",
                                "Access Approval AccessApprovalSettings"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAccessContextManagerAccessLevelsGenericResource]: {
                        text: {
                            withServiceName: [
                                "access context manager accesslevel",
                                "access context manager accesslevels"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Access Context Manager AccessLevel",
                                "Access Context Manager AccessLevels"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAccessContextManagerAccessPoliciesGenericResource]: {
                        text: {
                            withServiceName: [
                                "access context manager accesspolicy",
                                "access context manager accesspolicies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Access Context Manager AccessPolicy",
                                "Access Context Manager AccessPolicies"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAccessContextManagerAuthorizedOrgsDescsGenericResource]: {
                        text: {
                            withServiceName: [
                                "access context manager authorizedorgsdesc",
                                "access context manager authorizedorgsdescs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Access Context Manager AuthorizedOrgsDesc",
                                "Access Context Manager AuthorizedOrgsDescs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAccessContextManagerServicePerimetersGenericResource]: {
                        text: {
                            withServiceName: [
                                "access context manager serviceperimeter",
                                "access context manager serviceperimeters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Access Context Manager ServicePerimeter",
                                "Access Context Manager ServicePerimeters"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAiPlatformBatchPredictionJobsGenericResource]: {
                        text: {
                            withServiceName: [
                                "vertex ai batchpredictionjob",
                                "vertex ai batchpredictionjobs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vertex AI BatchPredictionJob",
                                "Vertex AI BatchPredictionJobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAiPlatformCustomJobsGenericResource]: {
                        text: {
                            withServiceName: [
                                "vertex ai customjob",
                                "vertex ai customjobs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vertex AI CustomJob",
                                "Vertex AI CustomJobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAiPlatformDataLabelingJobsGenericResource]: {
                        text: {
                            withServiceName: [
                                "vertex ai datalabelingjob",
                                "vertex ai datalabelingjobs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vertex AI DataLabelingJob",
                                "Vertex AI DataLabelingJobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAiPlatformDatasetsGenericResource]: {
                        text: {
                            withServiceName: [
                                "vertex ai dataset",
                                "vertex ai datasets"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vertex AI Dataset",
                                "Vertex AI Datasets"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAiPlatformEndpointsGenericResource]: {
                        text: {
                            withServiceName: [
                                "vertex ai endpoint",
                                "vertex ai endpoints"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vertex AI Endpoint",
                                "Vertex AI Endpoints"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAiPlatformFeatureGroupsGenericResource]: {
                        text: {
                            withServiceName: [
                                "vertex ai featuregroup",
                                "vertex ai featuregroups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vertex AI FeatureGroup",
                                "Vertex AI FeatureGroups"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAiPlatformFeatureOnlineStoresGenericResource]: {
                        text: {
                            withServiceName: [
                                "vertex ai featureonlinestore",
                                "vertex ai featureonlinestores"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vertex AI FeatureOnlineStore",
                                "Vertex AI FeatureOnlineStores"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAiPlatformFeaturestoresGenericResource]: {
                        text: {
                            withServiceName: [
                                "vertex ai featurestore",
                                "vertex ai featurestores"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vertex AI Featurestore",
                                "Vertex AI Featurestores"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAiPlatformHyperparameterTuningJobsGenericResource]: {
                        text: {
                            withServiceName: [
                                "vertex ai hyperparametertuningjob",
                                "vertex ai hyperparametertuningjobs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vertex AI HyperparameterTuningJob",
                                "Vertex AI HyperparameterTuningJobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAiPlatformIndexEndpointsGenericResource]: {
                        text: {
                            withServiceName: [
                                "vertex ai indexendpoint",
                                "vertex ai indexendpoints"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vertex AI IndexEndpoint",
                                "Vertex AI IndexEndpoints"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAiPlatformIndicesGenericResource]: {
                        text: {
                            withServiceName: [
                                "vertex ai index",
                                "vertex ai indices"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vertex AI Index",
                                "Vertex AI Indices"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAiPlatformMetadataStoresGenericResource]: {
                        text: {
                            withServiceName: [
                                "vertex ai metadatastore",
                                "vertex ai metadatastores"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vertex AI MetadataStore",
                                "Vertex AI MetadataStores"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAiPlatformModelDeploymentMonitoringJobsGenericResource]: {
                        text: {
                            withServiceName: [
                                "vertex ai modeldeploymentmonitoringjob",
                                "vertex ai modeldeploymentmonitoringjobs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vertex AI ModelDeploymentMonitoringJob",
                                "Vertex AI ModelDeploymentMonitoringJobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAiPlatformModelsGenericResource]: {
                        text: {
                            withServiceName: [
                                "vertex ai model",
                                "vertex ai models"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vertex AI Model",
                                "Vertex AI Models"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAiPlatformNasJobsGenericResource]: {
                        text: {
                            withServiceName: [
                                "vertex ai nasjob",
                                "vertex ai nasjobs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vertex AI NasJob",
                                "Vertex AI NasJobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAiPlatformNotebookExecutionJobsGenericResource]: {
                        text: {
                            withServiceName: [
                                "vertex ai notebookexecutionjob",
                                "vertex ai notebookexecutionjobs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vertex AI NotebookExecutionJob",
                                "Vertex AI NotebookExecutionJobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAiPlatformNotebookRuntimeTemplatesGenericResource]: {
                        text: {
                            withServiceName: [
                                "vertex ai notebookruntimetemplate",
                                "vertex ai notebookruntimetemplates"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vertex AI NotebookRuntimeTemplate",
                                "Vertex AI NotebookRuntimeTemplates"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAiPlatformNotebookRuntimesGenericResource]: {
                        text: {
                            withServiceName: [
                                "vertex ai notebookruntime",
                                "vertex ai notebookruntimes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vertex AI NotebookRuntime",
                                "Vertex AI NotebookRuntimes"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAiPlatformPipelineJobsGenericResource]: {
                        text: {
                            withServiceName: [
                                "vertex ai pipelinejob",
                                "vertex ai pipelinejobs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vertex AI PipelineJob",
                                "Vertex AI PipelineJobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAiPlatformSpecialistPoolsGenericResource]: {
                        text: {
                            withServiceName: [
                                "vertex ai specialistpool",
                                "vertex ai specialistpools"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vertex AI SpecialistPool",
                                "Vertex AI SpecialistPools"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAiPlatformTensorboardsGenericResource]: {
                        text: {
                            withServiceName: [
                                "vertex ai tensorboard",
                                "vertex ai tensorboards"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vertex AI Tensorboard",
                                "Vertex AI Tensorboards"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAiPlatformTrainingPipelinesGenericResource]: {
                        text: {
                            withServiceName: [
                                "vertex ai trainingpipeline",
                                "vertex ai trainingpipelines"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vertex AI TrainingPipeline",
                                "Vertex AI TrainingPipelines"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAiPlatformTuningJobsGenericResource]: {
                        text: {
                            withServiceName: [
                                "vertex ai tuningjob",
                                "vertex ai tuningjobs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vertex AI TuningJob",
                                "Vertex AI TuningJobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAlloydbBackupsGenericResource]: {
                        text: {
                            withServiceName: [
                                "alloydb backup",
                                "alloydb backups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Alloydb Backup",
                                "Alloydb Backups"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAlloydbClustersGenericResource]: {
                        text: {
                            withServiceName: [
                                "alloydb cluster",
                                "alloydb clusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Alloydb Cluster",
                                "Alloydb Clusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAlloydbInstancesGenericResource]: {
                        text: {
                            withServiceName: [
                                "alloydb instance",
                                "alloydb instances"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Alloydb Instance",
                                "Alloydb Instances"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpApiGatewayApiconfigsGenericResource]: {
                        text: {
                            withServiceName: [
                                "api gateway apiconfig",
                                "api gateway apiconfigs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Api Gateway ApiConfig",
                                "Api Gateway ApiConfigs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpApiGatewayApisGenericResource]: {
                        text: {
                            withServiceName: [
                                "api gateway api",
                                "api gateway apis"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Api Gateway Api",
                                "Api Gateway Apis"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpApiGatewayGatewaysGenericResource]: {
                        text: {
                            withServiceName: [
                                "api gateway",
                                "api gateways"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "API Gateway",
                                "API Gateways"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpApigeeInstancesGenericResource]: {
                        text: {
                            withServiceName: [
                                "apigee instance",
                                "apigee instances"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Apigee Instance",
                                "Apigee Instances"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpApigeeOrganizationsGenericResource]: {
                        text: {
                            withServiceName: [
                                "apigee organization",
                                "apigee organizations"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Apigee Organization",
                                "Apigee Organizations"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpApihubApiHubInstancesGenericResource]: {
                        text: {
                            withServiceName: [
                                "apihub apihubinstance",
                                "apihub apihubinstances"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Apihub ApiHubInstance",
                                "Apihub ApiHubInstances"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpApikeysKeysGenericResource]: {
                        text: {
                            withServiceName: [
                                "apikeys key",
                                "apikeys keys"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Apikeys Key",
                                "Apikeys Keys"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAppEngineApplicationsGenericResource]: {
                        text: {
                            withServiceName: [
                                "app engine application",
                                "app engine applications"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "App Engine Application",
                                "App Engine Applications"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAppEngineServicesGenericResource]: {
                        text: {
                            withServiceName: [
                                "app engine service",
                                "app engine services"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "App Engine Service",
                                "App Engine Services"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAppEngineVersionsGenericResource]: {
                        text: {
                            withServiceName: [
                                "app engine version",
                                "app engine versions"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "App Engine Version",
                                "App Engine Versions"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpApphubApplicationsGenericResource]: {
                        text: {
                            withServiceName: [
                                "apphub application",
                                "apphub applications"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Apphub Application",
                                "Apphub Applications"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpApphubServiceProjectAttachmentsGenericResource]: {
                        text: {
                            withServiceName: [
                                "apphub serviceprojectattachment",
                                "apphub serviceprojectattachments"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Apphub ServiceProjectAttachment",
                                "Apphub ServiceProjectAttachments"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpApphubServicesGenericResource]: {
                        text: {
                            withServiceName: [
                                "apphub service",
                                "apphub services"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Apphub Service",
                                "Apphub Services"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpApphubWorkloadGenericResource]: {
                        text: {
                            withServiceName: [
                                "apphub workload",
                                "apphub workload"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Apphub Workload",
                                "Apphub Workload"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpArtifactDockerimagesGenericResource]: {
                        text: {
                            withServiceName: [
                                "artifact registry dockerimage",
                                "artifact registry dockerimages"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Artifact Registry DockerImage",
                                "Artifact Registry DockerImages"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpArtifactMavenartifactsGenericResource]: {
                        text: {
                            withServiceName: [
                                "artifact registry mavenartifact",
                                "artifact registry mavenartifacts"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Artifact Registry MavenArtifact",
                                "Artifact Registry MavenArtifacts"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpArtifactNpmpackagesGenericResource]: {
                        text: {
                            withServiceName: [
                                "artifact registry npmpackage",
                                "artifact registry npmpackages"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Artifact Registry NpmPackage",
                                "Artifact Registry NpmPackages"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpArtifactPythonpackagesGenericResource]: {
                        text: {
                            withServiceName: [
                                "artifact registry pythonpackage",
                                "artifact registry pythonpackages"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Artifact Registry PythonPackage",
                                "Artifact Registry PythonPackages"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpArtifactRepositoriesGenericResource]: {
                        text: {
                            withServiceName: [
                                "artifact registry repository",
                                "artifact registry repositories"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Artifact Registry Repository",
                                "Artifact Registry Repositories"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpArtifactRulesGenericResource]: {
                        text: {
                            withServiceName: [
                                "artifact registry rule",
                                "artifact registry rules"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Artifact Registry Rule",
                                "Artifact Registry Rules"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpAssuredWorkloadsWorkloadGenericResource]: {
                        text: {
                            withServiceName: [
                                "assured workloads workload",
                                "assured workloads workload"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Assured Workloads Workload",
                                "Assured Workloads Workload"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpBackupdrBackupPlanAssociationsGenericResource]: {
                        text: {
                            withServiceName: [
                                "backupdr backupplanassociation",
                                "backupdr backupplanassociations"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Backupdr BackupPlanAssociation",
                                "Backupdr BackupPlanAssociations"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpBackupdrBackupPlansGenericResource]: {
                        text: {
                            withServiceName: [
                                "backupdr backupplan",
                                "backupdr backupplans"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Backupdr BackupPlan",
                                "Backupdr BackupPlans"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpBackupdrManagementServersGenericResource]: {
                        text: {
                            withServiceName: [
                                "backupdr managementserver",
                                "backupdr managementservers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Backupdr ManagementServer",
                                "Backupdr ManagementServers"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpBatchJobsGenericResource]: {
                        text: {
                            withServiceName: [
                                "batch job",
                                "batch jobs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Batch Job",
                                "Batch Jobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpBeyondcorpAppConnectionsGenericResource]: {
                        text: {
                            withServiceName: [
                                "beyondcorp appconnection",
                                "beyondcorp appconnections"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Beyondcorp AppConnection",
                                "Beyondcorp AppConnections"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpBeyondcorpAppConnectorsGenericResource]: {
                        text: {
                            withServiceName: [
                                "beyondcorp appconnector",
                                "beyondcorp appconnectors"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Beyondcorp AppConnector",
                                "Beyondcorp AppConnectors"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpBeyondcorpAppGatewaysGenericResource]: {
                        text: {
                            withServiceName: [
                                "beyondcorp appgateway",
                                "beyondcorp appgateways"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Beyondcorp AppGateway",
                                "Beyondcorp AppGateways"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpBeyondcorpClientConnectorServicesGenericResource]: {
                        text: {
                            withServiceName: [
                                "beyondcorp clientconnectorservice",
                                "beyondcorp clientconnectorservices"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Beyondcorp ClientConnectorService",
                                "Beyondcorp ClientConnectorServices"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpBeyondcorpClientGatewaysGenericResource]: {
                        text: {
                            withServiceName: [
                                "beyondcorp clientgateway",
                                "beyondcorp clientgateways"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Beyondcorp ClientGateway",
                                "Beyondcorp ClientGateways"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpBigQueryDatasetsGenericResource]: {
                        text: {
                            withServiceName: [
                                "big query dataset",
                                "big query datasets"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Big Query Dataset",
                                "Big Query Datasets"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpBigQueryModelsGenericResource]: {
                        text: {
                            withServiceName: [
                                "bigquery model",
                                "bigquery models"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "BigQuery Model",
                                "BigQuery Models"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpBigQueryTablesGenericResource]: {
                        text: {
                            withServiceName: [
                                "big query table",
                                "big query tables"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Big Query Table",
                                "Big Query Tables"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpBigquerymigrationMigrationWorkflowsGenericResource]: {
                        text: {
                            withServiceName: [
                                "bigquerymigration migrationworkflow",
                                "bigquerymigration migrationworkflows"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Bigquerymigration MigrationWorkflow",
                                "Bigquerymigration MigrationWorkflows"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpBigtableAppProfilesGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud bigtable appprofile",
                                "cloud bigtable appprofiles"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Bigtable AppProfile",
                                "Cloud Bigtable AppProfiles"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpBigtableBackupsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud bigtable backup",
                                "cloud bigtable backups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Bigtable Backup",
                                "Cloud Bigtable Backups"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpBigtableClustersGenericResource]: {
                        text: {
                            withServiceName: [
                                "bigtable cluster",
                                "bigtable clusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Bigtable Cluster",
                                "Bigtable Clusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpBigtableInstancesGenericResource]: {
                        text: {
                            withServiceName: [
                                "bigtable instance",
                                "bigtable instances"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Bigtable Instance",
                                "Bigtable Instances"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpBigtableTablesGenericResource]: {
                        text: {
                            withServiceName: [
                                "bigtable table",
                                "bigtable tables"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Bigtable Table",
                                "Bigtable Tables"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpBillingAccountsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud billing billingaccount",
                                "cloud billing billingaccounts"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Billing BillingAccount",
                                "Cloud Billing BillingAccounts"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpBillingProjectBillingInfosGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud billing projectbillinginfo",
                                "cloud billing projectbillinginfos"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Billing ProjectBillingInfo",
                                "Cloud Billing ProjectBillingInfos"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpBlockchainnodeengineBlockchainNodesGenericResource]: {
                        text: {
                            withServiceName: [
                                "blockchainnodeengine blockchainnode",
                                "blockchainnodeengine blockchainnodes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Blockchainnodeengine BlockchainNode",
                                "Blockchainnodeengine BlockchainNodes"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCertificatemanagerCertificateIssuanceConfigsGenericResource]: {
                        text: {
                            withServiceName: [
                                "certificatemanager certificateissuanceconfig",
                                "certificatemanager certificateissuanceconfigs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Certificatemanager CertificateIssuanceConfig",
                                "Certificatemanager CertificateIssuanceConfigs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCertificatemanagerCertificateMapEntriesGenericResource]: {
                        text: {
                            withServiceName: [
                                "certificatemanager certificatemapentry",
                                "certificatemanager certificatemapentries"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Certificatemanager CertificateMapEntry",
                                "Certificatemanager CertificateMapEntries"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCertificatemanagerCertificateMapsGenericResource]: {
                        text: {
                            withServiceName: [
                                "certificatemanager certificatemap",
                                "certificatemanager certificatemaps"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Certificatemanager CertificateMap",
                                "Certificatemanager CertificateMaps"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCertificatemanagerCertificatesGenericResource]: {
                        text: {
                            withServiceName: [
                                "certificatemanager certificate",
                                "certificatemanager certificates"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Certificatemanager Certificate",
                                "Certificatemanager Certificates"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCertificatemanagerDnsauthorizationsGenericResource]: {
                        text: {
                            withServiceName: [
                                "certificatemanager dnsauthorization",
                                "certificatemanager dnsauthorizations"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Certificatemanager DnsAuthorization",
                                "Certificatemanager DnsAuthorizations"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCertificatemanagerTrustconfigsGenericResource]: {
                        text: {
                            withServiceName: [
                                "certificatemanager trustconfig",
                                "certificatemanager trustconfigs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Certificatemanager TrustConfig",
                                "Certificatemanager TrustConfigs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudAssetFeedsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud asset feed",
                                "cloud asset feeds"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Asset Feed",
                                "Cloud Asset Feeds"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudBuildBitbucketServerConfigsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud build bitbucketserverconfig",
                                "cloud build bitbucketserverconfigs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Build BitbucketServerConfig",
                                "Cloud Build BitbucketServerConfigs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudBuildBuildTriggersGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud build buildtrigger",
                                "cloud build buildtriggers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Build BuildTrigger",
                                "Cloud Build BuildTriggers"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudBuildBuildsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud build build",
                                "cloud build builds"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Build Build",
                                "Cloud Build Builds"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudBuildConnectionsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud build connection",
                                "cloud build connections"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Build Connection",
                                "Cloud Build Connections"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudBuildGithubEnterpriseConfigsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud build githubenterpriseconfig",
                                "cloud build githubenterpriseconfigs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Build GithubEnterpriseConfig",
                                "Cloud Build GithubEnterpriseConfigs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudBuildGlobalTriggerSettingsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud build globaltriggersettings",
                                "cloud build globaltriggersettings"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Build GlobalTriggerSettings",
                                "Cloud Build GlobalTriggerSettings"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudBuildInstallationsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud build installation",
                                "cloud build installations"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Build Installation",
                                "Cloud Build Installations"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudBuildRepositoriesGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud build repository",
                                "cloud build repositories"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Build Repository",
                                "Cloud Build Repositories"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudBuildWorkerpoolsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud build workerpool",
                                "cloud build workerpools"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Build WorkerPool",
                                "Cloud Build WorkerPools"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudFunctionsFunctionsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud functions cloudfunction",
                                "cloud functions cloudfunctions"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Functions CloudFunction",
                                "Cloud Functions CloudFunctions"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudKmsAutokeyConfigsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud kms autokeyconfig",
                                "cloud kms autokeyconfigs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Kms AutokeyConfig",
                                "Cloud Kms AutokeyConfigs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudKmsCryptoKeyVersionsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud kms cryptokeyversion",
                                "cloud kms cryptokeyversions"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Kms CryptoKeyVersion",
                                "Cloud Kms CryptoKeyVersions"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudKmsCryptoKeysGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud kms cryptokey",
                                "cloud kms cryptokeys"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Kms CryptoKey",
                                "Cloud Kms CryptoKeys"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudKmsEkmConfigsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud kms ekmconfig",
                                "cloud kms ekmconfigs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Kms EkmConfig",
                                "Cloud Kms EkmConfigs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudKmsEkmConnectionsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud kms ekmconnection",
                                "cloud kms ekmconnections"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Kms EkmConnection",
                                "Cloud Kms EkmConnections"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudKmsImportJobsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud kms importjob",
                                "cloud kms importjobs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Kms ImportJob",
                                "Cloud Kms ImportJobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudKmsKeyHandlesGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud kms keyhandle",
                                "cloud kms keyhandles"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Kms KeyHandle",
                                "Cloud Kms KeyHandles"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudKmsKeyRingsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud kms keyring",
                                "cloud kms keyrings"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Kms KeyRing",
                                "Cloud Kms KeyRings"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudSqlBackupRunsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud sql backuprun",
                                "cloud sql backupruns"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud SQL BackupRun",
                                "Cloud SQL BackupRuns"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudSqlBackupsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud sql backup",
                                "cloud sql backups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud SQL Backup",
                                "Cloud SQL Backups"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudSqlInstancesGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud sql instance",
                                "cloud sql instances"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud SQL Instance",
                                "Cloud SQL Instances"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudTasksQueuesGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud tasks queue",
                                "cloud tasks queues"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Tasks Queue",
                                "Cloud Tasks Queues"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudaicompanionCodeRepositoryIndicesGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloudaicompanion coderepositoryindex",
                                "cloudaicompanion coderepositoryindices"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloudaicompanion CodeRepositoryIndex",
                                "Cloudaicompanion CodeRepositoryIndices"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudaicompanionRepositoryGroupsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloudaicompanion repositorygroup",
                                "cloudaicompanion repositorygroups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloudaicompanion RepositoryGroup",
                                "Cloudaicompanion RepositoryGroups"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudcontrolspartnerCustomersGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloudcontrolspartner customer",
                                "cloudcontrolspartner customers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloudcontrolspartner Customer",
                                "Cloudcontrolspartner Customers"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudcontrolspartnerPartnersGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloudcontrolspartner partner",
                                "cloudcontrolspartner partners"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloudcontrolspartner Partner",
                                "Cloudcontrolspartner Partners"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudcontrolspartnerWorkloadGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloudcontrolspartner workload",
                                "cloudcontrolspartner workload"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloudcontrolspartner Workload",
                                "Cloudcontrolspartner Workload"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpClouddeployDeliveryPipelinesGenericResource]: {
                        text: {
                            withServiceName: [
                                "clouddeploy deliverypipeline",
                                "clouddeploy deliverypipelines"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Clouddeploy DeliveryPipeline",
                                "Clouddeploy DeliveryPipelines"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpClouddeployReleasesGenericResource]: {
                        text: {
                            withServiceName: [
                                "clouddeploy release",
                                "clouddeploy releases"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Clouddeploy Release",
                                "Clouddeploy Releases"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpClouddeployRolloutsGenericResource]: {
                        text: {
                            withServiceName: [
                                "clouddeploy rollout",
                                "clouddeploy rollouts"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Clouddeploy Rollout",
                                "Clouddeploy Rollouts"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpClouddeployTargetsGenericResource]: {
                        text: {
                            withServiceName: [
                                "clouddeploy target",
                                "clouddeploy targets"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Clouddeploy Target",
                                "Clouddeploy Targets"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpCloudquotasQuotaPreferencesGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloudquotas quotapreference",
                                "cloudquotas quotapreferences"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloudquotas QuotaPreference",
                                "Cloudquotas QuotaPreferences"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComposerEnvironmentsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud composer environment",
                                "cloud composer environments"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Composer Environment",
                                "Cloud Composer Environments"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeAddressesGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine address",
                                "compute engine addresses"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine Address",
                                "Compute Engine Addresses"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeAutoscalersGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine autoscaler",
                                "compute engine autoscalers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine Autoscaler",
                                "Compute Engine Autoscalers"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeBackendBucketsGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine backendbucket",
                                "compute engine backendbuckets"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine BackendBucket",
                                "Compute Engine BackendBuckets"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeBackendServicesGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine backendservice",
                                "compute engine backendservices"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine BackendService",
                                "Compute Engine BackendServices"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeCommitmentsGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine commitment",
                                "compute engine commitments"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine Commitment",
                                "Compute Engine Commitments"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeDisksGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine disk",
                                "compute engine disks"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine Disk",
                                "Compute Engine Disks"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeExternalVpnGatewaysGenericResource]: {
                        text: {
                            withServiceName: [
                                "external vpn gateway",
                                "external vpn gateways"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "External VPN Gateway",
                                "External VPN Gateways"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeFirewallPoliciesGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine firewallpolicy",
                                "compute engine firewallpolicies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine FirewallPolicy",
                                "Compute Engine FirewallPolicies"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeFirewallsGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine firewall",
                                "compute engine firewalls"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine Firewall",
                                "Compute Engine Firewalls"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeForwardingRulesGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine forwardingrule",
                                "compute engine forwardingrules"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine ForwardingRule",
                                "Compute Engine ForwardingRules"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeGlobalAddressesGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine globaladdress",
                                "compute engine globaladdresses"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine GlobalAddress",
                                "Compute Engine GlobalAddresses"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeGlobalForwardingRulesGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine globalforwardingrule",
                                "compute engine globalforwardingrules"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine GlobalForwardingRule",
                                "Compute Engine GlobalForwardingRules"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeHealthChecksGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine healthcheck",
                                "compute engine healthchecks"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine HealthCheck",
                                "Compute Engine HealthChecks"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeHttpHealthChecksGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine httphealthcheck",
                                "compute engine httphealthchecks"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine HttpHealthCheck",
                                "Compute Engine HttpHealthChecks"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeHttpsHealthChecksGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine httpshealthcheck",
                                "compute engine httpshealthchecks"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine HttpsHealthCheck",
                                "Compute Engine HttpsHealthChecks"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeImagesGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine image",
                                "compute engine images"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine Image",
                                "Compute Engine Images"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeInstanceGroupManagersGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine instancegroupmanager",
                                "compute engine instancegroupmanagers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine InstanceGroupManager",
                                "Compute Engine InstanceGroupManagers"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeInstanceGroupsGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine instancegroup",
                                "compute engine instancegroups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine InstanceGroup",
                                "Compute Engine InstanceGroups"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeInstanceTemplatesGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine instancetemplate",
                                "compute engine instancetemplates"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine InstanceTemplate",
                                "Compute Engine InstanceTemplates"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeInstancesGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine instance",
                                "compute engine instances"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine Instance",
                                "Compute Engine Instances"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeInstantSnapshotsGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine instantsnapshot",
                                "compute engine instantsnapshots"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine InstantSnapshot",
                                "Compute Engine InstantSnapshots"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeInterconnectAttachmentsGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine interconnectattachment",
                                "compute engine interconnectattachments"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine InterconnectAttachment",
                                "Compute Engine InterconnectAttachments"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeInterconnectsGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine interconnect",
                                "compute engine interconnects"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine Interconnect",
                                "Compute Engine Interconnects"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeLicensesGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine license",
                                "compute engine licenses"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine License",
                                "Compute Engine Licenses"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeMachineImagesGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine machineimage",
                                "compute engine machineimages"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine MachineImage",
                                "Compute Engine MachineImages"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeNetworkAttachmentsGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine networkattachment",
                                "compute engine networkattachments"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine NetworkAttachment",
                                "Compute Engine NetworkAttachments"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeNetworkEdgeSecurityServicesGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine networkedgesecurityservice",
                                "compute engine networkedgesecurityservices"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine NetworkEdgeSecurityService",
                                "Compute Engine NetworkEdgeSecurityServices"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeNetworkEndpointGroupsGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine networkendpointgroup",
                                "compute engine networkendpointgroups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine NetworkEndpointGroup",
                                "Compute Engine NetworkEndpointGroups"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeNetworksGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine network",
                                "compute engine networks"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine Network",
                                "Compute Engine Networks"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeNodeGroupsGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute node group",
                                "compute node groups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Node Group",
                                "Compute Node Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeNodeTemplatesGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute node template",
                                "compute node templates"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Node Template",
                                "Compute Node Templates"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputePacketMirroringsGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine packetmirroring",
                                "compute engine packetmirrorings"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine PacketMirroring",
                                "Compute Engine PacketMirrorings"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeProjectsGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine project",
                                "compute engine projects"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine Project",
                                "Compute Engine Projects"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputePublicDelegatedPrefixesGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine publicdelegatedprefix",
                                "compute engine publicdelegatedprefixes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine PublicDelegatedPrefix",
                                "Compute Engine PublicDelegatedPrefixes"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeRegionBackendServicesGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine regionbackendservice",
                                "compute engine regionbackendservices"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine RegionBackendService",
                                "Compute Engine RegionBackendServices"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeRegionDisksGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine regiondisk",
                                "compute engine regiondisks"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine RegionDisk",
                                "Compute Engine RegionDisks"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeReservationsGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine reservation",
                                "compute engine reservations"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine Reservation",
                                "Compute Engine Reservations"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeResourcePoliciesGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine resourcepolicy",
                                "compute engine resourcepolicies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine ResourcePolicy",
                                "Compute Engine ResourcePolicies"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeRoutersGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine router",
                                "compute engine routers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine Router",
                                "Compute Engine Routers"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeRoutesGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine route",
                                "compute engine routes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine Route",
                                "Compute Engine Routes"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeSecurityPoliciesGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine securitypolicy",
                                "compute engine securitypolicies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine SecurityPolicy",
                                "Compute Engine SecurityPolicies"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeServiceAttachmentsGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine serviceattachment",
                                "compute engine serviceattachments"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine ServiceAttachment",
                                "Compute Engine ServiceAttachments"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeSnapshotsGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine snapshot",
                                "compute engine snapshots"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine Snapshot",
                                "Compute Engine Snapshots"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeSslCertificatesGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine sslcertificate",
                                "compute engine sslcertificates"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine SslCertificate",
                                "Compute Engine SslCertificates"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeSslPoliciesGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine sslpolicy",
                                "compute engine sslpolicies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine SslPolicy",
                                "Compute Engine SslPolicies"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeStoragePoolsGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine storagepool",
                                "compute engine storagepools"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine StoragePool",
                                "Compute Engine StoragePools"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeSubnetworksGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine subnetwork",
                                "compute engine subnetworks"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine Subnetwork",
                                "Compute Engine Subnetworks"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeTargetGrpcProxiesGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine targetgrpcproxy",
                                "compute engine targetgrpcproxies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine TargetGrpcProxy",
                                "Compute Engine TargetGrpcProxies"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeTargetHttpProxiesGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine targethttpproxy",
                                "compute engine targethttpproxies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine TargetHttpProxy",
                                "Compute Engine TargetHttpProxies"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeTargetHttpsProxiesGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine targethttpsproxy",
                                "compute engine targethttpsproxies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine TargetHttpsProxy",
                                "Compute Engine TargetHttpsProxies"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeTargetInstancesGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine targetinstance",
                                "compute engine targetinstances"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine TargetInstance",
                                "Compute Engine TargetInstances"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeTargetPoolsGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine targetpool",
                                "compute engine targetpools"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine TargetPool",
                                "Compute Engine TargetPools"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeTargetSslProxiesGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine targetsslproxy",
                                "compute engine targetsslproxies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine TargetSslProxy",
                                "Compute Engine TargetSslProxies"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeTargetTcpProxiesGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine targettcpproxy",
                                "compute engine targettcpproxies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine TargetTcpProxy",
                                "Compute Engine TargetTcpProxies"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeTargetVpnGatewaysGenericResource]: {
                        text: {
                            withServiceName: [
                                "classic vpn gateway",
                                "classic vpn gateways"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Classic VPN gateway",
                                "Classic VPN gateways"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeUrlMapsGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine urlmap",
                                "compute engine urlmaps"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine UrlMap",
                                "Compute Engine UrlMaps"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeVpnGatewaysGenericResource]: {
                        text: {
                            withServiceName: [
                                "vpn gateway",
                                "vpn gateways"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "VPN Gateway",
                                "VPN Gateways"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpComputeVpnTunnelsGenericResource]: {
                        text: {
                            withServiceName: [
                                "compute engine vpntunnel",
                                "compute engine vpntunnels"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Compute Engine VpnTunnel",
                                "Compute Engine VpnTunnels"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpConfigDeploymentsGenericResource]: {
                        text: {
                            withServiceName: [
                                "config deployment",
                                "config deployments"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Config Deployment",
                                "Config Deployments"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpConfigPreviewsGenericResource]: {
                        text: {
                            withServiceName: [
                                "config preview",
                                "config previews"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Config Preview",
                                "Config Previews"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpConnectorsConnectionsGenericResource]: {
                        text: {
                            withServiceName: [
                                "connectors connection",
                                "connectors connections"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Connectors Connection",
                                "Connectors Connections"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpConnectorsEndpointAttachmentsGenericResource]: {
                        text: {
                            withServiceName: [
                                "connectors endpointattachment",
                                "connectors endpointattachments"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Connectors EndpointAttachment",
                                "Connectors EndpointAttachments"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpConnectorsEventSubscriptionsGenericResource]: {
                        text: {
                            withServiceName: [
                                "connectors eventsubscription",
                                "connectors eventsubscriptions"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Connectors EventSubscription",
                                "Connectors EventSubscriptions"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpConnectorsManagedZonesGenericResource]: {
                        text: {
                            withServiceName: [
                                "connectors managedzone",
                                "connectors managedzones"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Connectors ManagedZone",
                                "Connectors ManagedZones"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContactcenterinsightsIssueModelsGenericResource]: {
                        text: {
                            withServiceName: [
                                "contactcenterinsights issuemodel",
                                "contactcenterinsights issuemodels"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Contactcenterinsights IssueModel",
                                "Contactcenterinsights IssueModels"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContactcenterinsightsPhraseMatchersGenericResource]: {
                        text: {
                            withServiceName: [
                                "contactcenterinsights phrasematcher",
                                "contactcenterinsights phrasematchers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Contactcenterinsights PhraseMatcher",
                                "Contactcenterinsights PhraseMatchers"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContactcenterinsightsViewsGenericResource]: {
                        text: {
                            withServiceName: [
                                "contactcenterinsights view",
                                "contactcenterinsights views"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Contactcenterinsights View",
                                "Contactcenterinsights Views"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerClusterRoleBindingsGenericResource]: {
                        text: {
                            withServiceName: [
                                "google kubernetes engine clusterrolebinding",
                                "google kubernetes engine clusterrolebindings"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Kubernetes Engine ClusterRoleBinding",
                                "Google Kubernetes Engine ClusterRoleBindings"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerClusterRolesGenericResource]: {
                        text: {
                            withServiceName: [
                                "google kubernetes engine clusterrole",
                                "google kubernetes engine clusterroles"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Kubernetes Engine ClusterRole",
                                "Google Kubernetes Engine ClusterRoles"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerClustersGenericResource]: {
                        text: {
                            withServiceName: [
                                "google kubernetes engine cluster",
                                "google kubernetes engine clusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Kubernetes Engine Cluster",
                                "Google Kubernetes Engine Clusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerCronJobsGenericResource]: {
                        text: {
                            withServiceName: [
                                "google kubernetes engine cronjob",
                                "google kubernetes engine cronjobs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Kubernetes Engine CronJob",
                                "Google Kubernetes Engine CronJobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerDaemonSetsGenericResource]: {
                        text: {
                            withServiceName: [
                                "google kubernetes engine daemonset",
                                "google kubernetes engine daemonsets"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Kubernetes Engine DaemonSet",
                                "Google Kubernetes Engine DaemonSets"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerDeploymentsGenericResource]: {
                        text: {
                            withServiceName: [
                                "google kubernetes engine deployment",
                                "google kubernetes engine deployments"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Kubernetes Engine Deployment",
                                "Google Kubernetes Engine Deployments"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerEndpointsGenericResource]: {
                        text: {
                            withServiceName: [
                                "google kubernetes engine endpoints",
                                "google kubernetes engine endpoints"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Kubernetes Engine Endpoints",
                                "Google Kubernetes Engine Endpoints"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerExtensionsDaemonSetGenericResource]: {
                        text: {
                            withServiceName: [
                                "google kubernetes engine daemonset",
                                "google kubernetes engine daemonsets"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Kubernetes Engine DaemonSet",
                                "Google Kubernetes Engine DaemonSets"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerExtensionsIngressGenericResource]: {
                        text: {
                            withServiceName: [
                                "google kubernetes engine ingress",
                                "google kubernetes engine ingresses"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Kubernetes Engine Ingress",
                                "Google Kubernetes Engine Ingresses"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerHorizontalPodAutoscalersGenericResource]: {
                        text: {
                            withServiceName: [
                                "google kubernetes engine horizontalpodautoscaler",
                                "google kubernetes engine horizontalpodautoscalers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Kubernetes Engine HorizontalPodAutoscaler",
                                "Google Kubernetes Engine HorizontalPodAutoscalers"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerJobsGenericResource]: {
                        text: {
                            withServiceName: [
                                "google kubernetes engine job",
                                "google kubernetes engine jobs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Kubernetes Engine Job",
                                "Google Kubernetes Engine Jobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerNamespacesGenericResource]: {
                        text: {
                            withServiceName: [
                                "google kubernetes engine namespace",
                                "google kubernetes engine namespaces"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Kubernetes Engine Namespace",
                                "Google Kubernetes Engine Namespaces"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerNetworkPoliciesGenericResource]: {
                        text: {
                            withServiceName: [
                                "google kubernetes engine networkpolicy",
                                "google kubernetes engine networkpolicies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Kubernetes Engine NetworkPolicy",
                                "Google Kubernetes Engine NetworkPolicies"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerNetworkingIngressGenericResource]: {
                        text: {
                            withServiceName: [
                                "google kubernetes engine ingress",
                                "google kubernetes engine ingresses"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Kubernetes Engine Ingress",
                                "Google Kubernetes Engine Ingresses"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerNodePoolsGenericResource]: {
                        text: {
                            withServiceName: [
                                "kubernetes node pool",
                                "kubernetes node pools"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Kubernetes Node Pool",
                                "Kubernetes Node Pools"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerNodesGenericResource]: {
                        text: {
                            withServiceName: [
                                "google kubernetes engine node",
                                "google kubernetes engine nodes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Kubernetes Engine Node",
                                "Google Kubernetes Engine Nodes"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerPersistentVolumeClaimsGenericResource]: {
                        text: {
                            withServiceName: [
                                "google kubernetes engine persistentvolumeclaim",
                                "google kubernetes engine persistentvolumeclaims"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Kubernetes Engine PersistentVolumeClaim",
                                "Google Kubernetes Engine PersistentVolumeClaims"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerPersistentVolumesGenericResource]: {
                        text: {
                            withServiceName: [
                                "google kubernetes engine persistentvolume",
                                "google kubernetes engine persistentvolumes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Kubernetes Engine PersistentVolume",
                                "Google Kubernetes Engine PersistentVolumes"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerPodTemplatesGenericResource]: {
                        text: {
                            withServiceName: [
                                "google kubernetes engine podtemplate",
                                "google kubernetes engine podtemplates"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Kubernetes Engine PodTemplate",
                                "Google Kubernetes Engine PodTemplates"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerPodsGenericResource]: {
                        text: {
                            withServiceName: [
                                "google kubernetes engine pod",
                                "google kubernetes engine pods"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Kubernetes Engine Pod",
                                "Google Kubernetes Engine Pods"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerReplicaSetsGenericResource]: {
                        text: {
                            withServiceName: [
                                "google kubernetes engine replicaset",
                                "google kubernetes engine replicasets"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Kubernetes Engine ReplicaSet",
                                "Google Kubernetes Engine ReplicaSets"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerReplicationControllersGenericResource]: {
                        text: {
                            withServiceName: [
                                "google kubernetes engine replicationcontroller",
                                "google kubernetes engine replicationcontrollers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Kubernetes Engine ReplicationController",
                                "Google Kubernetes Engine ReplicationControllers"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerResourceQuotaGenericResource]: {
                        text: {
                            withServiceName: [
                                "google kubernetes engine resourcequota",
                                "google kubernetes engine resourcequotas"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Kubernetes Engine ResourceQuota",
                                "Google Kubernetes Engine ResourceQuotas"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerRoleBindingsGenericResource]: {
                        text: {
                            withServiceName: [
                                "google kubernetes engine rolebinding",
                                "google kubernetes engine rolebindings"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Kubernetes Engine RoleBinding",
                                "Google Kubernetes Engine RoleBindings"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerRolesGenericResource]: {
                        text: {
                            withServiceName: [
                                "google kubernetes engine role",
                                "google kubernetes engine roles"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Kubernetes Engine Role",
                                "Google Kubernetes Engine Roles"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerSecretsGenericResource]: {
                        text: {
                            withServiceName: [
                                "google kubernetes engine secret",
                                "google kubernetes engine secrets"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Kubernetes Engine Secret",
                                "Google Kubernetes Engine Secrets"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerServiceAccountsGenericResource]: {
                        text: {
                            withServiceName: [
                                "google kubernetes engine serviceaccount",
                                "google kubernetes engine serviceaccounts"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Kubernetes Engine ServiceAccount",
                                "Google Kubernetes Engine ServiceAccounts"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerServicesGenericResource]: {
                        text: {
                            withServiceName: [
                                "google kubernetes engine service",
                                "google kubernetes engine services"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Kubernetes Engine Service",
                                "Google Kubernetes Engine Services"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerStatefulSetsGenericResource]: {
                        text: {
                            withServiceName: [
                                "google kubernetes engine statefulset",
                                "google kubernetes engine statefulsets"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Kubernetes Engine StatefulSet",
                                "Google Kubernetes Engine StatefulSets"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpContainerStorageClassesGenericResource]: {
                        text: {
                            withServiceName: [
                                "google kubernetes engine storageclass",
                                "google kubernetes engine storageclasses"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Google Kubernetes Engine StorageClass",
                                "Google Kubernetes Engine StorageClasses"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDataFusionDnsPeeringsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud data fusion dnspeering",
                                "cloud data fusion dnspeerings"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Data Fusion DnsPeering",
                                "Cloud Data Fusion DnsPeerings"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDataFusionInstancesGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud data fusion instance",
                                "cloud data fusion instances"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Data Fusion Instance",
                                "Cloud Data Fusion Instances"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDataflowJobsGenericResource]: {
                        text: {
                            withServiceName: [
                                "dataflow job",
                                "dataflow jobs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dataflow Job",
                                "Dataflow Jobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDataformCompilationResultsGenericResource]: {
                        text: {
                            withServiceName: [
                                "dataform compilationresult",
                                "dataform compilationresults"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dataform CompilationResult",
                                "Dataform CompilationResults"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDataformReleaseConfigsGenericResource]: {
                        text: {
                            withServiceName: [
                                "dataform releaseconfig",
                                "dataform releaseconfigs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dataform ReleaseConfig",
                                "Dataform ReleaseConfigs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDataformRepositoriesGenericResource]: {
                        text: {
                            withServiceName: [
                                "dataform repository",
                                "dataform repositories"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dataform Repository",
                                "Dataform Repositories"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDataformWorkflowConfigsGenericResource]: {
                        text: {
                            withServiceName: [
                                "dataform workflowconfig",
                                "dataform workflowconfigs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dataform WorkflowConfig",
                                "Dataform WorkflowConfigs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDataformWorkflowInvocationsGenericResource]: {
                        text: {
                            withServiceName: [
                                "dataform workflowinvocation",
                                "dataform workflowinvocations"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dataform WorkflowInvocation",
                                "Dataform WorkflowInvocations"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDataformWorkspacesGenericResource]: {
                        text: {
                            withServiceName: [
                                "dataform workspace",
                                "dataform workspaces"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dataform Workspace",
                                "Dataform Workspaces"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDatalineageProcessesGenericResource]: {
                        text: {
                            withServiceName: [
                                "datalineage process",
                                "datalineage processes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Datalineage Process",
                                "Datalineage Processes"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDatamigrationConnectionprofilesGenericResource]: {
                        text: {
                            withServiceName: [
                                "datamigration connectionprofile",
                                "datamigration connectionprofiles"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Datamigration ConnectionProfile",
                                "Datamigration ConnectionProfiles"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDatamigrationConversionworkspacesGenericResource]: {
                        text: {
                            withServiceName: [
                                "datamigration conversionworkspace",
                                "datamigration conversionworkspaces"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Datamigration ConversionWorkspace",
                                "Datamigration ConversionWorkspaces"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDatamigrationMigrationjobsGenericResource]: {
                        text: {
                            withServiceName: [
                                "datamigration migrationjob",
                                "datamigration migrationjobs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Datamigration MigrationJob",
                                "Datamigration MigrationJobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDatamigrationPrivateconnectionsGenericResource]: {
                        text: {
                            withServiceName: [
                                "datamigration privateconnection",
                                "datamigration privateconnections"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Datamigration PrivateConnection",
                                "Datamigration PrivateConnections"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDataplexAspectTypesGenericResource]: {
                        text: {
                            withServiceName: [
                                "dataplex aspecttype",
                                "dataplex aspecttypes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dataplex AspectType",
                                "Dataplex AspectTypes"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDataplexAssetsGenericResource]: {
                        text: {
                            withServiceName: [
                                "dataplex asset",
                                "dataplex assets"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dataplex Asset",
                                "Dataplex Assets"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDataplexDatascansGenericResource]: {
                        text: {
                            withServiceName: [
                                "dataplex datascan",
                                "dataplex datascans"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dataplex DataScan",
                                "Dataplex DataScans"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDataplexEntryGroupsGenericResource]: {
                        text: {
                            withServiceName: [
                                "dataplex entrygroup",
                                "dataplex entrygroups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dataplex EntryGroup",
                                "Dataplex EntryGroups"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDataplexEntryTypesGenericResource]: {
                        text: {
                            withServiceName: [
                                "dataplex entrytype",
                                "dataplex entrytypes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dataplex EntryType",
                                "Dataplex EntryTypes"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDataplexEnvironmentsGenericResource]: {
                        text: {
                            withServiceName: [
                                "dataplex environment",
                                "dataplex environments"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dataplex Environment",
                                "Dataplex Environments"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDataplexLakesGenericResource]: {
                        text: {
                            withServiceName: [
                                "dataplex lake",
                                "dataplex lakes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dataplex Lake",
                                "Dataplex Lakes"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDataplexTasksGenericResource]: {
                        text: {
                            withServiceName: [
                                "dataplex task",
                                "dataplex tasks"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dataplex Task",
                                "Dataplex Tasks"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDataplexZonesGenericResource]: {
                        text: {
                            withServiceName: [
                                "dataplex zone",
                                "dataplex zones"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dataplex Zone",
                                "Dataplex Zones"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDataprocAutoscalingPoliciesGenericResource]: {
                        text: {
                            withServiceName: [
                                "dataproc autoscalingpolicy",
                                "dataproc autoscalingpolicies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dataproc AutoscalingPolicy",
                                "Dataproc AutoscalingPolicies"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDataprocBatchesGenericResource]: {
                        text: {
                            withServiceName: [
                                "dataproc batch",
                                "dataproc batches"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dataproc Batch",
                                "Dataproc Batches"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDataprocClustersGenericResource]: {
                        text: {
                            withServiceName: [
                                "dataproc cluster",
                                "dataproc clusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dataproc Cluster",
                                "Dataproc Clusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDataprocJobsGenericResource]: {
                        text: {
                            withServiceName: [
                                "dataproc job",
                                "dataproc jobs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dataproc Job",
                                "Dataproc Jobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDataprocSessionsGenericResource]: {
                        text: {
                            withServiceName: [
                                "dataproc session",
                                "dataproc sessions"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dataproc Session",
                                "Dataproc Sessions"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDataprocWorkflowTemplatesGenericResource]: {
                        text: {
                            withServiceName: [
                                "dataproc workflowtemplate",
                                "dataproc workflowtemplates"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dataproc WorkflowTemplate",
                                "Dataproc WorkflowTemplates"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDatastoreDatabasesGenericResource]: {
                        text: {
                            withServiceName: [
                                "datastore database",
                                "datastore databases"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Datastore Database",
                                "Datastore Databases"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDatastreamConnectionProfilesGenericResource]: {
                        text: {
                            withServiceName: [
                                "datastream connectionprofile",
                                "datastream connectionprofiles"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Datastream ConnectionProfile",
                                "Datastream ConnectionProfiles"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDatastreamPrivateConnectionsGenericResource]: {
                        text: {
                            withServiceName: [
                                "datastream privateconnection",
                                "datastream privateconnections"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Datastream PrivateConnection",
                                "Datastream PrivateConnections"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDatastreamStreamsGenericResource]: {
                        text: {
                            withServiceName: [
                                "datastream stream",
                                "datastream streams"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Datastream Stream",
                                "Datastream Streams"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDeveloperconnectConnectionsGenericResource]: {
                        text: {
                            withServiceName: [
                                "developerconnect connection",
                                "developerconnect connections"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Developerconnect Connection",
                                "Developerconnect Connections"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDeveloperconnectGitRepositoryLinksGenericResource]: {
                        text: {
                            withServiceName: [
                                "developerconnect gitrepositorylink",
                                "developerconnect gitrepositorylinks"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Developerconnect GitRepositoryLink",
                                "Developerconnect GitRepositoryLinks"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDialogflowAgentsGenericResource]: {
                        text: {
                            withServiceName: [
                                "dialogflow agent",
                                "dialogflow agents"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dialogflow Agent",
                                "Dialogflow Agents"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDialogflowConversationProfilesGenericResource]: {
                        text: {
                            withServiceName: [
                                "dialogflow conversationprofile",
                                "dialogflow conversationprofiles"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dialogflow ConversationProfile",
                                "Dialogflow ConversationProfiles"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDialogflowKnowledgeBasesGenericResource]: {
                        text: {
                            withServiceName: [
                                "dialogflow knowledgebase",
                                "dialogflow knowledgebases"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dialogflow KnowledgeBase",
                                "Dialogflow KnowledgeBases"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDialogflowLocationSettingsGenericResource]: {
                        text: {
                            withServiceName: [
                                "dialogflow locationsettings",
                                "dialogflow locationsettings"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dialogflow LocationSettings",
                                "Dialogflow LocationSettings"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDiscoveryengineCollectionsGenericResource]: {
                        text: {
                            withServiceName: [
                                "discoveryengine collection",
                                "discoveryengine collections"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Discoveryengine Collection",
                                "Discoveryengine Collections"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDiscoveryengineDataStoresGenericResource]: {
                        text: {
                            withServiceName: [
                                "discoveryengine datastore",
                                "discoveryengine datastores"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Discoveryengine DataStore",
                                "Discoveryengine DataStores"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDiscoveryengineEnginesGenericResource]: {
                        text: {
                            withServiceName: [
                                "discoveryengine engine",
                                "discoveryengine engines"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Discoveryengine Engine",
                                "Discoveryengine Engines"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDlpDeidentifyTemplatesGenericResource]: {
                        text: {
                            withServiceName: [
                                "dlp deidentifytemplate",
                                "dlp deidentifytemplates"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dlp DeidentifyTemplate",
                                "Dlp DeidentifyTemplates"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDlpDiscoveryConfigsGenericResource]: {
                        text: {
                            withServiceName: [
                                "dlp discoveryconfig",
                                "dlp discoveryconfigs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dlp DiscoveryConfig",
                                "Dlp DiscoveryConfigs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDlpInspectTemplatesGenericResource]: {
                        text: {
                            withServiceName: [
                                "dlp inspecttemplate",
                                "dlp inspecttemplates"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dlp InspectTemplate",
                                "Dlp InspectTemplates"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDlpJobTriggersGenericResource]: {
                        text: {
                            withServiceName: [
                                "dlp jobtrigger",
                                "dlp jobtriggers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dlp JobTrigger",
                                "Dlp JobTriggers"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDlpJobsGenericResource]: {
                        text: {
                            withServiceName: [
                                "dlp dlpjob",
                                "dlp dlpjobs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dlp DlpJob",
                                "Dlp DlpJobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDlpStoredInfoTypesGenericResource]: {
                        text: {
                            withServiceName: [
                                "dlp storedinfotype",
                                "dlp storedinfotypes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Dlp StoredInfoType",
                                "Dlp StoredInfoTypes"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDnsManagedZonesGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud dns managedzone",
                                "cloud dns managedzones"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud DNS ManagedZone",
                                "Cloud DNS ManagedZones"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDnsPoliciesGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud dns policy",
                                "cloud dns policies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud DNS Policy",
                                "Cloud DNS Policies"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDocumentAiHumanReviewConfigsGenericResource]: {
                        text: {
                            withServiceName: [
                                "document ai humanreviewconfig",
                                "document ai humanreviewconfigs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Document AI HumanReviewConfig",
                                "Document AI HumanReviewConfigs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDocumentAiLabelerPoolsGenericResource]: {
                        text: {
                            withServiceName: [
                                "document ai labelerpool",
                                "document ai labelerpools"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Document AI LabelerPool",
                                "Document AI LabelerPools"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDocumentAiProcessorVersionsGenericResource]: {
                        text: {
                            withServiceName: [
                                "document ai processorversion",
                                "document ai processorversions"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Document AI ProcessorVersion",
                                "Document AI ProcessorVersions"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDocumentAiProcessorsGenericResource]: {
                        text: {
                            withServiceName: [
                                "document ai processor",
                                "document ai processors"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Document AI Processor",
                                "Document AI Processors"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpDomainsRegistrationsGenericResource]: {
                        text: {
                            withServiceName: [
                                "domains registration",
                                "domains registrations"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Domains Registration",
                                "Domains Registrations"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpEssentialcontactsContactsGenericResource]: {
                        text: {
                            withServiceName: [
                                "essentialcontacts contact",
                                "essentialcontacts contacts"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Essentialcontacts Contact",
                                "Essentialcontacts Contacts"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpEventarcTriggersGenericResource]: {
                        text: {
                            withServiceName: [
                                "eventarc trigger",
                                "eventarc triggers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Eventarc Trigger",
                                "Eventarc Triggers"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpFileBackupsGenericResource]: {
                        text: {
                            withServiceName: [
                                "filestore backup",
                                "filestore backups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Filestore Backup",
                                "Filestore Backups"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpFileInstancesGenericResource]: {
                        text: {
                            withServiceName: [
                                "filestore instance",
                                "filestore instances"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Filestore Instance",
                                "Filestore Instances"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpFileSnapshotsGenericResource]: {
                        text: {
                            withServiceName: [
                                "filestore snapshot",
                                "filestore snapshots"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Filestore Snapshot",
                                "Filestore Snapshots"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpFinancialservicesBacktestResultsGenericResource]: {
                        text: {
                            withServiceName: [
                                "financialservices backtestresult",
                                "financialservices backtestresults"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Financialservices BacktestResult",
                                "Financialservices BacktestResults"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpFinancialservicesDatasetsGenericResource]: {
                        text: {
                            withServiceName: [
                                "financialservices dataset",
                                "financialservices datasets"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Financialservices Dataset",
                                "Financialservices Datasets"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpFinancialservicesEngineConfigsGenericResource]: {
                        text: {
                            withServiceName: [
                                "financialservices engineconfig",
                                "financialservices engineconfigs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Financialservices EngineConfig",
                                "Financialservices EngineConfigs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpFinancialservicesInstancesGenericResource]: {
                        text: {
                            withServiceName: [
                                "financialservices instance",
                                "financialservices instances"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Financialservices Instance",
                                "Financialservices Instances"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpFinancialservicesModelsGenericResource]: {
                        text: {
                            withServiceName: [
                                "financialservices model",
                                "financialservices models"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Financialservices Model",
                                "Financialservices Models"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpFinancialservicesPredictionResultsGenericResource]: {
                        text: {
                            withServiceName: [
                                "financialservices predictionresult",
                                "financialservices predictionresults"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Financialservices PredictionResult",
                                "Financialservices PredictionResults"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpFirebaseFirebaseAppInfosGenericResource]: {
                        text: {
                            withServiceName: [
                                "firebase firebaseappinfo",
                                "firebase firebaseappinfos"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Firebase FirebaseAppInfo",
                                "Firebase FirebaseAppInfos"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpFirebaseFirebaseProjectsGenericResource]: {
                        text: {
                            withServiceName: [
                                "firebase firebaseproject",
                                "firebase firebaseprojects"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Firebase FirebaseProject",
                                "Firebase FirebaseProjects"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpFirebaseRulesReleasesGenericResource]: {
                        text: {
                            withServiceName: [
                                "firebase rules release",
                                "firebase rules releases"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Firebase Rules Release",
                                "Firebase Rules Releases"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpFirebaseRulesRulesetsGenericResource]: {
                        text: {
                            withServiceName: [
                                "firebase rules ruleset",
                                "firebase rules rulesets"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Firebase Rules Ruleset",
                                "Firebase Rules Rulesets"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpGameServicesGameServerClustersGenericResource]: {
                        text: {
                            withServiceName: [
                                "game services gameservercluster",
                                "game services gameserverclusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Game Services GameServerCluster",
                                "Game Services GameServerClusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpGameServicesGameServerConfigsGenericResource]: {
                        text: {
                            withServiceName: [
                                "game services gameserverconfig",
                                "game services gameserverconfigs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Game Services GameServerConfig",
                                "Game Services GameServerConfigs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpGameServicesGameServerDeploymentsGenericResource]: {
                        text: {
                            withServiceName: [
                                "game services gameserverdeployment",
                                "game services gameserverdeployments"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Game Services GameServerDeployment",
                                "Game Services GameServerDeployments"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpGameServicesRealmsGenericResource]: {
                        text: {
                            withServiceName: [
                                "game services realm",
                                "game services realms"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Game Services Realm",
                                "Game Services Realms"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpGkeHubFeaturesGenericResource]: {
                        text: {
                            withServiceName: [
                                "hub feature",
                                "hub features"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Hub Feature",
                                "Hub Features"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpGkeHubFleetsGenericResource]: {
                        text: {
                            withServiceName: [
                                "hub fleet",
                                "hub fleets"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Hub Fleet",
                                "Hub Fleets"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpGkeHubMembershipbindingsGenericResource]: {
                        text: {
                            withServiceName: [
                                "hub membershipbinding",
                                "hub membershipbindings"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Hub MembershipBinding",
                                "Hub MembershipBindings"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpGkeHubMembershipsGenericResource]: {
                        text: {
                            withServiceName: [
                                "hub membership",
                                "hub memberships"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Hub Membership",
                                "Hub Memberships"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpGkeHubNamespacesGenericResource]: {
                        text: {
                            withServiceName: [
                                "hub namespace",
                                "hub namespaces"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Hub Namespace",
                                "Hub Namespaces"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpGkeHubRbacrolebindingsGenericResource]: {
                        text: {
                            withServiceName: [
                                "hub rbacrolebinding",
                                "hub rbacrolebindings"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Hub RBACRoleBinding",
                                "Hub RBACRoleBindings"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpGkeHubScopesGenericResource]: {
                        text: {
                            withServiceName: [
                                "hub scope",
                                "hub scopes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Hub Scope",
                                "Hub Scopes"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpGkebackupBackupPlansGenericResource]: {
                        text: {
                            withServiceName: [
                                "gkebackup backupplan",
                                "gkebackup backupplans"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Gkebackup BackupPlan",
                                "Gkebackup BackupPlans"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpGkebackupBackupsGenericResource]: {
                        text: {
                            withServiceName: [
                                "gkebackup backup",
                                "gkebackup backups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Gkebackup Backup",
                                "Gkebackup Backups"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpGkebackupRestorePlansGenericResource]: {
                        text: {
                            withServiceName: [
                                "gkebackup restoreplan",
                                "gkebackup restoreplans"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Gkebackup RestorePlan",
                                "Gkebackup RestorePlans"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpGkebackupRestoresGenericResource]: {
                        text: {
                            withServiceName: [
                                "gkebackup restore",
                                "gkebackup restores"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Gkebackup Restore",
                                "Gkebackup Restores"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpGkebackupVolumeBackupsGenericResource]: {
                        text: {
                            withServiceName: [
                                "gkebackup volumebackup",
                                "gkebackup volumebackups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Gkebackup VolumeBackup",
                                "Gkebackup VolumeBackups"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpGkebackupVolumeRestoresGenericResource]: {
                        text: {
                            withServiceName: [
                                "gkebackup volumerestore",
                                "gkebackup volumerestores"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Gkebackup VolumeRestore",
                                "Gkebackup VolumeRestores"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpGkemulticloudAttachedClustersGenericResource]: {
                        text: {
                            withServiceName: [
                                "gkemulticloud attachedcluster",
                                "gkemulticloud attachedclusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Gkemulticloud AttachedCluster",
                                "Gkemulticloud AttachedClusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpGkemulticloudAwsClustersGenericResource]: {
                        text: {
                            withServiceName: [
                                "gkemulticloud awscluster",
                                "gkemulticloud awsclusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Gkemulticloud AwsCluster",
                                "Gkemulticloud AwsClusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpGkemulticloudAwsNodePoolsGenericResource]: {
                        text: {
                            withServiceName: [
                                "gkemulticloud awsnodepool",
                                "gkemulticloud awsnodepools"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Gkemulticloud AwsNodePool",
                                "Gkemulticloud AwsNodePools"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpGkemulticloudAzureClientsGenericResource]: {
                        text: {
                            withServiceName: [
                                "gkemulticloud azureclient",
                                "gkemulticloud azureclients"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Gkemulticloud AzureClient",
                                "Gkemulticloud AzureClients"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpGkemulticloudAzureClustersGenericResource]: {
                        text: {
                            withServiceName: [
                                "gkemulticloud azurecluster",
                                "gkemulticloud azureclusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Gkemulticloud AzureCluster",
                                "Gkemulticloud AzureClusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpGkemulticloudAzureNodePoolsGenericResource]: {
                        text: {
                            withServiceName: [
                                "gkemulticloud azurenodepool",
                                "gkemulticloud azurenodepools"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Gkemulticloud AzureNodePool",
                                "Gkemulticloud AzureNodePools"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpGkemulticloudConnectedClustersGenericResource]: {
                        text: {
                            withServiceName: [
                                "gkemulticloud connectedcluster",
                                "gkemulticloud connectedclusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Gkemulticloud ConnectedCluster",
                                "Gkemulticloud ConnectedClusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpGkeonpremBareMetalClustersGenericResource]: {
                        text: {
                            withServiceName: [
                                "gkeonprem baremetalcluster",
                                "gkeonprem baremetalclusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Gkeonprem BareMetalCluster",
                                "Gkeonprem BareMetalClusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpGkeonpremBareMetalNodePoolsGenericResource]: {
                        text: {
                            withServiceName: [
                                "gkeonprem baremetalnodepool",
                                "gkeonprem baremetalnodepools"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Gkeonprem BareMetalNodePool",
                                "Gkeonprem BareMetalNodePools"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpGkeonpremVmwareClustersGenericResource]: {
                        text: {
                            withServiceName: [
                                "gkeonprem vmwarecluster",
                                "gkeonprem vmwareclusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Gkeonprem VmwareCluster",
                                "Gkeonprem VmwareClusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpGkeonpremVmwareNodePoolsGenericResource]: {
                        text: {
                            withServiceName: [
                                "gkeonprem vmwarenodepool",
                                "gkeonprem vmwarenodepools"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Gkeonprem VmwareNodePool",
                                "Gkeonprem VmwareNodePools"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpHealthcareConsentStoresGenericResource]: {
                        text: {
                            withServiceName: [
                                "healthcare consentstore",
                                "healthcare consentstores"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Healthcare ConsentStore",
                                "Healthcare ConsentStores"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpHealthcareDatasetsGenericResource]: {
                        text: {
                            withServiceName: [
                                "healthcare dataset",
                                "healthcare datasets"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Healthcare Dataset",
                                "Healthcare Datasets"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpHealthcareDicomStoresGenericResource]: {
                        text: {
                            withServiceName: [
                                "healthcare dicomstore",
                                "healthcare dicomstores"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Healthcare DicomStore",
                                "Healthcare DicomStores"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpHealthcareFhirStoresGenericResource]: {
                        text: {
                            withServiceName: [
                                "healthcare fhirstore",
                                "healthcare fhirstores"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Healthcare FhirStore",
                                "Healthcare FhirStores"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpHealthcareHl7V2StoresGenericResource]: {
                        text: {
                            withServiceName: [
                                "healthcare hl7v2store",
                                "healthcare hl7v2stores"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Healthcare Hl7V2Store",
                                "Healthcare Hl7V2Stores"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIamPolicyV2sGenericResource]: {
                        text: {
                            withServiceName: [
                                "identity and access management policyv2",
                                "identity and access management policyv2s"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Identity and Access Management PolicyV2",
                                "Identity and Access Management PolicyV2s"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIamRolesGenericResource]: {
                        text: {
                            withServiceName: [
                                "identity and access management role",
                                "identity and access management roles"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Identity and Access Management Role",
                                "Identity and Access Management Roles"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIamServiceAccountKeysGenericResource]: {
                        text: {
                            withServiceName: [
                                "identity and access management serviceaccountkey",
                                "identity and access management serviceaccountkeys"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Identity and Access Management ServiceAccountKey",
                                "Identity and Access Management ServiceAccountKeys"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIamServiceAccountsGenericResource]: {
                        text: {
                            withServiceName: [
                                "identity and access management serviceaccount",
                                "identity and access management serviceaccounts"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Identity and Access Management ServiceAccount",
                                "Identity and Access Management ServiceAccounts"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIapTunnelDestGroupsGenericResource]: {
                        text: {
                            withServiceName: [
                                "iap tunneldestgroup",
                                "iap tunneldestgroups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Iap TunnelDestGroup",
                                "Iap TunnelDestGroups"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIapTunnelInstancesGenericResource]: {
                        text: {
                            withServiceName: [
                                "iap tunnelinstance",
                                "iap tunnelinstances"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Iap TunnelInstance",
                                "Iap TunnelInstances"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIapTunnelZonesGenericResource]: {
                        text: {
                            withServiceName: [
                                "iap tunnelzone",
                                "iap tunnelzones"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Iap TunnelZone",
                                "Iap TunnelZones"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIapTunnelsGenericResource]: {
                        text: {
                            withServiceName: [
                                "iap tunnel",
                                "iap tunnels"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Iap Tunnel",
                                "Iap Tunnels"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIapWebServiceVersionsGenericResource]: {
                        text: {
                            withServiceName: [
                                "iap webserviceversion",
                                "iap webserviceversions"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Iap WebServiceVersion",
                                "Iap WebServiceVersions"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIapWebServicesGenericResource]: {
                        text: {
                            withServiceName: [
                                "iap webservice",
                                "iap webservices"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Iap WebService",
                                "Iap WebServices"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIapWebTypesGenericResource]: {
                        text: {
                            withServiceName: [
                                "iap webtype",
                                "iap webtypes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Iap WebType",
                                "Iap WebTypes"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIapWebsGenericResource]: {
                        text: {
                            withServiceName: [
                                "iap web",
                                "iap webs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Iap Web",
                                "Iap Webs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIdentitytoolkitConfigsGenericResource]: {
                        text: {
                            withServiceName: [
                                "identitytoolkit config",
                                "identitytoolkit configs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Identitytoolkit Config",
                                "Identitytoolkit Configs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIdentitytoolkitDefaultSupportedIdpConfigsGenericResource]: {
                        text: {
                            withServiceName: [
                                "identitytoolkit defaultsupportedidpconfig",
                                "identitytoolkit defaultsupportedidpconfigs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Identitytoolkit DefaultSupportedIdpConfig",
                                "Identitytoolkit DefaultSupportedIdpConfigs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIdentitytoolkitInboundSamlConfigsGenericResource]: {
                        text: {
                            withServiceName: [
                                "identitytoolkit inboundsamlconfig",
                                "identitytoolkit inboundsamlconfigs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Identitytoolkit InboundSamlConfig",
                                "Identitytoolkit InboundSamlConfigs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIdentitytoolkitOauthIdpConfigsGenericResource]: {
                        text: {
                            withServiceName: [
                                "identitytoolkit oauthidpconfig",
                                "identitytoolkit oauthidpconfigs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Identitytoolkit OauthIdpConfig",
                                "Identitytoolkit OauthIdpConfigs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIdentitytoolkitTenantsGenericResource]: {
                        text: {
                            withServiceName: [
                                "identitytoolkit tenant",
                                "identitytoolkit tenants"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Identitytoolkit Tenant",
                                "Identitytoolkit Tenants"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIdsEndpointsGenericResource]: {
                        text: {
                            withServiceName: [
                                "ids endpoint",
                                "ids endpoints"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Ids Endpoint",
                                "Ids Endpoints"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIntegrationsAuthConfigsGenericResource]: {
                        text: {
                            withServiceName: [
                                "integrations authconfig",
                                "integrations authconfigs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Integrations AuthConfig",
                                "Integrations AuthConfigs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIntegrationsCertificatesGenericResource]: {
                        text: {
                            withServiceName: [
                                "integrations certificate",
                                "integrations certificates"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Integrations Certificate",
                                "Integrations Certificates"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIntegrationsExecutionsGenericResource]: {
                        text: {
                            withServiceName: [
                                "integrations execution",
                                "integrations executions"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Integrations Execution",
                                "Integrations Executions"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIntegrationsIntegrationVersionsGenericResource]: {
                        text: {
                            withServiceName: [
                                "integrations integrationversion",
                                "integrations integrationversions"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Integrations IntegrationVersion",
                                "Integrations IntegrationVersions"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIntegrationsIntegrationsGenericResource]: {
                        text: {
                            withServiceName: [
                                "integrations integration",
                                "integrations integrations"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Integrations Integration",
                                "Integrations Integrations"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIntegrationsSfdcChannelsGenericResource]: {
                        text: {
                            withServiceName: [
                                "integrations sfdcchannel",
                                "integrations sfdcchannels"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Integrations SfdcChannel",
                                "Integrations SfdcChannels"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIntegrationsSfdcInstancesGenericResource]: {
                        text: {
                            withServiceName: [
                                "integrations sfdcinstance",
                                "integrations sfdcinstances"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Integrations SfdcInstance",
                                "Integrations SfdcInstances"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpIntegrationsSuspensionsGenericResource]: {
                        text: {
                            withServiceName: [
                                "integrations suspension",
                                "integrations suspensions"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Integrations Suspension",
                                "Integrations Suspensions"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpKrmapihostingKrmApiHostsGenericResource]: {
                        text: {
                            withServiceName: [
                                "krmapihosting krmapihost",
                                "krmapihosting krmapihosts"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Krmapihosting KrmApiHost",
                                "Krmapihosting KrmApiHosts"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpLivestreamAssetsGenericResource]: {
                        text: {
                            withServiceName: [
                                "livestream asset",
                                "livestream assets"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Livestream Asset",
                                "Livestream Assets"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpLivestreamChannelsGenericResource]: {
                        text: {
                            withServiceName: [
                                "livestream channel",
                                "livestream channels"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Livestream Channel",
                                "Livestream Channels"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpLivestreamInputsGenericResource]: {
                        text: {
                            withServiceName: [
                                "livestream input",
                                "livestream inputs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Livestream Input",
                                "Livestream Inputs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpLivestreamPoolsGenericResource]: {
                        text: {
                            withServiceName: [
                                "livestream pool",
                                "livestream pools"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Livestream Pool",
                                "Livestream Pools"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpLoggingBucketsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud logging logbucket",
                                "cloud logging logbuckets"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Logging LogBucket",
                                "Cloud Logging LogBuckets"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpLoggingLinksGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud logging link",
                                "cloud logging links"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Logging Link",
                                "Cloud Logging Links"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpLoggingLogMetricsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud logging logmetric",
                                "cloud logging logmetrics"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Logging LogMetric",
                                "Cloud Logging LogMetrics"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpLoggingLogViewsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud logging logview",
                                "cloud logging logviews"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Logging LogView",
                                "Cloud Logging LogViews"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpLoggingRecentQueriesGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud logging recentquery",
                                "cloud logging recentqueries"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Logging RecentQuery",
                                "Cloud Logging RecentQueries"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpLoggingSavedQueriesGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud logging savedquery",
                                "cloud logging savedqueries"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Logging SavedQuery",
                                "Cloud Logging SavedQueries"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpLoggingSettingsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud logging settings",
                                "cloud logging settings"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Logging Settings",
                                "Cloud Logging Settings"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpLoggingSinksGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud logging logsink",
                                "cloud logging logsinks"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Logging LogSink",
                                "Cloud Logging LogSinks"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpLookerInstancesGenericResource]: {
                        text: {
                            withServiceName: [
                                "looker instance",
                                "looker instances"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Looker Instance",
                                "Looker Instances"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpManagedIdentitiesDomainsGenericResource]: {
                        text: {
                            withServiceName: [
                                "managed identities domain",
                                "managed identities domains"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Managed Identities Domain",
                                "Managed Identities Domains"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpManagedkafkaClustersGenericResource]: {
                        text: {
                            withServiceName: [
                                "managedkafka cluster",
                                "managedkafka clusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Managedkafka Cluster",
                                "Managedkafka Clusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpMemcacheInstancesGenericResource]: {
                        text: {
                            withServiceName: [
                                "memcache instance",
                                "memcache instances"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Memcache Instance",
                                "Memcache Instances"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpMetastoreBackupsGenericResource]: {
                        text: {
                            withServiceName: [
                                "metastore backup",
                                "metastore backups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Metastore Backup",
                                "Metastore Backups"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpMetastoreImportsGenericResource]: {
                        text: {
                            withServiceName: [
                                "metastore metadataimport",
                                "metastore metadataimports"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Metastore MetadataImport",
                                "Metastore MetadataImports"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpMetastoreServicesGenericResource]: {
                        text: {
                            withServiceName: [
                                "metastore service",
                                "metastore services"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Metastore Service",
                                "Metastore Services"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpMonitoringAlertPoliciesGenericResource]: {
                        text: {
                            withServiceName: [
                                "monitoring alertpolicy",
                                "monitoring alertpolicies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Monitoring AlertPolicy",
                                "Monitoring AlertPolicies"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpMonitoringDashboardsGenericResource]: {
                        text: {
                            withServiceName: [
                                "monitoring dashboard",
                                "monitoring dashboards"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Monitoring Dashboard",
                                "Monitoring Dashboards"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpMonitoringNotificationChannelsGenericResource]: {
                        text: {
                            withServiceName: [
                                "monitoring notificationchannel",
                                "monitoring notificationchannels"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Monitoring NotificationChannel",
                                "Monitoring NotificationChannels"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpMonitoringSnoozesGenericResource]: {
                        text: {
                            withServiceName: [
                                "monitoring snooze",
                                "monitoring snoozes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Monitoring Snooze",
                                "Monitoring Snoozes"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpMonitoringUptimeCheckConfigsGenericResource]: {
                        text: {
                            withServiceName: [
                                "monitoring uptimecheckconfig",
                                "monitoring uptimecheckconfigs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Monitoring UptimeCheckConfig",
                                "Monitoring UptimeCheckConfigs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetappActiveDirectoriesGenericResource]: {
                        text: {
                            withServiceName: [
                                "netapp activedirectory",
                                "netapp activedirectories"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Netapp ActiveDirectory",
                                "Netapp ActiveDirectories"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetappBackupPoliciesGenericResource]: {
                        text: {
                            withServiceName: [
                                "netapp backuppolicy",
                                "netapp backuppolicies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Netapp BackupPolicy",
                                "Netapp BackupPolicies"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetappBackupVaultsGenericResource]: {
                        text: {
                            withServiceName: [
                                "netapp backupvault",
                                "netapp backupvaults"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Netapp BackupVault",
                                "Netapp BackupVaults"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetappBackupsGenericResource]: {
                        text: {
                            withServiceName: [
                                "netapp backup",
                                "netapp backups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Netapp Backup",
                                "Netapp Backups"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetappKmsConfigsGenericResource]: {
                        text: {
                            withServiceName: [
                                "netapp kmsconfig",
                                "netapp kmsconfigs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Netapp KmsConfig",
                                "Netapp KmsConfigs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetappReplicationsGenericResource]: {
                        text: {
                            withServiceName: [
                                "netapp replication",
                                "netapp replications"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Netapp Replication",
                                "Netapp Replications"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetappSnapshotsGenericResource]: {
                        text: {
                            withServiceName: [
                                "netapp snapshot",
                                "netapp snapshots"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Netapp Snapshot",
                                "Netapp Snapshots"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetappStoragePoolsGenericResource]: {
                        text: {
                            withServiceName: [
                                "netapp storagepool",
                                "netapp storagepools"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Netapp StoragePool",
                                "Netapp StoragePools"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetappVolumesGenericResource]: {
                        text: {
                            withServiceName: [
                                "netapp volume",
                                "netapp volumes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Netapp Volume",
                                "Netapp Volumes"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetworkManagementConnectivitytestsGenericResource]: {
                        text: {
                            withServiceName: [
                                "network management connectivitytest",
                                "network management connectivitytests"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Management ConnectivityTest",
                                "Network Management ConnectivityTests"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetworkSecurityAddressGroupsGenericResource]: {
                        text: {
                            withServiceName: [
                                "network security addressgroup",
                                "network security addressgroups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Security AddressGroup",
                                "Network Security AddressGroups"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetworkSecurityFirewallEndpointAssociationsGenericResource]: {
                        text: {
                            withServiceName: [
                                "network security firewallendpointassociation",
                                "network security firewallendpointassociations"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Security FirewallEndpointAssociation",
                                "Network Security FirewallEndpointAssociations"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetworkSecurityFirewallEndpointsGenericResource]: {
                        text: {
                            withServiceName: [
                                "network security firewallendpoint",
                                "network security firewallendpoints"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Security FirewallEndpoint",
                                "Network Security FirewallEndpoints"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetworkSecurityGatewaySecurityPoliciesGenericResource]: {
                        text: {
                            withServiceName: [
                                "network security gatewaysecuritypolicy",
                                "network security gatewaysecuritypolicies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Security GatewaySecurityPolicy",
                                "Network Security GatewaySecurityPolicies"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetworkSecurityGatewaySecurityPolicyRulesGenericResource]: {
                        text: {
                            withServiceName: [
                                "network security gatewaysecuritypolicyrule",
                                "network security gatewaysecuritypolicyrules"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Security GatewaySecurityPolicyRule",
                                "Network Security GatewaySecurityPolicyRules"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetworkSecuritySecurityProfileGroupsGenericResource]: {
                        text: {
                            withServiceName: [
                                "network security securityprofilegroup",
                                "network security securityprofilegroups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Security SecurityProfileGroup",
                                "Network Security SecurityProfileGroups"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetworkSecuritySecurityProfilesGenericResource]: {
                        text: {
                            withServiceName: [
                                "network security securityprofile",
                                "network security securityprofiles"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Security SecurityProfile",
                                "Network Security SecurityProfiles"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetworkSecurityTlsInspectionPoliciesGenericResource]: {
                        text: {
                            withServiceName: [
                                "network security tlsinspectionpolicy",
                                "network security tlsinspectionpolicies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Security TlsInspectionPolicy",
                                "Network Security TlsInspectionPolicies"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetworkSecurityUrlListsGenericResource]: {
                        text: {
                            withServiceName: [
                                "network security urllist",
                                "network security urllists"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Security UrlList",
                                "Network Security UrlLists"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetworkServicesEdgeCacheKeysetsGenericResource]: {
                        text: {
                            withServiceName: [
                                "network services edgecachekeyset",
                                "network services edgecachekeysets"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Services EdgeCacheKeyset",
                                "Network Services EdgeCacheKeysets"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetworkServicesEdgeCacheOriginsGenericResource]: {
                        text: {
                            withServiceName: [
                                "network services edgecacheorigin",
                                "network services edgecacheorigins"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Services EdgeCacheOrigin",
                                "Network Services EdgeCacheOrigins"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetworkServicesEdgeCacheServicesGenericResource]: {
                        text: {
                            withServiceName: [
                                "network services edgecacheservice",
                                "network services edgecacheservices"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Services EdgeCacheService",
                                "Network Services EdgeCacheServices"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetworkServicesEndpointPoliciesGenericResource]: {
                        text: {
                            withServiceName: [
                                "network services endpointpolicy",
                                "network services endpointpolicies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Services EndpointPolicy",
                                "Network Services EndpointPolicies"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetworkServicesGatewaysGenericResource]: {
                        text: {
                            withServiceName: [
                                "network services gateway",
                                "network services gateways"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Services Gateway",
                                "Network Services Gateways"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetworkServicesGrpcRoutesGenericResource]: {
                        text: {
                            withServiceName: [
                                "network services grpcroute",
                                "network services grpcroutes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Services GrpcRoute",
                                "Network Services GrpcRoutes"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetworkServicesHttpRoutesGenericResource]: {
                        text: {
                            withServiceName: [
                                "network services httproute",
                                "network services httproutes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Services HttpRoute",
                                "Network Services HttpRoutes"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetworkServicesLbRouteExtensionsGenericResource]: {
                        text: {
                            withServiceName: [
                                "network services lbrouteextension",
                                "network services lbrouteextensions"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Services LbRouteExtension",
                                "Network Services LbRouteExtensions"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetworkServicesLbTrafficExtensionsGenericResource]: {
                        text: {
                            withServiceName: [
                                "network services lbtrafficextension",
                                "network services lbtrafficextensions"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Services LbTrafficExtension",
                                "Network Services LbTrafficExtensions"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetworkServicesMeshesGenericResource]: {
                        text: {
                            withServiceName: [
                                "network services mesh",
                                "network services meshes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Services Mesh",
                                "Network Services Meshes"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetworkServicesServiceBindingsGenericResource]: {
                        text: {
                            withServiceName: [
                                "network services servicebinding",
                                "network services servicebindings"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Services ServiceBinding",
                                "Network Services ServiceBindings"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetworkServicesServiceLbPoliciesGenericResource]: {
                        text: {
                            withServiceName: [
                                "network services servicelbpolicy",
                                "network services servicelbpolicies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Services ServiceLbPolicy",
                                "Network Services ServiceLbPolicies"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetworkServicesTcpRoutesGenericResource]: {
                        text: {
                            withServiceName: [
                                "network services tcproute",
                                "network services tcproutes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Services TcpRoute",
                                "Network Services TcpRoutes"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetworkServicesTlsRoutesGenericResource]: {
                        text: {
                            withServiceName: [
                                "network services tlsroute",
                                "network services tlsroutes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Services TlsRoute",
                                "Network Services TlsRoutes"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetworkServicesWasmPluginVersionsGenericResource]: {
                        text: {
                            withServiceName: [
                                "network services wasmpluginversion",
                                "network services wasmpluginversions"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Services WasmPluginVersion",
                                "Network Services WasmPluginVersions"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetworkServicesWasmPluginsGenericResource]: {
                        text: {
                            withServiceName: [
                                "network services wasmplugin",
                                "network services wasmplugins"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Network Services WasmPlugin",
                                "Network Services WasmPlugins"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetworkconnectivityHubRoutesGenericResource]: {
                        text: {
                            withServiceName: [
                                "networkconnectivity hubroute",
                                "networkconnectivity hubroutes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Networkconnectivity HubRoute",
                                "Networkconnectivity HubRoutes"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetworkconnectivityHubsGenericResource]: {
                        text: {
                            withServiceName: [
                                "networkconnectivity hub",
                                "networkconnectivity hubs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Networkconnectivity Hub",
                                "Networkconnectivity Hubs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetworkconnectivityPolicyBasedRoutesGenericResource]: {
                        text: {
                            withServiceName: [
                                "networkconnectivity policybasedroute",
                                "networkconnectivity policybasedroutes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Networkconnectivity PolicyBasedRoute",
                                "Networkconnectivity PolicyBasedRoutes"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetworkconnectivityRouteTablesGenericResource]: {
                        text: {
                            withServiceName: [
                                "networkconnectivity routetable",
                                "networkconnectivity routetables"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Networkconnectivity RouteTable",
                                "Networkconnectivity RouteTables"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNetworkconnectivitySpokesGenericResource]: {
                        text: {
                            withServiceName: [
                                "networkconnectivity spoke",
                                "networkconnectivity spokes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Networkconnectivity Spoke",
                                "Networkconnectivity Spokes"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpNotebooksInstancesGenericResource]: {
                        text: {
                            withServiceName: [
                                "notebooks instance",
                                "notebooks instances"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Notebooks Instance",
                                "Notebooks Instances"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpOrgPolicyCustomConstraintsGenericResource]: {
                        text: {
                            withServiceName: [
                                "org policy customconstraint",
                                "org policy customconstraints"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Org Policy CustomConstraint",
                                "Org Policy CustomConstraints"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpOrgPolicyPoliciesGenericResource]: {
                        text: {
                            withServiceName: [
                                "org policy policy",
                                "org policy policies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Org Policy Policy",
                                "Org Policy Policies"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpOsconfigOsPolicyAssignmentReportsGenericResource]: {
                        text: {
                            withServiceName: [
                                "osconfig ospolicyassignmentreport",
                                "osconfig ospolicyassignmentreports"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Osconfig OSPolicyAssignmentReport",
                                "Osconfig OSPolicyAssignmentReports"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpOsconfigOsPolicyAssignmentsGenericResource]: {
                        text: {
                            withServiceName: [
                                "osconfig ospolicyassignment",
                                "osconfig ospolicyassignments"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Osconfig OSPolicyAssignment",
                                "Osconfig OSPolicyAssignments"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpOsconfigPatchDeploymentsGenericResource]: {
                        text: {
                            withServiceName: [
                                "osconfig patchdeployment",
                                "osconfig patchdeployments"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Osconfig PatchDeployment",
                                "Osconfig PatchDeployments"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpOsconfigVulnerabilityReportsGenericResource]: {
                        text: {
                            withServiceName: [
                                "osconfig vulnerabilityreport",
                                "osconfig vulnerabilityreports"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Osconfig VulnerabilityReport",
                                "Osconfig VulnerabilityReports"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpPrivateCaCaPoolsGenericResource]: {
                        text: {
                            withServiceName: [
                                "private ca capool",
                                "private ca capools"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Private Ca CaPool",
                                "Private Ca CaPools"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpPrivateCaCertificateAuthoritiesGenericResource]: {
                        text: {
                            withServiceName: [
                                "private ca certificateauthority",
                                "private ca certificateauthorities"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Private Ca CertificateAuthority",
                                "Private Ca CertificateAuthorities"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpPrivateCaCertificateRevocationListsGenericResource]: {
                        text: {
                            withServiceName: [
                                "private ca certificaterevocationlist",
                                "private ca certificaterevocationlists"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Private Ca CertificateRevocationList",
                                "Private Ca CertificateRevocationLists"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpPrivateCaCertificateTemplatesGenericResource]: {
                        text: {
                            withServiceName: [
                                "private ca certificatetemplate",
                                "private ca certificatetemplates"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Private Ca CertificateTemplate",
                                "Private Ca CertificateTemplates"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpPrivateCaCertificatesGenericResource]: {
                        text: {
                            withServiceName: [
                                "private ca certificate",
                                "private ca certificates"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Private Ca Certificate",
                                "Private Ca Certificates"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpPrivilegedaccessmanagerGrantsGenericResource]: {
                        text: {
                            withServiceName: [
                                "privilegedaccessmanager grant",
                                "privilegedaccessmanager grants"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Privilegedaccessmanager Grant",
                                "Privilegedaccessmanager Grants"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpPubSubSchemasGenericResource]: {
                        text: {
                            withServiceName: [
                                "pub/sub schema",
                                "pub/sub schemas"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Pub/Sub Schema",
                                "Pub/Sub Schemas"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpPubSubSnapshotsGenericResource]: {
                        text: {
                            withServiceName: [
                                "pub/sub snapshot",
                                "pub/sub snapshots"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Pub/Sub Snapshot",
                                "Pub/Sub Snapshots"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpPubSubSubscriptionsGenericResource]: {
                        text: {
                            withServiceName: [
                                "pub/sub subscription",
                                "pub/sub subscriptions"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Pub/Sub Subscription",
                                "Pub/Sub Subscriptions"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpPubSubTopicsGenericResource]: {
                        text: {
                            withServiceName: [
                                "pub/sub topic",
                                "pub/sub topics"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Pub/Sub Topic",
                                "Pub/Sub Topics"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpRecaptchaEnterpriseFirewallpoliciesGenericResource]: {
                        text: {
                            withServiceName: [
                                "recaptcha enterprise firewallpolicy",
                                "recaptcha enterprise firewallpolicies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Recaptcha Enterprise FirewallPolicy",
                                "Recaptcha Enterprise FirewallPolicies"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpRecaptchaEnterpriseKeysGenericResource]: {
                        text: {
                            withServiceName: [
                                "recaptcha enterprise key",
                                "recaptcha enterprise keys"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Recaptcha Enterprise Key",
                                "Recaptcha Enterprise Keys"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpRedisClustersGenericResource]: {
                        text: {
                            withServiceName: [
                                "redis cluster",
                                "redis clusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Redis Cluster",
                                "Redis Clusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpRedisInstancesGenericResource]: {
                        text: {
                            withServiceName: [
                                "redis instance",
                                "redis instances"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Redis Instance",
                                "Redis Instances"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpResourceManagerFoldersGenericResource]: {
                        text: {
                            withServiceName: [
                                "resource manager folder",
                                "resource manager folders"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource Manager Folder",
                                "Resource Manager Folders"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpResourceManagerLiensGenericResource]: {
                        text: {
                            withServiceName: [
                                "resource manager lien",
                                "resource manager liens"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource Manager Lien",
                                "Resource Manager Liens"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpResourceManagerOrganizationsGenericResource]: {
                        text: {
                            withServiceName: [
                                "resource manager organization",
                                "resource manager organizations"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource Manager Organization",
                                "Resource Manager Organizations"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpResourceManagerProjectsGenericResource]: {
                        text: {
                            withServiceName: [
                                "resource manager project",
                                "resource manager projects"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource Manager Project",
                                "Resource Manager Projects"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpResourceManagerTagBindingsGenericResource]: {
                        text: {
                            withServiceName: [
                                "resource manager tagbinding",
                                "resource manager tagbindings"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource Manager TagBinding",
                                "Resource Manager TagBindings"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpResourceManagerTagKeysGenericResource]: {
                        text: {
                            withServiceName: [
                                "resource manager tagkey",
                                "resource manager tagkeys"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource Manager TagKey",
                                "Resource Manager TagKeys"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpResourceManagerTagValuesGenericResource]: {
                        text: {
                            withServiceName: [
                                "resource manager tagvalue",
                                "resource manager tagvalues"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Resource Manager TagValue",
                                "Resource Manager TagValues"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpRunDomainMappingsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud run domainmapping",
                                "cloud run domainmappings"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Run DomainMapping",
                                "Cloud Run DomainMappings"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpRunExecutionsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud run execution",
                                "cloud run executions"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Run Execution",
                                "Cloud Run Executions"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpRunJobsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud run job",
                                "cloud run jobs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Run Job",
                                "Cloud Run Jobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpRunRevisionsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud run revision",
                                "cloud run revisions"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Run Revision",
                                "Cloud Run Revisions"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpRunServicesGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud run service",
                                "cloud run services"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Run Service",
                                "Cloud Run Services"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpSecretManagerSecretsGenericResource]: {
                        text: {
                            withServiceName: [
                                "secret manager secret",
                                "secret manager secrets"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Secret Manager Secret",
                                "Secret Manager Secrets"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpSecretManagerVersionsGenericResource]: {
                        text: {
                            withServiceName: [
                                "secret manager secretversion",
                                "secret manager secretversions"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Secret Manager SecretVersion",
                                "Secret Manager SecretVersions"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpSecuresourcemanagerInstancesGenericResource]: {
                        text: {
                            withServiceName: [
                                "securesourcemanager instance",
                                "securesourcemanager instances"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Securesourcemanager Instance",
                                "Securesourcemanager Instances"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpSecurityposturePostureDeploymentsGenericResource]: {
                        text: {
                            withServiceName: [
                                "securityposture posturedeployment",
                                "securityposture posturedeployments"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Securityposture PostureDeployment",
                                "Securityposture PostureDeployments"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpSecurityposturePosturesGenericResource]: {
                        text: {
                            withServiceName: [
                                "securityposture posture",
                                "securityposture postures"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Securityposture Posture",
                                "Securityposture Postures"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpServiceManagementServicesGenericResource]: {
                        text: {
                            withServiceName: [
                                "service management managedservice",
                                "service management managedservices"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Service Management ManagedService",
                                "Service Management ManagedServices"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpServiceUsageServicesGenericResource]: {
                        text: {
                            withServiceName: [
                                "service usage service",
                                "service usage services"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Service Usage Service",
                                "Service Usage Services"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpServicedirectoryEndpointsGenericResource]: {
                        text: {
                            withServiceName: [
                                "servicedirectory endpoint",
                                "servicedirectory endpoints"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Servicedirectory Endpoint",
                                "Servicedirectory Endpoints"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpServicedirectoryNamespacesGenericResource]: {
                        text: {
                            withServiceName: [
                                "servicedirectory namespace",
                                "servicedirectory namespaces"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Servicedirectory Namespace",
                                "Servicedirectory Namespaces"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpServicedirectoryServicesGenericResource]: {
                        text: {
                            withServiceName: [
                                "servicedirectory service",
                                "servicedirectory services"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Servicedirectory Service",
                                "Servicedirectory Services"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpSpannerBackupsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud spanner backup",
                                "cloud spanner backups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Spanner Backup",
                                "Cloud Spanner Backups"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpSpannerDatabasesGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud spanner database",
                                "cloud spanner databases"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Spanner Database",
                                "Cloud Spanner Databases"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpSpannerInstanceConfigsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud spanner instanceconfig",
                                "cloud spanner instanceconfigs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Spanner InstanceConfig",
                                "Cloud Spanner InstanceConfigs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpSpannerInstancesGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud spanner instance",
                                "cloud spanner instances"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Spanner Instance",
                                "Cloud Spanner Instances"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpSpeakeridPhrasesGenericResource]: {
                        text: {
                            withServiceName: [
                                "speakerid phrase",
                                "speakerid phrases"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Speakerid Phrase",
                                "Speakerid Phrases"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpSpeakeridSettingsGenericResource]: {
                        text: {
                            withServiceName: [
                                "speakerid settings",
                                "speakerid settings"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Speakerid Settings",
                                "Speakerid Settings"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpSpeakeridSpeakersGenericResource]: {
                        text: {
                            withServiceName: [
                                "speakerid speaker",
                                "speakerid speakers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Speakerid Speaker",
                                "Speakerid Speakers"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpSpeechConfigsGenericResource]: {
                        text: {
                            withServiceName: [
                                "speech config",
                                "speech configs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Speech Config",
                                "Speech Configs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpSpeechCustomClassesGenericResource]: {
                        text: {
                            withServiceName: [
                                "speech customclass",
                                "speech customclasses"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Speech CustomClass",
                                "Speech CustomClasses"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpSpeechPhraseSetsGenericResource]: {
                        text: {
                            withServiceName: [
                                "speech phraseset",
                                "speech phrasesets"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Speech PhraseSet",
                                "Speech PhraseSets"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpSpeechRecognizersGenericResource]: {
                        text: {
                            withServiceName: [
                                "speech recognizer",
                                "speech recognizers"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Speech Recognizer",
                                "Speech Recognizers"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpStorageBucketsGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud storage bucket",
                                "cloud storage buckets"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud Storage Bucket",
                                "Cloud Storage Buckets"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpStorageinsightsReportConfigsGenericResource]: {
                        text: {
                            withServiceName: [
                                "storageinsights reportconfig",
                                "storageinsights reportconfigs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Storageinsights ReportConfig",
                                "Storageinsights ReportConfigs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpStorageinsightsReportDetailsGenericResource]: {
                        text: {
                            withServiceName: [
                                "storageinsights reportdetail",
                                "storageinsights reportdetails"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Storageinsights ReportDetail",
                                "Storageinsights ReportDetails"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpTpuNodesGenericResource]: {
                        text: {
                            withServiceName: [
                                "cloud tpu node",
                                "cloud tpu nodes"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Cloud TPU Node",
                                "Cloud TPU Nodes"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpTranscoderJobTemplatesGenericResource]: {
                        text: {
                            withServiceName: [
                                "transcoder jobtemplate",
                                "transcoder jobtemplates"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Transcoder JobTemplate",
                                "Transcoder JobTemplates"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpTranscoderJobsGenericResource]: {
                        text: {
                            withServiceName: [
                                "transcoder job",
                                "transcoder jobs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Transcoder Job",
                                "Transcoder Jobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpVideoStitcherCdnKeysGenericResource]: {
                        text: {
                            withServiceName: [
                                "video stitcher cdnkey",
                                "video stitcher cdnkeys"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Video Stitcher CdnKey",
                                "Video Stitcher CdnKeys"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpVideoStitcherLiveConfigsGenericResource]: {
                        text: {
                            withServiceName: [
                                "video stitcher liveconfig",
                                "video stitcher liveconfigs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Video Stitcher LiveConfig",
                                "Video Stitcher LiveConfigs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpVideoStitcherSlatesGenericResource]: {
                        text: {
                            withServiceName: [
                                "video stitcher slate",
                                "video stitcher slates"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Video Stitcher Slate",
                                "Video Stitcher Slates"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpVideoStitcherVodConfigsGenericResource]: {
                        text: {
                            withServiceName: [
                                "video stitcher vodconfig",
                                "video stitcher vodconfigs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Video Stitcher VodConfig",
                                "Video Stitcher VodConfigs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpVmMigrationCloneJobsGenericResource]: {
                        text: {
                            withServiceName: [
                                "vm migration clonejob",
                                "vm migration clonejobs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vm Migration CloneJob",
                                "Vm Migration CloneJobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpVmMigrationCutoverJobsGenericResource]: {
                        text: {
                            withServiceName: [
                                "vm migration cutoverjob",
                                "vm migration cutoverjobs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vm Migration CutoverJob",
                                "Vm Migration CutoverJobs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpVmMigrationDatacenterConnectorsGenericResource]: {
                        text: {
                            withServiceName: [
                                "vm migration datacenterconnector",
                                "vm migration datacenterconnectors"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vm Migration DatacenterConnector",
                                "Vm Migration DatacenterConnectors"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpVmMigrationGroupsGenericResource]: {
                        text: {
                            withServiceName: [
                                "vm migration group",
                                "vm migration groups"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vm Migration Group",
                                "Vm Migration Groups"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpVmMigrationMigratingVmsGenericResource]: {
                        text: {
                            withServiceName: [
                                "vm migration migratingvm",
                                "vm migration migratingvms"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vm Migration MigratingVm",
                                "Vm Migration MigratingVms"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpVmMigrationSourcesGenericResource]: {
                        text: {
                            withServiceName: [
                                "vm migration source",
                                "vm migration sources"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vm Migration Source",
                                "Vm Migration Sources"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpVmMigrationTargetProjectsGenericResource]: {
                        text: {
                            withServiceName: [
                                "vm migration targetproject",
                                "vm migration targetprojects"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vm Migration TargetProject",
                                "Vm Migration TargetProjects"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpVmMigrationUtilizationReportsGenericResource]: {
                        text: {
                            withServiceName: [
                                "vm migration utilizationreport",
                                "vm migration utilizationreports"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vm Migration UtilizationReport",
                                "Vm Migration UtilizationReports"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpVmwareengineClustersGenericResource]: {
                        text: {
                            withServiceName: [
                                "vmwareengine cluster",
                                "vmwareengine clusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vmwareengine Cluster",
                                "Vmwareengine Clusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpVmwareengineExternalAccessRulesGenericResource]: {
                        text: {
                            withServiceName: [
                                "vmwareengine externalaccessrule",
                                "vmwareengine externalaccessrules"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vmwareengine ExternalAccessRule",
                                "Vmwareengine ExternalAccessRules"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpVmwareengineExternalAddressesGenericResource]: {
                        text: {
                            withServiceName: [
                                "vmwareengine externaladdress",
                                "vmwareengine externaladdresses"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vmwareengine ExternalAddress",
                                "Vmwareengine ExternalAddresses"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpVmwareengineNetworkPeeringsGenericResource]: {
                        text: {
                            withServiceName: [
                                "vmwareengine networkpeering",
                                "vmwareengine networkpeerings"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vmwareengine NetworkPeering",
                                "Vmwareengine NetworkPeerings"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpVmwareengineNetworkPoliciesGenericResource]: {
                        text: {
                            withServiceName: [
                                "vmwareengine networkpolicy",
                                "vmwareengine networkpolicies"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vmwareengine NetworkPolicy",
                                "Vmwareengine NetworkPolicies"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpVmwareenginePrivateCloudsGenericResource]: {
                        text: {
                            withServiceName: [
                                "vmwareengine privatecloud",
                                "vmwareengine privateclouds"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vmwareengine PrivateCloud",
                                "Vmwareengine PrivateClouds"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpVmwareenginePrivateConnectionsGenericResource]: {
                        text: {
                            withServiceName: [
                                "vmwareengine privateconnection",
                                "vmwareengine privateconnections"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vmwareengine PrivateConnection",
                                "Vmwareengine PrivateConnections"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpVmwareengineVmwareEngineNetworksGenericResource]: {
                        text: {
                            withServiceName: [
                                "vmwareengine vmwareenginenetwork",
                                "vmwareengine vmwareenginenetworks"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vmwareengine VmwareEngineNetwork",
                                "Vmwareengine VmwareEngineNetworks"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpVpcAccessConnectorsGenericResource]: {
                        text: {
                            withServiceName: [
                                "vpc access connector",
                                "vpc access connectors"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Vpc Access Connector",
                                "Vpc Access Connectors"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpWorkflowsWorkflowsGenericResource]: {
                        text: {
                            withServiceName: [
                                "workflows workflow",
                                "workflows workflows"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Workflows Workflow",
                                "Workflows Workflows"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpWorkstationsWorkstationClustersGenericResource]: {
                        text: {
                            withServiceName: [
                                "workstations workstationcluster",
                                "workstations workstationclusters"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Workstations WorkstationCluster",
                                "Workstations WorkstationClusters"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpWorkstationsWorkstationConfigsGenericResource]: {
                        text: {
                            withServiceName: [
                                "workstations workstationconfig",
                                "workstations workstationconfigs"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Workstations WorkstationConfig",
                                "Workstations WorkstationConfigs"
                            ]
                        }
                    },
                    [Contract.TypeNames.GcpWorkstationsWorkstationsGenericResource]: {
                        text: {
                            withServiceName: [
                                "workstations workstation",
                                "workstations workstations"
                            ]
                        },
                        title: {
                            withServiceName: [
                                "Workstations Workstation",
                                "Workstations Workstations"
                            ]
                        }
                    }
                }
        }
        ));
}