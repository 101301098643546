import { Loading, useRoute, useSetRoute } from "@infrastructure";
import { Box, Grid2, Stack, Tab, Tabs } from "@mui/material";
import _ from "lodash";
import React, { Fragment, RefObject, useMemo } from "react";
import { Contract, CustomerConsoleAppUrlHelperCategoryView, useTheme } from "../../../../../../../../../../common";
import { RiskDefinitionSection, RiskDefinitionSectionCategory, RiskDefinitionSectionGroup } from "../../../../../../hooks/useDefinition/utilities";
import { RiskView } from "../../../../../../utilities";
import { ElementClass } from "./Category.element";
import { Section } from "./components/Section";

type CategoryProps = {
    category: RiskDefinitionSectionCategory;
    containerRef?: RefObject<HTMLDivElement>;
    getUrl: (riskId: string, categoryView?: CustomerConsoleAppUrlHelperCategoryView<RiskDefinitionSectionCategory, RiskView>) => string;
    riskModel: Contract.RiskModel;
    tabs: CategoryTab[];
};

export class CategoryTab {
    constructor(
        public category: RiskDefinitionSectionCategory,
        public sections: (RiskDefinitionSection | RiskDefinitionSectionGroup)[],
        public title: string,
        public view: RiskView) {
    }
}

export function Category({ category, containerRef, getUrl, riskModel, tabs }: CategoryProps) {
    const { view } = useRoute(`${getUrl(riskModel.id)}/${category}/{view}`);
    const setRoute = useSetRoute();

    const [views, viewToTabMap] =
        useMemo(
            () => {
                const views =
                    _.map(
                        tabs,
                        tab => tab.view);

                const viewToTabMap =
                    _.keyBy(
                        tabs,
                        tab => tab.view);

                return [views, viewToTabMap];
            },
            [tabs]);

    const currentView =
        useMemo(
            () =>
                _.includes(views, view)
                    ? view
                    : _.first(views)!,
            [views, view]);

    const theme = useTheme();
    return (
        <Stack
            className={ElementClass.categoryContainer}
            sx={{ height: "100%" }}>
            {_.size(viewToTabMap) > 1 &&
                <Tabs
                    indicatorColor="primary"
                    sx={{
                        border: "unset",
                        padding: theme.spacing(0, 2.5)
                    }}
                    value={currentView}
                    variant="scrollable"
                    onChange={(_event, view) => setRoute(getUrl(riskModel.id, { category, view }))}>
                    {_.map(
                        views,
                        view =>
                            <Tab
                                key={view}
                                label={viewToTabMap[view].title}
                                sx={{
                                    marginRight: theme.spacing(3),
                                    padding: 0
                                }}
                                value={view}/>)}
                </Tabs>}
            <Stack
                ref={containerRef}
                sx={{
                    height: "100%",
                    overflow: "hidden auto",
                    padding: theme.spacing(0, 2),
                    width: "100%"
                }}>
                {!_.isNil(viewToTabMap[currentView]) &&
                    <Loading>
                        <Fragment>
                            {_.map(
                                viewToTabMap[currentView].sections,
                                (section, sectionIndex) => {
                                    if (section instanceof RiskDefinitionSection) {
                                        return (
                                            <Box
                                                key={`${riskModel.id}-${category}-${sectionIndex}`}
                                                sx={{
                                                    position: "relative",
                                                    width: "100%"
                                                }}>
                                                <Section
                                                    riskId={riskModel.id}
                                                    section={section}/>
                                            </Box>);
                                    } else {
                                        const visibleSections =
                                            _.filter(
                                                section.sections,
                                                section => riskModel.risk.status !== Contract.RiskStatus.Closed || section.options?.resolvedRiskVisible === true);
                                        return (
                                            <Box
                                                key={`${riskModel.id}-${category}-${sectionIndex}`}
                                                sx={{
                                                    position: "relative",
                                                    width: "100%"
                                                }}>
                                                {_.map(
                                                    visibleSections,
                                                    (visibleChildSection, visibleChildSectionIndex) =>
                                                        <Grid2
                                                            key={visibleChildSectionIndex}
                                                            size={{
                                                                xs:
                                                                    visibleSections.length > 12
                                                                        ? 1
                                                                        : (12 / visibleSections.length as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12)
                                                            }}
                                                            sx={{ position: "relative" }}>
                                                            <Section
                                                                riskId={riskModel.id}
                                                                section={visibleChildSection}
                                                                sx={{ height: "100%" }}/>
                                                        </Grid2>)}
                                            </Box>);
                                    }
                                })}
                        </Fragment>
                    </Loading>}
            </Stack>
        </Stack>);
}