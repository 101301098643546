import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ImplyIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58343_2658)">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0V0C18.6274 0 24 5.37258 24 12V12C24 18.6274 18.6274 24 12 24V24C5.37258 24 0 18.6274 0 12V12Z"
                    fill="#0A7EE0"/>
                <path
                    d="M13.305 5.29504L13.365 5.31004L13.515 5.59504L13.71 5.98504L13.86 6.25504L14.1 6.66004L14.43 7.15504L14.625 7.42504L14.82 7.68004L14.985 7.89004L15.12 8.04004L15.225 8.16004L15.36 8.31004L15.57 8.53504L15.93 8.89504L15.96 8.91004V8.94004L16.02 8.97004L16.155 9.10504L16.32 9.24004L16.53 9.42004L16.74 9.58504L16.995 9.78004L17.25 9.96004L17.61 10.2L17.91 10.38L18.3 10.605L18.735 10.83L19.02 10.965L19.59 11.205L19.62 11.235V12.75L19.575 12.795L18.975 13.065L18.705 13.185L18.42 13.335L18.06 13.53L17.76 13.71L17.43 13.92L17.19 14.085L16.92 14.28L16.65 14.49L16.395 14.7L16.185 14.88L15.825 15.21L15.495 15.54L15.48 15.57H15.45L15.42 15.63L15.27 15.78L15.165 15.9L15.03 16.05L14.835 16.305L14.685 16.5L14.505 16.74L14.31 17.01L14.16 17.25L13.965 17.565L13.74 17.97L13.53 18.375L13.41 18.615L13.35 18.72L13.275 18.705L11.985 18.21L11.685 18.09L11.625 18.045L11.64 17.94L11.82 17.505L11.955 17.205L12.075 16.965L12.27 16.59L12.51 16.185L12.69 15.9L12.855 15.66L13.005 15.45L13.2 15.195L13.305 15.06L13.455 14.88L13.485 14.82L5.31003 14.835L5.28003 14.82V13.08L5.29503 13.065L5.70003 13.05H15.27L15.405 13.035L15.645 12.885L15.81 12.765L16.095 12.585L16.32 12.45L16.665 12.255L17.07 12.045L17.145 12.015L17.16 11.985L17.07 11.955L16.65 11.745L16.305 11.55L16.005 11.37L15.75 11.205L15.51 11.04L15.405 10.98L5.29503 10.965L5.28003 10.95V9.22504L5.34003 9.19504H13.5L13.335 8.98504L13.14 8.74504L12.93 8.46004L12.66 8.05504L12.48 7.77004L12.33 7.51504L12.135 7.15504L12 6.90004L11.835 6.54004L11.685 6.18004L11.625 6.01504L11.655 5.94004L12 5.79004L12.66 5.53504L13.26 5.31004L13.305 5.29504Z"
                    fill="#FDFDFD"/>
            </g>
            <defs>
                <clipPath id="clip0_58343_2658">
                    <rect
                        fill="white"
                        height="24"
                        width="23.9962"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}