import { UnexpectedError } from "@infrastructure";
import { Contract } from "../../../../../../../../../common";

export function useGetCustomerLicenseObjectTenantType() {
    return (objectType: Contract.CustomerLicenseObjectType) => {
        switch (objectType) {
            case Contract.CustomerLicenseObjectType.AwsDynamoDbAnalyzedTable:
            case Contract.CustomerLicenseObjectType.AwsDynamoDbTable:
            case Contract.CustomerLicenseObjectType.AwsEc2Image:
            case Contract.CustomerLicenseObjectType.AwsEc2Instance:
            case Contract.CustomerLicenseObjectType.AwsEcrRepository:
            case Contract.CustomerLicenseObjectType.AwsEcsClusterInstance:
            case Contract.CustomerLicenseObjectType.AwsEcsFargateService:
            case Contract.CustomerLicenseObjectType.AwsEcsService:
            case Contract.CustomerLicenseObjectType.AwsEksCluster:
            case Contract.CustomerLicenseObjectType.AwsEksClusterInstance:
            case Contract.CustomerLicenseObjectType.AwsEsDomain:
            case Contract.CustomerLicenseObjectType.AwsUnmanagedKubernetesClusterNode:
            case Contract.CustomerLicenseObjectType.AwsLambdaFunctionConfiguration:
            case Contract.CustomerLicenseObjectType.AwsRdsAnalyzedCluster:
            case Contract.CustomerLicenseObjectType.AwsRdsDatabaseInstance:
            case Contract.CustomerLicenseObjectType.AwsRdsDatabaseAnalyzedInstance:
            case Contract.CustomerLicenseObjectType.AwsRedshiftCluster:
            case Contract.CustomerLicenseObjectType.AwsS3AnalyzedBucket:
            case Contract.CustomerLicenseObjectType.AwsS3Bucket:
                return Contract.TenantType.Aws;
            case Contract.CustomerLicenseObjectType.AzureClassicComputeVirtualMachineGenericResource:
            case Contract.CustomerLicenseObjectType.AzureClassicStorageStorageAccountGenericResource:
            case Contract.CustomerLicenseObjectType.AzureComputeVirtualMachine:
            case Contract.CustomerLicenseObjectType.AzureComputeVirtualMachineScaleSet:
            case Contract.CustomerLicenseObjectType.AzureComputeVirtualMachineScaleSetVirtualMachine:
            case Contract.CustomerLicenseObjectType.AzureContainerInstanceContainerGroup:
            case Contract.CustomerLicenseObjectType.AzureContainerInstanceContainerGroupGenericResource:
            case Contract.CustomerLicenseObjectType.AzureContainerRegistryRepository:
            case Contract.CustomerLicenseObjectType.AzureContainerServiceManagedCluster:
            case Contract.CustomerLicenseObjectType.AzureContainerServiceManagedClusterVirtualMachineScaleSetVirtualMachine:
            case Contract.CustomerLicenseObjectType.AzureDbForMariaDbServerGenericResource:
            case Contract.CustomerLicenseObjectType.AzureDocumentDbCassandraClusterGenericResource:
            case Contract.CustomerLicenseObjectType.AzureDocumentDbDatabaseAccount:
            case Contract.CustomerLicenseObjectType.AzureUnmanagedKubernetesClusterNode:
            case Contract.CustomerLicenseObjectType.AzureMySqlFlexibleServer:
            case Contract.CustomerLicenseObjectType.AzureMySqlSingleServer:
            case Contract.CustomerLicenseObjectType.AzurePostgreSqlFlexibleServer:
            case Contract.CustomerLicenseObjectType.AzurePostgreSqlSingleServer:
            case Contract.CustomerLicenseObjectType.AzureSqlAnalyzedServer:
            case Contract.CustomerLicenseObjectType.AzureSqlServer:
            case Contract.CustomerLicenseObjectType.AzureStorageAnalyzedStorageAccountBlobContainer:
            case Contract.CustomerLicenseObjectType.AzureStorageStorageAccount:
            case Contract.CustomerLicenseObjectType.AzureSynapseWorkspaceGenericResource:
            case Contract.CustomerLicenseObjectType.AzureWebApplication:
                return Contract.TenantType.Azure;
            case Contract.CustomerLicenseObjectType.CiContainerImageRepository:
                return Contract.TenantType.Ci;
            case Contract.CustomerLicenseObjectType.CodeContainerImageRepository:
                return Contract.TenantType.Code;
            case Contract.CustomerLicenseObjectType.GcpArtifactContainerImageRepository:
            case Contract.CustomerLicenseObjectType.GcpBigQueryAnalyzedDataset:
            case Contract.CustomerLicenseObjectType.GcpBigQueryDataset:
            case Contract.CustomerLicenseObjectType.GcpBigtableInstancesGenericResource:
            case Contract.CustomerLicenseObjectType.GcpCloudRunService:
            case Contract.CustomerLicenseObjectType.GcpComputeInstance:
            case Contract.CustomerLicenseObjectType.GcpContainerAutopilotCluster:
            case Contract.CustomerLicenseObjectType.GcpContainerClusterInstance:
            case Contract.CustomerLicenseObjectType.GcpContainerNodePoolsGenericResource:
            case Contract.CustomerLicenseObjectType.GcpFileInstancesGenericResource:
            case Contract.CustomerLicenseObjectType.GcpFunctionsFunction:
            case Contract.CustomerLicenseObjectType.GcpUnmanagedKubernetesClusterNode:
            case Contract.CustomerLicenseObjectType.GcpSpannerInstance:
            case Contract.CustomerLicenseObjectType.GcpSqlAnalyzedInstance:
            case Contract.CustomerLicenseObjectType.GcpSqlInstance:
            case Contract.CustomerLicenseObjectType.GcpStorageAnalyzedBucket:
            case Contract.CustomerLicenseObjectType.GcpStorageBucket:
                return Contract.TenantType.Gcp;
            case Contract.CustomerLicenseObjectType.OciComputeInstance:
            case Contract.CustomerLicenseObjectType.OciContainerEngineClusterInstance:
            case Contract.CustomerLicenseObjectType.OciFunctionsFunction:
                return Contract.TenantType.Oci;
            case Contract.CustomerLicenseObjectType.OpUnmanagedKubernetesClusterNode:
                return Contract.TenantType.Op;
            default:
                throw new UnexpectedError("objectType", objectType);
        }
    };
}