import { EmptyMessageText, MultiSelect, SearchListCategory, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";

export type AwsRegionSystemNamesSelectorProps = {
    disabled: boolean;
    label: string;
    onSelectedRegionSystemNamesChanged: (selectedRegionSystemNames: string[]) => void;
    regionSystemNames: string[];
    selectedRegionSystemNames: string[];
};

export function AwsRegionSystemNamesSelector({ disabled, label, onSelectedRegionSystemNamesChanged, regionSystemNames, selectedRegionSystemNames }: AwsRegionSystemNamesSelectorProps) {

    function getRegionGeography(regionSystemName: string) {
        return _(regionSystemName).
            split("-").
            first();
    }

    const geographyDatas =
        useMemo(
            () =>
                _(regionSystemNames).
                    map(
                        regionSystemName => ({
                            geography: getRegionGeography(regionSystemName),
                            regionSystemName
                        })).
                    groupBy(geographyData => geographyData.geography).
                    map(
                        (geographyDatas, geography) => ({
                            geography,
                            regionSystemNames:
                                _(geographyDatas).
                                    map(geographyData => geographyData.regionSystemName).
                                    orderBy().
                                    value()
                        })).
                    orderBy(
                        [
                            geographyAndRegions => geographyAndRegions.geography === "us",
                            geographyAndRegions => geographyAndRegions.geography
                        ],
                        [
                            "desc",
                            "asc"
                        ]).
                    value(),
            [regionSystemNames]);

    const localization =
        useLocalization(
            "tenants.aws.awsRegionSystemNamesSelector",
            () => ({
                empty: "No matching regions",
                selection: {
                    all: "All (Existing and future regions)",
                    some: [
                        "1 Region",
                        "{{count | NumberFormatter.humanize}} Regions"
                    ]
                }
            }));

    return (
        <MultiSelect
            categories={
                _.map(
                    geographyDatas,
                    geographyData =>
                        new SearchListCategory(
                            geographyData.geography,
                            regionSystemName => getRegionGeography(regionSystemName) === geographyData.geography))}
            disabled={disabled}
            emptyMessageText={new EmptyMessageText(localization.empty())}
            fieldOptions={{ variant: "itemSelector" }}
            fullWidth={true}
            getItemText={item => item}
            items={
                _.flatMap(
                    geographyDatas,
                    geographyData => geographyData.regionSystemNames)}
            placeholder={label}
            selectedItems={selectedRegionSystemNames}
            selectedItemsTranslator={
                selectedItems =>
                    _.size(selectedItems) == _.size(regionSystemNames)
                        ? localization.selection.all()
                        : localization.selection.some(_.size(selectedItems))}
            sorted={false}
            onSelectedItemsChanged={onSelectedRegionSystemNamesChanged}/>);
}