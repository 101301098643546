import { Action1, makeContextProvider, UnexpectedError, useChangeEffect } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, entityModelStore, TypeHelper } from "../../../..";
import { EntityProfileInfo } from "../../../../../views/Customer/components/Entities/components/Profile/components";
import { useRiskResolutionAutomationContext, useSetRiskResolutionAutomationContext } from "../../utilities";
import { AwsDetachPrincipalManagedPolicyChangeInfo, AwsDisableUserAccessKeyChangeInfo, AwsUpsertPrincipalPolicyChangeInfo, AwsUpsertResourcePolicyChangeInfo, AzureAuthorizationRoleAssignmentResourceChangeInfo, GcpDeleteScopeResourcePrincipalRoleBindingsChangeInfo, GcpDisableServiceAccountChangeChangeInfo, GcpUpdateRoleBindingChangeInfo } from "./components";

type ChangeInfoProps = {
    change: Contract.Change;
};

export class ChangeInfoContext {
    constructor(
        public change: Contract.Change,
        public updateChange: Action1<Action1<Contract.Change>>,
        public updatedChange: Contract.Change) {
    }
}

export const [useChangeInfoContext, useSetChangeInfoContext, useChangeInfoContextProvider] = makeContextProvider<ChangeInfoContext>();

export function ChangeInfo({ change }: ChangeInfoProps) {
    const { updatedChangeMap } = useRiskResolutionAutomationContext();
    const setAutomationContext = useSetRiskResolutionAutomationContext();
    const [{ updatedChange }, setContext, ContextProvider] =
        useChangeInfoContextProvider(
            () =>
                new ChangeInfoContext(
                    change,
                    (updateChange: Action1<Contract.Change>) => {
                        const updatedChange = _.cloneDeep(change);
                        updateChange(updatedChange);

                        setAutomationContext(
                            automationContext => ({
                                ...automationContext,
                                updatedChangeMap: {
                                    ...updatedChangeMap,
                                    [updatedChange.id]: updatedChange
                                }
                            }));
                    },
                    updatedChangeMap[change.id] ?? change));
    useChangeEffect(
        () => {
            setContext(
                context => ({
                    ...context,
                    updatedChange: updatedChangeMap[change.id]
                }));
        },
        [updatedChangeMap]);

    return (
        <ContextProvider>
            {(() => {
                switch (change.typeName) {
                    case Contract.TypeNames.AwsDeleteSecurityGroupReferencingSecurityGroupRulesChange:
                        return (
                            <EntityProfileInfo
                                entityId={(change as Contract.AwsDeleteSecurityGroupReferencingSecurityGroupRulesChange).resourceId}
                                entityInfoData={(change as Contract.AwsDeleteSecurityGroupReferencingSecurityGroupRulesChange).referencedSecurityGroupId}/>);
                    case Contract.TypeNames.AwsDetachPermissionSetManagedPolicyChange:
                        return <EntityProfileInfo entityId={(change as Contract.AwsDetachPermissionSetManagedPolicyChange).managedPolicyId}/>;
                    case Contract.TypeNames.AwsDetachPrincipalManagedPolicyChange:
                        return <AwsDetachPrincipalManagedPolicyChangeInfo/>;
                    case Contract.TypeNames.AwsDisableUserAccessKeyChange:
                        return <AwsDisableUserAccessKeyChangeInfo/>;
                    case Contract.TypeNames.AwsUpsertBucketPolicyChange:
                    case Contract.TypeNames.AwsUpsertRepositoryPolicyChange:
                    case Contract.TypeNames.AwsUpsertSecretPolicyChange:
                        return (
                            <AwsUpsertResourcePolicyChangeInfo
                                updatedRawDocument={(updatedChange as Contract.AwsUpsertResourcePolicyChange).rawDocument}
                                updateRawDocument={rawDocument => (updatedChange as Contract.AwsUpsertResourcePolicyChange).rawDocument = rawDocument}/>);
                    case Contract.TypeNames.AwsUpsertPermissionSetInlinePolicyChange:
                        return (
                            <AwsUpsertPrincipalPolicyChangeInfo
                                type="inline"
                                updatedRawDocument={(updatedChange as Contract.AwsUpsertPermissionSetInlinePolicyChange).rawDocument}
                                updateRawDocument={rawDocument => (updatedChange as Contract.AwsUpsertPermissionSetInlinePolicyChange).rawDocument = rawDocument}/>);
                    case Contract.TypeNames.AwsUpsertPrincipalManagedPolicyChange:
                        return (
                            <AwsUpsertPrincipalPolicyChangeInfo
                                title={(change as Contract.AwsUpsertPrincipalManagedPolicyChange).name}
                                type="managed"
                                updatedRawDocument={(updatedChange as Contract.AwsUpsertPrincipalManagedPolicyChange).rawDocument}
                                updateRawDocument={rawDocument => (updatedChange as Contract.AwsUpsertPrincipalManagedPolicyChange).rawDocument = rawDocument}/>);
                    case Contract.TypeNames.AzureDeleteRoleAssignmentChange:
                    case Contract.TypeNames.AzureDeleteRoleAssignmentScheduleChange:
                    case Contract.TypeNames.AzureDeleteRoleEligibilityScheduleChange: {
                        const deleteRoleAssignmentResourceChange = change as Contract.AzureDeleteRoleAssignmentResourceChange;
                        const deleteRoleAssignmentResourceChangeResourceModel = entityModelStore.useGet(deleteRoleAssignmentResourceChange.resourceId) as Contract.AzureAuthorizationRoleAssignmentModel;
                        const deleteRoleAssignmentResourceChangeResourceEntity = deleteRoleAssignmentResourceChangeResourceModel.entity as Contract.AzureAuthorizationRoleAssignmentResource;
                        return <AzureAuthorizationRoleAssignmentResourceChangeInfo entityId={deleteRoleAssignmentResourceChangeResourceEntity.roleDefinitionId}/>;
                    }
                    case Contract.TypeNames.GcpCreateRoleBindingChange: {
                        const createRoleBindingChange = change as Contract.GcpCreateRoleBindingChange;
                        return <GcpUpdateRoleBindingChangeInfo entityId={createRoleBindingChange.resourceId}/>;
                    }
                    case Contract.TypeNames.GcpDeleteRoleBindingChange: {
                        const deleteRoleBindingChange = change as Contract.GcpDeleteRoleBindingChange;
                        const roleBinding = entityModelStore.useGet(deleteRoleBindingChange.resourceId).entity as Contract.GcpIamRoleBinding;
                        return <GcpUpdateRoleBindingChangeInfo entityId={roleBinding.roleId}/>;
                    }
                    case Contract.TypeNames.GcpDeleteScopeResourcePrincipalRoleBindingsChange: {
                        const deleteScopeResourcePrincipalRoleBindingsChange = change as Contract.GcpDeleteScopeResourcePrincipalRoleBindingsChange;
                        const scopeResourceModel = entityModelStore.useGet(deleteScopeResourcePrincipalRoleBindingsChange.resourceId);
                        const principalModel = entityModelStore.useGet(deleteScopeResourcePrincipalRoleBindingsChange.principalId);
                        return (
                            <GcpDeleteScopeResourcePrincipalRoleBindingsChangeInfo
                                principalModel={principalModel}
                                scopeResourceModel={scopeResourceModel}/>);
                    }
                    case Contract.TypeNames.GcpDisableServiceAccountChange:
                        return <GcpDisableServiceAccountChangeChangeInfo/>;
                    default:
                        if (TypeHelper.extendOrImplement(change.typeName, Contract.TypeNames.AwsResourceChange)) {
                            return <EntityProfileInfo entityId={(change as Contract.AwsResourceChange).resourceId}/>;
                        } else if (TypeHelper.extendOrImplement(change.typeName, Contract.TypeNames.AzureResourceChange)) {
                            return <EntityProfileInfo entityId={(change as Contract.AzureResourceChange).resourceId}/>;
                        } else if (TypeHelper.extendOrImplement(change.typeName, Contract.TypeNames.GcpResourceChange)) {
                            return <EntityProfileInfo entityId={(change as Contract.GcpResourceChange).resourceId}/>;
                        }

                        throw new UnexpectedError("change.typeName", change.typeName);
                }
            })()}
        </ContextProvider>);
}