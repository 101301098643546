import { Action0, ActionMenuItem, EditIcon, setUrlRoute, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { BuiltInRiskPolicyItem, Contract, CustomerConsoleAppUrlHelper, RiskPoliciesType, RisksIcon, ScopeHelper, scopeNodeModelStore, tenantModelStore } from "../../../../../../../../../../../common";
import { useRiskPoliciesTypeData } from "../../../../../../../hooks";

export function useCommonDefinition(item: BuiltInRiskPolicyItem, onClick: Action0, riskPoliciesType: RiskPoliciesType, scopeId: string) {
    const scopeNodeModel = scopeNodeModelStore.useGet(scopeId);
    const { tenantTypes: riskPoliciesTenantTypes } = useRiskPoliciesTypeData(riskPoliciesType);
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap(riskPoliciesTenantTypes);
    const scopeNode = scopeNodeMap[scopeNodeModel.configuration.id];

    const filteredActiveTenantModels = tenantModelStore.useGetFilteredActiveTenants();
    const tenantIds =
        useMemo(
            () => {
                const scopeTenantType = ScopeHelper.getTenantType(scopeNodeModel);
                if (_.isNil(scopeTenantType)) {
                    return undefined;
                }

                return _(filteredActiveTenantModels).
                    filter(filteredActiveTenantModel => filteredActiveTenantModel.tenantType === scopeTenantType).
                    map(filteredActiveTenantModel => filteredActiveTenantModel.configuration.id).
                    intersection(scopeNode.tenantIds).
                    value();
            },
            [filteredActiveTenantModels, scopeNodeModel]);

    const localization =
        useLocalization(
            "views.customer.riskPolicies.actionsCell.builtInActionsCell.hooks.useDefinition.hooks.useCommonDefinition",
            () => ({
                risks: "View findings",
                settings: "Edit"
            }));

    return useMemo(
        () => ({
            actionMenuItems:
                _<ActionMenuItem>([]).
                    concatIf(
                        item.result.riskCount > 0,
                        new ActionMenuItem(
                            () =>
                                setUrlRoute(
                                    CustomerConsoleAppUrlHelper.getRisksRelativeUrl(
                                        Contract.RisksView.Open,
                                        {
                                            policyConfigurationTypeNameOrIds: [item.riskPolicyConfigurationTypeNameOrId],
                                            tenantIds
                                        })),
                            localization.risks(),
                            {
                                icon: <RisksIcon/>
                            })).
                    concat(
                        new ActionMenuItem(
                            onClick,
                            localization.settings(),
                            {
                                icon: <EditIcon/>
                            })).
                    value()
        }),
        [item, onClick, setUrlRoute]);
}