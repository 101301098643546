import React, { FunctionComponentElement, useEffect, useState } from "react";
import { DataTableActions, DataTableColumnProps } from ".";
import { useActions } from "../hooks";
import { Action1 } from "../types";
import { DataTableColumnMultiSelect as InfrastructureDataTableColumnMultiSelect } from "./DataTable/components/DataTableColumnMultiSelect";

type DataTableColumnMultiSelectProps = {
    actionsRef: React.MutableRefObject<DataTableColumnMultiSelectActions | undefined>;
    dataTableActionsRef: React.MutableRefObject<DataTableActions | undefined>;
};

export type DataTableColumnMultiSelectActions = {
    reset: () => void;
    setSelectedColumnIds: Action1<string[]>;
};

export function DataTableColumnMultiSelect({ actionsRef, dataTableActionsRef }: DataTableColumnMultiSelectProps) {
    const [columns, setColumns] = useState<FunctionComponentElement<DataTableColumnProps>[]>([]);
    const [selectedColumnIds, setSelectedColumnIds] = useState<string[]>([]);

    const actions =
        useActions<DataTableColumnMultiSelectActions>(
            actionsRef,
            {
                reset() {
                    setColumns(dataTableActionsRef.current!.getSelectorEnabledVisibleColumnElements());
                    setSelectedColumnIds(dataTableActionsRef.current!.getSelectorVisibleColumnIds());
                    dataTableActionsRef.current!.setSelectedVisibleColumnIds(dataTableActionsRef.current!.getSelectorVisibleColumnIds());
                },
                setSelectedColumnIds(ids: string[]) {
                    setSelectedColumnIds(ids);
                }
            });

    useEffect(
        () => {
            actions.reset();
        },
        []);

    return (
        <InfrastructureDataTableColumnMultiSelect
            columns={columns}
            selectedIds={selectedColumnIds}
            onSelectedIdsChanged={
                selectedIds => {
                    setSelectedColumnIds(selectedIds);
                    dataTableActionsRef.current!.setSelectedVisibleColumnIds(selectedIds);
                }}/>);
}