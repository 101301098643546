import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAwsCommonKubernetesNamespaceResourceInfoItemElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, entityModelStore, InfoItem, useKubernetesServiceTypeTranslator } from "../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../components";
import { KubernetesServicePortsInfoCard, KubernetesYamlInfoCard } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useAwsKubernetesServiceDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonKubernetesNamespaceResourceInfoItemElements = useAwsCommonKubernetesNamespaceResourceInfoItemElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const serviceModel = resourceModel as Contract.AwsKubernetesServiceModel;
    const ingressModels = entityModelStore.useGet(serviceModel.ingressIds) as Contract.AwsKubernetesIngressModel[];
    const service = resourceModel.entity as Contract.AwsKubernetesService;
    const loadBalancerModels =
        entityModelStore.useGet(
            _(ingressModels).
                map(ingressModel => ingressModel.loadBalancerSearchableReference?.id).
                filter().
                as<string>().
                uniq().
                value());

    const kubernetesServiceTypeTranslator = useKubernetesServiceTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsKubernetesServiceDefinition",
            () => ({
                info: {
                    ingresses: "Ingresses",
                    loadBalancers: "Load Balancers",
                    type: "Type"
                },
                tabs: {
                    yaml: "YAML"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <KubernetesYamlInfoCard rawYaml={service.data.rawYaml!}/>,
                localization.tabs.yaml(),
                "yaml")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    commonKubernetesNamespaceResourceInfoItemElements.annotationsInfoItemElement,
                    commonKubernetesNamespaceResourceInfoItemElements.clusterInfoItemElement,
                    commonKubernetesNamespaceResourceInfoItemElements.namespaceInfoItemElement,
                    <InfoItem
                        key="type"
                        title={localization.info.type()}
                        value={kubernetesServiceTypeTranslator(service.data.type)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={ingressModels}
                        entityTypeName={Contract.TypeNames.AwsKubernetesIngress}
                        key="ingressModels"
                        title={localization.info.ingresses()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={loadBalancerModels}
                        entityTypeName={Contract.TypeNames.AwsElbApplicationLoadBalancer}
                        key="loadBalancerModels"
                        title={localization.info.loadBalancers()}/>
                ]}
                options={options?.infoOptions}>
                <KubernetesServicePortsInfoCard ports={service.data.ports}/>
            </Info>
    });
}