import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, InlineItems, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React from "react";
import { Contract, Entity } from "../../../common";

type OciNetworkingNetworkLoadBalancerListenersTableProps = {
    backendIpAddressToInstanceIdMap: Dictionary<string>;
    backendSetNameToBackendsMap: Dictionary<Contract.OciNetworkingNetworkLoadBalancerBackend[]>;
    listeners: Contract.OciNetworkingNetworkLoadBalancerListener[];
};

export function OciNetworkingNetworkLoadBalancerListenersTable({ backendIpAddressToInstanceIdMap, backendSetNameToBackendsMap, listeners }: OciNetworkingNetworkLoadBalancerListenersTableProps) {
    const localization =
        useLocalization(
            "tenants.oci.ociNetworkingNetworkLoadBalancerListenersTable",
            () => ({
                columns: {
                    backendSetName: "Backend Set Name",
                    ipAddressFamily: {
                        title: "IP Protocol Version",
                        [Contract.TypeNames.IpAddressFamily]: {
                            [Contract.IpAddressFamily.IpV4]: "IPv4",
                            [Contract.IpAddressFamily.IpV6]: "IPv6"
                        }

                    },
                    name: "Name",
                    port: {
                        any: "Any",
                        title: "Port"
                    },
                    protocol: {
                        title: "Protocol",
                        [Contract.TypeNames.OciNetworkingNetworkLoadBalancerListenerProtocol]: {
                            [Contract.OciNetworkingNetworkLoadBalancerListenerProtocol.Any]: "Any",
                            [Contract.OciNetworkingNetworkLoadBalancerListenerProtocol.Tcp]: "TCP",
                            [Contract.OciNetworkingNetworkLoadBalancerListenerProtocol.TcpAndUdp]: "UDP/TCP",
                            [Contract.OciNetworkingNetworkLoadBalancerListenerProtocol.Udp]: "UDP"
                        }
                    },
                    targets: "Targets"
                },
                empty: "No Backends"
            }));

    return (
        <DataTable
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={() => listeners}
            getItemId={(item: Contract.OciNetworkingNetworkLoadBalancerListener) => item.name}
            sortOptions={{ enabled: false }}
            variant="card">
            <DataTableColumn
                id={OciNetworkingNetworkLoadBalancerListenersTableColumnId.Name}
                itemProperty={(item: Contract.OciNetworkingNetworkLoadBalancerListener) => item.name}
                title={localization.columns.name()}/>
            <DataTableColumn
                id={OciNetworkingNetworkLoadBalancerListenersTableColumnId.Protocol}
                itemProperty={(item: Contract.OciNetworkingNetworkLoadBalancerListener) => localization.columns.protocol[Contract.TypeNames.OciNetworkingNetworkLoadBalancerListenerProtocol][item.protocol]()}
                title={localization.columns.protocol.title()}/>
            <DataTableColumn
                id={OciNetworkingNetworkLoadBalancerListenersTableColumnId.IpAddressFamily}
                itemProperty={(item: Contract.OciNetworkingNetworkLoadBalancerListener) => localization.columns.ipAddressFamily[Contract.TypeNames.IpAddressFamily][item.ipAddressFamily]()}
                title={localization.columns.ipAddressFamily.title()}/>
            <DataTableColumn
                id={OciNetworkingNetworkLoadBalancerListenersTableColumnId.Port}
                itemProperty={(item: Contract.OciNetworkingNetworkLoadBalancerListener) =>
                    item.port == 0
                        ? localization.columns.port.any()
                        : item.port}
                title={localization.columns.port.title()}/>
            <DataTableColumn
                id={OciNetworkingNetworkLoadBalancerListenersTableColumnId.BackendSetName}
                itemProperty={(item: Contract.OciNetworkingNetworkLoadBalancerListener) => item.backendSetName}
                title={localization.columns.backendSetName()}/>
            <DataTableColumn
                id={OciNetworkingNetworkLoadBalancerListenersTableColumnId.Targets}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OciNetworkingNetworkLoadBalancerListener>) =>
                        <InlineItems
                            items={backendSetNameToBackendsMap[item.backendSetName]}
                            variant="itemPlusItemCount">
                            {backend =>
                                _.has(backendIpAddressToInstanceIdMap, backend.ipAddress)
                                    ? <Entity
                                        entityIdOrModel={backendIpAddressToInstanceIdMap[backend.ipAddress]}
                                        variant="iconText"/>
                                    : <Typography noWrap={true}> {backend.ipAddress} </Typography>}
                        </InlineItems>
                }
                title={localization.columns.targets()}/>
        </DataTable>);
}

enum OciNetworkingNetworkLoadBalancerListenersTableColumnId {
    BackendSetName = "backendSetName",
    IpAddressFamily = "ipAddressFamily",
    Name = "name",
    Port = "port",
    Protocol = "protocol",
    Targets = "targets"
}