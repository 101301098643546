import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function TicketingIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M7.51966 11.2069C7.73917 11.183 7.96111 11.2162 8.16404 11.3032C8.3521 11.3839 8.51793 11.5082 8.64792 11.6656C9.85043 12.9604 10.6427 14.6635 10.9733 16.3819C11.3048 18.1045 11.1855 19.9167 10.468 21.4015C10.3622 21.6238 10.1946 21.8109 9.98516 21.9403C9.77758 22.0686 9.53773 22.1349 9.2939 22.1314H3.49823C3.27698 22.1314 3.05933 22.0748 2.86609 21.9671C2.67285 21.8593 2.51036 21.704 2.39405 21.5158C2.27773 21.3275 2.21145 21.1127 2.20151 20.8917C2.19156 20.6707 2.23827 20.4508 2.33721 20.2529L6.50078 11.9283C6.59413 11.7335 6.73521 11.5653 6.911 11.4394C7.09051 11.3108 7.30015 11.2307 7.51966 11.2069ZM7.73077 13.0459L3.98686 20.5314H9.10684C9.57138 19.4545 9.67122 18.0827 9.40214 16.6842C9.14603 15.353 8.56655 14.058 7.73077 13.0459Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M12.195 2.19923C12.4338 2.19835 12.668 2.2648 12.8708 2.39098C13.0727 2.5166 13.2353 2.69635 13.3401 2.90977C13.4724 3.17732 15.4653 7.41241 17.4207 11.5708C18.4086 13.6718 19.3904 15.7606 20.1252 17.3241L21.3447 19.9192C21.44 20.1142 21.4849 20.3302 21.4751 20.5472C21.4652 20.7682 21.3989 20.983 21.2826 21.1713C21.1663 21.3595 21.0038 21.5148 20.8105 21.6226C20.6173 21.7303 20.3997 21.7869 20.1785 21.7869H14.3767C14.1357 21.7869 13.8993 21.7198 13.6942 21.593C13.4892 21.4663 13.3235 21.2849 13.2157 21.0693L13.2045 21.047L10.406 14.4495C9.50893 12.6216 9.10053 10.5923 9.22075 8.5594C9.34063 6.53213 9.98211 4.57044 11.0827 2.86427C11.1861 2.67362 11.3367 2.51226 11.5205 2.39596C11.7224 2.26829 11.9561 2.20011 12.195 2.19923ZM20.1784 20.4754C20.2828 20.4263 20.4264 20.3589 20.6238 20.2661L20.1784 20.4754ZM20.1784 20.4754C20.1764 20.4763 20.1744 20.4773 20.1724 20.4782C20.1703 20.4792 20.1681 20.4802 20.166 20.4812C20.1613 20.4834 20.1568 20.4856 20.1523 20.4877C19.9282 20.5929 19.903 20.6047 19.9001 20.606L20.1784 20.4754ZM19.7027 20.1869H14.5777L11.8613 13.7831L11.8512 13.7626C11.0697 12.1781 10.7137 10.4176 10.818 8.65385C10.9116 7.06991 11.3737 5.53227 12.1643 4.16224C12.2051 4.24848 12.2502 4.34384 12.2992 4.44774C12.5312 4.93895 12.8521 5.61957 13.2317 6.42565C13.9909 8.03774 14.9849 10.1507 15.9728 12.2516C16.9606 14.3525 17.9424 16.4412 18.6772 18.0046L19.7027 20.1869Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}