import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function GciFolderIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M7.11637 0C7.6964 0 8.24787 0.251813 8.62775 0.690138L9.55003 1.75431C9.92991 2.19263 10.4814 2.44444 11.0614 2.44444H20.8815C22.0467 2.44444 23 3.54444 23 4.88889V10.565L22.2176 9.78256C20.4266 7.99156 18.0329 7 15.5 7C12.9671 7 10.5735 7.99159 8.78257 9.78249C8.48606 10.079 8.21301 10.3913 7.96344 10.7155C6.68938 12.3702 6 14.3893 6 16.5004C6 17.0068 6.03967 17.5079 6.11756 18H3C1.89543 18 1 17.1046 1 16V6C1 5.94114 1.00254 5.88288 1.00752 5.82532L1.01059 2.44444C1.01059 1.1 1.95333 0 3.11852 0H7.11637Z"
                fill="#BAC5D3"/>
            <path
                d="M11.7813 16.5004C11.7813 15.8068 11.9724 15.157 12.3044 14.6006V12.2406H9.94438C9.00772 13.4571 8.5 14.9397 8.5 16.5004C8.5 18.0612 9.00772 19.5438 9.94438 20.7603H12.3044V18.4002C11.9724 17.8439 11.7813 17.1941 11.7813 16.5004Z"
                fill="#FBBD00"/>
            <path
                d="M15.5 20.2187L13.8594 21.8593L15.5 23.5C17.0608 23.5 18.5433 22.9923 19.7597 22.0556V19.6981H17.4023C16.841 20.0313 16.1885 20.2187 15.5 20.2187Z"
                fill="#0F9D58"/>
            <path
                d="M12.3044 18.4002L9.94438 20.7603C10.1298 21.0011 10.3311 21.2314 10.5496 21.45C11.8718 22.7721 13.6302 23.5 15.5 23.5V20.2187C14.1431 20.2187 12.9538 19.4886 12.3044 18.4002Z"
                fill="#31AA52"/>
            <path
                d="M22.5 16.4995C22.5 16.0736 22.4614 15.6469 22.3854 15.2313L22.3238 14.895H15.5V18.1764H18.821C18.4985 18.8179 18.0026 19.3416 17.4023 19.6981L19.7597 22.0556C20.0006 21.8701 20.2312 21.6679 20.4497 21.4494C21.7719 20.1272 22.5 18.3693 22.5 16.4995Z"
                fill="#3C79E6"/>
            <path
                d="M18.1296 13.8705L18.4196 14.1605L20.7398 11.8403L20.4498 11.5503C19.1276 10.2282 17.3698 9.5 15.5 9.5L13.8594 11.1407L15.5 12.7813C16.4933 12.7813 17.4272 13.1681 18.1296 13.8705Z"
                fill="#CF2D48"/>
            <path
                d="M15.5 12.7813V9.5C13.6302 9.5 11.8725 10.2282 10.5503 11.5503C10.3318 11.7688 10.1298 11.9997 9.94438 12.2406L12.3044 14.6006C12.9539 13.5122 14.1431 12.7813 15.5 12.7813Z"
                fill="#EB4132"/>
        </SvgIcon>);
}