import { MailIcon, MailParser, useLocalization, useSelectionActionsContext } from "@infrastructure";
import _ from "lodash";
import React, { Fragment, useState } from "react";
import { SendRisksMailDialog } from "../../../../../..";
import { Contract, riskModelStore, SelectionActionButton } from "../../../../../../../../../../../common";

type SendMailSelectionActionProps = {
    clearSelection: () => void;
};

export function SendMailPerResourceOwnerAction({ clearSelection }: SendMailSelectionActionProps) {
    const { actionEnded, actionExecuting, actionStarted, itemIds } = useSelectionActionsContext();
    const [open, setOpen] = useState(false);
    const riskModels = riskModelStore.useGet(itemIds);
    const resourceOwnerEnabled =
        _.some(
            riskModels,
            riskModel =>
                _.some(
                    riskModel.riskedEntityOwner?.values,
                    principalReferenceValue =>
                        !principalReferenceValue.principalSearchableIdReference.idReference.startsWith(Contract.TenantType.Unknown.toLowerCase()) ||
                        MailParser.validate(principalReferenceValue.principalSearchableIdReference.displayName)));

    const disabled = itemIds.length > 10;

    const localization =
        useLocalization(
            "views.customer.risks.items.hooks.useRiskTypeDefinition.hooks.useCommonSelectionActions.sendMailPerResourceOwnerAction",
            () => ({
                disabledTooltip: "Sending to owner is limited to 10 findings",
                share: "Send per resource owner"
            }));

    return (
        resourceOwnerEnabled
            ? <Fragment>
                {open &&
                    <SendRisksMailDialog
                        riskIds={itemIds}
                        typeName={Contract.TypeNames.ResourceOwnerMailDelivery}
                        onClose={
                            canceled => {
                                if (!canceled) {
                                    clearSelection();
                                }
                                actionEnded();
                                setOpen(false);
                            }}/>}
                <SelectionActionButton
                    disabled={disabled}
                    startIcon={
                        <MailIcon
                            sx={{
                                opacity:
                                    actionExecuting
                                        ? 0.4
                                        : 1
                            }}/>}
                    tooltip={
                        disabled
                            ? localization.disabledTooltip()
                            : undefined}
                    onClick={
                        () => {
                            setOpen(true);
                            actionStarted(true);
                        }}>
                    {localization.share()}
                </SelectionActionButton>
            </Fragment>
            : <Fragment/>);
}