import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../../../../common";

export function useProjectModelStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useProjectsDefinition.useProjectModelStatusTranslator",
            () => ({
                [Contract.TypeNames.ProjectStateStatus]: {
                    [Contract.ProjectStateStatus.Pending]: "Pending",
                    [Contract.ProjectStateStatus.Updating]: "Updating",
                    [Contract.ProjectStateStatus.Ready]: "Ready"
                }
            }));
    return (status: Contract.ProjectStateStatus) => localization[Contract.TypeNames.ProjectStateStatus][status]();
}