import { FormLayout, makeContextProvider, OrderedWizard, OrderedWizardItem, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useJiraContext, useSetJiraContext } from "../..";
import { Contract } from "../../../../../../../../../../../../../../common";
import { AccessTokenItem, FinishItem, OrganizationItem, UserItem } from "./components";

export class AddOrEditContext {
    public instanceAuthenticationType: Contract.JiraInstanceConfigurationConnectionInfoAuthenticationType;
    public instanceUrl: string;
    public instanceUserName: string;
    public instanceUserToken?: string;

    constructor(
        public instanceModel?: Contract.ScopeSystemEntityModel) {
        this.instanceAuthenticationType = (instanceModel?.configuration as Contract.JiraInstanceConfiguration)?.connectionInfo.authenticationType ?? Contract.JiraInstanceConfigurationConnectionInfoAuthenticationType.Basic;
        this.instanceUrl = (instanceModel?.configuration as Contract.JiraInstanceConfiguration)?.connectionInfo.url ?? "";
        this.instanceUserName = (instanceModel?.configuration as Contract.JiraInstanceConfiguration)?.connectionInfo.userName ?? "";
        this.instanceUserToken =
            _.isNil(instanceModel)
                ? ""
                : undefined;
    }
}

export const [useAddOrEditContext, useSetAddOrEditContext, useAddOrEditContextProvider] = makeContextProvider<AddOrEditContext>();

export function AddOrEdit() {
    const { instanceModel } = useJiraContext();
    const setJiraContext = useSetJiraContext();
    const [, , ContextProvider] = useAddOrEditContextProvider(() => new AddOrEditContext(instanceModel));

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useTicketingItems.jira.addOrEdit",
            () => ({
                steps: {
                    accessToken: "Create API Token",
                    organization: "Activate Instance",
                    user: "Create a New Jira User"
                },
                title: {
                    add: "Add Jira Instance",
                    edit: "Edit Jira Instance"
                }
            }));
    return (
        <ContextProvider>
            <FormLayout
                disableContentPadding={true}
                titleOptions={{
                    text:
                        _.isNil(instanceModel)
                            ? localization.title.add()
                            : localization.title.edit()
                }}>
                <OrderedWizard
                    finishItemElement={<FinishItem/>}
                    startItemIndex={
                        _.isNil(instanceModel)
                            ? 0
                            : 2}
                    onClose={
                        () =>
                            setJiraContext(
                                jiraContext => ({
                                    ...jiraContext,
                                    addOrEditType: undefined,
                                    instanceModel: undefined
                                }))}>
                    <OrderedWizardItem title={localization.steps.user()}>
                        <UserItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem title={localization.steps.accessToken()}>
                        <AccessTokenItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem
                        deferredLoading={true}
                        title={localization.steps.organization()}>
                        <OrganizationItem/>
                    </OrderedWizardItem>
                </OrderedWizard>
            </FormLayout>
        </ContextProvider>);
}