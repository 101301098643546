﻿import { useLocalization } from "@infrastructure";

export function useAwsSetInstanceMetadataServiceV2VersionChangeDefinition() {
    const localization =
        useLocalization(
            "common.change.hooks.useDefinition.hooks.useAwsDefinition.hooks.useAwsSetInstanceMetadataServiceV2VersionChangeDefinition",
            () => ({
                title: "Update EC2 instance metadata options"
            }));
    return {
        title: localization.title()
    };
}