import { Typography } from "@mui/material";
import React from "react";
import { Contract, CustomRiskPolicy, GroupedTableColumnRenderProps, RiskPolicyTypeMetadataHelper, useRiskPolicyTitleTranslator } from "../../../../../../../../../../../../../common";
import { GroupedItem } from "../../../../../Grouped";

export function RiskPolicyConfigurationTypeName({ item }: GroupedTableColumnRenderProps<GroupedItem>) {
    const riskPolicyConfigurationTypeName = (item.riskGroup as Contract.RiskControllerGetRiskGroupsResponseRiskPolicyTypeGroup).riskPolicyConfigurationTypeName;
    const riskPolicyTitleTranslator = useRiskPolicyTitleTranslator();
    return (
        <Typography noWrap={true}>
            {RiskPolicyTypeMetadataHelper.get(riskPolicyConfigurationTypeName).category === Contract.RiskPolicyCategory.Custom
                ? <CustomRiskPolicy
                    riskPolicyConfigurationTypeName={riskPolicyConfigurationTypeName}
                    sx={{ fontWeight: 600 }}/>
                : <Typography
                    component="span"
                    noWrap={true}
                    sx={{ fontWeight: 600 }}>
                    {riskPolicyTitleTranslator(riskPolicyConfigurationTypeName)}
                </Typography>}
        </Typography>);
}