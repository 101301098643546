import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../common";
import { useAwsCommonBehaviorIdentityRiskDefinition } from "./useAwsCommonBehaviorIdentityRiskDefinition";

export function useAwsReconnaissanceRiskDefinition(riskModel: Contract.RiskModel) {
    const reconnaissanceRiskModel = riskModel as Contract.AwsReconnaissanceRiskModel;
    const identityModel = entityModelStore.useGet(reconnaissanceRiskModel.risk.identityId);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.behavior.useAwsReconnaissanceRiskDefinition",
            () => ({
                description: "{{identity}} was observed collecting information about {{resources}}"
            }));

    return useAwsCommonBehaviorIdentityRiskDefinition(
        riskModel,
        localization.description({
            identity:
                <Entity
                    entityIdOrModel={identityModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            resources:
                <InlineEntities
                    entityIdsOrModels={reconnaissanceRiskModel.resourceIdReferences}
                    entityTypeName={Contract.TypeNames.AwsResource}
                    variant="itemAndTypeOrItemCountAndType"/>
        }));
}