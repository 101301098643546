import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAzureStorageStorageAccountBlobContainerRiskContext, useGetAzureStorageStorageAccountRiskContext } from "../../..";
import { Contract, Entity, entityModelStore, tenantModelStore } from "../../../../../../../../../../../../common";
import { useAzureStorageStorageAccountBlobContainerPublicAccessTranslator } from "../../../../../../../../../../../../tenants";
import { EntityExternalConsoleLink, ResourcesExternalConsoleLink } from "../../components";

export function useAzureStorageStorageAccountBlobContainerPublicAccessExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureStorageStorageAccountBlobContainerPublicAccessExistsRisk;
    const blobContainerModel = entityModelStore.useGet(risk.entityId) as Contract.AzureStorageStorageAccountBlobContainerModel;
    const storageAccountModel =
        blobContainerModel.unknown
            ? undefined
            : entityModelStore.useGet((blobContainerModel.entity as Contract.AzureStorageStorageAccountBlobContainer).storageAccountId) as Contract.AzureStorageStorageAccountModel;
    const tenantConfiguration = tenantModelStore.useGet(riskModel.tenantId).configuration as Contract.AzureTenantConfiguration;
    const aadTenantConfiguration = tenantModelStore.useGet(tenantConfiguration.aadTenantId).configuration as Contract.AadTenantConfiguration;

    const getAzureStorageStorageAccountBlobContainerRiskContext = useGetAzureStorageStorageAccountBlobContainerRiskContext();
    const getAzureStorageStorageAccountRiskContext = useGetAzureStorageStorageAccountRiskContext();

    const storageStorageAccountBlobContainerAccessLevelTranslator = useAzureStorageStorageAccountBlobContainerPublicAccessTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureStorageStorageAccountBlobContainerPublicAccessExistsRiskDefinition",
            () => ({
                description: {
                    text: "{{blobContainer}} has **{{effectivePublicAccess}}** public access level",
                    unknown: "{{blobContainer}} is publicly accessible"
                },
                sections: {
                    resolution: {
                        step1: "Before changing the access level, verify that identities can access the blob container and the blobs within it without public access",
                        step2: "Click the **Change access level** button and select **Private**",
                        step3: "Click **OK** to confirm"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        (blobContainerModel.unknown
            ? localization.description.unknown
            : localization.description.text)({
            blobContainer:
                <Entity
                    entityIdOrModel={blobContainerModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            effectivePublicAccess:
                blobContainerModel.unknown
                    ? undefined
                    : storageStorageAccountBlobContainerAccessLevelTranslator((blobContainerModel.entity as Contract.AzureStorageStorageAccountBlobContainer).effectivePublicAccess)
        }),
        () => [
            blobContainerModel.unknown
                ? <ResourcesExternalConsoleLink
                    partitionType={aadTenantConfiguration.partitionType}
                    typeName={Contract.TypeNames.AzureStorageStorageAccount}/>
                : <EntityExternalConsoleLink
                    entityId={risk.entityId}
                    key={risk.entityId}
                    page={Contract.AzureConsoleEntityPage.Overview}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const blobContainerRiskContext = getAzureStorageStorageAccountBlobContainerRiskContext(blobContainerModel);
            const storageAccountRiskContext = getAzureStorageStorageAccountRiskContext(storageAccountModel!);
            return [
                blobContainerRiskContext.generalInformation,
                blobContainerRiskContext.sensitive,
                blobContainerRiskContext.effectivePublicAccess,
                blobContainerRiskContext.storageAccount,
                storageAccountRiskContext.sensitive,
                blobContainerRiskContext.highestDataSensitivity,
                blobContainerRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}