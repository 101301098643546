import { CopyToClipboardActionButton, Sx, useChangeEffect } from "@infrastructure";
import MonacoEditor, { EditorProps, loader } from "@monaco-editor/react";
import { alpha, Box, SxProps, useTheme } from "@mui/material";
import _ from "lodash";
import { editor, MarkerSeverity, Range } from "monaco-editor/esm/vs/editor/editor.api";
import React, { ReactNode, useCallback, useRef } from "react";
import { commonOptions, MAX_HEIGHT } from "./commonOptions";
import { themeName, useCommonStyle, useMonacoTheme } from "./hooks";

loader.config({
    paths: {
        vs: "/monaco-editor/min/vs"
    }
});

type TextViewerProps = EditorProps & {
    actionsElement?: ReactNode;
    actionsElementSx?: SxProps;
    copyToClipboard?: boolean;
    format: string;
    highlightedLines?: number[];
    markers?: editor.IMarkerData[];
    onValidChanged?: (valid: boolean) => void;
    startLine?: number;
    sx?: SxProps;
    text: string;
};

export const TextViewerClassName = "TextViewer";

export function TextViewer({ actionsElement, actionsElementSx, copyToClipboard = false, format, height, highlightedLines, markers, onValidChanged, startLine, sx, text, ...props }: TextViewerProps) {
    const { options, ...editorProps } = props;
    const setMarkersRef = useRef<(markers: editor.IMarkerData[]) => void>();
    const editorRef = useRef<editor.IStandaloneCodeEditor>();
    const containerRef = useRef<HTMLDivElement>(null);

    const updateHeight =
        useCallback(
            () => {
                if (!editorRef.current || !containerRef.current) {
                    return;
                }

                const height = Math.min(MAX_HEIGHT, editorRef.current.getContentHeight());
                const { width } = editorRef.current.getLayoutInfo();
                containerRef.current.style.height = `${height}px`;
                const { height: actualHeight } = containerRef.current.getBoundingClientRect();
                editorRef.current.layout({ height: actualHeight - 2, width });
            },
            [editorRef, containerRef]);

    useChangeEffect(
        () => {
            if (setMarkersRef.current && markers) {
                setMarkersRef.current(markers);
            }
        },
        [markers]);

    const commonStyle = useCommonStyle();
    const monacoTheme = useMonacoTheme();
    const theme = useTheme();
    return (
        <Box
            className={TextViewerClassName}
            ref={containerRef}
            sx={
                Sx.combine(
                    commonStyle,
                    {
                        ".highlight": {
                            backgroundColor: theme.palette.textEditor.highlightBackground
                        },
                        backgroundColor: theme.palette.background.paper,
                        border: theme.border.primary,
                        borderRadius: theme.spacing(0.75),
                        height: height ?? "100%",
                        maxHeight: height ?? "100%",
                        minHeight: theme.spacing(9),
                        overflow: "hidden",
                        position: "relative",
                        width: "100%"
                    },
                    sx)}>
            <MonacoEditor
                language={format}
                options={{
                    ...commonOptions,
                    automaticLayout: true,
                    lineNumbers:
                        _.isNil(startLine)
                            ? undefined
                            : (lineNumber: number) => `${lineNumber + startLine - 1}`,
                    ...options
                }}
                value={text}
                {...editorProps}
                beforeMount={monacoEditor => monacoEditor.editor.defineTheme(themeName, monacoTheme)}
                theme={themeName}
                onMount={
                    (standaloneCodeEditor, monacoEditor) => {
                        editorRef.current = standaloneCodeEditor;
                        setMarkersRef.current =
                            (markers: editor.IMarkerData[]) =>
                                monacoEditor.editor.setModelMarkers(
                                    standaloneCodeEditor.getModel()!,
                                    "owner",
                                    markers);

                        if (!_.isEmpty(highlightedLines)) {
                            standaloneCodeEditor.deltaDecorations(
                                [],
                                _.map(
                                    highlightedLines,
                                    line => {
                                        const actualLine = line - (startLine ?? 0) + 1;
                                        return ({
                                            options: {
                                                inlineClassName: "highlight",
                                                isWholeLine: true
                                            },
                                            range: new Range(actualLine, 0, actualLine, 0)
                                        });
                                    }));
                            standaloneCodeEditor.revealLinesInCenterIfOutsideViewport(
                                _.head(highlightedLines)! - (startLine ?? 0),
                                _.head(highlightedLines)! - (startLine ?? 0));
                        }

                        if (!height) {
                            updateHeight();
                            standaloneCodeEditor.onDidContentSizeChange(updateHeight);
                        }
                    }}
                onValidate={
                    markers =>
                        onValidChanged?.(
                            _(markers).
                                filter(marker => marker.severity === MarkerSeverity.Error).
                                isEmpty())}/>
            {(!_.isNil(actionsElement) || copyToClipboard) &&
                <Box
                    sx={{
                        backgroundColor: alpha(theme.palette.action.hover, 0.8),
                        borderRadius: theme.spacing(2),
                        position: "absolute",
                        right: theme.spacing(1),
                        top: theme.spacing(1),
                        ...actionsElementSx
                    }}>
                    {copyToClipboard &&
                        <CopyToClipboardActionButton getValue={() => text}/>}
                    {actionsElement}
                </Box>}
        </Box>);
}