import { CopyToClipboardActionButton, Message, Optional, useChangeEffect, useLocalization, useSetWizardContext, useWizardContext } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Contract, RadioGroup, RadioGroupItem, TenantController, useTheme } from "../../../../../../../..";
import { useRiskResolutionAutomationContext, useSetRiskResolutionAutomationContext } from "../../../../../../utilities";
import { GcpRiskResolutionAutomationContext } from "../../useGcpDefinition";
import { AccessTokenInput } from "./components";

export function GcpAccessTokenItem() {
    const { executing, setLoaded, setValid, useNextEffect } = useWizardContext();
    const {
        changeModels,
        gcpAccessToken,
        initialStoredGcpAccessToken: initialStoredAccessToken,
        someChangeableSomeNotChangeable
    } = useRiskResolutionAutomationContext() as GcpRiskResolutionAutomationContext;
    const riskTenantIds =
        _(changeModels).
            flatMap(changeModel => changeModel.changeDatas).
            map(changeData => changeData.riskTenantId).
            uniq().
            value();
    const [accessToken, setAccessToken] = useState(gcpAccessToken ?? "");
    const [accessTokenInputValid, setAccessTokenInputValid] = useState<Optional<boolean>>(undefined);
    const [store, setStore] = useState(false);
    const [mode, setMode] =
        useState(
            initialStoredAccessToken
                ? GcpAccessTokenMode.Stored
                : GcpAccessTokenMode.New);

    const localization =
        useLocalization(
            "common.riskResolutionAutomation.hooks.useDefinition.hooks.useGcpDefinition.gcpAccessTokenItem",
            () => ({
                actions: {
                    next: {
                        prompt: "* You’ll be able to review all changes before they are applied"
                    },
                    validateAccessToken: {
                        error: {
                            [GcpAccessTokenValidationError.General]: "Failed to validate Access Token",
                            [GcpAccessTokenValidationError.NotValid]: "Access Token is not valid"
                        }
                    }
                },
                mode: {
                    [GcpAccessTokenMode.New]: {
                        initialStoredAccessTokenExists: "Provide new credentials",
                        initialStoredAccessTokenNotExists: "Provide credentials"
                    },
                    [GcpAccessTokenMode.None]: {
                        info: "Tenable Cloud Security will only perform remediation steps for which it has the needed permissions.",
                        title: "Continue without credentials"
                    },
                    [GcpAccessTokenMode.Stored]: "Use previously stored credentials"
                },
                subtitle: {
                    part1: {
                        command: "gcloud auth print-access-token",
                        text: "Provide a user access token that will be used to perform the remediation. You can generate a user token by running the following command while being authenticated with the required user credentials: {{command}}"
                    },
                    part2: "The user should have sufficient privileges to perform the required operations."
                },
                title: "Provide One-Time Credentials"
            }));

    const setWizardContext = useSetWizardContext();
    useEffect(
        () => {
            setWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    bottomElement:
                        <Typography>
                            {localization.actions.next.prompt()}
                        </Typography>
                }));
            setLoaded();
        },
        []);

    const setAutomationContext = useSetRiskResolutionAutomationContext();
    useEffect(
        () => {
            setAutomationContext(
                automationContext => ({
                    ...automationContext,
                    gcpAccessToken:
                        mode === GcpAccessTokenMode.New
                            ? accessToken
                            : undefined,
                    storedGcpAccessToken: mode === GcpAccessTokenMode.Stored
                } as GcpRiskResolutionAutomationContext));
            setValid(
                mode !== GcpAccessTokenMode.New ||
                accessTokenInputValid === true);
        },
        [accessToken, accessTokenInputValid, mode]);

    useChangeEffect(
        () => {
            setAccessToken("");
            setStore(false);
        },
        [mode]);

    useNextEffect(
        async () => {
            if (mode === GcpAccessTokenMode.New) {
                try {
                    const { valid } =
                        await TenantController.validateGcpAccessToken(
                            new Contract.TenantControllerValidateGcpAccessTokenRequest(
                                accessToken,
                                store,
                                riskTenantIds));
                    if (!valid) {
                        return localization.actions.validateAccessToken.error[GcpAccessTokenValidationError.NotValid]();
                    }
                } catch {
                    return localization.actions.validateAccessToken.error[GcpAccessTokenValidationError.General]();
                }
            }

            setWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    bottomElement: undefined
                }));
            return undefined;
        },
        [accessToken, mode, store]);

    const theme = useTheme();
    return (
        <Stack spacing={4}>
            <Stack spacing={1}>
                <Typography variant="h3">
                    {localization.title()}
                </Typography>
                <Box sx={{ maxWidth: theme.spacing(69) }}>
                    <Typography>
                        {localization.subtitle.part1.text({
                            command:
                                <Typography
                                    component="span"
                                    sx={{
                                        fontStyle: "italic",
                                        fontWeight: 600
                                    }}>
                                    <CopyToClipboardActionButton getValue={() => localization.subtitle.part1.command()}/>
                                    {localization.subtitle.part1.command()}
                                </Typography>
                        })}
                    </Typography>
                    <Typography>
                        {localization.subtitle.part2()}
                    </Typography>
                </Box>
            </Stack>
            {initialStoredAccessToken || someChangeableSomeNotChangeable
                ? <RadioGroup
                    items={
                        _([
                            initialStoredAccessToken
                                ? {
                                    label: localization.mode[GcpAccessTokenMode.Stored](),
                                    value: GcpAccessTokenMode.Stored
                                }
                                : undefined,
                            {
                                children:
                                    <Box
                                        sx={{
                                            paddingBottom: theme.spacing(1.5),
                                            paddingLeft: theme.spacing(3),
                                            paddingTop: theme.spacing(1.5)
                                        }}>
                                        <AccessTokenInput
                                            disabled={executing}
                                            initialAccessToken={accessToken}
                                            onAccessTokenChanged={
                                                (newAccessToken, newAccessTokenInputValid) => {
                                                    setAccessToken(newAccessToken);
                                                    setAccessTokenInputValid(newAccessTokenInputValid);
                                                }
                                            }
                                            onStoreChanged={setStore}/>
                                    </Box>,
                                label:
                                    initialStoredAccessToken
                                        ? localization.mode[GcpAccessTokenMode.New].initialStoredAccessTokenExists()
                                        : localization.mode[GcpAccessTokenMode.New].initialStoredAccessTokenNotExists(),
                                value: GcpAccessTokenMode.New
                            },
                            someChangeableSomeNotChangeable
                                ? {
                                    label:
                                        <Stack
                                            alignItems="center"
                                            direction="row"
                                            spacing={1}>
                                            <Typography>
                                                {localization.mode[GcpAccessTokenMode.None].title()}
                                            </Typography>
                                            <Message
                                                level="info"
                                                title={localization.mode[GcpAccessTokenMode.None].info()}
                                                variant="minimal"/>
                                        </Stack>,
                                    value: GcpAccessTokenMode.None
                                }
                                : undefined]).
                            filter().
                            as<RadioGroupItem<GcpAccessTokenMode>>().
                            value()}
                    selectedValue={mode}
                    onChange={event => setMode(event)}/>
                : <AccessTokenInput
                    disabled={executing}
                    initialAccessToken={accessToken}
                    onAccessTokenChanged={
                        (newAccessToken, newAccessTokenInputValid) => {
                            setAccessToken(newAccessToken);
                            setAccessTokenInputValid(newAccessTokenInputValid);
                        }}
                    onStoreChanged={setStore}/>}
        </Stack>);
}

enum GcpAccessTokenMode {
    New = "new",
    None = "none",
    Stored = "stored"
}

enum GcpAccessTokenValidationError {
    General = "general",
    NotValid = "notValid"
}