import { useLocalization } from "@infrastructure";
import React from "react";
import { useOciDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem, useEntityTypeNameTranslator, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { useOciNetworkingSubnetStatusTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, EntityTypeNameGroupsInfoCard, Info } from "../../../../components";

export function useOciNetworkingSubnetDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);
    const subnetModel = resourceModel as Contract.OciNetworkingSubnetModel;
    const subnet = subnetModel.entity as Contract.OciNetworkingSubnet;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const subnetStatusTranslator = useOciNetworkingSubnetStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciNetworkingSubnetDefinition",
            () => ({
                info: {
                    cards: {
                        resources: {
                            emptyMessage: "No resources",
                            title: "Resources"
                        }
                    },
                    items: {
                        availabilityDomain: "Availability Domain",
                        domainName: "DNS Domain Name",
                        flowLogsEnabled: {
                            false: "Disabled",
                            title: "Flow Logs",
                            true: "Enabled"
                        },
                        ipv4Subnet: "IPv4 CIDR Block",
                        ipv6Subnets: "IPv6 Prefixes",
                        networkedResources: "Attached Resources",
                        prohibitPublicIpOnVnic: {
                            false: "Public",
                            title: "Subnet Access",
                            true: "Private"
                        },
                        regional: {
                            false: "Availability Domain-Specific",
                            title: "Subnet Type",
                            true: "Regional"
                        },
                        status: "State"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={subnetStatusTranslator(subnet.status)}/>,
                    <InfoItem
                        key="prohibitPublicIpOnVnic"
                        title={localization.info.items.prohibitPublicIpOnVnic.title()}
                        value={
                            subnet.prohibitPublicIpOnVnic
                                ? localization.info.items.prohibitPublicIpOnVnic.true()
                                : localization.info.items.prohibitPublicIpOnVnic.false()}/>,
                    <InfoItem
                        key="regional"
                        title={localization.info.items.regional.title()}
                        value={
                            subnet.regional
                                ? localization.info.items.regional.true()
                                : localization.info.items.regional.false()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={subnetModel.vcnIdReference}
                        entityTypeName={Contract.TypeNames.OciNetworkingVcn}
                        key="vcn"
                        title={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn)}/>,
                    <InfoItem
                        key="ipv4Subnet"
                        title={localization.info.items.ipv4Subnet()}
                        value={subnet.ipv4Subnet}/>,
                    <VerticalTopItemsInfoItem
                        items={subnet.ipv6Subnets}
                        key="ipv6Subnets"
                        title={localization.info.items.ipv6Subnets()}/>,
                    <InfoItem
                        key="availabilityDomain"
                        location="all"
                        title={localization.info.items.availabilityDomain()}
                        value={subnet.availabilityDomain}/>,
                    <InfoItem
                        key="domainName"
                        title={localization.info.items.domainName()}
                        value={subnet.domainName}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={subnetModel.routeTableIdReference}
                        entityTypeName={Contract.TypeNames.OciNetworkingRouteTable}
                        key="routeTable"
                        title={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingRouteTable)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={subnetModel.securityListIdReferences}
                        entityTypeName={Contract.TypeNames.OciNetworkingSecurityList}
                        key="securityLists"
                        title={entityTypeNameTranslator(
                            Contract.TypeNames.OciNetworkingSecurityList,
                            { count: 0 })}/>,
                    <InfoItem
                        key="flowLogsEnabled"
                        title={localization.info.items.flowLogsEnabled.title()}
                        value={
                            subnetModel.flowLogsEnabled
                                ? localization.info.items.flowLogsEnabled.true()
                                : localization.info.items.flowLogsEnabled.false()}/>
                ]}
                options={options?.infoOptions}>
                <EntityTypeNameGroupsInfoCard
                    emptyMessage={localization.info.cards.resources.emptyMessage()}
                    entityTypeNameToIdsMap={subnetModel.networkedResourceTypeNameToIdsMap}
                    title={localization.info.cards.resources.title()}/>
            </Info>
    });
}