import _ from "lodash";
import moment, { DurationInputArg1, DurationInputArg2, MomentInput, unitOfTime } from "moment";
import "moment-timezone";

export class TimeHelper {
    public static DateTimeMaxValue = "9999-12-31T23:59:59.9999999";
    public static DateTimeMinValue = "0001-01-01T00:00:00";
    public static addTime(time: string, interval?: DurationInputArg1, unit?: DurationInputArg2) {
        return moment(time).add(interval, unit);
    }

    public static elapsed(
        time: moment.MomentInput,
        interval: moment.DurationInputArg1,
        units: moment.DurationInputArg2) {
        return moment(time).add(interval, units) < TimeHelper.utcNow();
    }

    public static getDuration(time: string, otherTime: string) {
        return moment(time).diff(moment(otherTime));
    }

    public static getInterval(time: string, unit?: DurationInputArg2) {
        return moment(time).diff(TimeHelper.utcNow(), unit);
    }

    public static getLocalTimeFromNow(duration: DurationInputArg1) {
        return moment().add(duration);
    }

    public static getSortable(time?: string) {
        return _.isNil(time)
            ? 0
            : new Date(time).getTime();
    }

    public static getWeekday(time: moment.MomentInput) {
        return moment(time).weekday();
    }

    public static isAfter(time: string, other: MomentInput) {
        return moment(time).isAfter(other);
    }

    public static isBefore(time: string, other: MomentInput) {
        return moment(time).isBefore(other);
    }

    public static now() {
        return moment();
    }

    public static round(time: moment.MomentInput, unit: unitOfTime.StartOf) {
        return moment(time).startOf(unit);
    }

    public static setTime(time: moment.MomentInput, unit: unitOfTime.All, value: number) {
        return moment(time).set(unit, value);
    }

    public static setWeekday(time: moment.MomentInput, weekday: number) {
        return moment(time).weekday(weekday);
    }

    public static timeZoneId() {
        return moment.tz.guess();
    }

    public static utcNow() {
        return moment().utc();
    }
}