import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpDnsManagedZoneDnsSecStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpDnsManagedZoneDnsSecStatusTranslator",
            () => ({
                [Contract.TypeNames.GcpDnsManagedZoneDnsSecStatus]: {
                    [Contract.GcpDnsManagedZoneDnsSecStatus.Disabled]: "Off",
                    [Contract.GcpDnsManagedZoneDnsSecStatus.Enabled]: "On",
                    [Contract.GcpDnsManagedZoneDnsSecStatus.Transfer]: "Transfer"
                }
            }));
    return (status: Contract.GcpDnsManagedZoneDnsSecStatus) =>
        localization[Contract.TypeNames.GcpDnsManagedZoneDnsSecStatus][status]();
}