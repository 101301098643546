import { SxProps, Typography } from "@mui/material";
import { TOptions } from "i18next";
import React from "react";
import { Contract } from "../../../../..";
import { InlineEntitySelection } from "../..";

type ViewProps = {
    riskPolicyConfiguration?: Contract.AwsS3BucketManagementRiskPolicyConfiguration;
    sx?: SxProps;
    templateTranslator: (options?: TOptions) => string;
};

export function View({ riskPolicyConfiguration, sx, templateTranslator }: ViewProps) {
    return (
        <Typography
            component="span"
            sx={sx}>
            {templateTranslator({
                buckets:
                    <InlineEntitySelection
                        entityTypeName={Contract.TypeNames.AwsS3Bucket}
                        selection={{
                            entityBuiltInAttributeTypeNames: riskPolicyConfiguration?.resourceBuiltInAttributeTypeNames,
                            entityCustomAttributeDefinitionIds: riskPolicyConfiguration?.resourceCustomAttributeDefinitionIds,
                            entityIds: riskPolicyConfiguration?.resourceIds,
                            entityTags: riskPolicyConfiguration?.resourceTags,
                            sensitiveEntity: riskPolicyConfiguration?.sensitiveBucket
                        }}
                        sx={sx}/>
            })}
        </Typography>);
}