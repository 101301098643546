import { Action0 } from "@infrastructure";
import { Menu, MenuItem, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { Fragment, ReactNode, useRef, useState } from "react";

type NodeContentContextMenuProps = {
    children: ReactNode;
    items?: NodeContentContextMenuItem[];
};

export class NodeContentContextMenuItem {
    constructor(
        public onClick: Action0,
        public title: string,
        public onlyIfHighlighted?: boolean) {
    }
}

export function NodeContentContextMenu({ children, items }: NodeContentContextMenuProps) {
    const groupElementRef = useRef<SVGSVGElement | null>(null);
    const [contextMenuOpen, setContextMenuOpen] = useState(false);
    const theme = useTheme();
    return (
        <Fragment>
            {!_.isEmpty(items) &&
                <Menu
                    anchorEl={groupElementRef.current}
                    anchorOrigin={{
                        horizontal: "right",
                        vertical: "center"
                    }}
                    open={contextMenuOpen}
                    sx={{ margin: theme.spacing(0, 1) }}
                    transformOrigin={{
                        horizontal: "left",
                        vertical: "center"
                    }}
                    onClick={event => event.stopPropagation()}
                    onClose={
                        (event: React.MouseEvent) => {
                            setContextMenuOpen(false);
                            event.preventDefault();
                            event.stopPropagation();
                        }}>
                    {_.map(
                        items,
                        (contextMenuItem, contextMenuItemIndex) =>
                            <MenuItem
                                key={contextMenuItemIndex}
                                onClick={
                                    event => {
                                        contextMenuItem.onClick();
                                        setContextMenuOpen(false);
                                        event.preventDefault();
                                        event.stopPropagation();
                                    }}>
                                <Typography
                                    noWrap={true}
                                    sx={{ maxWidth: theme.spacing(30) }}>
                                    {contextMenuItem.title}
                                </Typography>
                            </MenuItem>)}
                </Menu>}
            <g
                ref={groupElementRef}
                onContextMenu={
                    event => {
                        setContextMenuOpen(true);
                        event.preventDefault();
                        event.stopPropagation();
                    }}>
                {children}
            </g>
        </Fragment>);
}