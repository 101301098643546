﻿import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useGcpCommonComputeLoadBalancerResourceDefinition } from "./useGcpCommonComputeLoadBalancerResourceDefinition";

export function useGcpComputeBackendServiceDefinition(definitionData: DefinitionData) {
    const commonComputeLoadBalancerResourceDefinition = useGcpCommonComputeLoadBalancerResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpComputeBackendServiceDefinition",
            () => ({
                columns: {
                    backendResourceIds: "Backends",
                    vpcIdReference: "VPC Network"
                }
            }));

    return new EntityTableDefinition(
        [
            commonComputeLoadBalancerResourceDefinition.columns.tenantColumn,
            commonComputeLoadBalancerResourceDefinition.columns.creationTimeColumn,
            commonComputeLoadBalancerResourceDefinition.columns.creatorIdentityCsvColumn,
            commonComputeLoadBalancerResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            commonComputeLoadBalancerResourceDefinition.columns.accessLevelColumn,
            commonComputeLoadBalancerResourceDefinition.columns.loadBalancerIdsColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpComputeBackendServiceModel>(
                        Contract.TypeNames.GcpScopeResource,
                        item => item.backendResourceIds,
                        localization.columns.backendResourceIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpComputeBackendServiceBackendResources)}
                                placeholder={localization.columns.backendResourceIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeBackendServiceBackendResources}
                key={Contract.EntityModelProperty.GcpComputeBackendServiceBackendResources}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpComputeBackendServiceModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.backendResourceIds}
                            entityTypeName={Contract.TypeNames.GcpScopeResource}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.backendResourceIds()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpComputeBackendServiceModel>(
                        Contract.TypeNames.GcpComputeVpc,
                        item => [item.vpcIdReference],
                        localization.columns.vpcIdReference())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpComputeBackendServiceVpc)}
                                placeholder={localization.columns.vpcIdReference()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeBackendServiceVpc}
                key={Contract.EntityModelProperty.GcpComputeBackendServiceVpc}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpComputeBackendServiceModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.vpcIdReference}
                            entityTypeName={Contract.TypeNames.GcpComputeVpc}
                            entityVariant="iconText"/>}
                title={localization.columns.vpcIdReference()}/>,
            commonComputeLoadBalancerResourceDefinition.columns.regionColumn,
            commonComputeLoadBalancerResourceDefinition.columns.regionLocationColumn,
            commonComputeLoadBalancerResourceDefinition.columns.attributesColumn,
            commonComputeLoadBalancerResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonComputeLoadBalancerResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonComputeLoadBalancerResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpComputeBackendServiceRequest(
                new Contract.EntityControllerGetEntityModelPageGcpComputeBackendServiceRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceAccessLevel]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeLoadBalancerResourceLoadBalancers]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeBackendServiceBackendResources]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeBackendServiceVpc])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}