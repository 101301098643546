﻿import { InlineItems, Link, Step, Steps, TextViewer, useLocalization, useLocalizeList } from "@infrastructure";
import { Button, useTheme } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useState } from "react";
import { Contract, Entity, entityModelStore, InlineEntities, riskPolicyModelStore, SecretExclusionAction, useEntityTypeNameTranslator, UserHelper } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem, RiskDefinitionSection } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useResourceSecretExistsRiskContext } from "../../../useResourceSecretExistsRiskContext";
import { EntityExternalConsoleLink } from "../../components";
import { useGetAzureLogicWorkflowRiskContext } from "../contexts";

export function useAzureLogicWorkflowSecretExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const [exclude, setExclude] = useState<boolean>(false);
    const workflowSecretExistsRiskModel = riskModel as Contract.AzureLogicWorkflowSecretExistsRiskModel;
    const risk = riskModel.risk as Contract.AzureLogicWorkflowSecretExistsRisk;
    const { riskPolicyConfiguration } = riskPolicyModelStore.useGet(risk.policyId);
    const workflowModel = entityModelStore.useGet(risk.entityId) as Contract.AzureLogicWorkflowModel;
    const secretParameterNames = _.keys(risk.secretParameterNameToActionNamesMap);
    const unusedSecretParameterNames =
        _(risk.secretParameterNameToActionNamesMap).
            pickBy(actions => _.isEmpty(actions)).
            keys().
            value();

    const getAzureLogicWorkflowRiskContext = useGetAzureLogicWorkflowRiskContext();
    const secretRiskContext = useResourceSecretExistsRiskContext();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureLogicWorkflowSecretExistsRiskDefinition",
            () => ({
                contextItems: {
                    identities: {
                        identities: [
                            "1 identity",
                            "{{count | NumberFormatter.humanize}} identities"
                        ],
                        identitiesTypesPart: {
                            text: " including {{identitiesTypes}}",
                            type: {
                                guestUsers: [
                                    "1 guest user",
                                    "{{count | NumberFormatter.humanize}} guest users"],
                                vendorApplications: [
                                    "1 third party application",
                                    "{{count | NumberFormatter.humanize}} third party applications"]
                            }
                        },
                        text: "{{identities}}{{identitiesTypesPart}} can read your secrets, which can lead to sensitive data leakage"
                    },
                    secretInputs: {
                        actions: [
                            "1 action",
                            "{{count | NumberFormatter.humanize}} actions"
                        ],
                        parameters: [
                            "1 parameter",
                            "{{count | NumberFormatter.humanize}} parameters"
                        ],
                        text: "The {{translatedWorkflowTypeName}} has {{inputs}} exposing secrets in clear text"
                    },
                    secureOutputDisabledKeyVault: {
                        keyVaultActions: [
                            "1 key vault action",
                            "{{count | NumberFormatter.humanize}} key vault actions"
                        ],
                        text: "The {{translatedWorkflowTypeName}} has {{keyVaultActions}} that aren’t securing their output, resulting in exposed secrets in the run history"
                    }
                },
                description: "{{workflow}} is exposing secrets in clear text",
                excludeAction: "Exclude Secret",
                sections: {
                    definition: "Definition",
                    resolution: {
                        step1: "Click **Logic app designer**",
                        step2:
                            {
                                parameterNames: [
                                    "1 parameter",
                                    "{{count | NumberFormatter.humanize}} parameters"
                                ],
                                title: "Click **Parameters** and delete the following unused {{parameterNames}}"
                            },
                        step3: {
                            actionNames: [
                                "1 action",
                                "{{count | NumberFormatter.humanize}} actions"
                            ],
                            step1: "Navigate to the action and find the exposed secret. Note that the secret might be stored in the **Parameters** section",
                            step2: "Copy the secret value and save it in a safe location as you will need to store it in a key vault in the following steps",
                            step3: {
                                link: "key vault",
                                text: "Navigate to Azure {{keyVaultLink}} and select or create a key vault where you want to store your secret"
                            },
                            step4: "In the key vault, navigate to **Secrets**, generate a new secret using the value you saved earlier as the **Secret value** and click **Create**",
                            step5: "Return to the logic app designer and create a **Get secret** action (Key Vault) in an earlier stage of the workflow",
                            step6: "Select the **ellipses (...)** button next to the action you created, and then select **Settings**",
                            step7: "Turn on **Secure Outputs** and select **Done**",
                            step8: "In the action with the secret, replace the secret with a reference to the dynamic parameter value generated by the **Get secret** action. Note that if the secret is stored in the **Parameters** section, it needs to be deleted",
                            title: "Repeat the following steps for each of these {{actionNames}}"
                        },
                        step4: {
                            keyVaultActionNames: [
                                "1 key vault action",
                                "{{count | NumberFormatter.humanize}} key vault actions"
                            ],
                            step1: "Navigate to the action and click the **ellipses (...)** button, and then select **Settings**",
                            step2: "Turn on **Secure Outputs** and select **Done**",
                            title: "Repeat the following steps for each of these {{keyVaultActionNames}}"
                        },
                        step5: "Click on **Save**"
                    }
                }
            }));
    const translatedWorkflowTypeName =
        entityTypeNameTranslator(
            workflowModel.entity.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            workflow:
                <Entity
                    entityIdOrModel={workflowModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}/>,
            localization.sections.resolution.step1(),
            _.isEmpty(unusedSecretParameterNames)
                ? undefined
                : localization.sections.resolution.step2.title({
                    parameterNames:
                        <InlineItems
                            items={unusedSecretParameterNames}
                            namePluralizer={localization.sections.resolution.step2.parameterNames}
                            variant="itemCountAndType"/>
                }),
            _.isEmpty(workflowSecretExistsRiskModel.actionNames)
                ? undefined
                : new Step(
                    localization.sections.resolution.step3.title({
                        actionNames:
                            <InlineItems
                                items={workflowSecretExistsRiskModel.actionNames}
                                namePluralizer={localization.sections.resolution.step3.actionNames}
                                variant="itemCountAndType"/>
                    }),
                    {
                        contentElement:
                            <Steps variant="plainNumbers">
                                {localization.sections.resolution.step3.step1()}
                                {localization.sections.resolution.step3.step2()}
                                {localization.sections.resolution.step3.step3.text({
                                    keyVaultLink:
                                        <Link
                                            urlOrGetUrl={workflowSecretExistsRiskModel.keyVaultUrl}
                                            variant="external">
                                            {localization.sections.resolution.step3.step3.link()}
                                        </Link>
                                })}
                                {localization.sections.resolution.step3.step4()}
                                {localization.sections.resolution.step3.step5()}
                                {localization.sections.resolution.step3.step6()}
                                {localization.sections.resolution.step3.step7()}
                                {localization.sections.resolution.step3.step8()}
                            </Steps>
                    }),
            _.isEmpty(risk.secureOutputDisabledKeyVaultActionNames)
                ? undefined
                : new Step(
                    localization.sections.resolution.step4.title({
                        keyVaultActionNames:
                            <InlineItems
                                items={risk.secureOutputDisabledKeyVaultActionNames}
                                namePluralizer={localization.sections.resolution.step4.keyVaultActionNames}
                                variant="itemCountAndType"/>
                    }),
                    {
                        contentElement:
                            <Steps variant="plainNumbers">
                                {localization.sections.resolution.step4.step1()}
                                {localization.sections.resolution.step4.step2()}
                            </Steps>
                    }),
            localization.sections.resolution.step5()],
        riskModel,
        () => {
            const workflowRiskContext = getAzureLogicWorkflowRiskContext(workflowModel);
            return [
                workflowRiskContext.generalInformation,
                workflowRiskContext.sensitive,
                workflowRiskContext.status,
                workflowRiskContext.version,
                workflowRiskContext.allNetworkAccess,
                new RiskDefinitionContextItem(
                    localization.contextItems.identities.text({
                        identities:
                            <InlineEntities
                                entityIdsOrModels={risk.readPermissionActionIdentityIds}
                                entityTypeName={Contract.TypeNames.AadDirectoryIdentity}
                                namePluralizer={localization.contextItems.identities.identities}
                                variant="itemCountAndType"/>,
                        identitiesTypesPart:
                            _.isEmpty(risk.readPermissionActionGuestUserIds) && _.isEmpty(risk.readPermissionActionVendorApplicationServicePrincipalIds)
                                ? ""
                                : localization.contextItems.identities.identitiesTypesPart.text({
                                    identitiesTypes:
                                        localizeList(
                                            _.filter([
                                                _.isEmpty(risk.readPermissionActionGuestUserIds)
                                                    ? undefined
                                                    : <InlineEntities
                                                        entityIdsOrModels={risk.readPermissionActionGuestUserIds}
                                                        entityTypeName={Contract.TypeNames.AadDirectoryUser}
                                                        namePluralizer={localization.contextItems.identities.identitiesTypesPart.type.guestUsers}
                                                        variant="itemCountAndType"/>,
                                                _.isEmpty(risk.readPermissionActionVendorApplicationServicePrincipalIds)
                                                    ? undefined
                                                    : <InlineEntities
                                                        entityIdsOrModels={risk.readPermissionActionVendorApplicationServicePrincipalIds}
                                                        entityTypeName={Contract.TypeNames.AadDirectoryApplicationServicePrincipal}
                                                        namePluralizer={localization.contextItems.identities.identitiesTypesPart.type.vendorApplications}
                                                        variant="itemCountAndType"/>]))
                                })
                    })),
                _.isEmpty(secretParameterNames) && _.isEmpty(risk.secretActionNames)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.secretInputs.text({
                            inputs:
                                localizeList(
                                    _.filter([
                                        _.isEmpty(secretParameterNames)
                                            ? undefined
                                            : <InlineItems
                                                items={secretParameterNames}
                                                namePluralizer={localization.contextItems.secretInputs.parameters}
                                                variant="itemCountAndType"/>,
                                        _.isEmpty(risk.secretActionNames)
                                            ? undefined
                                            : <InlineItems
                                                items={risk.secretActionNames}
                                                namePluralizer={localization.contextItems.secretInputs.actions}
                                                variant="itemCountAndType"/>
                                    ])),
                            translatedWorkflowTypeName
                        })),
                secretRiskContext.getExcludedSecretsContextItem(
                    risk.excludedSecrets,
                    risk.typeName,
                    risk.tenantId),
                _.isEmpty(risk.secureOutputDisabledKeyVaultActionNames)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.secureOutputDisabledKeyVault.text({
                            keyVaultActions:
                                <InlineItems
                                    items={risk.secureOutputDisabledKeyVaultActionNames}
                                    namePluralizer={localization.contextItems.secureOutputDisabledKeyVault.keyVaultActions}
                                    variant="itemCountAndType"/>,
                            translatedWorkflowTypeName
                        })),
                workflowRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <Fragment>
                        {exclude &&
                            <SecretExclusionAction
                                riskPolicyConfiguration={riskPolicyConfiguration}
                                onExcludeChanged={setExclude}/>}
                        <TextViewer
                            actionsElement={
                                UserHelper.hasAnyScopePermissions(riskModel.risk.scopeIds, Contract.IdentityPermission.SecurityWrite)
                                    ? <Button
                                        sx={{
                                            "&:hover": {
                                                backgroundColor: theme.palette.textEditor.background
                                            },
                                            backgroundColor: theme.palette.textEditor.background,
                                            margin: theme.spacing(1),
                                            padding: theme.spacing(1, 3)
                                        }}
                                        variant="outlined"
                                        onClick={() => setExclude(true)}>
                                        {localization.excludeAction()}
                                    </Button>
                                    : undefined}
                            actionsElementSx={{ backgroundColor: "unset" }}
                            format="json"
                            highlightedLines={risk.definition.highlightedLines}
                            startLine={risk.definition.startLine}
                            text={risk.definition.text}/>
                    </Fragment>,
                    localization.sections.definition())]
        });
}