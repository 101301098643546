import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../components";
import { useGetAzureKeyVaultVaultRiskContext } from "../contexts";

export function useAzureKeyVaultVaultEventDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureKeyVaultVaultEventDisabledRisk;
    const vaultModel = entityModelStore.useGet(risk.entityId) as Contract.AzureKeyVaultVaultModel;

    const getAzureKeyVaultVaultRiskContext = useGetAzureKeyVaultVaultRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureKeyVaultVaultEventDisabledRiskDefinition",
            () => ({
                description: "{{vault}} logging is not enabled",
                sections: {
                    resolution: {
                        step1: "Click the **Add diagnostic setting** button",
                        step2: "Under **categories** section, check the **Audit Logs** option",
                        step3: "Select the appropriate option/s in **Destination details**",
                        step4: "Click **Save** to confirm"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            vault:
                <Entity
                    entityIdOrModel={vaultModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.Diagnostics}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const vaultRiskContext = getAzureKeyVaultVaultRiskContext(vaultModel);
            return [
                vaultRiskContext.generalInformation,
                vaultRiskContext.sensitive,
                vaultRiskContext.vaultObjectsExist,
                vaultRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}