import { Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { Fragment } from "react";
import { Contract, Entity, entityModelStore, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { useDefinition } from "./hooks/useDefinition";

type EntityExternalConsoleLinkProps = {
    entityId: string;
    page?: Contract.AzureConsoleEntityPage;
    translatedTabName?: string;
};

export function EntityExternalConsoleLink({ entityId, page = Contract.AzureConsoleEntityPage.Overview, translatedTabName = undefined }: EntityExternalConsoleLinkProps) {
    const entityModel = entityModelStore.useGet(entityId);
    const definition = useDefinition(entityModel, page);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.entityExternalConsoleLink",
            () => ({
                description: {
                    withoutTab: "In Azure Portal, open the {{entityExternalConsoleLink}} of {{translatedEntityTypeName}} **{{entityId}}**",
                    withTab: "In Azure Portal, open the {{entityExternalConsoleLink}} of {{translatedEntityTypeName}} **{{entityId}}** and navigate to the **{{translatedTabName}}** tab"
                },
                entityPage: {
                    link: "{{name}} page",
                    name: {
                        [Contract.AzureConsoleEntityPage.AccessControl]: "Networking",
                        [Contract.AzureConsoleEntityPage.AccessKeys]: "Access keys",
                        [Contract.AzureConsoleEntityPage.AccessPolicies]: "Access policies",
                        [Contract.AzureConsoleEntityPage.ActiveDirectoryAdmin]: "Microsoft Microsoft Entra ID",
                        [Contract.AzureConsoleEntityPage.ActiveDirectoryAdmins]: "Authentication",
                        [Contract.AzureConsoleEntityPage.ActivityLog]: "Activity log",
                        [Contract.AzureConsoleEntityPage.Authentication]: "Authentication",
                        [Contract.AzureConsoleEntityPage.Certificates]: "Certificates",
                        [Contract.AzureConsoleEntityPage.CertificatesReact]: "Certificates",
                        [Contract.AzureConsoleEntityPage.ClusterConfiguration]: "Cluster configuration",
                        [Contract.AzureConsoleEntityPage.ClusterNodePools]: "Cluster Node pools",
                        [Contract.AzureConsoleEntityPage.Configuration]: "Configuration",
                        [Contract.AzureConsoleEntityPage.ConnectionSecurity]: "Connection security",
                        [Contract.AzureConsoleEntityPage.DataProtection]: "Data protection",
                        [Contract.AzureConsoleEntityPage.Diagnostics]: "Diagnostic settings",
                        [Contract.AzureConsoleEntityPage.DataEncryption]: "Data Encryption",
                        [Contract.AzureConsoleEntityPage.Disks]: "Disks",
                        [Contract.AzureConsoleEntityPage.Encryption]: "Encryption",
                        [Contract.AzureConsoleEntityPage.Firewall]: "Firewalls and virtual networks",
                        [Contract.AzureConsoleEntityPage.InboundRules]: "Inbound security rules",
                        [Contract.AzureConsoleEntityPage.Keys]: "Keys",
                        [Contract.AzureConsoleEntityPage.Network]: "Networking",
                        [Contract.AzureConsoleEntityPage.Networking]: "Networking",
                        [Contract.AzureConsoleEntityPage.NetworkingHub]: "Networking",
                        [Contract.AzureConsoleEntityPage.NetworkSettings]: "Networking",
                        [Contract.AzureConsoleEntityPage.Overview]: "Overview",
                        [Contract.AzureConsoleEntityPage.Properties]: "Properties",
                        [Contract.AzureConsoleEntityPage.RoleAssignments]: "Access control (IAM)",
                        [Contract.AzureConsoleEntityPage.Secrets]: "Secrets",
                        [Contract.AzureConsoleEntityPage.SecurityAlertPolicy]: "Microsoft Defender for Cloud",
                        [Contract.AzureConsoleEntityPage.ServerAuditing]: "Auditing",
                        [Contract.AzureConsoleEntityPage.ServerConfigurations]: "Server parameters",
                        [Contract.AzureConsoleEntityPage.ServerTransparentDataEncryption]: "Transparent data encryption",
                        [Contract.AzureConsoleEntityPage.VirtualNetworkSubnets]: "Subnets"
                    }
                }
            }));

    return (
        <Fragment>
            {
                entityModel.entity.typeName !== Contract.TypeNames.AzureRoot &&
                entityModel.entity.typeName !== Contract.TypeNames.AzureStorageStorageAccountFileShare &&
                entityModel.entity.typeName !== Contract.TypeNames.AzureStorageStorageAccountTable &&
                (_.isNil(translatedTabName)
                    ? localization.description.withoutTab
                    : localization.description.withTab)({
                    entityExternalConsoleLink:
                        <Link
                            urlOrGetUrl={definition.url}
                            variant="external">
                            {localization.entityPage.link({ name: localization.entityPage.name.translate(page) })}
                        </Link>,
                    entityId:
                        <Entity
                            entityIdOrModel={entityModel.entity.id}
                            linkOptions={{ disabled: true }}
                            variant="text"/>,
                    translatedEntityTypeName:
                        entityTypeNameTranslator(
                            entityModel.entity.typeName,
                            { variant: "text" }),
                    translatedTabName
                })}
        </Fragment>);
}