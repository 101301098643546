import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function MicrosoftIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M12.6923 3H21V11.3077H12.6923V3Z"
                fill="#80BB42"/>
            <path
                d="M3 12.6923H11.3077V21H3V12.6923Z"
                fill="#329FD9"/>
            <path
                d="M12.6923 12.6923H21V21H12.6923V12.6923Z"
                fill="#FDB715"/>
            <path
                d="M3 3H11.3077V11.3077H3V3Z"
                fill="#F05226"/>
        </SvgIcon>);
}