import { Optional, UnexpectedError } from "@infrastructure";
import _, { Dictionary, Function0 } from "lodash";

export function map<TValue extends string, TMappedValue>(
    value: Optional<TValue>,
    valueMap: Dictionary<Function0<TMappedValue>>,
    defaultValue?: Function0<TMappedValue>): TMappedValue {
    const mappedValue =
        _.isNil(value)
            ? defaultValue
            : valueMap[value] ?? defaultValue;
    if (_.isNil(mappedValue)) {
        throw new UnexpectedError("value", value);
    }
    return mappedValue();
}