import { useMemo } from "react";
import { useAadDefaultDirectoryEntityDefinition, useAadDirectoryApplicationCertificateDefinition, useAadDirectoryApplicationDefinition, useAadDirectoryApplicationSecretDefinition, useAadDirectoryDirectoryRoleDefinitionDefinition, useAadDirectoryDirectoryRoleManagementPolicyAssignmentDefinition, useAadDirectoryGroupDefinition, useAadDirectoryServicePrincipalDefinition, useAadDirectoryUserDefinition, useAadTenantEntityDefinition } from ".";
import { Contract } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAadDirectoryEntityDefinition(definitionData: DefinitionData) {
    const useDefinition =
        useMemo(
            () => {
                switch (definitionData.entityTypeEntitiesViewName) {
                    case Contract.TypeNames.AadDirectoryApplication:
                        return useAadDirectoryApplicationDefinition;
                    case Contract.TypeNames.AadDirectoryApplicationCertificate:
                        return useAadDirectoryApplicationCertificateDefinition;
                    case Contract.TypeNames.AadDirectoryApplicationSecret:
                        return useAadDirectoryApplicationSecretDefinition;
                    case Contract.TypeNames.AadDirectoryDirectoryRoleDefinition:
                        return useAadDirectoryDirectoryRoleDefinitionDefinition;
                    case Contract.TypeNames.AadDirectoryDirectoryRoleManagementPolicyAssignment:
                        return useAadDirectoryDirectoryRoleManagementPolicyAssignmentDefinition;
                    case Contract.TypeNames.AadDirectoryGroup:
                        return useAadDirectoryGroupDefinition;
                    case Contract.TypeNames.AadDirectoryServicePrincipal:
                        return useAadDirectoryServicePrincipalDefinition;
                    case Contract.TypeNames.AadDirectoryUser:
                        return useAadDirectoryUserDefinition;
                    case Contract.TypeNames.AadTenantEntity:
                        return useAadTenantEntityDefinition;
                    default:
                        return useAadDefaultDirectoryEntityDefinition;
                }
            },
            []);

    return useDefinition(definitionData);
}