import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsCloudFormationStackDriftStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsCloudFormationStackDriftStatusTranslator",
            () => ({
                [Contract.TypeNames.AwsCloudFormationStackDriftStatus]: {
                    [Contract.AwsCloudFormationStackDriftStatus.Drifted]: "Drifted",
                    [Contract.AwsCloudFormationStackDriftStatus.InSync]: "In-Sync",
                    [Contract.AwsCloudFormationStackDriftStatus.NotChecked]: "Not Checked",
                    [Contract.AwsCloudFormationStackDriftStatus.Unknown]: "Unknown"
                }
            }));

    return (status: Contract.AwsCloudFormationStackDriftStatus) =>
        localization[Contract.TypeNames.AwsCloudFormationStackDriftStatus][status]();
}