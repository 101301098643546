import { UserConsoleAppView } from "../../common/controllers/types.generated";

export const UserView = {
    [UserConsoleAppView.ApproverUserPermissionRequests]: "approverUserPermissionRequests",
    [UserConsoleAppView.AuditEvents]: "auditEvents",
    [UserConsoleAppView.Customers]: "customers",
    [UserConsoleAppView.GranteeUserPermissionRequests]: "granteeUserPermissionRequests",
    [UserConsoleAppView.PermissionEligibilities]: "permissionEligibilities",
    [UserConsoleAppView.SignIn]: "signIn",
    [UserConsoleAppView.SignInError]: "signInError"
};