import { EmptyMessage, EmptyMessageText, Message, Sx } from "@infrastructure";
import { Box, Stack, SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React, { Children, isValidElement, ReactNode, useMemo } from "react";
import { useTheme } from "../..";
import { InfoItemLocation } from "../InfoItem";

export type InfoCardProps = {
    actionsElement?: ReactNode;
    children: ReactNode;
    columnContainerSx?: SxProps;
    columns?: boolean;
    containerSx?: SxProps;
    emptyMessage?: EmptyMessageText;
    itemContainerSx?: SxProps;
    location?: InfoItemLocation;
    minChildElementLength?: number;
    title?: ReactNode;
    titleHelp?: string | ReactNode;
    titleInfoElement?: ReactNode;
    titleSx?: SxProps;
};

export function InfoCard({ actionsElement, children, columnContainerSx, columns, containerSx, emptyMessage, itemContainerSx, minChildElementLength = 1, title, titleHelp, titleInfoElement, titleSx }: InfoCardProps) {
    const childElements = Children.toArray(children);
    const childElementChunks =
        useMemo(
            () => {
                if (columns && _.size(childElements) > minChildElementLength) {
                    return _.chunk(childElements, Math.ceil(childElements.length / 2));
                } else {
                    return [childElements];
                }
            },
            [childElements, columns]);

    const theme = useTheme();
    return (
        <Stack sx={containerSx}>
            {(!_.isNil(title) || !_.isNil(actionsElement)) &&
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between">
                    {title &&
                        <Stack
                            alignItems="center"
                            direction="row"
                            style={{ flex: 1 }}>
                            {isValidElement(title)
                                ? title
                                : <Typography
                                    sx={
                                        Sx.combine({
                                            fontSize: "14px",
                                            fontWeight: 500,
                                            lineHeight: "20px",
                                            margin: theme.spacing(1, 0, 1, 0),
                                            paddingRight: theme.spacing(0.5)
                                        },
                                        titleSx)}
                                    variant="h3">
                                    {title}
                                </Typography>}
                            {!_.isNil(titleInfoElement) &&
                                titleInfoElement}
                            {!_.isNil(titleHelp) &&
                                <Message
                                    level="info"
                                    title={titleHelp}
                                    variant="minimal"/>}
                        </Stack>}
                    {!_.isNil(actionsElement) &&
                        <Box>
                            {actionsElement}
                        </Box>}
                </Stack>}
            {_.isEmpty(childElements) && !_.isNil(emptyMessage)
                ? <EmptyMessage
                    message={emptyMessage.getText()}
                    size="small"
                    verticalCenter={true}/>
                : <Stack
                    direction="row"
                    sx={{ position: "relative" }}>
                    {_.map(
                        childElementChunks,
                        (childElementChunk, childElementChunkIndex) =>
                            <Box
                                key={childElementChunkIndex}
                                sx={{
                                    paddingRight: theme.spacing(2),
                                    width: "100%",
                                    ...(childElementChunks.length === 2 && {
                                        width: "50%"
                                    }),
                                    ...columnContainerSx
                                }}>
                                <Stack>
                                    {_.map(
                                        childElementChunk,
                                        (childElement, childElementIndex) =>
                                            <Stack
                                                key={childElementIndex}
                                                sx={
                                                    Sx.combine(
                                                        {
                                                            padding: theme.spacing(0, 0, 1, 0),
                                                            width: "100%"
                                                        },
                                                        itemContainerSx)}>
                                                {childElement}
                                            </Stack>)}
                                </Stack>
                            </Box>)}
                </Stack>}
        </Stack>);
}