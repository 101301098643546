import { SvgIcon, SvgIconProps } from "@mui/material";
import React, { forwardRef, memo, Ref } from "react";

const InfoIconForwardRef = memo(forwardRef(InfoIcon));
export { InfoIconForwardRef as InfoIcon };

function InfoIcon(props: SvgIconProps, ref: Ref<SVGSVGElement>) {
    return (
        <SvgIcon
            ref={ref}
            {...props}>
            <path
                clipRule="evenodd"
                d="M1 12C1 5.92472 5.92472 1 12 1C18.0753 1 23 5.92472 23 12C23 18.0753 18.0753 23 12 23C5.92472 23 1 18.0753 1 12ZM12 3C7.02928 3 3 7.02928 3 12C3 16.9707 7.02928 21 12 21C16.9707 21 21 16.9707 21 12C21 7.02928 16.9707 3 12 3ZM10.9922 8C10.9922 7.44772 11.4399 7 11.9922 7H12.0012C12.5535 7 13.0012 7.44772 13.0012 8C13.0012 8.55228 12.5535 9 12.0012 9H11.9922C11.4399 9 10.9922 8.55228 10.9922 8ZM11.9239 10.0271C12.1711 10.0602 12.5112 10.1488 12.8009 10.4365L12.8057 10.4413L12.8057 10.4413C13.0938 10.7314 13.1821 11.0723 13.2151 11.3187C13.2425 11.5232 13.2423 11.7609 13.2422 11.9607C13.2422 11.974 13.2422 11.9871 13.2422 12V17C13.2422 17.5523 12.7945 18 12.2422 18C11.6899 18 11.2422 17.5523 11.2422 17V12C10.6899 12 10.2422 11.5523 10.2422 11C10.2422 10.4477 10.6899 10 11.2422 10C11.2551 10 11.2683 9.99999 11.2816 9.99998C11.4817 9.99986 11.7195 9.99971 11.9239 10.0271ZM11.662 12.01C11.662 12.01 11.6614 12.0099 11.6604 12.0097L11.662 12.01ZM11.2325 11.5821C11.2323 11.5809 11.2322 11.5803 11.2322 11.5803L11.2325 11.5821Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}