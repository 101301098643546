import { Action0, DirectedGraphModelNode, Optional, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React, { MutableRefObject } from "react";
import { EntityTypeMetadataModelHelper, ItemSelectionHelper } from "../../../../../..";
import { AccessDefinition, AccessDefinitionToolbarActions, ToolbarFilterId } from "../..";
import { AwsActionHelper } from "../../../../../../../tenants";
import { Contract, EntityController } from "../../../../../../controllers";
import { GraphColumnId } from "../../../../components";
import { AwsToolbarFilterId, getPermissionPathNodeContentAnchorPoint, getPermissionPathNodeContentSize, PermissionPathNodeContent, Permitters, Toolbar } from "./components";

export function useAwsDefinition(entityTypeName: string) {
    const entityTypeMetadataModel = EntityTypeMetadataModelHelper.get(entityTypeName);

    const localization =
        useLocalization(
            "common.access.hooks.useDefinition.hooks.useAwsDefinition",
            () => ({
                csvExport: {
                    fileNamePrefix: "AwsPermissions"
                }
            }));

    return new AccessDefinition(
        localization.csvExport.fileNamePrefix(),
        {
            entityTypeNames: {
                destinationEntityTypeName: Contract.TypeNames.AwsResource,
                serviceTypeName: Contract.TypeNames.AwsService,
                sourceEntityTypeName: Contract.TypeNames.AwsIamIdentity
            },
            getPermissionActionName: AwsActionHelper.getName,
            getServiceName: entityModel => (entityModel.entity as Contract.AwsService).shortName,
            permitterComponent: Permitters
        },
        (direction: Contract.EntityAccessDirection, entityId: string, filters: Contract.EntityControllerGetAccessDataRequestFilters, scope: Contract.EntityAccessScope) =>
            EntityController.getAccessGraph(
                new Contract.EntityControllerGetAwsAccessGraphRequest(
                    direction,
                    entityId,
                    filters as Contract.EntityControllerGetAwsAccessDataRequestFilters,
                    scope)),
        (direction: Contract.EntityAccessDirection, entityId: string, filters: Contract.EntityControllerGetAccessDataRequestFilters, scope: Contract.EntityAccessScope, selection: Contract.EntityControllerGetAccessListPermissionActionsRequestSelection, selectedPermissionPaths: Contract.AccessGraphPermissionPath[]) =>
            EntityController.getAccessListPermissionActions(
                new Contract.EntityControllerGetAwsAccessListPermissionActionsRequest(
                    direction,
                    entityId,
                    filters as Contract.EntityControllerGetAwsAccessDataRequestFilters,
                    scope,
                    selection,
                    _.map(
                        selectedPermissionPaths as Contract.AwsAccessGraphPermissionPath[],
                        permissionPath => permissionPath.identifier))),
        (direction: Contract.EntityAccessDirection, entityId: string, filters: Contract.EntityControllerGetAccessDataRequestFilters, scope: Contract.EntityAccessScope) =>
            new Contract.ReportControllerAwsAccessReportRequestDefinition(
                undefined,
                undefined,
                Contract.TypeNames.ReportControllerAwsAccessReportRequestDefinition,
                direction,
                entityId,
                scope,
                filters as Contract.EntityControllerGetAwsAccessDataRequestFilters),
        (filterMap: Dictionary<any>, destinationResourceTenantId?: string): Contract.EntityControllerGetAwsAccessDataRequestFilters => ({
            accessLevelSelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.AccessLevels]),
            destinationEntityIdSelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.DestinationEntityIds]),
            destinationResourceBuiltInEntityAttributeTypeNameSelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.DestinationResourceBuiltInEntityAttributeTypeNames]),
            destinationResourceTenantIdSelection:
                _.isNil(destinationResourceTenantId)
                    ? ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.DestinationResourceTenantIds])
                    : ItemSelectionHelper.toItemSelectionFromValues([destinationResourceTenantId]),
            excessivePermissionEvaluationStartDate: filterMap[AwsToolbarFilterId.ExcessivePermissionEvaluationStartDate] as Optional<string>,
            groupIdSelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.GroupIds]),
            identityIdSelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.IdentityIds]),
            originatorEntityIdSelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.OriginatorEntityIds]),
            originatorEntityTypeNameSelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.OriginatorEntityTypeNames]),
            permissionActionExcessiveness: filterMap[AwsToolbarFilterId.PermissionActionExcessiveness] as Optional<Contract.PermissionActionExcessiveness>,
            permissionActionRiskCategorySelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.PermissionActionRiskCategories]),
            permissionActionSelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.PermissionActions]),
            permissionActionSeveritySelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.PermissionActionSeverity]),
            permitterIdSelection: ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[ToolbarFilterId.PermitterIds]),
            vendorRoles: filterMap[AwsToolbarFilterId.VendorRoles] as boolean
        }),
        entityTypeMetadataModel.awsOutboundAccessGraph
            ? Contract.EntityAccessDirection.Outbound
            : Contract.EntityAccessDirection.Inbound,
        {
            getDirectedGraphModelNode: (
                permissionPath: Contract.AccessGraphPermissionPath,
                toolbarActionsRef: MutableRefObject<Optional<AccessDefinitionToolbarActions>>,
                onClick: Action0) =>
                new DirectedGraphModelNode(
                    permissionPath.id,
                    GraphColumnId.PermissionPaths,
                    <PermissionPathNodeContent
                        permissionPath={permissionPath as Contract.AwsAccessGraphPermissionPath}
                        toolbarActionsRef={toolbarActionsRef}/>,
                    getPermissionPathNodeContentSize(permissionPath as Contract.AwsAccessGraphPermissionPath),
                    edge =>
                        getPermissionPathNodeContentAnchorPoint(
                            edge.getAnchorPlacement(permissionPath.id),
                            permissionPath as Contract.AwsAccessGraphPermissionPath),
                    {
                        onClick
                    }),
            getEntityPermissionPath:
                (entityId: string, permissionPaths: Contract.AccessGraphPermissionPath[]) =>
                    _(permissionPaths as Contract.AwsAccessGraphPermissionPath[]).
                        filter(permissionPath => permissionPath.identifier.principalPolicyId === entityId).
                        orderBy(permissionPath => !_.isNil(permissionPath.identifier.resourcePermitterType)).
                        first()
        },
        Toolbar);
}