//@ts-nocheck
import Mustache from "mustache";
import { Contract } from "../../../common";

export class GciConsoleUrlBuilder {
    private static readonly _groupMembersUrlTemplate = "https://admin.google.com/u/1/ac/groups/{{uniqueId}}/members";
    private static readonly _groupUrlTemplate = "https://admin.google.com/ac/groups/{{uniqueId}}";
    private static readonly _tenantEntityUrlTemplate = "https://admin.google.com/u/1/ac/accountsettings";
    private static readonly _userGroupsUrlTemplate = "https://admin.google.com/u/1/ac/users/{{uniqueId}}/groups";
    private static readonly _userUrlTemplate = "https://admin.google.com/ac/users/{{uniqueId}}";

public static GetGroupMembersUrl(uniqueId: string) {
        return Mustache.render(
            GciConsoleUrlBuilder._groupMembersUrlTemplate,
            {
                "uniqueId": uniqueId
            });
    }

public static GetGroupUrl(uniqueId: string) {
        return Mustache.render(
            GciConsoleUrlBuilder._groupUrlTemplate,
            {
                "uniqueId": uniqueId
            });
    }

public static GetTenantEntityUrl() {
        return GciConsoleUrlBuilder._tenantEntityUrlTemplate;
    }

public static GetUserGroupsUrl(uniqueId: string) {
        return Mustache.render(
            GciConsoleUrlBuilder._userGroupsUrlTemplate,
            {
                "uniqueId": uniqueId
            });
    }

public static GetUserUrl(uniqueId: string) {
        return Mustache.render(
            GciConsoleUrlBuilder._userUrlTemplate,
            {
                "uniqueId": uniqueId
            });
    }
}