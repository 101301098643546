import { ActionMenuItem, AddIcon, DataTable, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableSearchOptions, DataTableSortDirection, DataTableSortType, Dialog, makeContextProvider, Menu, StringHelper, useChangeEffect, useExecuteOperation, useLocalization } from "@infrastructure";
import { Group as GroupIcon, Person as UserIcon } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import _, { Function0 } from "lodash";
import React, { Fragment, useRef } from "react";
import { AdministrationController, Contract, useIdentityRoleTranslator, useTheme } from "../../../../../../common";
import { ActionsCell, AddOrEditPrincipal } from "./components";

class SupportPrincipalsContext {
    constructor(
        public addOrEditPrincipalOpen: undefined | "user" | "group" | Contract.AdministrationControllerGetSupportPrincipalsResponseSupportPrincipalData,
        public executeGetSupportPrincipals: Function0<Promise<void>>,
        public supportPrincipalDatas: Contract.AdministrationControllerGetSupportPrincipalsResponseSupportPrincipalData[]) {
    }
}

export const [useSupportPrincipalsContext, useSetSupportPrincipalsContext, useSupportPrincipalsContextProvider] = makeContextProvider<SupportPrincipalsContext>();

export function SupportPrincipals() {
    const [{ supportPrincipalDatas }, executeGetSupportPrincipals] =
        useExecuteOperation(
            SupportPrincipals,
            AdministrationController.getSupportPrincipals);

    const [context, setContext, ContextProvider] =
        useSupportPrincipalsContextProvider(
            () =>
                new SupportPrincipalsContext(
                    undefined,
                    executeGetSupportPrincipals,
                    supportPrincipalDatas));

    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => {
            setContext(
                context => ({
                    ...context,
                    supportPrincipalDatas
                }));

            dataTableActionsRef.current!.reset();
        },
        [supportPrincipalDatas]);

    const identityRoleTranslator = useIdentityRoleTranslator();
    const localization =
        useLocalization(
            "views.customer.administration.supportPrincipals",
            () => ({
                actions: {
                    add: {
                        group: "Add group",
                        title: "Add",
                        user: "Add user"
                    },
                    search: "Search"
                },
                columns: {
                    application: {
                        false: "Organization",
                        title: "Level",
                        true: "Application"
                    },
                    displayName: "Principal",
                    role: "Role"
                },
                expirationTime: {
                    ever: "{{expirationTime | TimeFormatter.longDateTime}} ({{expirationTime | TimeFormatter.humanizePastDuration}})",
                    never: "Never",
                    title: "Expiration Time"
                }
            }));

    const searchFilterId = "searchText";
    const theme = useTheme();
    return (
        <ContextProvider>
            {!_.isNil(context.addOrEditPrincipalOpen) &&
                <Dialog
                    variant="editor"
                    onClose={
                        () =>
                            setContext(
                                context => ({
                                    ...context,
                                    addOrEditPrincipalOpen: undefined
                                }))}>
                    <AddOrEditPrincipal/>
                </Dialog>}
            <DataTable
                actionsRef={dataTableActionsRef}
                fetchItems={
                    (filterMap, sort) =>
                        _(supportPrincipalDatas).
                            filter(
                                item =>
                                    StringHelper.search(
                                        item.application
                                            ? localization.columns.application.true()
                                            : localization.columns.application.false(),
                                        filterMap[searchFilterId]) ||
                                    StringHelper.search(item.identifier, filterMap[searchFilterId]) ||
                                    StringHelper.search(identityRoleTranslator(item.role), filterMap[searchFilterId])).
                            orderBy(
                                [
                                    item => {
                                        switch (sort?.columnId) {
                                            case SupportPrincipalsColumnId.Application:
                                                return item.application;
                                            case SupportPrincipalsColumnId.DisplayName:
                                                return StringHelper.getSortValue(item.identifier);
                                            case SupportPrincipalsColumnId.Role:
                                                return StringHelper.getSortValue(identityRoleTranslator(item.role));
                                            default:
                                                return undefined;
                                        }
                                    },
                                    item => item.group,
                                    item => item.application,
                                    item => StringHelper.getSortValue(identityRoleTranslator(item.role)),
                                    item => StringHelper.getSortValue(item.identifier)
                                ],
                                [
                                    sort?.direction == DataTableSortDirection.Ascending
                                        ? "asc"
                                        : "desc",
                                    "desc",
                                    "desc",
                                    "asc",
                                    "asc"
                                ]).
                            value()}
                getItemId={(item: Contract.AdministrationControllerGetSupportPrincipalsResponseSupportPrincipalData) => item.identifier}
                searchOptions={
                    new DataTableSearchOptions(
                        searchFilterId,
                        localization.actions.search())}>
                <DataTableAction>
                    <Menu
                        itemsOrGetItems={[
                            new ActionMenuItem(
                                () =>
                                    setContext(
                                        context =>
                                            ({
                                                ...context,
                                                addOrEditPrincipalOpen: "user"
                                            })),
                                localization.actions.add.user(),
                                { icon: <UserIcon/> }),
                            new ActionMenuItem(
                                () =>
                                    setContext(
                                        context =>
                                            ({
                                                ...context,
                                                addOrEditPrincipalOpen: "group"
                                            })),
                                localization.actions.add.group(),
                                { icon: <GroupIcon/> })]}
                        variant="bottomRight">
                        <Button
                            size="small"
                            startIcon={<AddIcon/>}>
                            {localization.actions.add.title()}
                        </Button>
                    </Menu>
                </DataTableAction>
                <DataTableColumn
                    id={SupportPrincipalsColumnId.DisplayName}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AdministrationControllerGetSupportPrincipalsResponseSupportPrincipalData>) =>
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={1}>
                                {item.group
                                    ? <GroupIcon
                                        sx={{
                                            color: theme.palette.text.primary,
                                            fontSize: "18px"
                                        }}/>
                                    : <UserIcon
                                        sx={{
                                            color: theme.palette.text.primary,
                                            fontSize: "18px"
                                        }}/>}
                                <Typography>
                                    {item.identifier}
                                </Typography>
                            </Stack>}
                    title={localization.columns.displayName()}/>
                <DataTableColumn
                    id={SupportPrincipalsColumnId.Application}
                    itemProperty={
                        (item: Contract.AdministrationControllerGetSupportPrincipalsResponseSupportPrincipalData) =>
                            item.application
                                ? localization.columns.application.true()
                                : localization.columns.application.false()}
                    title={localization.columns.application.title()}/>
                <DataTableColumn
                    id={SupportPrincipalsColumnId.ExpirationTime}
                    itemProperty={
                        (item: Contract.AdministrationControllerGetSupportPrincipalsResponseSupportPrincipalData) =>
                            _.isNil(item.expirationTime)
                                ? localization.expirationTime.never()
                                : localization.expirationTime.ever({ expirationTime: item.expirationTime })}
                    sortOptions={{ type: DataTableSortType.Date }}
                    title={localization.expirationTime.title()}/>
                <DataTableColumn
                    id={SupportPrincipalsColumnId.Role}
                    itemProperty={(item: Contract.AdministrationControllerGetSupportPrincipalsResponseSupportPrincipalData) => identityRoleTranslator(item.role)}
                    title={localization.columns.role()}/>
                <DataTableColumn
                    id={SupportPrincipalsColumnId.Actions}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AdministrationControllerGetSupportPrincipalsResponseSupportPrincipalData>) =>
                            item.application
                                ? <Fragment/>
                                : <ActionsCell item={item}/>}
                    sortOptions={{ enabled: false }}/>
            </DataTable>
        </ContextProvider>);
}

enum SupportPrincipalsColumnId {
    Actions = "actions",
    Application = "application",
    DisplayName = "displayName",
    ExpirationTime = "expirationTime",
    Role = "role"
}