import _ from "lodash";
import React from "react";
import { DataTableColumn, DataTableSortType, optionalTableCell, useLocalization } from "@infrastructure";
import { useOpCommonResourceDefinition } from ".";
import { Contract, InlineResourceTags, PagedEntityResourceTagFilter, ResourceTagHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useCommonKubernetesResourceDefinition } from "../useCommonKubernetesResourceDefinition";

export function useOpCommonKubernetesResourceDefinition(definitionData: DefinitionData) {
    const commonEntityDefinition = useOpCommonResourceDefinition(definitionData);
    const commonKubernetesResourceDefinition = useCommonKubernetesResourceDefinition(definitionData);

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.op.useOpCommonKubernetesResourceDefinition",
            () => ({
                columns: {
                    tags: "Kubernetes Labels"
                }
            }));
    return {
        columns: {
            ...commonEntityDefinition.columns,
            ...commonKubernetesResourceDefinition.columns,
            tagsColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.OpResourceModel) => ({
                                [localization.columns.tags()]:
                                    _((item.entity as Contract.OpResource).tags).
                                        map(ResourceTagHelper.getDisplayName).
                                        join("\n")
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityResourceTagFilter
                                    entityTypeName={definitionData.entityTypeEntitiesViewName}
                                    placeholder={localization.columns.tags()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.ResourceTags}
                    key={Contract.EntityModelProperty.ResourceTags}
                    render={
                        optionalTableCell<Contract.OpResourceModel>(
                            (item: Contract.OpResourceModel) =>
                                _.isEmpty((item.entity as Contract.OpResource).tags)
                                    ? undefined
                                    : <InlineResourceTags tags={(item.entity as Contract.OpResource).tags}/>)}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.tags()}/>
        }
    };
}