﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useGcpCommonEncryptedResourceInfoElements, useGcpCommonScopeResourceInfoItemElements, useGcpCommonServiceAccountOriginatorScopeResourceInfoElements, useGcpDefaultResourceInfoItemElements } from "..";
import { Contract, CustomerConsoleAppUrlHelper, InfoItem } from "../../../../../../../../../../../common";
import { useGcpContainerClusterStatusTranslator } from "../../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../../components";
import { GcpScopeResourceRoleBindings, KubernetesClusterResourcesInfoCard } from "../../../components";
import { useCommonKubernetesClusterInfoElements } from "../../useCommonKubernetesClusterInfoElements";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { AuthorizedSubnetDatasInfoCard, ClusterNetworkingInfoCard } from "./components";

export function useGcpContainerClusterDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const clusterModel = scopeResourceModel as Contract.GcpContainerClusterModel;
    const cluster = clusterModel.entity as Contract.GcpContainerCluster;
    const autopilot = cluster.mode === Contract.GcpContainerClusterMode.Autopilot;
    const commonEncryptedResourceInfoElements = useGcpCommonEncryptedResourceInfoElements(scopeResourceModel);
    const commonKubernetesClusterInfoElements = useCommonKubernetesClusterInfoElements(_.as<Contract.IKubernetesClusterModel>(scopeResourceModel));
    const commonScopeResourcesInfoElements = useGcpCommonScopeResourceInfoItemElements(scopeResourceModel);
    const commonServiceAccountOriginatorScopeResourceInfoElements = useGcpCommonServiceAccountOriginatorScopeResourceInfoElements(scopeResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);

    const containerClusterStatusTranslator = useGcpContainerClusterStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpContainerClusterDefinition",
            () => ({
                info: {
                    items: {
                        computeInstanceGroupIds: "Instance Groups",
                        mode: "Mode",
                        network: {
                            nodePublicIpAddressExists: {
                                false: "Private",
                                title: "Cluster Type",
                                true: "Public"
                            }
                        },
                        nodePoolIds: "Node Pools",
                        shieldedNodes: {
                            false: "Disabled",
                            title: "Shielded GKE Nodes",
                            true: "Enabled"
                        },
                        status: "Status",
                        version: "Kubernetes Version",
                        vpcId: "VPC Network",
                        workloadIdentityEnabled: {
                            false: "Disabled",
                            title: "Workload Identity",
                            true: "Enabled"
                        },
                        workloadIdentityPoolRawId: "Workload Identity Pool"
                    }
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings",
                    resources: "Resources"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <KubernetesClusterResourcesInfoCard clusterId={clusterModel.entity.id}/>,
                localization.tabs.resources(),
                "resources"),
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GcpScopeResourceRoleBindings
                    csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                    scopeResourceModel={scopeResourceModel}/>,
                localization.tabs.resourceRoleBindings(),
                "resourceRoleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    commonScopeResourcesInfoElements.accessLevelInfoItemElement,
                    commonEncryptedResourceInfoElements.encryptionExists,
                    commonEncryptedResourceInfoElements.kmsEncryptionKeys,
                    commonServiceAccountOriginatorScopeResourceInfoElements.serviceAccount,
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={containerClusterStatusTranslator(cluster.status)}/>,
                    <InfoItem
                        key="mode"
                        location="all"
                        title={localization.info.items.mode()}
                        value={cluster.mode}/>,
                    <InfoItem
                        key="networkNodePublicIpAddressExists"
                        location="all"
                        title={localization.info.items.network.nodePublicIpAddressExists.title()}
                        value={
                            cluster.network.nodePublicIpAddressExists
                                ? localization.info.items.network.nodePublicIpAddressExists.true()
                                : localization.info.items.network.nodePublicIpAddressExists.false()}/>,
                    <InfoItem
                        key="version"
                        location="all"
                        title={localization.info.items.version()}
                        value={cluster.version}/>,
                    commonKubernetesClusterInfoElements.createApiConnectivityStatusInfoItemElement(CustomerConsoleAppUrlHelper.getDocsOnboardClusterViaTenableConnectorRelativeUrl()),
                    <InfoItem
                        key="workloadIdentityEnabled"
                        title={localization.info.items.workloadIdentityEnabled.title()}
                        value={
                            cluster.workloadIdentity
                                ? localization.info.items.workloadIdentityEnabled.true()
                                : localization.info.items.workloadIdentityEnabled.false()}/>,
                    <InfoItem
                        key="workloadIdentityPoolRawId"
                        title={localization.info.items.workloadIdentityPoolRawId()}
                        value={
                            _.isNil(cluster.workloadIdentityPoolRawId)
                                ? undefined
                                : cluster.workloadIdentityPoolRawId}/>,
                    <InfoItem
                        key="shieldedNodes"
                        title={localization.info.items.shieldedNodes.title()}
                        value={
                            cluster.shieldedNodes
                                ? localization.info.items.shieldedNodes.true()
                                : localization.info.items.shieldedNodes.false()}/>,
                    autopilot
                        ? undefined
                        : <EntitiesInfoItem
                            entityIdsOrModels={clusterModel.nodePoolIds}
                            entityTypeName={Contract.TypeNames.GcpContainerClusterNodePool}
                            key="nodePoolIds"
                            title={localization.info.items.nodePoolIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={clusterModel.computeInstanceGroupIds}
                        entityTypeName={Contract.TypeNames.GcpComputeInstanceGroup}
                        key="computeInstanceGroupIds"
                        title={localization.info.items.computeInstanceGroupIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={clusterModel.vpcIdReference}
                        entityTypeName={Contract.TypeNames.GcpComputeVpc}
                        key="vpcId"
                        location="miniGlance"
                        title={localization.info.items.vpcId()}/>
                ]}
                options={options?.infoOptions}>
                <ClusterNetworkingInfoCard
                    network={cluster.network}
                    subnetId={clusterModel.subnetIdReference}
                    url={cluster.url}
                    vpcId={clusterModel.vpcIdReference}/>
                {!_.isEmpty(cluster.network.authorizedSubnetDatas) &&
                    <AuthorizedSubnetDatasInfoCard authorizedSubnetDatas={cluster.network.authorizedSubnetDatas!}/>}
            </Info>
    });
}