import { Action1, InlineItems, Link, TimeFormatter, UnexpectedError, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, Identity, RiskTypeMetadataModelHelper, useRiskResolutionReasonTranslator, useRiskStatusSummeryTranslator, useSeverityTranslator, useTheme } from "../../../../../../../../../../../../../common";
import { useRiskContext } from "../../../../../../../../../hooks";
import { Edit, View } from "./RiskStatusUpdateAuditEvent";

type RiskAuditEventProps = {
    auditEventModel: Contract.AuditEventModel;
    onChange: Action1<Contract.AuditEventModel>;
};

export function RiskAuditEvent({ auditEventModel, onChange }: RiskAuditEventProps) {
    const { riskModel } = useRiskContext();
    const [edit, setEdit] = useState(false);
    const severityTranslator = useSeverityTranslator();
    const riskResolutionReasonTranslator = useRiskResolutionReasonTranslator();
    const riskStatusSummeryTranslator = useRiskStatusSummeryTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.profile.core.auditEventsSection.auditEvent.riskAuditEvent",
            () => ({
                mails: [
                    "1 mail",
                    "{{count | NumberFormatter.humanize}} mails"
                ],
                pullRequest: {
                    link: "Pull Request"
                },
                resolutionReason: " with closed reason **{{resolutionReason}}**",
                ticketLink: {
                    jiraIssue: "Jira ticket",
                    serviceNowIncident: "ServiceNow incident"
                },
                title: "**{{identity}}** {{translatedAuditEventTypeName}}",
                [Contract.TypeNames.AuditEvent]: {
                    [Contract.TypeNames.RiskCodeResolutionAuditEvent]: "created a {{pullRequestLink}} for this finding",
                    [Contract.TypeNames.RiskCreationAuditEvent]: "created this finding",
                    [Contract.TypeNames.RiskJiraIssueCreationAuditEvent]: "created a {{jiraIssueLink}} for this finding",
                    [Contract.TypeNames.RiskJiraIssueUpdateAuditEvent]: "updated the {{jiraIssueLink}} for this finding",
                    [Contract.TypeNames.RiskResolutionAuditEvent]: "remediated this finding",
                    [Contract.TypeNames.RiskSendMailAuditEvent]: "shared this finding with {{mails}}",
                    [Contract.TypeNames.RiskSendWebhookAuditEvent]: "sent this finding to webhook {{webhookName}}",
                    [Contract.TypeNames.RiskServiceNowIncidentCreationAuditEvent]: "created a {{serviceNowIncidentLink}} for this finding",
                    [Contract.TypeNames.RiskServiceNowIncidentUpdateAuditEvent]: "updated the {{serviceNowIncidentLink}} for this finding",
                    [Contract.TypeNames.RiskSeverityUpdateAuditEvent]: "changed this finding severity from **{{riskOldSeverity}}** to **{{riskNewSeverity}}**",
                    [Contract.TypeNames.RiskStarredUpdateAuditEvent]: {
                        false: "unstarred this finding",
                        true: "starred this finding"
                    },
                    [Contract.TypeNames.RiskStatusUpdateAuditEvent]: "changed this finding status from **{{oldStatus}}** to **{{newStatus}}**{{translatedResolutionReason}}"
                }
            }));

    const translatedAuditEventTypeName =
        useMemo(
            () => {
                switch (auditEventModel.auditEvent.typeName) {
                    case Contract.TypeNames.RiskCodeResolutionAuditEvent:
                        return localization[Contract.TypeNames.AuditEvent][Contract.TypeNames.RiskCodeResolutionAuditEvent]({
                            pullRequestLink:
                                <Link
                                    urlOrGetUrl={(auditEventModel.auditEvent as Contract.RiskCodeResolutionAuditEvent).pullRequestUrl}
                                    variant="external">
                                    {localization.pullRequest.link()}
                                </Link>
                        });
                    case Contract.TypeNames.RiskCreationAuditEvent:
                        return localization[Contract.TypeNames.AuditEvent][Contract.TypeNames.RiskCreationAuditEvent]();
                    case Contract.TypeNames.RiskStatusUpdateAuditEvent: {
                        const statusUpdateAuditEvent = auditEventModel.auditEvent as Contract.RiskStatusUpdateAuditEvent;
                        return localization[Contract.TypeNames.AuditEvent][Contract.TypeNames.RiskStatusUpdateAuditEvent]({
                            newStatus:
                                riskStatusSummeryTranslator(
                                    statusUpdateAuditEvent.riskNewStatus,
                                    statusUpdateAuditEvent.riskNewSubStatus,
                                    statusUpdateAuditEvent.riskNewIgnoreExpirationDate),
                            oldStatus:
                                riskStatusSummeryTranslator(
                                    statusUpdateAuditEvent.riskOldStatus,
                                    statusUpdateAuditEvent.riskOldSubStatus,
                                    statusUpdateAuditEvent.riskOldIgnoreExpirationDate),
                            translatedResolutionReason:
                                !_.isNil(statusUpdateAuditEvent.riskResolutionReason)
                                    ? localization.resolutionReason({
                                        resolutionReason: riskResolutionReasonTranslator(
                                            statusUpdateAuditEvent.riskResolutionReason,
                                            RiskTypeMetadataModelHelper.get(riskModel.risk.typeName).riskedEntityTypeName)
                                    })
                                    : ""
                        });
                    }
                    case Contract.TypeNames.RiskJiraIssueCreationAuditEvent:
                        return localization[Contract.TypeNames.AuditEvent][Contract.TypeNames.RiskJiraIssueCreationAuditEvent]({
                            jiraIssueLink:
                                <Link
                                    urlOrGetUrl={(auditEventModel.auditEvent as Contract.RiskJiraIssueCreationAuditEvent).jiraIssueUrl}
                                    variant="external">
                                    {localization.ticketLink.jiraIssue()}
                                </Link>
                        });
                    case Contract.TypeNames.RiskJiraIssueUpdateAuditEvent:
                        return localization[Contract.TypeNames.AuditEvent][Contract.TypeNames.RiskJiraIssueUpdateAuditEvent]({
                            jiraIssueLink:
                                <Link
                                    urlOrGetUrl={(auditEventModel.auditEvent as Contract.RiskJiraIssueUpdateAuditEvent).jiraIssueUrl}
                                    variant="external">
                                    {localization.ticketLink.jiraIssue()}
                                </Link>
                        });
                    case Contract.TypeNames.RiskResolutionAuditEvent:
                        return localization[Contract.TypeNames.AuditEvent][Contract.TypeNames.RiskResolutionAuditEvent]();
                    case Contract.TypeNames.RiskSendMailAuditEvent:
                        return localization[Contract.TypeNames.AuditEvent][Contract.TypeNames.RiskSendMailAuditEvent]({
                            mails:
                                <InlineItems
                                    items={(auditEventModel.auditEvent as Contract.RiskSendMailAuditEvent).mails}
                                    namePluralizer={localization.mails}
                                    variant="itemOrItemCountAndType"/>
                        });
                    case Contract.TypeNames.RiskSendWebhookAuditEvent:
                        return localization[Contract.TypeNames.AuditEvent][Contract.TypeNames.RiskSendWebhookAuditEvent]({
                            webhookName:
                                <InlineItems
                                    items={(auditEventModel.auditEvent as Contract.RiskSendWebhookAuditEvent).endpointName}
                                    variant="text"/>
                        });
                    case Contract.TypeNames.RiskServiceNowIncidentCreationAuditEvent:
                        return localization[Contract.TypeNames.AuditEvent][Contract.TypeNames.RiskServiceNowIncidentCreationAuditEvent]({
                            serviceNowIncidentLink:
                                <Link
                                    urlOrGetUrl={(auditEventModel.auditEvent as Contract.RiskServiceNowIncidentCreationAuditEvent).serviceNowIncidentUrl}
                                    variant="external">
                                    {localization.ticketLink.serviceNowIncident()}
                                </Link>
                        });
                    case Contract.TypeNames.RiskServiceNowIncidentUpdateAuditEvent:
                        return localization[Contract.TypeNames.AuditEvent][Contract.TypeNames.RiskServiceNowIncidentUpdateAuditEvent]({
                            serviceNowIncidentLink:
                                <Link
                                    urlOrGetUrl={(auditEventModel.auditEvent as Contract.RiskServiceNowIncidentUpdateAuditEvent).serviceNowIncidentUrl}
                                    variant="external">
                                    {localization.ticketLink.serviceNowIncident()}
                                </Link>
                        });
                    case Contract.TypeNames.RiskSeverityUpdateAuditEvent:
                        return localization[Contract.TypeNames.AuditEvent][Contract.TypeNames.RiskSeverityUpdateAuditEvent]({
                            riskNewSeverity: severityTranslator((auditEventModel.auditEvent as Contract.RiskSeverityUpdateAuditEvent).newSeverity),
                            riskOldSeverity: severityTranslator((auditEventModel.auditEvent as Contract.RiskSeverityUpdateAuditEvent).oldSeverity)
                        });
                    case Contract.TypeNames.RiskStarredUpdateAuditEvent:
                        return (auditEventModel.auditEvent as Contract.RiskStarredUpdateAuditEvent).starred
                            ? localization[Contract.TypeNames.AuditEvent][Contract.TypeNames.RiskStarredUpdateAuditEvent].true()
                            : localization[Contract.TypeNames.AuditEvent][Contract.TypeNames.RiskStarredUpdateAuditEvent].false();
                }

                throw new UnexpectedError("auditEventModel.auditEvent.typeName", auditEventModel.auditEvent.typeName);
            },
            [auditEventModel]);

    const theme = useTheme();
    return (
        <Stack>
            <Typography>
                {localization.title({
                    identity:
                        <Identity
                            identityIdOrReference={auditEventModel.auditEvent.identityReference}
                            sx={{ fontSize: "13px" }}
                            variant="text"/>,
                    translatedAuditEventTypeName
                })}
            </Typography>
            {auditEventModel.auditEvent.typeName == Contract.TypeNames.RiskStatusUpdateAuditEvent
                ? edit
                    ? <Edit
                        riskStatusUpdateAuditEvent={auditEventModel.auditEvent as Contract.RiskStatusUpdateAuditEvent}
                        onChange={onChange}
                        onClose={() => setEdit(false)}/>
                    : <View
                        riskStatusUpdateAuditEvent={auditEventModel.auditEvent as Contract.RiskStatusUpdateAuditEvent}
                        onChange={onChange}
                        onClick={() => setEdit(true)}/>
                : <Typography
                    sx={{
                        color: theme.palette.text.secondary,
                        fontSize: "11px"
                    }}>
                    {TimeFormatter.longDateTime(auditEventModel.auditEvent.systemCreationTime)}
                </Typography>}
        </Stack>);
}