import { DataTable, DataTableColumn, DataTableColumnRenderProps, InlineTexts, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { Contract, InfoCard, IntRangeHelper } from "../../../../../../../../../../../../common";

type PortsInfoCardProps = {
    portNameToRangesMap: Dictionary<Contract.IntRange[]>;
};

export function PortsInfoCard({ portNameToRangesMap }: PortsInfoCardProps) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpComputeInstanceGroupDefinition.portsInfoCard",
            () => ({
                columns: {
                    name: "Name",
                    ranges: "Ports"
                },
                title: "Port Name Mapping"
            }));

    return (
        <InfoCard title={localization.title()}>
            <DataTable
                fetchItems={
                    () =>
                        _.map(
                            portNameToRangesMap,
                            (ranges, name) =>
                                new PortsInfoCardItem(
                                    name,
                                    ranges))}
                getItemId={(item: PortsInfoCardItem) => item.name}
                sortOptions={{ enabled: false }}
                variant="card">
                <DataTableColumn
                    id={PortColumnId.Name}
                    itemProperty={(item: PortsInfoCardItem) => item.name}
                    title={localization.columns.name()}/>
                <DataTableColumn
                    id={PortColumnId.Ranges}
                    render={
                        ({ item }: DataTableColumnRenderProps<PortsInfoCardItem>) =>
                            <InlineTexts
                                texts={
                                    _.map(
                                        item.ranges,
                                        IntRangeHelper.format)}
                                variant="itemPlusItemCount"/>}
                    title={localization.columns.ranges()}/>
            </DataTable>
        </InfoCard>);
}

class PortsInfoCardItem {
    constructor(
        public name: string,
        public ranges: Contract.IntRange[]) {
    }
}

enum PortColumnId {
    Name = "name",
    Ranges = "ranges"
}