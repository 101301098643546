import { ActionMenuItem, ConfirmDialog, DataTableColumnRenderProps, Menu, MenuItem, Message, useLocalization } from "@infrastructure";
import { Button, CircularProgress, Stack } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { Contract, permissionManagementActivePermissionStore, PermissionManagementController, PermissionManagementHelper, useTheme } from "../../../../../../../../../../../common";
import { useGranteeUserPermissionRequestsContext, useSetGranteeUserPermissionRequestsContext } from "../../../../../../../GranteeUserPermissionRequests";

export function ActiveActionsCell({ item }: DataTableColumnRenderProps<Contract.PermissionRequestModel>) {
    const activePermissionRequestPrincipalTenantIdToConsoleUrlMap = permissionManagementActivePermissionStore.useGet();
    const { notDeletedPermissionEligibilityIdSet, triggerPermissionRequestModelChange } = useGranteeUserPermissionRequestsContext();
    const setGranteeUserPermissionRequestsContext = useSetGranteeUserPermissionRequestsContext();

    const [confirmRevoke, setConfirmRevoke] = useState(false);
    const [revokePermissionRequestError, setRevokePermissionRequestError] = useState(false);
    const [revokePermissionRequestExecuting, setRevokePermissionRequestExecuting] = useState(false);

    useEffect(
        () => {
            permissionManagementActivePermissionStore.notify();
        },
        [item.permissionRequest.status]);

    async function revokePermissionRequest() {
        setRevokePermissionRequestExecuting(true);
        setRevokePermissionRequestError(false);

        try {
            await PermissionManagementController.revokePermissionRequest(new Contract.PermissionManagementControllerRevokePermissionRequestRequest(item.permissionRequest.id));
            await triggerPermissionRequestModelChange();
        } catch {
            setRevokePermissionRequestError(true);
        }

        setRevokePermissionRequestExecuting(false);
    }

    const consoleUrl =
        _.includes(
            PermissionManagementHelper.noConsoleUrlPermissionRequestTypeNames,
            item.permissionRequest.typeName)
            ? undefined
            : activePermissionRequestPrincipalTenantIdToConsoleUrlMap[item.permissionRequest.granteeUserTenantId];

    const localization =
        useLocalization(
            "views.user.granteeUserPermissionRequests.table.hooks.useDefinition.hooks.useActiveDefinition.activeActionsCell",
            () => ({
                actions: {
                    connect: "Connect",
                    requestAgain: "Request again",
                    revoke: {
                        confirm: "Are you sure you want to revoke this request?",
                        error: "Failed to revoke request",
                        title: "Revoke"
                    }
                }
            }));

    const theme = useTheme();
    return (
        <Fragment>
            {confirmRevoke &&
                <ConfirmDialog
                    message={localization.actions.revoke.confirm()}
                    onClose={
                        confirmed => {
                            if (confirmed) {
                                revokePermissionRequest();
                            }

                            setConfirmRevoke(false);
                        }}/>}
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="flex-end"
                spacing={1}>
                {!_.isNil(consoleUrl) &&
                    <Button
                        href={consoleUrl}
                        target="_blank"
                        variant="outlined">
                        {localization.actions.connect()}
                    </Button>}
                {revokePermissionRequestExecuting && (
                    <CircularProgress
                        size={theme.spacing(2)}
                        variant="indeterminate"/>)}
                {revokePermissionRequestError && (
                    <Message
                        level="error"
                        title={localization.actions.revoke.error()}
                        variant="minimal"/>)}
                <Menu
                    itemsOrGetItems={
                        _<MenuItem>([]).
                            concat(
                                new ActionMenuItem(
                                    () => setConfirmRevoke(true),
                                    localization.actions.revoke.title())).
                            concatIf(
                                notDeletedPermissionEligibilityIdSet.has(item.permissionRequest.permissionEligibilityId),
                                new ActionMenuItem(
                                    () =>
                                        setGranteeUserPermissionRequestsContext(
                                            granteeUserPermissionRequestsContext => ({
                                                ...granteeUserPermissionRequestsContext,
                                                addOpen: item
                                            })),
                                    localization.actions.requestAgain())).
                            value()}
                    variant="bottomCenter"/>
            </Stack>
        </Fragment>);
}