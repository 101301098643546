import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsIamPrincipalRiskContext } from "./useGetAwsIamPrincipalRiskContext";

export function useGetAwsIamUserRiskContext() {
    return useMemo(
        () => useAwsIamUserRiskContext,
        []);
}

function useAwsIamUserRiskContext(userModel: Contract.AwsIamUserModel) {
    const user = userModel.entity as Contract.AwsIamUser;
    const principalRiskContext = useGetAwsIamPrincipalRiskContext()(userModel);

    const enabledAccessKeys =
        _<Contract.AwsIamUserAccessKey>([]).
            concatIf(
                user.accessKey1?.enabled ?? false,
                user.accessKey1!).
            concatIf(
                user.accessKey2?.enabled ?? false,
                user.accessKey2!).
            value();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsIamUserRiskContext",
            () => ({
                enabledAccessKey: {
                    text: "The {{translatedUserTypeName}} access key **{{accessKeyUniqueId}}** is enabled, was created on {{accessKeyCreationTime | TimeFormatter.shortDate}} and was {{accessKeyUsage}}",
                    usage: {
                        lastUsed: "last used on **{{accessKeyUsageTime | TimeFormatter.shortDate}}**",
                        neverUsed: "**never used**"
                    }
                },
                mfaEnabled: {
                    false: "The {{translatedUserTypeName}} has no MFA enabled",
                    true: "The {{translatedUserTypeName}} has MFA enabled"
                },
                passwordEnabled: "The {{translatedUserTypeName}} has a console password enabled"
            }));
    const translatedUserTypeName =
        entityTypeNameTranslator(
            user.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });
    return {
        ...principalRiskContext,
        accessKeys:
            _.map(
                enabledAccessKeys,
                enabledAccessKey =>
                    new RiskDefinitionContextItem(
                        localization.enabledAccessKey.text({
                            accessKeyCreationTime: enabledAccessKey.creationTime!,
                            accessKeyUniqueId: enabledAccessKey.uniqueId,
                            accessKeyUsage:
                                _.isNil(enabledAccessKey.usageTime)
                                    ? localization.enabledAccessKey.usage.neverUsed()
                                    : localization.enabledAccessKey.usage.lastUsed({ accessKeyUsageTime: enabledAccessKey.usageTime! }),
                            translatedUserTypeName
                        }))),
        mfaEnabled:
            user.password?.enabled
                ? new RiskDefinitionContextItem(
                    user.mfaEnabled
                        ? localization.mfaEnabled.true({ translatedUserTypeName })
                        : localization.mfaEnabled.false({ translatedUserTypeName }))
                : undefined,
        passwordEnabled:
            user.password?.enabled
                ? new RiskDefinitionContextItem(localization.passwordEnabled({ translatedUserTypeName }))
                : undefined
    };
}