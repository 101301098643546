import _, { Dictionary } from "lodash";
import { useMemo } from "react";
import { Contract, UserHelper } from "../../../../../../../common";
import { AuthenticationItem, AuthenticationItemApplication, AuthenticationType } from "../Authentication";

export function useItems(aadIdentityProviderEnabled: boolean, consoleAppTypeToSamlIdentityProviderMap: Dictionary<Contract.ApplicationCustomerConfigurationAuthenticationSamlIdentityProvider>, gciIdentityProviderEnabled: boolean) {
    return useMemo(
        () => {
            const oidcApplications =
                _<AuthenticationItemApplication>([Contract.ConsoleAppType.Customer]).
                    concatIf(
                        _.includes(
                            UserHelper.enabledConsoleAppTypes,
                            Contract.ConsoleAppType.User),
                        Contract.ConsoleAppType.User).
                    value();

            const items: AuthenticationItem[] = [
                {
                    applications: oidcApplications,
                    authenticationType: AuthenticationType.Oidc,
                    configured: aadIdentityProviderEnabled,
                    fieldKey: "aadIdentityProviderEnabled",
                    identityProviderType: Contract.TenantType.Azure
                },
                {
                    applications: oidcApplications,
                    authenticationType: AuthenticationType.Oidc,
                    configured: gciIdentityProviderEnabled,
                    fieldKey: "gciIdentityProviderEnabled",
                    identityProviderType: Contract.TenantType.Gcp
                },
                {
                    applications: [Contract.ConsoleAppType.Customer],
                    authenticationType: AuthenticationType.Saml,
                    configured: !!consoleAppTypeToSamlIdentityProviderMap[Contract.ConsoleAppType.Customer],
                    fieldKey: "consoleAppTypeToSamlIdentityProviderMap",
                    identityProviderType: consoleAppTypeToSamlIdentityProviderMap[Contract.ConsoleAppType.Customer]?.type
                },
                {
                    applications: [Contract.ConsoleAppType.User],
                    authenticationType: AuthenticationType.Saml,
                    configured: !!consoleAppTypeToSamlIdentityProviderMap[Contract.ConsoleAppType.User],
                    fieldKey: "consoleAppTypeToSamlIdentityProviderMap",
                    identityProviderType: consoleAppTypeToSamlIdentityProviderMap[Contract.ConsoleAppType.User]?.type
                }];

            const [configuredAuthenticationItems, notConfiguredAuthenticationItems] =
                _.partition(
                    items,
                    item => item.configured);

            return {
                configuredAuthenticationItems,
                notConfiguredAuthenticationItems
            };
        },
        [aadIdentityProviderEnabled, consoleAppTypeToSamlIdentityProviderMap, gciIdentityProviderEnabled]);
}