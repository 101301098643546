import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ResourceTagIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <mask
                fill="white"
                id="ResourceTagIcon_path-1-inside-1">
                <path d="M17 3H7C5.9 3 5 3.9 5 5V20.5542C5 20.7269 5.20402 20.8184 5.33297 20.7036L11.867 14.8876C11.9428 14.8201 12.0572 14.8201 12.133 14.8876L18.667 20.7036C18.796 20.8184 19 20.7269 19 20.5542V5C19 3.9 18.1 3 17 3Z"/>
            </mask>
            <path
                d="M11.867 14.8876L10.5373 13.3937L11.867 14.8876ZM12.133 14.8876L13.4627 13.3937L12.133 14.8876ZM5.33297 20.7036L6.66272 22.1975L5.33297 20.7036ZM17 1H7V5H17V1ZM7 1C4.79543 1 3 2.79543 3 5H7V1ZM3 5V20.5542H7V5H3ZM6.66272 22.1975L13.1968 16.3815L10.5373 13.3937L4.00323 19.2097L6.66272 22.1975ZM10.8032 16.3815L17.3373 22.1975L19.9968 19.2097L13.4627 13.3937L10.8032 16.3815ZM21 20.5542V5H17V20.5542H21ZM21 5C21 2.79543 19.2046 1 17 1V5H17H21ZM17.3373 22.1975C18.7557 23.4601 21 22.4532 21 20.5542H17C17 19.0005 18.8362 18.1767 19.9968 19.2097L17.3373 22.1975ZM13.1968 16.3815C12.5145 16.9888 11.4855 16.9888 10.8032 16.3815L13.4627 13.3937C12.6288 12.6514 11.3712 12.6514 10.5373 13.3937L13.1968 16.3815ZM3 20.5542C3 22.4532 5.24427 23.4601 6.66272 22.1975L4.00323 19.2097C5.16378 18.1767 7 19.0005 7 20.5542H3Z"
                mask="url(#ResourceTagIcon_path-1-inside-1)"/>
        </SvgIcon>);
}