import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, entityModelStore, InlineWorkloadAnalysisPackages, useEntityTypeNameTranslator, useSeverityTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";

export function useGetWorkloadResourceOperatingSystemUnpatchedRiskContext() {
    return useMemo(
        () => useResourceOperatingSystemUnpatchedRiskContext,
        []);
}

function useResourceOperatingSystemUnpatchedRiskContext(risk: Contract.WorkloadAnalysisRisk) {
    const entityModel = entityModelStore.useGet(risk.entityId);
    const workloadResourceOperatingSystemUnpatchedRisk = _.as<Contract.IWorkloadResourceOperatingSystemUnpatchedRisk>(risk);


    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const severityTranslator = useSeverityTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.workloadAnalysis.hooks.contexts.useGetWorkloadResourceOperatingSystemUnpatchedRiskContext",
            () => ({
                package: {
                    exploitable: "The {{translatedEntityTypeName}} contains {{packages}} with at least one {{vulnerabilitySeverity}} vulnerability with a known exploit",
                    nonExploitable: "The {{translatedEntityTypeName}} contains {{packages}} with at least one {{vulnerabilitySeverity}} vulnerability without a known exploit"
                }
            }));

    const translatedEntityTypeName =
        entityTypeNameTranslator(
            entityModel.entity.typeName,
            {
                includeServiceName: false
            });
    return {
        criticalExploitableVulnerabilityPackageNames:
            _.isEmpty(workloadResourceOperatingSystemUnpatchedRisk.criticalExploitableVulnerabilityPackageNames)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.package.exploitable(
                        {
                            packages:
                                <InlineWorkloadAnalysisPackages
                                    packageNames={workloadResourceOperatingSystemUnpatchedRisk.criticalExploitableVulnerabilityPackageNames}
                                    packageScope={workloadResourceOperatingSystemUnpatchedRisk.packageScope}/>,
                            translatedEntityTypeName,
                            vulnerabilitySeverity:
                                severityTranslator(
                                    Contract.Severity.Critical,
                                    "text")
                        })),
        criticalNonExploitableVulnerabilityPackageNames:
            _.isEmpty(workloadResourceOperatingSystemUnpatchedRisk.criticalNonExploitableVulnerabilityPackageNames)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.package.nonExploitable(
                        {
                            packages:
                                <InlineWorkloadAnalysisPackages
                                    packageNames={workloadResourceOperatingSystemUnpatchedRisk.criticalNonExploitableVulnerabilityPackageNames}
                                    packageScope={workloadResourceOperatingSystemUnpatchedRisk.packageScope}/>,
                            translatedEntityTypeName,
                            vulnerabilitySeverity:
                                severityTranslator(
                                    Contract.Severity.Critical,
                                    "text")
                        })),
        highExploitableVulnerabilityPackageNames:
            _.isEmpty(workloadResourceOperatingSystemUnpatchedRisk.highExploitableVulnerabilityPackageNames)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.package.exploitable(
                        {
                            packages:
                                <InlineWorkloadAnalysisPackages
                                    packageNames={workloadResourceOperatingSystemUnpatchedRisk.highExploitableVulnerabilityPackageNames}
                                    packageScope={workloadResourceOperatingSystemUnpatchedRisk.packageScope}/>,
                            translatedEntityTypeName,
                            vulnerabilitySeverity:
                                severityTranslator(
                                    Contract.Severity.High,
                                    "text")
                        })),
        highNonExploitableVulnerabilityPackageNames:
            _.isEmpty(workloadResourceOperatingSystemUnpatchedRisk.highNonExploitableVulnerabilityPackageNames)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.package.nonExploitable(
                        {
                            packages:
                                <InlineWorkloadAnalysisPackages
                                    packageNames={workloadResourceOperatingSystemUnpatchedRisk.highNonExploitableVulnerabilityPackageNames}
                                    packageScope={workloadResourceOperatingSystemUnpatchedRisk.packageScope}/>,
                            translatedEntityTypeName,
                            vulnerabilitySeverity:
                                severityTranslator(
                                    Contract.Severity.High,
                                    "text")
                        }))
    };
}