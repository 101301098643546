import React from "react";
import { useAwsCommonEncryptedResourceInfoElements, useAwsCommonNetworkingInfoItemElements, useAwsCommonRdsDatabaseInstanceResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract } from "../../../../../../../../../../common";
import { Info } from "../../../../components";
import { NetworkingInfoCard } from "../../components";

export function useAwsRdsDocDbDatabaseInstanceDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const databaseInstanceModel = resourceModel as Contract.AwsRdsDocDbDatabaseInstanceModel;
    const databaseInstance = databaseInstanceModel.entity as Contract.AwsRdsDocDbDatabaseInstance;
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);
    const commonNetworkCardInfoItems =
        useAwsCommonNetworkingInfoItemElements({
            networkInterfaceIds: databaseInstance.networkInterfaceIds,
            publicIpAddresses: databaseInstance.publicIpAddresses,
            resourceModel
        });
    const commonRdsDatabaseInstanceResourceInfoElements =
        useAwsCommonRdsDatabaseInstanceResourceInfoElements(
            Contract.TypeNames.AwsRdsDocDbCluster,
            databaseInstanceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    commonRdsDatabaseInstanceResourceInfoElements.status,
                    commonRdsDatabaseInstanceResourceInfoElements.engineVersion,
                    commonRdsDatabaseInstanceResourceInfoElements.storageSize,
                    commonRdsDatabaseInstanceResourceInfoElements.instanceRawType,
                    commonRdsDatabaseInstanceResourceInfoElements.cluster,
                    commonRdsDatabaseInstanceResourceInfoElements.endpoint,
                    commonRdsDatabaseInstanceResourceInfoElements.vpc,
                    ...commonEncryptedResourceInfoElements,
                    commonRdsDatabaseInstanceResourceInfoElements.backupRetentionTimeFrame,
                    commonRdsDatabaseInstanceResourceInfoElements.cloudWatchLoggingEnabled,
                    commonRdsDatabaseInstanceResourceInfoElements.deleteProtection,
                    commonRdsDatabaseInstanceResourceInfoElements.minorVersionUpgradeEnabled,
                    commonRdsDatabaseInstanceResourceInfoElements.availabilityZone
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {commonNetworkCardInfoItems.inboundAccessType}
                    {commonNetworkCardInfoItems.inboundExternalAccessScope}
                    {commonNetworkCardInfoItems.getPublicIpAddresses()}
                    {commonNetworkCardInfoItems.getVpcs()}
                    {commonNetworkCardInfoItems.getSubnets()}
                    {commonNetworkCardInfoItems.getNetworkInterfaces()}
                    {commonNetworkCardInfoItems.getSecurityGroups()}
                </NetworkingInfoCard>
            </Info>
    });
}