import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useAzureTenantManagerErrorTranslator() {
    const localization =
        useLocalization(
            "tenants.azure.hooks.useAzureTenantManagerErrorTranslator",
            () => ({
                [Contract.TypeNames.AzureTenantManagerError]: {
                    [Contract.AzureTenantManagerError.CommonErrorAadTenantIssue]: "Microsoft Entra ID is not connected",
                    [Contract.AzureTenantManagerError.CommonErrorAadTenantMismatch]: "Microsoft Entra ID is not connected",
                    [Contract.AzureTenantManagerError.CommonErrorMoveScopeDependencyExists]: "Target folder does not have the same configured integrations as the source folder.",
                    [Contract.AzureTenantManagerError.CommonErrorParentFolderNotExist]: "Parent folder does not exist",
                    [Contract.AzureTenantManagerError.CommonErrorTenantNotExist]: "Subscription does not exist",
                    [Contract.AzureTenantManagerError.DeleteTenantErrorOrganizationExists]: "Subscription is configured as part of an Organization",
                    [Contract.AzureTenantManagerError.InsertTenantErrorTenantExists]: "Subscription already exist",
                    [Contract.AzureTenantManagerError.InsertTenantErrorTenantMaxCount]: "Subscription limit was reached",
                    [Contract.AzureTenantManagerError.UpsertOrganizationErrorServicePrincipalUnauthorized]: "Cannot access organization",
                    [Contract.AzureTenantManagerError.UpsertOrganizationErrorTenantNotExists]: "Missing permissions: no subscriptions detected. Please ensure the right roles are assigned to the Tenable Cloud Security Connector",
                    [Contract.AzureTenantManagerError.UpsertTenantErrorServicePrincipalAssignedRoleDefinitionMismatch]: "Permissions not granted",
                    [Contract.AzureTenantManagerError.UpsertTenantErrorServicePrincipalUnauthorized]: "Cannot access subscription in selected tenant",
                    [Contract.AzureTenantManagerError.UpsertTenantErrorTenantBlocked]: "Subscription is blocked due to lack of usage",
                    [Contract.AzureTenantManagerError.UpsertTenantErrorTenantDeletePending]: "Subscription is pending deletion",
                    [Contract.AzureTenantManagerError.UpsertTenantErrorTenantNotEnabled]: "Subscription is not active",
                    [Contract.AzureTenantManagerError.UpsertTenantErrorTenantNotSupported]: "Subscription is not supported"
                }
            }));
    return (error: Contract.AzureTenantManagerError) => localization[Contract.TypeNames.AzureTenantManagerError][error]();
}