import _ from "lodash";
import { Contract } from "../../../../../common";
import { useGetVulnerabilitiesCsvItem } from "../../../hooks";
import { useContainerImageDataScanStatusTranslator } from "../hooks";

export function useGetAwsEcrRepositoryContainerImageVulnerabilitiesCsvItem() {
    const containerImageScanStatusTranslator = useContainerImageDataScanStatusTranslator();
    const getVulnerabilitiesCsvItem = useGetVulnerabilitiesCsvItem();
    return (containerImageScanStatus?: Contract.ContainerImageDataScanStatus, vulnerabilities?: Contract.VulnerabilityData[]) => {
        if (_.isNil(containerImageScanStatus)) {
            return "";
        }

        return _.isEmpty(vulnerabilities)
            ? containerImageScanStatusTranslator(containerImageScanStatus)
            : getVulnerabilitiesCsvItem(vulnerabilities);
    };
}