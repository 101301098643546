import React from "react";
import { AzureAuthorizationRoleAssignmentResources } from ".";
import { Contract, useEntityTypeNameTranslator } from "../../../../../../../../../common";

type AzureResourceRoleAssignmentResourcesProps = {
    csvExportFilePrefix: string;
    scopeResourceModel: Contract.AzureScopeResourceModel;
};

export function AzureResourceRoleAssignmentResources({ csvExportFilePrefix, scopeResourceModel }: AzureResourceRoleAssignmentResourcesProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    return (
        <AzureAuthorizationRoleAssignmentResources
            csvExportFilePrefixes={[
                entityTypeNameTranslator(scopeResourceModel.entity.typeName, { includeServiceName: false }),
                scopeResourceModel.entity.displayName,
                csvExportFilePrefix
            ]}
            principalOrResourceId={scopeResourceModel.entity.id}
            roleAssignmentResourceIds={scopeResourceModel.roleAssignmentResourceIds}
            variant="principalAndRoleAndScopeAndRoleManagementPolicyAssignment"/>);
}