﻿import { FormLayout, makeContextProvider, Optional, OrderedWizard, OrderedWizardItem, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useGitHubServerContext, useSetGitHubServerContext } from "../..";
import { Contract } from "../../../../../../../../../../../../../../common";
import { AppDetailsItem, FinishItem, RegisterAppItem, ServerDetailsItem } from "./components";

export class AddOrEditContext {
    public updatedApplicationClientId?: string;
    public updatedApplicationClientSecret?: string;
    public updatedApplicationId?: number;
    public updatedApplicationOrganizationName?: string;
    public updatedApplicationPrivateKey?: string;
    public updatedServerCertificateValidationEnabled?: boolean;
    public updatedServerEndpoint?: string;
    public updatedServerEndpointConnectorId?: string;
    public updatedServerName?: string;

    constructor(public serverModel?: Contract.CodeServerModel) {
        const gitHubServerConfiguration = serverModel?.configuration as Optional<Contract.GitHubServerConfiguration>;

        this.updatedApplicationClientId = gitHubServerConfiguration?.applicationClientId;
        this.updatedApplicationId = gitHubServerConfiguration?.applicationId;
        this.updatedApplicationOrganizationName = gitHubServerConfiguration?.applicationOrganizationName;
        this.updatedServerCertificateValidationEnabled = gitHubServerConfiguration?.serverCertificateValidationEnabled;
        this.updatedServerEndpoint = gitHubServerConfiguration?.endpoint;
        this.updatedServerEndpointConnectorId = gitHubServerConfiguration?.endpointConnectorId;
        this.updatedServerName = gitHubServerConfiguration?.name;
    }
}

export const [useAddOrEditContext, useSetAddOrEditContext, useAddOrEditContextProvider] = makeContextProvider<AddOrEditContext>();

export function AddOrEditGitHubServer() {
    const { addOrEditOpen } = useGitHubServerContext();
    const gitHubServerContext = useSetGitHubServerContext();

    const [, , ContextProvider] =
        useAddOrEditContextProvider(
            () =>
                new AddOrEditContext(
                    _.isBoolean(addOrEditOpen)
                        ? undefined
                        : addOrEditOpen));

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeServerItems.gitHubServer.addOrEditGitHubServer",
            () => ({
                steps: {
                    appDetails: "Configure App Details",
                    registerApp: "Register the Tenable Cloud Security GitHub App",
                    serverDetails: "Configure Server Details"
                },
                title: {
                    add: "Add GitHub Enterprise Server",
                    edit: "Edit GitHub Enterprise Server"
                }
            }));

    return (
        <ContextProvider>
            <FormLayout
                disableContentPadding={true}
                titleOptions={{
                    text:
                        _.isBoolean(addOrEditOpen)
                            ? localization.title.add()
                            : localization.title.edit()
                }}>
                <OrderedWizard
                    finishItemElement={<FinishItem/>}
                    startItemIndex={
                        !_.isBoolean(addOrEditOpen)
                            ? 2
                            : 0}
                    onClose={
                        () =>
                            gitHubServerContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: false
                                }))}>
                    <OrderedWizardItem title={localization.steps.serverDetails()}>
                        <ServerDetailsItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem title={localization.steps.registerApp()}>
                        <RegisterAppItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem title={localization.steps.appDetails()}>
                        <AppDetailsItem/>
                    </OrderedWizardItem>
                </OrderedWizard>
            </FormLayout>
        </ContextProvider>);
}