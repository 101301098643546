import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../../../../../../common";
import { ConditionType } from "../../../utilities";

export function useEntityTagPatternConditionDefinition(condition: Contract.ProjectConfigurationRuleCondition) {
    const entityNamePatternCondition = condition as Contract.ProjectConfigurationRuleTagPatternEntityCondition;
    return useMemo(
        () => ({
            conditionType: ConditionType.EntityTagPattern,
            not: entityNamePatternCondition.not,
            value: entityNamePatternCondition.tagPattern
        }),
        [entityNamePatternCondition]);
}