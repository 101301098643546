import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGciDirectoryGroupTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGciDirectoryGroupTypeTranslator",
            () => ({
                [Contract.TypeNames.GciDirectoryGroupType]: {
                    [Contract.GciDirectoryGroupType.Dynamic]: "Dynamic",
                    [Contract.GciDirectoryGroupType.Google]: "Mailing",
                    [Contract.GciDirectoryGroupType.IdentityMapped]: "Identity Mapped",
                    [Contract.GciDirectoryGroupType.Locked]: "Locked",
                    [Contract.GciDirectoryGroupType.Posix]: "Posix",
                    [Contract.GciDirectoryGroupType.Security]: "Security"
                }
            }));
    return (type: Contract.GciDirectoryGroupType) =>
        localization[Contract.TypeNames.GciDirectoryGroupType][type]();
}