import { DataTableColumn, DataTableColumnRenderProps, EnumValuesFilter, optionalTableCell, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { AadDirectoryApplicationExpirationDateCell, useAadDirectoryApplicationObjectExpirationStatusTranslator } from "../../../../../../../../../../tenants";
import { useAadDirectoryApplicationTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { useAadCommonDirectoryEntityDefinition } from "./useAadCommonDirectoryEntityDefinition";

export function useAadDirectoryApplicationDefinition(definitionData: DefinitionData) {
    const commonDirectoryEntityDefinition = useAadCommonDirectoryEntityDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const directoryApplicationObjectExpirationStatusTranslator = useAadDirectoryApplicationObjectExpirationStatusTranslator();
    const directoryApplicationTypeTranslator = useAadDirectoryApplicationTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aad.useAadDirectoryApplicationDefinition",
            () => ({
                columns: {
                    expirationStatus: "Certificates & Secrets",
                    ownerPrincipalIds: "Owners",
                    type: "Account Types"
                }
            }));

    function translateType(entityModel: Contract.EntityModel) {
        return _.isNil((entityModel.entity as Contract.AadDirectoryApplication).type)
            ? undefined
            : directoryApplicationTypeTranslator((entityModel.entity as Contract.AadDirectoryApplication).type!);
    }

    return new EntityTableDefinition(
        _.filter([
            commonDirectoryEntityDefinition.columns.tenantColumn,
            commonDirectoryEntityDefinition.columns.creationTimeColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AadDirectoryApplicationModel) => ({
                            [localization.columns.type()]: translateType(item) ?? ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AadDirectoryApplicationType}
                                enumTypeTranslator={directoryApplicationTypeTranslator}
                                placeholder={localization.columns.type()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AadDirectoryApplicationType}
                key={Contract.EntityModelProperty.AadDirectoryApplicationType}
                render={optionalTableCell(translateType)}
                title={localization.columns.type()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AadDirectoryApplicationModel>(
                        Contract.TypeNames.AadDirectoryIdentity,
                        item => (item.entity as Contract.AadDirectoryApplication).ownerPrincipalIds,
                        localization.columns.ownerPrincipalIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AadDirectoryApplicationOwnerPrincipals)}
                                placeholder={localization.columns.ownerPrincipalIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AadDirectoryApplicationOwnerPrincipals}
                key={Contract.EntityModelProperty.AadDirectoryApplicationOwnerPrincipals}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AadDirectoryApplicationModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={(item.entity as Contract.AadDirectoryApplication).ownerPrincipalIds}
                            entityTypeName={Contract.TypeNames.AadDirectoryIdentity}
                            entityVariant="iconText"/>}
                title={localization.columns.ownerPrincipalIds()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AadDirectoryApplicationModel) => ({
                            [localization.columns.expirationStatus()]:
                                _.isNil(item.credentialsExpirationStatus)
                                    ? ""
                                    : directoryApplicationObjectExpirationStatusTranslator(item.credentialsExpirationStatus)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                emptyValueOptions={{ enabled: true }}
                                enumType={Contract.AadDirectoryApplicationObjectExpirationStatus}
                                enumTypeTranslator={directoryApplicationObjectExpirationStatusTranslator}
                                placeholder={localization.columns.expirationStatus()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AadDirectoryApplicationExpirationStatus}
                key={Contract.EntityModelProperty.AadDirectoryApplicationExpirationStatus}
                render={
                    optionalTableCell<Contract.AadDirectoryApplicationModel>(
                        (item: Contract.AadDirectoryApplicationModel) =>
                            _.isNil(item.credentialsExpirationStatus)
                                ? undefined
                                : <AadDirectoryApplicationExpirationDateCell
                                    expirationDate={item.credentialsMinExpirationTime!}
                                    expirationStatus={item.credentialsExpirationStatus}
                                    variant="statusAndIcon"/>)}
                title={localization.columns.expirationStatus()}/>,
            commonDirectoryEntityDefinition.columns.attributesColumn,
            commonDirectoryEntityDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonDirectoryEntityDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonDirectoryEntityDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAadDirectoryApplicationRequest(
                new Contract.EntityControllerGetEntityModelPageAadDirectoryApplicationRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.AadDirectoryEntityCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryApplicationExpirationStatus]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryApplicationOwnerPrincipals]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryApplicationType])),
                limit,
                skip,
                requestSort));
}