import { useLocalization } from "@infrastructure";
import { Box, Divider, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Page, usePageContext } from "..";
import { Contract, LogoTextIcon, ReportHelper, ScopeHelper, scopeNodeModelStore, UserHelper, useTenantTypeTranslator, useTheme } from "../../../../../../common";

type CoverPageProps = {
    reportType: Contract.ReportType;
    scopeId: string;
    subtitle: string;
    tenantTypes?: Contract.TenantType[];
};

export function CoverPage({ reportType, scopeId, subtitle, tenantTypes }: CoverPageProps) {
    const { reportTitle } = usePageContext();
    const scopeNodeMap =
        scopeNodeModelStore.useGetActiveScopeNodeMap(
            undefined,
            true);
    const [reportTenantIds, reportTenantTypes] =
        ScopeHelper.isProjectScope(scopeNodeMap[scopeId].scopeNodeModel)
            ? [[], []]
            : getReportScopeTenantsData(
                scopeId,
                tenantTypes);
    const [unfilteredReportTenantsIds] =
        getReportScopeTenantsData(
            ScopeHelper.customerId,
            undefined);
    const scopeNodeModel = scopeNodeMap[scopeId].scopeNodeModel;


    const tenantTypeTranslator = useTenantTypeTranslator();
    const localization =
        useLocalization(
            "views.system.exportReport.coverPage",
            () => ({
                info: {
                    customer: "Organization name: **{{customerName}}**",
                    scope: {
                        customer: {
                            multipleTenantTypes: {
                                all: "Scope: Organization **{{customerName}}** (all accounts)",
                                some: "Scope: Organization **{{customerName}}** ({{count | NumberFormatter.humanize}} accounts)"
                            },
                            singleTenantType: "Scope: Organization **{{customerName}}** ({{count | NumberFormatter.humanize}} {{translatedTenantType}} accounts)"
                        },
                        empty: "Scope: No accounts",
                        folder: "Scope: Folder **{{folderName}}**({{count | NumberFormatter.humanize}} accounts)",
                        project: "Scope: Project **{{name}}**",
                        projectFolder: "Scope: Project folder **{{name}}**",
                        registry: "Scope: Registry **{{name}}**",
                        tenant: "Scope: Account **{{tenantId}}**"
                    },
                    time: "Generated at: **{{time | TimeFormatter.longDate}}**"
                }
            }));

    const translatedScope =
        useMemo(
            () => {
                switch (scopeNodeModel.type) {
                    case Contract.ScopeType.Customer:
                        if (_.isEqual(reportTenantIds, unfilteredReportTenantsIds)) {
                            return localization.info.scope.customer.multipleTenantTypes.all({ customerName: UserHelper.customer!.name });
                        }
                        return reportTenantTypes.length > 1
                            ? localization.info.scope.customer.multipleTenantTypes.some({
                                count: reportTenantIds.length,
                                customerName: UserHelper.customer!.name
                            })
                            : localization.info.scope.customer.singleTenantType({
                                count: reportTenantIds.length,
                                customerName: UserHelper.customer!.name,
                                translatedTenantType: tenantTypeTranslator(reportTenantTypes[0])
                            });
                    case Contract.ScopeType.Folder:
                        if (reportTenantIds.length > 1) {
                            return localization.info.scope.folder({
                                count: reportTenantIds.length,
                                folderName: (scopeNodeModel.configuration as Contract.FolderConfiguration).name
                            });
                        }
                        break;
                    case Contract.ScopeType.Project:
                        return localization.info.scope.project({ name: scopeNodeModel.configuration.name });
                    case Contract.ScopeType.ProjectFolder:
                        return localization.info.scope.projectFolder({ name: scopeNodeModel.configuration.name });
                }
                return reportTenantIds.length == 1
                    ? reportTenantTypes[0] == Contract.TenantType.Ci
                        ? localization.info.scope.registry({ name: scopeNodeMap[reportTenantIds[0]].scopeNodeModel.configuration.name })
                        : localization.info.scope.tenant({ tenantId: reportTenantIds[0] })
                    : localization.info.scope.empty();
            },
            [localization, reportTenantIds, reportTenantTypes, scopeNodeModel, unfilteredReportTenantsIds]);

    function getReportScopeTenantsData(scopeId: string, tenantTypes?: Contract.TenantType[]): [string[], Contract.TenantType[]] {
        const scopeActiveTenantIds = ScopeHelper.getScopeActiveTenantIds([scopeId]);
        let reportTenantTypes =
            _.intersection(
                _(scopeActiveTenantIds).
                    flatMap(scopeId => scopeNodeMap[scopeId].getTenantTypes()).
                    uniq().
                    value(),
                ReportHelper.getTenantTypes(reportType));
        if (!_.isNil(tenantTypes)) {
            reportTenantTypes =
                _.intersection(
                    reportTenantTypes,
                    tenantTypes);
        }
        const reportTenantIds =
            _.filter(
                scopeActiveTenantIds,
                scopeTenantId =>
                    _.includes(
                        reportTenantTypes,
                        ScopeHelper.getTenantType(scopeNodeMap[scopeTenantId].scopeNodeModel)));
        return [reportTenantIds, reportTenantTypes];
    }

    const theme = useTheme();
    return (
        <Page sx={{ background: "linear-gradient(16.15deg, #B3D6F3 7.11%, #E9FCFF 113.34%)" }}>
            <Stack
                sx={{
                    height: "inherit",
                    padding: theme.spacing(6)
                }}>
                <LogoTextIcon/>
                <Typography
                    style={{
                        fontSize: "36px",
                        fontWeight: 500,
                        marginTop: theme.spacing(10)
                    }}>
                    {reportTitle}
                </Typography>
                <Typography
                    style={{
                        fontSize: "18px",
                        marginTop: theme.spacing(1),
                        paddingRight: theme.spacing(20)
                    }}>
                    {subtitle}
                </Typography>
                <Divider
                    flexItem={true}
                    style={{
                        alignSelf: "stretch",
                        margin: theme.spacing(3, 0)
                    }}
                    variant="middle"/>
                <Box
                    style={{
                        colorAdjust: "exact",
                        width: theme.spacing(80)
                    }}>
                    <Stack spacing={1}>
                        <Typography sx={{ fontSize: "16px" }}>
                            {localization.info.customer({ customerName: UserHelper.customer!.name })}
                        </Typography>
                        <Typography sx={{ fontSize: "16px" }}>
                            {localization.info.time({ time: Date.now() })}
                        </Typography>
                        <Typography sx={{ fontSize: "16px" }}>
                            {translatedScope}
                        </Typography>
                    </Stack>
                </Box>
            </Stack>
        </Page>);
}