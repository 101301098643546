import { OperationStore } from "@infrastructure";
import { Contract, RiskController } from "../controllers";

export const codeRiskPolicyItemsOperationStore =
    new OperationStore(
        async (scopeId: string) => {
            const [getRiskPoliciesResponse, getRiskPolicySummaryResponse] =
                await Promise.all([
                    RiskController.getRiskPolicies(new Contract.RiskControllerGetCodeRiskPoliciesRequest(scopeId)),
                    RiskController.getRiskPolicySummary(new Contract.RiskControllerGetCodeRiskPolicySummaryRequest(scopeId))
                ]);

            return {
                ...getRiskPoliciesResponse,
                ...getRiskPolicySummaryResponse
            };
        });