import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useAwsCommonResourceInfoItemElements, useAwsDefaultResourceInfoItemElements } from "..";
import { Contract, entityModelStore, InfoItem, TenantEntityParentPathCell } from "../../../../../../../../../../../common";
import { useAwsOrganizationsAccountOrganizationJoinMethodTranslator, useAwsOrganizationsAccountStatusTranslator } from "../../../../../../../hooks";
import { Info } from "../../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { AwsOrganizationsPolicyTable } from "./components";

export function useAwsTenantEntityDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonResourceInfoItemElements = useAwsCommonResourceInfoItemElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const tenantEntityModel = resourceModel as Contract.AwsTenantEntityModel;
    const accountModel = entityModelStore.useGet(tenantEntityModel.account?.id);

    const accountOrganizationJoinMethodTranslator = useAwsOrganizationsAccountOrganizationJoinMethodTranslator();
    const accountStatusTranslator = useAwsOrganizationsAccountStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsTenantEntityDefinition.useAwsTenantEntityDefinition",
            () => ({
                info: {
                    items: {
                        creationTime: "Joined Time",
                        organizationJoinMethod: "Join Method",
                        parentPath: "Path",
                        rootUserMail: "Admin Email",
                        status: "Status"
                    }
                },
                tabs: {
                    policyIds: "Organization Policies"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs:
            _.isNil(accountModel) || accountModel.unknown
                ? undefined
                : [
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Overview,
                        <AwsOrganizationsPolicyTable
                            account={accountModel.entity as Contract.AwsOrganizationsAccount}
                            organizationsScopeResourceIdToResourceControlPolicySearchableReferencesMap={tenantEntityModel.organizationsScopeResourceIdToResourceControlPolicySearchableReferencesMap}
                            organizationsScopeResourceIdToServiceControlPolicySearchableReferencesMap={tenantEntityModel.organizationsScopeResourceIdToServiceControlPolicySearchableReferencesMap}/>,
                        localization.tabs.policyIds(),
                        "policies")
                ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="rootUserMail"
                        title={localization.info.items.rootUserMail()}
                        value={tenantEntityModel?.account?.rootUserMail}/>,
                    <InfoItem
                        key="organizationJoinMethod"
                        title={localization.info.items.organizationJoinMethod()}
                        value={
                            _.isNil(tenantEntityModel?.account)
                                ? undefined
                                : accountOrganizationJoinMethodTranslator(tenantEntityModel.account.organizationJoinMethod!)}/>,
                    commonResourceInfoItemElements.getCreationTimeInfoItemElement(localization.info.items.creationTime()),
                    <InfoItem
                        key="status"
                        location="all"
                        title={localization.info.items.status()}
                        value={
                            _.isNil(tenantEntityModel?.account)
                                ? undefined
                                : accountStatusTranslator(tenantEntityModel.account!.status)}/>,
                    <InfoItem
                        key="parentPath"
                        title={localization.info.items.parentPath()}
                        value={
                            <TenantEntityParentPathCell
                                parentEntityId={tenantEntityModel?.account?.parentEntityId}
                                parentEntityPath={tenantEntityModel.parentEntityPath}/>}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}