import { EmptyValueOptions, Optional, PagedValuesFilter, PagedValuesFilterValuePage } from "@infrastructure";
import _ from "lodash";
import React, { useCallback } from "react";
import { Entity } from ".";
import { entityModelStore } from "..";

type PagedEntityFilterProps = {
    emptyValueOptions?: EmptyValueOptions;
    getEntityIdPage: (searchText: Optional<string>, skip: number, limit: number, data?: any) => Promise<PagedEntityFilterEntityIdPage>;
    placeholder: string;
};

export class PagedEntityFilterEntityIdPage {
    constructor(
        public count: number,
        public emptyValue: Optional<boolean>,
        public ids: string[],
        public applyData?: () => any) {
    }
}

export function PagedEntityFilter({ emptyValueOptions, getEntityIdPage, placeholder }: PagedEntityFilterProps) {
    return (
        <PagedValuesFilter
            emptyValueOptions={{
                enabled: emptyValueOptions?.enabled ?? true,
                title: emptyValueOptions?.title,
                variant: emptyValueOptions?.variant ?? "iconText"
            }}
            getValuePage={
                useCallback(
                    async (searchText: string | undefined, skip: number, limit: number, data?: any): Promise<PagedValuesFilterValuePage> => {
                        const { applyData, count, emptyValue, ids } =
                            await getEntityIdPage(
                                searchText,
                                skip,
                                limit,
                                data);
                        return new PagedValuesFilterValuePage(
                            count,
                            emptyValue,
                            ids,
                            applyData);
                    },
                    [getEntityIdPage])}
            getValuesData={
                async values => {
                    const valuesData = await entityModelStore.get(values);
                    return _.reduce(
                        valuesData,
                        (arr, value) => ({ ...arr, [value.id]: value }),
                        {});
                }}
            placeholder={placeholder}>
            {(inline, entityId) =>
                <Entity
                    entityIdOrModel={entityId}
                    linkOptions={{ disabled: true }}
                    sxOptions={{
                        icon: {
                            cursor: "pointer"
                        },
                        title: {
                            cursor: "pointer",
                            fontWeight: 400
                        }
                    }}
                    variant={
                        inline
                            ? "text"
                            : "iconTextTypeTenant"}/>}
        </PagedValuesFilter>);
}