import { Step, Steps, TextSnippet, TextSnippetItem, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, useTheme } from "../../../../../../../../../../../../../../../../../common";
import { useAddOrEditContext } from "../../../AddOrEdit";

export function PowerShell() {
    const { aadTenantModel, permissionTypes } = useAddOrEditContext();

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.azure.addOrEdit.roleDefinitionsItem.powerShell",
            () => ({
                dataAnalysisRoleDefinitionName: "Tenable Data Scanning",
                steps: {
                    step1: {
                        info: "Note: Administrator privileges are required to create the role assignments.",
                        title: "Log in to the Azure portal and open a new Cloud Shell session (Switch to **PowerShell** and click **Confirm**)"
                    },
                    step2: "Copy the following command and run it in your shell:",
                    step3: "When the script completes successfully, click **Finish**"
                }
            }));

    const dataAnalysisRoleDefinitionName = localization.dataAnalysisRoleDefinitionName();
    const dataAnalysisRoleDefinitionRoleName = `${dataAnalysisRoleDefinitionName} (${aadTenantModel!.configuration.rawId})`;
    const cmdletCode =
        useMemo(
            () => {
                const customRoleDefinitionsCmdletCode =
                    _<string>([]).
                        concatIf(
                            _.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.DataAnalysis),
                            `New-AzRoleDefinition -Role @{
    Name = "${dataAnalysisRoleDefinitionRoleName}"
    Id = (New-Guid).Guid
    IsCustom = $true
    Description = "${dataAnalysisRoleDefinitionName}"
    Actions = @(
        "Microsoft.Sql/servers/databases/write",
        "Microsoft.Storage/storageAccounts/privateEndpointConnections/write"
    )
    NotActions = @()
    AssignableScopes = @("/providers/Microsoft.Management/managementGroups/${aadTenantModel!.configuration.rawId}")
}
`
                        ).
                        join(",\n");

                const roleDefinitionNames =
                    _<string>([]).
                        concat([
                            "Reader",
                            "Key Vault Reader",
                            "Azure Kubernetes Service Cluster User Role",
                            "Azure Kubernetes Service RBAC Reader"
                        ]).
                        concatIf(
                            _.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.WorkloadAnalysis),
                            "Disk Snapshot Contributor").
                        concatIf(
                            _.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.PermissionManagement),
                            "User Access Administrator").
                        concatIf(
                            _.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.Write),
                            "Owner").
                        concatIf(
                            _.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.DataAnalysis),
                            "Storage Blob Data Reader",
                            dataAnalysisRoleDefinitionRoleName).
                        map(roleDefinitionName => `    "${roleDefinitionName}"`).
                        join(",\n");

                const roleAssignmentsCmdletCode =
                    `$roleDefinitionNames = @(
${roleDefinitionNames}
)
foreach ($roleDefinitionName in $roleDefinitionNames) {
    $roleDefinition = Get-AzRoleDefinition -Name $roleDefinitionName
    New-AzRoleAssignment -ObjectId ${aadTenantModel!.configuration.applicationServicePrincipalRawId} -RoleDefinitionId $roleDefinition.Id -Scope /providers/Microsoft.Management/managementGroups/${aadTenantModel!.configuration.rawId}
}`;
                return `${customRoleDefinitionsCmdletCode}${roleAssignmentsCmdletCode}`;
            },
            []);

    const theme = useTheme();
    return (
        <Steps variant="letters">
            {new Step(
                localization.steps.step1.title(),
                {
                    contentElement:
                        <Typography>
                            {localization.steps.step1.info()}
                        </Typography>
                })}
            {new Step(
                localization.steps.step2(),
                {
                    contentElement:
                        <TextSnippet
                            height={theme.spacing(52)}
                            items={[
                                new TextSnippetItem(
                                    cmdletCode,
                                    "")
                            ]}/>
                })}
            {localization.steps.step3()}
        </Steps>);
}