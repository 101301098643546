import { TextViewer, useLocalization } from "@infrastructure";
import { Box } from "@mui/material";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, InfoItem, useEntityTypeNameTranslator, useTheme } from "../../../../../../../../../../common";
import { useAadDirectoryDirectoryRoleDefinitionTypeTranslator } from "../../../../../../hooks";
import { Info } from "../../../../components";
import { AadDirectoryDirectoryRoleAssignmentResources } from "../../components";
import { useAadDefaultDirectoryEntityInfoItemElements } from "./useAadDefaultDirectoryEntityInfoItemElements";

export function useAadDirectoryDirectoryRoleDefinitionDefinition(directoryEntityModel: Contract.AadDirectoryEntityModel, options?: DefinitionOptions) {
    const defaultDirectoryEntityInfoItemElements = useAadDefaultDirectoryEntityInfoItemElements(directoryEntityModel);
    const directoryRoleDefinition = directoryEntityModel.entity as Contract.AadDirectoryDirectoryRoleDefinition;
    const directoryRoleDefinitionModel = directoryEntityModel as Contract.AadDirectoryDirectoryRoleDefinitionModel;

    const directoryRoleDefinitionTypeTranslator = useAadDirectoryDirectoryRoleDefinitionTypeTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aad.useAadDirectoryDirectoryRoleDefinitionDefinition",
            () => ({
                info: {
                    items: {
                        description: "Description",
                        templateId: "Template ID",
                        type: "Role Type"
                    }
                },
                tabs: {
                    directoryRoleAssignments: "Assignments",
                    rawDefinition: "Definition"
                }
            }));

    const theme = useTheme();
    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AadDirectoryDirectoryRoleAssignmentResources
                    csvExportFilePrefixes={[
                        entityTypeNameTranslator(directoryEntityModel.entity.typeName, { includeServiceName: false }),
                        directoryEntityModel.entity.displayName,
                        localization.tabs.directoryRoleAssignments()
                    ]}
                    directoryRoleAssignmentResourceIds={directoryRoleDefinitionModel.directoryRoleAssignmentResourceIds}
                    variant="principalAndScopeAndRoleManagementPolicyAssignment"/>,
                localization.tabs.directoryRoleAssignments(),
                "assignments"),
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <Box sx={{ paddingTop: theme.spacing(1.5) }}>
                    <TextViewer
                        format="json"
                        text={directoryRoleDefinition.rawDefinition}/>
                </Box>,
                localization.tabs.rawDefinition(),
                "definition")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultDirectoryEntityInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="templateId"
                        location="profile"
                        title={localization.info.items.templateId()}
                        value={directoryRoleDefinition.templateId}/>,
                    <InfoItem
                        key="description"
                        location="all"
                        title={localization.info.items.description()}
                        value={directoryRoleDefinition.description}/>,
                    <InfoItem
                        key="type"
                        location="all"
                        title={localization.info.items.type()}
                        value={directoryRoleDefinitionTypeTranslator(directoryRoleDefinition.type)}/>
                ]}
                options={options?.infoOptions}>
            </Info>,
        sensitive: false
    });
}