﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, useEntityTypeNameTranslator } from "../../../../../../../../../../../common";

export function useOciResourceGeneralInformationStep(resourceModel: Contract.EntityModel) {
    const ociResourceModel = resourceModel as Contract.OciResourceModel;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.useResourceGeneralInformationStep.hooks.useOciResourceGeneralInformationStep",
            () => ({
                creationTime: "*capitalize*{{resourceTypeName}}** {{resource}} was created on {{creationTime | TimeFormatter.shortDate}}"
            }));
    return localization.creationTime({
        creationTime: ociResourceModel.creationTime,
        resource:
            <Entity
                entityIdOrModel={ociResourceModel}
                entityTypeNameTranslatorOptions={{ variant: "title" }}
                variant="text"/>,
        resourceTypeName:
            entityTypeNameTranslator(
                ociResourceModel.entity.typeName,
                {
                    includeServiceName: false,
                    variant: "text"
                })
    });
}