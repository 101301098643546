import { useLocalization } from "@infrastructure";
import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsAthenaWorkgroupRiskContext() {
    return useMemo(
        () => useAwsAthenaWorkgroupRiskContext,
        []);
}

function useAwsAthenaWorkgroupRiskContext(workgroupModel: Contract.AwsAthenaWorkgroupModel) {
    const workgroup = workgroupModel.entity as Contract.AwsAthenaWorkgroup;
    const resourceRiskContext = useGetAwsResourceRiskContext()(workgroupModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsAthenaWorkgroupRiskContext",
            () => ({
                status: {
                    false: "Disabled",
                    title: "The Athena workgroup state is **{{status}}**",
                    true: "Enabled"
                }
            }));

    return {
        ...resourceRiskContext,
        status:
            new RiskDefinitionContextItem(
                localization.status.title({
                    status:
                        workgroup.enabled
                            ? localization.status.true()
                            : localization.status.false()

                }))
    };
}