import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { ActionMenuItem, DeleteIcon, EditIcon, Menu, useLocalization } from "@infrastructure";
import { ConfigurationController, Contract, InfoItem, settingConfigurationOperationStore, settingConfigurationTypeNamesOperationStore, UserHelper, useScopeNameTranslator } from "../../../../../../../common";
import { useScopeSettingConfigurationDerivedTypeNameTranslator } from "../hooks";
import { useSetConfigurationContextContext } from "../SettingConfigurationTable";

type ActionsCellProps = {
    scopeSettingConfigurationData: Contract.ScopeSettingConfigurationData;
};

export function ActionsCell({ scopeSettingConfigurationData }: ActionsCellProps) {
    const setSettingContextContext = useSetConfigurationContextContext();

    const scopeNameTranslator = useScopeNameTranslator();
    const scopeSettingConfigurationDerivedTypeNameTranslator = useScopeSettingConfigurationDerivedTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.settingConfigurationTable.actionsCell",
            () => ({
                delete: {
                    confirmation: "Are you sure you want to delete {{ name }}?",
                    title: "Delete"
                },
                edit: "Edit",
                scope: "Scope"
            }));

    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            <Menu
                disabled={
                    !UserHelper.hasScopePermissions(
                        scopeSettingConfigurationData.scopeSettingConfiguration.scopeId,
                        Contract.IdentityPermission.SecurityAdministrationRead)}
                itemsOrGetItems={
                    [
                        new ActionMenuItem(
                            () =>
                                setSettingContextContext(
                                    context => ({
                                        ...context,
                                        addOrEditOpen: scopeSettingConfigurationData.scopeSettingConfiguration
                                    })),
                            localization.edit(),
                            {
                                disabled: !_.includes(scopeSettingConfigurationData.actions, Contract.ScopeSettingAction.Upsert),
                                icon: <EditIcon/>
                            }),
                        new ActionMenuItem(
                            async () => {
                                await ConfigurationController.deleteScopeSettingConfiguration(new Contract.ConfigurationControllerDeleteScopeSettingConfigurationRequest(scopeSettingConfigurationData.scopeSettingConfiguration.id));
                                await Promise.all([
                                    settingConfigurationOperationStore.notifyAll(),
                                    settingConfigurationTypeNamesOperationStore.notifyAll()
                                ]);
                            },
                            localization.delete.title(),
                            {
                                confirmOptions: {
                                    descriptionElement:
                                        <Stack
                                            direction="row"
                                            justifyContent="center">
                                            <InfoItem
                                                title={localization.scope()}
                                                value={
                                                    <Typography noWrap={true}>
                                                        {scopeNameTranslator(scopeSettingConfigurationData.scopeSettingConfiguration.scopeId, { path: true })}
                                                    </Typography>}/>
                                        </Stack>,
                                    message: localization.delete.confirmation({ name: scopeSettingConfigurationDerivedTypeNameTranslator(scopeSettingConfigurationData.scopeSettingConfiguration.typeName as Contract.ScopeSettingConfigurationDerivedTypeNames).title })
                                },
                                disabled: !_.includes(scopeSettingConfigurationData.actions, Contract.ScopeSettingAction.Delete),
                                icon: <DeleteIcon/>
                            })
                    ]}/>
        </Stack>);
}