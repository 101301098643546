import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAzureAuthorizationRoleDefinitionTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAzureAuthorizationRoleDefinitionTypeTranslator",
            () => ({
                [Contract.TypeNames.AzureAuthorizationRoleDefinitionType]: {
                    [Contract.AzureAuthorizationRoleDefinitionType.BuiltIn]: "Built-in",
                    [Contract.AzureAuthorizationRoleDefinitionType.Custom]: "Custom"
                }
            }));
    return (type: Contract.AzureAuthorizationRoleDefinitionType) =>
        localization[Contract.TypeNames.AzureAuthorizationRoleDefinitionType][type]();
}