import { InlineItems, map, NumberFormatter, Optional, TimeSpanHelper, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "../../..";
import { Contract, InlineEntities, ResourceTagPatternHelper, useEntityTypeNameTranslator, useExclusionEntityPropertyPatternTranslator } from "../../../../../../../../../../../common";

export function useGetRiskPolicyUpdateAuditEventDetailsItems() {
    function getNumericChangeDetailsItem(
        changeValue: Optional<number>,
        title: string) {
        return new AuditEventDefinitionDetailItem(
            title,
            _.isNil(changeValue)
                ? localization.inherit()
                : NumberFormatter.humanize(changeValue));
    }

    function getPropertyPatternValue(propertyPattern: Contract.RiskPolicyConfigurationEntityExclusionEntityPropertyPattern) {
        return map(
            propertyPattern.typeName,
            {
                [Contract.TypeNames.RiskPolicyConfigurationEntityExclusionAwsResourceArnPattern]: () => (propertyPattern as Contract.RiskPolicyConfigurationEntityExclusionAwsResourceArnPattern).arnPattern,
                [Contract.TypeNames.RiskPolicyConfigurationEntityExclusionEntityNamePattern]: () => (propertyPattern as Contract.RiskPolicyConfigurationEntityExclusionEntityNamePattern).namePattern,
                [Contract.TypeNames.RiskPolicyConfigurationEntityExclusionResourceTagPattern]: () => ResourceTagPatternHelper.getDisplayName((propertyPattern as Contract.RiskPolicyConfigurationEntityExclusionResourceTagPattern).resourceTagPattern)
            });
    }

    function getTimeFrameChangeDetailsItem(
        changeTimeFrame: Optional<string>,
        title: string) {
        return new AuditEventDefinitionDetailItem(
            title,
            _.isNil(changeTimeFrame)
                ? localization.inherit()
                : localization.days(
                    TimeSpanHelper.getDays(changeTimeFrame!),
                    {
                        count: TimeSpanHelper.getDays(changeTimeFrame)
                    }));
    }

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const exclusionEntityPropertyPatternTranslator = useExclusionEntityPropertyPatternTranslator();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useRiskPolicyAuditEventDefinition.hooks.useGetRiskPolicyUpdateAuditEventDetailsItems",
            () => ({
                addedApiGatewayRoutePattern: [
                    "Excluded API gateway endpoint pattern",
                    "Excluded API gateway endpoint patterns"
                ],
                addedCodeExclusions: [
                    "Excluded file path pattern",
                    "Excluded file path patterns"
                ],
                addedEntityPropertyPatterns: [
                    "Excluded resource pattern",
                    "Excluded resource patterns"
                ],
                addedExcludedEntityIds: [
                    "Excluded resource",
                    "Excluded resources"
                ],
                addedSecretPatterns: [
                    "Excluded secret pattern",
                    "Excluded secret patterns"
                ],
                codeEnabled: {
                    false: "Disabled",
                    previous: "Old code policy status",
                    true: "Enabled",
                    updated: "New code policy status"
                },
                days: [
                    "{{count | NumberFormatter.humanize}} day",
                    "{{count | NumberFormatter.humanize}} days"
                ],
                enabled: {
                    false: "Disabled",
                    previous: "Old policy status",
                    true: "Enabled",
                    updated: "New policy status"
                },
                entityCreationTimeAnalysisDelayTimeFrame: {
                    previous: "Old delay",
                    updated: "New delay"
                },
                entityPropertyPattern: [
                    "{{count | NumberFormatter.humanize}} pattern",
                    "{{count | NumberFormatter.humanize}} patterns"
                ],
                inactivePrincipalDeleteGroupMembershipEnabled: {
                    false: "Disabled",
                    previous: "Old remove inactive principals from groups",
                    true: "Enabled",
                    updated: "New remove inactive principals from groups"
                },
                inactivityTimeFrame: {
                    previous: "Old inactivity period",
                    updated: "New inactivity period"
                },
                includeDisabledIdentity: {
                    false: "Exclude",
                    previous: "Old disabled identities",
                    true: "Include",
                    updated: "New disabled identities"
                },
                includeInvitationPendingAcceptanceGuestUser: {
                    false: "Exclude",
                    previous: "Old guest users with pending tenant invitation",
                    true: "Include",
                    updated: "New guest users with pending tenant invitation"
                },
                includeUnusedRootUser: {
                    false: "Exclude",
                    previous: "Old member account root user",
                    true: "Include",
                    updated: "New member account root user"
                },
                inherit: "Inherit",
                passwordRequirement: {
                    previous: "Old password requirement",
                    updated: "New password requirement"
                },
                patternCount: [
                    "{{count | NumberFormatter.humanize}} pattern",
                    "{{count | NumberFormatter.humanize}} patterns"
                ],
                removedApiGatewayRoutePattern: [
                    "Removed API gateway endpoint pattern",
                    "Removed API gateway endpoint patterns"
                ],
                removedCodeExclusions: [
                    "Removed file path pattern",
                    "Removed file path patterns"
                ],
                removedEntityPropertyPatterns: [
                    "Removed excluded resource pattern",
                    "Removed excluded resource patterns"
                ],
                removedExcludedEntityIds: [
                    "Removed excluded resource",
                    "Removed excluded resources"
                ],
                removedSecretPatterns: [
                    "Removed secret pattern",
                    "Removed secret patterns"
                ],
                retentionTimeFrame: {
                    previous: "Old retention period",
                    updated: "New retention period"
                },
                rotationTimeFrame: {
                    previous: "Old rotation period",
                    updated: "New rotation period"
                }
            }));

    return (changes: Contract.RiskPolicyUpdateAuditEventChange[], context: AuditEventDefinitionContext) =>
        _(changes).
            flatMap(
                change =>
                    map(
                        change.typeName,
                        {
                            [Contract.TypeNames.AadDirectoryApplicationCertificateNotRotatedRiskPolicyUpdateAuditEventCertificateRotationTimeFrameChange]:
                                () => [
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AadDirectoryApplicationCertificateNotRotatedRiskPolicyUpdateAuditEventCertificateRotationTimeFrameChange).previousCertificateRotationTimeFrame,
                                        localization.rotationTimeFrame.previous()),
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AadDirectoryApplicationCertificateNotRotatedRiskPolicyUpdateAuditEventCertificateRotationTimeFrameChange).certificateRotationTimeFrame,
                                        localization.rotationTimeFrame.updated())],
                            [Contract.TypeNames.AadDirectoryApplicationSecretNotRotatedRiskPolicyUpdateAuditEventSecretRotationTimeFrameChange]:
                                () => [
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AadDirectoryApplicationSecretNotRotatedRiskPolicyUpdateAuditEventSecretRotationTimeFrameChange).previousSecretRotationTimeFrame,
                                        localization.rotationTimeFrame.previous()),
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AadDirectoryApplicationSecretNotRotatedRiskPolicyUpdateAuditEventSecretRotationTimeFrameChange).secretRotationTimeFrame,
                                        localization.rotationTimeFrame.updated())],
                            [Contract.TypeNames.AadDirectoryIdentityInactiveRiskPolicyUpdateAuditEventIncludeDisabledIdentityChange]:
                                () => [
                                    new AuditEventDefinitionDetailItem(
                                        localization.includeDisabledIdentity.previous(),
                                        _.isNil((change as Contract.AadDirectoryIdentityInactiveRiskPolicyUpdateAuditEventIncludeDisabledIdentityChange).previousIncludeDisabledIdentity)
                                            ? localization.inherit()
                                            : (change as Contract.AadDirectoryIdentityInactiveRiskPolicyUpdateAuditEventIncludeDisabledIdentityChange).previousIncludeDisabledIdentity
                                                ? localization.includeDisabledIdentity.true()
                                                : localization.includeDisabledIdentity.false()),
                                    new AuditEventDefinitionDetailItem(
                                        localization.includeDisabledIdentity.updated(),
                                        _.isNil((change as Contract.AadDirectoryIdentityInactiveRiskPolicyUpdateAuditEventIncludeDisabledIdentityChange).includeDisabledIdentity)
                                            ? localization.inherit()
                                            : (change as Contract.AadDirectoryIdentityInactiveRiskPolicyUpdateAuditEventIncludeDisabledIdentityChange).includeDisabledIdentity
                                                ? localization.includeDisabledIdentity.true()
                                                : localization.includeDisabledIdentity.false())],
                            [Contract.TypeNames.AadDirectoryUserInactiveRiskPolicyUpdateAuditEventIncludeInvitationPendingAcceptanceGuestUserChange]:
                                () => [
                                    new AuditEventDefinitionDetailItem(
                                        localization.includeInvitationPendingAcceptanceGuestUser.previous(),
                                        _.isNil((change as Contract.AadDirectoryUserInactiveRiskPolicyUpdateAuditEventIncludeInvitationPendingAcceptanceGuestUserChange).previousIncludeInvitationPendingAcceptanceGuestUser)
                                            ? localization.inherit()
                                            : (change as Contract.AadDirectoryUserInactiveRiskPolicyUpdateAuditEventIncludeInvitationPendingAcceptanceGuestUserChange).previousIncludeInvitationPendingAcceptanceGuestUser
                                                ? localization.includeInvitationPendingAcceptanceGuestUser.true()
                                                : localization.includeInvitationPendingAcceptanceGuestUser.false()),
                                    new AuditEventDefinitionDetailItem(
                                        localization.includeInvitationPendingAcceptanceGuestUser.updated(),
                                        _.isNil((change as Contract.AadDirectoryUserInactiveRiskPolicyUpdateAuditEventIncludeInvitationPendingAcceptanceGuestUserChange).includeInvitationPendingAcceptanceGuestUser)
                                            ? localization.inherit()
                                            : (change as Contract.AadDirectoryUserInactiveRiskPolicyUpdateAuditEventIncludeInvitationPendingAcceptanceGuestUserChange).includeInvitationPendingAcceptanceGuestUser
                                                ? localization.includeInvitationPendingAcceptanceGuestUser.true()
                                                : localization.includeInvitationPendingAcceptanceGuestUser.false())],
                            [Contract.TypeNames.AwsDocDbClusterBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange]:
                                () => [
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AwsDocDbClusterBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange).previousBackupRetentionTimeFrame,
                                        localization.retentionTimeFrame.previous()),
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AwsDocDbClusterBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange).backupRetentionTimeFrame,
                                        localization.retentionTimeFrame.updated())],
                            [Contract.TypeNames.AwsEc2SecurityGroupUnusedRiskPolicyUpdateAuditEventUsageTimeFrameChange]:
                                () => [
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AwsEc2SecurityGroupUnusedRiskPolicyUpdateAuditEventUsageTimeFrameChange).previousUsageTimeFrame,
                                        localization.inactivityTimeFrame.previous()),
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AwsEc2SecurityGroupUnusedRiskPolicyUpdateAuditEventUsageTimeFrameChange).usageTimeFrame,
                                        localization.inactivityTimeFrame.updated())],
                            [Contract.TypeNames.AwsElasticBeanstalkEnvironmentCloudWatchLoggingDisabledRiskPolicyUpdateAuditEventCloudWatchLogRetentionTimeFrameChange]:
                                () => [
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AwsElasticBeanstalkEnvironmentCloudWatchLoggingDisabledRiskPolicyUpdateAuditEventCloudWatchLogRetentionTimeFrameChange).previousCloudWatchLogRetentionTimeFrame,
                                        localization.retentionTimeFrame.previous()),
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AwsElasticBeanstalkEnvironmentCloudWatchLoggingDisabledRiskPolicyUpdateAuditEventCloudWatchLogRetentionTimeFrameChange).cloudWatchLogRetentionTimeFrame,
                                        localization.retentionTimeFrame.updated())],
                            [Contract.TypeNames.AwsElastiCacheReplicationGroupAutomaticBackupsDisabledRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange]:
                                () => [
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AwsElastiCacheReplicationGroupAutomaticBackupsDisabledRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange).previousBackupRetentionTimeFrame,
                                        localization.retentionTimeFrame.previous()),
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AwsElastiCacheReplicationGroupAutomaticBackupsDisabledRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange).backupRetentionTimeFrame,
                                        localization.retentionTimeFrame.updated())],
                            [Contract.TypeNames.AwsIamPasswordLengthPolicyRiskPolicyUpdateAuditEventPasswordPolicyMinLengthChange]:
                                () => [
                                    getNumericChangeDetailsItem(
                                        (change as Contract.AwsIamPasswordLengthPolicyRiskPolicyUpdateAuditEventPasswordPolicyMinLengthChange).previousPasswordPolicyMinLength,
                                        localization.passwordRequirement.previous()),
                                    getNumericChangeDetailsItem(
                                        (change as Contract.AwsIamPasswordLengthPolicyRiskPolicyUpdateAuditEventPasswordPolicyMinLengthChange).passwordPolicyMinLength,
                                        localization.passwordRequirement.updated())],
                            [Contract.TypeNames.AwsIamPasswordReusePolicyRiskPolicyUpdateAuditEventPasswordPolicyPreventReusePreviousPasswordCountChange]:
                                () => [
                                    getNumericChangeDetailsItem(
                                        (change as Contract.AwsIamPasswordReusePolicyRiskPolicyUpdateAuditEventPasswordPolicyPreventReusePreviousPasswordCountChange).previousPasswordPolicyPreventReusePreviousPasswordCount,
                                        localization.passwordRequirement.previous()),
                                    getNumericChangeDetailsItem(
                                        (change as Contract.AwsIamPasswordReusePolicyRiskPolicyUpdateAuditEventPasswordPolicyPreventReusePreviousPasswordCountChange).passwordPolicyPreventReusePreviousPasswordCount,
                                        localization.passwordRequirement.updated())],
                            [Contract.TypeNames.AwsIamRootUserMfaDisabledRiskPolicyUpdateAuditEventIncludeUnusedRootUserChange]:
                                () => [
                                    new AuditEventDefinitionDetailItem(
                                        localization.includeUnusedRootUser.previous(),
                                        _.isNil((change as Contract.AwsIamRootUserMfaDisabledRiskPolicyUpdateAuditEventIncludeUnusedRootUserChange).previousIncludeUnusedRootUser)
                                            ? localization.inherit()
                                            : (change as Contract.AwsIamRootUserMfaDisabledRiskPolicyUpdateAuditEventIncludeUnusedRootUserChange).previousIncludeUnusedRootUser
                                                ? localization.includeUnusedRootUser.true()
                                                : localization.includeUnusedRootUser.false()),
                                    new AuditEventDefinitionDetailItem(
                                        localization.includeUnusedRootUser.updated(),
                                        _.isNil((change as Contract.AwsIamRootUserMfaDisabledRiskPolicyUpdateAuditEventIncludeUnusedRootUserChange).includeUnusedRootUser)
                                            ? localization.inherit()
                                            : (change as Contract.AwsIamRootUserMfaDisabledRiskPolicyUpdateAuditEventIncludeUnusedRootUserChange).includeUnusedRootUser
                                                ? localization.includeUnusedRootUser.true()
                                                : localization.includeUnusedRootUser.false())],
                            [Contract.TypeNames.AwsIamUserUnusedAccessKeysEnabledRiskPolicyUpdateAuditEventAccessKeyUsageTimeFrameChange]:
                                () => [
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AwsIamUserUnusedAccessKeysEnabledRiskPolicyUpdateAuditEventAccessKeyUsageTimeFrameChange).previousAccessKeyUsageTimeFrame,
                                        localization.inactivityTimeFrame.previous()),
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AwsIamUserUnusedAccessKeysEnabledRiskPolicyUpdateAuditEventAccessKeyUsageTimeFrameChange).accessKeyUsageTimeFrame,
                                        localization.inactivityTimeFrame.updated())],
                            [Contract.TypeNames.AwsIamUserUnusedPasswordEnabledRiskPolicyUpdateAuditEventPasswordUsageTimeFrameChange]:
                                () => [
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AwsIamUserUnusedPasswordEnabledRiskPolicyUpdateAuditEventPasswordUsageTimeFrameChange).previousPasswordUsageTimeFrame,
                                        localization.inactivityTimeFrame.previous()),
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AwsIamUserUnusedPasswordEnabledRiskPolicyUpdateAuditEventPasswordUsageTimeFrameChange).passwordUsageTimeFrame,
                                        localization.inactivityTimeFrame.updated())],
                            [Contract.TypeNames.AwsIamUserUnrotatedAccessKeyRiskPolicyUpdateAuditEventAccessKeyRotationTimeFrameChange]:
                                () => [
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AwsIamUserUnrotatedAccessKeyRiskPolicyUpdateAuditEventAccessKeyRotationTimeFrameChange).previousAccessKeyRotationTimeFrame,
                                        localization.rotationTimeFrame.previous()),
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AwsIamUserUnrotatedAccessKeyRiskPolicyUpdateAuditEventAccessKeyRotationTimeFrameChange).accessKeyRotationTimeFrame,
                                        localization.rotationTimeFrame.updated())],
                            [Contract.TypeNames.AwsKmsKeyRotationDisabledRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange]:
                                () => [
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AwsKmsKeyRotationDisabledRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange).previousKeyRotationTimeFrame,
                                        localization.rotationTimeFrame.previous()),
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AwsKmsKeyRotationDisabledRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange).keyRotationTimeFrame,
                                        localization.rotationTimeFrame.updated())],
                            [Contract.TypeNames.AwsLambdaFunctionConfigurationPublicAccessExistsRiskPolicyUpdateAuditEventApiGatewayRouteExclusionsChange]:
                                () => {
                                    const apiGatewayRouteExclusionsChange = change as Contract.AwsLambdaFunctionConfigurationPublicAccessExistsRiskPolicyUpdateAuditEventApiGatewayRouteExclusionsChange;
                                    return [
                                        _.isEmpty(apiGatewayRouteExclusionsChange.addedRoutePatterns)
                                            ? undefined!
                                            : new AuditEventDefinitionDetailItem(
                                                localization.addedApiGatewayRoutePattern(apiGatewayRouteExclusionsChange.addedRoutePatterns.length),
                                                localization.patternCount(
                                                    apiGatewayRouteExclusionsChange.addedRoutePatterns.length,
                                                    {
                                                        count: apiGatewayRouteExclusionsChange.addedRoutePatterns.length
                                                    }),
                                                <InlineItems
                                                    items={apiGatewayRouteExclusionsChange.addedRoutePatterns}
                                                    variant="itemPlusItemCount">
                                                    {(routePattern: Contract.AwsLambdaFunctionConfigurationPublicAccessExistsApiGatewayRouteExclusionRoutePattern) =>
                                                        <Typography component="span">
                                                            {`${routePattern.pathPattern} \(${_.join(routePattern.methods, ",")}\)`}
                                                        </Typography>}
                                                </InlineItems>),
                                        _.isEmpty(apiGatewayRouteExclusionsChange.removedRoutePatterns)
                                            ? undefined!
                                            : new AuditEventDefinitionDetailItem(
                                                localization.removedApiGatewayRoutePattern(apiGatewayRouteExclusionsChange.removedRoutePatterns.length),
                                                localization.patternCount(
                                                    apiGatewayRouteExclusionsChange.removedRoutePatterns.length,
                                                    {
                                                        count: apiGatewayRouteExclusionsChange.removedRoutePatterns.length
                                                    }),
                                                <InlineItems
                                                    items={apiGatewayRouteExclusionsChange.removedRoutePatterns}
                                                    variant="itemPlusItemCount">
                                                    {(routePattern: Contract.AwsLambdaFunctionConfigurationPublicAccessExistsApiGatewayRouteExclusionRoutePattern) =>
                                                        <Typography component="span">
                                                            {`${routePattern.pathPattern} \(${_.join(routePattern.methods, ",")}\)`}
                                                        </Typography>}
                                                </InlineItems>)];
                                },
                            [Contract.TypeNames.AwsRdsClusterBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange]:
                                () => [
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AwsRdsClusterBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange).previousBackupRetentionTimeFrame,
                                        localization.retentionTimeFrame.previous()),
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AwsRdsClusterBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange).backupRetentionTimeFrame,
                                        localization.retentionTimeFrame.updated())],
                            [Contract.TypeNames.AwsRdsDatabaseInstanceBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange]:
                                () => [
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AwsRdsDatabaseInstanceBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange).previousBackupRetentionTimeFrame,
                                        localization.retentionTimeFrame.previous()),
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AwsRdsDatabaseInstanceBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange).backupRetentionTimeFrame,
                                        localization.retentionTimeFrame.updated())],
                            [Contract.TypeNames.AwsRdsNeptuneClusterBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange]:
                                () => [
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AwsRdsNeptuneClusterBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange).previousBackupRetentionTimeFrame,
                                        localization.retentionTimeFrame.previous()),
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AwsRdsNeptuneClusterBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange).backupRetentionTimeFrame,
                                        localization.retentionTimeFrame.updated())],
                            [Contract.TypeNames.AwsRedshiftClusterBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange]:
                                () => [
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AwsRedshiftClusterBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange).previousBackupRetentionTimeFrame,
                                        localization.retentionTimeFrame.previous()),
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AwsRedshiftClusterBackupRetentionTimeFrameRiskPolicyUpdateAuditEventBackupRetentionTimeFrameChange).backupRetentionTimeFrame,
                                        localization.retentionTimeFrame.updated())],
                            [Contract.TypeNames.AzureExcessivePermissionPrincipalRiskPolicyUpdateAuditEventInactivePrincipalDeleteGroupMembershipEnabledChange]:
                                () => [
                                    new AuditEventDefinitionDetailItem(
                                        localization.inactivePrincipalDeleteGroupMembershipEnabled.previous(),
                                        _.isNil((change as Contract.AzureExcessivePermissionPrincipalRiskPolicyUpdateAuditEventInactivePrincipalDeleteGroupMembershipEnabledChange).previousInactivePrincipalDeleteGroupMembershipEnabled)
                                            ? localization.inherit()
                                            : (change as Contract.AzureExcessivePermissionPrincipalRiskPolicyUpdateAuditEventInactivePrincipalDeleteGroupMembershipEnabledChange).previousInactivePrincipalDeleteGroupMembershipEnabled
                                                ? localization.inactivePrincipalDeleteGroupMembershipEnabled.true()
                                                : localization.inactivePrincipalDeleteGroupMembershipEnabled.false()),
                                    new AuditEventDefinitionDetailItem(
                                        localization.inactivePrincipalDeleteGroupMembershipEnabled.updated(),
                                        _.isNil((change as Contract.AzureExcessivePermissionPrincipalRiskPolicyUpdateAuditEventInactivePrincipalDeleteGroupMembershipEnabledChange).inactivePrincipalDeleteGroupMembershipEnabled)
                                            ? localization.inherit()
                                            : (change as Contract.AzureExcessivePermissionPrincipalRiskPolicyUpdateAuditEventInactivePrincipalDeleteGroupMembershipEnabledChange).inactivePrincipalDeleteGroupMembershipEnabled
                                                ? localization.inactivePrincipalDeleteGroupMembershipEnabled.true()
                                                : localization.inactivePrincipalDeleteGroupMembershipEnabled.false())],
                            [Contract.TypeNames.AzureKeyVaultVaultCertificateNotRotatedRiskPolicyUpdateAuditEventCertificateRotationTimeFrameChange]:
                                () => [
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AzureKeyVaultVaultCertificateNotRotatedRiskPolicyUpdateAuditEventCertificateRotationTimeFrameChange).previousCertificateRotationTimeFrame,
                                        localization.rotationTimeFrame.previous()),
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AzureKeyVaultVaultCertificateNotRotatedRiskPolicyUpdateAuditEventCertificateRotationTimeFrameChange).certificateRotationTimeFrame,
                                        localization.rotationTimeFrame.updated())],
                            [Contract.TypeNames.AzureKeyVaultVaultKeyNotRotatedRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange]:
                                () => [
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AzureKeyVaultVaultKeyNotRotatedRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange).previousKeyRotationTimeFrame,
                                        localization.rotationTimeFrame.previous()),
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AzureKeyVaultVaultKeyNotRotatedRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange).keyRotationTimeFrame,
                                        localization.rotationTimeFrame.updated())],
                            [Contract.TypeNames.AzureKeyVaultVaultKeyRotationDisabledRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange]:
                                () => [
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AzureKeyVaultVaultKeyRotationDisabledRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange).previousKeyRotationTimeFrame,
                                        localization.rotationTimeFrame.previous()),
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AzureKeyVaultVaultKeyRotationDisabledRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange).keyRotationTimeFrame,
                                        localization.rotationTimeFrame.updated())],
                            [Contract.TypeNames.AzureSqlServerEventRetentionTimeFrameRiskPolicyUpdateAuditEventEventRetentionTimeFrameChange]:
                                () => [
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AzureSqlServerEventRetentionTimeFrameRiskPolicyUpdateAuditEventEventRetentionTimeFrameChange).previousEventRetentionTimeFrame,
                                        localization.retentionTimeFrame.previous()),
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.AzureSqlServerEventRetentionTimeFrameRiskPolicyUpdateAuditEventEventRetentionTimeFrameChange).eventRetentionTimeFrame,
                                        localization.retentionTimeFrame.updated())],
                            [Contract.TypeNames.GciDirectoryUserInactiveRiskPolicyUpdateAuditEventIncludeDisabledUserChange]:
                                () => [
                                    new AuditEventDefinitionDetailItem(
                                        localization.includeDisabledIdentity.previous(),
                                        _.isNil((change as Contract.GciDirectoryUserInactiveRiskPolicyUpdateAuditEventIncludeDisabledUserChange).previousIncludeDisabledUser)
                                            ? localization.inherit()
                                            : (change as Contract.GciDirectoryUserInactiveRiskPolicyUpdateAuditEventIncludeDisabledUserChange).previousIncludeDisabledUser
                                                ? localization.includeDisabledIdentity.true()
                                                : localization.includeDisabledIdentity.false()),
                                    new AuditEventDefinitionDetailItem(
                                        localization.includeDisabledIdentity.updated(),
                                        _.isNil((change as Contract.GciDirectoryUserInactiveRiskPolicyUpdateAuditEventIncludeDisabledUserChange).includeDisabledUser)
                                            ? localization.inherit()
                                            : (change as Contract.GciDirectoryUserInactiveRiskPolicyUpdateAuditEventIncludeDisabledUserChange).includeDisabledUser
                                                ? localization.includeDisabledIdentity.true()
                                                : localization.includeDisabledIdentity.false())],
                            [Contract.TypeNames.GcpApiKeysKeyNotRotatedRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange]:
                                () => [
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.GcpApiKeysKeyNotRotatedRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange).previousKeyRotationTimeFrame,
                                        localization.rotationTimeFrame.previous()),
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.GcpApiKeysKeyNotRotatedRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange).keyRotationTimeFrame,
                                        localization.rotationTimeFrame.updated())],
                            [Contract.TypeNames.GcpIamServiceAccountUnrotatedUserManagedKeyRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange]:
                                () => [
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.GcpIamServiceAccountUnrotatedUserManagedKeyRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange).previousKeyRotationTimeFrame,
                                        localization.rotationTimeFrame.previous()),
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.GcpIamServiceAccountUnrotatedUserManagedKeyRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange).keyRotationTimeFrame,
                                        localization.rotationTimeFrame.updated())],
                            [Contract.TypeNames.GcpIamServiceAccountInactiveRiskPolicyUpdateAuditEventIncludeDisabledServiceAccountChange]:
                                () => [
                                    new AuditEventDefinitionDetailItem(
                                        localization.includeDisabledIdentity.previous(),
                                        _.isNil((change as Contract.GcpIamServiceAccountInactiveRiskPolicyUpdateAuditEventIncludeDisabledServiceAccountChange).previousIncludeDisabledServiceAccount)
                                            ? localization.inherit()
                                            : (change as Contract.GcpIamServiceAccountInactiveRiskPolicyUpdateAuditEventIncludeDisabledServiceAccountChange).previousIncludeDisabledServiceAccount
                                                ? localization.includeDisabledIdentity.true()
                                                : localization.includeDisabledIdentity.false()),
                                    new AuditEventDefinitionDetailItem(
                                        localization.includeDisabledIdentity.updated(),
                                        _.isNil((change as Contract.GcpIamServiceAccountInactiveRiskPolicyUpdateAuditEventIncludeDisabledServiceAccountChange).includeDisabledServiceAccount)
                                            ? localization.inherit()
                                            : (change as Contract.GcpIamServiceAccountInactiveRiskPolicyUpdateAuditEventIncludeDisabledServiceAccountChange).includeDisabledServiceAccount
                                                ? localization.includeDisabledIdentity.true()
                                                : localization.includeDisabledIdentity.false())],
                            [Contract.TypeNames.GcpExcessivePermissionPrincipalRiskPolicyUpdateAuditEventInactivePrincipalDeleteGroupMembershipEnabledChange]:
                                () => [
                                    new AuditEventDefinitionDetailItem(
                                        localization.inactivePrincipalDeleteGroupMembershipEnabled.previous(),
                                        _.isNil((change as Contract.GcpExcessivePermissionPrincipalRiskPolicyUpdateAuditEventInactivePrincipalDeleteGroupMembershipEnabledChange).previousInactivePrincipalDeleteGroupMembershipEnabled)
                                            ? localization.inherit()
                                            : (change as Contract.GcpExcessivePermissionPrincipalRiskPolicyUpdateAuditEventInactivePrincipalDeleteGroupMembershipEnabledChange).previousInactivePrincipalDeleteGroupMembershipEnabled
                                                ? localization.inactivePrincipalDeleteGroupMembershipEnabled.true()
                                                : localization.inactivePrincipalDeleteGroupMembershipEnabled.false()),
                                    new AuditEventDefinitionDetailItem(
                                        localization.inactivePrincipalDeleteGroupMembershipEnabled.updated(),
                                        _.isNil((change as Contract.GcpExcessivePermissionPrincipalRiskPolicyUpdateAuditEventInactivePrincipalDeleteGroupMembershipEnabledChange).inactivePrincipalDeleteGroupMembershipEnabled)
                                            ? localization.inherit()
                                            : (change as Contract.GcpExcessivePermissionPrincipalRiskPolicyUpdateAuditEventInactivePrincipalDeleteGroupMembershipEnabledChange).inactivePrincipalDeleteGroupMembershipEnabled
                                                ? localization.inactivePrincipalDeleteGroupMembershipEnabled.true()
                                                : localization.inactivePrincipalDeleteGroupMembershipEnabled.false())],
                            [Contract.TypeNames.GcpKmsKeyRingKeyRotationTimeFrameRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange]:
                                () => [
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.GcpKmsKeyRingKeyRotationTimeFrameRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange).previousKeyRotationTimeFrame,
                                        localization.rotationTimeFrame.previous()),
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.GcpKmsKeyRingKeyRotationTimeFrameRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange).keyRotationTimeFrame,
                                        localization.rotationTimeFrame.updated())],
                            [Contract.TypeNames.GcpSecretManagerSecretRotationTimeFrameRiskPolicyUpdateAuditEventSecretRotationTimeFrameChange]:
                                () => [
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.GcpSecretManagerSecretRotationTimeFrameRiskPolicyUpdateAuditEventSecretRotationTimeFrameChange).previousSecretRotationTimeFrame,
                                        localization.rotationTimeFrame.previous()),
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.GcpSecretManagerSecretRotationTimeFrameRiskPolicyUpdateAuditEventSecretRotationTimeFrameChange).secretRotationTimeFrame,
                                        localization.rotationTimeFrame.updated())],
                            [Contract.TypeNames.GcpTenantEntityUnusedRiskPolicyUpdateAuditEventProjectUsageTimeFrameChange]:
                                () => [
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.GcpTenantEntityUnusedRiskPolicyUpdateAuditEventProjectUsageTimeFrameChange).previousProjectUsageTimeFrame,
                                        localization.inactivityTimeFrame.previous()),
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.GcpTenantEntityUnusedRiskPolicyUpdateAuditEventProjectUsageTimeFrameChange).projectUsageTimeFrame,
                                        localization.inactivityTimeFrame.updated())],
                            [Contract.TypeNames.OciIamDomainPasswordExpirationPolicyRiskPolicyUpdateAuditEventPasswordPolicyExpirationIntervalDaysChange]:
                                () => [
                                    getNumericChangeDetailsItem(
                                        (change as Contract.OciIamDomainPasswordExpirationPolicyRiskPolicyUpdateAuditEventPasswordPolicyExpirationIntervalDaysChange).passwordPolicyExpirationIntervalDays,
                                        localization.passwordRequirement.previous()),
                                    getNumericChangeDetailsItem(
                                        (change as Contract.OciIamDomainPasswordExpirationPolicyRiskPolicyUpdateAuditEventPasswordPolicyExpirationIntervalDaysChange).passwordPolicyExpirationIntervalDays,
                                        localization.passwordRequirement.updated())],
                            [Contract.TypeNames.OciIamDomainPasswordLengthPolicyRiskPolicyUpdateAuditEventPasswordPolicyMinLengthChange]:
                                () => [
                                    getNumericChangeDetailsItem(
                                        (change as Contract.OciIamDomainPasswordLengthPolicyRiskPolicyUpdateAuditEventPasswordPolicyMinLengthChange).passwordPolicyMinLength,
                                        localization.passwordRequirement.previous()),
                                    getNumericChangeDetailsItem(
                                        (change as Contract.OciIamDomainPasswordLengthPolicyRiskPolicyUpdateAuditEventPasswordPolicyMinLengthChange).passwordPolicyMinLength,
                                        localization.passwordRequirement.updated())],
                            [Contract.TypeNames.OciIamDomainPasswordReusePolicyRiskPolicyUpdateAuditEventPasswordPolicyPreventReusePreviousPasswordCountChange]:
                                () => [
                                    getNumericChangeDetailsItem(
                                        (change as Contract.OciIamDomainPasswordReusePolicyRiskPolicyUpdateAuditEventPasswordPolicyPreventReusePreviousPasswordCountChange).passwordPolicyPreventReusePreviousPasswordCount,
                                        localization.passwordRequirement.previous()),
                                    getNumericChangeDetailsItem(
                                        (change as Contract.OciIamDomainPasswordReusePolicyRiskPolicyUpdateAuditEventPasswordPolicyPreventReusePreviousPasswordCountChange).passwordPolicyPreventReusePreviousPasswordCount,
                                        localization.passwordRequirement.updated())],
                            [Contract.TypeNames.OciIamUserCredentialNotRotatedRiskPolicyUpdateAuditEventCredentialRotationTimeFrameChange]:
                                () => [
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.OciIamUserCredentialNotRotatedRiskPolicyUpdateAuditEventCredentialRotationTimeFrameChange).previousCredentialRotationTimeFrame,
                                        localization.rotationTimeFrame.previous()),
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.OciIamUserCredentialNotRotatedRiskPolicyUpdateAuditEventCredentialRotationTimeFrameChange).credentialRotationTimeFrame,
                                        localization.rotationTimeFrame.updated())],
                            [Contract.TypeNames.OciKmsVaultKeyNotRotatedRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange]:
                                () => [
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.OciKmsVaultKeyNotRotatedRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange).previousKeyRotationTimeFrame,
                                        localization.rotationTimeFrame.previous()),
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.OciKmsVaultKeyNotRotatedRiskPolicyUpdateAuditEventKeyRotationTimeFrameChange).keyRotationTimeFrame,
                                        localization.rotationTimeFrame.updated())],
                            [Contract.TypeNames.RiskPolicyUpdateAuditEventCodeEnabledChange]:
                                () => [
                                    new AuditEventDefinitionDetailItem(
                                        localization.codeEnabled.previous(),
                                        _.isNil((change as Contract.RiskPolicyUpdateAuditEventCodeEnabledChange).previousEnabled)
                                            ? localization.inherit()
                                            : (change as Contract.RiskPolicyUpdateAuditEventCodeEnabledChange).previousEnabled
                                                ? localization.codeEnabled.true()
                                                : localization.codeEnabled.false()),
                                    new AuditEventDefinitionDetailItem(
                                        localization.codeEnabled.updated(),
                                        _.isNil((change as Contract.RiskPolicyUpdateAuditEventCodeEnabledChange).enabled)
                                            ? localization.inherit()
                                            : (change as Contract.RiskPolicyUpdateAuditEventCodeEnabledChange).enabled
                                                ? localization.codeEnabled.true()
                                                : localization.codeEnabled.false())],
                            [Contract.TypeNames.RiskPolicyUpdateAuditEventEnabledChange]:
                                () => [
                                    new AuditEventDefinitionDetailItem(
                                        localization.enabled.previous(),
                                        _.isNil((change as Contract.RiskPolicyUpdateAuditEventEnabledChange).previousEnabled)
                                            ? localization.inherit()
                                            : (change as Contract.RiskPolicyUpdateAuditEventEnabledChange).previousEnabled
                                                ? localization.enabled.true()
                                                : localization.enabled.false()),
                                    new AuditEventDefinitionDetailItem(
                                        localization.enabled.updated(),
                                        _.isNil((change as Contract.RiskPolicyUpdateAuditEventEnabledChange).enabled)
                                            ? localization.inherit()
                                            : (change as Contract.RiskPolicyUpdateAuditEventEnabledChange).enabled
                                                ? localization.enabled.true()
                                                : localization.enabled.false())],
                            [Contract.TypeNames.RiskPolicyUpdateAuditEventEntityCreationTimeAnalysisDelayTimeFrameChange]:
                                () => [
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.RiskPolicyUpdateAuditEventEntityCreationTimeAnalysisDelayTimeFrameChange).previousEntityCreationTimeAnalysisDelayTimeFrame,
                                        localization.entityCreationTimeAnalysisDelayTimeFrame.previous()),
                                    getTimeFrameChangeDetailsItem(
                                        (change as Contract.RiskPolicyUpdateAuditEventEntityCreationTimeAnalysisDelayTimeFrameChange).entityCreationTimeAnalysisDelayTimeFrame,
                                        localization.entityCreationTimeAnalysisDelayTimeFrame.updated())],
                            [Contract.TypeNames.RiskPolicyUpdateAuditEventCodeExclusionsChange]:
                                () => {
                                    const codeExclusionsChange = change as Contract.RiskPolicyUpdateAuditEventCodeExclusionsChange;
                                    return [
                                        _.isEmpty(codeExclusionsChange.addedCodeExclusions)
                                            ? undefined!
                                            : new AuditEventDefinitionDetailItem(
                                                localization.addedCodeExclusions(codeExclusionsChange.addedCodeExclusions.length),
                                                localization.patternCount(
                                                    codeExclusionsChange.addedCodeExclusions.length,
                                                    {
                                                        count: codeExclusionsChange.addedCodeExclusions.length
                                                    }),
                                                <InlineItems
                                                    items={codeExclusionsChange.addedCodeExclusions}
                                                    variant="itemPlusItemCount">
                                                    {(codeExclusion: Contract.RiskPolicyConfigurationCodeExclusion) =>
                                                        <Typography component="span">
                                                            {codeExclusion.fileRelativePathPattern}
                                                        </Typography>}
                                                </InlineItems>),
                                        _.isEmpty(codeExclusionsChange.removedCodeExclusions)
                                            ? undefined!
                                            : new AuditEventDefinitionDetailItem(
                                                localization.removedCodeExclusions(codeExclusionsChange.removedCodeExclusions.length),
                                                localization.patternCount(
                                                    codeExclusionsChange.removedCodeExclusions.length,
                                                    {
                                                        count: codeExclusionsChange.removedCodeExclusions.length
                                                    }),
                                                <InlineItems
                                                    items={codeExclusionsChange.removedCodeExclusions}
                                                    variant="itemPlusItemCount">
                                                    {(codeExclusion: Contract.RiskPolicyConfigurationCodeExclusion) =>
                                                        <Typography component="span">
                                                            {codeExclusion.fileRelativePathPattern}
                                                        </Typography>}
                                                </InlineItems>)];
                                },
                            [Contract.TypeNames.RiskPolicyUpdateAuditEventEntityExclusionsChange]:
                                () => {
                                    const entityExclusionsChange = change as Contract.RiskPolicyUpdateAuditEventEntityExclusionsChange;
                                    const addedEntityPropertyPatterns =
                                        _(entityExclusionsChange.addedEntityTypeNameToEntityPropertyPatternsMap).
                                            flatMap(propertyPatterns => propertyPatterns).
                                            value();
                                    const removedEntityPropertyPatterns =
                                        _(entityExclusionsChange.removedEntityTypeNameToEntityPropertyPatternsMap).
                                            flatMap(propertyPatterns => propertyPatterns).
                                            value();

                                    return [
                                        _.isEmpty(entityExclusionsChange.addedExcludedEntityIds)
                                            ? undefined!
                                            : new AuditEventDefinitionDetailItem(
                                                localization.addedExcludedEntityIds(entityExclusionsChange.addedExcludedEntityIds.length),
                                                entityExclusionsChange.addedExcludedEntityIds.length === 1
                                                    ? context.entityModelMap[entityExclusionsChange.addedExcludedEntityIds[0]].entity.displayName
                                                    : entityTypeNameTranslator(
                                                        Contract.TypeNames.Entity,
                                                        {
                                                            count: entityExclusionsChange.addedExcludedEntityIds.length,
                                                            includeCount: true
                                                        }),
                                                <InlineEntities
                                                    entityIdsOrModels={entityExclusionsChange.addedExcludedEntityIds}
                                                    entityTypeName={Contract.TypeNames.Entity}
                                                    variant="itemPlusItemCount"/>),
                                        _.isEmpty(entityExclusionsChange.removedExcludedEntityIds)
                                            ? undefined!
                                            : new AuditEventDefinitionDetailItem(
                                                localization.removedExcludedEntityIds(entityExclusionsChange.removedExcludedEntityIds.length),
                                                entityExclusionsChange.removedExcludedEntityIds.length === 1
                                                    ? context.entityModelMap[entityExclusionsChange.removedExcludedEntityIds[0]].entity.displayName
                                                    : entityTypeNameTranslator(
                                                        Contract.TypeNames.Entity,
                                                        {
                                                            count: entityExclusionsChange.removedExcludedEntityIds.length,
                                                            includeCount: true
                                                        }),
                                                <InlineEntities
                                                    entityIdsOrModels={entityExclusionsChange.removedExcludedEntityIds}
                                                    entityTypeName={Contract.TypeNames.Entity}
                                                    variant="itemPlusItemCount"/>),
                                        _.isEmpty(addedEntityPropertyPatterns)
                                            ? undefined!
                                            : new AuditEventDefinitionDetailItem(
                                                localization.addedEntityPropertyPatterns(addedEntityPropertyPatterns!.length),
                                                localization.entityPropertyPattern(
                                                    addedEntityPropertyPatterns!.length,
                                                    {
                                                        count: addedEntityPropertyPatterns!.length
                                                    }),
                                                <InlineItems
                                                    items={addedEntityPropertyPatterns}
                                                    variant="itemPlusItemCount">
                                                    {(entityPropertyPattern: Contract.RiskPolicyConfigurationEntityExclusionEntityPropertyPattern) =>
                                                        <Typography component="span">
                                                            {`${exclusionEntityPropertyPatternTranslator(entityPropertyPattern.typeName)}: ${getPropertyPatternValue(entityPropertyPattern)}`}
                                                        </Typography>}
                                                </InlineItems>),
                                        _.isEmpty(removedEntityPropertyPatterns)
                                            ? undefined!
                                            : new AuditEventDefinitionDetailItem(
                                                localization.removedEntityPropertyPatterns(removedEntityPropertyPatterns!.length),
                                                localization.entityPropertyPattern(
                                                    removedEntityPropertyPatterns!.length,
                                                    {
                                                        count: removedEntityPropertyPatterns!.length
                                                    }),
                                                <InlineItems
                                                    items={removedEntityPropertyPatterns}
                                                    variant="itemPlusItemCount">
                                                    {(entityPropertyPattern: Contract.RiskPolicyConfigurationEntityExclusionEntityPropertyPattern) =>
                                                        <Typography component="span">
                                                            {`${exclusionEntityPropertyPatternTranslator(entityPropertyPattern.typeName)}: ${getPropertyPatternValue(entityPropertyPattern)}`}
                                                        </Typography>}
                                                </InlineItems>)];
                                },
                            [Contract.TypeNames.SecretExistsRiskPolicyUpdateAuditEventSecretExclusionsChange]:
                                () => {
                                    const secretExclusionsChange = change as Contract.SecretExistsRiskPolicyUpdateAuditEventSecretExclusionsChange;
                                    return [
                                        _.isEmpty(secretExclusionsChange.addedSecretPatterns)
                                            ? undefined!
                                            : new AuditEventDefinitionDetailItem(
                                                localization.addedSecretPatterns(secretExclusionsChange.addedSecretPatterns.length),
                                                localization.patternCount(
                                                    secretExclusionsChange.addedSecretPatterns.length,
                                                    {
                                                        count: secretExclusionsChange.addedSecretPatterns.length
                                                    }),
                                                <InlineItems
                                                    items={secretExclusionsChange.addedSecretPatterns}
                                                    variant="itemPlusItemCount">
                                                    {(secretPattern: Contract.SecretExistsRiskPolicyConfigurationSecretExclusionSecretPattern) =>
                                                        <Typography component="span">
                                                            {`${secretPattern.keyPattern}: ${secretPattern.valuePattern}`}
                                                        </Typography>}
                                                </InlineItems>),
                                        _.isEmpty(secretExclusionsChange.removedSecretPatterns)
                                            ? undefined!
                                            : new AuditEventDefinitionDetailItem(
                                                localization.removedSecretPatterns(secretExclusionsChange.removedSecretPatterns.length),
                                                localization.patternCount(
                                                    secretExclusionsChange.removedSecretPatterns.length,
                                                    {
                                                        count: secretExclusionsChange.removedSecretPatterns.length
                                                    }),
                                                <InlineItems
                                                    items={secretExclusionsChange.removedSecretPatterns}
                                                    variant="itemPlusItemCount">
                                                    {(secretPattern: Contract.SecretExistsRiskPolicyConfigurationSecretExclusionSecretPattern) =>
                                                        <Typography component="span">
                                                            {`${secretPattern.keyPattern}: ${secretPattern.valuePattern}`}
                                                        </Typography>}
                                                </InlineItems>)];
                                }
                        })).
            filter().
            value();
}