import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function RubrikIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58343_1966)">
                <path
                    clipRule="evenodd"
                    d="M11.8356 0C11.7808 0 11.6712 0.0550459 11.6164 0.110092L8.32877 3.41284C8.10959 3.63303 8.10959 3.9633 8.32877 4.12844L11.6164 7.43119C11.8356 7.65138 12.1644 7.65138 12.3288 7.43119L15.6164 4.12844C15.8356 3.90826 15.8356 3.57798 15.6164 3.41284L12.3288 0.110092C12.3288 0.0550459 12.274 0 12.1644 0H11.8356Z"
                    fill="#0AC9BB"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M11.6712 16.5688L8.38356 19.8716C8.16438 20.0917 8.16438 20.422 8.38356 20.5872L11.6712 23.8899C11.726 23.945 11.7808 23.945 11.8356 24H12.274C12.3288 24 12.3836 23.945 12.4384 23.8899L15.726 20.5872C15.9452 20.367 15.9452 20.0367 15.726 19.8716L12.4384 16.5688C12.3288 16.4587 12.2192 16.4037 12.0548 16.4037C11.8904 16.4587 11.726 16.5138 11.6712 16.5688Z"
                    fill="#0AC9BB"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M19.8356 8.36697L16.5479 11.6697C16.3288 11.8899 16.3288 12.2202 16.5479 12.3853L19.8356 15.6881C20.0548 15.9083 20.3836 15.9083 20.5479 15.6881L23.8356 12.3853C24.0548 12.1651 24.0548 11.8349 23.8356 11.6697L20.5479 8.36697C20.4384 8.25688 20.3288 8.20183 20.1644 8.20183C20.0548 8.20183 19.9452 8.25688 19.8356 8.36697Z"
                    fill="#0AC9BB"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M7.50685 20.6972L6.0274 22.1835C5.86301 22.2936 5.86301 22.5688 5.9726 22.6789C6.0274 22.7339 6.08219 22.7339 6.13699 22.789L7.39726 23.3945C7.56164 23.5046 7.72603 23.4495 7.83562 23.2844C7.89041 23.2294 7.89041 23.1743 7.89041 23.0642V20.8073C7.89041 20.6422 7.83562 20.5321 7.72603 20.5321C7.61644 20.5872 7.56164 20.6422 7.50685 20.6972Z"
                    fill="#0AC9BB"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M16.1096 20.8073V23.0092C16.1096 23.1743 16.2192 23.3395 16.3836 23.3945C16.4384 23.3945 16.5479 23.3945 16.6027 23.3395L17.863 22.7339C18.0274 22.6789 18.137 22.4587 18.0274 22.2385C18.0274 22.1835 17.9726 22.1284 17.9178 22.0734L16.4384 20.5872C16.3836 20.5321 16.3288 20.4771 16.2192 20.4771C16.1644 20.5321 16.1096 20.6422 16.1096 20.8073Z"
                    fill="#0AC9BB"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M16.6575 16.1284C16.3836 16.1284 16.1644 16.3486 16.1644 16.6239V19.5963C16.1644 19.8716 16.3836 20.0917 16.6575 20.0917H19.6164C19.8904 20.0917 20.1644 19.8716 20.1644 19.5963V16.6239C20.1644 16.3486 19.9452 16.1284 19.6712 16.1284C19.6164 16.1284 16.6575 16.1284 16.6575 16.1284Z"
                    fill="#0AC9BB"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M22.137 6.05505L20.6575 7.54128C20.4384 7.76147 20.5479 7.92661 20.8219 7.92661H23.0137C23.1781 7.92661 23.3425 7.81651 23.3973 7.65138C23.3973 7.59633 23.3973 7.54128 23.3425 7.48624L22.7397 6.22018C22.6849 6.11009 22.5753 6 22.411 6C22.3014 5.94495 22.1918 6 22.137 6.05505Z"
                    fill="#0AC9BB"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M16.1096 0.990826V3.19266C16.1096 3.46789 16.274 3.52294 16.4931 3.3578L17.9726 1.87156C18.137 1.76147 18.137 1.54128 18.0274 1.37615C17.9726 1.3211 17.9178 1.3211 17.863 1.26606L16.6027 0.66055C16.5479 0.66055 16.4931 0.66055 16.4384 0.66055C16.274 0.605505 16.1644 0.770642 16.1096 0.990826C16.1644 0.990826 16.1644 0.93578 16.1096 0.990826Z"
                    fill="#0AC9BB"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M1.26027 6.16514L0.657534 7.43119C0.547945 7.59633 0.60274 7.76147 0.767123 7.87156C0.821918 7.92661 0.876712 7.92661 0.986301 7.92661H3.17808C3.45205 7.92661 3.56164 7.76147 3.34247 7.54128L1.91781 6.05505C1.86301 6 1.75342 5.94495 1.64384 5.94495C1.47945 5.94495 1.36986 6 1.26027 6.16514Z"
                    fill="#0AC9BB"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M4.43836 3.85321C4.10959 3.85321 3.89041 4.12844 3.89041 4.40367V7.37615C3.89041 7.65138 4.10959 7.87156 4.38356 7.87156H7.34247C7.61644 7.87156 7.83562 7.65138 7.83562 7.37615V4.40367C7.89041 4.12844 7.67123 3.85321 7.39726 3.85321H4.43836Z"
                    fill="#0AC9BB"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M7.39726 0.660551L6.13699 1.26606C5.9726 1.3211 5.86301 1.54128 5.91781 1.76147C5.91781 1.81651 5.9726 1.87156 6.0274 1.92661L7.50685 3.41284C7.72603 3.63303 7.89041 3.52294 7.89041 3.24771V0.990826C7.89041 0.825688 7.78082 0.660551 7.61644 0.660551C7.61644 0.660551 7.61644 0.660551 7.56164 0.660551C7.56164 0.605505 7.50685 0.660551 7.39726 0.660551Z"
                    fill="#0AC9BB"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M20.8219 16.1284C20.5479 16.1284 20.4931 16.2936 20.6575 16.5138L22.137 18C22.2466 18.1651 22.5205 18.1651 22.6849 18.055C22.7397 18 22.7397 18 22.7945 17.945L23.3973 16.6789C23.5068 16.5138 23.4521 16.3486 23.2877 16.2385C23.2329 16.1835 23.1781 16.1835 23.0685 16.1835L20.8219 16.1284Z"
                    fill="#0AC9BB"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M4.43836 16.1284C4.16438 16.1284 3.94521 16.3486 3.94521 16.6239V19.5963C3.94521 19.8716 4.16438 20.0917 4.43836 20.0917H7.39726C7.67123 20.0917 7.89041 19.8716 7.89041 19.5963V16.6239C7.89041 16.3486 7.67123 16.1284 7.39726 16.1284H4.43836Z"
                    fill="#0AC9BB"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M0.986301 16.1284C0.821918 16.1284 0.657534 16.2385 0.60274 16.4037C0.60274 16.4587 0.60274 16.5688 0.657534 16.6239L1.26027 17.8899C1.31507 18.055 1.53425 18.1651 1.75342 18.1101C1.80822 18.1101 1.86301 18.055 1.91781 18L3.39726 16.5138C3.61644 16.2936 3.50685 16.1284 3.23288 16.1284H0.986301Z"
                    fill="#0AC9BB"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M16.6575 3.85321C16.3836 3.85321 16.1644 4.07339 16.1644 4.34862V7.3211C16.1644 7.59633 16.3836 7.81651 16.6575 7.81651H19.6164C19.8904 7.81651 20.1096 7.59633 20.1096 7.3211V4.40367C20.1096 4.12844 19.8904 3.90826 19.6164 3.90826C19.6164 3.85321 16.6575 3.85321 16.6575 3.85321Z"
                    fill="#0AC9BB"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M3.45205 8.36697L0.164384 11.6697C-0.0547945 11.8899 -0.0547945 12.2202 0.164384 12.3853L3.45205 15.6881C3.67123 15.9083 4 15.9083 4.16438 15.6881L7.45205 12.3853C7.67123 12.1651 7.67123 11.8349 7.45205 11.6697L4.16438 8.36697C4.05479 8.25688 3.94521 8.20183 3.83562 8.20183C3.67123 8.20183 3.56164 8.25688 3.45205 8.36697Z"
                    fill="#0AC9BB"
                    fillRule="evenodd"/>
            </g>
        </SvgIcon>);
}