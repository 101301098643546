import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsEcsServiceLaunchTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsEcsServiceLaunchTypeTranslator",
            () => ({
                [Contract.TypeNames.AwsEcsServiceLaunchType]: {
                    [Contract.AwsEcsServiceLaunchType.Ec2]: "EC2",
                    [Contract.AwsEcsServiceLaunchType.External]: "External",
                    [Contract.AwsEcsServiceLaunchType.Fargate]: "Fargate"
                }
            }));
    return (launchType: Contract.AwsEcsServiceLaunchType) =>
        localization[Contract.TypeNames.AwsEcsServiceLaunchType][launchType]();
}