import { InlineCopyToClipboardText, Step, Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useState } from "react";
import { useAddOrEditContext } from "../../..";
import { Contract, TenantRoleItemAccordion } from "../../../../../../../../../../../../../../../../../common";

export function Manual() {
    const dataAnalysisCustomRoleId = "tenable_data_scanning";
    const dataAnalysisCustomRoleName = "Tenable Data Scanning";
    const workloadAnalysisCustomRoleName = "Tenable Workload Scanning";

    const { gciTenantModel, organizationOnboardingInfo, permissionTypes } = useAddOrEditContext();
    const [permissionState, setPermissionState] = useState<boolean>(false);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.gcp.addOrEdit.rolesItem.manual",
            () => ({
                dataAnalysisCustomRole: {
                    steps: {
                        step1: "Navigate to **IAM & Admin -> Roles**.",
                        step2: "Click on **Create** role.",
                        step3: "Change the title to {{dataAnalysisCustomRoleName}}.",
                        step4: "Change the ID to {{dataAnalysisCustomRoleId}}.",
                        step5: "Click **+ ADD PERMISSIONS**.",
                        step6: "In the Filter (by permissions) field, search and check the box next to permission name for the following permissions:",
                        step7: "Click **ADD**, and then click **CREATE**."
                    },
                    text: "To create a custom role for Data Analysis, follow these steps:"
                },
                steps: {
                    step1: "Log into your **Google Cloud Console** and select the **organization** you want to add using the resource selector at the top left of the screen.",
                    step2: "Navigate to **IAM & Admin -> IAM**.",
                    step3: "Click **+ Grant Access.**",
                    step4: "Copy the following value and paste it in the **New principals** field: {{serviceAccountMail}}",
                    step5: "Copy the following roles and paste them into the **Role** field, one by one. Click **+ADD ANOTHER ROLE** for each additional role.",
                    step6: "Click **Save**"
                },
                workloadAnalysisCustomRole: {
                    steps: {
                        step1: "Navigate to **IAM & Admin -> Roles**.",
                        step2: "In the filter field, search for and select {{backupComputeEngineOperatorRole}}.",
                        step3: "Click the three dots next to the role name and then **CREATE ROLE FROM THIS ROLE**.",
                        step4: "Change the title to {{workloadAnalysisCustomRoleName}}.",
                        step5: "Click **+ ADD PERMISSIONS**.",
                        step6: "In the Filter field, search for and select: {{computeSnapshotsSetIamPolicyActionName}}.",
                        step7: "Check the box next to the permission name, and click **ADD**.",
                        step8: "Under assigned permissions, search for the following permission: {{iamServiceAccountsActAsActionName}}.",
                        step9: "Clear the checkbox to **remove** the permission, and then click **CREATE**."
                    },
                    text: "To create a custom role for Workload Protection, follow these steps:"
                }
            }));

    return (
        <Steps variant="letters">
            {_<any>([]).
                concat(localization.steps.step1()).
                concatIf(
                    _.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.DataAnalysis),
                    new Step(
                        localization.dataAnalysisCustomRole.text(),
                        {
                            contentElement:
                                <Steps variant="plainNumbers">
                                    {localization.dataAnalysisCustomRole.steps.step1()}
                                    {localization.dataAnalysisCustomRole.steps.step2()}
                                    {localization.dataAnalysisCustomRole.steps.step3({
                                        dataAnalysisCustomRoleName:
                                            <InlineCopyToClipboardText text={dataAnalysisCustomRoleName}/>
                                    })}
                                    {localization.dataAnalysisCustomRole.steps.step4({
                                        dataAnalysisCustomRoleId:
                                            <InlineCopyToClipboardText text={dataAnalysisCustomRoleId}/>
                                    })}
                                    {localization.dataAnalysisCustomRole.steps.step5()}
                                    {new Step(
                                        localization.dataAnalysisCustomRole.steps.step6(),
                                        {
                                            contentElement:
                                                <TenantRoleItemAccordion
                                                    expanded={permissionState}
                                                    rawText={organizationOnboardingInfo.serviceAccountDataAnalysisPermissionActions.join("\n")}
                                                    title="Tenable Data Scanning Permissions"
                                                    tooltip="Tenable Data Scanning Permissions"
                                                    onChange={ expanded => setPermissionState(expanded)}/>
                                        })}
                                    {localization.dataAnalysisCustomRole.steps.step7()}
                                </Steps>
                        })).
                concatIf(
                    _.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.WorkloadAnalysis),
                    new Step(
                        localization.workloadAnalysisCustomRole.text(),
                        {
                            contentElement:
                                <Steps variant="plainNumbers">
                                    {localization.workloadAnalysisCustomRole.steps.step1()}
                                    {localization.workloadAnalysisCustomRole.steps.step2({
                                        backupComputeEngineOperatorRole:
                                            <InlineCopyToClipboardText text="Backup and DR Compute Engine Operator"/>
                                    })}
                                    {localization.workloadAnalysisCustomRole.steps.step3()}
                                    {localization.workloadAnalysisCustomRole.steps.step4({
                                        workloadAnalysisCustomRoleName:
                                            <InlineCopyToClipboardText text={workloadAnalysisCustomRoleName}/>
                                    })}
                                    {localization.workloadAnalysisCustomRole.steps.step5()}
                                    {localization.workloadAnalysisCustomRole.steps.step6({
                                        computeSnapshotsSetIamPolicyActionName:
                                            <InlineCopyToClipboardText text="compute.snapshots.setIamPolicy"/>
                                    })}
                                    {localization.workloadAnalysisCustomRole.steps.step7()}
                                    {localization.workloadAnalysisCustomRole.steps.step8({
                                        iamServiceAccountsActAsActionName:
                                            <InlineCopyToClipboardText text="iam.serviceAccounts.actAs"/>
                                    })}
                                    {localization.workloadAnalysisCustomRole.steps.step9()}
                                </Steps>
                        })).
                concat(
                    localization.steps.step2(),
                    localization.steps.step3(),
                    localization.steps.step4({
                        serviceAccountMail:
                            <InlineCopyToClipboardText text={gciTenantModel!.configuration.serviceAccountMail}/>
                    }),
                    new Step(
                        localization.steps.step5(),
                        {
                            contentElement:
                                <Steps variant="plainNumbers">
                                    {_<string>(_.values(organizationOnboardingInfo.serviceAccountOrganizationReadOnlyRoleRawIdToNameMap)).
                                        concat(
                                            _.values(organizationOnboardingInfo.serviceAccountReadOnlyRoleRawIdToNameMap)).
                                        concatIf(
                                            _.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.DataAnalysis),
                                            dataAnalysisCustomRoleName).
                                        concatIf(
                                            _.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.WorkloadAnalysis),
                                            workloadAnalysisCustomRoleName).
                                        concatIf(
                                            _.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.PermissionManagement),
                                            _.values(organizationOnboardingInfo.serviceAccountPermissionManagementRoleRawIdToNameMap)).
                                        concatIf(
                                            _.includes(permissionTypes, Contract.CloudProviderTenantPermissionType.Write),
                                            _.values(organizationOnboardingInfo.serviceAccountManagementRoleRawIdToNameMap)).
                                        uniq().
                                        orderBy().
                                        map(
                                            roleName =>
                                                <InlineCopyToClipboardText
                                                    key={roleName}
                                                    text={roleName}/>).
                                        value()}
                                </Steps>
                        }),
                    localization.steps.step6()).
                value()}
        </Steps>);
}