﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetCommonKubernetesClusterRiskContext } from "../../../..";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../../utilities";

export function useKubernetesClusterKubeletReadOnlyPortEnabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.KubernetesClusterKubeletReadOnlyPortEnabledRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId);
    const getCommonKubernetesClusterRiskContext = useGetCommonKubernetesClusterRiskContext(clusterModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.kubernetes.hooks.clusters.kubelets.useKubernetesClusterKubeletReadOnlyPortEnabledRiskDefinition",
            () => ({
                contextItems: {
                    inboundExternalReadOnlyPortNodes: "The cluster has {{nodes}} in which the Kubelet server read-only port is open to the internet",
                    readOnlyPortNodes: "The cluster has {{nodes}} in which the read-only port is enabled"
                },
                description: "{{cluster}} Kubelet read-only port is enabled",
                resolutionSection: {
                    step1: "For the following {{nodes}}, ensure that the --read-only-port is disabled"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{
                        includeServiceName: true,
                        variant: "title"
                    }}
                    variant="typeText"/>
        }),
        () => [
            localization.resolutionSection.step1({
                nodes:
                    <InlineEntities
                        entityIdsOrModels={risk.nodeOrVirtualMachineIds}
                        entityTypeName={Contract.TypeNames.IKubernetesNode}
                        variant="itemCountAndType"/>
            })
        ],
        riskModel,
        () => {
            const clusterRiskContext = getCommonKubernetesClusterRiskContext(clusterModel);
            const inboundExternalReadOnlyPortNodeOrVirtualMachineIds =
                _(risk.nodeDatas).
                    filter(nodeData => !_.isNil(nodeData.inboundExternalReadOnlyPort)).
                    map(nodeData => nodeData.nodeOrVirtualMachineId).
                    value();

            return [
                clusterRiskContext.generalInformation,
                clusterRiskContext.virtualNetwork,
                new RiskDefinitionContextItem(
                    localization.contextItems.readOnlyPortNodes({
                        nodes:
                            <InlineEntities
                                entityIdsOrModels={risk.nodeOrVirtualMachineIds}
                                entityTypeName={Contract.TypeNames.IKubernetesNode}
                                variant="itemCountAndType"/>
                    })),
                _.isEmpty(inboundExternalReadOnlyPortNodeOrVirtualMachineIds)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.inboundExternalReadOnlyPortNodes({
                            nodes:
                                <InlineEntities
                                    entityIdsOrModels={inboundExternalReadOnlyPortNodeOrVirtualMachineIds}
                                    entityTypeName={Contract.TypeNames.IKubernetesNode}
                                    variant="itemCountAndType"/>
                        })),
                clusterRiskContext.sensitive,
                clusterRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}