import { NoneIcon, useFormatRelativeTime } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { UdmObjectPropertyItemProps } from "../..";

export function InfraDateTime({ item }: UdmObjectPropertyItemProps) {
    const formatRelativeTime = useFormatRelativeTime();
    return _.isEmpty(item)
        ? <NoneIcon sx={{ fontSize: "18px" }}/>
        : <Typography
            noWrap={true}
            sx={{ width: "max-content" }}>
            {formatRelativeTime(item)}
        </Typography>;
}