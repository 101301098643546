import { UnexpectedError } from "@infrastructure";
import { Dictionary } from "lodash";
import { useMemo } from "react";
import { Contract, RiskPoliciesType, RiskPoliciesView } from "../..";
import { useCloudRiskPolicyConfigurationControllerDefinition, useCodeRiskPolicyConfigurationControllerDefinition, useKubernetesAdmissionControllerRiskPolicyConfigurationControllerDefinition } from "./hooks";

export function useRiskPolicyConfigurationControllerDefinition(type: RiskPoliciesType, riskPolicyConfigurationTypeNameOrId: string, scopeId: string, view = RiskPoliciesView.BuiltIn): RiskPolicyConfigurationControllerDefinition {
    const useDefinition =
        useMemo(
            () => {
                switch (type) {
                    case RiskPoliciesType.Cloud:
                        return useCloudRiskPolicyConfigurationControllerDefinition;
                    case RiskPoliciesType.Code:
                        return useCodeRiskPolicyConfigurationControllerDefinition;
                    case RiskPoliciesType.KubernetesAdmissionController:
                        return useKubernetesAdmissionControllerRiskPolicyConfigurationControllerDefinition;
                    default:
                        throw new UnexpectedError("useRiskPolicyConfigurationControllerDefinition.type", type);
                }
            },
            [type]);

    return useDefinition(view, riskPolicyConfigurationTypeNameOrId, scopeId);
}

export interface RiskPolicyConfigurationControllerDefinition {
    deleteCustomRiskPolicyConfiguration?: () => Promise<void>;
    updateRiskPolicyConfiguration: (props: UpdateProps) => Promise<void>;
}

type UpdateProps = {
    codeExclusions?: Contract.RiskPolicyConfigurationCodeExclusion[];
    entityTypeNameToExclusionsMap?: Dictionary<Contract.RiskPolicyConfigurationEntityExclusion>;
    riskPoliciesIds: string[];
    riskPolicyConfiguration: Contract.RiskPolicyConfiguration;
    riskPolicyIdToSystemUpdateTimeMap: Dictionary<string>;
};