﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../../..";
import { Contract } from "../../../../../../../../../../../common";
import { Info } from "../../../../../components";
import { AzureResourceRoleAssignmentResources } from "../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { useAzureDefaultKeyVaultVaultObjectInfoItemElements } from "../useAzureDefaultKeyVaultVaultObjectInfoItemElements";
import { useAzureDefaultResourceInfoItemElements } from "../useAzureDefaultResourceInfoItemElements";
import { Versions } from "./components";

export function useAzureKeyVaultVaultSecretDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const secretModel = resourceGroupResourceModel as Contract.AzureKeyVaultVaultSecretModel;
    const defaultKeyVaultVaultObjectInfoItemElements = useAzureDefaultKeyVaultVaultObjectInfoItemElements(secretModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureKeyVaultVaultSecretDefinition",
            () => ({
                tabs: {
                    resourceRoleAssignments: "Resource Role Assignments",
                    versions: "Versions ({{versionCount | NumberFormatter.humanize}})"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <Versions secretModel={secretModel}/>,
                localization.tabs.versions({ versionCount: _.size((secretModel.entity as Contract.AzureKeyVaultVaultSecret).versionNameToVersionMap) }),
                "versions"),
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AzureResourceRoleAssignmentResources
                    csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                    scopeResourceModel={resourceGroupResourceModel}/>,
                localization.tabs.resourceRoleAssignments(),
                "resourceRoleAssignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={defaultKeyVaultVaultObjectInfoItemElements}
                options={options?.infoOptions}>
            </Info>
    });
}