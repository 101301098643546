import { DataTableHighlightedItemOptions, DataTableRowOptions, StorageItem, UnexpectedError } from "@infrastructure";
import { Dictionary } from "lodash";
import { ReactNode, useMemo } from "react";
import { ColumnIdToItemValuesMap, GetItemValueDefinition, RiskPoliciesType, RiskPoliciesView, RiskPolicyItem } from "../../../../../../common";
import { useCloudRiskPoliciesDefinition, useCodeRiskPoliciesDefinition, useKubernetesAdmissionControllerRiskPoliciesDefinition } from "./hooks";

export function useDefinition(type: RiskPoliciesType, scopeId: string, view = RiskPoliciesView.BuiltIn): PolicyTableDefinition {
    const useDefinition =
        useMemo(
            () => {
                switch (type) {
                    case RiskPoliciesType.Cloud:
                        return useCloudRiskPoliciesDefinition;
                    case RiskPoliciesType.Code:
                        return useCodeRiskPoliciesDefinition;
                    case RiskPoliciesType.KubernetesAdmissionController:
                        return useKubernetesAdmissionControllerRiskPoliciesDefinition;
                    default:
                        throw new UnexpectedError("useDefinition.type", type);
                }
            },
            [type, scopeId, view]);

    return useDefinition(scopeId, view);
}

type PolicyTableElement = {
    [columnId: string]: GetItemValueDefinition<RiskPolicyItem>;
};

export class PolicyTableDefinition {
    constructor(
        public additionalColumnElements: (columnIdToItemValuesMap: ColumnIdToItemValuesMap<PolicyTableElement>) => ReactNode[],
        public columnIdToGetItemValueMap: PolicyTableElement,
        public getItemId: (item: RiskPolicyItem) => string,
        public highlightItemHashRouteTemplate: DataTableHighlightedItemOptions,
        public storage: PolicyTableDefinitionStorage,
        public addOrEdit?: ReactNode,
        public csvOptions?: CsvOptions,
        public dataTableActions?: ReactNode[],
        public options?: PolicyTableDefinitionOptions) {
    }
}

type PolicyTableDefinitionOptions = {
    defaultSortColumnIds?: string[];
    initialFilterMap?: Dictionary<any>;
    rowOptions?: DataTableRowOptions;
};

type PolicyTableDefinitionStorage = {
    columnOrder: StorageItem;
    columnSelector: StorageItem;
    flatView?: StorageItem;
    tableFilters: StorageItem;
};

type CsvOptions = {
    csvExportFilePrefixes: string[];
    getCsvItem: (item: RiskPolicyItem) => any;
    getCsvItemPage?: never;
    prefix?: never;
} | {
    csvExportFilePrefixes?: never;
    getCsvItem?: never;
    getCsvItemPage: (items: RiskPolicyItem[]) => () => Promise<unknown[]>;
    prefix: string;
};