import { Action0, ActionMenuItem, ContentMenuItem, DialogMenuItem, Loading, map, MenuItem, Message, SeparatorMenuItem, StaticMenuItem, SubMenuItem, Sx, useAsyncEffect, useLocalization } from "@infrastructure";
import { Box, Divider, Menu as MuiMenu, PopoverOrigin, Stack, SxProps, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { Fragment, MutableRefObject, useState } from "react";
import { ActionMenu, ContentMenu, DialogMenu, SubMenu } from ".";

type PopupProps = {
    elementRef: MutableRefObject<any | null>;
    itemsOrGetItems: MenuItem[] | (() => Promise<MenuItem[]>);
    itemSx?: SxProps;
    onClose: Action0;
    open: boolean;
    sx?: SxProps;
    titleOptions?: PopupTitleOptions;
    variant?: "bottomCenter" | "bottomLeft" | "bottomRight" | "topCenter" | "topRight";
};

export type PopupTitleOptions = {
    subtitle?: string;
    titleOrGetTitle?: string | ((items: MenuItem[]) => string);
    underline?: boolean;
};

export function Popup({ elementRef, itemsOrGetItems, itemSx, onClose, open, sx, titleOptions, variant = "topRight" }: PopupProps) {
    const localization =
        useLocalization(
            "infrastructure.menu.popup",
            () => ({
                actions: {
                    getItems: {
                        error: "Failed to get items"
                    }
                }
            }));

    const [getItemsExecuting, setGetItemsExecuting] = useState(false);
    const [getItemsError, setGetItemsError] = useState(false);
    const [items, setItems] = useState<MenuItem[]>();
    const [title, setTitle] = useState<string>();
    const [menuHidden, setMenuHidden] = useState(false);

    useAsyncEffect(
        async () => {
            if (open) {
                setGetItemsExecuting(true);
                setGetItemsError(false);
                setTitle(undefined);
                setMenuHidden(false);
                try {
                    const items =
                        _.isFunction(itemsOrGetItems)
                            ? await itemsOrGetItems()
                            : itemsOrGetItems;

                    setItems(items);
                    setGetItemsExecuting(false);
                    if (!_.isNil(titleOptions)) {
                        setTitle(
                            _.isFunction(titleOptions.titleOrGetTitle)
                                ? titleOptions.titleOrGetTitle(items)
                                : titleOptions.titleOrGetTitle);
                    }
                } catch {
                    setGetItemsError(true);
                }
            }
        },
        [open]);

    const theme = useTheme();
    return (
        <MuiMenu
            anchorEl={elementRef.current}
            MenuListProps={{ sx: Sx.combine(sx, { minWidth: theme.spacing(13) }) }}
            open={
                open && (
                    !_.isEmpty(items) ||
                    !_.isNil(titleOptions))}
            {...map<string, { anchorOrigin: PopoverOrigin; transformOrigin: PopoverOrigin }>(
                variant,
                {
                    "bottomCenter":
                        () =>
                            ({
                                anchorOrigin: {
                                    horizontal: "center",
                                    vertical: "bottom"
                                },
                                transformOrigin: {
                                    horizontal: "center",
                                    vertical: "top"
                                }
                            }),
                    "bottomLeft":
                        () =>
                            ({
                                anchorOrigin: {
                                    horizontal: "left",
                                    vertical: "bottom"
                                },
                                transformOrigin: {
                                    horizontal: "left",
                                    vertical: "top"
                                }
                            }),
                    "bottomRight":
                        () =>
                            ({
                                anchorOrigin: {
                                    horizontal: "right",
                                    vertical: "bottom"
                                },
                                transformOrigin: {
                                    horizontal: "right",
                                    vertical: "top"
                                }
                            }),
                    "topCenter":
                        () =>
                            ({
                                anchorOrigin: {
                                    horizontal: "center",
                                    vertical: "top"
                                },
                                transformOrigin: {
                                    horizontal: "center",
                                    vertical: "bottom"
                                }
                            }),
                    "topRight":
                        () =>
                            ({
                                anchorOrigin: {
                                    horizontal: "left",
                                    vertical: "top"
                                },
                                transformOrigin: {
                                    horizontal: "right",
                                    vertical: "top"
                                }
                            })
                })}
            sx={{
                visibility:
                    menuHidden
                        ? "hidden"
                        : "visible"
            }}
            onClick={event => event.stopPropagation()}
            onClose={() => onClose()}>
            <Loading
                container="popup"
                loading={getItemsExecuting}>
                {!_.isNil(titleOptions) && (
                    <Stack>
                        <Typography
                            sx={{ padding: theme.spacing(1, 2) }}
                            variant="h5">
                            {title}
                        </Typography>
                        {!_.isNil(titleOptions?.subtitle) && (
                            <Typography
                                sx={{
                                    fontWeight: 300,
                                    padding: theme.spacing(0, 2, 1, 2)
                                }}>
                                {titleOptions?.subtitle}
                            </Typography>)}
                        {titleOptions.underline && (
                            <Divider
                                flexItem={true}
                                variant="middle"/>)}
                    </Stack>)}
                <Stack
                    sx={{
                        maxHeight: theme.spacing(60),
                        overflow: "auto"
                    }}>
                    {getItemsError
                        ? <Message
                            level="error"
                            title={localization.actions.getItems.error()}/>
                        : _.map(
                            items,
                            (item, itemIndex) => {
                                if (item instanceof ActionMenuItem) {
                                    return (
                                        <ActionMenu
                                            item={item}
                                            key={itemIndex}
                                            sx={itemSx}
                                            onClose={onClose}
                                            onConfirmDialogOpen={() => setMenuHidden(true)}/>);
                                } else if (item instanceof ContentMenuItem) {
                                    return (
                                        <ContentMenu
                                            item={item}
                                            key={itemIndex}
                                            sx={itemSx}
                                            onClose={onClose}/>);
                                } else if (item instanceof DialogMenuItem) {
                                    return (
                                        <DialogMenu
                                            item={item}
                                            key={itemIndex}
                                            sx={itemSx}
                                            onClose={onClose}/>);
                                } else if (item instanceof SubMenuItem) {
                                    return (
                                        <SubMenu
                                            item={item}
                                            key={itemIndex}
                                            onClose={onClose}/>);
                                } else if (item instanceof SeparatorMenuItem) {
                                    return (
                                        <Divider
                                            component="li"
                                            flexItem={true}
                                            key={itemIndex}
                                            style={{
                                                marginBottom: 0,
                                                marginTop: 0
                                            }}/>);
                                } else if (item instanceof StaticMenuItem) {
                                    return (
                                        <Box
                                            key={itemIndex}
                                            sx={{
                                                overflow: "hidden",
                                                padding: theme.spacing(1, 1.5)
                                            }}>
                                            {item.element}
                                        </Box>);
                                } else {
                                    return <Fragment key={itemIndex}/>;
                                }
                            })}
                </Stack>
            </Loading>
        </MuiMenu>);
}