import _ from "lodash";
import { Contract, TypeMetadataHelper } from "../../../common";

export class AwsNetworkResourceHelper {
    public static getVpcIds =
        (entity: Contract.Entity) => {
            const typeNameMetadata = TypeMetadataHelper.getTypeMetadata(entity.typeName);
            return _.includes(typeNameMetadata.implementTypeNames, Contract.TypeNames.IAwsNetworkedResource)
                ? (entity as Contract.Entity & Contract.IAwsNetworkedResource).vpcIds ?? []
                : _.includes(typeNameMetadata.extendTypeNames, Contract.TypeNames.AwsEc2Resource) &&
                !_.isNil((entity as AwsNetworkResourceHelperEc2Resource).vpcId)
                    ? [(entity as AwsNetworkResourceHelperEc2Resource).vpcId!]
                    : [];
        };
}

type AwsNetworkResourceHelperEc2Resource =
    Contract.Entity & {
        vpcId?: string;
    };