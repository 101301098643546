import { Dialog, makeContextProvider } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { AddOrEdit, AddOrEditProject, List } from "./components";

export class JiraContext {
    public addOrEditType?: JiraAddOrEditType;
    public projectConfiguration?: Contract.JiraInstanceConfigurationProject;

    constructor(public instanceModel?: Contract.ScopeSystemEntityModel) {
    }
}

export const [useJiraContext, useSetJiraContext, useJiraContextProvider] = makeContextProvider<JiraContext>();

export function Jira() {
    const [context, setContext, ContextProvider] = useJiraContextProvider(() => new JiraContext());

    return (
        <ContextProvider>
            {context.addOrEditType === JiraAddOrEditType.AddOrEditInstance &&
                <Dialog
                    size="large"
                    variant="editor"
                    onClose={
                        () =>
                            setContext(
                                context => ({
                                    ...context,
                                    addOrEditType: undefined,
                                    instanceModel: undefined
                                })
                            )}>
                    <AddOrEdit/>
                </Dialog>}
            {context.addOrEditType === JiraAddOrEditType.AddOrEditProject &&
                <Dialog
                    size="large"
                    variant="editor"
                    onClose={
                        () =>
                            setContext(
                                context => ({
                                    ...context,
                                    addOrEditType: undefined,
                                    instanceModel: undefined
                                })
                            )}>
                    <AddOrEditProject/>
                </Dialog>}
            <List/>
        </ContextProvider>);
}

export enum JiraAddOrEditType {
    AddOrEditInstance = "addOrEditInstance",
    AddOrEditProject = "addOrEditProject"
}