import { ContentCard, Link, useLocalization } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { CustomerConsoleAppUrlHelper, useLayoutOptions, UserHelper, useTheme } from "../../../common";

export function Welcome() {
    const localization =
        useLocalization(
            "views.user.welcome",
            () => ({
                contact: "For more information, contact the Tenable Cloud Security team.",
                description: "Self-Service JIT access lets you securely grant users access to your cloud accounts on an as-needed basis.\nDefine who can request access to specific accounts, which permissions they can use, how long they have access for, and how requests are approved.\nAll JIT-related activities are logged, allowing you to keep track of who did what.",
                notSecurityAdministrator: {
                    contactSecurityAdministrator: "To get started, contact your Tenable Cloud Security administrator."
                },
                securityAdministrator: {
                    docs: {
                        links: {
                            documentation: "documentation"
                        },
                        text: "To read more about this feature, see our {{documentationLink}}."
                    },
                    steps: {
                        step1: {
                            links: {
                                tenantOnboarding: "configured your organization in Tenable Cloud Security"
                            },
                            text: "Make sure you {{tenantOnboardingLink}}"
                        },
                        step2: {
                            links: {
                                grantingPermissionManagementPermissions: "JIT management permissions"
                            },
                            text: "Grant Tenable Cloud Security {{GrantingPermissionManagementPermissionsLink}}"
                        },
                        step3: "Start using it!",
                        title: "To get started, take the following 3 small steps"
                    }
                },
                title: "Welcome to Self-Service JIT Access!"
            }));

    useLayoutOptions({ contained: false });

    const theme = useTheme();
    return (
        <ContentCard>
            <Stack sx={{ padding: theme.spacing(4) }}>
                <Typography
                    noWrap={true}
                    sx={{
                        fontWeight: 700,
                        marginBottom: theme.spacing(3)
                    }}
                    variant="h1">
                    {localization.title()}
                </Typography>
                <Stack>
                    <Typography
                        noWrap={true}
                        sx={{
                            fontSize: "14px",
                            whiteSpace: "pre-wrap"
                        }}>
                        {localization.description()}
                    </Typography>
                    <Typography
                        noWrap={true}
                        sx={{
                            fontSize: "14px",
                            paddingTop:
                                UserHelper.securityAdministrationWritePermissionExists
                                    ? undefined
                                    : theme.spacing(2)
                        }}>
                        {UserHelper.securityAdministrationWritePermissionExists
                            ? localization.securityAdministrator.docs.text({
                                documentationLink:
                                    <Link
                                        urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsJitAccessRelativeUrl()}
                                        variant="external">
                                        {localization.securityAdministrator.docs.links.documentation()}
                                    </Link>
                            })
                            : localization.notSecurityAdministrator.contactSecurityAdministrator()}
                    </Typography>
                </Stack>
                {UserHelper.securityAdministrationWritePermissionExists &&
                    <Box
                        sx={{
                            paddingTop: theme.spacing(5),
                            position: "relative"
                        }}>
                        <Stack spacing={5}>
                            <Typography variant="h4">
                                {localization.securityAdministrator.steps.title()}
                            </Typography>
                            <Step index={1}>
                                <Typography>
                                    {localization.securityAdministrator.steps.step1.text({
                                        tenantOnboardingLink:
                                            <Link
                                                sx={{
                                                    color: theme.palette.text.primary,
                                                    fontWeight: 600,
                                                    textDecoration: "underline"
                                                }}
                                                urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsCloudOnboardingRelativeUrl()}
                                                variant="external">
                                                {localization.securityAdministrator.steps.step1.links.tenantOnboarding()}
                                            </Link>
                                    })}
                                </Typography>
                            </Step>
                            <Step index={2}>
                                <Typography>
                                    {localization.securityAdministrator.steps.step2.text({
                                        GrantingPermissionManagementPermissionsLink:
                                            <Link
                                                sx={{
                                                    color: theme.palette.text.primary,
                                                    fontWeight: 600,
                                                    textDecoration: "underline"
                                                }}
                                                urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsGrantTenableJitPermissionsRelativeUrl()}
                                                variant="external">
                                                {localization.securityAdministrator.steps.step2.links.grantingPermissionManagementPermissions()}
                                            </Link>
                                    })}
                                </Typography>
                            </Step>
                            <Step index={3}>
                                <Typography>
                                    {localization.securityAdministrator.steps.step3()}
                                </Typography>
                            </Step>
                        </Stack>
                        <svg
                            height={123}
                            style={{
                                left: 16,
                                position: "absolute",
                                top: 135,
                                zIndex: -1
                            }}
                            viewBox="0 0 2 123"
                            width={2}>
                            <line
                                style={{
                                    stroke: theme.palette.text.primary,
                                    strokeDasharray: "5 4",
                                    strokeWidth: 1
                                }}
                                x1="1"
                                x2="1"
                                y1="0"
                                y2="123"/>
                        </svg>
                    </Box>}
            </Stack>
        </ContentCard>);
}

type StepProps = {
    children: ReactNode;
    index: number;
};

function Step({ children, index }: StepProps) {
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row">
            <svg
                height={theme.spacing(4.25)}
                viewBox={`0 0 ${theme.spacing(4.25)} ${theme.spacing(4.25)}`}
                width={theme.spacing(4.25)}>
                <circle
                    color={theme.palette.text.primary}
                    cx="50%"
                    cy="50%"
                    fill={theme.palette.background.paper}
                    r="16px"
                    style={{
                        stroke: theme.palette.text.primary,
                        strokeDasharray: "5 4",
                        strokeWidth: 1
                    }}>
                </circle>
                <text
                    dominantBaseline="central"
                    fill={theme.palette.text.primary}
                    fontSize="14px"
                    textAnchor="middle"
                    x="50%"
                    y="50%">
                    {index}
                </text>
            </svg>
            <Box sx={{ marginLeft: theme.spacing(2.5) }}>
                {children}
            </Box>
        </Stack>);
}