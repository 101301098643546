import _, { Function0 } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useLocalization } from "@infrastructure";
import { CodeDashboardContext, Contract, StorageHelper, useDashboardContext, WidgetDefinition } from "../../../../../../..";
import { Risks, RisksWidgetSeverityMultiSelect } from "../components";

export function useGetCodeRisksDefinition(): () => WidgetDefinition {
    const { summary } = useDashboardContext<CodeDashboardContext>();

    const riskSeverityToCountMap =
        useMemo(
            () =>
                _.mapValues(
                    summary.severityToRiskPolicyCategoryToCountMap,
                    riskPolicyCategoryToCountMap =>
                        _(riskPolicyCategoryToCountMap).
                            values().
                            sum()),
            [summary.severityToRiskPolicyCategoryToCountMap]);

    const [selectedSeverities, setSelectedSeverities] =
        useState(
            () => {
                const riskSeveritiesJson = StorageHelper.customerCodeDashboardRisksWidgetSeverities.getValue();
                return riskSeveritiesJson == undefined
                    ? riskSeverityToCountMap[Contract.Severity.Critical] > 0
                        ? [
                            Contract.Severity.Critical,
                            Contract.Severity.High
                        ]
                        : []
                    : JSON.parse(riskSeveritiesJson) as Contract.Severity[];
            });

    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetCodeRisksDefinition",
            () => ({
                helpText: "See a high-level overview of all your open findings. Findings represent at-risk identities and resources. View all findings that are currently open, the number of different finding types (categorized by the policy that triggered them), and a breakdown of findings/types by category.",
                title: "Open Findings"
            }));

    return useCallback<Function0<WidgetDefinition>>(
        () => ({
            element:
                <Risks
                    selectedSeverities={selectedSeverities}
                    severityToRiskPolicyCategoryToCountMap={summary.severityToRiskPolicyCategoryToCountMap}/>,
            options: {
                helpText: localization.helpText(),
                title: localization.title(),
                topElement:
                    <RisksWidgetSeverityMultiSelect
                        selectedSeverities={selectedSeverities}
                        setSelectedSeverities={setSelectedSeverities}
                        storageItem={StorageHelper.customerCodeDashboardRisksWidgetSeverities}/>
            }
        }),
        [localization, selectedSeverities]);
}