import { Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, CustomerConsoleAppUrlHelper, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../components";
import { useGetAzureContainerServiceManagedClusterRiskContext } from "../contexts";

export function useAzureContainerServiceManagedClusterInboundExternalWideRangeRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureContainerServiceManagedClusterInboundExternalWideRangeRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId) as Contract.AzureContainerServiceManagedClusterModel;
    const cluster = clusterModel.entity as Contract.AzureContainerServiceManagedCluster;

    const getAzureContainerServiceManagedClusterRiskContext = useGetAzureContainerServiceManagedClusterRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureContainerServiceManagedClusterInboundExternalWideRangeRiskDefinition",
            () => ({
                description: "{{cluster}} authorized networks configuration allows wide inbound internet access to the Kubernetes API",
                sections: {
                    authorizedNetworks: "Authorized Networks",
                    resolution: {
                        step1: "Check the **Set authorized IP ranges** checkbox",
                        step2: {
                            link: "Tenable Cloud Security's IP addresses",
                            text: "Edit the IP Address Ranges to allow access only to the specific IP addresses that require access to the Kubernetes API, including {{documentationApplicationIpAddressesLink}}"
                        },
                        step3: "Click **Apply** to confirm"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{ includeServiceName: true }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.Networking}/>,
            _.isNil(cluster.network.authorizedSubnets)
                ? localization.sections.resolution.step1()
                : undefined,
            localization.sections.resolution.step2.text({
                documentationApplicationIpAddressesLink:
                    <Link
                        urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsArchitectureAndDataHandlingApplicationIpAddressesRelativeUrl()}
                        variant="external">
                        {localization.sections.resolution.step2.link()}
                    </Link>
            }),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const clusterRiskContext = getAzureContainerServiceManagedClusterRiskContext(clusterModel);
            return [
                clusterRiskContext.generalInformation,
                clusterRiskContext.sensitive,
                clusterRiskContext.virtualNetwork,
                clusterRiskContext.wideRangeInboundSubnets,
                clusterRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}