import React, { Fragment } from "react";
import { AuditEventsIcon, PolicyDocumentIcon } from "../../../../../../../../../common";
import { ProfileCategory } from "../Profile";

type CategoryViewIconProps = {
    category: ProfileCategory;
};

export function CategoryIcon({ category }: CategoryViewIconProps) {
    switch (category) {
        case ProfileCategory.Overview:
            return <AuditEventsIcon/>;
        case ProfileCategory.RequestJson:
            return <PolicyDocumentIcon/>;
        default:
            return <Fragment/>;
    }
}