import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { useTheme } from "../../themes";

export function ActivePermissionRequestIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon {...props}>
            <path d="M0.000425775 11.92C0.0537573 5.36002 5.41357 0 12 0C18.6931 0.0533328 24.0529 5.46665 23.9996 12.08C23.9463 18.64 18.5865 24 12 24H11.92C8.72013 23.9733 5.7069 22.72 3.46697 20.4267C1.20038 18.16 -0.02624 15.12 0.000425775 11.92Z"/>
            <path
                d="M9.57592 15.1038L6.95063 12.3453C6.64783 12.0271 6.14038 12.0271 5.83757 12.3453C5.55523 12.642 5.55523 13.108 5.83757 13.4046L8.85154 16.5715C9.24567 16.9857 9.90617 16.9857 10.3003 16.5715L18.1628 8.3101C18.4451 8.01343 18.4451 7.54745 18.1628 7.25079C17.8599 6.93262 17.3525 6.93262 17.0497 7.25079L9.57592 15.1038Z"
                fill={theme.palette.background.paper}/>
        </SvgIcon>);
}