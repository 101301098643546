import { Box, Divider, List, ListItem, ListProps, Stack, useTheme } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useMemo, useState } from "react";
import { CollapsedIcon, Expand, ExpandedIcon, MemoItemRenderer, SearchListCategory, SearchListItemOptions, SearchListItemsProps, Sx, useSearchListContext } from "@infrastructure";

export function CategorizedItems({ categories, dependencies, itemOptions, listProps, sx }: SearchListItemsProps) {
    const { filteredItems } = useSearchListContext();
    const [expandedCategoryIds, setExpandedCategoryIds] =
        useState<string[]>(
            () =>
                _(categories).
                    filter(
                        category =>
                            category.options?.expandable !== true ||
                            (category.options?.initialExpand ?? true)).
                    map(category => category.id).
                    value());
    const theme = useTheme();
    return (
        <List
            {...listProps}
            sx={
                Sx.combine(
                    { width: "100%" },
                    sx)}>
            {_(categories).
                filter(category => _.some(filteredItems, category.isItemIncluded)).
                map(
                    (category, categoryIndex) =>
                        <Stack
                            key={category.id}
                            spacing={3}>
                            <Box>
                                {(!_.isNil(category.options?.titleElement) || category.options?.expandable) &&
                                    <Stack
                                        alignItems="center"
                                        direction="row"
                                        spacing={1}
                                        sx={{ cursor: "pointer" }}
                                        onClick={
                                            () =>
                                                category.options?.expandable &&
                                                setExpandedCategoryIds(
                                                    _.includes(expandedCategoryIds, category.id)
                                                        ? _.without(expandedCategoryIds, category.id)
                                                        : _.concat(expandedCategoryIds, category.id))}>
                                        <Box
                                            sx={{
                                                flex: 1,
                                                fontWeight: 600
                                            }}>
                                            {category.options?.titleElement}
                                        </Box>
                                        {category.options?.expandable &&
                                            (_.includes(expandedCategoryIds, category.id)
                                                ? <ExpandedIcon/>
                                                : <CollapsedIcon/>)}
                                    </Stack>}
                                <Expand expanded={_.includes(expandedCategoryIds, category.id)}>
                                    <Fragment>
                                        {!_.isNil(category.options?.headerElement) && (
                                            <Box>
                                                {category.options?.headerElement}
                                            </Box>)}
                                        {(categoryIndex > 0 || !_.isNil(category.options?.headerElement)) &&
                                                <Divider
                                                    sx={{
                                                        backgroundColor: theme.palette.action.hover,
                                                        margin: theme.spacing(1, 0)
                                                    }}/>}
                                        <Items
                                            category={category}
                                            dependencies={dependencies}
                                            itemOptions={itemOptions}
                                            listProps={listProps}/>
                                    </Fragment>
                                </Expand>
                            </Box>
                        </Stack>).
                value()}
        </List>);
}

type ItemsProps = {
    category: SearchListCategory;
    dependencies: any[];
    itemOptions?: SearchListItemOptions;
    listProps: ListProps;
};

function Items({ category, dependencies, itemOptions, listProps }: ItemsProps) {
    const { filteredItems } = useSearchListContext();
    const theme = useTheme();
    return (
        <List {...listProps}>
            {useMemo(
                () =>
                    _(filteredItems).
                        filter(category.isItemIncluded).
                        map(
                            (item, itemIndex) =>
                                <ListItem
                                    disableGutters={true}
                                    key={itemIndex}
                                    sx={{
                                        paddingBottom: theme.spacing(itemOptions?.spacing ?? 0),
                                        paddingTop: theme.spacing(itemOptions?.spacing ?? 0)
                                    }}>
                                    {_.isNil(itemOptions?.render)
                                        ? item
                                        : <MemoItemRenderer
                                            getDependencies={itemOptions?.getDependencies}
                                            render={itemOptions!.render}
                                            renderArguments={[item]}/>}
                                </ListItem>).
                        value(),
                [filteredItems, ...dependencies])}
        </List>);
}