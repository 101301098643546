import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function Ec2SnapshotIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M6.5 0L13 2.72728L13 6.48L12.1872 6.48012L12.0541 6.48638C11.9206 6.4997 11.7872 6.53407 11.6612 6.58855C11.4929 6.66131 11.3417 6.76825 11.2172 6.9026L10.0416 8.1787C10.274 7.0959 9.94657 5.92651 9.05667 5.08637C7.64833 3.75682 5.35889 3.75682 3.95055 5.08637C2.54222 6.41591 2.54222 8.57728 3.95055 9.90679C5.13409 11.0241 6.9345 11.2084 8.31564 10.4478L8.49333 10.3432L9.484 11.279L9.486 11.505L9.40409 11.567L9.25929 11.691C8.84385 12.0721 8.60161 12.5344 8.60161 13.0898L8.60052 13.2498L8.60804 13.3822L8.6318 13.5128L8.67688 13.6637C8.72228 13.7943 8.7824 13.918 8.85621 14.0351C8.13568 14.4755 7.34295 14.8062 6.5 15C2.85803 14.1604 0.126553 10.7618 0.00427604 7.07579L0 6.81819V2.72728L6.5 0ZM6.5 5.45455C7.69662 5.45455 8.66667 6.37033 8.66667 7.5C8.66667 8.62968 7.69662 9.54549 6.5 9.54549C5.30338 9.54549 4.33333 8.62968 4.33333 7.5C4.33333 6.37033 5.30338 5.45455 6.5 5.45455Z"/>
            <path d="M18.6003 9.6208H10.8019C10.7395 9.62101 10.6783 9.60295 10.626 9.56883C10.5737 9.53472 10.5325 9.48604 10.5075 9.4288C10.4825 9.37182 10.4746 9.30879 10.4848 9.24741C10.4951 9.18603 10.523 9.12896 10.5651 9.0832L11.9507 7.58239C11.9809 7.54983 12.0176 7.5239 12.0583 7.50627C12.0991 7.48864 12.1431 7.47969 12.1875 7.47999H17.2147C17.2586 7.48014 17.302 7.4893 17.3422 7.50691C17.3824 7.52453 17.4185 7.55022 17.4483 7.58239L18.8403 9.0832C18.883 9.12862 18.9115 9.18552 18.9223 9.24692C18.9331 9.30831 18.9258 9.37153 18.9011 9.4288C18.8757 9.48705 18.8335 9.53642 18.78 9.57062C18.7264 9.60481 18.6638 9.62228 18.6003 9.6208ZM11.5315 8.9808H17.8803L17.0739 8.12H12.3283L11.5315 8.9808Z"/>
            <path d="M18.9203 11.3999H18.2803V10.8207H11.1219V11.3999H10.4819V10.5007C10.4819 10.4158 10.5156 10.3344 10.5757 10.2744C10.6357 10.2144 10.7171 10.1807 10.8019 10.1807H18.6003C18.6852 10.1807 18.7666 10.2144 18.8266 10.2744C18.8866 10.3344 18.9203 10.4158 18.9203 10.5007V11.3999Z"/>
            <path d="M14.6802 22.5198C13.0802 22.5198 10.7026 22.1614 10.6642 21.179L9.59538 13.2302C9.57884 13.1454 9.59668 13.0574 9.64499 12.9857C9.6933 12.914 9.76812 12.8644 9.85298 12.8478C9.93786 12.8313 10.0258 12.8491 10.0975 12.8974C10.1692 12.9457 10.2188 13.0206 10.2354 13.1054C10.3314 13.5278 12.1202 14.139 14.6802 14.139C17.2402 14.139 19.0322 13.5278 19.1602 13.1054C19.1768 13.0206 19.2263 12.9457 19.298 12.8974C19.3698 12.8491 19.4577 12.8313 19.5426 12.8478C19.6274 12.8644 19.7023 12.914 19.7506 12.9857C19.7989 13.0574 19.8168 13.1454 19.8002 13.2302L18.7026 21.179C18.7026 21.5278 18.3826 21.819 17.8194 22.0462C17.5741 22.138 17.3229 22.2128 17.0674 22.2702C16.2832 22.4406 15.4826 22.5244 14.6802 22.5198ZM10.3474 14.0398L11.3074 21.1278C11.3074 21.1278 11.3074 21.1566 11.3074 21.1726C11.3874 21.3998 12.7346 21.8798 14.6802 21.8798C15.4332 21.8846 16.1844 21.8062 16.9202 21.6462C17.1443 21.5969 17.3646 21.5317 17.5794 21.451C18.0082 21.2814 18.0594 21.147 18.0594 21.147C18.058 21.1353 18.058 21.1235 18.0594 21.1118L19.0194 14.0206C17.9698 14.5646 16.0562 14.779 14.6834 14.779C13.3106 14.779 11.4002 14.5646 10.3474 14.0398Z"/>
            <path d="M14.6801 14.7793C12.7985 14.7793 9.88008 14.3761 9.60168 13.2689C9.60022 13.2561 9.60022 13.2432 9.60168 13.2305V13.1313C9.60168 13.1153 9.60168 13.1025 9.60168 13.0897C9.60168 11.9793 12.2417 11.2305 14.7217 11.2305C17.2017 11.2305 19.8001 11.9793 19.8001 13.0801V13.2593C19.4801 14.3761 16.5681 14.7793 14.6801 14.7793ZM10.2257 13.1217C10.3729 13.5409 12.1457 14.1393 14.6833 14.1393C17.2209 14.1393 18.9969 13.5409 19.1409 13.1217L19.1601 13.0801C19.1153 12.7121 17.3937 11.8769 14.6961 11.8769C11.9985 11.8769 10.2641 12.7057 10.2225 13.0801L10.2257 13.1217Z"/>
            <path d="M19.7169 18.0432C18.9809 18.0432 17.4769 17.6144 14.5425 16.1712L14.8241 15.5952C16.7441 16.5392 19.0225 17.4416 19.6977 17.4416C19.4522 17.2199 19.1777 17.0325 18.8817 16.8848L18.8401 16.8656L19.1601 16.3056L19.1953 16.3248C19.9889 16.7632 20.3569 17.0928 20.4177 17.4288C20.4317 17.5041 20.4289 17.5815 20.4095 17.6557C20.3901 17.7298 20.3547 17.7987 20.3057 17.8576C20.2271 17.9297 20.1336 17.9837 20.0319 18.0157C19.9301 18.0478 19.8226 18.0572 19.7169 18.0432Z"/>
            <path d="M14.6799 16.4815C14.5616 16.4815 14.4459 16.4464 14.3475 16.3806C14.2491 16.3149 14.1724 16.2214 14.1271 16.1121C14.0818 16.0027 14.0699 15.8824 14.0931 15.7663C14.1161 15.6503 14.1731 15.5436 14.2568 15.4599C14.3405 15.3763 14.4471 15.3193 14.5632 15.2961C14.6793 15.2731 14.7996 15.2849 14.9089 15.3302C15.0183 15.3755 15.1117 15.4522 15.1775 15.5506C15.2433 15.649 15.2783 15.7647 15.2783 15.8831C15.2783 16.0418 15.2153 16.194 15.1031 16.3062C14.9909 16.4184 14.8387 16.4815 14.6799 16.4815ZM14.6799 15.8415C14.6689 15.8415 14.6583 15.8459 14.6505 15.8537C14.6427 15.8615 14.6383 15.872 14.6383 15.8831C14.6383 15.9311 14.7247 15.9311 14.7247 15.8831C14.7248 15.8773 14.7236 15.8717 14.7213 15.8664C14.719 15.8611 14.7157 15.8564 14.7115 15.8525C14.7073 15.8485 14.7023 15.8455 14.6969 15.8437C14.6914 15.8418 14.6857 15.841 14.6799 15.8415Z"/>
        </SvgIcon>);
}