import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciContainerEngineClusterNodePoolStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciContainerEngineClusterNodePoolStatusTranslator",
            () => ({
                [Contract.TypeNames.OciContainerEngineClusterNodePoolStatus]: {
                    [Contract.OciContainerEngineClusterNodePoolStatus.Active]: "Active",
                    [Contract.OciContainerEngineClusterNodePoolStatus.Attention]: "Needs Attention",
                    [Contract.OciContainerEngineClusterNodePoolStatus.Creating]: "Creating",
                    [Contract.OciContainerEngineClusterNodePoolStatus.Deleted]: "Deleted",
                    [Contract.OciContainerEngineClusterNodePoolStatus.Deleting]: "Deleting",
                    [Contract.OciContainerEngineClusterNodePoolStatus.Failed]: "Failed",
                    [Contract.OciContainerEngineClusterNodePoolStatus.Inactive]: "Inactive",
                    [Contract.OciContainerEngineClusterNodePoolStatus.Updating]: "Updating"
                }
            }));
    return (status: Contract.OciContainerEngineClusterNodePoolStatus) =>
        localization[Contract.TypeNames.OciContainerEngineClusterNodePoolStatus][status]();
}