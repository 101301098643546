﻿import { map, useInputValidation, useLocalization } from "@infrastructure";
import { FormHelperText, Stack } from "@mui/material";
import _ from "lodash";
import React, { useEffect } from "react";
import { Contract, TextInput } from "../../../../../../../../../../../common";
import { useAddOrEditContext, useSetAddOrEditContext } from "../../../../../AddOrEdit";
import { AwsSsoPermissionSetAssignmentEligibilityData, awsSsoPermissionSetNamePrefixMaxLength } from "../utilities";

export function PermissionSetNamePrefix() {
    const { permissionEligibilityData, upsertPermissionEligibilityError, upsertPermissionEligibilityExecuting } = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();
    const { autoGeneratePermissionSetNamePrefix, permissionSetNamePrefix } = permissionEligibilityData as AwsSsoPermissionSetAssignmentEligibilityData;

    const localization =
        useLocalization(
            "views.user.permissionEligibilities.addOrEdit.hooks.useDefinition.hooks.useAwsDefinition.permissionSetNamePrefix",
            () => ({
                error: {
                    exists: "ID already exists",
                    format: "ID must only contain characters: A-Z a-z 0-9 + = , . @ - _",
                    length: "ID length constraints: minimum length of 1, maximum length of {{permissionSetNamePrefixMaxLength}}"
                },
                title: "ID",
                tooltip: "A unique identifier for the permission, which will be displayed in the cloud provider console."
            }));

    const [permissionSetNamePrefixValidationController, permissionSetNamePrefixValidationMessage] =
        useInputValidation(
            () => {
                if (autoGeneratePermissionSetNamePrefix) {
                    return undefined;
                }

                const validationPermissionSetNamePrefix = _.trim(permissionSetNamePrefix);
                if (_.isEmpty(validationPermissionSetNamePrefix) ||
                    validationPermissionSetNamePrefix.length > awsSsoPermissionSetNamePrefixMaxLength) {
                    return localization.error.length({ permissionSetNamePrefixMaxLength: awsSsoPermissionSetNamePrefixMaxLength });
                }

                if (!/^[A-Za-z0-9+=,.@_\-]+$/.test(validationPermissionSetNamePrefix)) {
                    return localization.error.format();
                }

                return undefined;
            },
            [autoGeneratePermissionSetNamePrefix, permissionSetNamePrefix]);

    useEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    fieldNameToValidMap: {
                        ...addOrEditContext.fieldNameToValidMap,
                        permissionSetNamePrefix: permissionSetNamePrefixValidationController.isValid()
                    }
                }));
        },
        [permissionSetNamePrefix]);

    return (
        <Stack spacing={1.5}>
            <TextInput
                disabled={upsertPermissionEligibilityExecuting}
                info={localization.tooltip()}
                placeholder={localization.title()}
                readOnly={false}
                text={permissionSetNamePrefix}
                onChange={
                    label =>
                        setAddOrEditContext(
                            addOrEditContext => {
                                (addOrEditContext.permissionEligibilityData as AwsSsoPermissionSetAssignmentEligibilityData).setPermissionSetNamePrefix(label);
                                return { ...addOrEditContext };
                            })}/>
            {(!_.isNil(permissionSetNamePrefixValidationMessage) ||
                upsertPermissionEligibilityError === Contract.ConfigurationControllerUpsertPermissionEligibilityError.AwsInvalidSsoPermissionSetNamePrefixFormat ||
                upsertPermissionEligibilityError === Contract.ConfigurationControllerUpsertPermissionEligibilityError.AwsInvalidSsoPermissionSetNamePrefixLength ||
                upsertPermissionEligibilityError === Contract.ConfigurationControllerUpsertPermissionEligibilityError.AwsSsoPermissionSetNamePrefixExists) &&
                <FormHelperText error={true}>
                    {_.isNil(permissionSetNamePrefixValidationMessage)
                        ? map(
                            upsertPermissionEligibilityError as Contract.ConfigurationControllerUpsertPermissionEligibilityError,
                            {
                                [Contract.ConfigurationControllerUpsertPermissionEligibilityError.AwsInvalidSsoPermissionSetNamePrefixFormat]: () => localization.error.format(),
                                [Contract.ConfigurationControllerUpsertPermissionEligibilityError.AwsInvalidSsoPermissionSetNamePrefixLength]: () => localization.error.length(),
                                [Contract.ConfigurationControllerUpsertPermissionEligibilityError.AwsSsoPermissionSetNamePrefixExists]: () => localization.error.exists()
                            })
                        : permissionSetNamePrefixValidationMessage}
                </FormHelperText>}
        </Stack>);
}