﻿import React from "react";

export function AeIcon() {
    return (
        <svg
            fill="none"
            height={50}
            viewBox="0 0 50 50"
            width={50}>
            <path
                d="M49.5185 24.8191L49.2925 28.1796C48.2212 34.9502 45.0867 40.3652 39.8889 44.4246C34.6552 48.4807 28.492 50.1254 21.3996 49.3588C14.6307 48.2872 9.21719 45.1519 5.1589 39.9527C3.85858 38.2721 2.78752 36.4409 1.9457 34.459L0.884425 31.4028C0.301363 29.2998 0.0065545 27.1052 0 24.819C0.177 17.8031 2.59427 11.9657 7.25182 7.30696C11.9094 2.64818 17.7452 0.230266 24.7593 0.0532227C31.7733 0.230269 37.6092 2.64818 42.2667 7.30697C46.9243 11.9657 49.3415 17.8031 49.5185 24.8191Z"
                fill="#D72612"/>
            <path
                d="M24.7395 0V9.76401e-05C19.5255 0.131605 14.9626 1.5015 11.0508 4.10968C11.0511 8.09077 11.187 12.3251 11.1868 16.3062C23.4746 16.3061 35.6264 16.0527 47.9137 16.0526C46.7086 12.76 44.8197 9.8271 42.247 7.25375C37.5894 2.59496 31.7536 0.177044 24.7395 0Z"
                fill="#00822F"/>
            <path
                d="M11.1706 15.7842L11.1705 16.0954L11.1699 34.1574L47.6968 34.1563C48.4399 32.2985 48.9796 30.3071 49.3158 28.1822L49.5418 24.8216C49.4594 21.5536 48.8908 18.5413 47.8362 15.7847L11.1706 15.7842Z"
                fill="white"/>
            <path
                d="M47.744 34.1337V34.1338L11.1906 34.0239C11.1417 41.6786 11.1456 45.4315 11.1456 45.4315C14.1411 47.4114 17.5779 48.7522 21.4754 49.3253C22.5973 49.4902 23.7068 49.5081 24.7872 49.5081C30.5038 49.5081 35.5686 47.8098 39.9647 44.3911C43.5331 41.6161 46.1207 38.1896 47.7447 34.1335L47.744 34.1337Z"
                fill="black"/>
        </svg>);
}