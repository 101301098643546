import { useLocalization } from "@infrastructure";
import { Contract } from "../../common";

export function useNetworkAccessScopeTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useNetworkAccessScopeTranslator",
            () => ({
                [Contract.TypeNames.NetworkAccessScope]: {
                    [Contract.NetworkAccessScope.All]: "All IPs",
                    [Contract.NetworkAccessScope.None]: "None",
                    [Contract.NetworkAccessScope.Restricted]: "Specific IPs",
                    [Contract.NetworkAccessScope.Wide]: "Wide range of IPs"
                }
            }));

    return (accessScope: Contract.NetworkAccessScope) =>
        localization[Contract.TypeNames.NetworkAccessScope][accessScope]();
}