﻿import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, PagedValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, PagedEntityFilter, SeverityFilter, TypeHelper, useEntitiesExportOptions, useEntityTypeNameTranslator } from "../../../../../../../../../common";
import { useGetVulnerabilitiesCsvItem } from "../../../../../../../hooks";
import { VulnerabilitiesCell } from "../../../../VulnerabilitiesCell";
import { DefinitionData } from "../../../Table";

export function useCommonKubernetesWorkloadResourceDefinition(definitionData: DefinitionData) {
    const entitiesExportOptions = useEntitiesExportOptions();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.useCommonKubernetesWorkloadResourceDefinition",
            () => ({
                columns: {
                    containerImages: "Container Images",
                    containerImageVulnerabilities: "Vulnerabilities",
                    containerImageVulnerabilitySeverities: "Vulnerability Severity",
                    serviceAccountId: "Service Account",
                    typeName: "Type"
                }
            }));

    const getVulnerabilitiesCsvItem = useGetVulnerabilitiesCsvItem();
    return {
        columns: {
            containerImagesColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.IKubernetesWorkloadResourceModel>(
                            Contract.TypeNames.IContainerImage,
                            item => item.containerImageIdReferences,
                            localization.columns.containerImages())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.KubernetesWorkloadResourceContainerImages)}
                                    placeholder={localization.columns.containerImages()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.KubernetesWorkloadResourceContainerImages}
                    key={Contract.EntityModelProperty.KubernetesWorkloadResourceContainerImages}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.IKubernetesWorkloadResourceModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.containerImageIdReferences}
                                entityTypeName={Contract.TypeNames.IContainerImage}
                                entityVariant="iconText"/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.containerImages()}/>,
            containerImageVulnerabilitiesColumn:
                <DataTableColumn
                    cellMinWidth={110}
                    exportOptions={{
                        getItem:
                            (item: Contract.IKubernetesWorkloadResourceModel) => ({
                                [localization.columns.containerImageVulnerabilities()]: getVulnerabilitiesCsvItem(item.containerImageVulnerabilities)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: [
                            {
                                element:
                                    <PagedValuesFilter
                                        getValuePage={
                                            ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                                definitionData.entityTypeEntitiesViewName,
                                                Contract.EntityModelProperty.KubernetesWorkloadResourceContainerImageVulnerabilities)}
                                        placeholder={localization.columns.containerImageVulnerabilities()}/>,
                                id: Contract.EntityModelProperty.KubernetesWorkloadResourceContainerImageVulnerabilities,
                                title: localization.columns.containerImageVulnerabilities()
                            },
                            {
                                element:
                                    <SeverityFilter placeholder={localization.columns.containerImageVulnerabilitySeverities()}/>,
                                id: Contract.EntityModelProperty.KubernetesWorkloadContainerImageVulnerabilitySeverities,
                                title: localization.columns.containerImageVulnerabilitySeverities()
                            }
                        ]
                    }}
                    id={Contract.EntityModelProperty.KubernetesWorkloadResourceContainerImageVulnerabilities}
                    key={Contract.EntityModelProperty.KubernetesWorkloadResourceContainerImageVulnerabilities}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.IKubernetesWorkloadResourceModel>) =>
                            <VulnerabilitiesCell
                                containerImageScanStatus={item.containerImageScanStatus}
                                vulnerabilities={item.containerImageVulnerabilities}/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.containerImageVulnerabilities()}/>,
            serviceAccountColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.IKubernetesWorkloadResourceModel>(
                            Contract.TypeNames.IKubernetesServiceAccount,
                            item => [item.serviceAccountReference.idReference],
                            localization.columns.serviceAccountId())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.KubernetesWorkloadResourceServiceAccount)}
                                    placeholder={localization.columns.serviceAccountId()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.KubernetesWorkloadResourceServiceAccount}
                    key={Contract.EntityModelProperty.KubernetesWorkloadResourceServiceAccount}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.IKubernetesWorkloadResourceModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.serviceAccountReference.idReference}
                                entityTypeName={Contract.TypeNames.IKubernetesServiceAccount}
                                entityVariant="iconText"/>}
                    title={localization.columns.serviceAccountId()}/>,
            typeColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.IKubernetesWorkloadResourceModel) => ({
                                [localization.columns.typeName()]: entityTypeNameTranslator(item.entity.typeName)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter placeholder={localization.columns.typeName()}>
                                    {_.map(
                                        TypeHelper.getSealedAssignableTypeNames(definitionData.entityTypeEntitiesViewName),
                                        typeName =>
                                            <ValuesFilterItem
                                                key={typeName}
                                                title={
                                                    entityTypeNameTranslator(
                                                        typeName,
                                                        {
                                                            includeServiceName: false
                                                        })}
                                                value={typeName}/>)}
                                </ValuesFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.KubernetesWorkloadResourceTypeName}
                    itemProperty={
                        (item: Contract.IKubernetesWorkloadResourceModel) =>
                            entityTypeNameTranslator(
                                item.entity.typeName,
                                {
                                    includeServiceName: false
                                })}
                    key={Contract.EntityModelProperty.KubernetesWorkloadResourceTypeName}
                    title={localization.columns.typeName()}/>

        }
    };
}