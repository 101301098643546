import { useLocalization } from "@infrastructure";
import { Grid2, Stack } from "@mui/material";
import React from "react";
import { useTheme } from "../../../../../../../../common";
import { Disk } from "../Disk";
import { ExclusionTagsTable } from "../ExclusionTags";
import { VirtualMachineImageActionsCell, VirtualMachineImageAddExclusionTags, VirtualMachineImageEnabled } from "./components";

export function VirtualMachineImages() {
    const localization =
        useLocalization(
            "views.customer.configuration.workloadAnalysis.virtualMachineImages",
            () => ({
                resourceType: "virtual machine images"
            }));
    const theme = useTheme();
    return (
        <Grid2
            size={{ xs: 8 }}
            sx={{
                height: "100%",
                maxWidth: theme.spacing(125),
                overflowY: "auto",
                padding: theme.spacing(3, 4)
            }}>
            <Stack spacing={2}>
                <VirtualMachineImageEnabled/>
                <Disk resourceType={localization.resourceType()}/>
                <VirtualMachineImageExclusionTags/>
            </Stack>
        </Grid2>);
}

function VirtualMachineImageExclusionTags() {
    const localization =
        useLocalization(
            "views.customer.configuration.workloadAnalysis.virtualMachineImages.virtualMachineImageExclusionTags",
            () => ({
                description: "By default, all virtual machine images in the account are scanned. You can exclude specific virtual machine images based on their tags",
                resourceType: {
                    caps: "Virtual Machine Image",
                    noCaps: "virtual machine image"
                }
            }));
    return (
        <ExclusionTagsTable
            ActionsCell={VirtualMachineImageActionsCell}
            AddComponent={VirtualMachineImageAddExclusionTags}
            exclusionTagsTableLocalization={{
                description: localization.description(),
                resourceType: {
                    caps: localization.resourceType.caps(),
                    noCaps: localization.resourceType.noCaps()
                }
            }}
            getScopeConfigurationWorkloadAnalysisResourceTags={scopedConfiguration => scopedConfiguration.virtualMachineImage.exclusionTags}/>);
}