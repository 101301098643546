import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, Optional, optionalTableCell, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { AadDirectoryApplicationExpirationDateCell } from ".";
import { Contract, EntitiesCell, entityModelStore } from "../../../common";

type AadDirectoryApplicationCertificatesTableProps = {
    applicationModel: Contract.AadDirectoryApplicationModel;
    getHighlightColor?: (certificateId: string, opacity?: number) => Optional<string>;
};

export function AadDirectoryApplicationCertificatesTable({ applicationModel, getHighlightColor }: AadDirectoryApplicationCertificatesTableProps) {
    const certificateModels = entityModelStore.useGet(applicationModel.certificateIds);

    const localization =
        useLocalization(
            "tenants.aad.aadDirectoryApplicationCertificatesTable",
            () => ({
                columns: {
                    certificates: "Certificates",
                    creationTime: "Start Time",
                    expirationTime: "Expiration Time",
                    thumbprint: "Thumbprint"
                },
                empty: "No Certificates"
            }));

    return (
        <DataTable
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={() => certificateModels}
            getItemId={(certificate: Contract.AadDirectoryApplicationCertificateModel) => certificate.id}
            rowOptions={{
                getHighlightColor: (certificate: Contract.AadDirectoryApplicationCertificateModel) => getHighlightColor?.(certificate.id),
                getSx:
                    (certificate: Contract.AadDirectoryApplicationCertificateModel) => ({
                        backgroundColor: getHighlightColor?.(certificate.id, 0.1)
                    })
            }}
            sortOptions={{ enabled: false }}
            variant="card">
            <DataTableColumn
                id={ApplicationCertificatesInfoCardTableColumnId.RawDisplayName}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AadDirectoryApplicationCertificateModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item}
                            entityTypeName={Contract.TypeNames.AadDirectoryApplicationCertificate}
                            entityVariant="iconTextTypeTenant"/>}
                title={localization.columns.certificates()}/>
            <DataTableColumn
                id={ApplicationCertificatesInfoCardTableColumnId.Thumbprint}
                render={optionalTableCell<Contract.AadDirectoryApplicationCertificateModel>(item => (item.entity as Contract.AadDirectoryApplicationCertificate).thumbprint)}
                title={localization.columns.thumbprint()}/>
            <DataTableColumn
                id={ApplicationCertificatesInfoCardTableColumnId.CreationTime}
                render={
                    optionalTableCell<Contract.AadDirectoryApplicationCertificateModel>(
                        item =>
                            _.isNil((item.entity as Contract.AadDirectoryEntity).creationTime)
                                ? undefined
                                : TimeFormatter.monthDayAndYear((item.entity as Contract.AadDirectoryApplicationCertificate).creationTime))}
                title={localization.columns.creationTime()}/>
            <DataTableColumn
                id={ApplicationCertificatesInfoCardTableColumnId.ExpirationTime}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AadDirectoryApplicationCertificateModel>) =>
                        <AadDirectoryApplicationExpirationDateCell
                            expirationDate={(item.entity as Contract.AadDirectoryApplicationCertificate).expirationTime}
                            expirationStatus={(item.entity as Contract.AadDirectoryApplicationCertificate).expirationStatus}
                            variant="date"/>}
                title={localization.columns.expirationTime()}/>
        </DataTable>);
}

enum ApplicationCertificatesInfoCardTableColumnId {
    CreationTime = "creationTime",
    ExpirationTime = "expirationTime",
    RawDisplayName = "rawDisplayName",
    Thumbprint = "thumbprint"
}