﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { AwsResourcePermitterType, useAwsResourcePermitterTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetDataAnalysisResourceRiskContext } from "../../../useGetDataAnalysisResourceRiskContext";
import { InlineActions } from "../../components";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsS3BucketRiskContext() {
    return useMemo(
        () => useAwsS3BucketRiskContext,
        []);
}

function useAwsS3BucketRiskContext(bucketModel: Contract.AwsS3BucketModel) {
    const bucket = bucketModel.entity as Contract.AwsS3Bucket;
    const dataAnalysisResourceRiskContext = useGetDataAnalysisResourceRiskContext()(bucketModel);
    const resourceRiskContext = useGetAwsResourceRiskContext()(bucketModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const resourcePermitterTranslator = useAwsResourcePermitterTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsS3BucketRiskContext",
            () => ({
                encryption: {
                    text: "The {{translatedBucketTypeName}} is {{encryption}}",
                    type: {
                        kms: "encrypted using KMS key {{key}}",
                        none: "not encrypted",
                        s3: "encrypted using SSE-S3"
                    }
                },
                objectVersioningEnabled: {
                    false: "The {{translatedBucketTypeName}} does not have versioning enabled",
                    true: "The {{translatedBucketTypeName}} has versioning enabled"
                },
                policyDocumentPublicAccessPermissionActions: "The {{translatedBucketTypeName}} has a {{translatedResourcePermitter}} granting {{publicAccessPermissionActions}} to any principal",
                public: "The {{bucketName}} is publicly accessible via bucket policy or Access Control List (ACL)",
                publicAccessHighSeveritySensitivePermissionActions: {
                    all: "The {{translatedBucketTypeName}} has a {{translatedResourcePermitter}} granting {{publicAccessHighSeveritySensitivePermissionActions}} with high severity to any principal",
                    none: "The {{translatedBucketTypeName}} has a {{translatedResourcePermitter}} granting {{publicAccessPermissionActions}} to any principal, none with high severity",
                    some: "The {{translatedBucketTypeName}} has a {{translatedResourcePermitter}} granting {{publicAccessPermissionActions}} to any principal, {{publicAccessHighSeveritySensitivePermissionActions}} with high severity"
                },
                storageSize: {
                    empty: "The {{translatedBucketTypeName}} is empty",
                    notEmpty: "The {{translatedBucketTypeName}}’s size is {{storageSize | NumberFormatter.storage}}"
                }
            }));
    const translatedBucketTypeName =
        entityTypeNameTranslator(
            bucket.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...dataAnalysisResourceRiskContext,
        ...resourceRiskContext,
        accessControlListPublicAccessHighSeveritySensitivePermissionActions:
            _.isEmpty(bucket.accessControlList.publicAccessPermissionActions)
                ? undefined
                : new RiskDefinitionContextItem(
                    (_.isEmpty(bucket.accessControlListPublicAccessHighSeveritySensitivePermissionActions)
                        ? localization.publicAccessHighSeveritySensitivePermissionActions.none
                        : _.size(bucket.accessControlList.publicAccessPermissionActions) === _.size(bucket.accessControlListPublicAccessHighSeveritySensitivePermissionActions)
                            ? localization.publicAccessHighSeveritySensitivePermissionActions.all
                            : localization.publicAccessHighSeveritySensitivePermissionActions.some)({
                        publicAccessHighSeveritySensitivePermissionActions:
                            <InlineActions actions={bucket.accessControlListPublicAccessHighSeveritySensitivePermissionActions}/>,
                        publicAccessPermissionActions:
                            <InlineActions actions={bucket.accessControlList.publicAccessPermissionActions}/>,
                        translatedBucketTypeName,
                        translatedResourcePermitter: resourcePermitterTranslator(new AwsResourcePermitterType(Contract.AwsResourcePermitterType.S3BucketAccessControlList))
                    })),
        accessControlListPublicAccessPermissionActions:
            _.isEmpty(bucket.accessControlList.publicAccessPermissionActions)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.policyDocumentPublicAccessPermissionActions({
                        bucket:
                            <Entity
                                entityIdOrModel={bucketModel}
                                variant="text"/>,
                        publicAccessPermissionActions:
                            <InlineActions actions={bucket.accessControlList.publicAccessPermissionActions}/>,
                        translatedBucketTypeName,
                        translatedResourcePermitter:
                            resourcePermitterTranslator(
                                new AwsResourcePermitterType(
                                    Contract.AwsResourcePermitterType.S3BucketAccessControlList,
                                    bucket.typeName))
                    })),
        encryption:
            new RiskDefinitionContextItem(
                localization.encryption.text({
                    encryption:
                        _.isNil(bucket.serverSideEncryptionType)
                            ? localization.encryption.type.none()
                            : bucket.serverSideEncryptionType === Contract.AwsS3BucketServerSideEncryptionType.S3
                                ? localization.encryption.type.s3()
                                : localization.encryption.type.kms({
                                    key:
                                        <Entity
                                            entityIdOrModel={bucketModel.kmsEncryptionKeyIdReferences[0]}
                                            variant="text"/>
                                }),
                    translatedBucketTypeName
                })),
        objectVersioningEnabled:
            new RiskDefinitionContextItem(
                (bucket.objectVersioningEnabled
                    ? localization.objectVersioningEnabled.true
                    : localization.objectVersioningEnabled.false)({ translatedBucketTypeName })),
        policyDocumentPublicAccessHighSeveritySensitivePermissionActions:
            _.isEmpty(bucket.policyDocumentPublicAccessPermissionActions)
                ? undefined
                : new RiskDefinitionContextItem(
                    (_.isEmpty(bucket.policyDocumentPublicAccessHighSeveritySensitivePermissionActions)
                        ? localization.publicAccessHighSeveritySensitivePermissionActions.none
                        : _.size(bucket.policyDocumentPublicAccessPermissionActions) === _.size(bucket.policyDocumentPublicAccessHighSeveritySensitivePermissionActions)
                            ? localization.publicAccessHighSeveritySensitivePermissionActions.all
                            : localization.publicAccessHighSeveritySensitivePermissionActions.some)({
                        publicAccessHighSeveritySensitivePermissionActions:
                            <InlineActions actions={bucket.policyDocumentPublicAccessHighSeveritySensitivePermissionActions}/>,
                        publicAccessPermissionActions:
                            <InlineActions actions={bucket.policyDocumentPublicAccessPermissionActions}/>,
                        translatedBucketTypeName,
                        translatedResourcePermitter:
                            resourcePermitterTranslator(
                                new AwsResourcePermitterType(
                                    Contract.AwsResourcePermitterType.ResourcePolicy,
                                    bucket.typeName))
                    })),
        policyDocumentPublicAccessPermissionActions:
            _.isEmpty(bucket.policyDocumentPublicAccessPermissionActions)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.policyDocumentPublicAccessPermissionActions({
                        publicAccessPermissionActions:
                            <InlineActions actions={bucket.policyDocumentPublicAccessPermissionActions}/>,
                        translatedBucketTypeName,
                        translatedResourcePermitter:
                            resourcePermitterTranslator(
                                new AwsResourcePermitterType(
                                    Contract.AwsResourcePermitterType.ResourcePolicy,
                                    bucket.typeName))
                    })),
        public:
            bucketModel?.accessLevel === Contract.AwsResourceAccessLevel.Public
                ? new RiskDefinitionContextItem(
                    localization.public({
                        bucketName:
                            <Entity
                                entityIdOrModel={bucketModel}
                                variant="typeText"/>
                    }))
                : undefined,
        storageSize:
            new RiskDefinitionContextItem(
                (bucket.storageSize > 0
                    ? localization.storageSize.notEmpty
                    : localization.storageSize.empty)({
                    storageSize: bucket.storageSize,
                    translatedBucketTypeName
                }))
    };
}