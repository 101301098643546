﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function NetherlandsIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_60203_5282)">
                <rect
                    fill="white"
                    height="15.9638"
                    rx="2"
                    width="24"
                    y="4"/>
                <path
                    d="M24 4.00012H0V19.9639H24V4.00012Z"
                    fill="#21468B"/>
                <path
                    d="M24 4.00012H0V14.6427H24V4.00012Z"
                    fill="white"/>
                <path
                    clipRule="evenodd"
                    d="M1 9.32312V14.6444H0V9.32312H1ZM23 14.6444V9.32312H24V14.6444H23Z"
                    fill="#BAC5D3"
                    fillRule="evenodd"/>
                <path
                    d="M24 4.00012H0V9.32139H24V4.00012Z"
                    fill="#AE1C28"/>
            </g>
            <defs>
                <clipPath id="clip0_60203_5282">
                    <rect
                        fill="white"
                        height="15.9638"
                        rx="2"
                        width="24"
                        y="4"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}