import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, entityModelStore, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetNetworkAccessResourceRiskContext } from "../../../useGetNetworkAccessResourceRiskContext";
import { useGetAzureResourceRiskContext } from "./useGetAzureResourceRiskContext";

export function useGetAzureContainerRegistryRegistryRiskContext() {
    return useMemo(
        () => useAzureContainerRegistryRegistryRiskContext,
        []);
}

function useAzureContainerRegistryRegistryRiskContext(registryModel: Contract.AzureContainerRegistryRegistryModel) {
    const registry = registryModel.entity as Contract.AzureContainerRegistryRegistry;
    const resourceRiskContext = useGetAzureResourceRiskContext()(registryModel);
    const networkAccessResourceRiskContext = useGetNetworkAccessResourceRiskContext()(registryModel);
    const repositoryModels = entityModelStore.useGet(registry.repositoryIds);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.contexts.useGetAzureContainerRegistryRegistryRiskContext",
            () => ({
                accessKeyEnabled: "The {{translatedRegistryTypeName}} admin user flag is enabled",
                repositories: {
                    any: "The {{translatedRegistryTypeName}} contains {{repositories}}",
                    empty: "The {{translatedRegistryTypeName}} does not contain any repositories"
                },
                restricted: "The {{translatedRegistryTypeName}} has network restrictions, which prevent Tenable Cloud Security from accessing repositories, and which may affect the severity",
                sensitiveRepositories: [
                    "The {{translatedRegistryTypeName}} contains {{repositories}} which is marked as sensitive",
                    "The {{translatedRegistryTypeName}} contains {{repositories}} which are marked as sensitive"
                ]
            }));
    const sensitiveRepositoryModels =
        useMemo(
            () =>
                _.filter(
                    repositoryModels,
                    repositoryModel => repositoryModel.entityConfiguration?.sensitive === true),
            [repositoryModels]);

    const translatedRegistryTypeName =
        entityTypeNameTranslator(
            registry.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });
    return {
        ...resourceRiskContext,
        ...networkAccessResourceRiskContext,
        accessKey:
            registry.accessKey
                ? new RiskDefinitionContextItem(localization.accessKeyEnabled({ translatedRegistryTypeName }))
                : undefined,
        repositories:
            new RiskDefinitionContextItem(
                _.isEmpty(repositoryModels)
                    ? localization.repositories.empty({ translatedRegistryTypeName })
                    : localization.repositories.any({
                        repositories:
                            <InlineEntities
                                entityIdsOrModels={repositoryModels}
                                entityTypeName={Contract.TypeNames.AzureContainerRegistryRepository}
                                variant="itemCountAndType"/>,
                        translatedRegistryTypeName
                    })),
        restricted:
            _.isNil(registry.repositoryIds)
                ? new RiskDefinitionContextItem(localization.restricted({ translatedRegistryTypeName }))
                : undefined,
        sensitiveRepositories:
            !_.isEmpty(sensitiveRepositoryModels)
                ? new RiskDefinitionContextItem(
                    localization.sensitiveRepositories(
                        sensitiveRepositoryModels.length,
                        {
                            repositories:
                                <InlineEntities
                                    entityIdsOrModels={sensitiveRepositoryModels}
                                    entityTypeName={Contract.TypeNames.AzureContainerRegistryRepository}
                                    variant="itemCountAndType"/>,
                            translatedRegistryTypeName
                        }))
                : undefined,
        wideRangeInboundSubnets:
            registry.networkAccessType != Contract.AzureContainerRegistryRegistryNetworkAccessType.Public
                ? resourceRiskContext.getWideRangeInboundSubnetsContextItem(registry.wideRangeFirewallRuleSubnets)
                : undefined
    };
}