import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { ItemSelector, Link, map, Message, Optional, Step, Steps, StringHelper, TextSnippet, TextSnippetItem, ToggleList, ToggleListItem, useExecuteOperation, useLocalization } from "@infrastructure";
import { ConsoleAppUrlHelper, Contract, CustomerConsoleAppUrlHelper, KubernetesController, LicensingHelper, ScopeHelper, scopeNodeModelStore, ScopeSelector, scopeSystemEntityModelStore, UserHelper, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../common";
import { SystemKubernetesClusterHelmStatus, SystemKubernetesClusterHelper } from "../../../utilities";
import { useAddOrEditContext } from "../AddOrEdit";

export function CommandItem() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const scopeNodeMap =
        scopeNodeModelStore.useGetActiveScopeNodeMap(
            undefined,
            true);
    const [selectedTenantId, setSelectedTenantId] =
        useState<Optional<string>>(
            () =>
                ScopeHelper.isCloudProviderTenant(scopeNodeModel)
                    ? scopeNodeModel.configuration.id
                    : undefined);
    const kubernetesClusterConnectorModels = scopeSystemEntityModelStore.useGetKubernetesClusterConnector();
    const { systemKubernetesClusterModel } = useAddOrEditContext();

    const [{ clusterConnectorIdToDataMap, containerImageRegistryUserName, containerImageRegistryUserPassword, orderedClusterConnectorIds }] =
        useExecuteOperation(
            CommandItem,
            async () => {
                const { clusterConnectorIdToApiKeyTokenMap, containerImageRegistryToken } = await KubernetesController.getKubernetesClusterOnboardingInfo(new Contract.KubernetesControllerGetKubernetesClusterOnboardingInfoRequest(scopeNodeModel.configuration.id));
                const kubernetesClusterConnectorModelMap =
                    _.keyBy(
                        kubernetesClusterConnectorModels,
                        kubernetesClusterConnectorModel => kubernetesClusterConnectorModel.id);
                const clusterConnectorIdToDataMap =
                    _.mapValues(
                        clusterConnectorIdToApiKeyTokenMap,
                        (_clusterConnectorApiKeyToken, clusterConnectorId) => ({
                            apiKeyToken: _clusterConnectorApiKeyToken,
                            name: _.as<Contract.KubernetesClusterConnectorConfiguration>(kubernetesClusterConnectorModelMap[clusterConnectorId].configuration).name
                        }));
                return {
                    clusterConnectorIdToDataMap,
                    containerImageRegistryUserName: containerImageRegistryToken?.userName,
                    containerImageRegistryUserPassword: containerImageRegistryToken?.userPassword,
                    orderedClusterConnectorIds:
                        _(clusterConnectorIdToApiKeyTokenMap).
                            keys().
                            orderBy(clusterConnectorId => StringHelper.getSortValue(clusterConnectorIdToDataMap[clusterConnectorId].name)).
                            value()
                };
            });

    const [clusterConnectorId, setClusterConnectorId] = useState(_.head(orderedClusterConnectorIds));

    const localization =
        useLocalization(
            "views.customer.kubernetes.systemKubernetesClusters.addOrEdit.commandItem",
            () => ({
                fields: {
                    apiKeyToken: {
                        link: "Click here to create.",
                        title: {
                            withLink: "Missing Kubernetes Connector. {{integrationsKubernetesLink}}",
                            withoutLink: "Missing Kubernetes Connector."
                        }
                    }
                },
                steps: {
                    step1: {
                        connector: "Kubernetes Connector",
                        title: "Select the connector. It’s recommended to use a single connector for all clusters."
                    },
                    step2: {
                        info: {
                            links: {
                                documentation: "Learn more"
                            },
                            title: "The default cluster account location is detected and assigned automatically. {{documentationLink}}."
                        },
                        tenant: {
                            empty: "Automatically detect account"
                        },
                        title: "Select the account that the cluster will be associated with."
                    },
                    step3: {
                        admissionController: {
                            subtitle: "Monitor and enforce policies that intercept Kubernetes requests. {{documentationLink}}.",
                            title: "Admission Controller"
                        },
                        connector: {
                            subtitle: "Gain visibility into the cluster’s resources to detect misconfigurations and trigger findings. {{documentationLink}}.",
                            title: "Resource Sync"
                        },
                        links: {
                            documentation: "Learn more"
                        },
                        sensor: {
                            subtitle: "Scan the cluster nodes and container images for vulnerabilities. {{documentationLink}}.",
                            title: "Vulnerability Management"
                        },
                        title: "Select the features you want installed."
                    },
                    step4: {
                        info: {
                            links: {
                                documentation: "Learn more"
                            },
                            title: "{{documentationLink}} about optional configurations"
                        },
                        snippet: {
                            info: "The combination of Network and Identity is required if the Kubernetes cluster is not publicly accessible and Tenable doesn't have permissions to access the Kubernetes cluster.",
                            title: "Network & Identity"
                        },
                        title: "Run the following command to install the Helm chart. You can use a single command for all of your clusters."
                    }
                }
            }));

    const addCluster = _.isNil(systemKubernetesClusterModel);
    const cnappEnterpriseLicense = LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.CnappEnterprise);
    const helmStatus = SystemKubernetesClusterHelper.getHelmStatus(systemKubernetesClusterModel);
    const sensorEnabled =
        cnappEnterpriseLicense && (
            scopeNodeModel.configuration.id === ScopeHelper.customerId ||
            ScopeHelper.getTenantType(scopeNodeModel) === Contract.TenantType.Op);
    const [selectedComponentTypes, setSelectedComponentTypes] =
        useState(
            () =>
                _<ComponentType>([]).
                    concatIf(
                        !addCluster &&
                        cnappEnterpriseLicense &&
                        systemKubernetesClusterModel.helm.admissionController.status !== Contract.SystemKubernetesClusterModelHelmComponentStatus.NotInstalled,
                        ComponentType.admissionController).
                    concatIf(
                        helmStatus === SystemKubernetesClusterHelmStatus.NotInstalled
                            ? systemKubernetesClusterModel?.apiConnectivityStatus !== Contract.SystemKubernetesClusterApiConnectivityStatus.Success
                            : systemKubernetesClusterModel?.helm.connector.status !== Contract.SystemKubernetesClusterModelHelmComponentStatus.NotInstalled,
                        ComponentType.connector).
                    concatIf(
                        !addCluster &&
                        sensorEnabled &&
                        systemKubernetesClusterModel.helm.sensor.status !== Contract.SystemKubernetesClusterModelHelmComponentStatus.NotInstalled,
                        ComponentType.sensor).
                    value());

    const apiKeyToken =
        _.isNil(clusterConnectorId)
            ? undefined
            : clusterConnectorIdToDataMap[clusterConnectorId].apiKeyToken;

    const chartName = "cloud-security-kubernetes-cluster";
    const commandAction =
        map(
            helmStatus,
            {
                [SystemKubernetesClusterHelmStatus.NotInstalled]: () => "helm upgrade --install",
                [SystemKubernetesClusterHelmStatus.Installed]: () => "helm upgrade",
                [SystemKubernetesClusterHelmStatus.Outdated]: () => "curl -s https://charts.tenable.com/migrations/cloud-security-kubernetes-cluster-connector.sh | sh -s --"
            });

    const command =
        _<string>([]).
            concat(`${commandAction} tenable-${chartName} tenable/${chartName} -n tenable-${chartName} --create-namespace --set apiKeyToken=${apiKeyToken} --set apiUrl=${ConsoleAppUrlHelper.url}`).
            concat(`--set containerImage.registryUsername=${containerImageRegistryUserName}`).
            concat(`--set containerImage.registryPassword=${containerImageRegistryUserPassword}`).
            concatIf(
                !_.isNil(selectedTenantId),
                () => `--set accountId=${_.as<Contract.CloudProviderTenantConfiguration>(scopeNodeMap[selectedTenantId!].scopeNodeModel.configuration).displayReference}`).
            concatIf(
                _.includes(selectedComponentTypes, ComponentType.admissionController),
                "--set admissionController.enabled=true").
            concatIf(
                !_.includes(selectedComponentTypes, ComponentType.connector),
                "--set connector.enabled=false").
            concatIf(
                _.includes(selectedComponentTypes, ComponentType.sensor),
                "--set sensor.enabled=true").
            join(" ");

    const theme = useTheme();
    return _.isNil(apiKeyToken)
        ? <Message
            level="error"
            title={
                (UserHelper.hasScopePermissions(scopeNodeModel.configuration.id, Contract.IdentityPermission.SecurityAdministrationRead)
                    ? localization.fields.apiKeyToken.title.withLink
                    : localization.fields.apiKeyToken.title.withoutLink)(
                    {
                        integrationsKubernetesLink:
                            <Link urlOrGetUrl={CustomerConsoleAppUrlHelper.getConfigurationIntegrationsKubernetesRelativeUrl(scopeNodeModel.configuration.id)}>
                                {localization.fields.apiKeyToken.link()}
                            </Link>
                    })}/>
        : <Steps variant="plainNumbers">
            {[
                new Step(
                    localization.steps.step1.title(),
                    {
                        contentElement:
                            <Box sx={{ marginTop: theme.spacing(2) }}>
                                <ItemSelector
                                    disabled={_.isEmpty(orderedClusterConnectorIds)}
                                    fullWidth={true}
                                    items={orderedClusterConnectorIds}
                                    placeholder={localization.steps.step1.connector()}
                                    selectedItem={clusterConnectorId}
                                    sorted={false}
                                    onSelectedItemChanged={setClusterConnectorId}>
                                    {connectorId => clusterConnectorIdToDataMap[connectorId].name}
                                </ItemSelector>
                            </Box>
                    }),

                new Step(
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1.5}>
                        <Typography>
                            {localization.steps.step2.title()}
                        </Typography>
                        <Message
                            level="info"
                            title={
                                localization.steps.step2.info.title({
                                    documentationLink:
                                        <Link
                                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsOnboardClusterViaTenableConnectorClusterAccountLocationRelativeUrl()}
                                            variant="external">
                                            {localization.steps.step2.info.links.documentation()}
                                        </Link>
                                })}
                            variant="minimal"/>
                    </Stack>,
                    {
                        contentElement:
                            <Box sx={{ marginTop: theme.spacing(2) }}>
                                <ScopeSelector
                                    emptyValue={localization.steps.step2.tenant.empty()}
                                    expandOnScopeClick={true}
                                    fullWidth={true}
                                    isScopeExpanded={() => true}
                                    popoverElementContainerSx={{ padding: 0 }}
                                    rootFolderId={scopeNodeModel.configuration.id}
                                    scopeIds={scopeNodeMap[scopeNodeModel.configuration.id].tenantIds}
                                    scopeSelectable={scopeId => ScopeHelper.isCloudProviderTenant(scopeNodeMap[scopeId].scopeNodeModel)}
                                    selectedScopeId={selectedTenantId}
                                    onSelectedScopeIdChanged={tenantId => setSelectedTenantId(tenantId)}
                                    onSelectionCleared={() => setSelectedTenantId(undefined)}/>
                            </Box>
                    }),
                new Step(
                    localization.steps.step3.title(),
                    {
                        contentElement:
                            <Box sx={{ marginTop: theme.spacing(2) }}>
                                <ToggleList
                                    selectedValues={selectedComponentTypes}
                                    sx={{ maxWidth: "100%" }}
                                    onSelectedValuesChanged={setSelectedComponentTypes}>
                                    <ToggleListItem
                                        subtitle={
                                            localization.steps.step3.connector.subtitle({
                                                documentationLink:
                                                    <Link
                                                        urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsViewAndManageClustersResourceSyncStatusRelativeUrl()}
                                                        variant="external">
                                                        {localization.steps.step3.links.documentation()}
                                                    </Link>
                                            })}
                                        title={localization.steps.step3.connector.title()}
                                        value={ComponentType.connector}/>
                                    <ToggleListItem
                                        contentElement={
                                            cnappEnterpriseLicense
                                                ? null
                                                : <CnappEnterpriseLicenseLabel/>}
                                        contentElementPlacement="right"
                                        disabled={!cnappEnterpriseLicense}
                                        subtitle={
                                            localization.steps.step3.admissionController.subtitle({
                                                documentationLink:
                                                    <Link
                                                        urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsAdmissionControllerRelativeUrl()}
                                                        variant="external">
                                                        {localization.steps.step3.links.documentation()}
                                                    </Link>
                                            })}
                                        title={localization.steps.step3.admissionController.title()}
                                        value={ComponentType.admissionController}/>
                                    <ToggleListItem
                                        contentElement={
                                            cnappEnterpriseLicense
                                                ? null
                                                : <CnappEnterpriseLicenseLabel/>}
                                        contentElementPlacement="right"
                                        disabled={!sensorEnabled}
                                        subtitle={
                                            localization.steps.step3.sensor.subtitle({
                                                documentationLink:
                                                    <Link
                                                        urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsHowTenableScansYourOnPremisesKubernetesWorkloadsRelativeUrl()}
                                                        variant="external">
                                                        {localization.steps.step3.links.documentation()}
                                                    </Link>
                                            })}
                                        title={localization.steps.step3.sensor.title()}
                                        value={ComponentType.sensor}/>
                                </ToggleList>
                            </Box>
                    }),
                new Step(
                    <Typography sx={{ whiteSpace: "pre-wrap" }}>
                        {localization.steps.step4.title()}
                    </Typography>,
                    {
                        contentElement:
                            <Stack spacing={3}>
                                <TextSnippet
                                    height={theme.spacing(22)}
                                    items={[
                                        new TextSnippetItem(
                                            command,
                                            localization.steps.step4.snippet.title(),
                                            localization.steps.step4.snippet.info())
                                    ]}/>
                                <Message
                                    level="info"
                                    title={
                                        localization.steps.step4.info.title({
                                            documentationLink:
                                                <Link
                                                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsOnboardClusterViaTenableConnectorAddYourClusterRelativeUrl()}
                                                    variant="external">
                                                    {localization.steps.step4.info.links.documentation()}
                                                </Link>
                                        })}/>
                            </Stack>
                    })
            ]}
        </Steps>;
}

enum ComponentType {
    admissionController = "AdmissionController",
    connector = "Connector",
    sensor = "Sensor"
}

function CnappEnterpriseLicenseLabel() {
    const localization =
        useLocalization(
            "views.customer.kubernetes.systemKubernetesClusters.addOrEdit.commandItem.cnappEnterpriseLicenseLabel",
            () => ({
                title: "Enterprise license required"
            }));

    const theme = useTheme();
    return (
        <Box textAlign="right">
            <Link
                urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsLicensingLicenseTypesRelativeUrl()}
                variant="external">
                <Typography
                    sx={{
                        color: theme.palette.primary.main,
                        fontStyle: "italic",
                        fontWeight: 600
                    }}>
                    {localization.title()}
                </Typography>
            </Link>
        </Box>);
}