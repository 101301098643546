﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetGcpEntityRiskContext } from "./useGetGcpEntityRiskContext";

export function useGetGcpPubSubTopicRiskContext() {
    return useMemo(
        () => useGcpPubSubTopicRiskContext,
        []);
}

function useGcpPubSubTopicRiskContext(topicModel: Contract.GcpPubSubTopicModel) {
    const entityRiskContext = useGetGcpEntityRiskContext()(topicModel);
    const topic = topicModel.entity as Contract.GcpPubSubTopic;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.contexts.useGetGcpPubSubTopicRiskContext",
            () => ({
                subscriptions: {
                    withoutSubscriptions: "The {{translatedTopicTypeName}} has no subscriptions",
                    withSubscriptions: "The {{translatedTopicTypeName}} has {{subscriptions}}"
                },
                triggerFunctions: "The {{translatedTopicTypeName}} triggers {{triggerFunction}}"
            }));
    const translatedTopicTypeName =
        entityTypeNameTranslator(
            topic.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...entityRiskContext,
        subscriptions:
            new RiskDefinitionContextItem(
                (!_.isEmpty(topicModel.subscriptionIds)
                    ? localization.subscriptions.withSubscriptions
                    : localization.subscriptions.withoutSubscriptions)({
                    subscriptions:
                        <InlineEntities
                            entityIdsOrModels={topicModel.subscriptionIds}
                            entityTypeName={Contract.TypeNames.GcpPubSubSubscription}
                            variant="itemCountAndType"/>,
                    translatedTopicTypeName
                })),
        triggerFunctions:
            !_.isEmpty(topicModel.triggerFunctionIds)
                ? new RiskDefinitionContextItem(
                    localization.triggerFunctions({
                        translatedTopicTypeName,
                        triggerFunction:
                            <InlineEntities
                                entityIdsOrModels={topicModel.triggerFunctionIds}
                                entityTypeName={Contract.TypeNames.GcpFunctionsFunction}
                                variant="itemCountAndType"/>
                    }))
                : undefined
    };
}