import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function RackspaceTechnologyIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58343_2196)">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0V0C18.6274 0 24 5.37258 24 12V12C24 18.6274 18.6274 24 12 24V24C5.37258 24 0 18.6274 0 12V12Z"
                    fill="#FA1508"/>
                <path
                    d="M17.145 4.31995H17.955L18.075 4.33495L18.06 4.48495L17.85 5.60995L17.64 6.70495L17.28 8.57995L17.235 8.80495L17.01 8.78995L16.545 8.74495H15.9L15.465 8.78995L15 8.87995L14.565 9.01495L14.235 9.14995L13.905 9.32995L13.665 9.47995L13.425 9.65995L13.275 9.79495L13.155 9.89995L12.975 10.0799L12.87 10.1999L12.69 10.4249L12.42 10.8299L12.24 11.1449L12.06 11.5049L11.865 11.9849L11.7 12.4799L11.565 12.9899L11.445 13.5599L11.19 14.9099L10.98 16.0049L10.77 17.1149L10.56 18.2099L10.32 19.4699L10.275 19.6799H5.94005L5.92505 19.6649L5.94005 19.5149L6.12005 18.5699L6.33005 17.4749L6.45005 16.8599L6.58505 16.1249L6.73505 15.3449L6.93005 14.3249L7.03505 13.7699L7.17005 13.0649L7.27505 12.5099L7.51505 11.2499L7.63505 10.6049L7.78505 9.82495L7.98005 8.78995L8.13005 8.00995L8.29505 7.13995L8.49005 6.11995L8.59505 5.56495L8.73005 4.85995L8.77505 4.67995L8.79005 4.66495L12.585 4.64995L12.6 4.72495L12.33 6.16495L12.12 7.27495L12.015 7.76995V7.84495L12.105 7.67995L12.255 7.39495L12.45 7.07995L12.57 6.89995L12.78 6.61495L12.945 6.41995L13.05 6.29995L13.185 6.14995L13.395 5.93995L13.515 5.83495L13.74 5.63995L13.98 5.45995L14.175 5.32495L14.46 5.14495L14.73 4.99495L15.135 4.79995L15.555 4.63495L16.005 4.49995L16.41 4.40995L16.8 4.34995L17.145 4.31995Z"
                    fill="#FFFFFE"/>
                <path
                    d="M11.4451 19.26L11.4901 19.275L11.4601 19.5L11.4151 19.65L11.3551 19.68L11.3401 19.635L11.3701 19.545L11.3851 19.425L11.3101 19.485L11.2351 19.56L11.1601 19.545L11.1001 19.44H11.0851L11.0251 19.665H10.9651L10.9801 19.545L11.0251 19.305L11.0401 19.275L11.1151 19.29L11.1901 19.425V19.47L11.2651 19.44L11.4151 19.275L11.4451 19.26Z"
                    fill="#FFFFFE"/>
                <path
                    d="M10.62 19.26H10.935L10.98 19.29L10.845 19.32H10.815L10.785 19.515L10.74 19.665H10.68L10.71 19.485L10.74 19.32L10.605 19.305L10.62 19.26Z"
                    fill="#FFFFFE"/>
            </g>
            <defs>
                <clipPath id="clip0_58343_2196">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}