import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, scopeSystemEntityModelStore, TenantIcon } from "../../../../../../../../../../common";
import { IntegrationItem, IntegrationItemCategory } from "../../../../utilities";
import { AzureDevOps, Bitbucket, GitHub, GitLab } from "./components";

export function useCodeOrganizationItems(): IntegrationItem[] {
    const azureDevOpsOrganizationModels = scopeSystemEntityModelStore.useGetCodeOrganization(Contract.CodeTenantType.AzureDevOps);
    const bitbucketOrganizationModels = scopeSystemEntityModelStore.useGetCodeOrganization(Contract.CodeTenantType.Bitbucket);
    const gitHubOrganizationModels = scopeSystemEntityModelStore.useGetCodeOrganization(Contract.CodeTenantType.GitHub);
    const gitLabOrganizationModels = scopeSystemEntityModelStore.useGetCodeOrganization(Contract.CodeTenantType.GitLab);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeOrganizationItems",
            () => ({
                description: "{{count | NumberFormatter.humanize}} configured",
                [Contract.TypeNames.CodeTenantType]: {
                    [Contract.CodeTenantType.AzureDevOps]: "Azure DevOps Organization",
                    [Contract.CodeTenantType.Bitbucket]: "Bitbucket Workspace",
                    [Contract.CodeTenantType.GitHub]: "GitHub Organization",
                    [Contract.CodeTenantType.GitLab]: "GitLab Group"
                }
            }));

    return [
        new IntegrationItem(
            IntegrationItemCategory.CodeOrganizations,
            azureDevOpsOrganizationModels,
            [localization.description({ count: azureDevOpsOrganizationModels.length })],
            <TenantIcon
                data={{ codeTenantType: Contract.CodeTenantType.AzureDevOps }}
                tenantType={Contract.TenantType.Code}/>,
            localization[Contract.TypeNames.CodeTenantType][Contract.CodeTenantType.AzureDevOps](),
            Contract.CodeTenantType.AzureDevOps,
            <AzureDevOps/>
        ),
        new IntegrationItem(
            IntegrationItemCategory.CodeOrganizations,
            bitbucketOrganizationModels,
            [localization.description({ count: bitbucketOrganizationModels.length })],
            <TenantIcon
                data={{ codeTenantType: Contract.CodeTenantType.Bitbucket }}
                tenantType={Contract.TenantType.Code}/>,
            localization[Contract.TypeNames.CodeTenantType][Contract.CodeTenantType.Bitbucket](),
            Contract.CodeTenantType.Bitbucket,
            <Bitbucket/>
        ),
        new IntegrationItem(
            IntegrationItemCategory.CodeOrganizations,
            gitHubOrganizationModels,
            [localization.description({ count: gitHubOrganizationModels.length })],
            <TenantIcon
                data={{ codeTenantType: Contract.CodeTenantType.GitHub }}
                tenantType={Contract.TenantType.Code}/>,
            localization[Contract.TypeNames.CodeTenantType][Contract.CodeTenantType.GitHub](),
            Contract.CodeTenantType.GitHub,
            <GitHub/>
        ),
        new IntegrationItem(
            IntegrationItemCategory.CodeOrganizations,
            gitLabOrganizationModels,
            [localization.description({ count: gitLabOrganizationModels.length })],
            <TenantIcon
                data={{ codeTenantType: Contract.CodeTenantType.GitLab }}
                tenantType={Contract.TenantType.Code}/>,
            localization[Contract.TypeNames.CodeTenantType][Contract.CodeTenantType.GitLab](),
            Contract.CodeTenantType.GitLab,
            <GitLab/>
        )
    ];
}

export type CodeOrganizationQueryParameters = {
    addOrEditOpen?: string;
};