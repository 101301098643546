﻿import { useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { useEntityTypeNameTranslator } from "../../../../../../../hooks";
import { useTheme } from "../../../../../../../themes";

type AllProps = {
    entityTypeName: string;
    includeServiceName: boolean;
};

export function All({ entityTypeName, includeServiceName }: AllProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.entityMultiSelect.condition.all",
            () => ({
                title: "All {{translatedEntityTypeName}}"
            }));

    const theme = useTheme();
    return (
        <Typography sx={{ margin: theme.spacing(0.5, 0) }}>
            {localization.title({
                translatedEntityTypeName:
                    entityTypeNameTranslator(
                        entityTypeName,
                        {
                            count: 0,
                            includeServiceName,
                            variant: "text"
                        })
            })}
        </Typography>);
}