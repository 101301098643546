import EventEmitter from "eventemitter3";
import _, { Dictionary } from "lodash";
import { defer, delay } from "q";
import { ApiError } from "@infrastructure";
import { Contract } from ".";
import { ConsoleAppUrlHelper, GeneralInformation, StorageHelper } from "../utilities";

export class ApiController {
    private static _consoleAppType?: Contract.ConsoleAppType;
    private static _eventEmitter = new EventEmitter();
    private static _scopeId?: string;
    private static _scopeIdChangedEventName = "ScopeIdChanged";

    public static initialize(consoleAppType: Contract.ConsoleAppType) {
        ApiController._consoleAppType = consoleAppType;
    }

    public static clearScopeId() {
        StorageHelper.
            apiControllerScopeId(ApiController._consoleAppType!).
            removeValue();
        ApiController._scopeId = undefined;
    }

    public static get eventEmitter() {
        return this._eventEmitter;
    }

    public static get scopeIdChangedEventName() {
        return this._scopeIdChangedEventName;
    }

    public static get scopeId() {
        if (_.isNil(ApiController._scopeId)) {
            const selectedScopeId =
                StorageHelper.
                    apiControllerScopeId(ApiController._consoleAppType!).
                    getValue();
            ApiController._scopeId =
                _.isNil(selectedScopeId)
                    ? ""
                    : selectedScopeId;
        }

        return ApiController._scopeId!;
    }

    public static set scopeId(scopeId: string) {
        ApiController._scopeId = scopeId;
        StorageHelper.apiControllerScopeId(ApiController._consoleAppType!).setValue(scopeId);
        ApiController.eventEmitter.emit(ApiController.scopeIdChangedEventName);
    }

    public static executeFetch(url: string, options: RequestInit) {
        return fetch(
            url,
            {
                ...options,
                headers: {
                    ...options.headers,
                    Accept: "application/json",
                    ConsoleAppType: ApiController._consoleAppType!,
                    "Content-Type": "application/json",
                    ...(_.isNil(ApiController._consoleAppType)
                        ? {}
                        : { ScopeId: ApiController.scopeId }),
                    Version: GeneralInformation.assemblyFileVersion,
                    "X-Requested-With": "XMLHttpRequest"
                }
            });
    }

    protected static formatUrlParameters(urlTemplate: string, parameterMap: Dictionary<any>) {
        const url =
            urlTemplate.replace(
                /\{(\w+)\}/g,
                (match, parameterName) => {
                    const parameterValue = parameterMap[parameterName];
                    delete parameterMap[parameterName];
                    return encodeURIComponent(parameterValue);
                });
        const urlParameterMap =
            _.pickBy(
                parameterMap,
                parameterValue => !_.isNil(parameterValue));
        if (_.isEmpty(urlParameterMap)) {
            return url;
        }

        return _(urlParameterMap).
            map((parameterValue, parameterName) => `${parameterName}=${encodeURIComponent(parameterValue)}`).
            join("&").
            replace(/.*/, queryString => `${url}?${queryString}`);
    }

    protected static getRequest<TResponse>(url: string): Promise<TResponse> {
        return ApiController.executeOperation(
            url,
            {
                method: "GET"
            },
            2);
    }

    protected static postRequest<TRequest, TResponse>(url: string, request: TRequest): Promise<TResponse> {
        return ApiController.executeOperation(
            url,
            {
                body: JSON.stringify(request),
                method: "POST"
            });
    }

    private static async executeOperation<TResponse>(
        url: string,
        options: RequestInit,
        retryCount = 1): Promise<TResponse> {
        let retryIndex = 1;
        while (true) {
            try {
                const response = await ApiController.executeFetch(url, options);
                if (response.redirected) {
                    window.location.assign(response.url);
                    await defer<TResponse>().promise;
                }

                if (response.status === 401 || response.status === 403) {
                    window.location.assign(
                        ConsoleAppUrlHelper.getUnauthorizedPageRelativeUrl(
                            ApiController._consoleAppType!,
                            ConsoleAppUrlHelper.getConsoleAppRelativeUrl(window.location.pathname + window.location.search + window.location.hash)));
                }

                if (response.status === 406) {
                    window.location.reload();
                }

                if (response.status >= 400) {
                    const errorCode = await response.text();
                    throw new ApiError(
                        errorCode ?? response.statusText,
                        response.headers.get("request-id") ?? undefined,
                        response.status);
                }

                const contentType = response.headers.get("Content-Type") ?? "";
                if (contentType.indexOf("application/json") !== -1) {
                    return response.json();
                }

                return undefined!;
            } catch (error) {
                if (error instanceof ApiError ||
                    retryIndex === retryCount) {
                    throw error;
                }

                await delay(Math.pow(2, retryIndex) * 1000);
                retryIndex++;
            }
        }
    }
}