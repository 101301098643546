import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../../..";
import { Contract, InfoItem } from "../../../../../../../../../../../common";
import { useAwsEcsTaskDefinitionStatusTranslator } from "../../../../../../../hooks";
import { VulnerabilitiesCell } from "../../../../../../VulnerabilitiesCell";
import { EntitiesInfoItem, Info } from "../../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { useAwsDefaultResourceInfoItemElements } from "../useAwsDefaultResourceInfoItemElements";
import { ContainerDefinitions } from "./components";

export function useAwsEcsTaskDefinitionDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const taskDefinitionModel = resourceModel as Contract.AwsEcsTaskDefinitionModel;
    const taskDefinition = taskDefinitionModel.entity as Contract.AwsEcsTaskDefinition;
    const latestRevision =
        _.isNil(taskDefinition.latestRevisionId)
            ? undefined
            : taskDefinition.revisionMap[taskDefinition!.latestRevisionId];

    const ecsTaskDefinitionStatusTranslator = useAwsEcsTaskDefinitionStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsEcsTaskDefinitionDefinition",
            () => ({
                info: {
                    items: {
                        containerImages: "Container Images",
                        containerImageVulnerabilities: "Vulnerabilities",
                        executionRole: "Task Execution Role",
                        networkInterfaceIds: "Network Interfaces",
                        networkMode: {
                            [Contract.TypeNames.AwsEcsTaskDefinitionRevisionNetworkMode]: {
                                [Contract.AwsEcsTaskDefinitionRevisionNetworkMode.Bridge]: "Bridge",
                                [Contract.AwsEcsTaskDefinitionRevisionNetworkMode.Default]: "Default",
                                [Contract.AwsEcsTaskDefinitionRevisionNetworkMode.Host]: "Host",
                                [Contract.AwsEcsTaskDefinitionRevisionNetworkMode.None]: "None",
                                [Contract.AwsEcsTaskDefinitionRevisionNetworkMode.Vpc]: "AwsVpc"
                            },
                            title: "Network Mode"
                        },
                        roleReference: "Task Role",
                        serviceIds: "ECS Services",
                        status: "Status"
                    }
                },
                tabs: {
                    containers: "Containers"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs:
            _<EntityProfileDefinitionTab>([]).
                concatIf(
                    !_.isNil(latestRevision),
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Overview,
                        <ContainerDefinitions taskDefinitionModel={taskDefinitionModel}/>,
                        localization.tabs.containers(),
                        "containers")).
                value(),
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={ecsTaskDefinitionStatusTranslator(taskDefinition.status)}/>,
                    <InfoItem
                        key="networkMode"
                        title={localization.info.items.networkMode.title()}
                        value={
                            _.isNil(latestRevision)
                                ? undefined
                                : localization.info.items.networkMode[Contract.TypeNames.AwsEcsTaskDefinitionRevisionNetworkMode][latestRevision.networkMode]()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={latestRevision?.executionRoleReference?.idReference}
                        entityTypeName={Contract.TypeNames.AwsIamRole}
                        key="executionRole"
                        title={localization.info.items.executionRole()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={latestRevision?.roleReference?.idReference}
                        entityTypeName={Contract.TypeNames.AwsIamRole}
                        key="roleReference"
                        title={localization.info.items.roleReference()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={taskDefinitionModel.containerImageIdReferences}
                        entityTypeName={Contract.TypeNames.IContainerImage}
                        key="containerImages"
                        title={localization.info.items.containerImages()}/>,
                    <InfoItem
                        key="taskDefinitionContainerImageVulnerabilities"
                        title={localization.info.items.containerImageVulnerabilities()}
                        value={
                            <VulnerabilitiesCell
                                containerImageScanStatus={taskDefinitionModel.containerImageScanStatus}
                                variant="initialAndCount"
                                vulnerabilities={taskDefinitionModel.containerImageVulnerabilities}/>}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={taskDefinitionModel.serviceIds}
                        entityTypeName={Contract.TypeNames.AwsEcsService}
                        key="servicesIds"
                        title={localization.info.items.serviceIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={taskDefinitionModel.networkInterfaceIds}
                        entityTypeName={Contract.TypeNames.AwsEc2NetworkInterface}
                        key="networkInterfaceIds"
                        title={localization.info.items.networkInterfaceIds()}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}