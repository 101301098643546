﻿import { ListItem, ListItemButton } from "@mui/material";
import React, { ReactNode } from "react";
import { useTheme } from "../../../../../themes";

type ChangeListItemProps = {
    children: ReactNode;
    disabled: boolean;
    onClick: () => void;
    selected: boolean;
};

export function ChangeListItem({ children, disabled, onClick, selected }: ChangeListItemProps) {
    const theme = useTheme();
    return disabled
        ? <ListItem sx={{ position: "relative" }}>{children}</ListItem>
        : <ListItemButton
            selected={selected}
            sx={{
                "&.Mui-selected": {
                    "&:hover": {
                        backgroundColor: theme.palette.action.hover
                    },
                    backgroundColor: theme.palette.action.hover,
                    color: "unset",
                    fontWeight: "unset"
                },
                position: "relative"
            }}
            onClick={onClick}>
            {children}
        </ListItemButton>;
}