import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, entityModelStore, TypeHelper, useEntityTypeNameTranslator } from "../../../../../../..";
import { EditItem } from "../../../../../components";
import { useRiskResolutionAutomationContext } from "../../../../../utilities";
import { GcpRiskResolutionAutomationContext } from "../useGcpDefinition";

export function GcpEditItem() {
    const {
        changeModels,
        initialStoredGcpAccessToken,
        isChangeResolvable,
        someChangeableSomeNotChangeable
    } = useRiskResolutionAutomationContext() as GcpRiskResolutionAutomationContext;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();

    const localization =
        useLocalization(
            "common.riskResolutionAutomation.hooks.useDefinition.hooks.useGcpDefinition.gcpEditItem",
            () => ({
                changeNotChangeable: "Tenable Cloud Security does not have permissions on {{translatedEntityTypeName}} {{entity}}.",
                changeNotResolvable: {
                    gciChange: "This step must be performed manually by a Google Workspace admin as Tenable Cloud Security does not have permissions to manage Google Workspace groups.",
                    gcpChange: "Automatic remediation for {{translatedEntityTypeName}} is not yet supported."
                },
                notChangeable: {
                    all: "Tenable Cloud Security does not have permissions to perform the remediation steps. To allow Tenable Cloud Security to perform the steps, provide one-time credentials when prompted.",
                    some: "Tenable Cloud Security has permissions to perform some, but not all, of the remediation steps. To allow Tenable Cloud Security to perform all steps, provide one-time credentials when prompted."
                }
            }));

    const notChangeableMessage =
        useMemo(
            () =>
                _.some(
                    changeModels,
                    changeModel =>
                        isChangeResolvable(changeModel) &&
                        !changeModel.changeable) &&
                !initialStoredGcpAccessToken
                    ? someChangeableSomeNotChangeable
                        ? localization.notChangeable.some()
                        : localization.notChangeable.all()
                    : undefined,
            [changeModels, initialStoredGcpAccessToken]);

    return (
        <EditItem
            getChangeNotChangeableMessage={
                (changeModel: Contract.ChangeModel) => {
                    const scopeResourceModel =
                        TypeHelper.extendOrImplement(
                            changeModel.change.typeName,
                            Contract.TypeNames.GcpDeleteScopeResourcePrincipalRoleBindingsChange,
                            Contract.TypeNames.GcpDeleteServiceAccountChange,
                            Contract.TypeNames.GcpDisableServiceAccountChange)
                            ? entityModelStore.useGet((changeModel.change as Contract.GcpDeleteScopeResourcePrincipalRoleBindingsChange | Contract.GcpDeleteServiceAccountChange | Contract.GcpDisableServiceAccountChange).resourceId)
                            : entityModelStore.useGet((changeModel.change as Contract.GcpCreateRoleBindingChange | Contract.GcpDeleteRoleBindingChange).scopeResourceId);

                    return localization.changeNotChangeable({
                        entity:
                            <Entity
                                entityIdOrModel={scopeResourceModel}
                                linkOptions={{ disabled: true }}
                                variant="text"/>,
                        translatedEntityTypeName:
                            entityTypeNameTranslator(
                                scopeResourceModel.entity.typeName,
                                { variant: "text" })
                    });
                }}
            getChangeNotResolvableMessage={
                (changeModel: Contract.ChangeModel) =>
                    TypeHelper.extendOrImplement(changeModel.change.typeName, Contract.TypeNames.GciChange)
                        ? localization.changeNotResolvable.gciChange()
                        : localization.changeNotResolvable.gcpChange({
                            translatedEntityTypeName:
                                entityTypeNameTranslator(
                                    entityModelStore.useGet((changeModel.change as Contract.GcpCreateRoleBindingChange | Contract.GcpDeleteRoleBindingChange).scopeResourceId).entity.typeName,
                                    { variant: "text" })
                        })}
            isChangeResolvable={isChangeResolvable}
            nextDisabled={
                _.every(
                    changeModels,
                    changeModel => !isChangeResolvable(changeModel))}
            notChangeableMessage={notChangeableMessage}/>);
}