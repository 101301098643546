import { Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Fragment, useRef, useState } from "react";
import { CheckboxField, DataTableActions, ItemSelector, Optional, UnexpectedError, useLocalization } from "@infrastructure";
import { Contract, ReportItem } from "../../../../../../../../../../common";
import { WorkloadResourceModelRequestFiltersHelper } from "../../../../../../../WorkloadAnalysis/utilities";
import { useVulnerabilityReportTypeTranslator } from "../../../../../../hooks/useVulnerabilityReportTypeTranslator";
import { ContainerImageVulnerabilityReportFilters, VirtualMachineVulnerabilityReportFilters } from "./components";

type VulnerabilityReportItemProps = {
    filters: Optional<Contract.WorkloadControllerWorkloadResourceModelFilters>;
    includeDescription: boolean;
    includeResolvedVulnerabilities: boolean;
    vulnerabilityReportType: Optional<Contract.VulnerabilityReportType>;
};

export function useGetWorkloadResourcePackageVulnerabilitiesReportItem(vulnerabilityReportItemProps: VulnerabilityReportItemProps) {
    const dataTableActionsRef = useRef<DataTableActions>();
    const [vulnerabilityReportType, setVulnerabilityReportType] = useState(vulnerabilityReportItemProps.vulnerabilityReportType);
    const [vulnerabilityIncludeDescription, setVulnerabilityIncludeDescription] = useState(vulnerabilityReportItemProps.includeDescription);
    const [vulnerabilityIncludeResolvedVulnerabilities, setVulnerabilityIncludeResolvedVulnerabilities] = useState(vulnerabilityReportItemProps.includeResolvedVulnerabilities);
    const [filterMap, setFilterMap] =
        useState<Dictionary<unknown>>(() =>
            _.isNil(vulnerabilityReportType)
                ? { [Contract.WorkloadControllerRequestProperty.ContainerImageWorkloadExists]: true }
                : (() => {
                    switch (vulnerabilityReportType) {
                        case Contract.VulnerabilityReportType.ContainerImages:
                            return WorkloadResourceModelRequestFiltersHelper.toFilterMapFromContainerImageFilters(vulnerabilityReportItemProps.filters as Contract.WorkloadControllerContainerImageModelFilters);
                        case Contract.VulnerabilityReportType.VirtualMachines:
                            return WorkloadResourceModelRequestFiltersHelper.toFilterMapFromVirtualMachineFilters(vulnerabilityReportItemProps.filters as Contract.WorkloadControllerVirtualMachineModelFilters);
                        default:
                            throw new UnexpectedError("vulnerabilityReportType", vulnerabilityReportItemProps.vulnerabilityReportType);
                    }
                })());

    const vulnerabilityReportTypeTranslator = useVulnerabilityReportTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.reports.addOrEdit.hooks.useItems.hooks.useGetVulnerabilitiesReportItem.useGetWorkloadResourcePackageVulnerabilitiesReportItem",
            () => ({
                vulnerability: {
                    containerImage: {
                        unused: "Include unused images"
                    },
                    description: "Include vulnerability description (will increase report size)",
                    resolvedVulnerabilities: "Include vulnerabilities resolved in last 30 days"
                },
                vulnerabilityReportType: "Scanned Resource Type"
            }));

    return (vulnerabilityReportTypes: Contract.VulnerabilityReportType[]): ReportItem => ({
        configuration:
            <Stack spacing={3}>
                <Stack spacing={1}>
                    <Typography variant="h4">
                        {localization.vulnerabilityReportType()}
                    </Typography>
                    <ItemSelector
                        fullWidth={true}
                        getItemText={vulnerabilityReportTypeTranslator}
                        items={vulnerabilityReportTypes}
                        placeholder={localization.vulnerabilityReportType()}
                        selectedItem={vulnerabilityReportType}
                        sorted={true}
                        onSelectedItemChanged={setVulnerabilityReportType}>
                        {vulnerabilityReportTypeTranslator}
                    </ItemSelector>
                </Stack>
                <CheckboxField
                    checked={vulnerabilityIncludeDescription}
                    onChange={() => setVulnerabilityIncludeDescription(!vulnerabilityIncludeDescription)}>
                    <Typography variant="h5">
                        {localization.vulnerability.description()}
                    </Typography>
                </CheckboxField>
                {vulnerabilityReportType == Contract.VulnerabilityReportType.VirtualMachines &&
                    <Fragment>
                        <CheckboxField
                            checked={vulnerabilityIncludeResolvedVulnerabilities}
                            onChange={() => setVulnerabilityIncludeResolvedVulnerabilities(!vulnerabilityIncludeResolvedVulnerabilities)}>
                            <Typography variant="h5">
                                {localization.vulnerability.resolvedVulnerabilities()}
                            </Typography>
                        </CheckboxField>
                        <VirtualMachineVulnerabilityReportFilters
                            dataTableActionsRef={dataTableActionsRef}
                            filterMap={filterMap}
                            setFilterMap={setFilterMap}/>
                    </Fragment>}
                {vulnerabilityReportType == Contract.VulnerabilityReportType.ContainerImages &&
                    <ContainerImageVulnerabilityReportFilters
                        dataTableActionsRef={dataTableActionsRef}
                        filterMap={filterMap}
                        setFilterMap={setFilterMap}/>}
            </Stack>,
        generate:
            name =>
                vulnerabilityReportType === Contract.VulnerabilityReportType.VirtualMachines
                    ? new Contract.ReportControllerVirtualMachinePackageVulnerabilitiesReportRequestDefinition(
                        name,
                        undefined,
                        Contract.TypeNames.ReportControllerVirtualMachinePackageVulnerabilitiesReportRequestDefinition,
                        !_.isNil(filterMap),
                        WorkloadResourceModelRequestFiltersHelper.getVirtualMachineFilters(
                            filterMap ?? {},
                            dataTableActionsRef),
                        vulnerabilityIncludeDescription,
                        vulnerabilityIncludeResolvedVulnerabilities)
                    : new Contract.ReportControllerContainerImagePackageVulnerabilitiesReportRequestDefinition(
                        name,
                        undefined,
                        Contract.TypeNames.ReportControllerContainerImagePackageVulnerabilitiesReportRequestDefinition,
                        !_.isNil(filterMap),
                        WorkloadResourceModelRequestFiltersHelper.getContainerImageFilters(
                            filterMap ?? {},
                            dataTableActionsRef),
                        vulnerabilityIncludeDescription),
        notValid:
            () => _.isNil(vulnerabilityReportType)
    });
}