import { Store } from "@infrastructure";
import { Contract, EntityController } from "..";

export const entitySearchableReferenceStore =
    new Store<Contract.EntitySearchableReference, never, never>(
        entitySearchableReference => entitySearchableReference.id,
        {
            get:
                async ids => {
                    const { entitySearchableReferences } = await EntityController.getEntitySearchableReferences(new Contract.EntityControllerGetEntitySearchableReferencesRequest(ids));
                    return entitySearchableReferences;
                }
        });