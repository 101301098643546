import { InlineItems, map, Step, useChangeEffect, useExecuteOperation, useInputValidation, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { Box, FormControl, FormHelperText, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { AwsResourceArnParser, Contract, TenantController, tenantModelStore, useTheme } from "../../../../../../../../../../../../common";
import { AwsCloudFormationTrail } from "../../../../../../../../../../../../tenants";
import { useAddOrEditContext, useSetAddOrEditContext } from "../../index";
import { CloudFormationCli, CloudFormationConsole, LinkButton, Manual } from "./components";

export function RoleItem() {
    const setAddOrEditContext = useSetAddOrEditContext();

    const { executing, setError, setLoaded, setValid, useNextEffect } = useOrderedWizardContext();
    const {
        parentFolderId,
        tenantModel,
        tenantName,
        tenantPartitionType,
        tenantPermissionTypes,
        tenantRegionSystemNames,
        tenantRoleArn,
        tenantTerraformStateBucketEnabled,
        tenantTrailBucket,
        tenantTrailBucketEnabled
    } = useAddOrEditContext();

    const [roleArn, setRoleArn] = useState(tenantRoleArn ?? "");

    const tenantConfiguration = tenantModel?.configuration;
    const tenantModels = tenantModelStore.useGetPermittedAwsTenants();
    const existingTenantConfigurations =
        useMemo(
            () =>
                _(tenantModels).
                    filter(tenantModel => tenantModel.configuration.id !== tenantConfiguration?.id).
                    map(tenantModel => tenantModel.configuration as Contract.AwsTenantConfiguration).
                    value(),
            [tenantModels]);

    const [{ tenantOnboardingPoliciesInfo, terraformStateBucketNameToEncryptionKeyArnsMap, trailBucketNameToEncryptionKeyArnsMap }, executeGetOnboardingPoliciesInfo] =
        useExecuteOperation(
            [RoleItem, tenantModel?.configuration.id],
            async () => {
                const trailBucketNameToEncryptionKeyArnsMap =
                    tenantTrailBucketEnabled
                        ? {
                            ...tenantModel?.state.eventAnalysis.trailBucketNameToEncryptionKeyArnsMap,
                            ...(_.isNil(tenantTrailBucket)
                                ? undefined
                                : {
                                    [tenantTrailBucket.name]:
                                        _([tenantTrailBucket.encryptionKeyArn]).
                                            concat(tenantModel?.state.eventAnalysis.trailBucketNameToEncryptionKeyArnsMap[tenantTrailBucket.name]).
                                            filter().
                                            uniq().
                                            value() as string[]
                                })
                        }
                        : {};
                const terraformStateBucketNameToEncryptionKeyArnsMap =
                    tenantTerraformStateBucketEnabled
                        ? tenantModel?.state.codeAnalysis.terraformStateBucketNameToEncryptionKeyArnsMap ?? {}
                        : {};

                const tenantOnboardingPoliciesInfo =
                    await TenantController.getAwsTenantOnboardingPoliciesInfo(
                        new Contract.TenantControllerGetAwsTenantOnboardingPoliciesInfoRequest(
                            tenantPartitionType,
                            terraformStateBucketNameToEncryptionKeyArnsMap,
                            trailBucketNameToEncryptionKeyArnsMap));

                return { tenantOnboardingPoliciesInfo, terraformStateBucketNameToEncryptionKeyArnsMap, trailBucketNameToEncryptionKeyArnsMap };
            });

    useChangeEffect(
        executeGetOnboardingPoliciesInfo,
        [tenantTrailBucket]);

    const [cloudFormationTrail, onboardingTypes] =
        useMemo(
            () => {
                const onboardingTypes =
                    tenantModel?.configuration.tenantManagement ||
                    _.size(trailBucketNameToEncryptionKeyArnsMap) > 1 ||
                    !_.isEmpty(terraformStateBucketNameToEncryptionKeyArnsMap)
                        ? [OnboardingType.Manual]
                        : [
                            OnboardingType.CloudFormationConsole,
                            OnboardingType.CloudFormationCli,
                            OnboardingType.Manual
                        ];

                const trailBucketNameToEncryptionKeyArns =
                    _(trailBucketNameToEncryptionKeyArnsMap).
                        toPairs().
                        first();
                const cloudFormationTrail =
                    _.isNil(trailBucketNameToEncryptionKeyArns)
                        ? undefined
                        : new AwsCloudFormationTrail(
                            trailBucketNameToEncryptionKeyArns[0],
                            trailBucketNameToEncryptionKeyArns[1]);

                return [cloudFormationTrail, onboardingTypes];
            },
            [trailBucketNameToEncryptionKeyArnsMap]);

    useEffect(
        () => {
            setLoaded();
        },
        []);

    const [roleArnValidationController, roleArnValidationMessage] =
        useInputValidation(
            () => {
                const validationRoleArn = roleArn?.trim();
                const validationTenantRawId = AwsResourceArnParser.tryGetTenantId(validationRoleArn);
                if (_.isEmpty(validationRoleArn) || !AwsResourceArnParser.validateIamRole(validationRoleArn)) {
                    return localization.fields.roleArn.error.invalid();
                }

                if (_.find(existingTenantConfigurations, existingTenantConfiguration => existingTenantConfiguration.rawId === validationTenantRawId)) {
                    return localization.fields.roleArn.error.tenantExists();
                }

                return undefined;
            },
            [roleArn]);

    useEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    tenantRoleArn: roleArn?.trim()
                }));
            setValid(roleArnValidationController.isValid());
        },
        [roleArn]);

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAwsDefinition.addOrEdit.roleItem",
            () => ({
                actions: {
                    save: {
                        error: {
                            add: "Failed to add account",
                            edit: "Failed to save account"
                        }
                    },
                    useValidRegions: {
                        link: "Click here",
                        text: " {{useValidRegionsLink}} to use the valid {{validRegionSystemNames}}."
                    },
                    validate: {
                        error: "Failed to connect. Verify that role {{roleArn}} is properly configured",
                        [Contract.TypeNames.AwsTenantManagerValidateTenantResult]: {
                            [Contract.AwsTenantManagerValidateTenantResult.RegionSystemNamesMissing]: "No active regions selected: Please select all of the AWS regions used by your organization.",
                            [Contract.AwsTenantManagerValidateTenantResult.RegionValidationFailure]: "**Access denied**: Failed to query {{failedRegionSystemNames}}.{{useValidRegions}}\nPlease ensure {{roleArn}} has permission to query the selected active regions.\nIf your organization isn't using some of these regions and you would like to ignore them, update the region selector accordingly.",
                            [Contract.AwsTenantManagerValidateTenantResult.RoleAssumeFailure]: "**Missing permissions**: Failed to assume role {{roleArn}}.\nPlease ensure the IAM Role's trust policy is configured properly.",
                            [Contract.AwsTenantManagerValidateTenantResult.RoleReadOnlyPolicyDocumentAccessDenied]: "**Access denied**: {{readOnlyPolicyDocumentAccessDeniedErrorMessage}}.\nPlease ensure you granted the required read-only permissions (Step 2).\nIn case of an explicit deny, ensure {{roleArn}} is excluded from your SCP(s).",
                            [Contract.AwsTenantManagerValidateTenantResult.RoleReadOnlyPolicyDocumentMismatch]: "**Missing permissions**: Role {{roleArn}} is missing {{readOnlyPolicyDocumentMissingActions}}.\nPlease ensure you granted the required read-only permissions (Step 2).",
                            [Contract.AwsTenantManagerValidateTenantResult.TrailsAccessDenied]: "**Access denied**: {{trailsAccessDeniedErrorMessage}}.\nPlease ensure you granted the required read-only permissions (Step 2).\nIn case of an explicit deny, ensure {{roleArn}} is excluded from your SCP(s)."
                        }
                    }
                },
                fields: {
                    readOnlyPolicyDocumentMissingActions: [
                        "1 permission",
                        "{{count | NumberFormatter.humanize}} permissions"
                    ],
                    regionSystemNames:
                        {
                            error: {
                                empty: "Active Regions cannot be empty"
                            },
                            some: [
                                "1 Region",
                                "{{count | NumberFormatter.humanize}} Regions"
                            ],
                            title: "Active Regions"
                        },
                    roleArn: {
                        error: {
                            invalid: "Invalid Role ARN",
                            tenantExists: "Account already exists"
                        },
                        step: "Paste the IAM Role ARN",
                        title: "Role ARN"
                    }
                },
                onboardingType: {
                    [OnboardingType.CloudFormationCli]: "CloudFormation (AWS CLI)",
                    [OnboardingType.CloudFormationConsole]: "CloudFormation (AWS Console)",
                    [OnboardingType.Manual]: "Manual (AWS Console)"
                }
            }));

    useNextEffect(
        async () => {
            const roleArnValue = roleArn.trim();
            try {
                const { failedRegionSystemNames, readOnlyPolicyDocumentAccessDeniedErrorMessage, readOnlyPolicyDocumentMissingActions, result, trailsAccessDeniedErrorMessage, validRegionSystemNames, validTrails } =
                    await TenantController.validateAwsTenant(
                        new Contract.TenantControllerValidateAwsTenantRequest(
                            parentFolderId,
                            tenantPermissionTypes,
                            tenantRegionSystemNames,
                            roleArnValue,
                            tenantTrailBucket));

                if (result !== Contract.AwsTenantManagerValidateTenantResult.Valid) {
                    return (
                        <Typography
                            sx={{
                                color: "unset",
                                whiteSpace: "pre-wrap"
                            }}>
                            {localization.actions.validate[Contract.TypeNames.AwsTenantManagerValidateTenantResult][result]({
                                failedRegionSystemNames:
                                    _.isEmpty(failedRegionSystemNames)
                                        ? undefined
                                        : <InlineItems
                                            items={failedRegionSystemNames!.sort()}
                                            namePluralizer={localization.fields.regionSystemNames.some}
                                            sx={{
                                                color: "unset",
                                                fontWeight: 600,
                                                textDecoration: "underline"
                                            }}
                                            variant="itemCountAndType"/>,
                                readOnlyPolicyDocumentAccessDeniedErrorMessage,
                                readOnlyPolicyDocumentMissingActions:
                                    _.isEmpty(readOnlyPolicyDocumentMissingActions)
                                        ? undefined
                                        : <InlineItems
                                            items={readOnlyPolicyDocumentMissingActions!.sort()}
                                            namePluralizer={localization.fields.readOnlyPolicyDocumentMissingActions}
                                            sx={{
                                                color: "unset",
                                                fontWeight: 600,
                                                textDecoration: "underline"
                                            }}
                                            variant="itemCountAndType"/>,
                                roleArn: roleArnValue,
                                trailsAccessDeniedErrorMessage,
                                useValidRegions:
                                    _.isEmpty(validRegionSystemNames)
                                        ? ""
                                        : localization.actions.useValidRegions.text({
                                            useValidRegionsLink:
                                                <LinkButton
                                                    sx={{ display: "inline" }}
                                                    title={localization.actions.useValidRegions.link()}
                                                    onClick={
                                                        () => {
                                                            setAddOrEditContext(
                                                                addOrEditContext => ({
                                                                    ...addOrEditContext,
                                                                    tenantRegionSystemNames: validRegionSystemNames
                                                                }));
                                                            setError(undefined);
                                                        }}/>,
                                            validRegionSystemNames:
                                                <InlineItems
                                                    items={validRegionSystemNames!.sort()}
                                                    namePluralizer={localization.fields.regionSystemNames.some}
                                                    sx={{
                                                        color: "unset",
                                                        fontWeight: 600,
                                                        textDecoration: "underline"
                                                    }}
                                                    variant="itemCountAndType"/>
                                        })
                            })}
                        </Typography>);
                }

                setAddOrEditContext(
                    addOrEditContext => ({
                        ...addOrEditContext,
                        tenantRawId: AwsResourceArnParser.tryGetTenantId(roleArn),
                        tenantValidTrails: validTrails
                    }));
            } catch {
                return localization.actions.validate.error({ roleArn: roleArnValue });
            }

            if (!_.includes(tenantPermissionTypes, Contract.CloudProviderTenantPermissionType.Read)) {
                try {
                    let updatedTenantModel: Contract.AwsTenantModel;
                    if (!_.isNil(tenantModel)) {
                        const { tenantModel } =
                            await TenantController.updateAwsTenant(
                                new Contract.TenantControllerUpdateAwsTenantRequest(
                                    tenantName!,
                                    tenantPermissionTypes,
                                    tenantRegionSystemNames,
                                    roleArn!,
                                    undefined,
                                    tenantTrailBucket));
                        updatedTenantModel = tenantModel;
                    } else {
                        const { tenantModel } =
                            await TenantController.insertAwsTenant(
                                new Contract.TenantControllerInsertAwsTenantRequest(
                                    tenantName!,
                                    parentFolderId,
                                    tenantPermissionTypes,
                                    tenantRegionSystemNames,
                                    roleArnValue,
                                    undefined,
                                    tenantTrailBucket));
                        updatedTenantModel = tenantModel;
                    }

                    setAddOrEditContext(
                        addOrEditContext => ({
                            ...addOrEditContext,
                            updatedTenantModel
                        }));
                } catch {
                    return (
                        _.isNil(tenantModel)
                            ? localization.actions.save.error.add()
                            : localization.actions.save.error.edit());
                }
            }

            return undefined;
        },
        [roleArn, tenantRegionSystemNames]);

    const theme = useTheme();
    const roleArnStep =
        new Step(
            localization.fields.roleArn.step(),
            {
                contentElement:
                    <FormControl
                        fullWidth={true}
                        variant="standard">
                        <TextField
                            disabled={executing}
                            label={localization.fields.roleArn.title()}
                            sx={{ marginTop: theme.spacing(3) }}
                            value={roleArn}
                            variant="outlined"
                            onChange={event => setRoleArn(event.target.value)}/>
                        {!_.isNil(roleArnValidationMessage) &&
                            <FormHelperText error={true}>{roleArnValidationMessage}</FormHelperText>}
                    </FormControl>
            });

    const [selectedOnboardingType, setSelectedOnboardingType] = useState(onboardingTypes[0]);
    return (
        <Stack
            spacing={2}
            sx={{ height: "100%" }}>
            <Stack
                spacing={2}
                sx={{ height: "100%" }}>
                <Tabs
                    indicatorColor="primary"
                    value={selectedOnboardingType}
                    variant={
                        _.size(onboardingTypes) > 1
                            ? "fullWidth"
                            : "standard"}
                    onChange={(_, value) => setSelectedOnboardingType(value)}>
                    {_.map(
                        onboardingTypes,
                        onboardingType =>
                            <Tab
                                key={onboardingType}
                                label={localization.onboardingType[onboardingType]()}
                                sx={{ padding: theme.spacing(1) }}
                                value={onboardingType}/>)}
                </Tabs>
                <Box
                    key={selectedOnboardingType}
                    sx={{
                        overflow: "hidden auto",
                        width: "100%"
                    }}>
                    <Box sx={{ maxWidth: theme.spacing(80) }}>
                        {map(
                            selectedOnboardingType,
                            {
                                [OnboardingType.CloudFormationCli]: () =>
                                    <CloudFormationCli
                                        roleArnStep={roleArnStep}
                                        tenantOnboardingPoliciesInfo={tenantOnboardingPoliciesInfo}
                                        trail={cloudFormationTrail}/>,
                                [OnboardingType.CloudFormationConsole]: () =>
                                    <CloudFormationConsole
                                        roleArnStep={roleArnStep}
                                        tenantOnboardingPoliciesInfo={tenantOnboardingPoliciesInfo}
                                        trail={cloudFormationTrail}/>,
                                [OnboardingType.Manual]: () =>
                                    <Manual
                                        roleArnStep={roleArnStep}
                                        tenantOnboardingPoliciesInfo={tenantOnboardingPoliciesInfo}/>
                            })}
                    </Box>
                </Box>
            </Stack>
        </Stack>);
}

enum OnboardingType {
    CloudFormationCli = "cloudFormationCli",
    CloudFormationConsole = "cloudFormationConsole",
    Manual = "manual"
}