﻿import { useLocalization } from "@infrastructure";
import { Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTheme } from "../../../../../../../../../common";
import { useAddOrEditContext, useSetAddOrEditContext } from "../../../AddOrEdit";

export function ExpirationTimeFrame() {
    const { permissionEligibilityData: { expirationTimeFrameHours }, upsertPermissionEligibilityExecuting } = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();

    const localization =
        useLocalization(
            "views.user.permissionEligibilities.addOrEdit.hooks.useDefinition.expirationTimeFrame",
            () => ({
                title: "Maximum duration",
                units: "Hours"
            }));

    const theme = useTheme();
    return (
        <Stack spacing={2}>
            <Typography variant="h4">
                {localization.title()}
            </Typography>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}>
                <TextField
                    disabled={upsertPermissionEligibilityExecuting}
                    slotProps={{
                        htmlInput: {
                            max: 24,
                            min: 1,
                            style: {
                                color: theme.palette.text.primary,
                                fontSize: "1.2rem",
                                padding: theme.spacing(1, 0),
                                textAlign: "center"
                            }
                        }
                    }}
                    sx={{
                        width: theme.spacing(8)
                    }}
                    type="number"
                    value={expirationTimeFrameHours}
                    variant="outlined"
                    onChange={
                        event => {
                            const value = _.parseInt(event.target.value);
                            if (value > 0 && value < 25) {
                                setAddOrEditContext(
                                    addOrEditContext => {
                                        addOrEditContext.permissionEligibilityData.expirationTimeFrameHours = value as number;
                                        return { ...addOrEditContext };
                                    });
                            }
                        }}/>
                <Typography style={{ fontSize: "1.2rem" }}>
                    {localization.units()}
                </Typography>
            </Stack>
        </Stack>);
}