import { map } from "@infrastructure";
import { Contract, RegionModelHelper, tenantModelStore } from "../../../../../../../../../../../../../../../common";
import { GcpConsoleUrlBuilder } from "../../../../../../../../../../../../../../../tenants";
import { EntityExternalConsoleLinkDefinition } from "../useDefinition";

export function useGcpContainerClusterDefinition(entityModel: Contract.EntityModel, page: Contract.GcpConsolePage) {
    const tenantModel = tenantModelStore.useGet(entityModel.entity.tenantId);
    const tenantConfiguration = tenantModel.configuration as Contract.GcpTenantConfiguration;

    const getUrl =
        map(
            page,
            {
                [Contract.GcpConsolePage.Permissions]: () => undefined,
                [Contract.GcpConsolePage.Resource]: () => GcpConsoleUrlBuilder.GetContainerClusterUrl
            });
    const cluster = entityModel.entity as Contract.GcpContainerCluster;
    return new EntityExternalConsoleLinkDefinition(
        getUrl?.(
            RegionModelHelper.get(cluster.regionId!).systemName,
            cluster.name,
            tenantConfiguration.rawShortNameId));
}