import React from "react";

export function AnalyzingImage() {
    return (
        <svg
            fill="none"
            height="275"
            viewBox="0 0 411 276"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M32.6589 267.253C57.0088 267.253 386.192 266.546 386.192 266.497C386.323 266.185 427.974 215.135 375.528 161.753C349.535 135.3 399.385 101.043 375.528 71.6325C334.649 21.2404 294.198 49.6157 260.499 35.4527C254.551 32.9553 248.571 28.9299 242.59 22.752C239.222 19.2852 235.541 16.2456 231.582 13.6168C230.974 13.206 230.366 12.8117 229.741 12.4338C227.375 10.9715 224.927 9.6406 222.397 8.44118C220.705 7.63609 218.98 6.89672 217.205 6.22307C214.412 5.13867 211.537 4.21857 208.612 3.42991C204.455 2.31264 200.167 1.47469 195.796 0.916058C193.89 0.669602 191.968 0.472439 190.029 0.324566C152.371 -2.50146 110.013 13.4196 88.1443 36.685C83.741 41.3677 63.7288 65.6189 79.502 98.9398C89.4588 119.987 47.7256 140.081 32.889 164.103C-4.80234 225.174 32.3139 266.448 32.6589 267.253Z"
                fill="#C3D9FF"
                fillOpacity="0.2"/>
            <path
                d="M410.504 268H0.512841C0.229894 268 0 267.827 0 267.613V267.387C0 267.173 0.229894 267 0.512841 267H410.486C410.769 267 410.999 267.173 410.999 267.387V267.613C411.017 267.827 410.787 268 410.504 268Z"
                fill="#9E9E9E"/>
            <path
                d="M271.762 123.117C255.706 107.223 234.367 98.4505 211.68 98.4505C210.415 98.4505 209.15 98.4847 207.857 98.5531C207.35 98.58 206.899 98.2279 206.809 97.7279L205.864 92.4958C205.703 91.6027 204.531 91.369 204.039 92.1318L189.905 114.062C189.603 114.53 189.742 115.154 190.213 115.45L212.064 129.189C212.796 129.649 213.727 129.026 213.582 128.174L212.92 124.306C212.814 123.688 213.296 123.126 213.922 123.149C229.165 123.696 243.457 129.851 254.308 140.611C265.702 151.912 272.021 166.98 272.125 183.029C272.229 198.975 266.117 214.043 254.877 225.499C243.69 236.904 228.773 243.303 212.768 243.612C212.405 243.612 211.991 243.612 211.629 243.612H54.3075C47.5103 243.612 42 249.123 42 255.92C42 262.717 47.5103 268.227 54.3075 268.227H211.68C212.198 268.227 212.768 268.227 213.286 268.227C235.765 267.815 256.846 258.732 272.591 242.735C288.337 226.686 296.987 205.425 296.883 182.978C296.728 160.272 287.819 139.063 271.762 123.117Z"
                fill="#CDDDF8"/>
            <path
                d="M281.751 135C291.477 148.97 296.764 165.532 296.883 182.978C296.987 205.425 288.337 226.686 272.591 242.735C256.846 258.732 235.765 267.815 213.286 268.227C212.768 268.227 212.198 268.227 211.68 268.227H54.3075C47.5103 268.227 42 262.717 42 255.92V255.92C42 249.123 47.5103 243.612 54.3075 243.612H80M275.881 127.5C259.779 109.127 236.122 98.4505 211.68 98.4505C210.415 98.4505 209.15 98.4847 207.857 98.5531C207.35 98.58 206.899 98.2279 206.809 97.7279L205.864 92.4958C205.703 91.6027 204.531 91.369 204.039 92.1318L189.905 114.062C189.603 114.53 189.742 115.154 190.213 115.45L212.064 129.189C212.796 129.649 213.727 129.026 213.582 128.174L212.92 124.306C212.814 123.688 213.296 123.126 213.922 123.149C229.165 123.696 243.457 129.851 254.308 140.611C265.702 151.912 272.021 166.98 272.125 183.029C272.229 198.975 266.117 214.043 254.877 225.499C243.69 236.904 228.773 243.303 212.768 243.612C212.405 243.612 211.991 243.612 211.629 243.612H88"
                stroke="#37474F"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"/>
            <path
                d="M331.284 256.037C331.678 255.646 331.678 255.008 331.283 254.617L312.83 236.341C312.198 235.715 311.126 236.163 311.126 237.051V242.045C311.126 242.597 310.678 243.045 310.126 243.045H279.376C278.288 244.283 277.149 245.47 276.009 246.657C267.204 255.636 256.897 262.654 245.709 267.505L245.605 267.608H310.178C310.73 267.608 311.178 268.056 311.178 268.608V273.598C311.178 274.487 312.251 274.934 312.882 274.307L331.284 256.037Z"
                fill="#9FB9DE"
                stroke="#37474F"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"/>
            <path
                d="M151.132 183.236C151.132 155.948 169.466 132.886 194.544 125.553C195.398 125.303 195.569 124.14 194.807 123.68L184.099 117.215C183.607 116.917 183.466 116.268 183.791 115.793L194.253 100.516C194.758 99.7795 194.117 98.8118 193.246 99.0055C155.374 107.427 126.943 143.048 126.943 183.236C126.943 203.671 134.298 222.455 146.47 237.059H184.591C164.805 227.203 151.132 206.768 151.132 183.236Z"
                fill="#9FB9DE"
                stroke="#37474F"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"/>
            <path
                d="M213.02 140.521C236.752 141.261 255.311 161.101 254.473 184.837C253.634 208.574 233.714 227.218 209.98 226.479C186.248 225.739 167.689 205.899 168.527 182.163C169.366 158.426 189.286 139.782 213.02 140.521Z"
                fill="#DFEAFD"
                stroke="#37474F"/>
            <path
                d="M248.477 184.652C249.198 164.228 233.228 147.155 212.806 146.518C192.385 145.882 175.245 161.924 174.524 182.348C173.802 202.772 189.772 219.846 210.194 220.482C230.615 221.118 247.755 205.076 248.477 184.652Z"
                fill="white"/>
            <path
                clipRule="evenodd"
                d="M212.791 147.018C192.644 146.39 175.735 162.216 175.023 182.366C174.312 202.513 190.065 219.354 210.209 219.982C230.356 220.61 247.265 204.784 247.977 184.634C248.688 164.487 232.935 147.646 212.791 147.018ZM174.024 182.33C174.755 161.631 192.125 145.374 212.822 146.019C233.52 146.663 249.707 163.969 248.976 184.67C248.245 205.369 230.875 221.626 210.178 220.981C189.48 220.337 173.293 203.031 174.024 182.33Z"
                fill="#37474F"
                fillRule="evenodd"/>
            <circle
                cx="211.5"
                cy="152.5"
                fill="#37474F"
                r="1.5"/>
            <circle
                cx="226.395"
                cy="157.056"
                fill="#37474F"
                r="1.5"
                transform="rotate(30.7986 226.395 157.056)"/>
            <circle
                cx="196.056"
                cy="157.056"
                fill="#37474F"
                r="1.5"
                transform="rotate(30.7986 196.056 157.056)"/>
            <circle
                cx="237.519"
                cy="167.066"
                fill="#37474F"
                r="1.5"
                transform="rotate(58.1099 237.519 167.066)"/>
            <path
                d="M186.857 168.34C186.154 168.778 185.229 168.562 184.791 167.859C184.353 167.156 184.569 166.231 185.272 165.793C185.976 165.355 186.901 165.571 187.338 166.274C187.776 166.978 187.56 167.903 186.857 168.34Z"
                fill="#37474F"/>
            <circle
                cx="242.5"
                cy="182.5"
                fill="#37474F"
                r="1.5"
                transform="rotate(-90 242.5 182.5)"/>
            <circle
                cx="211.5"
                cy="214.5"
                fill="#37474F"
                r="1.5"/>
            <path
                d="M195.89 210.268C195.466 210.98 194.545 211.213 193.834 210.789C193.122 210.365 192.889 209.444 193.313 208.732C193.738 208.021 194.658 207.788 195.37 208.212C196.081 208.636 196.314 209.557 195.89 210.268Z"
                fill="#37474F"/>
            <path
                d="M229.343 210.268C228.919 210.98 227.998 211.213 227.287 210.789C226.575 210.365 226.342 209.444 226.766 208.732C227.191 208.021 228.111 207.788 228.823 208.212C229.535 208.636 229.768 209.557 229.343 210.268Z"
                fill="#37474F"/>
            <circle
                cx="184.48"
                cy="200.066"
                fill="#37474F"
                r="1.5"
                transform="rotate(58.1099 184.48 200.066)"/>
            <circle
                cx="238.066"
                cy="199.933"
                fill="#37474F"
                r="1.5"
                transform="rotate(58.1099 238.066 199.933)"/>
            <circle
                cx="180.5"
                cy="183.584"
                fill="#37474F"
                r="1.5"
                transform="rotate(89.8526 180.5 183.584)"/>
            <path
                d="M212.17 181.935L212.14 182.515L212.718 182.459L213.895 182.343L214.352 182.298L214.346 181.839L214.107 163.871L213.107 163.852L212.17 181.935Z"
                fill="#9FB9DE"
                stroke="#37474F"
                strokeMiterlimit="10"/>
            <path
                d="M212.627 184.692L213.279 184.396L212.777 183.886L211.954 183.048L211.696 182.786L211.369 182.954L191.493 193.172L191.928 194.072L212.627 184.692Z"
                fill="#9FB9DE"
                stroke="#37474F"
                strokeMiterlimit="10"/>
            <path
                d="M214.901 183.365C214.862 184.264 214.131 184.945 213.25 184.941C212.367 184.903 211.671 184.155 211.712 183.289C211.751 182.391 212.482 181.709 213.363 181.714C214.244 181.719 214.939 182.467 214.901 183.365Z"
                fill="#37474F"
                stroke="#37474F"
                strokeMiterlimit="10"/>
        </svg>);
}