import { InlineItems, Optional, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, entityModelStore, InlineEntities, NetworkScopeFormatter, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetNetworkAccessResourceRiskContext } from "../../../useGetNetworkAccessResourceRiskContext";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsElbLoadBalancerRiskContext() {
    return useMemo(
        () => useAwsElbLoadBalancerRiskContext,
        []);
}

function useAwsElbLoadBalancerRiskContext(loadBalancerModel: Contract.AwsElbLoadBalancerModel) {
    const loadBalancer = loadBalancerModel.entity as Contract.AwsElbLoadBalancer;
    const networkedResourceStateNetwork = loadBalancerModel.entityNetwork as Optional<Contract.AwsNetworkedResourceStateNetwork>;
    const networkAccessResourceRiskContext = useGetNetworkAccessResourceRiskContext()(loadBalancerModel);
    const resourceRiskContext = useGetAwsResourceRiskContext()(loadBalancerModel);
    const targetGroupModels = entityModelStore.useGet((loadBalancerModel as Contract.AwsElbLoadBalancerModel).targetGroupIds);
    const targetIds =
        _.flatMap(
            targetGroupModels,
            targetGroupModel => {
                const targetGroup = targetGroupModel.entity as Contract.AwsElbTargetGroup;
                return _(targetGroup.applicationLoadBalancerIds).
                    concat(
                        targetGroup.functionConfigurationIds,
                        targetGroup.instanceIds,
                        targetGroup.ipAddresses).
                    uniq().
                    value();
            });
    const targetModels = entityModelStore.useGet(targetIds);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsElbLoadBalancerRiskContext",
            () => ({
                ports: [
                    "1 port",
                    "{{count | NumberFormatter.humanize}} ports"
                ],
                sensitiveTargetExists: [
                    "The {{target}} {{targets}} is associated with high severity permissions and expose {{networkScopes}}",
                    "{{targets}} are associated with high severity permissions and expose {{networkScopes}}"
                ],
                targetExists: "The {{translatedLoadBalancerTypeName}} has {{targets}}",
                targetGroupManagementPortOpenAndHighPermission: [
                    "The {{targets}} is associated with high severity permissions and expose {{networkScopes}}",
                    "{{targets}} are associated with high severity permissions and expose {{networkScopes}}"
                ],
                targetGroupPorts: "The {{translatedLoadBalancerTypeName}} is exposing {{ports}} via {{targetGroups}}",
                targets: [
                    "1 target",
                    "{{count | NumberFormatter.humanize}} targets"
                ]
            }));
    const translatedLoadBalancerTypeName =
        entityTypeNameTranslator(
            loadBalancer.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    const [targetGroupIpAddresses, sensitiveTargetModels] =
        useMemo(
            () => {
                const targetGroupIpAddresses =
                    _(targetGroupModels).
                        flatMap(targetGroupModel => (targetGroupModel.entity as Contract.AwsElbTargetGroup).ipAddresses).
                        uniq().
                        value();
                const sensitiveTargetModels =
                    _.filter(
                        targetModels,
                        targetModel => targetModel.entityConfiguration?.sensitive === true);
                return [targetGroupIpAddresses, sensitiveTargetModels];
            },
            [networkedResourceStateNetwork, targetGroupModels]);

    return {
        ...resourceRiskContext,
        ...networkAccessResourceRiskContext,
        sensitiveTargets:
            !_.isEmpty(sensitiveTargetModels)
                ? new RiskDefinitionContextItem(
                    localization.sensitiveTargetExists(
                        sensitiveTargetModels.length,
                        {
                            targets:
                                <InlineEntities
                                    entityIdsOrModels={sensitiveTargetModels}
                                    entityTypeName={Contract.TypeNames.AwsResource}
                                    namePluralizer={localization.targets}
                                    variant="itemOrItemCountAndType"/>
                        }
                    ))
                : undefined,
        targetExists:
            !_.isEmpty(targetIds)
                ? new RiskDefinitionContextItem(
                    localization.targetExists({
                        targets:
                            <InlineItems
                                items={targetIds}
                                namePluralizer={localization.targets}
                                variant="itemCountAndType">
                                {(targetIdentifier: string) =>
                                    _.includes(targetGroupIpAddresses, targetIdentifier)
                                        ? <Stack
                                            alignItems="center"
                                            spacing={1}>
                                            <Typography>
                                                {targetIdentifier}
                                            </Typography>
                                        </Stack>
                                        : <Entity
                                            entityIdOrModel={targetIdentifier}
                                            variant="iconTextTypeTenant"/>}
                            </InlineItems>,
                        translatedLoadBalancerTypeName
                    }))
                : undefined,
        targetGroupPorts:
            loadBalancer.typeName !== Contract.TypeNames.AwsElbNetworkLoadBalancer ||
            _.isEmpty(loadBalancerModel.targetGroupIds) ||
            _.isNil(networkedResourceStateNetwork)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.targetGroupPorts({
                        ports:
                            <InlineItems
                                items={
                                    _.map(
                                        networkedResourceStateNetwork.inboundExternalDestinationNetworkScopes,
                                        inboundExternalDestinationNetworkScope => NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(inboundExternalDestinationNetworkScope))}
                                namePluralizer={localization.ports}
                                variant="itemCountAndType"/>,
                        targetGroups:
                            <InlineEntities
                                entityIdsOrModels={targetGroupModels}
                                entityTypeName={Contract.TypeNames.AwsElbTargetGroup}
                                variant="itemAndTypeOrItemCountAndType"/>,
                        translatedLoadBalancerTypeName
                    }))
    };
}