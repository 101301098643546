import React from "react";
import { CustomerConsoleAppUrlHelper, ProfileActions } from "../../../../../../../common";
import { Profile } from "../../../../Events/components/Profile";
import { SideViewItem } from "../../../SideView";
import { SideViewDefinition } from "../useDefinition";

export function useEventsDefinition(item: SideViewItem): SideViewDefinition {
    return {
        getActionsElement:
            () =>
                <ProfileActions
                    getLink={() => CustomerConsoleAppUrlHelper.getEventProfileUrl(item.id)!}
                    key={`${item.id}-actions`}/>,
        getViewElement:
            () =>
                <Profile
                    eventId={item.id}
                    key={`${item.id}-profile`}/>
    };
}