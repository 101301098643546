import { FormLayout, makeContextProvider, OrderedWizard, OrderedWizardItem, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { PingIdentityTenantsContext, usePingIdentityTenantsContext, useSetPingIdentityTenantsContext } from "../..";
import { Contract } from "../../../../../../../../../../../../../../common";
import { ClientCredentialsItem, FinishItem, TenantItem } from "./components";

class AddOrEditContext {
    public tenantClientId: string;
    public tenantClientSecret?: string;
    public tenantName: string;
    public tenantRawId: string;
    public tenantRegion?: Contract.PingIdentityClientRegion;
    public updatedTenantModel?: Contract.PingIdentityTenantModel;

    constructor(public tenantConfiguration?: Contract.PingIdentityTenantConfiguration) {
        this.tenantClientId = tenantConfiguration?.clientId ?? "";
        this.tenantClientSecret =
            _.isNil(tenantConfiguration)
                ? ""
                : undefined;
        this.tenantName = tenantConfiguration?.name ?? "";
        this.tenantRawId = tenantConfiguration?.rawId ?? "";
        this.tenantRegion = tenantConfiguration?.region;
    }
}

export const [useAddOrEditContext, useSetAddOrEditContext, useAddOrEditContextProvider] = makeContextProvider<AddOrEditContext>();

export function AddOrEdit() {
    const { addOrEditOpen } = usePingIdentityTenantsContext();
    const tenantConfiguration =
        _.isBoolean(addOrEditOpen)
            ? undefined
            : addOrEditOpen;

    const setPingIdentityTenantsContext = useSetPingIdentityTenantsContext();
    const [, , ContextProvider] = useAddOrEditContextProvider(() => new AddOrEditContext(tenantConfiguration));
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.pingIdentityTenants.addOrEdit",
            () => ({
                steps: {
                    clientCredentials: "Create a New Application",
                    tenant: "Provide Details"
                },
                subtitle: "Provide the following details, which can be found in the application's **Configuration** tab under the **General** section and in **Settings -> Environment Properties**:",
                title: {
                    add: "Add Ping Identity environment",
                    edit: "Edit Ping Identity environment"
                }
            }));
    return (
        <ContextProvider>
            <FormLayout
                disableContentPadding={true}
                titleOptions={{
                    text:
                        _.isNil(tenantConfiguration)
                            ? localization.title.add()
                            : localization.title.edit()
                }}>
                <OrderedWizard
                    finishItemElement={<FinishItem/>}
                    startItemIndex={
                        _.isNil(tenantConfiguration)
                            ? 0
                            : 1}
                    onClose={() => setPingIdentityTenantsContext(new PingIdentityTenantsContext(false))}>
                    <OrderedWizardItem title={localization.steps.clientCredentials()}>
                        <ClientCredentialsItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem
                        deferredLoading={true}
                        subtitle={localization.subtitle()}
                        title={localization.steps.tenant()}>
                        <TenantItem/>
                    </OrderedWizardItem>
                </OrderedWizard>
            </FormLayout>
        </ContextProvider>);
}