﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsKmsKeyRiskContext() {
    return useMemo(
        () => useAwsKmsKeyRiskContext,
        []);
}

function useAwsKmsKeyRiskContext(keyModel: Contract.AwsKmsKeyModel) {
    const key = keyModel.entity as Contract.AwsKmsKey;
    const resourceRiskContext = useGetAwsResourceRiskContext()(keyModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsKmsKeyRiskContext",
            () => ({
                enabled: {
                    false: "The {{translatedKeyTypeName}} status is **Disabled**",
                    true: "The {{translatedKeyTypeName}} status is **Enabled**"
                },
                resources: "The {{translatedKeyTypeName}} is used to encrypt {{resources}}",
                rotationTime: {
                    empty: "The {{translatedKeyTypeName}} rotation period is not set",
                    text: "The {{translatedKeyTypeName}}'s rotation period is currently set to {{rotationTimeFrame | TimeSpanFormatter.humanizeDays}}"
                }
            }));
    const translatedKeyTypeName =
        entityTypeNameTranslator(
            key.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        enabled:
            new RiskDefinitionContextItem(
                (key.enabled
                    ? localization.enabled.true
                    : localization.enabled.false)({ translatedKeyTypeName })),
        publicAccessPermissionActions:
            resourceRiskContext.getPublicAccessPermissionActions(
                key.publicAccessHighSeveritySensitivePermissionActions,
                key.publicAccessPermissionActions),
        resources:
            _.isEmpty(keyModel.encryptedResourceIds)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.resources({
                        resources:
                            <InlineEntities
                                entityIdsOrModels={keyModel.encryptedResourceIds}
                                entityTypeName={Contract.TypeNames.AwsResource}
                                variant="itemCountAndType"/>,
                        translatedKeyTypeName
                    })),
        rotationTime:
            new RiskDefinitionContextItem(
                _.isNil(key.rotationTimeFrame)
                    ? localization.rotationTime.empty({ translatedKeyTypeName })
                    : localization.rotationTime.text({
                        rotationTimeFrame: key.rotationTimeFrame,
                        translatedKeyTypeName
                    }))
    };
}