﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetCommonKubernetesClusterRiskContext } from "../../../..";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../../utilities";

export function useKubernetesClusterKubeletInsufficientEventsPerSecondMaxCountRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.KubernetesClusterKubeletInsufficientEventsPerSecondMaxCountRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId);
    const getCommonKubernetesClusterRiskContext = useGetCommonKubernetesClusterRiskContext(clusterModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.kubernetes.hooks.clusters.kubelets.useKubernetesClusterKubeletInsufficientEventsPerSecondMaxCountRiskDefinition",
            () => ({
                contextItems: {
                    insufficientEventsPerSecondMaxCountNodes: "The cluster has {{nodes}} in which the --eventRecordQPS argument is set between **1** to **4**"
                },
                description: "{{cluster}} does not have appropriate event capture level",
                resolutionSection: {
                    step1: "For the following {{nodes}}, ensure that the --eventRecordQPS argument is set to **5** or higher, or a level which ensures appropriate event capture"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{
                        includeServiceName: true,
                        variant: "title"
                    }}
                    variant="typeText"/>
        }),
        () => [
            localization.resolutionSection.step1({
                nodes:
                    <InlineEntities
                        entityIdsOrModels={risk.nodeOrVirtualMachineIds}
                        entityTypeName={Contract.TypeNames.IKubernetesNode}
                        variant="itemCountAndType"/>
            })
        ],
        riskModel,
        () => {
            const clusterRiskContext = getCommonKubernetesClusterRiskContext(clusterModel);

            return [
                clusterRiskContext.generalInformation,
                clusterRiskContext.virtualNetwork,
                new RiskDefinitionContextItem(
                    localization.contextItems.insufficientEventsPerSecondMaxCountNodes({
                        nodes:
                            <InlineEntities
                                entityIdsOrModels={risk.nodeOrVirtualMachineIds}
                                entityTypeName={Contract.TypeNames.IKubernetesNode}
                                variant="itemCountAndType"/>
                    })),
                clusterRiskContext.sensitive,
                clusterRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}