﻿import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../../common";
import { RiskDefinition } from "../../../../../../../utilities";
import { useAwsEc2SnapshotEncryptionDisabledRiskLaunchConfigurationDefinition, useAwsEc2SnapshotEncryptionDisabledRiskLaunchTemplateDefinition, useAwsEc2SnapshotEncryptionDisabledRiskSnapshotDefinition, useAwsEc2SnapshotEncryptionDisabledRiskVolumeDefinition } from "./hooks";

export function useAwsEc2SnapshotEncryptionDisabledRiskDefinition(riskModel: Contract.RiskModel): RiskDefinition {
    const risk = riskModel.risk as Contract.AwsEc2SnapshotEncryptionDisabledRisk;

    const useDefinition =
        useMemo(
            () => {
                switch (risk.aggregatingEntityTypeName) {
                    case Contract.TypeNames.AwsAutoScalingLaunchConfiguration:
                        return useAwsEc2SnapshotEncryptionDisabledRiskLaunchConfigurationDefinition;
                    case Contract.TypeNames.AwsEc2LaunchTemplate:
                        return useAwsEc2SnapshotEncryptionDisabledRiskLaunchTemplateDefinition;
                    case Contract.TypeNames.AwsEc2Snapshot:
                        return useAwsEc2SnapshotEncryptionDisabledRiskSnapshotDefinition;
                    case Contract.TypeNames.AwsEc2Volume:
                        return useAwsEc2SnapshotEncryptionDisabledRiskVolumeDefinition;
                    default:
                        throw new UnexpectedError("risk.aggregatingEntityTypeName", risk.aggregatingEntityTypeName);
                }
            },
            []);

    return useDefinition(riskModel);
}