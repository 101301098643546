import { ContentMenuItem, EditIcon, Menu as MuiMenu, MenuItem, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo, useRef } from "react";
import { customEntityAttributeDefinitionModelStore, CustomerConsoleAppUrlHelper, EntityAttributeDefinitionList, EntityController, entityModelStore, ProfileActions as BaseProfileActions, ScopeHelper, scopeNodeModelStore, TenantHelper, useProjectScopeSelected, UserHelper } from "../../../../../common";
import { TypeNames } from "../../../../../common/controllers/typeNames.generated";
import { EntityControllerUpdateConfigurationRequest, EntityModel, IdentityPermission } from "../../../../../common/controllers/types.generated";
import { ProfileView } from "./Profile";
import { EntityProfileDefinition, ProfileCategory } from "./Profile/hooks";

type ProfileActionsProps = {
    definition: EntityProfileDefinition;
    entityModel: EntityModel;
};

export function ProfileActions({ definition, entityModel }: ProfileActionsProps) {
    const entitiesTenantTypes =
        useMemo(
            () => TenantHelper.EntitiesTenantTypes,
            []);
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap(entitiesTenantTypes);
    const customEntityAttributeDefinitionModels = customEntityAttributeDefinitionModelStore.useGetAll();
    const projectScopeSelected = useProjectScopeSelected();

    const [manualCustomEntityAttributeDefinitionIds, selectedManualCustomEntityAttributeDefinitionIds] =
        useMemo(
            () => {
                const entityParentScopeIds = scopeNodeMap[entityModel.tenantId]?.parentScopeIds ?? [ScopeHelper.customerId];
                const manualCustomEntityAttributeDefinitionIds =
                    _(customEntityAttributeDefinitionModels).
                        filter(
                            customEntityAttributeDefinitionModel =>
                                customEntityAttributeDefinitionModel.configuration.typeName === TypeNames.ManualCustomEntityAttributeDefinitionConfiguration &&
                                (_.includes(entityParentScopeIds, customEntityAttributeDefinitionModel.configuration.scopeId) ||
                                    entityModel.tenantId === customEntityAttributeDefinitionModel.configuration.scopeId)).
                        map(customEntityAttributeDefinitionModel => customEntityAttributeDefinitionModel.configuration.id).
                        value();

                const selectedManualCustomEntityAttributeDefinitionIds =
                    _.isNil(entityModel.entityConfiguration)
                        ? []
                        : _.keys(entityModel.entityConfiguration!.manualCustomEntityAttributeDefinitionIdToIdentityReferenceMap);

                return [manualCustomEntityAttributeDefinitionIds, selectedManualCustomEntityAttributeDefinitionIds];
            },
            [entityModel]);
    const selectedManualCustomEntityAttributeDefinitionIdsRef = useRef(selectedManualCustomEntityAttributeDefinitionIds);
    const writePermission =
        useMemo(
            () =>
                UserHelper.hasAnyScopePermissions(entityModel.entity.scopeIds, IdentityPermission.SecurityWrite),
            [entityModel]);
    async function toggleManualCustomEntityAttributeDefinitionId(manualCustomEntityAttributeDefinitionId: string) {
        selectedManualCustomEntityAttributeDefinitionIdsRef.current =
            _.includes(selectedManualCustomEntityAttributeDefinitionIdsRef.current, manualCustomEntityAttributeDefinitionId)
                ? _.without(selectedManualCustomEntityAttributeDefinitionIdsRef.current, manualCustomEntityAttributeDefinitionId)
                : _.concat(selectedManualCustomEntityAttributeDefinitionIdsRef.current, manualCustomEntityAttributeDefinitionId);

        await EntityController.updateConfiguration(
            new EntityControllerUpdateConfigurationRequest(
                entityModel.id,
                selectedManualCustomEntityAttributeDefinitionIdsRef.current,
                undefined));

        await entityModelStore.notify(entityModel.id);
    }

    const localization =
        useLocalization(
            "views.customer.entities.profileActions",
            () => ({
                menu: {
                    labels: "Edit Labels"
                }
            }));

    const menuItems =
        useMemo(
            () =>
                _([] as MenuItem[]).
                    concatIf(
                        !projectScopeSelected &&
                        !TenantHelper.isCommonTenant(entityModel.tenantId) &&
                        writePermission,
                        new ContentMenuItem(
                            () =>
                                <EntityAttributeDefinitionList
                                    customEntityAttributeDefinitionIds={manualCustomEntityAttributeDefinitionIds}
                                    selectedCustomEntityAttributeDefinitionIds={selectedManualCustomEntityAttributeDefinitionIds}
                                    title={true}
                                    onToggleCustomEntityAttributeItem={toggleManualCustomEntityAttributeDefinitionId}/>,
                            localization.menu.labels(),
                            {
                                icon: <EditIcon/>,
                                placement: "topRight",
                                variant: "subMenu"
                            })).
                    concat(definition.options.topbarMenuItems ?? []).
                    value(),
            [entityModel, definition.options.topbarMenuItems]);

    return (
        <BaseProfileActions
            getLink={
                () =>
                    CustomerConsoleAppUrlHelper.getEntityProfileUrl(
                        entityModel,
                        {
                            category: ProfileCategory.Overview,
                            view: ProfileView.Info
                        })!}
            menu={
                _.some(menuItems)
                    ? <MuiMenu
                        itemsOrGetItems={menuItems}
                        variant="bottomRight"/>
                    : undefined}/>);
}