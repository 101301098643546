import { useLocalization } from "@infrastructure";
import React from "react";
import { useGetGcpStorageBucketRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { GcpRoleBindingTable } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../components";

export function useGcpStorageBucketUniformAccessControlDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.GcpStorageBucketUniformAccessControlDisabledRisk;
    const bucketModel = entityModelStore.useGet(risk.entityId) as Contract.GcpStorageBucketModel;

    const getGcpStorageBucketRiskContext = useGetGcpStorageBucketRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpStorageBucketUniformAccessControlDisabledRiskDefinition",
            () => ({
                description: "{{bucket}} uniform access policy is not enabled",
                sections: {
                    resolution: {
                        step1: "Before changing the access control mode, verify that applications can access the object buckets without fine grained object ACLs",
                        step2: "Under **Access Control**, click **Switch to uniform**"
                    },
                    roleBindingIds: "Role Bindings"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            bucket:
                <Entity
                    entityIdOrModel={bucketModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.Permissions}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2()
        ],
        riskModel,
        () => {
            const bucketRiskContext = getGcpStorageBucketRiskContext(bucketModel);
            return [
                bucketRiskContext.generalInformation,
                bucketRiskContext.sensitive,
                bucketRiskContext.highestDataSensitivity,
                bucketRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <GcpRoleBindingTable
                        csvExportFilePrefixes={[localization.sections.roleBindingIds()]}
                        principalOrResourceId={risk.entityId}
                        roleBindingIds={bucketModel.roleBindingIds}
                        variant="principalAndRoleAndScope"/>,
                    localization.sections.roleBindingIds())
            ]
        });
}