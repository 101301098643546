import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function InfraBooleanIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                clipRule="evenodd"
                d="M9.5 11H7.5V13H9.5V11ZM7.5 9.5C6.67157 9.5 6 10.1716 6 11V13C6 13.8284 6.67157 14.5 7.5 14.5H9.5C10.3284 14.5 11 13.8284 11 13V11C11 10.1716 10.3284 9.5 9.5 9.5H7.5Z"
                fillRule="evenodd"/>
            <path d="M13 11C13 10.1716 13.6716 9.5 14.5 9.5H16.5C17.3284 9.5 18 10.1716 18 11V13C18 13.8284 17.3284 14.5 16.5 14.5H14.5C13.6716 14.5 13 13.8284 13 13V11Z"/>
            <path
                clipRule="evenodd"
                d="M19 4H5C4.44772 4 4 4.44772 4 5V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V5C20 4.44772 19.5523 4 19 4ZM5 2C3.34315 2 2 3.34315 2 5V19C2 20.6569 3.34315 22 5 22H19C20.6569 22 22 20.6569 22 19V5C22 3.34315 20.6569 2 19 2H5Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}