import { Link, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { CustomerConsoleAppUrlHelper, LogoTextIcon, SignInErrorImage, UserHelper, useTheme } from "../../../common";

export function SignInError() {
    const localization =
        useLocalization(
            "views.customer.signInError",
            () => ({
                goBack: "Go back to",
                link: "sign in page",
                tenable: {
                    subtitle: "Contact your Tenable administrator to get access to Tenable Cloud Security.\nBy default, only Tenable One administrator users have access to Tenable Cloud Security."
                },
                title: "You are not authorized to sign in"
            }));
    const theme = useTheme();
    return (
        <Stack
            sx={{
                alignItems: "center",
                background: theme.palette.background.paper,
                flex: 1,
                overflow: "hidden",
                padding: theme.spacing(6),
                position: "relative"
            }}>
            <LogoTextIcon
                sx={{
                    height: "20px",
                    position: "absolute",
                    top: "15vh",
                    width: "214px"
                }}/>
            <Stack
                sx={{
                    alignItems: "center",
                    flex: 1,
                    justifyContent: "center"
                }}>
                <SignInErrorImage/>
                <Typography
                    align="center"
                    sx={{
                        fontSize: "24px",
                        fontWeight: 700,
                        marginTop: theme.spacing(3)
                    }}>
                    {localization.title()}
                </Typography>
                {UserHelper.tenable &&
                    <Typography
                        align="center"
                        sx={{
                            fontSize: "18px",
                            fontWeight: 500,
                            marginBottom: theme.spacing(3),
                            whiteSpace: "pre"
                        }}>
                        {localization.tenable.subtitle()}
                    </Typography>}
                <Typography sx={{ fontSize: "16px" }}>
                    {localization.goBack()}
                    &nbsp;
                    <Link
                        sx={{ fontSize: "16px" }}
                        urlOrGetUrl={CustomerConsoleAppUrlHelper.getSignInRelativeUrl()}
                        variant="internal">
                        {localization.link()}
                    </Link>
                </Typography>
            </Stack>
        </Stack>);
}