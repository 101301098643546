import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function EcrRepositoryIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M7.5 1L14 3.72728L14 6.098L11.0607 7.91211C10.9381 7.24352 10.6036 6.60275 10.0567 6.08637C8.64833 4.75682 6.35889 4.75682 4.95055 6.08637C3.54222 7.41591 3.54222 9.57728 4.95055 10.9068C5.78237 11.6921 6.91891 12.0165 8.00044 11.8759L8.00133 15.867C7.83635 15.9167 7.66919 15.9611 7.5 16C3.85803 15.1604 1.12655 11.7618 1.00428 8.07579L1 7.81819V3.72728L7.5 1ZM7.5 6.45455C8.69662 6.45455 9.66667 7.37033 9.66667 8.5C9.66667 8.59652 9.65959 8.69147 9.64589 8.78443L8.20205 9.67508C8.14055 9.713 8.0895 9.76758 8.05403 9.83331C8.01856 9.89904 8 9.97361 8 10.0495L8.0007 10.4906C7.83998 10.5265 7.67234 10.5455 7.5 10.5455C6.30338 10.5455 5.33333 9.62968 5.33333 8.5C5.33333 7.37033 6.30338 6.45455 7.5 6.45455Z"/>
            <path
                clipRule="evenodd"
                d="M22.2719 19.0245C22.2717 19.0538 22.264 19.0825 22.2495 19.108C22.235 19.1335 22.2143 19.1548 22.1892 19.1699L16.2509 22.5909C16.2257 22.6054 16.1972 22.6133 16.1682 22.6137C16.1461 22.6137 16.1242 22.6094 16.1038 22.6009C16.0834 22.5925 16.0649 22.5801 16.0493 22.5645C16.0336 22.5488 16.0213 22.5303 16.0128 22.5099C16.0044 22.4895 16 22.4676 16 22.4455V19.3096C16.0002 19.2803 16.0079 19.2516 16.0224 19.2261C16.0368 19.2006 16.0576 19.1793 16.0827 19.1642L19.1331 17.4052C19.1362 17.3568 19.1362 17.3082 19.1331 17.2598V13.759C19.1331 13.7007 19.2209 13.6537 19.2764 13.624C19.2834 13.6202 19.2899 13.6168 19.2956 13.6136L22.0181 12.0399C22.0435 12.0263 22.072 12.0194 22.1008 12.02L22.1037 12C22.1213 11.9997 22.1388 12.0022 22.1555 12.0074C22.1597 12.0087 22.1638 12.0102 22.1678 12.0118C22.1825 12.0177 22.1963 12.0256 22.2088 12.0354C22.2135 12.0391 22.2181 12.0432 22.2224 12.0474C22.2381 12.0628 22.2505 12.0812 22.259 12.1014C22.2675 12.1217 22.2719 12.1434 22.2719 12.1654V19.0245ZM21.2719 13.6263L20.1331 14.2845V17.2329C20.1367 17.3116 20.1361 17.3904 20.131 17.469L20.0969 18.0038L17 19.7896V21.0053L21.2719 18.5443V13.6263Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M9 18.7762C8.99992 18.8554 9.02069 18.9331 9.06023 19.0017C9.09977 19.0703 9.15667 19.1272 9.22522 19.1668L15.1664 22.5878C15.2349 22.6272 15.3126 22.6478 15.3916 22.6477C15.4706 22.6478 15.5483 22.6272 15.6168 22.5878C15.6858 22.5487 15.743 22.4918 15.7826 22.4232C15.8222 22.3545 15.8427 22.2765 15.842 22.1972V14.9162L22.151 11.2671C22.2193 11.2266 22.276 11.1691 22.3155 11.1001C22.355 11.0312 22.3759 10.9531 22.3762 10.8737C22.376 10.7946 22.3551 10.7169 22.3156 10.6484C22.2761 10.5799 22.2193 10.5229 22.151 10.4831L16.2126 7.06208C16.144 7.02144 16.0657 7 15.986 7C15.9063 7 15.828 7.02144 15.7594 7.06208L9.22522 10.8338C9.15667 10.8733 9.09977 10.9303 9.06023 10.9988C9.02069 11.0674 8.99992 11.1452 9 11.2243V18.7762ZM10 11.5412L15.9863 8.08574L20.8298 10.876L14.842 14.3393V21.2471L10 18.459V11.5412Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}