import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { InlineItems, ItemWithValidation, Loading, useDebouncedEffect, useLocalization } from "@infrastructure";
import { ConfigurationController, Contract, Scope } from "../../../../../../../../../../../../../common";

type MatchingActiveTenantsProps = {
    projectConfigurationRules: ItemWithValidation<Contract.ProjectConfigurationRule>[];
};

export function MatchingActiveTenants({ projectConfigurationRules }: MatchingActiveTenantsProps) {
    const validProjectConfigurationRules =
        useMemo(
            () =>
                _.filter(
                    projectConfigurationRules,
                    projectConfigurationRule =>
                        !!projectConfigurationRule.valid &&
                        !_.isEmpty(projectConfigurationRule.scopeIds)),
            [projectConfigurationRules]);

    const previousValidProjectConfigurationRules = useRef<ItemWithValidation<Contract.ProjectConfigurationRule>[]>();
    const [matchingActiveTenantIdsLoading, setMatchingActiveTenantIdsLoading] = useState(!_.isEmpty(validProjectConfigurationRules));
    const [matchingActiveTenantIdsError, setMatchingActiveTenantIdsError] = useState(false);
    const [matchingActiveTenantIds, setMatchingActiveTenantIds] = useState<string[]>([]);

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useProjectsDefinition.addOrEdit.projectConfigurationRules.matchingActiveTenants",
            () => ({
                error: "We couldn't match any accounts to your criteria. Try adjusting the scope/conditions and try again.",
                rulePluralizer: [
                    "match this criteria",
                    "match this project's scopes"
                ],
                tenantPluralizer: [
                    "1 account",
                    "{{count | NumberFormatter.humanize}} accounts"
                ],
                title: "{{tenantPluralizer}} {{text}}"
            }));

    useDebouncedEffect(
        () => {
            async function fetchMatchingActiveTenantIds() {
                if (_.isEmpty(validProjectConfigurationRules) ||
                    _.isEqual(validProjectConfigurationRules, previousValidProjectConfigurationRules.current)) {
                    return;
                }

                setMatchingActiveTenantIdsError(false);
                setMatchingActiveTenantIdsLoading(true);

                previousValidProjectConfigurationRules.current = validProjectConfigurationRules;
                try {
                    const { tenantIds } = await ConfigurationController.getProjectConfigurationRulesMatchingActiveTenantIds(new Contract.ConfigurationControllerGetProjectConfigurationRulesMatchingTenantIdsRequest(validProjectConfigurationRules));
                    setMatchingActiveTenantIds(tenantIds);
                } catch (error) {
                    setMatchingActiveTenantIdsError(true);
                }

                setMatchingActiveTenantIdsLoading(false);
            }

            fetchMatchingActiveTenantIds();
        },
        500,
        [validProjectConfigurationRules]);

    useEffect(
        () => {
            if (_.some(
                projectConfigurationRules,
                projectConfigurationRule =>
                    !_.isEmpty(projectConfigurationRule.scopeIds) ||
                    !_.isEmpty(projectConfigurationRule.conditions))) {
                return;
            }
            setMatchingActiveTenantIds([]);
        },
        [projectConfigurationRules]);

    return (
        <Loading
            container={
                projectConfigurationRules.length > 1
                    ? "cell-wide"
                    : "cell-medium"}
            loading={matchingActiveTenantIdsLoading}>
            <Typography>
                {matchingActiveTenantIdsError
                    ? localization.error()
                    : localization.title({
                        tenantPluralizer:
                            <InlineItems
                                items={
                                    _.map(
                                        matchingActiveTenantIds,
                                        tenantId =>
                                            <Scope
                                                scopeId={tenantId}
                                                variant="text"/>)}
                                namePluralizer={localization.tenantPluralizer}
                                variant="itemCountAndType"/>,
                        text: localization.rulePluralizer(projectConfigurationRules.length)
                    })}
            </Typography>
        </Loading>);
}