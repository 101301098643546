
import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { useTheme } from "../../../../../../../../../../../../../../../common";

export function DataLeakageIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M3.29209 4.58052C3.01022 4.83042 3 4.97447 3 5C3 5.02553 3.01022 5.16958 3.29209 5.41948C3.57279 5.66834 4.03602 5.93815 4.69427 6.18499C6.0034 6.67591 7.87903 7 10 7C10.5523 7 11 7.44772 11 8C11 8.55228 10.5523 9 10 9C7.70269 9 5.57833 8.65251 3.99202 8.05765C3.20256 7.7616 2.49422 7.38496 1.96527 6.91599C1.43749 6.44806 1 5.80289 1 5C1 4.19711 1.43749 3.55194 1.96527 3.08401C2.49422 2.61504 3.20256 2.2384 3.99202 1.94235C5.57833 1.34749 7.70269 1 10 1C10.5361 1 11.0612 1.01891 11.571 1.05517C12.1218 1.09436 12.5367 1.57271 12.4975 2.1236C12.4583 2.6745 11.9799 3.08932 11.429 3.05013C10.967 3.01726 10.4893 3 10 3C7.87903 3 6.0034 3.32409 4.69427 3.81501C4.03602 4.06185 3.57279 4.33166 3.29209 4.58052Z"
                fill={theme.palette.text.primary}
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M4.04191 10.5556C4.20013 10.0265 4.75733 9.72576 5.28647 9.88397C5.83976 10.0494 6.46578 10.1897 7.14981 10.2933C7.69586 10.3761 8.07145 10.8858 7.98872 11.4319C7.90598 11.9779 7.39625 12.3535 6.85019 12.2708C6.08222 12.1544 5.36424 11.9947 4.71353 11.8001C4.18439 11.6419 3.8837 11.0847 4.04191 10.5556ZM2 11.0001C2.55228 11.0001 3 11.4478 3 12.0001C3 12.0256 3.01024 12.1697 3.29211 12.4196C3.57283 12.6685 4.03608 12.9383 4.69436 13.1851C6.00352 13.676 7.87916 14.0001 10 14.0001C10.8335 14.0001 11.633 13.95 12.3777 13.8582C12.9258 13.7906 13.4249 14.1802 13.4925 14.7283C13.5601 15.2765 13.1705 15.7756 12.6223 15.8432C11.7939 15.9453 10.9126 16.0001 10 16.0001C7.70284 16.0001 5.57848 15.6526 3.99214 15.0578C3.20267 14.7617 2.4943 14.3851 1.96533 13.9161C1.43751 13.4482 1 12.803 1 12.0001C1 11.4478 1.44772 11.0001 2 11.0001ZM4.04191 17.5556C4.20013 17.0265 4.75733 16.7258 5.28647 16.884C5.83976 17.0494 6.46579 17.1897 7.14981 17.2933C7.69586 17.3761 8.07145 17.8858 7.98872 18.4319C7.90598 18.9779 7.39625 19.3535 6.85019 19.2708C6.08222 19.1544 5.36424 18.9947 4.71353 18.8001C4.18439 18.6419 3.8837 18.0847 4.04191 17.5556Z"
                fill={theme.palette.text.primary}
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M2 4C2.55228 4 3 4.44772 3 5V19C3 19.0256 3.01024 19.1696 3.29211 19.4195C3.57283 19.6684 4.03608 19.9382 4.69436 20.185C6.00352 20.6759 7.87916 21 10 21C10.8335 21 11.633 20.9499 12.3777 20.8581C12.9258 20.7906 13.4249 21.1801 13.4925 21.7283C13.5601 22.2764 13.1705 22.7755 12.6223 22.8431C11.7939 22.9452 10.9126 23 10 23C7.70284 23 5.57848 22.6526 3.99214 22.0577C3.20267 21.7617 2.4943 21.385 1.96533 20.9161C1.43751 20.4481 1 19.8029 1 19V5C1 4.44772 1.44772 4 2 4Z"
                fill={theme.palette.text.primary}
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M15.2978 6.49466C15.2978 7.98406 16.5052 9.19145 17.9946 9.19145C19.484 9.19145 20.6914 7.98406 20.6914 6.49466C20.6914 5.00526 19.484 3.79786 17.9946 3.79786C16.5052 3.79786 15.2978 5.00526 15.2978 6.49466ZM13.4999 6.49466C13.4999 8.97699 15.5123 10.9893 17.9946 10.9893C20.4769 10.9893 22.4893 8.97699 22.4893 6.49466C22.4893 4.01233 20.4769 2 17.9946 2C15.5123 2 13.4999 4.01233 13.4999 6.49466Z"
                fill={theme.palette.text.primary}
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M18.082 10.1641C18.5785 10.1641 18.9809 10.5665 18.9809 11.063L18.9809 21.1011C18.9809 21.5975 18.5785 22 18.082 22C17.5855 22 17.1831 21.5975 17.1831 21.1011L17.1831 11.063C17.1831 10.5665 17.5855 10.1641 18.082 10.1641Z"
                fill={theme.palette.text.primary}
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M18.2443 19.5537C18.2443 20.0502 17.8418 20.4527 17.3454 20.4527L14.8983 20.4527C14.4018 20.4527 13.9993 20.0502 13.9993 19.5537C13.9993 19.0573 14.4018 18.6548 14.8983 18.6548L17.3454 18.6548C17.8418 18.6548 18.2443 19.0573 18.2443 19.5537Z"
                fill={theme.palette.text.primary}
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M18.2443 16.3689C18.2443 16.8654 17.8418 17.2678 17.3454 17.2678L15.9595 17.2678C15.463 17.2678 15.0606 16.8654 15.0606 16.3689C15.0606 15.8724 15.463 15.47 15.9595 15.47L17.3454 15.47C17.8418 15.47 18.2443 15.8724 18.2443 16.3689Z"
                fill={theme.palette.text.primary}
                fillRule="evenodd"/>
        </SvgIcon>);
}