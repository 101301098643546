﻿import React from "react";
import { BuiltInRiskPolicyItem, CustomRiskPolicyItem, RiskPoliciesType, RiskPolicyItem } from "../../../../../../common";
import { BuiltInActionsCell, CustomActionsCell } from "./components";

type ActionsProps = {
    item: RiskPolicyItem;
    riskPoliciesType: RiskPoliciesType;
    scopeId: string;
};

export function ActionsCell({ item, riskPoliciesType, scopeId }: ActionsProps) {
    return (
        item.custom
            ? <CustomActionsCell
                item={item as CustomRiskPolicyItem}
                riskPoliciesType={riskPoliciesType}
                scopeId={scopeId}/>
            : <BuiltInActionsCell
                item={item as BuiltInRiskPolicyItem}
                riskPoliciesType={riskPoliciesType}
                scopeId={scopeId}/>);
}