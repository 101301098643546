
import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ProjectIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <rect
                fill="#5BB5FF"
                height="5"
                width="4.87805"
                x="2"
                y="2"/>
            <rect
                fill="#5BB5FF"
                height="5"
                width="4.87805"
                x="9.31689"
                y="2"/>
            <rect
                fill="#5BB5FF"
                height="5"
                width="4.87805"
                x="2"
                y="9.5"/>
            <rect
                fill="#5BB5FF"
                height="5"
                width="4.87805"
                x="9.31689"
                y="9.5"/>
            <rect
                fill="#5BB5FF"
                height="5"
                width="4.87805"
                x="2"
                y="17"/>
            <rect
                fill="#5BB5FF"
                height="5"
                width="4.87805"
                x="9.31689"
                y="17"/>
            <rect
                fill="#5BB5FF"
                height="5"
                width="4.87805"
                x="17.1221"
                y="17"/>
            <rect
                fill="#5BB5FF"
                height="5"
                width="4.87805"
                x="17.1221"
                y="9.5"/>
            <rect
                fill="#5BB5FF"
                height="5"
                width="4.87805"
                x="17.1221"
                y="2"/>
        </SvgIcon>);
}