import { EmptyMessageText, ItemSelector, PasswordText, useLocalization } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { Contract, entityModelStore, KeyValueTable, KeyValueTableItem, SecretExclusionActionCell, useTheme } from "../../../../../../../../../../../../../../common";

export type ContainersSectionProps = { risk: Contract.GcpCloudRunServiceEnvironmentVariableSecretExistsRisk };

export function RevisionsSection({ risk }: ContainersSectionProps) {
    const serviceModel = entityModelStore.useGet(risk.entityId) as Contract.GcpCloudRunServiceModel;
    const latestRevisionId = serviceModel.revisionIds[0];
    const revisionIds = _.keys(risk.revisionIdToSecretEnvironmentVariableNamesMap);

    const revisionModels = entityModelStore.useGet(revisionIds) as Contract.GcpCloudRunServiceRevisionModel[];
    const revisionModelMap =
        _.keyBy(
            revisionModels,
            revisionModel => revisionModel.id);

    const sortedRevisionIds =
        _(revisionModels).
            orderBy(
                item => item.creationTime,
                "desc").
            map(revisionModel => revisionModel.id).
            value();

    const [selectedRevisionId, setSelectedRevisionId] = useState(sortedRevisionIds[0]);

    const theme = useTheme();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpCloudRunServiceEnvironmentVariableSecretExistsRiskDefinition.revisionsSection",
            () => ({
                environmentVariables: {
                    empty: "No environment variables",
                    valueDisplayName: "Value"
                },
                latest: "Latest"
            }));
    return (
        <Stack
            spacing={4}
            sx={{
                maxHeight: theme.spacing(60),
                overflow: "hidden auto"
            }}>
            <Box sx={{ flex: 1 }}>
                <ItemSelector
                    dense={true}
                    fullWidth={true}
                    items={sortedRevisionIds}
                    selectedItem={selectedRevisionId}
                    sorted={false}
                    onSelectedItemChanged={setSelectedRevisionId}>
                    {revisionId =>
                        revisionId === latestRevisionId
                            ? localization.latest()
                            : revisionModelMap[revisionId].entity.displayName}
                </ItemSelector>
            </Box>
            <KeyValueTable
                emptyMessageText={new EmptyMessageText(localization.environmentVariables.empty())}
                items={
                    _.map(
                        (revisionModelMap[selectedRevisionId].entity as Contract.GcpCloudRunServiceRevision).environmentVariableNameToValueMap,
                        (environmentVariableValue, environmentVariableName) =>
                            new KeyValueTableItem(
                                environmentVariableName,
                                environmentVariableValue))}
                key={selectedRevisionId}
                renderActions={
                    item =>
                        <SecretExclusionActionCell
                            displayNameToValueSecretDataMap={{
                                [localization.environmentVariables.valueDisplayName()]: {
                                    secret: _.includes(risk.revisionIdToSecretEnvironmentVariableNamesMap[selectedRevisionId], item.key),
                                    value: item.value
                                }
                            }}
                            risk={risk}
                            secretKey={item.key}/>}
                renderValue={
                    item =>
                        _.includes(risk.revisionIdToSecretEnvironmentVariableNamesMap[selectedRevisionId], item.key)
                            ? <PasswordText password={item.value!}/>
                            : item.value}
                rowOptions={{
                    getHighlightColor:
                        item =>
                            _.includes(risk.revisionIdToSecretEnvironmentVariableNamesMap[selectedRevisionId], item.key)
                                ? theme.palette.severity(risk.severity)
                                : undefined,
                    getSx:
                        item =>
                            _.includes(risk.revisionIdToSecretEnvironmentVariableNamesMap[selectedRevisionId], item.key)
                                ? { backgroundColor: theme.palette.opacity(theme.palette.severity(risk.severity), 0.1) }
                                : undefined
                }}/>
        </Stack>);
}