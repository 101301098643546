import { DataTableColumn, DataTableSortType, DeferredFilter, TimeFormatter, TimeRangeFilter, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, TimeRangeHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useCommonEntityDefinition } from "../useCommonEntityDefinition";

export function usePingIdentityCommonDirectoryEntityDefinition(definitionData: DefinitionData) {
    const commonEntityDefinition = useCommonEntityDefinition(definitionData);
    const directoryEntityModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.PingIdentityDirectoryEntityModelFilters>;

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.pingIdentity.usePingIdentityCommonDirectoryEntityDefinition",
            () => ({
                columns: {
                    creationTime: "Creation Time"
                }
            }));
    return {
        columns: {
            ...commonEntityDefinition.columns,
            creationTimeColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.PingIdentityDirectoryEntityModel) => ({
                                [localization.columns.creationTime()]: TimeFormatter.iso8601String((item.entity as Contract.PingIdentityDirectoryEntity).creationTime)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <DeferredFilter
                                    promiseOrGetPromise={directoryEntityModelFiltersPromise}
                                    title={localization.columns.creationTime()}>
                                    {filters =>
                                        <TimeRangeFilter
                                            emptyValue={filters.creationTimeRange.emptyValue}
                                            placeholder={localization.columns.creationTime()}
                                            timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.creationTimeRange.timeRange)}/>}
                                </DeferredFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.PingIdentityDirectoryEntityCreationTime}
                    itemProperty={(item: Contract.PingIdentityDirectoryEntityModel) => TimeFormatter.monthDayAndYear((item.entity as Contract.PingIdentityDirectoryEntity).creationTime)}
                    key={Contract.EntityModelProperty.PingIdentityDirectoryEntityCreationTime}
                    sortOptions={{ type: DataTableSortType.Date }}
                    title={localization.columns.creationTime()}/>
        }
    };
}