﻿import { Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useOpenRiskedEntityRisksStep } from "../../../../..";
import { Contract, SeveritySquare, useRiskSeverityReasonTranslator, useSeverityTranslator } from "../../../../../../../../../../../../../../common";
import { useResourceGeneralInformationStep } from "../../../../../useResourceGeneralInformationStep";

export type ContextSectionProps = {
    risk: Contract.AwsIamUserMfaDisabledRisk;
    userModel: Contract.AwsIamUserModel;
};

export function ContextSection({ risk, userModel }: ContextSectionProps) {
    const openRiskedEntityRisksStep = useOpenRiskedEntityRisksStep(userModel, risk.id);
    const resourceGeneralInformationStep = useResourceGeneralInformationStep(userModel);
    const severityReasonTranslator = useRiskSeverityReasonTranslator();
    const severityTranslator = useSeverityTranslator();
    const userPermissionActionSeverity = risk.userPermissionActionSeverity;

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsIamUserMfaDisabledRiskDefinition.contextSection",
            () => ({
                userPermissionSeverity: "These credentials would give an attacker {{severitySquare}}**{{severity}}** severity permissions if compromised"
            }));
    return (
        <Steps variant="bullets">
            {_.filter([
                resourceGeneralInformationStep,
                _.isNil(userPermissionActionSeverity)
                    ? undefined
                    : localization.userPermissionSeverity({
                        severity: severityTranslator(userPermissionActionSeverity, "text"),
                        severitySquare: <SeveritySquare severity={userPermissionActionSeverity}/>
                    }),
                openRiskedEntityRisksStep,
                severityReasonTranslator(
                    risk.severity,
                    risk.severityReason!,
                    {
                        userPermissionActionSeverity:
                            _.isNil(userPermissionActionSeverity)
                                ? undefined
                                : severityTranslator(userPermissionActionSeverity, "text")
                    })
            ])}
        </Steps>);
}