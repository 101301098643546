import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAzureComputeVirtualMachineResourceDefinition } from ".";
import { EntityTableDefinition } from "../..";
import { useCommonNetworkAccessResourceDefinition } from "..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAzureComputeVirtualMachineScaleSetTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useAzureComputeVirtualMachineScaleSetDefinition(definitionData: DefinitionData) {
    const commonNetworkAccessResourceDefinition = useCommonNetworkAccessResourceDefinition();
    const computeVirtualMachineResourceDefinition = useAzureComputeVirtualMachineResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const virtualMachineScaleSetModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.AzureComputeVirtualMachineScaleSetModelFilters>;

    const computeVirtualMachineScaleSetTypeTranslator = useAzureComputeVirtualMachineScaleSetTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureComputeVirtualMachineScaleSetDefinition",
            () => ({
                columns: {
                    type: "Orchestration Mode",
                    virtualMachineIds: "Virtual Machines"
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            computeVirtualMachineResourceDefinition.columns.rawIdColumn,
            computeVirtualMachineResourceDefinition.columns.tenantColumn,
            commonNetworkAccessResourceDefinition.columns.accessLevelColumn,
            computeVirtualMachineResourceDefinition.columns.privateIpAddressesColumn,
            computeVirtualMachineResourceDefinition.columns.publicIpAddressesColumn,
            commonNetworkAccessResourceDefinition.columns.inboundExternalAccessScopeColumn,
            commonNetworkAccessResourceDefinition.columns.destinationNetworkScopesColumn(virtualMachineScaleSetModelFiltersPromise),
            computeVirtualMachineResourceDefinition.columns.creationTimeColumn,
            computeVirtualMachineResourceDefinition.columns.creatorIdentityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureComputeVirtualMachineScaleSetModel) => ({
                            [localization.columns.type()]: computeVirtualMachineScaleSetTypeTranslator((item.entity as Contract.AzureComputeVirtualMachineScaleSet).type)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AzureComputeVirtualMachineScaleSetType}
                                enumTypeTranslator={computeVirtualMachineScaleSetTypeTranslator}
                                placeholder={localization.columns.type()}
                                sorted={false}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureComputeVirtualMachineScaleSetType}
                itemProperty={(item: Contract.AzureComputeVirtualMachineScaleSetModel) => computeVirtualMachineScaleSetTypeTranslator((item.entity as Contract.AzureComputeVirtualMachineScaleSet).type)}
                key={Contract.EntityModelProperty.AzureComputeVirtualMachineScaleSetType}
                title={localization.columns.type()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureComputeVirtualMachineScaleSetModel>(
                        Contract.TypeNames.AzureComputeVirtualMachineScaleSetVirtualMachine,
                        item => item.virtualMachineIds,
                        localization.columns.virtualMachineIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureComputeVirtualMachineScaleSetVirtualMachines)}
                                placeholder={localization.columns.virtualMachineIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureComputeVirtualMachineScaleSetVirtualMachines}
                key={Contract.EntityModelProperty.AzureComputeVirtualMachineScaleSetVirtualMachines}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureResourceGroupResourceModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={(item as Contract.AzureComputeVirtualMachineScaleSetModel).virtualMachineIds}
                            entityTypeName={Contract.TypeNames.AzureComputeVirtualMachineScaleSetVirtualMachine}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.virtualMachineIds()}/>,
            computeVirtualMachineResourceDefinition.columns.virtualNetworkColumn,
            computeVirtualMachineResourceDefinition.columns.loadBalancersColumn,
            computeVirtualMachineResourceDefinition.columns.applicationGatewaysColumn,
            computeVirtualMachineResourceDefinition.columns.regionColumn,
            computeVirtualMachineResourceDefinition.columns.regionLocationColumn,
            computeVirtualMachineResourceDefinition.columns.resourceGroupColumn,
            computeVirtualMachineResourceDefinition.columns.tagsColumn,
            computeVirtualMachineResourceDefinition.columns.attributesColumn,
            computeVirtualMachineResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            computeVirtualMachineResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            computeVirtualMachineResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureComputeVirtualMachineScaleSetRequest(
                new Contract.EntityControllerGetEntityModelPageAzureComputeVirtualMachineScaleSetRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureResourceGroupResourceResourceGroup]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureComputeVirtualMachineResourceApplicationGateways]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureComputeVirtualMachineResourceLoadBalancers]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureComputeVirtualMachineResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureComputeVirtualMachineResourceVirtualNetwork]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureComputeVirtualMachineScaleSetType]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureComputeVirtualMachineScaleSetVirtualMachines])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestNetworkAccess(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundAccessType]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalAccessScope]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalDestinationNetworkScopes]))));
}