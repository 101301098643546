import { InlineItems, Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsLambdaFunctionConfigurationRiskContext, useResourceSecretExistsRiskContext } from "../../../..";
import { Contract, Entity, entityModelStore, useTheme } from "../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem, RiskDefinitionSection } from "../../../../../../../utilities";
import { RevisionSection } from "./components";

export function useAwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsRisk;
    const lambdaFunctionConfigurationEnvironmentVariableSecretExistsRiskModel = riskModel as Contract.AwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsRiskModel;
    const functionConfigurationModel = entityModelStore.useGet(risk.entityId) as Contract.AwsLambdaFunctionConfigurationModel;
    const functionConfiguration = functionConfigurationModel.entity as Contract.AwsLambdaFunctionConfiguration;
    const environmentVariablesPermissionsIdentityModels = entityModelStore.useGet(risk.environmentVariablesPermissionsIdentityIds);
    const environmentVariablesPermissionsThirdPartyRoleModels =
        _.filter(
            environmentVariablesPermissionsIdentityModels,
            environmentVariablesPermissionsIdentityModel =>
                environmentVariablesPermissionsIdentityModel.typeName == Contract.TypeNames.AwsIamRoleModel &&
                (environmentVariablesPermissionsIdentityModel.entity as Contract.AwsIamRole).vendor);

    const getAwsLambdaFunctionConfigurationRiskContext = useGetAwsLambdaFunctionConfigurationRiskContext();
    const secretContextItems = useResourceSecretExistsRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsRiskDefinition",
            () => ({
                description: "{{functionConfiguration}} is exposing secrets using {{environmentVariables}} in {{revisions}}",
                environmentVariables: [
                    "1 environment variable",
                    "{{count | NumberFormatter.humanize}} environment variables"
                ],
                identities: {
                    identities: [
                        "1 identity",
                        "{{count | NumberFormatter.humanize}} identities"
                    ],
                    text: "{{identities}}{{thirdPartyRolesPart}} are granted with permissions allowing them to read your secrets",
                    thirdPartyRolesPart: {
                        text: " including {{thirdPartyRoles}}",
                        thirdPartyRoles: [
                            "1 third party role",
                            "{{count | NumberFormatter.humanize}} third party roles"
                        ]
                    }
                },
                revisions: [
                    "1 revision",
                    "{{count | NumberFormatter.humanize}} revisions"
                ],
                sections: {
                    resolution: {
                        step1: {
                            link: "Secrets Manager",
                            text: "Migrate your secrets to {{secretsManagerGuideLink}}"
                        },
                        step2: "Click on **Remove** for the environment variables exposing secrets",
                        step3: "Click **Save** to confirm",
                        step4: "Click **Actions**, **Publish new version** and **Publish** buttons",
                        step5: "Update your applications to use the new secured version",
                        step6: "Click on **Versions**, check all the versions that contain secrets and click on the **Delete** button"
                    },
                    revisions: "Revisions"
                }
            }));
    const theme = useTheme();
    const identities =
        localization.identities.text({
            identities:
                <InlineItems
                    items={
                        _.map(
                            environmentVariablesPermissionsIdentityModels,
                            environmentVariablesPermissionsIdentityModel =>
                                <Entity
                                    entityIdOrModel={environmentVariablesPermissionsIdentityModel}
                                    key={environmentVariablesPermissionsIdentityModel.id}
                                    linkOptions={{ disabledHighlight: false }}
                                    variant="iconTextTypeTenant"/>)}
                    namePluralizer={localization.identities.identities}
                    sx={{ color: theme.palette.text.primary }}
                    variant="itemCountAndType"/>,
            thirdPartyRolesPart:
                environmentVariablesPermissionsThirdPartyRoleModels.length <= 1
                    ? ""
                    : localization.identities.thirdPartyRolesPart.text({
                        thirdPartyRoles:
                            <InlineItems
                                items={
                                    _.map(
                                        environmentVariablesPermissionsThirdPartyRoleModels,
                                        environmentVariablesPermissionsThirdPartyRoleModel =>
                                            <Entity
                                                entityIdOrModel={environmentVariablesPermissionsThirdPartyRoleModel}
                                                key={environmentVariablesPermissionsThirdPartyRoleModel.id}
                                                linkOptions={{ disabledHighlight: false }}
                                                variant="iconTextTypeTenant"/>)}
                                namePluralizer={localization.identities.thirdPartyRolesPart.thirdPartyRoles}
                                sx={{ color: theme.palette.text.primary }}
                                variant="itemCountAndType"/>
                    })
        });
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            environmentVariables:
                <InlineItems
                    items={lambdaFunctionConfigurationEnvironmentVariableSecretExistsRiskModel.secretEnvironmentVariableNames}
                    namePluralizer={localization.environmentVariables}
                    variant="itemCountAndType"/>,
            functionConfiguration:
                <Entity
                    entityIdOrModel={functionConfigurationModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>,
            revisions:
                <InlineItems
                    items={_.keys(risk.revisionIdToSecretEnvironmentVariableNamesMap)}
                    namePluralizer={localization.revisions}
                    variant="itemCountAndType"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Lambda,
                AwsConsoleUrlBuilder.getLambdaFunctionConfigurationEditEnvironmentVariablesUrl(functionConfiguration)),
            localization.sections.resolution.step1.text({
                secretsManagerGuideLink:
                    <Link
                        urlOrGetUrl={lambdaFunctionConfigurationEnvironmentVariableSecretExistsRiskModel.secretsManagerDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step1.link()}
                    </Link>
            }),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6()
        ],
        riskModel,
        () => {
            const functionConfigurationContextItems = getAwsLambdaFunctionConfigurationRiskContext(functionConfigurationModel);
            return [
                functionConfigurationContextItems.generalInformation,
                functionConfigurationContextItems.sensitive,
                secretContextItems.getSecretExistsContextItem(
                    risk.entityId,
                    Contract.TypeNames.AwsLambdaFunctionConfiguration,
                    lambdaFunctionConfigurationEnvironmentVariableSecretExistsRiskModel.secretEnvironmentVariableNames),
                secretContextItems.getExcludedSecretsContextItem(
                    risk.excludedSecretEnvironmentVariableNames,
                    risk.typeName,
                    risk.tenantId),
                new RiskDefinitionContextItem(identities),
                functionConfigurationContextItems.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <RevisionSection
                        functionConfigurationModel={functionConfigurationModel}
                        risk={risk}/>,
                    localization.sections.revisions(),
                    {
                        expandable: true
                    })
            ]
        });
}