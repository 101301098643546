import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function BitnamiIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <g clipPath="url(#clip0_52885_254684)">
                <path
                    clipRule="evenodd"
                    d="M14.4356 10.6054L11.9995 9.22461L9.56348 10.6055L11.9995 12L14.4356 10.6054Z"
                    fill="#00577B"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M12.001 14.7474L14.4371 13.366V10.6035L12.001 11.9848V14.7474Z"
                    fill="#1E384B"
                    fillRule="evenodd"/>
                <path
                    clipRule="evenodd"
                    d="M11.9995 14.7474L9.56348 13.366V10.6035L11.9995 11.9848V14.7474Z"
                    fill="#15211F"
                    fillRule="evenodd"/>
                <path
                    d="M17.804 8.72725V15.2871L11.9998 18.5776L6.19493 15.2871V15.2773L2.29492 17.4779C2.49933 17.8322 2.79101 18.1139 3.11399 18.2971C5.68804 19.7561 8.2616 21.2155 10.8358 22.6745C11.5913 23.1029 12.4043 23.1049 13.1555 22.6792L20.8961 18.2909C21.6187 17.8813 21.9997 17.2313 21.9997 16.4047V7.66003C21.9997 7.22989 21.904 6.84627 21.7198 6.51758L17.804 8.72725Z"
                    fill="#00437C"/>
                <mask
                    height="15"
                    id="mask0_52885_254684"
                    maskUnits="userSpaceOnUse"
                    width="5"
                    x="2"
                    y="3">
                    <path
                        d="M3.10312 5.6662C2.38402 6.07386 2 6.47746 2 7.27686V16.3759C2 16.7916 2.11285 17.1658 2.29533 17.4824L6.19549 15.2818V3.91273L3.10312 5.6662Z"
                        fill="white"/>
                </mask>
                <g mask="url(#mask0_52885_254684)">
                    <path
                        d="M3.10312 5.6662C2.38402 6.07386 2 6.47746 2 7.27686V16.3759C2 16.7916 2.11285 17.1658 2.29533 17.4824L6.19549 15.2818V3.91273L3.10312 5.6662Z"
                        fill="url(#paint0_linear_52885_254684)"/>
                </g>
                <mask
                    height="9"
                    id="mask1_52885_254684"
                    maskUnits="userSpaceOnUse"
                    width="13"
                    x="9"
                    y="0">
                    <path
                        d="M10.8779 1.29636L9.56055 2.04316V6.8004L12.0003 5.41744L17.8045 8.70745V8.72852L21.7202 6.51916C21.5314 6.18235 21.2498 5.90361 20.8791 5.69354L13.125 1.29776C12.7835 1.10253 12.3956 0.999316 12.0004 0.998572C11.6061 0.999216 11.2189 1.10193 10.8779 1.29636Z"
                        fill="white"/>
                </mask>
                <g mask="url(#mask1_52885_254684)">
                    <path
                        d="M10.8779 1.29636L9.56055 2.04316V6.8004L12.0003 5.41744L17.8045 8.70745V8.72852L21.7202 6.51916C21.5314 6.18235 21.2498 5.90361 20.8791 5.69354L13.125 1.29776C12.7835 1.10253 12.3956 0.999316 12.0004 0.998572C11.6061 0.999216 11.2189 1.10193 10.8779 1.29636Z"
                        fill="url(#paint1_linear_52885_254684)"/>
                </g>
            </g>
            <defs>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint0_linear_52885_254684"
                    x1="77.5608"
                    x2="484.559"
                    y1="-1069.42"
                    y2="-961.423">
                    <stop stopColor="#00437C"/>
                    <stop
                        offset="1"
                        stopColor="#093266"/>
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint1_linear_52885_254684"
                    x1="9.56055"
                    x2="21.7202"
                    y1="-377.769"
                    y2="-377.769">
                    <stop stopColor="#00437C"/>
                    <stop
                        offset="1"
                        stopColor="#3984B5"/>
                </linearGradient>
                <clipPath id="clip0_52885_254684">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}