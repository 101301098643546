import { ActionButton, DeleteIcon, InfoIcon, Tooltip, useChangeEffect, useInputValidation, useLocalization } from "@infrastructure";
import { Box, Button, FormControl, FormHelperText, Stack, TextField } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, useTheme } from "../../../../../../../../../../../../../../../../../common";
import { HttpHeadersItem } from "../HttpHeaders";

type ItemProps = {
    item: HttpHeadersItem;
    onDelete?: () => void;
    onItemChanged: (item: HttpHeadersItem) => void;
    onValidChanged?: (valid: boolean) => void;
};

export function Item({ item, onDelete, onItemChanged, onValidChanged }: ItemProps) {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useWebhooksItems.webhook.addOrEdit.httpHeaders.item",
            () => ({
                fields: {
                    encrypted: "Convert to secret",
                    error: {
                        illegalHeader: "Header cannot contain special characters",
                        nameRequired: "Name cannot be empty"
                    },
                    name: "Name",
                    tooltip: "Note that after conversion to a secret you will not be able to see it again",
                    value: "Value"
                }
            }));

    const [httpHeaderValidationController, httpHeaderValidationMessage] =
        useInputValidation(
            () => {
                const validationName = item.httpHeader.name.trim();
                if (_.isEmpty(validationName)) {
                    return localization.fields.error.nameRequired();
                }

                const httpHeaderNameValidationRegex = /^[!#$%&'*+.^_`|~0-9a-zA-Z-]+$/;
                if (!httpHeaderNameValidationRegex.test(item.httpHeader.name)) {
                    return localization.fields.error.illegalHeader();
                }
                return undefined;
            },
            [item.httpHeader]);

    useChangeEffect(
        () => {
            onValidChanged?.(httpHeaderValidationController.isValid());
        },
        [item]);

    const theme = useTheme();
    return (
        <Stack>
            <Stack
                alignItems="center"
                direction="row"
                spacing={2}>
                <Box sx={{ width: theme.spacing(30) }}>
                    <FormControl
                        fullWidth={true}
                        variant="standard">
                        <TextField
                            label={localization.fields.name()}
                            value={item.httpHeader.name}
                            variant="outlined"
                            onChange={
                                event =>
                                    onItemChanged(
                                        new HttpHeadersItem(
                                            new Contract.WebhookManagerHttpHeader(
                                                item.httpHeader.encrypted,
                                                item.httpHeader.id,
                                                event.target.value,
                                                item.httpHeader.value),
                                            item.id))}/>
                    </FormControl>
                </Box>
                <Box sx={{ width: theme.spacing(30) }}>
                    <FormControl
                        fullWidth={true}
                        variant="standard">
                        <TextField
                            disabled={item.httpHeader.encrypted}
                            label={localization.fields.value()}
                            type={
                                item.httpHeader.encrypted
                                    ? "password"
                                    : "text"}
                            value={
                                item.httpHeader.encrypted
                                    ? _.repeat("*", 10)
                                    : item.httpHeader.value}
                            variant="outlined"
                            onChange={
                                event =>
                                    onItemChanged(
                                        new HttpHeadersItem(
                                            new Contract.WebhookManagerHttpHeader(
                                                item.httpHeader.encrypted,
                                                item.httpHeader.id,
                                                item.httpHeader.name,
                                                event.target.value),
                                            item.id))}/>
                    </FormControl>
                </Box>
                {!_.isNil(onDelete) && (
                    <ActionButton onClick={() => onDelete()}>
                        <DeleteIcon/>
                    </ActionButton>)}
                {!_.isNil(onDelete) &&
                    !item.httpHeader.encrypted && (
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}>
                        <Button
                            disabled={_.isEmpty(item.httpHeader.value)}
                            variant="outlined"
                            onClick={
                                () =>
                                    onItemChanged(
                                        new HttpHeadersItem(
                                            new Contract.WebhookManagerHttpHeader(
                                                true,
                                                item.httpHeader.id,
                                                item.httpHeader.name,
                                                item.httpHeader.value),
                                            item.id))}>
                            {localization.fields.encrypted()}
                        </Button>
                        <Tooltip titleOrGetTitle={localization.fields.tooltip()}>
                            <InfoIcon
                                sx={{
                                    color: theme.palette.text.secondary,
                                    fontSize: "18px"
                                }}/>
                        </Tooltip>
                    </Stack>)}
            </Stack>
            <Box>
                {!_.isNil(httpHeaderValidationMessage) &&
                    !_.isNil(onDelete) && (
                    <FormHelperText error={true}>{httpHeaderValidationMessage}</FormHelperText>)}
            </Box>
        </Stack>);
}