import { EmptyMessageText, Link, Message, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useCommonEntityNetworkInfoItemElements, useCustomEntityPropertyInfoItemElements } from "../..";
import { useAzureCommonEncryptedResourceInfoElements, useAzureDefaultResourceInfoItemElements } from "..";
import { Contract, CustomerConsoleAppUrlHelper, InfoItem } from "../../../../../../../../../../../common";
import { useAzureContainerRegistryRegistrySkuTranslator } from "../../../../../../../hooks";
import { EntitiesInfoCard, EntitiesInfoItem, Info } from "../../../../../components";
import { AzureResourceRoleAssignmentResources, NetworkingInfoCard } from "../../../components";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../../useDefinition";


export function useAzureContainerRegistryRegistryDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const commonEncryptedResourceInfoElements = useAzureCommonEncryptedResourceInfoElements(resourceGroupResourceModel);
    const commonEntityNetworkInfoItemElements = useCommonEntityNetworkInfoItemElements(resourceGroupResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);
    const registry = resourceGroupResourceModel.entity as Contract.AzureContainerRegistryRegistry;

    const containerRegistryRegistrySkuTranslator = useAzureContainerRegistryRegistrySkuTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureContainerRegistryRegistryDefinition",
            () => ({
                info: {
                    cards: {
                        repositoryIds: {
                            empty: "No Repositories",
                            title: "Repositories",
                            warning: {
                                classic: "Since this container registry's service tier is Classic, which is deprecated, Tenable Cloud Security can't display its container repositories.",
                                restricted: {
                                    link: "Tenable Cloud Security's IP addresses",
                                    text: "Since this container registry's network access is restricted, Tenable Cloud Security can't display its container repositories. To resolve, grant the registry access to {{documentationApplicationIpAddressesLink}}."
                                },
                                title: "Missing repositories"
                            }
                        }
                    },
                    items: {
                        accessKey: {
                            false: "Disabled",
                            title: "Admin User",
                            true: "Enabled"
                        },
                        allowAnyAzureTrustedServices: {
                            false: "Disabled",
                            title: "Allow Azure Services Access",
                            true: "Enabled"
                        },
                        firewallRuleSubnets: {
                            rules: "{{subnets}}",
                            subnets: [
                                "1 IP address range",
                                "{{count | NumberFormatter.humanize}} IP address ranges"
                            ],
                            title: "Inbound IP Address ranges"
                        },
                        networkAccess: {
                            title: "Network Access",
                            [Contract.TypeNames.AzureContainerRegistryRegistryNetworkAccessType]: {
                                [Contract.AzureContainerRegistryRegistryNetworkAccessType.Public]: "All Networks",
                                [Contract.AzureContainerRegistryRegistryNetworkAccessType.Restricted]: "Selected Networks",
                                [Contract.AzureContainerRegistryRegistryNetworkAccessType.Private]: "Disabled"
                            }
                        },
                        sku: "SKU",
                        url: "Login Server",
                        zoneRedundancy: {
                            false: "Disabled",
                            title: "Availability Zones",
                            true: "Enabled"
                        }
                    }
                },
                tabs: {
                    resourceRoleAssignments: "Resource Role Assignments"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AzureResourceRoleAssignmentResources
                    csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                    scopeResourceModel={resourceGroupResourceModel}/>,
                localization.tabs.resourceRoleAssignments(),
                "resourceRoleAssignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="zoneRedundancy"
                        title={localization.info.items.zoneRedundancy.title()}
                        value={
                            registry.zoneRedundancy
                                ? localization.info.items.zoneRedundancy.true()
                                : localization.info.items.zoneRedundancy.false()}/>,
                    <InfoItem
                        key="url"
                        title={localization.info.items.url()}
                        value={
                            <Link
                                urlOrGetUrl={registry.url}
                                variant="external"/>}/>,
                    <InfoItem
                        key="sku"
                        title={localization.info.items.sku()}
                        value={containerRegistryRegistrySkuTranslator(registry.sku)}/>,
                    <InfoItem
                        key="accessKey"
                        title={localization.info.items.accessKey.title()}
                        value={
                            registry.accessKey
                                ? localization.info.items.accessKey.true()
                                : localization.info.items.accessKey.false()}/>,
                    <InfoItem
                        key="networkAccess"
                        title={localization.info.items.networkAccess.title()}
                        value={localization.info.items.networkAccess[Contract.TypeNames.AzureContainerRegistryRegistryNetworkAccessType][registry.networkAccessType]()}/>,
                    <InfoItem
                        key="allowAnyAzureTrustedServices"
                        title={localization.info.items.allowAnyAzureTrustedServices.title()}
                        value={
                            registry.allowAnyAzureTrustedServices
                                ? localization.info.items.allowAnyAzureTrustedServices.true()
                                : localization.info.items.allowAnyAzureTrustedServices.false()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={
                            registry.networkAccessType !== Contract.AzureContainerRegistryRegistryNetworkAccessType.Restricted
                                ? undefined
                                : registry.firewallRuleSubnets}
                        entityTypeName={Contract.TypeNames.AzureNetworkVirtualNetworkSubnet}
                        key="firewallRuleSubnets"
                        title={localization.info.items.firewallRuleSubnets.title()}/>
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {commonEntityNetworkInfoItemElements.inboundAccessType}
                    {commonEntityNetworkInfoItemElements.inboundExternalAccessScope}
                </NetworkingInfoCard>
                <EntitiesInfoCard
                    emptyMessage={new EmptyMessageText(localization.info.cards.repositoryIds.empty())}
                    entityIdsOrModels={registry.repositoryIds ?? []}
                    entityTypeName={Contract.TypeNames.AzureContainerRegistryRepository}
                    title={localization.info.cards.repositoryIds.title()}
                    titleInfoElement={
                        !_.isNil(registry.repositoryIds)
                            ? undefined
                            : <Typography>
                                <Message
                                    description={localization.info.cards.repositoryIds.warning.title()}
                                    level="warning"
                                    title={
                                        registry.sku === Contract.AzureContainerRegistryRegistrySku.Classic
                                            ? localization.info.cards.repositoryIds.warning.classic()
                                            : localization.info.cards.repositoryIds.warning.restricted.text({
                                                documentationApplicationIpAddressesLink:
                                                    <Link
                                                        urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsArchitectureAndDataHandlingApplicationIpAddressesRelativeUrl()}
                                                        variant="external">
                                                        {localization.info.cards.repositoryIds.warning.restricted.link()}
                                                    </Link>
                                            })}
                                    variant="minimal"/>
                            </Typography>}/>
            </Info>
    });
}