import { useLocalization } from "@infrastructure";
import { Contract } from "..";

export function useActionRiskCategoryTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useActionRiskCategoryTranslator",
            () => ({
                [Contract.TypeNames.ActionRiskCategory]: {
                    [Contract.ActionRiskCategory.DataLeakage]: {
                        initials: "DA",
                        title: "Data Access"
                    },
                    [Contract.ActionRiskCategory.InfrastructureModification]: {
                        initials: "IM",
                        title: "Infrastructure Modifications"
                    },
                    [Contract.ActionRiskCategory.PermissionManagement]: {
                        initials: "PM",
                        title: "Permissions Management"
                    },
                    [Contract.ActionRiskCategory.PrivilegeEscalation]: {
                        initials: "PE",
                        title: "Privilege Escalation"
                    },
                    [Contract.ActionRiskCategory.Reconnaissance]: {
                        initials: "R",
                        title: "Reconnaissance"
                    }
                }
            }));
    return (actionRiskCategory: Contract.ActionRiskCategory) => ({
        initials: localization[Contract.TypeNames.ActionRiskCategory][actionRiskCategory].initials(),
        title: localization[Contract.TypeNames.ActionRiskCategory][actionRiskCategory].title()
    });
}