﻿import { useLocalization } from "@infrastructure";

export function useAwsSetInstanceMetadataServiceTokenHopMaxCountChangeDefinition() {
    const localization =
        useLocalization(
            "common.change.hooks.useDefinition.hooks.useAwsDefinition.hooks.useAwsSetInstanceMetadataServiceTokenHopMaxCountChangeDefinition",
            () => ({
                title: "Update EC2 instance metadata options"
            }));
    return {
        title: localization.title()
    };
}