﻿import { DataTableColumn, optionalTableCell, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, EntitiesCell, EntityFilter, ItemSelectionHelper } from "../../../../../../../../../common";
import { TableColumnId } from "../../../Table";
import { TenantDefinition } from "../useDefinition";
import { useCommonColumns } from "./useCommonColumns";

export function useGcpDefinition(filters: Contract.PermissionManagementControllerPermissionEligibilityFilters): TenantDefinition {
    const gcpFilters = filters as Contract.PermissionManagementControllerGcpPermissionEligibilityFilters;
    const localization =
        useLocalization(
            "views.user.permissionEligibilities.table.hooks.useDefinition.hooks.useGcpDefinition",
            () => ({
                columns: {
                    roleIds: "Permissions"
                }
            }));
    const commonColumns = useCommonColumns();
    return new TenantDefinition(
        (commonFilters, filterMap, limit, scope, skip, sort) =>
            new Contract.PermissionManagementControllerGetGcpPermissionEligibilityModelPageRequest(
                new Contract.PermissionManagementControllerGetGcpPermissionEligibilityModelPageRequestFilters(
                    commonFilters.approvalRequired,
                    commonFilters.approverPrincipalIdSelection,
                    commonFilters.granteePrincipalIdSelection,
                    commonFilters.nameSelection,
                    commonFilters.principalTenantIdSelection,
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.GcpRoleIds])),
                limit,
                scope,
                skip,
                sort),
        getEntitiesExportOptions =>
            [
                <DataTableColumn
                    exportOptions={
                        getEntitiesExportOptions(
                            item => (item as Contract.GcpRoleBindingEligibilityModel).roleIdReferences,
                            localization.columns.roleIds())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EntityFilter
                                    entityIdsOrSearchableReferences={gcpFilters.roleSearchableReferences}
                                    placeholder={localization.columns.roleIds()}/>
                        }
                    }}
                    id={TableColumnId.GcpRoleIds}
                    key={TableColumnId.GcpRoleIds}
                    render={
                        optionalTableCell<Contract.PermissionEligibilityModel>(
                            item =>
                                <EntitiesCell
                                    entityIdsOrModels={(item as Contract.GcpRoleBindingEligibilityModel).roleIdReferences}
                                    entityLinkOptions={{ disabled: true }}
                                    entityTypeName={Contract.TypeNames.GcpIamRole}
                                    entityVariant="iconText"/>)}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.roleIds()}/>,
                commonColumns.permissionRequestScopeIds
            ]);
}