import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity } from "../../../../../../..";

export function useAwsDeleteUserGroupMembershipChangeDefinition(change: Contract.AwsChange, entityLinkDisabled?: boolean) {
    const deleteUserGroupMembershipChange = change as Contract.AwsDeleteUserGroupMembershipChange;

    const localization =
        useLocalization(
            "common.change.hooks.useDefinition.hooks.useAwsDefinition.hooks.useAwsDeleteUserGroupMembershipChangeDefinition",
            () => ({
                title: "Remove {{user}} from {{group}}"
            }));

    return {
        title:
            localization.title({
                group:
                    <Entity
                        entityIdOrModel={deleteUserGroupMembershipChange.groupId}
                        entityTypeNameTranslatorOptions={{ variant: "text" }}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: entityLinkDisabled }}
                        variant="typeText"/>,
                user:
                    <Entity
                        entityIdOrModel={deleteUserGroupMembershipChange.resourceId}
                        entityTypeNameTranslatorOptions={{ variant: "text" }}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: entityLinkDisabled }}
                        variant="typeText"/>
            })
    };
}