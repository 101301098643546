﻿import { useChangeEffect, useLocalization, useUniqueKey } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, ScopeHelper, ScopeMultiSelect, scopeNodeModelStore, tenantModelStore, useScopeNavigationViewContext } from "../../../../../../../../../../common";
import { useAddOrEditContext, useSetAddOrEditContext } from "../../../../AddOrEdit";
import { PermissionAssignmentEligibilityData } from "../../utilities";

export function PermissionRequestScopes() {
    const { permissionEligibilityData, upsertPermissionEligibilityExecuting } = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();

    const { scopeNodeModel } = useScopeNavigationViewContext();
    const tenantTypes =
        useMemo(
            () => [ScopeHelper.getTenantType(scopeNodeModel)!],
            [scopeNodeModel]);

    const { permissionRequestScopeIds, principalTenantId, scopeId } = permissionEligibilityData as PermissionAssignmentEligibilityData;

    const localization =
        useLocalization(
            "views.user.permissionEligibilities.addOrEdit.hooks.useDefinition.permissionAssignmentEligibility.permissionRequestScopes",
            () => ({
                emptyValue: "All existing and future accounts",
                title: "Accounts"
            }));

    const [multiSelectKey, updateMultiSelectKey] = useUniqueKey();

    useChangeEffect(
        () => {
            updateMultiSelectKey();
        },
        [principalTenantId]);

    const activeCloudProviderTenantModels = tenantModelStore.useGetActiveCloudProviderTenants(tenantTypes) as Contract.CloudProviderTenantModel[];
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const principalTenantActiveCloudProviderTenantIds =
        useMemo(
            () => {
                if (_.isNil(principalTenantId)) {
                    return [];
                }

                const scopeTenantIds = scopeNodeMap[scopeId].tenantIds;

                return _(activeCloudProviderTenantModels).
                    filter(
                        activeCloudProviderTenantModel =>
                            _.includes(scopeTenantIds, activeCloudProviderTenantModel.configuration.id) &&
                            activeCloudProviderTenantModel.state.permissionManagementEnabled &&
                            activeCloudProviderTenantModel.state.permissionManagementPrincipalTenantId === principalTenantId).
                    map(activeCloudProviderTenantModel => activeCloudProviderTenantModel.configuration.id).
                    value();
            },
            [activeCloudProviderTenantModels, principalTenantId, scopeId, scopeNodeMap]);

    return (
        <Stack spacing={1.5}>
            <Typography variant="h4">
                {localization.title()}
            </Typography>
            <ScopeMultiSelect
                disabled={
                    _.isNil(principalTenantId) ||
                    upsertPermissionEligibilityExecuting}
                emptyValue={localization.emptyValue()}
                fullWidth={true}
                key={multiSelectKey}
                rootFolderId={scopeId}
                scopeIds={principalTenantActiveCloudProviderTenantIds}
                selectedScopeIds={permissionRequestScopeIds}
                tenantTypes={tenantTypes}
                variant="itemSelector"
                onSelectedScopeIdsChanged={
                    permissionRequestScopeIds =>
                        setAddOrEditContext(
                            addOrEditContext => {
                                (permissionEligibilityData as PermissionAssignmentEligibilityData).permissionRequestScopeIds = permissionRequestScopeIds;
                                return { ...addOrEditContext };
                            })}/>
        </Stack>);
}