import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function GoVulnerabilityDatabaseIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M1.81139 10.6961C1.76465 10.6961 1.75296 10.6729 1.77633 10.638L2.02171 10.3242C2.04508 10.2894 2.1035 10.2661 2.15024 10.2661H6.32161C6.36835 10.2661 6.38003 10.301 6.35666 10.3358L6.15803 10.638C6.13466 10.6729 6.07624 10.7078 6.04118 10.7078L1.81139 10.6961Z"
                fill="#00ACD7"/>
            <path
                d="M0.0467381 11.7655C0 11.7655 -0.0116845 11.7422 0.0116845 11.7074L0.257059 11.3936C0.280428 11.3587 0.338851 11.3354 0.385589 11.3354H5.71373C5.76047 11.3354 5.78384 11.3703 5.77215 11.4052L5.67868 11.6841C5.66699 11.7306 5.62025 11.7539 5.57351 11.7539L0.0467381 11.7655Z"
                fill="#00ACD7"/>
            <path
                d="M2.87486 12.8348C2.82813 12.8348 2.81644 12.7999 2.83981 12.7651L3.00339 12.4745C3.02676 12.4397 3.0735 12.4048 3.12024 12.4048H5.45714C5.50388 12.4048 5.52725 12.4397 5.52725 12.4861L5.50388 12.7651C5.50388 12.8116 5.45714 12.8464 5.42209 12.8464L2.87486 12.8348Z"
                fill="#00ACD7"/>
            <path
                d="M15.0032 10.4873C14.2671 10.6732 13.7647 10.8127 13.0402 10.9986C12.865 11.0451 12.8533 11.0567 12.7014 10.8824C12.5261 10.6848 12.3976 10.557 12.1522 10.4408C11.4161 10.0805 10.7033 10.1851 10.0373 10.6151C9.24276 11.1265 8.83381 11.8819 8.84549 12.8233C8.85718 13.7531 9.49982 14.5202 10.4229 14.648C11.2174 14.7526 11.8835 14.4737 12.4093 13.881C12.5144 13.7531 12.6079 13.6136 12.7248 13.4509C12.3041 13.4509 11.7783 13.4509 10.4696 13.4509C10.2243 13.4509 10.1658 13.2998 10.2476 13.1023C10.3995 12.742 10.68 12.1376 10.8435 11.8354C10.8786 11.7657 10.9604 11.6495 11.1357 11.6495C11.7316 11.6495 13.9283 11.6495 15.3888 11.6495C15.3655 11.9633 15.3655 12.2771 15.3187 12.5909C15.1902 13.4277 14.8747 14.1948 14.3606 14.8688C13.5193 15.973 12.421 16.6587 11.0305 16.8446C9.88541 16.9957 8.82212 16.7749 7.88736 16.0776C7.02271 15.4267 6.53196 14.5667 6.40343 13.4974C6.25153 12.2306 6.62543 11.0916 7.39661 10.0921C8.22621 9.01122 9.32456 8.32551 10.6683 8.08144C11.7666 7.88386 12.8182 8.01171 13.7647 8.65093C14.384 9.05771 14.828 9.61558 15.1201 10.2897C15.1902 10.3943 15.1434 10.4524 15.0032 10.4873Z"
                fill="#00ACD7"/>
            <path
                d="M18.87 16.9142C17.8067 16.8909 16.8369 16.5887 16.019 15.8914C15.3296 15.2987 14.8973 14.5432 14.7571 13.6483C14.5468 12.335 14.909 11.1728 15.7035 10.1384C16.5565 9.02265 17.5847 8.44153 18.9752 8.19746C20.167 7.98826 21.2887 8.10449 22.3053 8.7902C23.2284 9.4178 23.8009 10.2662 23.9528 11.382C24.1514 12.951 23.6957 14.2294 22.6091 15.3219C21.8379 16.1006 20.8915 16.5887 19.8048 16.8096C19.4893 16.8677 19.1738 16.8793 18.87 16.9142ZM21.651 12.2188C21.6393 12.0677 21.6393 11.9515 21.6159 11.8352C21.4056 10.6846 20.3423 10.0338 19.2323 10.2895C18.1456 10.5335 17.4445 11.2193 17.1875 12.3117C16.9771 13.2183 17.4212 14.1364 18.2624 14.5084C18.9051 14.7873 19.5477 14.7524 20.167 14.4386C21.0901 13.9621 21.5925 13.2183 21.651 12.2188Z"
                fill="#00ACD7"/>
        </SvgIcon>);
}