import { Link, Steps, useLocalization, useSetOrderedWizardContext } from "@infrastructure";
import React, { useEffect } from "react";
import { ConsoleAppUrlHelper, useTheme } from "../../../../../../../../../../../../../../../../common";
import { InstructionsImageUrl } from "./images";

export function ExtensionSetupItem() {
    const setOrderedWizardContext = useSetOrderedWizardContext();
    useEffect(
        () => {
            setOrderedWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    sideElement:
                        <img src={InstructionsImageUrl}/>
                }));
        },
        []);
    const theme = useTheme();
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useObservabilityItems.qRadar.addOrEdit.extensionSetupItem",
            () => ({
                download: "**Download**",
                steps: {
                    step1: "{{link}} the Tenable Cloud Security QRadar extension (named Ermetic)",
                    step2: "In the QRadar Admin page, click on **Extension Management**",
                    step3: "Add the Tenable Cloud Security QRadar extension and click on **Install**"
                }
            }));

    return (
        <Steps variant="letters">
            {localization.steps.step1({
                link:
                    <Link
                        sx={{
                            color: theme.palette.text.primary,
                            textDecoration: "underline"
                        }}
                        urlOrGetUrl={ConsoleAppUrlHelper.getResourcesQRadarErmeticExtensionRelativeUrl()}
                        variant="external">
                        {localization.download()}
                    </Link>
            })}
            {localization.steps.step2()}
            {localization.steps.step3()}
        </Steps>);
}