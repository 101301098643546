import { useLocalization } from "@infrastructure";

export function useAwsDeleteRepositoryPolicyChangeDefinition() {
    const localization =
        useLocalization(
            "common.change.hooks.useDefinition.hooks.useAwsDefinition.hooks.useAwsDeleteRepositoryPolicyChangeDefinition",
            () => ({
                title: "Delete the repository policy"
            }));
    return {
        title: localization.title()
    };
}