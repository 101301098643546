﻿import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, Optional, optionalTableCell, StringHelper, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, InfoCard } from "../../../../../../../../../common";

type KubernetesIngressRulesInfoCardProps = {
    rules: Contract.KubernetesIngressDataRule[];
};

export function KubernetesIngressRulesInfoCard({ rules }: KubernetesIngressRulesInfoCardProps) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.kubernetesIngressRulesInfoCard",
            () => ({
                columns: {
                    certificateSecretName: "TLS Secret",
                    hostNamePattern: "Host",
                    service: "Service",
                    servicePathPattern: "Path",
                    servicePathType: {
                        title: "Path Type",
                        [Contract.TypeNames.KubernetesIngressDataRulePathType]: {
                            [Contract.KubernetesIngressDataRulePathType.Exact]: "Exact",
                            [Contract.KubernetesIngressDataRulePathType.ImplementationSpecific]: "Implementation Specific",
                            [Contract.KubernetesIngressDataRulePathType.Prefix]: "Prefix"
                        }
                    },
                    servicePortName: "Service Port"
                },
                empty: "No rules",
                title: "Rules"
            }));
    return (
        <InfoCard title={localization.title()}>
            <DataTable
                emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                fetchItems={
                    () =>
                        _(rules).
                            flatMap(
                                rule =>
                                    _(rule.paths).
                                        filter(path => path.typeName === Contract.TypeNames.KubernetesIngressDataRuleServicePath).
                                        as<Contract.KubernetesIngressDataRuleServicePath>().
                                        map(
                                            servicePath =>
                                                new RuleItem(
                                                    rule.certificateSecretName,
                                                    rule.hostNamePattern,
                                                    servicePath)).
                                        value()).
                            orderBy([
                                item => StringHelper.getSortValue(item.hostNamePattern),
                                item => StringHelper.getSortValue(item.servicePath.pattern)
                            ]).
                            value()}
                getItemId={(item: RuleItem) => item.id}
                sortOptions={{ enabled: false }}
                variant="card">
                <DataTableColumn
                    id={RuleTableColumnId.HostNamePattern}
                    itemProperty={(item: RuleItem) => item.hostNamePattern}
                    title={localization.columns.hostNamePattern()}/>
                <DataTableColumn
                    id={RuleTableColumnId.ServicePathPattern}
                    itemProperty={(item: RuleItem) => item.servicePath.pattern ?? "*"}
                    title={localization.columns.servicePathPattern()}/>
                <DataTableColumn
                    id={RuleTableColumnId.ServicePathType}
                    itemProperty={(item: RuleItem) => localization.columns.servicePathType[Contract.TypeNames.KubernetesIngressDataRulePathType][item.servicePath.type]()}
                    title={localization.columns.servicePathType.title()}/>
                <DataTableColumn
                    id={RuleTableColumnId.Service}
                    render={
                        ({ item }: DataTableColumnRenderProps<RuleItem>) =>
                            <Entity
                                entityIdOrModel={item.servicePath.serviceReference.idReference}
                                variant="text"/>}
                    title={localization.columns.service()}/>
                <DataTableColumn
                    id={RuleTableColumnId.ServicePortName}
                    itemProperty={(item: RuleItem) => item.servicePath.servicePortName}
                    title={localization.columns.servicePortName()}/>
                <DataTableColumn
                    id={RuleTableColumnId.CertificateSecretName}
                    render={optionalTableCell((item: RuleItem) => item.certificateSecretName)}
                    title={localization.columns.certificateSecretName()}/>
            </DataTable>
        </InfoCard>);
}

class RuleItem {
    public id: string;
    public hostNamePattern: string;

    constructor(
        public certificateSecretName: Optional<string>,
        hostNamePattern: Optional<string>,
        public servicePath: Contract.KubernetesIngressDataRuleServicePath) {
        this.hostNamePattern = hostNamePattern ?? "*";
        this.id = `${this.hostNamePattern ?? "*"}-${servicePath.pattern}-${servicePath.type}`;
    }
}

enum RuleTableColumnId {
    CertificateSecretName = "certificateSecretName",
    HostNamePattern = "hostNamePattern",
    Service = "service",
    ServicePathPattern = "servicePathPattern",
    ServicePathType = "servicePathType",
    ServicePortName = "servicePortName"
}