import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, ReactNode, Ref, useState } from "react";
import { Dropdown, DropdownActions, EmptyMessageText, Loading, Optional, SearchList, useLocalization } from "@infrastructure";
import { useTheme } from "../..";

type AllItemsProps = {
    actionsRef?: Ref<Optional<DropdownActions>>;
    children: (item: any) => ReactNode;
    dropdownActionsRef?: Ref<Optional<DropdownActions>>;
    emptyMessageText?: EmptyMessageText | undefined;
    getSearchValue?: (item: any) => string;
    getSortValue: (item: any) => string;
    items: unknown[];
    itemsPopover?: (items: any[]) => ReactNode;
    topItems?: number;
};

export function VerticalTopItems({ children: render, dropdownActionsRef, emptyMessageText, getSearchValue, getSortValue, items, itemsPopover, topItems = 3 }: AllItemsProps) {
    const listItems = _.filter(items, item => !_.isNil(item));
    const localization =
        useLocalization(
            "common.verticalTopItems.verticalTopItems",
            () => ({
                showAll: "Show all ({{ itemsCount | NumberFormatter.humanize }})"
            }));

    const theme = useTheme();
    const [hover, setHover] = useState(false);
    return (
        <Fragment>
            {_.map(
                _.take(listItems, topItems),
                (item, index) =>
                    <Stack
                        key={index}
                        sx={{
                            maxWidth: "fit-content",
                            padding: theme.spacing(0.5, 0)
                        }}>
                        {render(item)}
                    </Stack>)}
            {_.size(listItems) > topItems &&
                <Dropdown
                    actionsRef={dropdownActionsRef}
                    popoverElement={
                        <Loading container="popup">
                            {_.isNil(itemsPopover)
                                ? <SearchList
                                    emptyMessageText={emptyMessageText}
                                    itemOptions={{
                                        getSearchValue,
                                        getSortValue,
                                        render,
                                        spacing: 0.5
                                    }}
                                    items={listItems}
                                    sorted={true}
                                    sx={{
                                        maxWidth: "450px",
                                        padding: theme.spacing(0, 1.5)
                                    }}
                                    variant="dropdown"/>
                                : itemsPopover(listItems)}
                        </Loading>}
                    popoverElementContainerSx={{ padding: 0 }}
                    popoverPropsLocation={{
                        anchorOrigin: {
                            horizontal: "right",
                            vertical: "center"
                        },
                        transformOrigin: {
                            horizontal: "left",
                            vertical: "center"
                        }
                    }}
                    sx={{
                        maxWidth: "100%",
                        width: "min-content"
                    }}>
                    {open =>
                        <Typography
                            noWrap={true}
                            sx={{
                                backgroundColor: theme.palette.background.paper,
                                borderBottom:
                                    hover || open
                                        ? theme.border.primary
                                        : theme.border.hoverFocus,
                                color: theme.palette.text.secondary,
                                marginRight: theme.spacing(1)
                            }}
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}>
                            {localization.showAll({ itemsCount: _.size(items) })}
                        </Typography>}
                </Dropdown>}
        </Fragment>);
}