import { DataTableColumn, DataTableSortType, DeferredFilter, optionalTableCell, TimeFormatter, TimeRangeFilter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InlineResourceTags, PagedEntityResourceTagFilter, ResourceTagHelper, ScopeTenantFilter, tenantModelStore, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { TenantCell, useCommonEntityDefinition } from "../useCommonEntityDefinition";

export function useAzureCommonResourceDefinition(definitionData: DefinitionData) {
    const commonEntityDefinition = useCommonEntityDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const resourceModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.AzureResourceModelFilters>;

    const azureTenantModelMap = tenantModelStore.useGetAzureTenantMap();
    const azureTenantIds =
        _.map(
            azureTenantModelMap,
            azureTenantModel => azureTenantModel.configuration.id);

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureCommonResourceDefinition",
            () => ({
                columns: {
                    azureTenant: "Account",
                    creationTime: "Creation Time",
                    creatorIdentityModel: "Created By Identity",
                    tags: "Tags"
                }
            }));

    const createTagsColumn =
        (title: string) =>
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureResourceModel) => ({
                            [title]:
                                _((item.entity as Contract.AzureResource).tags).
                                    map(ResourceTagHelper.getDisplayName).
                                    join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityResourceTagFilter
                                entityTypeName={definitionData.entityTypeEntitiesViewName}
                                placeholder={title}/>
                    }
                }}
                id={Contract.EntityModelProperty.ResourceTags}
                key={Contract.EntityModelProperty.ResourceTags}
                render={
                    optionalTableCell<Contract.AzureResourceModel>(
                        (item: Contract.AzureResourceModel) =>
                            _.isEmpty((item.entity as Contract.AzureResource).tags)
                                ? undefined
                                : <InlineResourceTags tags={(item.entity as Contract.AzureResource).tags}/>)}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={title}/>;

    return {
        columns: {
            ...commonEntityDefinition.columns,
            azureTenantColumn:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <DeferredFilter
                                    promiseOrGetPromise={definitionData.entityModelFiltersPromise}
                                    title={localization.columns.azureTenant()}>
                                    {filters =>
                                        <ScopeTenantFilter
                                            placeholder={localization.columns.azureTenant()}
                                            tenantIds={
                                                _.intersection(
                                                    filters.tenantIdItems.items,
                                                    azureTenantIds)}/>
                                    }
                                </DeferredFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.TenantId}
                    key={Contract.EntityModelProperty.TenantId}
                    render={TenantCell}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.azureTenant()}/>,
            creationTimeColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AzureResourceModel) => ({
                                [localization.columns.creationTime()]: TimeFormatter.iso8601String((item as Contract.AzureResourceModel).creationTime)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <DeferredFilter
                                    promiseOrGetPromise={resourceModelFiltersPromise}
                                    title={localization.columns.creationTime()}>
                                    {filters =>
                                        <TimeRangeFilter
                                            emptyValue={filters.creationTimeRange.emptyValue}
                                            placeholder={localization.columns.creationTime()}
                                            timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.creationTimeRange.timeRange)}/>}
                                </DeferredFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.ResourceCreationTime}
                    key={Contract.EntityModelProperty.ResourceCreationTime}
                    render={
                        optionalTableCell<Contract.AzureResourceModel>(
                            item =>
                                _.isNil(item.creationTime)
                                    ? undefined
                                    : TimeFormatter.monthDayAndYear(item.creationTime))}
                    sortOptions={{ type: DataTableSortType.Date }}
                    title={localization.columns.creationTime()}/>,
            creatorIdentityCsvColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AzureResourceModel>(
                            Contract.TypeNames.AadDirectoryPrincipal,
                            item => [item.creatorIdentityIdReference],
                            localization.columns.creatorIdentityModel())}
                    id="creatorIdentityIdReference"
                    key="creatorIdentityIdReference"/>,
            tagsColumn: createTagsColumn(localization.columns.tags())
        },
        createTagsColumn
    };
}