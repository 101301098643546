import { SxProps } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { DialogMenuItem, Menu, useLocalization } from "@infrastructure";
import { InsertTrustedFilesDialog } from ".";
import { Contract, tenantModelStore, UserHelper } from "../../../../../../../common";
import { InsertTrustedFileIcon } from "./icons";

type ActionsProps = {
    contentSha256String: string;
    sx?: SxProps;
    tenantId?: string;
};

export function Actions({ contentSha256String, sx, tenantId }: ActionsProps) {
    const activeCloudProviderTenantModels = tenantModelStore.useGetActiveCloudProviderTenants();
    const tenantIds =
        useMemo(
            () => {
                const tenantIds =
                    _.isNil(tenantId)
                        ? _.map(
                            activeCloudProviderTenantModels,
                            activeCloudProviderTenantModel => activeCloudProviderTenantModel.configuration.id)
                        : [tenantId];
                return _.filter(
                    tenantIds,
                    tenantId =>
                        UserHelper.hasScopePermissions(
                            tenantId,
                            Contract.IdentityPermission.SecurityAdministrationRead));
            },
            [activeCloudProviderTenantModels, tenantId]);

    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.maliciousFiles.actions",
            () => ({
                actions: {
                    insertTrustedFile: {
                        disabledTooltip: "Missing permissions",
                        title: "Mark as trusted"
                    }
                }
            }));

    return (
        <Menu
            itemsOrGetItems={[
                new DialogMenuItem(
                    onClose =>
                        <InsertTrustedFilesDialog
                            contentSha256Strings={[contentSha256String]}
                            tenantIds={tenantIds}
                            onClose={onClose}/>,
                    localization.actions.insertTrustedFile.title(),
                    {
                        disabled: _.size(tenantIds) == 0,
                        icon: <InsertTrustedFileIcon/>,
                        tooltip:
                            _.size(tenantIds) == 0
                                ? localization.actions.insertTrustedFile.disabledTooltip()
                                : undefined
                    })
            ]}
            sx={sx}/>);
}