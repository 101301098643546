import { UnexpectedError } from "@infrastructure";
import { ReactNode, useMemo } from "react";
import { RiskType } from "../../../../../../../../common";
import { RisksView } from "../../../../../../../../common/controllers/types.generated";
import { useCloudItems, useCodeItems } from "./hooks";

export function useItems(riskType: RiskType, riskView: RisksView, relatedEntityId?: string): ReactNode[] {
    const useItems =
        useMemo(
            () => {
                switch (riskType) {
                    case RiskType.Cloud:
                        return useCloudItems;
                    case RiskType.Code:
                        return useCodeItems;
                    default:
                        throw new UnexpectedError("riskType", riskType);
                }
            },
            [riskType]);

    return useItems(riskView, relatedEntityId);
}