import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function MSP360Icon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58343_2024)">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                    fill="#11449E"/>
                <path
                    d="M2.625 11.115H3.255L3.3 11.16L3.495 11.505L3.705 11.865L3.915 12.24L4.095 12.54L4.14 12.585L4.5 11.955L4.65 11.7L4.785 11.46L4.965 11.16L5.01 11.115H5.625L5.64 11.13V13.5L7.56 13.485L7.665 13.455L7.725 13.425L7.77 13.275V13.125L7.71 13.02V12.99L7.635 12.96L7.545 12.93L6.6 12.915L6.42 12.885L6.24 12.81L6.105 12.72L5.985 12.585L5.91 12.435L5.85 12.225L5.835 12.12V11.895L5.88 11.685L5.955 11.505L6.045 11.385L6.15 11.28L6.36 11.175L6.555 11.13L6.705 11.115H10.095L10.38 11.16L10.635 11.235L10.845 11.355L11.025 11.505L11.175 11.715L11.265 11.925L11.31 12.105L11.325 12.3L11.295 12.525L11.22 12.765L11.1 12.975L10.965 13.125L10.83 13.23L10.59 13.35L10.38 13.41L10.095 13.455H9.33V14.115L9.315 14.13L8.925 14.145H8.715L8.685 14.115V11.76L6.66 11.775L6.57 11.805L6.495 11.865L6.45 11.985V12.09L6.51 12.225L6.57 12.27L6.615 12.285L7.44 12.3L7.65 12.315L7.86 12.375L8.07 12.48L8.19 12.585L8.295 12.75L8.355 12.915L8.385 13.08V13.35L8.34 13.605L8.235 13.815L8.145 13.92L7.995 14.025L7.86 14.085L7.65 14.13L7.425 14.145H5.625L5.43 14.115L5.295 14.055L5.16 13.95L5.07 13.815L4.995 13.635L4.98 13.5L4.965 12.42L4.815 12.705L4.665 12.96L4.455 13.32L4.215 13.74L4.14 13.86L4.08 13.8L3.9 13.485L3.69 13.125L3.495 12.78L3.315 12.465L3.285 12.42L3.27 14.115L3.24 14.13H2.625L2.61 14.115L2.595 13.56V12.675L2.61 11.13L2.625 11.115Z"
                    fill="#FDFDFD"/>
                <path
                    d="M17.985 11.115H18.225L18.465 11.145L18.645 11.205L18.81 11.295L18.945 11.43L19.05 11.64L19.095 11.805L19.11 11.91V13.035L19.095 13.395L19.035 13.635L18.945 13.8L18.81 13.95L18.675 14.04L18.54 14.1L18.345 14.13H17.49L17.265 14.1L17.07 14.025L16.905 13.92L16.785 13.755L16.71 13.545L16.68 13.38L16.665 13.14V11.91L16.725 11.655L16.785 11.52L16.875 11.385L17.01 11.265L17.205 11.175L17.34 11.145L17.49 11.13L17.985 11.115Z"
                    fill="#FB773F"/>
                <path
                    d="M18.39 9.165H18.585L18.84 9.21L19.125 9.315L19.38 9.465L19.575 9.645L19.695 9.78L19.845 9.99L19.965 10.065L20.25 10.185L20.475 10.32L20.67 10.47L20.895 10.695L21.06 10.92L21.165 11.1L21.285 11.4L21.36 11.715L21.39 11.955L21.375 12.285L21.315 12.57L21.225 12.825L21.105 13.08L21 13.245L20.865 13.41L20.82 13.47H20.79L20.76 13.53L20.64 13.635L20.43 13.8L20.19 13.935L19.875 14.055L19.665 14.1L19.35 14.13L19.365 14.055L19.455 13.875L19.5 13.71L19.53 13.53L19.575 13.485L19.89 13.365L20.1 13.26L20.265 13.14L20.385 13.035L20.52 12.855L20.64 12.63L20.73 12.315L20.76 12.12V11.895L20.685 11.58L20.58 11.355L20.46 11.16L20.325 11.01L20.175 10.875L19.95 10.74L19.725 10.65L19.41 10.59L19.35 10.425L19.245 10.215L19.155 10.095L19.02 9.975L18.78 9.855L18.615 9.81H18.33L18.09 9.9L17.895 10.02L17.775 10.155L17.67 10.335L17.52 10.725L17.49 10.755L17.34 10.635L17.13 10.515L16.965 10.47L16.74 10.515L16.605 10.605L16.53 10.68L16.455 10.665L16.32 10.5L16.17 10.35L16.11 10.23L16.155 10.155L16.305 10.035L16.545 9.915L16.77 9.855H17.1L17.205 9.87L17.37 9.675L17.505 9.54L17.715 9.39L17.88 9.3L18.12 9.21L18.39 9.165Z"
                    fill="#FB773F"/>
                <path
                    d="M15.21 11.115H15.885L15.855 11.19L15.675 11.355L15.27 11.76L15.195 11.85H15.165L15.135 11.91L14.94 12.09L14.79 12.24L14.775 12.3L15.48 12.315L15.735 12.345L15.945 12.42L16.11 12.525L16.245 12.69L16.335 12.885L16.38 13.065V13.44L16.335 13.59L16.23 13.785L16.125 13.92L16.02 14.01L15.87 14.085L15.69 14.13L15.6 14.145L15.12 14.16H14.82L14.565 14.13L14.355 14.07L14.175 13.98L14.07 13.89L13.98 13.74L13.905 13.545L13.875 13.395V12.765L13.92 12.555L14.01 12.33L14.1 12.195L14.265 12.015L14.43 11.85L14.535 11.73L14.76 11.52L14.955 11.325L15.075 11.22L15.21 11.115Z"
                    fill="#FB773F"/>
                <path
                    d="M11.46 11.13L12.615 11.145L12.945 11.175L13.155 11.25L13.29 11.34L13.365 11.4L13.47 11.565L13.53 11.715L13.575 11.94V12.165L13.515 12.42L13.425 12.63V12.69L13.53 12.9L13.575 13.11V13.335L13.53 13.575L13.455 13.755L13.365 13.875L13.26 13.98L13.08 14.085L12.915 14.13L12.495 14.145H11.355L11.34 14.115L11.445 13.905L11.595 13.62L11.67 13.5L12.675 13.485L12.825 13.47L12.915 13.41L12.96 13.32L12.975 13.215L12.945 13.095L12.9 13.02L12.81 12.975L11.94 12.96L11.925 12.93L12.015 12.78L12.225 12.375L12.27 12.33L12.84 12.3L12.93 12.24L12.975 12.105L12.96 11.955L12.9 11.85L12.825 11.79L12.78 11.775H11.82L11.745 11.7L11.655 11.55L11.535 11.31L11.46 11.19V11.13Z"
                    fill="#FB773F"/>
                <path
                    d="M17.745 11.76H18.06L18.27 11.775L18.42 11.835L18.495 11.925L18.51 11.97V13.275L18.465 13.38L18.39 13.455L18.3 13.485L18.135 13.5H17.625L17.445 13.485L17.355 13.425L17.28 13.32L17.265 13.23V11.955L17.325 11.865L17.445 11.79L17.505 11.775L17.745 11.76Z"
                    fill="#11449E"/>
                <path
                    d="M9.375 11.76H10.08L10.305 11.82L10.455 11.91L10.545 12.015L10.605 12.135L10.635 12.285L10.62 12.48L10.53 12.66L10.41 12.78L10.23 12.87L10.17 12.885L9.855 12.9H9.615L9.33 12.885V11.775L9.375 11.76Z"
                    fill="#11449E"/>
                <path
                    d="M14.73 12.81H15.63L15.75 12.915L15.78 13.005L15.795 13.23L15.765 13.425L15.705 13.5L15.585 13.56L15.51 13.575H14.73L14.61 13.545L14.52 13.485L14.46 13.365V13.02L14.505 12.93L14.595 12.855L14.73 12.81Z"
                    fill="#11449E"/>
            </g>
            <defs>
                <clipPath id="clip0_58343_2024">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}