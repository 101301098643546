import { Stack, SxProps } from "@mui/material";
import React, { ReactNode } from "react";

type VerticalGrowContainerProps = {
    listElement: ReactNode;
    searchElement?: ReactNode;
    sx?: SxProps;
};

export function VerticalGrowContainer({ listElement, searchElement, sx }: VerticalGrowContainerProps) {
    return (
        <Stack
            spacing={1}
            sx={sx}>
            {searchElement}
            {listElement}
        </Stack>);
}