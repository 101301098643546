import _ from "lodash";
import { Contract } from "../../../../../controllers";
import { useAwsOrganizationsPolicyTypeTranslator } from "../../../../../hooks";
import { EntityDefinition } from "../useDefinition";

export function useAwsOrganizationsPolicyDefinition(entityModel: Contract.EntityModel) {
    const organizationsPolicyTypeTranslator = useAwsOrganizationsPolicyTypeTranslator();
    return new EntityDefinition(
        entityModel,
        {
            translatedEntityTypeName:
                entityModel.unknown
                    ? undefined
                    : organizationsPolicyTypeTranslator(_.as<Contract.AwsOrganizationsPolicy>(entityModel.entity).type)
        });
}