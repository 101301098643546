import { useLocalization } from "@infrastructure";
import { Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { Contract, LicensingHelper, useTheme } from "../../../../../../../../../../../common";

export function Common() {
    const localization =
        useLocalization(
            "views.system.exportReport.riskPolicyReport.item.title.common",
            () => ({
                analyzedCount: "Assessed",
                complianceSectionTypes: "Compliance",
                name: "Policy",
                securedCount: "Passed",
                tenantType: "Platform",
                unsecuredCount: "Failed"
            }));
    const theme = useTheme();
    return (
        <Fragment>
            <Grid
                item={true}
                xs={1.25}>
                <Typography variant="h5">
                    {localization.tenantType()}
                </Typography>
            </Grid>
            <Grid
                item={true}
                xs={true}>
                <Typography variant="h5">
                    {localization.name()}
                </Typography>
            </Grid>
            {LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm) &&
                <Grid
                    item={true}
                    sx={{ width: theme.px(120) }}>
                    <Typography variant="h5">
                        {localization.complianceSectionTypes()}
                    </Typography>
                </Grid>}
            <Grid
                item={true}
                xs={1.25}>
                <Typography variant="h5">
                    {localization.analyzedCount()}
                </Typography>
            </Grid>
            <Grid
                item={true}
                xs={1}>
                <Typography variant="h5">
                    {localization.securedCount()}
                </Typography>
            </Grid>
            <Grid
                item={true}
                xs={4}>
                <Typography variant="h5">
                    {localization.unsecuredCount()}
                </Typography>
            </Grid>
        </Fragment>);
}