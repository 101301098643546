import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function CollapseIcon(props: SvgIconProps) {
    return (
        <SvgIcon fill="none" height="24" viewBox="0 0 24 24" width="24" {...props}>
            <path
                clipRule="evenodd"
                d="M3 12C3 11.4477 3.36631 11 3.81818 11H20.1818C20.6337 11 21 11.4477 21 12C21 12.5523 20.6337 13 20.1818 13H3.81818C3.36631 13 3 12.5523 3 12Z"
                fillRule="evenodd"/>
            <path d="M16.5118 19.7298C16.8961 19.3761 16.8961 18.7878 16.5118 18.4341L13.055 15.2521C12.6898 14.916 12.1102 14.916 11.745 15.2521L8.28819 18.4341C7.90394 18.7878 7.90394 19.3761 8.28819 19.7298C8.65343 20.066 9.23294 20.066 9.59818 19.7298L11.4568 18.019L11.4568 22.9C11.4568 23.4276 11.9109 23.8 12.4 23.8C12.8891 23.8 13.3432 23.4276 13.3432 22.9L13.3432 18.019L15.2018 19.7298C15.5671 20.066 16.1466 20.066 16.5118 19.7298Z"/>
            <path d="M16.5118 4.06997C16.8961 4.42368 16.8961 5.01197 16.5118 5.36568L13.055 8.54766C12.6898 8.88385 12.1102 8.88385 11.745 8.54766L8.28819 5.36568C7.90394 5.01197 7.90394 4.42368 8.28819 4.06997C8.65343 3.73377 9.23294 3.73377 9.59818 4.06997L11.4568 5.78084L11.4568 0.899804C11.4568 0.372195 11.9109 -0.000195503 12.4 -0.000195503C12.8891 -0.000195503 13.3432 0.372196 13.3432 0.899804L13.3432 5.78084L15.2018 4.06997C15.5671 3.73377 16.1466 3.73377 16.5118 4.06997Z"/>
        </SvgIcon>);
}