﻿import { Optional } from "@infrastructure";
import { Contract } from "../../../../../../../../../../../common";
import { PermissionAssignmentEligibilityData } from "../../../utilities";

export class GcpRoleBindingEligibilityData extends PermissionAssignmentEligibilityData {
    public roleIds: string[];

    constructor(
        permissionEligibilityModel?: Optional<Contract.PermissionEligibilityModel>,
        scopeId?: string) {
        super(
            permissionEligibilityModel,
            scopeId);
        this.roleIds = (permissionEligibilityModel as Contract.GcpRoleBindingEligibilityModel)?.existingRoleIds ?? [];
    }

    protected UpdatePrincipalTenantRelatedData() {
        super.UpdatePrincipalTenantRelatedData();
        this.roleIds = [];
    }
}