import { useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, ScopeNode, tenantModelStore } from "../../../../../common";

type ScopeTooltipProps = {
    scopeNode: ScopeNode;
};

export function ScopeTooltip({ scopeNode }: ScopeTooltipProps) {
    const tenantModels =
        tenantModelStore.useGetPermittedCloudProviderTenants().
            concat(tenantModelStore.useGetPermittedCiTenants());

    const [directTenantCount, tenantCount] =
        useMemo(
            () => {
                const directTenantCount =
                    _(tenantModels).
                        filter(tenantModel => _.as<Contract.IChildScopeConfiguration>(tenantModel.configuration).parentScopeId === scopeNode.scopeNodeModel.configuration.id).
                        size();
                const tenantCount =
                    _(tenantModels).
                        filter(tenantModel => _.includes(scopeNode.scopeIds, _.as<Contract.IChildScopeConfiguration>(tenantModel.configuration).parentScopeId)).
                        size();
                return [directTenantCount, tenantCount];
            },
            []);

    const localization =
        useLocalization(
            "views.customer.scopes.scopeTooltip",
            () => ({
                direct: "Accounts: {{directTenantCount | NumberFormatter.humanize}} Direct",
                empty: "Accounts: 0 Overall",
                overall: "Accounts: {{directTenantCount | NumberFormatter.humanize}} Direct, {{tenantCount | NumberFormatter.humanize}} Overall"
            }));

    return (
        <Typography>
            {tenantCount === 0
                ? localization.empty()
                : localization[
                    directTenantCount === tenantCount
                        ? "direct"
                        : "overall"]({
                    directTenantCount,
                    tenantCount
                })}
        </Typography>);
}