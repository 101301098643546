import { DataTableColumn, DataTableColumnRenderProps, Optional, optionalTableCell, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { useCommonCustomSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, EntityFilter, entityModelStore, InlineEntities, InlineTextViewer, useTableDefinition } from "../../../../../../../../../../../../common";
import { Table } from "../../../../components";

export function useAwsRoleTemplateMismatchRiskDefinition(riskModel: Contract.RiskModel) {
    const roleTemplateMismatchRiskModel = riskModel as Contract.AwsRoleTemplateMismatchRiskModel;

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.custom.useAwsRoleTemplateMismatchRiskDefinition",
            () => ({
                violations: {
                    many: "{{roles}} have policies attached which do not match the reference role",
                    single: "{{roles}} has policies attached which do not match the reference role"
                }
            }));
    const description =
        (_.size(roleTemplateMismatchRiskModel.risk.riskedEntityIds) === 1
            ? localization.violations.single
            : localization.violations.many)({
            roles:
                    <InlineEntities
                        entityIdsOrModels={roleTemplateMismatchRiskModel.risk.riskedEntityIds}
                        entityTypeName={Contract.TypeNames.AwsIamRole}
                        variant="itemCountAndType"/>
        });
    return useCommonCustomSectionsAndDescriptionDefinition(
        description,
        riskModel,
        "violations",
        <ViolationTable risk={roleTemplateMismatchRiskModel.risk}/>);
}

type ViolationTableProps = {
    risk: Contract.AwsRoleTemplateMismatchRisk;
};

function ViolationTable({ risk }: ViolationTableProps) {

    const roleIdToRiskItemMap =
        _.keyBy(
            risk.items as Contract.AwsIamRoleTemplateMismatchRiskItem[],
            riskItem => riskItem.entityId);

    const entityModelMap =
        _.keyBy(
            entityModelStore.useGet(
                _.flatMap(
                    risk.items as Contract.AwsIamRoleTemplateMismatchRiskItem[],
                    riskItem =>
                        _.filter(
                            [
                                ...riskItem.inlinePolicyIds,
                                ...riskItem.managedPolicyIds,
                                riskItem.entityId,
                                riskItem.permissionBoundaryId
                            ]) as string[]
                )),
            entityModel => entityModel.id);


    const items =
        useMemo(
            () => _.map(
                roleIdToRiskItemMap, (riskItem, roleId) =>
                    new ViolationTableItem(
                        riskItem,
                        riskItem.assumeRolePolicyRawDocument,
                        _.map(
                            riskItem.inlinePolicyIds,
                            inlinePolicyId => entityModelMap[inlinePolicyId]),
                        _.map(
                            riskItem.managedPolicyIds,
                            managedPolicyId => entityModelMap[managedPolicyId]),
                        riskItem.missingPermissionBoundary,
                        _.isNil(riskItem.permissionBoundaryId)
                            ? undefined
                            : entityModelMap[riskItem.permissionBoundaryId],
                        entityModelMap[roleId])),
            []);

    const tableDefinition =
        useTableDefinition(
            items,
            {
                [ViolationTableColumnId.InlinePolicies]: {
                    getFilterValue: item => item.riskItem.inlinePolicyIds,
                    getSortValue: item => item.riskItem.inlinePolicyIds.length
                },
                [ViolationTableColumnId.ManagedPolicies]: {
                    getFilterValue: item => item.riskItem.managedPolicyIds,
                    getSortValue: item => item.riskItem.managedPolicyIds.length
                },
                [ViolationTableColumnId.PermissionBoundary]: {
                    getFilterValue: item => item.riskItem.permissionBoundaryId,
                    getSortValue: item =>
                        _.isNil(item.riskItem.permissionBoundaryId)
                            ? undefined
                            : entityModelMap[item.riskItem.permissionBoundaryId].entity.displayName
                },
                [ViolationTableColumnId.Role]: {
                    getFilterValue: item => item.roleModel.entity.id,
                    getSortValue: item => item.roleModel.entity.displayName
                }
            },
            ViolationTableColumnId.Role);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.custom.useAwsRoleTemplateMismatchRiskDefinition.violationTable",
            () => ({
                columns: {
                    inlinePolicies: "Inline Policies",
                    managedPolicies: "Managed Policies",
                    permissionBoundary: {
                        missing: "Missing",
                        title: "Permission Boundary"
                    },
                    role: "Role Name",
                    trustPolicy: {
                        json: {
                            title: "JSON",
                            view: "View JSON"
                        },
                        title: "Trust Relationship Policy"
                    }
                }
            }));

    return (
        <Table
            fetchItems={tableDefinition.filterAndSortItems}
            getItemId={(item: ViolationTableItem) => item.roleModel.id}
            sortEnabled={true}>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.Role]}
                                placeholder={localization.columns.role()}/>
                    }
                }}
                id={ViolationTableColumnId.Role}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <Entity
                            entityIdOrModel={item.roleModel}
                            variant="iconTextTypeTenantTags"/>}
                title={localization.columns.role()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.ManagedPolicies]}
                                placeholder={localization.columns.managedPolicies()}/>
                    }
                }}
                id={ViolationTableColumnId.ManagedPolicies}
                render={
                    optionalTableCell<ViolationTableItem>(
                        item =>
                            <InlineEntities
                                entityIdsOrModels={item.managedPolicyModels}
                                entityTypeName={Contract.TypeNames.AwsIamManagedPolicy}
                                variant="itemPlusItemCount"/>)}
                title={localization.columns.managedPolicies()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.InlinePolicies]}
                                placeholder={localization.columns.inlinePolicies()}/>
                    }
                }}
                id={ViolationTableColumnId.InlinePolicies}
                render={
                    optionalTableCell<ViolationTableItem>(
                        item =>
                            <InlineEntities
                                entityIdsOrModels={item.inlinePolicyModels}
                                entityTypeName={Contract.TypeNames.AwsIamInlinePolicy}
                                variant="itemPlusItemCount"/>)}
                title={localization.columns.inlinePolicies()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.PermissionBoundary]}
                                placeholder={localization.columns.permissionBoundary.title()}/>
                    }
                }}
                id={ViolationTableColumnId.PermissionBoundary}
                render={
                    optionalTableCell<ViolationTableItem>(
                        item =>
                            item.permissionBoundaryMissing
                                ? localization.columns.permissionBoundary.missing()
                                : _.isNil(item.permissionBoundaryModel)
                                    ? undefined
                                    : <Entity
                                        entityIdOrModel={item.permissionBoundaryModel}
                                        variant="text"/>)}
                title={localization.columns.permissionBoundary.title()}/>
            <DataTableColumn
                id={ViolationTableColumnId.TrustPolicy}
                render={
                    optionalTableCell<ViolationTableItem>(
                        item =>
                            _.isNil(item.assumeRolePolicyRawDocument)
                                ? undefined
                                : <InlineTextViewer
                                    dialogTitle={localization.columns.trustPolicy.json.view()}
                                    text={item.assumeRolePolicyRawDocument}
                                    title={localization.columns.trustPolicy.json.title()}/>)}
                sortOptions={{ enabled: false }}
                title={localization.columns.trustPolicy.title()}/>
        </Table>);
}

enum ViolationTableColumnId {
    InlinePolicies = "inlinePolicies",
    ManagedPolicies = "managedPolicies",
    PermissionBoundary = "permissionBoundary",
    Role = "role",
    TrustPolicy = "trustPolicy"
}

class ViolationTableItem {
    constructor(
        public riskItem: Contract.AwsIamRoleTemplateMismatchRiskItem,
        public assumeRolePolicyRawDocument: Optional<string>,
        public inlinePolicyModels: Contract.EntityModel[],
        public managedPolicyModels: Contract.EntityModel[],
        public permissionBoundaryMissing: boolean,
        public permissionBoundaryModel: Optional<Contract.EntityModel>,
        public roleModel: Contract.EntityModel) {
    }
}