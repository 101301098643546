﻿import { ApiError, InfoIcon, Optional, PasswordTextField, Tooltip, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { FormControl, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useAddOrEditContext, useSetAddOrEditContext } from "..";
import { ConfigurationController, Contract, useTheme } from "../../../../../../../../../../../../../../../common";

export function AppDetailsItem() {
    const setAddOrEditContext = useSetAddOrEditContext();
    const { serverModel, updatedApplicationClientId, updatedApplicationClientSecret, updatedApplicationId, updatedApplicationOrganizationName, updatedApplicationPrivateKey, updatedServerCertificateValidationEnabled, updatedServerEndpoint, updatedServerEndpointConnectorId, updatedServerName } = useAddOrEditContext();

    const gitHubServerConfiguration = serverModel?.configuration as Optional<Contract.GitHubServerConfiguration>;

    const [applicationClientId, setApplicationClientId] = useState(updatedApplicationClientId ?? "");
    const [applicationClientSecret, setApplicationClientSecret] =
        useState<Optional<string>>(
            updatedApplicationClientSecret ??
            (_.isNil(gitHubServerConfiguration)
                ? ""
                : undefined));
    const [applicationId, setApplicationId] = useState(updatedApplicationId ?? NaN);
    const [applicationPrivateKey, setApplicationPrivateKey] =
        useState<Optional<string>>(
            updatedApplicationPrivateKey ??
            (_.isNil(gitHubServerConfiguration)
                ? ""
                : undefined));

    const { executing, setValid, useNextEffect } = useOrderedWizardContext();

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeServerItems.gitHubServer.addOrEditGitHubServer.appDetailsItem",
            () => ({
                details: "Enter the following details for the Tenable Cloud Security GitHub app (**App settings page > General**):",
                error: {
                    general: "Failed to access the server",
                    [Contract.TypeNames.GitHubServerManagerError]: {
                        [Contract.GitHubServerManagerError.CommonErrorApplicationClientIdInvalid]: "Invalid Client ID",
                        [Contract.GitHubServerManagerError.CommonErrorApplicationClientSecretInvalid]: "Invalid Client Secret",
                        [Contract.GitHubServerManagerError.CommonErrorApplicationIdInvalid]: "Invalid App ID",
                        [Contract.GitHubServerManagerError.CommonErrorApplicationPrivateKeyInvalid]: "Invalid Private Key",
                        [Contract.GitHubServerManagerError.CommonErrorEndpointInvalid]: "Unreachable GitHub Enterprise Server Endpoint",
                        [Contract.GitHubServerManagerError.CommonErrorEndpointNotExist]: "Endpoint Connector integration is not connected",
                        [Contract.GitHubServerManagerError.CommonErrorServerCertificateValidationFailed]: "SSL certificate validation fails. Select the checkbox to accept any server SSL certificates.",
                        [Contract.GitHubServerManagerError.CommonErrorServerNameExists]: "Server name already exists",
                        [Contract.GitHubServerManagerError.InsertServerErrorServerExists]: "Server already exists"
                    }
                },
                fields: {
                    applicationClientId: "Client ID",
                    applicationClientSecret: {
                        title: "Client Secret",
                        tooltip: "Generate and copy a client secret, and then enter it here."
                    },
                    applicationId: "App ID",
                    applicationPrivateKey: {
                        title: "Private Key",
                        tooltip: "Generate and download a private key, and then enter it here."
                    }
                }
            }));

    useNextEffect(
        async () => {
            try {
                const { codeServerModel } =
                    _.isNil(gitHubServerConfiguration)
                        ? await ConfigurationController.insertGitHubServer(
                            new Contract.ConfigurationControllerInsertGitHubServerRequest(
                                applicationClientId,
                                applicationClientSecret!,
                                applicationId,
                                updatedApplicationOrganizationName!,
                                applicationPrivateKey!,
                                updatedServerEndpointConnectorId,
                                updatedServerName!,
                                updatedServerEndpoint,
                                updatedServerCertificateValidationEnabled!))
                        : await ConfigurationController.updateGitHubServer(
                            new Contract.ConfigurationControllerUpdateGitHubServerRequest(
                                applicationClientId,
                                applicationClientSecret,
                                applicationId,
                                applicationPrivateKey,
                                gitHubServerConfiguration.id,
                                updatedServerName!,
                                updatedServerCertificateValidationEnabled!));
                setAddOrEditContext(
                    context => ({
                        ...context,
                        serverModel: codeServerModel
                    }));
            } catch (error) {
                return error instanceof ApiError && error.statusCode === 400
                    ? localization.error[Contract.TypeNames.GitHubServerManagerError].translate(error.error)
                    : localization.error.general();
            }
        },
        [applicationClientId, applicationClientSecret, applicationId, applicationPrivateKey, updatedServerCertificateValidationEnabled, updatedServerName, updatedServerEndpoint]);

    useEffect(
        () => {
            setAddOrEditContext(
                context => ({
                    ...context,
                    updatedApplicationClientId: applicationClientId
                }));
        },
        [applicationClientId]);

    useEffect(
        () => {
            setAddOrEditContext(
                context => ({
                    ...context,
                    updatedApplicationClientSecret: applicationClientSecret
                }));
        },
        [applicationClientSecret]);

    useEffect(
        () => {
            setAddOrEditContext(
                context => ({
                    ...context,
                    updatedApplicationId: applicationId
                }));
        },
        [applicationId]);

    useEffect(
        () => {
            setAddOrEditContext(
                context => ({
                    ...context,
                    updatedApplicationPrivateKey: applicationPrivateKey
                }));
        },
        [applicationPrivateKey]);

    useEffect(
        () => {
            setValid(
                !!applicationClientId &&
                !isNaN(applicationId) &&
                (!_.isNil(gitHubServerConfiguration) || (!!applicationClientSecret && !!applicationPrivateKey)));
        },
        [applicationClientId, applicationClientSecret, applicationId, applicationPrivateKey, gitHubServerConfiguration]);

    const theme = useTheme();
    return (
        <Stack
            spacing={4}
            sx={{ maxWidth: theme.spacing(70) }}>
            <Typography>
                {localization.details()}
            </Typography>
            <FormControl>
                <TextField
                    disabled={executing}
                    label={localization.fields.applicationId()}
                    type="number"
                    value={applicationId}
                    onChange={event => setApplicationId(_.parseInt(event.target.value))}/>
            </FormControl>
            <TextField
                disabled={executing}
                label={localization.fields.applicationClientId()}
                value={applicationClientId}
                onChange={event => setApplicationClientId(event.target.value)}/>
            <FormControl>
                <PasswordTextField
                    disabled={executing}
                    label={localization.fields.applicationClientSecret.title()}
                    password={applicationClientSecret}
                    slotProps={{
                        input: {
                            endAdornment:
                                <InputAdornment position="end">
                                    <Tooltip
                                        titleOrGetTitle={localization.fields.applicationClientSecret.tooltip()}>
                                        <InfoIcon
                                            sx={{
                                                color: theme.palette.text.secondary,
                                                fontSize: "24px"
                                            }}/>
                                    </Tooltip>
                                </InputAdornment>
                        }
                    }}
                    variant="outlined"
                    onPasswordChanged={clientSecret => setApplicationClientSecret(clientSecret)}/>
            </FormControl>
            <FormControl>
                <PasswordTextField
                    disabled={executing}
                    label={localization.fields.applicationPrivateKey.title()}
                    multiline={true}
                    password={applicationPrivateKey}
                    rows={28}
                    slotProps={{
                        input: {
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                    sx={{
                                        alignItems: "flex-start !important",
                                        marginTop: theme.spacing(1)
                                    }}>
                                    <Tooltip titleOrGetTitle={localization.fields.applicationPrivateKey.tooltip()}>
                                        <InfoIcon
                                            sx={{
                                                color: theme.palette.text.secondary,
                                                fontSize: "24px"
                                            }}/>
                                    </Tooltip>
                                </InputAdornment>
                            ),
                            sx: {
                                alignItems: "flex-start",
                                minHeight: theme.spacing(70)
                            }
                        }
                    }}
                    variant="outlined"
                    onPasswordChanged={privateKey => setApplicationPrivateKey(privateKey)}/>
            </FormControl>
        </Stack>);
}