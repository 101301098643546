import { Function0 } from "lodash";
import React, { useCallback } from "react";
import { useLocalization } from "@infrastructure";
import { Contract, CustomerConsoleAppUrlHelper, RiskTypeGroups, Tenant, WidgetDefinition } from "../../../../../../..";
import { SummaryDashboardContext, useDashboardContext } from "../../../../../Dashboard";
import { TopRisks } from "../components";

export function useGetTopRiskTenantsDefinition(): () => WidgetDefinition {
    const { summary } = useDashboardContext<SummaryDashboardContext>();

    const getRisksRelativeUrl =
        useCallback(
            (tenantId: string, severity?: Contract.Severity) =>
                CustomerConsoleAppUrlHelper.getRisksRelativeUrl(
                    Contract.RisksView.Open,
                    {
                        severity,
                        tenantIds: [tenantId]
                    },
                    RiskTypeGroups.RiskPolicyType),
            []);

    const riskIdToElementMapper =
        useCallback(
            (tenantId: string) => <Tenant tenantId={tenantId}/>,
            []);

    const localization =
        useLocalization(
            "common.dashboard.widget.hooks.useDefinition.hooks.useGetTopRiskTenantsDefinition",
            () => ({
                helpText: "View the most at-risk accounts in your organization, with a breakdown of the number of findings per severity level. Click on an account or a specific number to assess and remediate the findings.",
                title: "Top Accounts at Risk"
            }));

    return useCallback<Function0<WidgetDefinition>>(
        () => ({
            element: (
                <TopRisks
                    getRisksRelativeUrl={getRisksRelativeUrl}
                    itemToRiskSeverityToRiskCount={summary.riskTenantIdToSeverityToRiskCountMap}>
                    {riskIdToElementMapper}
                </TopRisks>),
            options: {
                helpText: localization.helpText(),
                title: localization.title(),
                variant: "scrollable"
            }
        }),
        [localization]);
}