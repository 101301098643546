import { TextField } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTheme } from "../../../../../../../../../../../../../../../../../common";

type PatternTextFieldProps = {
    onChange: (value: string) => void;
    value?: string;
};

export function PatternTextField({ onChange, value }: PatternTextFieldProps) {
    const theme = useTheme();
    return (
        <TextField
            slotProps={{
                htmlInput: {
                    sx: {
                        padding: theme.spacing(0.5, 0, 0.25),
                        textOverflow: "ellipsis",
                        width: `${_.min([_.max([value?.length, 6]), 30])}ch`
                    }
                }
            }}
            sx={{ paddingRight: theme.spacing(1) }}
            value={value}
            variant="standard"
            onChange={event => onChange(event.target.value)}/>
    );
}