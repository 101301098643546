import { Box, Divider, Stack } from "@mui/material";
import React, { Fragment, ReactNode } from "react";
import { Contract } from "../../controllers";
import { UsGovImage } from "../../images";
import { useTheme } from "../../themes";
import { GeographyHelper } from "../../utilities";
import { AboutDropdown, BeamerButton, UserDropdown } from "./components";

export const topbarHeight = 54;

type TopbarProps = {
    children?: ReactNode;
    consoleAppType: Contract.ConsoleAppType;
    customerContext?: boolean;
};

export function Topbar({ children, consoleAppType, customerContext = true }: TopbarProps) {
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            spacing={0.5}
            sx={{ padding: theme.spacing(1.5, 2) }}>
            <Box sx={{ flex: 1 }}>
                {children}
            </Box>
            <AboutDropdown/>
            <BeamerButton/>
            <UserDropdown
                consoleAppType={consoleAppType}
                customerContext={customerContext}/>
            {GeographyHelper.deploymentPartitionType === Contract.PartitionType.UsGov && (
                <Fragment>
                    <TopbarDividerItem/>
                    <UsGovImage/>
                </Fragment>)}
        </Stack>);
}

export function TopbarDividerItem() {
    return (
        <Divider
            flexItem={true}
            orientation="vertical"
            sx={{
                alignSelf: "center",
                height: "32px"
            }}/>);
}