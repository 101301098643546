﻿import { useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, EntityController } from "../../../../../../../controllers";
import { useEntityTypeNameTranslator } from "../../../../../../../hooks";
import { useTheme } from "../../../../../../../themes";
import { ElasticsearchItemPageHelper } from "../../../../../../../utilities";
import { OperatorSelector } from "../../../../../../OperatorSelector";
import { PagedEntityMultiSelect } from "../../../../../../PagedEntityMultiSelect";
import { useCustomRiskPolicyContext } from "../../../../../CustomRiskPolicy";
import { useGcpPrincipalNotAllowedResourceRoleRiskPolicyConfigurationRolesOperatorTranslator } from "../hooks";

type RoleSelectorProps = {
    onSelectionChanged: (selection: Contract.GcpPrincipalNotAllowedResourceRoleRiskPolicyConfigurationRoles) => void;
    selection: Contract.GcpPrincipalNotAllowedResourceRoleRiskPolicyConfigurationRoles;
};

export function RoleSelector({ onSelectionChanged, selection }: RoleSelectorProps) {
    const { scopeId } = useCustomRiskPolicyContext();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const operatorTranslator = useGcpPrincipalNotAllowedResourceRoleRiskPolicyConfigurationRolesOperatorTranslator();

    const localization =
        useLocalization(
            "common.customRiskPolicy.gcpPrincipalNotAllowedResourceRoleRiskPolicy.edit.roleSelector",
            () => ({
                text: "of these roles"
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}
            sx={{ padding: theme.spacing(2) }}>
            <OperatorSelector
                operators={_.values(Contract.GcpPrincipalNotAllowedResourceRoleRiskPolicyConfigurationRolesOperator)}
                operatorTranslator={operatorTranslator}
                selectedOperator={selection.operator}
                onSelectedOperatorChanged={
                    (operator: Contract.GcpPrincipalNotAllowedResourceRoleRiskPolicyConfigurationRolesOperator) =>
                        onSelectionChanged(
                            new Contract.GcpPrincipalNotAllowedResourceRoleRiskPolicyConfigurationRoles(
                                selection.ids,
                                operator))
                }/>
            <Typography>
                {localization.text()}
            </Typography>
            <PagedEntityMultiSelect
                fieldOptions={{ dense: true }}
                getEntityModelPage={
                    ElasticsearchItemPageHelper.makePagedEntityMultiSelect(
                        async (itemNextPageSearchCursor, searchText) => {
                            const { entityModelPage } =
                                await EntityController.searchEntityModels(
                                    new Contract.EntityControllerSearchEntityModelsTypeRequest(
                                        true,
                                        15,
                                        itemNextPageSearchCursor,
                                        scopeId,
                                        searchText,
                                        false,
                                        Contract.TypeNames.GcpIamRole));
                            return entityModelPage;
                        })}
                placeholder={entityTypeNameTranslator(Contract.TypeNames.GcpIamRole)}
                selectedEntityIds={selection.ids}
                onSelectedEntityIdsChanged={
                    ids =>
                        onSelectionChanged(
                            new Contract.GcpPrincipalNotAllowedResourceRoleRiskPolicyConfigurationRoles(
                                ids,
                                selection.operator))
                }/>
        </Stack>);
}