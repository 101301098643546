import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract, TypeHelper } from "../../../../../../../../../../../../../common";
import { useAwsInboundExternalEc2InstanceRiskInstanceDefinition, useAwsInboundExternalEc2InstanceRiskLaunchConfigurationDefinition, useAwsInboundExternalEc2InstanceRiskLaunchTemplateDefinition, useAwsInboundExternalEc2InstanceRiskTagResourceGroupDefinition } from "./hooks";

export function useAwsInboundExternalEc2InstanceRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsInboundExternalEc2InstanceRisk;

    const useDefinition =
        useMemo(
            () => {
                switch (risk.aggregatingEntityTypeName) {
                    case Contract.TypeNames.AwsAutoScalingLaunchConfiguration:
                        return useAwsInboundExternalEc2InstanceRiskLaunchConfigurationDefinition;
                    case Contract.TypeNames.AwsEc2Instance:
                        return useAwsInboundExternalEc2InstanceRiskInstanceDefinition;
                    case Contract.TypeNames.AwsEc2LaunchTemplate:
                        return useAwsInboundExternalEc2InstanceRiskLaunchTemplateDefinition;
                    default:
                        if (TypeHelper.extendOrImplement(risk.aggregatingEntityTypeName, Contract.TypeNames.AwsTagResourceGroup)) {
                            return useAwsInboundExternalEc2InstanceRiskTagResourceGroupDefinition;
                        }

                        throw new UnexpectedError("risk.aggregatingEntityTypeName", risk.aggregatingEntityTypeName);
                }
            },
            []);

    return useDefinition(riskModel as Contract.AwsInboundExternalEc2InstanceRiskModel);
}