import { Message, useChangeEffect, useExecuteOperation, useLocalization } from "@infrastructure";
import { Button, CircularProgress, Stack, Switch, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { AdministrationController, Contract, useScopeNavigationViewContext, useTheme } from "../../../../../common";

export function WorkloadAnalysis() {
    const { scopeNodeModel } = useScopeNavigationViewContext();

    const [{ diskDefaultMaxSize, diskMaxSize: initialDiskMaxSize, inherited: initialInherited }, executeGetWorkloadAnalysis] =
        useExecuteOperation(
            WorkloadAnalysis,
            () => AdministrationController.getWorkloadAnalysis(new Contract.AdministrationControllerGetWorkloadAnalysisRequest(scopeNodeModel.configuration.id)));

    const [updateError, setUpdateError] = useState(false);
    const [updateExecuting, setUpdateExecuting] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);

    const [diskMaxSize, setDiskMaxSize] = useState(initialDiskMaxSize);
    const [inherited, setInherited] = useState(initialInherited);

    useChangeEffect(
        () => {
            setDiskMaxSize(initialDiskMaxSize);
            setInherited(initialInherited);
        },
        [initialDiskMaxSize, initialInherited]);

    const localization =
        useLocalization(
            "views.customer.administration.workloadAnalysis",
            () => ({
                actions: {
                    inherit: "Inherit",
                    setDefault: "Set to default",
                    update: {
                        error: "Failed",
                        success: "Success",
                        text: "Update"
                    }
                },
                diskMaxSize: "Disk max size (GB)"
            }));

    const gigabyteByteCount = 1024 * 1024 * 1024;
    const theme = useTheme();
    return (
        <Stack
            spacing={3}
            sx={{ padding: theme.spacing(3, 2) }}>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}
                sx={{
                    border: theme.border.primary,
                    borderRadius: theme.spacing(0.75),
                    padding: theme.spacing(2)
                }}>
                <Typography variant="h4">
                    {localization.diskMaxSize()}
                </Typography>
                <TextField
                    disabled={inherited}
                    size="small"
                    sx={{ width: theme.spacing(10) }}
                    type="number"
                    value={diskMaxSize / gigabyteByteCount}
                    onChange={
                        event => {
                            setDiskMaxSize(_.parseInt(event.target.value) * gigabyteByteCount);
                            setUpdateSuccess(false);
                        }}/>
                {scopeNodeModel.type == Contract.ScopeType.Customer
                    ? <Button
                        disabled={diskMaxSize == diskDefaultMaxSize}
                        variant="outlined"
                        onClick={() => setDiskMaxSize(diskDefaultMaxSize)}>
                        <Typography>
                            {localization.actions.setDefault()}
                        </Typography>
                    </Button>
                    : <Stack
                        alignItems="center"
                        direction="row">
                        <Typography
                            sx={{
                                flex: 1,
                                fontWeight: 500
                            }}
                            variant="h4">
                            {localization.actions.inherit()}
                        </Typography>
                        <Switch
                            checked={inherited}
                            color="secondary"
                            size="small"
                            onChange={(event, checked) => setInherited(checked)}/>
                    </Stack>}
            </Stack>
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="flex-end"
                spacing={1}>
                {updateSuccess && (
                    <Message
                        level="success"
                        title={localization.actions.update.success()}/>
                )}
                {updateExecuting && (
                    <CircularProgress
                        size={theme.spacing(2)}
                        variant="indeterminate"/>)}
                {updateError && (
                    <Message
                        level="error"
                        title={localization.actions.update.error()}/>)}
                <Button
                    disabled={
                        diskMaxSize === initialDiskMaxSize &&
                        inherited === initialInherited}
                    onClick={
                        async () => {
                            setUpdateError(false);
                            setUpdateExecuting(true);

                            try {
                                await AdministrationController.updateWorkloadAnalysis(
                                    new Contract.AdministrationControllerUpdateWorkloadAnalysisRequest(
                                        inherited
                                            ? undefined
                                            : diskMaxSize,
                                        scopeNodeModel.configuration.id));
                                setUpdateSuccess(true);
                            } catch (error) {
                                setUpdateError(true);
                            }

                            await executeGetWorkloadAnalysis();

                            setUpdateExecuting(false);
                        }}>
                    {localization.actions.update.text()}
                </Button>
            </Stack>
        </Stack>);
}