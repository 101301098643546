﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../../..";
import { useAzureDefaultResourceInfoItemElements, useCustomEntityPropertyInfoItemElements } from "../..";
import { Contract } from "../../../../../../../../../../../common";
import { Info } from "../../../../../components";
import { AzureResourceRoleAssignmentResources } from "../../../components";
import { useAzureDefaultKeyVaultVaultObjectInfoItemElements } from "../useAzureDefaultKeyVaultVaultObjectInfoItemElements";
import { CertificateVersions } from "./components";

export function useAzureKeyVaultVaultCertificateDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const certificateModel = resourceGroupResourceModel as Contract.AzureKeyVaultVaultCertificateModel;
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultKeyVaultVaultObjectInfoItemElements = useAzureDefaultKeyVaultVaultObjectInfoItemElements(certificateModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureKeyVaultVaultCertificateDefinition",
            () => ({
                tabs: {
                    certificateModels: "Versions ({{certificateModelsCount | NumberFormatter.humanize}})",
                    resourceRoleAssignments: "Resource Role Assignments"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <CertificateVersions certificateModel={certificateModel}/>,
                localization.tabs.certificateModels({ certificateModelsCount: _.size((certificateModel.entity as Contract.AzureKeyVaultVaultCertificate).versionNameToVersionMap) }),
                "certificateModels"),
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AzureResourceRoleAssignmentResources
                    csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                    scopeResourceModel={resourceGroupResourceModel}/>,
                localization.tabs.resourceRoleAssignments(),
                "resourceRoleAssignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={defaultKeyVaultVaultObjectInfoItemElements}
                options={options?.infoOptions}>
            </Info>
    });
}