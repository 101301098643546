import { Action1 } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import React from "react";
import { Contract, Identity, useTheme } from "../../../../../../../../../../../../common";
import { RiskAuditEvent, RiskNoteCreationAuditEvent } from "./components";

type AuditEventProps = {
    auditEventModel: Contract.AuditEventModel;
    onChange: Action1<Contract.AuditEventModel>;
    onDelete: Action1<string>;
};

export function AuditEvent({ auditEventModel, onChange, onDelete }: AuditEventProps) {
    const theme = useTheme();
    return (
        <Stack direction="row">
            <Box sx={{ marginRight: theme.spacing(1.5) }}>
                <Identity
                    identityIdOrReference={auditEventModel.auditEvent.identityReference}
                    variant="icon"/>
            </Box>
            <Box
                sx={{
                    flex: 1,
                    maxWidth: theme.spacing(90),
                    overflow: "hidden"
                }}>
                {auditEventModel.auditEvent.typeName === Contract.TypeNames.RiskNoteCreationAuditEvent
                    ? <RiskNoteCreationAuditEvent
                        auditEventModel={auditEventModel}
                        onChange={onChange}
                        onDelete={onDelete}/>
                    : <RiskAuditEvent
                        auditEventModel={auditEventModel}
                        onChange={onChange}/>}
            </Box>
        </Stack>);
}