//@ts-nocheck
import { Optional } from "@infrastructure";
import { ApiController, Contract } from ".";
import { TypeMetadataHelper } from "../utilities";

export class AccessController extends ApiController {
    public static getDashboardSummaryUrl =
        () => "/api/console/access/getDashboardSummary";

    public static getDashboardSummary(): Promise<Contract.AccessControllerGetDashboardSummaryResponse> {
        return ApiController.postRequest<any, Contract.AccessControllerGetDashboardSummaryResponse>(
            AccessController.getDashboardSummaryUrl(),
            {});
    }


    public static getAwsFederationEdgeFilterItemPageUrl =
        () => "/api/console/access/getAwsFederationEdgeFilterItemPage";

    public static getAwsFederationEdgeFilterItemPage(request: Contract.AccessControllerGetAwsFederationEdgeFilterItemPageRequest): Promise<Contract.AccessControllerGetAwsFederationEdgeFilterItemPageResponse> {
        return ApiController.postRequest<Contract.AccessControllerGetAwsFederationEdgeFilterItemPageRequest, Contract.AccessControllerGetAwsFederationEdgeFilterItemPageResponse>(
            AccessController.getAwsFederationEdgeFilterItemPageUrl(),
            request);
    }


    public static getAwsFederationEdgeFiltersUrl =
        () => "/api/console/access/getAwsFederationEdgeFilters";

    public static getAwsFederationEdgeFilters(request: Contract.AccessControllerGetAwsFederationEdgeFiltersRequest): Promise<Contract.AccessControllerGetAwsFederationEdgeFiltersResponse> {
        return ApiController.postRequest<Contract.AccessControllerGetAwsFederationEdgeFiltersRequest, Contract.AccessControllerGetAwsFederationEdgeFiltersResponse>(
            AccessController.getAwsFederationEdgeFiltersUrl(),
            request);
    }


    public static getAwsFederationEdgeModelPageUrl =
        () => "/api/console/access/getAwsFederationEdgeModelPage";

    public static getAwsFederationEdgeModelPage(request: Contract.AccessControllerGetAwsFederationEdgeModelPageRequest): Promise<Contract.AccessControllerGetAwsFederationEdgeModelPageResponse> {
        return ApiController.postRequest<Contract.AccessControllerGetAwsFederationEdgeModelPageRequest, Contract.AccessControllerGetAwsFederationEdgeModelPageResponse>(
            AccessController.getAwsFederationEdgeModelPageUrl(),
            request);
    }


    public static getPermissionFiltersUrl =
        () => "/api/console/access/getPermissionFilters";

    public static getPermissionFilters(): Promise<Contract.AccessControllerGetPermissionFiltersResponse> {
        return ApiController.postRequest<any, Contract.AccessControllerGetPermissionFiltersResponse>(
            AccessController.getPermissionFiltersUrl(),
            {});
    }


    public static getPermissionsModelActionsUrl =
        () => "/api/console/access/getPermissionsModelActions";

    public static getPermissionsModelActions(request: Contract.AccessControllerGetPermissionsModelActionsRequest): Promise<Contract.AccessControllerGetPermissionsModelActionsResponse> {
        return ApiController.postRequest<Contract.AccessControllerGetPermissionsModelActionsRequest, Contract.AccessControllerGetPermissionsModelActionsResponse>(
            AccessController.getPermissionsModelActionsUrl(),
            request);
    }


    public static getPermissionsModelCountUrl =
        () => "/api/console/access/getPermissionsModelCount";

    public static getPermissionsModelCount(request: Contract.AccessControllerGetPermissionsModelCountRequest): Promise<Contract.AccessControllerGetPermissionsModelCountResponse> {
        return ApiController.postRequest<Contract.AccessControllerGetPermissionsModelCountRequest, Contract.AccessControllerGetPermissionsModelCountResponse>(
            AccessController.getPermissionsModelCountUrl(),
            request);
    }


    public static getPermissionsModelDestinationEntityIdsUrl =
        () => "/api/console/access/getPermissionsModelDestinationEntityIds";

    public static getPermissionsModelDestinationEntityIds(request: Contract.AccessControllerGetPermissionsModelDestinationEntityIdsRequest): Promise<Contract.AccessControllerGetPermissionsModelDestinationEntityIdsResponse> {
        return ApiController.postRequest<Contract.AccessControllerGetPermissionsModelDestinationEntityIdsRequest, Contract.AccessControllerGetPermissionsModelDestinationEntityIdsResponse>(
            AccessController.getPermissionsModelDestinationEntityIdsUrl(),
            request);
    }


    public static getPermissionsModelExportUrl =
        () => "/api/console/access/getPermissionsModelExport";

    public static getPermissionsModelExport(request: Contract.AccessControllerGetPermissionsModelExportRequest): Promise<Contract.AccessControllerGetPermissionsModelExportResponse> {
        return ApiController.postRequest<Contract.AccessControllerGetPermissionsModelExportRequest, Contract.AccessControllerGetPermissionsModelExportResponse>(
            AccessController.getPermissionsModelExportUrl(),
            request);
    }


    public static getPermissionsModelOriginatorEntityIdsUrl =
        () => "/api/console/access/getPermissionsModelOriginatorEntityIds";

    public static getPermissionsModelOriginatorEntityIds(request: Contract.AccessControllerGetPermissionsModelOriginatorEntityIdsRequest): Promise<Contract.AccessControllerGetPermissionsModelOriginatorEntityIdsResponse> {
        return ApiController.postRequest<Contract.AccessControllerGetPermissionsModelOriginatorEntityIdsRequest, Contract.AccessControllerGetPermissionsModelOriginatorEntityIdsResponse>(
            AccessController.getPermissionsModelOriginatorEntityIdsUrl(),
            request);
    }


    public static getPermissionsModelPageUrl =
        () => "/api/console/access/getPermissionsModelPage";

    public static getPermissionsModelPage(request: Contract.AccessControllerGetPermissionsModelPageRequest): Promise<Contract.AccessControllerGetPermissionsModelPageResponse> {
        return ApiController.postRequest<Contract.AccessControllerGetPermissionsModelPageRequest, Contract.AccessControllerGetPermissionsModelPageResponse>(
            AccessController.getPermissionsModelPageUrl(),
            request);
    }


    public static getPermissionsModelSourceEntityIdsUrl =
        () => "/api/console/access/getPermissionsModelSourceEntityIds";

    public static getPermissionsModelSourceEntityIds(request: Contract.AccessControllerGetPermissionsModelSourceEntityIdsRequest): Promise<Contract.AccessControllerGetPermissionsModelSourceEntityIdsResponse> {
        return ApiController.postRequest<Contract.AccessControllerGetPermissionsModelSourceEntityIdsRequest, Contract.AccessControllerGetPermissionsModelSourceEntityIdsResponse>(
            AccessController.getPermissionsModelSourceEntityIdsUrl(),
            request);
    }


    public static getPrincipalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMapUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/access/getPrincipalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMap/{typeName}", { typeName });

    public static getPrincipalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMap(request: Contract.AccessControllerGetPrincipalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMapRequest): Promise<Contract.AccessControllerGetPrincipalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMapResponse> {
        return ApiController.postRequest<Contract.AccessControllerGetPrincipalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMapRequest, Contract.AccessControllerGetPrincipalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMapResponse>(
            AccessController.getPrincipalIdToOpenAccessRiskIdToScopeIdToIdentityPermissionsMapUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getPrincipalExcessivePermissionPrincipalRiskTenantPermissionUrl =
        () => "/api/console/access/getPrincipalExcessivePermissionPrincipalRiskTenantPermission";

    public static getPrincipalExcessivePermissionPrincipalRiskTenantPermission(request: Contract.AccessControllerGetPrincipalExcessivePermissionPrincipalRiskTenantPermissionRequest): Promise<Contract.AccessControllerGetPrincipalExcessivePermissionPrincipalRiskTenantPermissionResponse> {
        return ApiController.postRequest<Contract.AccessControllerGetPrincipalExcessivePermissionPrincipalRiskTenantPermissionRequest, Contract.AccessControllerGetPrincipalExcessivePermissionPrincipalRiskTenantPermissionResponse>(
            AccessController.getPrincipalExcessivePermissionPrincipalRiskTenantPermissionUrl(),
            request);
    }


    public static getPrincipalExcessivePermissionRiskResolutionEnabledUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/access/getPrincipalExcessivePermissionRiskResolutionEnabled/{typeName}", { typeName });

    public static getPrincipalExcessivePermissionRiskResolutionEnabled(request: Contract.AccessControllerGetPrincipalExcessivePermissionRiskResolutionEnabledRequest): Promise<Contract.AccessControllerGetPrincipalExcessivePermissionRiskResolutionEnabledResponse> {
        return ApiController.postRequest<Contract.AccessControllerGetPrincipalExcessivePermissionRiskResolutionEnabledRequest, Contract.AccessControllerGetPrincipalExcessivePermissionRiskResolutionEnabledResponse>(
            AccessController.getPrincipalExcessivePermissionRiskResolutionEnabledUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getPrincipalFilterItemPageUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/access/getPrincipalFilterItemPage/{typeName}", { typeName });

    public static getPrincipalFilterItemPage(request: Contract.AccessControllerGetPrincipalFilterItemPageRequest): Promise<Contract.AccessControllerGetPrincipalFilterItemPageResponseBase> {
        return ApiController.postRequest<Contract.AccessControllerGetPrincipalFilterItemPageRequest, Contract.AccessControllerGetPrincipalFilterItemPageResponseBase>(
            AccessController.getPrincipalFilterItemPageUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getPrincipalModelFiltersUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/access/getPrincipalModelFilters/{typeName}", { typeName });

    public static getPrincipalModelFilters(request: Contract.AccessControllerGetPrincipalModelFiltersRequest): Promise<Contract.AccessControllerGetPrincipalModelFiltersResponse> {
        return ApiController.postRequest<Contract.AccessControllerGetPrincipalModelFiltersRequest, Contract.AccessControllerGetPrincipalModelFiltersResponse>(
            AccessController.getPrincipalModelFiltersUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getPrincipalModelPageUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/access/getPrincipalModelPage/{typeName}", { typeName });

    public static getPrincipalModelPage(request: Contract.AccessControllerGetPrincipalModelPageRequest): Promise<Contract.AccessControllerGetPrincipalModelPageResponse> {
        return ApiController.postRequest<Contract.AccessControllerGetPrincipalModelPageRequest, Contract.AccessControllerGetPrincipalModelPageResponse>(
            AccessController.getPrincipalModelPageUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getPrincipalModelPropertyItemsUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/access/getPrincipalModelPropertyItems/{typeName}", { typeName });

    public static getPrincipalModelPropertyItems(request: Contract.AccessControllerGetPrincipalPropertyItemsRequest): Promise<Contract.AccessControllerGetPrincipalPropertyItemsResponse> {
        return ApiController.postRequest<Contract.AccessControllerGetPrincipalPropertyItemsRequest, Contract.AccessControllerGetPrincipalPropertyItemsResponse>(
            AccessController.getPrincipalModelPropertyItemsUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getPrincipalModelSummaryUrl =
        () => "/api/console/access/getPrincipalModelSummary";

    public static getPrincipalModelSummary(request: Contract.AccessControllerGetPrincipalSummaryRequest): Promise<Contract.AccessControllerGetPrincipalSummaryResponse> {
        return ApiController.postRequest<Contract.AccessControllerGetPrincipalSummaryRequest, Contract.AccessControllerGetPrincipalSummaryResponse>(
            AccessController.getPrincipalModelSummaryUrl(),
            request);
    }


    public static getIdentityDestinationTenantIdsUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/access/getIdentityDestinationTenantIds/{typeName}", { typeName });

    public static getIdentityDestinationTenantIds(request: Contract.AccessControllerGetIdentityDestinationTenantIdsRequest): Promise<Contract.AccessControllerGetIdentityDestinationTenantIdsResponse> {
        return ApiController.postRequest<Contract.AccessControllerGetIdentityDestinationTenantIdsRequest, Contract.AccessControllerGetIdentityDestinationTenantIdsResponse>(
            AccessController.getIdentityDestinationTenantIdsUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }
}

export class AdministrationController extends ApiController {
    public static configureTenantUrl =
        () => "/api/console/administration/configureTenant";

    public static configureTenant(request: Contract.AdministrationControllerConfigureTenantRequest): Promise<Contract.AdministrationControllerConfigureTenantResponse> {
        return ApiController.postRequest<Contract.AdministrationControllerConfigureTenantRequest, Contract.AdministrationControllerConfigureTenantResponse>(
            AdministrationController.configureTenantUrl(),
            request);
    }


    public static deleteLogoUrl =
        () => "/api/console/administration/deleteLogo";

    public static deleteLogo(): Promise<void> {
        return ApiController.postRequest<any, void>(
            AdministrationController.deleteLogoUrl(),
            {});
    }


    public static getInformationUrl =
        () => "/api/console/administration/getInformation";

    public static getInformation(): Promise<Contract.AdministrationControllerGetInformationResponse> {
        return ApiController.postRequest<any, Contract.AdministrationControllerGetInformationResponse>(
            AdministrationController.getInformationUrl(),
            {});
    }


    public static getOrchestrationStageDatasUrl =
        () => "/api/console/administration/getOrchestrationStageDatas";

    public static getOrchestrationStageDatas(): Promise<Contract.AdministrationControllerGetOrchestrationStageDatasResponse> {
        return ApiController.postRequest<any, Contract.AdministrationControllerGetOrchestrationStageDatasResponse>(
            AdministrationController.getOrchestrationStageDatasUrl(),
            {});
    }


    public static updateCustomerUrl =
        () => "/api/console/administration/updateCustomer";

    public static updateCustomer(request: Contract.AdministrationControllerUpdateCustomerRequest): Promise<void> {
        return ApiController.postRequest<Contract.AdministrationControllerUpdateCustomerRequest, void>(
            AdministrationController.updateCustomerUrl(),
            request);
    }


    public static updateLogoUrl =
        () => "/api/console/administration/updateLogo";

    public static updateLogo(request: Contract.AdministrationControllerUpdateLogoRequest): Promise<void> {
        return ApiController.postRequest<Contract.AdministrationControllerUpdateLogoRequest, void>(
            AdministrationController.updateLogoUrl(),
            request);
    }


    public static getAuthenticationConfigurationUrl =
        () => "/api/console/administration/getAuthenticationConfiguration";

    public static getAuthenticationConfiguration(): Promise<Contract.AdministrationControllerGetAuthenticationConfigurationResponse> {
        return ApiController.postRequest<any, Contract.AdministrationControllerGetAuthenticationConfigurationResponse>(
            AdministrationController.getAuthenticationConfigurationUrl(),
            {});
    }


    public static getAuthenticationUsersUrl =
        () => "/api/console/administration/getAuthenticationUsers";

    public static getAuthenticationUsers(): Promise<Contract.AdministrationControllerGetAuthenticationUsersResponse> {
        return ApiController.postRequest<any, Contract.AdministrationControllerGetAuthenticationUsersResponse>(
            AdministrationController.getAuthenticationUsersUrl(),
            {});
    }


    public static resetCognitoUserPasswordUrl =
        () => "/api/console/administration/resetCognitoUserPassword";

    public static resetCognitoUserPassword(request: Contract.AdministrationControllerResetCognitoUserPasswordRequest): Promise<Contract.AdministrationControllerResetCognitoUserPasswordResponse> {
        return ApiController.postRequest<Contract.AdministrationControllerResetCognitoUserPasswordRequest, Contract.AdministrationControllerResetCognitoUserPasswordResponse>(
            AdministrationController.resetCognitoUserPasswordUrl(),
            request);
    }


    public static updateAuthenticationConfigurationUrl =
        () => "/api/console/administration/updateAuthenticationConfiguration";

    public static updateAuthenticationConfiguration(request: Contract.AdministrationControllerUpdateAuthenticationConfigurationRequest): Promise<void> {
        return ApiController.postRequest<Contract.AdministrationControllerUpdateAuthenticationConfigurationRequest, void>(
            AdministrationController.updateAuthenticationConfigurationUrl(),
            request);
    }


    public static getDataAnalysisUrl =
        () => "/api/console/administration/getDataAnalysis";

    public static getDataAnalysis(request: Contract.AdministrationControllerGetDataAnalysisRequest): Promise<Contract.AdministrationControllerGetDataAnalysisResponse> {
        return ApiController.postRequest<Contract.AdministrationControllerGetDataAnalysisRequest, Contract.AdministrationControllerGetDataAnalysisResponse>(
            AdministrationController.getDataAnalysisUrl(),
            request);
    }


    public static updateDataAnalysisUrl =
        () => "/api/console/administration/updateDataAnalysis";

    public static updateDataAnalysis(request: Contract.AdministrationControllerUpdateDataAnalysisRequest): Promise<void> {
        return ApiController.postRequest<Contract.AdministrationControllerUpdateDataAnalysisRequest, void>(
            AdministrationController.updateDataAnalysisUrl(),
            request);
    }


    public static getFeaturesUrl =
        () => "/api/console/administration/getFeatures";

    public static getFeatures(): Promise<Contract.AdministrationControllerGetFeaturesResponse> {
        return ApiController.postRequest<any, Contract.AdministrationControllerGetFeaturesResponse>(
            AdministrationController.getFeaturesUrl(),
            {});
    }


    public static updateFeatureUrl =
        () => "/api/console/administration/updateFeature";

    public static updateFeature(request: Contract.AdministrationControllerUpdateFeatureRequest): Promise<void> {
        return ApiController.postRequest<Contract.AdministrationControllerUpdateFeatureRequest, void>(
            AdministrationController.updateFeatureUrl(),
            request);
    }


    public static getLicensingConfigurationUrl =
        () => "/api/console/administration/getLicensingConfiguration";

    public static getLicensingConfiguration(): Promise<Contract.AdministrationControllerGetLicensingConfigurationResponse> {
        return ApiController.postRequest<any, Contract.AdministrationControllerGetLicensingConfigurationResponse>(
            AdministrationController.getLicensingConfigurationUrl(),
            {});
    }


    public static getLicensingObjectCountAveragePreviewUrl =
        () => "/api/console/administration/getLicensingObjectCountAveragePreview";

    public static getLicensingObjectCountAveragePreview(request: Contract.AdministrationControllerGetLicensingObjectCountAveragePreviewRequest): Promise<Contract.AdministrationControllerGetLicensingObjectCountAveragePreviewResponse> {
        return ApiController.postRequest<Contract.AdministrationControllerGetLicensingObjectCountAveragePreviewRequest, Contract.AdministrationControllerGetLicensingObjectCountAveragePreviewResponse>(
            AdministrationController.getLicensingObjectCountAveragePreviewUrl(),
            request);
    }


    public static updateLicensingUrl =
        () => "/api/console/administration/updateLicensing";

    public static updateLicensing(request: Contract.AdministrationControllerUpdateLicensingRequest): Promise<void> {
        return ApiController.postRequest<Contract.AdministrationControllerUpdateLicensingRequest, void>(
            AdministrationController.updateLicensingUrl(),
            request);
    }


    public static deleteSupportPrincipalUrl =
        () => "/api/console/administration/deleteSupportPrincipal";

    public static deleteSupportPrincipal(request: Contract.AdministrationControllerDeleteSupportPrincipalRequest): Promise<void> {
        return ApiController.postRequest<Contract.AdministrationControllerDeleteSupportPrincipalRequest, void>(
            AdministrationController.deleteSupportPrincipalUrl(),
            request);
    }


    public static getSupportPrincipalsUrl =
        () => "/api/console/administration/getSupportPrincipals";

    public static getSupportPrincipals(): Promise<Contract.AdministrationControllerGetSupportPrincipalsResponse> {
        return ApiController.postRequest<any, Contract.AdministrationControllerGetSupportPrincipalsResponse>(
            AdministrationController.getSupportPrincipalsUrl(),
            {});
    }


    public static upsertSupportPrincipalUrl =
        () => "/api/console/administration/upsertSupportPrincipal";

    public static upsertSupportPrincipal(request: Contract.AdministrationControllerUpsertSupportPrincipalRequest): Promise<void> {
        return ApiController.postRequest<Contract.AdministrationControllerUpsertSupportPrincipalRequest, void>(
            AdministrationController.upsertSupportPrincipalUrl(),
            request);
    }


    public static getWorkloadAnalysisUrl =
        () => "/api/console/administration/getWorkloadAnalysis";

    public static getWorkloadAnalysis(request: Contract.AdministrationControllerGetWorkloadAnalysisRequest): Promise<Contract.AdministrationControllerGetWorkloadAnalysisResponse> {
        return ApiController.postRequest<Contract.AdministrationControllerGetWorkloadAnalysisRequest, Contract.AdministrationControllerGetWorkloadAnalysisResponse>(
            AdministrationController.getWorkloadAnalysisUrl(),
            request);
    }


    public static updateWorkloadAnalysisUrl =
        () => "/api/console/administration/updateWorkloadAnalysis";

    public static updateWorkloadAnalysis(request: Contract.AdministrationControllerUpdateWorkloadAnalysisRequest): Promise<void> {
        return ApiController.postRequest<Contract.AdministrationControllerUpdateWorkloadAnalysisRequest, void>(
            AdministrationController.updateWorkloadAnalysisUrl(),
            request);
    }
}

export class AuditEventController extends ApiController {
    public static deleteRiskNoteCreationAuditEventUrl =
        () => "/api/console/auditEvent/deleteRiskNoteCreationAuditEvent";

    public static deleteRiskNoteCreationAuditEvent(request: Contract.AuditEventControllerDeleteRiskNoteCreationAuditEventRequest): Promise<void> {
        return ApiController.postRequest<Contract.AuditEventControllerDeleteRiskNoteCreationAuditEventRequest, void>(
            AuditEventController.deleteRiskNoteCreationAuditEventUrl(),
            request);
    }


    public static deleteRiskStatusNoteAuditEventUrl =
        () => "/api/console/auditEvent/deleteRiskStatusNoteAuditEvent";

    public static deleteRiskStatusNoteAuditEvent(request: Contract.AuditEventControllerDeleteRiskStatusNoteAuditEventRequest): Promise<Contract.AuditEventControllerDeleteRiskStatusNoteAuditEventResponse> {
        return ApiController.postRequest<Contract.AuditEventControllerDeleteRiskStatusNoteAuditEventRequest, Contract.AuditEventControllerDeleteRiskStatusNoteAuditEventResponse>(
            AuditEventController.deleteRiskStatusNoteAuditEventUrl(),
            request);
    }


    public static getAuditEventFiltersUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/auditEvent/getAuditEventFilters/{typeName}", { typeName });

    public static getAuditEventFilters(request: Contract.AuditEventControllerGetAuditEventFiltersRequestBase): Promise<Contract.AuditEventControllerGetAuditEventFiltersResponse> {
        return ApiController.postRequest<Contract.AuditEventControllerGetAuditEventFiltersRequestBase, Contract.AuditEventControllerGetAuditEventFiltersResponse>(
            AuditEventController.getAuditEventFiltersUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getAuditEventModelPageUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/auditEvent/getAuditEventModelPage/{typeName}", { typeName });

    public static getAuditEventModelPage(request: Contract.AuditEventControllerGetAuditEventModelPageRequestBase): Promise<Contract.AuditEventControllerGetAuditEventModelPageResponse> {
        return ApiController.postRequest<Contract.AuditEventControllerGetAuditEventModelPageRequestBase, Contract.AuditEventControllerGetAuditEventModelPageResponse>(
            AuditEventController.getAuditEventModelPageUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getAuditEventModelsUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/auditEvent/getAuditEventModels/{typeName}", { typeName });

    public static getAuditEventModels(request: Contract.AuditEventControllerGetAuditEventModelsRequest): Promise<Contract.AuditEventControllerGetAuditEventModelsResponse> {
        return ApiController.postRequest<Contract.AuditEventControllerGetAuditEventModelsRequest, Contract.AuditEventControllerGetAuditEventModelsResponse>(
            AuditEventController.getAuditEventModelsUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getAuditEventTypeNamesUrl =
        () => "/api/console/auditEvent/getAuditEventTypeNames";

    public static getAuditEventTypeNames(): Promise<Contract.AuditEventControllerGetAuditEventTypeNamesResponse> {
        return ApiController.postRequest<any, Contract.AuditEventControllerGetAuditEventTypeNamesResponse>(
            AuditEventController.getAuditEventTypeNamesUrl(),
            {});
    }


    public static getPermissionAuditEventFilterItemPageUrl =
        () => "/api/console/auditEvent/getPermissionAuditEventFilterItemPage";

    public static getPermissionAuditEventFilterItemPage(request: Contract.AuditEventControllerGetPermissionAuditEventFilterItemPageRequest): Promise<Contract.AuditEventControllerGetPermissionAuditEventFilterItemPageResponseBase> {
        return ApiController.postRequest<Contract.AuditEventControllerGetPermissionAuditEventFilterItemPageRequest, Contract.AuditEventControllerGetPermissionAuditEventFilterItemPageResponseBase>(
            AuditEventController.getPermissionAuditEventFilterItemPageUrl(),
            request);
    }


    public static insertRiskNoteCreationAuditEventUrl =
        () => "/api/console/auditEvent/insertRiskNoteCreationAuditEvent";

    public static insertRiskNoteCreationAuditEvent(request: Contract.AuditEventControllerInsertRiskNoteCreationAuditEventRequest): Promise<Contract.AuditEventControllerInsertRiskNoteCreationAuditEventResponse> {
        return ApiController.postRequest<Contract.AuditEventControllerInsertRiskNoteCreationAuditEventRequest, Contract.AuditEventControllerInsertRiskNoteCreationAuditEventResponse>(
            AuditEventController.insertRiskNoteCreationAuditEventUrl(),
            request);
    }


    public static updateRiskNoteCreationAuditEventUrl =
        () => "/api/console/auditEvent/updateRiskNoteCreationAuditEvent";

    public static updateRiskNoteCreationAuditEvent(request: Contract.AuditEventControllerUpdateRiskNoteCreationAuditEventRequest): Promise<Contract.AuditEventControllerUpdateRiskNoteCreationAuditEventResponse> {
        return ApiController.postRequest<Contract.AuditEventControllerUpdateRiskNoteCreationAuditEventRequest, Contract.AuditEventControllerUpdateRiskNoteCreationAuditEventResponse>(
            AuditEventController.updateRiskNoteCreationAuditEventUrl(),
            request);
    }


    public static updateRiskStatusUpdateAuditEventUrl =
        () => "/api/console/auditEvent/updateRiskStatusUpdateAuditEvent";

    public static updateRiskStatusUpdateAuditEvent(request: Contract.AuditEventControllerUpdateRiskStatusUpdateAuditEventRequest): Promise<Contract.AuditEventControllerUpdateRiskStatusUpdateAuditEventResponse> {
        return ApiController.postRequest<Contract.AuditEventControllerUpdateRiskStatusUpdateAuditEventRequest, Contract.AuditEventControllerUpdateRiskStatusUpdateAuditEventResponse>(
            AuditEventController.updateRiskStatusUpdateAuditEventUrl(),
            request);
    }
}

export class AuthenticationController extends ApiController {
    public static changeCognitoUserPasswordUrl =
        () => "/api/console/authentication/changeCognitoUserPassword";

    public static changeCognitoUserPassword(request: Contract.AuthenticationControllerChangeCognitoUserPasswordRequest): Promise<Contract.AuthenticationControllerChangeCognitoUserPasswordResponse> {
        return ApiController.postRequest<Contract.AuthenticationControllerChangeCognitoUserPasswordRequest, Contract.AuthenticationControllerChangeCognitoUserPasswordResponse>(
            AuthenticationController.changeCognitoUserPasswordUrl(),
            request);
    }


    public static getUserUrl =
        () => "/api/console/authentication/getUser";

    public static getUser(): Promise<Contract.AuthenticationControllerGetUserResponse> {
        return ApiController.postRequest<any, Contract.AuthenticationControllerGetUserResponse>(
            AuthenticationController.getUserUrl(),
            {});
    }


    public static getUserCustomerDataUrl =
        () => "/api/console/authentication/getUserCustomerData";

    public static getUserCustomerData(): Promise<Contract.AuthenticationControllerGetUserCustomerDataResponse> {
        return ApiController.postRequest<any, Contract.AuthenticationControllerGetUserCustomerDataResponse>(
            AuthenticationController.getUserCustomerDataUrl(),
            {});
    }


    public static getUserCustomerDatasUrl =
        () => "/api/console/authentication/getUserCustomerDatas";

    public static getUserCustomerDatas(): Promise<Contract.AuthenticationControllerGetUserCustomerDatasResponse> {
        return ApiController.postRequest<any, Contract.AuthenticationControllerGetUserCustomerDatasResponse>(
            AuthenticationController.getUserCustomerDatasUrl(),
            {});
    }


    public static getUserIdentityProvidersUrl =
        () => "/api/console/authentication/getUserIdentityProviders";

    public static getUserIdentityProviders(request: Contract.AuthenticationControllerGetUserIdentityProvidersRequest): Promise<Contract.AuthenticationControllerGetUserIdentityProvidersResponse> {
        return ApiController.postRequest<Contract.AuthenticationControllerGetUserIdentityProvidersRequest, Contract.AuthenticationControllerGetUserIdentityProvidersResponse>(
            AuthenticationController.getUserIdentityProvidersUrl(),
            request);
    }


    public static recoverCognitoUserPasswordUrl =
        () => "/api/console/authentication/recoverCognitoUserPassword";

    public static recoverCognitoUserPassword(request: Contract.AuthenticationControllerRecoverCognitoUserPasswordRequest): Promise<Contract.AuthenticationControllerRecoverCognitoUserPasswordResponse> {
        return ApiController.postRequest<Contract.AuthenticationControllerRecoverCognitoUserPasswordRequest, Contract.AuthenticationControllerRecoverCognitoUserPasswordResponse>(
            AuthenticationController.recoverCognitoUserPasswordUrl(),
            request);
    }


    public static setUserCustomerUrl =
        () => "/api/console/authentication/setUserCustomer";

    public static setUserCustomer(request: Contract.AuthenticationControllerSetUserCustomerRequest): Promise<void> {
        return ApiController.postRequest<Contract.AuthenticationControllerSetUserCustomerRequest, void>(
            AuthenticationController.setUserCustomerUrl(),
            request);
    }


    public static signInCognitoUserUrl =
        () => "/api/console/authentication/signInCognitoUser";

    public static signInCognitoUser(request: Contract.AuthenticationControllerSignInCognitoUserRequest): Promise<Contract.AuthenticationControllerSignInCognitoUserResponse> {
        return ApiController.postRequest<Contract.AuthenticationControllerSignInCognitoUserRequest, Contract.AuthenticationControllerSignInCognitoUserResponse>(
            AuthenticationController.signInCognitoUserUrl(),
            request);
    }


    public static getTeamsSignInDeploymentRedirectUrl =
        () => "/api/console/authentication/getTeamsSignInDeploymentRedirect";

    public static getTeamsSignInDeploymentRedirect(request: Contract.AuthenticationControllerGetTeamsSignInDeploymentRedirectRequest): Promise<Contract.AuthenticationControllerGetTeamsSignInDeploymentRedirectResponse> {
        return ApiController.postRequest<Contract.AuthenticationControllerGetTeamsSignInDeploymentRedirectRequest, Contract.AuthenticationControllerGetTeamsSignInDeploymentRedirectResponse>(
            AuthenticationController.getTeamsSignInDeploymentRedirectUrl(),
            request);
    }


    public static signInTeamsUrl =
        () => "/api/console/authentication/signInTeams";

    public static signInTeams(request: Contract.AuthenticationControllerSignInTeamsRequest): Promise<Contract.AuthenticationControllerSignInTeamsResponse> {
        return ApiController.postRequest<Contract.AuthenticationControllerSignInTeamsRequest, Contract.AuthenticationControllerSignInTeamsResponse>(
            AuthenticationController.signInTeamsUrl(),
            request);
    }


    public static signInAadSystemUserUrl =
        (applicationConsentTenantRawId: string, partitionType: Contract.AadPartitionType) => ApiController.formatUrlParameters("/api/console/authentication/signInAadSystemUser", { applicationConsentTenantRawId, partitionType });

    public static signInAadSystemUser(applicationConsentTenantRawId: string, partitionType: Contract.AadPartitionType): Promise<void> {
        return ApiController.getRequest<void>(AuthenticationController.signInAadSystemUserUrl(applicationConsentTenantRawId, partitionType));
    }


    public static signInAadUserUrl =
        (localEnvironment: Optional<boolean>, userMail: Optional<string>) => ApiController.formatUrlParameters("/api/console/authentication/signInAadUser", { localEnvironment, userMail });

    public static signInAadUser(localEnvironment: Optional<boolean>, userMail: Optional<string>): Promise<void> {
        return ApiController.getRequest<void>(AuthenticationController.signInAadUserUrl(localEnvironment, userMail));
    }


    public static signInGciUserUrl =
        (localEnvironment: Optional<boolean>, userMail: Optional<string>) => ApiController.formatUrlParameters("/api/console/authentication/signInGciUser", { localEnvironment, userMail });

    public static signInGciUser(localEnvironment: Optional<boolean>, userMail: Optional<string>): Promise<void> {
        return ApiController.getRequest<void>(AuthenticationController.signInGciUserUrl(localEnvironment, userMail));
    }


    public static signInSamlUserUrl =
        (issuerId: string) => ApiController.formatUrlParameters("/api/console/authentication/signInSamlUser/{issuerId}", { issuerId });

    public static signInSamlUser(issuerId: string): Promise<void> {
        return ApiController.getRequest<void>(AuthenticationController.signInSamlUserUrl(issuerId));
    }


    public static signOutUserUrl =
        () => "/api/console/authentication/signOutUser";

    public static signOutUser(): Promise<void> {
        return ApiController.postRequest<any, void>(
            AuthenticationController.signOutUserUrl(),
            {});
    }


    public static startRecoverCognitoUserPasswordUrl =
        () => "/api/console/authentication/startRecoverCognitoUserPassword";

    public static startRecoverCognitoUserPassword(request: Contract.AuthenticationControllerStartRecoverCognitoUserPasswordRequest): Promise<Contract.AuthenticationControllerStartRecoverCognitoUserPasswordResponse> {
        return ApiController.postRequest<Contract.AuthenticationControllerStartRecoverCognitoUserPasswordRequest, Contract.AuthenticationControllerStartRecoverCognitoUserPasswordResponse>(
            AuthenticationController.startRecoverCognitoUserPasswordUrl(),
            request);
    }
}

export class CodeController extends ApiController {
    public static getDashboardSummaryUrl =
        () => "/api/console/code/getDashboardSummary";

    public static getDashboardSummary(): Promise<Contract.CodeControllerGetDashboardSummaryResponse> {
        return ApiController.postRequest<any, Contract.CodeControllerGetDashboardSummaryResponse>(
            CodeController.getDashboardSummaryUrl(),
            {});
    }


    public static getResourcesUrl =
        () => "/api/console/code/getResources";

    public static getResources(request: Contract.CodeControllerGetResourcesRequest): Promise<Contract.CodeControllerGetResourcesResponse> {
        return ApiController.postRequest<Contract.CodeControllerGetResourcesRequest, Contract.CodeControllerGetResourcesResponse>(
            CodeController.getResourcesUrl(),
            request);
    }


    public static getContainerImageScanExistsUrl =
        () => "/api/console/code/getContainerImageScanExists";

    public static getContainerImageScanExists(): Promise<Contract.CodeControllerGetContainerImageScanExistsResponse> {
        return ApiController.postRequest<any, Contract.CodeControllerGetContainerImageScanExistsResponse>(
            CodeController.getContainerImageScanExistsUrl(),
            {});
    }


    public static getContainerImageScanFilterItemPageUrl =
        () => "/api/console/code/getContainerImageScanFilterItemPage";

    public static getContainerImageScanFilterItemPage(request: Contract.CodeControllerGetContainerImageScanFilterItemPageRequest): Promise<Contract.CodeControllerGetContainerImageScanFilterItemPageResponse> {
        return ApiController.postRequest<Contract.CodeControllerGetContainerImageScanFilterItemPageRequest, Contract.CodeControllerGetContainerImageScanFilterItemPageResponse>(
            CodeController.getContainerImageScanFilterItemPageUrl(),
            request);
    }


    public static getContainerImageScanFiltersUrl =
        () => "/api/console/code/getContainerImageScanFilters";

    public static getContainerImageScanFilters(): Promise<Contract.CodeControllerGetContainerImageScanFiltersResponse> {
        return ApiController.postRequest<any, Contract.CodeControllerGetContainerImageScanFiltersResponse>(
            CodeController.getContainerImageScanFiltersUrl(),
            {});
    }


    public static getContainerImageScanPageUrl =
        () => "/api/console/code/getContainerImageScanPage";

    public static getContainerImageScanPage(request: Contract.CodeControllerGetContainerImageScanPageRequest): Promise<Contract.CodeControllerGetContainerImageScanPageResponse> {
        return ApiController.postRequest<Contract.CodeControllerGetContainerImageScanPageRequest, Contract.CodeControllerGetContainerImageScanPageResponse>(
            CodeController.getContainerImageScanPageUrl(),
            request);
    }


    public static getContainerImageScansUrl =
        () => "/api/console/code/getContainerImageScans";

    public static getContainerImageScans(request: Contract.CodeControllerGetContainerImageScansRequest): Promise<Contract.CodeControllerGetContainerImageScansResponse> {
        return ApiController.postRequest<Contract.CodeControllerGetContainerImageScansRequest, Contract.CodeControllerGetContainerImageScansResponse>(
            CodeController.getContainerImageScansUrl(),
            request);
    }


    public static getContainerImageScanPackageUrl =
        () => "/api/console/code/getContainerImageScanPackage";

    public static getContainerImageScanPackage(request: Contract.CodeControllerGetContainerImageScanPackageRequest): Promise<Contract.CodeControllerGetContainerImageScanPackageResponse> {
        return ApiController.postRequest<Contract.CodeControllerGetContainerImageScanPackageRequest, Contract.CodeControllerGetContainerImageScanPackageResponse>(
            CodeController.getContainerImageScanPackageUrl(),
            request);
    }


    public static getContainerImageScanPackageFilterItemPageUrl =
        () => "/api/console/code/getContainerImageScanPackageFilterItemPage";

    public static getContainerImageScanPackageFilterItemPage(request: Contract.CodeControllerGetContainerImageScanPackageFilterItemPageRequest): Promise<Contract.CodeControllerGetContainerImageScanPackageFilterItemPageResponse> {
        return ApiController.postRequest<Contract.CodeControllerGetContainerImageScanPackageFilterItemPageRequest, Contract.CodeControllerGetContainerImageScanPackageFilterItemPageResponse>(
            CodeController.getContainerImageScanPackageFilterItemPageUrl(),
            request);
    }


    public static getContainerImageScanPackagePageUrl =
        () => "/api/console/code/getContainerImageScanPackagePage";

    public static getContainerImageScanPackagePage(request: Contract.CodeControllerGetContainerImageScanPackagePageRequest): Promise<Contract.CodeControllerGetContainerImagePackagePageResponse> {
        return ApiController.postRequest<Contract.CodeControllerGetContainerImageScanPackagePageRequest, Contract.CodeControllerGetContainerImagePackagePageResponse>(
            CodeController.getContainerImageScanPackagePageUrl(),
            request);
    }


    public static getContainerImageScanVulnerabilityUrl =
        () => "/api/console/code/getContainerImageScanVulnerability";

    public static getContainerImageScanVulnerability(request: Contract.CodeControllerGetContainerImageScanVulnerabilityRequest): Promise<Contract.CodeControllerGetContainerImageScanVulnerabilityResponse> {
        return ApiController.postRequest<Contract.CodeControllerGetContainerImageScanVulnerabilityRequest, Contract.CodeControllerGetContainerImageScanVulnerabilityResponse>(
            CodeController.getContainerImageScanVulnerabilityUrl(),
            request);
    }


    public static getContainerImageScanVulnerabilityFilterItemPageUrl =
        () => "/api/console/code/getContainerImageScanVulnerabilityFilterItemPage";

    public static getContainerImageScanVulnerabilityFilterItemPage(request: Contract.CodeControllerGetContainerImageScanVulnerabilityFilterItemPageRequest): Promise<Contract.CodeControllerGetContainerImageScanVulnerabilityFilterItemPageResponse> {
        return ApiController.postRequest<Contract.CodeControllerGetContainerImageScanVulnerabilityFilterItemPageRequest, Contract.CodeControllerGetContainerImageScanVulnerabilityFilterItemPageResponse>(
            CodeController.getContainerImageScanVulnerabilityFilterItemPageUrl(),
            request);
    }


    public static getContainerImageScanVulnerabilityPageUrl =
        () => "/api/console/code/getContainerImageScanVulnerabilityPage";

    public static getContainerImageScanVulnerabilityPage(request: Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequest): Promise<Contract.CodeControllerGetContainerImageVulnerabilityPageResponse> {
        return ApiController.postRequest<Contract.CodeControllerGetContainerImageScanVulnerabilityPageRequest, Contract.CodeControllerGetContainerImageVulnerabilityPageResponse>(
            CodeController.getContainerImageScanVulnerabilityPageUrl(),
            request);
    }


    public static getIacRiskUrl =
        () => "/api/console/code/getIacRisk";

    public static getIacRisk(request: Contract.CodeControllerGetIacRiskRequest): Promise<Contract.CodeControllerGetIacRiskResponse> {
        return ApiController.postRequest<Contract.CodeControllerGetIacRiskRequest, Contract.CodeControllerGetIacRiskResponse>(
            CodeController.getIacRiskUrl(),
            request);
    }


    public static getIacRiskFilterItemPageUrl =
        () => "/api/console/code/getIacRiskFilterItemPage";

    public static getIacRiskFilterItemPage(request: Contract.CodeControllerGetIacRiskFilterItemPageRequest): Promise<Contract.CodeControllerGetIacRiskFilterItemPageResponse> {
        return ApiController.postRequest<Contract.CodeControllerGetIacRiskFilterItemPageRequest, Contract.CodeControllerGetIacRiskFilterItemPageResponse>(
            CodeController.getIacRiskFilterItemPageUrl(),
            request);
    }


    public static getIacRiskFiltersUrl =
        () => "/api/console/code/getIacRiskFilters";

    public static getIacRiskFilters(request: Contract.CodeControllerGetIacRiskFiltersRequest): Promise<Contract.CodeControllerGetIacRiskFiltersResponse> {
        return ApiController.postRequest<Contract.CodeControllerGetIacRiskFiltersRequest, Contract.CodeControllerGetIacRiskFiltersResponse>(
            CodeController.getIacRiskFiltersUrl(),
            request);
    }


    public static getIacRiskPageUrl =
        () => "/api/console/code/getIacRiskPage";

    public static getIacRiskPage(request: Contract.CodeControllerGetIacRiskPageRequest): Promise<Contract.CodeControllerGetIacRiskPageResponse> {
        return ApiController.postRequest<Contract.CodeControllerGetIacRiskPageRequest, Contract.CodeControllerGetIacRiskPageResponse>(
            CodeController.getIacRiskPageUrl(),
            request);
    }


    public static getIacScanUrl =
        () => "/api/console/code/getIacScan";

    public static getIacScan(request: Contract.CodeControllerGetIacScanRequest): Promise<Contract.CodeControllerGetIacScanResponse> {
        return ApiController.postRequest<Contract.CodeControllerGetIacScanRequest, Contract.CodeControllerGetIacScanResponse>(
            CodeController.getIacScanUrl(),
            request);
    }


    public static getIacScanExistsUrl =
        () => "/api/console/code/getIacScanExists";

    public static getIacScanExists(): Promise<Contract.CodeControllerGetIacScanExistsResponse> {
        return ApiController.postRequest<any, Contract.CodeControllerGetIacScanExistsResponse>(
            CodeController.getIacScanExistsUrl(),
            {});
    }


    public static getIacScanFilterItemPageUrl =
        () => "/api/console/code/getIacScanFilterItemPage";

    public static getIacScanFilterItemPage(request: Contract.CodeControllerGetIacScanFilterItemPageRequest): Promise<Contract.CodeControllerGetIacScanFilterItemPageResponse> {
        return ApiController.postRequest<Contract.CodeControllerGetIacScanFilterItemPageRequest, Contract.CodeControllerGetIacScanFilterItemPageResponse>(
            CodeController.getIacScanFilterItemPageUrl(),
            request);
    }


    public static getIacScanFiltersUrl =
        () => "/api/console/code/getIacScanFilters";

    public static getIacScanFilters(): Promise<Contract.CodeControllerGetIacScanFiltersResponse> {
        return ApiController.postRequest<any, Contract.CodeControllerGetIacScanFiltersResponse>(
            CodeController.getIacScanFiltersUrl(),
            {});
    }


    public static getIacScanPageUrl =
        () => "/api/console/code/getIacScanPage";

    public static getIacScanPage(request: Contract.CodeControllerGetIacScanPageRequest): Promise<Contract.CodeControllerGetIacScanPageResponse> {
        return ApiController.postRequest<Contract.CodeControllerGetIacScanPageRequest, Contract.CodeControllerGetIacScanPageResponse>(
            CodeController.getIacScanPageUrl(),
            request);
    }
}

export class ConfigurationController extends ApiController {
    public static deleteAnalysisNetworkUrl =
        () => "/api/console/configuration/deleteAnalysisNetwork";

    public static deleteAnalysisNetwork(request: Contract.ConfigurationControllerDeleteAnalysisNetworkRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteAnalysisNetworkRequest, void>(
            ConfigurationController.deleteAnalysisNetworkUrl(),
            request);
    }


    public static getAnalysisNetworkUrl =
        () => "/api/console/configuration/getAnalysisNetwork";

    public static getAnalysisNetwork(): Promise<Contract.ConfigurationControllerGetAnalysisNetworkResponse> {
        return ApiController.postRequest<any, Contract.ConfigurationControllerGetAnalysisNetworkResponse>(
            ConfigurationController.getAnalysisNetworkUrl(),
            {});
    }


    public static upsertAnalysisNetworkUrl =
        () => "/api/console/configuration/upsertAnalysisNetwork";

    public static upsertAnalysisNetwork(request: Contract.ConfigurationControllerUpsertAnalysisNetworkRequest): Promise<Contract.ConfigurationControllerUpsertAnalysisNetworkResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpsertAnalysisNetworkRequest, Contract.ConfigurationControllerUpsertAnalysisNetworkResponse>(
            ConfigurationController.upsertAnalysisNetworkUrl(),
            request);
    }


    public static deleteApiKeyUrl =
        () => "/api/console/configuration/deleteApiKey";

    public static deleteApiKey(request: Contract.ConfigurationControllerDeleteApiKeyRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteApiKeyRequest, void>(
            ConfigurationController.deleteApiKeyUrl(),
            request);
    }


    public static getApiKeysUrl =
        () => "/api/console/configuration/getApiKeys";

    public static getApiKeys(request: Contract.ConfigurationControllerGetApiKeysRequest): Promise<Contract.ConfigurationControllerGetApiKeysResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerGetApiKeysRequest, Contract.ConfigurationControllerGetApiKeysResponse>(
            ConfigurationController.getApiKeysUrl(),
            request);
    }


    public static insertApiKeyUrl =
        () => "/api/console/configuration/insertApiKey";

    public static insertApiKey(request: Contract.ConfigurationControllerInsertApiKeyRequest): Promise<Contract.ConfigurationControllerInsertApiKeyResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerInsertApiKeyRequest, Contract.ConfigurationControllerInsertApiKeyResponse>(
            ConfigurationController.insertApiKeyUrl(),
            request);
    }


    public static deleteAutomaticCustomEntityAttributeDefinitionTemplateUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/configuration/deleteAutomaticCustomEntityAttributeDefinitionTemplate/{typeName}", { typeName });

    public static deleteAutomaticCustomEntityAttributeDefinitionTemplate(request: Contract.ConfigurationControllerDeleteAutomaticCustomEntityAttributeDefinitionTemplateRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteAutomaticCustomEntityAttributeDefinitionTemplateRequest, void>(
            ConfigurationController.deleteAutomaticCustomEntityAttributeDefinitionTemplateUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getAutomaticCustomEntityAttributeDefinitionTemplateModelsUrl =
        () => "/api/console/configuration/getAutomaticCustomEntityAttributeDefinitionTemplateModels";

    public static getAutomaticCustomEntityAttributeDefinitionTemplateModels(): Promise<Contract.ConfigurationControllerGetAutomaticCustomEntityAttributeDefinitionTemplateModelsResponse> {
        return ApiController.postRequest<any, Contract.ConfigurationControllerGetAutomaticCustomEntityAttributeDefinitionTemplateModelsResponse>(
            ConfigurationController.getAutomaticCustomEntityAttributeDefinitionTemplateModelsUrl(),
            {});
    }


    public static getAutomaticCustomEntityAttributeDefinitionTemplateUsageUrl =
        () => "/api/console/configuration/getAutomaticCustomEntityAttributeDefinitionTemplateUsage";

    public static getAutomaticCustomEntityAttributeDefinitionTemplateUsage(request: Contract.ConfigurationControllerGetAutomaticCustomEntityAttributeDefinitionTemplateUsageRequest): Promise<Contract.ConfigurationControllerGetAutomaticCustomEntityAttributeDefinitionTemplateUsageResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerGetAutomaticCustomEntityAttributeDefinitionTemplateUsageRequest, Contract.ConfigurationControllerGetAutomaticCustomEntityAttributeDefinitionTemplateUsageResponse>(
            ConfigurationController.getAutomaticCustomEntityAttributeDefinitionTemplateUsageUrl(),
            request);
    }


    public static upsertAutomaticCustomEntityAttributeDefinitionTemplateUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/configuration/upsertAutomaticCustomEntityAttributeDefinitionTemplate/{typeName}", { typeName });

    public static upsertAutomaticCustomEntityAttributeDefinitionTemplate(request: Contract.ConfigurationControllerUpsertAutomaticCustomEntityAttributeDefinitionTemplateRequest): Promise<Contract.ConfigurationControllerUpsertAutomaticCustomEntityAttributeDefinitionTemplateResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpsertAutomaticCustomEntityAttributeDefinitionTemplateRequest, Contract.ConfigurationControllerUpsertAutomaticCustomEntityAttributeDefinitionTemplateResponse>(
            ConfigurationController.upsertAutomaticCustomEntityAttributeDefinitionTemplateUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static deleteAutomationRuleUrl =
        () => "/api/console/configuration/deleteAutomationRule";

    public static deleteAutomationRule(request: Contract.ConfigurationControllerDeleteAutomationRuleRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteAutomationRuleRequest, void>(
            ConfigurationController.deleteAutomationRuleUrl(),
            request);
    }


    public static upsertAuditEventAutomationRuleUrl =
        () => "/api/console/configuration/upsertAuditEventAutomationRule";

    public static upsertAuditEventAutomationRule(request: Contract.ConfigurationControllerUpsertAuditEventAutomationRuleRequest): Promise<Contract.ConfigurationControllerUpsertScopeSystemEntityResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpsertAuditEventAutomationRuleRequest, Contract.ConfigurationControllerUpsertScopeSystemEntityResponse>(
            ConfigurationController.upsertAuditEventAutomationRuleUrl(),
            request);
    }


    public static upsertRiskAutomationRuleUrl =
        () => "/api/console/configuration/upsertRiskAutomationRule";

    public static upsertRiskAutomationRule(request: Contract.ConfigurationControllerUpsertRiskAutomationRuleRequest): Promise<Contract.ConfigurationControllerUpsertScopeSystemEntityResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpsertRiskAutomationRuleRequest, Contract.ConfigurationControllerUpsertScopeSystemEntityResponse>(
            ConfigurationController.upsertRiskAutomationRuleUrl(),
            request);
    }


    public static getAwsRegionsUrl =
        () => "/api/console/configuration/getAwsRegions";

    public static getAwsRegions(): Promise<Contract.ConfigurationControllerGetAwsRegionsResponse> {
        return ApiController.postRequest<any, Contract.ConfigurationControllerGetAwsRegionsResponse>(
            ConfigurationController.getAwsRegionsUrl(),
            {});
    }


    public static deleteCiCloudsmithIntegrationUrl =
        () => "/api/console/configuration/deleteCiCloudsmithIntegration";

    public static deleteCiCloudsmithIntegration(request: Contract.ConfigurationControllerDeleteCiCloudsmithIntegrationRequest): Promise<Contract.ConfigurationControllerDeleteCiCloudsmithIntegrationResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteCiCloudsmithIntegrationRequest, Contract.ConfigurationControllerDeleteCiCloudsmithIntegrationResponse>(
            ConfigurationController.deleteCiCloudsmithIntegrationUrl(),
            request);
    }


    public static insertCiCloudsmithIntegrationUrl =
        () => "/api/console/configuration/insertCiCloudsmithIntegration";

    public static insertCiCloudsmithIntegration(request: Contract.ConfigurationControllerInsertCiCloudsmithIntegrationRequest): Promise<Contract.ConfigurationControllerInsertCiCloudsmithIntegrationResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerInsertCiCloudsmithIntegrationRequest, Contract.ConfigurationControllerInsertCiCloudsmithIntegrationResponse>(
            ConfigurationController.insertCiCloudsmithIntegrationUrl(),
            request);
    }


    public static updateCiCloudsmithIntegrationUrl =
        () => "/api/console/configuration/updateCiCloudsmithIntegration";

    public static updateCiCloudsmithIntegration(request: Contract.ConfigurationControllerUpdateCiCloudsmithIntegrationRequest): Promise<Contract.ConfigurationControllerUpdateCiCloudsmithIntegrationResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpdateCiCloudsmithIntegrationRequest, Contract.ConfigurationControllerUpdateCiCloudsmithIntegrationResponse>(
            ConfigurationController.updateCiCloudsmithIntegrationUrl(),
            request);
    }


    public static deleteCiGeneralIntegrationUrl =
        () => "/api/console/configuration/deleteCiGeneralIntegration";

    public static deleteCiGeneralIntegration(request: Contract.ConfigurationControllerDeleteCiGeneralIntegrationRequest): Promise<Contract.ConfigurationControllerDeleteCiGeneralIntegrationResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteCiGeneralIntegrationRequest, Contract.ConfigurationControllerDeleteCiGeneralIntegrationResponse>(
            ConfigurationController.deleteCiGeneralIntegrationUrl(),
            request);
    }


    public static insertCiGeneralIntegrationUrl =
        () => "/api/console/configuration/insertCiGeneralIntegration";

    public static insertCiGeneralIntegration(request: Contract.ConfigurationControllerInsertCiGeneralIntegrationRequest): Promise<Contract.ConfigurationControllerInsertCiGeneralIntegrationResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerInsertCiGeneralIntegrationRequest, Contract.ConfigurationControllerInsertCiGeneralIntegrationResponse>(
            ConfigurationController.insertCiGeneralIntegrationUrl(),
            request);
    }


    public static updateCiGeneralIntegrationUrl =
        () => "/api/console/configuration/updateCiGeneralIntegration";

    public static updateCiGeneralIntegration(request: Contract.ConfigurationControllerUpdateCiGeneralIntegrationRequest): Promise<Contract.ConfigurationControllerUpdateCiGeneralIntegrationResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpdateCiGeneralIntegrationRequest, Contract.ConfigurationControllerUpdateCiGeneralIntegrationResponse>(
            ConfigurationController.updateCiGeneralIntegrationUrl(),
            request);
    }


    public static deleteCiJfrogIntegrationUrl =
        () => "/api/console/configuration/deleteCiJfrogIntegration";

    public static deleteCiJfrogIntegration(request: Contract.ConfigurationControllerDeleteCiJfrogIntegrationRequest): Promise<Contract.ConfigurationControllerDeleteCiJfrogIntegrationResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteCiJfrogIntegrationRequest, Contract.ConfigurationControllerDeleteCiJfrogIntegrationResponse>(
            ConfigurationController.deleteCiJfrogIntegrationUrl(),
            request);
    }


    public static insertCiJfrogIntegrationUrl =
        () => "/api/console/configuration/insertCiJfrogIntegration";

    public static insertCiJfrogIntegration(request: Contract.ConfigurationControllerInsertCiJfrogIntegrationRequest): Promise<Contract.ConfigurationControllerInsertCiJfrogIntegrationResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerInsertCiJfrogIntegrationRequest, Contract.ConfigurationControllerInsertCiJfrogIntegrationResponse>(
            ConfigurationController.insertCiJfrogIntegrationUrl(),
            request);
    }


    public static updateCiJfrogIntegrationUrl =
        () => "/api/console/configuration/updateCiJfrogIntegration";

    public static updateCiJfrogIntegration(request: Contract.ConfigurationControllerUpdateCiJfrogIntegrationRequest): Promise<Contract.ConfigurationControllerUpdateCiJfrogIntegrationResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpdateCiJfrogIntegrationRequest, Contract.ConfigurationControllerUpdateCiJfrogIntegrationResponse>(
            ConfigurationController.updateCiJfrogIntegrationUrl(),
            request);
    }


    public static updateCodeUrl =
        () => "/api/console/configuration/updateCode";

    public static updateCode(request: Contract.ConfigurationControllerUpdateCodeRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpdateCodeRequest, void>(
            ConfigurationController.updateCodeUrl(),
            request);
    }


    public static deleteCodePipelineUrl =
        () => "/api/console/configuration/deleteCodePipeline";

    public static deleteCodePipeline(request: Contract.ConfigurationControllerDeleteCodePipelineRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteCodePipelineRequest, void>(
            ConfigurationController.deleteCodePipelineUrl(),
            request);
    }


    public static getCodePipelineApiRootUrlUrl =
        () => "/api/console/configuration/getCodePipelineApiRootUrl";

    public static getCodePipelineApiRootUrl(): Promise<Contract.ConfigurationControllerGetCodePipelineApiRootUrlResponse> {
        return ApiController.postRequest<any, Contract.ConfigurationControllerGetCodePipelineApiRootUrlResponse>(
            ConfigurationController.getCodePipelineApiRootUrlUrl(),
            {});
    }


    public static getTerraformCloudScanApiUrlUrl =
        () => "/api/console/configuration/getTerraformCloudScanApiUrl";

    public static getTerraformCloudScanApiUrl(): Promise<Contract.ConfigurationControllerGetTerraformCloudScanApiUrlResponse> {
        return ApiController.postRequest<any, Contract.ConfigurationControllerGetTerraformCloudScanApiUrlResponse>(
            ConfigurationController.getTerraformCloudScanApiUrlUrl(),
            {});
    }


    public static insertCodePipelineUrl =
        () => "/api/console/configuration/insertCodePipeline";

    public static insertCodePipeline(request: Contract.ConfigurationControllerInsertCodePipelineRequest): Promise<Contract.ConfigurationControllerInsertCodePipelineResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerInsertCodePipelineRequest, Contract.ConfigurationControllerInsertCodePipelineResponse>(
            ConfigurationController.insertCodePipelineUrl(),
            request);
    }


    public static updateCodePipelineUrl =
        () => "/api/console/configuration/updateCodePipeline";

    public static updateCodePipeline(request: Contract.ConfigurationControllerUpdateCodePipelineRequest): Promise<Contract.ConfigurationControllerUpdateCodePipelineResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpdateCodePipelineRequest, Contract.ConfigurationControllerUpdateCodePipelineResponse>(
            ConfigurationController.updateCodePipelineUrl(),
            request);
    }


    public static deleteGitHubServerUrl =
        () => "/api/console/configuration/deleteGitHubServer";

    public static deleteGitHubServer(request: Contract.ConfigurationControllerDeleteGitHubServerRequest): Promise<Contract.ConfigurationControllerDeleteGitHubServerResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteGitHubServerRequest, Contract.ConfigurationControllerDeleteGitHubServerResponse>(
            ConfigurationController.deleteGitHubServerUrl(),
            request);
    }


    public static getGitHubServerOnboardingApplicationInfoUrl =
        () => "/api/console/configuration/getGitHubServerOnboardingApplicationInfo";

    public static getGitHubServerOnboardingApplicationInfo(): Promise<Contract.ConfigurationControllerGetGitHubServerOnboardingApplicationInfoResponse> {
        return ApiController.postRequest<any, Contract.ConfigurationControllerGetGitHubServerOnboardingApplicationInfoResponse>(
            ConfigurationController.getGitHubServerOnboardingApplicationInfoUrl(),
            {});
    }


    public static insertGitHubServerUrl =
        () => "/api/console/configuration/insertGitHubServer";

    public static insertGitHubServer(request: Contract.ConfigurationControllerInsertGitHubServerRequest): Promise<Contract.ConfigurationControllerInsertGitHubServerResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerInsertGitHubServerRequest, Contract.ConfigurationControllerInsertGitHubServerResponse>(
            ConfigurationController.insertGitHubServerUrl(),
            request);
    }


    public static updateGitHubServerUrl =
        () => "/api/console/configuration/updateGitHubServer";

    public static updateGitHubServer(request: Contract.ConfigurationControllerUpdateGitHubServerRequest): Promise<Contract.ConfigurationControllerUpdateGitHubServerResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpdateGitHubServerRequest, Contract.ConfigurationControllerUpdateGitHubServerResponse>(
            ConfigurationController.updateGitHubServerUrl(),
            request);
    }


    public static validateGitHubServerOnboardingServerInfoUrl =
        () => "/api/console/configuration/validateGitHubServerOnboardingServerInfo";

    public static validateGitHubServerOnboardingServerInfo(request: Contract.ConfigurationControllerValidateGitHubServerOnboardingServerInfoRequest): Promise<Contract.ConfigurationControllerValidateGitHubServerOnboardingServerInfoResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerValidateGitHubServerOnboardingServerInfoRequest, Contract.ConfigurationControllerValidateGitHubServerOnboardingServerInfoResponse>(
            ConfigurationController.validateGitHubServerOnboardingServerInfoUrl(),
            request);
    }


    public static deleteGitLabServerUrl =
        () => "/api/console/configuration/deleteGitLabServer";

    public static deleteGitLabServer(request: Contract.ConfigurationControllerDeleteGitLabServerRequest): Promise<Contract.ConfigurationControllerDeleteGitLabServerResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteGitLabServerRequest, Contract.ConfigurationControllerDeleteGitLabServerResponse>(
            ConfigurationController.deleteGitLabServerUrl(),
            request);
    }


    public static insertGitLabServerUrl =
        () => "/api/console/configuration/insertGitLabServer";

    public static insertGitLabServer(request: Contract.ConfigurationControllerInsertGitLabServerRequest): Promise<Contract.ConfigurationControllerInsertGitLabServerResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerInsertGitLabServerRequest, Contract.ConfigurationControllerInsertGitLabServerResponse>(
            ConfigurationController.insertGitLabServerUrl(),
            request);
    }


    public static updateGitLabServerUrl =
        () => "/api/console/configuration/updateGitLabServer";

    public static updateGitLabServer(request: Contract.ConfigurationControllerUpdateGitLabServerRequest): Promise<Contract.ConfigurationControllerUpdateGitLabServerResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpdateGitLabServerRequest, Contract.ConfigurationControllerUpdateGitLabServerResponse>(
            ConfigurationController.updateGitLabServerUrl(),
            request);
    }


    public static deleteCustomComplianceUrl =
        () => "/api/console/configuration/deleteCustomCompliance";

    public static deleteCustomCompliance(request: Contract.ConfigurationControllerDeleteCustomComplianceRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteCustomComplianceRequest, void>(
            ConfigurationController.deleteCustomComplianceUrl(),
            request);
    }


    public static getScopePathCustomComplianceIdsUrl =
        () => "/api/console/configuration/getScopePathCustomComplianceIds";

    public static getScopePathCustomComplianceIds(request: Contract.ConfigurationControllerGetScopePathCustomComplianceIdsRequest): Promise<Contract.ConfigurationControllerGetScopePathCustomComplianceIdsResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerGetScopePathCustomComplianceIdsRequest, Contract.ConfigurationControllerGetScopePathCustomComplianceIdsResponse>(
            ConfigurationController.getScopePathCustomComplianceIdsUrl(),
            request);
    }


    public static upsertCustomComplianceUrl =
        () => "/api/console/configuration/upsertCustomCompliance";

    public static upsertCustomCompliance(request: Contract.ConfigurationControllerUpsertCustomComplianceRequest): Promise<Contract.ConfigurationControllerUpsertScopeSystemEntityResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpsertCustomComplianceRequest, Contract.ConfigurationControllerUpsertScopeSystemEntityResponse>(
            ConfigurationController.upsertCustomComplianceUrl(),
            request);
    }


    public static deleteManualCustomEntityAttributeDefinitionUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/configuration/deleteManualCustomEntityAttributeDefinition/{typeName}", { typeName });

    public static deleteManualCustomEntityAttributeDefinition(request: Contract.ConfigurationControllerDeleteManualCustomEntityAttributeDefinitionRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteManualCustomEntityAttributeDefinitionRequest, void>(
            ConfigurationController.deleteManualCustomEntityAttributeDefinitionUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getCustomEntityAttributeDefinitionModelsUrl =
        () => "/api/console/configuration/getCustomEntityAttributeDefinitionModels";

    public static getCustomEntityAttributeDefinitionModels(): Promise<Contract.ConfigurationControllerGetCustomEntityAttributeDefinitionModelsResponse> {
        return ApiController.postRequest<any, Contract.ConfigurationControllerGetCustomEntityAttributeDefinitionModelsResponse>(
            ConfigurationController.getCustomEntityAttributeDefinitionModelsUrl(),
            {});
    }


    public static getCustomEntityAttributeDefinitionUsageUrl =
        () => "/api/console/configuration/getCustomEntityAttributeDefinitionUsage";

    public static getCustomEntityAttributeDefinitionUsage(request: Contract.ConfigurationControllerGetCustomEntityAttributeDefinitionUsageRequest): Promise<Contract.ConfigurationControllerGetCustomEntityAttributeDefinitionUsageResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerGetCustomEntityAttributeDefinitionUsageRequest, Contract.ConfigurationControllerGetCustomEntityAttributeDefinitionUsageResponse>(
            ConfigurationController.getCustomEntityAttributeDefinitionUsageUrl(),
            request);
    }


    public static upsertManualCustomEntityAttributeDefinitionUrl =
        () => "/api/console/configuration/upsertManualCustomEntityAttributeDefinition";

    public static upsertManualCustomEntityAttributeDefinition(request: Contract.ConfigurationControllerUpsertManualCustomEntityAttributeDefinitionRequest): Promise<Contract.ConfigurationControllerUpsertManualCustomEntityAttributeDefinitionResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpsertManualCustomEntityAttributeDefinitionRequest, Contract.ConfigurationControllerUpsertManualCustomEntityAttributeDefinitionResponse>(
            ConfigurationController.upsertManualCustomEntityAttributeDefinitionUrl(),
            request);
    }


    public static getAuthenticationConfigurationUrl =
        () => "/api/console/configuration/getAuthenticationConfiguration";

    public static getAuthenticationConfiguration(): Promise<Contract.ConfigurationControllerGetAuthenticationConfigurationResponse> {
        return ApiController.postRequest<any, Contract.ConfigurationControllerGetAuthenticationConfigurationResponse>(
            ConfigurationController.getAuthenticationConfigurationUrl(),
            {});
    }


    public static updateAuthenticationConfigurationUrl =
        () => "/api/console/configuration/updateAuthenticationConfiguration";

    public static updateAuthenticationConfiguration(request: Contract.ConfigurationControllerUpdateAuthenticationConfigurationRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpdateAuthenticationConfigurationRequest, void>(
            ConfigurationController.updateAuthenticationConfigurationUrl(),
            request);
    }


    public static validateAuthenticationSamlIdentityProviderUrl =
        () => "/api/console/configuration/validateAuthenticationSamlIdentityProvider";

    public static validateAuthenticationSamlIdentityProvider(request: Contract.ConfigurationControllerValidateAuthenticationSamlIdentityProviderRequest): Promise<Contract.ConfigurationControllerValidateAuthenticationSamlIdentityProviderResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerValidateAuthenticationSamlIdentityProviderRequest, Contract.ConfigurationControllerValidateAuthenticationSamlIdentityProviderResponse>(
            ConfigurationController.validateAuthenticationSamlIdentityProviderUrl(),
            request);
    }


    public static updateDataAnalysisUrl =
        () => "/api/console/configuration/updateDataAnalysis";

    public static updateDataAnalysis(request: Contract.ConfigurationControllerUpdateDataAnalysisRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpdateDataAnalysisRequest, void>(
            ConfigurationController.updateDataAnalysisUrl(),
            request);
    }


    public static deleteDatadogOrganizationUrl =
        () => "/api/console/configuration/deleteDatadogOrganization";

    public static deleteDatadogOrganization(request: Contract.ConfigurationControllerDeleteDatadogOrganizationRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteDatadogOrganizationRequest, void>(
            ConfigurationController.deleteDatadogOrganizationUrl(),
            request);
    }


    public static insertDatadogOrganizationUrl =
        () => "/api/console/configuration/insertDatadogOrganization";

    public static insertDatadogOrganization(request: Contract.ConfigurationControllerInsertDatadogOrganizationRequest): Promise<Contract.ConfigurationControllerUpsertScopeSystemEntityResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerInsertDatadogOrganizationRequest, Contract.ConfigurationControllerUpsertScopeSystemEntityResponse>(
            ConfigurationController.insertDatadogOrganizationUrl(),
            request);
    }


    public static updateDatadogOrganizationUrl =
        () => "/api/console/configuration/updateDatadogOrganization";

    public static updateDatadogOrganization(request: Contract.ConfigurationControllerUpdateDatadogOrganizationRequest): Promise<Contract.ConfigurationControllerUpsertScopeSystemEntityResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpdateDatadogOrganizationRequest, Contract.ConfigurationControllerUpsertScopeSystemEntityResponse>(
            ConfigurationController.updateDatadogOrganizationUrl(),
            request);
    }


    public static deleteEndpointConnectorUrl =
        () => "/api/console/configuration/deleteEndpointConnector";

    public static deleteEndpointConnector(request: Contract.ConfigurationControllerDeleteEndpointConnectorRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteEndpointConnectorRequest, void>(
            ConfigurationController.deleteEndpointConnectorUrl(),
            request);
    }


    public static getEndpointConnectorApiKeyTokenUrl =
        () => "/api/console/configuration/getEndpointConnectorApiKeyToken";

    public static getEndpointConnectorApiKeyToken(request: Contract.ConfigurationControllerGetEndpointConnectorApiKeyTokenRequest): Promise<Contract.ConfigurationControllerGetEndpointConnectorApiKeyTokenResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerGetEndpointConnectorApiKeyTokenRequest, Contract.ConfigurationControllerGetEndpointConnectorApiKeyTokenResponse>(
            ConfigurationController.getEndpointConnectorApiKeyTokenUrl(),
            request);
    }


    public static getEndpointConnectorIdToIntegrationNameMapUrl =
        () => "/api/console/configuration/getEndpointConnectorIdToIntegrationNameMap";

    public static getEndpointConnectorIdToIntegrationNameMap(): Promise<Contract.ConfigurationControllerGetEndpointConnectorIdToIntegrationNameMapResponse> {
        return ApiController.postRequest<any, Contract.ConfigurationControllerGetEndpointConnectorIdToIntegrationNameMapResponse>(
            ConfigurationController.getEndpointConnectorIdToIntegrationNameMapUrl(),
            {});
    }


    public static getEndpointConnectorTunnelSessionClientIdsUrl =
        () => "/api/console/configuration/getEndpointConnectorTunnelSessionClientIds";

    public static getEndpointConnectorTunnelSessionClientIds(): Promise<Contract.ConfigurationControllerGetEndpointConnectorTunnelSessionClientIdsResponse> {
        return ApiController.postRequest<any, Contract.ConfigurationControllerGetEndpointConnectorTunnelSessionClientIdsResponse>(
            ConfigurationController.getEndpointConnectorTunnelSessionClientIdsUrl(),
            {});
    }


    public static insertEndpointConnectorUrl =
        () => "/api/console/configuration/insertEndpointConnector";

    public static insertEndpointConnector(request: Contract.ConfigurationControllerInsertEndpointConnectorRequest): Promise<Contract.ConfigurationControllerUpsertScopeSystemEntityResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerInsertEndpointConnectorRequest, Contract.ConfigurationControllerUpsertScopeSystemEntityResponse>(
            ConfigurationController.insertEndpointConnectorUrl(),
            request);
    }


    public static deleteEntityPropertyDefinitionUrl =
        () => "/api/console/configuration/deleteEntityPropertyDefinition";

    public static deleteEntityPropertyDefinition(request: Contract.ConfigurationControllerDeleteEntityPropertyDefinitionRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteEntityPropertyDefinitionRequest, void>(
            ConfigurationController.deleteEntityPropertyDefinitionUrl(),
            request);
    }


    public static getEntityPropertyIdentifierUsageUrl =
        () => "/api/console/configuration/getEntityPropertyIdentifierUsage";

    public static getEntityPropertyIdentifierUsage(request: Contract.ConfigurationControllerGetEntityPropertyIdentifierUsageRequest): Promise<Contract.ConfigurationControllerGetEntityPropertyIdentifierUsageResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerGetEntityPropertyIdentifierUsageRequest, Contract.ConfigurationControllerGetEntityPropertyIdentifierUsageResponse>(
            ConfigurationController.getEntityPropertyIdentifierUsageUrl(),
            request);
    }


    public static upsertEntityPropertyDefinitionUrl =
        () => "/api/console/configuration/upsertEntityPropertyDefinition";

    public static upsertEntityPropertyDefinition(request: Contract.ConfigurationControllerUpsertEntityPropertyDefinitionRequest): Promise<Contract.ConfigurationControllerUpsertScopeSystemEntityResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpsertEntityPropertyDefinitionRequest, Contract.ConfigurationControllerUpsertScopeSystemEntityResponse>(
            ConfigurationController.upsertEntityPropertyDefinitionUrl(),
            request);
    }


    public static getFeaturesUrl =
        () => "/api/console/configuration/getFeatures";

    public static getFeatures(): Promise<Contract.ConfigurationControllerGetFeaturesResponse> {
        return ApiController.postRequest<any, Contract.ConfigurationControllerGetFeaturesResponse>(
            ConfigurationController.getFeaturesUrl(),
            {});
    }


    public static deleteJiraInstanceUrl =
        () => "/api/console/configuration/deleteJiraInstance";

    public static deleteJiraInstance(request: Contract.ConfigurationControllerDeleteJiraInstanceRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteJiraInstanceRequest, void>(
            ConfigurationController.deleteJiraInstanceUrl(),
            request);
    }


    public static deleteJiraProjectUrl =
        () => "/api/console/configuration/deleteJiraProject";

    public static deleteJiraProject(request: Contract.ConfigurationControllerDeleteJiraProjectRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteJiraProjectRequest, void>(
            ConfigurationController.deleteJiraProjectUrl(),
            request);
    }


    public static getJiraRawProjectDataUrl =
        () => "/api/console/configuration/getJiraRawProjectData";

    public static getJiraRawProjectData(request: Contract.ConfigurationControllerGetJiraRawProjectDataRequest): Promise<Contract.ConfigurationControllerGetJiraRawProjectDataResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerGetJiraRawProjectDataRequest, Contract.ConfigurationControllerGetJiraRawProjectDataResponse>(
            ConfigurationController.getJiraRawProjectDataUrl(),
            request);
    }


    public static upsertJiraInstanceUrl =
        () => "/api/console/configuration/upsertJiraInstance";

    public static upsertJiraInstance(request: Contract.ConfigurationControllerUpsertJiraInstanceRequest): Promise<Contract.ConfigurationControllerUpsertScopeSystemEntityResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpsertJiraInstanceRequest, Contract.ConfigurationControllerUpsertScopeSystemEntityResponse>(
            ConfigurationController.upsertJiraInstanceUrl(),
            request);
    }


    public static upsertJiraProjectUrl =
        () => "/api/console/configuration/upsertJiraProject";

    public static upsertJiraProject(request: Contract.ConfigurationControllerUpsertJiraProjectRequest): Promise<Contract.ConfigurationControllerUpsertScopeSystemEntityResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpsertJiraProjectRequest, Contract.ConfigurationControllerUpsertScopeSystemEntityResponse>(
            ConfigurationController.upsertJiraProjectUrl(),
            request);
    }


    public static deleteKubernetesClusterConnectorUrl =
        () => "/api/console/configuration/deleteKubernetesClusterConnector";

    public static deleteKubernetesClusterConnector(request: Contract.ConfigurationControllerDeleteKubernetesClusterConnectorRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteKubernetesClusterConnectorRequest, void>(
            ConfigurationController.deleteKubernetesClusterConnectorUrl(),
            request);
    }


    public static getKubernetesClusterConnectorIdToClusterIdsMapUrl =
        () => "/api/console/configuration/getKubernetesClusterConnectorIdToClusterIdsMap";

    public static getKubernetesClusterConnectorIdToClusterIdsMap(): Promise<Contract.ConfigurationControllerGetKubernetesClusterConnectorIdToClusterIdsMapResponse> {
        return ApiController.postRequest<any, Contract.ConfigurationControllerGetKubernetesClusterConnectorIdToClusterIdsMapResponse>(
            ConfigurationController.getKubernetesClusterConnectorIdToClusterIdsMapUrl(),
            {});
    }


    public static upsertKubernetesClusterConnectorUrl =
        () => "/api/console/configuration/upsertKubernetesClusterConnector";

    public static upsertKubernetesClusterConnector(request: Contract.ConfigurationControllerUpsertKubernetesClusterConnectorRequest): Promise<Contract.ConfigurationControllerUpsertScopeSystemEntityResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpsertKubernetesClusterConnectorRequest, Contract.ConfigurationControllerUpsertScopeSystemEntityResponse>(
            ConfigurationController.upsertKubernetesClusterConnectorUrl(),
            request);
    }


    public static getLicensingUrl =
        () => "/api/console/configuration/getLicensing";

    public static getLicensing(): Promise<Contract.ConfigurationControllerGetLicensingResponse> {
        return ApiController.postRequest<any, Contract.ConfigurationControllerGetLicensingResponse>(
            ConfigurationController.getLicensingUrl(),
            {});
    }


    public static getLicensingDataUrl =
        () => "/api/console/configuration/getLicensingData";

    public static getLicensingData(request: Contract.ConfigurationControllerGetLicensingDataRequest): Promise<Contract.ConfigurationControllerGetLicensingDataResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerGetLicensingDataRequest, Contract.ConfigurationControllerGetLicensingDataResponse>(
            ConfigurationController.getLicensingDataUrl(),
            request);
    }


    public static getLicensingExportDataUrl =
        () => "/api/console/configuration/getLicensingExportData";

    public static getLicensingExportData(request: Contract.ConfigurationControllerGetLicensingExportDataRequest): Promise<Contract.ConfigurationControllerGetLicensingExportDataResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerGetLicensingExportDataRequest, Contract.ConfigurationControllerGetLicensingExportDataResponse>(
            ConfigurationController.getLicensingExportDataUrl(),
            request);
    }


    public static getAwsOrganizationOnboardingInfoUrl =
        () => "/api/console/configuration/getAwsOrganizationOnboardingInfo";

    public static getAwsOrganizationOnboardingInfo(request: Contract.ConfigurationControllerGetAwsOrganizationOnboardingInfoRequest): Promise<Contract.ConfigurationControllerGetAwsOrganizationOnboardingInfoResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerGetAwsOrganizationOnboardingInfoRequest, Contract.ConfigurationControllerGetAwsOrganizationOnboardingInfoResponse>(
            ConfigurationController.getAwsOrganizationOnboardingInfoUrl(),
            request);
    }


    public static getAwsOrganizationOnboardingInfoRootUrl =
        () => "/api/console/configuration/getAwsOrganizationOnboardingInfoRoot";

    public static getAwsOrganizationOnboardingInfoRoot(request: Contract.ConfigurationControllerGetAwsOrganizationOnboardingInfoRootRequest): Promise<Contract.ConfigurationControllerGetAwsOrganizationOnboardingInfoRootResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerGetAwsOrganizationOnboardingInfoRootRequest, Contract.ConfigurationControllerGetAwsOrganizationOnboardingInfoRootResponse>(
            ConfigurationController.getAwsOrganizationOnboardingInfoRootUrl(),
            request);
    }


    public static upsertAwsOrganizationUrl =
        () => "/api/console/configuration/upsertAwsOrganization";

    public static upsertAwsOrganization(request: Contract.ConfigurationControllerUpsertAwsOrganizationRequest): Promise<Contract.ConfigurationControllerUpsertAwsOrganizationResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpsertAwsOrganizationRequest, Contract.ConfigurationControllerUpsertAwsOrganizationResponse>(
            ConfigurationController.upsertAwsOrganizationUrl(),
            request);
    }


    public static upsertAzureOrganizationUrl =
        () => "/api/console/configuration/upsertAzureOrganization";

    public static upsertAzureOrganization(request: Contract.ConfigurationControllerUpsertAzureOrganizationRequest): Promise<Contract.ConfigurationControllerUpsertAzureOrganizationResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpsertAzureOrganizationRequest, Contract.ConfigurationControllerUpsertAzureOrganizationResponse>(
            ConfigurationController.upsertAzureOrganizationUrl(),
            request);
    }


    public static getGcpOrganizationOnboardingInfoUrl =
        () => "/api/console/configuration/getGcpOrganizationOnboardingInfo";

    public static getGcpOrganizationOnboardingInfo(): Promise<Contract.ConfigurationControllerGetGcpOrganizationOnboardingInfoResponse> {
        return ApiController.postRequest<any, Contract.ConfigurationControllerGetGcpOrganizationOnboardingInfoResponse>(
            ConfigurationController.getGcpOrganizationOnboardingInfoUrl(),
            {});
    }


    public static upsertGcpOrganizationUrl =
        () => "/api/console/configuration/upsertGcpOrganization";

    public static upsertGcpOrganization(request: Contract.ConfigurationControllerUpsertGcpOrganizationRequest): Promise<Contract.ConfigurationControllerUpsertGcpOrganizationResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpsertGcpOrganizationRequest, Contract.ConfigurationControllerUpsertGcpOrganizationResponse>(
            ConfigurationController.upsertGcpOrganizationUrl(),
            request);
    }


    public static insertOciOrganizationUrl =
        () => "/api/console/configuration/insertOciOrganization";

    public static insertOciOrganization(request: Contract.ConfigurationControllerInsertOciOrganizationRequest): Promise<Contract.ConfigurationControllerInsertOciOrganizationResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerInsertOciOrganizationRequest, Contract.ConfigurationControllerInsertOciOrganizationResponse>(
            ConfigurationController.insertOciOrganizationUrl(),
            request);
    }


    public static updateOciOrganizationUrl =
        () => "/api/console/configuration/updateOciOrganization";

    public static updateOciOrganization(request: Contract.ConfigurationControllerUpdateOciOrganizationRequest): Promise<Contract.ConfigurationControllerUpdateOciOrganizationResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpdateOciOrganizationRequest, Contract.ConfigurationControllerUpdateOciOrganizationResponse>(
            ConfigurationController.updateOciOrganizationUrl(),
            request);
    }


    public static deleteAzureDevOpsOrganizationUrl =
        () => "/api/console/configuration/deleteAzureDevOpsOrganization";

    public static deleteAzureDevOpsOrganization(request: Contract.ConfigurationControllerDeleteAzureDevOpsOrganizationRequest): Promise<Contract.ConfigurationControllerDeleteAzureDevOpsOrganizationResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteAzureDevOpsOrganizationRequest, Contract.ConfigurationControllerDeleteAzureDevOpsOrganizationResponse>(
            ConfigurationController.deleteAzureDevOpsOrganizationUrl(),
            request);
    }


    public static getAzureDevOpsOrganizationOnboardingApplicationInfoUrl =
        () => "/api/console/configuration/getAzureDevOpsOrganizationOnboardingApplicationInfo";

    public static getAzureDevOpsOrganizationOnboardingApplicationInfo(): Promise<Contract.ConfigurationControllerGetAzureDevOpsOrganizationOnboardingApplicationInfoResponse> {
        return ApiController.postRequest<any, Contract.ConfigurationControllerGetAzureDevOpsOrganizationOnboardingApplicationInfoResponse>(
            ConfigurationController.getAzureDevOpsOrganizationOnboardingApplicationInfoUrl(),
            {});
    }


    public static getAzureDevOpsOrganizationOnboardingOrganizationsInfoUrl =
        () => "/api/console/configuration/getAzureDevOpsOrganizationOnboardingOrganizationsInfo";

    public static getAzureDevOpsOrganizationOnboardingOrganizationsInfo(request: Contract.ConfigurationControllerGetAzureDevOpsOrganizationOnboardingOrganizationsInfoRequest): Promise<Contract.ConfigurationControllerGetAzureDevOpsOrganizationOnboardingOrganizationsInfoResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerGetAzureDevOpsOrganizationOnboardingOrganizationsInfoRequest, Contract.ConfigurationControllerGetAzureDevOpsOrganizationOnboardingOrganizationsInfoResponse>(
            ConfigurationController.getAzureDevOpsOrganizationOnboardingOrganizationsInfoUrl(),
            request);
    }


    public static getAzureDevOpsOrganizationOnboardingTenantsInfoUrl =
        () => "/api/console/configuration/getAzureDevOpsOrganizationOnboardingTenantsInfo";

    public static getAzureDevOpsOrganizationOnboardingTenantsInfo(request: Contract.ConfigurationControllerGetAzureDevOpsOrganizationOnboardingTenantsInfoRequest): Promise<Contract.ConfigurationControllerGetAzureDevOpsOrganizationOnboardingTenantsInfoResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerGetAzureDevOpsOrganizationOnboardingTenantsInfoRequest, Contract.ConfigurationControllerGetAzureDevOpsOrganizationOnboardingTenantsInfoResponse>(
            ConfigurationController.getAzureDevOpsOrganizationOnboardingTenantsInfoUrl(),
            request);
    }


    public static insertAzureDevOpsOrganizationUrl =
        () => "/api/console/configuration/insertAzureDevOpsOrganization";

    public static insertAzureDevOpsOrganization(request: Contract.ConfigurationControllerInsertAzureDevOpsOrganizationRequest): Promise<Contract.ConfigurationControllerInsertAzureDevOpsOrganizationResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerInsertAzureDevOpsOrganizationRequest, Contract.ConfigurationControllerInsertAzureDevOpsOrganizationResponse>(
            ConfigurationController.insertAzureDevOpsOrganizationUrl(),
            request);
    }


    public static processAzureDevOpsOrganizationAuthenticationCallbackUrl =
        (code: string, error: Optional<string>, state: string) => ApiController.formatUrlParameters("/api/console/configuration/azureDevOps/authenticationCallback", { code, error, state });

    public static processAzureDevOpsOrganizationAuthenticationCallback(code: string, error: Optional<string>, state: string): Promise<void> {
        return ApiController.getRequest<void>(ConfigurationController.processAzureDevOpsOrganizationAuthenticationCallbackUrl(code, error, state));
    }


    public static processAzureDevOpsOrganizationConsentCallbackUrl =
        (error: Optional<string>, tenant: Optional<string>) => ApiController.formatUrlParameters("/api/console/configuration/azureDevOps/consentCallback", { error, tenant });

    public static processAzureDevOpsOrganizationConsentCallback(error: Optional<string>, tenant: Optional<string>): Promise<void> {
        return ApiController.getRequest<void>(ConfigurationController.processAzureDevOpsOrganizationConsentCallbackUrl(error, tenant));
    }


    public static updateAzureDevOpsOrganizationUrl =
        () => "/api/console/configuration/updateAzureDevOpsOrganization";

    public static updateAzureDevOpsOrganization(request: Contract.ConfigurationControllerUpdateAzureDevOpsOrganizationRequest): Promise<Contract.ConfigurationControllerUpdateAzureDevOpsOrganizationResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpdateAzureDevOpsOrganizationRequest, Contract.ConfigurationControllerUpdateAzureDevOpsOrganizationResponse>(
            ConfigurationController.updateAzureDevOpsOrganizationUrl(),
            request);
    }


    public static deleteBitbucketOrganizationUrl =
        () => "/api/console/configuration/deleteBitbucketOrganization";

    public static deleteBitbucketOrganization(request: Contract.ConfigurationControllerDeleteBitbucketOrganizationRequest): Promise<Contract.ConfigurationControllerDeleteBitbucketOrganizationResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteBitbucketOrganizationRequest, Contract.ConfigurationControllerDeleteBitbucketOrganizationResponse>(
            ConfigurationController.deleteBitbucketOrganizationUrl(),
            request);
    }


    public static getBitbucketOrganizationOnboardingTenantsInfoUrl =
        () => "/api/console/configuration/getBitbucketOrganizationOnboardingTenantsInfo";

    public static getBitbucketOrganizationOnboardingTenantsInfo(request: Contract.ConfigurationControllerGetBitbucketOrganizationOnboardingTenantsInfoRequest): Promise<Contract.ConfigurationControllerGetBitbucketOrganizationOnboardingTenantsInfoResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerGetBitbucketOrganizationOnboardingTenantsInfoRequest, Contract.ConfigurationControllerGetBitbucketOrganizationOnboardingTenantsInfoResponse>(
            ConfigurationController.getBitbucketOrganizationOnboardingTenantsInfoUrl(),
            request);
    }


    public static insertBitbucketOrganizationUrl =
        () => "/api/console/configuration/insertBitbucketOrganization";

    public static insertBitbucketOrganization(request: Contract.ConfigurationControllerInsertBitbucketOrganizationRequest): Promise<Contract.ConfigurationControllerInsertBitbucketOrganizationResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerInsertBitbucketOrganizationRequest, Contract.ConfigurationControllerInsertBitbucketOrganizationResponse>(
            ConfigurationController.insertBitbucketOrganizationUrl(),
            request);
    }


    public static updateBitbucketOrganizationUrl =
        () => "/api/console/configuration/updateBitbucketOrganization";

    public static updateBitbucketOrganization(request: Contract.ConfigurationControllerUpdateBitbucketOrganizationRequest): Promise<Contract.ConfigurationControllerUpdateBitbucketOrganizationResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpdateBitbucketOrganizationRequest, Contract.ConfigurationControllerUpdateBitbucketOrganizationResponse>(
            ConfigurationController.updateBitbucketOrganizationUrl(),
            request);
    }


    public static deleteGitHubOrganizationUrl =
        () => "/api/console/configuration/deleteGitHubOrganization";

    public static deleteGitHubOrganization(request: Contract.ConfigurationControllerDeleteGitHubOrganizationRequest): Promise<Contract.ConfigurationControllerDeleteGitHubOrganizationResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteGitHubOrganizationRequest, Contract.ConfigurationControllerDeleteGitHubOrganizationResponse>(
            ConfigurationController.deleteGitHubOrganizationUrl(),
            request);
    }


    public static getGitHubOrganizationOnboardingApplicationInfoUrl =
        () => "/api/console/configuration/getGitHubOrganizationOnboardingApplicationInfo";

    public static getGitHubOrganizationOnboardingApplicationInfo(): Promise<Contract.ConfigurationControllerGetGitHubOrganizationOnboardingApplicationInfoResponse> {
        return ApiController.postRequest<any, Contract.ConfigurationControllerGetGitHubOrganizationOnboardingApplicationInfoResponse>(
            ConfigurationController.getGitHubOrganizationOnboardingApplicationInfoUrl(),
            {});
    }


    public static getGitHubOrganizationOnboardingOrganizationsInfoUrl =
        () => "/api/console/configuration/getGitHubOrganizationOnboardingOrganizationsInfo";

    public static getGitHubOrganizationOnboardingOrganizationsInfo(request: Contract.ConfigurationControllerGetGitHubOrganizationOnboardingOrganizationsInfoRequest): Promise<Contract.ConfigurationControllerGetGitHubOrganizationOnboardingOrganizationsInfoResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerGetGitHubOrganizationOnboardingOrganizationsInfoRequest, Contract.ConfigurationControllerGetGitHubOrganizationOnboardingOrganizationsInfoResponse>(
            ConfigurationController.getGitHubOrganizationOnboardingOrganizationsInfoUrl(),
            request);
    }


    public static getGitHubOrganizationOnboardingTenantsInfoUrl =
        () => "/api/console/configuration/getGitHubOrganizationOnboardingTenantsInfo";

    public static getGitHubOrganizationOnboardingTenantsInfo(request: Contract.ConfigurationControllerGetGitHubOrganizationOnboardingTenantsInfoRequest): Promise<Contract.ConfigurationControllerGetGitHubOrganizationOnboardingTenantsInfoResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerGetGitHubOrganizationOnboardingTenantsInfoRequest, Contract.ConfigurationControllerGetGitHubOrganizationOnboardingTenantsInfoResponse>(
            ConfigurationController.getGitHubOrganizationOnboardingTenantsInfoUrl(),
            request);
    }


    public static insertGitHubOrganizationUrl =
        () => "/api/console/configuration/insertGitHubOrganization";

    public static insertGitHubOrganization(request: Contract.ConfigurationControllerInsertGitHubOrganizationRequest): Promise<Contract.ConfigurationControllerInsertGitHubOrganizationResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerInsertGitHubOrganizationRequest, Contract.ConfigurationControllerInsertGitHubOrganizationResponse>(
            ConfigurationController.insertGitHubOrganizationUrl(),
            request);
    }


    public static processGitHubOrganizationCallbackUrl =
        (code: Optional<string>, serverId: Optional<string>) => ApiController.formatUrlParameters("/api/console/configuration/processGitHubOrganizationCallback", { code, serverId });

    public static processGitHubOrganizationCallback(code: Optional<string>, serverId: Optional<string>): Promise<void> {
        return ApiController.getRequest<void>(ConfigurationController.processGitHubOrganizationCallbackUrl(code, serverId));
    }


    public static updateGitHubOrganizationUrl =
        () => "/api/console/configuration/updateGitHubOrganization";

    public static updateGitHubOrganization(request: Contract.ConfigurationControllerUpdateGitHubOrganizationRequest): Promise<Contract.ConfigurationControllerUpdateGitHubOrganizationResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpdateGitHubOrganizationRequest, Contract.ConfigurationControllerUpdateGitHubOrganizationResponse>(
            ConfigurationController.updateGitHubOrganizationUrl(),
            request);
    }


    public static deleteGitLabOrganizationUrl =
        () => "/api/console/configuration/deleteGitLabOrganization";

    public static deleteGitLabOrganization(request: Contract.ConfigurationControllerDeleteGitLabOrganizationRequest): Promise<Contract.ConfigurationControllerDeleteGitLabOrganizationResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteGitLabOrganizationRequest, Contract.ConfigurationControllerDeleteGitLabOrganizationResponse>(
            ConfigurationController.deleteGitLabOrganizationUrl(),
            request);
    }


    public static getGitLabOrganizationOnboardingTenantsInfoUrl =
        () => "/api/console/configuration/getGitLabOrganizationOnboardingTenantsInfo";

    public static getGitLabOrganizationOnboardingTenantsInfo(request: Contract.ConfigurationControllerGetGitLabOrganizationOnboardingTenantsInfoRequest): Promise<Contract.ConfigurationControllerGetGitLabOrganizationOnboardingTenantsInfoResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerGetGitLabOrganizationOnboardingTenantsInfoRequest, Contract.ConfigurationControllerGetGitLabOrganizationOnboardingTenantsInfoResponse>(
            ConfigurationController.getGitLabOrganizationOnboardingTenantsInfoUrl(),
            request);
    }


    public static insertGitLabOrganizationUrl =
        () => "/api/console/configuration/insertGitLabOrganization";

    public static insertGitLabOrganization(request: Contract.ConfigurationControllerInsertGitLabOrganizationRequest): Promise<Contract.ConfigurationControllerInsertGitLabOrganizationResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerInsertGitLabOrganizationRequest, Contract.ConfigurationControllerInsertGitLabOrganizationResponse>(
            ConfigurationController.insertGitLabOrganizationUrl(),
            request);
    }


    public static updateGitLabOrganizationUrl =
        () => "/api/console/configuration/updateGitLabOrganization";

    public static updateGitLabOrganization(request: Contract.ConfigurationControllerUpdateGitLabOrganizationRequest): Promise<Contract.ConfigurationControllerUpdateGitLabOrganizationResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpdateGitLabOrganizationRequest, Contract.ConfigurationControllerUpdateGitLabOrganizationResponse>(
            ConfigurationController.updateGitLabOrganizationUrl(),
            request);
    }


    public static getOrganizationMemberDatasUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/configuration/getOrganizationMemberDatas/{typeName}", { typeName });

    public static getOrganizationMemberDatas(request: Contract.ConfigurationControllerGetOrganizationMemberDatasRequest): Promise<Contract.ConfigurationControllerGetOrganizationMemberDatasResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerGetOrganizationMemberDatasRequest, Contract.ConfigurationControllerGetOrganizationMemberDatasResponse>(
            ConfigurationController.getOrganizationMemberDatasUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static processPermissionManagementAadTenantCallbackUrl =
        (error: Optional<string>, tenant: Optional<string>) => ApiController.formatUrlParameters("/api/console/configuration/permissionManagement/groupMembership/aad/callback", { error, tenant });

    public static processPermissionManagementAadTenantCallback(error: Optional<string>, tenant: Optional<string>): Promise<void> {
        return ApiController.getRequest<void>(ConfigurationController.processPermissionManagementAadTenantCallbackUrl(error, tenant));
    }


    public static updatePermissionManagementSlackEnabledUrl =
        () => "/api/console/configuration/updatePermissionManagementSlackEnabled";

    public static updatePermissionManagementSlackEnabled(request: Contract.ConfigurationControllerUpdatePermissionManagementSlackEnabledRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpdatePermissionManagementSlackEnabledRequest, void>(
            ConfigurationController.updatePermissionManagementSlackEnabledUrl(),
            request);
    }


    public static updatePermissionManagementTeamsEnabledUrl =
        () => "/api/console/configuration/updatePermissionManagementTeamsEnabled";

    public static updatePermissionManagementTeamsEnabled(request: Contract.ConfigurationControllerUpdatePermissionManagementTeamsEnabledRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpdatePermissionManagementTeamsEnabledRequest, void>(
            ConfigurationController.updatePermissionManagementTeamsEnabledUrl(),
            request);
    }


    public static deletePermissionEligibilityUrl =
        () => "/api/console/configuration/deletePermissionEligibility";

    public static deletePermissionEligibility(request: Contract.ConfigurationControllerDeletePermissionEligibilityRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeletePermissionEligibilityRequest, void>(
            ConfigurationController.deletePermissionEligibilityUrl(),
            request);
    }


    public static insertPermissionEligibilityUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/configuration/insertPermissionEligibility/{typeName}", { typeName });

    public static insertPermissionEligibility(request: Contract.ConfigurationControllerInsertPermissionEligibilityRequest): Promise<Contract.ConfigurationControllerUpsertScopeSystemEntityResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerInsertPermissionEligibilityRequest, Contract.ConfigurationControllerUpsertScopeSystemEntityResponse>(
            ConfigurationController.insertPermissionEligibilityUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static updatePermissionEligibilityUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/configuration/updatePermissionEligibility/{typeName}", { typeName });

    public static updatePermissionEligibility(request: Contract.ConfigurationControllerUpdatePermissionEligibilityRequest): Promise<Contract.ConfigurationControllerUpsertScopeSystemEntityResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpdatePermissionEligibilityRequest, Contract.ConfigurationControllerUpsertScopeSystemEntityResponse>(
            ConfigurationController.updatePermissionEligibilityUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getPrincipalRoleAssignmentsUrl =
        () => "/api/console/configuration/getPrincipalRoleAssignments";

    public static getPrincipalRoleAssignments(request: Contract.ConfigurationControllerGetPrincipalRoleAssignmentsRequest): Promise<Contract.ConfigurationControllerGetPrincipalRoleAssignmentsResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerGetPrincipalRoleAssignmentsRequest, Contract.ConfigurationControllerGetPrincipalRoleAssignmentsResponse>(
            ConfigurationController.getPrincipalRoleAssignmentsUrl(),
            request);
    }


    public static deleteGroupRoleAssignmentUrl =
        () => "/api/console/configuration/deleteGroupRoleAssignment";

    public static deleteGroupRoleAssignment(request: Contract.ConfigurationControllerDeleteGroupRoleAssignmentRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteGroupRoleAssignmentRequest, void>(
            ConfigurationController.deleteGroupRoleAssignmentUrl(),
            request);
    }


    public static insertGroupRoleAssignmentUrl =
        () => "/api/console/configuration/insertGroupRoleAssignment";

    public static insertGroupRoleAssignment(request: Contract.ConfigurationControllerInsertGroupRoleAssignmentRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerInsertGroupRoleAssignmentRequest, void>(
            ConfigurationController.insertGroupRoleAssignmentUrl(),
            request);
    }


    public static updateGroupRoleAssignmentUrl =
        () => "/api/console/configuration/updateGroupRoleAssignment";

    public static updateGroupRoleAssignment(request: Contract.ConfigurationControllerUpdateGroupRoleAssignmentRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpdateGroupRoleAssignmentRequest, void>(
            ConfigurationController.updateGroupRoleAssignmentUrl(),
            request);
    }


    public static deleteUserRoleAssignmentUrl =
        () => "/api/console/configuration/deleteUserRoleAssignment";

    public static deleteUserRoleAssignment(request: Contract.ConfigurationControllerDeleteUserRoleAssignmentRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteUserRoleAssignmentRequest, void>(
            ConfigurationController.deleteUserRoleAssignmentUrl(),
            request);
    }


    public static insertUserRoleAssignmentUrl =
        () => "/api/console/configuration/insertUserRoleAssignment";

    public static insertUserRoleAssignment(request: Contract.ConfigurationControllerInsertUserRoleAssignmentRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerInsertUserRoleAssignmentRequest, void>(
            ConfigurationController.insertUserRoleAssignmentUrl(),
            request);
    }


    public static setOwnerUrl =
        () => "/api/console/configuration/setOwner";

    public static setOwner(request: Contract.ConfigurationControllerSetOwnerRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerSetOwnerRequest, void>(
            ConfigurationController.setOwnerUrl(),
            request);
    }


    public static updateUserRoleAssignmentUrl =
        () => "/api/console/configuration/updateUserRoleAssignment";

    public static updateUserRoleAssignment(request: Contract.ConfigurationControllerUpdateUserRoleAssignmentRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpdateUserRoleAssignmentRequest, void>(
            ConfigurationController.updateUserRoleAssignmentUrl(),
            request);
    }


    public static deleteProjectUrl =
        () => "/api/console/configuration/deleteProject";

    public static deleteProject(request: Contract.ConfigurationControllerDeleteProjectRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteProjectRequest, void>(
            ConfigurationController.deleteProjectUrl(),
            request);
    }


    public static getProjectConfigurationRulesMatchingActiveTenantIdsUrl =
        () => "/api/console/configuration/getProjectConfigurationRulesMatchingActiveTenantIds";

    public static getProjectConfigurationRulesMatchingActiveTenantIds(request: Contract.ConfigurationControllerGetProjectConfigurationRulesMatchingTenantIdsRequest): Promise<Contract.ConfigurationControllerGetProjectConfigurationRulesMatchingTenantIdsResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerGetProjectConfigurationRulesMatchingTenantIdsRequest, Contract.ConfigurationControllerGetProjectConfigurationRulesMatchingTenantIdsResponse>(
            ConfigurationController.getProjectConfigurationRulesMatchingActiveTenantIdsUrl(),
            request);
    }


    public static getProjectModelsUrl =
        () => "/api/console/configuration/getProjectModels";

    public static getProjectModels(request: Contract.ProjectControllerGetProjectModelsRequest): Promise<Contract.ProjectControllerGetProjectModelsResponse> {
        return ApiController.postRequest<Contract.ProjectControllerGetProjectModelsRequest, Contract.ProjectControllerGetProjectModelsResponse>(
            ConfigurationController.getProjectModelsUrl(),
            request);
    }


    public static insertProjectUrl =
        () => "/api/console/configuration/insertProject";

    public static insertProject(request: Contract.ConfigurationControllerInsertProjectRequest): Promise<Contract.ConfigurationControllerInsertProjectResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerInsertProjectRequest, Contract.ConfigurationControllerInsertProjectResponse>(
            ConfigurationController.insertProjectUrl(),
            request);
    }


    public static updateProjectUrl =
        () => "/api/console/configuration/updateProject";

    public static updateProject(request: Contract.ConfigurationControllerUpdateProjectRequest): Promise<Contract.ConfigurationControllerUpdateProjectResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpdateProjectRequest, Contract.ConfigurationControllerUpdateProjectResponse>(
            ConfigurationController.updateProjectUrl(),
            request);
    }


    public static deleteQRadarServerUrl =
        () => "/api/console/configuration/deleteQRadarServer";

    public static deleteQRadarServer(request: Contract.ConfigurationControllerDeleteQRadarServerRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteQRadarServerRequest, void>(
            ConfigurationController.deleteQRadarServerUrl(),
            request);
    }


    public static insertQRadarServerUrl =
        () => "/api/console/configuration/insertQRadarServer";

    public static insertQRadarServer(request: Contract.ConfigurationControllerInsertQRadarServerRequest): Promise<Contract.ConfigurationControllerUpsertScopeSystemEntityResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerInsertQRadarServerRequest, Contract.ConfigurationControllerUpsertScopeSystemEntityResponse>(
            ConfigurationController.insertQRadarServerUrl(),
            request);
    }


    public static updateQRadarServerUrl =
        () => "/api/console/configuration/updateQRadarServer";

    public static updateQRadarServer(request: Contract.ConfigurationControllerUpdateQRadarServerRequest): Promise<Contract.ConfigurationControllerUpsertScopeSystemEntityResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpdateQRadarServerRequest, Contract.ConfigurationControllerUpsertScopeSystemEntityResponse>(
            ConfigurationController.updateQRadarServerUrl(),
            request);
    }


    public static deleteReportScheduleUrl =
        () => "/api/console/configuration/deleteReportSchedule";

    public static deleteReportSchedule(request: Contract.ConfigurationControllerDeleteReportScheduleRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteReportScheduleRequest, void>(
            ConfigurationController.deleteReportScheduleUrl(),
            request);
    }


    public static getReportDefinitionsUrl =
        () => "/api/console/configuration/getReportDefinitions";

    public static getReportDefinitions(): Promise<Contract.ConfigurationControllerGetReportDefinitionsResponse> {
        return ApiController.postRequest<any, Contract.ConfigurationControllerGetReportDefinitionsResponse>(
            ConfigurationController.getReportDefinitionsUrl(),
            {});
    }


    public static upsertReportScheduleUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/configuration/upsertReportSchedule/{typeName}", { typeName });

    public static upsertReportSchedule(request: Contract.ConfigurationControllerUpsertReportScheduleRequest): Promise<Contract.ConfigurationControllerUpsertScopeSystemEntityResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpsertReportScheduleRequest, Contract.ConfigurationControllerUpsertScopeSystemEntityResponse>(
            ConfigurationController.upsertReportScheduleUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getScopeSystemEntityModelsUrl =
        () => "/api/console/configuration/getScopeSystemEntityModels";

    public static getScopeSystemEntityModels(request: Contract.ConfigurationControllerGetScopeSystemEntityModelsRequest): Promise<Contract.ConfigurationControllerGetScopeSystemEntityModelsResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerGetScopeSystemEntityModelsRequest, Contract.ConfigurationControllerGetScopeSystemEntityModelsResponse>(
            ConfigurationController.getScopeSystemEntityModelsUrl(),
            request);
    }


    public static deleteServiceNowInstanceUrl =
        () => "/api/console/configuration/deleteServiceNowInstance";

    public static deleteServiceNowInstance(request: Contract.ConfigurationControllerDeleteServiceNowInstanceRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteServiceNowInstanceRequest, void>(
            ConfigurationController.deleteServiceNowInstanceUrl(),
            request);
    }


    public static upsertServiceNowInstanceUrl =
        () => "/api/console/configuration/upsertServiceNowInstance";

    public static upsertServiceNowInstance(request: Contract.ConfigurationControllerUpsertServiceNowInstanceRequest): Promise<Contract.ConfigurationControllerUpsertScopeSystemEntityResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpsertServiceNowInstanceRequest, Contract.ConfigurationControllerUpsertScopeSystemEntityResponse>(
            ConfigurationController.upsertServiceNowInstanceUrl(),
            request);
    }


    public static deleteScopeSettingConfigurationUrl =
        () => "/api/console/configuration/deleteScopeSettingConfiguration";

    public static deleteScopeSettingConfiguration(request: Contract.ConfigurationControllerDeleteScopeSettingConfigurationRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteScopeSettingConfigurationRequest, void>(
            ConfigurationController.deleteScopeSettingConfigurationUrl(),
            request);
    }


    public static getAddableScopeSettingConfigurationTypeNamesUrl =
        () => "/api/console/configuration/getAddableScopeSettingConfigurationTypeNames";

    public static getAddableScopeSettingConfigurationTypeNames(request: Contract.ConfigurationControllerGetAddableScopeSettingConfigurationTypeNamesRequest): Promise<Contract.ConfigurationControllerGetAddableScopeSettingConfigurationTypeNamesResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerGetAddableScopeSettingConfigurationTypeNamesRequest, Contract.ConfigurationControllerGetAddableScopeSettingConfigurationTypeNamesResponse>(
            ConfigurationController.getAddableScopeSettingConfigurationTypeNamesUrl(),
            request);
    }


    public static getScopeSettingConfigurationDatasUrl =
        () => "/api/console/configuration/getScopeSettingConfigurationDatas";

    public static getScopeSettingConfigurationDatas(request: Contract.ConfigurationControllerGetScopeSettingConfigurationDatasRequest): Promise<Contract.ConfigurationControllerGetScopeSettingConfigurationDatasResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerGetScopeSettingConfigurationDatasRequest, Contract.ConfigurationControllerGetScopeSettingConfigurationDatasResponse>(
            ConfigurationController.getScopeSettingConfigurationDatasUrl(),
            request);
    }


    public static upsertScopeSettingConfigurationUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/configuration/upsertScopeSettingConfiguration/{typeName}", { typeName });

    public static upsertScopeSettingConfiguration(request: Contract.ConfigurationControllerUpsertScopeSettingConfigurationRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpsertScopeSettingConfigurationRequest, void>(
            ConfigurationController.upsertScopeSettingConfigurationUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static deleteSlackWorkspaceUrl =
        () => "/api/console/configuration/deleteSlackWorkspace";

    public static deleteSlackWorkspace(request: Contract.ConfigurationControllerDeleteSlackWorkspaceRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteSlackWorkspaceRequest, void>(
            ConfigurationController.deleteSlackWorkspaceUrl(),
            request);
    }


    public static getSlackWorkspaceIdToOAuthAuthorizationUrlMapUrl =
        () => "/api/console/configuration/getSlackWorkspaceIdToOAuthAuthorizationUrlMap";

    public static getSlackWorkspaceIdToOAuthAuthorizationUrlMap(request: Contract.ConfigurationControllerGetSlackWorkspaceIdToOAuthAuthorizationUrlMapRequest): Promise<Contract.ConfigurationControllerGetSlackWorkspaceIdToOAuthAuthorizationUrlMapResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerGetSlackWorkspaceIdToOAuthAuthorizationUrlMapRequest, Contract.ConfigurationControllerGetSlackWorkspaceIdToOAuthAuthorizationUrlMapResponse>(
            ConfigurationController.getSlackWorkspaceIdToOAuthAuthorizationUrlMapUrl(),
            request);
    }


    public static getSlackWorkspaceOnboardingInfoUrl =
        () => "/api/console/configuration/getSlackWorkspaceOnboardingInfo";

    public static getSlackWorkspaceOnboardingInfo(request: Contract.ConfigurationControllerGetSlackWorkspaceOnboardingInfoRequest): Promise<Contract.ConfigurationControllerGetSlackWorkspaceOnboardingInfoResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerGetSlackWorkspaceOnboardingInfoRequest, Contract.ConfigurationControllerGetSlackWorkspaceOnboardingInfoResponse>(
            ConfigurationController.getSlackWorkspaceOnboardingInfoUrl(),
            request);
    }


    public static getWorkspaceIdToChannelRawIdToNameMapUrl =
        () => "/api/console/configuration/getWorkspaceIdToChannelRawIdToNameMap";

    public static getWorkspaceIdToChannelRawIdToNameMap(): Promise<Contract.ConfigurationControllerGetWorkspaceIdToChannelRawIdToNameMapResponse> {
        return ApiController.postRequest<any, Contract.ConfigurationControllerGetWorkspaceIdToChannelRawIdToNameMapResponse>(
            ConfigurationController.getWorkspaceIdToChannelRawIdToNameMapUrl(),
            {});
    }


    public static processSlackWorkspaceCallbackUrl =
        (code: string, scopeId: string, state: string, workspaceRawId: Optional<string>) => ApiController.formatUrlParameters("/api/console/configuration/slack/callback", { code, scopeId, state, workspaceRawId });

    public static processSlackWorkspaceCallback(code: string, scopeId: string, state: string, workspaceRawId: Optional<string>): Promise<void> {
        return ApiController.getRequest<void>(ConfigurationController.processSlackWorkspaceCallbackUrl(code, scopeId, state, workspaceRawId));
    }


    public static updateSlackWorkspacesPermissionManagementEnabledUrl =
        () => "/api/console/configuration/updateSlackWorkspacesPermissionManagementEnabled";

    public static updateSlackWorkspacesPermissionManagementEnabled(request: Contract.ConfigurationControllerUpdateSlackWorkspacesPermissionManagementEnabledRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpdateSlackWorkspacesPermissionManagementEnabledRequest, void>(
            ConfigurationController.updateSlackWorkspacesPermissionManagementEnabledUrl(),
            request);
    }


    public static deleteSplunkEventCollectorUrl =
        () => "/api/console/configuration/deleteSplunkEventCollector";

    public static deleteSplunkEventCollector(request: Contract.ConfigurationControllerDeleteSplunkEventCollectorRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteSplunkEventCollectorRequest, void>(
            ConfigurationController.deleteSplunkEventCollectorUrl(),
            request);
    }


    public static upsertSplunkEventCollectorUrl =
        () => "/api/console/configuration/upsertSplunkEventCollector";

    public static upsertSplunkEventCollector(request: Contract.ConfigurationControllerUpsertSplunkEventCollectorRequest): Promise<Contract.ConfigurationControllerUpsertScopeSystemEntityResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpsertSplunkEventCollectorRequest, Contract.ConfigurationControllerUpsertScopeSystemEntityResponse>(
            ConfigurationController.upsertSplunkEventCollectorUrl(),
            request);
    }


    public static deleteSqsQueueUrl =
        () => "/api/console/configuration/deleteSqsQueue";

    public static deleteSqsQueue(request: Contract.ConfigurationControllerDeleteSqsQueueRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteSqsQueueRequest, void>(
            ConfigurationController.deleteSqsQueueUrl(),
            request);
    }


    public static upsertSqsQueueUrl =
        () => "/api/console/configuration/upsertSqsQueue";

    public static upsertSqsQueue(request: Contract.ConfigurationControllerUpsertSqsQueueRequest): Promise<Contract.ConfigurationControllerUpsertScopeSystemEntityResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpsertSqsQueueRequest, Contract.ConfigurationControllerUpsertScopeSystemEntityResponse>(
            ConfigurationController.upsertSqsQueueUrl(),
            request);
    }


    public static deleteTeamsOrganizationUrl =
        () => "/api/console/configuration/deleteTeamsOrganization";

    public static deleteTeamsOrganization(request: Contract.ConfigurationControllerDeleteTeamsOrganizationRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteTeamsOrganizationRequest, void>(
            ConfigurationController.deleteTeamsOrganizationUrl(),
            request);
    }


    public static getTeamsOrganizationOnboardingInfoUrl =
        () => "/api/console/configuration/getTeamsOrganizationOnboardingInfo";

    public static getTeamsOrganizationOnboardingInfo(request: Contract.ConfigurationControllerGetTeamsOrganizationOnboardingInfoRequest): Promise<Contract.ConfigurationControllerGetTeamsOrganizationOnboardingInfoResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerGetTeamsOrganizationOnboardingInfoRequest, Contract.ConfigurationControllerGetTeamsOrganizationOnboardingInfoResponse>(
            ConfigurationController.getTeamsOrganizationOnboardingInfoUrl(),
            request);
    }


    public static processTeamsOrganizationCallbackUrl =
        (error: Optional<string>, state: Optional<string>, tenant: Optional<string>) => ApiController.formatUrlParameters("/api/console/configuration/teams/callback", { error, state, tenant });

    public static processTeamsOrganizationCallback(error: Optional<string>, state: Optional<string>, tenant: Optional<string>): Promise<void> {
        return ApiController.getRequest<void>(ConfigurationController.processTeamsOrganizationCallbackUrl(error, state, tenant));
    }


    public static deleteWebhookEndpointUrl =
        () => "/api/console/configuration/deleteWebhookEndpoint";

    public static deleteWebhookEndpoint(request: Contract.ConfigurationControllerDeleteWebhookEndpointRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteWebhookEndpointRequest, void>(
            ConfigurationController.deleteWebhookEndpointUrl(),
            request);
    }


    public static insertWebhookEndpointUrl =
        () => "/api/console/configuration/insertWebhookEndpoint";

    public static insertWebhookEndpoint(request: Contract.ConfigurationControllerInsertWebhookEndpointRequest): Promise<Contract.ConfigurationControllerUpsertScopeSystemEntityResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerInsertWebhookEndpointRequest, Contract.ConfigurationControllerUpsertScopeSystemEntityResponse>(
            ConfigurationController.insertWebhookEndpointUrl(),
            request);
    }


    public static updateWebhookEndpointUrl =
        () => "/api/console/configuration/updateWebhookEndpoint";

    public static updateWebhookEndpoint(request: Contract.ConfigurationControllerUpdateWebhookEndpointRequest): Promise<Contract.ConfigurationControllerUpsertScopeSystemEntityResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpdateWebhookEndpointRequest, Contract.ConfigurationControllerUpsertScopeSystemEntityResponse>(
            ConfigurationController.updateWebhookEndpointUrl(),
            request);
    }


    public static deleteWorkloadAnalysisTrustedFileUrl =
        () => "/api/console/configuration/deleteWorkloadAnalysisTrustedFile";

    public static deleteWorkloadAnalysisTrustedFile(request: Contract.ConfigurationControllerDeleteWorkloadAnalysisTrustedFileRequest): Promise<Contract.ConfigurationControllerDeleteWorkloadAnalysisTrustedFileResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerDeleteWorkloadAnalysisTrustedFileRequest, Contract.ConfigurationControllerDeleteWorkloadAnalysisTrustedFileResponse>(
            ConfigurationController.deleteWorkloadAnalysisTrustedFileUrl(),
            request);
    }


    public static getWorkloadAnalysisOperatingSystemEndOfLifeTimeFrameUrl =
        () => "/api/console/configuration/getWorkloadAnalysisOperatingSystemEndOfLifeTimeFrame";

    public static getWorkloadAnalysisOperatingSystemEndOfLifeTimeFrame(): Promise<Contract.ConfigurationControllerGetWorkloadAnalysisOperatingSystemEndOfLifeTimeFrameResponse> {
        return ApiController.postRequest<any, Contract.ConfigurationControllerGetWorkloadAnalysisOperatingSystemEndOfLifeTimeFrameResponse>(
            ConfigurationController.getWorkloadAnalysisOperatingSystemEndOfLifeTimeFrameUrl(),
            {});
    }


    public static getWorkloadAnalysisVulnerabilityScoreTypeUrl =
        () => "/api/console/configuration/getWorkloadAnalysisVulnerabilityScoreType";

    public static getWorkloadAnalysisVulnerabilityScoreType(): Promise<Contract.ConfigurationControllerGetWorkloadAnalysisVulnerabilityScoreTypeResponse> {
        return ApiController.postRequest<any, Contract.ConfigurationControllerGetWorkloadAnalysisVulnerabilityScoreTypeResponse>(
            ConfigurationController.getWorkloadAnalysisVulnerabilityScoreTypeUrl(),
            {});
    }


    public static insertWorkloadAnalysisTrustedFilesUrl =
        () => "/api/console/configuration/insertWorkloadAnalysisTrustedFiles";

    public static insertWorkloadAnalysisTrustedFiles(request: Contract.ConfigurationControllerInsertWorkloadAnalysisTrustedFilesRequest): Promise<Contract.ConfigurationControllerInsertWorkloadAnalysisTrustedFilesResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerInsertWorkloadAnalysisTrustedFilesRequest, Contract.ConfigurationControllerInsertWorkloadAnalysisTrustedFilesResponse>(
            ConfigurationController.insertWorkloadAnalysisTrustedFilesUrl(),
            request);
    }


    public static updateAzureWorkloadAnalysisUrl =
        () => "/api/console/configuration/updateAzureWorkloadAnalysis";

    public static updateAzureWorkloadAnalysis(request: Contract.ConfigurationControllerUpdateAzureWorkloadAnalysisRequest): Promise<Contract.ConfigurationControllerUpdateAzureWorkloadAnalysisResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpdateAzureWorkloadAnalysisRequest, Contract.ConfigurationControllerUpdateAzureWorkloadAnalysisResponse>(
            ConfigurationController.updateAzureWorkloadAnalysisUrl(),
            request);
    }


    public static updateWorkloadAnalysisUrl =
        () => "/api/console/configuration/updateWorkloadAnalysis";

    public static updateWorkloadAnalysis(request: Contract.ConfigurationControllerUpdateWorkloadAnalysisRequest): Promise<Contract.ConfigurationControllerUpdateWorkloadAnalysisResponse> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpdateWorkloadAnalysisRequest, Contract.ConfigurationControllerUpdateWorkloadAnalysisResponse>(
            ConfigurationController.updateWorkloadAnalysisUrl(),
            request);
    }


    public static updateWorkloadAnalysisOperatingSystemEndOfLifeTimeFrameUrl =
        () => "/api/console/configuration/updateWorkloadAnalysisOperatingSystemEndOfLifeTimeFrame";

    public static updateWorkloadAnalysisOperatingSystemEndOfLifeTimeFrame(request: Contract.ConfigurationControllerUpdateWorkloadAnalysisOperatingSystemEndOfLifeTimeFrameRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpdateWorkloadAnalysisOperatingSystemEndOfLifeTimeFrameRequest, void>(
            ConfigurationController.updateWorkloadAnalysisOperatingSystemEndOfLifeTimeFrameUrl(),
            request);
    }


    public static updateWorkloadAnalysisVulnerabilityScoreTypeUrl =
        () => "/api/console/configuration/updateWorkloadAnalysisVulnerabilityScoreType";

    public static updateWorkloadAnalysisVulnerabilityScoreType(request: Contract.ConfigurationControllerUpdateWorkloadAnalysisVulnerabilityScoreTypeRequest): Promise<void> {
        return ApiController.postRequest<Contract.ConfigurationControllerUpdateWorkloadAnalysisVulnerabilityScoreTypeRequest, void>(
            ConfigurationController.updateWorkloadAnalysisVulnerabilityScoreTypeUrl(),
            request);
    }
}

export class DashboardController extends ApiController {
    public static getPublicEntityIdsUrl =
        () => "/api/console/dashboard/getPublicEntityIds";

    public static getPublicEntityIds(request: Contract.DashboardControllerGetPublicEntityIdsRequest): Promise<Contract.DashboardControllerGetPublicEntityIdsResponse> {
        return ApiController.postRequest<Contract.DashboardControllerGetPublicEntityIdsRequest, Contract.DashboardControllerGetPublicEntityIdsResponse>(
            DashboardController.getPublicEntityIdsUrl(),
            request);
    }


    public static getSummaryUrl =
        () => "/api/console/dashboard/getSummary";

    public static getSummary(): Promise<Contract.DashboardGetSummaryResponse> {
        return ApiController.postRequest<any, Contract.DashboardGetSummaryResponse>(
            DashboardController.getSummaryUrl(),
            {});
    }


    public static getSummaryTopCriticalVulnerabilityModelsUrl =
        () => "/api/console/dashboard/getSummaryTopCriticalVulnerabilityModels";

    public static getSummaryTopCriticalVulnerabilityModels(): Promise<Contract.DashboardControllerGetSummaryTopCriticalVulnerabilityModelsResponse> {
        return ApiController.postRequest<any, Contract.DashboardControllerGetSummaryTopCriticalVulnerabilityModelsResponse>(
            DashboardController.getSummaryTopCriticalVulnerabilityModelsUrl(),
            {});
    }


    public static getSummaryVulnerabilityModelWorkloadResourceIdsUrl =
        () => "/api/console/dashboard/getSummaryVulnerabilityModelWorkloadResourceIds";

    public static getSummaryVulnerabilityModelWorkloadResourceIds(request: Contract.DashboardControllerGetSummaryVulnerabilityWorkloadResourceIdsRequest): Promise<Contract.DashboardControllerGetSummaryVulnerabilityWorkloadResourceIdsResponse> {
        return ApiController.postRequest<Contract.DashboardControllerGetSummaryVulnerabilityWorkloadResourceIdsRequest, Contract.DashboardControllerGetSummaryVulnerabilityWorkloadResourceIdsResponse>(
            DashboardController.getSummaryVulnerabilityModelWorkloadResourceIdsUrl(),
            request);
    }
}

export class DataController extends ApiController {
    public static getDataAnalysisResourceScansUrl =
        () => "/api/console/data/getDataAnalysisResourceScans";

    public static getDataAnalysisResourceScans(request: Contract.DataControllerGetDataAnalysisResourceScansRequest): Promise<Contract.DataControllerGetDataAnalysisResourceScansResponse> {
        return ApiController.postRequest<Contract.DataControllerGetDataAnalysisResourceScansRequest, Contract.DataControllerGetDataAnalysisResourceScansResponse>(
            DataController.getDataAnalysisResourceScansUrl(),
            request);
    }


    public static getDataClassifierModelsUrl =
        () => "/api/console/data/getDataClassifierModels";

    public static getDataClassifierModels(): Promise<Contract.DataControllerGetDataClassifierModelsResponse> {
        return ApiController.postRequest<any, Contract.DataControllerGetDataClassifierModelsResponse>(
            DataController.getDataClassifierModelsUrl(),
            {});
    }


    public static updateDataAnalysisResourcePriorityScanRequestUrl =
        () => "/api/console/data/updateDataAnalysisResourcePriorityScanRequest";

    public static updateDataAnalysisResourcePriorityScanRequest(request: Contract.DataControllerUpdateDataAnalysisResourcePriorityScanRequestRequest): Promise<void> {
        return ApiController.postRequest<Contract.DataControllerUpdateDataAnalysisResourcePriorityScanRequestRequest, void>(
            DataController.updateDataAnalysisResourcePriorityScanRequestUrl(),
            request);
    }
}

export class EntityController extends ApiController {
    public static generateAwsResourceNonexcessivePolicyUrl =
        () => "/api/console/entity/generateAwsResourceNonexcessivePolicy";

    public static generateAwsResourceNonexcessivePolicy(request: Contract.EntityControllerGenerateAwsResourceNonexcessivePolicyRequest): Promise<Contract.EntityControllerGenerateAwsResourceNonexcessivePolicyResponse> {
        return ApiController.postRequest<Contract.EntityControllerGenerateAwsResourceNonexcessivePolicyRequest, Contract.EntityControllerGenerateAwsResourceNonexcessivePolicyResponse>(
            EntityController.generateAwsResourceNonexcessivePolicyUrl(),
            request);
    }


    public static generateAzureIdentityNonexcessiveRoleDefinitionUrl =
        () => "/api/console/entity/generateAzureIdentityNonexcessiveRoleDefinition";

    public static generateAzureIdentityNonexcessiveRoleDefinition(request: Contract.EntityControllerGenerateAzureIdentityNonexcessiveRoleDefinitionRequest): Promise<Contract.EntityControllerGenerateAzureIdentityNonexcessiveRoleDefinitionResponse> {
        return ApiController.postRequest<Contract.EntityControllerGenerateAzureIdentityNonexcessiveRoleDefinitionRequest, Contract.EntityControllerGenerateAzureIdentityNonexcessiveRoleDefinitionResponse>(
            EntityController.generateAzureIdentityNonexcessiveRoleDefinitionUrl(),
            request);
    }


    public static generateGcpIdentityNonexcessiveRoleUrl =
        () => "/api/console/entity/generateGcpIdentityNonexcessiveRole";

    public static generateGcpIdentityNonexcessiveRole(request: Contract.EntityControllerGenerateGcpIdentityNonexcessiveRoleRequest): Promise<Contract.EntityControllerGenerateGcpIdentityNonexcessiveRoleResponse> {
        return ApiController.postRequest<Contract.EntityControllerGenerateGcpIdentityNonexcessiveRoleRequest, Contract.EntityControllerGenerateGcpIdentityNonexcessiveRoleResponse>(
            EntityController.generateGcpIdentityNonexcessiveRoleUrl(),
            request);
    }


    public static getAwsAccessListPermissionActionConditionJsonsUrl =
        () => "/api/console/entity/getAwsAccessListPermissionActionConditionJsons";

    public static getAwsAccessListPermissionActionConditionJsons(request: Contract.EntityControllerGetAwsAccessListPermissionActionConditionJsonsRequest): Promise<Contract.EntityControllerGetAwsAccessListPermissionActionConditionJsonsResponse> {
        return ApiController.postRequest<Contract.EntityControllerGetAwsAccessListPermissionActionConditionJsonsRequest, Contract.EntityControllerGetAwsAccessListPermissionActionConditionJsonsResponse>(
            EntityController.getAwsAccessListPermissionActionConditionJsonsUrl(),
            request);
    }


    public static getAccessGraphUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/entity/getAccessGraph/{typeName}", { typeName });

    public static getAccessGraph(request: Contract.EntityControllerGetAccessGraphRequest): Promise<Contract.EntityControllerGetAccessGraphResponse> {
        return ApiController.postRequest<Contract.EntityControllerGetAccessGraphRequest, Contract.EntityControllerGetAccessGraphResponse>(
            EntityController.getAccessGraphUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getAccessListPermissionActionsUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/entity/getAccessListPermissionActions/{typeName}", { typeName });

    public static getAccessListPermissionActions(request: Contract.EntityControllerGetAccessListPermissionActionsRequest): Promise<Contract.EntityControllerGetAccessListPermissionActionsResponse> {
        return ApiController.postRequest<Contract.EntityControllerGetAccessListPermissionActionsRequest, Contract.EntityControllerGetAccessListPermissionActionsResponse>(
            EntityController.getAccessListPermissionActionsUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getActionsUrl =
        () => "/api/console/entity/getActions";

    public static getActions(request: Contract.EntityControllerGetActionsRequest): Promise<Contract.EntityControllerGetActionsResponse> {
        return ApiController.postRequest<Contract.EntityControllerGetActionsRequest, Contract.EntityControllerGetActionsResponse>(
            EntityController.getActionsUrl(),
            request);
    }


    public static getAwsDistributionIdsUrl =
        () => "/api/console/entity/getAwsDistributionIds";

    public static getAwsDistributionIds(request: Contract.EntityControllerGetAwsDistributionIdsRequest): Promise<Contract.EntityControllerGetAwsDistributionIdsResponse> {
        return ApiController.postRequest<Contract.EntityControllerGetAwsDistributionIdsRequest, Contract.EntityControllerGetAwsDistributionIdsResponse>(
            EntityController.getAwsDistributionIdsUrl(),
            request);
    }


    public static getAwsKmsKeyGrantsUrl =
        () => "/api/console/entity/getAwsKmsKeyGrants";

    public static getAwsKmsKeyGrants(request: Contract.EntityControllerGetAwsKmsKeyGrantsRequest): Promise<Contract.EntityControllerGetAwsKmsKeyGrantsResponse> {
        return ApiController.postRequest<Contract.EntityControllerGetAwsKmsKeyGrantsRequest, Contract.EntityControllerGetAwsKmsKeyGrantsResponse>(
            EntityController.getAwsKmsKeyGrantsUrl(),
            request);
    }


    public static getAwsLoggingBucketDistributionIdsUrl =
        () => "/api/console/entity/getAwsLoggingBucketDistributionIds";

    public static getAwsLoggingBucketDistributionIds(request: Contract.EntityControllerGetAwsLoggingBucketDistributionIdsAsyncRequest): Promise<Contract.EntityControllerGetAwsLoggingBucketDistributionIdsAsyncResponse> {
        return ApiController.postRequest<Contract.EntityControllerGetAwsLoggingBucketDistributionIdsAsyncRequest, Contract.EntityControllerGetAwsLoggingBucketDistributionIdsAsyncResponse>(
            EntityController.getAwsLoggingBucketDistributionIdsUrl(),
            request);
    }


    public static getAwsTimestreamDatabaseTableIdsUrl =
        () => "/api/console/entity/getAwsTimestreamDatabaseTableIds";

    public static getAwsTimestreamDatabaseTableIds(request: Contract.EntityControllerGetAwsTimestreamDatabaseTableIdsRequest): Promise<Contract.EntityControllerGetAwsTimestreamDatabaseTableIdsResponse> {
        return ApiController.postRequest<Contract.EntityControllerGetAwsTimestreamDatabaseTableIdsRequest, Contract.EntityControllerGetAwsTimestreamDatabaseTableIdsResponse>(
            EntityController.getAwsTimestreamDatabaseTableIdsUrl(),
            request);
    }


    public static getAzureKeyVaultVaultObjectIdsUrl =
        () => "/api/console/entity/getAzureKeyVaultVaultObjectIds";

    public static getAzureKeyVaultVaultObjectIds(request: Contract.EntityControllerGetAzureKeyVaultVaultObjectIdsRequest): Promise<Contract.EntityControllerGetAzureKeyVaultVaultObjectIdsResponse> {
        return ApiController.postRequest<Contract.EntityControllerGetAzureKeyVaultVaultObjectIdsRequest, Contract.EntityControllerGetAzureKeyVaultVaultObjectIdsResponse>(
            EntityController.getAzureKeyVaultVaultObjectIdsUrl(),
            request);
    }


    public static getAzureResourceGroupResourceDatasUrl =
        () => "/api/console/entity/getAzureResourceGroupResourceDatas";

    public static getAzureResourceGroupResourceDatas(request: Contract.EntityControllerGetAzureResourceGroupResourceDatasRequest): Promise<Contract.EntityControllerGetAzureResourceGroupResourceDatasResponse> {
        return ApiController.postRequest<Contract.EntityControllerGetAzureResourceGroupResourceDatasRequest, Contract.EntityControllerGetAzureResourceGroupResourceDatasResponse>(
            EntityController.getAzureResourceGroupResourceDatasUrl(),
            request);
    }


    public static getAzureStorageAccountChildResourceDatasUrl =
        () => "/api/console/entity/getAzureStorageAccountChildResourceDatas";

    public static getAzureStorageAccountChildResourceDatas(request: Contract.EntityControllerGetAzureStorageAccountChildResourceDatasRequest): Promise<Contract.EntityControllerGetAzureStorageAccountChildResourceDatasResponse> {
        return ApiController.postRequest<Contract.EntityControllerGetAzureStorageAccountChildResourceDatasRequest, Contract.EntityControllerGetAzureStorageAccountChildResourceDatasResponse>(
            EntityController.getAzureStorageAccountChildResourceDatasUrl(),
            request);
    }


    public static getClusterKubernetesResourceModelsUrl =
        () => "/api/console/entity/getClusterKubernetesResourceModels";

    public static getClusterKubernetesResourceModels(request: Contract.EntityControllerGetClusterKubernetesResourceModelsRequest): Promise<Contract.EntityControllerGetClusterKubernetesResourceModelsResponse> {
        return ApiController.postRequest<Contract.EntityControllerGetClusterKubernetesResourceModelsRequest, Contract.EntityControllerGetClusterKubernetesResourceModelsResponse>(
            EntityController.getClusterKubernetesResourceModelsUrl(),
            request);
    }


    public static getEntityEntitiesViewTypeNamesUrl =
        () => "/api/console/entity/getEntityEntitiesViewTypeNames";

    public static getEntityEntitiesViewTypeNames(request: Contract.EntityControllerGetEntityEntitiesViewTypeNamesRequest): Promise<Contract.EntityControllerGetEntityEntitiesViewTypeNamesResponse> {
        return ApiController.postRequest<Contract.EntityControllerGetEntityEntitiesViewTypeNamesRequest, Contract.EntityControllerGetEntityEntitiesViewTypeNamesResponse>(
            EntityController.getEntityEntitiesViewTypeNamesUrl(),
            request);
    }


    public static getEntityExcessivePermissionCustomEvaluationDataUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/entity/getEntityExcessivePermissionCustomEvaluationData/{typeName}", { typeName });

    public static getEntityExcessivePermissionCustomEvaluationData(request: Contract.EntityControllerGetEntityExcessivePermissionCustomEvaluationDataRequest): Promise<Contract.EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponse> {
        return ApiController.postRequest<Contract.EntityControllerGetEntityExcessivePermissionCustomEvaluationDataRequest, Contract.EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponse>(
            EntityController.getEntityExcessivePermissionCustomEvaluationDataUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getEntityModelFilterItemPageUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/entity/getEntityModelFilterItemPage/{typeName}", { typeName });

    public static getEntityModelFilterItemPage(request: Contract.EntityControllerGetEntityModelFilterItemPageRequest): Promise<Contract.EntityControllerGetEntityModelFilterItemPageResponseBase> {
        return ApiController.postRequest<Contract.EntityControllerGetEntityModelFilterItemPageRequest, Contract.EntityControllerGetEntityModelFilterItemPageResponseBase>(
            EntityController.getEntityModelFilterItemPageUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getEntityModelFiltersUrl =
        () => "/api/console/entity/getEntityModelFilters";

    public static getEntityModelFilters(request: Contract.EntityControllerGetEntityModelFiltersRequest): Promise<Contract.EntityControllerGetEntityModelFiltersResponse> {
        return ApiController.postRequest<Contract.EntityControllerGetEntityModelFiltersRequest, Contract.EntityControllerGetEntityModelFiltersResponse>(
            EntityController.getEntityModelFiltersUrl(),
            request);
    }


    public static getEntityModelPageUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/entity/getEntityModelPage/{typeName}", { typeName });

    public static getEntityModelPage(request: Contract.EntityControllerGetEntityModelPageRequest): Promise<Contract.EntityControllerGetEntityModelPageResponse> {
        return ApiController.postRequest<Contract.EntityControllerGetEntityModelPageRequest, Contract.EntityControllerGetEntityModelPageResponse>(
            EntityController.getEntityModelPageUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getEntityModelSummaryUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/entity/getEntityModelSummary/{typeName}", { typeName });

    public static getEntityModelSummary(request: Contract.EntityControllerGetEntityModelPageRequest): Promise<Contract.EntityControllerGetEntityModelSummaryResponse> {
        return ApiController.postRequest<Contract.EntityControllerGetEntityModelPageRequest, Contract.EntityControllerGetEntityModelSummaryResponse>(
            EntityController.getEntityModelSummaryUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getEntityModelsUrl =
        () => "/api/console/entity/getEntityModels";

    public static getEntityModels(request: Contract.EntityControllerGetEntityModelsRequest): Promise<Contract.EntityControllerGetEntityModelsResponse> {
        return ApiController.postRequest<Contract.EntityControllerGetEntityModelsRequest, Contract.EntityControllerGetEntityModelsResponse>(
            EntityController.getEntityModelsUrl(),
            request);
    }


    public static getEntitySearchableReferencesUrl =
        () => "/api/console/entity/getEntitySearchableReferences";

    public static getEntitySearchableReferences(request: Contract.EntityControllerGetEntitySearchableReferencesRequest): Promise<Contract.EntityControllerGetEntitySearchableReferencesResponse> {
        return ApiController.postRequest<Contract.EntityControllerGetEntitySearchableReferencesRequest, Contract.EntityControllerGetEntitySearchableReferencesResponse>(
            EntityController.getEntitySearchableReferencesUrl(),
            request);
    }


    public static getEntitySnapshotsUrl =
        () => "/api/console/entity/getEntitySnapshots";

    public static getEntitySnapshots(request: Contract.EntityControllerGetEntitySnapshotsRequest): Promise<Contract.EntityControllerGetEntitySnapshotsResponse> {
        return ApiController.postRequest<Contract.EntityControllerGetEntitySnapshotsRequest, Contract.EntityControllerGetEntitySnapshotsResponse>(
            EntityController.getEntitySnapshotsUrl(),
            request);
    }


    public static getEntityTypeEntitySearchableReferencesUrl =
        () => "/api/console/entity/getEntityTypeEntitySearchableReferences";

    public static getEntityTypeEntitySearchableReferences(request: Contract.EntityControllerGetEntityTypeEntitySearchableReferencesRequest): Promise<Contract.EntityControllerGetEntityTypeEntitySearchableReferencesResponse> {
        return ApiController.postRequest<Contract.EntityControllerGetEntityTypeEntitySearchableReferencesRequest, Contract.EntityControllerGetEntityTypeEntitySearchableReferencesResponse>(
            EntityController.getEntityTypeEntitySearchableReferencesUrl(),
            request);
    }


    public static getEntityTypeMetadataModelsUrl =
        () => "/api/console/entity/getEntityTypeMetadataModels";

    public static getEntityTypeMetadataModels(): Promise<Contract.EntityControllerGetEntityTypeMetadataModelsResponse> {
        return ApiController.postRequest<any, Contract.EntityControllerGetEntityTypeMetadataModelsResponse>(
            EntityController.getEntityTypeMetadataModelsUrl(),
            {});
    }


    public static getExcessivePermissionMultiRiskPrincipalIdsUrl =
        () => "/api/console/entity/getExcessivePermissionMultiRiskPrincipalIds";

    public static getExcessivePermissionMultiRiskPrincipalIds(request: Contract.EntityControllerGetExcessivePermissionMultiRiskPrincipalIdsRequest): Promise<Contract.EntityControllerGetExcessivePermissionMultiRiskPrincipalIdsResponse> {
        return ApiController.postRequest<Contract.EntityControllerGetExcessivePermissionMultiRiskPrincipalIdsRequest, Contract.EntityControllerGetExcessivePermissionMultiRiskPrincipalIdsResponse>(
            EntityController.getExcessivePermissionMultiRiskPrincipalIdsUrl(),
            request);
    }


    public static getGcpBigQueryDatasetTableIdsUrl =
        () => "/api/console/entity/getGcpBigQueryDatasetTableIds";

    public static getGcpBigQueryDatasetTableIds(request: Contract.EntityControllerGetGcpBigQueryDatasetTableIdsRequest): Promise<Contract.EntityControllerGetGcpBigQueryDatasetTableIdsResponse> {
        return ApiController.postRequest<Contract.EntityControllerGetGcpBigQueryDatasetTableIdsRequest, Contract.EntityControllerGetGcpBigQueryDatasetTableIdsResponse>(
            EntityController.getGcpBigQueryDatasetTableIdsUrl(),
            request);
    }


    public static getGcpSpannerInstanceDatabaseIdsUrl =
        () => "/api/console/entity/getGcpSpannerInstanceDatabaseIds";

    public static getGcpSpannerInstanceDatabaseIds(request: Contract.EntityControllerGetGcpSpannerInstanceDatabaseIdsRequest): Promise<Contract.EntityControllerGetGcpSpannerInstanceDatabaseIdsResponse> {
        return ApiController.postRequest<Contract.EntityControllerGetGcpSpannerInstanceDatabaseIdsRequest, Contract.EntityControllerGetGcpSpannerInstanceDatabaseIdsResponse>(
            EntityController.getGcpSpannerInstanceDatabaseIdsUrl(),
            request);
    }


    public static getKubernetesSystemNamespaceIdsUrl =
        () => "/api/console/entity/getKubernetesSystemNamespaceIds";

    public static getKubernetesSystemNamespaceIds(request: Contract.EntityControllerGetKubernetesSystemNamespaceIdsRequest): Promise<Contract.EntityControllerGetKubernetesSystemNamespaceIdsResponse> {
        return ApiController.postRequest<Contract.EntityControllerGetKubernetesSystemNamespaceIdsRequest, Contract.EntityControllerGetKubernetesSystemNamespaceIdsResponse>(
            EntityController.getKubernetesSystemNamespaceIdsUrl(),
            request);
    }


    public static getNetworkGraphUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/entity/getNetworkGraph/{typeName}", { typeName });

    public static getNetworkGraph(request: Contract.EntityControllerGetNetworkGraphRequest): Promise<Contract.EntityControllerGetNetworkGraphResponse> {
        return ApiController.postRequest<Contract.EntityControllerGetNetworkGraphRequest, Contract.EntityControllerGetNetworkGraphResponse>(
            EntityController.getNetworkGraphUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getPrincipalIdToModelAccessMapUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/entity/getPrincipalIdToModelAccessMap/{typeName}", { typeName });

    public static getPrincipalIdToModelAccessMap(request: Contract.EntityControllerGetPrincipalIdToAccessMapRequest): Promise<Contract.EntityControllerGetPrincipalIdToModelAccessMapResponse> {
        return ApiController.postRequest<Contract.EntityControllerGetPrincipalIdToAccessMapRequest, Contract.EntityControllerGetPrincipalIdToModelAccessMapResponse>(
            EntityController.getPrincipalIdToModelAccessMapUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getRegionModelsUrl =
        () => "/api/console/entity/getRegionModels";

    public static getRegionModels(): Promise<Contract.EntityControllerGetRegionModelsResponse> {
        return ApiController.postRequest<any, Contract.EntityControllerGetRegionModelsResponse>(
            EntityController.getRegionModelsUrl(),
            {});
    }


    public static getSummaryUrl =
        () => "/api/console/entity/getSummary";

    public static getSummary(request: Contract.EntityControllerGetSummaryRequest): Promise<Contract.EntityControllerGetSummaryResponse> {
        return ApiController.postRequest<Contract.EntityControllerGetSummaryRequest, Contract.EntityControllerGetSummaryResponse>(
            EntityController.getSummaryUrl(),
            request);
    }


    public static searchAllEntityModelsUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/entity/searchAllEntityModels/{typeName}", { typeName });

    public static searchAllEntityModels(request: Contract.EntityControllerSearchAllEntityModelsRequest): Promise<Contract.EntityControllerSearchAllEntityModelsResponse> {
        return ApiController.postRequest<Contract.EntityControllerSearchAllEntityModelsRequest, Contract.EntityControllerSearchAllEntityModelsResponse>(
            EntityController.searchAllEntityModelsUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static searchEntityIdsUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/entity/searchEntityIds/{typeName}", { typeName });

    public static searchEntityIds(request: Contract.EntityControllerSearchEntityIdsRequest): Promise<Contract.EntityControllerSearchEntityIdsResponse> {
        return ApiController.postRequest<Contract.EntityControllerSearchEntityIdsRequest, Contract.EntityControllerSearchEntityIdsResponse>(
            EntityController.searchEntityIdsUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static searchEntityModelsUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/entity/searchEntityModels/{typeName}", { typeName });

    public static searchEntityModels(request: Contract.EntityControllerSearchEntityModelsRequest): Promise<Contract.EntityControllerSearchEntityModelsResponse> {
        return ApiController.postRequest<Contract.EntityControllerSearchEntityModelsRequest, Contract.EntityControllerSearchEntityModelsResponse>(
            EntityController.searchEntityModelsUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static updateConfigurationUrl =
        () => "/api/console/entity/updateConfiguration";

    public static updateConfiguration(request: Contract.EntityControllerUpdateConfigurationRequest): Promise<void> {
        return ApiController.postRequest<Contract.EntityControllerUpdateConfigurationRequest, void>(
            EntityController.updateConfigurationUrl(),
            request);
    }
}

export class EntitySnapshotController extends ApiController {
    public static getSnapshotsUrl =
        () => "/api/console/entitySnapshot/getSnapshots";

    public static getSnapshots(request: Contract.EntitySnapshotControllerGetSnapshotsRequest): Promise<Contract.EntitySnapshotControllerGetSnapshotsResponse> {
        return ApiController.postRequest<Contract.EntitySnapshotControllerGetSnapshotsRequest, Contract.EntitySnapshotControllerGetSnapshotsResponse>(
            EntitySnapshotController.getSnapshotsUrl(),
            request);
    }
}

export class EventController extends ApiController {
    public static getEventFilterItemPageUrl =
        () => "/api/console/event/getEventFilterItemPage";

    public static getEventFilterItemPage(request: Contract.EventControllerGetEventFilterItemPageRequest): Promise<Contract.EventControllerGetEventFilterItemPageResponseBase> {
        return ApiController.postRequest<Contract.EventControllerGetEventFilterItemPageRequest, Contract.EventControllerGetEventFilterItemPageResponseBase>(
            EventController.getEventFilterItemPageUrl(),
            request);
    }


    public static getEventFiltersUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/event/getEventFilters/{typeName}", { typeName });

    public static getEventFilters(request: Contract.EventControllerGetEventFiltersRequest): Promise<Contract.EventControllerGetEventFiltersResponse> {
        return ApiController.postRequest<Contract.EventControllerGetEventFiltersRequest, Contract.EventControllerGetEventFiltersResponse>(
            EventController.getEventFiltersUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getEventModelCountUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/event/getEventModelCount/{typeName}", { typeName });

    public static getEventModelCount(request: Contract.EventControllerGetEventModelPageRequest): Promise<Contract.EventControllerGetEventModelCountResponse> {
        return ApiController.postRequest<Contract.EventControllerGetEventModelPageRequest, Contract.EventControllerGetEventModelCountResponse>(
            EventController.getEventModelCountUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getEventModelPageUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/event/getEventModelPage/{typeName}", { typeName });

    public static getEventModelPage(request: Contract.EventControllerGetEventModelPageRequest): Promise<Contract.EventControllerGetEventModelPageResponse> {
        return ApiController.postRequest<Contract.EventControllerGetEventModelPageRequest, Contract.EventControllerGetEventModelPageResponse>(
            EventController.getEventModelPageUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getEventModelsUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/event/getEventModels/{typeName}", { typeName });

    public static getEventModels(request: Contract.EventControllerGetEventModelsRequest): Promise<Contract.EventControllerGetEventModelsResponse> {
        return ApiController.postRequest<Contract.EventControllerGetEventModelsRequest, Contract.EventControllerGetEventModelsResponse>(
            EventController.getEventModelsUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }
}

export class FileController extends ApiController {
    public static getFileModelsUrl =
        () => "/api/console/file/getFileModels";

    public static getFileModels(request: Contract.FileControllerGetFileModelsRequest): Promise<Contract.FileControllerGetFileModelsResponse> {
        return ApiController.postRequest<Contract.FileControllerGetFileModelsRequest, Contract.FileControllerGetFileModelsResponse>(
            FileController.getFileModelsUrl(),
            request);
    }
}

export class FolderController extends ApiController {
    public static deleteFolderUrl =
        () => "/api/console/folder/deleteFolder";

    public static deleteFolder(request: Contract.FolderControllerDeleteFolderRequest): Promise<void> {
        return ApiController.postRequest<Contract.FolderControllerDeleteFolderRequest, void>(
            FolderController.deleteFolderUrl(),
            request);
    }


    public static insertFolderUrl =
        () => "/api/console/folder/insertFolder";

    public static insertFolder(request: Contract.FolderControllerInsertFolderRequest): Promise<Contract.FolderControllerInsertFolderResponse> {
        return ApiController.postRequest<Contract.FolderControllerInsertFolderRequest, Contract.FolderControllerInsertFolderResponse>(
            FolderController.insertFolderUrl(),
            request);
    }


    public static updateFolderUrl =
        () => "/api/console/folder/updateFolder";

    public static updateFolder(request: Contract.FolderControllerUpdateFolderRequest): Promise<Contract.FolderControllerUpdateFolderResponse> {
        return ApiController.postRequest<Contract.FolderControllerUpdateFolderRequest, Contract.FolderControllerUpdateFolderResponse>(
            FolderController.updateFolderUrl(),
            request);
    }
}

export class IdentityController extends ApiController {
    public static getIdentityModelsUrl =
        () => "/api/console/identity/getIdentityModels";

    public static getIdentityModels(): Promise<Contract.IdentityControllerGetIdentityModelsResponse> {
        return ApiController.postRequest<any, Contract.IdentityControllerGetIdentityModelsResponse>(
            IdentityController.getIdentityModelsUrl(),
            {});
    }
}

export class KubernetesController extends ApiController {
    public static deleteSystemKubernetesClusterUrl =
        () => "/api/console/kubernetes/deleteSystemKubernetesCluster";

    public static deleteSystemKubernetesCluster(request: Contract.KubernetesControllerDeleteSystemKubernetesClusterRequest): Promise<void> {
        return ApiController.postRequest<Contract.KubernetesControllerDeleteSystemKubernetesClusterRequest, void>(
            KubernetesController.deleteSystemKubernetesClusterUrl(),
            request);
    }


    public static getKubernetesControllerGetKubernetesClusterAdmissionControllerEventFilterItemPageUrl =
        () => "/api/console/kubernetes/getKubernetesControllerGetKubernetesClusterAdmissionControllerEventFilterItemPage";

    public static getKubernetesControllerGetKubernetesClusterAdmissionControllerEventFilterItemPage(request: Contract.KubernetesControllerGetKubernetesClusterAdmissionControllerEventFilterItemPageRequest): Promise<Contract.KubernetesControllerGetKubernetesClusterAdmissionControllerEventFilterItemPageResponseBase> {
        return ApiController.postRequest<Contract.KubernetesControllerGetKubernetesClusterAdmissionControllerEventFilterItemPageRequest, Contract.KubernetesControllerGetKubernetesClusterAdmissionControllerEventFilterItemPageResponseBase>(
            KubernetesController.getKubernetesControllerGetKubernetesClusterAdmissionControllerEventFilterItemPageUrl(),
            request);
    }


    public static getKubernetesClusterAdmissionControllerEventFiltersUrl =
        () => "/api/console/kubernetes/getKubernetesClusterAdmissionControllerEventFilters";

    public static getKubernetesClusterAdmissionControllerEventFilters(request: Contract.KubernetesControllerGetKubernetesClusterAdmissionControllerEventFiltersRequest): Promise<Contract.KubernetesControllerGetKubernetesClusterAdmissionControllerEventFiltersResponse> {
        return ApiController.postRequest<any, Contract.KubernetesControllerGetKubernetesClusterAdmissionControllerEventFiltersResponse>(
            KubernetesController.getKubernetesClusterAdmissionControllerEventFiltersUrl(),
            {});
    }


    public static getKubernetesClusterAdmissionControllerEventModelPageUrl =
        () => "/api/console/kubernetes/getKubernetesClusterAdmissionControllerEventModelPage";

    public static getKubernetesClusterAdmissionControllerEventModelPage(request: Contract.KubernetesControllerGetKubernetesClusterAdmissionControllerEventModelPageRequest): Promise<Contract.KubernetesControllerGetKubernetesClusterAdmissionControllerEventModelPageResponse> {
        return ApiController.postRequest<Contract.KubernetesControllerGetKubernetesClusterAdmissionControllerEventModelPageRequest, Contract.KubernetesControllerGetKubernetesClusterAdmissionControllerEventModelPageResponse>(
            KubernetesController.getKubernetesClusterAdmissionControllerEventModelPageUrl(),
            request);
    }


    public static getKubernetesClusterAdmissionControllerEventModelsUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/kubernetes/getKubernetesClusterAdmissionControllerEventModels/{typeName}", { typeName });

    public static getKubernetesClusterAdmissionControllerEventModels(request: Contract.KubernetesControllerGetKubernetesClusterAdmissionControllerEventModelRequest): Promise<Contract.KubernetesControllerGetKubernetesClusterAdmissionControllerEventModelResponse> {
        return ApiController.postRequest<Contract.KubernetesControllerGetKubernetesClusterAdmissionControllerEventModelRequest, Contract.KubernetesControllerGetKubernetesClusterAdmissionControllerEventModelResponse>(
            KubernetesController.getKubernetesClusterAdmissionControllerEventModelsUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getKubernetesClusterOnboardingInfoUrl =
        () => "/api/console/kubernetes/getKubernetesClusterOnboardingInfo";

    public static getKubernetesClusterOnboardingInfo(request: Contract.KubernetesControllerGetKubernetesClusterOnboardingInfoRequest): Promise<Contract.KubernetesControllerGetKubernetesClusterOnboardingInfoResponse> {
        return ApiController.postRequest<Contract.KubernetesControllerGetKubernetesClusterOnboardingInfoRequest, Contract.KubernetesControllerGetKubernetesClusterOnboardingInfoResponse>(
            KubernetesController.getKubernetesClusterOnboardingInfoUrl(),
            request);
    }


    public static getKubernetesControllerGetSystemKubernetesClusterFilterItemPageUrl =
        () => "/api/console/kubernetes/getKubernetesControllerGetSystemKubernetesClusterFilterItemPage";

    public static getKubernetesControllerGetSystemKubernetesClusterFilterItemPage(request: Contract.KubernetesControllerGetSystemKubernetesClusterFilterItemPageRequest): Promise<Contract.KubernetesControllerGetSystemKubernetesClusterFilterItemPageResponseBase> {
        return ApiController.postRequest<Contract.KubernetesControllerGetSystemKubernetesClusterFilterItemPageRequest, Contract.KubernetesControllerGetSystemKubernetesClusterFilterItemPageResponseBase>(
            KubernetesController.getKubernetesControllerGetSystemKubernetesClusterFilterItemPageUrl(),
            request);
    }


    public static getSystemKubernetesClusterFiltersUrl =
        () => "/api/console/kubernetes/getSystemKubernetesClusterFilters";

    public static getSystemKubernetesClusterFilters(request: Contract.KubernetesControllerGetSystemKubernetesClusterFiltersRequest): Promise<Contract.KubernetesControllerGetSystemKubernetesClusterFiltersResponse> {
        return ApiController.postRequest<Contract.KubernetesControllerGetSystemKubernetesClusterFiltersRequest, Contract.KubernetesControllerGetSystemKubernetesClusterFiltersResponse>(
            KubernetesController.getSystemKubernetesClusterFiltersUrl(),
            request);
    }


    public static getSystemKubernetesClusterModelPageUrl =
        () => "/api/console/kubernetes/getSystemKubernetesClusterModelPage";

    public static getSystemKubernetesClusterModelPage(request: Contract.KubernetesControllerGetSystemKubernetesClusterModelPageRequest): Promise<Contract.KubernetesControllerGetSystemKubernetesClusterModelPageResponse> {
        return ApiController.postRequest<Contract.KubernetesControllerGetSystemKubernetesClusterModelPageRequest, Contract.KubernetesControllerGetSystemKubernetesClusterModelPageResponse>(
            KubernetesController.getSystemKubernetesClusterModelPageUrl(),
            request);
    }
}

export class LocalizationController extends ApiController {
    public static getLocalizationUrl =
        (language: Optional<string>) => ApiController.formatUrlParameters("/api/console/localization/getLocalization", { language });

    public static getLocalization(language: Optional<string>): Promise<string> {
        return ApiController.getRequest<string>(LocalizationController.getLocalizationUrl(language));
    }
}

export class PermissionManagementController extends ApiController {
    public static getAadTenantOnboardingInfoUrl =
        () => "/api/console/permissionManagement/getAadTenantOnboardingInfo";

    public static getAadTenantOnboardingInfo(): Promise<Contract.PermissionManagementControllerGetAadTenantOnboardingInfoResponse> {
        return ApiController.postRequest<any, Contract.PermissionManagementControllerGetAadTenantOnboardingInfoResponse>(
            PermissionManagementController.getAadTenantOnboardingInfoUrl(),
            {});
    }


    public static getInfoUrl =
        () => "/api/console/permissionManagement/getInfo";

    public static getInfo(): Promise<Contract.PermissionManagementControllerGetInfoResponse> {
        return ApiController.postRequest<any, Contract.PermissionManagementControllerGetInfoResponse>(
            PermissionManagementController.getInfoUrl(),
            {});
    }


    public static getPermissionsEligibilityFiltersUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/permissionManagement/getPermissionsEligibilityFilters/{typeName}", { typeName });

    public static getPermissionsEligibilityFilters(request: Contract.PermissionManagementControllerGetPermissionEligibilityFiltersRequest): Promise<Contract.PermissionManagementControllerGetPermissionEligibilityFiltersResponse> {
        return ApiController.postRequest<Contract.PermissionManagementControllerGetPermissionEligibilityFiltersRequest, Contract.PermissionManagementControllerGetPermissionEligibilityFiltersResponse>(
            PermissionManagementController.getPermissionsEligibilityFiltersUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getPermissionsEligibilityModelPageUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/permissionManagement/getPermissionsEligibilityModelPage/{typeName}", { typeName });

    public static getPermissionsEligibilityModelPage(request: Contract.PermissionManagementControllerGetPermissionEligibilityModelPageRequest): Promise<Contract.PermissionManagementControllerGetPermissionEligibilityModelPageResponse> {
        return ApiController.postRequest<Contract.PermissionManagementControllerGetPermissionEligibilityModelPageRequest, Contract.PermissionManagementControllerGetPermissionEligibilityModelPageResponse>(
            PermissionManagementController.getPermissionsEligibilityModelPageUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getPermissionEligibilityPrincipalOverlapUrl =
        () => "/api/console/permissionManagement/getPermissionEligibilityPrincipalOverlap";

    public static getPermissionEligibilityPrincipalOverlap(request: Contract.PermissionManagementControllerGetPermissionEligibilityPrincipalOverlapRequest): Promise<Contract.PermissionManagementControllerGetPermissionEligibilityPrincipalOverlapResponse> {
        return ApiController.postRequest<Contract.PermissionManagementControllerGetPermissionEligibilityPrincipalOverlapRequest, Contract.PermissionManagementControllerGetPermissionEligibilityPrincipalOverlapResponse>(
            PermissionManagementController.getPermissionEligibilityPrincipalOverlapUrl(),
            request);
    }


    public static getPermissionEligibilityTenantIdsUrl =
        () => "/api/console/permissionManagement/getPermissionEligibilityTenantIds";

    public static getPermissionEligibilityTenantIds(request: Contract.PermissionManagementControllerGetPermissionEligibilityTenantIdsRequest): Promise<Contract.PermissionManagementControllerGetPermissionEligibilityTenantIdsResponse> {
        return ApiController.postRequest<Contract.PermissionManagementControllerGetPermissionEligibilityTenantIdsRequest, Contract.PermissionManagementControllerGetPermissionEligibilityTenantIdsResponse>(
            PermissionManagementController.getPermissionEligibilityTenantIdsUrl(),
            request);
    }


    public static getUserInfoUrl =
        () => "/api/console/permissionManagement/getUserInfo";

    public static getUserInfo(): Promise<Contract.PermissionManagementControllerGetUserInfoResponse> {
        return ApiController.postRequest<any, Contract.PermissionManagementControllerGetUserInfoResponse>(
            PermissionManagementController.getUserInfoUrl(),
            {});
    }


    public static getUserPermissionEligibilityIdsUrl =
        () => "/api/console/permissionManagement/getUserPermissionEligibilityIds";

    public static getUserPermissionEligibilityIds(): Promise<Contract.PermissionManagementControllerGetUserPermissionEligibilityIdsResponse> {
        return ApiController.postRequest<any, Contract.PermissionManagementControllerGetUserPermissionEligibilityIdsResponse>(
            PermissionManagementController.getUserPermissionEligibilityIdsUrl(),
            {});
    }


    public static getUserPrincipalsUrl =
        () => "/api/console/permissionManagement/getUserPrincipals";

    public static getUserPrincipals(): Promise<Contract.PermissionManagementControllerGetUserPrincipalsResponse> {
        return ApiController.postRequest<any, Contract.PermissionManagementControllerGetUserPrincipalsResponse>(
            PermissionManagementController.getUserPrincipalsUrl(),
            {});
    }


    public static validateAwsIamPolicyDocumentUrl =
        () => "/api/console/permissionManagement/validateAwsIamPolicyDocument";

    public static validateAwsIamPolicyDocument(request: Contract.PermissionManagementControllerValidateAwsIamPolicyDocumentRequest): Promise<Contract.PermissionManagementControllerValidateAwsIamPolicyDocumentResponse> {
        return ApiController.postRequest<Contract.PermissionManagementControllerValidateAwsIamPolicyDocumentRequest, Contract.PermissionManagementControllerValidateAwsIamPolicyDocumentResponse>(
            PermissionManagementController.validateAwsIamPolicyDocumentUrl(),
            request);
    }


    public static approvePermissionRequestUrl =
        () => "/api/console/permissionManagement/approvePermissionRequest";

    public static approvePermissionRequest(request: Contract.PermissionManagementControllerApprovePermissionRequestRequest): Promise<void> {
        return ApiController.postRequest<Contract.PermissionManagementControllerApprovePermissionRequestRequest, void>(
            PermissionManagementController.approvePermissionRequestUrl(),
            request);
    }


    public static cancelPermissionRequestUrl =
        () => "/api/console/permissionManagement/cancelPermissionRequest";

    public static cancelPermissionRequest(request: Contract.PermissionManagementControllerCancelPermissionRequestRequest): Promise<void> {
        return ApiController.postRequest<Contract.PermissionManagementControllerCancelPermissionRequestRequest, void>(
            PermissionManagementController.cancelPermissionRequestUrl(),
            request);
    }


    public static getActivePermissionRequestPrincipalTenantIdToConsoleUrlMapUrl =
        () => "/api/console/permissionManagement/getActivePermissionRequestPrincipalTenantIdToConsoleUrlMap";

    public static getActivePermissionRequestPrincipalTenantIdToConsoleUrlMap(): Promise<Contract.PermissionManagementControllerGetActivePermissionRequestPrincipalTenantIdToConsoleUrlMapResponse> {
        return ApiController.postRequest<any, Contract.PermissionManagementControllerGetActivePermissionRequestPrincipalTenantIdToConsoleUrlMapResponse>(
            PermissionManagementController.getActivePermissionRequestPrincipalTenantIdToConsoleUrlMapUrl(),
            {});
    }


    public static getApproverUserPermissionRequestFiltersUrl =
        () => "/api/console/permissionManagement/getApproverUserPermissionRequestFilters";

    public static getApproverUserPermissionRequestFilters(): Promise<Contract.PermissionManagementControllerGetApproverUserPermissionRequestFiltersResponse> {
        return ApiController.postRequest<any, Contract.PermissionManagementControllerGetApproverUserPermissionRequestFiltersResponse>(
            PermissionManagementController.getApproverUserPermissionRequestFiltersUrl(),
            {});
    }


    public static getApproverUserPermissionRequestModelPageUrl =
        () => "/api/console/permissionManagement/getApproverUserPermissionRequestModelPage";

    public static getApproverUserPermissionRequestModelPage(request: Contract.PermissionManagementControllerGetApproverUserPermissionRequestModelPageRequest): Promise<Contract.PermissionManagementControllerGetApproverUserPermissionRequestModelPageResponse> {
        return ApiController.postRequest<Contract.PermissionManagementControllerGetApproverUserPermissionRequestModelPageRequest, Contract.PermissionManagementControllerGetApproverUserPermissionRequestModelPageResponse>(
            PermissionManagementController.getApproverUserPermissionRequestModelPageUrl(),
            request);
    }


    public static getGranteeUserPermissionRequestFiltersUrl =
        () => "/api/console/permissionManagement/getGranteeUserPermissionRequestFilters";

    public static getGranteeUserPermissionRequestFilters(): Promise<Contract.PermissionManagementControllerGetGranteeUserPermissionRequestFiltersResponse> {
        return ApiController.postRequest<any, Contract.PermissionManagementControllerGetGranteeUserPermissionRequestFiltersResponse>(
            PermissionManagementController.getGranteeUserPermissionRequestFiltersUrl(),
            {});
    }


    public static getGranteeUserPermissionRequestModelPageUrl =
        () => "/api/console/permissionManagement/getGranteeUserPermissionRequestModelPage";

    public static getGranteeUserPermissionRequestModelPage(request: Contract.PermissionManagementControllerGetGranteeUserPermissionRequestModelPageRequest): Promise<Contract.PermissionManagementControllerGetGranteeUserPermissionRequestModelPageResponse> {
        return ApiController.postRequest<Contract.PermissionManagementControllerGetGranteeUserPermissionRequestModelPageRequest, Contract.PermissionManagementControllerGetGranteeUserPermissionRequestModelPageResponse>(
            PermissionManagementController.getGranteeUserPermissionRequestModelPageUrl(),
            request);
    }


    public static insertPermissionRequestUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/permissionManagement/insertPermissionRequest/{typeName}", { typeName });

    public static insertPermissionRequest(request: Contract.PermissionManagementControllerInsertPermissionRequestRequest): Promise<Contract.PermissionManagementControllerInsertPermissionRequestResponse> {
        return ApiController.postRequest<Contract.PermissionManagementControllerInsertPermissionRequestRequest, Contract.PermissionManagementControllerInsertPermissionRequestResponse>(
            PermissionManagementController.insertPermissionRequestUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static rejectPermissionRequestUrl =
        () => "/api/console/permissionManagement/rejectPermissionRequest";

    public static rejectPermissionRequest(request: Contract.PermissionManagementControllerRejectPermissionRequestRequest): Promise<void> {
        return ApiController.postRequest<Contract.PermissionManagementControllerRejectPermissionRequestRequest, void>(
            PermissionManagementController.rejectPermissionRequestUrl(),
            request);
    }


    public static revokePermissionRequestUrl =
        () => "/api/console/permissionManagement/revokePermissionRequest";

    public static revokePermissionRequest(request: Contract.PermissionManagementControllerRevokePermissionRequestRequest): Promise<void> {
        return ApiController.postRequest<Contract.PermissionManagementControllerRevokePermissionRequestRequest, void>(
            PermissionManagementController.revokePermissionRequestUrl(),
            request);
    }
}

export class ReportController extends ApiController {
    public static deleteReportUrl =
        () => "/api/console/report/deleteReport";

    public static deleteReport(request: Contract.ReportControllerDeleteReportRequest): Promise<void> {
        return ApiController.postRequest<Contract.ReportControllerDeleteReportRequest, void>(
            ReportController.deleteReportUrl(),
            request);
    }


    public static generateExportReportUrl =
        (reportDefinitionId: string, scopeId: string) => ApiController.formatUrlParameters("/api/console/report/generateExportReport", { reportDefinitionId, scopeId });

    public static generateExportReport(reportDefinitionId: string, scopeId: string): Promise<void> {
        return ApiController.getRequest<void>(ReportController.generateExportReportUrl(reportDefinitionId, scopeId));
    }


    public static generateReportUrl =
        () => "/api/console/report/generateReport";

    public static generateReport(request: Contract.ReportControllerGenerateReportRequest): Promise<Contract.ReportControllerGenerateReportResponse> {
        return ApiController.postRequest<Contract.ReportControllerGenerateReportRequest, Contract.ReportControllerGenerateReportResponse>(
            ReportController.generateReportUrl(),
            request);
    }


    public static getDashboardReportUrl =
        () => "/api/console/report/getDashboardReport";

    public static getDashboardReport(request: Contract.ReportControllerGetDashboardReportRequest): Promise<Contract.ReportControllerGetDashboardReportResponse> {
        return ApiController.postRequest<Contract.ReportControllerGetDashboardReportRequest, Contract.ReportControllerGetDashboardReportResponse>(
            ReportController.getDashboardReportUrl(),
            request);
    }


    public static getReportFileUrl =
        (id: string, inline: boolean) => ApiController.formatUrlParameters("/api/console/report/getReportFile/{id}", { id, inline });

    public static getReportFile(id: string, inline: boolean): Promise<string> {
        return ApiController.getRequest<string>(ReportController.getReportFileUrl(id, inline));
    }


    public static getReportFiltersUrl =
        () => "/api/console/report/getReportFilters";

    public static getReportFilters(request: Contract.ReportControllerGetReportFiltersRequest): Promise<Contract.ReportControllerGetReportFiltersResponse> {
        return ApiController.postRequest<Contract.ReportControllerGetReportFiltersRequest, Contract.ReportControllerGetReportFiltersResponse>(
            ReportController.getReportFiltersUrl(),
            request);
    }


    public static getReportPageUrl =
        () => "/api/console/report/getReportPage";

    public static getReportPage(request: Contract.ReportControllerGetReportPageRequest): Promise<Contract.ReportControllerGetReportPageResponse> {
        return ApiController.postRequest<Contract.ReportControllerGetReportPageRequest, Contract.ReportControllerGetReportPageResponse>(
            ReportController.getReportPageUrl(),
            request);
    }


    public static insertExportReportDefinitionUrl =
        () => "/api/console/report/insertExportReportDefinition";

    public static insertExportReportDefinition(request: Contract.ReportControllerInsertExportReportDefinitionRequest): Promise<Contract.ReportControllerInsertExportReportDefinitionResponse> {
        return ApiController.postRequest<Contract.ReportControllerInsertExportReportDefinitionRequest, Contract.ReportControllerInsertExportReportDefinitionResponse>(
            ReportController.insertExportReportDefinitionUrl(),
            request);
    }
}

export class RiskController extends ApiController {
    public static deleteCustomRiskPolicyUrl =
        () => "/api/console/risk/deleteCustomRiskPolicy";

    public static deleteCustomRiskPolicy(request: Contract.RiskControllerDeleteCustomRiskPolicyRequest): Promise<void> {
        return ApiController.postRequest<Contract.RiskControllerDeleteCustomRiskPolicyRequest, void>(
            RiskController.deleteCustomRiskPolicyUrl(),
            request);
    }


    public static excludeEntityUrl =
        () => "/api/console/risk/excludeEntity";

    public static excludeEntity(request: Contract.RiskControllerExcludeEntityRequest): Promise<void> {
        return ApiController.postRequest<Contract.RiskControllerExcludeEntityRequest, void>(
            RiskController.excludeEntityUrl(),
            request);
    }


    public static getBuiltInComplianceDatasUrl =
        () => "/api/console/risk/getBuiltInComplianceDatas";

    public static getBuiltInComplianceDatas(): Promise<Contract.RiskControllerGetBuiltInComplianceDatasResponse> {
        return ApiController.postRequest<any, Contract.RiskControllerGetBuiltInComplianceDatasResponse>(
            RiskController.getBuiltInComplianceDatasUrl(),
            {});
    }


    public static getBuiltInComplianceTypeToAnalysisGroupTypeToRiskPolicyConfigurationTypeNamesMapUrl =
        () => "/api/console/risk/getBuiltInComplianceTypeToAnalysisGroupTypeToRiskPolicyConfigurationTypeNamesMap";

    public static getBuiltInComplianceTypeToAnalysisGroupTypeToRiskPolicyConfigurationTypeNamesMap(): Promise<Contract.RiskControllerGetBuiltInComplianceTypeToAnalysisGroupTypeToRiskPolicyConfigurationTypeNamesMapResponse> {
        return ApiController.postRequest<any, Contract.RiskControllerGetBuiltInComplianceTypeToAnalysisGroupTypeToRiskPolicyConfigurationTypeNamesMapResponse>(
            RiskController.getBuiltInComplianceTypeToAnalysisGroupTypeToRiskPolicyConfigurationTypeNamesMapUrl(),
            {});
    }


    public static getBuiltInRiskPolicyModelsUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/risk/getBuiltInRiskPolicyModels/{typeName}", { typeName });

    public static getBuiltInRiskPolicyModels(request: Contract.RiskControllerGetBuiltInRiskPolicyModelsRequest): Promise<Contract.RiskControllerGetBuiltInRiskPolicyModelsResponse> {
        return ApiController.postRequest<Contract.RiskControllerGetBuiltInRiskPolicyModelsRequest, Contract.RiskControllerGetBuiltInRiskPolicyModelsResponse>(
            RiskController.getBuiltInRiskPolicyModelsUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getComplianceSectionDatasUrl =
        () => "/api/console/risk/getComplianceSectionDatas";

    public static getComplianceSectionDatas(request: Contract.RiskControllerGetComplianceSectionDatasRequest): Promise<Contract.RiskControllerGetComplianceSectionDatasResponse> {
        return ApiController.postRequest<Contract.RiskControllerGetComplianceSectionDatasRequest, Contract.RiskControllerGetComplianceSectionDatasResponse>(
            RiskController.getComplianceSectionDatasUrl(),
            request);
    }


    public static getCustomRiskEntityMatchConditionsMatchEntityModelCountUrl =
        () => "/api/console/risk/getCustomRiskEntityMatchConditionsMatchEntityModelCount";

    public static getCustomRiskEntityMatchConditionsMatchEntityModelCount(request: Contract.RiskControllerGetCustomRiskEntityMatchConditionsMatchEntityModelCountRequest): Promise<Contract.RiskControllerGetCustomRiskEntityMatchConditionsMatchEntityModelCountResponse> {
        return ApiController.postRequest<Contract.RiskControllerGetCustomRiskEntityMatchConditionsMatchEntityModelCountRequest, Contract.RiskControllerGetCustomRiskEntityMatchConditionsMatchEntityModelCountResponse>(
            RiskController.getCustomRiskEntityMatchConditionsMatchEntityModelCountUrl(),
            request);
    }


    public static getCustomRiskEntityMatchConditionsMatchEntityModelPageUrl =
        () => "/api/console/risk/getCustomRiskEntityMatchConditionsMatchEntityModelPage";

    public static getCustomRiskEntityMatchConditionsMatchEntityModelPage(request: Contract.RiskControllerGetCustomRiskEntityMatchConditionsMatchEntityModelPageRequest): Promise<Contract.RiskControllerGetCustomRiskEntityMatchConditionsMatchEntityModelPageResponse> {
        return ApiController.postRequest<Contract.RiskControllerGetCustomRiskEntityMatchConditionsMatchEntityModelPageRequest, Contract.RiskControllerGetCustomRiskEntityMatchConditionsMatchEntityModelPageResponse>(
            RiskController.getCustomRiskEntityMatchConditionsMatchEntityModelPageUrl(),
            request);
    }


    public static getDeletedRiskUrl =
        () => "/api/console/risk/getDeletedRisk";

    public static getDeletedRisk(request: Contract.RiskControllerGetDeletedRiskRequest): Promise<Contract.RiskControllerGetDeletedRiskResponse> {
        return ApiController.postRequest<Contract.RiskControllerGetDeletedRiskRequest, Contract.RiskControllerGetDeletedRiskResponse>(
            RiskController.getDeletedRiskUrl(),
            request);
    }


    public static getKubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToLastViolationEventTimeMapUrl =
        () => "/api/console/risk/getKubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToLastViolationEventTimeMap";

    public static getKubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToLastViolationEventTimeMap(request: Contract.RiskControllerGetKubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToLastViolationEventTimeMapRequest): Promise<Contract.RiskControllerGetKubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToLastViolationEventTimeMapResponse> {
        return ApiController.postRequest<Contract.RiskControllerGetKubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToLastViolationEventTimeMapRequest, Contract.RiskControllerGetKubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToLastViolationEventTimeMapResponse>(
            RiskController.getKubernetesAdmissionControllerRiskPolicyConfigurationTypeNameToLastViolationEventTimeMapUrl(),
            request);
    }


    public static getRiskChangeModelsUrl =
        () => "/api/console/risk/getRiskChangeModels";

    public static getRiskChangeModels(request: Contract.RiskControllerGetRiskChangeModelsRequest): Promise<Contract.RiskControllerGetRiskChangeModelsResponse> {
        return ApiController.postRequest<Contract.RiskControllerGetRiskChangeModelsRequest, Contract.RiskControllerGetRiskChangeModelsResponse>(
            RiskController.getRiskChangeModelsUrl(),
            request);
    }


    public static getRiskCodeFileDiffsUrl =
        () => "/api/console/risk/getRiskCodeFileDiffs";

    public static getRiskCodeFileDiffs(request: Contract.RiskControllerGetRiskCodeFileDiffsRequest): Promise<Contract.RiskControllerGetRiskCodeFileDiffsResponse> {
        return ApiController.postRequest<Contract.RiskControllerGetRiskCodeFileDiffsRequest, Contract.RiskControllerGetRiskCodeFileDiffsResponse>(
            RiskController.getRiskCodeFileDiffsUrl(),
            request);
    }


    public static getRiskExcludedEntityIdsUrl =
        () => "/api/console/risk/getRiskExcludedEntityIds";

    public static getRiskExcludedEntityIds(request: Contract.RiskControllerGetRiskExcludedEntityIdsRequest): Promise<Contract.RiskControllerGetRiskExcludedEntityIdsResponse> {
        return ApiController.postRequest<Contract.RiskControllerGetRiskExcludedEntityIdsRequest, Contract.RiskControllerGetRiskExcludedEntityIdsResponse>(
            RiskController.getRiskExcludedEntityIdsUrl(),
            request);
    }


    public static getRiskFilterItemPageUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/risk/getRiskFilterItemPage/{typeName}", { typeName });

    public static getRiskFilterItemPage(request: Contract.RiskControllerGetRiskFilterItemPageRequest): Promise<Contract.RiskControllerGetRiskFilterItemPageResponseBase> {
        return ApiController.postRequest<Contract.RiskControllerGetRiskFilterItemPageRequest, Contract.RiskControllerGetRiskFilterItemPageResponseBase>(
            RiskController.getRiskFilterItemPageUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getRiskFiltersUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/risk/getRiskFilters/{typeName}", { typeName });

    public static getRiskFilters(request: Contract.RiskControllerGetRiskFiltersRequest): Promise<Contract.RiskControllerGetRiskFiltersResponse> {
        return ApiController.postRequest<Contract.RiskControllerGetRiskFiltersRequest, Contract.RiskControllerGetRiskFiltersResponse>(
            RiskController.getRiskFiltersUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getRiskPolicyTypeGroupFilterItemPageUrl =
        () => "/api/console/risk/getRiskPolicyTypeGroupFilterItemPage";

    public static getRiskPolicyTypeGroupFilterItemPage(request: Contract.RiskControllerGetRiskPolicyTypeGroupFilterItemPageRequest): Promise<Contract.RiskControllerGetRiskPolicyTypeGroupFilterItemPageResponseBase> {
        return ApiController.postRequest<Contract.RiskControllerGetRiskPolicyTypeGroupFilterItemPageRequest, Contract.RiskControllerGetRiskPolicyTypeGroupFilterItemPageResponseBase>(
            RiskController.getRiskPolicyTypeGroupFilterItemPageUrl(),
            request);
    }


    public static getRiskGroupsUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/risk/getRiskGroups/{typeName}", { typeName });

    public static getRiskGroups(request: Contract.RiskControllerGetRiskGroupsRequest): Promise<Contract.RiskControllerGetRiskGroupsResponse> {
        return ApiController.postRequest<Contract.RiskControllerGetRiskGroupsRequest, Contract.RiskControllerGetRiskGroupsResponse>(
            RiskController.getRiskGroupsUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getRiskIdToDataMapUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/risk/getRiskIdToDataMap/{typeName}", { typeName });

    public static getRiskIdToDataMap(request: Contract.RiskControllerGetRiskIdToDataMapRequest): Promise<Contract.RiskControllerGetRiskIdToDataMapResponse> {
        return ApiController.postRequest<Contract.RiskControllerGetRiskIdToDataMapRequest, Contract.RiskControllerGetRiskIdToDataMapResponse>(
            RiskController.getRiskIdToDataMapUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getRiskIdToJiraIssuesMapUrl =
        () => "/api/console/risk/getRiskIdToJiraIssuesMap";

    public static getRiskIdToJiraIssuesMap(request: Contract.RiskControllerGetRiskIdToJiraIssuesMapRequest): Promise<Contract.RiskControllerGetRiskIdToJiraIssuesMapResponse> {
        return ApiController.postRequest<Contract.RiskControllerGetRiskIdToJiraIssuesMapRequest, Contract.RiskControllerGetRiskIdToJiraIssuesMapResponse>(
            RiskController.getRiskIdToJiraIssuesMapUrl(),
            request);
    }


    public static getRiskIdToScopeIdentityPermissionsMapUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/risk/getRiskIdToScopeIdentityPermissionsMap/{typeName}", { typeName });

    public static getRiskIdToScopeIdentityPermissionsMap(request: Contract.RiskControllerGetRiskIdToScopeIdentityPermissionsMapRequest): Promise<Contract.RiskControllerGetRiskIdToScopeIdentityPermissionsMapResponse> {
        return ApiController.postRequest<Contract.RiskControllerGetRiskIdToScopeIdentityPermissionsMapRequest, Contract.RiskControllerGetRiskIdToScopeIdentityPermissionsMapResponse>(
            RiskController.getRiskIdToScopeIdentityPermissionsMapUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getRiskIdToServiceNowIncidentsMapUrl =
        () => "/api/console/risk/getRiskIdToServiceNowIncidentsMap";

    public static getRiskIdToServiceNowIncidentsMap(request: Contract.RiskControllerGetRiskIdToServiceNowIncidentsMapRequest): Promise<Contract.RiskControllerGetRiskIdToServiceNowIncidentsMapResponse> {
        return ApiController.postRequest<Contract.RiskControllerGetRiskIdToServiceNowIncidentsMapRequest, Contract.RiskControllerGetRiskIdToServiceNowIncidentsMapResponse>(
            RiskController.getRiskIdToServiceNowIncidentsMapUrl(),
            request);
    }


    public static getRiskModelUrl =
        () => "/api/console/risk/getRiskModel";

    public static getRiskModel(request: Contract.RiskControllerGetRiskModelRequest): Promise<Contract.RiskControllerGetRiskModelResponse> {
        return ApiController.postRequest<Contract.RiskControllerGetRiskModelRequest, Contract.RiskControllerGetRiskModelResponse>(
            RiskController.getRiskModelUrl(),
            request);
    }


    public static getRiskModelPageUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/risk/getRiskModelPage/{typeName}", { typeName });

    public static getRiskModelPage(request: Contract.RiskControllerGetRiskModelPageRequest): Promise<Contract.RiskControllerGetRiskModelPageResponse> {
        return ApiController.postRequest<Contract.RiskControllerGetRiskModelPageRequest, Contract.RiskControllerGetRiskModelPageResponse>(
            RiskController.getRiskModelPageUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getRiskModelsUrl =
        () => "/api/console/risk/getRiskModels";

    public static getRiskModels(request: Contract.RiskControllerGetRiskModelsRequest): Promise<Contract.RiskControllerGetRiskModelsResponse> {
        return ApiController.postRequest<Contract.RiskControllerGetRiskModelsRequest, Contract.RiskControllerGetRiskModelsResponse>(
            RiskController.getRiskModelsUrl(),
            request);
    }


    public static getRiskPoliciesUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/risk/getRiskPolicies/{typeName}", { typeName });

    public static getRiskPolicies(request: Contract.RiskControllerGetRiskPoliciesRequest): Promise<Contract.RiskControllerGetRiskPoliciesResponse> {
        return ApiController.postRequest<Contract.RiskControllerGetRiskPoliciesRequest, Contract.RiskControllerGetRiskPoliciesResponse>(
            RiskController.getRiskPoliciesUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getRiskPolicyAnalyzedEntityCountUrl =
        () => "/api/console/risk/getRiskPolicyAnalyzedEntityCount";

    public static getRiskPolicyAnalyzedEntityCount(request: Contract.RiskControllerGetRiskPolicyAnalyzedEntityCountRequest): Promise<Contract.RiskControllerGetRiskPolicyAnalyzedEntityCountResponse> {
        return ApiController.postRequest<Contract.RiskControllerGetRiskPolicyAnalyzedEntityCountRequest, Contract.RiskControllerGetRiskPolicyAnalyzedEntityCountResponse>(
            RiskController.getRiskPolicyAnalyzedEntityCountUrl(),
            request);
    }


    public static getRiskPolicyExcludedEntityIdsUrl =
        () => "/api/console/risk/getRiskPolicyExcludedEntityIds";

    public static getRiskPolicyExcludedEntityIds(request: Contract.RiskControllerGetRiskPolicyExcludedEntityIdsRequest): Promise<Contract.RiskControllerGetRiskPolicyExcludedEntityIdsResponse> {
        return ApiController.postRequest<Contract.RiskControllerGetRiskPolicyExcludedEntityIdsRequest, Contract.RiskControllerGetRiskPolicyExcludedEntityIdsResponse>(
            RiskController.getRiskPolicyExcludedEntityIdsUrl(),
            request);
    }


    public static getRiskPolicyFailedEntityCountUrl =
        () => "/api/console/risk/getRiskPolicyFailedEntityCount";

    public static getRiskPolicyFailedEntityCount(request: Contract.RiskControllerGetRiskPolicyFailedEntityCountRequest): Promise<Contract.RiskControllerGetRiskPolicyFailedEntityCountResponse> {
        return ApiController.postRequest<Contract.RiskControllerGetRiskPolicyFailedEntityCountRequest, Contract.RiskControllerGetRiskPolicyFailedEntityCountResponse>(
            RiskController.getRiskPolicyFailedEntityCountUrl(),
            request);
    }


    public static getRiskPolicyFailedEntityIdsUrl =
        () => "/api/console/risk/getRiskPolicyFailedEntityIds";

    public static getRiskPolicyFailedEntityIds(request: Contract.RiskControllerGetRiskPolicyFailedEntityIdsRequest): Promise<Contract.RiskControllerGetRiskPolicyFailedEntityIdsResponse> {
        return ApiController.postRequest<Contract.RiskControllerGetRiskPolicyFailedEntityIdsRequest, Contract.RiskControllerGetRiskPolicyFailedEntityIdsResponse>(
            RiskController.getRiskPolicyFailedEntityIdsUrl(),
            request);
    }


    public static getRiskPolicyModelsUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/risk/getRiskPolicyModels/{typeName}", { typeName });

    public static getRiskPolicyModels(request: Contract.RiskControllerGetRiskPolicyModelsRequestBase): Promise<Contract.RiskControllerGetRiskPolicyModelsResponse> {
        return ApiController.postRequest<Contract.RiskControllerGetRiskPolicyModelsRequestBase, Contract.RiskControllerGetRiskPolicyModelsResponse>(
            RiskController.getRiskPolicyModelsUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getRiskPolicySummaryUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/risk/getRiskPolicySummary/{typeName}", { typeName });

    public static getRiskPolicySummary(request: Contract.RiskControllerGetRiskPolicySummaryRequest): Promise<Contract.RiskControllerGetRiskPolicySummaryResponse> {
        return ApiController.postRequest<Contract.RiskControllerGetRiskPolicySummaryRequest, Contract.RiskControllerGetRiskPolicySummaryResponse>(
            RiskController.getRiskPolicySummaryUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getRiskPolicyTypeGroupFiltersUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/risk/getRiskPolicyTypeGroupFilters/{typeName}", { typeName });

    public static getRiskPolicyTypeGroupFilters(request: Contract.RiskControllerGetRiskPolicyTypeGroupFiltersRequest): Promise<Contract.RiskControllerGetRiskPolicyTypeGroupFiltersResponse> {
        return ApiController.postRequest<Contract.RiskControllerGetRiskPolicyTypeGroupFiltersRequest, Contract.RiskControllerGetRiskPolicyTypeGroupFiltersResponse>(
            RiskController.getRiskPolicyTypeGroupFiltersUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getRiskPolicyTypeMetadatasUrl =
        () => "/api/console/risk/getRiskPolicyTypeMetadatas";

    public static getRiskPolicyTypeMetadatas(): Promise<Contract.RiskControllerGetRiskPolicyTypeMetadatasResponse> {
        return ApiController.postRequest<any, Contract.RiskControllerGetRiskPolicyTypeMetadatasResponse>(
            RiskController.getRiskPolicyTypeMetadatasUrl(),
            {});
    }


    public static getRiskTypeMetadataModelsUrl =
        () => "/api/console/risk/getRiskTypeMetadataModels";

    public static getRiskTypeMetadataModels(): Promise<Contract.RiskControllerGetRiskTypeMetadataModelsResponse> {
        return ApiController.postRequest<any, Contract.RiskControllerGetRiskTypeMetadataModelsResponse>(
            RiskController.getRiskTypeMetadataModelsUrl(),
            {});
    }


    public static insertCustomRiskPolicyUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/risk/insertCustomRiskPolicy/{typeName}", { typeName });

    public static insertCustomRiskPolicy(request: Contract.RiskControllerInsertCustomRiskPolicyRequest): Promise<Contract.RiskControllerInsertCustomRiskPolicyResponse> {
        return ApiController.postRequest<Contract.RiskControllerInsertCustomRiskPolicyRequest, Contract.RiskControllerInsertCustomRiskPolicyResponse>(
            RiskController.insertCustomRiskPolicyUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static resolveRiskAutomationUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/risk/resolveRiskAutomation/{typeName}", { typeName });

    public static resolveRiskAutomation(request: Contract.RiskControllerResolveRiskAutomationRequest): Promise<Contract.RiskControllerResolveRiskAutomationResponse> {
        return ApiController.postRequest<Contract.RiskControllerResolveRiskAutomationRequest, Contract.RiskControllerResolveRiskAutomationResponse>(
            RiskController.resolveRiskAutomationUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static resolveRiskCodeAutomationUrl =
        () => "/api/console/risk/resolveRiskCodeAutomation";

    public static resolveRiskCodeAutomation(request: Contract.RiskControllerResolveRiskCodeAutomationRequest): Promise<Contract.RiskControllerResolveRiskCodeAutomationResponse> {
        return ApiController.postRequest<Contract.RiskControllerResolveRiskCodeAutomationRequest, Contract.RiskControllerResolveRiskCodeAutomationResponse>(
            RiskController.resolveRiskCodeAutomationUrl(),
            request);
    }


    public static sendRisksMailUrl =
        () => "/api/console/risk/sendRisksMail";

    public static sendRisksMail(request: Contract.RiskControllerSendRisksMailRequest): Promise<void> {
        return ApiController.postRequest<Contract.RiskControllerSendRisksMailRequest, void>(
            RiskController.sendRisksMailUrl(),
            request);
    }


    public static sendRisksWebhookUrl =
        () => "/api/console/risk/sendRisksWebhook";

    public static sendRisksWebhook(request: Contract.RiskControllerSendRisksWebhookRequest): Promise<void> {
        return ApiController.postRequest<Contract.RiskControllerSendRisksWebhookRequest, void>(
            RiskController.sendRisksWebhookUrl(),
            request);
    }


    public static updateBuiltInComplianceEnabledUrl =
        () => "/api/console/risk/updateBuiltInComplianceEnabled";

    public static updateBuiltInComplianceEnabled(request: Contract.RiskControllerUpdateBuiltInComplianceEnabledRequest): Promise<void> {
        return ApiController.postRequest<Contract.RiskControllerUpdateBuiltInComplianceEnabledRequest, void>(
            RiskController.updateBuiltInComplianceEnabledUrl(),
            request);
    }


    public static updateCustomRiskPolicySeverityUrl =
        () => "/api/console/risk/updateCustomRiskPolicySeverity";

    public static updateCustomRiskPolicySeverity(request: Contract.RiskControllerUpdateCustomRiskPolicySeverityRequest): Promise<void> {
        return ApiController.postRequest<Contract.RiskControllerUpdateCustomRiskPolicySeverityRequest, void>(
            RiskController.updateCustomRiskPolicySeverityUrl(),
            request);
    }


    public static updateRiskChangesUrl =
        () => "/api/console/risk/updateRiskChanges";

    public static updateRiskChanges(request: Contract.RiskControllerUpdateRiskChangesRequest): Promise<void> {
        return ApiController.postRequest<Contract.RiskControllerUpdateRiskChangesRequest, void>(
            RiskController.updateRiskChangesUrl(),
            request);
    }


    public static updateRiskPolicyConfigurationUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/risk/updateRiskPolicyConfiguration/{typeName}", { typeName });

    public static updateRiskPolicyConfiguration(request: Contract.RiskControllerUpdateRiskPolicyConfigurationRequest): Promise<void> {
        return ApiController.postRequest<Contract.RiskControllerUpdateRiskPolicyConfigurationRequest, void>(
            RiskController.updateRiskPolicyConfigurationUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static updateRiskStarredUrl =
        () => "/api/console/risk/updateRiskStarred";

    public static updateRiskStarred(request: Contract.RiskControllerUpdateRiskStarredRequest): Promise<void> {
        return ApiController.postRequest<Contract.RiskControllerUpdateRiskStarredRequest, void>(
            RiskController.updateRiskStarredUrl(),
            request);
    }


    public static updateRiskStatusUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/risk/updateRiskStatus/{typeName}", { typeName });

    public static updateRiskStatus(request: Contract.RiskControllerUpdateRiskStatusRequest): Promise<void> {
        return ApiController.postRequest<Contract.RiskControllerUpdateRiskStatusRequest, void>(
            RiskController.updateRiskStatusUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static upsertRiskJiraIssueUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/risk/upsertRiskJiraIssue/{typeName}", { typeName });

    public static upsertRiskJiraIssue(request: Contract.RiskControllerUpsertRiskJiraIssueRequest): Promise<Contract.RiskControllerUpsertRiskJiraIssuesResponse> {
        return ApiController.postRequest<Contract.RiskControllerUpsertRiskJiraIssueRequest, Contract.RiskControllerUpsertRiskJiraIssuesResponse>(
            RiskController.upsertRiskJiraIssueUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static upsertRiskJiraIssuesUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/risk/upsertRiskJiraIssues/{typeName}", { typeName });

    public static upsertRiskJiraIssues(request: Contract.RiskControllerUpsertRiskJiraIssuesRequest): Promise<Contract.RiskControllerUpsertRiskJiraIssuesResponse> {
        return ApiController.postRequest<Contract.RiskControllerUpsertRiskJiraIssuesRequest, Contract.RiskControllerUpsertRiskJiraIssuesResponse>(
            RiskController.upsertRiskJiraIssuesUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static upsertRiskServiceNowIncidentUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/risk/upsertRiskServiceNowIncident/{typeName}", { typeName });

    public static upsertRiskServiceNowIncident(request: Contract.RiskControllerUpsertRiskServiceNowIncidentRequest): Promise<Contract.RiskControllerUpsertRiskServiceNowIncidentsResponse> {
        return ApiController.postRequest<Contract.RiskControllerUpsertRiskServiceNowIncidentRequest, Contract.RiskControllerUpsertRiskServiceNowIncidentsResponse>(
            RiskController.upsertRiskServiceNowIncidentUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static upsertRiskServiceNowIncidentsUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/risk/upsertRiskServiceNowIncidents/{typeName}", { typeName });

    public static upsertRiskServiceNowIncidents(request: Contract.RiskControllerUpsertRiskServiceNowIncidentsRequest): Promise<Contract.RiskControllerUpsertRiskServiceNowIncidentsResponse> {
        return ApiController.postRequest<Contract.RiskControllerUpsertRiskServiceNowIncidentsRequest, Contract.RiskControllerUpsertRiskServiceNowIncidentsResponse>(
            RiskController.upsertRiskServiceNowIncidentsUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }
}

export class ScopeController extends ApiController {
    public static getScopeNodeModelsUrl =
        () => "/api/console/scope/getScopeNodeModels";

    public static getScopeNodeModels(request: Contract.ScopeControllerGetScopeNodeModelsRequest): Promise<Contract.ScopeControllerGetScopeNodeModelsResponse> {
        return ApiController.postRequest<Contract.ScopeControllerGetScopeNodeModelsRequest, Contract.ScopeControllerGetScopeNodeModelsResponse>(
            ScopeController.getScopeNodeModelsUrl(),
            request);
    }


    public static moveScopeUrl =
        () => "/api/console/scope/moveScope";

    public static moveScope(request: Contract.ScopeControllerMoveScopeRequest): Promise<Contract.ScopeControllerMoveScopeResponse> {
        return ApiController.postRequest<Contract.ScopeControllerMoveScopeRequest, Contract.ScopeControllerMoveScopeResponse>(
            ScopeController.moveScopeUrl(),
            request);
    }
}

export class SystemController extends ApiController {
    public static getGeographyInfoUrl =
        () => "/api/console/system/getGeographyInfo";

    public static getGeographyInfo(): Promise<Contract.SystemControllerGetGeographyInfoResponse> {
        return ApiController.postRequest<any, Contract.SystemControllerGetGeographyInfoResponse>(
            SystemController.getGeographyInfoUrl(),
            {});
    }


    public static getInfoUrl =
        () => "/api/console/system/getInfo";

    public static getInfo(): Promise<Contract.SystemControllerGetInfoResponse> {
        return ApiController.postRequest<any, Contract.SystemControllerGetInfoResponse>(
            SystemController.getInfoUrl(),
            {});
    }
}

export class TenantController extends ApiController {
    public static getAwsTenantOnboardingInfoUrl =
        () => "/api/console/tenant/getAwsTenantOnboardingInfo";

    public static getAwsTenantOnboardingInfo(request: Contract.TenantControllerGetAwsTenantOnboardingInfoRequest): Promise<Contract.TenantControllerGetAwsTenantOnboardingInfoResponse> {
        return ApiController.postRequest<Contract.TenantControllerGetAwsTenantOnboardingInfoRequest, Contract.TenantControllerGetAwsTenantOnboardingInfoResponse>(
            TenantController.getAwsTenantOnboardingInfoUrl(),
            request);
    }


    public static getAwsTenantOnboardingPoliciesInfoUrl =
        () => "/api/console/tenant/getAwsTenantOnboardingPoliciesInfo";

    public static getAwsTenantOnboardingPoliciesInfo(request: Contract.TenantControllerGetAwsTenantOnboardingPoliciesInfoRequest): Promise<Contract.TenantControllerGetAwsTenantOnboardingPoliciesInfoResponse> {
        return ApiController.postRequest<Contract.TenantControllerGetAwsTenantOnboardingPoliciesInfoRequest, Contract.TenantControllerGetAwsTenantOnboardingPoliciesInfoResponse>(
            TenantController.getAwsTenantOnboardingPoliciesInfoUrl(),
            request);
    }


    public static insertAwsTenantUrl =
        () => "/api/console/tenant/insertAwsTenant";

    public static insertAwsTenant(request: Contract.TenantControllerInsertAwsTenantRequest): Promise<Contract.TenantControllerInsertAwsTenantResponse> {
        return ApiController.postRequest<Contract.TenantControllerInsertAwsTenantRequest, Contract.TenantControllerInsertAwsTenantResponse>(
            TenantController.insertAwsTenantUrl(),
            request);
    }


    public static updateAwsTenantUrl =
        () => "/api/console/tenant/updateAwsTenant";

    public static updateAwsTenant(request: Contract.TenantControllerUpdateAwsTenantRequest): Promise<Contract.TenantControllerUpdateAwsTenantResponse> {
        return ApiController.postRequest<Contract.TenantControllerUpdateAwsTenantRequest, Contract.TenantControllerUpdateAwsTenantResponse>(
            TenantController.updateAwsTenantUrl(),
            request);
    }


    public static validateAwsAccessKeyUrl =
        () => "/api/console/tenant/validateAwsAccessKey";

    public static validateAwsAccessKey(request: Contract.TenantControllerValidateAwsAccessKeyRequest): Promise<Contract.TenantControllerValidateAwsAccessKeyResponse> {
        return ApiController.postRequest<Contract.TenantControllerValidateAwsAccessKeyRequest, Contract.TenantControllerValidateAwsAccessKeyResponse>(
            TenantController.validateAwsAccessKeyUrl(),
            request);
    }


    public static validateAwsTenantUrl =
        () => "/api/console/tenant/validateAwsTenant";

    public static validateAwsTenant(request: Contract.TenantControllerValidateAwsTenantRequest): Promise<Contract.TenantControllerValidateAwsTenantResponse> {
        return ApiController.postRequest<Contract.TenantControllerValidateAwsTenantRequest, Contract.TenantControllerValidateAwsTenantResponse>(
            TenantController.validateAwsTenantUrl(),
            request);
    }


    public static validateAwsTenantTrailBucketUrl =
        () => "/api/console/tenant/validateAwsTenantTrailBucket";

    public static validateAwsTenantTrailBucket(request: Contract.TenantControllerValidateAwsTenantTrailBucketRequest): Promise<Contract.TenantControllerValidateAwsTenantTrailBucketResponse> {
        return ApiController.postRequest<Contract.TenantControllerValidateAwsTenantTrailBucketRequest, Contract.TenantControllerValidateAwsTenantTrailBucketResponse>(
            TenantController.validateAwsTenantTrailBucketUrl(),
            request);
    }


    public static validateStoredAwsAccessKeyUrl =
        () => "/api/console/tenant/validateStoredAwsAccessKey";

    public static validateStoredAwsAccessKey(request: Contract.TenantControllerValidateStoredAwsAccessKeyRequest): Promise<Contract.TenantControllerValidateStoredAwsAccessKeyResponse> {
        return ApiController.postRequest<Contract.TenantControllerValidateStoredAwsAccessKeyRequest, Contract.TenantControllerValidateStoredAwsAccessKeyResponse>(
            TenantController.validateStoredAwsAccessKeyUrl(),
            request);
    }


    public static getAzureTenantOnboardingInfoUrl =
        () => "/api/console/tenant/getAzureTenantOnboardingInfo";

    public static getAzureTenantOnboardingInfo(): Promise<Contract.TenantControllerGetAzureTenantOnboardingInfoResponse> {
        return ApiController.postRequest<any, Contract.TenantControllerGetAzureTenantOnboardingInfoResponse>(
            TenantController.getAzureTenantOnboardingInfoUrl(),
            {});
    }


    public static insertAzureTenantUrl =
        () => "/api/console/tenant/insertAzureTenant";

    public static insertAzureTenant(request: Contract.TenantControllerInsertAzureTenantRequest): Promise<Contract.TenantControllerInsertAzureTenantResponse> {
        return ApiController.postRequest<Contract.TenantControllerInsertAzureTenantRequest, Contract.TenantControllerInsertAzureTenantResponse>(
            TenantController.insertAzureTenantUrl(),
            request);
    }


    public static updateAzureTenantUrl =
        () => "/api/console/tenant/updateAzureTenant";

    public static updateAzureTenant(request: Contract.TenantControllerUpdateAzureTenantRequest): Promise<Contract.TenantControllerUpdateAzureTenantResponse> {
        return ApiController.postRequest<Contract.TenantControllerUpdateAzureTenantRequest, Contract.TenantControllerUpdateAzureTenantResponse>(
            TenantController.updateAzureTenantUrl(),
            request);
    }


    public static getGcpTenantOnboardingInfoUrl =
        () => "/api/console/tenant/getGcpTenantOnboardingInfo";

    public static getGcpTenantOnboardingInfo(): Promise<Contract.TenantControllerGetGcpTenantOnboardingInfoResponse> {
        return ApiController.postRequest<any, Contract.TenantControllerGetGcpTenantOnboardingInfoResponse>(
            TenantController.getGcpTenantOnboardingInfoUrl(),
            {});
    }


    public static insertGcpTenantUrl =
        () => "/api/console/tenant/insertGcpTenant";

    public static insertGcpTenant(request: Contract.TenantControllerInsertGcpTenantRequest): Promise<Contract.TenantControllerInsertGcpTenantResponse> {
        return ApiController.postRequest<Contract.TenantControllerInsertGcpTenantRequest, Contract.TenantControllerInsertGcpTenantResponse>(
            TenantController.insertGcpTenantUrl(),
            request);
    }


    public static updateGcpTenantUrl =
        () => "/api/console/tenant/updateGcpTenant";

    public static updateGcpTenant(request: Contract.TenantControllerUpdateGcpTenantRequest): Promise<Contract.TenantControllerUpdateGcpTenantResponse> {
        return ApiController.postRequest<Contract.TenantControllerUpdateGcpTenantRequest, Contract.TenantControllerUpdateGcpTenantResponse>(
            TenantController.updateGcpTenantUrl(),
            request);
    }


    public static validateGcpAccessTokenUrl =
        () => "/api/console/tenant/validateGcpAccessToken";

    public static validateGcpAccessToken(request: Contract.TenantControllerValidateGcpAccessTokenRequest): Promise<Contract.TenantControllerValidateGcpAccessTokenResponse> {
        return ApiController.postRequest<Contract.TenantControllerValidateGcpAccessTokenRequest, Contract.TenantControllerValidateGcpAccessTokenResponse>(
            TenantController.validateGcpAccessTokenUrl(),
            request);
    }


    public static validateGcpOrganizationUrl =
        () => "/api/console/tenant/validateGcpOrganization";

    public static validateGcpOrganization(request: Contract.TenantControllerValidateGcpOrganizationRequest): Promise<Contract.TenantControllerValidateGcpOrganizationResponse> {
        return ApiController.postRequest<Contract.TenantControllerValidateGcpOrganizationRequest, Contract.TenantControllerValidateGcpOrganizationResponse>(
            TenantController.validateGcpOrganizationUrl(),
            request);
    }


    public static validateGcpTenantUrl =
        () => "/api/console/tenant/validateGcpTenant";

    public static validateGcpTenant(request: Contract.TenantControllerValidateGcpTenantRequest): Promise<Contract.TenantControllerValidateGcpTenantResponse> {
        return ApiController.postRequest<Contract.TenantControllerValidateGcpTenantRequest, Contract.TenantControllerValidateGcpTenantResponse>(
            TenantController.validateGcpTenantUrl(),
            request);
    }


    public static validateStoredGcpAccessTokenUrl =
        () => "/api/console/tenant/validateStoredGcpAccessToken";

    public static validateStoredGcpAccessToken(request: Contract.TenantControllerValidateStoredGcpAccessTokenRequest): Promise<Contract.TenantControllerValidateStoredGcpAccessTokenResponse> {
        return ApiController.postRequest<Contract.TenantControllerValidateStoredGcpAccessTokenRequest, Contract.TenantControllerValidateStoredGcpAccessTokenResponse>(
            TenantController.validateStoredGcpAccessTokenUrl(),
            request);
    }


    public static insertOpTenantUrl =
        () => "/api/console/tenant/insertOpTenant";

    public static insertOpTenant(request: Contract.TenantControllerInsertOpTenantRequest): Promise<Contract.TenantControllerInsertOpTenantResponse> {
        return ApiController.postRequest<Contract.TenantControllerInsertOpTenantRequest, Contract.TenantControllerInsertOpTenantResponse>(
            TenantController.insertOpTenantUrl(),
            request);
    }


    public static updateOpTenantUrl =
        () => "/api/console/tenant/updateOpTenant";

    public static updateOpTenant(request: Contract.TenantControllerUpdateOpTenantRequest): Promise<Contract.TenantControllerUpdateOpTenantResponse> {
        return ApiController.postRequest<Contract.TenantControllerUpdateOpTenantRequest, Contract.TenantControllerUpdateOpTenantResponse>(
            TenantController.updateOpTenantUrl(),
            request);
    }


    public static getAadTenantOnboardingInfoUrl =
        () => "/api/console/tenant/getAadTenantOnboardingInfo";

    public static getAadTenantOnboardingInfo(): Promise<Contract.TenantControllerGetAadTenantOnboardingInfoResponse> {
        return ApiController.postRequest<any, Contract.TenantControllerGetAadTenantOnboardingInfoResponse>(
            TenantController.getAadTenantOnboardingInfoUrl(),
            {});
    }


    public static insertAadChinaTenantUrl =
        () => "/api/console/tenant/insertAadChinaTenant";

    public static insertAadChinaTenant(request: Contract.TenantControllerInsertAadChinaTenantRequest): Promise<Contract.TenantControllerInsertAadChinaTenantResponse> {
        return ApiController.postRequest<Contract.TenantControllerInsertAadChinaTenantRequest, Contract.TenantControllerInsertAadChinaTenantResponse>(
            TenantController.insertAadChinaTenantUrl(),
            request);
    }


    public static insertAadTenantUrl =
        (aadTenantToken: string) => ApiController.formatUrlParameters("/api/console/tenant/insertAadTenant", { aadTenantToken });

    public static insertAadTenant(aadTenantToken: string): Promise<void> {
        return ApiController.getRequest<void>(TenantController.insertAadTenantUrl(aadTenantToken));
    }


    public static processAadTenantCallbackUrl =
        (error: Optional<string>, stateJson: Optional<string>, tenant: Optional<string>) => ApiController.formatUrlParameters("/api/console/tenant/processAadConsentCallback", { error, stateJson, tenant });

    public static processAadTenantCallback(error: Optional<string>, stateJson: Optional<string>, tenant: Optional<string>): Promise<void> {
        return ApiController.getRequest<void>(TenantController.processAadTenantCallbackUrl(error, stateJson, tenant));
    }


    public static updateAadTenantUrl =
        () => "/api/console/tenant/updateAadTenant";

    public static updateAadTenant(request: Contract.TenantControllerUpdateAadTenantRequest): Promise<Contract.TenantControllerUpdateAadTenantResponse> {
        return ApiController.postRequest<Contract.TenantControllerUpdateAadTenantRequest, Contract.TenantControllerUpdateAadTenantResponse>(
            TenantController.updateAadTenantUrl(),
            request);
    }


    public static getGciTenantOnboardingInfoUrl =
        () => "/api/console/tenant/getGciTenantOnboardingInfo";

    public static getGciTenantOnboardingInfo(): Promise<Contract.TenantControllerGetGciTenantOnboardingInfoResponse> {
        return ApiController.postRequest<any, Contract.TenantControllerGetGciTenantOnboardingInfoResponse>(
            TenantController.getGciTenantOnboardingInfoUrl(),
            {});
    }


    public static insertGciTenantUrl =
        () => "/api/console/tenant/insertGciTenant";

    public static insertGciTenant(request: Contract.TenantControllerInsertGciTenantRequest): Promise<Contract.TenantControllerInsertGciTenantResponse> {
        return ApiController.postRequest<Contract.TenantControllerInsertGciTenantRequest, Contract.TenantControllerInsertGciTenantResponse>(
            TenantController.insertGciTenantUrl(),
            request);
    }


    public static updateGciTenantUrl =
        () => "/api/console/tenant/updateGciTenant";

    public static updateGciTenant(request: Contract.TenantControllerUpdateGciTenantRequest): Promise<Contract.TenantControllerUpdateGciTenantResponse> {
        return ApiController.postRequest<Contract.TenantControllerUpdateGciTenantRequest, Contract.TenantControllerUpdateGciTenantResponse>(
            TenantController.updateGciTenantUrl(),
            request);
    }


    public static validateGciTenantUrl =
        () => "/api/console/tenant/validateGciTenant";

    public static validateGciTenant(request: Contract.TenantControllerValidateGciTenantRequest): Promise<Contract.TenantControllerValidateGciTenantResponse> {
        return ApiController.postRequest<Contract.TenantControllerValidateGciTenantRequest, Contract.TenantControllerValidateGciTenantResponse>(
            TenantController.validateGciTenantUrl(),
            request);
    }


    public static insertOktaTenantUrl =
        () => "/api/console/tenant/insertOktaTenant";

    public static insertOktaTenant(request: Contract.TenantControllerInsertOktaTenantRequest): Promise<Contract.TenantControllerInsertOktaTenantResponse> {
        return ApiController.postRequest<Contract.TenantControllerInsertOktaTenantRequest, Contract.TenantControllerInsertOktaTenantResponse>(
            TenantController.insertOktaTenantUrl(),
            request);
    }


    public static updateOktaTenantUrl =
        () => "/api/console/tenant/updateOktaTenant";

    public static updateOktaTenant(request: Contract.TenantControllerUpdateOktaTenantRequest): Promise<Contract.TenantControllerUpdateOktaTenantResponse> {
        return ApiController.postRequest<Contract.TenantControllerUpdateOktaTenantRequest, Contract.TenantControllerUpdateOktaTenantResponse>(
            TenantController.updateOktaTenantUrl(),
            request);
    }


    public static insertOneLoginTenantUrl =
        () => "/api/console/tenant/insertOneLoginTenant";

    public static insertOneLoginTenant(request: Contract.TenantControllerInsertOneLoginTenantRequest): Promise<Contract.TenantControllerInsertOneLoginTenantResponse> {
        return ApiController.postRequest<Contract.TenantControllerInsertOneLoginTenantRequest, Contract.TenantControllerInsertOneLoginTenantResponse>(
            TenantController.insertOneLoginTenantUrl(),
            request);
    }


    public static updateOneLoginTenantUrl =
        () => "/api/console/tenant/updateOneLoginTenant";

    public static updateOneLoginTenant(request: Contract.TenantControllerUpdateOneLoginTenantRequest): Promise<Contract.TenantControllerUpdateOneLoginTenantResponse> {
        return ApiController.postRequest<Contract.TenantControllerUpdateOneLoginTenantRequest, Contract.TenantControllerUpdateOneLoginTenantResponse>(
            TenantController.updateOneLoginTenantUrl(),
            request);
    }


    public static validateOneLoginTenantUrl =
        () => "/api/console/tenant/validateOneLoginTenant";

    public static validateOneLoginTenant(request: Contract.TenantControllerValidateOneLoginTenantRequest): Promise<Contract.TenantControllerValidateOneLoginTenantResponse> {
        return ApiController.postRequest<Contract.TenantControllerValidateOneLoginTenantRequest, Contract.TenantControllerValidateOneLoginTenantResponse>(
            TenantController.validateOneLoginTenantUrl(),
            request);
    }


    public static insertPingIdentityTenantUrl =
        () => "/api/console/tenant/insertPingIdentityTenant";

    public static insertPingIdentityTenant(request: Contract.TenantControllerInsertPingIdentityTenantRequest): Promise<Contract.TenantControllerInsertPingIdentityTenantResponse> {
        return ApiController.postRequest<Contract.TenantControllerInsertPingIdentityTenantRequest, Contract.TenantControllerInsertPingIdentityTenantResponse>(
            TenantController.insertPingIdentityTenantUrl(),
            request);
    }


    public static updatePingIdentityTenantUrl =
        () => "/api/console/tenant/updatePingIdentityTenant";

    public static updatePingIdentityTenant(request: Contract.TenantControllerUpdatePingIdentityTenantRequest): Promise<Contract.TenantControllerUpdatePingIdentityTenantResponse> {
        return ApiController.postRequest<Contract.TenantControllerUpdatePingIdentityTenantRequest, Contract.TenantControllerUpdatePingIdentityTenantResponse>(
            TenantController.updatePingIdentityTenantUrl(),
            request);
    }


    public static deleteOrganizationUrl =
        () => "/api/console/tenant/deleteOrganization";

    public static deleteOrganization(request: Contract.TenantControllerDeleteOrganizationRequest): Promise<Contract.TenantControllerDeleteOrganizationResponse> {
        return ApiController.postRequest<Contract.TenantControllerDeleteOrganizationRequest, Contract.TenantControllerDeleteOrganizationResponse>(
            TenantController.deleteOrganizationUrl(),
            request);
    }


    public static deleteTenantUrl =
        () => "/api/console/tenant/deleteTenant";

    public static deleteTenant(request: Contract.TenantControllerDeleteTenantRequest): Promise<Contract.TenantControllerDeleteTenantResponse> {
        return ApiController.postRequest<Contract.TenantControllerDeleteTenantRequest, Contract.TenantControllerDeleteTenantResponse>(
            TenantController.deleteTenantUrl(),
            request);
    }


    public static getTenantModelsUrl =
        () => "/api/console/tenant/getTenantModels";

    public static getTenantModels(request: Contract.TenantControllerGetTenantModelsRequest): Promise<Contract.TenantControllerGetTenantModelsResponse> {
        return ApiController.postRequest<Contract.TenantControllerGetTenantModelsRequest, Contract.TenantControllerGetTenantModelsResponse>(
            TenantController.getTenantModelsUrl(),
            request);
    }
}

export class TicketingServiceController extends ApiController {
    public static getJiraCustomLabelsFieldAutocompleteSuggestionsUrl =
        () => "/api/console/ticketingService/getJiraCustomLabelsFieldAutocompleteSuggestions";

    public static getJiraCustomLabelsFieldAutocompleteSuggestions(request: Contract.TicketingServiceControllerGetJiraCustomLabelsFieldAutocompleteSuggestionsRequest): Promise<Contract.TicketingServiceControllerGetJiraCustomLabelsFieldAutoCompleteSuggestionsResponse> {
        return ApiController.postRequest<Contract.TicketingServiceControllerGetJiraCustomLabelsFieldAutocompleteSuggestionsRequest, Contract.TicketingServiceControllerGetJiraCustomLabelsFieldAutoCompleteSuggestionsResponse>(
            TicketingServiceController.getJiraCustomLabelsFieldAutocompleteSuggestionsUrl(),
            request);
    }


    public static getJiraProjectUrl =
        () => "/api/console/ticketingService/getJiraProject";

    public static getJiraProject(request: Contract.TicketingServiceControllerGetJiraProjectRequest): Promise<Contract.TicketingServiceControllerGetJiraProjectResponse> {
        return ApiController.postRequest<Contract.TicketingServiceControllerGetJiraProjectRequest, Contract.TicketingServiceControllerGetJiraProjectResponse>(
            TicketingServiceController.getJiraProjectUrl(),
            request);
    }


    public static getServiceNowInstanceUrl =
        () => "/api/console/ticketingService/getServiceNowInstance";

    public static getServiceNowInstance(request: Contract.TicketingServiceControllerGetServiceNowInstanceRequest): Promise<Contract.TicketingServiceControllerGetServiceNowInstanceResponse> {
        return ApiController.postRequest<Contract.TicketingServiceControllerGetServiceNowInstanceRequest, Contract.TicketingServiceControllerGetServiceNowInstanceResponse>(
            TicketingServiceController.getServiceNowInstanceUrl(),
            request);
    }


    public static getServiceNowSeverityImpactAndUrgencyUrl =
        () => "/api/console/ticketingService/getServiceNowSeverityImpactAndUrgency";

    public static getServiceNowSeverityImpactAndUrgency(request: Contract.TicketingServiceControllerGetServiceNowSeverityImpactAndUrgencyRequest): Promise<Contract.TicketingServiceControllerGetServiceNowSeverityImpactAndUrgencyResponse> {
        return ApiController.postRequest<Contract.TicketingServiceControllerGetServiceNowSeverityImpactAndUrgencyRequest, Contract.TicketingServiceControllerGetServiceNowSeverityImpactAndUrgencyResponse>(
            TicketingServiceController.getServiceNowSeverityImpactAndUrgencyUrl(),
            request);
    }


    public static getTicketsUrl =
        () => "/api/console/ticketingService/getTickets";

    public static getTickets(request: Contract.TicketingServiceControllerGetTicketsRequest): Promise<Contract.TicketingServiceControllerGetTicketsResponse> {
        return ApiController.postRequest<Contract.TicketingServiceControllerGetTicketsRequest, Contract.TicketingServiceControllerGetTicketsResponse>(
            TicketingServiceController.getTicketsUrl(),
            request);
    }


    public static searchJiraProjectUsersUrl =
        () => "/api/console/ticketingService/searchJiraProjectUsers";

    public static searchJiraProjectUsers(request: Contract.TicketingServiceControllerSearchJiraProjectUsersRequest): Promise<Contract.TicketingServiceControllerSearchJiraProjectUsersResponse> {
        return ApiController.postRequest<Contract.TicketingServiceControllerSearchJiraProjectUsersRequest, Contract.TicketingServiceControllerSearchJiraProjectUsersResponse>(
            TicketingServiceController.searchJiraProjectUsersUrl(),
            request);
    }


    public static tryGetJiraIssueInfoUrl =
        () => "/api/console/ticketingService/tryGetJiraIssueInfo";

    public static tryGetJiraIssueInfo(request: Contract.TicketingServiceControllerTryGetJiraIssueInfoRequest): Promise<Contract.TicketingServiceControllerTryGetJiraIssueInfoResponse> {
        return ApiController.postRequest<Contract.TicketingServiceControllerTryGetJiraIssueInfoRequest, Contract.TicketingServiceControllerTryGetJiraIssueInfoResponse>(
            TicketingServiceController.tryGetJiraIssueInfoUrl(),
            request);
    }


    public static tryGetServiceNowIncidentInfoUrl =
        () => "/api/console/ticketingService/tryGetServiceNowIncidentInfo";

    public static tryGetServiceNowIncidentInfo(infoRequest: Contract.TicketingServiceControllerTryGetServiceNowIncidentInfoRequest): Promise<Contract.TicketingServiceControllerTryGetServiceNowIncidentInfoResponse> {
        return ApiController.postRequest<Contract.TicketingServiceControllerTryGetServiceNowIncidentInfoRequest, Contract.TicketingServiceControllerTryGetServiceNowIncidentInfoResponse>(
            TicketingServiceController.tryGetServiceNowIncidentInfoUrl(),
            infoRequest);
    }
}

export class UdmController extends ApiController {
    public static getObjectItemsPageUrl =
        () => "/api/console/udm/getObjectItemsPage";

    public static getObjectItemsPage(request: Contract.UdmControllerGetObjectItemsPageRequest): Promise<Contract.UdmControllerObjectItemsPageResponse> {
        return ApiController.postRequest<Contract.UdmControllerGetObjectItemsPageRequest, Contract.UdmControllerObjectItemsPageResponse>(
            UdmController.getObjectItemsPageUrl(),
            request);
    }


    public static getObjectTypeMetadataModelsUrl =
        () => "/api/console/udm/getObjectTypeMetadataModels";

    public static getObjectTypeMetadataModels(): Promise<Contract.UdmControllerGetObjectTypeMetadatasModelsResponse> {
        return ApiController.postRequest<any, Contract.UdmControllerGetObjectTypeMetadatasModelsResponse>(
            UdmController.getObjectTypeMetadataModelsUrl(),
            {});
    }


    public static getPropertyValuePageUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/udm/getPropertyValuePage/{typeName}", { typeName });

    public static getPropertyValuePage(request: Contract.UdmControllerGetPropertyValuePageRequest): Promise<Contract.UdmControllerGetPropertyValuePageResponse> {
        return ApiController.postRequest<Contract.UdmControllerGetPropertyValuePageRequest, Contract.UdmControllerGetPropertyValuePageResponse>(
            UdmController.getPropertyValuePageUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }
}

export class VulnerabilityController extends ApiController {
    public static getVulnerabilityModelsUrl =
        () => "/api/console/vulnerability/getVulnerabilityModels";

    public static getVulnerabilityModels(request: Contract.VulnerabilityControllerGetVulnerabilityModelsRequest): Promise<Contract.VulnerabilityControllerGetVulnerabilityModelsResponse> {
        return ApiController.postRequest<Contract.VulnerabilityControllerGetVulnerabilityModelsRequest, Contract.VulnerabilityControllerGetVulnerabilityModelsResponse>(
            VulnerabilityController.getVulnerabilityModelsUrl(),
            request);
    }
}

export class WorkloadController extends ApiController {
    public static getContainerImageScanFiltersUrl =
        () => "/api/console/workload/getContainerImageScanFilters";

    public static getContainerImageScanFilters(request: Contract.WorkloadControllerGetWorkloadResourceScanFiltersRequest): Promise<Contract.WorkloadControllerGetWorkloadResourceScanFiltersResponse<Contract.ContainerImageScanFilters>> {
        return ApiController.postRequest<Contract.WorkloadControllerGetWorkloadResourceScanFiltersRequest, Contract.WorkloadControllerGetWorkloadResourceScanFiltersResponse<Contract.ContainerImageScanFilters>>(
            WorkloadController.getContainerImageScanFiltersUrl(),
            request);
    }


    public static getMaliciousFileModelCountUrl =
        () => "/api/console/workload/getMaliciousFileModelCount";

    public static getMaliciousFileModelCount(request: Contract.WorkloadControllerGetMaliciousFileModelPageRequest): Promise<Contract.WorkloadControllerGetMaliciousFileModelCountResponse> {
        return ApiController.postRequest<Contract.WorkloadControllerGetMaliciousFileModelPageRequest, Contract.WorkloadControllerGetMaliciousFileModelCountResponse>(
            WorkloadController.getMaliciousFileModelCountUrl(),
            request);
    }


    public static getMaliciousFileModelPageUrl =
        () => "/api/console/workload/getMaliciousFileModelPage";

    public static getMaliciousFileModelPage(request: Contract.WorkloadControllerGetMaliciousFileModelPageRequest): Promise<Contract.WorkloadControllerGetMaliciousFileModelPageResponse> {
        return ApiController.postRequest<Contract.WorkloadControllerGetMaliciousFileModelPageRequest, Contract.WorkloadControllerGetMaliciousFileModelPageResponse>(
            WorkloadController.getMaliciousFileModelPageUrl(),
            request);
    }


    public static getMaliciousFileModelPropertyItemsUrl =
        () => "/api/console/workload/getMaliciousFileModelPropertyItems";

    public static getMaliciousFileModelPropertyItems(request: Contract.WorkloadControllerMaliciousFileModelPropertyItemsRequest): Promise<Contract.WorkloadControllerMaliciousFileModelPropertyItemsResponseBase> {
        return ApiController.postRequest<Contract.WorkloadControllerMaliciousFileModelPropertyItemsRequest, Contract.WorkloadControllerMaliciousFileModelPropertyItemsResponseBase>(
            WorkloadController.getMaliciousFileModelPropertyItemsUrl(),
            request);
    }


    public static getWorkloadResourceScanFileFilterItemPageUrl =
        () => "/api/console/workload/getWorkloadResourceScanFileFilterItemPage";

    public static getWorkloadResourceScanFileFilterItemPage(request: Contract.WorkloadControllerGetWorkloadResourceScanFileFilterItemPageRequest): Promise<Contract.WorkloadControllerGetWorkloadFileFilterScanItemPageResponseBase> {
        return ApiController.postRequest<Contract.WorkloadControllerGetWorkloadResourceScanFileFilterItemPageRequest, Contract.WorkloadControllerGetWorkloadFileFilterScanItemPageResponseBase>(
            WorkloadController.getWorkloadResourceScanFileFilterItemPageUrl(),
            request);
    }


    public static getWorkloadResourceScanFileFiltersUrl =
        () => "/api/console/workload/getWorkloadResourceScanFileFilters";

    public static getWorkloadResourceScanFileFilters(): Promise<Contract.WorkloadControllerGetWorkloadResourceScanFileFiltersResponse> {
        return ApiController.postRequest<any, Contract.WorkloadControllerGetWorkloadResourceScanFileFiltersResponse>(
            WorkloadController.getWorkloadResourceScanFileFiltersUrl(),
            {});
    }


    public static getPackageModelCountUrl =
        () => "/api/console/workload/getPackageModelCount";

    public static getPackageModelCount(request: Contract.WorkloadControllerGetPackageModelPageRequest): Promise<Contract.WorkloadControllerGetPackageModelCountResponse> {
        return ApiController.postRequest<Contract.WorkloadControllerGetPackageModelPageRequest, Contract.WorkloadControllerGetPackageModelCountResponse>(
            WorkloadController.getPackageModelCountUrl(),
            request);
    }


    public static getPackageModelPageUrl =
        () => "/api/console/workload/getPackageModelPage";

    public static getPackageModelPage(request: Contract.WorkloadControllerGetPackageModelPageRequest): Promise<Contract.WorkloadControllerGetPackageModelPageResponse> {
        return ApiController.postRequest<Contract.WorkloadControllerGetPackageModelPageRequest, Contract.WorkloadControllerGetPackageModelPageResponse>(
            WorkloadController.getPackageModelPageUrl(),
            request);
    }


    public static getPackageModelPropertyItemsUrl =
        () => "/api/console/workload/getPackageModelPropertyItems";

    public static getPackageModelPropertyItems(request: Contract.WorkloadControllerPackageModelPropertyItemsRequest): Promise<Contract.WorkloadControllerPackageModelPropertyItemsResponseBase> {
        return ApiController.postRequest<Contract.WorkloadControllerPackageModelPropertyItemsRequest, Contract.WorkloadControllerPackageModelPropertyItemsResponseBase>(
            WorkloadController.getPackageModelPropertyItemsUrl(),
            request);
    }


    public static getPackageVulnerabilityModelCountUrl =
        () => "/api/console/workload/getPackageVulnerabilityModelCount";

    public static getPackageVulnerabilityModelCount(request: Contract.WorkloadControllerGetPackageVulnerabilityModelPageRequest): Promise<Contract.WorkloadControllerGetPackageVulnerabilityModelCountResponse> {
        return ApiController.postRequest<Contract.WorkloadControllerGetPackageVulnerabilityModelPageRequest, Contract.WorkloadControllerGetPackageVulnerabilityModelCountResponse>(
            WorkloadController.getPackageVulnerabilityModelCountUrl(),
            request);
    }


    public static getPackageVulnerabilityModelPageUrl =
        () => "/api/console/workload/getPackageVulnerabilityModelPage";

    public static getPackageVulnerabilityModelPage(request: Contract.WorkloadControllerGetPackageVulnerabilityModelPageRequest): Promise<Contract.WorkloadControllerGetPackageVulnerabilityModelPageResponse> {
        return ApiController.postRequest<Contract.WorkloadControllerGetPackageVulnerabilityModelPageRequest, Contract.WorkloadControllerGetPackageVulnerabilityModelPageResponse>(
            WorkloadController.getPackageVulnerabilityModelPageUrl(),
            request);
    }


    public static getPackageVulnerabilityModelPropertyItemsUrl =
        () => "/api/console/workload/getPackageVulnerabilityModelPropertyItems";

    public static getPackageVulnerabilityModelPropertyItems(request: Contract.WorkloadControllerPackageVulnerabilityModelPropertyItemsRequest): Promise<Contract.WorkloadControllerPackageVulnerabilityModelPropertyItemsResponseBase> {
        return ApiController.postRequest<Contract.WorkloadControllerPackageVulnerabilityModelPropertyItemsRequest, Contract.WorkloadControllerPackageVulnerabilityModelPropertyItemsResponseBase>(
            WorkloadController.getPackageVulnerabilityModelPropertyItemsUrl(),
            request);
    }


    public static getVirtualMachineImagesScanFiltersUrl =
        () => "/api/console/workload/getVirtualMachineImagesScanFilters";

    public static getVirtualMachineImagesScanFilters(request: Contract.WorkloadControllerGetWorkloadResourceScanFiltersRequest): Promise<Contract.WorkloadControllerGetWorkloadResourceScanFiltersResponse<Contract.VirtualMachineImageScanFilters>> {
        return ApiController.postRequest<Contract.WorkloadControllerGetWorkloadResourceScanFiltersRequest, Contract.WorkloadControllerGetWorkloadResourceScanFiltersResponse<Contract.VirtualMachineImageScanFilters>>(
            WorkloadController.getVirtualMachineImagesScanFiltersUrl(),
            request);
    }


    public static getVirtualMachineScanFiltersUrl =
        () => "/api/console/workload/getVirtualMachineScanFilters";

    public static getVirtualMachineScanFilters(request: Contract.WorkloadControllerGetWorkloadResourceScanFiltersRequest): Promise<Contract.WorkloadControllerGetWorkloadResourceScanFiltersResponse<Contract.VirtualMachineScanFilters>> {
        return ApiController.postRequest<Contract.WorkloadControllerGetWorkloadResourceScanFiltersRequest, Contract.WorkloadControllerGetWorkloadResourceScanFiltersResponse<Contract.VirtualMachineScanFilters>>(
            WorkloadController.getVirtualMachineScanFiltersUrl(),
            request);
    }


    public static updateVirtualMachinePriorityScanRequestUrl =
        () => "/api/console/workload/updateVirtualMachinePriorityScanRequest";

    public static updateVirtualMachinePriorityScanRequest(request: Contract.WorkloadControllerUpdateVirtualMachinePriorityScanRequestRequest): Promise<void> {
        return ApiController.postRequest<Contract.WorkloadControllerUpdateVirtualMachinePriorityScanRequestRequest, void>(
            WorkloadController.updateVirtualMachinePriorityScanRequestUrl(),
            request);
    }


    public static getWorkloadResourceRiskVulnerabilityRawIdsUrl =
        () => "/api/console/workload/getWorkloadResourceRiskVulnerabilityRawIds";

    public static getWorkloadResourceRiskVulnerabilityRawIds(request: Contract.WorkloadControllerGetWorkloadResourceRiskVulnerabilityRawIdsRequest): Promise<Contract.WorkloadControllerGetWorkloadResourceRiskVulnerabilityRawIdsResponse> {
        return ApiController.postRequest<Contract.WorkloadControllerGetWorkloadResourceRiskVulnerabilityRawIdsRequest, Contract.WorkloadControllerGetWorkloadResourceRiskVulnerabilityRawIdsResponse>(
            WorkloadController.getWorkloadResourceRiskVulnerabilityRawIdsUrl(),
            request);
    }


    public static getWorkloadResourceScanFilterItemPageUrl =
        () => "/api/console/workload/getWorkloadResourceScanFilterItemPage";

    public static getWorkloadResourceScanFilterItemPage(request: Contract.WorkloadControllerGetWorkloadResourceScanFilterItemPageRequest): Promise<Contract.WorkloadControllerGetWorkloadResourceFilterScanItemPageResponseBase> {
        return ApiController.postRequest<Contract.WorkloadControllerGetWorkloadResourceScanFilterItemPageRequest, Contract.WorkloadControllerGetWorkloadResourceFilterScanItemPageResponseBase>(
            WorkloadController.getWorkloadResourceScanFilterItemPageUrl(),
            request);
    }


    public static getWorkloadResourceScanPackageFilterItemPageUrl =
        () => "/api/console/workload/getWorkloadResourceScanPackageFilterItemPage";

    public static getWorkloadResourceScanPackageFilterItemPage(request: Contract.WorkloadControllerGetWorkloadResourceScanPackageFilterItemPageRequest): Promise<Contract.WorkloadControllerGetWorkloadPackageFilterScanItemPageResponseBase> {
        return ApiController.postRequest<Contract.WorkloadControllerGetWorkloadResourceScanPackageFilterItemPageRequest, Contract.WorkloadControllerGetWorkloadPackageFilterScanItemPageResponseBase>(
            WorkloadController.getWorkloadResourceScanPackageFilterItemPageUrl(),
            request);
    }


    public static getWorkloadResourceScanPackageFiltersUrl =
        () => "/api/console/workload/getWorkloadResourceScanPackageFilters";

    public static getWorkloadResourceScanPackageFilters(request: Contract.WorkloadControllerGetWorkloadPackageScanFiltersRequest): Promise<Contract.WorkloadControllerGetWorkloadPackageScanFiltersResponse> {
        return ApiController.postRequest<any, Contract.WorkloadControllerGetWorkloadPackageScanFiltersResponse>(
            WorkloadController.getWorkloadResourceScanPackageFiltersUrl(),
            {});
    }


    public static getWorkloadResourceModelPageUrl =
        (typeName: string) => ApiController.formatUrlParameters("/api/console/workload/getWorkloadResourceModelPage/{typeName}", { typeName });

    public static getWorkloadResourceModelPage(request: Contract.WorkloadControllerGetWorkloadResourceModelPageRequest): Promise<Contract.WorkloadControllerGetWorkloadResourceModelPageResponse> {
        return ApiController.postRequest<Contract.WorkloadControllerGetWorkloadResourceModelPageRequest, Contract.WorkloadControllerGetWorkloadResourceModelPageResponse>(
            WorkloadController.getWorkloadResourceModelPageUrl(TypeMetadataHelper.getTypeMetadata(request).name),
            request);
    }


    public static getWorkloadResourceModelPropertyItemsUrl =
        () => "/api/console/workload/getWorkloadResourceModelPropertyItems";

    public static getWorkloadResourceModelPropertyItems(request: Contract.WorkloadControllerWorkloadResourceModelPropertyItemsRequest): Promise<Contract.WorkloadControllerWorkloadResourceModelPropertyItemsResponseBase> {
        return ApiController.postRequest<Contract.WorkloadControllerWorkloadResourceModelPropertyItemsRequest, Contract.WorkloadControllerWorkloadResourceModelPropertyItemsResponseBase>(
            WorkloadController.getWorkloadResourceModelPropertyItemsUrl(),
            request);
    }
}