import { ActionMenuItem, DataTableColumnRenderProps, DeleteIcon, EditIcon, Menu, Message, useLocalization } from "@infrastructure";
import { CircularProgress, Stack } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useMemo, useState } from "react";
import { useSetKubernetesClusterConnectorsContext } from "../../..";
import { ConfigurationController, Contract, scopeNodeModelStore, scopeSystemEntityModelStore, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../../../../../../../common";

export function ActionsCell({ item }: DataTableColumnRenderProps<Contract.ScopeSystemEntityModel>) {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const setKubernetesClusterConnectorContext = useSetKubernetesClusterConnectorsContext();

    const [deleteKubernetesClusterConnectorExecuting, setDeleteKubernetesClusterConnectorExecuting] = useState(false);
    const [deleteKubernetesClusterConnectorError, setDeleteKubernetesClusterConnectorError] = useState(false);
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const childScopeIds =
        useMemo(
            () => scopeNodeMap[scopeNodeModel.configuration.id].scopeIds,
            [scopeNodeModel.configuration.id, scopeNodeMap]);


    async function deleteKubernetesClusterConnector() {
        setDeleteKubernetesClusterConnectorExecuting(true);
        setDeleteKubernetesClusterConnectorError(false);

        try {
            await ConfigurationController.deleteKubernetesClusterConnector(new Contract.ConfigurationControllerDeleteKubernetesClusterConnectorRequest(item.configuration.id));
            await scopeSystemEntityModelStore.notify();
        } catch {
            setDeleteKubernetesClusterConnectorError(true);
        }

        setDeleteKubernetesClusterConnectorExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useKubernetesItems.kubernetesClusterConnectors.table.actionsCell",
            () => ({
                actions: {
                    delete: {
                        error: "Failed to delete",
                        prompt: "Are you sure you want to delete {{name}}?",
                        title: "Delete"
                    },
                    edit: "Edit"
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            {deleteKubernetesClusterConnectorExecuting && (
                <CircularProgress
                    size={theme.spacing(2)}
                    variant="indeterminate"/>)}
            {deleteKubernetesClusterConnectorError && (
                <Message
                    level="error"
                    title={localization.actions.delete.error()}
                    variant="minimal"/>)}
            {(item.configuration.scopeId === scopeNodeModel.configuration.id ||
                    _.includes(childScopeIds, item.configuration.scopeId)) &&
                <Fragment>
                    <Menu
                        itemsOrGetItems={[
                            new ActionMenuItem(
                                () =>
                                    setKubernetesClusterConnectorContext(
                                        KubernetesClusterConnectorContext => ({
                                            ...KubernetesClusterConnectorContext,
                                            addOrEditOpen: item
                                        })),
                                localization.actions.edit(),
                                {
                                    disabled: deleteKubernetesClusterConnectorExecuting,
                                    icon: <EditIcon/>
                                }),
                            new ActionMenuItem(
                                () => deleteKubernetesClusterConnector(),
                                localization.actions.delete.title(),
                                {
                                    confirmOptions: {
                                        message: localization.actions.delete.prompt({ name: (item.configuration as Contract.KubernetesClusterConnectorConfiguration).name })
                                    },
                                    disabled: deleteKubernetesClusterConnectorExecuting,
                                    icon: <DeleteIcon/>
                                })
                        ]}/>
                </Fragment>}
        </Stack>);
}