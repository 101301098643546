import { Action0, IconText, Link, Loading, Tooltip } from "@infrastructure";
import { Box, Typography } from "@mui/material";
import { Instance as PopperInstance } from "@popperjs/core";
import React, { useRef } from "react";
import { CustomerConsoleAppUrlHelper, useTheme, VulnerabilitiesIcon } from "..";
import { Vulnerability } from "./Vulnerability";

type InlineVulnerabilityProps = {
    onClick?: Action0;
    rawId: string;
};

export function InlineVulnerability({ onClick, rawId }: InlineVulnerabilityProps) {
    const tooltipPopperRef = useRef<PopperInstance>(null);
    const theme = useTheme();
    return (
        <Tooltip
            enterDelay={1000}
            enterNextDelay={1000}
            leaveDelay={250}
            placement="bottom-start"
            slotProps={{
                popper: {
                    onClick: event => event.stopPropagation(),
                    popperRef: tooltipPopperRef
                },
                tooltip: {
                    sx: {
                        backgroundColor: theme.palette.background.paper,
                        border: theme.border.primary,
                        margin: theme.spacing(0),
                        padding: theme.spacing(0),
                        spacing: theme.spacing(0)
                    }
                }
            }}
            sx={{ padding: 0 }}
            titleOrGetTitle={
                <Loading container="popup">
                    <Vulnerability
                        rawId={rawId}
                        onMount={() => tooltipPopperRef.current?.update()}/>
                </Loading>}>
            <Box>
                <Link
                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getVulnerabilityHashUrl(rawId)}
                    variant="text"
                    onClick={onClick}>
                    <IconText
                        icon={
                            <VulnerabilitiesIcon
                                sx={{
                                    color: theme.palette.text.primary,
                                    fontSize: "18px"
                                }}/>}
                        text={
                            <Typography noWrap={true}>
                                {rawId}
                            </Typography>}/>
                </Link>
            </Box>
        </Tooltip>);
}