﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, entityModelStore, InlineEntities, InlinePermissionActions, useEntityTypeNameTranslator } from "../../../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../../../utilities";
import { useGetEntitiesNetworkRiskContext } from "../../../../../useGetEntitiesNetworkRiskContext";

export function useGetAwsEc2InstanceMetadataServiceVersionRiskContext() {
    return useMemo(
        () => useAwsEc2InstanceMetadataServiceVersionRiskContext,
        []);
}

function useAwsEc2InstanceMetadataServiceVersionRiskContext(riskModel: Contract.AwsEc2InstanceMetadataServiceVersionRiskModel, resourceDisplayName?: string) {
    const aggregatingEntityModel = entityModelStore.useGet(riskModel.risk.entityId);
    const entitiesNetworkContextItems =
        useGetEntitiesNetworkRiskContext()(
            riskModel.risk.publicInstanceIds,
            Contract.TypeNames.AwsEc2Instance);

    const destinationNetworkScopes =
        _(riskModel.risk.instanceIdToDataMap).
            flatMap(publicAccessData => publicAccessData.destinationNetworkScope).
            filter().
            value() as Contract.DestinationNetworkScope[];
    const permissionActions =
        _(riskModel.risk.instanceIdToDataMap).
            flatMap(publicAccessData => publicAccessData.highSeverityRolePermissionActions).
            filter().
            value() as string[];
    const securityGroupIds =
        _(riskModel.risk.instanceIdToDataMap).
            flatMap(publicAccessData => publicAccessData.securityGroupIds).
            filter().
            uniq().
            value() as string[];

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceMetadataServiceVersionRiskDefinition.hooks.useGetAwsEc2InstanceMetadataServiceVersionRiskContext",
            () => ({
                instanceNotExists: "The {{translatedEntityTypeName}} is not associated with any instance that is not enforcing IMDSv2",
                instances: [
                    "The {{translatedEntityTypeName}} is associated with {{instances}}, which is not enforcing IMDSv2",
                    "The {{translatedEntityTypeName}} is associated with {{instances}}, which are not enforcing IMDSv2"
                ],
                permissionActions: [
                    "{{instances}} is associated with {{highSeverityPermissionActions}} with **high** severity",
                    "{{instances}} are associated with {{highSeverityPermissionActions}} with **high** severity"
                ]
            }));

    const translatedEntityTypeName =
        resourceDisplayName ??
        entityTypeNameTranslator(
            aggregatingEntityModel.entity.typeName,
            {
                includeServiceName: true,
                variant: "text"
            });

    return {
        instancePublicAccess:
            entitiesNetworkContextItems.getInboundExternalAccessContextItem(
                destinationNetworkScopes,
                securityGroupIds),
        instances:
            riskModel.risk.aggregatingEntityTypeName !== Contract.TypeNames.AwsEc2Instance &&
            !_.isEmpty(riskModel.risk.aggregatedEntityIds)
                ? new RiskDefinitionContextItem(
                    localization.instances(
                        riskModel.risk.aggregatedEntityIds.length,
                        {
                            instances:
                                <InlineEntities
                                    entityIdsOrModels={riskModel.risk.aggregatedEntityIds}
                                    entityTypeName={Contract.TypeNames.AwsEc2Instance}
                                    variant="itemCountAndType"/>,
                            translatedEntityTypeName
                        }))
                : new RiskDefinitionContextItem(localization.instanceNotExists({ translatedEntityTypeName })),
        permissionActions:
            _.isEmpty(riskModel.risk.highSeverityRolePermissionInstanceIds)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.permissionActions(
                        riskModel.risk.highSeverityRolePermissionInstanceIds.length,
                        {
                            highSeverityPermissionActions:
                                <InlinePermissionActions
                                    permissionActions={permissionActions}
                                    variant="itemCountAndType"/>,
                            instances:
                                <InlineEntities
                                    entityIdsOrModels={riskModel.risk.highSeverityRolePermissionInstanceIds}
                                    entityTypeName={Contract.TypeNames.AwsEc2Instance}
                                    variant="itemCountAndType"/>
                        }))
    };
}