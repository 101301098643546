import { map } from "@infrastructure";
import { Contract, RegionModelHelper, tenantModelStore } from "../../../../../../../../../../../../../../../common";
import { GcpConsoleUrlBuilder } from "../../../../../../../../../../../../../../../tenants";
import { EntityExternalConsoleLinkDefinition } from "../useDefinition";

export function useGcpCloudRunServiceDefinition(entityModel: Contract.EntityModel, page: Contract.GcpConsolePage) {
    const service = entityModel.entity as Contract.GcpCloudRunService;
    const tenantModel = tenantModelStore.useGet(service.tenantId);

    const getUrl =
        map(
            page,
            {
                [Contract.GcpConsolePage.Permissions]: () => undefined,
                [Contract.GcpConsolePage.Revisions]: () => GcpConsoleUrlBuilder.GetCloudRunServiceUrl
            });

    return new EntityExternalConsoleLinkDefinition(
        getUrl?.(
            RegionModelHelper.get(service.regionId!).systemName,
            service.name,
            (tenantModel.configuration as Contract.GcpTenantConfiguration).rawShortNameId));
}