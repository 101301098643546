import _ from "lodash";
import React, { memo } from "react";
import { EmptyValueOptions, ValuesFilter, ValuesFilterItem } from "./ValuesFilter";

const EnumValuesFilterMemo = memo(EnumValuesFilter) as typeof EnumValuesFilter;
export { EnumValuesFilterMemo as EnumValuesFilter };

type EnumValuesFilterProps<TEnum> = {
    emptyValueOptions?: EmptyValueOptions;
    enumType: object;
    enumTypeTranslator: ((value: TEnum) => string) | ((value?: TEnum) => string);
    exclude?: TEnum[];
    infoMessage?: string;
    placeholder: string;
    sorted?: boolean;
};

function EnumValuesFilter<TEnum>({ emptyValueOptions, enumType, enumTypeTranslator, exclude = [], infoMessage, placeholder, sorted }: EnumValuesFilterProps<TEnum>) {
    return (
        <ValuesFilter
            emptyValueOptions={emptyValueOptions}
            groupItemTitle={true}
            infoMessage={infoMessage}
            placeholder={placeholder}
            sorted={sorted}>
            {_(enumType).
                values().
                filter(enumType => !exclude.includes(enumType)).
                map(
                    enumValue =>
                        <ValuesFilterItem
                            key={enumValue}
                            title={enumTypeTranslator(enumValue)}
                            value={enumValue}/>).
                value()}
        </ValuesFilter>);
}