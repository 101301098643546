import { useLocalization } from "@infrastructure";
import React from "react";
import { ProfileTopbarInfoItem, RiskStatusIcon, useRiskSubStatusTranslator } from "../../../../../../../../../../common";
import { Risk } from "../../../../../../../../../../common/controllers/types.generated";
import { useStatusTranslator } from "../../../../../Items";

type ProfileTopbarStatusInfoItemProps = {
    risk: Risk;
};

export function ProfileTopbarStatusInfoItem({ risk }: ProfileTopbarStatusInfoItemProps) {
    const riskSubStatusTranslator = useRiskSubStatusTranslator();
    const statusTranslator = useStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.profile.hooks.useProfileDefinition.hooks.profileTopbarStatusInfoItem",
            () => ({
                status: "{{status}} ({{subStatus}})"
            }));

    return (
        <ProfileTopbarInfoItem
            icon={<RiskStatusIcon status={risk.status}/>}
            text={
                localization.status({
                    status: statusTranslator(risk.status),
                    subStatus: riskSubStatusTranslator(risk.subStatus)
                })}/>);
}