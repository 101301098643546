import { Box, Stack } from "@mui/material";
import _ from "lodash";
import React from "react";
import { ActivePermissionRequestIcon, Contract, EntitiesCell, PendingPermissionRequestIcon, PermissionManagementHelper } from "../../../../../../../../../../../common";

type ApprovalLevelApproverUsersCellProps = {
    level: number;
    permissionRequestModel: Contract.PermissionRequestModel;
};

export function ApprovalLevelApproverUsersCell({ level, permissionRequestModel }: ApprovalLevelApproverUsersCellProps) {
    const approverUserIdReferences =
        permissionRequestModel.levelToApproverUserIdReferenceMap[level] ??
        permissionRequestModel.levelToPotentialApproverUserIdReferencesMap[level] ??
        [];
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            {!_.isEmpty(approverUserIdReferences) &&
                <Box sx={{ fontSize: "18px" }}>
                    {_.has(permissionRequestModel.levelToApproverUserIdReferenceMap, level)
                        ? <ActivePermissionRequestIcon sx={{ color: PermissionManagementHelper.approvedPermissionRequestColor }}/>
                        : <PendingPermissionRequestIcon sx={{ color: PermissionManagementHelper.pendingPermissionRequestColor }}/>}
                </Box>}
            <EntitiesCell
                entityIdsOrModels={approverUserIdReferences}
                entityLinkOptions={{ disabled: true }}
                entityTypeName={Contract.TypeNames.IPermissionManagementUser}
                entityVariant="iconText"/>
        </Stack>);
}