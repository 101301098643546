import { useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract, DnsEndpointHelper, TypeHelper } from "../../../../../../../../../common";
import { useCiTenantTypeTranslator } from "../../../../../../../../../tenants/ci";

export function useCiIntegrationAuditEventDefinition(context: AuditEventDefinitionContext) {
    const tenantTypeTranslator = useCiTenantTypeTranslator();

    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useCiIntegrationAuditEventDefinition",
            () => ({
                integrationEndpoint: "Endpoint",
                integrationEndpointConnectorName: "Endpoint connector",
                integrationName: "Registry name",
                integrationServerCertificateValidationEnabled: {
                    false: "Yes",
                    title: "Accept any server SSL certificates",
                    true: "No"
                },
                integrationToken: "Password",
                integrationUserName: "Username",
                propertyChanges: "Updated",
                tenantType: "Provider"
            }));

    const integrationAuditEvent = context.auditEventModel.auditEvent as Contract.CiIntegrationAuditEvent;
    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>([]).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.integrationName(),
                    integrationAuditEvent.integrationName),
                new AuditEventDefinitionDetailItem(
                    localization.integrationServerCertificateValidationEnabled.title(),
                    integrationAuditEvent.integrationServerCertificateValidationEnabled
                        ? localization.integrationServerCertificateValidationEnabled.true()
                        : localization.integrationServerCertificateValidationEnabled.false())).
            concatIf(
                !_.isNil(integrationAuditEvent.endpoint),
                () =>
                    new AuditEventDefinitionDetailItem(
                        localization.integrationEndpoint(),
                        DnsEndpointHelper.getHttpsEndpointString(integrationAuditEvent.endpoint!))).
            concatIf(
                !_.isNil(integrationAuditEvent.endpointConnectorName),
                () =>
                    new AuditEventDefinitionDetailItem(
                        localization.integrationEndpointConnectorName(),
                        integrationAuditEvent.endpointConnectorName!)).
            concatIf(
                TypeHelper.extendOrImplement(
                    integrationAuditEvent.typeName,
                    Contract.TypeNames.CiIntegrationUpdateAuditEvent),
                () => {
                    const properties =
                        _.filter([
                            (integrationAuditEvent as Contract.CiIntegrationUpdateAuditEvent).integrationNameChanged
                                ? localization.integrationName()
                                : undefined,
                            (integrationAuditEvent as Contract.CiIntegrationUpdateAuditEvent).integrationServerCertificateValidationChanged
                                ? localization.integrationServerCertificateValidationEnabled.title()
                                : undefined,
                            (integrationAuditEvent as Contract.CiIntegrationUpdateAuditEvent).integrationTokenChanged
                                ? localization.integrationToken()
                                : undefined,
                            (integrationAuditEvent as Contract.CiIntegrationUpdateAuditEvent).integrationUserNameChanged
                                ? localization.integrationUserName()
                                : undefined
                        ]);
                    return (
                        _<AuditEventDefinitionDetailItem>([]).
                            concatIf(
                                !_.isEmpty(properties),
                                new AuditEventDefinitionDetailItem(
                                    localization.propertyChanges(),
                                    localizeList(properties)!)).
                            value());
                }).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.tenantType(),
                    tenantTypeTranslator(integrationAuditEvent.integrationType))).
            value());
}