import { BreakpointResizer, SlideItems } from "@infrastructure";
import { Grid2 } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import { Contract } from "../../../../../../../../controllers";
import { CustomerConsoleAppUrlHelperGetEntitiesRelativeUrlFiltersSelection, EntityTypeMetadataModelHelper } from "../../../../../../../../utilities";
import { EntityCategoryData } from "../../../../utilities";
import { EntityCategory } from "./components";

type EntitiesProps = {
    entitiesRelativeUrlFilters?: Partial<CustomerConsoleAppUrlHelperGetEntitiesRelativeUrlFiltersSelection>;
    entityTypeCategoryToEntityCategoryDataMap: Dictionary<EntityCategoryData>;
    entityTypeEntitiesViewNameToCountMap: Dictionary<number>;
};

export function Entities({ entitiesRelativeUrlFilters, entityTypeCategoryToEntityCategoryDataMap, entityTypeEntitiesViewNameToCountMap }: EntitiesProps) {
    const entityTypeCategories =
        useMemo(
            () =>
                _(Contract.ObjectTypeCategory).
                    filter(objectTypeCategory => entityTypeCategoryToEntityCategoryDataMap[objectTypeCategory]?.resourceCount > 0).
                    orderBy(
                        objectTypeCategory => objectTypeCategory,
                        "asc").
                    value(),
            [entityTypeCategoryToEntityCategoryDataMap]);

    const [tilesContainerWidth, setTilesContainerWidth] = useState(0);
    const columnsCount =
        tilesContainerWidth >= breakpointX
            ? 5
            : 4;
    const onBreakpointChange =
        useCallback(
            (breakpointX: number) => {
                if (tilesContainerWidth !== breakpointX) {
                    setTilesContainerWidth(breakpointX);
                }
            },
            [tilesContainerWidth]);

    return (
        <Fragment>
            <BreakpointResizer
                breakpointXs={[breakpointX]}
                onSize={onBreakpointChange}/>
            <SlideItems
                buttonSx={{ top: "calc(50% - 16px)" }}
                chunkSize={columnsCount * 2}
                items={entityTypeCategories}
                sx={{ height: "calc(100% - 8px)" }}>
                {entityTypeCategoryChunk =>
                    <Grid2
                        columns={columnsCount}
                        container={true}
                        spacing={1}
                        sx={{ flex: 1 }}>
                        {_.map(
                            entityTypeCategoryChunk,
                            entityTypeCategory =>
                                <Grid2
                                    className={`item ${entityTypeCategory}`}
                                    key={entityTypeCategory}
                                    size={{ xs: 1 }}
                                    sx={{ height: "50%" }}>
                                    <EntityCategory
                                        category={entityTypeCategory}
                                        disabled={entityTypeCategoryToEntityCategoryDataMap[entityTypeCategory].disabled}
                                        entityRelativeUrlFilters={entitiesRelativeUrlFilters}
                                        entityTypeEntitiesViewNameToCountMap={
                                            _.pickBy(
                                                entityTypeEntitiesViewNameToCountMap,
                                                (_entityTypeEntitiesCount, entityTypeEntitiesViewName) =>
                                                    EntityTypeMetadataModelHelper.get(entityTypeEntitiesViewName).category === entityTypeCategory)}
                                        resourceCount={entityTypeCategoryToEntityCategoryDataMap[entityTypeCategory].resourceCount}
                                        title={entityTypeCategoryToEntityCategoryDataMap[entityTypeCategory].title}/>
                                </Grid2>)}
                    </Grid2>}
            </SlideItems>
        </Fragment>);
}

const breakpointX = 1206;