﻿import { useLocalization } from "@infrastructure";
import { Stack, TextField } from "@mui/material";
import React from "react";
import { Contract, useTheme } from "../../../../../../../../../../../../../../../common";
import { InsertRequestQueryParameters } from "../AddOrEdit";

type SuccessProps = {
    consentResponse?: InsertRequestQueryParameters;
    updatedTenantConfiguration?: Contract.AadTenantConfiguration;
};

export function Success({ consentResponse, updatedTenantConfiguration }: SuccessProps) {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.aadTenants.addOrEdit.success",
            () => ({
                id: "Tenant ID",
                name: "Tenant name",
                primaryDomain: "Primary Domain"
            }));

    const theme = useTheme();
    return (
        <Stack
            spacing={5}
            sx={{ maxWidth: theme.spacing(75) }}>
            <TextField
                fullWidth={true}
                label={localization.id()}
                slotProps={{ input: { readOnly: true } }}
                value={updatedTenantConfiguration?.rawId ?? consentResponse!.aadTenantId}
                variant="outlined"/>
            <TextField
                fullWidth={true}
                label={localization.name()}
                slotProps={{ input: { readOnly: true } }}
                value={updatedTenantConfiguration?.name ?? consentResponse!.name}
                variant="outlined"/>
            <TextField
                fullWidth={true}
                label={localization.primaryDomain()}
                slotProps={{ input: { readOnly: true } }}
                value={updatedTenantConfiguration?.primaryDomainName ?? consentResponse!.primaryDomainName}
                variant="outlined"/>
        </Stack>);
}