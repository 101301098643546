import { Link, Step, Steps, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useOciConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetOciFssFileSystemRiskContext } from "../contexts";

export function useOciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledRiskDefinition(riskModel: Contract.RiskModel) {
    const fssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledRiskModel = riskModel as Contract.OciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledRiskModel;
    const risk = riskModel.risk as Contract.OciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledRisk;
    const fileSystemModel = entityModelStore.useGet(risk.entityId) as Contract.OciFssFileSystemModel;

    const getOciFssFileSystemRiskContext = useGetOciFssFileSystemRiskContext();

    const consoleSignInStepTranslator = useOciConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.compliance.useOciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledRiskDefinition",
            () => ({
                description: "{{fileSystem}} root user access is not restricted",
                sections: {
                    resolution: {
                        step1: {
                            link: "OCI documentation",
                            text: "File system export options are a set of parameters within the export that specify the level of access granted to NFS clients when they connect to a mount target. Learn more in the {{exportOptionsDocumentationLink}}"
                        },
                        step2: {
                            step1: "**Squash:Root**",
                            step2: "**Squish UID:65534**",
                            step3: "**Squish GID:65534**",
                            title: "Click on each export path and ensure options are set using the following values"
                        },
                        step4: "Click **Save changes**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            fileSystem:
                <Entity
                    entityIdOrModel={fileSystemModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.OciConsoleView.Fss,
                fileSystemModel.consoleUrl!),
            localization.sections.resolution.step1.text({
                exportOptionsDocumentationLink:
                    <Link
                        urlOrGetUrl={fssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledRiskModel.exportOptionsDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step1.link()}
                    </Link>
            }),
            new Step(
                localization.sections.resolution.step2.title(),
                {
                    contentElement:
                        <Steps variant="bullets">
                            {localization.sections.resolution.step2.step1()}
                            {localization.sections.resolution.step2.step2()}
                            {localization.sections.resolution.step2.step3()}
                        </Steps>
                })
        ],
        riskModel,
        () => {
            const ociFssFileSystemRiskContext = getOciFssFileSystemRiskContext(fileSystemModel);
            return [
                ociFssFileSystemRiskContext.generalInformation,
                ociFssFileSystemRiskContext.mountTargetExports,
                ociFssFileSystemRiskContext.sensitive,
                ociFssFileSystemRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}