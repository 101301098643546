import { Divider } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo } from "react";
import { Action0, SelectionActionsAction } from "@infrastructure";
import { Contract, CreateJiraIssuesSelectionAction, CreateServiceNowIncidentsSelectionAction, OpenIcon, RiskStatusIcon, RiskType, UpdateJiraIssuesSelectionAction, UpdateServiceNowIncidentsSelectionAction, useTheme } from "../../../../../../../../../../common";
import { InsertRiskNoteSelectionAction, UpdateStarredSelectionAction, UpdateStatusSelectionAction } from "../../../../components";
import { SendMailPerResourceOwnerAction, SendMailSelectionAction, SendWebhookSelectionAction } from "./components";

export function useCommonSelectionActions(riskType: RiskType, view: Contract.RisksView) {
    const theme = useTheme();
    return useMemo(
        () => ({
            close:
                (reloadRiskModels: (status?: Contract.RiskStatus | undefined) => Promise<void>) =>
                    <SelectionActionsAction
                        id={`UpdateStatusButton-${Contract.RiskStatus.Closed}`}
                        key={`UpdateStatusButton-${Contract.RiskStatus.Closed}`}
                        permissions={[Contract.IdentityPermission.SecurityWrite]}>
                        <UpdateStatusSelectionAction
                            icon={<RiskStatusIcon status={Contract.RiskStatus.Closed}/>}
                            reloadRiskModels={reloadRiskModels}
                            riskType={riskType}
                            status={Contract.RiskStatus.Closed}
                            view={view}/>
                    </SelectionActionsAction>,
            comment:
                (clearSelection: Action0) =>
                    <SelectionActionsAction
                        id="InsertRiskNoteButton"
                        key="InsertRiskNoteButton"
                        permissions={[Contract.IdentityPermission.SecurityWrite]}>
                        <InsertRiskNoteSelectionAction clearSelection={clearSelection}/>
                    </SelectionActionsAction>,
            divider:
                <SelectionActionsAction
                    id="divider"
                    key="divider">
                    <Divider
                        flexItem={true}
                        orientation="vertical"
                        sx={{ margin: theme.spacing(1.5, 0.25) }}/>
                </SelectionActionsAction>,
            jiraCreate:
                (getSelectedRiskIdToScopeIdsMap: (() => Dictionary<string[]>), reloadRiskModels: (status?: Contract.RiskStatus | undefined) => Promise<void>, riskType: RiskType) =>
                    <SelectionActionsAction
                        id="CreateJiraIssuesButton"
                        key="CreateJiraIssuesButton"
                        permissions={[Contract.IdentityPermission.SecurityWrite]}>
                        <CreateJiraIssuesSelectionAction
                            getItemIssueCreationCount={() => 1}
                            getSelectedRiskIdToScopeIdsMap={getSelectedRiskIdToScopeIdsMap}
                            reloadItems={reloadRiskModels}
                            riskType={riskType}
                            startElement={
                                <Divider
                                    flexItem={true}
                                    orientation="vertical"
                                    sx={{ margin: theme.spacing(1.5, 0.25) }}/>}/>
                    </SelectionActionsAction>,
            jiraUpdate:
                (getSelectedRiskIdToScopeIdsMap: (() => Dictionary<string[]>), reloadRiskModels: (status?: Contract.RiskStatus | undefined) => Promise<void>, riskType: RiskType) =>
                    <SelectionActionsAction
                        id="UpdateJiraIssuesButton"
                        key="UpdateJiraIssuesButton"
                        permissions={[Contract.IdentityPermission.SecurityWrite]}>
                        <UpdateJiraIssuesSelectionAction
                            getItemIssueUpdateCount={riskModelItem => (riskModelItem as Contract.RiskModel).riskState?.integration.jira.issueIds.length ?? 0}
                            getSelectedRiskIdToScopeIdsMap={getSelectedRiskIdToScopeIdsMap}
                            getSelectionRiskIdToJiraIssueIdMap={
                                riskModelItems =>
                                    _(riskModelItems as Contract.RiskModel[]).
                                        keyBy(riskModel => riskModel.risk.id).
                                        mapValues(
                                            riskModel =>
                                                _.map(
                                                    riskModel.riskState?.integration.jira.issueIds,
                                                    jiraIssueId => jiraIssueId
                                                )).
                                        value()}
                            reloadItems={reloadRiskModels}
                            riskType={riskType}/>
                    </SelectionActionsAction>,
            open:
                (reloadRiskModels: (status?: Contract.RiskStatus | undefined) => Promise<void>) =>
                    <SelectionActionsAction
                        id={`UpdateStatusButton-${Contract.RiskStatus.Open}`}
                        key={`UpdateStatusButton-${Contract.RiskStatus.Open}`}
                        permissions={[Contract.IdentityPermission.SecurityWrite]}>
                        <UpdateStatusSelectionAction
                            icon={<OpenIcon/>}
                            reloadRiskModels={reloadRiskModels}
                            riskType={riskType}
                            status={Contract.RiskStatus.Open}
                            view={view}/>
                    </SelectionActionsAction>,
            sendMail:
                (clearSelection: Action0) =>
                    <SelectionActionsAction
                        id="SendMailButton"
                        key="SendMailButton"
                        permissions={[Contract.IdentityPermission.SecurityRead]}>
                        <SendMailSelectionAction clearSelection={clearSelection}/>
                    </SelectionActionsAction>,
            sendPerResourceOwner:
                (clearSelection: Action0) =>
                    <SelectionActionsAction
                        id="SendMailPerResourceOwnerButton"
                        key="SendMailPerResourceOwnerButton"
                        permissions={[Contract.IdentityPermission.SecurityRead]}>
                        <SendMailPerResourceOwnerAction clearSelection={clearSelection}/>
                    </SelectionActionsAction>,
            sendWebhook:
                (clearSelection: Action0, getSelectedRiskIdToScopeIdsMap: (() => Dictionary<string[]>)) =>
                    <SelectionActionsAction
                        id="SendWebhookButton"
                        key="SendWebhookButton"
                        permissions={[Contract.IdentityPermission.SecurityRead]}>
                        <SendWebhookSelectionAction
                            clearSelection={() => clearSelection()}
                            getSelectedRiskIdToScopeIdsMap={getSelectedRiskIdToScopeIdsMap}/>
                    </SelectionActionsAction>,
            serviceNowCreate:
                (getSelectedRiskIdToScopeIdsMap: (() => Dictionary<string[]>), reloadRiskModels: (status?: Contract.RiskStatus | undefined) => Promise<void>) =>
                    <SelectionActionsAction
                        id="CreateServiceNowIncidentButton"
                        key="CreateServiceNowIncidentButton"
                        permissions={[Contract.IdentityPermission.SecurityWrite]}>
                        <CreateServiceNowIncidentsSelectionAction
                            getItemIncidentCreationCount={() => 1}
                            getSelectedRiskIdToScopeIdsMap={getSelectedRiskIdToScopeIdsMap}
                            reloadItems={reloadRiskModels}
                            riskType={riskType}
                            startElement={
                                <Divider
                                    flexItem={true}
                                    orientation="vertical"
                                    sx={{ margin: theme.spacing(1.5, 0.25) }}/>}/>
                    </SelectionActionsAction>,
            serviceNowUpdate:
                (getSelectedRiskIdToScopeIdsMap: (() => Dictionary<string[]>), reloadRiskModels: (status?: Contract.RiskStatus | undefined) => Promise<void>) =>
                    <SelectionActionsAction
                        id="UpdateServiceNowIncidentButton"
                        key="UpdateServiceNowIncidentButton"
                        permissions={[Contract.IdentityPermission.SecurityWrite]}>
                        <UpdateServiceNowIncidentsSelectionAction
                            getItemIncidentUpdateCount={riskModelItem => (riskModelItem as Contract.RiskModel).riskState?.integration.serviceNow.incidentIds.length ?? 0}
                            getRiskIdToServiceNowIncidentIdsMap={
                                riskModelItems =>
                                    _(riskModelItems as Contract.RiskModel[]).
                                        keyBy(riskModel => riskModel.risk.id).
                                        mapValues(
                                            riskModel =>
                                                _.map(
                                                    riskModel.riskState?.integration.serviceNow.incidentIds,
                                                    serviceNowIncidentId => serviceNowIncidentId)).
                                        value()}
                            getSelectedRiskIdToScopeIdsMap={getSelectedRiskIdToScopeIdsMap}
                            reloadItems={reloadRiskModels}
                            riskType={riskType}/>
                    </SelectionActionsAction>,
            star:
                (reloadRiskModels: (status?: Contract.RiskStatus | undefined) => Promise<void>) =>
                    <SelectionActionsAction
                        id="UpdateStarredButton"
                        key="UpdateStarredButton"
                        permissions={[Contract.IdentityPermission.SecurityWrite]}>
                        <UpdateStarredSelectionAction reloadRiskModels={reloadRiskModels}/>
                    </SelectionActionsAction>
        }),
        [riskType, view]);
}