﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, RiskController } from "../../../../../../../controllers";
import { SummaryItem, SummaryItemItem } from "../../../../../components";
import { useRiskResolutionAutomationContext } from "../../../../../utilities";
import { AzureRiskResolutionAutomationContext } from "../useAzureDefinition";

export function AzureSummaryItem() {
    const {
        changeModels,
        isChangeResolvableChangeable,
        updatedChangeMap
    } = useRiskResolutionAutomationContext() as AzureRiskResolutionAutomationContext;

    const localization =
        useLocalization(
            "common.riskResolutionAutomation.hooks.useDefinition.hooks.useAzureDefinition.azureSummaryItem",
            () => ({
                confirm: "Are you sure you want to continue and apply these changes in your Azure environment?",
                updateStatus: "Update models"
            }));

    const items =
        useMemo(
            () =>
                _(changeModels).
                    filter(changeModel => isChangeResolvableChangeable(changeModel)).
                    map(
                        changeModel =>
                            new AzureSummaryItemItem(
                                updatedChangeMap[changeModel.change.id] ?? changeModel.change,
                                changeModel.changeDatas) as SummaryItemItem).
                    value(),
            []);

    return (
        <SummaryItem
            confirm={localization.confirm()}
            items={items}/>);
}

export class AzureSummaryItemItem extends SummaryItemItem {
    constructor(
        change: Contract.Change,
        changeDatas: Contract.ChangeModelChangeData[]) {
        super(
            change,
            changeDatas);
    }

    protected async executeCore(): Promise<boolean> {
        this.resolveRiskAutomationResponse =
            await RiskController.resolveRiskAutomation(
                new Contract.RiskControllerResolveAzureRiskAutomationRequest(
                    this.change,
                    this.riskId));
        return this.resolveRiskAutomationResponse.success;
    }
}