﻿import _ from "lodash";
import { useCallback } from "react";
import { Contract } from "../../../../../../../../../../../../../../../common";
import { useGetAzureManagedIdentitySystemManagedIdentityApiManagementServiceResourceGenericResourceResolutionChangeSteps, useGetAzureManagedIdentitySystemManagedIdentityDataFactoryFactoryGenericResourceResolutionChangeSteps, useGetAzureManagedIdentitySystemManagedIdentityResolutionChangeSteps, useGetAzureManagedIdentitySystemManagedIdentitySqlManagedInstanceGenericResourceResolutionChangeSteps, useGetAzureManagedIdentityUserManagedIdentityResolutionChangeSteps } from "./hooks";

export function useGetResolutionChangeSteps() {
    const getAzureManagedIdentitySystemManagedIdentityApiManagementServiceResourceGenericResourceResolutionChangeSteps = useGetAzureManagedIdentitySystemManagedIdentityApiManagementServiceResourceGenericResourceResolutionChangeSteps();
    const getAzureManagedIdentitySystemManagedIdentityDataFactoryFactoryGenericResourceResolutionChangeSteps = useGetAzureManagedIdentitySystemManagedIdentityDataFactoryFactoryGenericResourceResolutionChangeSteps();
    const getAzureManagedIdentitySystemManagedIdentityResolutionChangeSteps = useGetAzureManagedIdentitySystemManagedIdentityResolutionChangeSteps();
    const getAzureManagedIdentitySystemManagedIdentitySqlManagedInstanceGenericResourceResolutionChangeSteps = useGetAzureManagedIdentitySystemManagedIdentitySqlManagedInstanceGenericResourceResolutionChangeSteps();
    const getAzureManagedIdentityUserManagedIdentityResolutionChangeSteps = useGetAzureManagedIdentityUserManagedIdentityResolutionChangeSteps();

    return useCallback(
        (managedIdentityModel: Contract.AzureManagedIdentityManagedIdentityModel, risk: Contract.AzureManagedIdentityManagedIdentityInactiveRisk) => {
            if (_.isEmpty(risk.resolutionChanges)) {
                return [];
            }

            if (managedIdentityModel.type === Contract.AzureManagedIdentityManagedIdentityModelType.User) {
                return getAzureManagedIdentityUserManagedIdentityResolutionChangeSteps(risk);
            }

            switch ((risk.resolutionChanges[0] as Contract.AzureDeleteSystemManagedIdentityChange).systemManagedIdentityScopeResourceTypeName) {
                case Contract.TypeNames.AzureApiManagementServiceResourceGenericResource:
                    return getAzureManagedIdentitySystemManagedIdentityApiManagementServiceResourceGenericResourceResolutionChangeSteps(managedIdentityModel, risk);
                case Contract.TypeNames.AzureDataFactoryFactoryGenericResource:
                    return getAzureManagedIdentitySystemManagedIdentityDataFactoryFactoryGenericResourceResolutionChangeSteps(managedIdentityModel, risk);
                case Contract.TypeNames.AzureSqlManagedInstanceGenericResource:
                    return getAzureManagedIdentitySystemManagedIdentitySqlManagedInstanceGenericResourceResolutionChangeSteps(managedIdentityModel, risk);
                default:
                    return getAzureManagedIdentitySystemManagedIdentityResolutionChangeSteps(managedIdentityModel, risk);
            }
        },
        []);
}