﻿import { DataTable, DataTableActions, DataTableColumn, ItemSelector, optionalTableCell, StringHelper, TimeFormatter, useChangeEffect, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { Contract, Entity, entityModelStore, InfoCard, InfoItem, InlineResourceTags } from "../../../../../../../../../../../../common";
import { useAwsEksFargateProfileStatusTranslator } from "../../../../../../../../hooks";
import { EntitiesInfoItem } from "../../../../../../components";

type FargateProfileInfoCardProps = {
    clusterModel: Contract.AwsEksClusterModel;
};

export function FargateProfileInfoCard({ clusterModel }: FargateProfileInfoCardProps) {
    const fargateProfileModels = entityModelStore.useGet(clusterModel.fargateProfileIds) as Contract.AwsEksFargateProfileModel[];
    const [fargateProfileModelMap, orderedFargateProfileIds] =
        useMemo(
            () => {
                const fargateProfileModelMap =
                    _.keyBy(
                        fargateProfileModels,
                        fargateProfileModel => fargateProfileModel.id);

                const orderedFargateProfileIds =
                    _(fargateProfileModels).
                        orderBy(fargateProfileModel => StringHelper.getSortValue(fargateProfileModel.entity.displayName)).
                        map(fargateProfileModel => fargateProfileModel.id).
                        value();

                return [fargateProfileModelMap, orderedFargateProfileIds];
            },
            []);

    const [selectedFargateProfileId, setSelectedFargateProfileId] = useState(() => orderedFargateProfileIds[0]);
    const podSelectorsDataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => {
            podSelectorsDataTableActionsRef.current!.reset();
        },
        [selectedFargateProfileId]);

    const eksFargateProfileStatusTranslator = useAwsEksFargateProfileStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsEksClusterDefinition.fargateProfileInfoCard",
            () => ({
                empty: "No Fargate Profiles",
                info: {
                    creationTime: "Creation Time",
                    podExecutionRoleReference: "Pod execution IAM role",
                    podSelectors: {
                        namespace: "Namespace",
                        tags: "Labels",
                        title: "Pod Selectors"
                    },
                    status: "Status",
                    subnetIds: "Subnets"
                },
                title: "Fargate Profiles ({{fargateProfileCount | NumberFormatter.humanize}})"
            }));

    const selectedFargateProfileModel = fargateProfileModelMap[selectedFargateProfileId];
    const selectedFargateProfile = selectedFargateProfileModel.entity as Contract.AwsEksFargateProfile;
    return (
        <InfoCard title={localization.title({ fargateProfileCount: _.size(clusterModel.fargateProfileIds) })}>
            <ItemSelector
                dense={true}
                fullWidth={true}
                items={orderedFargateProfileIds}
                selectedItem={selectedFargateProfileId}
                sorted={false}
                onSelectedItemChanged={selectedFargateProfileId => setSelectedFargateProfileId(selectedFargateProfileId)}>
                {(fargateProfileId, inline) =>
                    <Entity
                        entityIdOrModel={fargateProfileId}
                        linkOptions={{ disabled: true }}
                        variant={
                            inline
                                ? "text"
                                : "iconTextTypeTenant"}/>}
            </ItemSelector>
            <InfoCard columns={true}>
                <InfoItem
                    title={localization.info.creationTime()}
                    value={TimeFormatter.profileFormatDateTime(selectedFargateProfileModel.creationTime)}/>
                <InfoItem
                    title={localization.info.status()}
                    value={eksFargateProfileStatusTranslator(selectedFargateProfile.status)}/>
                <EntitiesInfoItem
                    entityIdsOrModels={selectedFargateProfile.subnetIds}
                    entityTypeName={Contract.TypeNames.AwsEc2Subnet}
                    title={localization.info.subnetIds()}/>
                <EntitiesInfoItem
                    entityIdsOrModels={selectedFargateProfile.podExecutionRoleReference.idReference}
                    entityTypeName={Contract.TypeNames.AwsIamRole}
                    title={localization.info.podExecutionRoleReference()}/>
            </InfoCard>
            <InfoCard title={localization.info.podSelectors.title()}>
                <DataTable
                    actionsRef={podSelectorsDataTableActionsRef}
                    fetchItems={
                        () =>
                            _.orderBy(
                                selectedFargateProfile.podSelectors,
                                podSelector => StringHelper.getSortValue(podSelector.namespace))}
                    getItemId={(podSelector: Contract.AwsEksFargateProfilePodSelector) => _.indexOf(selectedFargateProfile.podSelectors, podSelector).toString()}
                    sortOptions={{ enabled: false }}
                    variant="card">
                    <DataTableColumn
                        id="namespace"
                        itemProperty={(podSelector: Contract.AwsEksFargateProfilePodSelector) => podSelector.namespace}
                        title={localization.info.podSelectors.namespace()}/>
                    <DataTableColumn
                        id="labels"
                        render={
                            optionalTableCell<Contract.AwsEksFargateProfilePodSelector>(
                                item =>
                                    _.isEmpty(item.tags)
                                        ? undefined
                                        : <InlineResourceTags tags={item.tags}/>)}
                        title={localization.info.podSelectors.tags()}/>
                </DataTable>
            </InfoCard>
        </InfoCard>);
}