import _, { Dictionary } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { map, useChangeEffect, useExecuteOperation } from "@infrastructure";
import { ConfigurationController, Contract, FeatureHelper, LicensingHelper, ReportHelper, ReportItems, RiskPolicyTypeMetadataHelper, RiskType, scopeSystemEntityModelStore, TenantHelper, tenantModelStore, useBuiltInComplianceTypes, useComplianceSectionDatas } from "../../../../../../../../common";
import { useGetEntitiesReportItem } from "../../../../../Entities";
import { FiltersHelper, useGetRiskReportItem } from "../../../../../Risks";
import { useGetComplianceReportItem, useGetComplianceScopesReportItem, useGetEventsReportItem, useGetWorkloadResourcePackageVulnerabilitiesReportItem } from "./hooks";

type UseItemsProps = {
    reportDefinitionConfiguration?: Contract.ReportDefinitionConfiguration;
    selectedScopeId: string;
};

export function useItems({ reportDefinitionConfiguration, selectedScopeId }: UseItemsProps): ReportItems {
    const reportsTenantTypes =
        useMemo(
            () => TenantHelper.ReportsTenantTypes,
            []);
    const reportActiveTenantTypes =
        _.intersection(
            tenantModelStore.useGetFilteredActiveTenantTypes(),
            reportsTenantTypes);

    const { customComplianceSectionDatas } = useComplianceSectionDatas(selectedScopeId);
    const [, enabledBuiltInComplianceTypes] = useBuiltInComplianceTypes(reportActiveTenantTypes);

    const filteredCustomComplianceIds =
        useMemo(
            () => {
                const getRiskPolicyDatas =
                    (complianceSectionData: Contract.ComplianceSectionData): Contract.ComplianceRiskPolicyData[] =>
                        _(complianceSectionData.sectionDatas).
                            flatMap(complianceSectionDataSectionData => getRiskPolicyDatas(complianceSectionDataSectionData)).
                            concat(complianceSectionData.riskPolicyDatas).
                            value();

                return _(customComplianceSectionDatas).
                    filter(
                        customComplianceSectionData =>
                            customComplianceSectionData.hasResults &&
                            _(getRiskPolicyDatas(customComplianceSectionData)).
                                flatMap(
                                    complianceRiskPolicyData =>
                                        RiskPolicyTypeMetadataHelper.getTenantTypes(
                                            complianceRiskPolicyData.riskPolicyConfigurationTypeName,
                                            complianceRiskPolicyData.analysisGroupType)).
                                intersection(reportActiveTenantTypes).
                                some()).
                    map(customComplianceSectionData => customComplianceSectionData.identifier).
                    value();
            },
            [customComplianceSectionDatas, reportActiveTenantTypes]);

    const [{ allComplianceIdentifiers, customComplianceConfigurationMap }, refreshCompliancesRequest] =
        useExecuteOperation(
            useItems,
            async () => {
                let customComplianceConfigurationMap: Dictionary<Contract.CustomComplianceConfiguration> = {};
                let allComplianceIdentifiers: (Contract.BuiltInComplianceSectionType | string)[] = [];
                if (LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm)) {
                    const { customComplianceIds } = await ConfigurationController.getScopePathCustomComplianceIds(new Contract.ConfigurationControllerGetScopePathCustomComplianceIdsRequest(selectedScopeId));
                    const customComplianceModels = await scopeSystemEntityModelStore.get(customComplianceIds);
                    customComplianceConfigurationMap =
                        _(customComplianceModels).
                            map(customComplianceModel => customComplianceModel.configuration as Contract.CustomComplianceConfiguration).
                            filter(customComplianceConfiguration => _.includes(filteredCustomComplianceIds, customComplianceConfiguration.id)).
                            keyBy(customComplianceConfiguration => customComplianceConfiguration.id).
                            value();

                    allComplianceIdentifiers =
                        _([] as (Contract.BuiltInComplianceSectionType | string)[]).
                            concat(enabledBuiltInComplianceTypes).
                            concat(_.keys(customComplianceConfigurationMap)).
                            value();
                }

                return {
                    allComplianceIdentifiers,
                    customComplianceConfigurationMap
                };
            });

    useChangeEffect(
        () => {
            async function refresh() {
                await refreshCompliancesRequest();
            }

            refresh();
        },
        [enabledBuiltInComplianceTypes, filteredCustomComplianceIds]);

    const [complianceIdentifier, setComplianceIdentifier] =
        useState(
            () => {
                if (_.isNil(reportDefinitionConfiguration)) {
                    return undefined;
                }

                return map(
                    reportDefinitionConfiguration!.typeName,
                    {
                        [Contract.TypeNames.BuiltInComplianceReportDefinitionConfiguration]: () => (reportDefinitionConfiguration as Contract.BuiltInComplianceReportDefinitionConfiguration).sectionType,
                        [Contract.TypeNames.ComplianceScopesReportDefinitionConfiguration]: () => (reportDefinitionConfiguration as Contract.ComplianceScopesReportDefinitionConfiguration).sectionIdentifier,
                        [Contract.TypeNames.CustomComplianceReportDefinitionConfiguration]: () => (reportDefinitionConfiguration as Contract.CustomComplianceReportDefinitionConfiguration).customComplianceId
                    },
                    () => undefined);
            });

    useEffect(
        () =>
            setComplianceIdentifier(
                complianceId =>
                    _.includes(allComplianceIdentifiers, complianceId)
                        ? complianceId
                        : undefined),
        [allComplianceIdentifiers]);

    const [reportTypes, vulnerabilityReportTypes] =
        useMemo(
            () => {
                const reportTypes =
                    _(Contract.ReportType).
                        filter(
                            reportType =>
                                reportType !== Contract.ReportType.EntityAccess &&
                                (reportType !== Contract.ReportType.Events ||
                                    FeatureHelper.enabled(Contract.FeatureName.UiEventsReportEnabled)) &&
                                reportType !== Contract.ReportType.WorkloadAnalysis &&
                                reportType !== Contract.ReportType.WorkloadResources).
                        filter(
                            reportType =>
                                !_(ReportHelper.getTenantTypes(reportType)).
                                    intersection(reportActiveTenantTypes).
                                    isEmpty() && (
                                    reportType !== Contract.ReportType.Compliance ||
                                    LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm)) &&
                                (
                                    reportType !== Contract.ReportType.Vulnerabilities ||
                                    LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp))).
                        value();
                const vulnerabilityReportTypes =
                    _(Contract.VulnerabilityReportType).
                        filter(
                            vulnerabilityReportType =>
                                vulnerabilityReportType !== Contract.VulnerabilityReportType.VirtualMachines ||
                                _.size(reportActiveTenantTypes) > 1 ||
                                _.first(reportActiveTenantTypes) !== Contract.TenantType.Ci).
                        value();
                return [reportTypes, vulnerabilityReportTypes];
            },
            [reportActiveTenantTypes, reportActiveTenantTypes]);

    const getComplianceReportItem = useGetComplianceReportItem(reportDefinitionConfiguration?.contentType);
    const getComplianceScopesReportItem = useGetComplianceScopesReportItem();
    const complianceReportItemProps =
        useMemo(
            () => ({
                allComplianceIdentifiers,
                complianceIdentifier,
                customComplianceConfigurationMap,
                setComplianceIdentifier
            }),
            [allComplianceIdentifiers, customComplianceConfigurationMap, complianceIdentifier]);

    const getEventReportItem = useGetEventsReportItem((reportDefinitionConfiguration as Contract.EventsReportDefinitionConfiguration)?.filterMap);

    const entitiesReportData =
        useMemo(
            () => ({
                entityTypeName:
                    _.isNil(reportDefinitionConfiguration)
                        ? undefined
                        : (reportDefinitionConfiguration as Contract.InventoryReportDefinitionConfiguration).entityTypeName,
                filterMap:
                    !_.isNil(reportDefinitionConfiguration) &&
                    !_.isNil((reportDefinitionConfiguration as Contract.InventoryReportDefinitionConfiguration | Contract.EventsReportDefinitionConfiguration).filterMap)
                        ? JSON.parse((reportDefinitionConfiguration as Contract.InventoryReportDefinitionConfiguration | Contract.EventsReportDefinitionConfiguration).filterMap!)
                        : {}
            }),
            [reportDefinitionConfiguration]);

    const getEntitiesReportItem = useGetEntitiesReportItem(entitiesReportData, reportActiveTenantTypes);

    const getRiskReportItemProps =
        (riskType: RiskType, filtersMap: () => Dictionary<any>) =>
            ({
                additionalColumnResourceTagKeys: (reportDefinitionConfiguration as Contract.RisksReportDefinitionConfiguration)?.additionalColumnResourceTagKeys,
                filtersMap:
                    _.isNil((reportDefinitionConfiguration as Contract.RisksReportDefinitionConfiguration)?.filters)
                        ? undefined
                        : filtersMap(),
                reportType:
                    riskType === RiskType.Cloud
                        ? Contract.ReportType.Risks
                        : Contract.ReportType.CodeRisks,
                riskType,
                scopeId: selectedScopeId,
                status: (reportDefinitionConfiguration as Contract.RisksReportDefinitionConfiguration)?.status
            });

    const cloudRiskReportItemProps =
        useMemo(
            () => getRiskReportItemProps(
                RiskType.Cloud,
                () => FiltersHelper.toFilterMapFromRequestCloudFilters((reportDefinitionConfiguration as Contract.CloudRisksReportDefinitionConfiguration).filters as Contract.RiskControllerCloudRiskModelFilters)),
            [reportDefinitionConfiguration, selectedScopeId]);
    const codeRiskReportItemProps =
        useMemo(
            () => getRiskReportItemProps(
                RiskType.Code,
                () => FiltersHelper.toFilterMapFromRequestCodeFilters((reportDefinitionConfiguration as Contract.CodeRisksReportDefinitionConfiguration).filters as Contract.RiskControllerCodeRiskModelFilters)),
            [reportDefinitionConfiguration, selectedScopeId]);

    const getCloudRiskReportItem = useGetRiskReportItem(cloudRiskReportItemProps);
    const getCodeRiskReportItem = useGetRiskReportItem(codeRiskReportItemProps);

    const workloadResourcePackageVulnerabilitiesReportDefinitionConfiguration = reportDefinitionConfiguration as Contract.WorkloadResourcePackageVulnerabilitiesReportDefinitionConfiguration;
    const vulnerabilityReportData =
        useMemo(
            () => ({
                filters: workloadResourcePackageVulnerabilitiesReportDefinitionConfiguration?.filters,
                includeDescription: workloadResourcePackageVulnerabilitiesReportDefinitionConfiguration?.includeDescription ?? false,
                includeResolvedVulnerabilities: (workloadResourcePackageVulnerabilitiesReportDefinitionConfiguration as Contract.VirtualMachinePackageVulnerabilitiesReportDefinitionConfiguration)?.includeResolvedVulnerabilities ?? false,
                vulnerabilityReportType: workloadResourcePackageVulnerabilitiesReportDefinitionConfiguration?.vulnerabilityReportType
            }),
            [workloadResourcePackageVulnerabilitiesReportDefinitionConfiguration]);

    const getWorkloadResourcePackageVulnerabilityReportItem = useGetWorkloadResourcePackageVulnerabilitiesReportItem(vulnerabilityReportData);

    return useMemo(
        () => _.pick(
            {
                [Contract.ReportType.AwsIamPolicies]: () => undefined,
                [Contract.ReportType.AwsIamUserAccessKeys]: () => undefined,
                [Contract.ReportType.AwsSsoUserTenantAssignments]: () => undefined,
                [Contract.ReportType.AzureAuthorizationRoleAssignments]: () => undefined,
                [Contract.ReportType.CodeRisks]: () => getCodeRiskReportItem(),
                [Contract.ReportType.Compliance]: () => getComplianceReportItem(complianceReportItemProps),
                [Contract.ReportType.ComplianceScopes]: () => getComplianceScopesReportItem(complianceReportItemProps),
                [Contract.ReportType.Dashboard]: () => undefined,
                [Contract.ReportType.Events]: () => getEventReportItem(),
                [Contract.ReportType.GcpIamRoleBindings]: () => undefined,
                [Contract.ReportType.GcpIamServiceAccountUserManagedKeys]: () => undefined,
                [Contract.ReportType.Inventory]: () => getEntitiesReportItem(),
                [Contract.ReportType.RiskPolicy]: () => undefined,
                [Contract.ReportType.Risks]: () => getCloudRiskReportItem(),
                [Contract.ReportType.Vulnerabilities]: () => getWorkloadResourcePackageVulnerabilityReportItem(vulnerabilityReportTypes)
            },
            reportTypes),
        [cloudRiskReportItemProps, codeRiskReportItemProps, complianceReportItemProps, getCloudRiskReportItem, getCodeRiskReportItem, getComplianceReportItem, getEventReportItem, getEntitiesReportItem, getWorkloadResourcePackageVulnerabilityReportItem, reportActiveTenantTypes, vulnerabilityReportTypes, selectedScopeId]);
}