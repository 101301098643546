﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useOpTenantModelStatusTranslator() {
    const localization =
        useLocalization(
            "tenants.op.hooks.useOpTenantModelStatusTranslator",
            () => ({
                [Contract.TypeNames.OpTenantModelStatus]: {
                    [Contract.OpTenantModelStatus.Deleting]: "Deleting, this may take a while...",
                    [Contract.OpTenantModelStatus.Valid]: "Active"
                }
            }));
    return (status: Contract.OpTenantModelStatus) => localization[Contract.TypeNames.OpTenantModelStatus][status]();
}