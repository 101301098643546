import { useLocalization } from "@infrastructure";

export function useAwsDeleteSecretPolicyChangeDefinition() {
    const localization =
        useLocalization(
            "common.change.hooks.useDefinition.hooks.useAwsDefinition.hooks.useAwsDeleteSecretPolicyChangeDefinition",
            () => ({
                title: "Delete the secret policy"
            }));
    return {
        title: localization.title()
    };
}