import { InlineItems, MailParser, Message, useInputValidation, useLocalization, useOrderedWizardContext, useSetOrderedWizardContext } from "@infrastructure";
import { Box, FormControl, FormHelperText, Stack, TextField } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useAddOrEditContext, useSetAddOrEditContext } from "../..";
import { Contract, TenantController, tenantModelStore, useTheme } from "../../../../../../../../../../../../../../../../common";
import { InstructionsImageUrl } from "./images";

export function TenantItem() {
    const tenantModels = tenantModelStore.useGetPermittedGciTenants();
    const { tenantConfiguration, tenantRawId, tenantServiceAccountMail, tenantUserMail } = useAddOrEditContext();
    const [rawId, setRawId] = useState(tenantRawId);
    const [serviceAccountMail, setServiceAccountMail] = useState(tenantServiceAccountMail);
    const [userMail, setUserMail] = useState(tenantUserMail);

    const { executing, setLoaded, setValid, useNextEffect } = useOrderedWizardContext();
    const setAddOrEditContext = useSetAddOrEditContext();
    const setOrderedWizardContext = useSetOrderedWizardContext();
    useEffect(
        () => {
            setOrderedWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    sideElement: <img src={InstructionsImageUrl}/>
                }));
            setLoaded();
        },
        []);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.gciTenants.addOrEdit.tenantItem",
            () => ({
                actions: {
                    save: {
                        error: {
                            add: "Failed to add Google Workspace",
                            edit: "Failed to save Google Workspace"
                        }
                    },
                    validate: {
                        error: "Failed to validate Google Workspace",
                        [Contract.TypeNames.TenantControllerValidateGciTenantResult]: {
                            [Contract.TenantControllerValidateGciTenantResult.ApplicationServiceAccountUnauthorized]: "Service account impersonation permissions not granted. Ensure that \"iam.serviceAccounts.actAs\" is granted to the Tenable service account.",
                            [Contract.TenantControllerValidateGciTenantResult.ServiceAccountNotExist]: "Cannot find the service account.",
                            [Contract.TenantControllerValidateGciTenantResult.ServiceAccountTenantExternalIdTagMismatch]: "The service account project external ID label does not match. Update the labels accordingly.",
                            [Contract.TenantControllerValidateGciTenantResult.ServiceAccountTenantUnauthorized]: "The service account is missing permissions to the project. Assign the necessary role binding(s).",
                            [Contract.TenantControllerValidateGciTenantResult.ServiceAccountUnauthorized]: "The service account is missing permissions in Google Workspace. Ensure the right role has been assigned.",
                            [Contract.TenantControllerValidateGciTenantResult.ServiceAccountUserDelegationAuthorizationScopesMismatch]: "Service account is missing domain-wide delegation permissions: {{serviceAccountUserDelegationMissingAuthorizationScopes}}",
                            [Contract.TenantControllerValidateGciTenantResult.UserNotExist]: "Cannot find user based on the e-mail address provided in Google Workspace (customer id: {{rawId}})",
                            [Contract.TenantControllerValidateGciTenantResult.UserTenantMismatch]: "The provided user does not exist in the provided tenant (customer id: {{rawId}})",
                            [Contract.TenantControllerValidateGciTenantResult.UserUnauthorized]: "The user is not a Super Admin. Ensure the e-mail belongs to a user with Super Admin permissions."
                        }
                    }
                },
                fields: {
                    authorizationScopes: {
                        inline: "{{scope}} Scope",
                        pluralizer: [
                            "1 Scope",
                            "{{count | NumberFormatter.humanize}} Scopes"
                        ]
                    },
                    rawId: {
                        error: {
                            exists: "Customer ID already exists",
                            required: "Customer ID cannot be empty"
                        },
                        helpText: "Available in the Google Admin console, under Account > Account settings, in the Profile area.",
                        title: "Customer ID"
                    },
                    serviceAccountMail: {
                        error: {
                            exists: "Service account mail already exists",
                            invalidFormat: "Not a valid mail address",
                            required: "Service account mail cannot be empty"
                        },
                        helpText: "Available in the Google Cloud Console, under IAM & Admin > Service Accounts, in the Email field.",
                        title: "Service Account Mail"
                    },
                    userMail: {
                        error: {
                            exists: "User mail already exists",
                            invalidFormat: "Not a valid mail address",
                            required: "User mail cannot be empty"
                        },
                        helpText: "Provide the email of the user you like Tenable Cloud Security to use when accessing your environment. This user needs to have the Super Admin role, but don’t worry - our permissions will be restricted to the Domain Wide Delegation scopes.",
                        title: "User Mail"
                    }
                }
            }));

    useNextEffect(
        async () => {
            try {
                const { result, serviceAccountUserDelegationMissingAuthorizationScopes } =
                    await TenantController.validateGciTenant(
                        new Contract.TenantControllerValidateGciTenantRequest(
                            rawId.trim(),
                            serviceAccountMail.trim(),
                            userMail.trim()));
                if (result !== Contract.TenantControllerValidateGciTenantResult.Valid) {
                    return localization.actions.validate[Contract.TypeNames.TenantControllerValidateGciTenantResult][result]({
                        rawId,
                        serviceAccountUserDelegationMissingAuthorizationScopes:
                            <InlineItems
                                items={serviceAccountUserDelegationMissingAuthorizationScopes?.sort()}
                                namePluralizer={localization.fields.authorizationScopes.pluralizer}
                                sx={{
                                    color: "unset",
                                    fontWeight: 600,
                                    textDecoration: "underline"
                                }}
                                variant="itemOrItemCountAndType">
                                {(serviceAccountUserDelegationMissingAuthorizationScope, inline) =>
                                    inline
                                        ? localization.fields.authorizationScopes.inline({ scope: serviceAccountUserDelegationMissingAuthorizationScope })
                                        : serviceAccountUserDelegationMissingAuthorizationScope}
                            </InlineItems>
                    });
                }
            } catch (error) {
                return localization.actions.validate.error();
            }

            try {
                let updatedTenantModel: Contract.GciTenantModel;
                if (!_.isNil(tenantConfiguration)) {
                    const { tenantModel } =
                        await TenantController.updateGciTenant(
                            new Contract.TenantControllerUpdateGciTenantRequest(
                                tenantConfiguration.id,
                                serviceAccountMail.trim(),
                                userMail.trim()));
                    updatedTenantModel = tenantModel;
                } else {
                    const { tenantModel } =
                        await TenantController.insertGciTenant(
                            new Contract.TenantControllerInsertGciTenantRequest(
                                rawId.trim(),
                                serviceAccountMail.trim(),
                                userMail.trim()));
                    updatedTenantModel = tenantModel;
                }

                setAddOrEditContext(
                    addOrEditContext => ({
                        ...addOrEditContext,
                        updatedTenantModel
                    }));
            } catch (error) {
                return (
                    _.isNil(tenantConfiguration)
                        ? localization.actions.save.error.add()
                        : localization.actions.save.error.edit());
            }
        },
        [rawId, serviceAccountMail, userMail]);

    const existingTenantConfigurations =
        useMemo(
            () =>
                _(tenantModels).
                    filter(tenantModel => tenantModel.configuration.id !== tenantConfiguration?.id).
                    map(tenantModel => tenantModel.configuration as Contract.GciTenantConfiguration).
                    value(),
            [tenantModels]);

    const [rawIdValidationController, rawIdValidationMessage] =
        useInputValidation(
            () => {
                const validationRawId = rawId.trim();
                if (_.isEmpty(validationRawId)) {
                    return localization.fields.rawId.error.required();
                }
                if (_.some(
                    existingTenantConfigurations,
                    existingTenantConfiguration => existingTenantConfiguration.rawId === validationRawId)) {
                    return localization.fields.rawId.error.exists();
                }

                return undefined;
            },
            [rawId]);

    const [serviceAccountMailValidationController, serviceAccountMailValidationMessage] =
        useInputValidation(
            () => {
                const validationServiceAccountMail = serviceAccountMail.trim();
                if (_.isEmpty(validationServiceAccountMail)) {
                    return localization.fields.serviceAccountMail.error.required();
                }
                if (!MailParser.validate(validationServiceAccountMail)) {
                    return localization.fields.serviceAccountMail.error.invalidFormat();
                }
                if (_.some(
                    existingTenantConfigurations,
                    existingTenantConfiguration => existingTenantConfiguration.serviceAccountMail === validationServiceAccountMail)) {
                    return localization.fields.serviceAccountMail.error.exists();
                }

                return undefined;
            },
            [serviceAccountMail]);

    const [userMailValidationController, userMailValidationMessage] =
        useInputValidation(
            () => {
                const validationUserMail = userMail.trim();
                if (_.isEmpty(validationUserMail)) {
                    return localization.fields.userMail.error.required();
                }
                if (!MailParser.validate(validationUserMail)) {
                    return localization.fields.userMail.error.invalidFormat();
                }
                if (_.some(
                    existingTenantConfigurations,
                    existingTenantConfiguration => existingTenantConfiguration.userMail === validationUserMail)) {
                    return localization.fields.userMail.error.exists();
                }

                return undefined;
            },
            [userMail]);

    useEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    tenantRawId: rawId.trim(),
                    tenantServiceAccountMail: serviceAccountMail.trim(),
                    tenantUserMail: userMail.trim()
                }));

            setValid(
                rawIdValidationController.isValid() &&
                serviceAccountMailValidationController.isValid() &&
                userMailValidationController.isValid());
        },
        [rawId, serviceAccountMail, userMail]);

    const theme = useTheme();
    return (
        <Stack
            spacing={4}
            sx={{ maxWidth: theme.spacing(60) }}>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}>
                <Box sx={{ flex: 1 }}>
                    <FormControl
                        fullWidth={true}
                        variant="standard">
                        <TextField
                            disabled={executing}
                            label={localization.fields.rawId.title()}
                            slotProps={{
                                input: { readOnly: !_.isNil(tenantConfiguration) }
                            }}
                            value={rawId}
                            variant="outlined"
                            onChange={event => setRawId(event.target.value)}/>
                        {!_.isNil(rawIdValidationMessage) && (
                            <FormHelperText error={true}>{rawIdValidationMessage}</FormHelperText>)}
                    </FormControl>
                </Box>
                <Box sx={{ width: theme.spacing(5) }}>
                    <Message
                        level="info"
                        title={localization.fields.rawId.helpText()}
                        variant="minimal"/>
                </Box>
            </Stack>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}>
                <Box sx={{ flex: 1 }}>
                    <FormControl
                        fullWidth={true}
                        variant="standard">
                        <TextField
                            disabled={executing}
                            label={localization.fields.serviceAccountMail.title()}
                            value={serviceAccountMail}
                            variant="outlined"
                            onChange={event => setServiceAccountMail(event.target.value)}/>
                        {!_.isNil(serviceAccountMailValidationMessage) && (
                            <FormHelperText error={true}>{serviceAccountMailValidationMessage}</FormHelperText>)}
                    </FormControl>
                </Box>
                <Box sx={{ width: theme.spacing(5) }}>
                    <Message
                        level="info"
                        title={localization.fields.serviceAccountMail.helpText()}
                        variant="minimal"/>
                </Box>
            </Stack>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}>
                <Box sx={{ flex: 1 }}>
                    <FormControl
                        fullWidth={true}
                        variant="standard">
                        <TextField
                            disabled={executing}
                            label={localization.fields.userMail.title()}
                            value={userMail}
                            variant="outlined"
                            onChange={event => setUserMail(event.target.value)}/>
                        {!_.isNil(userMailValidationMessage) && (
                            <FormHelperText error={true}>{userMailValidationMessage}</FormHelperText>)}
                    </FormControl>
                </Box>
                <Box sx={{ width: theme.spacing(5) }}>
                    <Message
                        level="info"
                        title={localization.fields.userMail.helpText()}
                        variant="minimal"/>
                </Box>
            </Stack>
        </Stack>);
}