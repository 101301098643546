import { useExecuteOperation, useLocalization, WizardItem } from "@infrastructure";
import _ from "lodash";
import React, { ReactNode } from "react";
import { Contract, TenantController } from "../../../../../..";
import { RiskResolutionAutomationContext } from "../../../../utilities";
import { GcpAccessTokenItem, GcpEditItem, GcpSummaryItem } from "./components";

export class GcpRiskResolutionAutomationContext extends RiskResolutionAutomationContext {
    public gcpAccessToken?: string;
    public storedGcpAccessToken: boolean;

    constructor(
        changeModels: Contract.ChangeModel[],
        public initialStoredGcpAccessToken: boolean,
        riskedEntityModel: Contract.EntityModel) {
        super(
            changeModels,
            riskedEntityModel);

        this.storedGcpAccessToken = initialStoredGcpAccessToken;
    }
}

export function useGcpDefinition(
    changeModels: Contract.ChangeModel[],
    riskedEntityModel: Contract.EntityModel) {

    const [{ stored: initialStoredGcpAccessToken }] =
        useExecuteOperation(
            GcpAccessTokenItem,
            () => {
                const tenantIds =
                    _(changeModels).
                        flatMap(changeModel => changeModel.changeDatas).
                        flatMap(changeData => changeData.riskTenantId).
                        uniq().
                        value();
                return TenantController.validateStoredGcpAccessToken(new Contract.TenantControllerValidateStoredGcpAccessTokenRequest(tenantIds));
            });

    const localization =
        useLocalization(
            "common.riskResolutionAutomation.hooks.useDefinition.hooks.useGcpDefinition",
            () => ({
                items: {
                    edit: "The following steps are required in order to perform the remediation. Select a step to view more details about it",
                    summary: "The following steps will be applied once you click the **Apply** button"
                }
            }));

    return {
        createContext:
            () =>
                new GcpRiskResolutionAutomationContext(
                    changeModels,
                    initialStoredGcpAccessToken,
                    riskedEntityModel),
        wizardItems:
            _<ReactNode>([]).
                concat(
                    <WizardItem
                        disablePadding={true}
                        subtitle={localization.items.edit()}>
                        <GcpEditItem/>
                    </WizardItem>).
                concatIf(
                    _.some(
                        changeModels,
                        changeModel => !changeModel.changeable),
                    <WizardItem deferredLoading={true}>
                        <GcpAccessTokenItem/>
                    </WizardItem>).
                concat(
                    <WizardItem
                        disablePadding={true}
                        subtitle={localization.items.summary()}>
                        <GcpSummaryItem/>
                    </WizardItem>).
                value()
    };
}