﻿import { DataTableColumn, DeferredFilter, EnumValuesFilter, PagedValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAzureCommonResourceGroupResourceDefinition } from ".";
import { EntityTableDefinition } from "../..";
import { Contract, ElasticsearchItemPageHelper, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { useAzureMySqlServerAuthenticationTypeTranslator, useAzureMySqlServerStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { useCommonNetworkAccessResourceDefinition } from "../useCommonNetworkAccessResourceDefinition";

export function useAzureMySqlServerDefinition(definitionData: DefinitionData) {
    const azureResourceModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.AzureResourceModelFilters>;
    const commonNetworkAccessResourceDefinition = useCommonNetworkAccessResourceDefinition();
    const commonResourceGroupResourceDefinition = useAzureCommonResourceGroupResourceDefinition(definitionData);

    const mySqlServerAuthenticationTypeTranslator = useAzureMySqlServerAuthenticationTypeTranslator();
    const mySqlServerStatusTranslator = useAzureMySqlServerStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureMySqlServerDefinition",
            () => ({
                columns: {
                    administratorUserName: "Server Admin",
                    authenticationType: "Authentication Method",
                    endpoint: "Server",
                    status: "Status",
                    typeName: {
                        title: "Server Type",
                        [Contract.TypeNames.AzureMySqlFlexibleServer]: "Flexible",
                        [Contract.TypeNames.AzureMySqlSingleServer]: "Single"
                    }
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            commonResourceGroupResourceDefinition.columns.tenantColumn,
            commonResourceGroupResourceDefinition.columns.creationTimeColumn,
            commonResourceGroupResourceDefinition.columns.creatorIdentityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureMySqlServerModel) => ({
                            [localization.columns.typeName.title()]: localization.columns.typeName.translate(item.entity.typeName)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={definitionData.entityModelFiltersPromise}
                                title={localization.columns.typeName.title()}>
                                {filters =>
                                    <ValuesFilter
                                        emptyValueOptions={{ enabled: filters.typeNameItems.emptyValue }}
                                        placeholder={localization.columns.typeName.title()}>
                                        {_.map(
                                            filters.typeNameItems.items,
                                            typeName =>
                                                <ValuesFilterItem
                                                    key={typeName}
                                                    title={localization.columns.typeName.translate(typeName)}
                                                    value={typeName}/>)}
                                    </ValuesFilter>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AzureMySqlServerTypeName}
                itemProperty={(item: Contract.AzureMySqlServerModel) => localization.columns.typeName.translate(item.entity.typeName)}
                key={Contract.EntityModelProperty.AzureMySqlServerTypeName}
                title={localization.columns.typeName.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureMySqlServerModel) => ({
                            [localization.columns.administratorUserName()]: (item.entity as Contract.AzureMySqlServer).administratorUserName
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        Contract.TypeNames.AzureMySqlServer,
                                        Contract.EntityModelProperty.AzureMySqlServerAdministratorUserName)}
                                placeholder={localization.columns.administratorUserName()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureMySqlServerAdministratorUserName}
                itemProperty={(item: Contract.AzureResourceGroupResourceModel) => (item.entity as Contract.AzureMySqlServer).administratorUserName}
                key={Contract.EntityModelProperty.AzureMySqlServerAdministratorUserName}
                title={localization.columns.administratorUserName()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureMySqlServerModel) => ({
                            [localization.columns.endpoint()]: (item.entity as Contract.AzureMySqlServer).endpoint
                        })
                }}
                id="endpoint"
                key="endpoint"/>,
            commonNetworkAccessResourceDefinition.columns.accessLevelColumn,
            commonNetworkAccessResourceDefinition.columns.inboundExternalAccessScopeColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureMySqlServerModel) => ({
                            [localization.columns.authenticationType()]: mySqlServerAuthenticationTypeTranslator((item.entity as Contract.AzureMySqlServer).authenticationType)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AzureMySqlServerAuthenticationType}
                                enumTypeTranslator={mySqlServerAuthenticationTypeTranslator}
                                placeholder={localization.columns.authenticationType()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureMySqlServerAuthenticationType}
                itemProperty={(item: Contract.AzureMySqlServerModel) => mySqlServerAuthenticationTypeTranslator((item.entity as Contract.AzureMySqlServer).authenticationType)}
                key={Contract.EntityModelProperty.AzureMySqlServerAuthenticationType}
                title={localization.columns.authenticationType()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureMySqlServerModel) => ({
                            [localization.columns.status()]: mySqlServerStatusTranslator((item.entity as Contract.AzureMySqlServer).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={azureResourceModelFiltersPromise}
                                title={localization.columns.status()}>
                                {_ =>
                                    <EnumValuesFilter
                                        enumType={Contract.AzureMySqlServerStatus}
                                        enumTypeTranslator={mySqlServerStatusTranslator}
                                        placeholder={localization.columns.status()}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AzureMySqlServerStatus}
                itemProperty={(item: Contract.AzureMySqlServerModel) => mySqlServerStatusTranslator((item.entity as Contract.AzureMySqlServer).status)}
                key={Contract.EntityModelProperty.AzureMySqlServerStatus}
                title={localization.columns.status()}/>,
            commonResourceGroupResourceDefinition.columns.regionColumn,
            commonResourceGroupResourceDefinition.columns.regionLocationColumn,
            commonResourceGroupResourceDefinition.columns.resourceGroupColumn,
            commonResourceGroupResourceDefinition.columns.tagsColumn,
            commonResourceGroupResourceDefinition.columns.attributesColumn,
            commonResourceGroupResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureMySqlServerRequest(
                new Contract.EntityControllerGetEntityModelPageAzureMySqlServerRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureResourceGroupResourceResourceGroup]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureMySqlServerAdministratorUserName]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureMySqlServerAuthenticationType]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureMySqlServerStatus]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureMySqlServerTypeName])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestNetworkAccess(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundAccessType]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalAccessScope]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalDestinationNetworkScopes]))));
}