import { map } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../controllers";
import { CommonEntityAttributes, CommonEntityTags, CommonId, CommonSeverity, CommonTenantType, CommonVendor, CommonVulnerabilities, InfraBoolean, InfraByteSize, InfraDateTime, InfraEnum, InfraInteger, InfraIpAddress, InfraString, InfraTimeSpan, InfraUrl } from "./components";

export type UdmObjectPropertyItemProps = {
    filter?: boolean;
    item: any;
    objectId: string;
    objectProperty: Contract.UdmObjectProperty;
    objectTypeName: string;
};

export function UdmObjectPropertyItem(props: UdmObjectPropertyItemProps) {
    return map(
        props.objectProperty.dataType,
        {
            [Contract.UdmObjectPropertyDataType.CommonEntityAttributes]: () => <CommonEntityAttributes {...props}/>,
            [Contract.UdmObjectPropertyDataType.CommonEntityTags]: () => <CommonEntityTags {...props}/>,
            [Contract.UdmObjectPropertyDataType.CommonId]: () => <CommonId {...props}/>,
            [Contract.UdmObjectPropertyDataType.CommonSelfId]: () => <CommonId {...props}/>,
            [Contract.UdmObjectPropertyDataType.CommonSeverity]: () => <CommonSeverity {...props}/>,
            [Contract.UdmObjectPropertyDataType.CommonTenantType]: () => <CommonTenantType {...props}/>,
            [Contract.UdmObjectPropertyDataType.CommonVendor]: () => <CommonVendor {...props}/>,
            [Contract.UdmObjectPropertyDataType.CommonVulnerabilities]: () => <CommonVulnerabilities {...props}/>,
            [Contract.UdmObjectPropertyDataType.InfraBoolean]: () => <InfraBoolean {...props}/>,
            [Contract.UdmObjectPropertyDataType.InfraByteSize]: () => <InfraByteSize {...props}/>,
            [Contract.UdmObjectPropertyDataType.InfraDateTime]: () => <InfraDateTime {...props}/>,
            [Contract.UdmObjectPropertyDataType.InfraEnum]: () => <InfraEnum {...props}/>,
            [Contract.UdmObjectPropertyDataType.InfraInteger]: () => <InfraInteger {...props}/>,
            [Contract.UdmObjectPropertyDataType.InfraIpAddress]: () => <InfraIpAddress {...props}/>,
            [Contract.UdmObjectPropertyDataType.InfraString]: () => <InfraString {...props}/>,
            [Contract.UdmObjectPropertyDataType.InfraTimeSpan]: () => <InfraTimeSpan {...props}/>,
            [Contract.UdmObjectPropertyDataType.InfraUrl]: () => <InfraUrl {...props}/>
        });
}