import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetCommonKubernetesClusterRiskContext } from "../../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../common";

export function useKubernetesClusterApiServerKubeletClientCertificateAuthenticationDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.KubernetesClusterApiServerKubeletClientCertificateAuthenticationDisabledRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId);

    const getCommonKubernetesClusterRiskContext = useGetCommonKubernetesClusterRiskContext(clusterModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.kubernetes.hooks.clusters.apiServers.useKubernetesClusterApiServerKubeletClientCertificateAuthenticationDisabledRiskDefinition",
            () => ({
                description: "{{cluster}} API server uses insecure or unauthenticated connections to Kubelets",
                resolutionSection: {
                    step1: {
                        link: "Kubernetes documentation",
                        text: "Follow the {{kubeletAuthenticationDocumentationLink}} and set up the TLS connection between the API server and kubelets"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{
                        includeServiceName: true,
                        variant: "title"
                    }}
                    variant="typeText"/>
        }),
        () => [
            localization.resolutionSection.step1.text({
                kubeletAuthenticationDocumentationLink:
                        <Link
                            urlOrGetUrl={(riskModel as Contract.KubernetesClusterApiServerKubeletClientCertificateAuthenticationDisabledRiskModel).kubeletAuthenticationDocumentationUrl}
                            variant="external">
                            {localization.resolutionSection.step1.link()}
                        </Link>
            })
        ],
        riskModel,
        () => {
            const clusterRiskContext = getCommonKubernetesClusterRiskContext(clusterModel);

            return [
                clusterRiskContext.generalInformation,
                clusterRiskContext.virtualNetwork,
                clusterRiskContext.sensitive,
                clusterRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}