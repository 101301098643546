import { TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { ReactNode } from "react";
import { Contract, Entity, InfoItem, TenantHelper } from "../../../../../../../../../../common";
import { TagsInfoItem } from "../../../../components";
import { useGcpCommonEntityInfoItemElements } from "./useGcpCommonEntityInfoItemElements";

export function useGcpCommonResourceInfoItemElements(resourceModel: Contract.GcpResourceModel) {
    const commonEntitiesInfoElements = useGcpCommonEntityInfoItemElements(resourceModel);
    const resource = resourceModel.entity as Contract.GcpResource;
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpCommonResourceInfoItemElements",
            () => ({
                creator: {
                    identity: "Identity: {{creatorIdentity}}",
                    originator: "Originator: {{creatorOriginatorEntity}}",
                    title: "Created By"
                },
                tags: "GCP Labels",
                updateTime: "Update Time"
            }));

    return {
        ...commonEntitiesInfoElements,
        creatorEntityInfoItemElement:
            <InfoItem
                key="creator"
                location="all"
                title={localization.creator.title()}
                vertical={! _.isNil(resourceModel.creatorIdentityIdReference) && !_.isNil(resourceModel.creatorOriginatorEntityIdReference)}>
                {TenantHelper.isCommonTenant(resource.tenantId)
                    ? undefined
                    : _.isNil(resourceModel.creatorIdentityIdReference)
                        ? undefined
                        : _<ReactNode>([]).
                            concat(
                                <Entity
                                    entityIdOrModel={resourceModel.creatorIdentityIdReference}
                                    linkOptions={{ disabledHighlight: false }}
                                    variant="iconText"/>).
                            concatIf(
                                !_.isNil(resourceModel.creatorOriginatorEntityIdReference),
                                <Entity
                                    entityIdOrModel={resourceModel.creatorOriginatorEntityIdReference!}
                                    linkOptions={{ disabledHighlight: false }}
                                    variant="iconText"/>).
                            value()}
            </InfoItem>,
        tagsInfoItemElement:
            <TagsInfoItem
                key="tags"
                location="all"
                resourceTags={resource.tags}
                title={localization.tags()}/>,
        updateTimeInfoItemElement:
            <InfoItem
                key="updateTime"
                title={localization.updateTime()}
                value={
                    _.isNil(resource.updateTime)
                        ? undefined
                        : TimeFormatter.profileFormatDateTime(resource.updateTime)}/>
    };
}