﻿import { Dialog, Tooltip, useExecuteOperation, useLocalization } from "@infrastructure";
import { Button, Stack } from "@mui/material";
import React, { Fragment, useState } from "react";
import { Contract, RiskController, tenantModelStore, TypeHelper, UserHelper, useTheme } from "../../../../../../../../../../../../common";
import { useRiskContext } from "../../../../../../../../hooks";
import { CodeResolution } from "./CodeResolution";

type ResolveCodeProps = {
    codeTenantId: string;
};

export function ResolveCode({ codeTenantId }: ResolveCodeProps) {
    const [open, setOpen] = useState(false);
    const { reloadRiskModel, riskModel } = useRiskContext();
    const [{ changeModels, codeTenantModel }, executeGetRisksChangeModels] =
        useExecuteOperation(
            [ResolveCode, riskModel.id],
            async () => {
                const { changeModels } =
                    await RiskController.getRiskChangeModels(
                        new Contract.RiskControllerGetRiskChangeModelsRequest([riskModel.id]));
                const codeTenantModel = await tenantModelStore.get(codeTenantId) as Contract.CodeTenantModel;

                return {
                    changeModels,
                    codeTenantModel
                };
            });

    const gitTenantConfigurationExists =
        TypeHelper.extendOrImplement(codeTenantModel.configuration.typeName, Contract.TypeNames.GitTenantConfiguration) &&
        !codeTenantModel.configuration.systemDeleted;

    const localization =
        useLocalization(
            "views.customer.risks.profile.core.resolutionCategory.resolveCode",
            () => ({
                button: {
                    title: "Create Pull Request",
                    tooltip: "Configure code organization in order to enable pull request creation"
                }
            }));

    const theme = useTheme();
    return (
        <Fragment>
            {open &&
                <Dialog
                    size="large"
                    variant="editor"
                    onClose={() => setOpen(false)}>
                    <CodeResolution
                        changeModels={changeModels}
                        codeTenantModel={codeTenantModel}
                        riskModel={riskModel}
                        onFinished={
                            async (canceled: boolean) => {
                                if (!canceled) {
                                    await reloadRiskModel();
                                    await executeGetRisksChangeModels();
                                }

                                setOpen(false);
                            }}/>
                </Dialog>}
            <Stack
                alignItems="center"
                direction="row"
                spacing={2}>
                {UserHelper.hasScopePermissions(codeTenantId, Contract.IdentityPermission.SecurityWrite) && (
                    <Tooltip
                        disabled={gitTenantConfigurationExists}
                        titleOrGetTitle={localization.button.tooltip()}>
                        <Button
                            disabled={!gitTenantConfigurationExists}
                            sx={{
                                minWidth: "unset",
                                padding: theme.spacing(1, 2)
                            }}
                            onClick={() => setOpen(true)}>
                            {localization.button.title()}
                        </Button>
                    </Tooltip>)}
            </Stack>
        </Fragment>);
}