import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function FullWidthIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M5.34582 17.8811C5.72446 18.2597 6.35127 18.2468 6.74582 17.8523C7.14038 17.4577 7.15328 16.8309 6.77463 16.4523L3.89996 13.5776H10.2514C10.8229 13.5781 11.2996 13.0454 11.3 12.4738C11.3005 11.9345 10.8752 11.5084 10.3359 11.5079H3.9017L6.72328 8.74315C7.1337 8.33273 7.14712 7.68072 6.75324 7.28685C6.35937 6.89298 5.70736 6.90639 5.29694 7.31681L0.493862 12.1199C0.242782 12.371 0.234575 12.7698 0.475532 13.0108L5.34582 17.8811Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M18.6542 17.8811C18.2755 18.2597 17.6487 18.2468 17.2542 17.8523C16.8596 17.4577 16.8467 16.8309 17.2254 16.4523L20.1 13.5776H13.7486C13.1771 13.5781 12.7004 13.0454 12.7 12.4738C12.6995 11.9345 13.1248 11.5084 13.6641 11.5079H20.0983L17.2767 8.74315C16.8663 8.33273 16.8529 7.68072 17.2468 7.28685C17.6406 6.89298 18.2926 6.90639 18.7031 7.31681L23.5061 12.1199C23.7572 12.371 23.7654 12.7698 23.5245 13.0108L18.6542 17.8811Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}