import { EmptyMessageText, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { ReactNode } from "react";
import { EntitiesInfoItem } from ".";
import { InfoCard, InfoCardProps, useEntityTypeNameTranslator } from "../../../../../../../common";
import { EntityModel } from "../../../../../../../common/controllers/types.generated";

type EntitiesInfoCardProps = Omit<InfoCardProps, "children"> & {
    enabledSubTitles?: boolean;
    entityIdsOrModels: string | string[] | EntityModel | EntityModel[];
    entityTypeName: string;
    title?: ReactNode;
};

export function EntitiesInfoCard({ emptyMessage, enabledSubTitles = false, entityIdsOrModels, entityTypeName, title, ...props }: EntitiesInfoCardProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const translatedEntityTypeName =
        entityTypeNameTranslator(
            entityTypeName,
            {
                count: 0,
                includeServiceName: false
            });
    const localization =
        useLocalization(
            "views.customer.entities.profile.entitiesInfoCard",
            () => ({
                empty: "No {{translatedEntityTypeName}}"
            }));
    return (
        <InfoCard
            {...props}
            emptyMessage={
                emptyMessage ??
                new EmptyMessageText(
                    localization.empty({
                        translatedEntityTypeName:
                            title
                                ? title
                                : translatedEntityTypeName
                    }))}
            title={title}>
            {_.isEmpty(entityIdsOrModels)
                ? undefined
                : <EntitiesInfoItem
                    disabledTitle={!enabledSubTitles}
                    entityIdsOrModels={entityIdsOrModels}
                    entityTypeName={entityTypeName}
                    title={translatedEntityTypeName}/>}
        </InfoCard>);
}