import { useLocalization } from "@infrastructure";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";

export function useTeamsOrganizationAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useTeamsOrganizationAuditEventDefinition",
            () => ({
                teamsOrganizationName: "Teams Organization"
            }));
    return new AuditEventDefinition([
        new AuditEventDefinitionDetailItem(
            localization.teamsOrganizationName(),
            (context.auditEventModel.auditEvent as Contract.TeamsOrganizationAuditEvent).teamsOrganizationName)
    ]);
}