import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { useTheme } from "../../../..";

export function CancelledIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon {...props}>
            <path d="M0.000425775 11.92C0.0537573 5.36002 5.41357 0 12 0C18.6931 0.0533328 24.0529 5.46665 23.9996 12.08C23.9463 18.64 18.5865 24 12 24H11.92C8.72013 23.9733 5.7069 22.72 3.46697 20.4267C1.20038 18.16 -0.02624 15.12 0.000425775 11.92Z"/>
            <rect
                fill={theme.palette.background.paper}
                height="2"
                width="14"
                x="5"
                y="11"/>
        </SvgIcon>);
}