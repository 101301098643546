import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../..";
import { Entity } from "../../../../../..";

export function useAwsDetachPrincipalManagedPolicyChangeDefinition(change: Contract.AwsChange, entityLinkDisabled?: boolean) {
    const detachPrincipalManagedPolicyChange = change as Contract.AwsDetachPrincipalManagedPolicyChange;
    const localization =
        useLocalization(
            "common.change.hooks.useDefinition.hooks.useAwsDefinition.hooks.useAwsDetachPrincipalManagedPolicyChangeDefinition",
            () => ({
                deletePolicy: {
                    false: "Detach {{managedPolicy}}",
                    true: "Delete {{managedPolicy}}"
                }
            }));
    return {
        title:
            localization.deletePolicy[
                detachPrincipalManagedPolicyChange.deletePolicy
                    ? "true"
                    : "false"]({
                managedPolicy:
                    <Entity
                        entityIdOrModel={detachPrincipalManagedPolicyChange.resourceId}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: entityLinkDisabled }}
                        variant="typeText"/>
            })
    };
}