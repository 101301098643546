﻿import { Optional } from "@infrastructure";
import { Contract } from "../../../../../../../../../common";
import { PermissionEligibilityData } from "./PermissionEligibilityData";

export abstract class GroupMembershipEligibilityData extends PermissionEligibilityData {
    public groupIds: string[];

    protected constructor(
        permissionEligibilityModel?: Optional<Contract.PermissionEligibilityModel>,
        scopeId?: string) {
        super(
            permissionEligibilityModel,
            scopeId);
        this.groupIds = (permissionEligibilityModel as Contract.GroupMembershipEligibilityModel)?.existingGroupIds ?? [];
    }

    protected UpdatePrincipalTenantRelatedData() {
        super.UpdatePrincipalTenantRelatedData();
        this.groupIds = [];
    }
}