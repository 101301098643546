import { useMemo } from "react";
import { DefinitionOptions } from "../../..";
import { Contract } from "../../../../../../../../../../common";
import { useAzureResourceDefinition } from "./useAzureResourceDefinition";
import { useAzureUnmanagedKubernetesClusterDefinition } from "./useAzureUnmanagedKubernetesClusterDefinition";

export function useAzureEntityDefinition(entityModel: Contract.EntityModel, options?: DefinitionOptions) {
    const useDefinition =
        useMemo(
            () => {
                switch (entityModel.entity.typeName) {
                    case Contract.TypeNames.AzureUnmanagedKubernetesCluster:
                        return useAzureUnmanagedKubernetesClusterDefinition;
                    default:
                        return useAzureResourceDefinition;
                }
            },
            []);

    return useDefinition(entityModel as Contract.AzureEntityModel, options);
}