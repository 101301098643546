import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsEcsTaskDefinitionStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsEcsTaskDefinitionStatusTranslator",
            () => ({
                [Contract.TypeNames.AwsEcsTaskDefinitionStatus]: {
                    [Contract.AwsEcsTaskDefinitionStatus.Active]: "Active",
                    [Contract.AwsEcsTaskDefinitionStatus.Inactive]: "Inactive"
                }
            }));
    return (status: Contract.AwsEcsTaskDefinitionStatus) =>
        localization[Contract.TypeNames.AwsEcsTaskDefinitionStatus][status]();
}