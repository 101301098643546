﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAttackVectorTranslator() {
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.hooks.useAttackVectorTranslator",
            () => ({
                [Contract.TypeNames.VulnerabilityAttackVector]: {
                    [Contract.VulnerabilityAttackVector.Physical]: "Physical",
                    [Contract.VulnerabilityAttackVector.Local]: "Local",
                    [Contract.VulnerabilityAttackVector.Adjacent]: "Adjacent",
                    [Contract.VulnerabilityAttackVector.Network]: "Network"
                }
            }));
    return (vulnerabilityAttackVector: Contract.VulnerabilityAttackVector) =>
        localization[Contract.TypeNames.VulnerabilityAttackVector][vulnerabilityAttackVector]();
}