import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, NoneIcon, optionalTableCell, useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InfoCard, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";

type AzureNetworkVirtualNetworkSubnetsInfoCardProps = {
    subnetIds: string[];
};

export function AzureNetworkVirtualNetworkSubnetsInfoCard({ subnetIds }: AzureNetworkVirtualNetworkSubnetsInfoCardProps) {
    const subnetModels = entityModelStore.useGet(subnetIds);
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localizeList = useLocalizeList();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureNetworkVirtualNetworkDefinition.azureNetworkVirtualNetworkSubnetsInfoCard",
            () => ({
                columns: {
                    networkSecurityGroup: "Network Security Group",
                    subnet: "Subnet ID",
                    subnets: "Address Space"
                },
                empty: "No {{translatedEntityTypeName}}",
                title: "Subnets"
            }));
    return (
        <InfoCard title={localization.title()}>
            <DataTable
                emptyMessageOptions={{
                    emptyMessageText:
                        new EmptyMessageText(
                            localization.empty({
                                translatedEntityTypeName:
                                    entityTypeNameTranslator(
                                        Contract.TypeNames.AzureNetworkVirtualNetworkSubnet,
                                        { count: 0 })
                            }))
                }}
                fetchItems={() => subnetModels}
                getItemId={(item: Contract.EntityModel) => item.id}
                sortOptions={{ enabled: false }}
                variant="card">
                <DataTableColumn
                    id={SubnetColumnId.Subnet}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.EntityModel>) =>
                            <Entity
                                entityIdOrModel={item}
                                variant="iconTextTenant"/>}
                    title={localization.columns.subnet()}/>
                <DataTableColumn
                    id={SubnetColumnId.Subnets}
                    render={
                        optionalTableCell<Contract.AzureResourceGroupResourceModel>(
                            item =>
                                _.isNil((item.entity as Contract.AzureNetworkVirtualNetworkSubnet).subnets)
                                    ? undefined
                                    : localizeList((item.entity as Contract.AzureNetworkVirtualNetworkSubnet).subnets))}
                    title={localization.columns.subnets()}/>
                <DataTableColumn
                    id={SubnetColumnId.NetworkSecurityGroup}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.EntityModel>) =>
                            _.isNil((item.entity as Contract.AzureNetworkVirtualNetworkSubnet).networkSecurityGroupId)
                                ? <NoneIcon sx={{ fontSize: "18px" }}/>
                                : <Entity
                                    entityIdOrModel={(item.entity as Contract.AzureNetworkVirtualNetworkSubnet).networkSecurityGroupId!}
                                    variant="iconTextTenant"/>}
                    title={localization.columns.networkSecurityGroup()}/>
            </DataTable>
        </InfoCard>);
}

enum SubnetColumnId {
    NetworkSecurityGroup = "networkSecurityGroup",
    Subnet = "subnet",
    Subnets = "subnets"
}