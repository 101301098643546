﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { useGcpCommonKubernetesResourceInfoItemElements, useGcpDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract } from "../../../../../../../../../../common";
import { KubernetesResourceDataHelper } from "../../../../../../utilities";
import { Info } from "../../../../components";
import { KubernetesRoleBindings, KubernetesYamlInfoCard } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useGcpKubernetesClusterRoleDefinition(resourceModel: Contract.GcpResourceModel, options?: DefinitionOptions) {
    const clusterRole = resourceModel.entity as Contract.GcpKubernetesClusterRole;
    const clusterRoleModel = resourceModel as Contract.GcpKubernetesClusterRoleModel;
    const commonKubernetesResourceInfoItemElements = useGcpCommonKubernetesResourceInfoItemElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultPropertyInfoElements = useGcpDefaultResourceInfoItemElements(resourceModel);

    const localization = useLocalization(
        "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpKubernetesClusterRoleDefinition",
        () => ({
            tabs: {
                roleBindings: "Role Bindings",
                yaml: "YAML"
            }
        }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <KubernetesRoleBindings principalOrRoleModel={clusterRoleModel}/>,
                localization.tabs.roleBindings(),
                "roleBindings"),
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <KubernetesYamlInfoCard rawYaml={clusterRole.data.rawYaml!}/>,
                localization.tabs.yaml(),
                "yaml")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultPropertyInfoElements}
                entityPropertyInfoItemElements={[
                    commonKubernetesResourceInfoItemElements.annotationsInfoItemElement,
                    commonKubernetesResourceInfoItemElements.clusterInfoItemElement
                ]}
                options={options?.infoOptions}>
            </Info>,
        sensitive: KubernetesResourceDataHelper.entitySensitiveToggle(clusterRole.data)
    });
}