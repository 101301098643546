import React from "react";
import { Contract } from "../../../../../../../controllers";
import { UdmObjectPropertyFilterProps } from "../UdmObjectPropertyFilter";
import { ListFilter } from "./ListFilter";

export function CommonSelfId(props: UdmObjectPropertyFilterProps) {
    return (
        <ListFilter
            {...props}
            operators={operators}/>);
}

const operators = [
    Contract.UdmQueryRuleOperator.In
];