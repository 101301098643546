import { UnexpectedError, useQueryParameters } from "@infrastructure";
import React from "react";
import { ApiController, Contract, reportDefinitionConfigurationStore } from "../../../../common";
import { ComplianceReport, DashboardReport, EntityTypeInventoryReport, EventsReport, RiskPolicyReport } from "./components";

type ReportQueryParameters = {
    reportDefinitionId: string;
    scopeId: string;
};

export function ExportReport() {
    const { reportDefinitionId, scopeId } = useQueryParameters<ReportQueryParameters>();
    const reportDefinitionConfiguration = reportDefinitionConfigurationStore.useGet(reportDefinitionId);

    ApiController.scopeId = scopeId;

    switch (reportDefinitionConfiguration.type) {
        case Contract.ReportType.Compliance:
            return <ComplianceReport
                reportDefinitionConfiguration={reportDefinitionConfiguration as Contract.ComplianceReportDefinitionConfiguration}
                scopeId={scopeId}/>;
        case Contract.ReportType.Dashboard:
            return <DashboardReport scopeId={scopeId}/>;
        case Contract.ReportType.Events:
            return <EventsReport reportDefinitionConfiguration={reportDefinitionConfiguration as Contract.EventsReportDefinitionConfiguration}/>;
        case Contract.ReportType.Inventory:
            return <EntityTypeInventoryReport reportDefinitionConfiguration={reportDefinitionConfiguration as Contract.InventoryReportDefinitionConfiguration}/>;
        case Contract.ReportType.RiskPolicy:
            return <RiskPolicyReport scopeId={scopeId}/>;
        default:
            throw new UnexpectedError(reportDefinitionConfiguration.type);
    }
}