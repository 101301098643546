import { TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, InfoCard, InfoItem } from "../../../../../../../../../../../../common";
import { AwsIamUserAccessKey } from "../../../../../../../../../../../../common/controllers/types.generated";

type CredentialsProps = {
    userModel: Contract.AwsIamUserModel;
};

export function Credentials({ userModel }: CredentialsProps) {
    const user = userModel.entity as Contract.AwsIamUser;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsIamUserDefinition.credentials",
            () => ({
                items: {
                    accessKeys: {
                        accessKey: {
                            accessKeyEnabledTitle: "Access Key",
                            creationTime: "Creation Time",
                            enabled: {
                                false: "Disabled",
                                true: "Enabled"
                            },
                            neverUsed: "Never",
                            title: "Access Key {{accessKeyId}}",
                            usageRegionSystemName: "Last Used Region",
                            usageService: "Last Used Service",
                            usageTime: "Usage Time"
                        },
                        empty: "None",
                        title: "Access Keys"
                    },
                    mfaEnabled: {
                        enabled: {
                            false: "Disabled",
                            true: "Enabled"
                        },
                        title: "MFA"
                    },
                    password: {
                        creationTime: "Password Update Time",
                        enabled: {
                            false: "Disabled",
                            true: "Enabled"
                        },
                        neverUsed: "Never",
                        title: "Password",
                        usageTime: "Password Usage Time"
                    }
                }
            }));
    function getAccessKeyInfoItem(accessKey: AwsIamUserAccessKey) {
        return (
            <InfoCard
                columns={true}
                title={localization.items.accessKeys.accessKey.title({ accessKeyId: accessKey.uniqueId })}>
                <InfoItem
                    title={localization.items.accessKeys.accessKey.accessKeyEnabledTitle()}
                    value={
                        accessKey.enabled
                            ? localization.items.accessKeys.accessKey.enabled.true()
                            : localization.items.accessKeys.accessKey.enabled.false()}/>
                <InfoItem
                    title={localization.items.accessKeys.accessKey.creationTime()}
                    value={
                        _.isNil(accessKey.creationTime)
                            ? undefined
                            : TimeFormatter.profileFormatDateTime(accessKey.creationTime)}/>
                <InfoItem
                    emptyValue={localization.items.accessKeys.accessKey.neverUsed()}
                    title={localization.items.accessKeys.accessKey.usageTime()}
                    value={
                        _.isNil(accessKey.usageTime)
                            ? undefined
                            : TimeFormatter.shortDate(accessKey.usageTime)}/>
                <InfoItem
                    title={localization.items.accessKeys.accessKey.usageRegionSystemName()}
                    value={
                        _.isNil(accessKey.usageRegionSystemName)
                            ? undefined
                            : accessKey.usageRegionSystemName}/>
                <InfoItem
                    title={localization.items.accessKeys.accessKey.usageService()}
                    value={
                        _.isNil(accessKey.usageServiceIdReference)
                            ? undefined
                            : <Entity
                                entityIdOrModel={accessKey.usageServiceIdReference}
                                variant="iconText"/>}/>
            </InfoCard>);
    }

    return (
        <InfoCard>
            <InfoCard columns={true}>
                <InfoItem
                    title={localization.items.password.title()}
                    value={
                        user.password?.enabled
                            ? localization.items.password.enabled.true()
                            : localization.items.password.enabled.false()}/>
                <InfoItem
                    title={localization.items.password.creationTime()}
                    value={
                        user.password?.enabled || _.isNil(user.password?.creationTime)
                            ? undefined
                            : TimeFormatter.profileFormatDateTime(user.password!.creationTime)}/>
                <InfoItem
                    emptyValue={localization.items.password.neverUsed()}
                    title={localization.items.password.usageTime()}
                    value={
                        user.password?.enabled || _.isNil(user.password?.usageTime)
                            ? undefined
                            : TimeFormatter.profileFormatDateTime(user.password!.usageTime)}/>
                <InfoItem
                    title={localization.items.mfaEnabled.title()}
                    value={
                        user.mfaEnabled
                            ? localization.items.mfaEnabled.enabled.true()
                            : localization.items.mfaEnabled.enabled.false()}/>
            </InfoCard>
            {_.isNil(user.accessKey1) && _.isNil(user.accessKey2)
                ? <InfoItem
                    title={localization.items.accessKeys.title()}
                    value={localization.items.accessKeys.empty()}/>
                : undefined}
            {_.isNil(user.accessKey1)
                ? undefined
                : getAccessKeyInfoItem(user.accessKey1)}
            {_.isNil(user.accessKey2)
                ? undefined
                : getAccessKeyInfoItem(user.accessKey2)}
        </InfoCard>);
}