import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetCommonKubernetesClusterRiskContext } from "../../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../common";

export function useKubernetesClusterEtcdPeerSelfSignedTlsCertificateGenerationEnabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.KubernetesClusterEtcdPeerSelfSignedTlsCertificateGenerationEnabledRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId);

    const getCommonKubernetesClusterRiskContext = useGetCommonKubernetesClusterRiskContext(clusterModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.kubernetes.hooks.clusters.etcds.useKubernetesClusterEtcdPeerSelfSignedTlsCertificateGenerationEnabledRiskDefinition",
            () => ({
                description: "{{cluster}} has the --peer-auto-tls argument set to true",
                resolutionSection: {
                    step1: "Edit the etcd configuration file or command-line parameters used to start the etcd service",
                    step2: "Ensure the **--peer-auto-tls** argument is set to **false**",
                    step3: "Restart the etcd service to apply the changes"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{
                        includeServiceName: true,
                        variant: "title"
                    }}
                    variant="typeText"/>
        }),
        () => [
            localization.resolutionSection.step1(),
            localization.resolutionSection.step2(),
            localization.resolutionSection.step3()
        ],
        riskModel,
        () => {
            const clusterContextItems = getCommonKubernetesClusterRiskContext(clusterModel);

            return [
                clusterContextItems.generalInformation,
                clusterContextItems.virtualNetwork,
                clusterContextItems.sensitive,
                clusterContextItems.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}