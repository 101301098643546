import { InlineItems, Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, entityModelStore, InlineEntities, InlinePermissionActions } from "../../../../../../../../../../../../../common";
import { RiskDefinitionContextItem, RiskDefinitionSection } from "../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../useCommonSectionsAndDescriptionDefinition";
import { useResourceSecretExistsRiskContext } from "../../../../useResourceSecretExistsRiskContext";
import { EntityExternalConsoleLink } from "../../../components";
import { useGetGcpAppEngineApplicationRiskContext, useGetGcpAppEngineApplicationServiceRiskContext } from "../../contexts";
import { RevisionsSection } from "./components";

export function useGcpAppEngineApplicationServiceEnvironmentVariableSecretExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const appEngineApplicationServiceEnvironmentVariableSecretExistsRiskModel = riskModel as Contract.GcpAppEngineApplicationServiceEnvironmentVariableSecretExistsRiskModel;
    const risk = appEngineApplicationServiceEnvironmentVariableSecretExistsRiskModel.risk;
    const serviceModel = entityModelStore.useGet(risk.entityId) as Contract.GcpAppEngineApplicationServiceModel;
    const applicationModel = entityModelStore.useGet((serviceModel.entity as Contract.GcpAppEngineApplicationService).applicationId) as Contract.GcpAppEngineApplicationModel;

    const getGcpAppEngineApplicationRiskContext = useGetGcpAppEngineApplicationRiskContext();
    const getGcpAppEngineApplicationServiceRiskContext = useGetGcpAppEngineApplicationServiceRiskContext();
    const secretContextItems = useResourceSecretExistsRiskContext();

    const partialPrincipalIds =
        useMemo(
            () =>
                _(risk.principalIdToExternalMap).
                    pickBy(external => external).
                    keys().
                    value(),
            [risk]);
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpAppEngineApplicationServiceEnvironmentVariableSecretExistsRiskDefinition",
            () => ({
                contextItems: {
                    nonPublicReadPermissionActions: "{{principalIds}} were granted {{nonPublicReadPermissionActions}}, allowing them to read your secrets",
                    nonPublicReadPermissionActionsExternalPrincipals: "{{principalIds}} including {{partialPrincipalIds}} were granted {{nonPublicReadPermissionActions}}, allowing them to read your secrets",
                    publicReadPermissionActions: "All users / All authenticated users were granted {{publicReadPermissionActions}}, allowing them to read your secrets"
                },
                description: {
                    environmentVariables: [
                        "1 environment variable",
                        "{{count | NumberFormatter.humanize}} environment variables"
                    ],
                    revisions: [
                        "1 version",
                        "{{count | NumberFormatter.humanize}} versions"
                    ],
                    text: "{{service}} is exposing secrets using {{environmentVariableNames}} in {{revisions}}"
                },
                sections: {
                    resolution: {
                        step1: {
                            link: "Deploying versions of your Applications",
                            text: "For each version exposing secrets, migrate your secrets to Secrets Manager and deploy a new secured version. For more information see the relevant GCP documentation on {{deployVersionDocumentationLink}}"
                        },
                        step2: "Delete the unsecured version"
                    },
                    revisions: "Versions"
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description.text({
            environmentVariableNames:
                <InlineItems
                    items={appEngineApplicationServiceEnvironmentVariableSecretExistsRiskModel.secretEnvironmentVariableNames}
                    namePluralizer={localization.description.environmentVariables}
                    variant="itemCountAndType"/>,
            revisions:
                <InlineEntities
                    entityIdsOrModels={_.keys(risk.revisionIdToSecretEnvironmentVariableNamesMap)}
                    entityTypeName={Contract.TypeNames.GcpAppEngineApplicationServiceRevision}
                    namePluralizer={localization.description.revisions}
                    variant="itemCountAndType"/>,
            service:
                <Entity
                    entityIdOrModel={serviceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.GcpConsolePage.Versions}/>,
            localization.sections.resolution.step1.text({
                deployVersionDocumentationLink:
                    <Link
                        urlOrGetUrl={appEngineApplicationServiceEnvironmentVariableSecretExistsRiskModel.deployVersionDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step1.link()}
                    </Link>
            }),
            localization.sections.resolution.step2()
        ],
        riskModel,
        () => {
            const applicationContextItems = getGcpAppEngineApplicationRiskContext(applicationModel);
            const serviceContextItems = getGcpAppEngineApplicationServiceRiskContext(serviceModel);
            return [
                serviceContextItems.generalInformation,
                serviceContextItems.sensitive,
                applicationContextItems.sensitive,
                secretContextItems.getSecretExistsContextItem(
                    risk.entityId,
                    Contract.TypeNames.GcpAppEngineApplicationService,
                    appEngineApplicationServiceEnvironmentVariableSecretExistsRiskModel.secretEnvironmentVariableNames),
                secretContextItems.getExcludedSecretsContextItem(
                    risk.excludedSecretEnvironmentVariableNames,
                    risk.typeName,
                    risk.tenantId),
                _.isEmpty(risk.publicReadPermissionActions)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.publicReadPermissionActions({
                            publicReadPermissionActions:
                                <InlinePermissionActions
                                    permissionActions={risk.publicReadPermissionActions}
                                    variant="itemCountAndType"/>
                        })),
                _.isEmpty(risk.nonPublicReadPermissionActions)
                    ? undefined
                    : new RiskDefinitionContextItem(
                        (_.isEmpty(partialPrincipalIds)
                            ? localization.contextItems.nonPublicReadPermissionActions
                            : localization.contextItems.nonPublicReadPermissionActionsExternalPrincipals)({
                            nonPublicReadPermissionActions:
                                <InlinePermissionActions
                                    permissionActions={risk.nonPublicReadPermissionActions}
                                    variant="itemCountAndType"/>,
                            partialPrincipalIds:
                                <InlineEntities
                                    entityIdsOrModels={partialPrincipalIds}
                                    entityTypeName={Contract.TypeNames.IGciPartialPrincipal}
                                    variant="itemCountAndType"/>,
                            principalIds:
                                <InlineEntities
                                    entityIdsOrModels={_.keys(risk.principalIdToExternalMap)}
                                    entityTypeName={Contract.TypeNames.IGciPrincipal}
                                    variant="itemCountAndType"/>
                        })),
                serviceContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <RevisionsSection risk={risk}/>,
                    localization.sections.revisions())
            ]
        });
}