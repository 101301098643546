﻿import { useExecuteOperation, useLocalization } from "@infrastructure";
import { Grid, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { ComplianceReportProps, ContentPages, ContentPagesItem, CoverPage, PageData } from "../..";
import { ComplianceHelper, Contract, RiskPolicyTypeMetadataHelper, useComplianceSectionDatas, useComplianceTranslator, useGetAnalysisGroupTypeToRiskPolicyIdentifierToCountMap, useGetComplianceScopeId, useTheme } from "../../../../../../../common";
import { Chart, Policies, Policy, PolicyTitle } from "../components";

export function PdfReport({ reportDefinitionConfiguration, scopeId }: ComplianceReportProps) {
    const { customComplianceSectionDatas, disabledBuiltInComplianceSectionDatas, enabledBuiltInComplianceSectionDatas } =
        useComplianceSectionDatas(
            scopeId,
            {
                childScopeCustomCompliance: true
            });
    const customCompliance = reportDefinitionConfiguration.typeName === Contract.TypeNames.CustomComplianceReportDefinitionConfiguration;
    const complianceIdentifier =
        customCompliance
            ? (reportDefinitionConfiguration as Contract.CustomComplianceReportDefinitionConfiguration).customComplianceId
            : (reportDefinitionConfiguration as Contract.BuiltInComplianceReportDefinitionConfiguration).sectionType;

    const complianceSectionDatas =
        _.concat(
            customComplianceSectionDatas,
            disabledBuiltInComplianceSectionDatas,
            enabledBuiltInComplianceSectionDatas);
    const [complianceRiskPolicySectionDatas, complianceRiskPolicyDatas, complianceSectionData, complianceTenantTypes] =
        useMemo(
            () => {
                const getComplianceRiskPolicySectionDatas =
                    (complianceSectionData: Contract.ComplianceSectionData): Contract.ComplianceSectionData[] => {
                        const riskPolicySectionData =
                            !_.isEmpty(complianceSectionData.riskPolicyDatas)
                                ? [complianceSectionData]
                                : [];

                        return _.isEmpty(complianceSectionData.sectionDatas)
                            ? riskPolicySectionData
                            : _.concat(
                                riskPolicySectionData,
                                _.flatMap(
                                    complianceSectionData.sectionDatas,
                                    complianceSectionDataSectionData => getComplianceRiskPolicySectionDatas(complianceSectionDataSectionData)));
                    };
                const complianceSectionData =
                    _.find(
                        complianceSectionDatas,
                        complianceSectionData => complianceSectionData.identifier === complianceIdentifier)!;

                const complianceRiskPolicySectionDatas = getComplianceRiskPolicySectionDatas(complianceSectionData);
                const complianceRiskPolicyDatas =
                    _.flatMap(
                        complianceRiskPolicySectionDatas,
                        complianceRiskPolicySectionData => complianceRiskPolicySectionData.riskPolicyDatas);
                const complianceTenantTypes =
                    _(complianceRiskPolicyDatas).
                        flatMap(
                            riskPolicyData =>
                                RiskPolicyTypeMetadataHelper.getTenantTypes(
                                    riskPolicyData.riskPolicyConfigurationTypeName,
                                    riskPolicyData.analysisGroupType)).
                        uniq().
                        value();
                return [
                    complianceRiskPolicySectionDatas,
                    complianceRiskPolicyDatas,
                    complianceSectionData,
                    complianceTenantTypes
                ];
            },
            [complianceSectionDatas]);

    const getComplianceScopeId = useGetComplianceScopeId();
    const getAnalysisGroupTypeToRiskPolicyIdentifierToCountMap =
        useGetAnalysisGroupTypeToRiskPolicyIdentifierToCountMap(
            () => complianceRiskPolicyDatas,
            getComplianceScopeId(
                complianceIdentifier,
                scopeId));

    const [{ analysisGroupTypeToRiskPolicyIdentifierToCountMap, complianceRiskPolicyIdentifierToExcludedEntityIdsMap }] =
        useExecuteOperation(
            PdfReport,
            async () => {
                const analysisGroupTypeToRiskPolicyIdentifierToCountMap = await getAnalysisGroupTypeToRiskPolicyIdentifierToCountMap();

                const complianceRiskPolicyIdentifierToExcludedEntityIdsMap =
                    await ComplianceHelper.getRiskPolicyIdentifierToExcludedEntityIdsMap(
                        complianceRiskPolicyDatas,
                        scopeId);

                return { analysisGroupTypeToRiskPolicyIdentifierToCountMap, complianceRiskPolicyIdentifierToExcludedEntityIdsMap };
            });

    const complianceTranslator = useComplianceTranslator();
    const localization =
        useLocalization(
            "views.system.exportReport.complianceReport.pdfReport",
            () => ({
                requirements: "REQUIREMENTS",
                summary: {
                    subtitle: "Represents the overall standard score while categorizing the findings by their severity.",
                    title: "{{complianceSectionItemTitle}} summary"
                }
            }));

    const translatedCompliance = complianceTranslator(complianceSectionData.identifier);
    const theme = useTheme();
    return (
        <PageData reportTitle={translatedCompliance.title}>
            <CoverPage
                reportType={Contract.ReportType.Compliance}
                scopeId={scopeId}
                subtitle={translatedCompliance.description!}
                tenantTypes={complianceTenantTypes}/>
            <ContentPages number={1}>
                {!_.isNil(complianceSectionData.stats) && (
                    <ContentPagesItem>
                        <Grid
                            container={true}
                            direction="column"
                            spacing={1}>
                            <Grid item={true}>
                                <Typography
                                    sx={{
                                        fontSize: "15px",
                                        fontWeight: 600
                                    }}>
                                    {localization.summary.title({ complianceSectionItemTitle: translatedCompliance.title })}
                                </Typography>
                                <Typography>
                                    {localization.summary.subtitle()}
                                </Typography>
                            </Grid>
                            <Grid item={true}>
                                <Chart complianceSectionData={complianceSectionData}/>
                            </Grid>
                        </Grid>
                    </ContentPagesItem>)}
                {<ContentPagesItem paddingTop={40}>
                    <Grid
                        container={true}
                        style={{ paddingBottom: theme.spacing(3) }}>
                        <Typography
                            sx={{
                                fontSize: "15px",
                                fontWeight: 600
                            }}>
                            {localization.requirements()}
                        </Typography>
                    </Grid>
                </ContentPagesItem>}
                {_.map(
                    complianceRiskPolicySectionDatas,
                    (complianceRiskPolicySectionData, complianceRiskPolicySectionDataIndex) =>
                        <ContentPagesItem key={complianceRiskPolicySectionData.identifier}>
                            {({ pageFirstItem, pageLastItem }) =>
                                <Policies
                                    borderBottom={
                                        complianceRiskPolicySectionDataIndex === complianceRiskPolicySectionDatas.length - 1 ||
                                        pageLastItem}
                                    borderTop={
                                        complianceRiskPolicySectionDataIndex === 0 ||
                                        pageFirstItem}
                                    complianceSectionData={complianceRiskPolicySectionData}
                                    key={complianceRiskPolicySectionData.identifier}/>}
                        </ContentPagesItem>)}
                {_.flatMap(
                    complianceRiskPolicySectionDatas,
                    complianceRiskPolicySectionData => [
                        <ContentPagesItem
                            key={complianceRiskPolicySectionData.identifier}
                            paddingTop={40}>
                            {({ pageLastItem }) =>
                                <Grid
                                    container={true}
                                    direction="column">
                                    <PolicyTitle
                                        complianceSectionData={complianceRiskPolicySectionData}
                                        complianceSectionDescription={complianceTranslator(complianceRiskPolicySectionData.identifier).description}
                                        complianceSectionTitle={complianceTranslator(complianceRiskPolicySectionData.identifier).title}/>
                                    {_.some(complianceRiskPolicySectionData.riskPolicyDatas) &&
                                        <Policy
                                            borderBottom={
                                                pageLastItem ||
                                                complianceRiskPolicySectionData.riskPolicyDatas.length === 1}
                                            borderTop={false}
                                            complianceRiskPolicyData={complianceRiskPolicySectionData.riskPolicyDatas[0]}
                                            count={analysisGroupTypeToRiskPolicyIdentifierToCountMap[(complianceRiskPolicySectionData.riskPolicyDatas[0]).analysisGroupType][(complianceRiskPolicySectionData.riskPolicyDatas[0]).riskPolicyIdentifier]}
                                            excludedEntityIds={complianceRiskPolicyIdentifierToExcludedEntityIdsMap[(complianceRiskPolicySectionData.riskPolicyDatas[0]).riskPolicyIdentifier]}/>}
                                </Grid>}
                        </ContentPagesItem>,
                        ..._(complianceRiskPolicySectionData.riskPolicyDatas).
                            drop(1).
                            map(
                                (complianceRiskPolicyData, complianceRiskPolicyDataIndex) =>
                                    <ContentPagesItem key={complianceRiskPolicyDataIndex}>
                                        {({ pageFirstItem, pageLastItem }) =>
                                            <Policy
                                                borderBottom={
                                                    pageLastItem ||
                                                    complianceRiskPolicyDataIndex === complianceRiskPolicySectionData.riskPolicyDatas.length - 2}
                                                borderTop={pageFirstItem}
                                                complianceRiskPolicyData={(complianceRiskPolicyData)}
                                                count={analysisGroupTypeToRiskPolicyIdentifierToCountMap[(complianceRiskPolicyData).analysisGroupType][(complianceRiskPolicyData as Contract.ComplianceRiskPolicyData).riskPolicyIdentifier]}
                                                excludedEntityIds={complianceRiskPolicyIdentifierToExcludedEntityIdsMap[(complianceRiskPolicyData).riskPolicyIdentifier]}/>}
                                    </ContentPagesItem>).
                            value()])}
            </ContentPages>
        </PageData>);
}