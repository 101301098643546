import { Link, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useGcpCommonScopeResourceInfoItemElements, useGcpCommonServiceAccountOriginatorScopeResourceInfoElements, useGcpDefaultResourceInfoItemElements } from "..";
import { Contract, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../../common";
import { useGcpContainerClusterNodePoolNodeImageTypeTranslator } from "../../../../../../../hooks";
import { EntitiesInfoItem, Info, TagsInfoItem } from "../../../../../components";
import { GcpScopeResourceRoleBindings, KubernetesTaintsInfoCard } from "../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { ComputeInstanceGroupsInfoCard } from "./components/ComputeInstanceGroupsInfoCard";

export function useGcpContainerClusterNodePoolDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const clusterNodePoolModel = scopeResourceModel as Contract.GcpContainerClusterModel;
    const clusterNodePool = clusterNodePoolModel.entity as Contract.GcpContainerClusterNodePool;
    const commonScopeResourcesInfoElements = useGcpCommonScopeResourceInfoItemElements(scopeResourceModel);
    const commonServiceAccountOriginatorScopeResourceInfoElements = useGcpCommonServiceAccountOriginatorScopeResourceInfoElements(scopeResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);

    const containerClusterNodePoolNodeImageTypeTranslator = useGcpContainerClusterNodePoolNodeImageTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpContainerClusterNodePoolDefinition",
            () => ({
                info: {
                    clusterId: "Cluster",
                    computeInstanceGroupIds: "Instance Groups",
                    node: {
                        autoscalingEnabled: {
                            false: "Disabled",
                            title: "Autoscaling",
                            true: "Enabled"
                        },
                        autoscalingLimitsTotalMaxInstanceCount: "Maximum number of all nodes",
                        autoscalingLimitsTotalMinInstanceCount: "Minimum number of all nodes",
                        autoscalingLimitsZonalMaxInstanceCount: "Maximum number of nodes",
                        autoscalingLimitsZonalMinInstanceCount: "Minimum number of nodes",
                        imageType: "Image Type",
                        kubernetesTags: "Kubernetes Labels",
                        kubernetesVersion: "Kubernetes Version",
                        rawType: "Machine Type",
                        workloadIdentity: {
                            false: "Disabled",
                            title: "GKE Metadata Server",
                            true: "Enabled"
                        }
                    },
                    podSubnet: "Pod IP Address Range",
                    serviceAccountAuthorizationScopes: "Access Scopes",
                    status: "Status"
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings"
                }
            }));

    const nodeTotalAutoscalingLimits = clusterNodePool.nodeAutoscaling?.totalAutoscalingLimits;
    const nodeZonalAutoscalingLimits = clusterNodePool.nodeAutoscaling?.zonalAutoscalingLimits;
    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GcpScopeResourceRoleBindings
                    csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                    scopeResourceModel={scopeResourceModel}/>,
                localization.tabs.resourceRoleBindings(),
                "resourceRoleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    commonScopeResourcesInfoElements.accessLevelInfoItemElement,
                    commonServiceAccountOriginatorScopeResourceInfoElements.serviceAccount,
                    <InfoItem
                        key="status"
                        location="all"
                        title={localization.info.status()}
                        value={clusterNodePool.status}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={clusterNodePoolModel.computeInstanceGroupIds}
                        entityTypeName={Contract.TypeNames.GcpComputeInstanceGroup}
                        key="computeInstanceGroupIds"
                        title={localization.info.computeInstanceGroupIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={clusterNodePool.clusterId}
                        entityTypeName={Contract.TypeNames.GcpContainerCluster}
                        key="clusterId"
                        location="all"
                        title={localization.info.clusterId()}/>,
                    <TagsInfoItem
                        key="nodeKubernetesTags"
                        resourceTags={clusterNodePool.node.kubernetesTags}
                        title={localization.info.node.kubernetesTags()}/>,
                    <VerticalTopItemsInfoItem
                        items={clusterNodePool.serviceAccountData.authorizationScopes}
                        key="serviceAccountAuthorizationScopes"
                        title={localization.info.serviceAccountAuthorizationScopes()}>
                        {authorizationScope =>
                            <Link
                                urlOrGetUrl={authorizationScope}
                                variant="external"/>}
                    </VerticalTopItemsInfoItem>,
                    <InfoItem
                        key="nodeRawType"
                        title={localization.info.node.rawType()}
                        value={clusterNodePool.node.rawType}/>,
                    <InfoItem
                        key="nodeImageType"
                        title={localization.info.node.imageType()}
                        value={containerClusterNodePoolNodeImageTypeTranslator(clusterNodePool.node.imageType)}/>,
                    <InfoItem
                        key="nodeWorkloadIdentity"
                        title={localization.info.node.workloadIdentity.title()}
                        value={
                            clusterNodePool.node.workloadIdentity
                                ? localization.info.node.workloadIdentity.true()
                                : localization.info.node.workloadIdentity.false()}/>,
                    <InfoItem
                        key="nodeAutoscalingEnabled"
                        title={localization.info.node.autoscalingEnabled.title()}
                        value={
                            !_.isNil(clusterNodePool.nodeAutoscaling)
                                ? localization.info.node.autoscalingEnabled.true()
                                : localization.info.node.autoscalingEnabled.false()}/>,
                    <InfoItem
                        key="nodeAutoscalingLimitsTotalMinInstanceCount"
                        title={localization.info.node.autoscalingLimitsTotalMinInstanceCount()}
                        value={
                            _.isNil(nodeTotalAutoscalingLimits)
                                ? undefined
                                : nodeTotalAutoscalingLimits.minInstanceCount}/>,
                    <InfoItem
                        key="nodeAutoscalingLimitsTotalMaxInstanceCount"
                        title={localization.info.node.autoscalingLimitsTotalMaxInstanceCount()}
                        value={
                            _.isNil(nodeTotalAutoscalingLimits)
                                ? undefined
                                : nodeTotalAutoscalingLimits.maxInstanceCount}/>,
                    <InfoItem
                        key="nodeAutoscalingLimitsZonalMinInstanceCount"
                        title={localization.info.node.autoscalingLimitsZonalMinInstanceCount()}
                        value={
                            _.isNil(nodeZonalAutoscalingLimits)
                                ? undefined
                                : nodeZonalAutoscalingLimits.minInstanceCount}/>,
                    <InfoItem
                        key="nodeAutoscalingLimitsZonalMaxInstanceCount"
                        title={localization.info.node.autoscalingLimitsZonalMaxInstanceCount()}
                        value={
                            _.isNil(nodeZonalAutoscalingLimits)
                                ? undefined
                                : nodeZonalAutoscalingLimits.maxInstanceCount}/>,
                    <InfoItem
                        key="nodeKubernetesVersion"
                        title={localization.info.node.kubernetesVersion()}
                        value={clusterNodePool.node.kubernetesVersion}/>,
                    <InfoItem
                        key="podSubnet"
                        title={localization.info.podSubnet()}
                        value={clusterNodePool.podSubnet}/>
                ]}
                options={options?.infoOptions}>
                {!_.isEmpty(clusterNodePool.node.kubernetesTaints) &&
                    <KubernetesTaintsInfoCard kubernetesTaints={clusterNodePool.node.kubernetesTaints}/>}
                <ComputeInstanceGroupsInfoCard computeInstanceGroupIds={clusterNodePoolModel.computeInstanceGroupIds}/>
            </Info>
    });
}