import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { DataTableColumnRenderProps, map, TimeFormatter, Tooltip, useLocalization } from "@infrastructure";
import { useApproverUserPermissionRequestsContext } from "../../../../../../..";
import { ActivePermissionRequestIcon, Contract, PendingPermissionRequestIcon, PermissionManagementHelper, useTheme } from "../../../../../../../../../../../common";
import { usePermissionRequestActivationFailureDataTranslator } from "../../../../../../../../../hooks";
import { useTableContext } from "../../../../../Table";

export function StatusCell({ item }: DataTableColumnRenderProps<Contract.PermissionRequestModel>) {
    const { entityModelMap } = useTableContext();

    const { userIds } = useApproverUserPermissionRequestsContext();
    const approverUserType =
        useMemo(
            () =>
                _(item.levelToApproverUserIdReferenceMap).
                    values().
                    intersection(userIds).
                    isEmpty()
                    ? "other"
                    : "self",
            [item, userIds]);

    const permissionRequestActivationFailureDataTranslator = usePermissionRequestActivationFailureDataTranslator();
    const localization =
        useLocalization(
            "views.user.approverUserPermissionRequests.table.hooks.useDefinition.hooks.useActiveDefinition.statusCell",
            () => ({
                status: {
                    active: {
                        other: "Approved by someone else (active)",
                        self: "Approved by me (active)"
                    },
                    pendingActivation: {
                        other: "Approved by someone else (pending activation)",
                        self: "Approved by me (pending activation)"
                    },
                    pendingApproval: "Waiting for me (pending approval)"
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Tooltip
                titleOrGetTitle={
                    _.isNil(item.activationFailureData)
                        ? undefined
                        : <Typography sx={{ whiteSpace: "pre-wrap" }}>
                            {permissionRequestActivationFailureDataTranslator(
                                item.activationFailureData,
                                entityModelMap)}
                        </Typography>}>
                <Box
                    sx={{
                        color:
                            _.isNil(item.activationFailureData)
                                ? map(
                                    item.permissionRequest.status,
                                    {
                                        [Contract.PermissionRequestStatus.Active]: () => PermissionManagementHelper.approvedPermissionRequestColor,
                                        [Contract.PermissionRequestStatus.PendingActivation]: () => PermissionManagementHelper.pendingPermissionRequestColor,
                                        [Contract.PermissionRequestStatus.PendingApproval]: () => PermissionManagementHelper.pendingPermissionRequestColor
                                    })
                                : theme.palette.warning.main,
                        fontSize: "18px"
                    }}>
                    {map(
                        item.permissionRequest.status,
                        {
                            [Contract.PermissionRequestStatus.Active]: () => <ActivePermissionRequestIcon/>,
                            [Contract.PermissionRequestStatus.PendingActivation]: () => <PendingPermissionRequestIcon/>,
                            [Contract.PermissionRequestStatus.PendingApproval]: () => <PendingPermissionRequestIcon/>
                        })}
                </Box>
            </Tooltip>
            <Stack spacing={0}>
                <Typography noWrap={true}>
                    {map(
                        item.permissionRequest.status,
                        {
                            [Contract.PermissionRequestStatus.Active]: () => localization.status.active[approverUserType](),
                            [Contract.PermissionRequestStatus.PendingActivation]: () => localization.status.pendingActivation[approverUserType](),
                            [Contract.PermissionRequestStatus.PendingApproval]: () => localization.status.pendingApproval()
                        })}
                </Typography>
                <Typography
                    noWrap={true}
                    sx={{
                        color: theme.palette.text.secondary,
                        fontSize: "10px"
                    }}>
                    {TimeFormatter.longDateTime(item.permissionRequest.statusUpdateTime)}
                </Typography>
            </Stack>
        </Stack>);
}