import { Action0, EmptyMessage, EmptyMessageSize, Optional, setRef, Sx, useElementEventEffect, useSyncContext, useWindowEventEffect } from "@infrastructure";
import { Box, BoxProps, CircularProgress, Stack, SxProps, useTheme } from "@mui/material";
import _, { Function0 } from "lodash";
import React, { Fragment, ReactNode, Ref, RefObject, Suspense, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useActions } from "../../hooks";
import { makeContextProvider } from "../../utilities";
import { ElementClass } from "./InfiniteScroll.element";

export type InfiniteScrollProps = {
    actionsRef?: Ref<Optional<InfiniteScrollActions>>;
    children: ReactNode;
    className?: string;
    container?: "card" | "popup";
    emptyTextOptions?: InfiniteScrollEmptyTextOptions;
    externalLoading?: boolean;
    fetchData: Function0<InfiniteScrollFetchDataResult> | Function0<Promise<InfiniteScrollFetchDataResult>>;
    manual?: boolean;
    marginBottom?: string;
    onScroll?: BoxProps["onScroll"];
    scrollElementRef?: RefObject<HTMLElement>;
    sx?: SxProps;
    waitForReset?: boolean;
};

export type InfiniteScrollActions = {
    fetchData: Action0;
    getScrollElement: () => HTMLElement | null;
    reset: Action0;
    setHasData: (hasData: boolean) => void;
    setLoadingRelatedData: (loadingRelatedData: boolean) => void;
    setManualFetchCompleted: Action0;
    update: (fetchDataResult: InfiniteScrollFetchDataResult) => void;
};

type InfiniteScrollEmptyTextOptions = {
    size?: EmptyMessageSize;
    sx?: SxProps;
    text?: string;
};

export class InfiniteScrollFetchDataResult {
    constructor(
        public hasData: boolean,
        public lastData: boolean,
        public appendData?: Action0) {
    }
}

class InfiniteScrollContext {
    constructor(
        public actions: InfiniteScrollActions,
        public scrollElementRef: RefObject<HTMLElement>) {
    }
}

const [useInfiniteScrollContext, , useInfiniteScrollContextProvider] = makeContextProvider<InfiniteScrollContext>();

export function InfiniteScroll({ actionsRef, children, className, container = "card", emptyTextOptions, externalLoading = false, fetchData, manual = false, marginBottom, onScroll, scrollElementRef: parentScrollElementRef, sx, waitForReset }: InfiniteScrollProps) {
    const fetchDataCoreSyncContext = useSyncContext();
    const fetchDataCorePromiseRef = useRef<Promise<void>>();
    const scrollElementRef = useRef<HTMLElement>(null);
    const resetRef = useRef(false);
    const [executeFetchData, setExecuteFetchData] = useState({});
    const [fetchDataExecuting, setFetchDataExecuting] = useState(false);
    const [loadingRelatedData, setLoadingRelatedData] = useState(false);
    const [hasData, setHasData] = useState<boolean>();
    const [lastData, setLastData] = useState(false);
    const actions =
        useActions(
            actionsRef,
            {
                fetchData: () => setExecuteFetchData({}),
                getScrollElement: () => scrollElementRef.current,
                reset:
                    () => {
                        fetchDataCoreSyncContext.clear();
                        fetchDataCorePromiseRef.current = undefined;
                        resetRef.current = true;
                        setExecuteFetchData({});
                        setHasData(undefined);
                        setLastData(false);
                        setLoadingRelatedData(false);
                    },
                setHasData:
                    hasData => setHasData(!lastData || hasData),
                setLoadingRelatedData:
                    loadingRelatedData => {
                        setLoadingRelatedData(loadingRelatedData);

                        if (!manual && !loadingRelatedData && hasData) {
                            setExecuteFetchData({});
                        }
                    },
                setManualFetchCompleted:
                    () => {
                        if (manual) {
                            setFetchDataExecuting(false);
                        }
                    },
                update:
                    fetchDataResult => {
                        fetchDataResult.appendData?.();
                        setHasData(fetchDataResult.hasData);
                        setLastData(fetchDataResult.lastData);
                    }
            });

    const fetchDataCoreExecutingRef = useRef(false);
    const loadingElementRef = useRef<HTMLDivElement>(null);
    const [, , ContextProvider] = useInfiniteScrollContextProvider(() => new InfiniteScrollContext(actions, scrollElementRef));

    const fetchDataCore =
        () => {
            if (fetchDataCoreExecutingRef.current ||
                !_.isNil(fetchDataCorePromiseRef.current) ||
                lastData) {
                return;
            }

            if (manual) {
                setFetchDataExecuting(true);
            } else {
                const loadingElementBoundingClientRect = loadingElementRef.current!.getBoundingClientRect();
                const scrollElementBoundingClientRect = scrollElementRef.current!.getBoundingClientRect();
                if (scrollElementBoundingClientRect.bottom < loadingElementBoundingClientRect.top) {
                    return;
                }
            }

            fetchDataCoreExecutingRef.current = true;
            const syncContext = fetchDataCoreSyncContext.create();
            const promiseOrResult = fetchData();

            function updateState(result: InfiniteScrollFetchDataResult) {
                setHasData(result.hasData);
                setLastData(result.lastData);

                if (!manual) {
                    setFetchDataExecuting(false);
                    if (result.hasData) {
                        setExecuteFetchData({});
                    }
                }

                if (!result.hasData) {
                    setFetchDataExecuting(false);
                }
            }

            if (promiseOrResult instanceof Promise) {
                fetchDataCorePromiseRef.current =
                    promiseOrResult.then(
                        result => {
                            if (fetchDataCoreSyncContext.isActive(syncContext)) {
                                fetchDataCorePromiseRef.current = undefined;
                                result.appendData?.();

                                updateState(result);
                            }
                        },
                        error => {
                            if (fetchDataCoreSyncContext.isActive(syncContext)) {
                                setLastData(() => {
                                    throw error;
                                });
                            }
                        });
            } else {
                updateState(promiseOrResult);
            }
        };

    useEffect(
        () => {
            if (!_.isNil(parentScrollElementRef)) {
                setRef(scrollElementRef, parentScrollElementRef.current);
            }
        },
        []);

    const [spinnerActive, setSpinnerActive] = useState(true);

    function updateSpinner() {
        const loadingElementBoundingClientRect = loadingElementRef.current?.getBoundingClientRect();
        const scrollElementBoundingClientRect = scrollElementRef.current?.getBoundingClientRect();

        setSpinnerActive(
            _.isNil(loadingElementBoundingClientRect) ||
            _.isNil(scrollElementBoundingClientRect) ||
            scrollElementBoundingClientRect.bottom >= loadingElementBoundingClientRect.top);
    }

    useLayoutEffect(
        () => {
            fetchDataCoreExecutingRef.current = false;

            updateSpinner();
        });

    useLayoutEffect(
        () => {
            if (waitForReset && !resetRef.current) {
                return;
            }
            fetchDataCore();
        },
        [executeFetchData]);

    useWindowEventEffect(
        "resize",
        () => {
            if (!manual) {
                fetchDataCore();
            }

            updateSpinner();
        });

    const positionYRef = useRef(0);
    useElementEventEffect(
        scrollElementRef,
        "scroll",
        (e: HTMLElementEventMap["scroll"]) => {
            if (!manual) {
                const positionY = (e.currentTarget as HTMLElement)?.scrollTop;
                if (positionY !== positionYRef.current) {
                    positionYRef.current = positionY;
                    fetchDataCore();
                }
            }

            updateSpinner();
        });

    const theme = useTheme();
    return (
        <ContextProvider>
            <Stack
                className={className}
                ref={
                    gridElement => {
                        if (_.isNil(scrollElementRef.current)) {
                            setRef(scrollElementRef, gridElement);
                        }
                    }}
                sx={
                    Sx.combine(
                        {
                            height: "100%",
                            overflow: "auto",
                            position: "relative",
                            width: "100%"
                        },
                        sx)}
                onScroll={onScroll}>
                {children}
                {!_.isNil(emptyTextOptions?.text) &&
                    hasData === false &&
                    !externalLoading &&
                    <EmptyMessage
                        containerSx={emptyTextOptions!.sx}
                        message={emptyTextOptions!.text}
                        size={
                            emptyTextOptions!.size ??
                                (container === "popup"
                                    ? "small"
                                    : undefined)}
                        verticalCenter={container === "card"}/>}
                {(loadingRelatedData ||
                    manual && fetchDataExecuting ||
                    !manual && !lastData) && (
                    <Box
                        sx={{
                            background: "transparent",
                            left: 0,
                            position: "sticky",
                            textAlign: "center",
                            zIndex: 999
                        }}>
                        <CircularProgress
                            className={
                                spinnerActive
                                    ? ElementClass.circularProgressActive
                                    : ElementClass.circularProgressInactive}
                            ref={loadingElementRef}
                            size="18px"
                            sx={{ margin: theme.spacing(2) }}
                            variant={
                                spinnerActive
                                    ? "indeterminate"
                                    : "determinate"}/>
                    </Box>)}
                {!_.isNil(marginBottom) && (
                    <Box sx={{ marginBottom }}/>)}
            </Stack>
        </ContextProvider>);
}

type InfiniteScrollItemsLoaderProps = {
    itemChunkSize: number;
    items: any[];
    onItemsLoaded?: Action0;
    renderItemPlaceholderElements?: (items: any[]) => JSX.Element[];
    virtualizationEnabled?: boolean;
};

const rowHeight = 44;

export function InfiniteScrollItemsLoader({ itemChunkSize, items, onItemsLoaded, renderItemPlaceholderElements, virtualizationEnabled = false }: InfiniteScrollItemsLoaderProps) {
    const { actions, scrollElementRef } = useInfiniteScrollContext();
    const [loadedChunkCount, setLoadedChunkCount] = useState(0);
    const [itemChunks, itemElementChunks] =
        useMemo(
            () => {
                const itemChunks = _.chunk(items, itemChunkSize);
                const itemElementChunks =
                    _.map(
                        itemChunks,
                        (itemChunk, itemChunkIndex) =>
                            <Suspense
                                fallback={<Fragment/>}
                                key={itemChunkIndex}>
                                <ItemChunkLoader
                                    key={itemChunkIndex}
                                    onLoaded={
                                        () => {
                                            actions.setManualFetchCompleted();
                                            actions.setLoadingRelatedData(false);

                                            onItemsLoaded?.();
                                            setLoadedChunkCount(loadedChunksCount => loadedChunksCount + 1);
                                        }}
                                    onLoading={() => actions.setLoadingRelatedData(true)}>
                                    {itemChunk}
                                </ItemChunkLoader>
                            </Suspense>);

                return [itemChunks, itemElementChunks];
            },
            [items]);

    const chunkHeight = rowHeight * itemChunkSize;
    const [visibleChunkIndex, setVisibleChunkIndex] = useState(0);

    useElementEventEffect(
        scrollElementRef,
        "scroll",
        event => {
            const scrollTop = (event.target as HTMLElement).scrollTop;
            const visibleChunkIndex = Math.floor((scrollTop - chunkHeight / 2) / chunkHeight);
            setVisibleChunkIndex(Math.max(visibleChunkIndex, 0));
        },
        {
            debounce: 200
        });

    const itemChunkElementsToRender =
        useMemo(
            () => {
                if (!virtualizationEnabled) {
                    return itemElementChunks;
                }

                const visibleChunkCount =
                    _.isNil(scrollElementRef.current)
                        ? 1
                        : Math.ceil(scrollElementRef.current.clientHeight / chunkHeight) + 2;
                return _.map(
                    itemElementChunks,
                    (itemElementChunk, itemElementChunkIndex) =>
                        itemElementChunkIndex >= visibleChunkIndex &&
                            itemElementChunkIndex < visibleChunkIndex + visibleChunkCount
                            ? itemElementChunk
                            : itemElementChunkIndex < loadedChunkCount
                                ? <Suspense
                                    fallback={<Fragment/>}
                                    key={itemElementChunkIndex}>
                                    <ItemChunkLoader>
                                        {renderItemPlaceholderElements!(itemChunks[itemElementChunkIndex])}
                                    </ItemChunkLoader>
                                </Suspense>
                                : <Fragment/>);
            },
            [itemElementChunks, loadedChunkCount, visibleChunkIndex]);

    return (
        <Fragment>
            {itemChunkElementsToRender}
        </Fragment>);
}

type ItemChunkLoaderProps = {
    children: ReactNode;
    onLoaded?: Action0;
    onLoading?: Action0;
};

function ItemChunkLoader({ children, onLoaded, onLoading }: ItemChunkLoaderProps) {
    useMemo(
        () => {
            onLoading?.();
        },
        []);

    useEffect(
        () => {
            onLoaded?.();
        },
        []);

    return (
        <Fragment>
            {children}
        </Fragment>);
}