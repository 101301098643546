import { Action0, EmptyMessageText, SearchList, searchListInfiniteItemsPageSize, useLocalization } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, Entity, EntityAttributes, EntityGlanceOptions, EntityLinkOptions, entityModelStore, entitySearchableReferenceStore, EntityVariant, useTheme } from "..";
import { useEntityTypeNameTranslator } from "../hooks";

type EntitiesProps = {
    attributes?: boolean;
    entityGlanceOptions?: EntityGlanceOptions;
    entityIdsOrModels: string[] | Contract.EntityModel[];
    entityLinkOptions?: EntityLinkOptions;
    entityTypeName: string;
    entityVariant?: EntityVariant;
    onEntityClick?: Action0;
    searchEnabled?: boolean;
    variant?: "dropdown" | "verticalFill" | "verticalGrow";
};

export function Entities({ attributes, entityGlanceOptions, entityIdsOrModels, entityLinkOptions, entityTypeName, entityVariant, onEntityClick, searchEnabled = false, variant = "verticalFill" }: EntitiesProps) {
    const entitySearchableReferences =
        entitySearchableReferenceStore.useGet(
            _.isString(entityIdsOrModels[0])
                ? entityIdsOrModels as string[]
                : undefined);
    entityModelStore.useGet(
        _.isString(entityIdsOrModels[0])
            ? variant === "verticalGrow"
                ? entityIdsOrModels as string[]
                : _.take(entityIdsOrModels as string[], searchListInfiniteItemsPageSize)
            : undefined);
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.entities",
            () => ({
                empty: {
                    withFilter: "No Matching {{translatedEntityTypeName}}",
                    withoutFilter: "No {{translatedEntityTypeName}}"
                }
            }));
    const translatedEntityTypeName =
        entityTypeNameTranslator(
            entityTypeName,
            {
                count: 0,
                includeServiceName: false
            });

    const theme = useTheme();
    return _.isNil(entitySearchableReferences)
        ? <SearchList
            disablePadding={true}
            emptyMessageText={
                new EmptyMessageText(
                    localization.empty.withoutFilter({ translatedEntityTypeName }),
                    localization.empty.withFilter({ translatedEntityTypeName }))}
            itemOptions={{
                getSortValue: (entityModel: Contract.EntityModel) => entityModel.entity.displayName,
                render:
                    (entityModel: Contract.EntityModel) =>
                        <Item
                            attributes={attributes}
                            entityGlanceOptions={entityGlanceOptions}
                            entityId={entityModel.id}
                            entityLinkOptions={entityLinkOptions}
                            entityVariant={entityVariant}
                            onClick={onEntityClick}/>,
                spacing: 1
            }}
            items={entityIdsOrModels as Contract.EntityModel[]}
            searchOptions={{ enabled: searchEnabled }}
            sx={{ padding: theme.spacing(0, 1.5) }}
            variant={variant}/>
        : <SearchList
            disablePadding={true}
            emptyMessageText={
                new EmptyMessageText(
                    localization.empty.withoutFilter({ translatedEntityTypeName }),
                    localization.empty.withFilter({ translatedEntityTypeName }))}
            itemOptions={{
                getSortValue: (entitySearchableReference: Contract.EntitySearchableReference) => entitySearchableReference.displayName,
                render:
                    (entitySearchableReference: Contract.EntitySearchableReference) =>
                        <Item
                            attributes={attributes}
                            entityGlanceOptions={entityGlanceOptions}
                            entityId={entitySearchableReference.id}
                            entityLinkOptions={entityLinkOptions}
                            entityVariant={entityVariant}
                            onClick={onEntityClick}/>,
                spacing: 1
            }}
            items={entitySearchableReferences}
            searchOptions={{ enabled: searchEnabled }}
            sx={{ padding: theme.spacing(0, 1.5) }}
            variant={variant}
            onItemPageLoading={
                async (entitySearchableReferences: Contract.EntitySearchableReference[]) => {
                    await entityModelStore.get(
                        _.map(
                            entitySearchableReferences,
                            entitySearchableReference => entitySearchableReference.id));
                }}/>;
}

type ItemProps = {
    attributes?: boolean;
    entityGlanceOptions?: EntityGlanceOptions;
    entityId: string;
    entityLinkOptions?: EntityLinkOptions;
    entityVariant?: EntityVariant;
    onClick?: Action0;
};

function Item({ attributes = false, entityGlanceOptions, entityId, entityLinkOptions, entityVariant = "iconTextTenant", onClick }: ItemProps) {
    const entityModel = entityModelStore.useGet(entityId);

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ width: "100%" }}>
            <Box
                sx={{
                    flex: 1,
                    overflow: "hidden"
                }}>
                <Entity
                    entityIdOrModel={entityModel}
                    glanceOptions={entityGlanceOptions}
                    key={entityModel.id}
                    linkOptions={entityLinkOptions}
                    variant={entityVariant}
                    onClick={onClick}/>
            </Box>
            {attributes &&
                <EntityAttributes
                    breakpointToTopCount={{
                        "lg": 3,
                        "xl": 4,
                        "xs": 2
                    }}
                    entityAttributes={entityModel.attributes.attributes}
                    entityTypeName={entityModel.entity.typeName}
                    orderVariant="leftToRight"/>}
        </Stack>);
}