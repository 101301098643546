import _ from "lodash";
import { ReactNode, useMemo } from "react";
import { Contract, CustomerConsoleAppUrlHelper, RiskType, RiskTypeGroups, StorageHelper } from "../../../../../../../../../common";
import { RiskDefinitionSectionCategory } from "../../../../../hooks/useDefinition/utilities";
import { FiltersHelper } from "../../../../Filters";
import { useTableCommonCodeColumns } from "../../useTableCommonCodeColumns";
import { useTableCommonColumns } from "../../useTableCommonColumns";
import { RiskTypeDefinition } from "../useRiskTypeDefinition";
import { useCommonSelectionActions } from "./useCommonSelectionActions";

export function useCodeDefinition(view: Contract.RisksView) {
    const commonColumns = useTableCommonColumns(view, true);
    const commonCodeColumns = useTableCommonCodeColumns(true);
    const commonSelectionActions = useCommonSelectionActions(RiskType.Code, view);

    return useMemo(
        (): RiskTypeDefinition => ({
            groups: [
                RiskTypeGroups.RiskPolicyType,
                RiskTypeGroups.Repository
            ],
            rowOptions: {
                getUrl: (item: Contract.RiskModel) =>
                    CustomerConsoleAppUrlHelper.getCodeRiskProfileHashUrl(
                        item.risk.id,
                        { category: RiskDefinitionSectionCategory.Overview })
            },
            selectionActionElements:
                ({ clearSelection, getSelectedRiskIdToScopeIdsMap, reloadRiskModels }) =>
                    _<ReactNode>([]).
                        concatIf(
                            view !== Contract.RisksView.Closed,
                            commonSelectionActions.close(reloadRiskModels),
                            commonSelectionActions.divider).
                        concat([
                            commonSelectionActions.star(reloadRiskModels),
                            commonSelectionActions.comment(clearSelection)]).
                        concatIf(
                            view === Contract.RisksView.Closed,
                            commonSelectionActions.open(reloadRiskModels)).
                        concat([
                            commonSelectionActions.sendMail(clearSelection),
                            commonSelectionActions.sendWebhook(clearSelection, getSelectedRiskIdToScopeIdsMap),
                            commonSelectionActions.jiraCreate(getSelectedRiskIdToScopeIdsMap, reloadRiskModels, RiskType.Code),
                            commonSelectionActions.jiraUpdate(getSelectedRiskIdToScopeIdsMap, reloadRiskModels, RiskType.Code),
                            commonSelectionActions.serviceNowCreate(getSelectedRiskIdToScopeIdsMap, reloadRiskModels),
                            commonSelectionActions.serviceNowUpdate(getSelectedRiskIdToScopeIdsMap, reloadRiskModels)
                        ]).
                        value(),
            storageOptions: {
                groupBy: StorageHelper.customerCodeRisksItemsGroupingType,
                grouped: {
                    getColumnOrderStorageItem: (groupId: string) => StorageHelper.customerCodeRisksGroupColumnOrder(view, groupId),
                    getColumnSelectorStorageItem: (groupId: string) => StorageHelper.customerCodeRisksGroupColumnSelector(view, groupId)
                },
                ungrouped: {
                    columnOrder: StorageHelper.customerCodeRisksColumnOrder(view),
                    columnSelector: StorageHelper.customerCodeRisksColumnSelector(view)
                }
            },
            toRequestRiskFiltersFromFilterMap: FiltersHelper.toRequestCodeRiskFiltersFromFilterMap,
            toRiskReportRequestDefinitionFromFilters:
                (filtered: boolean, filters: Contract.RiskControllerRiskModelFilters, status: Contract.RiskStatus, sort?: Contract.RiskControllerRiskModelSort, timeZoneInfoId?: string) =>
                    new Contract.ReportControllerCodeRisksReportRequestDefinition(
                        undefined,
                        timeZoneInfoId,
                        Contract.TypeNames.ReportControllerCodeRisksReportRequestDefinition,
                        undefined,
                        filtered,
                        filters,
                        undefined,
                        sort,
                        status),
            ungrouped: {
                columns: [
                    commonColumns.statusUpdateTimeColumn,
                    commonColumns.severityColumn,
                    commonColumns.riskPolicyIdColumn,
                    commonCodeColumns.repository,
                    commonCodeColumns.file,
                    commonCodeColumns.resource,
                    commonColumns.statusColumn(false),
                    commonColumns.subStatusColumn(false),
                    commonColumns.openStatusUpdateTimeColumn,
                    commonColumns.systemCreationTimeColumn,
                    commonColumns.compliancesColumn,
                    commonColumns.actionsCell(RiskType.Code)
                ]
            }
        }),
        [StorageHelper, commonColumns, commonCodeColumns, commonSelectionActions, view]);
}