﻿import { Action1, EmptyMessageText, MultiSelect, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Contract, ScopeHelper, scopeSystemEntityModelStore, TeamsChannelReferenceHelper, TeamsIcon } from "..";

type TeamsChannelReferenceMultiSelectProps = {
    disabled?: boolean;
    iconVisible?: boolean;
    onSelectedChannelReferencesChanged: Action1<Contract.TeamsChannelReference[]>;
    scopeId: string;
    selectedChannelReferences: Contract.TeamsChannelReference[];
};

export function TeamsChannelReferenceMultiSelect({ disabled = false, iconVisible = true, onSelectedChannelReferencesChanged, scopeId, selectedChannelReferences }: TeamsChannelReferenceMultiSelectProps) {
    const teamsModels = scopeSystemEntityModelStore.useGetTeams();
    const organizationModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeId],
            teamsModels);
    const [channelReferenceIds, channelReferenceIdToDataMap] =
        useMemo(
            () => {
                const channelReferenceIdToDataMap =
                    _(organizationModels).
                        flatMap(
                            organizationModel =>
                                _.flatMap(
                                    (organizationModel.state as Contract.TeamsOrganizationState).teamRawIdToChannelReferencesMap,
                                    (channelReferences, teamRawId) =>
                                        _.map(
                                            channelReferences,
                                            channelReference => ({
                                                name: (organizationModel.state as Contract.TeamsOrganizationState).channelRawIdToNameMap[channelReference.rawId],
                                                organizationName: (organizationModel.configuration as Contract.TeamsOrganizationConfiguration).name,
                                                reference: channelReference,
                                                teamName: (organizationModel.state as Contract.TeamsOrganizationState).teamRawIdToNameMap[teamRawId]
                                            })))).
                        keyBy(({ reference }) => TeamsChannelReferenceHelper.getId(reference)).
                        value();

                return [_.keys(channelReferenceIdToDataMap), channelReferenceIdToDataMap];
            },
            [organizationModels]);

    const localization =
        useLocalization(
            "common.teamsChannelReferenceMultiSelect",
            () => ({
                channel: "{{teamName}} | {{name}}",
                empty: {
                    withFilter: "No Matching channels",
                    withoutFilter: "No channels"
                },
                placeholder: "Channels",
                selection: "{{channelsCount | NumberFormatter.humanize}} channels"
            }));

    const [selectedChannelReferenceIds, setSelectedChannelReferenceIds] =
        useState(
            () =>
                _.map(
                    selectedChannelReferences,
                    TeamsChannelReferenceHelper.getId));
    return (
        <MultiSelect
            disabled={disabled}
            emptyMessageText={
                new EmptyMessageText(
                    localization.empty.withoutFilter(),
                    localization.empty.withFilter())}
            fieldOptions={{ variant: "text" }}
            fullWidth={true}
            getItemText={channelReferenceId => `${channelReferenceIdToDataMap[channelReferenceId].organizationName}-${channelReferenceIdToDataMap[channelReferenceId].teamName}-${channelReferenceIdToDataMap[channelReferenceId].name}`}
            icon={
                iconVisible
                    ? <TeamsIcon
                        sx={{
                            fontSize: "24px",
                            opacity:
                                disabled
                                    ? 0.3
                                    : 1
                        }}/>
                    : undefined}
            items={channelReferenceIds}
            placeholder={localization.placeholder()}
            selectedItems={selectedChannelReferenceIds}
            selectedItemsTranslator={
                selectedChannelReferenceIds =>
                    selectedChannelReferenceIds.length === 1
                        ? channelReferenceIdToDataMap[selectedChannelReferenceIds[0]].name
                        : localization.selection({ channelsCount: selectedChannelReferenceIds.length }) }
            onSelectedItemsChanged={
                selectedChannelReferenceIds => {
                    setSelectedChannelReferenceIds(selectedChannelReferenceIds);
                    onSelectedChannelReferencesChanged(
                        _.map(
                            selectedChannelReferenceIds,
                            selectedChannelReferenceId => channelReferenceIdToDataMap[selectedChannelReferenceId].reference));
                }}>
            {channelReferenceId =>
                <Stack>
                    <Typography>
                        {localization.channel({
                            name: channelReferenceIdToDataMap[channelReferenceId].name,
                            teamName: channelReferenceIdToDataMap[channelReferenceId].teamName
                        })}
                    </Typography>
                    <Typography variant="subtitle1">
                        {channelReferenceIdToDataMap[channelReferenceId].organizationName}
                    </Typography>
                </Stack>}
        </MultiSelect>);
}