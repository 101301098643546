import { SxProps, Typography } from "@mui/material";
import { TOptions } from "i18next";
import React from "react";
import { InlineEntitySelection } from "../..";
import { Contract } from "../../../../../controllers";

type ViewProps = {
    riskPolicyConfiguration?: Contract.AwsEncryptedResourceKmsEncryptionNotExistsRiskPolicyConfiguration;
    sx?: SxProps;
    templateTranslator: (options?: TOptions) => string;
};

export function View({ riskPolicyConfiguration, sx, templateTranslator }: ViewProps) {
    return (
        <Typography
            component="span"
            sx={sx}>
            {templateTranslator({
                keys:
                    <InlineEntitySelection
                        entityTypeName={Contract.TypeNames.AwsKmsKey}
                        selection={{
                            anyCustomerManagedKmsKey: riskPolicyConfiguration?.anyCustomerManagedKmsKey,
                            anyEntity: riskPolicyConfiguration?.anyKmsKey,
                            entityBuiltInAttributeTypeNames: riskPolicyConfiguration?.kmsKeyBuiltInAttributeTypeNames,
                            entityCustomAttributeDefinitionIds: riskPolicyConfiguration?.kmsKeyCustomAttributeDefinitionIds,
                            entityIds: riskPolicyConfiguration?.kmsKeyIds,
                            entityTags: riskPolicyConfiguration?.kmsKeyTags
                        }}
                        sx={sx}/>,
                resources:
                    <InlineEntitySelection
                        entityTypeName={Contract.TypeNames.IAwsEncryptedResource}
                        selection={{
                            allEntities: riskPolicyConfiguration?.allEncryptedResources,
                            entityBuiltInAttributeTypeNames: riskPolicyConfiguration?.encryptedResourceBuiltInAttributeTypeNames,
                            entityCustomAttributeDefinitionIds: riskPolicyConfiguration?.encryptedResourceCustomAttributeDefinitionIds,
                            entityIds: riskPolicyConfiguration?.encryptedResourceIds,
                            entityTags: riskPolicyConfiguration?.encryptedResourceTags,
                            entityTypeNames: riskPolicyConfiguration?.encryptedResourceTypeNames
                        }}
                        sx={sx}/>
            })}
        </Typography>);
}