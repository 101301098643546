import { DataTableColumn, DataTableSortType, DeferredFilter, optionalTableCell, TimeFormatter, TimeRangeFilter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InlineResourceTags, PagedEntityResourceTagFilter, ResourceTagHelper, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useCommonEntityDefinition } from "../useCommonEntityDefinition";

export function useOciCommonResourceDefinition(definitionData: DefinitionData) {
    const commonEntityDefinition = useCommonEntityDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const resourceModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.OciResourceModelFilters>;

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciCommonResourceDefinition",
            () => ({
                columns: {
                    creationTime: "Creation Time",
                    creatorIdentityModel: "Created By Identity",
                    tags: "Tags"
                }
            }));

    return {
        columns: {
            ...commonEntityDefinition.columns,
            creationTimeColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.OciResourceModel) => ({
                                [localization.columns.creationTime()]: TimeFormatter.iso8601String((item as Contract.OciResourceModel).creationTime)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <DeferredFilter
                                    promiseOrGetPromise={resourceModelFiltersPromise}
                                    title={localization.columns.creationTime()}>
                                    {filters =>
                                        <TimeRangeFilter
                                            emptyValue={filters.creationTimeRange.emptyValue}
                                            placeholder={localization.columns.creationTime()}
                                            timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.creationTimeRange.timeRange)}/>}
                                </DeferredFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.ResourceCreationTime}
                    key={Contract.EntityModelProperty.ResourceCreationTime}
                    render={
                        optionalTableCell<Contract.OciResourceModel>(
                            item =>
                                _.isNil(item.creationTime)
                                    ? undefined
                                    : TimeFormatter.monthDayAndYear(item.creationTime))}
                    sortOptions={{ type: DataTableSortType.Date }}
                    title={localization.columns.creationTime()}/>,
            creatorCsvColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.OciResourceModel>(
                            Contract.TypeNames.OciIamPrincipal,
                            item => [item.creatorIdentityIdReference],
                            localization.columns.creatorIdentityModel())}
                    id="creatorIdModel"/>,
            tagsColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.OciResourceModel) => ({
                                [localization.columns.tags()]:
                                    _((item.entity as Contract.OciResource).tags).
                                        map(ResourceTagHelper.getDisplayName).
                                        join("\n")
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityResourceTagFilter
                                    entityTypeName={definitionData.entityTypeEntitiesViewName}
                                    placeholder={localization.columns.tags()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.ResourceTags}
                    key={Contract.EntityModelProperty.ResourceTags}
                    render={
                        optionalTableCell<Contract.OciResourceModel>(
                            (item: Contract.OciResourceModel) =>
                                _.isEmpty((item.entity as Contract.OciResource).tags)
                                    ? undefined
                                    : <InlineResourceTags tags={(item.entity as Contract.OciResource).tags}/>)}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.tags()}/>
        }
    };
}