import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, InlineItems, Optional, optionalTableCell, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity } from "../../../common";

type AwsCloudFrontDistributionOriginsInfoCardProps = {
    getHighlightColor?: (origin: Contract.AwsCloudFrontDistributionOrigin, opacity?: number) => Optional<string>;
    originIdToResourceIdReferenceMap: Dictionary<string>;
    origins: Contract.AwsCloudFrontDistributionOrigin[];
};

export function AwsCloudFrontDistributionOriginsTable({ getHighlightColor, originIdToResourceIdReferenceMap, origins }: AwsCloudFrontDistributionOriginsInfoCardProps) {
    const orderedOrigins =
        useMemo(
            () =>
                _.orderBy(
                    origins,
                    origin => _.isNil(getHighlightColor?.(origin))),
            [origins]);
    const localization =
        useLocalization(
            "tenants.aws.awsCloudFrontDistributionOriginsTable",
            () => ({
                columns: {
                    access: {
                        title: "Access",
                        [Contract.TypeNames.AwsCloudFrontDistributionOriginAccess]: {
                            [Contract.AwsCloudFrontDistributionOriginAccess.OriginAccessControl]: "Origin Access Control",
                            [Contract.AwsCloudFrontDistributionOriginAccess.OriginAccessIdentities]: "Origin Access Identities",
                            [Contract.AwsCloudFrontDistributionOriginAccess.Public]: "Public"
                        }
                    },
                    id: "Name",
                    origin: "Origin",
                    path: "Path",
                    protocolPolicy: {
                        title: "Protocol",
                        [Contract.TypeNames.AwsCloudFrontDistributionOriginProtocolPolicy]: {
                            [Contract.AwsCloudFrontDistributionOriginProtocolPolicy.HttpOnly]: "HTTP Only",
                            [Contract.AwsCloudFrontDistributionOriginProtocolPolicy.HttpsOnly]: "HTTPS Only",
                            [Contract.AwsCloudFrontDistributionOriginProtocolPolicy.MatchViewer]: "Match Viewer"
                        }
                    },
                    securityPolicy: {
                        custom: "Custom Policy",
                        title: "Security Policy"
                    },
                    sslProtocol: {
                        title: "Supported SSL protocols",
                        [Contract.TypeNames.AwsCloudFrontDistributionOriginSslProtocol]: {
                            [Contract.AwsCloudFrontDistributionOriginSslProtocol.SslV3]: "SSLv3",
                            [Contract.AwsCloudFrontDistributionOriginSslProtocol.TlsV1]: "TLSv1",
                            [Contract.AwsCloudFrontDistributionOriginSslProtocol.TlsV11]: "TLSv1.1",
                            [Contract.AwsCloudFrontDistributionOriginSslProtocol.TlsV12]: "TLSv1.2"
                        }
                    },
                    supportedProtocols: "Supported Protocols",
                    targetGroupName: "Target Group Name",
                    targets: "Targets",
                    type: {
                        title: "Type",
                        [Contract.TypeNames.AwsCloudFrontDistributionOriginType]: {
                            [Contract.AwsCloudFrontDistributionOriginType.Custom]: "Custom",
                            [Contract.AwsCloudFrontDistributionOriginType.S3]: "S3"
                        }
                    }
                },
                empty: "No Origins"
            }));

    return (
        <DataTable
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={() => orderedOrigins}
            getItemId={(item: Contract.AwsCloudFrontDistributionOrigin) => item.id}
            rowOptions={{
                getHighlightColor: (origin: Contract.AwsCloudFrontDistributionOrigin) => getHighlightColor?.(origin),
                getSx:
                    (origin: Contract.AwsCloudFrontDistributionOrigin) => ({
                        backgroundColor: getHighlightColor?.(origin, 0.1)
                    })
            }}
            sortOptions={{ enabled: false }}
            variant="card">
            <DataTableColumn
                id={CloudFrontDistributionOriginsColumnId.Id}
                itemProperty={(origin: Contract.AwsCloudFrontDistributionOrigin) => origin.id}
                title={localization.columns.id()}/>
            <DataTableColumn
                id={CloudFrontDistributionOriginsColumnId.Origin}
                render={
                    ({ item: origin }: DataTableColumnRenderProps<Contract.AwsCloudFrontDistributionOrigin>) =>
                        <Entity
                            entityIdOrModel={originIdToResourceIdReferenceMap[origin.id]}
                            variant="iconTextTypeTenant"/>}
                title={localization.columns.origin()}/>
            <DataTableColumn
                id={CloudFrontDistributionOriginsColumnId.Type}
                itemProperty={(origin: Contract.AwsCloudFrontDistributionOrigin) => localization.columns.type[Contract.TypeNames.AwsCloudFrontDistributionOriginType][origin.type]()}
                title={localization.columns.type.title()}/>
            <DataTableColumn
                id={CloudFrontDistributionOriginsColumnId.Path}
                itemProperty={(origin: Contract.AwsCloudFrontDistributionOrigin) => origin.path}
                title={localization.columns.path()}/>
            <DataTableColumn
                id={CloudFrontDistributionOriginsColumnId.Access}
                render={
                    optionalTableCell<Contract.AwsCloudFrontDistributionOrigin>(
                        origin =>
                            _.isNil(origin.access)
                                ? undefined
                                : localization.columns.access[Contract.TypeNames.AwsCloudFrontDistributionOriginAccess][origin.access]())}
                title={localization.columns.access.title()}/>
            <DataTableColumn
                id={CloudFrontDistributionOriginsColumnId.ProtocolPolicy}
                render={
                    optionalTableCell<Contract.AwsCloudFrontDistributionOrigin>(
                        origin =>
                            _.isNil(origin.protocolPolicy)
                                ? undefined
                                : localization.columns.protocolPolicy[Contract.TypeNames.AwsCloudFrontDistributionOriginProtocolPolicy][origin.protocolPolicy]())}
                title={localization.columns.protocolPolicy.title()}/>
            <DataTableColumn
                id={CloudFrontDistributionOriginsColumnId.SslProtocols}
                render={
                    optionalTableCell<Contract.AwsCloudFrontDistributionOrigin>(
                        origin =>
                            _.isNil(origin.sslProtocols)
                                ? undefined
                                : <InlineItems
                                    items={
                                        _(origin.sslProtocols).
                                            map(sslProtocol => localization.columns.sslProtocol[Contract.TypeNames.AwsCloudFrontDistributionOriginSslProtocol][sslProtocol]()).
                                            orderBy().
                                            value()}
                                    variant="itemPlusItemCount"/>)}
                title={localization.columns.sslProtocol.title()}/>
        </DataTable>);
}

enum CloudFrontDistributionOriginsColumnId {
    Access = "access",
    Id = "id",
    Origin = "origin",
    Path = "path",
    ProtocolPolicy = "protocolPolicy",
    SslProtocols = "sslProtocols",
    Type = "type"
}