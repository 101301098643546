import { AddIcon, CheckButton, DataTable, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, NoneIcon, StringHelper, useChangeEffect, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Button, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { Contract, InlineDeliveries, reportDefinitionConfigurationStore, ReportDeliveryType, scopeNodeModelStore, scopeSystemEntityModelStore, StorageHelper, useReportTypeTranslator, useScopeNameTranslator, useScopeNavigationViewContext } from "../../../../../../common";
import { useReportDefinitionTranslator } from "../../hooks";
import { useSetReportsContext } from "../../Reports";
import { ActionsCell } from "./components";
import { useReportScheduleCadenceTranslator } from "./hooks";

export function Schedule() {
    const reportDefinitionConfigurations = reportDefinitionConfigurationStore.useGetAll();
    const reportDefinitionConfigurationMap =
        useMemo(
            () =>
                _.keyBy(
                    reportDefinitionConfigurations,
                    reportDefinitionConfiguration => reportDefinitionConfiguration.id) as Dictionary<Contract.ReportDefinitionConfiguration>,
            [reportDefinitionConfigurations]);

    const reportScheduleModels = scopeSystemEntityModelStore.useGetReportSchedule();
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const [childScopeReportsEnabled, setChildScopeEnabledEnabled] = useState(!StringHelper.isFalse(StorageHelper.customerReportsScheduleFlatView.getValue()));
    const scopeNodeMap =
        scopeNodeModelStore.useGetActiveScopeNodeMap(
            undefined,
            true);
    const childScopeIds =
        useMemo(
            () => scopeNodeMap[scopeNodeModel.configuration.id].scopeIds,
            [scopeNodeModel.configuration.id, scopeNodeMap]);
    const scopeReportScheduleModels =
        useMemo(
            () =>
                _.filter(
                    reportScheduleModels,
                    reportScheduleModel =>
                        reportScheduleModel.configuration.scopeId === scopeNodeModel.configuration.id ||
                        (childScopeReportsEnabled && _.includes(childScopeIds, reportScheduleModel.configuration.scopeId))),
            [childScopeReportsEnabled, childScopeIds, reportScheduleModels]);

    const reportDefinitionTranslator = useReportDefinitionTranslator();
    const reportTypeTranslator = useReportTypeTranslator();
    const fetchReportScheduleModels =
        (filterMap: Dictionary<any>) =>
            _(scopeReportScheduleModels).
                filter(
                    scopeReportScheduleModel =>
                        (_.isNil(filterMap[ScheduleColumnId.DefinitionId]) ||
                            _.includes(
                                filterMap[ScheduleColumnId.DefinitionId].values,
                                (scopeReportScheduleModel.configuration as Contract.ReportScheduleConfiguration).definitionId)) &&
                        (_.isNil(filterMap[ScheduleColumnId.Name]) ||
                            _.includes(
                                filterMap[ScheduleColumnId.Name].values,
                                reportDefinitionConfigurationMap[(scopeReportScheduleModel.configuration as Contract.ReportScheduleConfiguration).definitionId].name) ||
                            filterMap[ScheduleColumnId.Name].emptyValue &&
                            _.isEmpty(reportDefinitionConfigurationMap[(scopeReportScheduleModel.configuration as Contract.ReportScheduleConfiguration).definitionId].name))).
                orderBy([
                    scopeReportScheduleModel => StringHelper.getSortValue(reportTypeTranslator(reportDefinitionConfigurationMap[(scopeReportScheduleModel.configuration as Contract.ReportScheduleConfiguration).definitionId].type)),
                    scopeReportScheduleModel => StringHelper.getSortValue(reportDefinitionTranslator((scopeReportScheduleModel.configuration as Contract.ReportScheduleConfiguration).definitionId)),
                    scopeReportScheduleModel => (scopeReportScheduleModel.configuration as Contract.ReportScheduleConfiguration).definitionId
                ]).
                value();

    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => dataTableActionsRef.current!.reload({ refreshFilters: true }),
        [reportScheduleModels, childScopeReportsEnabled]);

    const setReportsContext = useSetReportsContext();

    const reportScheduleCadenceTranslator = useReportScheduleCadenceTranslator();
    const scopeNameTranslator = useScopeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.reports.schedule",
            () => ({
                actions: {
                    add: "New Report",
                    childScopeReportsEnabled: "Flat View"
                },
                columns: {
                    cadence: "Schedule",
                    delivery: "Delivery",
                    name: "Name",
                    reportType: "Type",
                    scope: "Scope"
                },
                empty: {
                    withFilter: "No Matching Scheduled Reports",
                    withoutFilter: "No Scheduled Reports"
                }
            }));
    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            columnOptions={{
                orderOptions: {
                    enabled: true,
                    persistenceStorageItem: StorageHelper.customerReportsScheduleColumnOrder
                }
            }}
            emptyMessageOptions={{
                emptyMessageText:
                    new EmptyMessageText(
                        localization.empty.withoutFilter(),
                        localization.empty.withFilter())
            }}
            fetchItems={fetchReportScheduleModels}
            filtersOptions={{
                persist: {
                    visibilityStorageItem: StorageHelper.customerReportsScheduleTableFilters
                }
            }}
            getItemId={(item: Contract.ScopeSystemEntityModel) => item.configuration.id}
            sortOptions={{ enabled: false }}>
            <DataTableAction>
                <CheckButton
                    checked={childScopeReportsEnabled}
                    title={localization.actions.childScopeReportsEnabled()}
                    onCheckedChanged={
                        checked => {
                            setChildScopeEnabledEnabled(checked);
                            StorageHelper.customerReportsScheduleFlatView.setValue(checked);
                        }}/>
            </DataTableAction>
            <DataTableAction>
                <Button
                    size="small"
                    startIcon={<AddIcon/>}
                    onClick={
                        () =>
                            setReportsContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: true,
                                    reportDeliveryType: ReportDeliveryType.Schedule
                                }))}>
                    {localization.actions.add()}
                </Button>
            </DataTableAction>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <ValuesFilter
                                groupItemTitle={true}
                                placeholder={localization.columns.reportType()}>
                                {_(scopeReportScheduleModels).
                                    map(scopeReportScheduleModel => (scopeReportScheduleModel.configuration as Contract.ReportScheduleConfiguration).definitionId).
                                    uniq().
                                    map(
                                        definitionId =>
                                            <ValuesFilterItem
                                                key={definitionId}
                                                title={reportDefinitionTranslator(definitionId)}
                                                value={definitionId}/>).
                                    value()}
                            </ValuesFilter>
                    }
                }}
                id={ScheduleColumnId.DefinitionId}
                itemProperty={(item: Contract.ScopeSystemEntityModel) => reportDefinitionTranslator((item.configuration as Contract.ReportScheduleConfiguration).definitionId)}
                title={localization.columns.reportType()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        default: true,
                        element:
                            <ValuesFilter
                                emptyValueOptions={{ enabled: true }}
                                groupItemTitle={true}
                                placeholder={localization.columns.name()}>
                                {_(scopeReportScheduleModels).
                                    map(scopeReportScheduleModel => reportDefinitionConfigurationMap[(scopeReportScheduleModel.configuration as Contract.ReportScheduleConfiguration).definitionId].name).
                                    uniq().
                                    filter().
                                    map(
                                        name =>
                                            <ValuesFilterItem
                                                key={name}
                                                title={name}
                                                value={name}/>).
                                    value()}
                            </ValuesFilter>
                    }
                }}
                id={ScheduleColumnId.Name}
                render={({ item }: DataTableColumnRenderProps<Contract.ScopeSystemEntityModel>) => <Name definitionId={(item.configuration as Contract.ReportScheduleConfiguration).definitionId}/>}
                title={localization.columns.name()}/>
            <DataTableColumn
                id={ScheduleColumnId.Cadence}
                itemProperty={(item: Contract.ScopeSystemEntityModel) => reportScheduleCadenceTranslator((item.configuration as Contract.ReportScheduleConfiguration).cadence)}
                title={localization.columns.cadence()}/>
            <DataTableColumn
                id={ScheduleColumnId.Delivery}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.ScopeSystemEntityModel>) =>
                        _.isEmpty((item.configuration as Contract.ReportScheduleConfiguration).deliveries)
                            ? <NoneIcon/>
                            : <InlineDeliveries deliveries={(item.configuration as Contract.ReportScheduleConfiguration).deliveries}/>}
                title={localization.columns.delivery()}/>
            <DataTableColumn
                id={ScheduleColumnId.Scope}
                itemProperty={(item: Contract.ScopeSystemEntityModel) => scopeNameTranslator(item.scopeId, { path: true })}
                title={localization.columns.scope()}/>
            <DataTableColumn
                id={ScheduleColumnId.Actions}
                orderable={false}
                render={ActionsCell}
                resizable={false}/>
        </DataTable>);
}

enum ScheduleColumnId {
    Actions = "actions",
    Cadence = "cadence",
    DefinitionId = "definitionId",
    Delivery = "delivery",
    Name = "name",
    Scope = "scope"
}

type NameProps = {
    definitionId?: string;
};

function Name({ definitionId }: NameProps) {
    const reportDefinitionConfiguration = reportDefinitionConfigurationStore.useGet(definitionId);

    return _.isNil(reportDefinitionConfiguration?.name)
        ? <NoneIcon/>
        : <Typography noWrap={true}>
            {reportDefinitionConfiguration!.name}
        </Typography>;
}