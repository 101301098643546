import { ConfirmButton, useLocalization, WelcomeView } from "@infrastructure";
import { Button, Stack } from "@mui/material";
import React, { useCallback } from "react";
import { Contract, TenantIcon, useTheme } from "../../../../../../../../../common";
import { useSetScopesContext } from "../../../../../Scopes";

export function Welcome() {
    const setScopesContext = useSetScopesContext();
    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAwsDefinition.welcome",
            () => ({
                action: {
                    masterTenant: {
                        confirm: {
                            cancelTitle: "Cancel",
                            okTitle: "Add Account",
                            title: "To onboard an AWS Organization, please onboard the management account in monitoring mode first. Once the management account is onboarded, you will be able to proceed with onboarding member accounts."
                        },
                        title: "Add Organization"
                    },
                    tenant: "Add Account"
                },
                title: "Connect your AWS Environment"
            }));

    const onClick =
        useCallback(
            () =>
                setScopesContext(
                    context => ({
                        ...context,
                        addOrEditOpen: "scope"
                    })),
            [setScopesContext]);

    const theme = useTheme();
    return (
        <WelcomeView title={localization.title()}>
            <Stack
                spacing={4}
                sx={{
                    height: "100%",
                    width: "100%"
                }}>
                <ConfirmButton
                    cancelTitle={localization.action.masterTenant.confirm.cancelTitle()}
                    icon={<TenantIcon tenantType={Contract.TenantType.Aws}/>}
                    message={localization.action.masterTenant.confirm.title()}
                    okTitles={[localization.action.masterTenant.confirm.okTitle()]}
                    sx={{
                        padding: theme.spacing(1, 3),
                        width: "100%"
                    }}
                    variant="outlined"
                    onClick={onClick}>
                    {localization.action.masterTenant.title()}
                </ConfirmButton>
                <Button
                    startIcon={<TenantIcon tenantType={Contract.TenantType.Aws}/>}
                    sx={{ padding: theme.spacing(1, 3) }}
                    variant="outlined"
                    onClick={onClick}>
                    {localization.action.tenant()}
                </Button>
            </Stack>
        </WelcomeView>);
}