import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciKmsVaultKeyVersionStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciKmsVaultKeyVersionStatusTranslator",
            () => ({
                [Contract.TypeNames.OciKmsVaultKeyVersionStatus]: {
                    [Contract.OciKmsVaultKeyVersionStatus.CancellingDeletion]: "Cancelling Deletion",
                    [Contract.OciKmsVaultKeyVersionStatus.Creating]: "Creating",
                    [Contract.OciKmsVaultKeyVersionStatus.Deleted]: "Deleted",
                    [Contract.OciKmsVaultKeyVersionStatus.Deleting]: "Deleting",
                    [Contract.OciKmsVaultKeyVersionStatus.Disabled]: "Disabled",
                    [Contract.OciKmsVaultKeyVersionStatus.Disabling]: "Disabling",
                    [Contract.OciKmsVaultKeyVersionStatus.Enabled]: "Enabled",
                    [Contract.OciKmsVaultKeyVersionStatus.Enabling]: "Enabling",
                    [Contract.OciKmsVaultKeyVersionStatus.PendingDeletion]: "Pending Deletion",
                    [Contract.OciKmsVaultKeyVersionStatus.SchedulingDeletion]: "Scheduling Deletion"
                }
            }));
    return (status: Contract.OciKmsVaultKeyVersionStatus) =>
        localization[Contract.TypeNames.OciKmsVaultKeyVersionStatus][status]();
}