import { EmptyMessageText, ItemSelector, Link, Loading, TextViewer, useLocalization } from "@infrastructure";
import { Divider } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { Contract, InfoCard, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../../../common";
import { AwsLambdaFunctionConfigurationHelper, AwsResourceAccessLevel } from "../../../../../../../../../../../../tenants";
import { EntitiesInfoItem } from "../../../../../../components";
import { ExternalTriggersInfoSection } from "./ExternalTriggersInfoSection";
import { TriggersInfoSection } from "./TriggersInfoSection";

type AliasesProps = {
    functionConfigurationModel: Contract.AwsLambdaFunctionConfigurationModel;
};

export function Aliases({ functionConfigurationModel }: AliasesProps) {
    const functionConfiguration = functionConfigurationModel.entity as Contract.AwsLambdaFunctionConfiguration;

    const [selectedAliasName, setSelectedAliasName] =
        useState(
            () =>
                _(functionConfiguration.aliasMap).
                    toPairs().
                    map(
                        ([aliasName, alias]) => ({
                            aliasName,
                            latestRevisionValue:
                                _(alias.revisionIdToWeightMap).
                                    keys().
                                    map(
                                        revisionId =>
                                            revisionId === AwsLambdaFunctionConfigurationHelper.latest
                                                ? Infinity
                                                : Number(revisionId)).
                                    max()
                        })).
                    maxBy(aliasData => aliasData.latestRevisionValue)!.
                    aliasName);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsLambdaFunctionConfigurationDefinition.aliases",
            () => ({
                fields: {
                    accessLevel: "Access",
                    arn: "ARN",
                    authenticationType: {
                        false: "NONE",
                        title: "Authentication Type",
                        true: "AWS_IAM"
                    },
                    policyDocument: {
                        empty: "No Policy",
                        title: "Policy"
                    },
                    revisions: {
                        title: "Revisions",
                        value: "{{revisionId}} ({{revisionWeight | NumberFormatter.percentage}})"
                    },
                    role: "Role",
                    url: "Function URL"
                },
                latest: "Latest"
            }));

    const aliasRevisionId =
        _(functionConfiguration.aliasMap[selectedAliasName].revisionIdToWeightMap).
            keys().
            first()!;

    return (
        <InfoCard>
            <ItemSelector
                dense={true}
                fullWidth={true}
                items={_.keys(functionConfiguration.aliasMap)}
                selectedItem={selectedAliasName}
                onSelectedItemChanged={setSelectedAliasName}/>
            <Loading>
                <InfoCard columns={true}>
                    <InfoItem
                        title={localization.fields.arn()}
                        value={functionConfiguration.aliasMap[selectedAliasName].arn}/>
                    <VerticalTopItemsInfoItem
                        items={
                            _.map(
                                functionConfiguration.aliasMap[selectedAliasName].revisionIdToWeightMap,
                                (revisionWeight, revisionId) =>
                                    localization.fields.revisions.value({
                                        revisionId:
                                            revisionId === AwsLambdaFunctionConfigurationHelper.latest
                                                ? localization.latest()
                                                : revisionId,
                                        revisionWeight
                                    }))}
                        key="mountableSecretNames"
                        title={localization.fields.revisions.title()}/>
                    <EntitiesInfoItem
                        entityIdsOrModels={functionConfiguration.revisionMap[aliasRevisionId].roleReference.idReference}
                        entityTypeName={Contract.TypeNames.AwsIamRole}
                        title={localization.fields.role()}/>
                    <InfoItem
                        key="accessLevel"
                        title={localization.fields.accessLevel()}
                        value={
                            _.isNil(functionConfigurationModel.aliasNameToAccessLevelMap)
                                ? undefined
                                : <AwsResourceAccessLevel accessLevel={functionConfigurationModel.aliasNameToAccessLevelMap[selectedAliasName]}/>}/>
                    <InfoItem
                        key="url"
                        title={localization.fields.url()}
                        value={
                            _.isNil(functionConfiguration.aliasMap[selectedAliasName].url)
                                ? undefined
                                : <Link
                                    urlOrGetUrl={functionConfiguration.aliasMap[selectedAliasName].url!.url}
                                    variant="external"/>}/>
                    <InfoItem
                        key="authenticationType"
                        title={localization.fields.authenticationType.title()}
                        value={
                            _.isNil(functionConfiguration.aliasMap[selectedAliasName].url)
                                ? undefined
                                : functionConfiguration.aliasMap[selectedAliasName].url!.authenticationEnabled
                                    ? localization.fields.authenticationType.true()
                                    : localization.fields.authenticationType.false()}/>
                </InfoCard>
                <Divider/>
                <InfoCard
                    emptyMessage={new EmptyMessageText(localization.fields.policyDocument.empty())}
                    title={localization.fields.policyDocument.title()}>
                    {_.isNil(functionConfiguration.aliasMap[selectedAliasName].policyDocument)
                        ? undefined
                        : <TextViewer
                            format="json"
                            text={functionConfiguration.aliasMap[selectedAliasName].policyDocument!.raw}/>}
                </InfoCard>
                <Divider/>
                <TriggersInfoSection
                    triggerResourceArnToIdReferenceMap={functionConfigurationModel.triggerResourceArnToIdReferenceMap}
                    triggers={functionConfiguration.aliasMap[selectedAliasName].triggers.triggers}/>
                <ExternalTriggersInfoSection externalTriggers={functionConfiguration.aliasMap[selectedAliasName].triggers.externalTriggers}/>
            </Loading>
        </InfoCard>);
}