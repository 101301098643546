import { Steps, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { ChangeStep, RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsIamRoleRiskContext } from "../contexts";

export function useAwsIamRoleInactiveRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsIamRoleInactiveRisk;
    const roleModel = entityModelStore.useGet(risk.entityId) as Contract.AwsIamRoleModel;
    const role = roleModel.entity as Contract.AwsIamRole;

    const getAwsIamRoleRiskContext = useGetAwsIamRoleRiskContext();
    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsIamRoleInactiveRiskDefinition",
            () => ({
                description: "{{role}} is inactive and should be deleted",
                sections: {
                    context: {
                        [Contract.TypeNames.AwsIamIdentityInactiveRiskModelInfoType]: {
                            [Contract.AwsIamIdentityInactiveRiskModelInfoType.ActiveNever]: "The role was not active since it was created",
                            [Contract.AwsIamIdentityInactiveRiskModelInfoType.ActivityTime]: "The role was last active {{activityTime | TimeFormatter.humanizePastDuration}}"
                        }
                    },
                    resolution: "Click **Delete** and confirm the deletion"
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            role:
                <Entity
                    entityIdOrModel={roleModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => {
            const deleteRoleChange = risk.resolutionChanges![0] as Contract.AwsDeleteRoleChange;
            return [
                new ChangeStep(
                    deleteRoleChange,
                    {
                        contentElement:
                            <Steps variant="plainNumbers">
                                {[
                                    consoleSignInStepTranslator(
                                        Contract.AwsConsoleView.Iam,
                                        AwsConsoleUrlBuilder.getIamRoleUrl(role)),
                                    localization.sections.resolution()
                                ]}
                            </Steps>
                    }
                )];
        },
        riskModel,
        () => {
            const roleRiskContext = getAwsIamRoleRiskContext(roleModel);
            return [
                roleRiskContext.generalInformation,
                roleRiskContext.originatorWorkloadResources,
                roleRiskContext.vendor,
                new RiskDefinitionContextItem(localization.sections.context[Contract.TypeNames.AwsIamIdentityInactiveRiskModelInfoType][(riskModel as Contract.AwsIamRoleInactiveRiskModel).infoType]({ activityTime: risk.activityTime })),
                roleRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}