import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useGcpDefaultResourceInfoItemElements, useGcpDefaultScopeResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, InfoItem, IntRangeHelper } from "../../../../../../../../../../common";
import { useGcpComputeLoadBalancerLoadBalancingSchemeTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { GcpScopeResourceRoleBindings } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useGcpCommonComputeLoadBalancerResourceInfoItemElements } from "./useGcpCommonComputeLoadBalancerResourceInfoItemElements";

export function useGcpComputeForwardingRuleDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const forwardingRuleModel = scopeResourceModel as Contract.GcpComputeForwardingRuleModel;
    const commonComputeLoadBalancerResourceInfoItemElements = useGcpCommonComputeLoadBalancerResourceInfoItemElements(forwardingRuleModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const defaultScopeResourceInfoItemElements = useGcpDefaultScopeResourceInfoItemElements(scopeResourceModel);
    const forwardingRule = forwardingRuleModel.entity as Contract.GcpComputeForwardingRule;

    const computeLoadBalancerLoadBalancingSchemeTranslator = useGcpComputeLoadBalancerLoadBalancingSchemeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpComputeForwardingRuleDefinition",
            () => ({
                info: {
                    items: {
                        description: "Description",
                        dnsZoneIds: "DNS Zones",
                        ipAddress: "IP Address",
                        loadBalancingScheme: "Load Balancer Type",
                        networkTier: {
                            title: "Network Service Tier",
                            [Contract.TypeNames.GcpComputeForwardingRuleNetworkTier]: {
                                [Contract.GcpComputeForwardingRuleNetworkTier.Premium]: "Premium",
                                [Contract.GcpComputeForwardingRuleNetworkTier.Standard]: "Standard"
                            }
                        },
                        portRanges: "Ports",
                        protocol: {
                            title: "Protocol",
                            [Contract.TypeNames.GcpComputeForwardingRuleProtocol]: {
                                [Contract.GcpComputeForwardingRuleProtocol.Ah]: "AH",
                                [Contract.GcpComputeForwardingRuleProtocol.Esp]: "ESP",
                                [Contract.GcpComputeForwardingRuleProtocol.Icmp]: "ICMP",
                                [Contract.GcpComputeForwardingRuleProtocol.L3Default]: "L3 Default",
                                [Contract.GcpComputeForwardingRuleProtocol.Sctp]: "SCTP",
                                [Contract.GcpComputeForwardingRuleProtocol.Tcp]: "TCP",
                                [Contract.GcpComputeForwardingRuleProtocol.Udp]: "UDP"
                            }
                        },
                        vpcIdReference: "VPC Network"
                    }
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GcpScopeResourceRoleBindings
                    csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                    scopeResourceModel={scopeResourceModel}/>,
                localization.tabs.resourceRoleBindings(),
                "resourceRoleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...defaultScopeResourceInfoItemElements,
                    <InfoItem
                        key="description"
                        title={localization.info.items.description()}
                        value={
                            _.isNil(forwardingRule.description)
                                ? undefined
                                : forwardingRule.description}/>,
                    <InfoItem
                        key="protocol"
                        title={localization.info.items.protocol.title()}
                        value={
                            _.isNil(forwardingRule.protocol)
                                ? undefined
                                : localization.info.items.protocol[Contract.TypeNames.GcpComputeForwardingRuleProtocol][forwardingRule.protocol]()}/>,
                    <InfoItem
                        key="ipAddress"
                        title={localization.info.items.ipAddress()}
                        value={forwardingRule.ipAddress}/>,
                    <InfoItem
                        key="portRanges"
                        title={localization.info.items.portRanges()}
                        value={
                            _.isNil(forwardingRule.portRanges)
                                ? undefined
                                : _(forwardingRule.portRanges).
                                    map(portRange => IntRangeHelper.format(portRange)).
                                    join(", ")}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={forwardingRuleModel.dnsZoneIds}
                        entityTypeName={Contract.TypeNames.Entity}
                        key="dnsZoneIds"
                        title={localization.info.items.dnsZoneIds()}/>,
                    <InfoItem
                        key="loadBalancingScheme"
                        title={localization.info.items.loadBalancingScheme()}
                        value={
                            _.isNil(forwardingRule.loadBalancingScheme)
                                ? undefined
                                : computeLoadBalancerLoadBalancingSchemeTranslator(forwardingRule.loadBalancingScheme)}/>,
                    <InfoItem
                        key="networkTier"
                        title={localization.info.items.networkTier.title()}
                        value={localization.info.items.networkTier[Contract.TypeNames.GcpComputeForwardingRuleNetworkTier][forwardingRule.networkTier]()}/>,
                    commonComputeLoadBalancerResourceInfoItemElements.loadBalancerIdsInfoItemElement,
                    <EntitiesInfoItem
                        entityIdsOrModels={forwardingRuleModel?.vpcIdReference}
                        entityTypeName={Contract.TypeNames.GcpComputeVpc}
                        key="vpcIdReference"
                        title={localization.info.items.vpcIdReference()}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}