import { useExecuteOperation, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, EntityController, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetNetworkAccessResourceRiskContext } from "../../../useGetNetworkAccessResourceRiskContext";
import { useGetAzureResourceRiskContext } from "./useGetAzureResourceRiskContext";

export function useGetAzureStorageStorageAccountRiskContext() {
    return useMemo(
        () => useAzureStorageStorageAccountRiskContext,
        []);
}

function useAzureStorageStorageAccountRiskContext(storageAccountModel: Contract.AzureStorageStorageAccountModel) {
    const [{ blobContainerIds, fileShareIds, publicBlobContainerIds, queueIds, sensitiveBlobContainerIds, tableIds }] =
        useExecuteOperation(
            [useGetAzureStorageStorageAccountRiskContext, storageAccountModel.id],
            async () => await EntityController.getAzureStorageAccountChildResourceDatas(new Contract.EntityControllerGetAzureStorageAccountChildResourceDatasRequest(storageAccountModel.id)));
    const resourceRiskContext = useGetAzureResourceRiskContext()(storageAccountModel);
    const networkAccessResourceRiskContext = useGetNetworkAccessResourceRiskContext()(storageAccountModel);
    const storageAccount = storageAccountModel.entity as Contract.AzureStorageStorageAccount;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.contexts.useGetAzureStorageStorageAccountRiskContext",
            () => ({
                childResources: {
                    empty: "The {{translatedStorageAccountTypeName}} does not have any resources",
                    notEmpty: "The {{translatedStorageAccountTypeName}} contains {{childResources}}"
                },
                publicBlobContainers: "The {{translatedStorageAccountTypeName}} contains {{publicBlobContainers}} with public access",
                sensitiveBlobContainers: "The {{translatedStorageAccountTypeName}} contains {{sensitiveBlobContainers}} marked as sensitive",
                tlsMinVersion: "The {{translatedStorageAccountTypeName}} is configured with minimum TLS version {{tlsMinVersion}}"
            }
            ));

    const storageAccountChildResourceIds =
        useMemo(
            () =>
                _.concat(
                    blobContainerIds,
                    fileShareIds,
                    queueIds,
                    tableIds),
            [storageAccountModel]);
    const translatedStorageAccountTypeName =
        entityTypeNameTranslator(
            storageAccount.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        ...networkAccessResourceRiskContext,
        childResources:
            new RiskDefinitionContextItem(
                (_.isEmpty(storageAccountChildResourceIds)
                    ? localization.childResources.empty
                    : localization.childResources.notEmpty)
                ({
                    childResources:
                        <InlineEntities
                            entityIdsOrModels={storageAccountChildResourceIds}
                            entityTypeName={Contract.TypeNames.AzureResource}
                            variant="itemCountAndType"/>,
                    translatedStorageAccountTypeName
                })),
        publicBlobContainers:
            !_.isEmpty(publicBlobContainerIds)
                ? new RiskDefinitionContextItem(
                    localization.publicBlobContainers({
                        publicBlobContainers:
                            <InlineEntities
                                entityIdsOrModels={publicBlobContainerIds}
                                entityTypeName={Contract.TypeNames.AzureStorageStorageAccountBlobContainer}
                                variant="itemCountAndType"/>,
                        translatedStorageAccountTypeName
                    }))
                : undefined,
        sensitiveBlobContainers:
            !_.isEmpty(sensitiveBlobContainerIds)
                ? new RiskDefinitionContextItem(
                    localization.sensitiveBlobContainers({
                        sensitiveBlobContainers:
                            <InlineEntities
                                entityIdsOrModels={sensitiveBlobContainerIds}
                                entityTypeName={Contract.TypeNames.AzureStorageStorageAccountBlobContainer}
                                variant="itemCountAndType"/>,
                        translatedStorageAccountTypeName
                    }))
                : undefined,
        tlsMinVersion:
            new RiskDefinitionContextItem(
                localization.tlsMinVersion({
                    tlsMinVersion:
                    storageAccount.tlsMinVersion,
                    translatedStorageAccountTypeName
                })),
        wideRangeInboundSubnets:
            storageAccount.publicNetworkAccess
                ? resourceRiskContext.getWideRangeInboundSubnetsContextItem(storageAccount.wideRangeFirewallRuleSubnets)
                : undefined
    };
}