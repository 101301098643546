import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../../..";
import { Contract, entityModelStore, InfoCard, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../../common";
import { AzureNetworkApplicationGatewayRoutingRulesTable } from "../../../../../../../../../../../tenants";
import { Info } from "../../../../../components";
import { AzureResourceRoleAssignmentResources, NetworkingInfoCard } from "../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { useAzureCommonNetworkingInfoItemElements } from "../useAzureCommonNetworkingInfoItemElements";
import { useAzureDefaultResourceInfoItemElements } from "../useAzureDefaultResourceInfoItemElements";

export function useAzureNetworkApplicationGatewayDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const applicationGateway = resourceGroupResourceModel.entity as Contract.AzureNetworkApplicationGateway;
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);
    const networkInfoItems = useAzureCommonNetworkingInfoItemElements({ resourceModel: resourceGroupResourceModel });
    const publicIpAddressModels = entityModelStore.useGet(applicationGateway.publicIpAddressIds);
    const publicIpAddressDnsNames =
        _(publicIpAddressModels).
            map(entityModel => (entityModel.entity as Contract.AzureNetworkPublicIpAddress).dnsName).
            filter().
            value();

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureNetworkApplicationGatewayDefinition",
            () => ({
                info: {
                    cards: {
                        routingRules: "Request Routing Rules"
                    },
                    items: {
                        publicIpAddressDnsNames: "DNS Names",
                        sku: "Application Gateway SKU"
                    }
                },
                tabs: {
                    resourceRoleAssignments: "Resource Role Assignments"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AzureResourceRoleAssignmentResources
                    csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                    scopeResourceModel={resourceGroupResourceModel}/>,
                localization.tabs.resourceRoleAssignments(),
                "resourceRoleAssignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="sku"
                        title={localization.info.items.sku()}
                        value={applicationGateway.sku}/>,
                    <VerticalTopItemsInfoItem
                        items={publicIpAddressDnsNames}
                        key="publicIpAddressDnsNames"
                        title={localization.info.items.publicIpAddressDnsNames()}/>
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {networkInfoItems.inboundAccessType}
                    {networkInfoItems.inboundExternalAccessScope}
                    {networkInfoItems.getPublicIpAddresses()}
                    {networkInfoItems.getVirtualNetwork()}
                    {networkInfoItems.getSubnets()}
                    {networkInfoItems.getSubnetNetworkSecurityGroupIds()}
                </NetworkingInfoCard>
                <InfoCard title={localization.info.cards.routingRules()}>
                    <AzureNetworkApplicationGatewayRoutingRulesTable applicationGateway={applicationGateway}/>
                </InfoCard>
            </Info>
    });
}