import { Dialog, makeContextProvider } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { AddOrEdit, Table } from "./components";

export class DatadogContext {
    constructor(
        public addOrEditOpen: boolean | Contract.ScopeSystemEntityModel) {
    }
}

export const [useDatadogContext, useSetDatadogContext, useDatadogContextProvider] = makeContextProvider<DatadogContext>();

export function Datadog() {
    const [context, setContext, ContextProvider] = useDatadogContextProvider(() => new DatadogContext(false));

    return (
        <ContextProvider>
            {context.addOrEditOpen !== false && (
                <Dialog
                    size="large"
                    variant="editor"
                    onClose={
                        () =>
                            setContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: false
                                }))}>
                    <AddOrEdit/>
                </Dialog>)}
            <Table/>
        </ContextProvider>);
}