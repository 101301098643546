import { NumberFormatter, TimeSpanFormatter, TimeSpanHelper, useLocalization } from "@infrastructure";
import { Avatar, Grid2, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Fragment, ReactElement, ReactNode } from "react";
import { SectionHeader } from ".";
import { Contract, RisksIcon, RiskStatusIcon, TicketingIcon, TimeRangeHelper, useTheme } from "../../../../../../../common";

type RiskTrendsSectionProps = {
    timeFrameToRiskTrendsDataMap: Dictionary<Contract.DashboardSummaryRiskTrendsData>;
};

export function RiskTrendsSection({ timeFrameToRiskTrendsDataMap }: RiskTrendsSectionProps) {
    const localization =
        useLocalization(
            "views.system.exportReport.dashboardReport.riskTrendsSection",
            () => ({
                column: {
                    name: "Trend",
                    [Contract.TypeNames.TimeFrame]: "{{ time }} days"
                },
                item: {
                    closedRiskCount: "Closed findings",
                    createdTicketCount: "Tickets created",
                    ignoredRiskCount: "Ignored findings",
                    openRiskCount: {
                        highOrCriticalSeverityOpenRiskCount: "{{formattedRiskCount}} high/critical severity",
                        newPolicyOpenRiskCount: {
                            helpText: "Track findings that were opened as a result of new policies added to Tenable Cloud Security during this time period.",
                            title: "{{formattedRiskCount}} due to new features"
                        },
                        title: "Opened findings"
                    },
                    riskAverageOpenTimeFrame: "average time to close {{formattedRiskAverageOpenTimeFrame}}"
                },
                subtitle: "Track the status of findings by viewing a snapshot of activity data over a set period of time (last 7, 30, or 90 days). See how many findings were opened/closed/starred/ignored, and how many tickets were created (manually or via automation rules) based on findings. For opened findings, track how many have high/critical severity, and how many were opened as a result of new policies added to Tenable Cloud Security during this time period.",
                title: "Trends"
            }));

    const theme = useTheme();
    return (
        <Grid2
            container={true}
            direction="column"
            spacing={2}>
            <Grid2>
                <SectionHeader
                    subtitle={localization.subtitle()}
                    title={localization.title()}
                    variant="sub"/>
            </Grid2>
            <Grid2>
                <Grid2
                    container={true}
                    direction="column">
                    <Grid2
                        alignItems="center"
                        container={true}
                        justifyContent="space-between"
                        style={{
                            backgroundColor: theme.palette.background.paper,
                            border: theme.border.primary,
                            borderBottom: theme.border.primary,
                            borderRadius: theme.spacing(0.75, 0.75, 0, 0),
                            height: theme.spacing(5),
                            padding: theme.spacing(0, 1),
                            width: "100%"
                        }}>
                        <Grid2
                            size="grow"
                            style={{ paddingLeft: theme.spacing(2) }}>
                            <Typography
                                style={{
                                    fontSize: "13px",
                                    fontWeight: 600
                                }}>
                                {localization.column.name()}
                            </Typography>
                        </Grid2>
                        <Grid2
                            sx={{ width: theme.px(140) }}>
                            <Typography
                                style={{
                                    fontSize: "13px",
                                    fontWeight: 600
                                }}>
                                {localization.column[Contract.TypeNames.TimeFrame]({ time: TimeRangeHelper.getTimeFrameValue(Contract.TimeFrame.Short) })}
                            </Typography>
                        </Grid2>
                        <Grid2
                            sx={{ width: theme.px(140) }}>
                            <Typography
                                style={{
                                    fontSize: "13px",
                                    fontWeight: 600
                                }}>
                                {localization.column[Contract.TypeNames.TimeFrame]({ time: TimeRangeHelper.getTimeFrameValue(Contract.TimeFrame.Medium) })}
                            </Typography>
                        </Grid2>
                        <Grid2
                            sx={{ width: theme.px(140) }}>
                            <Typography
                                style={{
                                    fontSize: "13px",
                                    fontWeight: 600
                                }}>
                                {localization.column[Contract.TypeNames.TimeFrame]({ time: TimeRangeHelper.getTimeFrameValue(Contract.TimeFrame.Long) })}
                            </Typography>
                        </Grid2>
                    </Grid2>
                    <Item
                        icon={<RisksIcon/>}
                        timeFrameToCount={
                            _.mapValues(
                                timeFrameToRiskTrendsDataMap,
                                riskTrendsData => riskTrendsData.openRiskCount)}
                        title={localization.item.openRiskCount.title()}>
                        {timeFrame =>
                            <Fragment>
                                {timeFrameToRiskTrendsDataMap[timeFrame].highOrCriticalOpenRiskCount !== 0 &&
                                    <ItemInfo>
                                        {localization.item.openRiskCount.highOrCriticalSeverityOpenRiskCount({
                                            formattedRiskCount:
                                                <Typography
                                                    component="span"
                                                    style={{
                                                        fontSize: "unset",
                                                        fontWeight: 600
                                                    }}>
                                                    {NumberFormatter.humanize(timeFrameToRiskTrendsDataMap[timeFrame].highOrCriticalOpenRiskCount)}
                                                </Typography>
                                        })}
                                    </ItemInfo>}
                                {timeFrameToRiskTrendsDataMap[timeFrame].newPolicyOpenRiskCount !== 0 &&
                                    <ItemInfo>
                                        {localization.item.openRiskCount.newPolicyOpenRiskCount.title({
                                            formattedRiskCount:
                                                <Typography
                                                    component="span"
                                                    style={{
                                                        fontSize: "unset",
                                                        fontWeight: 600
                                                    }}>
                                                    {NumberFormatter.humanize(timeFrameToRiskTrendsDataMap[timeFrame].newPolicyOpenRiskCount)}
                                                </Typography>
                                        })}
                                    </ItemInfo>}
                            </Fragment>}
                    </Item>
                    <Item
                        icon={<RiskStatusIcon status={Contract.RiskStatus.Closed}/>}
                        timeFrameToCount={
                            _.mapValues(
                                timeFrameToRiskTrendsDataMap,
                                riskTrendsData => riskTrendsData.closedRiskCount)}
                        title={localization.item.closedRiskCount()}>
                        {timeFrame =>
                            TimeSpanHelper.getDays(timeFrameToRiskTrendsDataMap[timeFrame].riskAverageOpenTimeFrame) >= 1 &&
                            <ItemInfo>
                                {localization.item.riskAverageOpenTimeFrame({
                                    formattedRiskAverageOpenTimeFrame:
                                        <Typography
                                            component="span"
                                            style={{
                                                fontSize: "unset",
                                                fontWeight: 600
                                            }}>
                                            {TimeSpanFormatter.humanizeDays(timeFrameToRiskTrendsDataMap[timeFrame].riskAverageOpenTimeFrame)}
                                        </Typography>
                                })}
                            </ItemInfo>}
                    </Item>
                    <Item
                        icon={<RiskStatusIcon status={Contract.RiskStatus.Ignored}/>}
                        timeFrameToCount={
                            _.mapValues(
                                timeFrameToRiskTrendsDataMap,
                                riskTrendsData => riskTrendsData.ignoredRiskCount)}
                        title={localization.item.ignoredRiskCount()}/>
                    <Item
                        borderBottom={true}
                        icon={<TicketingIcon/>}
                        timeFrameToCount={
                            _.mapValues(
                                timeFrameToRiskTrendsDataMap,
                                riskTrendsData => riskTrendsData.createdTicketCount)}
                        title={localization.item.createdTicketCount()}/>
                </Grid2>
            </Grid2>
        </Grid2>);
}

type ItemProps = {
    borderBottom?: boolean;
    children?: (timeFrame: Contract.TimeFrame) => ReactNode;
    icon: ReactElement;
    timeFrameToCount: Dictionary<number>;
    title: string;
};

function Item({ borderBottom = false, children, icon, timeFrameToCount, title }: ItemProps) {
    const theme = useTheme();
    return (
        <Grid2
            alignItems="center"
            container={true}
            style={{
                border: theme.border.primary,
                borderRadius:
                    borderBottom
                        ? theme.spacing(0, 0, 0.75, 0.75)
                        : undefined,
                borderTop: "none",
                padding: theme.spacing(2, 1)
            }}>
            <Grid2
                size="grow"
                style={{ paddingLeft: theme.spacing(2) }}>
                <Grid2
                    alignItems="center"
                    container={true}
                    spacing={2}>
                    <Grid2>
                        <Avatar
                            sx={{
                                backgroundColor: theme.palette.risk.defaultCategory,
                                color: theme.palette.text.primary,
                                fontSize: "18px",
                                height: "34px",
                                width: "34px"
                            }}>
                            {icon}
                        </Avatar>
                    </Grid2>
                    <Grid2>
                        <Typography
                            sx={{
                                fontSize: "15px",
                                fontWeight: 500
                            }}>
                            {title}
                        </Typography>
                    </Grid2>
                </Grid2>
            </Grid2>
            {_.map(
                [
                    Contract.TimeFrame.Short,
                    Contract.TimeFrame.Medium,
                    Contract.TimeFrame.Long
                ],
                timeFrame =>
                    <Grid2
                        key={timeFrame}
                        sx={{ width: theme.px(140) }}>
                        <Grid2
                            container={true}
                            direction="column">
                            <Grid2>
                                <Typography>
                                    {timeFrameToCount[timeFrame] === 0
                                        ? "-"
                                        : NumberFormatter.humanize(timeFrameToCount[timeFrame])}
                                </Typography>
                            </Grid2>
                            {children?.(timeFrame)}
                        </Grid2>
                    </Grid2>)}
        </Grid2>);
}

type ItemInfoProps = {
    children: string;
};

function ItemInfo({ children }: ItemInfoProps) {
    const theme = useTheme();
    return (
        <Grid2>
            <Typography
                sx={{
                    color: theme.palette.text.secondary,
                    fontSize: "10px"
                }}>
                {children}
            </Typography>
        </Grid2>);
}