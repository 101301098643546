import { Localization, TranslateOptions, useLocalization } from "@infrastructure";
import { Contract } from "../controllers";

export function useDeliveryTranslator() {
    const deliveryMessageTranslator = useDeliveryMessageTranslator();
    const localization: DeliveryTranslatorLocalization =
        useLocalization(
            "common.hooks.useDeliveryTranslator",
            () => ({
                [Contract.DeliveryDerivedTypeNames.ResourceOwnerMailDelivery]: {
                    groupTitle: [
                        "Findings will be sent to resource owners",
                        "Findings will be sent to resource owners"
                    ],
                    title: "Mail per resource owner"
                },
                [Contract.DeliveryDerivedTypeNames.DatadogDelivery]: {
                    groupTitle: [
                        "Findings will be sent to Datadog organization **{{item}}**",
                        "Findings will be sent to **{{count | NumberFormatter.humanize}}** Datadog organizations"
                    ],
                    title: "Datadog"
                },
                [Contract.DeliveryDerivedTypeNames.JiraDelivery]: {
                    groupTitle: [
                        "Findings will be sent to Jira Project **{{item}}**",
                        "Findings will be sent to **{{count | NumberFormatter.humanize}}** Jira projects"
                    ],
                    title: "Jira"
                },
                [Contract.DeliveryDerivedTypeNames.MailDelivery]: {
                    groupTitle: [
                        "Findings will be sent to **{{item}}**",
                        "Findings will be sent to **{{count | NumberFormatter.humanize}}** email addresses"
                    ],
                    title: "Mail"
                },
                [Contract.DeliveryDerivedTypeNames.QRadarDelivery]: {
                    groupTitle: [
                        "Findings will be sent to QRadar server **{{item}}**",
                        "Findings will be sent to **{{count | NumberFormatter.humanize}}** QRadar servers"
                    ],
                    title: "QRadar"
                },
                [Contract.DeliveryDerivedTypeNames.ServiceNowDelivery]: {
                    groupTitle: [
                        "Findings will be sent to Service Now instance **{{item}}**",
                        "Findings will be sent to **{{count | NumberFormatter.humanize}}** Service Now instances"
                    ],
                    title: "ServiceNow"
                },
                [Contract.DeliveryDerivedTypeNames.SlackDelivery]: {
                    groupTitle: [
                        "Findings will be sent to Slack channel **{{item}}**",
                        "Findings will be sent to **{{count | NumberFormatter.humanize}}** Slack channels"
                    ],
                    title: "Slack"
                },
                [Contract.DeliveryDerivedTypeNames.SplunkDelivery]: {
                    groupTitle: [
                        "Findings will be sent to Splunk event collector **{{item}}**",
                        "Findings will be sent to **{{count | NumberFormatter.humanize}}** Splunk event collectors"
                    ],
                    title: "Splunk"
                },
                [Contract.DeliveryDerivedTypeNames.SqsDelivery]: {
                    groupTitle: [
                        "Findings will be sent to SQS queue **{{item}}**",
                        "Findings will be sent to **{{count | NumberFormatter.humanize}}** SQS queues"
                    ],
                    title: "SQS"
                },
                [Contract.DeliveryDerivedTypeNames.TeamsDelivery]: {
                    groupTitle: [
                        "Findings will be sent to Teams channel **{{item}}**",
                        "Findings will be sent to **{{count | NumberFormatter.humanize}}** Teams channels"
                    ],
                    title: "Teams"
                },
                [Contract.DeliveryDerivedTypeNames.WebhookDelivery]: {
                    groupTitle: [
                        "Findings will be sent to webhook endpoint **{{item}}**",
                        "Findings will be sent to **{{count | NumberFormatter.humanize}}** webhook endpoints"
                    ],
                    title: "Webhooks"
                }
            }));

    return (typeName: Contract.DeliveryDerivedTypeNames, variant: "title" | "group", options?: TranslateOptions) => ({
        message: deliveryMessageTranslator(typeName),
        text:
            variant === "title"
                ? localization[typeName].title()
                : localization[typeName].groupTitle(options?.count ?? 1, options)
    });
}

function useDeliveryMessageTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useDeliveryTranslator.useDeliveriesMessageTranslator",
            () => ({
                [Contract.DeliveryDerivedTypeNames.ResourceOwnerMailDelivery]: "Send each resource owner a report of findings associated with their resources."
            }));

    return (typeName: Contract.DeliveryDerivedTypeNames) => {
        try {
            return localization.translate(typeName);
        } catch {
            return undefined;
        }
    };
}


type DeliveryTranslatorLocalization =
    Localization<{
        [key in Contract.DeliveryDerivedTypeNames]: {
            groupTitle: [string, string];
            title: string;
        }
    }>;