import _ from "lodash";
import { useEffect, useState } from "react";
import { ApiError, Optional, useExecuteOperation, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { ConfigurationController, Contract } from "../../../../../../../../../../../../../../../common";
import { OrganizationMemberSelection } from "../../../../../../../components";
import { OrganizationMemberSelectionHelper } from "../../../../../../../utilities";
import { useAddOrEditContext, useSetAddOrEditContext } from "../AddOrEditGitLab";
import { useGitLabOrganizationManagerErrorTranslator } from "../hooks";

export function OrganizationMemberSelectionItem() {
    const addOrEditContext = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();

    const { setError, setLoaded, setValid, useNextEffect } = useOrderedWizardContext();

    const [scopeNodeRootTreeItem] =
        useExecuteOperation(
            OrganizationMemberSelectionItem,
            async () => {
                const { node } =
                    await ConfigurationController.getOrganizationMemberDatas(
                        new Contract.ConfigurationControllerGetGitLabOrganizationMemberDatasRequest(
                            addOrEditContext.updatedAccessToken,
                            addOrEditContext.organizationModel?.id,
                            addOrEditContext.updatedServerId));
                return OrganizationMemberSelectionHelper.createTreeItem(node);
            });

    const [selectionType, setSelectionType] = useState(addOrEditContext.memberSelection?.type);
    const [selectedTenantIds, setSelectedTenantIds] =
        useState(
            () =>
                OrganizationMemberSelectionHelper.getSelectedTenantIds(
                    scopeNodeRootTreeItem.value,
                    addOrEditContext.memberSelection?.rawIds));

    useEffect(setLoaded, []);

    const gitLabOrganizationManagerErrorTranslator = useGitLabOrganizationManagerErrorTranslator();

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeOrganizationItems.gitLab.addOrEditGitLab.organizationMemberSelectionItem",
            () => ({
                actions: {
                    save: {
                        error: {
                            general: "Failed to access group"
                        }
                    }
                },
                errors: {
                    empty: "Empty selection is not allowed",
                    excludeAll: "Excluding all {{name}} is not allowed"
                }
            }));

    useNextEffect(
        async () => {
            if (!_.isNil(selectionType) && _.isEmpty(selectedTenantIds)) {
                return localization.errors.empty();
            }

            const newMemberSelection: Optional<Contract.OrganizationMemberSelection> =
                _.isNil(selectionType)
                    ? undefined
                    : {
                        rawIds:
                            OrganizationMemberSelectionHelper.getSelectedScopeIds(
                                scopeNodeRootTreeItem.value,
                                selectedTenantIds),
                        type: selectionType
                    };

            try {
                if (_.isNil(addOrEditContext.organizationModel)) {
                    const { folderScopeNodeModel, organizationModel } =
                        await ConfigurationController.insertGitLabOrganization(
                            new Contract.ConfigurationControllerInsertGitLabOrganizationRequest(
                                addOrEditContext.updatedAccessToken!,
                                addOrEditContext.updatedFolderEnabled!,
                                newMemberSelection,
                                addOrEditContext.updatedServerId,
                                addOrEditContext.updatedSyncEnabled!));
                    setAddOrEditContext(
                        context => ({
                            ...context,
                            organizationModel,
                            updatedFolderScopeNodeModel: folderScopeNodeModel
                        }));

                } else {
                    const { organizationModel } =
                        await ConfigurationController.updateGitLabOrganization(
                            new Contract.ConfigurationControllerUpdateGitLabOrganizationRequest(
                                addOrEditContext.updatedAccessToken,
                                addOrEditContext.updatedFolderEnabled!,
                                newMemberSelection,
                                addOrEditContext.organizationModel.id,
                                addOrEditContext.updatedSyncEnabled!));
                    setAddOrEditContext(
                        context => ({
                            ...context,
                            organizationModel
                        }));
                }
            } catch (error) {
                return error instanceof ApiError && error.statusCode === 400
                    ? gitLabOrganizationManagerErrorTranslator(error.error as Contract.GitLabOrganizationManagerError)
                    : localization.actions.save.error.general();
            }
        },
        [selectedTenantIds, selectionType]);

    return (
        <OrganizationMemberSelection
            codeTenantType={Contract.CodeTenantType.GitLab}
            scopeNodeRootTreeItem={scopeNodeRootTreeItem}
            selectedTenantIds={selectedTenantIds}
            selectionType={selectionType}
            tenantType={Contract.TenantType.Code}
            onError={
                error => {
                    setValid(_.isNil(error));
                    setError(error);
                }}
            onSelectedTenantIdsChanged={setSelectedTenantIds}
            onSelectionTypeChanged={setSelectionType}/>);
}