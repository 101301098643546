﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoItem, useEntityTypeNameTranslator, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { useOciComputeInstancePoolStatusTranslator } from "../../../../../../hooks";
import { EntitiesInfoCard, EntitiesInfoItem, Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useOciDefaultResourceInfoItemElements } from "./useOciDefaultResourceInfoItemElements";

export function useOciComputeInstancePoolDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);
    const instancePoolModel = resourceModel as Contract.OciComputeInstancePoolModel;
    const instancePool = instancePoolModel.entity as Contract.OciComputeInstancePool;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const instancePoolStatusTranslator = useOciComputeInstancePoolStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciComputeInstancePoolDefinition",
            () => ({
                info: {
                    cards: {
                        loadBalancerIdReferences: "Load Balancers"
                    },
                    items: {
                        availabilityDomains: "Availability Domains",
                        instanceCount: "Target Instance Count",
                        status: "Status"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={instancePoolStatusTranslator(instancePool.status)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instancePoolModel.instanceConfigurationIdReference}
                        entityTypeName={Contract.TypeNames.OciComputeInstanceConfiguration}
                        key="instanceConfigurationIdReference"
                        location="all"
                        title={entityTypeNameTranslator(Contract.TypeNames.OciComputeInstanceConfiguration)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instancePoolModel.autoscalingConfigurationId}
                        entityTypeName={Contract.TypeNames.OciAutoscalingAutoscalingConfiguration}
                        key="autoscalingConfigurationId"
                        location="all"
                        title={entityTypeNameTranslator(Contract.TypeNames.OciAutoscalingAutoscalingConfiguration)}/>,
                    <InfoItem
                        key="instanceCount"
                        title={localization.info.items.instanceCount()}
                        value={instancePool.instanceCount}/>,
                    <VerticalTopItemsInfoItem
                        key="availabilityDomains"
                        title={localization.info.items.availabilityDomains()}
                        value={
                            _.map(
                                instancePool.placementConfigurations,
                                instancePoolPlacementConfiguration => instancePoolPlacementConfiguration.availabilityDomain)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instancePoolModel.subnetIdReferences}
                        entityTypeName={Contract.TypeNames.OciNetworkingSubnet}
                        key="subnetIdReferences"
                        location="all"
                        title={entityTypeNameTranslator(
                            Contract.TypeNames.OciNetworkingSubnet,
                            { count: 0 })}/>
                ]}
                options={options?.infoOptions}>
                <EntitiesInfoCard
                    entityIdsOrModels={instancePoolModel.instanceIdReferences}
                    entityTypeName={Contract.TypeNames.OciComputeInstance}
                    title={
                        entityTypeNameTranslator(
                            Contract.TypeNames.OciComputeInstance,
                            { count: 0 })}/>
                <EntitiesInfoCard
                    entityIdsOrModels={instancePoolModel.loadBalancerIdReferences}
                    entityTypeName={Contract.TypeNames.OciResource}
                    title={localization.info.cards.loadBalancerIdReferences()}/>
            </Info>
    });
}