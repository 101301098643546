import { Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAwsCommonEc2ResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCommonEntityNetworkInfoItemElements, useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { useAwsEc2FlowLogFilterTranslator, useAwsEc2VpcBlockPublicAccessPolicyNetworkDirectionTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, EntityTypeNameGroupsInfoCard, Info } from "../../../../components";
import { AwsEc2RouteTableRoutesInfoCard } from "../../components";

export function useAwsEc2SubnetDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonEc2ResourceInfoItemElements = useAwsCommonEc2ResourceInfoElements(resourceModel);
    const commonEntityNetworkInfoItemElements = useCommonEntityNetworkInfoItemElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const subnet = resourceModel.entity as Contract.AwsEc2Subnet;
    const subnetModel = resourceModel as Contract.AwsEc2SubnetModel;
    const subnetNetwork = subnetModel.entityNetwork as Optional<Contract.AwsEc2SubnetStateNetwork>;

    const flowLogFilterTranslator = useAwsEc2FlowLogFilterTranslator();
    const vpcBlockPublicAccessPolicyNetworkDirectionTranslator = useAwsEc2VpcBlockPublicAccessPolicyNetworkDirectionTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsEc2SubnetDefinition",
            () => ({
                info: {
                    cards: {
                        resources: {
                            emptyMessage: "No resources",
                            title: "Resources"
                        },
                        routes: "Route Table Routes"
                    },
                    items: {
                        autoAssignPublicIpv4Addresses: {
                            false: "No",
                            title: "Auto Assign IPv4 Addresses",
                            true: "Yes"
                        },
                        availabilityZone: "Availability Zone",
                        defaultSubnet: {
                            false: "No",
                            title: "Default Subnet",
                            true: "Yes"
                        },
                        flowLogDestinationResourceIdReferences: "Flow Logs Destinations",
                        flowLogFilter: "Flow Logs Filter",
                        networkAcl: "Network ACL",
                        routeTable: "Route Table",
                        subnets: "CIDR",
                        vpcBlockPublicAccessPolicyBlockNetworkDirection: "VPC Block Public Access Mode",
                        vpcBlockPublicAccessPolicyBlockPolicy: "VPC Block Public Access Settings",
                        vpcId: "VPC"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonEc2ResourceInfoItemElements,
                    <InfoItem
                        key="availabilityZone"
                        title={localization.info.items.availabilityZone()}
                        value={subnet.availabilityZone.name}/>,
                    <VerticalTopItemsInfoItem
                        items={subnet.subnets}
                        key="subnets"
                        title={localization.info.items.subnets()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={subnet.vpcId}
                        entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                        key="vpcId"
                        title={localization.info.items.vpcId()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={subnetNetwork?.routeTableId}
                        entityTypeName={Contract.TypeNames.AwsEc2RouteTable}
                        key="routeTable"
                        title={localization.info.items.routeTable()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={subnetNetwork?.networkAclId}
                        entityTypeName={Contract.TypeNames.AwsEc2NetworkAcl}
                        key="networkAcl"
                        title={localization.info.items.networkAcl()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={subnetNetwork?.vpcBlockPublicAccessPolicyId}
                        entityTypeName={Contract.TypeNames.AwsEc2VpcBlockPublicAccessPolicy}
                        key="vpcBlockPublicAccessPolicyBlockPolicy"
                        title={localization.info.items.vpcBlockPublicAccessPolicyBlockPolicy()}/>,
                    <InfoItem
                        key="vpcBlockPublicAccessPolicyBlockNetworkDirection"
                        title={localization.info.items.vpcBlockPublicAccessPolicyBlockNetworkDirection()}
                        value={
                            _.isNil(subnetNetwork?.vpcBlockPublicAccessPolicyId)
                                ? undefined
                                : vpcBlockPublicAccessPolicyNetworkDirectionTranslator(subnetNetwork!.vpcBlockPublicAccessPolicyBlockNetworkDirection)}/>,
                    <InfoItem
                        key="autoAssignPublicIpv4Addresses"
                        title={localization.info.items.autoAssignPublicIpv4Addresses.title()}
                        value={
                            subnet.autoAssignPublicIpv4Addresses
                                ? localization.info.items.autoAssignPublicIpv4Addresses.true()
                                : localization.info.items.autoAssignPublicIpv4Addresses.false()}/>,
                    <InfoItem
                        key="defaultSubnet"
                        title={localization.info.items.defaultSubnet.title()}
                        value={
                            subnet.defaultSubnet
                                ? localization.info.items.defaultSubnet.true()
                                : localization.info.items.defaultSubnet.false()}/>,
                    <InfoItem
                        key="flowLogFilter"
                        title={localization.info.items.flowLogFilter()}
                        value={flowLogFilterTranslator(subnetModel.flowLogFilter)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={subnetModel.flowLogDestinationResourceIdReferences}
                        entityTypeName={Contract.TypeNames.AwsResource}
                        key="flowLogDestinationResourceIdReferences"
                        title={localization.info.items.flowLogDestinationResourceIdReferences()}/>,
                    commonEntityNetworkInfoItemElements.inboundAccessType
                ]}
                options={options?.infoOptions}>
                <EntityTypeNameGroupsInfoCard
                    emptyMessage={localization.info.cards.resources.emptyMessage()}
                    entityTypeNameToIdsMap={{
                        ...subnetModel.networkedResourceTypeNameToIdsMap,
                        [Contract.TypeNames.AwsEc2SecurityGroup]: subnetModel.networkInterfaceSecurityGroupIds
                    }}
                    title={localization.info.cards.resources.title()}/>
                {!_.isNil(subnetNetwork) &&
                    !_.isNil(subnetNetwork.routeTableId) &&
                    <AwsEc2RouteTableRoutesInfoCard
                        routeTableId={subnetNetwork.routeTableId}
                        title={localization.info.cards.routes()}/>}
            </Info>
    });
}