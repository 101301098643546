import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function GoogleIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M5.625 12.0005C5.625 10.8114 5.95261 9.6975 6.52177 8.74374V4.69805H2.47608C0.870375 6.78342 0 9.32494 0 12.0005C0 14.676 0.870375 17.2175 2.47608 19.3029H6.52177V15.2572C5.95261 14.3034 5.625 13.1895 5.625 12.0005Z"
                fill="#FBBD00"/>
            <path
                d="M12 18.3745L9.1875 21.187L12 23.9995C14.6756 23.9995 17.217 23.1291 19.3024 21.5234V17.482H15.261C14.2989 18.0533 13.1804 18.3745 12 18.3745Z"
                fill="#0F9D58"/>
            <path
                d="M6.52177 15.2572L2.47608 19.3029C2.79398 19.7158 3.13908 20.1106 3.51366 20.4852C5.78016 22.7517 8.79469 23.9995 12 23.9995V18.3745C9.67388 18.3745 7.63514 17.123 6.52177 15.2572Z"
                fill="#31AA52"/>
            <path
                d="M24 11.9989C24 11.2688 23.9339 10.5374 23.8035 9.82495L23.698 9.24844H12V14.8734H17.6931C17.1402 15.9732 16.2902 16.871 15.261 17.482L19.3024 21.5234C19.7153 21.2055 20.1106 20.8588 20.4853 20.4842C22.7518 18.2176 24 15.2042 24 11.9989Z"
                fill="#3C79E6"/>
            <path
                d="M16.5078 7.49217L17.005 7.98933L20.9825 4.01189L20.4853 3.51473C18.2188 1.24823 15.2054 0 12 0L9.1875 2.8125L12 5.625C13.7028 5.625 15.3037 6.28809 16.5078 7.49217Z"
                fill="#CF2D48"/>
            <path
                d="M12 5.625V0C8.79469 0 5.78138 1.24823 3.51483 3.51469C3.14025 3.88927 2.79398 4.28513 2.47608 4.69805L6.52177 8.74374C7.63519 6.87797 9.67388 5.625 12 5.625Z"
                fill="#EB4132"/>
        </SvgIcon>);
}