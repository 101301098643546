import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, NumberFormatter, optionalTableCell, PagedValuesFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonAccessResourceDefinition, useAwsCommonEc2ResourceDefinition, useAwsCommonEncryptedResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions, useTheme } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAwsEc2SnapshotDefinition(definitionData: DefinitionData) {
    const commonAccessResourceDefinition = useAwsCommonAccessResourceDefinition(definitionData);
    const commonEc2ResourceDefinition = useAwsCommonEc2ResourceDefinition(definitionData);
    const commonEncryptedResourceDefinition = useAwsCommonEncryptedResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsEc2SnapshotDefinition",
            () => ({
                columns: {
                    description: "Description",
                    size: "Size",
                    sourceVolume: "Source Volume",
                    sourceVolumeInstances: "Instances"
                }
            }));

    const theme = useTheme();
    return new EntityTableDefinition(
        _.filter(
            [
                commonEc2ResourceDefinition.columns.rawIdColumn,
                commonEc2ResourceDefinition.columns.tenantColumn,
                commonEc2ResourceDefinition.columns.creationTimeColumn,
                commonEc2ResourceDefinition.columns.creatorIdentityCsvColumn,
                commonEc2ResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
                <DataTableColumn
                    cellSx={{ maxWidth: theme.spacing(44) }}
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEc2SnapshotModel) => ({
                                [localization.columns.description()]: (item.entity as Contract.AwsEc2Snapshot).description
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsEc2SnapshotDescription)}
                                    placeholder={localization.columns.description()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2SnapshotDescription}
                    key={Contract.EntityModelProperty.AwsEc2SnapshotDescription}
                    render={optionalTableCell<Contract.AwsEc2SnapshotModel>(item => (item.entity as Contract.AwsEc2Snapshot).description)}
                    title={localization.columns.description()}/>,
                commonAccessResourceDefinition.columns.accessLevelColumn,
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsEc2SnapshotModel>(
                            Contract.TypeNames.AwsEc2Volume,
                            item => [(item.entity as Contract.AwsEc2Snapshot).sourceVolumeId],
                            localization.columns.sourceVolume())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsEc2SnapshotSourceVolume)}
                                    placeholder={localization.columns.sourceVolume()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2SnapshotSourceVolume}
                    key={Contract.EntityModelProperty.AwsEc2SnapshotSourceVolume}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsResourceModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={(item.entity as Contract.AwsEc2Snapshot).sourceVolumeId}
                                entityTypeName={Contract.TypeNames.AwsEc2Volume}
                                entityVariant="iconText"/>}
                    title={localization.columns.sourceVolume()}/>,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEc2SnapshotModel) => ({
                                [localization.columns.size()]: NumberFormatter.storage((item.entity as Contract.AwsEc2Snapshot).size)
                            })
                    }}
                    id={Contract.EntityModelProperty.AwsEc2SnapshotSize}
                    itemProperty={(item: Contract.AwsEc2SnapshotModel) => NumberFormatter.storage((item.entity as Contract.AwsEc2Snapshot).size)}
                    key={Contract.EntityModelProperty.AwsEc2SnapshotSize}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.size()}/>,
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsEc2SnapshotModel>(
                            Contract.TypeNames.AwsEc2Instance,
                            item => item.sourceVolumeInstanceIds,
                            localization.columns.sourceVolumeInstances())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsEc2SnapshotSourceVolumeInstances)}
                                    placeholder={localization.columns.sourceVolumeInstances()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2SnapshotSourceVolumeInstances}
                    key={Contract.EntityModelProperty.AwsEc2SnapshotSourceVolumeInstances}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsEc2SnapshotModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.sourceVolumeInstanceIds}
                                entityTypeName={Contract.TypeNames.AwsEc2Instance}
                                entityVariant="iconText"/>}
                    title={localization.columns.sourceVolumeInstances()}/>,
                commonEncryptedResourceDefinition.columns.encryptionExistsColumn,
                commonEncryptedResourceDefinition.columns.kmsEncryptionKeyColumn,
                commonEc2ResourceDefinition.columns.regionColumn,
                commonEc2ResourceDefinition.columns.regionLocationColumn,
                commonEc2ResourceDefinition.columns.tagsColumn,
                commonEc2ResourceDefinition.columns.attributesColumn,
                commonEc2ResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
                commonEc2ResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
                commonEc2ResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
            ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsEc2SnapshotRequest(
                new Contract.EntityControllerGetEntityModelPageAwsEc2SnapshotRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2ResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2SnapshotDescription]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2SnapshotSourceVolume]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2SnapshotSourceVolumeInstances])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestAccess(ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsResourceAccessLevel])),
                new Contract.EntityControllerGetEntityModelPageAwsResourceRequestEncrypted(
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceEncryptionExists]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEncryptedResourceKmsEncryptionKeys]))));
}