import { useChangeEffect, useLocalization, useWizardContext, WizardLayout } from "@infrastructure";
import { Box, Radio, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useAddContext, useSetAddContext } from "..";
import { Contract, CustomRiskPolicy, RiskPolicyTypeMetadataHelper, scopeSystemEntityModelStore, useTheme } from "../../../../../../../../../../../../../common";

export function RiskPolicyTypeItem() {
    const codeIntegrationConfigurationModels = scopeSystemEntityModelStore.useGetCodeIntegration();
    const { setLoaded, setValid } = useWizardContext();
    const { riskPolicyConfigurationTypeName, riskPolicyTypeItemCategory, scopeId, tenantType } = useAddContext();
    const setAddContext = useSetAddContext();
    const [selectedRiskPolicyConfigurationTypeName, setSelectedRiskPolicyConfigurationTypeName] = useState(riskPolicyConfigurationTypeName);

    const items =
        useMemo(
            () => {
                const riskPolicyCategoryToOrderedRiskPolicyConfigurationTypeNamesMap = {
                    [RiskPolicyTypeItemCategory.Access]: [
                        Contract.TypeNames.AwsPrincipalAllowedPermissionRiskPolicyConfiguration,
                        Contract.TypeNames.AwsPrincipalAllowedResourcePermissionRiskPolicyConfiguration,
                        Contract.TypeNames.AwsPrincipalNotAllowedResourcePermissionRiskPolicyConfiguration,
                        Contract.TypeNames.AwsRoleTemplateMismatchRiskPolicyConfiguration,
                        Contract.TypeNames.AwsS3BucketManagementRiskPolicyConfiguration,
                        Contract.TypeNames.AwsKmsKeyManagementRiskPolicyConfiguration,
                        Contract.TypeNames.AwsSecretsManagerSecretManagementRiskPolicyConfiguration,
                        Contract.TypeNames.AwsIamRoleManagementRiskPolicyConfiguration,
                        Contract.TypeNames.AzurePrincipalAllowedResourcePermissionRiskPolicyConfiguration,
                        Contract.TypeNames.AzurePrincipalNotAllowedResourcePermissionRiskPolicyConfiguration,
                        Contract.TypeNames.AzurePrincipalNotAllowedResourceRoleDefinitionRiskPolicyConfiguration,
                        Contract.TypeNames.AzureManagedIdentityNotAllowedResourcePermissionRiskPolicyConfiguration,
                        Contract.TypeNames.GcpPrincipalAllowedResourcePermissionRiskPolicyConfiguration,
                        Contract.TypeNames.GcpPrincipalNotAllowedResourcePermissionRiskPolicyConfiguration,
                        Contract.TypeNames.GcpPrincipalNotAllowedResourceRoleRiskPolicyConfiguration
                    ],
                    [RiskPolicyTypeItemCategory.Activity]: [
                        Contract.TypeNames.AwsIdentityActivityRiskPolicyConfiguration,
                        Contract.TypeNames.GcpIdentityActivityRiskPolicyConfiguration
                    ],
                    [RiskPolicyTypeItemCategory.Network]: [
                        Contract.TypeNames.AwsInboundExternalVpcRiskPolicyConfiguration,
                        Contract.TypeNames.AwsInboundExternalSubnetRiskPolicyConfiguration,
                        Contract.TypeNames.AwsEc2SecurityGroupManagementRiskPolicyConfiguration,
                        Contract.TypeNames.AwsNetworkedResourceInboundExternalPortRiskPolicyConfiguration,
                        Contract.TypeNames.AwsEc2SecurityGroupNotAllowedInboundRuleRiskPolicyConfiguration,
                        Contract.TypeNames.AwsEc2SecurityGroupAllowedInboundRuleRiskPolicyConfiguration,
                        Contract.TypeNames.AzureResourceInboundExternalPortRiskPolicyConfiguration,
                        Contract.TypeNames.GcpEntityInboundExternalPortRiskPolicyConfiguration
                    ],
                    [RiskPolicyTypeItemCategory.Resources]: [
                        Contract.TypeNames.AwsEc2SecurityGroupManagementRiskPolicyConfiguration,
                        Contract.TypeNames.AwsS3BucketManagementRiskPolicyConfiguration,
                        Contract.TypeNames.AwsKmsKeyManagementRiskPolicyConfiguration,
                        Contract.TypeNames.AwsSecretsManagerSecretManagementRiskPolicyConfiguration,
                        Contract.TypeNames.AwsIamPrincipalCreationRiskPolicyConfiguration,
                        Contract.TypeNames.AwsIamRoleManagementRiskPolicyConfiguration,
                        Contract.TypeNames.AwsResourceCodeResourceNotExistsRiskPolicyConfiguration,
                        Contract.TypeNames.AwsResourceTagNotExistsRiskPolicyConfiguration,
                        Contract.TypeNames.AwsSsoPermissionSetAssignmentRiskPolicyConfiguration,
                        Contract.TypeNames.AwsSsoPermissionSetManagementRiskPolicyConfiguration,
                        Contract.TypeNames.AwsSsoPrincipalCreationRiskPolicyConfiguration,
                        Contract.TypeNames.AwsEncryptedResourceKmsEncryptionNotExistsRiskPolicyConfiguration,
                        Contract.TypeNames.AzureResourceCodeResourceNotExistsRiskPolicyConfiguration,
                        Contract.TypeNames.AzureResourceTagNotExistsRiskPolicyConfiguration,
                        Contract.TypeNames.GcpResourceCodeResourceNotExistsRiskPolicyConfiguration,
                        Contract.TypeNames.GcpResourceTagNotExistsRiskPolicyConfiguration
                    ]
                };

                return _(
                    [
                        RiskPolicyTypeItemCategory.Access,
                        RiskPolicyTypeItemCategory.Activity,
                        RiskPolicyTypeItemCategory.Resources,
                        RiskPolicyTypeItemCategory.Network
                    ]).
                    map(
                        riskPolicyTypeItemCategory =>
                            new RiskPolicyTypeItemItem(
                                riskPolicyTypeItemCategory,
                                _.filter(
                                    riskPolicyCategoryToOrderedRiskPolicyConfigurationTypeNamesMap[riskPolicyTypeItemCategory],
                                    riskPolicyConfigurationTypeName =>
                                        RiskPolicyTypeMetadataHelper.isLicensed(riskPolicyConfigurationTypeName) &&
                                        !_.isNil(tenantType) &&
                                        _(RiskPolicyTypeMetadataHelper.getTenantTypes(riskPolicyConfigurationTypeName)).includes(tenantType)))).
                    filter(item => !_.isEmpty(item.riskPolicyConfigurationTypeNames)).
                    value();
            },
            [tenantType]);

    const [selectedItem, setSelectedItem] =
        useState(
            () =>
                _.find(
                    items,
                    item => item.category === riskPolicyTypeItemCategory) ?? items[0]);

    useEffect(
        () => {
            setLoaded();
        },
        []);

    useEffect(
        () => {
            setValid(!_.isNil(selectedRiskPolicyConfigurationTypeName));
        },
        [selectedRiskPolicyConfigurationTypeName]);

    useChangeEffect(
        () => {
            setSelectedRiskPolicyConfigurationTypeName(undefined);
        },
        [selectedItem]);

    useChangeEffect(
        () => {
            setAddContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    riskPolicyConfigurationTypeName: selectedRiskPolicyConfigurationTypeName,
                    riskPolicyTypeItemCategory: selectedItem.category
                }));
        },
        [selectedRiskPolicyConfigurationTypeName]);

    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useDefinition.hooks.cloud.hooks.useCloudRiskPoliciesCustomDefinition.add.riskPolicyTypeItem",
            () => ({
                category: {
                    [RiskPolicyTypeItemCategory.Access]: {
                        subtitle: "Track and enforce permissions that should or should not be granted or modified in your environment. Get notified when those changes occur.",
                        title: "Monitor Permissions"
                    },
                    [RiskPolicyTypeItemCategory.Activity]: {
                        subtitle: "Track activity of identities in your organization. Get notified of unexpected activity.",
                        title: "Monitor Activity"
                    },
                    [RiskPolicyTypeItemCategory.Network]: {
                        subtitle: "Track and enforce network security by monitoring and limiting exposure of your internal networks.",
                        title: "Monitor Network"
                    },
                    [RiskPolicyTypeItemCategory.Resources]: {
                        subtitle: "Track and get notified on changes in security related configurations in your environment",
                        title: "Monitor Resources"
                    }
                },
                prompt: "Select the template you would like to use for this policy"
            }));

    const theme = useTheme();
    return (
        <WizardLayout
            sideElement={
                <Stack
                    spacing={2}
                    sx={{ padding: theme.spacing(3, 2) }}>
                    {_.map(
                        items,
                        item =>
                            <Stack
                                direction="row"
                                key={item.category}
                                spacing={1}
                                sx={{ cursor: "pointer" }}
                                onClick={() => setSelectedItem(item)}>
                                <Box
                                    sx={{
                                        backgroundColor: theme.palette.primary.main,
                                        visibility:
                                            item === selectedItem
                                                ? "visible"
                                                : "hidden",
                                        width: "2px"
                                    }}/>
                                <Typography
                                    sx={
                                        item === selectedItem
                                            ? {
                                                color: theme.palette.primary.main,
                                                fontWeight: "bolder"
                                            }
                                            : undefined}>
                                    {localization.category[item.category].title()}
                                </Typography>
                            </Stack>)}
                </Stack>
            }>
            <Stack
                spacing={1}
                sx={{ marginTop: theme.spacing(4) }}>
                <Stack spacing={4}>
                    <Stack spacing={1}>
                        <Typography variant="h3">
                            {localization.category[selectedItem.category].title()}
                        </Typography>
                        <Typography>
                            {localization.category[selectedItem.category].subtitle()}
                        </Typography>
                    </Stack>
                    <Typography variant="h5">
                        {localization.prompt()}
                    </Typography>
                </Stack>
                <Stack spacing={2}>
                    {_.map(
                        selectedItem.riskPolicyConfigurationTypeNames,
                        riskPolicyConfigurationTypeName =>
                            _.includes(
                                [
                                    Contract.TypeNames.AwsResourceCodeResourceNotExistsRiskPolicyConfiguration,
                                    Contract.TypeNames.AzureResourceCodeResourceNotExistsRiskPolicyConfiguration,
                                    Contract.TypeNames.GcpResourceCodeResourceNotExistsRiskPolicyConfiguration
                                ],
                                riskPolicyConfigurationTypeName) &&
                            _.isEmpty(codeIntegrationConfigurationModels)
                                ? undefined
                                : <Stack
                                    alignItems="center"
                                    direction="row"
                                    key={riskPolicyConfigurationTypeName}
                                    sx={{
                                        backgroundColor: theme.palette.action.hover,
                                        border: theme.border.primary,
                                        borderRadius: theme.spacing(0.75),
                                        padding: theme.spacing(1)
                                    }}
                                    onClick={() => setSelectedRiskPolicyConfigurationTypeName(riskPolicyConfigurationTypeName)}>
                                    <Radio
                                        checked={riskPolicyConfigurationTypeName === selectedRiskPolicyConfigurationTypeName}
                                        size="small"/>
                                    <Typography
                                        sx={{
                                            cursor: "default",
                                            flex: 1
                                        }}>
                                        <CustomRiskPolicy
                                            riskPolicyConfigurationTypeName={riskPolicyConfigurationTypeName}
                                            scopeId={scopeId}/>
                                    </Typography>
                                </Stack>)}
                </Stack>
            </Stack>
        </WizardLayout>);
}

class RiskPolicyTypeItemItem {
    constructor(
        public category: RiskPolicyTypeItemCategory,
        public riskPolicyConfigurationTypeNames: string[]) {
    }
}

export enum RiskPolicyTypeItemCategory {
    Access = "access",
    Activity = "activity",
    Network = "network",
    Resources = "resources"
}