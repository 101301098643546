import { createGraphiQLFetcher } from "@graphiql/toolkit";
import { GraphiQL } from "graphiql";
import React, { useEffect, useState } from "react";
import { ThemeType, useApplicationContext } from "@infrastructure";
import { AnalyzingView, CustomerConsoleAppUrlHelper, GraphApiUrlHelper, StorageHelper, useTheme } from "../../../../common";
import "graphiql/graphiql.min.css";
import "./GraphApiExplorer.css";

export function GraphApiExplorer() {
    const theme = useTheme();
    const [key, setKey] = useState(StorageHelper.customerGraphApiExplorerTheme?.getValue() ?? "system");

    const { selectedScopeHasData } = useApplicationContext();

    useEffect(
        () => {
            if (theme.type === ThemeType.System) {
                StorageHelper.customerGraphApiExplorerTheme?.removeValue();
            } else {
                StorageHelper.customerGraphApiExplorerTheme?.setValue(theme.type.toLocaleLowerCase());
            }
            setKey(theme.type.toLocaleLowerCase());
        },
        [theme]);

    const fetcher =
        createGraphiQLFetcher({
            fetch:
                async (input, init) => {
                    const response =
                        await fetch(
                            input,
                            {
                                ...init,
                                credentials: "include"
                            });

                    if (response.redirected) {
                        window.location.assign(CustomerConsoleAppUrlHelper.getGraphqlRelativeUrl());
                    }

                    return response;
                },
            url: GraphApiUrlHelper.getUrl()
        });

    return selectedScopeHasData
        ? <GraphiQL
            fetcher={fetcher}
            isHeadersEditorEnabled={false}
            key={key}/>
        : <AnalyzingView/>;
}