import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract, useComplianceSectionDatas } from "../../../../../common";

export type ComplianceItem = {
    disabled: boolean;
    refreshComplianceSectionDatas: () => Promise<void>;
    sectionData: Contract.ComplianceSectionData;
};

export function useItem(identifier: string, scopeId: string): ComplianceItem {
    const { customComplianceSectionDatasMap, disabledBuiltInComplianceSectionDatasMap, enabledBuiltInComplianceSectionDatasMap, refreshComplianceSectionDatas } =
        useComplianceSectionDatas(
            scopeId,
            {
                childScopeCustomCompliance: true,
                timeFrameData: true
            });
    return useMemo(
        (): ComplianceItem => {
            function getItemData() {
                if (customComplianceSectionDatasMap[identifier]) {
                    return {
                        disabled: false,
                        sectionData: customComplianceSectionDatasMap[identifier]
                    };
                } else if (enabledBuiltInComplianceSectionDatasMap[identifier]) {
                    return {
                        disabled: false,
                        sectionData: enabledBuiltInComplianceSectionDatasMap[identifier]
                    };
                } else if (disabledBuiltInComplianceSectionDatasMap[identifier]) {
                    return {
                        disabled: true,
                        sectionData: disabledBuiltInComplianceSectionDatasMap[identifier]
                    };
                } else {
                    throw new UnexpectedError("useItem.identifier", identifier);
                }
            }

            const { disabled, sectionData } = getItemData();
            return {
                disabled,
                refreshComplianceSectionDatas,
                sectionData
            };
        },
        [customComplianceSectionDatasMap, disabledBuiltInComplianceSectionDatasMap, enabledBuiltInComplianceSectionDatasMap]);
}