import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ApproverUserPermissionRequestsIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M9.02184 16.9997C10.1769 18.8039 12.1988 19.9997 14.5 19.9997C18.0899 19.9997 21 17.0896 21 13.4997C21 10.0781 18.3562 7.27396 15 7.01865V9.02717C17.25 9.27589 19 11.1834 19 13.4997C19 15.985 16.9853 17.9997 14.5 17.9997C13.4284 17.9997 12.4442 17.6251 11.6714 16.9997H9.02184Z"/>
            <path
                clipRule="evenodd"
                d="M18.3386 17.9245C18.7292 17.534 19.3623 17.534 19.7528 17.9245L23.7071 21.8787C24.0976 22.2693 24.0976 22.9024 23.7071 23.293C23.3165 23.6835 22.6834 23.6835 22.2928 23.293L18.3386 19.3387C17.9481 18.9482 17.9481 18.3151 18.3386 17.9245Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M11 7H3L3 13H11V7ZM3 5C1.89543 5 1 5.89543 1 7V13C1 14.1046 1.89543 15 3 15H11C12.1046 15 13 14.1046 13 13V7C13 5.89543 12.1046 5 11 5H3Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M4 4C4 2.34315 5.34315 1 7 1C8.65685 1 10 2.34315 10 4V7H8V4C8 3.44772 7.55228 3 7 3C6.44772 3 6 3.44772 6 4V7H4V4Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}