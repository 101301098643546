import { useMemo } from "react";
import { Contract, usePrincipalModelAccess } from "../../../../../../../../../../../../common";
import { useGetGciDirectoryEntityRiskContext } from "./useGetGciDirectoryEntityRiskContext";

export function useGetGciDirectoryGroupRiskContext() {
    return useMemo(
        () => useGciDirectoryGroupRiskContext,
        []);
}

function useGciDirectoryGroupRiskContext(groupModel: Contract.GciDirectoryGroupModel) {
    const gciDirectoryEntityRiskContext = useGetGciDirectoryEntityRiskContext()(groupModel);
    const groupModelAccess = usePrincipalModelAccess<Contract.GroupModelAccess>(groupModel.id);

    return {
        ...gciDirectoryEntityRiskContext,
        activityTime: groupModelAccess.activity.time
    };
}