import { DataTableColumn, DataTableColumnRenderProps, Link, Loading, NoneIcon, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useMemo } from "react";
import { BuiltInRiskPolicyItem, Contract, CustomerConsoleAppUrlHelper, LicensingHelper, RiskController, RiskPoliciesType, RiskPoliciesView, RiskPolicyItem, RiskPolicyTypeMetadataHelper, StorageHelper, useComplianceTranslator, useDeliveryTranslator, useEntityTypeNameTranslator, useFileNameScopeTranslator, useRiskPolicyCategoryTranslator, useRiskPolicyTranslator, useScopeNameTranslator, useTenantTypeTranslator, useTheme } from "../../../../../../../../../common";
import { useGetRiskPolicyItemBuiltInComplianceTypes } from "../../../../../../Compliance/hooks";
import { ExclusionHelper } from "../../../../../../Risks/utilities";
import { CustomerSideViewItemType } from "../../../../../../SideView";
import { ActionsCell, CodeExclusions, TenantTypeCell, useRiskPoliciesContext } from "../../../../../components";
import { RiskPolicyHelper } from "../../../../../utilities";
import { PolicyTableDefinition } from "../../../useDefinition";
import { CommonTableColumnId, useCommonRiskPolicyItemTableItems } from "../../useCommonRiskPolicyItemTableItems";

export function useCodeRiskPoliciesBuiltInDefinition(scopeId: string): PolicyTableDefinition {
    const getRiskPolicyItemBuiltInComplianceTypes = useGetRiskPolicyItemBuiltInComplianceTypes();
    const { scopeNodeMap } = useRiskPoliciesContext();
    const scopeNode = scopeNodeMap[scopeId];
    const commonRiskPolicyTableItems =
        useCommonRiskPolicyItemTableItems(
            scopeId,
            RiskPoliciesType.Code);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const complianceTranslator = useComplianceTranslator();
    const deliveryTranslator = useDeliveryTranslator();
    const fileNameScopeTranslator = useFileNameScopeTranslator();
    const riskPolicyCategoryTranslator = useRiskPolicyCategoryTranslator();
    const riskPolicyTranslator = useRiskPolicyTranslator();
    const scopeNameTranslator = useScopeNameTranslator();
    const tenantTypeTranslator = useTenantTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useDefinition.hooks.code.hooks.useCodeRiskPoliciesBuiltInDefinition",
            () => ({
                columns: {
                    excludedPattern: "Excluded Pattern",
                    exclusions: {
                        entities: "{{count | NumberFormatter.humanize}} Excluded {{translatedEntityTypeName}}",
                        patterns: "{{count | NumberFormatter.humanize}} Excluded {{translatedEntityTypeName}} by pattern",
                        title: "Excluded Resources"
                    },
                    platform: "Platform",
                    risks: {
                        open: [
                            "1 Finding",
                            "{{count | NumberFormatter.humanize}} Findings"
                        ],
                        title: "Findings"
                    },
                    severity: {
                        empty: "Dynamic",
                        title: "Severity"
                    },
                    status: {
                        false: "Disabled",
                        inheritedFalse: "Disabled (Inherited)",
                        inheritedTrue: "Enabled (Inherited)",
                        title: "Status",
                        true: "Enabled"
                    }
                },
                csvExportFileName: "Code_Built_In_Policies_{{translatedScopeName}}"
            }));

    const getCsvItemPage =
        (items: RiskPolicyItem[]) =>
            async () => {
                const { riskPolicyConfigurationTypeNameToDataMap: builtInRiskPolicyConfigurationTypeNameToDataMap } =
                    await RiskController.getBuiltInRiskPolicyModels(
                        new Contract.RiskControllerGetBuiltInCloudRiskPolicyModelsRequest(
                            _.map(
                                items,
                                item => item.riskPolicyConfigurationTypeName),
                            scopeId));
                const riskPolicyConfigurationTypeNameToScopeIdToParentRiskPolicyModelMap =
                    _.mapValues(
                        builtInRiskPolicyConfigurationTypeNameToDataMap,
                        builtInRiskData =>
                            _.keyBy(
                                builtInRiskData.parentRiskPolicyModels,
                                parentRiskPolicyModel => parentRiskPolicyModel.riskPolicyConfiguration.scopeId));

                function getEnabledInheritedScopeName(riskPolicyConfigurationTypeName: string) {
                    for (const parentScopeId of scopeNode.parentScopeIds) {
                        if (!_.isNil(riskPolicyConfigurationTypeNameToScopeIdToParentRiskPolicyModelMap[riskPolicyConfigurationTypeName][parentScopeId].riskPolicyConfiguration.enabled)) {
                            return scopeNameTranslator(parentScopeId);
                        }
                    }

                    return undefined;
                }

                return _(items).
                    map(
                        item => {
                            const [exclusionEntityTypeNameToCountMap, exclusionEntityTypeNameToPropertyPatternCountMap] =
                                ExclusionHelper.getExclusionEntityTypeNameToCountMap(
                                    builtInRiskPolicyConfigurationTypeNameToDataMap[item.riskPolicyConfigurationTypeName].parentRiskPolicyModels,
                                    builtInRiskPolicyConfigurationTypeNameToDataMap[item.riskPolicyConfigurationTypeName].scopeRiskPolicyModel,
                                    item.riskPolicyConfigurationTypeName,
                                    builtInRiskPolicyConfigurationTypeNameToDataMap[item.riskPolicyConfigurationTypeName].tenantRiskPolicyModels);
                            const complianceIdOrSectionTypes =
                                LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm)
                                    ? _(getRiskPolicyItemBuiltInComplianceTypes(item)).
                                        as<string>().
                                        concat(item.customComplianceIds).
                                        filter().
                                        value()
                                    : undefined;
                            return {
                                /* eslint-disable sort-keys-fix/sort-keys-fix */
                                Cloud:
                                    _(RiskPolicyTypeMetadataHelper.getCodeRiskCloudTenantTypes(item.riskPolicyConfigurationTypeName)).
                                        map(tenantType => tenantTypeTranslator(tenantType)).
                                        join("\n"),
                                Category: riskPolicyCategoryTranslator(item.riskPolicyTypeMetadata.category),
                                Name: riskPolicyTranslator(item.riskPolicyConfigurationTypeName).title,
                                Description:
                                    riskPolicyTranslator(
                                        item.riskPolicyConfigurationTypeName,
                                        "text").
                                        description,
                                Severity: item.riskPolicyTypeMetadata.severity ?? localization.columns.severity.empty(),
                                Compliance:
                                    _.isNil(complianceIdOrSectionTypes)
                                        ? undefined
                                        : _.isEmpty(complianceIdOrSectionTypes)
                                            ? "-"
                                            : _(complianceIdOrSectionTypes).
                                                map(complianceIdOrSectionType => complianceTranslator(complianceIdOrSectionType).title).
                                                join("\n"),
                                Status:
                                    item.enabled
                                        ? (item as BuiltInRiskPolicyItem).enabledInherited
                                            ? localization.columns.status.inheritedTrue({
                                                scope: getEnabledInheritedScopeName(item.riskPolicyConfigurationTypeName)
                                            })
                                            : localization.columns.status.true()
                                        : (item as BuiltInRiskPolicyItem).enabledInherited
                                            ? localization.columns.status.inheritedFalse({
                                                scope: getEnabledInheritedScopeName(item.riskPolicyConfigurationTypeName)
                                            })
                                            : localization.columns.status.false(),
                                Findings:
                                    item.result.riskCount === 0
                                        ? "-"
                                        : localization.columns.risks.open(
                                            item.result.riskCount,
                                            { count: item.result.riskCount }),
                                Exclusions:
                                    _<string>([]).
                                        concat(
                                            _(exclusionEntityTypeNameToCountMap).
                                                pickBy(exclusionEntityCount => exclusionEntityCount > 0).
                                                map(
                                                    (exclusionEntityCount, entityTypeName) =>
                                                        localization.columns.exclusions.entities({
                                                            count: exclusionEntityCount,
                                                            translatedEntityTypeName:
                                                                entityTypeNameTranslator(
                                                                    entityTypeName,
                                                                    {
                                                                        count: exclusionEntityCount,
                                                                        includeServiceName: true,
                                                                        variant: "text"
                                                                    })
                                                        })).
                                                value(),
                                            _(exclusionEntityTypeNameToPropertyPatternCountMap).
                                                pickBy(exclusionEntityPropertyPatternCount => exclusionEntityPropertyPatternCount > 0).
                                                map(
                                                    (exclusionEntityPropertyPatternCount, entityTypeName) =>
                                                        localization.columns.exclusions.patterns({
                                                            count: exclusionEntityPropertyPatternCount,
                                                            translatedEntityTypeName:
                                                                entityTypeNameTranslator(
                                                                    entityTypeName,
                                                                    {
                                                                        count: exclusionEntityPropertyPatternCount,
                                                                        includeServiceName: true,
                                                                        variant: "text"
                                                                    })
                                                        })).
                                                value()).
                                        join("\n"),
                                Actions:
                                    _.isEmpty(item.deliveries)
                                        ? "-"
                                        : _(item.deliveries).
                                            map(({ delivery }) => deliveryTranslator(delivery.typeName as Contract.DeliveryDerivedTypeNames, "title").text).
                                            join("\n")
                                /* eslint-enable sort-keys-fix/sort-keys-fix */
                            };
                        }).
                    orderBy([
                        csvItem => csvItem.Cloud,
                        csvItem => csvItem.Category,
                        csvItem => csvItem.Name
                    ]).
                    value();
            };

    const theme = useTheme();
    return useMemo(
        () =>
            new PolicyTableDefinition(
                columnIdToItemValuesMap =>
                    _<ReactNode>([]).
                        concat(
                            <DataTableColumn
                                filterOptions={{
                                    itemOrItems: {
                                        default: true,
                                        element:
                                            <ValuesFilter
                                                emptyValueOptions={{ enabled: true }}
                                                placeholder={localization.columns.platform()}>
                                                {_.map(
                                                    columnIdToItemValuesMap[CommonTableColumnId.TenantsType],
                                                    tenantType =>
                                                        <ValuesFilterItem
                                                            key={tenantType}
                                                            title={tenantTypeTranslator(tenantType)}
                                                            value={tenantType}/>)}
                                            </ValuesFilter>
                                    }
                                }}
                                id={CommonTableColumnId.TenantsType}
                                key={CommonTableColumnId.TenantsType}
                                render={
                                    ({ item }: DataTableColumnRenderProps<BuiltInRiskPolicyItem>) =>
                                        <TenantTypeCell tenantTypes={RiskPolicyTypeMetadataHelper.getCodeRiskCloudTenantTypes(item.riskPolicyConfigurationTypeName)}/>}
                                title={localization.columns.platform()}/>).
                        concat(
                            _.map(
                                [
                                    CommonTableColumnId.Category,
                                    CommonTableColumnId.Name
                                ],
                                tableColumnId => commonRiskPolicyTableItems?.[tableColumnId].getColumn(columnIdToItemValuesMap[tableColumnId]))).
                        concatIf(
                            LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm),
                            commonRiskPolicyTableItems.compliances.getColumn(columnIdToItemValuesMap[CommonTableColumnId.Compliances])).
                        concat([
                            commonRiskPolicyTableItems.status.getColumn(),
                            <DataTableColumn
                                headerSx={{ minWidth: theme.spacing(5) }}
                                id={TableColumnId.Risks}
                                key={TableColumnId.Risks}
                                render={
                                    ({ item }: DataTableColumnRenderProps<BuiltInRiskPolicyItem>) => (
                                        item.result.riskCount === 0
                                            ? <NoneIcon/>
                                            : <Link urlOrGetUrl={CustomerConsoleAppUrlHelper.getCodeRisksRelativeUrl({ policyConfigurationTypeNameOrIds: [item.riskPolicyConfigurationTypeNameOrId] })}>
                                                <Typography
                                                    sx={{
                                                        fontWeight: 400,
                                                        textDecoration: "underline"
                                                    }}>
                                                    {localization.columns.risks.open(
                                                        item.result.riskCount,
                                                        { count: item.result.riskCount })}
                                                </Typography>
                                            </Link>)}
                                sortOptions={{ enabled: false }}
                                title={localization.columns.risks.title()}/>,
                            <DataTableColumn
                                headerSx={{ minWidth: theme.spacing(5) }}
                                id={TableColumnId.ExcludedPattern}
                                key={TableColumnId.ExcludedPattern}
                                render={
                                    ({ item }: DataTableColumnRenderProps<BuiltInRiskPolicyItem>) =>
                                        <Loading container="cell-medium">
                                            <CodeExclusions
                                                riskPoliciesType={RiskPoliciesType.Code}
                                                riskPolicyConfigurationTypeNameOrId={item.riskPolicyConfigurationTypeNameOrId}
                                                scopeId={scopeId}/>
                                        </Loading>}
                                sortOptions={{ enabled: false }}
                                title={localization.columns.excludedPattern()}/>,
                            <DataTableColumn
                                disableAction={true}
                                headerSx={{ minWidth: theme.spacing(5) }}
                                id={TableColumnId.Actions}
                                key={TableColumnId.Actions}
                                orderable={false}
                                render={
                                    ({ item }: DataTableColumnRenderProps<BuiltInRiskPolicyItem>) =>
                                        <ActionsCell
                                            item={item}
                                            riskPoliciesType={RiskPoliciesType.Code}
                                            scopeId={scopeId}/>}
                                resizable={false}
                                selectorOptions={{ disabled: true }}
                                sortOptions={{ enabled: false }}/>
                        ]).
                        value(),
                {
                    [CommonTableColumnId.TenantsType]: item => RiskPolicyTypeMetadataHelper.getCodeRiskCloudTenantTypes(item.riskPolicyConfigurationTypeName),
                    ...commonRiskPolicyTableItems.category.getValueMap(),
                    ...commonRiskPolicyTableItems.name.getValueMap(),
                    ...commonRiskPolicyTableItems.compliances.getValueMap(),
                    ...commonRiskPolicyTableItems.status.getValueMap()
                },
                item => RiskPolicyHelper.getSideViewItemId(item.riskPolicyConfigurationTypeName, scopeId, RiskPoliciesType.Code),
                {
                    hashRouteTemplate: `${CustomerSideViewItemType.RiskPolicies}/{itemId}`
                },
                {
                    columnOrder: StorageHelper.customerCodeRiskPoliciesTableColumnOrder(RiskPoliciesView.BuiltIn),
                    columnSelector: StorageHelper.customerCodeRiskPoliciesTableColumnSelector(RiskPoliciesView.BuiltIn),
                    tableFilters: StorageHelper.customerCodeRiskPoliciesTableFilters(RiskPoliciesView.BuiltIn)
                },
                undefined,
                {
                    getCsvItemPage,
                    prefix: localization.csvExportFileName({ translatedScopeName: fileNameScopeTranslator(scopeId) })
                },
                undefined,
                {
                    defaultSortColumnIds: [CommonTableColumnId.TenantsType, CommonTableColumnId.Category, CommonTableColumnId.Name],
                    rowOptions: {
                        getUrl: (item: any) => CustomerConsoleAppUrlHelper.getRiskPoliciesProfileHashUrl(RiskPoliciesType.Code, item.riskPolicyConfigurationTypeName, scopeId)
                    }
                }
            ),
        [commonRiskPolicyTableItems, scopeId]);
}

export enum TableColumnId {
    Actions = "actions",
    ExcludedPattern = "excludedPattern",
    Risks = "risks"
}