﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ChinaIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M0 6.00012C0 4.89555 0.895431 4.00012 2 4.00012H22C23.1046 4.00012 24 4.89555 24 6.00012V18.0001C24 19.1047 23.1046 20.0001 22 20.0001H2C0.89543 20.0001 0 19.1047 0 18.0001V6.00012Z"
                fill="#EE1C25"/>
            <path
                d="M2.20283 10.3673L3.71631 5.82678L5.22979 10.3673L1.19385 7.59253H6.23877L2.20283 10.3673Z"
                fill="#FFFF00"/>
            <path
                d="M8.76741 5.91404L7.20985 6.25921L8.24881 5.04853L8.14691 6.67794L7.28256 5.23542L8.76741 5.91404Z"
                fill="#FFFF00"/>
            <path
                d="M10.3393 7.90579L8.76975 7.61985L10.1969 6.90688L9.47106 8.36924L9.23382 6.70439L10.3393 7.90579Z"
                fill="#FFFF00"/>
            <path
                d="M10.1099 10.7008L8.79351 9.79961L10.3874 9.73072L9.1282 10.7699L9.5906 9.15303L10.1099 10.7008Z"
                fill="#FFFF00"/>
            <path
                d="M8.13034 12.5271L7.26414 11.1874L8.76092 11.7394L7.19812 12.2116L8.24909 10.8988L8.13034 12.5271Z"
                fill="#FFFF00"/>
        </SvgIcon>);
}