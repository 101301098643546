import { DataTableColumn, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, ItemSelectionHelper, RiskType } from "../../../../../../../../../../../../../../../../../../../../../../common";
import { useItemsContext, useTableCommonCloudColumns } from "../../../../../../../../../../../../../../../../hooks";
import { TableColumnId } from "../../../../../../../../../../../../../../../Table";
import { TableDefinition } from "../../../../../../../../useDefinition";
import { useCommonColumns } from "../../../../../../../useCommonColumns";
import { useCommonCloudRiskPolicyTableDefinition } from "../../../useCommonCloudRiskPolicyTableDefinition";

export function useGciDirectoryGroupInactiveRiskDefinition(riskPolicyConfigurationTypeName: string): TableDefinition {
    const { requestFilters } = useItemsContext();
    const localization =
        useLocalization(
            "views.customer.risks.items.grouped.item.table.hooks.useDefinition.hooks.useCloudDefinition.hooks.useRiskPolicyTypeDefinition.hooks.useAccessDefinition.hooks.gci.useGciDirectoryGroupInactiveRiskDefinition",
            () => ({
                columns: {
                    groupIdentityExists: {
                        false: "Yes",
                        title: "Empty Group",
                        true: "No"
                    }
                }
            }));
    const commonColumns = useCommonColumns();
    const commonCloudColumns = useTableCommonCloudColumns();
    return useCommonCloudRiskPolicyTableDefinition({
        columns:
            _.filter([
                commonColumns.statusUpdateTimeColumn,
                commonColumns.severityColumn,
                commonCloudColumns.descriptionColumn,
                commonColumns.tenantIdColumn,
                commonColumns.riskedEntityIdColumn(
                    riskPolicyConfigurationTypeName,
                    requestFilters as Contract.RiskControllerCloudRiskModelFilters),
                commonColumns.resourceOwnerColumn,
                commonColumns.resourceEnvironmentColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                        (item: Contract.GciDirectoryGroupInactiveRiskModel) => ({
                            [localization.columns.groupIdentityExists.title()]:
                                item.risk.groupIdentityExists
                                    ? localization.columns.groupIdentityExists.true()
                                    : localization.columns.groupIdentityExists.false()
                        })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                            <ValuesFilter
                                placeholder={localization.columns.groupIdentityExists.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.groupIdentityExists.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.groupIdentityExists.false()}
                                    value={false}/>
                            </ValuesFilter>
                        }
                    }}
                    id={TableColumnId.GciDirectoryGroupInactiveRiskGroupIdentityExists}
                    itemProperty={
                        (item: Contract.GciDirectoryGroupInactiveRiskModel) =>
                            item.risk.groupIdentityExists
                                ? localization.columns.groupIdentityExists.true()
                                : localization.columns.groupIdentityExists.false()}
                    key={TableColumnId.GciDirectoryGroupInactiveRiskGroupIdentityExists}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.groupIdentityExists.title()}/>,
                commonColumns.statusColumn(true),
                commonColumns.subStatusColumn(true),
                commonColumns.ignoreExpirationDateColumn,
                commonColumns.openStatusUpdateTimeColumn,
                commonColumns.systemCreationTimeColumn,
                commonColumns.riskedEntityAttributesColumn,
                commonColumns.actionsCell(RiskType.Cloud)
            ]),
        getGroupFilters:
            filterMap =>
                new Contract.RiskControllerGciDirectoryGroupInactiveRiskPolicyTypeFilters(
                    riskPolicyConfigurationTypeName,
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[TableColumnId.RiskedEntityIds]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[TableColumnId.GciDirectoryGroupInactiveRiskGroupIdentityExists])),
        getRiskIdToScopesIdentityPermissionsMapRequest:
            requestFilters =>
                new Contract.RiskControllerGetRiskPolicyTypeGroupCloudRiskIdToScopeIdentityPermissionsMapRequest(
                    requestFilters,
                    riskPolicyConfigurationTypeName)
    });
}