﻿import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { AnalyticsEventActionType, useExecuteOperation, useLocalization, useTrackAnalyticsEvent, WelcomeView } from "@infrastructure";
import { AccessController, AccessDashboardContext, AnalyzingView, compactLayout, Contract, Dashboard as DashboardBase, DashboardConfiguration, dashboardWidgetMap, DashboardWidgetType, defaultSeverities, FeatureHelper, StorageHelper, tenantModelStore, useGetSelectedScopeHasData, useLayoutOptions } from "../../../../../../common";
import { TopbarFilter } from "./components";

export function Dashboard() {
    const activeCloudProviderTenantModels = tenantModelStore.useGetActiveCloudProviderTenants();
    const filteredActiveCloudProviderTenantModels = tenantModelStore.useGetFilteredActiveCloudProviderTenants();
    const getSelectedScopeHasData = useGetSelectedScopeHasData();
    const [{ accessSummary }] =
        useExecuteOperation(
            Dashboard,
            AccessController.getDashboardSummary);
    const summaryDataExists =
        useMemo(
            () =>
                _(accessSummary.principalTypeToSummaryMap).
                    values().
                    some(principalTypeSummary => principalTypeSummary.permissionPrincipalCount > 0),
            [accessSummary]);

    const localization =
        useLocalization(
            "views.customer.access.dashboard",
            () => ({
                empty: "No Data",
                filtered: "You are currently filtering by identity provider/s.\n Expand the account scope to see relevant data"
            }));

    useTrackAnalyticsEvent(AnalyticsEventActionType.PageView);

    if (_.isEmpty(activeCloudProviderTenantModels)) {
        return <WelcomeView title={localization.empty()}/>;
    } else if (_.isEmpty(filteredActiveCloudProviderTenantModels)) {
        return <WelcomeView title={localization.filtered()}/>;
    } else if (!getSelectedScopeHasData(filteredActiveCloudProviderTenantModels)) {
        return <AnalyzingView/>;
    } else if (!summaryDataExists) {
        return <WelcomeView title={localization.empty()}/>;
    }

    return <Core summary={accessSummary}/>;
}

type CoreProps = {
    summary: Contract.AccessControllerGetDashboardSummaryResponseAccessSummary;
};

function Core({ summary }: CoreProps) {
    const excessivePermissionsEnabled = tenantModelStore.useGetFilteredActiveCloudProviderTenantsExcessivePermissionsEnabled();
    useLayoutOptions({ contained: false });

    const localization =
        useLocalization(
            "views.customer.access.dashboard.core",
            () => ({
                title: "IAM Dashboard"
            }));

    const widgetDatas =
        useMemo(
            () => {
                const dashboardWidgetWidthMap = {
                    [DashboardWidgetType.AccessPrincipals]: [12],
                    [DashboardWidgetType.AccessExcessivePermissionPrincipalTrends]: [12],
                    [DashboardWidgetType.AccessVendors]: [4, 12],
                    [DashboardWidgetType.AccessRiskCombinations]: [4, 12],
                    [DashboardWidgetType.AccessRisks]: [4, 12]
                };

                const orderedDashboardWidgetDatas =
                    _<DashboardWidgetType>([]).
                        concat(DashboardWidgetType.AccessPrincipals).
                        concatIf(
                            excessivePermissionsEnabled,
                            DashboardWidgetType.AccessExcessivePermissionPrincipalTrends).
                        concat(
                            DashboardWidgetType.AccessVendors,
                            DashboardWidgetType.AccessRiskCombinations,
                            DashboardWidgetType.AccessRisks).
                        map(
                            orderedDashboardWidgetType => ({
                                location: {
                                    height: dashboardWidgetMap[orderedDashboardWidgetType].h!,
                                    width: dashboardWidgetMap[orderedDashboardWidgetType].w!
                                },
                                type: orderedDashboardWidgetType
                            })).
                        value();

                return compactLayout(
                    orderedDashboardWidgetDatas,
                    dashboardWidgetWidthMap);
            },
            [excessivePermissionsEnabled]);
    const severitiesJson =
        useMemo(
            () => StorageHelper.customerAccessDashboardPrincipalsWidgetSeverities.getValue(),
            []);
    const fetchDashboardContextData =
        useCallback(
            async () =>
                new AccessDashboardContext(
                    {
                        severities:
                            _.isNil(severitiesJson)
                                ? defaultSeverities
                                : JSON.parse(severitiesJson) as Contract.Severity[]
                    },
                    summary),
            [severitiesJson]);

    const configuration =
        useMemo<DashboardConfiguration>(
            () => ({
                editable: FeatureHelper.enabled(Contract.FeatureName.DashboardEditEnabled),
                fetchDashboardContextData,
                title: localization.title(),
                widgetDatasOrGetWidgetDatas: widgetDatas
            }),
            [fetchDashboardContextData, localization, widgetDatas]);

    return (
        <DashboardBase
            className="accessDashboard"
            configuration={configuration}
            topBar={<TopbarFilter/>}/>);
}