import { DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, NoneIcon, StringHelper, TextValuesFilter, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, EntitiesCell, EntityFilter, entityModelStore, InlinePermissionActions, ItemTable, useEntityTypeNameTranslator } from "../../../../../../../../../../../../../../common";
import { GcpRoleBindingHelper } from "../../../../../../../../../../../../../../tenants";

export type RoleBindingTableProps = {
    csvExportFilePrefixes: string[];
    risk: Contract.GcpPrincipalWideScopeImpersonateServiceAccountActionExistsRisk;
    unknownMessageText?: EmptyMessageText;
};

export function RoleBindingTable({ csvExportFilePrefixes, risk, unknownMessageText }: RoleBindingTableProps) {
    const roleBindingModels = entityModelStore.useGet(_.keys(risk.roleBindingIdToImpersonatePermissionActionsMap)) as Contract.GcpIamRoleBindingModel[];
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.useGcpPrincipalWideScopeImpersonateServiceAccountActionExistsRiskDefinition.roleBindingTable",
            () => ({
                columns: {
                    impersonatePermissionActions: "Impersonation Permissions",
                    roleBindingId: "Role Binding",
                    usedImpersonatePermissionActions: {
                        empty: "No permission was used in the last 90 days",
                        title: "Impersonation Permissions Used In Last 90 Days"
                    }
                },
                empty: "No Bindings"
            }));

    const [items, roleBindingModelMap] =
        useMemo(
            () => {
                const roleBindingModelMap =
                    _.keyBy(
                        roleBindingModels,
                        roleBindingModel => roleBindingModel.id);

                const items =
                    _(roleBindingModels).
                        map(
                            roleBindingModel => {
                                const roleBinding = roleBindingModel.entity as Contract.GcpIamRoleBinding;
                                return ({
                                    impersonatePermissionActions: risk.roleBindingIdToImpersonatePermissionActionsMap[roleBinding.id],
                                    roleBinding,
                                    usedImpersonatePermissionActions:
                                        _.isNil(roleBindingModel.permissionUsage)
                                            ? undefined
                                            : _.intersection(
                                                risk.roleBindingIdToImpersonatePermissionActionsMap[roleBindingModel.entity.id],
                                                roleBindingModel.permissionUsage!.usedPermissionActions)

                                });
                            }).
                        as<RoleBindingTableItem>().
                        value();

                return [items, roleBindingModelMap];
            },
            [roleBindingModels]);

    return (
        <ItemTable
            columnIdToGetItemValueMap={{
                [RoleBindingTableColumnId.ImpersonatePermissionActions]: {
                    getFilterValue: item => item.impersonatePermissionActions,
                    getSortValue: item => item.impersonatePermissionActions.length
                },
                [RoleBindingTableColumnId.RoleBindingId]: {
                    getFilterValue: item => item.roleBinding.id,
                    getSortValue: item => StringHelper.normalize(item.roleBinding.displayName)
                },
                [RoleBindingTableColumnId.UsedImpersonatePermissionActions]: {
                    getFilterValue: item => item.usedImpersonatePermissionActions,
                    getSortValue: item => item.usedImpersonatePermissionActions?.length ?? -1
                }
            }}
            csvExportFilePrefixes={csvExportFilePrefixes}
            defaultSortColumnIdOrIds={RoleBindingTableColumnId.RoleBindingId}
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            getCsvItem={
                item => ({
                    /* eslint-disable sort-keys-fix/sort-keys-fix */
                    "Role Binding": GcpRoleBindingHelper.getDisplayName(
                        entityTypeNameTranslator,
                        roleBindingModelMap[item.roleBinding.id]),
                    "Impersonation Permissions": _.join(
                        item.impersonatePermissionActions,
                        "\n"),
                    "Impersonation Permissions Used In Last 90 Days":
                        _.isNil(item.usedImpersonatePermissionActions)
                            ? unknownMessageText?.getText() ?? "-"
                            : _.isEmpty(item.usedImpersonatePermissionActions)
                                ? localization.columns.usedImpersonatePermissionActions.empty()
                                : _.join(
                                    item.usedImpersonatePermissionActions,
                                    "\n")
                    /* eslint-enable sort-keys-fix/sort-keys-fix */
                })}
            getItemId={item => item.roleBinding.id}
            items={items}>
            {columnIdToItemValuesMap => [
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EntityFilter
                                    entityIdsOrSearchableReferences={columnIdToItemValuesMap[RoleBindingTableColumnId.RoleBindingId]}
                                    placeholder={localization.columns.roleBindingId()}/>
                        }
                    }}
                    id={RoleBindingTableColumnId.RoleBindingId}
                    key={RoleBindingTableColumnId.RoleBindingId}
                    render={
                        ({ item }: DataTableColumnRenderProps<RoleBindingTableItem>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.roleBinding.id}
                                entityTypeName={Contract.TypeNames.GcpIamRoleBinding}
                                entityVariant="iconTextTypeTenant"/>}
                    title={localization.columns.roleBindingId()}/>,
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <TextValuesFilter
                                    placeholder={localization.columns.impersonatePermissionActions()}
                                    values={
                                        _(columnIdToItemValuesMap[RoleBindingTableColumnId.ImpersonatePermissionActions]).
                                            flatMap().
                                            uniq().
                                            value()}/>
                        }
                    }}
                    id={RoleBindingTableColumnId.ImpersonatePermissionActions}
                    key={RoleBindingTableColumnId.ImpersonatePermissionActions}
                    render={
                        ({ item }: DataTableColumnRenderProps<RoleBindingTableItem>) =>
                            <InlinePermissionActions
                                permissionActions={item.impersonatePermissionActions}
                                variant="itemOrItemCountAndType"/>}
                    title={localization.columns.impersonatePermissionActions()}/>,
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <TextValuesFilter
                                    placeholder={localization.columns.usedImpersonatePermissionActions.title()}
                                    values={
                                        _(columnIdToItemValuesMap[RoleBindingTableColumnId.UsedImpersonatePermissionActions]).
                                            flatMap().
                                            uniq().
                                            value()}/>
                        }
                    }}
                    id={RoleBindingTableColumnId.UsedImpersonatePermissionActions}
                    key={RoleBindingTableColumnId.UsedImpersonatePermissionActions}
                    render={
                        ({ item }: DataTableColumnRenderProps<RoleBindingTableItem>) =>
                            _.isNil(item.usedImpersonatePermissionActions)
                                ? _.isNil(unknownMessageText)
                                    ? <NoneIcon sx={{ fontSize: "18px" }}/>
                                    : <Typography>
                                        {unknownMessageText.getText()}
                                    </Typography>
                                : _.isEmpty(item.usedImpersonatePermissionActions)
                                    ? <Typography>
                                        {localization.columns.usedImpersonatePermissionActions.empty()}
                                    </Typography>
                                    : <InlinePermissionActions
                                        permissionActions={item.usedImpersonatePermissionActions}
                                        variant="itemOrItemCountAndType"/>}
                    title={localization.columns.usedImpersonatePermissionActions.title()}/>
            ]}
        </ItemTable>);
}

enum RoleBindingTableColumnId {
    ImpersonatePermissionActions = "impersonatePermissionActions",
    RoleBindingId = "roleBindingId",
    UsedImpersonatePermissionActions = "usedImpersonatePermissionActions"
}

type RoleBindingTableItem = {
    impersonatePermissionActions: string[];
    roleBinding: Contract.GcpIamRoleBinding;
    usedImpersonatePermissionActions: string[];
};