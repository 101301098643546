import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { DataTableColumnRenderProps, Steps, useLocalization } from "@infrastructure";
import { Contract, StatusCell as BaseStatusCell } from "../../../../../../../../../common";
import { useAwsTenantModelStatusTranslator } from "../../../../../../../../../tenants";

export function StatusCell({ item }: DataTableColumnRenderProps<Contract.AwsTenantModel>) {
    const awsTenantStatusTranslator = useAwsTenantModelStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAwsDefinition.statusCell",
            () => ({
                [Contract.TypeNames.AwsTenantModelIssue]: {
                    [Contract.AwsTenantModelIssue.ContainerImageRepositoryAnalysisRolePolicyDocumentMismatch]: "Missing permissions for ECR scanning (Workload Protection)",
                    [Contract.AwsTenantModelIssue.DataAnalysisRolePolicyDocumentMismatch]: "Missing permissions for Data Protection",
                    [Contract.AwsTenantModelIssue.InstanceAnalysisRolePolicyDocumentMismatch]: "Missing permissions for EC2 scanning (Workload Protection)",
                    [Contract.AwsTenantModelIssue.PermissionManagementRolePolicyDocumentMismatch]: "Missing permissions for JIT (AWS Identity Center)",
                    [Contract.AwsTenantModelIssue.TerraformStateBucketAccessDenied]: "Can't read Terraform state files from the following S3 buckets due to missing permissions or an encryption issue - {{terraformStateBucketNames}}",
                    [Contract.AwsTenantModelIssue.TrailBucketAccessDenied]: "Can't read CloudTrail events from the following S3 buckets due to missing permissions or an encryption issue - {{trailBucketNames}}",
                    [Contract.AwsTenantModelIssue.WorkloadAnalysisAccessDenied]: "{{workloadAnalysisAccessDeniedErrorMessage}}"
                },
                [Contract.TypeNames.AwsTenantModelStatus]: {
                    [Contract.AwsTenantModelStatus.Deleting]: "Account is being deleted, this could take a while",
                    [Contract.AwsTenantModelStatus.RegionValidationFailure]: "Failed to query active regions. Please modify the region list or add an exclusion for Tenable’s IAM Role from your SCPs",
                    [Contract.AwsTenantModelStatus.RoleAssumeFailure]: "Missing permissions to AssumeRole. Please ensure the IAM Role exists and has the trust policy configured correctly",
                    [Contract.AwsTenantModelStatus.RoleReadOnlyPolicyDocumentAccessDenied]: "Missing IAM permissions. Please add the required permissions to the IAM Role",
                    [Contract.AwsTenantModelStatus.RoleReadOnlyPolicyDocumentMismatch]: "Missing read-only permissions. Please add the required permissions to the IAM Role",
                    [Contract.AwsTenantModelStatus.TenantDeletePending]: "The account is pending closure in AWS. It will be eventually deleted. Resources and findings associated with accounts pending deletion are hidden.",
                    [Contract.AwsTenantModelStatus.TenantSuspended]: "The account is suspended in AWS. It will be eventually deleted. Resources and findings of suspended accounts are hidden",
                    [Contract.AwsTenantModelStatus.Valid]: {
                        audit: "Only analyzing CloudTrail logs",
                        monitored: "Analyzing resources"
                    }
                }
            }));

    return (
        <BaseStatusCell
            statusSeverity={item.statusSeverity}
            title={awsTenantStatusTranslator(item.status)}
            tooltip={
                (() => {
                    switch (item.status) {
                        case Contract.AwsTenantModelStatus.AccessDenied:
                            return (
                                <Typography
                                    key={item.state.monitoring.analysisAccessDeniedErrorMessage}
                                    sx={{ whiteSpace: "pre-wrap" }}>
                                    {item.state.monitoring.analysisAccessDeniedErrorMessage}
                                </Typography>);
                        case Contract.AwsTenantModelStatus.Valid:
                            return (
                                <Typography>
                                    {item.configuration.audit
                                        ? localization[Contract.TypeNames.AwsTenantModelStatus][item.status].audit()
                                        : localization[Contract.TypeNames.AwsTenantModelStatus][item.status].monitored()}
                                </Typography>);
                        case Contract.AwsTenantModelStatus.ValidWithIssues:
                            return (
                                <Steps variant="bullets">
                                    {_.map(
                                        item.issues,
                                        issue =>
                                            <Typography>
                                                {localization[Contract.TypeNames.AwsTenantModelIssue][issue]({
                                                    terraformStateBucketNames: _.join(item.state.monitoring.accessDeniedTerraformStateBucketNames, ", "),
                                                    trailBucketNames: _(item.state.monitoring.trailBucketNameToAccessDeniedLogObjectKeyPrefixToAfterKeyMap).
                                                        keys().
                                                        join(", "),
                                                    workloadAnalysisAccessDeniedErrorMessage: item.state.monitoring.resourceAnalysisAccessDeniedErrorMessage
                                                })}
                                            </Typography>)}
                                </Steps>);
                        case Contract.AwsTenantModelStatus.Deleting:
                        case Contract.AwsTenantModelStatus.RegionValidationFailure:
                        case Contract.AwsTenantModelStatus.RoleAssumeFailure:
                        case Contract.AwsTenantModelStatus.RoleReadOnlyPolicyDocumentAccessDenied:
                        case Contract.AwsTenantModelStatus.RoleReadOnlyPolicyDocumentMismatch:
                        case Contract.AwsTenantModelStatus.TenantDeletePending:
                        case Contract.AwsTenantModelStatus.TenantSuspended:
                            return (
                                <Typography>
                                    {localization[Contract.TypeNames.AwsTenantModelStatus][item.status]()}
                                </Typography>);
                        default:
                            return undefined;
                    }
                })()}/>);
}