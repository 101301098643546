﻿import { DataTableColumn, DataTableColumnRenderProps, InlineItems, Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { Fragment } from "react";
import { useDetailSectionTableFetchItems } from "../../..";
import { Contract, EntitiesCell, Entity, InlineEntities, NetworkInboundAccessTypeCell, NetworkScopeFormatter, Region, RiskTypeMetadataModelHelper, useTheme } from "../../../../../../../../../../../../common";
import { RiskDetailsSectionItem } from "../../../../../../utilities";
import { EntityExclusionActionCell, EntityExclusionMessage, Table } from "../../../../components";
import { useCommonCustomSectionsAndDescriptionDefinition } from "../../../useCommonCustomSectionsAndDescriptionDefinition";

export function useGcpEntityInboundExternalPortRiskDefinition(riskModel: Contract.RiskModel) {
    const inboundExternalPortRiskModel = riskModel as Contract.GcpEntityInboundExternalPortRiskModel;
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.custom.useGcpEntityInboundExternalPortRiskDefinition",
            () => ({
                ports: [
                    "1 port range",
                    "{{count | NumberFormatter.humanize}} port ranges"
                ],
                violations: "{{resources}} allow inbound internet connection on {{destinationNetworkScopes}}"
            }));
    return useCommonCustomSectionsAndDescriptionDefinition(
        localization.violations({
            destinationNetworkScopes:
                <InlineItems
                    items={
                        _.map(
                            inboundExternalPortRiskModel.destinationNetworkScopes,
                            destinationNetworkScope => NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(destinationNetworkScope))}
                    namePluralizer={localization.ports}
                    variant="itemOrItemCountAndType"/>,
            resources:
                <InlineEntities
                    entityIdsOrModels={inboundExternalPortRiskModel.risk.riskedEntityIds}
                    entityTypeName={RiskTypeMetadataModelHelper.get(riskModel.risk.typeName).riskedEntityTypeName}
                    variant="itemAndTypeOrItemCountAndType"/>
        }),
        riskModel,
        "violations",
        <ViolationTable risk={riskModel.risk}/>);
}

type ViolationTableProps = {
    risk: Contract.GcpEntityInboundExternalPortRisk;
};

function ViolationTable({ risk }: ViolationTableProps) {
    const detailSectionTableFetchItems =
        useDetailSectionTableFetchItems(
            risk.items,
            {
                createItem:
                    (entityModelMap, riskItem) => {
                        const entityModel = entityModelMap[riskItem.entityId];
                        return new RiskDetailsSectionItem(
                            entityModel,
                            riskItem as Contract.GcpEntityInboundExternalPortRiskItem);
                    },
                getRelatedEntityIds:
                    (entityModel, riskItem) =>
                        _.concat(
                            (riskItem as Contract.GcpEntityInboundExternalPortRiskItem).gatewayResourceIds,
                            (riskItem as Contract.GcpEntityInboundExternalPortRiskItem).vpcIdReferences)
            });

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.custom.useGcpEntityInboundExternalPortRiskDefinition.violationTable",
            () => ({
                columns: {
                    destinationPortRanges: "Exposed Ports",
                    gatewayResourceIds: "Load Balancers",
                    inboundExternalInstance: "Resource",
                    networkInboundAccessType: "Exposure",
                    region: "Region",
                    subnets: "Sources",
                    vpcIdReferences: "VPC Network"
                }
            }));
    const theme = useTheme();
    return (
        <Fragment>
            <EntityExclusionMessage/>
            <Table
                fetchItems={detailSectionTableFetchItems}
                getItemId={(item: RiskDetailsSectionItem) => item.entityModel.id}>
                <DataTableColumn
                    cellSx={{ maxWidth: theme.spacing(44) }}
                    id={DetailsSectionColumnId.InboundExternalResource}
                    render={
                        ({ item }: DataTableColumnRenderProps<RiskDetailsSectionItem>) =>
                            <Entity
                                entityIdOrModel={item.entityModel}
                                variant="iconTextTypeTenant"/>}
                    title={localization.columns.inboundExternalInstance()}/>
                <DataTableColumn
                    id={DetailsSectionColumnId.DestinationPortRanges}
                    render={
                        ({ item }: DataTableColumnRenderProps<RiskDetailsSectionItem>) =>
                            <InlineItems
                                items={
                                    _.map(
                                        (item.riskItem as Contract.GcpEntityInboundExternalPortRiskItem).destinationNetworkScopes,
                                        destinationNetworkScope => NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(destinationNetworkScope))}
                                variant="itemPlusItemCount"/>}
                    title={localization.columns.destinationPortRanges()}/>
                <DataTableColumn
                    id={DetailsSectionColumnId.Subnets}
                    render={
                        ({ item }: DataTableColumnRenderProps<RiskDetailsSectionItem>) =>
                            <InlineItems
                                items={(item.riskItem as Contract.GcpEntityInboundExternalPortRiskItem).subnets}
                                variant="itemPlusItemCount"/>}
                    title={localization.columns.subnets()}/>
                <DataTableColumn
                    id={DetailsSectionColumnId.NetworkInboundAccessType}
                    render={
                        ({ item }: DataTableColumnRenderProps<RiskDetailsSectionItem>) => (
                            <NetworkInboundAccessTypeCell
                                networkInboundAccessType={(item.entityModel.entityNetwork as Optional<Contract.GcpNetworkAccessResourceStateNetwork>)?.inboundAccessType}
                                variant="risk"/>)}
                    title={localization.columns.networkInboundAccessType()}/>
                <DataTableColumn
                    id={DetailsSectionColumnId.GatewayResourceIds}
                    render={
                        ({ item }: DataTableColumnRenderProps<RiskDetailsSectionItem>) =>
                            <EntitiesCell
                                entityIdsOrModels={(item.riskItem as Contract.GcpEntityInboundExternalPortRiskItem).gatewayResourceIds}
                                entityTypeName={Contract.TypeNames.GcpComputeLoadBalancer}/>}
                    title={localization.columns.gatewayResourceIds()}/>
                <DataTableColumn
                    id={DetailsSectionColumnId.VpcIdReferences}
                    render={
                        ({ item }: DataTableColumnRenderProps<RiskDetailsSectionItem>) =>
                            <EntitiesCell
                                entityIdsOrModels={(item.riskItem as Contract.GcpEntityInboundExternalPortRiskItem).vpcIdReferences}
                                entityTypeName={Contract.TypeNames.GcpComputeVpc}/>}
                    title={localization.columns.vpcIdReferences()}/>
                <DataTableColumn
                    id={DetailsSectionColumnId.RegionSystemName}
                    render={
                        ({ item }: DataTableColumnRenderProps<RiskDetailsSectionItem>) =>
                            <Region regionId={item.entityModel.entity.regionId}/>}
                    title={localization.columns.region()}/>
                <DataTableColumn
                    id={DetailsSectionColumnId.Actions}
                    render={EntityExclusionActionCell}/>
            </Table>
        </Fragment>);
}

enum DetailsSectionColumnId {
    Actions = "actions",
    DestinationPortRanges = "destinationPortRanges",
    GatewayResourceIds = "gatewayResourceIds",
    InboundExternalResource = "inboundExternalResource",
    NetworkInboundAccessType = "networkInboundAccessType",
    RegionSystemName = "regionSystemName",
    Subnets = "subnets",
    VpcIdReferences = "vpcIdReferences"
}