﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function BelgiumIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M16 4H22C23.1046 4 24 4.89543 24 6V18C24 19.1046 23.1046 20 22 20H16V4Z"
                fill="#EF3340"/>
            <path
                d="M8 4H16V20H8V4Z"
                fill="#FDDA25"/>
            <path
                d="M0 6.00012C0 4.89555 0.895431 4.00012 2 4.00012H8V20.0001H2C0.89543 20.0001 0 19.1047 0 18.0001V6.00012Z"
                fill="#000008"/>
        </SvgIcon>);
}