﻿import { Menu } from "@infrastructure";
import { Button } from "@mui/material";
import React, { Fragment, useState } from "react";
import { BuiltInRiskPolicyItem, RiskPoliciesType, useTheme } from "../../../../../../../../common";
import { ConfigurationDialog } from "../../../ConfigurationDialog";
import { useDefinition } from "./hooks/useDefinition";

type BuiltInActionsCellProps = {
    item: BuiltInRiskPolicyItem;
    riskPoliciesType: RiskPoliciesType;
    scopeId: string;
};

export function BuiltInActionsCell({ item, riskPoliciesType, scopeId }: BuiltInActionsCellProps) {
    const [open, setOpen] = useState(false);
    const { actionMenuItems } = useDefinition(item, () => setOpen(true), scopeId, riskPoliciesType);

    const theme = useTheme();
    return (
        <Fragment>
            {open &&
                <ConfigurationDialog
                    item={item}
                    riskPoliciesType={riskPoliciesType}
                    scopeId={scopeId}
                    onClose={() => setOpen(false)}/>}
            <Button
                sx={{
                    "&:hover": {
                        textDecoration: "none"
                    },
                    height: theme.spacing(2.5),
                    minHeight: "unset",
                    padding: 0,
                    width: theme.spacing(2.5)
                }}
                variant="text">
                <Menu
                    itemsOrGetItems={actionMenuItems}
                    sx={{ color: theme.palette.text.primary }}
                    variant="bottomCenter"/>
            </Button>
        </Fragment>);
}