﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { Contract } from "../../../../../../../../../../../common";


export function useCodeOrganizationModelStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeOrganizationItems.hooks.useCodeOrganizationModelStatusTranslator",
            () => ({
                [Contract.TypeNames.CodeOrganizationModelStatus]: {
                    [Contract.CodeOrganizationModelStatus.AzureDevOpsAadTenantNotFound]: {
                        title: "Microsoft Entra ID tenant was not found"
                    },
                    [Contract.CodeOrganizationModelStatus.AzureDevOpsApplicationUnauthorized]: {
                        title: "Tenable Cloud Security Azure DevOps Application is unauthorized in you Microsoft Entra ID tenant"
                    },
                    [Contract.CodeOrganizationModelStatus.AzureDevOpsOrganizationNotFound]: {
                        title: "Azure DevOps organization was not found"
                    },
                    [Contract.CodeOrganizationModelStatus.BitbucketAccessTokenUnauthorized]: {
                        title: "Bitbucket workspace access token is not valid"
                    },
                    [Contract.CodeOrganizationModelStatus.BitbucketOrganizationMismatch]: {
                        title: "The workspace URL changed",
                        tooltip: "Delete the workspace in Tenable and add it again"
                    },
                    [Contract.CodeOrganizationModelStatus.CodeServerIssue]: {
                        title: "Server integration is not connected"
                    },
                    [Contract.CodeOrganizationModelStatus.Deleting]: {
                        title: "Deleting..."
                    },
                    [Contract.CodeOrganizationModelStatus.GitHubInstallationMissingBasicPermissions]: {
                        title: "Tenable Cloud Security GitHub App is missing mandatory permissions for your organization"
                    },
                    [Contract.CodeOrganizationModelStatus.GitHubInstallationMissingPermissions]: {
                        title: "Tenable Cloud Security GitHub App is missing some permissions for your organization"
                    },
                    [Contract.CodeOrganizationModelStatus.GitHubInstallationNetworkAccessDenied]: {
                        title: "Tenable Cloud Security GitHub App is denied access to your organization by its IP allowlist",
                        tooltip: "To allow access, add the Tenable IP addresses associated with your region to the allowlist"
                    },
                    [Contract.CodeOrganizationModelStatus.GitHubInstallationNotFound]: {
                        title: "Tenable Cloud Security GitHub App is not installed"
                    },
                    [Contract.CodeOrganizationModelStatus.GitLabAccessTokenUnauthorized]: {
                        title: "GitLab group access token is not valid"
                    },
                    [Contract.CodeOrganizationModelStatus.GitLabOrganizationMismatch]: {
                        title: "GitLab group URL changed",
                        tooltip: "Delete the group in Tenable and add it again"
                    },
                    [Contract.CodeOrganizationModelStatus.Valid]: {
                        title: "Connected"
                    }
                }
            }));
    return (status: Contract.CodeOrganizationModelStatus) => ({
        title: localization[Contract.TypeNames.CodeOrganizationModelStatus][status].title(),
        tooltip:
            _.has(localization[Contract.TypeNames.CodeOrganizationModelStatus][status], "tooltip")
                ? localization[Contract.TypeNames.CodeOrganizationModelStatus][status].translate("tooltip")
                : undefined
    });
}