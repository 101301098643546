import { Step, Steps, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsRdsClusterRiskContext } from "../contexts";

export function useAwsRdsClusterCloudWatchLoggingDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsRdsClusterCloudWatchLoggingDisabledRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId) as Contract.AwsRdsClusterModel;
    const cluster = clusterModel.entity as Contract.AwsRdsCluster;

    const getAwsRdsClusterRiskContext = useGetAwsRdsClusterRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsRdsClusterCloudWatchLoggingDisabledRiskDefinition",
            () => ({
                description: "{{cluster}} does not publish logs to CloudWatch Logs",
                sections: {
                    resolution: {
                        step1: "Click **Modify**",
                        step2: {
                            step1: "PostgreSQL: Postgresql, Upgrade logs",
                            step2: "MySQL: Audit, Error, General, SlowQuery logs",
                            step3: "Aurora: Audit, Error, General, SlowQuery logs",
                            step4: "Aurora-PostgreSQL: Postgresql, Upgrade logs",
                            step5: "Aurora-MySQL: Audit, Error, General, SlowQuery logs",
                            title: "Go to **Additional monitoring settings** and under **Log exports**, select the log types to publish according to your DB engine:"
                        },
                        step3: "Continue and save changes"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Rds,
                AwsConsoleUrlBuilder.getRdsClusterUrl(cluster)),
            localization.sections.resolution.step1(),
            new Step(
                localization.sections.resolution.step2.title(),
                {
                    contentElement:
                        <Steps>
                            {localization.sections.resolution.step2.step1()}
                            {localization.sections.resolution.step2.step2()}
                            {localization.sections.resolution.step2.step3()}
                            {localization.sections.resolution.step2.step4()}
                            {localization.sections.resolution.step2.step5()}
                        </Steps>
                }),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const clusterRiskContext = getAwsRdsClusterRiskContext(clusterModel);
            return [
                clusterRiskContext.generalInformation,
                clusterRiskContext.storageSize,
                clusterRiskContext.instances,
                clusterRiskContext.highestDataSensitivity,
                clusterRiskContext.sensitive,
                clusterRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}