import { Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useGcpDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, entityModelStore, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../components";
import { KubernetesIngressRulesInfoCard, KubernetesYamlInfoCard } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useGcpCommonKubernetesNamespaceResourceInfoItemElements } from "./useGcpCommonKubernetesNamespaceResourceInfoItemElements";

export function useGcpKubernetesIngressDefinition(resourceModel: Contract.GcpResourceModel, options?: DefinitionOptions) {
    const commonKubernetesNamespaceResourceInfoItemElements = useGcpCommonKubernetesNamespaceResourceInfoItemElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultPropertyInfoElements = useGcpDefaultResourceInfoItemElements(resourceModel);
    const ingress = resourceModel.entity as Contract.GcpKubernetesIngress;
    const ingressModel = resourceModel as Contract.GcpKubernetesIngressModel;

    const loadBalancerModel = entityModelStore.useGet(ingressModel.loadBalancerSearchableReference?.id);
    const forwardingRuleModels = entityModelStore.useGet((loadBalancerModel?.entity as Contract.GcpComputeLoadBalancer)?.resources.forwardingRuleIds) as Optional<Contract.GcpComputeForwardingRuleModel[]>;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpKubernetesIngressDefinition",
            () => ({
                info: {
                    loadBalancer: "Load Balancer",
                    loadBalancerForwardingRuleIpAddresses: "Address"
                },
                tabs: {
                    yaml: "YAML"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <KubernetesYamlInfoCard rawYaml={ingress.data.rawYaml!}/>,
                localization.tabs.yaml(),
                "yaml")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultPropertyInfoElements}
                entityPropertyInfoItemElements={[
                    commonKubernetesNamespaceResourceInfoItemElements.annotationsInfoItemElement,
                    commonKubernetesNamespaceResourceInfoItemElements.clusterInfoItemElement,
                    commonKubernetesNamespaceResourceInfoItemElements.namespaceInfoItemElement,
                    <VerticalTopItemsInfoItem
                        items={
                            _.map(
                                forwardingRuleModels,
                                forwardingRuleModel => (forwardingRuleModel.entity as Contract.GcpComputeForwardingRule).ipAddress)}
                        key="loadBalancerForwardingRuleIpAddresses"
                        title={localization.info.loadBalancerForwardingRuleIpAddresses()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={ingressModel?.loadBalancerSearchableReference?.id}
                        entityTypeName={Contract.TypeNames.GcpComputeLoadBalancer}
                        key="loadBalancerSearchableReference"
                        location="all"
                        title={localization.info.loadBalancer()}/>
                ]}
                options={options?.infoOptions}>
                <KubernetesIngressRulesInfoCard rules={ingress.data.rules}/>
            </Info>
    });
}