import { DropdownActions, Optional, PagedDropdown, PagedDropdownPage, useLocalization } from "@infrastructure";
import { List, ListItemButton } from "@mui/material";
import _ from "lodash";
import React, { useRef, useState } from "react";
import { Entity, entityModelStore, useTheme } from "..";

type PagedEntitySelectorProps = {
    disabled?: boolean;
    fullWidth?: boolean;
    getEntityModelPage: (searchText: Optional<string>, skip: number, data?: any) => Promise<PagedEntitySelectorPage>;
    onSelectedEntityIdChanged: (entityId: string) => void;
    placeholder: string;
    selectedEntityId?: string;
};

export class PagedEntitySelectorPage {
    constructor(
        public hasMore: boolean,
        public items: any[],
        public applyData?: () => any) {
    }
}

export function PagedEntitySelector({ disabled = false, fullWidth, getEntityModelPage, onSelectedEntityIdChanged, placeholder, selectedEntityId }: PagedEntitySelectorProps) {
    const localization =
        useLocalization(
            "common.pagedEntitySelector",
            () => ({
                empty: "No Results",
                search: "Search"
            }));

    const dropdownActionsRef = useRef<DropdownActions>(null);
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    return (
        <PagedDropdown
            actionsRef={dropdownActionsRef}
            disabled={disabled}
            emptyText={localization.empty()}
            fieldOptions={{
                placeholder,
                selection:
                    _.isNil(selectedEntityId)
                        ? undefined
                        : <Entity
                            entityIdOrModel={selectedEntityId}
                            linkOptions={{
                                color: "inherit",
                                disabled: true,
                                disabledHighlight: false
                            }}
                            variant="text"/>,
                variant: "itemSelector"
            }}
            fullWidth={fullWidth}
            getItemPage={
                async (searchText, skip, data) => {
                    const page =
                        await getEntityModelPage(
                            searchText,
                            skip,
                            data);

                    await entityModelStore.notify(page.items);

                    return new PagedDropdownPage(
                        page.hasMore,
                        _.map(
                            page.items,
                            entityModel => entityModel.id),
                        page.applyData);
                }}
            open={open}
            searchOptions={{
                enabled: true,
                placeholder: localization.search()
            }}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}>
            {(entityIds: string[]) =>
                <List dense={true}>
                    {_.map(
                        entityIds,
                        entityId =>
                            <ListItemButton
                                dense={true}
                                key={entityId}
                                sx={{ padding: theme.spacing(1) }}
                                onClick={
                                    () => {
                                        dropdownActionsRef.current!.close();
                                        onSelectedEntityIdChanged(entityId);
                                    }}>
                                <Entity
                                    entityIdOrModel={entityId}
                                    linkOptions={{ disabled: true }}
                                    variant="iconTextTypeTenant"/>
                            </ListItemButton>)}
                </List>}
        </PagedDropdown>);
}