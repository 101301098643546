import { SxProps, Typography } from "@mui/material";
import { TOptions } from "i18next";
import React, { useMemo } from "react";
import { InlineActionsSelection, InlineEntityMultiSelection } from "../../";
import { Contract } from "../../../../..";

type ViewProps = {
    riskPolicyConfiguration?: Contract.AzureManagedIdentityNotAllowedResourcePermissionRiskPolicyConfiguration;
    sx?: SxProps;
    templateTranslator: (options?: TOptions) => string;
};

export function View({ riskPolicyConfiguration, sx, templateTranslator }: ViewProps) {
    const [managedIdentityExcludeMatchConditions, managedIdentityMatchConditions, scopeResourceExcludeMatchConditions, scopeResourceMatchConditions] =
        useMemo(
            () => [
                riskPolicyConfiguration?.excludeManagedIdentityMatchConditions ?? [],
                riskPolicyConfiguration?.managedIdentityMatchConditions ?? [],
                riskPolicyConfiguration?.excludeScopeResourceMatchConditions ?? [],
                riskPolicyConfiguration?.scopeResourceMatchConditions ?? []
            ],
            [riskPolicyConfiguration]);
    return (
        <Typography
            component="span"
            sx={sx}>
            {templateTranslator({
                actions:
                    <InlineActionsSelection
                        selection={riskPolicyConfiguration}
                        sx={sx}/>,
                managedIdentities:
                    <InlineEntityMultiSelection
                        conditions={managedIdentityMatchConditions}
                        entityLinkOptions={{ disabled: true }}
                        entityTypeName={Contract.TypeNames.AzureManagedIdentityManagedIdentity}
                        excludeConditions={managedIdentityExcludeMatchConditions}
                        sx={sx}/>,
                scopeResources:
                    <InlineEntityMultiSelection
                        conditions={scopeResourceMatchConditions}
                        entityLinkOptions={{ disabled: true }}
                        entityTypeName={Contract.TypeNames.AzureScopeResource}
                        excludeConditions={scopeResourceExcludeMatchConditions}
                        sx={sx}/>
            })}
        </Typography>);
}