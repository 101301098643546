import { map } from "@infrastructure";
import { Contract } from "../../../common";

export class AwsLoadBalancerProtocolsHelper {
    public static getProtocolDisplayName =
        (protocol: Contract.AwsElbProtocol) =>
            map(
                protocol,
                {
                    [Contract.AwsElbProtocol.Http]: () => "HTTP",
                    [Contract.AwsElbProtocol.Https]: () => "HTTPS",
                    [Contract.AwsElbProtocol.Tcp]: () => "TCP",
                    [Contract.AwsElbProtocol.TcpUdp]: () => "TCP/UDP",
                    [Contract.AwsElbProtocol.Tls]: () => "TLS",
                    [Contract.AwsElbProtocol.Udp]: () => "UDP"
                });
}