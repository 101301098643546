import { FormLayout, Link, Message, Optional, useExecuteOperation, useLocalization, useSetWizardContext, useWizardContext } from "@infrastructure";
import { Box, Divider, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Contract, CustomerConsoleAppUrlHelper, RiskController, RiskType, ServiceNowIncidentSelector, ServiceNowIncidentSelectorData, TicketingServiceController } from "../../../../..";
import { useUpsertServiceNowIncidentContext } from "..";

export type AddServiceNowIncidentItemProps = {
    instanceId?: string;
    instanceModels: Contract.ScopeSystemEntityModel[];
    onClose: (successRiskIds?: string[]) => Promise<void>;
    riskIdToIncidentIdsMap: Dictionary<string[]>;
    riskType: RiskType;
};

export function ServiceNowIncidentItem({ instanceId, instanceModels, onClose, riskIdToIncidentIdsMap, riskType }: AddServiceNowIncidentItemProps) {
    const { executing, setLoaded, setValid, useNextEffect } = useWizardContext();
    const { multipleRisksSelection } = useUpsertServiceNowIncidentContext();
    const setWizardContext = useSetWizardContext();
    const [{ create, incidentCount, incidentError, incidentInfo, riskIds, riskModel }] =
        useExecuteOperation(
            ServiceNowIncidentItem,
            async () => {
                const riskIds = _.keys(riskIdToIncidentIdsMap);
                const { riskModel } =
                    riskIds.length === 1
                        ? await RiskController.getRiskModel(new Contract.RiskControllerGetRiskModelRequest(_.head(riskIds)!))
                        : { riskModel: undefined };
                let incidentInfo: Optional<Contract.ServiceNowIncidentInfo>;
                let incidentError = false;
                if (!_.isNil(riskModel) &&
                    riskIdToIncidentIdsMap[riskModel.risk.id].length === 1) {
                    const incidentId = _.head(riskIdToIncidentIdsMap[riskModel.risk.id])!;
                    try {
                        const { incidentInfo: existingIncidentInfo } =
                            await TicketingServiceController.tryGetServiceNowIncidentInfo(
                                new Contract.TicketingServiceControllerTryGetServiceNowIncidentInfoRequest(
                                    incidentId,
                                    riskModel.risk.id));
                        incidentInfo = existingIncidentInfo;
                        if (_.isNil(incidentInfo)) {
                            incidentError = true;
                        }
                    } catch {
                        incidentError = true;
                    }
                }

                const create =
                    _.some(
                        riskIdToIncidentIdsMap,
                        incidentIds => _.isEmpty(incidentIds));
                const incidentCount =
                    create
                        ? multipleRisksSelection === true
                            ? 1
                            : riskIds.length
                        : _(riskIdToIncidentIdsMap).
                            map(incidentIds => incidentIds.length).
                            sum();
                return {
                    create,
                    incidentCount,
                    incidentError,
                    incidentInfo,
                    riskIds,
                    riskModel
                };
            });

    instanceId = instanceId ?? incidentInfo?.instanceId;
    const instanceError =
        useMemo(
            () => {
                if (_.isNil(instanceId)) {
                    return false;
                }

                const instanceModel =
                    _.find(
                        instanceModels,
                        instanceModel => instanceModel.configuration.id === instanceId)!;
                return !_.isNil((instanceModel.state as Contract.ServiceNowInstanceState)?.issue);
            },
            [instanceId, instanceModels]);

    const localization =
        useLocalization(
            "common.upsertServiceNowIncidentDialog.upsertServiceNowIncident.serviceNowIncidentItem",
            () => ({
                actions: {
                    close: {
                        error: "Failed to refresh models"
                    },
                    retry: "Retry",
                    tryGetIncident: {
                        error: "Failed to get existing ServiceNow incident details"
                    }
                },
                bulk: {
                    actions: {
                        auditEvents: {
                            links: {
                                auditEvents: "Audit"
                            },
                            title: "{{errorMessage}}. Go to {{auditEventsLink}} for more information"
                        },
                        create: {
                            error: "Failed to create {{failedIncidentCount | NumberFormatter.humanize}}/{{incidentCount | NumberFormatter.humanize}} ServiceNow incidents",
                            title: "Create Incidents"
                        },
                        update: {
                            error: "Failed to update {{failedIncidentCount | NumberFormatter.humanize}}/{{incidentCount | NumberFormatter.humanize}} ServiceNow incidents",
                            title: "Update Incidents"
                        }
                    },
                    title: {
                        create: "Create {{incidentCount | NumberFormatter.humanize}} ServiceNow Incidents",
                        update: "Update {{incidentCount | NumberFormatter.humanize}} ServiceNow Incidents"
                    }
                },
                single: {
                    actions: {
                        create: {
                            error: "Failed to create ServiceNow incident",
                            title: "Create Incident"
                        },
                        error: {
                            links: {
                                serviceNow: "ServiceNow Configuration"
                            },
                            title: "There is a problem accessing your ServiceNow instance, go to {{serviceNowConfigurationLink}} for more details"
                        },
                        update: {
                            error: "Failed to update ServiceNow incident",
                            title: "Update Incident"
                        }
                    },
                    title: {
                        create: "Create a ServiceNow Incident",
                        update: "Update ServiceNow Incident"
                    }
                }
            }));

    const [riskIdToFailedIncidentIdsMap, setRiskIdToFailedIncidentIdsMap] = useState<Dictionary<string[]>>({});
    const [failedIncidentCount, successRiskIds] =
        useMemo(
            () => {
                const failedIncidentCount =
                    create
                        ? _(riskIdToFailedIncidentIdsMap).
                            keys().
                            size()
                        : _(riskIdToFailedIncidentIdsMap).
                            values().
                            flatMap().
                            size();
                const successRiskIds =
                    _.isEmpty(riskIdToFailedIncidentIdsMap)
                        ? []
                        : _.filter(
                            riskIds,
                            riskId =>
                                _.isNil(riskIdToFailedIncidentIdsMap[riskId]) ||
                                riskIdToFailedIncidentIdsMap[riskId].length !== riskIdToIncidentIdsMap[riskId].length);
                return [failedIncidentCount, successRiskIds];
            },
            [riskIds, riskIdToFailedIncidentIdsMap]);

    const [error, setError] = useState<"general" | "upsertIncidents">();

    const [serviceNowIncidentSelectorData, setServiceNowIncidentSelectorData] = useState<ServiceNowIncidentSelectorData>();
    const [selectorInfoTranslation, setSelectorInfoTranslation] = useState<Optional<string>>();

    useNextEffect(
        async () => {
            setError(undefined);
            try {
                const riskIdToUpsertIssueIdsMap =
                    _.isEmpty(riskIdToFailedIncidentIdsMap)
                        ? riskIdToIncidentIdsMap
                        : riskIdToFailedIncidentIdsMap;
                const { riskIdToFailedIncidentResultsMap } =
                    incidentCount === 1 && multipleRisksSelection !== true
                        ? await RiskController.upsertRiskServiceNowIncident(
                            new Contract.RiskControllerUpsertRiskServiceNowIncidentRequest(
                                riskIdToIncidentIdsMap[riskIds[0]][0],
                                serviceNowIncidentSelectorData!.incidentCreationData,
                                serviceNowIncidentSelectorData!.instanceId,
                                riskModel!.risk.id))
                        : await RiskController.upsertRiskServiceNowIncidents(
                            new Contract.RiskControllerUpsertRiskServiceNowIncidentsRequest(
                                serviceNowIncidentSelectorData!.incidentCreationData,
                                serviceNowIncidentSelectorData!.instanceId,
                                multipleRisksSelection ?? false,
                                riskIdToUpsertIssueIdsMap));

                if (_.isEmpty(riskIdToFailedIncidentResultsMap)) {
                    await onClose(riskIds);
                } else {
                    setRiskIdToFailedIncidentIdsMap(
                        _.mapValues(
                            riskIdToFailedIncidentResultsMap,
                            failedIncidentResults =>
                                _(failedIncidentResults).
                                    map(failedIncidentResult => failedIncidentResult.incidentId).
                                    filter().
                                    as<string>().
                                    value()));
                    setError("upsertIncidents");
                    return false;
                }
            } catch {
                setError("general");
                return false;
            }
            return undefined;
        },
        [serviceNowIncidentSelectorData]);

    useEffect(
        () => {
            setValid(!_.isNil(serviceNowIncidentSelectorData) && !executing);
        },
        [serviceNowIncidentSelectorData, executing]);
    useEffect(
        () => {
            setWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    nextTitle:
                        _.isEmpty(successRiskIds)
                            ? incidentCount === 1
                                ? create
                                    ? localization.single.actions.create.title()
                                    : localization.single.actions.update.title()
                                : create
                                    ? localization.bulk.actions.create.title()
                                    : localization.bulk.actions.update.title()
                            : localization.actions.retry()
                }));
        },
        [successRiskIds]);
    useEffect(
        () => {
            setLoaded();
        },
        []);

    return (
        <FormLayout
            footerOptions={{
                contentElement:
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1}
                        sx={{ width: "100%" }}>
                        <Box sx={{ flex: 1 }}>
                            {(
                                incidentError ||
                                instanceError) && (
                                <Message
                                    level="error"
                                    title={
                                        instanceError
                                            ? localization.single.actions.error.title({
                                                serviceNowConfigurationLink:
                                                    <Link
                                                        urlOrGetUrl={CustomerConsoleAppUrlHelper.getConfigurationIntegrationsServiceNowRelativeUrl()}>
                                                        {localization.single.actions.error.links.serviceNow()}
                                                    </Link>
                                            })
                                            : localization.actions.tryGetIncident.error()}/>)}
                            {error === "general" && (
                                <Message
                                    level="error"
                                    title={
                                        incidentCount === 1
                                            ? create
                                                ? localization.single.actions.create.error()
                                                : localization.single.actions.update.error()
                                            : (create
                                                ? localization.bulk.actions.create.error
                                                : localization.bulk.actions.update.error)({
                                                failedIncidentCount,
                                                incidentCount
                                            })}/>)}
                            {error === "upsertIncidents" && (
                                <Message
                                    level="error"
                                    title={
                                        incidentCount === 1
                                            ? create
                                                ? localization.single.actions.create.error()
                                                : localization.single.actions.update.error()
                                            : localization.bulk.actions.auditEvents.title({
                                                auditEventsLink:
                                                    <Link
                                                        urlOrGetUrl={CustomerConsoleAppUrlHelper.getAuditEventsRelativeUrl()}
                                                        variant="external">
                                                        {localization.bulk.actions.auditEvents.links.auditEvents()}
                                                    </Link>,
                                                errorMessage:
                                                    (create
                                                        ? localization.bulk.actions.create.error
                                                        : localization.bulk.actions.update.error)({
                                                        failedIncidentCount,
                                                        incidentCount
                                                    })
                                            })}/>)}
                        </Box>
                    </Stack>
            }}>
            <Stack spacing={5}>
                <ServiceNowIncidentSelector
                    disabled={executing || incidentError}
                    initialData={
                        _.isNil(incidentInfo)
                            ? undefined
                            : new Contract.ServiceNowIncidentCreationData(
                                incidentInfo.additionalMandatoryFieldNameToValueMap,
                                incidentInfo.description,
                                incidentInfo.fileNames,
                                incidentInfo.group,
                                incidentInfo.impact,
                                incidentInfo.summary,
                                incidentInfo.urgency,
                                incidentInfo.user
                            )}
                    initialInstanceNotValid={instanceError}
                    instanceId={instanceId}
                    instanceModels={instanceModels}
                    instanceReadOnly={!_.isNil(instanceId) || !_.isNil(incidentInfo)}
                    multipleRisks={multipleRisksSelection}
                    readOnly={!_.isEmpty(successRiskIds)}
                    riskIds={riskIds}
                    riskModel={riskModel}
                    riskType={riskType}
                    onDataChanged={
                        (jiraIssueSelectorData, _, selectorInfoTranslation) => {
                            setServiceNowIncidentSelectorData(jiraIssueSelectorData);
                            setSelectorInfoTranslation(selectorInfoTranslation);
                        }}/>
                {!_.isNil(selectorInfoTranslation) && (
                    <Fragment>
                        <Divider flexItem={true}/>
                        <Typography>
                            {selectorInfoTranslation}
                        </Typography>
                    </Fragment>)}
            </Stack>
        </FormLayout>);
}