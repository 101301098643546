﻿import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useGetEntitiesRiskContext } from "../../../useGetEntitiesRiskContext";

export function useGetGcpApiKeysKeysRiskContext() {
    return useMemo(
        () => useGcpApiKeysKeysRiskContext,
        []);
}

function useGcpApiKeysKeysRiskContext(keyIdsOrModels: string[] | Contract.GcpApiKeysKeyModel[]) {
    return useGetEntitiesRiskContext()(
        keyIdsOrModels,
        Contract.TypeNames.GcpApiKeysKey);
}