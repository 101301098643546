import { DataTableColumnRenderProps, Menu, PagedValuesFilterSelection, TimeRangeFilterSelection, TimeRangeFilterType, UrlMenuItem, useLocalization, ValuesFilterSelection } from "@infrastructure";
import { Box } from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";
import { Contract, CustomerConsoleAppUrlHelper } from "../../../../../../../common";

export function ActionsCell({ item }: DataTableColumnRenderProps<Contract.PermissionRequestAuditEventModel>) {
    const localization =
        useLocalization(
            "views.user.auditEvents.permissionRequestAuditEvents.actionsCell",
            () => ({
                menu: {
                    events: "View user activity"
                }
            }));

    return (
        item.auditEvent.typeName === Contract.TypeNames.PermissionRequestActivationAuditEvent &&
        !_.isEmpty((item as Contract.PermissionRequestActivationAuditEventModel).eventGranteeUserIdentityIdReferences) &&
        !_.isNil((item as Contract.PermissionRequestActivationAuditEventModel).expirationTime))
        ? <Box
            sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "flex-end"
            }}>
            <Menu
                itemsOrGetItems={[
                    new UrlMenuItem(
                        localization.menu.events(),
                        CustomerConsoleAppUrlHelper.getEventsRelativeUrl({
                            identity:
                                new PagedValuesFilterSelection(
                                    false,
                                    "include",
                                    (item as Contract.PermissionRequestActivationAuditEventModel).eventGranteeUserIdentityIdReferences!),
                            originatorEntity:
                                _.isEmpty((item as Contract.PermissionRequestActivationAuditEventModel).eventGranteeUserOriginatorIds)
                                    ? undefined
                                    : new PagedValuesFilterSelection(
                                        false,
                                        "include",
                                        (item as Contract.PermissionRequestActivationAuditEventModel).eventGranteeUserOriginatorIds),
                            tenantId:
                                _.isEmpty((item.auditEvent as Contract.PermissionRequestActivationAuditEvent).tenantIds)
                                    ? undefined
                                    : new ValuesFilterSelection(
                                        false,
                                        (item.auditEvent as Contract.PermissionRequestActivationAuditEvent).tenantIds),
                            time:
                                new TimeRangeFilterSelection(
                                    (item as Contract.PermissionRequestActivationAuditEventModel).expirationTime,
                                    (item.auditEvent as Contract.PermissionRequestActivationAuditEvent).systemCreationTime,
                                    undefined,
                                    TimeRangeFilterType.DateBetween)
                        }))
                ]}/>
        </Box>
        : <Fragment/>;
}