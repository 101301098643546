import { ActionMenuItem, AnalyticsEventActionType, AppError, DeleteIcon, EditIcon, Menu, useLocalization, useTrackAnalytics } from "@infrastructure";
import { CircularProgress, Stack, useTheme } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { ConfigurationController, Contract } from "../../../../../../../common";
import { AuthenticationItem, AuthenticationType, useAuthenticationContext, useSetAuthenticationContext } from "../Authentication";
import { AuthenticationHelper } from "../utilities";

type ActionsCellProps = {
    identityProviderText: string;
    item: AuthenticationItem;
};

export function ActionsCell({ identityProviderText, item }: ActionsCellProps) {
    const { aadIdentityProviderEnabled, consoleAppTypeToSamlIdentityProviderMap, executeGetAuthenticationConfiguration, gciIdentityProviderEnabled } = useAuthenticationContext();
    const setAuthenticationContext = useSetAuthenticationContext();
    const trackAnalytics = useTrackAnalytics();
    const [executing, setExecuting] = useState(false);
    async function deleteAuthenticationItem() {
        setExecuting(true);
        const authenticationRequest = { aadIdentityProviderEnabled, consoleAppTypeToSamlIdentityProviderMap, gciIdentityProviderEnabled };
        const [key, value] =
            item.authenticationType === AuthenticationType.Oidc
                ? [item.fieldKey, false]
                : [`${item.fieldKey}.${item.applications[0]}`, undefined];
        _.set(
            authenticationRequest,
            key,
            value);

        try {
            await ConfigurationController.updateAuthenticationConfiguration(
                new Contract.ConfigurationControllerUpdateAuthenticationConfigurationRequest(
                    authenticationRequest.aadIdentityProviderEnabled,
                    authenticationRequest.consoleAppTypeToSamlIdentityProviderMap,
                    authenticationRequest.gciIdentityProviderEnabled));
            await executeGetAuthenticationConfiguration();
            trackAnalytics(
                AnalyticsEventActionType.AuthenticationRemove,
                AuthenticationHelper.getTrackAnalyticsPropertyNameToValueMap(item, "Success"));
        } catch (error) {
            trackAnalytics(
                AnalyticsEventActionType.AuthenticationRemove,
                AuthenticationHelper.getTrackAnalyticsPropertyNameToValueMap(item, "Error"));
            throw new AppError(localization.actions.delete.error());
        } finally {
            setExecuting(false);
        }
    }

    const localization =
        useLocalization(
            "views.customer.configuration.authentication.actionsCell",
            () => ({
                actions: {
                    delete: {
                        confirm: "Are you sure you want to delete the identity provider {{identityProvider}}?",
                        error: "Something went wrong",
                        title: "Delete"
                    },
                    edit: {
                        title: "Edit"
                    }
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            {executing &&
                <CircularProgress
                    size={theme.spacing(2)}
                    variant="indeterminate"/>}
            <Menu
                disabled={executing}
                itemsOrGetItems={
                    _<ActionMenuItem>([]).
                        concatIf(
                            item.authenticationType === AuthenticationType.Saml,
                            new ActionMenuItem(
                                () =>
                                    setAuthenticationContext(
                                        context => ({
                                            ...context,
                                            addOrEditOpen:
                                                item.authenticationType === AuthenticationType.Saml
                                                    ? item
                                                    : true
                                        })),
                                localization.actions.edit.title(),
                                { icon: <EditIcon/> })).
                        concat(
                            new ActionMenuItem(
                                deleteAuthenticationItem,
                                localization.actions.delete.title(),
                                {
                                    confirmOptions: {
                                        message: localization.actions.delete.confirm({ identityProvider: identityProviderText })
                                    },
                                    icon: <DeleteIcon/>
                                })).
                        value()}
                variant="bottomCenter"/>
        </Stack>);
}