import { DataTableColumnRenderProps } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { Contract, NetworkScopeFormatter } from "../../../common";

export function AwsEc2NetworkRuleTypeCell({ item: rule }: DataTableColumnRenderProps<Contract.AwsEc2NetworkRule>) {
    return (
        <Typography noWrap={true}>
            {NetworkScopeFormatter.type(
                rule.destinationPortRange,
                rule.icmp?.type,
                rule.protocolRange)}
        </Typography>);
}