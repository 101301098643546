import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useGetGcpEntityRiskContext } from "./useGetGcpEntityRiskContext";

export function useGetGcpAppEngineApplicationRiskContext() {
    return useMemo(
        () => useGcpAppEngineApplicationServiceRiskContext,
        []);
}

function useGcpAppEngineApplicationServiceRiskContext(applicationModel: Contract.GcpAppEngineApplicationModel) {
    return useGetGcpEntityRiskContext()(applicationModel);
}