import { ActionMenuItem, DeleteIcon, EditIcon, Menu, MenuItem, Message, useLocalization } from "@infrastructure";
import { CircularProgress, Stack } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { ConfigurationController, Contract, Scope, useTheme } from "../../../../../../../../common";
import { usePrincipalsContext, useSetPrincipalsContext } from "../../Principals";
import { AddOrEditPrincipal } from "../AddOrEditPrincipal";

export type ActionsCellProps = {
    roleAssignment: Contract.ConfigurationControllerGetPrincipalRoleAssignmentsResponsePrincipalRoleAssignment;
};

export function ActionsCell({ roleAssignment }: ActionsCellProps) {
    const { executeGetPrincipals } = usePrincipalsContext();
    const setPrincipalsContext = useSetPrincipalsContext();

    const localization =
        useLocalization(
            "views.customer.configuration.principals.actionsCell",
            () => ({
                actions: {
                    delete: {
                        confirm: "Are you sure you want to remove the assignment of {{principalDisplayName}}?",
                        error: "Failed to remove the assignment of {{principalDisplayName}}",
                        title: "Delete"
                    },
                    edit: {
                        title: "Edit"
                    }
                },
                group: "group",
                user: "user"
            }));

    const [executing, setExecuting] = useState(false);
    const [error, setError] = useState<string>();

    async function deletePrincipalRole() {
        setExecuting(true);
        setError(undefined);

        try {
            if (roleAssignment.principalGroup) {
                await ConfigurationController.deleteGroupRoleAssignment(
                    new Contract.ConfigurationControllerDeleteGroupRoleAssignmentRequest(
                        roleAssignment.principalIdentifier,
                        roleAssignment.role,
                        roleAssignment.scopeId));
            } else {
                await ConfigurationController.deleteUserRoleAssignment(
                    new Contract.ConfigurationControllerDeleteUserRoleAssignmentRequest(
                        roleAssignment.principalIdentifier,
                        roleAssignment.role,
                        roleAssignment.scopeId));
            }

            await executeGetPrincipals();
        } catch {
            setError(localization.actions.delete.error({ principalDisplayName: roleAssignment.principalDisplayName }));
        }

        setExecuting(false);
    }

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            {executing && (
                <CircularProgress
                    size={theme.spacing(2)}
                    variant="indeterminate"/>)}
            {error && (
                <Message
                    level="error"
                    title={error}
                    variant="minimal"/>)}
            <Menu
                disabled={executing}
                itemsOrGetItems={
                    _<MenuItem>([]).
                        concat(
                            new ActionMenuItem(
                                () =>
                                    setPrincipalsContext(
                                        principalsContext => ({
                                            ...principalsContext,
                                            dialogContentElement:
                                                <AddOrEditPrincipal
                                                    roleAssignment={roleAssignment}
                                                    type={
                                                        roleAssignment.principalGroup
                                                            ? "group"
                                                            : "user"}/>
                                        })),
                                localization.actions.edit.title(),
                                {
                                    disabled: roleAssignment.role === Contract.IdentityRole.Owner,
                                    icon: <EditIcon/>
                                })).
                        concat(
                            new ActionMenuItem(
                                deletePrincipalRole,
                                localization.actions.delete.title(),
                                {
                                    confirmOptions: {
                                        message: localization.actions.delete.confirm({
                                            principalDisplayName: roleAssignment.principalDisplayName,
                                            scope:
                                                <Scope
                                                    scopeId={roleAssignment.scopeId}
                                                    variant="text"/>
                                        })
                                    },
                                    disabled: roleAssignment.role === Contract.IdentityRole.Owner,
                                    icon: <DeleteIcon/>
                                })).
                        value()}/>
        </Stack>);
}