import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, DeferredFilter, EnumValuesFilter, Optional, optionalTableCell, TimeFormatter, TimeRangeFilter, useLocalization, ValueFilter } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAzureCommonResourceGroupResourceDefinition } from ".";
import { EntityTableDefinition } from "../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useActivityTranslator, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAzureManagedIdentityManagedIdentityDefinition(definitionData: DefinitionData) {
    const managedIdentityModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.AzureManagedIdentityManagedIdentityModelFilters>;
    const commonResourceGroupResourceDefinition = useAzureCommonResourceGroupResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const activityTranslator = useActivityTranslator();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureManagedIdentityManagedIdentityDefinition",
            () => ({
                columns: {
                    originatorResourceIds: "Assigned To",
                    servicePrincipalActivityTime: {
                        helpText: "The last time a management write activity was seen in Azure for this identity, since read-only activity isn't logged in Azure.",
                        title: "Activity Time"
                    },
                    servicePrincipalContainedRoleAssignmentIds: {
                        helpText: "These role assignments are \"covered\" by another role assignment at a higher scope level or one that is assigned via group membership.",
                        title: "Covered Role Assignments"
                    },
                    servicePrincipalRoleAssignmentIds: "Role Assignments",
                    servicePrincipalRoleDefinitionIds: "Roles",
                    servicePrincipalSignInTime: {
                        empty: "Not seen",
                        title: "Sign-in Time"
                    },
                    type: {
                        title: "Type",
                        [Contract.TypeNames.AzureManagedIdentityManagedIdentityModelType]: {
                            [Contract.AzureManagedIdentityManagedIdentityModelType.System]: "System assigned",
                            [Contract.AzureManagedIdentityManagedIdentityModelType.User]: "User assigned"
                        }
                    }
                }
            }));

    return new EntityTableDefinition(
        _.filter([
            commonResourceGroupResourceDefinition.columns.tenantColumn,
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AzureManagedIdentityManagedIdentityModelType}
                                enumTypeTranslator={(type: Contract.AzureManagedIdentityManagedIdentityModelType) => localization.columns.type[Contract.TypeNames.AzureManagedIdentityManagedIdentityModelType][type]()}
                                placeholder={localization.columns.type.title()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureManagedIdentityManagedIdentityType}
                itemProperty={(item: Contract.AzureManagedIdentityManagedIdentityModel) => localization.columns.type[Contract.TypeNames.AzureManagedIdentityManagedIdentityModelType][item.type]()}
                key={Contract.EntityModelProperty.AzureManagedIdentityManagedIdentityType}
                title={localization.columns.type.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureManagedIdentityManagedIdentityModel) => ({
                            [localization.columns.servicePrincipalSignInTime.title()]: TimeFormatter.iso8601String(item.servicePrincipalSignInTime)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={managedIdentityModelFiltersPromise}
                                title={localization.columns.servicePrincipalSignInTime.title()}>
                                {filters =>
                                    <TimeRangeFilter
                                        emptyValue={filters.servicePrincipalSignInTimeRange.emptyValue}
                                        placeholder={localization.columns.servicePrincipalSignInTime.title()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.servicePrincipalSignInTimeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AzureManagedIdentityManagedIdentityServicePrincipalSignInTime}
                key={Contract.EntityModelProperty.AzureManagedIdentityManagedIdentityServicePrincipalSignInTime}
                render={
                    optionalTableCell<Contract.AzureManagedIdentityManagedIdentityModel>(
                        item =>
                            _.isNil(item.servicePrincipalSignInTime)
                                ? undefined
                                : TimeFormatter.monthDayAndYear(item.servicePrincipalSignInTime))}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.servicePrincipalSignInTime.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AzureManagedIdentityManagedIdentityModel) => ({
                            [localization.columns.servicePrincipalActivityTime.title()]:
                            activityTranslator(item.servicePrincipalActivity) ?? ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={managedIdentityModelFiltersPromise}
                                title={localization.columns.servicePrincipalActivityTime.title()}>
                                {filters =>
                                    <TimeRangeFilter
                                        emptyValue={filters.servicePrincipalActivityTimeRange.emptyValue}
                                        placeholder={localization.columns.servicePrincipalActivityTime.title()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.servicePrincipalActivityTimeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AzureManagedIdentityManagedIdentityServicePrincipalActivityTime}
                key={Contract.EntityModelProperty.AzureManagedIdentityManagedIdentityServicePrincipalActivityTime}
                message={localization.columns.servicePrincipalActivityTime.helpText()}
                messageLevel="info"
                render={
                    optionalTableCell<Contract.AzureManagedIdentityManagedIdentityModel>(
                        managedIdentityModel =>
                            activityTranslator(managedIdentityModel.servicePrincipalActivity))}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.servicePrincipalActivityTime.title()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureManagedIdentityManagedIdentityModel>(
                        Contract.TypeNames.AzureResource,
                        item => item.originatorResourceIds,
                        localization.columns.originatorResourceIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureManagedIdentityManagedIdentityOriginatorResources)}
                                placeholder={localization.columns.originatorResourceIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureManagedIdentityManagedIdentityOriginatorResources}
                key={Contract.EntityModelProperty.AzureManagedIdentityManagedIdentityOriginatorResources}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureManagedIdentityManagedIdentityModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.originatorResourceIds}
                            entityTypeName={Contract.TypeNames.AzureResource}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.originatorResourceIds()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureManagedIdentityManagedIdentityModel>(
                        Contract.TypeNames.AzureAuthorizationRoleAssignment,
                        item => item.servicePrincipalRoleAssignmentResourceIds,
                        localization.columns.servicePrincipalRoleAssignmentIds())}
                id={Contract.EntityModelProperty.AzureManagedIdentityManagedIdentityServicePrincipalRoleAssignmentResources}
                key={Contract.EntityModelProperty.AzureManagedIdentityManagedIdentityServicePrincipalRoleAssignmentResources}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureManagedIdentityManagedIdentityModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.servicePrincipalRoleAssignmentResourceIds}
                            entityTypeName={Contract.TypeNames.AzureAuthorizationRoleAssignmentResource}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.servicePrincipalRoleAssignmentIds()}/>,
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AzureManagedIdentityManagedIdentityServicePrincipalRoleDefinitions)}
                                placeholder={localization.columns.servicePrincipalRoleDefinitionIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureManagedIdentityManagedIdentityServicePrincipalRoleDefinitions}
                key={Contract.EntityModelProperty.AzureManagedIdentityManagedIdentityServicePrincipalRoleDefinitions}
                title={localization.columns.servicePrincipalRoleDefinitionIds()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AzureManagedIdentityManagedIdentityModel>(
                        Contract.TypeNames.AzureAuthorizationRoleAssignment,
                        item => item.servicePrincipalContainedRoleAssignmentResourceIds,
                        localization.columns.servicePrincipalContainedRoleAssignmentIds.title())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValueFilter
                                items={[
                                    {
                                        value: true
                                    }
                                ]}
                                placeholder={localization.columns.servicePrincipalContainedRoleAssignmentIds.title()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AzureManagedIdentityManagedIdentityServicePrincipalContainedRoleAssignmentResources}
                key={Contract.EntityModelProperty.AzureManagedIdentityManagedIdentityServicePrincipalContainedRoleAssignmentResources}
                message={localization.columns.servicePrincipalContainedRoleAssignmentIds.helpText()}
                messageLevel="info"
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AzureManagedIdentityManagedIdentityModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.servicePrincipalContainedRoleAssignmentResourceIds}
                            entityTypeName={Contract.TypeNames.AzureAuthorizationRoleAssignmentResource}
                            entityVariant="iconText"/>}
                selectorOptions={{ default: false }}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.servicePrincipalContainedRoleAssignmentIds.title()}/>,
            commonResourceGroupResourceDefinition.columns.creationTimeColumn,
            commonResourceGroupResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceGroupResourceDefinition.columns.regionColumn,
            commonResourceGroupResourceDefinition.columns.regionLocationColumn,
            commonResourceGroupResourceDefinition.columns.resourceGroupColumn,
            commonResourceGroupResourceDefinition.columns.tagsColumn,
            commonResourceGroupResourceDefinition.columns.attributesColumn,
            commonResourceGroupResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceGroupResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAzureManagedIdentityManagedIdentityRequest(
                new Contract.EntityControllerGetEntityModelPageAzureManagedIdentityManagedIdentityRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureResourceGroupResourceResourceGroup]),
                    filterMap[Contract.EntityModelProperty.AzureManagedIdentityManagedIdentityServicePrincipalContainedRoleAssignmentResources] as Optional<boolean>,
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureManagedIdentityManagedIdentityOriginatorResources]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.AzureManagedIdentityManagedIdentityServicePrincipalActivityTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureManagedIdentityManagedIdentityServicePrincipalRoleDefinitions]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.AzureManagedIdentityManagedIdentityServicePrincipalSignInTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AzureManagedIdentityManagedIdentityType])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}