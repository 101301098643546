import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useAwsCommonEc2ResourceInfoElements, useAwsCommonNetworkingInfoItemElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useAwsEc2FlowLogFilterTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { NetworkingInfoCard } from "../../components";

export function useAwsEc2NetworkInterfaceDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonEc2ResourceInfoItemElements = useAwsCommonEc2ResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const networkInterface = resourceModel.entity as Contract.AwsEc2NetworkInterface;
    const networkInterfaceModel = resourceModel as Contract.AwsEc2NetworkInterfaceModel;
    const commonNetworkCardInfoItems =
        useAwsCommonNetworkingInfoItemElements({
            elasticIpIds: networkInterfaceModel.elasticIpIds,
            privateIpAddresses: networkInterface.privateIpAddresses,
            publicIpAddresses: networkInterface.publicIpAddresses,
            resourceModel
        });

    const ec2FlowLogFilterTranslator = useAwsEc2FlowLogFilterTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsEc2NetworkInterfaceDefinition",
            () => ({
                info: {
                    items: {
                        availabilityZone: "Availability Zone",
                        description: "Description",
                        fabricAdapter: {
                            false: "Disabled",
                            title: "Elastic Fabric Adapter",
                            true: "Enabled"
                        },
                        flowLogDestinationResourceIdReferences: "Flow Logs Destinations",
                        flowLogFilter: "Flow Logs Filter",
                        instanceId: "EC2 Instance",
                        serviceIds: "ECS Services",
                        taskDefinitionIds: "Task Definition",
                        vpcId: "VPC"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonEc2ResourceInfoItemElements,
                    <InfoItem
                        key="availabilityZone"
                        title={localization.info.items.availabilityZone()}
                        value={networkInterface.availabilityZone.name}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={networkInterfaceModel.instanceId}
                        entityTypeName={Contract.TypeNames.AwsEc2Instance}
                        key="instanceId"
                        title={localization.info.items.instanceId()}/>,
                    <InfoItem
                        key="description"
                        title={localization.info.items.description()}
                        value={networkInterface.description}/>,
                    <InfoItem
                        key="fabricAdapter"
                        title={localization.info.items.fabricAdapter.title()}
                        value={
                            networkInterface.fabricAdapter
                                ? localization.info.items.fabricAdapter.true()
                                : localization.info.items.fabricAdapter.false()}/>,
                    <InfoItem
                        key="flowLogFilter"
                        title={localization.info.items.flowLogFilter()}
                        value={ec2FlowLogFilterTranslator(networkInterfaceModel.flowLogFilter)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={networkInterfaceModel.flowLogDestinationResourceIdReferences}
                        entityTypeName={Contract.TypeNames.AwsResource}
                        key="flowLogDestinationResourceIdReferences"
                        title={localization.info.items.flowLogDestinationResourceIdReferences()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={networkInterfaceModel.serviceIds}
                        entityTypeName={Contract.TypeNames.AwsEcsService}
                        key="serviceIds"
                        title={localization.info.items.serviceIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={
                            _.map(
                                networkInterfaceModel.taskDefinitionReferences,
                                taskDefinitionReference => taskDefinitionReference.idReference)}
                        entityTypeName={Contract.TypeNames.AwsEcsTaskDefinition}
                        key="taskDefinitionIds"
                        title={localization.info.items.taskDefinitionIds()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={networkInterface.vpcIds}
                        entityTypeName={Contract.TypeNames.AwsEc2Vpc}
                        key="vpc"
                        location="miniGlance"
                        title={localization.info.items.vpcId()}/>
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {commonNetworkCardInfoItems.getPublicIpAddresses()}
                    {commonNetworkCardInfoItems.getPrivateIpAddresses()}
                    {commonNetworkCardInfoItems.getMacAddress()}
                    {commonNetworkCardInfoItems.getVpcs()}
                    {commonNetworkCardInfoItems.getSubnets()}
                    {commonNetworkCardInfoItems.getSecurityGroups()}
                    {commonNetworkCardInfoItems.getElasticIps()}
                </NetworkingInfoCard>
            </Info>
    });
}