import _ from "lodash";
import React from "react";
import { DataTableColumn, DataTableColumnRenderProps, InlineItems, PagedValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Contract, ElasticsearchItemPageHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAwsCommonCloudWatchLoggingResourceDefinition(definitionData: DefinitionData) {
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsCommonCloudWatchLoggingResourceDefinition",
            () => ({
                columns: {
                    cloudWatchLoggingEnabled: {
                        false: "Disabled",
                        title: "CloudWatch Logging",
                        true: "Enabled"
                    },
                    cloudWatchLogTypes: "CloudWatch Log Types"
                }
            }));

    function getCloudWatchLoggingTranslation(resourceModel: Contract.AwsResourceModel) {
        return (_.as<Contract.IAwsCloudWatchLoggingResource>(resourceModel.entity)).cloudWatchLoggingEnabled
            ? localization.columns.cloudWatchLoggingEnabled.true()
            : localization.columns.cloudWatchLoggingEnabled.false();
    }

    return {
        columns: {
            cloudWatchLoggingEnabledColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsResourceModel) => ({
                                [localization.columns.cloudWatchLoggingEnabled.title()]: getCloudWatchLoggingTranslation(item)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter
                                    placeholder={localization.columns.cloudWatchLoggingEnabled.title()}
                                    sorted={false}>
                                    <ValuesFilterItem
                                        title={localization.columns.cloudWatchLoggingEnabled.true()}
                                        value={true}/>
                                    <ValuesFilterItem
                                        title={localization.columns.cloudWatchLoggingEnabled.false()}
                                        value={false}/>
                                </ValuesFilter>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsCloudWatchResourceCloudWatchLoggingEnabled}
                    itemProperty={getCloudWatchLoggingTranslation}
                    key={Contract.EntityModelProperty.AwsCloudWatchResourceCloudWatchLoggingEnabled}
                    selectorOptions={{ default: false }}
                    title={localization.columns.cloudWatchLoggingEnabled.title()}/>,
            cloudWatchLogTypesColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsResourceModel) => ({
                                [localization.columns.cloudWatchLogTypes()]:
                                _.as<Contract.IAwsCloudWatchLoggingResource>(item.entity).cloudWatchLogTypes
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsCloudWatchResourceCloudWatchLogTypes)}
                                    placeholder={localization.columns.cloudWatchLogTypes()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsCloudWatchResourceCloudWatchLogTypes}
                    key={Contract.EntityModelProperty.AwsCloudWatchResourceCloudWatchLogTypes}
                    render={({ item }: DataTableColumnRenderProps<Contract.IAwsCloudWatchLoggingResourceModel>) =>
                        <InlineItems
                            items={_.as<Contract.IAwsCloudWatchLoggingResource>(item.entity).cloudWatchLogTypes}
                            variant="itemPlusItemCount"/>}
                    selectorOptions={{ default: false }}
                    title={localization.columns.cloudWatchLogTypes()}/>
        }
    };
}