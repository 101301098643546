import { Action1, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableSortType, DataTableVariant, EmptyMessageText, Optional, StringHelper, TextValuesFilter, useLocalization, ValueFilter, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { SxProps } from "@mui/material";
import _ from "lodash";
import React, { MutableRefObject } from "react";
import { Contract, ItemTable, RiskPolicyCategory, Severity, SeverityFilter, TenantsIcon, TypeHelper, useRiskPolicyCategoryTranslator, useTenantTypeTranslator, useTheme } from "../../../../../../../../../../../common";
import { ItemTableColumnId, RiskPolicySelectItem, RiskPolicyTableVariant } from "../RiskPolicyTable";

type TableProps = {
    dataTableOptions: TableDataTableOptions;
    items: RiskPolicySelectItem[];
    onSelectChange: Action1<string[]>;
    sx?: SxProps;
    variant: RiskPolicyTableVariant;
};

type TableDataTableOptions = {
    actions?: React.ReactNode;
    actionsRef: MutableRefObject<Optional<DataTableActions>>;
    manualScroll?: boolean;
    pageSize?: number;
    variant: DataTableVariant;
};

export function Table({ dataTableOptions, items, onSelectChange, sx, variant }: TableProps) {
    const riskPolicyCategoryTranslator = useRiskPolicyCategoryTranslator();
    const tenantTypeTranslator = useTenantTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.compliance.addOrEdit.section.riskPolicyTable.table",
            () => ({
                columns: {
                    category: "Category",
                    policy: {
                        edit: "Name",
                        view: "Policy"
                    },
                    selected: "Selected",
                    severity: {
                        empty: "Dynamic",
                        title: "Severity"
                    },
                    tenantType: "Platform"
                },
                empty: {
                    withFilter: "No matching policies",
                    withoutFilter: "No policies"
                }
            }));

    const editMode = variant === "edit";
    const theme = useTheme();
    return (
        <ItemTable
            actionsRef={dataTableOptions.actionsRef}
            columnIdToGetItemValueMap={{
                [ItemTableColumnId.Selected]:
                    item =>
                        _.includes(
                            dataTableOptions.actionsRef?.current?.getSelectedItemIds(),
                            item.id),
                [ItemTableColumnId.Category]: item => riskPolicyCategoryTranslator(item.category),
                [ItemTableColumnId.Policy]: item => item.name,
                [ItemTableColumnId.Severity]: {
                    getFilterValue: item => item.severity,
                    getSortValue:
                        item =>
                            _.isNil(item.severity)
                                ? 0
                                : TypeHelper.getEnumValue(Contract.TypeNames.Severity, item.severity) + 1
                },
                [ItemTableColumnId.TenantTypes]: {
                    getFilterValue: item => item.tenantTypes,
                    getSortValue: item => StringHelper.getCombineSortValue(item.tenantTypes.length, ...item.tenantTypes)
                }
            }}
            dataTableActions={dataTableOptions.actions}
            defaultSortColumnIdOrIds={[
                ItemTableColumnId.TenantTypes,
                ItemTableColumnId.Category,
                ItemTableColumnId.Policy
            ]}
            emptyMessageOptions={{
                emptyMessageText:
                    new EmptyMessageText(
                        localization.empty.withoutFilter(),
                        localization.empty.withFilter())
            }}
            getItemId={(item: RiskPolicySelectItem) => item.id}
            items={items}
            pageSize={10}
            selectionOptions={{
                onChanged: selectedItems => onSelectChange(selectedItems),
                persistence: true,
                showActions: false
            }}
            sortOptions={{ enabled: false }}
            sx={sx}
            variant={dataTableOptions.variant}>
            {columnIdToItemValuesMap =>
                _.filter([
                    <DataTableColumn
                        cellSx={{ width: theme.spacing(8) }}
                        filterOptions={{
                            ...(editMode && {
                                itemOrItems: {
                                    default: true,
                                    element:
                                        <ValuesFilter placeholder={localization.columns.tenantType()}>
                                            {_.map(
                                                columnIdToItemValuesMap[ItemTableColumnId.TenantTypes],
                                                tenantType =>
                                                    <ValuesFilterItem
                                                        key={tenantType}
                                                        title={tenantTypeTranslator(tenantType)}
                                                        value={tenantType}/>)}
                                        </ValuesFilter>
                                }
                            })
                        }}
                        id={ItemTableColumnId.TenantTypes}
                        key={ItemTableColumnId.TenantTypes}
                        render={
                            ({ item }: DataTableColumnRenderProps<RiskPolicySelectItem>) =>
                                <TenantsIcon
                                    sx={{ fontSize: "18px" }}
                                    tenantTypes={item.tenantTypes}/>}
                        title={localization.columns.tenantType()}/>,
                    <DataTableColumn
                        filterOptions={{
                            ...(editMode && {
                                itemOrItems: {
                                    default: true,
                                    element:
                                        <TextValuesFilter
                                            placeholder={localization.columns.category()}
                                            values={columnIdToItemValuesMap[ItemTableColumnId.Category]}/>
                                }
                            })
                        }}
                        id={ItemTableColumnId.Category}
                        key={ItemTableColumnId.Category}
                        render={
                            ({ item }: DataTableColumnRenderProps<RiskPolicySelectItem>) =>
                                <RiskPolicyCategory
                                    riskPolicyCategory={item.category}
                                    variant="riskPolicy"/>}
                        title={localization.columns.category()}/>,
                    <DataTableColumn
                        cellMaxWidth="medium"
                        filterOptions={{
                            ...(editMode && {
                                itemOrItems: {
                                    default: true,
                                    element:
                                        <TextValuesFilter
                                            placeholder={localization.columns.policy.edit()}
                                            values={columnIdToItemValuesMap[ItemTableColumnId.Policy]}/>
                                }
                            })
                        }}
                        id={ItemTableColumnId.Policy}
                        itemProperty={(item: RiskPolicySelectItem) => item.name}
                        key={ItemTableColumnId.Policy}
                        title={
                            editMode
                                ? localization.columns.policy.edit()
                                : localization.columns.policy.view()}/>,
                    <DataTableColumn
                        cellSx={{ width: theme.spacing(8) }}
                        filterOptions={{
                            ...(editMode && {
                                itemOrItems: {
                                    default: true,
                                    element:
                                        <SeverityFilter
                                            emptyValueOptions={{
                                                enabled: true,
                                                title: localization.columns.severity.empty()
                                            }}
                                            placeholder={localization.columns.severity.title()}/>
                                }
                            })
                        }}
                        id={ItemTableColumnId.Severity}
                        key={ItemTableColumnId.Severity}
                        render={
                            ({ item }: DataTableColumnRenderProps<RiskPolicySelectItem>) =>
                                <Severity
                                    dynamicRiskPolicyConfigurationTypeName={item.id}
                                    severity={item.severity}/>}
                        sortOptions={{ type: DataTableSortType.Numeric }}
                        title={localization.columns.severity.title()}/>,
                    editMode
                        ? <DataTableColumn
                            filterOptions={{
                                itemOrItems: {
                                    default: true,
                                    element:
                                    <ValueFilter
                                        items={[{
                                            title: localization.columns.selected(),
                                            value: true
                                        }]}
                                        placeholder={localization.columns.selected()}/>
                                }
                            }}
                            id={ItemTableColumnId.Selected}
                            key={ItemTableColumnId.Selected}/>
                        : undefined
                ])}
        </ItemTable>);
}