import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function GitLabPipelineIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            sx={{ fontSize: "24px" }}
            {...props}>
            <path
                clipRule="evenodd"
                d="M9.46733 21.9913H14.0973C14.0973 21.9913 17.1111 14.9344 16.6695 10.3673C16.4123 7.70629 15.7483 6.06192 14.6118 3.76994C14.0933 2.72443 13.1497 1.54659 12.4955 0.790004C12.0807 0.31035 11.7823 0 11.7823 0C11.7823 0 11.4956 0.319946 11.0905 0.807728C10.452 1.57668 9.51947 2.76273 8.95289 3.76994C7.65328 6.0803 7.01777 7.60292 6.6379 10.3673C6.00478 14.9747 9.46733 21.9913 9.46733 21.9913ZM10.1409 20.9337H13.3841C13.4398 20.7912 13.5028 20.6275 13.5714 20.445C13.8407 19.7289 14.1952 18.7277 14.5357 17.589C15.2289 15.2707 15.8164 12.5339 15.6168 10.4691C15.3769 7.98767 14.7723 6.47439 13.6642 4.23979C13.2967 3.49851 12.6535 2.6323 12.0636 1.91746C11.9737 1.80845 11.8862 1.70438 11.8025 1.60637C11.7186 1.70839 11.6304 1.81665 11.5395 1.92988C10.9558 2.65676 10.2934 3.54406 9.87468 4.28846C8.62222 6.51498 8.04305 7.91058 7.68567 10.5113C7.40498 12.554 8.03728 15.2581 8.81727 17.5689C9.19911 18.7001 9.60094 19.6936 9.90736 20.4042C9.99386 20.6048 10.0726 20.7824 10.1409 20.9337Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M4.22062 16.1325L7.70819 12.7912L6.97653 12.0275L3.48896 15.3688C2.9084 15.925 2.83572 16.8276 3.3198 17.4695L7.89489 23.5365C8.47796 24.3097 9.69528 24.0737 9.94712 23.1386L10.4556 21.2507L9.43433 20.9757L8.92589 22.8636C8.903 22.9486 8.79233 22.97 8.73932 22.8998L4.16423 16.8327C4.00287 16.6187 4.0271 16.3179 4.22062 16.1325Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M19.0537 16.1325L15.5661 12.7912L16.2977 12.0275L19.7853 15.3688C20.3659 15.925 20.4386 16.8276 19.9545 17.4695L15.3794 23.5365C14.7963 24.3097 13.579 24.0737 13.3272 23.1386L12.8187 21.2507L13.8399 20.9757L14.3484 22.8636C14.3713 22.9486 14.482 22.97 14.535 22.8998L19.11 16.8327C19.2714 16.6187 19.2472 16.3179 19.0537 16.1325Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M11.6371 12.4799C12.6107 12.4799 13.3998 11.6907 13.3998 10.7172C13.3998 9.74369 12.6107 8.95451 11.6371 8.95451C10.6636 8.95451 9.87444 9.74369 9.87444 10.7172C9.87444 11.6907 10.6636 12.4799 11.6371 12.4799ZM11.6371 13.5375C13.1948 13.5375 14.4575 12.2748 14.4575 10.7172C14.4575 9.15959 13.1948 7.89689 11.6371 7.89689C10.0795 7.89689 8.81682 9.15959 8.81682 10.7172C8.81682 12.2748 10.0795 13.5375 11.6371 13.5375Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}