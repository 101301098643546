import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { RiskPoliciesView, RiskPolicyItem } from "../../../../../../../../../../common";
import { RiskPolicyInfoItems } from "../../useRiskPolicyInfoItems";
import { useCodeRiskPolicyBuiltInInfoItems } from "./hooks";

export function useCodeRiskPolicyInfoItems(scopeId: string, item: RiskPolicyItem, policyView: RiskPoliciesView): RiskPolicyInfoItems {
    const useCodeRiskPolicyInfoItems =
        useMemo(
            () => {
                switch (policyView) {
                    case RiskPoliciesView.BuiltIn:
                        return useCodeRiskPolicyBuiltInInfoItems;
                    default:
                        throw new UnexpectedError("useCodeRiskPolicyInfoItems.policyView", policyView);
                }
            },
            [policyView]);

    return useCodeRiskPolicyInfoItems(scopeId, item);
}