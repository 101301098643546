import { Box, ListItemButton, useTheme } from "@mui/material";
import _ from "lodash";
import React, { MouseEvent, useEffect, useState } from "react";
import { Action0, Expand, NavigationViewItem, setUrlRoute, useChangeEffect } from "@infrastructure";
import { AccordionItems, AccordionTitle, TreeItems, TreeTitle } from "./components";
import { ElementClass } from "./Item.element";

type ItemProps = {
    item: NavigationViewItem;
    level?: number;
    onNavigate?: Action0;
    paged?: boolean;
    searchMode: boolean;
    selectedItemId: string;
    variant: "accordion" | "tree";
};

export function Item({ item, level = 0, onNavigate, paged, searchMode, selectedItemId, variant }: ItemProps) {
    function hasSelectedItem(item: NavigationViewItem): boolean {
        return _.isEmpty(item.options?.items)
            ? false
            : _.some(
                item.options!.items,
                item =>
                    item.id === selectedItemId ||
                    hasSelectedItem(item));
    }

    const [expanded, setExpanded] = useState(() => item.options?.initialExpanded || hasSelectedItem(item));
    useChangeEffect(
        () => {
            if (hasSelectedItem(item)) {
                setExpanded(true);
            }
        },
        [selectedItemId]);

    useEffect(
        () => {
            setExpanded(
                searchMode
                    ? true
                    : item.options?.initialExpanded || hasSelectedItem(item));
        },
        [searchMode]);

    const theme = useTheme();
    const [ItemsComponent, TitleComponent] =
        variant === "accordion"
            ? [AccordionItems, AccordionTitle]
            : [TreeItems, TreeTitle];
    return (
        <Box sx={{ width: "100%" }}>
            {item.options?.disabled === true
                ? <TitleComponent
                    className={ElementClass.itemDisabled}
                    expanded={expanded}
                    item={item}
                    level={level}
                    onClick={() => setExpanded(expanded => !expanded)}/>
                : _.isNil(item.options?.path)
                    ? <ListItemButton
                        disableGutters={true}
                        id={item.id}
                        key={item.id}
                        selected={item.id === selectedItemId}
                        sx={{
                            color: theme.palette.text.secondary,
                            padding: 0
                        }}>
                        <TitleComponent
                            className={
                                expanded
                                    ? ElementClass.itemOpened
                                    : ElementClass.itemClosed}
                            expanded={expanded}
                            item={item}
                            level={level}
                            onClick={() => setExpanded(expanded => !expanded)}/>
                    </ListItemButton>
                    : <ListItemButton
                        component="a"
                        disableGutters={true}
                        href={item.options?.path}
                        id={item.id}
                        key={item.id}
                        selected={item.id === selectedItemId}
                        sx={{
                            color: theme.palette.text.secondary,
                            padding: 0
                        }}
                        onClick={
                            (event: MouseEvent) => {
                                event.preventDefault();
                                event.stopPropagation();
                            }}>
                        <TitleComponent
                            className={
                                !_.isNil(item.options?.path) && _.isEmpty(item.options?.items)
                                    ? ElementClass.itemNode
                                    : expanded
                                        ? ElementClass.itemOpened
                                        : ElementClass.itemClosed}
                            expanded={expanded}
                            item={item}
                            level={level}
                            onClick={
                                element => {
                                    if (element === "title" &&
                                        !_.isNil(item.options?.path)) {
                                        setUrlRoute(item.options!.path!);
                                        onNavigate?.();
                                    } else {
                                        setExpanded(expanded => !expanded);
                                    }
                                }}/>
                    </ListItemButton>}
            {!_.isEmpty(item.options?.items) && (
                <Expand expanded={expanded}>
                    <ItemsComponent
                        item={item}
                        level={level}
                        paged={paged}
                        searchMode={searchMode}
                        selectedItemId={selectedItemId}
                        onNavigate={onNavigate}/>
                </Expand>)}
        </Box>);
}