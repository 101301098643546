import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../components";
import { useAwsCommonKubernetesResourceInfoItemElements } from "./useAwsCommonKubernetesResourceInfoItemElements";

export function useAwsCommonKubernetesNamespaceResourceInfoItemElements(resourceModel: Contract.AwsResourceModel) {
    const commonResourceInfoElements = useAwsCommonKubernetesResourceInfoItemElements(resourceModel);
    const resource = resourceModel.entity as Contract.AwsKubernetesResource<Contract.KubernetesNamespaceResourceData>;
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsCommonKubernetesNamespaceResourceInfoItemElements",
            () => ({
                namespace: "Namespace"
            }));

    return {
        ...commonResourceInfoElements,
        namespaceInfoItemElement:
            <EntitiesInfoItem
                entityIdsOrModels={resource.data.namespaceId}
                entityTypeName={Contract.TypeNames.AwsKubernetesNamespace}
                key="namespaceId"
                location="all"
                title={localization.namespace()}/>
    };
}