import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract, RiskType } from "../../../../../../common";
import { useCloudDefinition, useCodeDefinition } from "./hooks";
import { RiskDefinition } from "./utilities";

export function useDefinition(riskType: RiskType, riskModel: Contract.RiskModel): RiskDefinition {
    const useDefinition =
        useMemo(
            () => {
                switch (riskType) {
                    case RiskType.Cloud:
                        return useCloudDefinition;
                    case RiskType.Code:
                        return useCodeDefinition;
                    default:
                        throw new UnexpectedError("riskType", riskType);
                }
            },
            [riskType]);

    return useDefinition(riskModel);
}