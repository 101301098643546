import { FormLayout, Link, Message, Optional, useExecuteOperation, useLocalization, useSetWizardContext, useWizardContext } from "@infrastructure";
import { Box, Divider, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Contract, CustomerConsoleAppUrlHelper, InlineTextViewer, JiraProjectReference, RiskController, RiskType, TicketingServiceController, ticketingServiceTicketStore } from "../../../../..";
import { JiraDeliveryProjectSelectorData, JiraIssueSelector } from "../../../../JiraIssueSelector";
import { useUpsertJiraIssueContext } from "../UpsertJiraIssue";

export type AddJiraIssueItemProps = {
    instanceModels: Contract.ScopeSystemEntityModel[];
    onClose: (successRiskIds?: string[]) => Promise<void>;
    projectReference?: JiraProjectReference;
    riskIdToIssueIdsMap: Dictionary<string[]>;
    riskType: RiskType;
};

export function IssueItem({ instanceModels, onClose, projectReference, riskIdToIssueIdsMap, riskType }: AddJiraIssueItemProps) {
    const { multipleRisksSelection } = useUpsertJiraIssueContext();
    const { setLoaded, setValid, useNextEffect } = useWizardContext();
    const setWizardContext = useSetWizardContext();

    const [{ create, issueCount, issueError, issueInfo, issueInstanceId, issueProjectReference, riskIds, riskModel }] =
        useExecuteOperation(
            IssueItem,
            async () => {
                const riskIds = _.keys(riskIdToIssueIdsMap);
                let riskModel: Optional<Contract.RiskModel>;
                if (riskIds.length === 1) {
                    const { riskModel: existingRiskModel } = await RiskController.getRiskModel(new Contract.RiskControllerGetRiskModelRequest(riskIds[0]));
                    riskModel = existingRiskModel;
                }

                let issueInfo: Optional<Contract.JiraIssueInfo>;
                let issueError = false;
                let issueInstanceId: Optional<string>;
                let issueProjectReference: Optional<JiraProjectReference>;
                if (!_.isNil(riskModel) &&
                    riskIdToIssueIdsMap[riskModel.risk.id].length === 1) {
                    const issueId = _.head(riskIdToIssueIdsMap[riskModel.risk.id])!;
                    const issue = await ticketingServiceTicketStore.get(issueId) as Contract.JiraIssue;
                    issueInstanceId = issue.instanceId;
                    issueProjectReference = {
                        instanceId: issue.instanceId,
                        projectRawId: issue.projectRawId
                    };
                    try {
                        const { issueInfo: existingIssueInfo } =
                            await TicketingServiceController.tryGetJiraIssueInfo(
                                new Contract.TicketingServiceControllerTryGetJiraIssueInfoRequest(
                                    issueId,
                                    riskModel.risk.id));
                        issueInfo = existingIssueInfo;
                        if (_.isNil(issueInfo)) {
                            issueError = true;
                        }
                    } catch {
                        issueError = true;
                    }
                }

                const create =
                    _.some(
                        riskIdToIssueIdsMap,
                        issueIds => _.isEmpty(issueIds));
                const issueCount =
                    create
                        ? multipleRisksSelection === true
                            ? 1
                            : riskIds.length
                        : _(riskIdToIssueIdsMap).
                            map(issueIds => issueIds.length).
                            sum();
                return {
                    create,
                    issueCount,
                    issueError,
                    issueInfo,
                    issueInstanceId,
                    issueProjectReference,
                    riskIds,
                    riskModel
                };
            });

    projectReference = projectReference ?? issueProjectReference;
    const projectError =
        useMemo(
            () => {
                if (_.isNil(projectReference)) {
                    return false;
                }

                const instanceModel =
                    _.find(
                        instanceModels,
                        instanceModel => instanceModel.configuration.id === projectReference!.instanceId)!;
                return !_.isNil((instanceModel.state as Contract.JiraInstanceState)?.projectRawIdToProjectMap[projectReference.projectRawId]?.issue);
            },
            [projectReference, instanceModels]);

    const localization =
        useLocalization(
            "common.upsertJiraIssueDialog.upsertJiraIssue.issueItem",
            () => ({
                actions: {
                    close: {
                        updated: "Done"
                    },
                    projectDatas: {
                        error: {
                            links: {
                                jiraConfiguration: "Jira Configuration"
                            },
                            title: "There is a problem accessing your Jira project, go to {{jiraConfigurationLink}} for more details"
                        }
                    },
                    retry: "Retry",
                    tryGetIssue: {
                        error: "Failed to get existing Jira issue details"
                    }
                },
                bulk: {
                    actions: {
                        auditEvents: {
                            links: {
                                auditEvents: "Audit"
                            },
                            title: "{{errorMessage}}. Go to {{auditEventsLink}} for more information"
                        },
                        create: {
                            error: {
                                general: "Failed to create {{failedIssueCount | NumberFormatter.humanize}}/{{issueCount | NumberFormatter.humanize}} jira issues",
                                [Contract.TypeNames.RiskSenderSendRiskJiraIssueResult]: {
                                    [Contract.RiskSenderSendRiskJiraIssueResult.FailureSetSprint]: "Project doesn't support sprints"
                                }
                            },
                            title: "Create Issues"
                        },
                        update: {
                            error: {
                                general: "Failed to update {{failedIssueCount | NumberFormatter.humanize}}/{{issueCount | NumberFormatter.humanize}} jira issues",
                                [Contract.TypeNames.RiskSenderSendRiskJiraIssueResult]: {
                                    [Contract.RiskSenderSendRiskJiraIssueResult.FailureSetSprint]: "Project doesn't support sprints"
                                }
                            },
                            title: "Update Issues"
                        }
                    },
                    title: {
                        create: "Create {{issueCount | NumberFormatter.humanize}} Jira Issues",
                        update: "Update {{issueCount | NumberFormatter.humanize}} Jira Issues"
                    }
                },
                single: {
                    actions: {
                        common: {
                            error: {
                                text: "Show Error",
                                title: "Jira Error"
                            }
                        },
                        create: {
                            error: "Failed to create jira issue. {{ jiraError }}",
                            title: "Create Issue"
                        },
                        update: {
                            error: "Failed to update jira issue. {{ jiraError }}",
                            title: "Update Issue"
                        }
                    },
                    title: {
                        create: "Create a Jira Issue",
                        update: "Update Jira Issue"
                    }
                }
            }));

    const [riskIdToFailedIssueIdsMap, setRiskIdToFailedIssueIdsMap] = useState<Dictionary<string[]>>({});
    const [failedIssueCount, successRiskIds] =
        useMemo(
            () => {
                const failedIssueCount =
                    create
                        ? _(riskIdToFailedIssueIdsMap).
                            keys().
                            size()
                        : _(riskIdToFailedIssueIdsMap).
                            values().
                            flatMap().
                            size();
                const successRiskIds =
                    _.isEmpty(riskIdToFailedIssueIdsMap)
                        ? []
                        : _.filter(
                            riskIds,
                            riskId =>
                                _.isNil(riskIdToFailedIssueIdsMap[riskId]) ||
                                riskIdToFailedIssueIdsMap[riskId].length !== riskIdToIssueIdsMap[riskId].length);
                return [failedIssueCount, successRiskIds];
            },
            [riskIds, riskIdToFailedIssueIdsMap]);

    const [error, setError] = useState<Contract.RiskSenderSendRiskJiraIssueResult.FailureSetSprint | "general" | "upsertIssues">();

    const [issueSelectorData, setIssueSelectorData] = useState<JiraDeliveryProjectSelectorData>();
    const [jiraRawError, setJiraRawError] = useState<string>();
    const [selectorInfoTranslation, setSelectorInfoTranslation] = useState<Optional<string>>();

    useNextEffect(
        async () => {
            setError(undefined);
            try {
                const riskIdToUpsertIssueIdsMap =
                    _.isEmpty(riskIdToFailedIssueIdsMap)
                        ? riskIdToIssueIdsMap
                        : riskIdToFailedIssueIdsMap;
                const { riskIdToFailedIssueResultsMap } =
                    issueCount === 1 && multipleRisksSelection !== true
                        ? await RiskController.upsertRiskJiraIssue(
                            new Contract.RiskControllerUpsertRiskJiraIssueRequest(
                                riskIdToIssueIdsMap[riskIds[0]][0],
                                issueSelectorData!.instanceId,
                                issueSelectorData!.issueCreationData,
                                riskModel!.risk.id))
                        : await RiskController.upsertRiskJiraIssues(
                            new Contract.RiskControllerUpsertRiskJiraIssuesRequest(
                                issueSelectorData!.instanceId,
                                issueSelectorData!.issueCreationData!,
                                multipleRisksSelection ?? false,
                                riskIdToUpsertIssueIdsMap));

                if (_.isEmpty(riskIdToFailedIssueResultsMap)) {
                    await onClose(riskIds);
                } else if (
                    _(riskIdToFailedIssueResultsMap).
                        values().
                        flatMap().
                        some(issueResult => issueResult.result === Contract.RiskSenderSendRiskJiraIssueResult.FailureSetSprint)) {
                    setError(Contract.RiskSenderSendRiskJiraIssueResult.FailureSetSprint);
                    return false;
                } else {
                    setRiskIdToFailedIssueIdsMap(
                        _.mapValues(
                            riskIdToFailedIssueResultsMap,
                            failedIssueResults =>
                                _(failedIssueResults).
                                    map(failedIssueResult => failedIssueResult.issueId).
                                    filter().
                                    as<string>().
                                    value()));
                    if (_.size(riskIdToFailedIssueResultsMap) == 1) {
                        setJiraRawError(_.values(riskIdToFailedIssueResultsMap)[0][0].rawError);
                    }
                    setError("upsertIssues");
                    return false;
                }
            } catch {
                setError("general");
                return false;
            }
            return undefined;
        },
        [issueSelectorData]);

    useEffect(
        () => {
            setValid(!_.isNil(issueSelectorData));
        },
        [issueSelectorData]);
    useEffect(
        () => {
            setWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    nextTitle:
                        _.isEmpty(successRiskIds)
                            ? issueCount === 1
                                ? create
                                    ? localization.single.actions.create.title()
                                    : localization.single.actions.update.title()
                                : create
                                    ? localization.bulk.actions.create.title()
                                    : localization.bulk.actions.update.title()
                            : localization.actions.retry()
                }));
        },
        [successRiskIds]);
    useEffect(
        () => {
            setLoaded();
        },
        []);

    const jiraError =
        jiraRawError &&
        <InlineTextViewer
            dialogTitle={localization.single.actions.common.error.title()}
            text={JSON.stringify(JSON.parse(jiraRawError), null, 2)}
            title={localization.single.actions.common.error.text()}/>;

    return (
        <FormLayout
            footerOptions={{
                contentElement:
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1}
                        sx={{ width: "100%" }}>
                        {(!_.isNil(error) || issueError || projectError) && (
                            <Box sx={{ flex: 1 }}>
                                {(issueError || projectError) && (
                                    <Message
                                        level="error"
                                        title={
                                            projectError
                                                ? localization.actions.projectDatas.error.title({
                                                    jiraConfigurationLink:
                                                        <Link
                                                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getConfigurationIntegrationsJiraRelativeUrl()}>
                                                            {localization.actions.projectDatas.error.links.jiraConfiguration()}
                                                        </Link>
                                                })
                                                : localization.actions.tryGetIssue.error()}/>)}
                                {error === Contract.RiskSenderSendRiskJiraIssueResult.FailureSetSprint && (
                                    <Message
                                        level="error"
                                        title={
                                            create
                                                ? localization.bulk.actions.create.error[Contract.TypeNames.RiskSenderSendRiskJiraIssueResult][Contract.RiskSenderSendRiskJiraIssueResult.FailureSetSprint]()
                                                : localization.bulk.actions.update.error[Contract.TypeNames.RiskSenderSendRiskJiraIssueResult][Contract.RiskSenderSendRiskJiraIssueResult.FailureSetSprint]()}/>)}
                                {error === "general" && (
                                    <Message
                                        level="error"
                                        title={
                                            issueCount === 1
                                                ? create
                                                    ? localization.single.actions.create.error({ jiraError: "" })
                                                    : localization.single.actions.update.error({ jiraError: "" })
                                                : (create
                                                    ? localization.bulk.actions.create.error.general
                                                    : localization.bulk.actions.update.error.general)({
                                                    failedIssueCount,
                                                    issueCount
                                                })}/>)}
                                {error === "upsertIssues" && (
                                    <Message
                                        level="error"
                                        title={
                                            issueCount === 1
                                                ? <Fragment>
                                                    {create
                                                        ? localization.single.actions.create.error({ jiraError })
                                                        : localization.single.actions.update.error({ jiraError })}
                                                </Fragment>
                                                : localization.bulk.actions.auditEvents.title({
                                                    auditEventsLink:
                                                        <Link
                                                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getAuditEventsRelativeUrl()}
                                                            variant="external">
                                                            {localization.bulk.actions.auditEvents.links.auditEvents()}
                                                        </Link>,
                                                    errorMessage:
                                                        (create
                                                            ? localization.bulk.actions.create.error.general
                                                            : localization.bulk.actions.update.error.general)({
                                                            failedIssueCount,
                                                            issueCount
                                                        })
                                                })}/>)}
                            </Box>)}
                    </Stack>
            }}>
            <Stack
                spacing={5}
                sx={{ width: "100%" }}>
                <JiraIssueSelector
                    disabled={issueError}
                    initialData={
                        _.isNil(issueInfo)
                            ? undefined
                            : {
                                instanceId: issueInstanceId!,
                                issueCreationData:
                                    new Contract.JiraIssueCreationData(
                                        issueInfo.additionalMandatoryFieldNameToValuesMap,
                                        issueInfo.description,
                                        issueInfo.epic,
                                        issueInfo.fileNames,
                                        issueInfo.labels,
                                        issueInfo.projectRawId,
                                        issueInfo.sprint,
                                        issueInfo.summary,
                                        issueInfo.issueTypeName,
                                        issueInfo.user!)
                            }}
                    initialProjectNotValid={projectError}
                    instanceModels={instanceModels}
                    multipleRisks={multipleRisksSelection}
                    projectReadOnly={!_.isNil(projectReference) || !_.isNil(issueInfo)}
                    projectReference={
                        _.isNil(projectReference) && _.isNil(issueInfo)
                            ? undefined
                            : {
                                instanceId: (issueInstanceId ?? projectReference?.instanceId)!,
                                projectRawId: (issueInfo?.projectRawId ?? projectReference?.projectRawId)!
                            }}
                    readOnly={!_.isEmpty(successRiskIds)}
                    riskIds={riskIds}
                    riskModel={riskModel}
                    riskType={riskType}
                    onDataChanged={
                        (jiraIssueSelectorData, _, selectorInfoTranslation) => {
                            setIssueSelectorData(jiraIssueSelectorData);
                            setSelectorInfoTranslation(selectorInfoTranslation);
                        }}/>
                {!_.isNil(selectorInfoTranslation) && (
                    <Fragment>
                        <Divider flexItem={true}/>
                        <Typography>
                            {selectorInfoTranslation}
                        </Typography>
                    </Fragment>)}
            </Stack>
        </FormLayout>);
}