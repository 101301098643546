﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { CustomerConsoleAppUrlHelper } from "../../../../../../../../../../common";
import { AzureEntityModel, AzureUnmanagedKubernetesClusterModel } from "../../../../../../../../../../common/controllers/types.generated";
import { Info } from "../../../../components";
import { KubernetesClusterResourcesInfoCard } from "../../components";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../useDefinition";
import { useCommonUnmanagedKubernetesClusterInfoElements } from "../useCommonUnmanagedKubernetesClusterInfoElements";
import { useAzureCommonEntityInfoItemElements } from "./useAzureCommonEntityInfoItemElements";

export function useAzureUnmanagedKubernetesClusterDefinition(entityModel: AzureEntityModel, options?: DefinitionOptions) {
    const clusterModel = entityModel as AzureUnmanagedKubernetesClusterModel;
    const commonEntityInfoItemElements = useAzureCommonEntityInfoItemElements(entityModel);
    const commonUnmanagedKubernetesClusterInfoElements = useCommonUnmanagedKubernetesClusterInfoElements(clusterModel);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureUnmanagedKubernetesClusterDefinition",
            () => ({
                tabs: {
                    resources: "Resources"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <KubernetesClusterResourcesInfoCard clusterId={clusterModel.entity.id}/>,
                localization.tabs.resources(),
                "resources")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={[
                    commonEntityInfoItemElements.typeNameInfoElement,
                    commonEntityInfoItemElements.tenantInfoItemElement,
                    commonUnmanagedKubernetesClusterInfoElements.creationTime,
                    commonEntityInfoItemElements.syncTimeInfoItemElement
                ]}
                entityPropertyInfoItemElements={[
                    commonUnmanagedKubernetesClusterInfoElements.version,
                    commonUnmanagedKubernetesClusterInfoElements.createApiConnectivityStatusInfoItemElement(CustomerConsoleAppUrlHelper.getDocsOnboardClusterViaTenableConnectorRelativeUrl())
                ]}
                options={options?.infoOptions}/>
    });
}