﻿import React from "react";
import { useOciCommonIamCredentialInfoItemElements, useOciCommonResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract } from "../../../../../../../../../../common";
import { Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useOciIamUserDatabasePasswordDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const userDatabasePasswordModel = resourceModel as Contract.OciIamUserDatabasePasswordModel;

    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const commonIamCredentialInfoItemElements = useOciCommonIamCredentialInfoItemElements(userDatabasePasswordModel);
    const commonResourceInfoItemElements = useOciCommonResourceInfoItemElements(resourceModel);

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={
                    [
                        commonResourceInfoItemElements.typeNameInfoElement,
                        commonResourceInfoItemElements.organizationInfoItemElement,
                        commonResourceInfoItemElements.tenantInfoItemElement,
                        commonResourceInfoItemElements.ocidInfoItemElement,
                        commonResourceInfoItemElements.creationTimeInfoItemElement,
                        commonResourceInfoItemElements.createdByInfoItemElement,
                        commonResourceInfoItemElements.syncTimeInfoItemElement
                    ]
                }
                entityPropertyInfoItemElements={[
                    commonIamCredentialInfoItemElements.descriptionInfoItemElement,
                    commonIamCredentialInfoItemElements.userIdInfoItemElement
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}