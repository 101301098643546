import { ActionMenuItem, AddIcon, DataTableAction, DataTableActions, DataTableColumn, DeleteIcon, Dialog, EditIcon, EmptyMessageText, Loading, makeContextProvider, map, Menu, TextValuesFilter, useChangeEffect, useExecuteOperation, useLocalization, VerticalFillGrid } from "@infrastructure";
import { Box, Button, Stack, Tab, Tabs } from "@mui/material";
import _ from "lodash";
import React, { useRef } from "react";
import { ConfigurationController, Contract, ItemTable, useScopeNavigationViewContext, useTheme } from "../../../../../../common";
import { ConfigurationView } from "../../utilities";
import { AddOrEdit } from "./components";

export function NetworkInternalSubnets() {
    const networkInternalSubnetsViews = [NetworkInternalSubnetsView.InternalIPAddressRanges];
    const { useViewRoute } = useScopeNavigationViewContext();
    const [view, setView] = useViewRoute(`${ConfigurationView.NetworkInternalSubnets}/{view}`, networkInternalSubnetsViews);

    const localization =
        useLocalization(
            "views.customer.configuration.networkInternalSubnets",
            () => ({
                tabs: {
                    networkInternalSubnetsView: {
                        [NetworkInternalSubnetsView.InternalIPAddressRanges]: "Internal IP Address Ranges"
                    }
                }
            }));
    const theme = useTheme();
    return (
        <VerticalFillGrid>
            <Tabs
                sx={{
                    border: "unset",
                    padding: theme.spacing(0, 2)
                }}
                value={view}
                variant="scrollable"
                onChange={(event, view) => setView(view as NetworkInternalSubnetsView)}>
                {_.map(
                    networkInternalSubnetsViews,
                    networkInternalSubnetsView =>
                        <Tab
                            key={networkInternalSubnetsView}
                            label={localization.tabs.networkInternalSubnetsView[networkInternalSubnetsView]()}
                            sx={{
                                marginRight: theme.spacing(3),
                                padding: 0
                            }}
                            value={networkInternalSubnetsView}/>)}
            </Tabs>
            <Box
                sx={{
                    height: "100%",
                    width: "100%"
                }}>
                <Loading>
                    {map(
                        view,
                        {
                            [NetworkInternalSubnetsView.InternalIPAddressRanges]: () => <Table/>
                        })}
                </Loading>
            </Box>
        </VerticalFillGrid>);
}

export enum NetworkInternalSubnetsView {
    InternalIPAddressRanges = "InternalIPAddressRanges"
}

class NetworkInternalSubnetsContext {
    constructor(
        public addOrEditOpen: boolean | InternalSubnetData,
        public internalSubnetDatas: InternalSubnetData[]
    ) {
    }
}

export type InternalSubnetData = {
    name: string;
    subnet: string;
};

export const [useNetworkInternalSubnetsContext, useSetNetworkInternalSubnetsContext, useNetworkInternalSubnetsContextProvider] = makeContextProvider<NetworkInternalSubnetsContext>();

function Table() {
    const [{ internalSubnetToNameMap }] =
        useExecuteOperation(
            Table,
            ConfigurationController.getAnalysisNetwork);

    const localization =
        useLocalization(
            "views.customer.configuration.networkInternalSubnets.table",
            () => ({
                actions: {
                    add: "Add Internal IP Address Range",
                    delete: {
                        confirm: "Are you sure you want to delete the IP Address Range {{name}}?",
                        label: "Delete"
                    },
                    edit: "Edit"
                },
                fields: {
                    empty: {
                        withFilter: "No matching IP Address Ranges",
                        withoutFilter: "No IP Address Ranges"
                    },
                    name: "Name",
                    subnet: {
                        label: "IP Address Range"
                    }
                }
            }));

    const [context, setContext, ContextProvider] =
        useNetworkInternalSubnetsContextProvider(
            () =>
                new NetworkInternalSubnetsContext(
                    false,
                    _.map(
                        _.keys(internalSubnetToNameMap),
                        subnet => ({
                            name: internalSubnetToNameMap[subnet],
                            subnet
                        }))));
    const itemTableActionsRef = useRef<DataTableActions>();

    useChangeEffect(
        () => itemTableActionsRef.current?.reset({ refreshFilters: true }),
        [context.internalSubnetDatas]);

    async function deleteSubnet(subnet: string) {
        await ConfigurationController.deleteAnalysisNetwork(new Contract.ConfigurationControllerDeleteAnalysisNetworkRequest(subnet));

        setContext(
            context => ({
                ...context,
                internalSubnetDatas:
                    _.filter(
                        context.internalSubnetDatas,
                        internalSubnetData => internalSubnetData.subnet !== subnet)
            }));
    }

    const theme = useTheme();
    return (
        <ContextProvider>
            <Stack
                spacing={1}
                sx={{ height: "100%" }}>
                {context.addOrEditOpen != false &&
                    <Dialog
                        variant="editor"
                        onClose={
                            () =>
                                setContext(
                                    context => ({
                                        ...context,
                                        addOrEditOpen: false
                                    }))}>
                        <AddOrEdit
                            onClose={
                                () =>
                                    setContext(
                                        context => ({
                                            ...context,
                                            addOrEditOpen: false
                                        }))}/>
                    </Dialog>}
                <VerticalFillGrid sx={{ overflow: "hidden" }}>
                    <ItemTable
                        actionsRef={itemTableActionsRef}
                        columnIdToGetItemValueMap={{
                            [NetworkInternalSubnetsTableColumnId.Subnet]: item => item.subnet,
                            [NetworkInternalSubnetsTableColumnId.Name]: item => item.name
                        }}
                        defaultSortColumnIdOrIds={NetworkInternalSubnetsTableColumnId.Name}
                        emptyMessageOptions={{
                            emptyMessageText:
                                new EmptyMessageText(
                                    localization.fields.empty.withoutFilter(),
                                    localization.fields.empty.withFilter())
                        }}
                        getItemId={item => item?.subnet}
                        items={context.internalSubnetDatas}
                        showEmptyTable={true}
                        variant="view">
                        {columnIdToItemValuesMap =>
                            [
                                <DataTableAction key={NetworkInternalSubnetsTableColumnId.Actions}>
                                    <Button
                                        size="small"
                                        startIcon={<AddIcon/>}
                                        onClick={
                                            () =>
                                                setContext(
                                                    context => ({
                                                        ...context,
                                                        addOrEditOpen: true
                                                    }))}>
                                        {localization.actions.add()}
                                    </Button>
                                </DataTableAction>,
                                <DataTableColumn
                                    filterOptions={{
                                        itemOrItems: {
                                            default: true,
                                            element:
                                                <TextValuesFilter
                                                    placeholder={localization.fields.name()}
                                                    values={columnIdToItemValuesMap[NetworkInternalSubnetsTableColumnId.Name]}/>
                                        }
                                    }}
                                    id={NetworkInternalSubnetsTableColumnId.Name}
                                    itemProperty={(item: InternalSubnetData) => item.name}
                                    key={NetworkInternalSubnetsTableColumnId.Name}
                                    title={localization.fields.name()}/>,
                                <DataTableColumn
                                    filterOptions={{
                                        itemOrItems: {
                                            default: true,
                                            element:
                                                <TextValuesFilter
                                                    placeholder={localization.fields.subnet.label()}
                                                    values={columnIdToItemValuesMap[NetworkInternalSubnetsTableColumnId.Subnet]}/>
                                        }
                                    }}
                                    id={NetworkInternalSubnetsTableColumnId.Subnet}
                                    itemProperty={(item: InternalSubnetData) => item.subnet}
                                    key={NetworkInternalSubnetsTableColumnId.Subnet}
                                    title={localization.fields.subnet.label()}/>,
                                <DataTableColumn
                                    headerSx={{ width: theme.spacing(0.5) }}
                                    id={NetworkInternalSubnetsTableColumnId.Actions}
                                    key={NetworkInternalSubnetsTableColumnId.Actions}
                                    render={({ item }) =>
                                        <Menu
                                            itemsOrGetItems={[
                                                new ActionMenuItem(
                                                    () =>
                                                        setContext(
                                                            context => ({
                                                                ...context,
                                                                addOrEditOpen: item
                                                            })),
                                                    localization.actions.edit(),
                                                    {
                                                        icon: <EditIcon/>
                                                    }),
                                                new ActionMenuItem(
                                                    () => deleteSubnet(item?.subnet),
                                                    localization.actions.delete.label(),
                                                    {
                                                        confirmOptions: {
                                                            message: localization.actions.delete.confirm({ name: item?.name ?? "" })
                                                        },
                                                        icon: <DeleteIcon/>
                                                    })
                                            ]}
                                            variant="bottomRight"/>
                                    }/>
                            ]}
                    </ItemTable>
                </VerticalFillGrid>
            </Stack>
        </ContextProvider>);
}

export enum NetworkInternalSubnetsTableColumnId {
    Actions = "actions",
    Name = "name",
    Subnet = "subnet"
}