import { InlineTexts, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract, useRiskPolicyTitleTranslator } from "../../../../../../../../../common";

export function useCustomComplianceAuditEventDefinition(context: AuditEventDefinitionContext) {
    const riskPolicyTitleTranslator = useRiskPolicyTitleTranslator();

    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useCustomComplianceAuditEventDefinition",
            () => ({
                description: "Description",
                name: "Name",
                policies: {
                    allPolicies: "All",
                    pluralizer: [
                        "1 policy",
                        "{{count | NumberFormatter.humanize}} policies"
                    ],
                    title: "Policies"
                }
            }));

    const customComplianceAuditEvent = context.auditEventModel.auditEvent as Contract.CustomComplianceAuditEvent;
    return new AuditEventDefinition(
        _.filter([
            new AuditEventDefinitionDetailItem(
                localization.name(),
                customComplianceAuditEvent.name),
            _.isNil(customComplianceAuditEvent.description)
                ? undefined!
                : new AuditEventDefinitionDetailItem(
                    localization.description(),
                    customComplianceAuditEvent.description!),
            new AuditEventDefinitionDetailItem(
                localization.policies.title(),
                undefined!,
                <InlineTexts
                    namePluralizer={localization.policies.pluralizer}
                    texts={
                        _(customComplianceAuditEvent.builtInRiskPolicyConfigurationTypeNames).
                            map(builtInRiskPolicyConfigurationTypeName => riskPolicyTitleTranslator(builtInRiskPolicyConfigurationTypeName)).
                            concat(customComplianceAuditEvent.customRiskPolicyNames).
                            sort().
                            value()}
                    variant="itemPlusItemCount"/>)
        ]));
}