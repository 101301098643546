import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOneLoginDirectoryUserProvisioningStateTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOneLoginDirectoryUserProvisioningStateTranslator",
            () => ({
                [Contract.TypeNames.OneLoginDirectoryUserProvisioningState]: {
                    [Contract.OneLoginDirectoryUserProvisioningState.Approved]: "Approved",
                    [Contract.OneLoginDirectoryUserProvisioningState.Rejected]: "Rejected",
                    [Contract.OneLoginDirectoryUserProvisioningState.Unapproved]: "Unapproved",
                    [Contract.OneLoginDirectoryUserProvisioningState.Unlicensed]: "Unlicensed"
                }
            }));
    return (provisioningState: Contract.OneLoginDirectoryUserProvisioningState) =>
        localization[Contract.TypeNames.OneLoginDirectoryUserProvisioningState][provisioningState]();
}