import { AuthenticationItem, AuthenticationType } from "../Authentication";

export class AuthenticationHelper {
    public static getTrackAnalyticsPropertyNameToValueMap(item: AuthenticationItem, status: "Success" | "Error") {
        return {
            "Identity Provider":
                item.authenticationType === AuthenticationType.Saml
                    ? "Saml"
                    : item.identityProviderType,
            "Status": status,
            ...(item.authenticationType === AuthenticationType.Saml && {
                "Saml Type": item.identityProviderType
            })
        };
    }
}