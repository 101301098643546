import { Action0, ContentMenuItem, map, Sx } from "@infrastructure";
import { ChevronRight } from "@mui/icons-material";
import { Box, Menu, MenuItem, PopoverOrigin, Stack, SxProps, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useRef, useState } from "react";

type ContentMenuProps = {
    item: ContentMenuItem;
    onClose: Action0;
    sx?: SxProps;
};

export function ContentMenu({ item, onClose, sx }: ContentMenuProps) {
    const [open, setOpen] = useState(false);

    function close() {
        setOpen(false);
        onClose();
    }

    const theme = useTheme();
    const elementRef = useRef<HTMLLIElement | null>(null);
    return (
        <Fragment>
            <Menu
                anchorEl={elementRef.current}
                {...map<string, { anchorOrigin: PopoverOrigin; transformOrigin: PopoverOrigin }>(
                    item.options?.placement,
                    {
                        "bottomLeft":
                            () =>
                                ({
                                    anchorOrigin: {
                                        horizontal: "left",
                                        vertical: "bottom"
                                    },
                                    transformOrigin: {
                                        horizontal: "left",
                                        vertical: "top"
                                    }
                                }),
                        "topRight":
                            () =>
                                ({
                                    anchorOrigin: {
                                        horizontal: "left",
                                        vertical: "top"
                                    },
                                    transformOrigin: {
                                        horizontal: "right",
                                        vertical: "top"
                                    }
                                })
                    })}
                MenuListProps={{
                    sx: {
                        minWidth: theme.spacing(13),
                        padding: 0
                    }
                }}
                open={open}
                onClose={() => close()}>
                {item.content(() => close())}
            </Menu>
            <MenuItem
                ref={elementRef}
                sx={
                    Sx.combine(
                        {
                            background:
                                open
                                    ? theme.palette.action.hover
                                    : undefined,
                            color: theme.palette.text.primary,
                            overflow: "hidden",
                            padding: theme.spacing(1)
                        },
                        sx)}
                onClick={() => setOpen(true)}>
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}
                    sx={{
                        color: theme.palette.text.primary,
                        display: "flex",
                        width: "100%"
                    }}>
                    {!_.isNil(item.options?.icon) &&
                        <Box sx={{ fontSize: "14px" }}>
                            {item.options?.icon}
                        </Box>}
                    <Typography sx={{ flex: 1 }}>
                        {item.title}
                    </Typography>
                    {item.options?.variant === "subMenu" &&
                        <ChevronRight sx={{ fontSize: "14px" }}/>}
                </Stack>
            </MenuItem>
        </Fragment>);
}