﻿import { Loading, useExecuteOperation, useLocalization } from "@infrastructure";
import { Box } from "@mui/material";
import _ from "lodash";
import React, { RefObject, useMemo } from "react";
import { Contract, CustomerConsoleAppUrlHelperCategoryView } from "../../../../../../../../../../common";
import { RiskDefinitionSection, RiskDefinitionSectionCategory } from "../../../../../../hooks/useDefinition/utilities";
import { RiskView } from "../../../../../../utilities";
import { Category, CategoryTab } from "../Category";
import { CodeResolutionSection, ResolveCode } from "./components";
import { getRiskCodeFileDiffsCache } from "./utilities";

type ResolutionCategoryProps = {
    containerRef?: RefObject<HTMLDivElement>;
    getUrl: (riskId: string, categoryView?: CustomerConsoleAppUrlHelperCategoryView<RiskDefinitionSectionCategory, RiskView>) => string;
    riskModel: Contract.RiskModel;
    tabs: CategoryTab[];
};

export function ResolutionCategory({ containerRef, getUrl, riskModel, tabs }: ResolutionCategoryProps) {
    const [riskCodeFileDiffsResponse] =
        useExecuteOperation(
            [ResolutionCategory, riskModel.id],
            () => getRiskCodeFileDiffsCache(riskModel.risk.id));

    const localization =
        useLocalization(
            "views.customer.risks.profile.core.resolutionCategory.resolutionCategory",
            () => ({
                resolutionCodeSectionDescription: "Review the code changes and apply them in your source code using the Create Pull Request button",
                resolutionCodeTabTitle: "IaC"
            }));

    const visibleTabs =
        useMemo(
            () =>
                _<CategoryTab>([]).
                    concatIf(
                        !_.isNil(riskCodeFileDiffsResponse?.data),
                        () =>
                            new CategoryTab(
                                RiskDefinitionSectionCategory.Resolution,
                                [
                                    new RiskDefinitionSection(
                                        <CodeResolutionSection fileDiffsData={riskCodeFileDiffsResponse!.data!}/>,
                                        localization.resolutionCodeSectionDescription(),
                                        {
                                            actionsElement:
                                                <Box
                                                    alignItems="center"
                                                    justifyContent="flex-end"
                                                    sx={{
                                                        display: "flex",
                                                        width: "100%"
                                                    }}>
                                                    <ResolveCode codeTenantId={riskCodeFileDiffsResponse!.data!.tenantId}/>
                                                </Box>
                                        })
                                ],
                                localization.resolutionCodeTabTitle(),
                                RiskView.ResolutionCode)).
                    concat(tabs).
                    value(),
            [riskCodeFileDiffsResponse]);

    return (
        <Loading>
            <Category
                category={RiskDefinitionSectionCategory.Resolution}
                containerRef={containerRef}
                getUrl={getUrl}
                riskModel={riskModel}
                tabs={visibleTabs}/>
        </Loading>);
}