import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, useEntityTypeNameTranslator } from "../../../../../../../../../../../../../common";
import { RiskDefinition } from "../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../useCommonSectionsAndDescriptionDefinition";
import { useGetVirtualMachineImageWorkloadAnalysisRiskContext } from "../../contexts";
import { useGetWorkloadResourceOperatingSystemContextItem } from "../../contexts/useGetWorkloadResourceOperatingSystemContextItem";
import { useGetVirtualMachineImageResolutionSteps } from "./useGetVirtualMachineImageResolutionSteps";

export function useVirtualMachineImageOperatingSystemEndOfLifeRiskDefinition(riskModel: Contract.RiskModel): RiskDefinition {
    const risk = riskModel.risk as Contract.VirtualMachineImageOperatingSystemEndOfLifeRisk;
    const getVirtualMachineImageResolutionSteps = useGetVirtualMachineImageResolutionSteps();

    const getWorkloadResourceOperatingSystemContextItem = useGetWorkloadResourceOperatingSystemContextItem();
    const getVirtualMachineImageWorkloadAnalysisRiskContext = useGetVirtualMachineImageWorkloadAnalysisRiskContext();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.workloadAnalysis.hooks.useWorkloadAnalysisDefinition.hooks.useVirtualMachineImageOperatingSystemEndOfLifeRiskDefinition",
            () => ({
                description: "{{riskedEntity}} is based on operating system {{operatingSystem}}, which is End-of-Life as of {{ operatingSystemEndOfLifeDate | TimeFormatter.shortDate}}",
                resolution: {
                    createVirtualMachineImage: "Create virtual machine image instance which is based on a newer version of the operating system"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            operatingSystem: risk.operatingSystemDisplayName,
            operatingSystemEndOfLifeDate: risk.operatingSystemEndOfLifeDate,
            riskedEntity: <Entity
                entityIdOrModel={risk.entityId}
                entityTypeNameTranslatorOptions={{ variant: "title" }}
                variant="typeText"/>
        }),
        () => getVirtualMachineImageResolutionSteps(localization.resolution.createVirtualMachineImage()),
        riskModel,
        () => {
            const translatedEntityTypeName =
                entityTypeNameTranslator(
                    riskModel.riskedEntityTypeName,
                    {
                        includeServiceName: false
                    });
            const virtualMachineImageWorkloadAnalysisRiskContext = getVirtualMachineImageWorkloadAnalysisRiskContext(risk);

            return [
                virtualMachineImageWorkloadAnalysisRiskContext.generalInformation,
                virtualMachineImageWorkloadAnalysisRiskContext.virtualMachineUsage,
                getWorkloadResourceOperatingSystemContextItem(
                    risk.operatingSystemDisplayName,
                    risk.operatingSystemEndOfLifeDate,
                    translatedEntityTypeName)
            ];
        });
}