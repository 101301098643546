import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, DeferredFilter, EnumValuesFilter, Optional, optionalTableCell, PagedValuesFilter, TimeFormatter, TimeRangeFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, tenantModelStore, TimeRangeHelper, useActivityTranslator, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAadDirectoryUserAuthenticationTypeTranslator, useAadDirectoryUserGuestUserInvitationStatusTranslator, useAadDirectoryUserTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { useAadCommonDirectoryPrincipalDefinition } from "./useAadCommonDirectoryPrincipalDefinition";

export function useAadDirectoryUserDefinition(definitionData: DefinitionData) {
    const identityModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.AadDirectoryIdentityModelFilters>;
    const activeCloudProviderTenantTypes = tenantModelStore.useGetActiveCloudProviderTenantTypes();
    const commonDirectoryPrincipalDefinition = useAadCommonDirectoryPrincipalDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();
    const activityTranslator = useActivityTranslator();
    const directoryUserAuthenticationTypeTranslator = useAadDirectoryUserAuthenticationTypeTranslator();
    const directoryUserGuestUserInvitationStatusTranslator = useAadDirectoryUserGuestUserInvitationStatusTranslator();
    const directoryUserTypeTranslator = useAadDirectoryUserTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aad.useAadDirectoryUserDefinition",
            () => ({
                columns: {
                    activityTime: {
                        helpText: "The last time a management write activity was seen in Azure for this identity, since read-only activity isn't logged in Azure.",
                        title: "Azure Activity Time"
                    },
                    adOrigin: {
                        false: "No",
                        title: "On-premises sync",
                        true: "Yes"
                    },
                    authenticationType: "Authentication Type",
                    awsRoles: "AWS Roles",
                    enabled: {
                        false: "Disabled",
                        title: "Status",
                        true: "Enabled"
                    },
                    groupIds: "Group Memberships",
                    guestUserInvitationStatus: "Invitation State",
                    location: "Location",
                    rawMail: "Mail",
                    signInTime: {
                        empty: "Not seen",
                        title: "Sign-in Time"
                    },
                    type: "User Type",
                    userPrincipalName: "User Principal Name"
                }
            }));

    function translateEnabled(userModel: Contract.EntityModel) {
        return (userModel.entity as Contract.AadDirectoryUser).enabled
            ? localization.columns.enabled.true()
            : localization.columns.enabled.false();
    }

    function translateSignInTime(userModel: Contract.EntityModel) {
        const userProfile = userModel.entityProfile as Contract.AadDirectoryUserProfile;
        return _.isNil(userProfile?.signInTime)
            ? localization.columns.signInTime.empty()
            : TimeFormatter.monthDayAndYear(userProfile.signInTime);
    }

    return new EntityTableDefinition(
        _.filter([
            commonDirectoryPrincipalDefinition.columns.tenantColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AadDirectoryUserModel) => ({
                            [localization.columns.rawMail()]: (item.entity as Contract.AadDirectoryUser).rawMail
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AadDirectoryUserRawMail)}
                                placeholder={localization.columns.rawMail()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AadDirectoryUserRawMail}
                key={Contract.EntityModelProperty.AadDirectoryUserRawMail}
                render={optionalTableCell<Contract.AadDirectoryUserModel>(item => (item.entity as Contract.AadDirectoryUser).rawMail)}
                title={localization.columns.rawMail()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AadDirectoryUserModel) => ({
                            [localization.columns.location()]: (item.entity as Contract.AadDirectoryUser).location ?? ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AadDirectoryUserLocation)}
                                placeholder={localization.columns.location()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AadDirectoryUserLocation}
                key={Contract.EntityModelProperty.AadDirectoryUserLocation}
                render={optionalTableCell<Contract.AadDirectoryUserModel>(userModel => (userModel.entity as Contract.AadDirectoryUser).location)}
                selectorOptions={{ default: false }}
                title={localization.columns.location()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AadDirectoryUserModel) => ({
                            [localization.columns.userPrincipalName()]: (item.entity as Contract.AadDirectoryUser).userPrincipalName
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AadDirectoryUserUserPrincipalName)}
                                placeholder={localization.columns.userPrincipalName()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AadDirectoryUserUserPrincipalName}
                key={Contract.EntityModelProperty.AadDirectoryUserUserPrincipalName}
                render={optionalTableCell<Contract.AadDirectoryUserModel>(userModel => (userModel.entity as Contract.AadDirectoryUser).userPrincipalName)}
                selectorOptions={{ default: false }}
                title={localization.columns.userPrincipalName()}/>,
            commonDirectoryPrincipalDefinition.columns.creationTimeColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AadDirectoryUserModel) => ({
                            [localization.columns.enabled.title()]: translateEnabled(item)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.enabled.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.enabled.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.enabled.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AadDirectoryIdentityEnabled}
                itemProperty={translateEnabled}
                key={Contract.EntityModelProperty.AadDirectoryIdentityEnabled}
                title={localization.columns.enabled.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AadDirectoryUserModel) => ({
                            [localization.columns.adOrigin.title()]:
                                (item.entity as Contract.AadDirectoryUser).adOrigin
                                    ? localization.columns.adOrigin.true()
                                    : localization.columns.adOrigin.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.adOrigin.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.adOrigin.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.adOrigin.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AadDirectoryUserAdOrigin}
                itemProperty={
                    (item: Contract.AadDirectoryUserModel) =>
                        (item.entity as Contract.AadDirectoryUser).adOrigin
                            ? localization.columns.adOrigin.true()
                            : localization.columns.adOrigin.false()}
                key={Contract.EntityModelProperty.AadDirectoryUserAdOrigin}
                title={localization.columns.adOrigin.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AadDirectoryUserModel) => ({
                            [localization.columns.type()]: directoryUserTypeTranslator((item.entity as Contract.AadDirectoryUser).type)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AadDirectoryUserType}
                                enumTypeTranslator={directoryUserTypeTranslator}
                                placeholder={localization.columns.type()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AadDirectoryUserType}
                itemProperty={(item: Contract.AadDirectoryUserModel) => directoryUserTypeTranslator((item.entity as Contract.AadDirectoryUser).type)}
                key={Contract.EntityModelProperty.AadDirectoryUserType}
                title={localization.columns.type()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AadDirectoryUserModel) => ({
                            [localization.columns.authenticationType()]: directoryUserAuthenticationTypeTranslator((item.entity as Contract.AadDirectoryUser).authenticationType)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AadDirectoryUserAuthenticationType}
                                enumTypeTranslator={directoryUserAuthenticationTypeTranslator}
                                placeholder={localization.columns.authenticationType()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AadDirectoryUserAuthenticationType}
                itemProperty={(item: Contract.AadDirectoryUserModel) => directoryUserAuthenticationTypeTranslator((item.entity as Contract.AadDirectoryUser).authenticationType)}
                key={Contract.EntityModelProperty.AadDirectoryUserAuthenticationType}
                title={localization.columns.authenticationType()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AadDirectoryUserModel) => {
                            const user = item.entity as Contract.AadDirectoryUser;
                            return ({
                                [localization.columns.guestUserInvitationStatus()]:
                                    _.isNil(user.guestUserInvitationStatus)
                                        ? ""
                                        : directoryUserGuestUserInvitationStatusTranslator(user.guestUserInvitationStatus)
                            });
                        }
                }}
                id="guestUserInvitationStatus"
                key="guestUserInvitationStatus"/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AadDirectoryUserModel) => ({
                            [localization.columns.signInTime.title()]:
                                _.isNil((item.entityProfile as Contract.AadDirectoryUserProfile))
                                    ? localization.columns.signInTime.empty()
                                    : TimeFormatter.iso8601String((item.entityProfile as Contract.AadDirectoryUserProfile).signInTime)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={identityModelFiltersPromise}
                                title={localization.columns.signInTime.title()}>
                                {filters =>
                                    <TimeRangeFilter
                                        emptyValue={filters.signInTimeRange.emptyValue}
                                        placeholder={localization.columns.signInTime.title()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.signInTimeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AadDirectoryIdentitySignInTime}
                itemProperty={translateSignInTime}
                key={Contract.EntityModelProperty.AadDirectoryIdentitySignInTime}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.signInTime.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AadDirectoryUserModel) => ({
                            [localization.columns.activityTime.title()]: activityTranslator(item.access?.activity) ?? ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={identityModelFiltersPromise}
                                title={localization.columns.activityTime.title()}>
                                {filters =>
                                    <TimeRangeFilter
                                        emptyValue={filters.activityTimeRange.emptyValue}
                                        placeholder={localization.columns.activityTime.title()}
                                        timeRange={TimeRangeHelper.getTimeRangeFilterRange(filters.activityTimeRange.timeRange)}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AadDirectoryIdentityActivityTime}
                key={Contract.EntityModelProperty.AadDirectoryIdentityActivityTime}
                message={localization.columns.activityTime.helpText()}
                messageLevel="info"
                render={
                    optionalTableCell<Contract.AadDirectoryUserModel>(
                        userModel =>
                            activityTranslator(userModel.access!.activity))}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.activityTime.title()}/>,
            _.includes(activeCloudProviderTenantTypes, Contract.TenantType.Aws)
                ? <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AadDirectoryUserModel>(
                            Contract.TypeNames.AwsIamRole,
                            item => item.awsRoleIds,
                            localization.columns.awsRoles())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AadDirectoryUserAwsRoles)}
                                    placeholder={localization.columns.awsRoles()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AadDirectoryUserAwsRoles}
                    key={Contract.EntityModelProperty.AadDirectoryUserAwsRoles}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AadDirectoryUserModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.awsRoleIds}
                                entityTypeName={Contract.TypeNames.AwsIamRole}
                                entityVariant="iconText"/>}
                    title={localization.columns.awsRoles()}/>
                : undefined!,
            commonDirectoryPrincipalDefinition.columns.azureRoleAssignmentResourcesColumn,
            commonDirectoryPrincipalDefinition.columns.azureRoleDefinitionsColumn,
            commonDirectoryPrincipalDefinition.columns.containedAzureRoleAssignmentResourcesColumn,
            commonDirectoryPrincipalDefinition.columns.directoryRoleAssignmentResourcesColumn,
            commonDirectoryPrincipalDefinition.columns.directoryRoleDefinitionsColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AadDirectoryUserModel>(
                        Contract.TypeNames.AadDirectoryGroup,
                        item => item.groupIds,
                        localization.columns.groupIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AadDirectoryUserGroups)}
                                placeholder={localization.columns.groupIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AadDirectoryUserGroups}
                key={Contract.EntityModelProperty.AadDirectoryUserGroups}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AadDirectoryPrincipalModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.groupIds}
                            entityTypeName={Contract.TypeNames.AadDirectoryGroup}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.groupIds()}/>,
            commonDirectoryPrincipalDefinition.columns.attributesColumn,
            commonDirectoryPrincipalDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonDirectoryPrincipalDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonDirectoryPrincipalDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAadDirectoryUserRequest(
                new Contract.EntityControllerGetEntityModelPageAadDirectoryUserRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.AadDirectoryEntityCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryPrincipalAzureRoleDefinitions]),
                    filterMap[Contract.EntityModelProperty.AadDirectoryPrincipalContainedAzureRoleAssignmentResources] as Optional<boolean>,
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryPrincipalDirectoryRoleDefinitions]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.AadDirectoryIdentityActivityTime]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryIdentityEnabled]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.AadDirectoryIdentitySignInTime]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryUserAdOrigin]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryUserAuthenticationType]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryUserAwsRoles]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryUserGroups]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryUserLocation]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryUserRawMail]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryUserType]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AadDirectoryUserUserPrincipalName])),
                limit,
                skip,
                requestSort));
}