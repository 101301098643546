import { IpAddressHelper, Optional, useLocalization } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { useAzureMySqlServerAuthenticationTypeTranslator, useAzureMySqlServerStatusTranslator } from "../../../../../../../../../Entities/hooks";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetNetworkAccessResourceRiskContext } from "../../../useGetNetworkAccessResourceRiskContext";
import { useGetAzureResourceRiskContext } from "./useGetAzureResourceRiskContext";

export function useGetAzureMySqlServerRiskContext() {
    return useMemo(
        () => useAzureMySqlServerRiskContext,
        []);
}

function useAzureMySqlServerRiskContext(serverModel: Contract.AzureMySqlServerModel) {
    const server = serverModel.entity as Contract.AzureMySqlServer;
    const resourceRiskContext = useGetAzureResourceRiskContext()(serverModel);
    const networkAccessResourceRiskContext = useGetNetworkAccessResourceRiskContext()(serverModel);
    const serverNetwork = serverModel.entityNetwork as Optional<Contract.AzureDatabaseResourceStateNetwork>;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const mySqlServerAuthenticationTypeTranslator = useAzureMySqlServerAuthenticationTypeTranslator();
    const mySqlServerStatusTranslator = useAzureMySqlServerStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.contexts.useGetAzureMySqlServerRiskContext",
            () => ({
                allowAnyAzureInternalIpAddress: {
                    [Contract.TypeNames.AzureMySqlFlexibleServer]: "**Allow public access from any Azure service within Azure to this server** is enabled allowing unrestricted network inbound access to your database server from all Azure resources, including resources from **any** subscription",
                    [Contract.TypeNames.AzureMySqlSingleServer]: "**Allow access to Azure services** is enabled allowing unrestricted network inbound access to your database server from all Azure resources, including resources from **any** subscription"
                },
                authenticationType: "The {{translatedServerTypeName}} is using **{{authenticationType}}** authentication",
                ipAddressRange: "IP address range {{startIpAddress}}-{{endIpAddress}} is open allowing access to {{ipAddressCount | NumberFormatter.unit}} IP addresses",
                status: "The {{translatedServerTypeName}}'s status is **{{status}}**"
            }));
    const translatedServerTypeName =
        entityTypeNameTranslator(
            server.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });
    return {
        ...resourceRiskContext,
        ...networkAccessResourceRiskContext,
        allowAnyAzureInternalIpAddress:
            server.allowAnyAzureInternalIpAddress
                ? new RiskDefinitionContextItem(localization.allowAnyAzureInternalIpAddress[server.typeName as Contract.TypeNames.AzureMySqlFlexibleServer | Contract.TypeNames.AzureMySqlSingleServer]())
                : undefined,
        authenticationType:
            new RiskDefinitionContextItem(
                localization.authenticationType({
                    authenticationType: mySqlServerAuthenticationTypeTranslator(server.authenticationType),
                    translatedServerTypeName
                })),
        firewallRuleRiskContext:
            _.isNil(serverNetwork)
                ? []
                : _.map(
                    serverNetwork.wideRangeFirewallIpAddressRangeRules,
                    wideRangeFirewallIpAddressRangeRule =>
                        new RiskDefinitionContextItem(
                            localization.ipAddressRange({
                                endIpAddress: wideRangeFirewallIpAddressRangeRule.endIpAddress,
                                ipAddressCount:
                                    IpAddressHelper.getIpAddressCount(
                                        wideRangeFirewallIpAddressRangeRule.startIpAddress,
                                        wideRangeFirewallIpAddressRangeRule.endIpAddress),
                                startIpAddress: wideRangeFirewallIpAddressRangeRule.startIpAddress
                            }))),
        status:
            new RiskDefinitionContextItem(
                localization.status({
                    status: mySqlServerStatusTranslator(server.status),
                    translatedServerTypeName
                }))
    };
}