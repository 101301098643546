import { NotValidIcon, SuccessIcon, Sx } from "@infrastructure";
import type { SxProps } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, useTheme } from "..";

type TenantModelStatusSeverityIconProps = {
    statusSeverity?: Contract.Severity;
    sx?: SxProps;
};

export function TenantModelStatusSeverityIcon({ statusSeverity, sx }: TenantModelStatusSeverityIconProps) {
    const theme = useTheme();
    return (
        _.isNil(statusSeverity)
            ? <SuccessIcon sx={Sx.combine(sx, { color: theme.palette.success.main })}/>
            : <NotValidIcon sx={Sx.combine(sx, { color: theme.palette.severity(statusSeverity) })}/>);
}