import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import React from "react";

export function NextIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon
            sx={{
                width: theme.spacing(1.5),
                ...props?.sx
            }}
            viewBox="0 0 12 24"
            {...props}>
            <path
                clipRule="evenodd"
                d="M1.03444 18.1114C0.543677 18.5969 0.539433 19.3884 1.02496 19.8792C1.51048 20.3699 2.30194 20.3741 2.79271 19.8886L10.7774 12L2.7927 4.11141C2.30194 3.62588 1.51049 3.63013 1.02496 4.12089C0.539433 4.61166 0.543827 5.40325 1.03459 5.88878L7.2222 12L1.03444 18.1114Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}