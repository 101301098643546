﻿import { DataTableColumn, EnumValuesFilter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../..";
import { Contract, ItemSelectionHelper, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useGcpComputeLoadBalancerTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { useCommonNetworkAccessResourceDefinition } from "../useCommonNetworkAccessResourceDefinition";
import { useGcpCommonEntityDefinition } from "./useGcpCommonEntityDefinition";

export function useGcpComputeLoadBalancerDefinition(definitionData: DefinitionData) {
    const commonEntityDefinition = useGcpCommonEntityDefinition(definitionData);
    const commonNetworkAccessResourceDefinition = useCommonNetworkAccessResourceDefinition();
    const entitiesExportOptions = useEntitiesExportOptions();
    const loadBalancerModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.GcpComputeLoadBalancerModelFilters>;

    const computeLoadBalancerTypeTranslator = useGcpComputeLoadBalancerTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpComputeLoadBalancerDefinition",
            () => ({
                columns: {
                    creatorIdentity: "Created By Identity",
                    creatorOriginatorEntity: "Created By Originator",
                    forwardingRuleIpAddresses: "Address",
                    location: "Location",
                    type: "Load Balancer Type"
                }
            }));

    return new EntityTableDefinition(
        [
            commonEntityDefinition.columns.tenantColumn,
            commonEntityDefinition.columns.creationTimeColumn,
            commonNetworkAccessResourceDefinition.columns.accessLevelColumn,
            commonNetworkAccessResourceDefinition.columns.inboundExternalAccessScopeColumn,
            commonNetworkAccessResourceDefinition.columns.destinationNetworkScopesColumn(loadBalancerModelFiltersPromise),
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpComputeLoadBalancerModel>(
                        Contract.TypeNames.GciDirectoryPrincipal,
                        item => [item.creatorIdentityIdReference],
                        localization.columns.creatorIdentity())}
                id="creatorIdentity"
                key="creatorIdentity"/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpComputeLoadBalancerModel>(
                        Contract.TypeNames.GciDirectoryPrincipal,
                        item => [item.creatorOriginatorEntityIdReference],
                        localization.columns.creatorOriginatorEntity())}
                id="creatorOriginatorEntity"
                key="creatorOriginatorEntity"/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpComputeLoadBalancerModel) => ({
                            [localization.columns.type()]: computeLoadBalancerTypeTranslator((item.entity as Contract.GcpComputeLoadBalancer).type)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.GcpComputeLoadBalancerType}
                                enumTypeTranslator={computeLoadBalancerTypeTranslator}
                                placeholder={localization.columns.type()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeLoadBalancerType}
                itemProperty={(item: Contract.EntityModel) => computeLoadBalancerTypeTranslator((item.entity as Contract.GcpComputeLoadBalancer).type)}
                key={Contract.EntityModelProperty.GcpComputeLoadBalancerType}
                title={localization.columns.type()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpComputeLoadBalancerModel>(
                        Contract.TypeNames.GcpComputeLoadBalancer,
                        item => (item.entity as Contract.GcpComputeLoadBalancer).resources.forwardingRuleIds,
                        localization.columns.forwardingRuleIpAddresses(),
                        { getValue: entityModel => (entityModel.entity as Contract.GcpComputeForwardingRule).ipAddress })
                }
                id="forwardingRuleIpAddresses"
                key="forwardingRuleIpAddresses"/>,
            commonEntityDefinition.columns.regionColumn,
            commonEntityDefinition.columns.regionLocationColumn,
            commonEntityDefinition.columns.attributesColumn,
            commonEntityDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonEntityDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonEntityDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        loadBalancerModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpComputeLoadBalancerRequest(
                new Contract.EntityControllerGetEntityModelPageGcpComputeLoadBalancerRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeLoadBalancerType])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestNetworkAccess(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundAccessType]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalAccessScope]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalDestinationNetworkScopes]))));
}