import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { DeferredFilter, Filter, Optional, PagedValuesFilterValuePage, useChangeEffect, useLocalization, useOperation, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Contract, PagedEntityFilter, PagedEntityFilterEntityIdPage, RiskController, ScopeTenantFilter, useTenantTypeTranslator } from "../../../../../../../../../common";
import { RisksFilterItemPageData, RisksItemsFilterId } from "../../../Filters";
import { useCommonFilters } from "./useCommonFilters";

export function useCodeItems(risksView: Contract.RisksView) {
    const [viewFiltersPromise, executeGetViewFilters] =
        useOperation<Contract.CodeRiskFilters>(
            [useCodeItems, risksView],
            async () => {
                const { filters } = await RiskController.getRiskFilters(new Contract.RiskControllerGetCodeRiskFiltersRequest(risksView));
                return filters as Contract.CodeRiskFilters;
            });
    const commonFilters =
        useCommonFilters(
            risksView,
            viewFiltersPromise);

    const getRiskFilterItemPage =
        useCallback(
            async (type: Contract.CodeRiskFilterItemType, searchText: Optional<string>, skip: number, limit: number, data: RisksFilterItemPageData) => {
                const { riskFilterItemPage } =
                    await RiskController.getRiskFilterItemPage(
                        new Contract.RiskControllerGetCodeRiskFilterItemPageRequest(
                            limit,
                            searchText,
                            skip,
                            risksView!,
                            type)) as Contract.RiskControllerGetRiskFilterItemPageResponse<any>;

                const riskFilterItemCount = riskFilterItemPage.count ?? data.count;

                return new PagedValuesFilterValuePage(
                    riskFilterItemCount,
                    riskFilterItemPage.emptyValue,
                    riskFilterItemPage.items,
                    () => ({
                        count: riskFilterItemCount,
                        itemNextPageSearchCursor: riskFilterItemPage.itemNextPageSearchCursor
                    }));
            },
            []);

    useChangeEffect(
        () => {
            executeGetViewFilters();
        },
        [risksView]);

    const tenantTypeTranslator = useTenantTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.filters.hooks.useItems.hooks.useCodeItems",
            () => ({
                filters: {
                    cloudTenant: "Platform",
                    filePath: "File",
                    resourceName: "Resource Name",
                    resourceTypeSystemName: "Resource Type",
                    tenant: "Repository"
                }
            }));

    return useMemo(
        () =>
            _.filter([
                commonFilters.statusUpdateTime,
                commonFilters.severity,
                commonFilters.policy,
                commonFilters.category,
                <Filter
                    default={true}
                    id={RisksItemsFilterId[Contract.RiskFilterId.TenantId]}
                    key={RisksItemsFilterId[Contract.RiskFilterId.TenantId]}
                    title={localization.filters.tenant()}>
                    <DeferredFilter
                        promiseOrGetPromise={viewFiltersPromise}
                        title={localization.filters.tenant()}>
                        {filters =>
                            <ScopeTenantFilter
                                placeholder={localization.filters.tenant()}
                                tenantIds={filters.tenantIdItems.items}/>}
                    </DeferredFilter>
                </Filter>,
                <Filter
                    default={true}
                    id={RisksItemsFilterId[Contract.RiskFilterId.CodeRiskCloudTenantType]}
                    key={RisksItemsFilterId[Contract.RiskFilterId.CodeRiskCloudTenantType]}
                    title={localization.filters.cloudTenant()}>
                    <DeferredFilter
                        promiseOrGetPromise={viewFiltersPromise}
                        title={localization.filters.cloudTenant()}>
                        {filters =>
                            <ValuesFilter
                                emptyValueOptions={{ enabled: filters.cloudTenantTypeItems.emptyValue }}
                                placeholder={localization.filters.cloudTenant()}>
                                {_.map(
                                    filters.cloudTenantTypeItems.items,
                                    cloudTenantType =>
                                        <ValuesFilterItem
                                            key={cloudTenantType}
                                            value={tenantTypeTranslator(cloudTenantType)}/>)}
                            </ValuesFilter>}
                    </DeferredFilter>
                </Filter>,
                <Filter
                    default={true}
                    id={RisksItemsFilterId[Contract.RiskFilterId.CodeRiskResourceName]}
                    key={RisksItemsFilterId[Contract.RiskFilterId.CodeRiskResourceName]}
                    title={localization.filters.resourceName()}>
                    <PagedEntityFilter
                        getEntityIdPage={
                            async (searchText, skip, limit, data) => {
                                const { applyData, count, emptyValue, values } =
                                    await getRiskFilterItemPage(
                                        Contract.CodeRiskFilterItemType.ResourceName,
                                        searchText,
                                        skip,
                                        limit,
                                        data);
                                return new PagedEntityFilterEntityIdPage(
                                    count,
                                    emptyValue,
                                    values,
                                    applyData);
                            }}
                        placeholder={localization.filters.resourceName()}/>
                </Filter>,
                <Filter
                    default={true}
                    id={RisksItemsFilterId[Contract.RiskFilterId.CodeRiskResourceTypeSystemName]}
                    key={RisksItemsFilterId[Contract.RiskFilterId.CodeRiskResourceTypeSystemName]}
                    title={localization.filters.resourceTypeSystemName()}>
                    <PagedEntityFilter
                        getEntityIdPage={
                            async (searchText, skip, limit, data) => {
                                const { applyData, count, emptyValue, values } =
                                    await getRiskFilterItemPage(
                                        Contract.CodeRiskFilterItemType.ResourceTypeSystemName,
                                        searchText,
                                        skip,
                                        limit,
                                        data);
                                return new PagedEntityFilterEntityIdPage(
                                    count,
                                    emptyValue,
                                    values,
                                    applyData);
                            }}
                        placeholder={localization.filters.resourceTypeSystemName()}/>
                </Filter>,
                <Filter
                    default={true}
                    id={RisksItemsFilterId[Contract.RiskFilterId.CodeRiskFileRelativePath]}
                    key={RisksItemsFilterId[Contract.RiskFilterId.CodeRiskFileRelativePath]}
                    title={localization.filters.filePath()}>
                    <PagedEntityFilter
                        getEntityIdPage={
                            async (searchText, skip, limit, data) => {
                                const { applyData, count, emptyValue, values } =
                                    await getRiskFilterItemPage(
                                        Contract.CodeRiskFilterItemType.FileRelativePath,
                                        searchText,
                                        skip,
                                        limit,
                                        data);
                                return new PagedEntityFilterEntityIdPage(
                                    count,
                                    emptyValue,
                                    values,
                                    applyData);
                            }}
                        placeholder={localization.filters.filePath()}/>
                </Filter>,
                commonFilters.openStatusUpdateTime,
                commonFilters.systemCreationTime,
                commonFilters.compliance,
                commonFilters.ticketingServiceType,
                commonFilters.starred
            ]),
        [commonFilters, viewFiltersPromise, getRiskFilterItemPage]);
}