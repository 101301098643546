import { EmptyMessageText, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../../..";
import { Contract, InfoItem, tenantModelStore, usePrincipalModelAccess } from "../../../../../../../../../../../common";
import { AwsTenantHelper } from "../../../../../../../../../../../tenants";
import { EntitiesInfoCard, Info, PrincipalSecurityStatsStat } from "../../../../../components";
import { AwsIamPolicyDocument } from "../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { useAwsDefaultResourceInfoItemElements } from "../useAwsDefaultResourceInfoItemElements";
import { PrincipalAssignments } from "./components";

export function useAwsSsoPermissionSetDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const permissionSetModel = (resourceModel as Contract.AwsSsoPermissionSetModel);
    const permissionSet = (permissionSetModel.entity as Contract.AwsSsoPermissionSet);
    const permissionSetModelAccess = usePrincipalModelAccess<Contract.PrincipalModelAccess>(permissionSetModel.id);
    const tenantModelMap = tenantModelStore.useGetAwsTenantMap();

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsSsoPermissionSetDefinition",
            () => ({
                info: {
                    cards: {
                        awsManagedPolicyIds: {
                            emptyMessage: "No attached managed policies",
                            title: "Managed Policies"
                        }
                    },
                    items: {
                        description: "Description",
                        relayStateRawUrl: "Relay State",
                        sessionDuration: "Session Duration"
                    }
                },
                tabs: {
                    assignments: "Assignments",
                    inlinePolicyDocument: "Inline Policy"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AwsIamPolicyDocument
                    policyDocument={permissionSet.inlinePolicyDocument}
                    variant="inlinePolicy"/>,
                localization.tabs.inlinePolicyDocument(),
                "inlinePolicyDocument"),
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <PrincipalAssignments permissionSetModel={permissionSetModel}/>,
                localization.tabs.assignments(),
                "assignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="description"
                        title={localization.info.items.description()}
                        value={permissionSet.description}/>,
                    <InfoItem
                        key="relayStateRawUrl"
                        title={localization.info.items.relayStateRawUrl()}
                        value={permissionSet.relayStateRawUrl}/>,
                    <InfoItem
                        key="sessionDuration"
                        title={localization.info.items.sessionDuration()}
                        value={permissionSet.sessionDuration}/>
                ]}
                options={options?.infoOptions}>
                <EntitiesInfoCard
                    emptyMessage={new EmptyMessageText(localization.info.cards.awsManagedPolicyIds.emptyMessage())}
                    entityIdsOrModels={permissionSet.awsManagedPolicyIds}
                    entityTypeName={Contract.TypeNames.AwsIamManagedPolicy}
                    title={localization.info.cards.awsManagedPolicyIds.title()}/>
            </Info>,
        sensitive: false,
        statElements:
            _.filter([
                AwsTenantHelper.isAccessAdvisorEnabled(resourceModel.entity, tenantModelMap) &&
                !_.isNil(permissionSetModelAccess?.permissions)
                    ? <PrincipalSecurityStatsStat
                        analyzingReason={permissionSetModel.analyzingReason}
                        securityStats={permissionSetModelAccess.permissions!.excessivePermissionActionStats}
                        typeName={Contract.TypeNames.AwsSsoPermissionSet}/>
                    : undefined
            ])
    });
}