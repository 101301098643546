import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../../../common";

export function usePermissionRequestAuditEventTypeNameTranslator() {
    const localization =
        useLocalization(
            "views.user.auditEvents.permissionRequestAuditEvents.hooks.usePermissionRequestAuditEventTypeNameTranslator",
            () => ({
                [Contract.TypeNames.PermissionRequestAuditEvent]: {
                    [Contract.PermissionRequestAuditEventDerivedTypeNames.PermissionRequestActivationAuditEvent]: "Access granted",
                    [Contract.PermissionRequestAuditEventDerivedTypeNames.PermissionRequestActivationFailureAuditEvent]: "Access request activation failed",
                    [Contract.PermissionRequestAuditEventDerivedTypeNames.PermissionRequestApprovalAuditEvent]: "Access request approved",
                    [Contract.PermissionRequestAuditEventDerivedTypeNames.PermissionRequestCancellationAuditEvent]: "Access request cancelled",
                    [Contract.PermissionRequestAuditEventDerivedTypeNames.PermissionRequestCreationAuditEvent]: "Access requested",
                    [Contract.PermissionRequestAuditEventDerivedTypeNames.PermissionRequestExpirationAuditEvent]: "Access expired",
                    [Contract.PermissionRequestAuditEventDerivedTypeNames.PermissionRequestRejectionAuditEvent]: "Access request denied",
                    [Contract.PermissionRequestAuditEventDerivedTypeNames.PermissionRequestRequestExpirationAuditEvent]: "Access request timed-out",
                    [Contract.PermissionRequestAuditEventDerivedTypeNames.PermissionRequestRevocationAuditEvent]: "Access revoked"
                }
            }));

    return (auditEventTypeName: string) => localization[Contract.TypeNames.PermissionRequestAuditEvent][auditEventTypeName as Contract.PermissionRequestAuditEventDerivedTypeNames]();
}