import { Store } from "@infrastructure";
import { AuditEventController, Contract } from "..";

export const auditEventModelStore =
    new Store<Contract.AuditEventModel, never, never>(
        auditEventModel => auditEventModel.auditEvent.id,
        {
            get:
                async ids => {
                    const { auditEventModels } = await AuditEventController.getAuditEventModels(new Contract.AuditEventControllerGetAuditEventModelsRequest(ids));
                    return auditEventModels;
                }
        });