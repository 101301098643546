import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, DeferredFilter, EnumValuesFilter, InlineItems, optionalTableCell, StringHelper, useLocalization, useOperation, ValueFilter, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React, { useCallback } from "react";
import { Contract, Entity, EntityTypeFilter, NetworkAccessScopeCell, NetworkAccessScopeFilter, NetworkScopeFormatter, PagedEntityFilter, Severity, SeverityFilter, VulnerabilityResolutionVersion, WorkloadController } from "../../../../../common";
import { WorkloadAnalysisScanStatusCell } from "../components";
import { ExploitableValueFilter } from "../components/ExploitableValueFilter";
import { useAttackVectorTranslator } from "./useAttackVectorTranslator";
import { useGetWorkloadResourceScanEntityFilterItemPage } from "./useGetWorkloadResourceScanEntityFilterItemPage";
import { useWorkloadAnalysisScanStatusTranslator } from "./useWorkloadAnalysisScanStatusTranslator";
import { useAttributesDataTableColumn, useNetworkInboundAccessTypeDataTableColumn, useOperatingSystemDataTableColumn, useOperatingSystemTypeDataTableColumn, usePackageDisplayNameDataTableColumn, useResourceTagsDataTableColumn, useRiskSeverityDataTableColumn, useScanTimeDataTableColumn, useTenantIdDataTableColumn, useVulnerabilitiesDataTableColumn } from "./WorkloadScanDataTableColumns";

export function useCommonVirtualMachineDefinition(vulnerabilityRawId?: string) {
    const attackVectorTranslator = useAttackVectorTranslator();
    const workloadAnalysisScanStatusTranslator = useWorkloadAnalysisScanStatusTranslator();

    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.hooks.useCommonVirtualMachineDefinition",
            () => ({
                columns: {
                    attributes: "Labels",
                    cloud: "Cloud",
                    exploitable: {
                        false: "No",
                        title: "Known Exploit",
                        true: "Yes"
                    },
                    fixableVulnerability: "With Fix Only",
                    identityPermissionActionSeverity: "Identity Risk",
                    inboundExternalDestinationNetworkScopes: "Exposed Ports",
                    kubernetesClusterSensor: {
                        false: "Agentless",
                        title: "Scan Source",
                        true: "Agent"
                    },
                    missingPatches: "Missing Patches",
                    networkInboundAccessType: "Network Exposure",
                    networkInboundExternalAccessScope: "Network Exposure Scope",
                    operatingSystem: "Operating System",
                    operatingSystemType: "Operating System Type",
                    packages: "Software",
                    privateIpAddresses: "Private IP Addresses",
                    publicIpAddresses: "Public IP Addresses",
                    risks: "Findings",
                    risksSeverity: "Findings Severity",
                    scanStatus: "Scan Status",
                    scanTime: "Scan Time",
                    status: {
                        title: "State",
                        [Contract.TypeNames.WorkloadResourceScanModelVirtualMachineStatus]: {
                            [Contract.WorkloadResourceScanModelVirtualMachineStatus.Running]: "Running",
                            [Contract.WorkloadResourceScanModelVirtualMachineStatus.Stopped]: "Stopped"
                        }
                    },
                    tags: "Tags",
                    tenantId: "Account",
                    virtualMachineId: {
                        id: "ID",
                        title: "Name",
                        typeName: "Type"
                    },
                    vulnerabilities: "Vulnerabilities",
                    vulnerabilityAttackVector: "Vulnerability Attack Vector",
                    vulnerabilitySeverityChart: "Vulnerability Severity",
                    workloadResourceTypeName: "Type"
                },
                empty: {
                    packages: {
                        withFilter: "No Matching Software",
                        withoutFilter: "No Software"
                    }
                }
            }));

    const [filtersPromise] =
        useOperation(
            useCommonVirtualMachineDefinition,
            useCallback(
                async () => {
                    const { filters } = await WorkloadController.getVirtualMachineScanFilters(new Contract.WorkloadControllerGetWorkloadResourceScanFiltersRequest(vulnerabilityRawId));
                    return filters;
                },
                []));

    return {
        columnNames: localization.columns,
        columns: {
            attributes:
                useAttributesDataTableColumn(
                    filtersPromise,
                    localization.columns,
                    vulnerabilityRawId),
            fixableVulnerability:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems:
                            {
                                default: true,
                                element:
                                    <ValueFilter
                                        items={[
                                            {
                                                title: localization.columns.fixableVulnerability(),
                                                value: true
                                            }
                                        ]}
                                        placeholder={localization.columns.fixableVulnerability()}/>
                            }
                    }}
                    id={Contract.WorkloadControllerRequestProperty.FixableVulnerability}
                    key={Contract.WorkloadControllerRequestProperty.FixableVulnerability}
                    selectorOptions={{ default: !vulnerabilityRawId }}
                    title={localization.columns.fixableVulnerability()}/>,
            identityPermissionActionSeverity:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <SeverityFilter placeholder={localization.columns.identityPermissionActionSeverity()}/>
                        }
                    }}
                    id={Contract.WorkloadControllerRequestProperty.IdentityPermissionActionSeverity}
                    key={Contract.WorkloadControllerRequestProperty.IdentityPermissionActionSeverity}
                    render={
                        ({ item: workloadResourceModel }: DataTableColumnRenderProps<Contract.WorkloadResourceVirtualMachineModel>) =>
                            <Severity severity={workloadResourceModel.identityPermissionActionSeverity}/>}
                    selectorOptions={{ default: !vulnerabilityRawId }}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.identityPermissionActionSeverity()}/>,
            inboundExternalDestinationNetworkScopes:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: !vulnerabilityRawId,
                            element: <DeferredFilter
                                promiseOrGetPromise={filtersPromise}
                                title={localization.columns.inboundExternalDestinationNetworkScopes()}>
                                {
                                    filters =>
                                        <ValuesFilter
                                            emptyValueOptions={{ enabled: filters.destinationNetworkScopeDataItems?.emptyValue }}
                                            placeholder={localization.columns.inboundExternalDestinationNetworkScopes()}>
                                            {_.map(
                                                filters.destinationNetworkScopeDataItems!.items,
                                                destinationNetworkScopeData =>
                                                    <ValuesFilterItem
                                                        key={destinationNetworkScopeData.filterId}
                                                        title={NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(destinationNetworkScopeData.destinationNetworkScope)}
                                                        value={destinationNetworkScopeData.filterId}/>)}
                                        </ValuesFilter>
                                }
                            </DeferredFilter>
                        }
                    }}
                    id={Contract.WorkloadControllerRequestProperty.InboundExternalDestinationNetworkScopes}
                    render={optionalTableCell((item: Contract.WorkloadResourceVirtualMachineModel) =>
                        _.size(item.inboundExternalDestinationNetworkScopes) > 0 &&
                        <InlineItems
                            items={_.map(
                                item.inboundExternalDestinationNetworkScopes,
                                NetworkScopeFormatter.networkScopeFromDestinationNetworkScope)}
                            variant="itemPlusItemCount"/>)}
                    selectorOptions={{ default: !vulnerabilityRawId }}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.inboundExternalDestinationNetworkScopes()}/>,
            kubernetesClusterSensor:
                <DataTableColumn
                    filterOptions={
                        {
                            itemOrItems: {
                                element:
                                    <ValuesFilter
                                        placeholder={localization.columns.kubernetesClusterSensor.title()}
                                        sorted={false}>
                                        <ValuesFilterItem
                                            title={localization.columns.kubernetesClusterSensor.true()}
                                            value={true}/>
                                        <ValuesFilterItem
                                            title={localization.columns.kubernetesClusterSensor.false()}
                                            value={false}/>
                                    </ValuesFilter>
                            }
                        }
                    }
                    id={Contract.WorkloadControllerRequestProperty.KubernetesClusterSensor}
                    itemProperty={(virtualMachineModel: Contract.WorkloadResourceVirtualMachineModel) =>
                        virtualMachineModel.kubernetesClusterSensor
                            ? localization.columns.kubernetesClusterSensor.true()
                            : localization.columns.kubernetesClusterSensor.false()}
                    key={Contract.WorkloadControllerRequestProperty.KubernetesClusterSensor}
                    selectorOptions={{ default: false }}
                    title={localization.columns.kubernetesClusterSensor.title()}/>,
            networkInboundAccessType:
                useNetworkInboundAccessTypeDataTableColumn(
                    localization.columns,
                    vulnerabilityRawId),
            networkInboundExternalAccessScope:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: !vulnerabilityRawId,
                            element:
                                <NetworkAccessScopeFilter placeholder={localization.columns.networkInboundExternalAccessScope()}/>
                        }
                    }}
                    id={Contract.WorkloadControllerRequestProperty.NetworkInboundExternalAccessScope}
                    key={Contract.WorkloadControllerRequestProperty.NetworkInboundExternalAccessScope}
                    render={
                        ({ item: workloadResourceModel }: DataTableColumnRenderProps<Contract.WorkloadResourceVirtualMachineModel>) =>
                            <NetworkAccessScopeCell
                                networkAccessScope={workloadResourceModel.networkInboundExternalAccessScope}/>}
                    selectorOptions={{ default: !vulnerabilityRawId }}
                    title={localization.columns.networkInboundExternalAccessScope()}/>,
            operatingSystemDisplayName:
                useOperatingSystemDataTableColumn(
                    localization.columns,
                    Contract.WorkloadAnalysisWorkloadResourceType.VirtualMachine,
                    vulnerabilityRawId),
            operatingSystemType:
                useOperatingSystemTypeDataTableColumn(
                    localization.columns,
                    vulnerabilityRawId),
            packageDisplayName:
                usePackageDisplayNameDataTableColumn(
                    localization,
                    Contract.WorkloadAnalysisWorkloadResourceType.VirtualMachine,
                    vulnerabilityRawId),
            resourceTags:
                useResourceTagsDataTableColumn(
                    localization.columns,
                    Contract.WorkloadAnalysisWorkloadResourceType.VirtualMachine,
                    undefined,
                    vulnerabilityRawId
                ),
            risksSeverity: useRiskSeverityDataTableColumn(localization.columns, vulnerabilityRawId),
            scanTime:
                useScanTimeDataTableColumn(
                    filtersPromise,
                    localization.columns,
                    vulnerabilityRawId),
            tenantId:
                useTenantIdDataTableColumn(
                    filtersPromise,
                    localization.columns,
                    vulnerabilityRawId),
            virtualMachineId:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: !!vulnerabilityRawId,
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        useGetWorkloadResourceScanEntityFilterItemPage(
                                            Contract.WorkloadControllerRequestProperty.VirtualMachineId,
                                            Contract.WorkloadAnalysisWorkloadResourceType.VirtualMachine,
                                            vulnerabilityRawId)}
                                    placeholder={localization.columns.virtualMachineId.title()}/>
                        }
                    }}
                    id={Contract.WorkloadControllerRequestProperty.VirtualMachineId}
                    key={Contract.WorkloadControllerRequestProperty.VirtualMachineId}
                    render={
                        ({ item: workloadResourceModel }: DataTableColumnRenderProps<Contract.WorkloadResourceModel>) =>
                            <Entity
                                entityIdOrModel={workloadResourceModel.id}
                                variant="iconText"/>}
                    selectorOptions={{ disabled: true }}
                    title={localization.columns.virtualMachineId.title()}/>,
            virtualMachineStatus:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element: <EnumValuesFilter
                                enumType={Contract.WorkloadResourceScanModelVirtualMachineStatus}
                                enumTypeTranslator={(status: Contract.WorkloadResourceScanModelVirtualMachineStatus) => localization.columns.status[Contract.TypeNames.WorkloadResourceScanModelVirtualMachineStatus][status]()}
                                placeholder={localization.columns.status.title()}/>
                        }
                    }}
                    id={Contract.WorkloadControllerRequestProperty.WorkloadResourceStatus}
                    key={Contract.WorkloadControllerRequestProperty.WorkloadResourceStatus}
                    render={
                        optionalTableCell<Contract.WorkloadResourceVirtualMachineModel>(workloadResourceModel =>
                            workloadResourceModel.status && localization.columns.status[Contract.TypeNames.WorkloadResourceScanModelVirtualMachineStatus][workloadResourceModel.status]())}
                    selectorOptions={{ default: !vulnerabilityRawId }}
                    title={localization.columns.status.title()}/>,
            vulnerabilities:
                useVulnerabilitiesDataTableColumn(
                    localization.columns,
                    Contract.WorkloadAnalysisWorkloadResourceType.VirtualMachine,
                    vulnerabilityRawId),
            vulnerabilityAttackVector:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    enumType={Contract.VulnerabilityAttackVector}
                                    enumTypeTranslator={attackVectorTranslator}
                                    placeholder={localization.columns.vulnerabilityAttackVector()}/>
                        }
                    }}
                    id={Contract.WorkloadControllerRequestProperty.VulnerabilityAttackVector}
                    key={Contract.WorkloadControllerRequestProperty.VulnerabilityAttackVector}
                    selectorOptions={{ default: !vulnerabilityRawId }}
                    title={localization.columns.vulnerabilityAttackVector()}/>,
            vulnerabilityExploitable:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element: <ExploitableValueFilter localization={localization.columns.exploitable}/>
                        }
                    }}
                    id={Contract.WorkloadControllerRequestProperty.VulnerabilityExploitable}
                    key={Contract.WorkloadControllerRequestProperty.VulnerabilityExploitable}
                    selectorOptions={{ default: !vulnerabilityRawId }}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.exploitable.title()}/>,
            vulnerabilityResolutionPatches:
                <DataTableColumn
                    id={Contract.WorkloadControllerRequestProperty.VulnerabilityResolutionPatch}
                    key={Contract.WorkloadControllerRequestProperty.VulnerabilityResolutionPatch}
                    render={
                        optionalTableCell<Contract.WorkloadResourceModel>(workloadResourceModel =>
                            _.size(workloadResourceModel.vulnerabilityResolutionPatches) > 0 &&
                            <InlineItems
                                items={
                                    _.orderBy(
                                        workloadResourceModel.vulnerabilityResolutionPatches,
                                        vulnerabilityResolutionPatch => StringHelper.getSortValue(vulnerabilityResolutionPatch))
                                }
                                variant="itemPlusItemCount">
                                {(resolutionPatch: string) =>
                                    <VulnerabilityResolutionVersion resolutionVersion={resolutionPatch}/>}
                            </InlineItems>)}
                    selectorOptions={{ default: !vulnerabilityRawId }}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.missingPatches()}/>,
            workloadAnalysisStatus:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    enumType={Contract.WorkloadAnalysisStatus}
                                    enumTypeTranslator={workloadAnalysisScanStatusTranslator}
                                    placeholder={localization.columns.scanStatus()}/>
                        }
                    }}
                    id={Contract.WorkloadControllerRequestProperty.WorkloadAnalysisStatus}
                    key={Contract.WorkloadControllerRequestProperty.WorkloadAnalysisStatus}
                    render={({ item }: DataTableColumnRenderProps<Contract.WorkloadResourceVirtualMachineModel>) =>
                        <WorkloadAnalysisScanStatusCell
                            azureKeyVaultId={item.diskEncryptionVaultIdReference}
                            resourceType={Contract.WorkloadAnalysisWorkloadResourceType.VirtualMachine}
                            workloadAnalysisError={item.workloadAnalysisError}
                            workloadAnalysisStatus={item.workloadAnalysisStatus}/>}
                    selectorOptions={{ default: !vulnerabilityRawId }}
                    title={localization.columns.scanStatus()}/>,
            workloadResourceTypeName:
                <DataTableColumn
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <DeferredFilter
                                    promiseOrGetPromise={filtersPromise}
                                    title={localization.columns.workloadResourceTypeName()}>
                                    {filters =>
                                        <EntityTypeFilter
                                            emptyValue={filters.workloadResourceTypeNameItems.emptyValue}
                                            entityTypeNames={filters.workloadResourceTypeNameItems.items}
                                            groupItemTitle={false}
                                            placeholder={localization.columns.workloadResourceTypeName()}/>}
                                </DeferredFilter>
                        }
                    }}
                    id={Contract.WorkloadControllerRequestProperty.WorkloadResourceTypeName}
                    key={Contract.WorkloadControllerRequestProperty.WorkloadResourceTypeName}
                    selectorOptions={{ default: !vulnerabilityRawId }}
                    title={localization.columns.workloadResourceTypeName()}/>
        }
    };
}