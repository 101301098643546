import _ from "lodash";
import React from "react";
import { AccessController, Contract, EntitiesCell } from "../../../../../../../common";
import { usePermissionsContext } from "../Permissions";

type InlineSourceEntitiesProps = {
    getFilters: () => Contract.PermissionManagerRequestFilters;
    permissionsModel: Contract.PermissionsModel;
};

export function InlineSourceEntities({ getFilters, permissionsModel }: InlineSourceEntitiesProps) {
    const { definition } = usePermissionsContext();
    return (
        <EntitiesCell
            deferredEntities={{
                count: permissionsModel.sourceEntityCount,
                firstItem: _.first(permissionsModel.sourceEntityTopIds),
                getItems:
                    async () => {
                        const { sourceEntityIds } =
                            await AccessController.getPermissionsModelSourceEntityIds(
                                new Contract.AccessControllerGetPermissionsModelSourceEntityIdsRequest(
                                    definition.getPermissionsModelEntityId(permissionsModel),
                                    getFilters()));
                        return sourceEntityIds;
                    }
            }}
            entityTypeName={Contract.TypeNames.Entity}
            entityVariant="iconTextTypeTenant"/>);
}