﻿import { useLocalization, useSetRoute } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { Contract, CustomerConsoleAppUrlHelper } from "../../../../../../../../../../../../../../common";
import { RiskView } from "../../../../../../../../../../utilities";
import { RiskDefinitionSectionCategory } from "../../../../../../../../utilities";
import { RiskContentProps } from "../../../../../../useCloudDefinition";

export function ResolutionCategoryViewLink({ riskModel }: RiskContentProps) {
    const excessivePermissionPrincipalRisk = riskModel.risk as Contract.AzureExcessivePermissionPrincipalRisk;
    const setRoute = useSetRoute();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.access.useAzureExcessivePermissionPrincipalRiskDefinition.resolutionCategoryViewLink",
            () => ({
                link: "'**Via Existing Role(s)**'"
            }));

    return <Typography
        component="span"
        sx={{
            cursor: "pointer",
            textDecoration: "underline"
        }}
        onClick={
            () =>
                setRoute(
                    CustomerConsoleAppUrlHelper.getRiskProfileHashUrl(
                        riskModel.id,
                        {
                            category: RiskDefinitionSectionCategory.Resolution,
                            view:
                                excessivePermissionPrincipalRisk.resolutionChanges!.length > 1
                                    ? RiskView.ResolutionRoles
                                    : RiskView.ResolutionRole
                        }))}>
        {localization.link()}
    </Typography>;
}