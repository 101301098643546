import { CheckboxField, DropdownActions, MemoItemRenderer, Optional, PagedDropdown, PagedDropdownFieldOptions, PagedDropdownPage, useLocalization } from "@infrastructure";
import { Box, List, ListItemButton, Stack } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useMemo, useRef, useState } from "react";
import { useTheme } from "..";

type PagedSelectorProps = {
    children: (item: any) => ReactNode;
    disabled?: boolean;
    fieldOptions?: PagedMultiSelectFieldOptions;
    fullWidth?: boolean;
    getItemPage: (searchText: Optional<string>, skip: number, data?: any) => Promise<PagedDropdownPage>;
    multiSelect: boolean;
    onSelectedItemsChanged: (selectedItems: any[]) => void;
    placeholder: string;
    readOnly?: boolean;
    selectedItems: any[];
};

export type PagedMultiSelectFieldOptions = Pick<PagedDropdownFieldOptions, | "dense" | "selection" | "selectionCount" | "fieldVariant">;

export function PagedSelector({ children: renderItem, disabled = false, fieldOptions, fullWidth, getItemPage, multiSelect = true, onSelectedItemsChanged, placeholder, readOnly = false, selectedItems }: PagedSelectorProps) {
    const [open, setOpen] = useState(false);
    const dropdownActionsRef = useRef<DropdownActions>(null);
    const localization =
        useLocalization(
            "common.pagedSelector",
            () => ({
                empty: "No Results",
                search: "Search"
            }));

    const [searchMode, setSearchMode] = useState(false);

    return (
        <PagedDropdown
            actionsRef={dropdownActionsRef}
            disabled={disabled}
            emptyText={localization.empty()}
            fieldOptions={{
                dense: fieldOptions?.dense,
                fieldVariant: fieldOptions?.fieldVariant,
                onSelectionCleared:
                    multiSelect
                        ? undefined
                        : () => onSelectedItemsChanged([]),
                placeholder,
                selection:
                    multiSelect
                        ? fieldOptions?.selection ?? undefined
                        : fieldOptions?.selection ?? _.head(selectedItems),
                selectionCount:
                    multiSelect && _.isNil(fieldOptions?.selection)
                        ? fieldOptions?.selectionCount ?? selectedItems.length
                        : undefined,
                variant: "itemSelector"
            }}
            fullWidth={fullWidth}
            getItemPage={getItemPage}
            open={open}
            readOnly={readOnly}
            searchOptions={{
                enabled: true,
                onTextChanged: searchText => setSearchMode(searchText.length > 0),
                placeholder: localization.search()
            }}
            onClose={
                () => {
                    setOpen(false);
                    setSearchMode(false);
                }}
            onOpen={() => setOpen(true)}>
            {(items: any[]) =>
                multiSelect
                    ? <MultiItems
                        items={items}
                        renderItem={renderItem}
                        searchMode={searchMode}
                        selectedItems={selectedItems}
                        onSelectedItemsChanged={onSelectedItemsChanged}/>
                    : <SingleItem
                        items={items}
                        renderItem={renderItem}
                        onSelectedItemChanged={
                            selectedItem => {
                                onSelectedItemsChanged([selectedItem]);
                                dropdownActionsRef.current?.close();
                            }}/>}
        </PagedDropdown>);
}

type SingleItemProps = {
    items: any[];
    onSelectedItemChanged: (selectedItem: any) => void;
    renderItem: (item: any) => ReactNode;
};

function SingleItem({ items, onSelectedItemChanged, renderItem }: SingleItemProps) {
    const theme = useTheme();
    return (
        <List
            dense={true}
            disablePadding={true}>
            {_.map(
                items,
                item =>
                    <ListItemButton
                        disableGutters={true}
                        key={item}
                        sx={{ padding: theme.spacing(1) }}
                        onClick={() => onSelectedItemChanged(item)}>
                        <MemoItemRenderer
                            render={renderItem}
                            renderArguments={[item]}/>
                    </ListItemButton>)}
        </List>);
}

type MultiItemsProps = {
    items: any[];
    onSelectedItemsChanged: (selectedItems: any[]) => void;
    renderItem: (item: any) => ReactNode;
    searchMode: boolean;
    selectedItems: any[];
};

function MultiItems({ items, onSelectedItemsChanged, renderItem, searchMode, selectedItems }: MultiItemsProps) {
    const initialSelectedItems =
        useMemo(
            () => selectedItems,
            [searchMode]);
    const orderedItems =
        useMemo(
            () =>
                searchMode
                    ? items
                    : _(initialSelectedItems).
                        concat(items).
                        uniq().
                        value(),
            [items, searchMode]);
    const theme = useTheme();
    return (
        <Stack>
            {_.map(
                orderedItems,
                item =>
                    <Box
                        key={item}
                        sx={{ padding: theme.spacing(1) }}>
                        <CheckboxField
                            checked={_.includes(selectedItems, item)}
                            color="primary"
                            sx={{
                                maxWidth: theme.spacing(50),
                                minWidth: theme.spacing(25)
                            }}
                            onChange={
                                (_event, checked) =>
                                    onSelectedItemsChanged(
                                        checked
                                            ? _.concat(selectedItems, item)
                                            : _.without(selectedItems, item))}>
                            <MemoItemRenderer
                                render={renderItem}
                                renderArguments={[item]}/>
                        </CheckboxField>
                    </Box>)}
        </Stack>);
}