﻿import _ from "lodash";
import { Contract } from "../../../../../../../../../../common";

export class ChangeHelper {
    public static getChanges(changes: Contract.ChangeBase[]): Contract.Change[] {
        return _.flatMap(
            changes,
            change =>
                change.typeName === Contract.TypeNames.ChangeSet
                    ? (change as Contract.ChangeSet).changes
                    : [change as Contract.Change]);
    }
}