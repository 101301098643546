import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { Contract } from "../../../../../common";

export function useAwsEc2FlowLogFilterTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsEc2FlowLogFilterTranslator",
            () => ({
                [Contract.TypeNames.AwsEc2FlowLogFilter]: {
                    [Contract.AwsEc2FlowLogFilter.Accept]: "Accept",
                    [Contract.AwsEc2FlowLogFilter.All]: "All",
                    [Contract.AwsEc2FlowLogFilter.Reject]: "Reject"
                },
                disabled: "Disabled"
            }));
    return (status?: Contract.AwsEc2FlowLogFilter) =>
        _.isNil(status)
            ? localization.disabled()
            : localization[Contract.TypeNames.AwsEc2FlowLogFilter][status]();
}