﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { RiskPoliciesType } from "../../../../../../../common";
import { ConfigurationCategory } from "../../../components";
import { SecretExclusion } from "../../../components/Configuration/components";
import { RiskPolicyConfigurationDefinitionData } from "../useConfigurationDefinition";

export function useSecretExistsRiskPolicyConfigurationDefinition(riskPoliciesType: RiskPoliciesType): RiskPolicyConfigurationDefinitionData {
    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useConfigurationDefinition.hooks.useSecretExistsRiskPolicyConfigurationDefinition",
            () => ({
                [ConfigurationCategory.SecretExclusions]: "Secret Exclusions"
            }));
    return {
        additionalTabs: [
            {
                element:
                    <SecretExclusion
                        key={ConfigurationCategory.SecretExclusions}
                        riskPoliciesType={riskPoliciesType}
                        title={localization[ConfigurationCategory.SecretExclusions]()}/>,
                id: ConfigurationCategory.SecretExclusions
            }]
    };
}