import { DataTableColumn, DataTableColumnRenderProps, EnumValuesFilter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, EntityModelHelper, ItemSelectionHelper, PagedEntityFilter, useEntitiesExportOptions, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { useGciDirectoryDirectoryRoleTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { useGciCommonEntityDefinition } from "./useGciCommonEntityDefinition";

export function useGciDirectoryDirectoryRoleDefinition(definitionData: DefinitionData) {
    const commonEntityDefinition = useGciCommonEntityDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const directoryDirectoryRoleTypeTranslator = useGciDirectoryDirectoryRoleTypeTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gci.useGciDirectoryDirectoryRoleDefinition",
            () => ({
                columns: {
                    assignedIdentityIdReferences: "Assigned To",
                    type: "Role Type"
                }
            }));

    return new EntityTableDefinition(
        [
            commonEntityDefinition.columns.tenantColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GciDirectoryDirectoryRoleModel) => ({
                            [localization.columns.type()]: directoryDirectoryRoleTypeTranslator((item.entity as Contract.GciDirectoryDirectoryRole).type)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.GciDirectoryDirectoryRoleType}
                                enumTypeTranslator={directoryDirectoryRoleTypeTranslator}
                                placeholder={localization.columns.type()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GciDirectoryDirectoryRoleType}
                itemProperty={(item: Contract.EntityModel) => directoryDirectoryRoleTypeTranslator((item.entity as Contract.GciDirectoryDirectoryRole).type)}
                key={Contract.EntityModelProperty.GciDirectoryDirectoryRoleType}
                title={localization.columns.type()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GciDirectoryDirectoryRoleModel>(
                        Contract.TypeNames.IGciIdentity,
                        item => item.assignedIdentityIdReferences,
                        localization.columns.assignedIdentityIdReferences(),
                        { getValue: entityModel => EntityModelHelper.formatDisplayReference(entityModel, entityTypeNameTranslator) })
                }
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GciDirectoryDirectoryRoleAssignedIdentities)}
                                placeholder={localization.columns.assignedIdentityIdReferences()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GciDirectoryDirectoryRoleAssignedIdentities}
                key={Contract.EntityModelProperty.GciDirectoryDirectoryRoleAssignedIdentities}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GciDirectoryDirectoryRoleModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.assignedIdentityIdReferences}
                            entityTypeName={Contract.TypeNames.IGciIdentity}
                            entityVariant="iconText"/>}
                title={localization.columns.assignedIdentityIdReferences()}/>,
            commonEntityDefinition.columns.attributesColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGciDirectoryDirectoryRoleRequest(
                new Contract.EntityControllerGetEntityModelPageGciDirectoryDirectoryRoleRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GciDirectoryDirectoryRoleAssignedIdentities]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GciDirectoryDirectoryRoleType])),
                limit,
                skip,
                requestSort));
}