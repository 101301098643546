import { InlineItems, Link, SuccessIcon, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { CustomerConsoleAppUrlHelper, scopeSystemEntityModelStore, tenantModelStore, useTheme } from "../../../../../../../../../../../../../../../common";
import { useAddOrEditContext } from "../AddOrEdit";

export function FinishItem() {
    const { masterTenantModel, name, organizationMemberTenantNames } = useAddOrEditContext();
    const { useNextEffect } = useOrderedWizardContext();

    useNextEffect(
        async () => {
            await tenantModelStore.notify(masterTenantModel!.configuration.id);
            await scopeSystemEntityModelStore.notify();
            return undefined;
        },
        []);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.aws.addOrEdit.finishItem",
            () => ({
                organizationMemberTenantNames: {
                    namePluralizer: [
                        "member account",
                        "{{count | NumberFormatter.humanize}} member accounts"
                    ],
                    title: "Detected {{organizationMemberTenantNames}}"
                },
                subtitle: {
                    documentationLink: "learn more",
                    text: "We're synchronizing your data now, and you'll be able to see it soon. {{documentationLink}}"
                },
                title: "Organization {{name}} configured successfully"
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{ height: "100%" }}>
            <SuccessIcon
                sx={{
                    color: theme.palette.success.main,
                    fontSize: "60px"
                }}/>
            <Typography
                align="center"
                sx={{ fontWeight: 400 }}
                variant="h2">
                {localization.title({ name })}
            </Typography>
            <Typography
                align="center"
                variant="h4">
                {localization.organizationMemberTenantNames.title({
                    organizationMemberTenantNames:
                        <InlineItems
                            items={_.orderBy(organizationMemberTenantNames)}
                            namePluralizer={localization.organizationMemberTenantNames.namePluralizer}
                            variant="itemCountAndType"/>
                })}
            </Typography>
            <Typography
                align="center"
                variant="h5">
                {localization.subtitle.text({
                    documentationLink:
                        <Link
                            urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsArchitectureAndDataHandlingHowOftenIsDataSyncedRelativeUrl()}
                            variant="external">
                            {localization.subtitle.documentationLink()}
                        </Link>
                })}
            </Typography>
        </Stack>);
}