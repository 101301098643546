import { Box } from "@mui/material";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { AnalyticsHelper, clearPersistentResults, setUrlRoute, UnexpectedError, useClearQueryParameters, useRoute } from "@infrastructure";
import { useCustomerContext, useSetCustomerContext } from "../../..";
import { ApiController, ApplicationView, clearComplianceSectionDatasCache, clearTopCriticalVulnerabilityModelsCache, cloudRiskPolicyItemsOperationStore, codeRiskPolicyItemsOperationStore, Contract, CustomerConsoleAppUrlHelper, entityModelStore, ScopeHelper, scopeNodeModelStore, ScopeSelector, useLayoutContext, UserHelper, useSelectedScopeId } from "../../../../../common";

export function GlobalScopeFilter() {
    const activeScopeNodeMap =
        scopeNodeModelStore.useGetActiveScopeNodeMap(
            undefined,
            true);
    const { options } = useLayoutContext();

    const clearQueryParameters = useClearQueryParameters();

    const { scopeIdInitialized } = useCustomerContext();
    const setCustomerContext = useSetCustomerContext();

    const { scopeId: routeScopeId } = useRoute(CustomerConsoleAppUrlHelper.getTemplatePath());
    const { selectedScopeId: apiControllerScopeId } = useSelectedScopeId();

    const [selectedScopeId, setSelectedScopeId] =
        useState<string>(
            () => {
                const selectedScopeId = routeScopeId ?? apiControllerScopeId;
                if (_.isNil(selectedScopeId)) {
                    throw new UnexpectedError("No selected scope ID");
                }

                return selectedScopeId;
            });

    useEffect(
        () => {
            const selectedScopeNodeModel = activeScopeNodeMap[selectedScopeId].scopeNodeModel;
            AnalyticsHelper.selectedScopeType =
                ScopeHelper.getTenantType(selectedScopeNodeModel) ??
                (ScopeHelper.isProjectScope(selectedScopeNodeModel)
                    ? Contract.ScopeType.Project
                    : selectedScopeNodeModel.type);
        },
        [selectedScopeId]);

    const { reloadView } = useCustomerContext();
    const changeSelectedScope =
        useCallback(
            (updatedSelectedScopeId: string) => {
                if (updatedSelectedScopeId !== apiControllerScopeId) {
                    ApiController.scopeId = updatedSelectedScopeId;
                }

                if (routeScopeId !== updatedSelectedScopeId) {
                    setUrlRoute(
                        CustomerConsoleAppUrlHelper.getTemplatePath(
                            _(window.location.pathname).
                                split(`${ApplicationView.Customer}/${encodeURIComponent(routeScopeId)}`).
                                last()) + window.location.hash,
                        { scopeId: encodeURIComponent(updatedSelectedScopeId) },
                        { appendBrowserHistory: false });
                }

                if (updatedSelectedScopeId === selectedScopeId) {
                    return;
                }

                setSelectedScopeId(updatedSelectedScopeId);
                entityModelStore.clear();
                clearComplianceSectionDatasCache();
                clearTopCriticalVulnerabilityModelsCache();
                clearQueryParameters();
                clearPersistentResults();
                cloudRiskPolicyItemsOperationStore.notifyAll();
                codeRiskPolicyItemsOperationStore.notifyAll();
                reloadView();
            },
            [apiControllerScopeId, reloadView, routeScopeId, selectedScopeId]);

    useEffect(
        () => {
            const selectedScopeId =
                !_.isNil(routeScopeId) &&
                    UserHelper.hasScopePermissions(
                        routeScopeId,
                        Contract.IdentityPermission.SecurityRead)
                    ? routeScopeId
                    : apiControllerScopeId;

            changeSelectedScope(selectedScopeId);

            if (!scopeIdInitialized) {
                setCustomerContext(
                    context => ({
                        ...context,
                        scopeIdInitialized: true
                    }));
            }
        },
        [apiControllerScopeId, routeScopeId, scopeIdInitialized]);

    return (
        <Box>
            <ScopeSelector
                disabled={options.scopeDisabled}
                includeEmptyFolders={true}
                includeProjects={true}
                inline={true}
                isScopeExpanded={
                    scopeNodeModelTreeItem =>
                        scopeNodeModelTreeItem.value.configuration.id !== selectedScopeId &&
                        ScopeHelper.isFolder(scopeNodeModelTreeItem.value) &&
                        _.some(
                            scopeNodeModelTreeItem.nodes(),
                            item => item.value.configuration.id === selectedScopeId)}
                popoverElementContainerSx={{
                    maxHeight: "unset",
                    padding: 0
                }}
                scopeIds={_.keys(activeScopeNodeMap)}
                scopeSelectable={
                    scopeId =>
                        UserHelper.hasScopePermissions(
                            scopeId,
                            Contract.IdentityPermission.SecurityRead)}
                selectedScopeId={selectedScopeId}
                treeSx={{
                    maxHeight: "600px"
                }}
                onSelectedScopeIdChanged={changeSelectedScope}/>
        </Box>);
}