import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { Contract } from "../../../../../controllers";
import { useTheme } from "../../../../../themes";

export function SecretsIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon fill="none" height="24" viewBox="0 0 24 24" width="24" {...props}>
            <path
                clipRule="evenodd"
                d="M5 15C6.65685 15 8 13.6569 8 12C8 10.3431 6.65685 9 5 9C3.34315 9 2 10.3431 2 12C2 13.6569 3.34315 15 5 15ZM5 17C7.76142 17 10 14.7614 10 12C10 9.23858 7.76142 7 5 7C2.23858 7 0 9.23858 0 12C0 14.7614 2.23858 17 5 17Z"
                fill={theme.palette.data.category(Contract.DataCategory.Secrets)}
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M9.08196 11.9028C9.08196 11.3505 9.52968 10.9028 10.082 10.9028H23.2486C23.8009 10.9028 24.2486 11.3505 24.2486 11.9028C24.2486 12.4551 23.8009 12.9028 23.2486 12.9028H10.082C9.52968 12.9028 9.08196 12.4551 9.08196 11.9028Z"
                fill={theme.palette.data.category(Contract.DataCategory.Secrets)}
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M21.5273 11.7222C22.0796 11.7222 22.5273 12.1699 22.5273 12.7222V15.4445C22.5273 15.9967 22.0796 16.4445 21.5273 16.4445C20.975 16.4445 20.5273 15.9967 20.5273 15.4445V12.7222C20.5273 12.1699 20.975 11.7222 21.5273 11.7222Z"
                fill={theme.palette.data.category(Contract.DataCategory.Secrets)}
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M17.9844 11.7222C18.5367 11.7222 18.9844 12.1699 18.9844 12.7222V14.2639C18.9844 14.8162 18.5367 15.2639 17.9844 15.2639C17.4321 15.2639 16.9844 14.8162 16.9844 14.2639V12.7222C16.9844 12.1699 17.4321 11.7222 17.9844 11.7222Z"
                fill={theme.palette.data.category(Contract.DataCategory.Secrets)}
                fillRule="evenodd"/>
        </SvgIcon>);
}