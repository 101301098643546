import { DialogMenuItem, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../..";
import { Contract, tenantModelStore, useEntityTypeNameTranslator, usePrincipalModelAccess } from "../../../../../../../../../../common";
import { GenerateAzureIdentityNonexcessiveRoleDefinitionDialog } from "../../../../../../../../../../tenants";
import { EntitiesInfoCard, EntitiesInfoItem, Info, PrincipalSecurityStatsStat } from "../../../../components";
import { GenerateEntityNonexcessivePermitterIcon } from "../../../../icons";
import { AadDirectoryPrincipalAzureRoleAssignmentResources, AadDirectoryPrincipalDirectoryRoleAssignmentResources, AadDirectoryServicePrincipalApplicationRoleAssignments } from "../../components";
import { AadDirectoryServicePrincipalMicrosoftGraphPermissions } from "../../components/AadDirectoryServicePrincipalMicrosoftGraphPermissions";
import { useAadCommonDirectoryServicePrincipalInfoItemElements } from "./useAadCommonDirectoryServicePrincipalInfoItemElements";
import { useAadDefaultDirectoryEntityInfoItemElements } from "./useAadDefaultDirectoryEntityInfoItemElements";

export function useAadDirectoryManagedIdentityServicePrincipalDefinition(directoryEntityModel: Contract.AadDirectoryEntityModel, options?: DefinitionOptions) {
    const activeCloudProviderTenantTypes = tenantModelStore.useGetActiveCloudProviderTenantTypes();
    const managedIdentityServicePrincipalModel = directoryEntityModel as Contract.AadDirectoryManagedIdentityServicePrincipalModel;
    const commonDirectoryServicePrincipalInfoItemElements = useAadCommonDirectoryServicePrincipalInfoItemElements(managedIdentityServicePrincipalModel);
    const defaultDirectoryEntityInfoItemElements = useAadDefaultDirectoryEntityInfoItemElements(directoryEntityModel);
    const managedIdentityServicePrincipal = directoryEntityModel.entity as Contract.AadDirectoryManagedIdentityServicePrincipal;
    const managedIdentityServicePrincipalModelAccess = usePrincipalModelAccess<Contract.ServiceIdentityModelAccess>(managedIdentityServicePrincipalModel.id);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aad.useAadDirectoryManagedIdentityServicePrincipalDefinition",
            () => ({
                info: {
                    cards: {
                        groupIds: "Group Memberships"
                    },
                    items: {
                        managedIdentityId: "Managed Identity"
                    }
                },
                tabs: {
                    aadAdministrativeRoleAssignments: "Microsoft Entra ID Administrative Role Assignments",
                    applicationRoleDefinitions: "Users and Groups",
                    azureRoleAssignments: "Azure Role Assignments",
                    graphApiPermissions: "Graph API Permissions"
                },
                topbar: {
                    menu: {
                        generate: "Generate Custom Role"
                    }
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs:
            _<EntityProfileDefinitionTab>([]).
                concat(
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Iam,
                        <AadDirectoryServicePrincipalApplicationRoleAssignments
                            applicationRoleDefinitions={managedIdentityServicePrincipal.applicationRoleDefinitions}
                            assignedPrincipalIdToApplicationRoleDefinitionRawIdsMap={managedIdentityServicePrincipalModel.assignedPrincipalIdToApplicationRoleDefinitionRawIdsMap}
                            csvExportFilePrefixes={[
                                entityTypeNameTranslator(directoryEntityModel.entity.typeName, { includeServiceName: false }),
                                directoryEntityModel.entity.displayName,
                                localization.tabs.applicationRoleDefinitions()
                            ]}/>,
                        localization.tabs.applicationRoleDefinitions(),
                        "applicationRoleDefinitions")).
                concat(
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Iam,
                        <AadDirectoryPrincipalDirectoryRoleAssignmentResources
                            csvExportFilePrefix={localization.tabs.aadAdministrativeRoleAssignments()}
                            principalModel={managedIdentityServicePrincipalModel}/>,
                        localization.tabs.aadAdministrativeRoleAssignments(),
                        "aadAdministrativeRoleAssignments")).
                concatIf(
                    _.includes(activeCloudProviderTenantTypes, Contract.TenantType.Azure),
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Iam,
                        <AadDirectoryPrincipalAzureRoleAssignmentResources
                            csvExportFilePrefix={localization.tabs.azureRoleAssignments()}
                            principalModel={managedIdentityServicePrincipalModel}/>,
                        localization.tabs.azureRoleAssignments(),
                        "azureRoleAssignments")).
                concat(
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Iam,
                        <AadDirectoryServicePrincipalMicrosoftGraphPermissions
                            applicationRoleDefinitions={managedIdentityServicePrincipalModel.microsoftGraphRoleAssignmentRoleDefinitions}
                            csvExportFilePrefixes={[
                                entityTypeNameTranslator(directoryEntityModel.entity.typeName, { includeServiceName: false }),
                                directoryEntityModel.entity.displayName,
                                localization.tabs.graphApiPermissions()]}/>,
                        localization.tabs.graphApiPermissions(),
                        "graphApiPermissions")).
                value(),
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultDirectoryEntityInfoItemElements}
                entityPropertyInfoItemElements={[
                    commonDirectoryServicePrincipalInfoItemElements.tagsInfoItemElement,
                    commonDirectoryServicePrincipalInfoItemElements.enabledInfoItemElement,
                    <EntitiesInfoItem
                        entityIdsOrModels={managedIdentityServicePrincipalModel.managedIdentityId}
                        entityTypeName={Contract.TypeNames.AzureManagedIdentityManagedIdentity}
                        key="managedIdentityId"
                        title={localization.info.items.managedIdentityId()}/>,
                    commonDirectoryServicePrincipalInfoItemElements.signInTimeInfoItemElement,
                    commonDirectoryServicePrincipalInfoItemElements.activityTimeInfoItemElement,
                    commonDirectoryServicePrincipalInfoItemElements.applicationRawIdInfoItemElement,
                    commonDirectoryServicePrincipalInfoItemElements.typeInfoItemElement,
                    commonDirectoryServicePrincipalInfoItemElements.homepageRawUrlInfoItemElement,
                    commonDirectoryServicePrincipalInfoItemElements.assignmentRequiredInfoItemElement,
                    commonDirectoryServicePrincipalInfoItemElements.visibleInfoItemElement,
                    commonDirectoryServicePrincipalInfoItemElements.ownerPrincipalIdsInfoItemElement
                ]}
                options={options?.infoOptions}>
                <EntitiesInfoCard
                    entityIdsOrModels={managedIdentityServicePrincipalModel.groupIds}
                    entityTypeName={Contract.TypeNames.AadDirectoryGroup}
                    title={localization.info.cards.groupIds()}/>
            </Info>,
        sensitive: false,
        statElements:
            _.filter([
                _.isNil(managedIdentityServicePrincipalModelAccess?.permissions)
                    ? undefined
                    : <PrincipalSecurityStatsStat
                        analyzingReason={managedIdentityServicePrincipalModel.analyzing?.reason}
                        analyzingTenants={
                            _.isNil(managedIdentityServicePrincipalModel.analyzing?.tenantIds)
                                ? undefined
                                : {
                                    ids: managedIdentityServicePrincipalModel.analyzing!.tenantIds,
                                    typeName: Contract.TypeNames.AzureTenantEntity
                                }}
                        securityStats={managedIdentityServicePrincipalModelAccess!.permissions!.excessivePermissionActionStats}
                        typeName={Contract.TypeNames.AadDirectoryManagedIdentityServicePrincipal}/>
            ]),
        topbarMenuItems:
            _.isNil(managedIdentityServicePrincipalModel.systemDeletionTime)
                ? [
                    new DialogMenuItem(
                        onClose =>
                            <GenerateAzureIdentityNonexcessiveRoleDefinitionDialog
                                identityModel={managedIdentityServicePrincipalModel}
                                onClose={onClose}/>,
                        localization.topbar.menu.generate(),
                        { icon: <GenerateEntityNonexcessivePermitterIcon/> })
                ]
                : undefined
    });
}