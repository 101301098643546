import { Box, Stack, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { ReactNode } from "react";
import { NotValidIcon, SuccessIcon } from "../assets/icons";
import { map } from "../utilities";

export type ToastProps = {
    content?: string | ReactNode;
    title: string;
} & ({
    icon?: never;
    level: ToastLevel;
} | {
    icon: ReactNode;
    level?: never;
});

export enum ToastLevel {
    Error = "error",
    Info = "info",
    Success = "success",
    Warning = "warning"
}

export function Toast({ content, icon, level, title }: ToastProps) {
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            spacing={1.5}
            sx={{ color: theme.palette.text.primary }}>
            <Box
                sx={{
                    fontSize: "16px",
                    height: "16px",
                    width: "16px"
                }}>
                {_.isNil(icon)
                    ? map(
                        level,
                        {
                            [ToastLevel.Success]: () => <SuccessIcon sx={{ height: "inherit", width: "inherit" }}/>
                        },
                        () => <NotValidIcon sx={{ color: theme.palette.toast[level!], height: "inherit", width: "inherit" }}/>)
                    : icon}
            </Box>
            <Stack spacing={1}>
                <Typography variant="h4">
                    {title}
                </Typography>
                <Box>
                    {_.isString(content)
                        ? <Typography
                            color={theme.palette.text.secondary}
                            variant="body1">
                            {content}
                        </Typography>
                        : content}
                </Box>
            </Stack>
        </Stack>);
}