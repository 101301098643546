import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../../..";
import { useGcpCommonNetworkingInfoItemElements, useGcpDefaultResourceInfoItemElements, useGcpDefaultScopeResourceInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../../common";
import { useGcpComputeInstanceGroupManagerStatusTranslator } from "../../../../../../../../../../../tenants";
import { useGcpComputeInstanceGroupTypeTranslator } from "../../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../../components";
import { NetworkingInfoCard } from "../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { InstancesInfoCard, PortsInfoCard } from "./components";

export function useGcpComputeInstanceGroupDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const defaultScopeResourceInfoItemElements = useGcpDefaultScopeResourceInfoItemElements(scopeResourceModel);
    const instanceGroupModel = scopeResourceModel as Contract.GcpComputeInstanceGroupModel;
    const instanceGroup = instanceGroupModel.entity as Contract.GcpComputeInstanceGroup;
    const networkInfoItems =
        useGcpCommonNetworkingInfoItemElements({
            resourceModel: scopeResourceModel,
            subnetIds: instanceGroupModel.subnetId,
            vpcIds: instanceGroupModel.vpcIdReference
        });

    const computeInstanceGroupManagerStatusTranslator = useGcpComputeInstanceGroupManagerStatusTranslator();
    const computeInstanceGroupTypeTranslator = useGcpComputeInstanceGroupTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpComputeInstanceGroupDefinition",
            () => ({
                info: {
                    items: {
                        backendServiceIds: "Load Balancer Backend Services",
                        instanceGroupManagerStatus: "Status",
                        instanceTemplateId: "Instance Template",
                        maxCapacity: "Maximum number of instances",
                        minCapacity: "Minimum number of instances",
                        nodePoolId: "Cluster Node Pool",
                        type: "Instance Group Type"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...defaultScopeResourceInfoItemElements,
                    <EntitiesInfoItem
                        entityIdsOrModels={instanceGroupModel.backendServiceIds}
                        entityTypeName={Contract.TypeNames.GcpComputeBackendService}
                        key="backendServiceIds"
                        title={localization.info.items.backendServiceIds()}/>,
                    <InfoItem
                        key="type"
                        title={localization.info.items.type()}
                        value={computeInstanceGroupTypeTranslator(instanceGroupModel.type)}/>,
                    <InfoItem
                        key="minCapacity"
                        title={localization.info.items.minCapacity()}
                        value={
                            _.isNil(instanceGroupModel.minCapacity)
                                ? undefined
                                : instanceGroupModel.minCapacity}/>,
                    <InfoItem
                        key="maxCapacity"
                        title={localization.info.items.maxCapacity()}
                        value={
                            _.isNil(instanceGroupModel.maxCapacity)
                                ? undefined
                                : instanceGroupModel.maxCapacity}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instanceGroupModel?.instanceTemplateIdReference}
                        entityTypeName={Contract.TypeNames.GcpComputeInstanceTemplate}
                        key="instanceTemplateId"
                        location="all"
                        title={localization.info.items.instanceTemplateId()}/>,
                    <InfoItem
                        key="instanceGroupManagerStatus"
                        title={localization.info.items.instanceGroupManagerStatus()}
                        value={
                            _.isNil(instanceGroupModel.instanceGroupManagerStatus)
                                ? undefined
                                : computeInstanceGroupManagerStatusTranslator(instanceGroupModel.instanceGroupManagerStatus)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instanceGroupModel?.nodePoolId}
                        entityTypeName={Contract.TypeNames.GcpContainerClusterNodePool}
                        key="nodePoolId"
                        title={localization.info.items.nodePoolId()}/>
                ]}
                options={options?.infoOptions}>
                <NetworkingInfoCard>
                    {networkInfoItems.inboundAccessType}
                    {networkInfoItems.inboundExternalAccessScope}
                    {networkInfoItems.getVpcs()}
                    {networkInfoItems.getSubnets()}
                </NetworkingInfoCard>
                <InstancesInfoCard instancesIds={instanceGroupModel.instanceIds}/>
                {!_.isEmpty(instanceGroup.portNameToRangesMap) &&
                    <PortsInfoCard portNameToRangesMap={instanceGroup.portNameToRangesMap}/>}
            </Info>
    });
}