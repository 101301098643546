import { DataTableColumn, useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../../common";
import { TableColumnId } from "../../../IdentityProviderTenantTable";
import { TenantDefinition } from "../useDefinition";

export function useOneLoginDefinition(): TenantDefinition {

    const localization =
        useLocalization(
            "views.customer.configuration.permissionManagement.identityProviderTenantTable.hooks.useDefinition.hooks.useOneLoginDefinition",
            () => ({
                columns: {
                    url: "Account URL"
                }
            }));
    return new TenantDefinition([
        <DataTableColumn
            id={TableColumnId.OneLoginUrl}
            itemProperty={(item: Contract.OneLoginTenantModel) => item.configuration.url}
            key={TableColumnId.OneLoginUrl}
            title={localization.columns.url()}/>
    ]);
}