﻿import { Optional, TimeFormatter, UnexpectedError, useLocalization } from "@infrastructure";
import _ from "lodash";
import { Contract } from "../controllers";

export function useActivityTranslator() {
    const localization =
        useLocalization(
            "common.hooks.useActivityTranslator",
            () => ({
                empty: "Never",
                olderThan: "More than {{activityTime | TimeFormatter.humanizePastDuration}}",
                unknown: "Unknown"
            }));

    return (activity: Optional<Contract.Activity>) => {
        switch (activity?.timeType) {
            case Contract.ActivityTimeType.Exact:
            case Contract.ActivityTimeType.NewerThan:
                return _.isNil(activity.time)
                    ? localization.empty()
                    : TimeFormatter.monthDayAndYear(activity.time);
            case Contract.ActivityTimeType.OlderThan:
                return localization.olderThan({ activityTime: activity.time });
            case Contract.ActivityTimeType.Unknown:
                return localization.unknown();
            case undefined:
                return undefined;
            default:
                throw new UnexpectedError("activity.timeType", activity?.timeType);
        }
    };
}