import { TimeFormatter, useExecuteOperation, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../../..";
import { useAwsCommonAccessResourceInfoElements } from "..";
import { Contract, EntityController, InfoItem } from "../../../../../../../../../../../common";
import { EntitiesInfoItem, Info } from "../../../../../components";
import { AwsIamPolicyDocument } from "../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { useAwsDefaultResourceInfoItemElements } from "../useAwsDefaultResourceInfoItemElements";
import { Grants } from "./components";

export function useAwsKmsKeyDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonAccessResourceInfoElements = useAwsCommonAccessResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const key = resourceModel.entity as Contract.AwsKmsKey;
    const keyModel = resourceModel as Contract.AwsKmsKeyModel;

    const [{ keyGrants }] =
        useExecuteOperation(
            [useAwsKmsKeyDefinition, resourceModel.id],
            async () => await EntityController.getAwsKmsKeyGrants(new Contract.EntityControllerGetAwsKmsKeyGrantsRequest(resourceModel.id)));

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsKmsKeyDefinition",
            () => ({
                info: {
                    items: {
                        awsManaged: {
                            false: "Customer Managed",
                            title: "Management",
                            true: "AWS Managed"
                        },
                        enabled: {
                            false: "Disabled",
                            title: "Status",
                            true: "Enabled"
                        },
                        encryptedResourceIds: "Encrypted Resources",
                        nextRotationTime: "Next rotation time",
                        rotationEnabled: {
                            false: "Disabled",
                            title: "Rotation Status",
                            true: "Enabled"
                        },
                        rotationTimeFrame: {
                            text: "Every {{rotationTimeFrame | TimeSpanFormatter.humanizeDays}}",
                            title: "Rotation period"
                        },
                        type: {
                            asymmetric: "Asymmetric",
                            symmetric: "Symmetric",
                            title: "Key Type"
                        }
                    }
                },
                tabs: {
                    grants: "Grants",
                    policyDocument: "Policy"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AwsIamPolicyDocument policyDocument={key.policyDocument}/>,
                localization.tabs.policyDocument(),
                "policyDocument"),
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <Grants grants={keyGrants}/>,
                localization.tabs.grants(),
                "grants")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonAccessResourceInfoElements,
                    <InfoItem
                        key="enabled"
                        title={localization.info.items.enabled.title()}
                        value={
                            key.enabled
                                ? localization.info.items.enabled.true()
                                : localization.info.items.enabled.false()}/>,
                    <InfoItem
                        key="awsManaged"
                        title={localization.info.items.awsManaged.title()}
                        value={
                            key.awsManaged
                                ? localization.info.items.awsManaged.true()
                                : localization.info.items.awsManaged.false()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={keyModel.encryptedResourceIds}
                        entityTypeName={Contract.TypeNames.IAwsEncryptedResource}
                        key="encryptedResourceIds"
                        location="all"
                        title={localization.info.items.encryptedResourceIds()}/>,
                    <InfoItem
                        key="rotationEnabled"
                        title={localization.info.items.rotationEnabled.title()}
                        value={
                            _.isNil(key.rotationEnabled)
                                ? undefined
                                : key.rotationEnabled
                                    ? localization.info.items.rotationEnabled.true()
                                    : localization.info.items.rotationEnabled.false()}/>,
                    <InfoItem
                        key="rotationTimeFrame"
                        location="all"
                        title={localization.info.items.rotationTimeFrame.title()}
                        value={
                            _.isNil(key.rotationTimeFrame)
                                ? undefined
                                : localization.info.items.rotationTimeFrame.text({ rotationTimeFrame: key.rotationTimeFrame })}/>,
                    <InfoItem
                        key="nextRotationTime"
                        title={localization.info.items.nextRotationTime()}
                        value={
                            _.isNil(key.nextRotationTime)
                                ? undefined
                                : TimeFormatter.profileFormatDateTime(key.nextRotationTime)}/>,
                    <InfoItem
                        key="type"
                        title={localization.info.items.type.title()}
                        value={
                            key.symmetric
                                ? localization.info.items.type.symmetric()
                                : localization.info.items.type.asymmetric()}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}