import { DataTableColumn, DataTableColumnRenderProps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { useCommonCustomSectionsAndDescriptionDefinition } from "../../..";
import { Contract, Entity, EntityFilter, entityModelStore, InlineEntities, useTableDefinition } from "../../../../../../../../../../../../common";
import { Table } from "../../../../components";

export function useAwsInboundExternalSubnetRiskDefinition(riskModel: Contract.RiskModel) {
    const inboundExternalSubnetRiskModel = riskModel as Contract.AwsInboundExternalSubnetRiskModel;
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.custom.useAwsInboundExternalSubnetRiskDefinition",
            () => ({
                violations: "{{subnets}} allow inbound internet connection"
            }));
    const translatedViolations =
        localization.violations({
            subnets:
                <InlineEntities
                    entityIdsOrModels={inboundExternalSubnetRiskModel.subnetIds}
                    entityTypeName={Contract.TypeNames.AwsEc2Subnet}
                    variant="itemAndTypeOrItemCountAndType"/>
        });
    return useCommonCustomSectionsAndDescriptionDefinition(
        translatedViolations,
        riskModel,
        "violations",
        <ViolationTable risk={inboundExternalSubnetRiskModel.risk}/>);
}

type ViolationTableProps = {
    risk: Contract.AwsInboundExternalSubnetRisk;
};

function ViolationTable({ risk }: ViolationTableProps) {
    const routeTableModels =
        entityModelStore.useGet(
            _.map(
                risk.inboundExternalSubnetItems,
                item => item.routeTableId));
    const subnetModels =
        entityModelStore.useGet(
            _.map(
                risk.inboundExternalSubnetItems,
                item => item.subnetId));
    const vpcModels =
        entityModelStore.useGet(
            _.map(
                risk.inboundExternalSubnetItems,
                item => item.vpcId));
    const items =
        useMemo(
            () => {
                const routeTableModelMap =
                    _.keyBy(
                        routeTableModels,
                        routeTable => routeTable.id);
                const subnetModelMap =
                    _.keyBy(
                        subnetModels,
                        subnet => subnet.id);
                const vpcModelMap =
                    _.keyBy(
                        vpcModels,
                        vpc => vpc.id);

                return _.map(
                    risk.inboundExternalSubnetItems,
                    riskItem =>
                        new ViolationTableItem(
                            riskItem,
                            routeTableModelMap[riskItem.routeTableId],
                            subnetModelMap[riskItem.subnetId],
                            vpcModelMap[riskItem.vpcId]));
            },
            []);

    const tableDefinition =
        useTableDefinition(
            items,
            {
                [ViolationTableColumnId.RouteTable]: {
                    getFilterValue: item => item.routeTableModel.entity.id,
                    getSortValue: item => item.routeTableModel.entity.displayName
                },
                [ViolationTableColumnId.Subnet]: {
                    getFilterValue: item => item.subnetModel.entity.id,
                    getSortValue: item => item.subnetModel.entity.displayName
                },
                [ViolationTableColumnId.Vpc]: {
                    getFilterValue: item => item.vpcModel.entity.id,
                    getSortValue: item => item.vpcModel.entity.displayName
                }
            },
            ViolationTableColumnId.Subnet);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.custom.useAwsInboundExternalSubnetRiskDefinition.violationTable",
            () => ({
                columns: {
                    routeTable: "Associated Route Table",
                    subnet: "Subnet",
                    vpc: "VPC"
                }
            }));

    return (
        <Table
            fetchItems={tableDefinition.filterAndSortItems}
            getItemId={(item: ViolationTableItem) => item.subnetModel.id}
            sortEnabled={true}>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.Subnet]}
                                placeholder={localization.columns.subnet()}/>
                    }
                }}
                id={ViolationTableColumnId.Subnet}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <Entity
                            entityIdOrModel={item.subnetModel}
                            variant="iconTextTypeTenantTags"/>}
                title={localization.columns.subnet()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.Vpc]}
                                placeholder={localization.columns.vpc()}/>
                    }
                }}
                id={ViolationTableColumnId.Vpc}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <Entity
                            entityIdOrModel={item.vpcModel}
                            variant="iconTextTypeTenantTags"/>}
                title={localization.columns.vpc()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.RouteTable]}
                                placeholder={localization.columns.routeTable()}/>
                    }
                }}
                id={ViolationTableColumnId.RouteTable}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <Entity
                            entityIdOrModel={item.routeTableModel}
                            variant="iconTextTypeTenantTags"/>}
                title={localization.columns.routeTable()}/>
        </Table>);
}

enum ViolationTableColumnId {
    RouteTable = "routeTable",
    Subnet = "subnet",
    Vpc = "vpc"
}

class ViolationTableItem {
    constructor(
        public riskItem: Contract.AwsInboundExternalSubnetRiskItem,
        public routeTableModel: Contract.EntityModel,
        public subnetModel: Contract.EntityModel,
        public vpcModel: Contract.EntityModel) {
    }
}