import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useAwsTenantModelStatusTranslator() {
    const localization =
        useLocalization(
            "tenants.aws.hooks.useAwsTenantModelStatusTranslator",
            () => ({
                [Contract.TypeNames.AwsTenantModelStatus]: {
                    [Contract.AwsTenantModelStatus.AccessDenied]: "Access denied",
                    [Contract.AwsTenantModelStatus.Deleting]: "Deleting",
                    [Contract.AwsTenantModelStatus.RegionValidationFailure]: "Failed to query regions",
                    [Contract.AwsTenantModelStatus.RoleAssumeFailure]: "Disconnected",
                    [Contract.AwsTenantModelStatus.RoleReadOnlyPolicyDocumentAccessDenied]: "Invalid permissions",
                    [Contract.AwsTenantModelStatus.RoleReadOnlyPolicyDocumentMismatch]: "Invalid permissions",
                    [Contract.AwsTenantModelStatus.TenantDeletePending]: "Pending deletion",
                    [Contract.AwsTenantModelStatus.TenantNotExist]: "Account not found",
                    [Contract.AwsTenantModelStatus.TenantSuspended]: "Suspended",
                    [Contract.AwsTenantModelStatus.Valid]: "Connected",
                    [Contract.AwsTenantModelStatus.ValidWithIssues]: "Connected with issues"
                }
            }));
    return (status: Contract.AwsTenantModelStatus) => localization[Contract.TypeNames.AwsTenantModelStatus][status]();
}