﻿import { useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import useResizeObserver from "@react-hook/resize-observer";
import React, { ReactNode, useRef, useState } from "react";

type MultiLineEllipsisProps = {
    children: ReactNode;
    maxLines: number;
};

export function MultiLineEllipsis({ children, maxLines }: MultiLineEllipsisProps) {
    const textElementRef = useRef<HTMLDivElement>(null);
    const [overflowing, setOverflowing] = useState(false);
    const [expanded, setExpanded] = useState(false);

    useResizeObserver(
        textElementRef,
        () => {
            if (textElementRef.current) {
                setOverflowing(textElementRef.current.scrollHeight > textElementRef.current.clientHeight);
            }
        });

    const localization =
        useLocalization(
            "common.multiLineEllipsis",
            () => ({
                collapse: "SHOW LESS",
                expand: "SHOW MORE"
            }));
    return (
        <Stack
            spacing={1}
            sx={{ position: "relative" }}>
            <Typography
                ref={textElementRef}
                sx={{
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp:
                        expanded
                            ? undefined
                            : maxLines,
                    whiteSpace: "pre-wrap",
                    wordBreak: "break-word"
                }}>
                {children}
            </Typography>
            {(overflowing || expanded) &&
                <Typography
                    sx={{
                        cursor: "pointer",
                        fontSize: "11px",
                        fontWeight: 600,
                        textDecoration: "underLine"
                    }}
                    onClick={
                        event => {
                            setExpanded(!expanded);

                            event.preventDefault();
                            event.stopPropagation();
                        }}>
                    {expanded
                        ? localization.collapse()
                        : localization.expand()}
                </Typography>}
        </Stack>);
}