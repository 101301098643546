import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciFssMountTargetExportStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciFssMountTargetExportStatusTranslator",
            () => ({
                [Contract.TypeNames.OciFssMountTargetExportStatus]: {
                    [Contract.OciFssMountTargetExportStatus.Active]: "Active",
                    [Contract.OciFssMountTargetExportStatus.Creating]: "Creating",
                    [Contract.OciFssMountTargetExportStatus.Deleted]: "Deleted",
                    [Contract.OciFssMountTargetExportStatus.Deleting]: "Deleting"
                }
            }));
    return (status: Contract.OciFssMountTargetExportStatus) =>
        localization[Contract.TypeNames.OciFssMountTargetExportStatus][status]();
}