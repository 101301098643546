import { ItemWithValidation, Loading, UnexpectedError } from "@infrastructure";
import React, { useMemo } from "react";
import { Contract } from "../../controllers";
import { Datadog, Jira, Mail, Options as MessageOptions, QRadar, SendToOwner, ServiceNow, Slack, Splunk, Sqs, Teams, Webhook } from "./components";

export type DeliveryProps = {
    delivery: ItemWithValidation<Contract.Delivery>;
    disabled: boolean;
    iconVisible?: boolean;
    messageOptions?: MessageOptions;
    onChange: (delivery: ItemWithValidation<Contract.Delivery>) => void;
    scopeId: string;
};

export function Delivery(props: DeliveryProps) {
    const deliveryItem =
        useMemo(
            () => {
                switch (props.delivery.typeName as Contract.DeliveryDerivedTypeNames) {
                    case Contract.DeliveryDerivedTypeNames.MailDelivery:
                        return <Mail {...props}/>;
                    case Contract.DeliveryDerivedTypeNames.ResourceOwnerMailDelivery:
                        return <SendToOwner {...props}/>;
                    case Contract.DeliveryDerivedTypeNames.SlackDelivery:
                        return <Slack {...props}/>;
                    case Contract.DeliveryDerivedTypeNames.TeamsDelivery:
                        return <Teams {...props}/>;
                    case Contract.DeliveryDerivedTypeNames.DatadogDelivery:
                        return <Datadog {...props}/>;
                    case Contract.DeliveryDerivedTypeNames.QRadarDelivery:
                        return <QRadar {...props}/>;
                    case Contract.DeliveryDerivedTypeNames.ServiceNowDelivery:
                        return <ServiceNow {...props}/>;
                    case Contract.DeliveryDerivedTypeNames.JiraDelivery:
                        return <Jira {...props}/>;
                    case Contract.DeliveryDerivedTypeNames.SplunkDelivery:
                        return <Splunk {...props}/>;
                    case Contract.DeliveryDerivedTypeNames.SqsDelivery:
                        return <Sqs {...props}/>;
                    case Contract.DeliveryDerivedTypeNames.WebhookDelivery:
                        return <Webhook {...props}/>;
                    default:
                        throw new UnexpectedError("delivery.typeName");
                }
            },
            [props]);

    return (
        <Loading>
            {deliveryItem}
        </Loading>);
}