import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetContainerImageWorkloadAnalysisRiskContext, useGetVirtualMachinesWorkloadAnalysisRiskContext } from "../../../../";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../common";
import { Vulnerabilities } from "../../../../../../../../../../WorkloadAnalysis";
import { RiskDefinition, RiskDefinitionContextItemImpact, RiskDefinitionSection } from "../../../../../../../utilities";
import { useWorkloadResourceOperatingSystemRiskRemediationStep } from "./useWorkloadResourceOperatingSystemRiskRemediationStep";

export function useContainerImageOperatingSystemEndOfLifeRiskDefinition(riskModel: Contract.RiskModel): RiskDefinition {
    const containerImageOperatingSystemEndOfLifeRisk = riskModel.risk as Contract.ContainerImageOperatingSystemEndOfLifeRisk;
    const workloadResourceOperatingSystemRiskRemediationStep = useWorkloadResourceOperatingSystemRiskRemediationStep();
    const containerImageModels = _.as<Contract.IContainerImageModel[]>(entityModelStore.useGet(containerImageOperatingSystemEndOfLifeRisk.containerImageIds));

    const operatingSystemEndOfLifeDate = containerImageOperatingSystemEndOfLifeRisk.operatingSystemEndOfLifeDate;
    const operatingSystemExtendedSupport =
        _(containerImageModels).
            filter(containerImageModel => !_.isNil(containerImageModel.workloadAnalysisOperatingSystemExtendedSupport)).
            first()?.
            workloadAnalysisOperatingSystemExtendedSupport;
    const operatingSystemReleaseNotesUrl =
        _(containerImageModels).
            filter(containerImageModel => !_.isNil(containerImageModel.operatingSystemReleaseNotesUrl)).
            first()?.
            operatingSystemReleaseNotesUrl;
    const operatingSystemType = containerImageOperatingSystemEndOfLifeRisk.operatingSystemType;
    const operatingSystemDisplayName = containerImageOperatingSystemEndOfLifeRisk.operatingSystemDisplayName;

    const getContainerImageWorkloadAnalysisRiskContext = useGetContainerImageWorkloadAnalysisRiskContext();
    const geVirtualMachinesWorkloadAnalysisRiskContext = useGetVirtualMachinesWorkloadAnalysisRiskContext(containerImageOperatingSystemEndOfLifeRisk);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.workloadAnalysis.hooks.useWorkloadAnalysisDefinition.hooks.useContainerImageOperatingSystemEndOfLifeRiskDefinition",
            () => ({
                description: {
                    extendedSupport: "The container image on {{riskedEntity}}, stored in {{containerImageRepository}}, is running operating system {{operatingSystem}}, which is End-of-Life as of {{ operatingSystemEndOfLifeDate | TimeFormatter.shortDate}} (based on extended support date)",
                    nonExtendedSupport: "The container image on {{riskedEntity}}, stored in {{containerImageRepository}}, is running operating system {{operatingSystem}}, which is End-of-Life as of {{ operatingSystemEndOfLifeDate | TimeFormatter.shortDate}} (based on end of security updates support date)"
                },
                sections: {
                    vulnerabilities: "Vulnerabilities"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        (operatingSystemExtendedSupport
            ? localization.description.extendedSupport
            : localization.description.nonExtendedSupport)({
            containerImageRepository:
                <Entity
                    entityIdOrModel={containerImageOperatingSystemEndOfLifeRisk.containerImageRepositoryReference}
                    variant="text"/>,
            operatingSystem: operatingSystemDisplayName,
            operatingSystemEndOfLifeDate,
            riskedEntity:
                <Entity
                    entityIdOrModel={containerImageOperatingSystemEndOfLifeRisk.entityId}
                    variant="text"/>
        }),
        () =>
            [
                workloadResourceOperatingSystemRiskRemediationStep(
                    operatingSystemReleaseNotesUrl,
                    operatingSystemType,
                    containerImageOperatingSystemEndOfLifeRisk.windowsUpdateDocumentationUrl)
            ],
        riskModel,
        () => {
            const containerImageWorkloadAnalysisRiskContext = getContainerImageWorkloadAnalysisRiskContext(containerImageOperatingSystemEndOfLifeRisk);
            const virtualMachinesWorkloadAnalysisRiskContext = geVirtualMachinesWorkloadAnalysisRiskContext();

            return [
                containerImageWorkloadAnalysisRiskContext.generalInformation,
                containerImageWorkloadAnalysisRiskContext.virtualMachines,
                containerImageWorkloadAnalysisRiskContext.operatingSystem,
                ...containerImageOperatingSystemEndOfLifeRisk.packageScope === Contract.WorkloadResourceOperatingSystemRiskPackageScope.All
                    ? []
                    : [
                        containerImageWorkloadAnalysisRiskContext.getVulnerabilities(
                            true,
                            true),
                        containerImageWorkloadAnalysisRiskContext.getSeverityVulnerabilities(Contract.Severity.Critical),
                        containerImageWorkloadAnalysisRiskContext.getSeverityVulnerabilities(Contract.Severity.High)
                    ],
                virtualMachinesWorkloadAnalysisRiskContext.sensitive?.withImpact(RiskDefinitionContextItemImpact.SeverityIncreased),
                virtualMachinesWorkloadAnalysisRiskContext.inboundExternalAccessScope?.withImpact(RiskDefinitionContextItemImpact.SeverityIncreased),
                virtualMachinesWorkloadAnalysisRiskContext.identityPermissionActionSeverity?.withImpact(RiskDefinitionContextItemImpact.SeverityIncreased),
                virtualMachinesWorkloadAnalysisRiskContext.stopped?.withImpact(RiskDefinitionContextItemImpact.SeverityDecreased),
                containerImageWorkloadAnalysisRiskContext.getOpenRiskedEntityRisksContextItem(containerImageOperatingSystemEndOfLifeRisk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <Vulnerabilities
                        entityIds={containerImageOperatingSystemEndOfLifeRisk.containerImageIds}
                        severityFilterValues={[
                            Contract.Severity.Critical,
                            Contract.Severity.High,
                            Contract.Severity.Medium]}
                        variant="risk"/>,
                    localization.sections.vulnerabilities(),
                    {
                        expandable: true
                    })]
        });
}