import { Action0, CheckboxField, Dropdown, EmptyMessageText, ItemSelectorField, MemoItemRenderer, SearchList, SearchListCategory, SearchListSelectors, Sx, useUncaptureValue } from "@infrastructure";
import { SxProps, useTheme } from "@mui/material";
import _ from "lodash";
import React, { ReactElement, ReactNode, useState } from "react";
import { TextField } from "./components";

type MultiSelectProps = {
    categories?: SearchListCategory[];
    checkboxSx?: SxProps;
    children?: (item: any) => ReactNode;
    disabled?: boolean;
    emptyMessageText?: EmptyMessageText;
    fieldOptions?: MultiSelectFieldOptions;
    fullWidth?: boolean;
    getItemText?: (item: any) => string | string[];
    icon?: ReactElement;
    items: any[];
    mandatoryItems?: any[];
    mandatoryItemsTooltipText?: string;
    onClose?: Action0;
    onSelectedItemsChanged: (items: any[]) => void;
    placeholder?: string;
    readonly?: boolean;
    selectedItems: any[];
    selectedItemsTranslator?: (items: any[]) => string;
    sorted?: boolean;
};

type MultiSelectFieldOptions = {
    dense?: boolean;
    sx?: SxProps;
    variant?: "itemSelector" | "text" | "textLarge";
};

export function MultiSelect({ categories, checkboxSx, children: renderItem, disabled = false, emptyMessageText, fieldOptions, fullWidth, getItemText, icon, items, mandatoryItems, mandatoryItemsTooltipText, onClose, onSelectedItemsChanged, placeholder, readonly, selectedItems, selectedItemsTranslator, sorted = true }: MultiSelectProps) {
    const [open, setOpen] = useState(false);
    const uncaptureSelectedItems = useUncaptureValue(selectedItems);

    const theme = useTheme();
    return (
        <Dropdown
            disabled={disabled}
            fullWidth={fullWidth}
            open={open}
            popoverElement={
                <SearchList
                    categories={categories}
                    dense={true}
                    dependencies={[selectedItems]}
                    disablePadding={true}
                    emptyMessageText={emptyMessageText}
                    headerElement={
                        !readonly && items.length > 5
                            ? <SearchListSelectors
                                items={items}
                                selectedItems={selectedItems}
                                sx={{ padding: 0 }}
                                onSelectedItemsChanged={selectedItems => onSelectedItemsChanged(selectedItems ?? [])}/>
                            : undefined}
                    itemOptions={{
                        getDependencies:
                            item => [
                                _.includes(mandatoryItems, item),
                                _.includes(selectedItems, item)
                            ],
                        getSortValue: getItemText,
                        render:
                            item =>
                                <CheckboxField
                                    checked={
                                        _.includes(mandatoryItems, item) ||
                                        _.includes(selectedItems, item)}
                                    disabled={
                                        readonly ||
                                        _.includes(mandatoryItems, item)}
                                    sx={
                                        Sx.combine(
                                            {
                                                width:
                                                    fullWidth
                                                        ? "100%"
                                                        : undefined
                                            },
                                            checkboxSx)}
                                    tooltipText={
                                        _.includes(mandatoryItems, item)
                                            ? mandatoryItemsTooltipText
                                            : undefined}
                                    onChange={
                                        () =>
                                            uncaptureSelectedItems(
                                                selectedItems =>
                                                    _.includes(selectedItems, item)
                                                        ? onSelectedItemsChanged(_.without(selectedItems, item))
                                                        : onSelectedItemsChanged(_.concat(selectedItems, item)))}>
                                    {_.isNil(renderItem)
                                        ? item
                                        : <MemoItemRenderer
                                            render={renderItem}
                                            renderArguments={[item]}/>}
                                </CheckboxField>,
                        spacing: 1
                    }}
                    items={items}
                    listOptions={{ sx: { padding: theme.spacing(0, 1) } }}
                    searchOptions={{ enabled: !_.isNil(getItemText) }}
                    sorted={sorted}
                    variant="dropdown"/>}
            popoverElementContainerSx={{ padding: 0 }}
            onClose={
                () => {
                    setOpen(false);
                    onClose?.();
                }}
            onOpen={() => setOpen(true)}>
            {fieldOptions?.variant === "itemSelector"
                ? <ItemSelectorField
                    dense={fieldOptions?.dense}
                    disabled={disabled}
                    open={open}
                    placeholder={placeholder}
                    selection={
                        _.isNil(selectedItemsTranslator) ||
                        _.isEmpty(selectedItems)
                            ? undefined
                            : selectedItemsTranslator(selectedItems)}
                    selectionCount={
                        _.isNil(selectedItemsTranslator)
                            ? selectedItems.length
                            : undefined}
                    sx={fieldOptions?.sx}/>
                : <TextField
                    disabled={disabled}
                    fieldVariant={fieldOptions?.variant ?? "text"}
                    icon={icon}
                    placeholder={placeholder}
                    selectedItems={selectedItems}
                    selectedItemsTranslator={selectedItemsTranslator!}/>}
        </Dropdown>);
}