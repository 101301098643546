import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsEsDomainRiskContext } from "../contexts";

export function useAwsEsDomainNodeCommunicationEncryptionDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsEsDomainNodeCommunicationEncryptionDisabledRisk;
    const domainModel = entityModelStore.useGet(risk.entityId) as Contract.AwsEsDomainModel;
    const domain = domainModel.entity as Contract.AwsEsDomain;

    const getAwsEsDomainRiskContext = useGetAwsEsDomainRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEsDomainNodeCommunicationEncryptionDisabledRiskDefinition",
            () => ({
                description: "{{domain}} is not enabled with node to node encryption",
                sections: {
                    resolution: {
                        step1: "In the **Encryption** section, check the **Node-to-node encryption** option",
                        step2: "Click **Save changes** to confirm"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description(
            {
                domain:
                    <Entity
                        entityIdOrModel={domainModel}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="typeText"/>
            }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Es,
                AwsConsoleUrlBuilder.getEsDomainEditSecurityConfigUrl(domain)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2()
        ],
        riskModel,
        () => {
            const domainRiskContext = getAwsEsDomainRiskContext(domainModel);
            return [
                domainRiskContext.generalInformation,
                domainRiskContext.sensitive,
                domainRiskContext.storageSize,
                domainRiskContext.encryption,
                domainRiskContext.vpc,
                domainRiskContext.accessLevel,
                domainRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}