import { DataTableActions, DataTableColumn, DataTableColumnRenderProps, Optional, optionalTableCell, useChangeEffect, useLocalization } from "@infrastructure";
import { Button } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useRef, useState } from "react";
import { Contract, Entity, entityModelStore, InlineTextViewer, NetworkInboundAccessTypeCell, Region, riskPolicyModelStore, SecretExclusionAction, UserHelper, useTheme } from "../../../../../../../../../../../../../../common";
import { useAwsEc2InstanceStatusTranslator } from "../../../../../../../../../../../Entities/hooks";
import { Table } from "../../../../../../components";
import { NetworkAccessScopeCell } from "../../../../components";

type InstanceTableProps = {
    instanceIds: string[];
    riskModel: Contract.AwsEc2InstanceUserDataSecretExistsRiskModel;
};

export function InstanceTable({ instanceIds, riskModel }: InstanceTableProps) {
    const instanceModels = entityModelStore.useGet(instanceIds) as Contract.AwsEc2InstanceModel[];
    const { riskPolicyConfiguration } = riskPolicyModelStore.useGet(riskModel.risk.policyId);
    const [exclude, setExclude] = useState<boolean>(false);

    const ec2InstanceStatusTranslator = useAwsEc2InstanceStatusTranslator();
    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => dataTableActionsRef.current?.reset(),
        [instanceIds]);

    const tableItems =
        _.map(
            instanceModels,
            instanceModel =>
                new InstanceTableItem(
                    instanceModel,
                    riskModel.risk.instanceIdToUserDataSecretExistsTextBlockMap![instanceModel.id]));

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceUserDataSecretExistsRiskDefinition.instanceTable",
            () => ({
                columns: {
                    instance: "Instance",
                    networkInboundAccessType: "Exposure",
                    networkInboundExternalAccessScope: "Exposure Scope",
                    regionSystemName: "Region",
                    status: "State",
                    userData: {
                        excludeAction: "Exclude Secret",
                        title: "User Data"
                    }
                }
            }));

    const theme = useTheme();
    return (
        <Fragment>
            {exclude &&
                <SecretExclusionAction
                    riskPolicyConfiguration={riskPolicyConfiguration}
                    onExcludeChanged={setExclude}/>}
            <Table
                dataTableActionsRef={dataTableActionsRef}
                fetchItems={() => tableItems}
                getItemId={(item: InstanceTableItem) => item.instanceModel.id}>
                <DataTableColumn
                    id={InstanceTableColumnId.Instance}
                    render={
                        ({ item }: DataTableColumnRenderProps<InstanceTableItem>) =>
                            <Entity
                                entityIdOrModel={item.instanceModel}
                                variant="iconTextTenant"/>}
                    title={localization.columns.instance()}/>
                <DataTableColumn
                    id={InstanceTableColumnId.UserData}
                    render={
                        optionalTableCell<InstanceTableItem>(
                            ({ instanceModel, textBlock }) =>
                                instanceModel.unknown
                                    ? undefined
                                    : <InlineTextViewer
                                        actionsElement={
                                            UserHelper.hasAnyScopePermissions(riskModel.risk.scopeIds, Contract.IdentityPermission.SecurityWrite)
                                                ? <Button
                                                    sx={{
                                                        "&:hover": {
                                                            backgroundColor: theme.palette.textEditor.background
                                                        },
                                                        backgroundColor: theme.palette.textEditor.background,
                                                        margin: theme.spacing(1),
                                                        padding: theme.spacing(1, 3)
                                                    }}
                                                    variant="outlined"
                                                    onClick={() => setExclude(true)}>
                                                    {localization.columns.userData.excludeAction()}
                                                </Button>
                                                : undefined}
                                        actionsElementSx={{ backgroundColor: "unset" }}
                                        dialogTitle={localization.columns.userData.title()}
                                        highlightedLines={textBlock.highlightedLines}
                                        startLine={textBlock.startLine}
                                        text={textBlock.text}
                                        title={localization.columns.userData.title()}/>)}
                    title={localization.columns.userData.title()}/>
                <DataTableColumn
                    id={InstanceTableColumnId.NetworkInboundAccessType}
                    render={
                        ({ item }: DataTableColumnRenderProps<InstanceTableItem>) => (
                            <NetworkInboundAccessTypeCell
                                networkInboundAccessType={(item.instanceModel.entityNetwork as Optional<Contract.AwsNetworkedResourceStateNetwork>)?.inboundAccessType}
                                variant="risk"/>)}
                    title={localization.columns.networkInboundAccessType()}/>
                <DataTableColumn
                    id={InstanceTableColumnId.NetworkInboundExternalAccessScope}
                    render={
                        ({ item }: DataTableColumnRenderProps<InstanceTableItem>) =>
                            <NetworkAccessScopeCell item={item.instanceModel}/>}
                    title={localization.columns.networkInboundExternalAccessScope()}/>
                <DataTableColumn
                    id={InstanceTableColumnId.Status}
                    render={
                        optionalTableCell<InstanceTableItem>(
                            ({ instanceModel }) =>
                                instanceModel.unknown
                                    ? undefined
                                    : ec2InstanceStatusTranslator((instanceModel.entity as Contract.AwsEc2Instance).status))}
                    title={localization.columns.status()}/>
                <DataTableColumn
                    id={InstanceTableColumnId.RegionSystemName}
                    render={
                        ({ item }: DataTableColumnRenderProps<InstanceTableItem>) =>
                            <Region regionId={item.instanceModel.entity.regionId}/>}
                    title={localization.columns.regionSystemName()}/>
            </Table>
        </Fragment>);
}

class InstanceTableItem {
    constructor(
        public instanceModel: Contract.AwsEc2InstanceModel,
        public textBlock: Contract.TextBlock) {
    }
}

enum InstanceTableColumnId {
    Actions = "actions",
    Instance = "instance",
    NetworkInboundAccessType = "networkInboundAccessType",
    NetworkInboundExternalAccessScope = "networkInboundExternalAccessScope",
    RegionSystemName = "regionSystemName",
    Status = "status",
    UserData = "userData"
}