import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ColoredOktaIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M1 12C1 5.92048 5.92048 1 12 1C18.0795 1 23 5.92048 23 12C23 18.0795 18.0795 23 12 23C5.92048 23 1 18.0795 1 12ZM6.48906 12C6.48906 15.0398 8.96024 17.5109 12 17.5109C15.0398 17.5109 17.5109 15.0398 17.5109 12C17.5109 8.96024 15.0398 6.48907 12 6.48907C8.96024 6.48907 6.48906 8.96024 6.48906 12Z"
                fill="#007DC1"
                fillRule="evenodd"/>
        </SvgIcon>);
}