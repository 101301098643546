import { ActionMenuItem, DataTableColumnRenderProps, DeleteIcon, EditIcon, Menu, Message, useLocalization } from "@infrastructure";
import { CircularProgress, Stack } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useSetWebhookContext } from "../../..";
import { ConfigurationController, Contract, scopeSystemEntityModelStore, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../../../../../../../common";

export function ActionsCell({ item }: DataTableColumnRenderProps<Contract.ScopeSystemEntityModel>) {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const setWebhookContext = useSetWebhookContext();

    const [deleteEndpointExecuting, setDeleteEndpointExecuting] = useState(false);
    const [deleteEndpointError, setDeleteEndpointError] = useState(false);

    async function deleteEndpoint() {
        setDeleteEndpointExecuting(true);
        setDeleteEndpointError(false);

        try {
            await ConfigurationController.deleteWebhookEndpoint(new Contract.ConfigurationControllerDeleteWebhookEndpointRequest(item.configuration.id));
            await scopeSystemEntityModelStore.notify();
        } catch {
            setDeleteEndpointError(true);
        }

        setDeleteEndpointExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useWebhooksItems.webhook.table.actionsCell",
            () => ({
                actions: {
                    delete: {
                        error: "Failed to delete",
                        prompt: "Are you sure you want to delete the Endpoint {{endpointName}}?",
                        title: "Delete"
                    },
                    edit: "Edit"
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            {deleteEndpointExecuting && (
                <CircularProgress
                    size={theme.spacing(2)}
                    variant="indeterminate"/>)}
            {deleteEndpointError && (
                <Message
                    level="error"
                    title={localization.actions.delete.error()}
                    variant="minimal"/>)}
            {scopeNodeModel.configuration.id === item.configuration.scopeId &&
                <Fragment>
                    <Menu
                        itemsOrGetItems={[
                            new ActionMenuItem(
                                () =>
                                    setWebhookContext(
                                        webhookContext => ({
                                            ...webhookContext,
                                            addOrEditOpen: item
                                        })),
                                localization.actions.edit(),
                                {
                                    disabled: deleteEndpointExecuting,
                                    icon: <EditIcon/>
                                }),
                            new ActionMenuItem(
                                () => deleteEndpoint(),
                                localization.actions.delete.title(),
                                {
                                    confirmOptions: {
                                        message: localization.actions.delete.prompt({ endpointName: (item.configuration as Contract.WebhookEndpointConfiguration).name })
                                    },
                                    disabled: deleteEndpointExecuting,
                                    icon: <DeleteIcon/>
                                })
                        ]}/>
                </Fragment>}
        </Stack>);
}