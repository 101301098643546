import { useMemo } from "react";
import { useAzureAuthorizationRoleAssignmentResourceDefinition, useAzureAuthorizationRoleDefinitionDefinition, useAzureAuthorizationRoleManagementPolicyAssignmentDefinition, useAzureContainerImageDefinition, useAzureDefaultResourceDefinition, useAzureKubernetesResourceDefinition, useAzureResourceGroupResourceDefinition, useAzureResourcesResourceGroupDefinition, useAzureTenantEntityDefinition } from ".";
import { DefinitionOptions } from "../../..";
import { Contract, TypeHelper } from "../../../../../../../../../../common";

export function useAzureResourceDefinition(entityModel: Contract.EntityModel, options?: DefinitionOptions) {
    const useDefinition =
        useMemo(
            () => {
                switch (entityModel.entity.typeName) {
                    case Contract.TypeNames.AzureAuthorizationRoleAssignmentResource:
                        return useAzureAuthorizationRoleAssignmentResourceDefinition;
                    case Contract.TypeNames.AzureAuthorizationRoleDefinition:
                        return useAzureAuthorizationRoleDefinitionDefinition;
                    case Contract.TypeNames.AzureAuthorizationRoleManagementPolicyAssignment:
                        return useAzureAuthorizationRoleManagementPolicyAssignmentDefinition;
                    case Contract.TypeNames.AzureContainerImage:
                        return useAzureContainerImageDefinition;
                    case Contract.TypeNames.AzureResourcesResourceGroup:
                        return useAzureResourcesResourceGroupDefinition;
                    case Contract.TypeNames.AzureTenantEntity:
                        return useAzureTenantEntityDefinition;
                    default:
                        if (TypeHelper.extendOrImplement(entityModel.entity.typeName, Contract.TypeNames.AzureAuthorizationRoleAssignmentResource)) {
                            return useAzureAuthorizationRoleAssignmentResourceDefinition;
                        }
                        if (TypeHelper.extendOrImplement(entityModel.entity.typeName, Contract.TypeNames.AzureKubernetesResource)) {
                            return useAzureKubernetesResourceDefinition;
                        }
                        if (TypeHelper.extendOrImplement(entityModel.entity.typeName, Contract.TypeNames.AzureResourceGroupResource)) {
                            return useAzureResourceGroupResourceDefinition;
                        }

                        return useAzureDefaultResourceDefinition;
                }
            },
            []);

    return useDefinition(entityModel as Contract.AzureResourceModel, options);
}