import { UnexpectedError } from "@infrastructure";
import { Contract } from "../controllers";

export enum RiskPoliciesType {
    Cloud = "cloud",
    Code = "code",
    KubernetesAdmissionController = "kubernetesAdmissionController"
}

export enum RiskPoliciesView {
    BuiltIn = "builtIn",
    Custom = "custom"
}

export class RiskPoliciesHelper {
    public static getGetBuiltInRiskPolicyModelsRequest(riskPoliciesType: RiskPoliciesType) {
        switch (riskPoliciesType) {
            case RiskPoliciesType.Cloud:
                return Contract.RiskControllerGetBuiltInCloudRiskPolicyModelsRequest;
            case RiskPoliciesType.Code:
                return Contract.RiskControllerGetBuiltInCodeRiskPolicyModelsRequest;
            case RiskPoliciesType.KubernetesAdmissionController:
                return Contract.RiskControllerGetBuiltInKubernetesAdmissionControllerRiskPolicyModelsRequest;
            default:
                throw new UnexpectedError("RiskPoliciesHelper.getGetBuiltInRiskPolicyModelsRequest", riskPoliciesType);
        }
    }
}