import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract, TypeHelper } from "../../../../../../../../../../../../../common";
import { useAwsEc2InstanceInstanceProfileNotExistRiskInstanceDefinition, useAwsEc2InstanceInstanceProfileNotExistRiskLaunchConfigurationDefinition, useAwsEc2InstanceInstanceProfileNotExistRiskLaunchTemplateDefinition, useAwsEc2InstanceInstanceProfileNotExistRiskTagResourceGroupDefinition } from "./hooks";

export function useAwsEc2InstanceInstanceProfileNotExistRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsEc2InstanceInstanceProfileNotExistRisk;

    const useDefinition =
        useMemo(
            () => {
                switch (risk.aggregatingEntityTypeName) {
                    case Contract.TypeNames.AwsAutoScalingLaunchConfiguration:
                        return useAwsEc2InstanceInstanceProfileNotExistRiskLaunchConfigurationDefinition;
                    case Contract.TypeNames.AwsEc2Instance:
                        return useAwsEc2InstanceInstanceProfileNotExistRiskInstanceDefinition;
                    case Contract.TypeNames.AwsEc2LaunchTemplate:
                        return useAwsEc2InstanceInstanceProfileNotExistRiskLaunchTemplateDefinition;
                    default:
                        if (TypeHelper.extendOrImplement(risk.aggregatingEntityTypeName, Contract.TypeNames.AwsTagResourceGroup)) {
                            return useAwsEc2InstanceInstanceProfileNotExistRiskTagResourceGroupDefinition;
                        }

                        throw new UnexpectedError("risk.aggregatingEntityTypeName", risk.aggregatingEntityTypeName);
                }
            },
            []);

    return useDefinition(riskModel as Contract.AwsEc2InstanceInstanceProfileNotExistRiskModel);
}