﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function NotConnectedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58472_1133)">
                <path d="M14.4424 5.63749C13.5125 4.87679 12.3784 4.35206 11.1591 4.12571C9.74896 3.86397 8.28824 4.01298 6.96796 4.5539L6.94318 4.56449C5.74364 5.0978 4.72227 5.91906 3.97569 6.95305L3.80887 7.1946C3.27085 8.00968 2.91417 8.92207 2.76161 9.87504L2.75539 9.91341L2.55152 10.0291C1.86716 10.4364 1.28279 10.9871 0.845385 11.6447C0.255775 12.5311 -0.0384815 13.5698 0.00403642 14.6226L0.00325498 14.8465L0.0132878 14.9849C0.18868 16.1879 0.717667 17.2684 1.53515 18.1247L2.87453 16.8289C2.36676 16.3035 2.02591 15.6571 1.87833 14.9316L1.84892 14.7661L1.84941 14.5839C1.82248 13.8977 2.00426 13.2561 2.37331 12.7012C2.74876 12.1367 3.30111 11.6876 3.96015 11.4156L4.48045 11.2009L4.55772 10.3606C4.65133 9.55659 4.96196 8.76213 5.46241 8.06903L5.63415 7.84442C6.10858 7.25789 6.71331 6.77199 7.41393 6.4169L7.67169 6.29459L7.65631 6.30052C8.65269 5.89229 9.7601 5.77933 10.8284 5.97762C11.6572 6.13148 12.43 6.46667 13.0854 6.95031L14.4424 5.63749Z"/>
                <path d="M4.63538 17.9081L3.16546 19.3302L4.63538 17.9081Z"/>
                <path d="M14.4797 8.3842L16.1953 6.72445L14.4797 8.3842Z"/>
                <path d="M8.22659 19.9994L18.7037 19.9998C19.3342 19.9767 19.9235 19.8451 20.5845 19.5997C21.2822 19.3409 21.9163 18.9446 22.4452 18.4356C22.9785 17.9223 23.3926 17.3058 23.659 16.6262L23.7493 16.3749C23.9226 15.8379 24.0033 15.3176 24 14.7947L23.9891 14.5102C24.0243 13.397 23.6385 12.2423 22.8988 11.3013L22.7376 11.1062C22.1855 10.4696 21.4849 9.97052 20.6965 9.6463L20.4988 9.56988L20.4715 9.48418C20.3589 9.17031 20.2069 8.87035 20.0187 8.59105L18.6476 9.91748C18.7347 10.0959 18.7957 10.284 18.8288 10.4772L18.9318 11.0776L19.5113 11.2274C20.2965 11.4303 20.9819 11.8726 21.4583 12.4786C21.9257 13.0732 22.1656 13.7914 22.1441 14.5204L22.1546 14.8394C22.1559 15.1255 22.1065 15.4435 22.0049 15.7594L21.9346 15.9545C21.7786 16.3513 21.5177 16.7397 21.1778 17.0668C20.8334 17.3984 20.4162 17.6591 19.9532 17.8309C19.4619 18.0132 19.0578 18.1035 18.6697 18.1181L10.1719 18.1174L8.22659 19.9994Z"/>
                <path
                    clipRule="evenodd"
                    d="M22.719 1.80469C23.1031 2.20162 23.0926 2.83469 22.6956 3.21871L2.69553 22.5679C2.2986 22.952 1.66552 22.9415 1.28151 22.5446C0.897494 22.1476 0.907965 21.5146 1.3049 21.1305L21.305 1.7813C21.7019 1.39728 22.335 1.40776 22.719 1.80469Z"
                    fillRule="evenodd"/>
            </g>
            <defs>
                <clipPath id="clip0_58472_1133">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}