import { DataTableColumn, DataTableColumnRenderProps, EnumValuesFilter, optionalTableCell, PagedValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useOciCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions, useEntityTypeNameTranslator } from "../../../../../../../../../../common";
import { useOciComputeInstanceStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useOciComputeInstanceDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useOciCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const instanceStatusTranslator = useOciComputeInstanceStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.oci.useOciComputeInstanceDefinition",
            () => ({
                columns: {
                    metadataServiceVersion1Enabled: {
                        false: "Version 2 only",
                        title: "Instance Metadata Service",
                        true: "Versions 1 and 2"
                    },
                    primaryPrivateIpAddress: "Private IP Address",
                    primaryPublicIpAddress: "Public IP Address",
                    security: {
                        enabled: {
                            false: "Disabled",
                            true: "Enabled"
                        },
                        memoryEncryptionEnabled: "Confidential Computing",
                        secureBootEnabled: "Secure Boot"
                    },
                    shape: "Shape",
                    status: "Status",
                    volumeEncryptionInTransitEnabled: {
                        false: "Disabled",
                        title: "In-Transit Encryption",
                        true: "Enabled"
                    }
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciComputeInstanceModel) => ({
                            [localization.columns.status()]: instanceStatusTranslator((item.entity as Contract.OciComputeInstance).status)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.OciComputeInstanceStatus}
                                enumTypeTranslator={instanceStatusTranslator}
                                placeholder={localization.columns.status()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciComputeInstanceStatus}
                itemProperty={(item: Contract.OciComputeInstanceModel) => instanceStatusTranslator((item.entity as Contract.OciComputeInstance).status)}
                key={Contract.EntityModelProperty.OciComputeInstanceStatus}
                title={localization.columns.status()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.OciComputeInstanceModel>(
                        Contract.TypeNames.OciComputeInstanceConfiguration,
                        item => [item.instanceConfigurationIdReference],
                        entityTypeNameTranslator(Contract.TypeNames.OciComputeInstanceConfiguration))}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciComputeInstanceInstanceConfiguration)}
                                placeholder={entityTypeNameTranslator(Contract.TypeNames.OciComputeInstanceConfiguration)}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciComputeInstanceInstanceConfiguration}
                key={Contract.EntityModelProperty.OciComputeInstanceInstanceConfiguration}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OciComputeInstanceModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.instanceConfigurationIdReference}
                            entityTypeName={Contract.TypeNames.OciComputeInstanceConfiguration}/>}
                selectorOptions={{ default: false }}
                title={entityTypeNameTranslator(Contract.TypeNames.OciComputeInstanceConfiguration)}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciComputeInstanceModel) => ({
                            [localization.columns.shape()]: (item.entity as Contract.OciComputeInstance).shape
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciComputeInstanceShape)}
                                placeholder={localization.columns.shape()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciComputeInstanceShape}
                key={Contract.EntityModelProperty.OciComputeInstanceShape}
                render={optionalTableCell<Contract.OciComputeInstanceModel>(item => (item.entity as Contract.OciComputeInstance).shape)}
                selectorOptions={{ default: false }}
                title={localization.columns.shape()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciComputeInstanceModel) => ({
                            [localization.columns.volumeEncryptionInTransitEnabled.title()]:
                                (item.entity as Contract.OciComputeInstance).volumeEncryptionInTransitEnabled
                                    ? localization.columns.volumeEncryptionInTransitEnabled.true()
                                    : localization.columns.volumeEncryptionInTransitEnabled.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.volumeEncryptionInTransitEnabled.title()}>
                                <ValuesFilterItem
                                    title={localization.columns.volumeEncryptionInTransitEnabled.false()}
                                    value={false}/>
                                <ValuesFilterItem
                                    title={localization.columns.volumeEncryptionInTransitEnabled.true()}
                                    value={true}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.OciComputeInstanceVolumeEncryptionInTransitEnabled}
                itemProperty={
                    (item: Contract.EntityModel) =>
                        (item.entity as Contract.OciComputeInstance).volumeEncryptionInTransitEnabled
                            ? localization.columns.volumeEncryptionInTransitEnabled.true()
                            : localization.columns.volumeEncryptionInTransitEnabled.false()}
                key={Contract.EntityModelProperty.OciComputeInstanceVolumeEncryptionInTransitEnabled}
                selectorOptions={{ default: false }}
                title={localization.columns.volumeEncryptionInTransitEnabled.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciComputeInstanceModel) => ({
                            [localization.columns.security.secureBootEnabled()]:
                                (item.entity as Contract.OciComputeInstance).security.secureBootEnabled
                                    ? localization.columns.security.enabled.true()
                                    : localization.columns.security.enabled.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.security.secureBootEnabled()}>
                                <ValuesFilterItem
                                    title={localization.columns.security.enabled.false()}
                                    value={false}/>
                                <ValuesFilterItem
                                    title={localization.columns.security.enabled.true()}
                                    value={true}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.OciComputeInstanceSecureBootEnabled}
                itemProperty={
                    (item: Contract.EntityModel) =>
                        (item.entity as Contract.OciComputeInstance).security.secureBootEnabled
                            ? localization.columns.security.enabled.true()
                            : localization.columns.security.enabled.false()}
                key={Contract.EntityModelProperty.OciComputeInstanceSecureBootEnabled}
                selectorOptions={{ default: false }}
                title={localization.columns.security.secureBootEnabled()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciComputeInstanceModel) => ({
                            [localization.columns.security.memoryEncryptionEnabled()]:
                                (item.entity as Contract.OciComputeInstance).security.memoryEncryptionEnabled
                                    ? localization.columns.security.enabled.true()
                                    : localization.columns.security.enabled.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.security.memoryEncryptionEnabled()}>
                                <ValuesFilterItem
                                    title={localization.columns.security.enabled.false()}
                                    value={false}/>
                                <ValuesFilterItem
                                    title={localization.columns.security.enabled.true()}
                                    value={true}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.OciComputeInstanceMemoryEncryptionEnabled}
                itemProperty={
                    (item: Contract.EntityModel) =>
                        (item.entity as Contract.OciComputeInstance).security.memoryEncryptionEnabled
                            ? localization.columns.security.enabled.true()
                            : localization.columns.security.enabled.false()}
                key={Contract.EntityModelProperty.OciComputeInstanceMemoryEncryptionEnabled}
                selectorOptions={{ default: false }}
                title={localization.columns.security.memoryEncryptionEnabled()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciComputeInstanceModel) => ({
                            [localization.columns.metadataServiceVersion1Enabled.title()]:
                                (item.entity as Contract.OciComputeInstance).metadataServiceVersion1Enabled
                                    ? localization.columns.metadataServiceVersion1Enabled.true()
                                    : localization.columns.metadataServiceVersion1Enabled.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter placeholder={localization.columns.metadataServiceVersion1Enabled.title()}>
                                <ValuesFilterItem
                                    title={localization.columns.metadataServiceVersion1Enabled.false()}
                                    value={false}/>
                                <ValuesFilterItem
                                    title={localization.columns.metadataServiceVersion1Enabled.true()}
                                    value={true}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.OciComputeInstanceMetadataServiceVersion1Enabled}
                itemProperty={
                    (item: Contract.EntityModel) =>
                        (item.entity as Contract.OciComputeInstance).metadataServiceVersion1Enabled
                            ? localization.columns.metadataServiceVersion1Enabled.true()
                            : localization.columns.metadataServiceVersion1Enabled.false()}
                key={Contract.EntityModelProperty.OciComputeInstanceMetadataServiceVersion1Enabled}
                selectorOptions={{ default: false }}
                title={localization.columns.metadataServiceVersion1Enabled.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciComputeInstanceModel) => ({
                            [localization.columns.primaryPrivateIpAddress()]: item.primaryPrivateIpAddress ?? ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciComputeInstancePrimaryPrivateIpAddress)}
                                placeholder={localization.columns.primaryPrivateIpAddress()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciComputeInstancePrimaryPrivateIpAddress}
                key={Contract.EntityModelProperty.OciComputeInstancePrimaryPrivateIpAddress}
                render={optionalTableCell<Contract.OciComputeInstanceModel>(item => item.primaryPrivateIpAddress)}
                title={localization.columns.primaryPrivateIpAddress()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.OciComputeInstanceModel) => ({
                            [localization.columns.primaryPublicIpAddress()]: item.primaryPublicIpAddress ?? ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.OciComputeInstancePrimaryPublicIpAddress)}
                                placeholder={localization.columns.primaryPublicIpAddress()}/>
                    }
                }}
                id={Contract.EntityModelProperty.OciComputeInstancePrimaryPublicIpAddress}
                key={Contract.EntityModelProperty.OciComputeInstancePrimaryPublicIpAddress}
                render={optionalTableCell<Contract.OciComputeInstanceModel>(item => item.primaryPublicIpAddress)}
                title={localization.columns.primaryPublicIpAddress()}/>,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageOciComputeInstanceRequest(
                new Contract.EntityControllerGetEntityModelPageOciComputeInstanceRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciComputeInstanceInstanceConfiguration]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciComputeInstanceMemoryEncryptionEnabled]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciComputeInstanceMetadataServiceVersion1Enabled]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciComputeInstancePrimaryPrivateIpAddress]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciComputeInstancePrimaryPublicIpAddress]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciComputeInstanceSecureBootEnabled]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciComputeInstanceShape]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciComputeInstanceStatus]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OciComputeInstanceVolumeEncryptionInTransitEnabled])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}