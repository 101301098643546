import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, EnumValuesFilter, InlineItems, optionalTableCell, PagedValuesFilter, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonEc2ResourceDefinition, useAwsCommonNetworkedResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, NetworkedResourcesCell, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useAwsEc2FlowLogFilterTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useAwsEc2SubnetDefinition(definitionData: DefinitionData) {
    const commonEc2ResourceDefinition = useAwsCommonEc2ResourceDefinition(definitionData);
    const commonNetworkedResourceDefinition = useAwsCommonNetworkedResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const ec2FlowLogFilterTranslator = useAwsEc2FlowLogFilterTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsEc2SubnetDefinition",
            () => ({
                columns: {
                    flowLogDestinationResourceIdReferences: "Flow Logs Destinations",
                    flowLogFilter: "Flow Logs Filter",
                    networkedResourceIds: "Attached Resources",
                    subnets: "CIDR"
                }
            }));

    return new EntityTableDefinition(
        _.filter(
            [
                commonEc2ResourceDefinition.columns.rawIdColumn,
                commonEc2ResourceDefinition.columns.tenantColumn,
                commonEc2ResourceDefinition.columns.creationTimeColumn,
                commonEc2ResourceDefinition.columns.creatorIdentityCsvColumn,
                commonEc2ResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEc2SubnetModel) => ({
                                [localization.columns.subnets()]: _.join((item.entity as Contract.AwsEc2Subnet).subnets, "\n")
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsEc2SubnetSubnets)}
                                    placeholder={localization.columns.subnets()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2SubnetSubnets}
                    key={Contract.EntityModelProperty.AwsEc2SubnetSubnets}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsEc2SubnetModel>) =>
                            <Typography noWrap={true}>
                                <InlineItems
                                    items={(item.entity as Contract.AwsEc2Subnet).subnets}
                                    variant="itemPlusItemCount"/>
                            </Typography>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.subnets()}/>,
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsEc2SubnetModel>(
                            Contract.TypeNames.INetworkedResource,
                            item =>
                                _(item.networkedResourceTypeNameToIdsMap).
                                    values().
                                    flatMap().
                                    value(),
                            localization.columns.networkedResourceIds())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsEc2SubnetNetworkedResources)}
                                    placeholder={localization.columns.networkedResourceIds()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2SubnetNetworkedResources}
                    key={Contract.EntityModelProperty.AwsEc2SubnetNetworkedResources}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsEc2SubnetModel>) =>
                            <NetworkedResourcesCell item={item.networkedResourceTypeNameToIdsMap}/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.networkedResourceIds()}/>,
                commonNetworkedResourceDefinition.columns.accessLevelColumn,
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AwsEc2SubnetModel) => ({
                                [localization.columns.flowLogFilter()]: ec2FlowLogFilterTranslator((item as Contract.AwsEc2SubnetModel).flowLogFilter)
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <EnumValuesFilter
                                    emptyValueOptions={{
                                        enabled: true,
                                        title: ec2FlowLogFilterTranslator(undefined)
                                    }}
                                    enumType={Contract.AwsEc2FlowLogFilter}
                                    enumTypeTranslator={ec2FlowLogFilterTranslator}
                                    placeholder={localization.columns.flowLogFilter()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2SubnetFlowLogFilter}
                    key={Contract.EntityModelProperty.AwsEc2SubnetFlowLogFilter}
                    render={optionalTableCell<Contract.AwsEc2SubnetModel>(item => ec2FlowLogFilterTranslator(item.flowLogFilter))}
                    title={localization.columns.flowLogFilter()}/>,
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AwsEc2SubnetModel>(
                            Contract.TypeNames.AwsResource,
                            item => (item as Contract.AwsEc2SubnetModel).flowLogDestinationResourceIdReferences,
                            localization.columns.flowLogDestinationResourceIdReferences())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AwsEc2SubnetFlowLogDestinationResources)}
                                    placeholder={localization.columns.flowLogDestinationResourceIdReferences()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AwsEc2SubnetFlowLogDestinationResources}
                    key={Contract.EntityModelProperty.AwsEc2SubnetFlowLogDestinationResources}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsEc2SubnetModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.flowLogDestinationResourceIdReferences}
                                entityTypeName={Contract.TypeNames.AwsResource}
                                entityVariant="iconText"/>}
                    sortOptions={{ type: DataTableSortType.Numeric }}
                    title={localization.columns.flowLogDestinationResourceIdReferences()}/>,
                commonNetworkedResourceDefinition.columns.getVpcsColumn(Contract.EntityModelProperty.AwsEc2SubnetVpc),
                commonEc2ResourceDefinition.columns.regionColumn,
                commonEc2ResourceDefinition.columns.regionLocationColumn,
                commonEc2ResourceDefinition.columns.tagsColumn,
                commonEc2ResourceDefinition.columns.attributesColumn,
                commonEc2ResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
                commonEc2ResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
                commonEc2ResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
            ]),
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsEc2SubnetRequest(
                new Contract.EntityControllerGetEntityModelPageAwsEc2SubnetRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2ResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2SubnetFlowLogDestinationResources]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2SubnetFlowLogFilter]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2SubnetNetworkedResources]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2SubnetSubnets]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsEc2SubnetVpc])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageEntityRequestNetworkAccess(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundAccessType]),
                    undefined,
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.NetworkInboundExternalDestinationNetworkScopes]))));
}