import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ArchIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <g clipPath="url(#clip0_52885_254676)">
                <path
                    d="M24 12C24 18.6274 18.6274 24 12 24C5.37259 24 0 18.6274 0 12C0 5.37258 5.37259 0 12 0C18.6274 0 24 5.37258 24 12Z"
                    fill="#1793D1"/>
                <path
                    clipRule="evenodd"
                    d="M11.9989 1.86328C11.2791 3.62803 10.845 4.78239 10.0436 6.49468C10.5349 7.01552 11.1381 7.62206 12.1175 8.3071C11.0645 7.87378 10.3462 7.43875 9.80942 6.98731C8.78378 9.12747 7.1769 12.176 3.91602 18.0352C6.47896 16.5555 8.46571 15.6433 10.3173 15.2952C10.2378 14.9533 10.1926 14.5834 10.1956 14.1975L10.1987 14.1153C10.2393 12.4733 11.0935 11.2106 12.1054 11.2964C13.1172 11.3821 13.9037 12.7835 13.863 14.4255C13.8554 14.7345 13.8205 15.0317 13.7596 15.3074C15.5911 15.6657 17.5566 16.5755 20.0849 18.0352C19.5863 17.1173 19.1414 16.29 18.7164 15.502C18.0471 14.9832 17.3489 14.308 15.9248 13.5771C16.9037 13.8314 17.6045 14.1249 18.1508 14.4529C13.8304 6.40899 13.4805 5.34012 11.9989 1.86328Z"
                    fill="white"
                    fillRule="evenodd"/>
            </g>
            <defs>
                <clipPath id="clip0_52885_254676">
                    <rect
                        fill="white"
                        height="24"
                        width="24"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}