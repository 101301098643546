﻿import { Message, useLocalization } from "@infrastructure";
import { Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { Contract } from "../../../../../../../controllers";
import { useEntityTypeNameTranslator } from "../../../../../../../hooks";
import { EntityMultiSelectProperty, useMultiSelectorContext, useSetMultiSelectorContext } from "../../../EntityMultiSelect";

type NamePatternProps = {
    disabled?: boolean;
    entityTypeName: string;
    includeServiceName: boolean;
};

export function NamePattern({ disabled, entityTypeName, includeServiceName }: NamePatternProps) {
    const { propertyIdToConditionMap } = useMultiSelectorContext();
    const condition = propertyIdToConditionMap[EntityMultiSelectProperty.NamePattern] as Contract.EntityNamePatternMatchCondition;
    const setMultiSelectorContext = useSetMultiSelectorContext();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.entityMultiSelect.condition.namePattern",
            () => ({
                helpText: "Pattern includes * and ?.\n* indicates zero or more characters.\n? indicates a single character.",
                placeholder: "Pattern",
                title: "*capitalize*{{translatedEntityTypeName}}** that match this pattern"
            }));

    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Typography>
                {localization.title({
                    translatedEntityTypeName:
                        entityTypeNameTranslator(
                            entityTypeName,
                            {
                                count: 0,
                                includeServiceName,
                                variant: "text"
                            })
                })}
            </Typography>
            <TextField
                disabled={disabled}
                label={localization.placeholder()}
                size="small"
                value={condition.namePattern}
                variant="outlined"
                onChange={
                    event =>
                        setMultiSelectorContext(
                            context => ({
                                propertyIdToConditionMap: {
                                    ...context.propertyIdToConditionMap,
                                    [EntityMultiSelectProperty.NamePattern]: new Contract.EntityNamePatternMatchCondition(event.target.value)
                                }
                            }))}/>
            <Message
                level="info"
                title={
                    <Typography style={{ whiteSpace: "pre-wrap" }}>
                        {localization.helpText()}
                    </Typography>}
                variant="minimal"/>
        </Stack>);
}