﻿import { SvgIconProps } from "@mui/material";
import React, { Fragment } from "react";
import { DashboardIcon, RiskPoliciesIcon, RisksIcon, ScansIcon } from ".";
import { CodeView } from "../../views/Customer/components";

type CodeViewIconProps =
    SvgIconProps & {
        view: CodeView;
    };

export function CodeViewIcon({ view, ...props }: CodeViewIconProps) {
    switch (view) {
        case CodeView.Scans:
            return <ScansIcon {...props}/>;
        case CodeView.Dashboard:
            return <DashboardIcon {...props}/>;
        case CodeView.Policies:
            return <RiskPoliciesIcon {...props}/>;
        case CodeView.Risks:
            return <RisksIcon {...props}/>;
        default:
            return <Fragment/>;
    }
}