import { Dictionary } from "lodash";

export class OciConsoleHelper {
    private static _RealmIdToDomainNameMap: Dictionary<string>;

    public static initialize(
        RealmIdToDomainNameMap: Dictionary<string>) {
        OciConsoleHelper._RealmIdToDomainNameMap = RealmIdToDomainNameMap;
    }

    public static getDomainName(partitionType: string) {
        return OciConsoleHelper._RealmIdToDomainNameMap[partitionType];
    }
}