import { ItemSelector, ItemSelectorProps } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { ReactNode } from "react";
import { useTheme } from "../../../../../../../../../../../../../../../common";

type MapTableProps = {
    getErrorMessage?: (key: string, index: number) => string | undefined;
    itemSelectorProps: Omit<ItemSelectorProps, "selectedItem" | "children" | "onSelectedItemChanged">;
    itemSelectorRenderer: (item: any, inline: boolean) => ReactNode;
    keyRenderer: (key: string) => ReactNode;
    keys: string[];
    keysToSelectedItems: Dictionary<any>;
    keyTitle: string;
    onItemSelected: (key: string, value: any) => void;
    valueTitle: string;
};

export function MapTable({ getErrorMessage, itemSelectorProps, itemSelectorRenderer, keyRenderer, keys, keysToSelectedItems, keyTitle, onItemSelected, valueTitle }: MapTableProps) {
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            sx={{
                border: theme.border.primary,
                borderRadius: theme.spacing(0.75),
                margin: theme.spacing(1, 0)
            }}>
            <MapTableRow
                keyElement={
                    <Typography sx={{ fontWeight: 600 }}>
                        {keyTitle}
                    </Typography>}
                valueElement={
                    <Typography sx={{ fontWeight: 600 }}>
                        {valueTitle}
                    </Typography>}/>
            {_.map(
                keys,
                (key, index) => {
                    const errorMessage = getErrorMessage?.(key, index);
                    return (
                        <MapTableRow
                            borderBottom={index < keys.length - 1}
                            error={!_.isNil(errorMessage)}
                            key={key}
                            keyElement={
                                <Box
                                    sx={{
                                        alignItems: "center",
                                        display: "flex"
                                    }}>
                                    {keyRenderer(key)}
                                </Box>}
                            valueElement={
                                <ItemSelector
                                    errorMessage={errorMessage}
                                    fullWidth={true}
                                    {...itemSelectorProps}
                                    fieldSx={{ maxHeight: theme.spacing(4) }}
                                    selectedItem={_.get(keysToSelectedItems, key)}
                                    onSelectedItemChanged={item => onItemSelected(key, item)}>
                                    {itemSelectorRenderer}
                                </ItemSelector>}/>);
                })}
        </Stack>);
}

type MapTableRowProps = {
    borderBottom?: boolean;
    error?: boolean;
    keyElement: ReactNode;
    valueElement: ReactNode;
};

function MapTableRow({ borderBottom = true, error, keyElement, valueElement }: MapTableRowProps) {
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            sx={{
                alignItems:
                    error
                        ? "baseline"
                        : "center",
                borderBottom:
                    borderBottom
                        ? theme.border.primary
                        : "none",
                padding: theme.spacing(1, 2),
                width: "100%"
            }}>
            <Box sx={{ flex: 1 }}>
                {keyElement}
            </Box>
            <Box sx={{ flex: 1 }}>
                {valueElement}
            </Box>
        </Stack>);
}