import { ItemWithValidation, MultiSelect, useChangeEffect, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Contract } from "../../../controllers";
import { scopeSystemEntityModelStore } from "../../../stores";
import { ScopeHelper } from "../../../utilities";
import { DeliveryProps } from "../Delivery";

export function Webhook({ delivery, disabled, onChange, scopeId }: DeliveryProps) {
    const webhooks = scopeSystemEntityModelStore.useGetWebhook();
    const [selectedItems, setSelectedItems] =
        useState(
            _.map(
                (delivery.destinations ?? []) as Contract.WebhookDeliveryEndpoint[],
                ({ endpointId }) => endpointId));
    const webhookEndpointModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeId],
            webhooks);
    const webhookEndpointModelMap =
        useMemo(
            () =>
                _.keyBy(
                    webhookEndpointModels,
                    webhookEndpointModel => webhookEndpointModel.configuration.id),
            [webhookEndpointModels]);

    useChangeEffect(
        () =>
            onChange({
                ...(delivery as Contract.WebhookDelivery),
                destinations:
                    _.map(
                        selectedItems,
                        endpointId => ({
                            endpointId,
                            typeName: Contract.TypeNames.WebhookDeliveryEndpoint
                        })) as Contract.WebhookDeliveryEndpoint[],
                valid: !_.isEmpty(selectedItems)
            } as ItemWithValidation<Contract.WebhookDelivery>),
        [selectedItems],
        500);

    const localization =
        useLocalization(
            "common.delivery.webhook",
            () => ({
                endpoints: "{{endpointCount | NumberFormatter.humanize}} Webhooks",
                title: "Webhooks"
            }));

    return (
        <MultiSelect
            disabled={disabled}
            fullWidth={true}
            items={_.keys(webhookEndpointModelMap)}
            placeholder={localization.title()}
            selectedItems={selectedItems}
            selectedItemsTranslator={
                selectedItems =>
                    selectedItems.length === 1
                        ? (webhookEndpointModelMap[selectedItems[0]].configuration as Contract.WebhookEndpointConfiguration).name
                        : localization.endpoints({ endpointCount: selectedItems.length })}
            onSelectedItemsChanged={setSelectedItems}>
            {item => (webhookEndpointModelMap[item].configuration as Contract.WebhookEndpointConfiguration).name}
        </MultiSelect>);
}