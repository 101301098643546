﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, FeatureHelper, scopeSystemEntityModelStore, TenantIcon } from "../../../../../../../../../../common";
import { IntegrationItem, IntegrationItemCategory } from "../../../../utilities";
import { CloudsmithIntegration, GeneralIntegration, JfrogIntegration } from "./components";

export function useCiIntegrationItems(): IntegrationItem[] {
    const ciCloudsmithIntegrationModels = scopeSystemEntityModelStore.useGetCiIntegration(Contract.CiTenantType.Cloudsmith);
    const ciGeneralIntegrationModels = scopeSystemEntityModelStore.useGetCiIntegration(Contract.CiTenantType.General);
    const ciJfrogIntegrationModels = scopeSystemEntityModelStore.useGetCiIntegration(Contract.CiTenantType.Jfrog);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCiIntegrationItems",
            () => ({
                description: "{{count | NumberFormatter.humanize}} configured",
                [Contract.TypeNames.CiTenantType]: {
                    [Contract.CiTenantType.Cloudsmith]: "Cloudsmith Organization",
                    [Contract.CiTenantType.General]: "Container Registry",
                    [Contract.CiTenantType.Jfrog]: "JFrog Organization"
                }
            }));

    return _<IntegrationItem>([]).
        concat([
            new IntegrationItem(
                IntegrationItemCategory.CiIntegration,
                ciGeneralIntegrationModels,
                [localization.description({ count: ciGeneralIntegrationModels.length })],
                <TenantIcon
                    data={{ ciTenantType: Contract.CiTenantType.General }}
                    tenantType={Contract.TenantType.Ci}/>,
                localization[Contract.TypeNames.CiTenantType][Contract.CiTenantType.General](),
                Contract.CiTenantType.General,
                <GeneralIntegration/>
            ),
            new IntegrationItem(
                IntegrationItemCategory.CiIntegration,
                ciJfrogIntegrationModels,
                [localization.description({ count: ciJfrogIntegrationModels.length })],
                <TenantIcon
                    data={{ ciTenantType: Contract.CiTenantType.Jfrog }}
                    tenantType={Contract.TenantType.Ci}/>,
                localization[Contract.TypeNames.CiTenantType][Contract.CiTenantType.Jfrog](),
                Contract.CiTenantType.Jfrog,
                <JfrogIntegration/>
            )
        ]).
        concatIf(
            FeatureHelper.enabled(Contract.FeatureName.CiContainerImageRegistryCloudsmith),
            new IntegrationItem(
                IntegrationItemCategory.CiIntegration,
                ciCloudsmithIntegrationModels,
                [localization.description({ count: ciCloudsmithIntegrationModels.length })],
                <TenantIcon
                    data={{ ciTenantType: Contract.CiTenantType.Cloudsmith }}
                    tenantType={Contract.TenantType.Ci}/>,
                localization[Contract.TypeNames.CiTenantType][Contract.CiTenantType.Cloudsmith](),
                Contract.CiTenantType.Cloudsmith,
                <CloudsmithIntegration/>)).
        value();
}

export type CiIntegrationQueryParameters = {
    addOrEditOpen?: string;
};