﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function BangladeshIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                d="M0 6C0 4.89543 0.895431 4 2 4H22C23.1046 4 24 4.89543 24 6V18C24 19.1046 23.1046 20 22 20H2C0.89543 20 0 19.1046 0 18V6Z"
                fill="#006A4E"/>
            <path
                d="M9 17C11.7614 17 14 14.7614 14 12C14 9.23858 11.7614 7 9 7C6.23858 7 4 9.23858 4 12C4 14.7614 6.23858 17 9 17Z"
                fill="#F42A41"/>
        </SvgIcon>);
}