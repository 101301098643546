import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { RiskPoliciesView } from "../../../..";
import { useCodeBuiltInRiskPolicyConfigurationControllerDefinition } from "./hooks";

export function useCodeRiskPolicyConfigurationControllerDefinition(view: RiskPoliciesView, riskPolicyConfigurationTypeNameOrId: string, scopeId: string) {
    const useDefinition =
        useMemo(
            () => {
                switch (view) {
                    case RiskPoliciesView.BuiltIn:
                        return useCodeBuiltInRiskPolicyConfigurationControllerDefinition;
                    default:
                        throw new UnexpectedError("useCodeRiskPolicyConfigurationControllerDefinition.view", view);
                }
            },
            [view]);

    return useDefinition(riskPolicyConfigurationTypeNameOrId, scopeId);
}