import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, tenantModelStore, VerticalTopItemsInfoItem } from "../../../../../../../../../../../common";
import { useGciDirectoryGroupTypeTranslator } from "../../../../../../../hooks";
import { EntitiesInfoCard, EntitiesInfoItem, Info } from "../../../../../components";
import { GciDirectoryPrincipalGcpRoleBindings } from "../../../components";
import { useGciCommonDirectoryPrincipalInfoItemElements } from "../useGciCommonDirectoryPrincipalInfoItemElements";
import { useGciDefaultEntityInfoItemElements } from "../useGciDefaultEntityInfoItemElements";
import { MemberPrincipals } from "./components";

export function useGciDirectoryGroupDefinition(entityModel: Contract.GciEntityModel, options?: DefinitionOptions) {
    const activeCloudProviderTenantTypes = tenantModelStore.useGetActiveCloudProviderTenantTypes();
    const groupModel = entityModel as Contract.GciDirectoryGroupModel;
    const commonDirectoryPrincipalInfoItemElements = useGciCommonDirectoryPrincipalInfoItemElements(groupModel);
    const defaultResourceInfoItemElements = useGciDefaultEntityInfoItemElements(groupModel);
    const group = groupModel.entity as Contract.GciDirectoryGroup;

    const directoryGroupTypeTranslator = useGciDirectoryGroupTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gci.useGciDirectoryGroupDefinition",
            () => ({
                info: {
                    cards: {
                        groupIds: "Group Memberships"
                    },
                    items: {
                        groupTypes: "Group Types",
                        memberPrincipals: "Members"
                    }
                },
                tabs: {
                    memberPrincipals: "Members",
                    principalRoleBindings: "Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs:
            _<EntityProfileDefinitionTab>([]).
                concat(
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Overview,
                        <MemberPrincipals group={group}/>,
                        localization.tabs.memberPrincipals(),
                        "memberPrincipals")).
                concatIf(
                    _.includes(activeCloudProviderTenantTypes, Contract.TenantType.Gcp),
                    new EntityProfileDefinitionTab(
                        ProfileCategory.Iam,
                        <GciDirectoryPrincipalGcpRoleBindings
                            csvExportFilePrefix={localization.tabs.principalRoleBindings()}
                            gcpRoleBindingIds={groupModel.gcpRoleBindingIds}
                            principalId={group.id}/>,
                        localization.tabs.principalRoleBindings(),
                        "principalRoleBindings")).
                value(),
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonDirectoryPrincipalInfoItemElements,
                    <VerticalTopItemsInfoItem
                        items={
                            _.map(
                                group.types,
                                type => directoryGroupTypeTranslator(type))}
                        key="groupTypes"
                        title={localization.info.items.groupTypes()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={groupModel.memberPrincipalIds}
                        entityTypeName={Contract.TypeNames.GciDirectoryPrincipal}
                        key="memberPrincipals"
                        location="miniGlance"
                        title={localization.info.items.memberPrincipals()}/>
                ]}
                options={options?.infoOptions}>
                <EntitiesInfoCard
                    entityIdsOrModels={groupModel.groupIds}
                    entityTypeName={Contract.TypeNames.GciDirectoryGroup}
                    title={localization.info.cards.groupIds()}/>
            </Info>,
        sensitive: false
    });
}