import { Box, Stack } from "@mui/material";
import React, { ReactNode } from "react";
import { useTheme } from "../../..";

type SidebarItemProps = {
    children: ReactNode;
    icon: ReactNode;
};

export const sidebarItemSizeClose = 54;
export const sidebarItemSizeOpen = 188;

export function SidebarItem({ children, icon }: SidebarItemProps) {
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1.5}
            sx={{ width: "100%" }}>
            <Box
                alignItems="center"
                display="flex"
                justifyContent="center"
                sx={{
                    minHeight: theme.px(sidebarItemSizeClose - 12),
                    minWidth: theme.px(34)
                }}>
                {icon}
            </Box>
            <Box sx={{ flex: 1 }}>
                {children}
            </Box>
        </Stack>);
}