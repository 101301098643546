import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useGcpTenantManagerErrorTranslator() {
    const localization =
        useLocalization(
            "tenants.gcp.hooks.useGcpTenantManagerErrorTranslator",
            () => ({
                [Contract.TypeNames.GcpTenantManagerError]: {
                    [Contract.GcpTenantManagerError.CommonErrorGciTenantIssue]: "Google Workspace (or Cloud Identity) is not connected. Please validate connectivity under Settings > Integrations.",
                    [Contract.GcpTenantManagerError.CommonErrorMoveScopeDependencyExists]: "You can't move the selected account/s because the target folder does not have the same configured integrations as the source folder.",
                    [Contract.GcpTenantManagerError.CommonErrorParentFolderNotExist]: "Parent folder does not exist",
                    [Contract.GcpTenantManagerError.CommonErrorServiceAccountMandatoryOrganizationPermissionsMismatch]: "Service account is missing permissions at the organization level.",
                    [Contract.GcpTenantManagerError.CommonErrorServiceAccountMandatoryPermissionsMismatch]: "Service account permissions are missing or misconfigured.",
                    [Contract.GcpTenantManagerError.CommonErrorServiceAccountUnauthorized]: "Service account is not authorized",
                    [Contract.GcpTenantManagerError.CommonErrorSinkExclusionFilterMismatch]: "The logging sink exclude filter does not match the provided filter.",
                    [Contract.GcpTenantManagerError.CommonErrorSinkInclusionFilterMismatch]: "The logging sink filter does not match the provided filter.",
                    [Contract.GcpTenantManagerError.CommonErrorSinkMultipleSinks]: "There are multiple sinks writing to the same topic.",
                    [Contract.GcpTenantManagerError.CommonErrorSinkNotExist]: "Logging sink not found.",
                    [Contract.GcpTenantManagerError.CommonErrorSinkOrganizationConfigurationExists]: "An organization sink is already configured.",
                    [Contract.GcpTenantManagerError.CommonErrorSinkOrganizationIncludeChildrenNotEnabled]: "The logging sink that redirects traffic to the subscription should be created at the organization level and have 'includeChildren' set to 'true'.",
                    [Contract.GcpTenantManagerError.CommonErrorSinkSubscriptionExpirationTimeFrameExists]: "Pub/Sub Subscription expiration period should be set to 'never expire'.",
                    [Contract.GcpTenantManagerError.CommonErrorSinkSubscriptionFilterExists]: "Pub/Sub Subscription should not contain a filter.",
                    [Contract.GcpTenantManagerError.CommonErrorSinkSubscriptionMessageDeliveryTypePush]: "Subscription delivery type should be Pull.",
                    [Contract.GcpTenantManagerError.CommonErrorSinkSubscriptionMessageRetentionDuration]: "Subscription message retention should be at least 3 days.",
                    [Contract.GcpTenantManagerError.CommonErrorSinkSubscriptionNotExist]: "Pub/Sub Subscription not found",
                    [Contract.GcpTenantManagerError.CommonErrorSinkSubscriptionRawIdNotValid]: "Invalid Pub/Sub Subscription name.",
                    [Contract.GcpTenantManagerError.CommonErrorSinkSubscriptionServiceAccountUnauthorized]: "Service account does not have Pub/Sub Subscriber permissions on the Pub/Sub Subscription.",
                    [Contract.GcpTenantManagerError.CommonErrorSinkSubscriptionTopicNotConnected]: "Pub/Sub Subscription is not connected to a topic.",
                    [Contract.GcpTenantManagerError.DeleteTenantErrorOrganizationExists]: "GCP project is configured as part of an Organization.",
                    [Contract.GcpTenantManagerError.InsertTenantErrorTenantDeletePending]: "GCP project is pending deletion.",
                    [Contract.GcpTenantManagerError.InsertTenantErrorTenantExists]: "GCP project already exists.",
                    [Contract.GcpTenantManagerError.InsertTenantErrorTenantMaxCount]: "GCP project limit was reached.",
                    [Contract.GcpTenantManagerError.InsertTenantErrorTenantNotFound]: "GCP project was not found.",
                    [Contract.GcpTenantManagerError.InsertTenantErrorTenantRawShortNameIdNotValid]: "GCP project ID format is invalid.",
                    [Contract.GcpTenantManagerError.InsertTenantErrorTenantSuspended]: "GCP project is suspended.",
                    [Contract.GcpTenantManagerError.UpdateTenantErrorTenantNotExist]: "GCP project does not exist.",
                    [Contract.GcpTenantManagerError.UpsertOrganizationErrorTenantNotExists]: "Missing permissions: no projects detected. Please ensure the correct roles are assigned to the Tenable service account"
                }
            }));
    return (error: Contract.GcpTenantManagerError) => localization[Contract.TypeNames.GcpTenantManagerError][error]();
}