import { useLocalization } from "@infrastructure";
import { useMemo } from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { useAzureCognitiveServicesAccountTypeTranslator } from "../../../../../../../../../Entities/hooks";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetNetworkAccessResourceRiskContext } from "../../../useGetNetworkAccessResourceRiskContext";
import { useGetAzureResourceRiskContext } from "./useGetAzureResourceRiskContext";

export function useGetAzureCognitiveServicesAccountRiskContext() {
    return useMemo(
        () => useAzureCognitiveServicesAccountRiskContext,
        []);
}

function useAzureCognitiveServicesAccountRiskContext(accountModel: Contract.AzureCognitiveServicesAccountModel) {
    const account = accountModel.entity as Contract.AzureCognitiveServicesAccount;
    const networkAccessResourceRiskContext = useGetNetworkAccessResourceRiskContext()(accountModel);
    const resourceRiskContext = useGetAzureResourceRiskContext()(accountModel);

    const cognitiveServicesAccountTypeTranslator = useAzureCognitiveServicesAccountTypeTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.contexts.useGetAzureCognitiveServicesAccountRiskContext",
            () => ({
                type: "The {{translatedAccountTypeName}} kind is **{{type}}**"
            }));
    const translatedAccountTypeName =
        entityTypeNameTranslator(
            account.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        ...networkAccessResourceRiskContext,
        type:
            new RiskDefinitionContextItem(
                localization.type({
                    translatedAccountTypeName,
                    type: cognitiveServicesAccountTypeTranslator(account.type)
                }))
    };
}