import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../..";
import { Contract, entityModelStore, InlineEntities, InlineResourceTags } from "../../../../../../../../../../../../../../../common";
import { useRemediationSteps } from "../useRemediationSteps";
import { ContextSection } from "./components";

export function useAwsInboundExternalEc2InstanceRiskTagResourceGroupDefinition(riskModel: Contract.AwsInboundExternalEc2InstanceRiskModel) {
    const tagResourceGroupModel = entityModelStore.useGet(riskModel.risk.entityId) as Contract.AwsTagResourceGroupModel;
    const tagResourceGroup = (tagResourceGroupModel.entity) as Contract.AwsTagResourceGroup;
    const remediationSteps = useRemediationSteps(riskModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsInboundExternalEc2InstanceRiskDefinition.hooks.useAwsInboundExternalEc2InstanceRiskTagResourceGroupDefinition",
            () => ({
                description: [
                    "{{instances}} grouped by {{tags}} is directly accessible from a wide range of public IP addresses",
                    "{{instances}} grouped by {{tags}} are directly accessible from a wide range of public IP addresses"
                ]
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description(
            _.size(riskModel.risk.aggregatedEntityIds),
            {
                instances:
                    <InlineEntities
                        entityIdsOrModels={riskModel.risk.aggregatedEntityIds}
                        entityTypeName={Contract.TypeNames.AwsEc2Instance}
                        variant="itemCountAndType"/>,
                tags:
                    <InlineResourceTags
                        tags={tagResourceGroup.groupTags}
                        textVariant="text"
                        variant="itemCountAndType"/>
            }),
        () => remediationSteps,
        riskModel,
        undefined,
        {
            contextSectionElement:
                <ContextSection
                    riskModel={riskModel}
                    tagResourceGroupModel={tagResourceGroupModel}/>
        });
}