﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpContainerClusterStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpContainerClusterStatusTranslator",
            () => ({
                [Contract.TypeNames.GcpContainerClusterStatus]: {
                    [Contract.GcpContainerClusterStatus.Degraded]: "Degraded",
                    [Contract.GcpContainerClusterStatus.Error]: "Error",
                    [Contract.GcpContainerClusterStatus.Provisioning]: "Provisioning",
                    [Contract.GcpContainerClusterStatus.Reconciling]: "Reconciling",
                    [Contract.GcpContainerClusterStatus.Running]: "Running",
                    [Contract.GcpContainerClusterStatus.Stopping]: "Stopping"
                }
            }));
    return (status: Contract.GcpContainerClusterStatus) =>
        localization[Contract.TypeNames.GcpContainerClusterStatus][status]();
}