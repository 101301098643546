import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useGetOciIamCredentialRiskContext } from "./useGetOciIamCredentialRiskContext";

export function useGetOciIamUserApiKeyRiskContext() {
    return useMemo(
        () => useOciIamUserApiKeyRiskContext,
        []);
}

function useOciIamUserApiKeyRiskContext(UserApiKeyModel: Contract.OciIamUserApiKeyModel) {
    return useGetOciIamCredentialRiskContext()(UserApiKeyModel);
}