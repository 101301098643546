import { useLocalization } from "@infrastructure";
import { ToolbarFilterId } from "../../../../../components";

export function useAwsNetworkToolbarFilterIdTranslator() {
    const localization =
        useLocalization(
            "common.network.hooks.useDefinition.hooks.useAwsDefinition.hooks.useAwsNetworkToolbarFilterIdTranslator",
            () => ({
                filters: {
                    [ToolbarFilterId.DestinationScopeFilterIds]: "Ports",
                    [ToolbarFilterId.InterfaceSecurityGroupIds]: "Security Groups",
                    [ToolbarFilterId.SourceEntityIds]: "Access Resources",
                    [ToolbarFilterId.SourceSubnets]: "IP Address Ranges"
                }
            }));
    return (toolbarFilterId: ToolbarFilterId) =>
        localization.filters.translate(toolbarFilterId);
}