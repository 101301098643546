import { ActionMenuItem, DataTableColumnRenderProps, DeleteIcon, EditIcon, Menu, Message, useLocalization } from "@infrastructure";
import { CircularProgress, Stack } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useMemo, useState } from "react";
import { useSetDatadogContext } from "../../..";
import { ConfigurationController, Contract, scopeNodeModelStore, scopeSystemEntityModelStore, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../../../../../../../common";

export function ActionsCell({ item }: DataTableColumnRenderProps<Contract.ScopeSystemEntityModel>) {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const setDatadogContext = useSetDatadogContext();
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const childScopeIds =
        useMemo(
            () => scopeNodeMap[scopeNodeModel.configuration.id].scopeIds,
            [scopeNodeModel.configuration.id, scopeNodeMap]);

    const [deleteOrganizationExecuting, setDeleteOrganizationExecuting] = useState(false);
    const [deleteOrganizationError, setDeleteOrganizationError] = useState(false);

    async function deleteOrganization() {
        setDeleteOrganizationExecuting(true);
        setDeleteOrganizationError(false);

        try {
            await ConfigurationController.deleteDatadogOrganization(new Contract.ConfigurationControllerDeleteDatadogOrganizationRequest(item.configuration.id));
            await scopeSystemEntityModelStore.notify();
        } catch {
            setDeleteOrganizationError(true);
        }

        setDeleteOrganizationExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useObservabilityItems.datadog.table.actionsCell",
            () => ({
                actions: {
                    delete: {
                        error: "Failed to delete",
                        prompt: "Are you sure you want to delete the organization {{organizationName}}?",
                        title: "Delete"
                    },
                    edit: "Edit"
                }
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            {deleteOrganizationExecuting && (
                <CircularProgress
                    size={theme.spacing(2)}
                    variant="indeterminate"/>)}
            {deleteOrganizationError && (
                <Message
                    level="error"
                    title={localization.actions.delete.error()}
                    variant="minimal"/>)}
            {(scopeNodeModel.configuration.id === item.configuration.scopeId || _.includes(childScopeIds, item.configuration.scopeId)) &&
                <Fragment>
                    <Menu
                        itemsOrGetItems={[
                            new ActionMenuItem(
                                () =>
                                    setDatadogContext(
                                        datadogContext => ({
                                            ...datadogContext,
                                            addOrEditOpen: item
                                        })),
                                localization.actions.edit(),
                                {
                                    disabled: deleteOrganizationExecuting,
                                    icon: <EditIcon/>
                                }),
                            new ActionMenuItem(
                                () => deleteOrganization(),
                                localization.actions.delete.title(),
                                {
                                    confirmOptions: {
                                        message: localization.actions.delete.prompt({ organizationName: (item.configuration as Contract.DatadogOrganizationConfiguration).name })
                                    },
                                    disabled: deleteOrganizationExecuting,
                                    icon: <DeleteIcon/>
                                })
                        ]}/>
                </Fragment>}
        </Stack>);
}