import React from "react";
import { CustomerConsoleAppUrlHelper, ProfileActions } from "../../../../../../../common";
import { Profile } from "../../../../Code/components/Scans/components/Scan";
import { SideViewItem } from "../../../SideView";
import { SideViewDefinition } from "../useDefinition";

export function useCodeScanDefinition(item: SideViewItem): SideViewDefinition {
    return {
        getActionsElement:
            () =>
                <ProfileActions
                    getLink={() => CustomerConsoleAppUrlHelper.getCodeScanProfileUrl(item.id)!}
                    key={`${item.id}-actions`}/>,
        getViewElement:
            () =>
                <Profile
                    key={`${item.id}-profile`}
                    scanId={item.id}/>
    };
}