import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useOciIamDomainLicenseTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useOciIamDomainLicenseTypeTranslator",
            () => ({
                [Contract.TypeNames.OciIamDomainLicenseType]: {
                    [Contract.OciIamDomainLicenseType.Enterprise]: "Enterprise",
                    [Contract.OciIamDomainLicenseType.ExternalUser]: "External User",
                    [Contract.OciIamDomainLicenseType.Free]: "Free",
                    [Contract.OciIamDomainLicenseType.OracleApps]: "Oracle Apps",
                    [Contract.OciIamDomainLicenseType.OracleAppsPremium]: "Oracle Apps Premium",
                    [Contract.OciIamDomainLicenseType.OracleIdentityCloudServiceConsumerUserByolUserPerMonth]: "Oracle Identity Cloud Service - Consumer User - BYOL - User Per Month",
                    [Contract.OciIamDomainLicenseType.OracleIdentityCloudServiceConsumerUserUserPerMonth]: "Oracle Identity Cloud Service - Consumer User - User Per Month",
                    [Contract.OciIamDomainLicenseType.OracleIdentityCloudServiceEnterpriseUserByolUserPerMonth]: "Oracle Identity Cloud Service - Enterprise User - BYOL - User Per Month",
                    [Contract.OciIamDomainLicenseType.OracleIdentityCloudServiceEnterpriseUserUserPerMonth]: "Oracle Identity Cloud Service - Enterprise User - User Per Month",
                    [Contract.OciIamDomainLicenseType.OracleIdentityFoundationCloudServiceEach]: "Oracle Identity Foundation Cloud Service - Each",
                    [Contract.OciIamDomainLicenseType.Premium]: "Premium"
                }
            }));
    return (type: Contract.OciIamDomainLicenseType) =>
        localization[Contract.TypeNames.OciIamDomainLicenseType][type]();
}