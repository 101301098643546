import { List, ListItem } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { SubnetItem } from "../../SourceIpAddressSelector";
import { Item } from "./components";

type SubnetsProps = {
    disabled?: boolean;
    existingSubnetItems: SubnetItem[];
    onSubnetItemsChanged: (subnetItems: SubnetItem[]) => void;
};

export function Subnets({ disabled = false, existingSubnetItems, onSubnetItemsChanged }: SubnetsProps) {
    const [subnetItems, setSubnetItems] = useState(existingSubnetItems);
    useEffect(
        () => onSubnetItemsChanged(subnetItems),
        [subnetItems]);

    return (
        <List>
            {_(subnetItems).
                concat(new SubnetItem(false, "")).
                map(
                    (subnetItem, subnetItemIndex) =>
                        <ListItem
                            key={subnetItem.id}
                            sx={{ padding: 0 }}>
                            {subnetItemIndex < subnetItems.length
                                ? <Item
                                    subnetItem={subnetItem}
                                    onChange={
                                        updatedSubnetItem => {
                                            subnetItems[subnetItemIndex] = updatedSubnetItem;
                                            setSubnetItems([...subnetItems]);
                                        }}
                                    onDelete={
                                        () =>
                                            setSubnetItems(
                                                _.filter(
                                                    subnetItems,
                                                    (subnetItem, currentSubnetItemIndex) => currentSubnetItemIndex !== subnetItemIndex))}/>
                                : <Item
                                    disabled={disabled}
                                    subnetItem={subnetItem}
                                    onChange={subnetItem => setSubnetItems(_.concat(subnetItems, subnetItem))}/>}
                        </ListItem>).
                value()}
        </List>);
}