﻿import { DeleteIcon, map, Message, UnexpectedError } from "@infrastructure";
import { Box, IconButton, Stack } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { EntityMultiSelectPermissionEvaluationEntities, EntityMultiSelectProperty } from "../..";
import { Contract } from "../../../../../../controllers";
import { EntityMatchConditionHelper } from "../../utilities";
import { AadDirectoryPrincipalGroupIds, All, Attributes, AwsIamPrincipalCriticalActionSeverityPermission, AwsIamUserGroupIds, AwsKmsKeyCustomerManaged, AwsSsoPermissionSetProvisioned, AzureScopeResourceParentEntityIds, GciPrincipalGroupIds, GcpScopeResourceParentScopeResourceIds, Ids, NamePattern, Properties, Sensitive, Tags, Tenants, TypeNames } from "./components";

type ConditionProps = {
    builtInEntityAttributeTypeNames?: string[];
    condition: Contract.EntityMatchCondition;
    directoryEntityType?: boolean;
    disableManualCustomEntityAttributes?: boolean;
    entityTypeName: string;
    entityTypeNameVariant?: "name" | "viewName";
    errorMessage?: string;
    excludeCommonCloudProviderTenants?: boolean;
    includeServiceName?: boolean;
    onDelete: () => void;
    permissionEvaluationEntities?: EntityMultiSelectPermissionEvaluationEntities;
    scopeResourceParentEntityTypeName?: string;
    tenantsTenantTypes?: Contract.TenantType[];
};

export function Condition({ builtInEntityAttributeTypeNames = [], condition, directoryEntityType = false, disableManualCustomEntityAttributes = false, entityTypeName, entityTypeNameVariant = "viewName", errorMessage, excludeCommonCloudProviderTenants = false, includeServiceName = false, onDelete, permissionEvaluationEntities, scopeResourceParentEntityTypeName, tenantsTenantTypes }: ConditionProps) {
    const property =
        useMemo(
            () => EntityMatchConditionHelper.getPropertyData(EntityMatchConditionHelper.getConditionPropertyId(condition)).property,
            [condition]);
    const [hover, setHover] = useState(false);
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}>
                <Box>
                    {map(
                        property,
                        {
                            [EntityMultiSelectProperty.AadDirectoryPrincipalGroupIds]:
                                () =>
                                    <AadDirectoryPrincipalGroupIds
                                        entityTypeName={entityTypeName}
                                        includeServiceName={includeServiceName}/>,
                            [EntityMultiSelectProperty.All]:
                                () =>
                                    <All
                                        entityTypeName={entityTypeName}
                                        includeServiceName={includeServiceName}/>,
                            [EntityMultiSelectProperty.Attributes]:
                                () =>
                                    <Attributes
                                        builtInEntityAttributeTypeNames={builtInEntityAttributeTypeNames}
                                        directoryEntityType={directoryEntityType}
                                        disableManualCustomEntityAttributes={disableManualCustomEntityAttributes}
                                        entityTypeName={entityTypeName}
                                        includeServiceName={includeServiceName}/>,
                            [EntityMultiSelectProperty.AwsIamPrincipalCriticalActionSeverityPermission]:
                                () =>
                                    <AwsIamPrincipalCriticalActionSeverityPermission
                                        entityTypeName={entityTypeName}
                                        includeServiceName={includeServiceName}/>,
                            [EntityMultiSelectProperty.AwsIamUserGroupIds]:
                                () =>
                                    <AwsIamUserGroupIds
                                        entityTypeName={entityTypeName}
                                        includeServiceName={includeServiceName}/>,
                            [EntityMultiSelectProperty.AwsKmsKeyCustomerManaged]:
                                () =>
                                    <AwsKmsKeyCustomerManaged
                                        entityTypeName={entityTypeName}
                                        includeServiceName={includeServiceName}/>,
                            [EntityMultiSelectProperty.AwsSsoPermissionSetProvisioned]:
                                () => <AwsSsoPermissionSetProvisioned/>,
                            [EntityMultiSelectProperty.AzureScopeResourceParentEntityIds]:
                                () =>
                                    <AzureScopeResourceParentEntityIds
                                        entityTypeName={entityTypeName}
                                        excludeCommonCloudProviderTenants={excludeCommonCloudProviderTenants}
                                        includeServiceName={includeServiceName}
                                        permissionEvaluationEntities={permissionEvaluationEntities}
                                        scopeResourceParentEntityTypeName={scopeResourceParentEntityTypeName}/>,
                            [EntityMultiSelectProperty.GciPrincipalGroupIds]:
                                () =>
                                    <GciPrincipalGroupIds
                                        entityTypeName={entityTypeName}
                                        includeServiceName={includeServiceName}/>,
                            [EntityMultiSelectProperty.GcpScopeResourceParentScopeResourceIds]:
                                () =>
                                    <GcpScopeResourceParentScopeResourceIds
                                        entityTypeName={entityTypeName}
                                        excludeCommonCloudProviderTenants={excludeCommonCloudProviderTenants}
                                        includeServiceName={includeServiceName}
                                        permissionEvaluationEntities={permissionEvaluationEntities}
                                        scopeResourceParentEntityTypeName={scopeResourceParentEntityTypeName}/>,
                            [EntityMultiSelectProperty.Ids]:
                                () =>
                                    <Ids
                                        directoryEntityType={directoryEntityType}
                                        entityTypeName={entityTypeName}
                                        excludeCommonCloudProviderTenants={excludeCommonCloudProviderTenants}
                                        includeServiceName={includeServiceName}
                                        permissionEvaluationEntities={permissionEvaluationEntities}/>,
                            [EntityMultiSelectProperty.NamePattern]:
                                () =>
                                    <NamePattern
                                        entityTypeName={entityTypeName}
                                        includeServiceName={includeServiceName}/>,
                            [EntityMultiSelectProperty.Properties]:
                                () =>
                                    <Properties
                                        entityTypeName={entityTypeName}
                                        identifier={(condition as Contract.EntityPropertyMatchCondition<string>).identifier}
                                        includeServiceName={includeServiceName}/>,
                            [EntityMultiSelectProperty.Sensitive]:
                                () =>
                                    <Sensitive
                                        entityTypeName={entityTypeName}
                                        includeServiceName={includeServiceName}/>,
                            [EntityMultiSelectProperty.Tags]:
                                () =>
                                    <Tags
                                        entityTypeName={entityTypeName}
                                        includeServiceName={includeServiceName}/>,
                            [EntityMultiSelectProperty.TenantIds]:
                                () =>
                                    <Tenants
                                        entityTypeName={entityTypeName}
                                        includeServiceName={includeServiceName}
                                        tenantTypes={tenantsTenantTypes}/>,
                            [EntityMultiSelectProperty.TypeNames]:
                                () =>
                                    <TypeNames
                                        entityTypeName={entityTypeName}
                                        entityTypeNameVariant={entityTypeNameVariant}
                                        includeServiceName={includeServiceName}
                                        permissionEvaluationEntities={!_.isNil(permissionEvaluationEntities)}/>
                        },
                        () => {
                            throw new UnexpectedError("EntityMultiSelectProperty", property);
                        })}
                </Box>
                <IconButton
                    size="small"
                    sx={{
                        visibility:
                            hover
                                ? "visible"
                                : "hidden"
                    }}
                    onClick={onDelete}>
                    <DeleteIcon/>
                </IconButton>
            </Stack>
            {!_.isEmpty(errorMessage) &&
                <Message
                    level="error"
                    title={errorMessage}/>}
        </Stack>);
}