import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, TenantRoleItemAccordion } from "../../../../../../../../../../../../../common";
import { PolicyType } from "./Manual";

export type PolicyDocumentAccordionProps = {
    policyDocument: Contract.AwsIamPolicyDocument;
    policyType: PolicyType;
    selectedPolicyType?: PolicyType;
    setSelectedPolicyType: (policyType?: PolicyType) => void;
    title: string;
};

export function PolicyDocumentAccordion({ policyDocument, policyType, selectedPolicyType, setSelectedPolicyType, title }: PolicyDocumentAccordionProps) {
    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useAwsDefinition.addOrEdit.roleItem.policyDocumentAccordion",
            () => ({
                tooltip: "Copy Inline Policy JSON"
            }));

    return (
        <TenantRoleItemAccordion
            expanded={policyType === selectedPolicyType}
            rawText={policyDocument.raw}
            title={title}
            tooltip={localization.tooltip()}
            onChange={expanded => setSelectedPolicyType(
                expanded
                    ? policyType
                    : undefined) }/>);
}