import { Stack, SxProps, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Sx } from "../assets";

export type EmptyMessageSize = "medium" | "small";

type EmptyMessageProps = {
    containerSx?: SxProps;
    message: string;
    noWrap?: boolean;
    size?: EmptyMessageSize;
    sx?: SxProps;
    verticalCenter?: boolean;
};

export function EmptyMessage({ containerSx, message, noWrap, size = "medium", sx, verticalCenter }: EmptyMessageProps) {
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            sx={{
                ...(verticalCenter
                    ? {
                        flex: 1,
                        justifyContent: "center",
                        minHeight: theme.spacing(10)
                    }
                    : {
                        padding:
                            size === "small"
                                ? theme.spacing(2)
                                : theme.spacing(5, 3)
                    }),
                ...containerSx
            }}>
            <Typography
                noWrap={noWrap ?? true}
                sx={
                    Sx.combine(
                        { fontWeight: 400 },
                        sx)
                }
                variant={
                    size === "small"
                        ? "h4"
                        : "h3"}>
                {message}
            </Typography>
        </Stack>);
}

export class EmptyMessageText {
    constructor(
        private withoutFilter: string,
        private withFilter?: string) {
    }

    public getText(hasFilter?: boolean) {
        return !_.isNil(this.withFilter) && hasFilter === true
            ? this.withFilter
            : this.withoutFilter;
    }
}