import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { useOciBlockStorageBootVolumeStatusTranslator } from "../../../../../../../../../Entities/hooks";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetOciResourceRiskContext } from "./useGetOciResourceRiskContext";

export function useGetOciBlockStorageBootVolumeRiskContext() {
    return useMemo(
        () => useOciBlockStorageBootVolumeRiskContext,
        []);
}

function useOciBlockStorageBootVolumeRiskContext(bootVolumeModel: Contract.OciBlockStorageBootVolumeModel) {
    const bootVolume = bootVolumeModel.entity as Contract.OciBlockStorageBootVolume;
    const resourceRiskContext = useGetOciResourceRiskContext()(bootVolumeModel);

    const bootVolumeStatusTranslator = useOciBlockStorageBootVolumeStatusTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.contexts.useGetOciBlockStorageBootVolumeRiskContext",
            () => ({
                instance: {
                    attached: "The {{translatedBootVolumeTypeName}} is attached to {{instance}}",
                    notAttached: "The {{translatedBootVolumeTypeName}} is not attached to an instance"
                },
                size: "The {{translatedBootVolumeTypeName}}'s size is {{size | NumberFormatter.storage}}",
                status: "The {{translatedBootVolumeTypeName}}'s state is {{status}}"
            }));

    const translatedBootVolumeTypeName =
        entityTypeNameTranslator(
            bootVolume.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...resourceRiskContext,
        instance:
            new RiskDefinitionContextItem(
                (_.isNil(bootVolumeModel.instanceIdReference)
                    ? localization.instance.notAttached
                    : localization.instance.attached)({
                    instance:
                        <Entity
                            entityIdOrModel={bootVolumeModel.instanceIdReference!}
                            variant="text"/>,
                    translatedBootVolumeTypeName
                })),
        size:
            new RiskDefinitionContextItem(
                localization.size({
                    size: bootVolume.size,
                    translatedBootVolumeTypeName
                })),
        status:
            new RiskDefinitionContextItem(
                localization.status({
                    status: bootVolumeStatusTranslator(bootVolume.status),
                    translatedBootVolumeTypeName
                }))
    };
}