﻿import { MailParser, Optional, UnexpectedError } from "@infrastructure";
import _ from "lodash";
import { Contract } from "../controllers";
import { scopeSystemEntityModelStore, tenantModelStore } from "../stores";
import { ScopeHelper } from "./scopeHelper";
import { TenantHelper } from "./tenantHelper";
import { TypeHelper } from "./typeHelper";

export class EntityPropertyHelper {
    public static getDefinitionIdentifiers(tenantModels: Contract.TenantModel[]) {
        const propertyTenantModels =
            _.filter(
                tenantModels,
                tenantModel =>
                    _.includes(
                        TenantHelper.PropertyTenantTypes,
                        tenantModel.tenantType));
        const parentEntityPropertyDefinitionModels =
            ScopeHelper.getParentScopeSystemEntityModelsUnion(
                _.map(
                    propertyTenantModels,
                    tenantModel => tenantModel.configuration.id),
                scopeSystemEntityModelStore.useGetEntityPropertyDefinition());

        return _(parentEntityPropertyDefinitionModels).
            map(parentEntityPropertyDefinitionModel => ((parentEntityPropertyDefinitionModel.configuration) as Contract.EntityPropertyDefinitionConfiguration).identifier).
            uniqBy(entityPropertyIdentifier => entityPropertyIdentifier.raw).
            orderBy(
                [
                    entityPropertyIdentifier => entityPropertyIdentifier.type !== Contract.EntityPropertyType.Owner,
                    entityPropertyIdentifier => entityPropertyIdentifier.type === Contract.EntityPropertyType.Custom,
                    entityPropertyIdentifier => entityPropertyIdentifier.name
                ]).
            value();
    }

    public static getOrderedValues(property: Contract.EntityProperty): Contract.EntityPropertyValue[] {
        return property.typeName === Contract.TypeNames.EntityPrincipalReferenceProperty
            ? _.orderBy(
                property.values as Contract.EntityPropertyPrincipalReferenceValue[],
                [
                    principalReferenceValue => principalReferenceValue.principalSearchableIdReference.idReference.startsWith(Contract.TenantType.Unknown.toLowerCase()),
                    principalReferenceValue => !MailParser.validate(principalReferenceValue.principalSearchableIdReference.displayName),
                    principalReferenceValue => principalReferenceValue.principalSearchableIdReference.displayName
                ])
            : _(property.values as Contract.EntityPropertyStringValue[]).
                orderBy(stringValue => stringValue.string).
                value();
    }

    public static isResourceEnvironmentConfigured(tenantModels: Contract.TenantModel[]) {
        return _.some(
            EntityPropertyHelper.getDefinitionIdentifiers(tenantModels),
            entityPropertyDefinitionIdentifier => entityPropertyDefinitionIdentifier.type === Contract.EntityPropertyType.Environment);
    }

    public static isResourceOwnerConfigured(tenantModels: Contract.TenantModel[]) {
        return _.some(
            EntityPropertyHelper.getDefinitionIdentifiers(tenantModels),
            entityPropertyDefinitionIdentifier => entityPropertyDefinitionIdentifier.type === Contract.EntityPropertyType.Owner);
    }

    public static tryParseRawIdentifier(rawIdentifier: string) {
        const rawIdentifierParts = _.split(rawIdentifier, "/");
        if (_.size(rawIdentifierParts) !== 3) {
            return undefined;
        }

        const type = TypeHelper.tryParseEnum(Contract.TypeNames.EntityPropertyType, rawIdentifierParts[1]) as Optional<Contract.EntityPropertyType>;
        const valueType = TypeHelper.tryParseEnum(Contract.TypeNames.EntityPropertyValueType, rawIdentifierParts[2]) as Optional<Contract.EntityPropertyValueType>;
        if (_.isNil(type) ||
            _.isNil(valueType)) {
            return undefined;
        }
        return new Contract.EntityPropertyIdentifier(
            rawIdentifierParts[0],
            rawIdentifier,
            type,
            valueType);
    }

    public static parseRawIdentifier(rawIdentifier: string) {
        const identifier = this.tryParseRawIdentifier(rawIdentifier);
        if (_.isNil(identifier)) {
            throw new UnexpectedError("EntityPropertyHelper.parseRawIdentifier", rawIdentifier);
        }

        return identifier;
    }

    public static showRiskResourceEnvironment(riskModel: Contract.RiskModel) {
        return !_.isNil(riskModel.riskedEntityEnvironment) &&
            this.isResourceEnvironmentConfigured([tenantModelStore.useGet(riskModel.tenantId)]);
    }

    public static showRiskResourceOwner(riskModel: Contract.RiskModel) {
        return !_.isNil(riskModel.riskedEntityOwner) &&
            this.isResourceOwnerConfigured([tenantModelStore.useGet(riskModel.tenantId)]);
    }
}