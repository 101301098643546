﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../../tenants";
import { RiskDefinitionSection } from "../../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../../useCommonSectionsAndDescriptionDefinition";
import { LaunchTemplateContextSection, RevisionsDeviceTable } from "./components";

export function useAwsEc2SnapshotEncryptionDisabledRiskLaunchTemplateDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsEc2SnapshotEncryptionDisabledRisk;
    const riskData = risk.data as Contract.AwsEc2SnapshotEncryptionDisabledRiskLaunchTemplateData;
    const launchTemplateModel = entityModelStore.useGet(risk.entityId) as Contract.AwsEc2LaunchTemplateModel;
    const launchTemplate = launchTemplateModel.entity as Contract.AwsEc2LaunchTemplate;
    const volumeModels = entityModelStore.useGet(riskData.volumeIds);

    const unencryptedVolumeModels =
        _.filter(
            volumeModels,
            volumeModel => _.isEmpty((volumeModel as Contract.AwsEc2VolumeModel).kmsEncryptionKeyIdReferences));

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2SnapshotEncryptionDisabledRiskDefinition.hooks.useAwsEc2SnapshotEncryptionDisabledRiskLaunchTemplateDefinition",
            () => ({
                description: {
                    none: "Launch template {{launchTemplate}} has unencrypted device mapping configuration",
                    snapshot: "Launch template {{launchTemplate}} was used to launch {{instances}} with {{volumes}} with {{snapshotIds}}"
                },
                sections: {
                    resolution: {
                        step1: "Click on **Actions** and **Modify template (Create new version)**",
                        step2: "For each unencrypted device under **Storage**, switch the **Encrypted** dropdown to **Yes**.",
                        step3: "Click **Create template version**",
                        step4: "Before replacing the template version, verify that all identities used to launch the instances have decrypt permissions on the selected encryption keys",
                        step5: "Update auto scaling groups and applications to use the new secured launch template",
                        step6: "Click on **Delete template version** to delete the insecure versions",
                        step7: "Wait until all unencrypted instances are relaunched with the encrypted launch template version",
                        step8: "For each unencrypted snapshot, select it and click **Actions** on top and then **Delete**"
                    },
                    title: "Launch Template Revisions"
                },
                snapshots: [
                    "1 unencrypted snapshot",
                    "{{count | NumberFormatter.humanize}} unencrypted snapshots"
                ],
                volumes: [
                    "1 unencrypted volume",
                    "{{count | NumberFormatter.humanize}} unencrypted volumes"
                ]
            }));
    return useCommonSectionsAndDescriptionDefinition(
        (_.isEmpty(risk.aggregatedEntityIds)
            ? localization.description.none
            : localization.description.snapshot)({
            instances:
                <InlineEntities
                    entityIdsOrModels={riskData.instanceIds}
                    entityTypeName={Contract.TypeNames.AwsEc2Instance}
                    variant="itemCountAndType"/>,
            launchTemplate:
                <Entity
                    entityIdOrModel={launchTemplateModel}
                    variant="text"/>,
            snapshotIds:
                <InlineEntities
                    entityIdsOrModels={risk.aggregatedEntityIds}
                    entityTypeName={Contract.TypeNames.AwsEc2Snapshot}
                    namePluralizer={localization.snapshots}
                    variant="itemCountAndType"/>,
            volumes:
                _.isEmpty(unencryptedVolumeModels)
                    ? <InlineEntities
                        entityIdsOrModels={volumeModels!}
                        entityTypeName={Contract.TypeNames.AwsEc2Volume}
                        variant="itemCountAndType"/>
                    : <InlineEntities
                        entityIdsOrModels={unencryptedVolumeModels}
                        entityTypeName={Contract.TypeNames.AwsEc2Volume}
                        namePluralizer={localization.volumes}
                        variant="itemCountAndType"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Ec2,
                AwsConsoleUrlBuilder.getEc2LaunchTemplateUrl(launchTemplate)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6(),
            localization.sections.resolution.step7(),
            localization.sections.resolution.step8()
        ],
        riskModel,
        undefined,
        {
            contextSectionElement:
                <LaunchTemplateContextSection
                    launchTemplateModel={launchTemplateModel}
                    risk={risk}/>,
            sections: [
                new RiskDefinitionSection(
                    <RevisionsDeviceTable
                        launchTemplateModel={launchTemplateModel}
                        risk={risk}/>,
                    localization.sections.title())
            ]
        });
}