import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, DeferredFilter, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TenantFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData, ExportData } from "../../../../Table";

export function useAwsSsoGroupDefinition(definitionData: DefinitionData) {
    const principalModelFiltersPromise = definitionData.entityModelFiltersPromise as Promise<Contract.AwsSsoPrincipalModelFilters>;
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsSsoGroupDefinition",
            () => ({
                columns: {
                    roles: "Assigned Roles",
                    roleTenants: "Assigned Role Account",
                    users: "Members"
                }
            }));

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getData: (item: Contract.AwsSsoGroupModel) => item.roleIds,
                    getItem:
                        (item: Contract.AwsSsoGroupModel, exportData: ExportData) => ({
                            [localization.columns.roles()]:
                                _(item.roleIds).
                                    map(roleId => exportData.relatedEntityModelMap[roleId].entity.displayReference).
                                    join("\n")
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsSsoPrincipalRoles)}
                                placeholder={localization.columns.roles()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsSsoPrincipalRoles}
                key={Contract.EntityModelProperty.AwsSsoPrincipalRoles}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsSsoGroupModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.roleIds}
                            entityTypeName={Contract.TypeNames.AwsIamRole}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.roles()}/>,
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <DeferredFilter
                                promiseOrGetPromise={principalModelFiltersPromise}
                                title={localization.columns.roleTenants()}>
                                {filters =>
                                    <TenantFilter
                                        emptyValueEnabled={filters.roleTenantIdItems.emptyValue}
                                        placeholder={localization.columns.roleTenants()}
                                        tenantIds={filters.roleTenantIdItems.items}/>}
                            </DeferredFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AwsSsoPrincipalRoleTenants}
                key={Contract.EntityModelProperty.AwsSsoPrincipalRoleTenants}
                title={localization.columns.roleTenants()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsSsoGroupModel>(
                        Contract.TypeNames.AwsSsoUser,
                        item => (item.entity as Contract.AwsIamGroup).userIds,
                        localization.columns.users())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityTypeFilter(
                                        Contract.TypeNames.AwsSsoUser,
                                        true)}
                                placeholder={localization.columns.users()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsSsoGroupUsers}
                key={Contract.EntityModelProperty.AwsSsoGroupUsers}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsSsoGroupModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={(item.entity as Contract.AwsSsoGroup).userIds}
                            entityTypeName={Contract.TypeNames.AwsSsoUser}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.users()}/>,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            commonResourceDefinition.columns.attributesColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsSsoGroupRequest(
                new Contract.EntityControllerGetEntityModelPageAwsSsoGroupRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    undefined,
                    undefined,
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsSsoPrincipalRoles]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsSsoPrincipalRoleTenants]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsSsoGroupUsers])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(undefined)));
}