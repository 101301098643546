import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../../common";
import { RiskDefinition } from "../../../../../../../utilities";
import { useGcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskDefinition, useGcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskDefinition } from "./hooks";

export function useGcpPrincipalWideScopeImpersonateServiceAccountActionExistsRiskDefinition(riskModel: Contract.RiskModel): RiskDefinition {
    const useDefinition =
        useMemo(
            () => {
                switch (riskModel.risk.typeName) {
                    case Contract.TypeNames.GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRisk:
                        return useGcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskDefinition;
                    case Contract.TypeNames.GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRisk:
                        return useGcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskDefinition;
                    default:
                        throw new UnexpectedError("riskModel.risk.typeName", riskModel.risk.typeName);
                }
            },
            []);

    return useDefinition(riskModel as Contract.GcpPrincipalWideScopeImpersonateServiceAccountActionExistsRiskModel);
}