import { Dictionary } from "lodash";
import { Contract, RiskController } from "..";

export class RiskTypeMetadataModelHelper {
    private static riskTypeNameToMetadataModelMap: Dictionary<Contract.RiskTypeMetadataModel>;

    public static async initialize() {
        const { riskTypeNameToMetadataModelMap } = await RiskController.getRiskTypeMetadataModels();
        RiskTypeMetadataModelHelper.riskTypeNameToMetadataModelMap = riskTypeNameToMetadataModelMap;
    }

    public static get(riskTypeName: string) {
        return RiskTypeMetadataModelHelper.riskTypeNameToMetadataModelMap[riskTypeName];
    }
}