﻿import { OrderedWizardItem, useLocalization } from "@infrastructure";
import React, { useMemo } from "react";
import { ScannerCommand, ScannerItem } from "../../components";
import { ScanContainerImageActionItem, ScanIacActionItem, SecretItem, SyncIacActionItem } from "./components";

export function useGitHubWorkflow() {
    const commands =
        useMemo(
            () =>
                [
                    {
                        contentElement: <ScanContainerImageActionItem/>,
                        type: ScannerCommand.ContainerImageScan
                    },
                    {
                        contentElement: <ScanIacActionItem/>,
                        type: ScannerCommand.IacScan
                    },
                    {
                        contentElement: <SyncIacActionItem/>,
                        type: ScannerCommand.IacSync
                    }
                ],
            []);
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodePipelineItems.codePipeline.addOrEdit.hooks.useDefinition.hooks.useGitHubWorkflow",
            () => ({
                steps: {
                    scanner: "Add Actions",
                    secret: "Add GitHub Secret (Recommended)"
                },
                title: "Add the relevant actions to your GitHub Workflow:"
            }));

    return {
        apiKeyItem: true,
        orderedWizardItems: [
            <OrderedWizardItem
                key="secret"
                title={localization.steps.secret()}>
                <SecretItem/>
            </OrderedWizardItem>,
            <OrderedWizardItem
                key="scannerItem"
                title={localization.steps.scanner()}>
                <ScannerItem
                    commands={commands}
                    title={localization.title()}/>
            </OrderedWizardItem>
        ]
    };
}