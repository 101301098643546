import { Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, useObjectTypeCategoryTranslator, useTheme } from "../../../../../../..";
import { ObjectTypeMetadataModelHelper } from "../../../../../../../utilities";
import { useUdmObjectPropertyIdTranslator, useUdmObjectTypeNameTranslator } from "../../../../../hooks";
import { ObjectTypeCategoryIcon } from "../../../icons";
import { ObjectSelector, ObjectSelectorItem } from "../../ObjectSelector";
import { UdmObject } from "../../UdmObject";

type PopupProps = {
    objectTypeName: string;
    onPropertySelected: (property: Contract.UdmObjectProperty) => void;
};

export function Popup({ objectTypeName, onPropertySelected }: PopupProps) {
    const objectTypeCategoryTranslator = useObjectTypeCategoryTranslator();
    const udmObjectPropertyIdTranslator = useUdmObjectPropertyIdTranslator();
    const udmObjectTypeNameTranslator = useUdmObjectTypeNameTranslator();

    const [objectCategoryToRelationPropertiesMap, objectPropertyMap] =
        useMemo(
            () => {
                const objectTypeMetadataModel = ObjectTypeMetadataModelHelper.get(objectTypeName);
                const objectPropertyMap =
                    _.keyBy(
                        objectTypeMetadataModel.udmProperties,
                        udmProperty => udmProperty.id);

                const objectCategoryToRelationPropertiesMap =
                    _(objectTypeMetadataModel?.udmProperties).
                        filter(property => !_.isNil(property.relation)).
                        groupBy(property => ObjectTypeMetadataModelHelper.get(property.relation!.objectTypeName).category).
                        value();

                return [objectCategoryToRelationPropertiesMap, objectPropertyMap];
            },
            [objectTypeName]);

    const theme = useTheme();
    const objectSelectorItems =
        useMemo(
            () =>
                _(objectCategoryToRelationPropertiesMap).
                    keys().
                    orderBy([
                        objectTypeCategory => objectTypeCategory === Contract.ObjectTypeCategory.Risk,
                        objectTypeCategory => objectTypeCategoryTranslator(objectTypeCategory as Contract.ObjectTypeCategory)
                    ]).
                    map<ObjectSelectorItem>(
                        objectTypeCategory =>
                            new ObjectSelectorItem(
                                objectTypeCategory,
                                objectTypeCategoryTranslator(objectTypeCategory as Contract.ObjectTypeCategory),
                                objectTypeCategoryTranslator(objectTypeCategory as Contract.ObjectTypeCategory),
                                {
                                    icon:
                                        <ObjectTypeCategoryIcon category={objectTypeCategory as Contract.ObjectTypeCategory}/>,
                                    items:
                                        _(objectCategoryToRelationPropertiesMap[objectTypeCategory]).
                                            map(
                                                objectProperty =>
                                                    new ObjectSelectorItem(
                                                        objectProperty.id,
                                                        udmObjectTypeNameTranslator(objectProperty.relation!.objectTypeName),
                                                        <UdmObject objectTypeName={objectProperty.relation!.objectTypeName}/>,
                                                        {
                                                            description:
                                                                <Typography sx={{ color: theme.palette.text.secondary }}>
                                                                    {udmObjectPropertyIdTranslator(objectProperty.id, "description")}
                                                                </Typography>
                                                        })).
                                            orderBy(item => item.searchText).
                                            value()
                                })).
                    value(),
            [objectCategoryToRelationPropertiesMap, theme]);

    return (
        <ObjectSelector
            items={objectSelectorItems}
            selectedItemId={objectTypeName}
            onSelectedItemIdChanged={itemId => onPropertySelected(objectPropertyMap[itemId])}/>);
}