import { useExecuteOperation, useLocalization, useSetRoute } from "@infrastructure";
import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { Contract, CustomerConsoleAppUrlHelper, RiskController, TenantIcon, useTheme } from "../../../../../../../common";

type DeleteRiskProps = {
    riskId: string;
};

export function DeleteRisk({ riskId }: DeleteRiskProps) {
    const [{ deletedRisk }] =
        useExecuteOperation(
            [DeleteRisk, riskId],
            () => RiskController.getDeletedRisk(new Contract.RiskControllerGetDeletedRiskRequest(riskId)));
    const localization =
        useLocalization(
            "views.customer.risks.profile.deletedRisk",
            () => ({
                actions: {
                    risks: "See More Findings"
                },
                fields: {
                    reason: "**Reason**: {{reason}}",
                    systemDeletionTime: "**Date Changed**: {{systemDeletionTime | TimeFormatter.longDateTime}}",
                    tenantId: "**Account**: {{tenantIcon}} {{tenantId}}",
                    title: "**Policy Name**: {{title}}"
                },
                title: "The finding that you're looking for has changed"
            }));

    const setRoute = useSetRoute();
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            spacing={5}
            sx={{
                height: "100%",
                overflow: "hidden auto",
                padding: theme.spacing(2)
            }}>
            <Stack
                alignItems="center"
                justifyContent="center"
                spacing={5}
                sx={{
                    height: "100%",
                    padding: theme.spacing(3)
                }}>
                <Stack sx={{ width: theme.spacing(56) }}>
                    <Typography
                        sx={{
                            fontSize: 34,
                            fontWeight: 700,
                            textAlign: "center"
                        }}>
                        {localization.title()}
                    </Typography>
                </Stack>
                <Stack
                    alignItems="flex-start"
                    spacing={2}
                    sx={{
                        border: theme.border.primary,
                        borderRadius: theme.spacing(0.75),
                        padding: theme.spacing(4),
                        width: theme.spacing(100)
                    }}>
                    <Stack>
                        <Typography sx={{ fontSize: "16px" }}>
                            {localization.fields.title({ title: deletedRisk.title })}
                        </Typography>
                    </Stack>
                    <Typography sx={{ fontSize: "16px" }}>
                        {localization.fields.tenantId({
                            tenantIcon:
                                <TenantIcon
                                    sx={{
                                        display: "inline-flex",
                                        fontSize: "24px",
                                        verticalAlign: "middle"
                                    }}
                                    tenantType={deletedRisk.tenantType}/>,
                            tenantId: deletedRisk.tenantId
                        })}
                    </Typography>
                    <Typography sx={{ fontSize: "16px" }}>
                        {localization.fields.systemDeletionTime({ systemDeletionTime: deletedRisk.systemDeletionTime })}
                    </Typography>
                    <Typography sx={{ fontSize: "16px" }}>
                        {localization.fields.reason({ reason: deletedRisk.reason })}
                    </Typography>
                </Stack>
                <Button
                    sx={{ width: theme.spacing(26) }}
                    onClick={() => setRoute(CustomerConsoleAppUrlHelper.getRisksRelativeUrl())}>
                    {localization.actions.risks()}
                </Button>
            </Stack>
        </Stack>);
}