import { DataTableColumn, DataTableSortType, EnumValuesFilter, NumberFormatter, optionalTableCell, PagedValuesFilter, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { useAwsCommonResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { useAwsRoute53HostedZoneSigningStatusTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";

export function useAwsRoute53HostedZoneDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);

    const route53HostedZoneSigningStatusTranslator = useAwsRoute53HostedZoneSigningStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsRoute53HostedZoneDefinition",
            () => ({
                columns: {
                    description: "Description",
                    name: "Name",
                    publicNetworkAccess: {
                        false: "Private",
                        title: "Hosted Zone Type",
                        true: "Public"
                    },
                    recordCount: "Record Count",
                    signingStatus: "DNSSEC Signing Status"
                }
            }));

    function getPublicNetworkAccessTranslation(hostedZoneModel: Contract.EntityModel) {
        return (hostedZoneModel.entity as Contract.AwsRoute53HostedZone).publicNetworkAccess
            ? localization.columns.publicNetworkAccess.true()
            : localization.columns.publicNetworkAccess.false();
    }

    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsRoute53HostedZoneModel) => ({
                            [localization.columns.name()]: (item.entity as Contract.AwsRoute53HostedZone).name
                        })
                }}
                id="name"
                key="name"/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsRoute53HostedZoneModel) => ({
                            [localization.columns.publicNetworkAccess.title()]: getPublicNetworkAccessTranslation(item)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.publicNetworkAccess.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.publicNetworkAccess.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.publicNetworkAccess.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AwsRoute53HostedZonePublicNetworkAccess}
                itemProperty={getPublicNetworkAccessTranslation}
                key={Contract.EntityModelProperty.AwsRoute53HostedZonePublicNetworkAccess}
                title={localization.columns.publicNetworkAccess.title()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsRoute53HostedZoneModel) => ({
                            [localization.columns.description()]: (item.entity as Contract.AwsRoute53HostedZone).description ?? ""
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedValuesFilter
                                getValuePage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsRoute53HostedZoneDescription)}
                                placeholder={localization.columns.description()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsRoute53HostedZoneDescription}
                key={Contract.EntityModelProperty.AwsRoute53HostedZoneDescription}
                render={optionalTableCell<Contract.AwsRoute53HostedZoneModel>(item => (item.entity as Contract.AwsRoute53HostedZone).description)}
                title={localization.columns.description()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsRoute53HostedZoneModel) => ({
                            [localization.columns.recordCount()]: NumberFormatter.humanize(item.recordCount)
                        })
                }}
                id={Contract.EntityModelProperty.AwsRoute53HostedZoneRecordCount}
                itemProperty={(item: Contract.AwsRoute53HostedZoneModel) => item.recordCount}
                key={Contract.EntityModelProperty.AwsRoute53HostedZoneRecordCount}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.recordCount()}/>,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsRoute53HostedZoneModel) => ({
                            [localization.columns.signingStatus()]:
                                _.isNil((item.entity as Contract.AwsRoute53HostedZone).signingStatus)
                                    ? ""
                                    : route53HostedZoneSigningStatusTranslator((item.entity as Contract.AwsRoute53HostedZone).signingStatus!)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.AwsRoute53HostedZoneSigningStatus}
                                enumTypeTranslator={route53HostedZoneSigningStatusTranslator}
                                placeholder={localization.columns.signingStatus()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsRoute53HostedZoneSigningStatus}
                key={Contract.EntityModelProperty.AwsRoute53HostedZoneSigningStatus}
                render={
                    optionalTableCell<Contract.AwsRoute53HostedZoneModel>(
                        item =>
                            _.isNil((item.entity as Contract.AwsRoute53HostedZone).signingStatus)
                                ? undefined
                                : route53HostedZoneSigningStatusTranslator((item.entity as Contract.AwsRoute53HostedZone).signingStatus!))}
                title={localization.columns.signingStatus()}/>,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsRoute53HostedZoneRequest(
                new Contract.EntityControllerGetEntityModelPageAwsRoute53HostedZoneRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsRoute53HostedZoneDescription]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsRoute53HostedZonePublicNetworkAccess]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsRoute53HostedZoneSigningStatus])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}