import { IconNodeContent, NodeContentContextMenuItem, Optional, useDirectedGraphNodeContext, useLocalization, useSetRoute } from "@infrastructure";
import _ from "lodash";
import React, { MutableRefObject } from "react";
import { CustomerConsoleAppUrlHelper, Entity, entityModelStore, getEntityNodeContentAnchorPoint, getEntityNodeContentSize, useEntityTypeNameTranslator, useNodeContentFilterContextMenuItems, useTheme } from "../../../../../";
import { ToolbarActions, ToolbarFilterId } from "../..";

type SecurityPerimeterNodeContentProps = {
    securityPerimeterResourceId: string;
    toolbarActionsRef: MutableRefObject<Optional<ToolbarActions>>;
    toolbarFilterId?: ToolbarFilterId;
};

export function SecurityPerimeterNodeContent({ securityPerimeterResourceId, toolbarActionsRef, toolbarFilterId }: SecurityPerimeterNodeContentProps) {
    const securityPerimeterResourceModel = entityModelStore.useGet(securityPerimeterResourceId);
    const { modelNode } = useDirectedGraphNodeContext();

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.network.graph.securityPerimeterNodeContent",
            () => ({
                menu: {
                    profile: "Go to {{translatedEntityTypeName}} profile page"
                }
            }));

    const filterContextMenuItems =
        useNodeContentFilterContextMenuItems(
            1,
            <Entity
                entityIdOrModel={securityPerimeterResourceId}
                linkOptions={{ disabled: true }}
                variant="text"/>,
            !_.isNil(toolbarFilterId)
                ? type =>
                    toolbarActionsRef.current!.filter(
                        toolbarFilterId,
                        type,
                        [securityPerimeterResourceId])
                : undefined);

    const setRoute = useSetRoute();
    const theme = useTheme();
    const url = CustomerConsoleAppUrlHelper.getEntityProfileRelativeUrl(securityPerimeterResourceModel);
    return (
        <IconNodeContent
            contextMenuItems={
                _<NodeContentContextMenuItem>([]).
                    concatIf(
                        !_.isNil(url),
                        () =>
                            new NodeContentContextMenuItem(
                                () => setRoute(url!),
                                localization.menu.profile({
                                    translatedEntityTypeName:
                                        entityTypeNameTranslator(
                                            securityPerimeterResourceModel.entity.typeName,
                                            {
                                                includeServiceName: false,
                                                variant: "text"
                                            })
                                }))).
                    concat(filterContextMenuItems).
                    value()}
            iconOptions={{
                highlightColor: theme.palette.primary.main,
                icon:
                    <Entity
                        blurred={modelNode.options.appearance === "normal"}
                        entityIdOrModel={securityPerimeterResourceModel}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: true }}
                        variant="icon"/>,
                shape: "rectangle"
            }}
            size={getSecurityPerimeterNodeContentSize()}
            titleElementOrOptions={
                <Entity
                    entityIdOrModel={securityPerimeterResourceModel}
                    glanceOptions={{ miniGlancePlacement: "center" }}
                    linkOptions={{ disabled: true }}
                    sxOptions={{
                        subtitle: { textAlign: "center" },
                        title: { textAlign: "center" }
                    }}
                    tenantNameTranslatorOptions={{ includeRawId: false }}
                    variant="textTypeTenant"/>}
            titlePosition="bottom"/>);
}

export const getSecurityPerimeterNodeContentAnchorPoint =
    (anchorPlacement: "left" | "right") =>
        getEntityNodeContentAnchorPoint(
            anchorPlacement,
            "bottom",
            "large");

export const getSecurityPerimeterNodeContentSize =
    () => getEntityNodeContentSize("bottom", "large");