import { EmptyMessageText, useLocalization } from "@infrastructure";
import React, { useContext } from "react";
import { infoContext } from ".";
import { Contract, InfoItemLocation, ResourceTag, ResourceTagData, ResourceTagHelper, VerticalTopItemsInfoItem } from "../../../../../../../common";

export type TagsInfoItemProps = {
    location?: InfoItemLocation;
    resourceTags: Contract.ResourceTag[];
    title: string;
};

export function TagsInfoItem({ resourceTags, title }: TagsInfoItemProps) {
    const infoContextValue = useContext(infoContext);
    const localization =
        useLocalization(
            "views.customer.entities.profile.tagsInfoItem",
            () => ({
                empty: {
                    withFilter: "No Matching Tags",
                    withoutFilter: "No Tags"
                }
            }));
    return (
        <VerticalTopItemsInfoItem
            emptyMessageText={
                new EmptyMessageText(
                    localization.empty.withoutFilter(),
                    localization.empty.withFilter())}
            getSortValue={(tag: ResourceTagData) => tag.displayName}
            items={ResourceTagHelper.getDatas(resourceTags)}
            title={title}>
            {(resourceTagData: Contract.ResourceTag) =>
                <ResourceTag
                    copyToClipboard={true}
                    key={resourceTagData.key}
                    resourceTag={resourceTagData}
                    sx={{
                        maxWidth: "fit-content",
                        ...infoContextValue?.itemValueSx
                    }}
                    variant="item"/>}
        </VerticalTopItemsInfoItem>);
}