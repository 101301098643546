﻿import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../../common";
import { RiskDefinition } from "../../../../../../../utilities";
import { useGcpComputeLoadBalancerInsecureSslPolicyRiskLoadBalancerDefinition, useGcpComputeLoadBalancerInsecureSslPolicyRiskSslPolicyDefinition } from "./hooks";

export function useGcpComputeLoadBalancerInsecureSslPolicyRiskDefinition(riskModel: Contract.RiskModel): RiskDefinition {
    const risk = riskModel.risk as Contract.GcpComputeLoadBalancerInsecureSslPolicyRisk;
    const useDefinition =
        useMemo(
            () => {
                switch (risk.aggregatingEntityTypeName) {
                    case Contract.TypeNames.GcpComputeLoadBalancer:
                        return useGcpComputeLoadBalancerInsecureSslPolicyRiskLoadBalancerDefinition;
                    case Contract.TypeNames.GcpComputeSslPolicy:
                        return useGcpComputeLoadBalancerInsecureSslPolicyRiskSslPolicyDefinition;
                    default:
                        throw new UnexpectedError("risk.aggregatingEntityTypeName", risk.aggregatingEntityTypeName);
                }
            },
            []);

    return useDefinition(riskModel as Contract.GcpComputeLoadBalancerInsecureSslPolicyRiskModel);
}