﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAwsEc2VpcEndpointStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAwsEc2VpcEndpointStatusTranslator",
            () => ({
                [Contract.TypeNames.AwsEc2VpcEndpointStatus]: {
                    [Contract.AwsEc2VpcEndpointStatus.Available]: "Available",
                    [Contract.AwsEc2VpcEndpointStatus.Deleted]: "Deleted",
                    [Contract.AwsEc2VpcEndpointStatus.Deleting]: "Deleting",
                    [Contract.AwsEc2VpcEndpointStatus.Expired]: "Expired",
                    [Contract.AwsEc2VpcEndpointStatus.Failed]: "Failed",
                    [Contract.AwsEc2VpcEndpointStatus.Pending]: "Pending",
                    [Contract.AwsEc2VpcEndpointStatus.PendingAcceptance]: "Pending Acceptance",
                    [Contract.AwsEc2VpcEndpointStatus.Rejected]: "Rejected"
                }
            }));
    return (status: Contract.AwsEc2VpcEndpointStatus) =>
        localization[Contract.TypeNames.AwsEc2VpcEndpointStatus][status]();
}