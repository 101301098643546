﻿import { AddIcon, DataTable, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableSort, DataTableSortDirection, DataTableSortType, EmptyMessageText, map, NotValidIcon, Optional, optionalTableCell, StringHelper, TimeFormatter, TimeHelper, useChangeEffect, useLocalization } from "@infrastructure";
import { Button, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useRef } from "react";
import { useSetCodePipelineContext } from "../..";
import { Contract, useTheme } from "../../../../../../../../../../../../../../common";
import { useCodePipelineTypeTranslator } from "../../../../../../../../../../../../../../tenants";
import { ActionsCell } from "./components";

type TableProps = {
    pipelineModels: Contract.CodePipelineModel[];
    pipelineType: Contract.CodePipelineType;
};

export function Table({ pipelineModels, pipelineType }: TableProps) {
    const theme = useTheme();

    const setCodePipelineContext = useSetCodePipelineContext();

    const pipelineTypeTranslator = useCodePipelineTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodePipelineItems.codePipeline.table",
            () => ({
                actions: {
                    add: "Add {{translatedPipelineType}}"
                },
                columns: {
                    creationTime: "Creation Time",
                    deleted: {
                        text: "Deleting... this may take some time"
                    },
                    name: "Name",
                    usageTime: "Usage Time"
                },
                empty: "No {{translatedPipelineType}}"
            }));

    function getItems(_filterMap: Dictionary<any>, sort: Optional<DataTableSort>) {
        return _.orderBy(
            pipelineModels,
            pipelineModel =>
                map<string, number | string | undefined>(
                    sort?.columnId ?? TableColumnId.Name,
                    {
                        [TableColumnId.CreationTime]: () => TimeHelper.getSortable(pipelineModel.configuration.systemCreationTime),
                        [TableColumnId.Name]: () => StringHelper.getSortValue((pipelineModel.configuration as Contract.CodePipelineConfiguration).name),
                        [TableColumnId.UsageTime]: () => TimeHelper.getSortable(pipelineModel.usageTime)
                    }),
            sort?.direction === DataTableSortDirection.Descending
                ? "desc"
                : "asc");
    }

    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => {
            dataTableActionsRef.current!.reset();
        },
        [pipelineModels]);

    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty({ translatedPipelineType: pipelineTypeTranslator(pipelineType, "configuration") })) }}
            fetchItems={getItems}
            getItemId={(item: Contract.CodePipelineModel) => item.configuration.id}
            sx={{ width: "100%" }}>
            <DataTableAction>
                <Button
                    size="small"
                    startIcon={<AddIcon/>}
                    onClick={
                        () =>
                            setCodePipelineContext(
                                codePipelineContext => ({
                                    ...codePipelineContext,
                                    addOrEditOpen: true
                                }))}>
                    {localization.actions.add({ translatedPipelineType: pipelineTypeTranslator(pipelineType, "configuration") })}
                </Button>
            </DataTableAction>
            <DataTableColumn
                id={TableColumnId.Name}
                itemProperty={(pipelineModel: Contract.CodePipelineModel) => (pipelineModel.configuration as Contract.CodePipelineConfiguration).name}
                title={localization.columns.name()}/>
            <DataTableColumn
                id={TableColumnId.CreationTime}
                itemProperty={(pipelineModel: Contract.CodePipelineModel) => TimeFormatter.shortDateTime(pipelineModel.configuration.systemCreationTime)}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.creationTime()}/>
            <DataTableColumn
                id={TableColumnId.UsageTime}
                render={
                    optionalTableCell<Contract.CodePipelineModel>(
                        item =>
                            _.isNil(item.usageTime)
                                ? undefined
                                : TimeFormatter.shortDateTime(item.usageTime))}
                sortOptions={{ type: DataTableSortType.Date }}
                title={localization.columns.usageTime()}/>
            <DataTableColumn
                id={TableColumnId.Deleted}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.CodePipelineModel>) =>
                        item.configuration.systemDeleted
                            ? <Stack
                                alignItems="center"
                                direction="row"
                                spacing={1}>
                                <NotValidIcon
                                    sx={{
                                        color: theme.palette.warning.light,
                                        fontSize: "18px"
                                    }}/>
                                <Typography>
                                    {localization.columns.deleted.text()}
                                </Typography>
                            </Stack>
                            : null}/>
            <DataTableColumn
                id={TableColumnId.Actions}
                render={ActionsCell}/>
        </DataTable>);
}

enum TableColumnId {
    Actions = "actions",
    CreationTime = "creationTime",
    Deleted = "deleted",
    Name = "name",
    UsageTime = "usageTime"
}