import { map } from "@infrastructure";
import { Contract, RegionModelHelper, tenantModelStore } from "../../../../../../../../../../../../../../../common";
import { GcpConsoleUrlBuilder } from "../../../../../../../../../../../../../../../tenants";
import { EntityExternalConsoleLinkDefinition } from "../useDefinition";

export function useGcpKmsKeyRingKeyDefinition(entityModel: Contract.EntityModel, page: Contract.GcpConsolePage) {
    const key = entityModel.entity as Contract.GcpKmsKeyRingKey;
    const tenantModel = tenantModelStore.useGet(key.tenantId);

    const getUrl =
        map(
            page,
            {
                [Contract.GcpConsolePage.Permissions]: () => GcpConsoleUrlBuilder.GetKmsKeyRingKeyPermissionsUrl,
                [Contract.GcpConsolePage.Resource]: () => GcpConsoleUrlBuilder.GetKmsKeyRingKeyUrl
            });

    return new EntityExternalConsoleLinkDefinition(
        getUrl(
            key.keyRingName,
            RegionModelHelper.get(key.regionId!).systemName,
            key.name,
            (tenantModel.configuration as Contract.GcpTenantConfiguration).rawShortNameId));
}