import { ActionMenuItem, DeleteIcon, ItemWithId, Menu, Optional, useLocalization } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";
import { Contract } from "../../../../../../../../controllers";
import { useTheme } from "../../../../../../../../themes";
import { UdmQueryHelper } from "../../../../../../utilities";
import { AndIcon, OrIcon } from "../../../../icons";
import { Badge } from "../../../Badge";
import { UdmObjectFilterSelector } from "../../../UdmObjectFilterSelector";
import { Rule } from "./components";
import { NotIcon } from "./icons";

type RulesProps = {
    enableRelationAdd?: boolean;
    objectTypeName: Optional<string>;
    onJoinAdded?: () => void;
    onOperatorAdded?: (operator: Contract.UdmQueryRuleGroupOperator) => void;
    onRuleAdded: (rule: Contract.UdmQueryRuleGroup | Contract.UdmQueryRelationRuleGroup) => void;
    onRuleChanged: (ruleGroup: Contract.UdmQueryRuleGroup) => void;
    onRuleCleared: () => void;
    parentObjectTypeName?: string;
    ruleGroup: Contract.UdmQueryRuleGroup;
};

export function Rules({ enableRelationAdd, objectTypeName, onJoinAdded, onOperatorAdded, onRuleAdded, onRuleChanged, onRuleCleared, parentObjectTypeName, ruleGroup }: RulesProps) {
    const localization =
        useLocalization(
            "common.udmObjectTable.udmQueryBuilder.ruleGroup.rules",
            () => ({
                delete: "Delete",
                edit: {
                    save: "Save",
                    title: "Edit group name"
                },
                empty: "Expression",
                filter: {
                    [Contract.UdmQueryRuleGroupOperator.And]: "And",
                    [Contract.UdmQueryRuleGroupOperator.Or]: "Or"
                },
                join: "Join",
                not: "Not",
                operation: {
                    and: "And",
                    or: "Or"
                },
                rule: "Property filter {{num}}",
                tooltip: "Remove",
                where: "Where"
            }));

    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={0.5}
            sx={{ overflow: "hidden" }}>
            <Stack
                direction="row"
                spacing={0.5}>
                {ruleGroup.not &&
                    <Badge
                        sx={{ color: theme.palette.borders.error }}
                        text={localization.not()}
                        tooltip={localization.tooltip()}
                        onClick={
                            () =>
                                onRuleChanged({
                                    ...ruleGroup,
                                    not: false
                                })}/>}
                {ruleGroup.operator === Contract.UdmQueryRuleGroupOperator.Or &&
                    <Badge
                        text={localization.operation.or()}
                        tooltip={localization.tooltip()}
                        onClick={
                            () =>
                                onRuleChanged({
                                    ...ruleGroup,
                                    operator: Contract.UdmQueryRuleGroupOperator.And
                                })}/>}
                {_.size(ruleGroup.rules) === 0 &&
                    <Badge text={localization.empty()}/>}
                <Stack
                    flexDirection="row"
                    flexWrap="wrap"
                    gap={0.5}
                    sx={{ overflow: "hidden" }}>
                    {_.map(
                        ruleGroup.rules,
                        rule =>
                            <Box key={(rule as ItemWithId<Contract.UdmQueryRuleBase>).id}>
                                <Rule
                                    objectTypeName={objectTypeName!}
                                    rule={rule as Contract.UdmQueryRule}
                                    onRuleChanged={
                                        updatedRule =>
                                            onRuleChanged(
                                                UdmQueryHelper.replaceRuleInRuleGroup(
                                                    ruleGroup,
                                                    updatedRule))}
                                    onRuleCleared={
                                        () => {
                                            const updatedRuleGroup =
                                                UdmQueryHelper.removeRuleFromRuleGroup(
                                                    ruleGroup,
                                                    rule);
                                            if (_.isEmpty(updatedRuleGroup.rules)) {
                                                onRuleCleared();
                                            } else {
                                                onRuleChanged(updatedRuleGroup);
                                            }
                                        }}/>
                            </Box>)}
                </Stack>
            </Stack>
            {!_.isEmpty(objectTypeName) &&
                <Fragment>
                    <UdmObjectFilterSelector
                        enableJoinOperator={enableRelationAdd}
                        objectTypeName={objectTypeName!}
                        parentObjectTypeName={parentObjectTypeName ?? objectTypeName}
                        onJoinAdded={onJoinAdded}
                        onOperatorAdded={onOperatorAdded}
                        onRuleAdd={
                            newRule => {
                                if (newRule.typeName === Contract.TypeNames.UdmQueryRelationRuleGroup && _.size(ruleGroup.rules) > 0) {
                                    onRuleAdded(newRule as Contract.UdmQueryRelationRuleGroup);
                                } else {
                                    onRuleChanged({
                                        ...ruleGroup,
                                        rules: [
                                            ...ruleGroup.rules,
                                            newRule
                                        ]
                                    });
                                }
                            }}/>
                    <Menu
                        iconSx={{ fontSize: "16px" }}
                        itemsOrGetItems={[
                            new ActionMenuItem(
                                () => onRuleCleared(),
                                localization.delete(),
                                { icon: <DeleteIcon/> }),
                            new ActionMenuItem(
                                () =>
                                    onRuleChanged({
                                        ...ruleGroup,
                                        operator:
                                            ruleGroup.operator === Contract.UdmQueryRuleGroupOperator.And
                                                ? Contract.UdmQueryRuleGroupOperator.Or
                                                : Contract.UdmQueryRuleGroupOperator.And
                                    }),
                                ruleGroup.operator === Contract.UdmQueryRuleGroupOperator.And
                                    ? localization.operation.or()
                                    : localization.operation.and(),
                                {
                                    icon:
                                        ruleGroup.operator === Contract.UdmQueryRuleGroupOperator.And
                                            ? <OrIcon/>
                                            : <AndIcon/>
                                }),
                            new ActionMenuItem(
                                () =>
                                    onRuleChanged({
                                        ...ruleGroup,
                                        not: !ruleGroup.not
                                    }),
                                localization.not(),
                                { icon: <NotIcon/> })
                        ]}
                        sx={{
                            "&:hover": {
                                background: theme.palette.action.hover
                            },
                            borderRadius: theme.spacing(0.75),
                            padding: theme.spacing(0.25)
                        }}
                        variant="bottomLeft"/>
                </Fragment>}
        </Stack>);
}