import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../common";
import { useGetGcpEntityRiskContext } from "./useGetGcpEntityRiskContext";

export function useGetGcpCloudBuildBuildTriggerRiskContext() {
    return useMemo(
        () => useGcpCloudBuildBuildTriggerRiskContext,
        []);
}

function useGcpCloudBuildBuildTriggerRiskContext(buildTriggerModel: Contract.GcpCloudBuildBuildTriggerModel) {
    return useGetGcpEntityRiskContext()(buildTriggerModel);}