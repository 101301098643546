import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function ExpandIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M15.0412 3C14.5173 3 14.0925 3.42474 14.0925 3.94868C14.0925 4.47263 14.5173 4.89737 15.0412 4.89737H17.6352L14.5681 7.83576C14.1723 8.21492 14.1717 8.8473 14.5668 9.22715C14.9396 9.58553 15.5287 9.58604 15.9021 9.22831L19.0266 6.23501V8.65397C19.0266 9.19897 19.4684 9.64079 20.0134 9.64079C20.5584 9.64079 21.0002 9.19897 21.0002 8.65397V3.60371C21.0002 3.27029 20.73 3 20.3965 3H15.0412Z" fill="#9E9E9E"/>
            <path d="M8.95913 20.0764C9.48308 20.0764 9.90783 19.6517 9.90783 19.1277C9.90783 18.6038 9.48308 18.179 8.95913 18.179L6.3651 18.179L9.4323 15.2406C9.82808 14.8615 9.82863 14.2291 9.4335 13.8492C9.06072 13.4908 8.4716 13.4903 8.09819 13.848L4.97367 16.8414L4.97367 14.4224C4.97367 13.8774 4.53185 13.4356 3.98683 13.4356C3.44182 13.4356 3 13.8774 3 14.4224L3 19.4727C3 19.8061 3.27029 20.0764 3.60371 20.0764H8.95913Z" fill="#9E9E9E"/>
            <path d="M8.95927 3C9.48321 3 9.90795 3.42474 9.90795 3.94868C9.90795 4.47263 9.48321 4.89737 8.95927 4.89737H6.36522L9.43241 7.83575C9.8282 8.21491 9.82875 8.84731 9.43362 9.22716C9.06084 9.58554 8.47172 9.58605 8.09832 9.22832L4.97379 6.23501V8.65395C4.97379 9.19897 4.53197 9.64079 3.98696 9.64079C3.44194 9.64079 3.00012 9.19897 3.00012 8.65395V3.60371C3.00012 3.27029 3.27041 3 3.60383 3H8.95927Z" fill="#9E9E9E"/>
            <path d="M15.0411 20.0764C14.5172 20.0764 14.0924 19.6517 14.0924 19.1278C14.0924 18.6038 14.5172 18.1791 15.0411 18.1791H17.6351L14.5679 15.2406C14.1722 14.8615 14.1716 14.2291 14.5667 13.8492C14.9395 13.4908 15.5286 13.4903 15.9021 13.8481L19.0266 16.8414V14.4224C19.0266 13.8774 19.4684 13.4356 20.0134 13.4356C20.5584 13.4356 21.0002 13.8774 21.0002 14.4224V19.4727C21.0002 19.8062 20.73 20.0764 20.3965 20.0764H15.0411Z" fill="#9E9E9E"/>
        </SvgIcon>);
}