﻿import { ApiError, CheckboxField, defined, EmptyMessageText, InfoIcon, ItemSelector, Link, PasswordTextField, Tooltip, useExecuteOperation, useInputValidation, useLocalization, useOrderedWizardContext, useSetOrderedWizardContext } from "@infrastructure";
import { FormControl, FormHelperText, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { AddOrEditContext, useAddOrEditContext, useSetAddOrEditContext } from "..";
import { ConfigurationController, Contract, CustomerConsoleAppUrlHelper, RadioField, scopeSystemEntityModelStore, useTheme } from "../../../../../../../../../../../../../../../common";
import { FolderEnabled } from "../../../../../../../../../components";
import { useGeneralIntegrationContext } from "../../../GeneralIntegration";

export function ConfigureIntegrationItem() {
    const integrationModels = scopeSystemEntityModelStore.useGetCiIntegration(Contract.CiTenantType.General);

    const { addOrEditOpen } = useGeneralIntegrationContext();
    const { executing, setLoaded, setValid, useNextEffect } = useOrderedWizardContext();
    const setAddOrEditContext = useSetAddOrEditContext();
    const setOrderedWizardContext = useSetOrderedWizardContext();
    const { integrationEndpoint, integrationEndpointConnectorId, integrationFolderEnabled, integrationModel, integrationName, integrationPassword, integrationServerCertificateValidationEnabled, integrationUserName } = useAddOrEditContext();
    const [endpointConnectorAvailableTunnelSessionClientIds] =
        useExecuteOperation(
            ConfigureIntegrationItem,
            async () => {
                const { endpointConnectorTunnelSessionClientIds } = await ConfigurationController.getEndpointConnectorTunnelSessionClientIds();
                const { endpointConnectorIdToIntegrationNameMap } = await ConfigurationController.getEndpointConnectorIdToIntegrationNameMap();
                return _.difference(
                    endpointConnectorTunnelSessionClientIds,
                    _.keys(endpointConnectorIdToIntegrationNameMap));
            });
    const endpointConnectorModels = scopeSystemEntityModelStore.useGetEndpointConnector();
    const endpointConnectorConfigurations =
        useMemo(
            () =>
                _(endpointConnectorModels).
                    filter(
                        endpointConnectorModel =>
                            endpointConnectorModel.configuration.id == integrationEndpointConnectorId ||
                            endpointConnectorAvailableTunnelSessionClientIds.includes(endpointConnectorModel.configuration.id)).
                    map(endpointConnectorModel => (endpointConnectorModel.configuration as Contract.EndpointConnectorConfiguration)).
                    value(),
            [endpointConnectorModels]);
    const updatedEndpointConnectorConfiguration =
        useMemo(
            () =>
                _.find(
                    endpointConnectorConfigurations,
                    endpointConnectorConfiguration => endpointConnectorConfiguration.id === integrationEndpointConnectorId),
            [endpointConnectorConfigurations, integrationEndpointConnectorId]);

    const integrationConfiguration = integrationModel?.configuration;
    const [endpoint, setEndpoint] = useState(integrationEndpoint);
    const [endpointConnectorEnabled, setEndpointConnectorEnabled] = useState(!_.isNil(integrationEndpointConnectorId));
    const [folderEnabled, setFolderEnabled] = useState(integrationFolderEnabled ?? true);
    const [name, setName] = useState(integrationName);
    const [password, setPassword] = useState(integrationPassword);
    const [selectedEndpointConnectorConfiguration, setSelectedEndpointConnectorConfiguration] = useState(updatedEndpointConnectorConfiguration ?? _.first(endpointConnectorConfigurations));
    const [serverCertificateValidationEnabled, setServerCertificateValidationEnabled] = useState(integrationServerCertificateValidationEnabled);
    const [userName, setUserName] = useState(integrationUserName);

    useEffect(
        () => {
            setOrderedWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    sideElement: undefined
                }));
            setLoaded();
        },
        []);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCiIntegrationItems.generalIntegration.addOrEdit.configureIntegrationItem",
            () => ({
                actions: {
                    save: {
                        error: {
                            add: "Failed to add container registry",
                            edit: "Failed to save container registry integration",
                            [Contract.TypeNames.CiIntegrationManagerError]: {
                                [Contract.CiIntegrationManagerError.AuthenticationFailed]: "Failed to authenticate to this registry. Check authentication details",
                                [Contract.CiIntegrationManagerError.EndpointNotExist]: "Endpoint Connector integration is not connected",
                                [Contract.CiIntegrationManagerError.IntegrationExists]: "Registry already exists",
                                [Contract.CiIntegrationManagerError.GetRepositoriesFailure]: "This registry type is not currently supported",
                                [Contract.CiIntegrationManagerError.RegistryEmpty]: "This registry is not supported as no repositories were found",
                                [Contract.CiIntegrationManagerError.RegistryNotFound]: "Failed to connect to this registry. Check the URL"
                            }
                        }
                    }
                },
                fields: {
                    disabled: "Connection details can't be edited. To update, delete and create a new integration",
                    endpoint: {
                        error: {
                            exists: "Endpoint already exists",
                            required: "Endpoint cannot be empty"
                        },
                        title: "Endpoint",
                        tooltip: "e.g. nexus.tenable.com or 192.82.156.1:8443"
                    },
                    endpointConnectorUsed: {
                        description: {
                            link: "click here",
                            title: "Select how Tenable connects to your server. If your server is network-isolated, use a Tenable Endpoint Connector to establish connection. If the connector option is disabled, {{addConnectorWizardLink}} to add one."
                        },
                        empty: {
                            link: "here",
                            withoutFilter: "No endpoint connectors were successfully installed. Click {{addConnectorWizardLink}} to add"
                        },
                        no: "Endpoint (internet-facing)",
                        placeholder: "Endpoint Connector",
                        yes: "Via endpoint connector (network-isolated registry)"

                    },
                    name: {
                        error: {
                            exists: "Name already exists",
                            required: "Name cannot be empty"
                        },
                        title: "Name",
                        tooltip: "The name of the container registry integration"
                    },
                    password: {
                        error: {
                            required: "Password cannot be empty"
                        },
                        title: "Password"
                    },
                    serverCertificateValidationEnabled: "Accept any server SSL certificates",
                    settings: {
                        folderEnabled: {
                            info: "Mirror your container registry's structure as folders within Tenable, preserving its hierarchy and keeping the view synchronized.",
                            title: "Automatically update folder structure"
                        }
                    },
                    username: {
                        error: {
                            required: "Username cannot be empty"
                        },
                        title: "Username"
                    }
                }
            }
            ));

    useNextEffect(
        async () => {
            try {
                let updatedIntegrationModel: Contract.CiIntegrationModel;
                if (!_.isNil(integrationConfiguration)) {
                    const { integrationModel } =
                        await ConfigurationController.updateCiGeneralIntegration(
                            new Contract.ConfigurationControllerUpdateCiGeneralIntegrationRequest(
                                folderEnabled,
                                integrationConfiguration.id,
                                name.trim(),
                                serverCertificateValidationEnabled,
                                password?.trim(),
                                userName.trim()));
                    updatedIntegrationModel = integrationModel;
                } else {
                    const { integrationModel } =
                        await ConfigurationController.insertCiGeneralIntegration(
                            new Contract.ConfigurationControllerInsertCiGeneralIntegrationRequest(
                                endpointConnectorEnabled
                                    ? selectedEndpointConnectorConfiguration?.id
                                    : undefined,
                                folderEnabled,
                                name.trim(),
                                endpointConnectorEnabled
                                    ? undefined
                                    : endpoint,
                                serverCertificateValidationEnabled,
                                defined(password?.trim()),
                                userName.trim()));
                    updatedIntegrationModel = integrationModel;
                }

                setAddOrEditContext(new AddOrEditContext(updatedIntegrationModel));
                await scopeSystemEntityModelStore.notify(updatedIntegrationModel);
            } catch (error) {
                return (
                    error instanceof ApiError && error.statusCode === 400
                        ? _.isNil(integrationConfiguration)
                            ? localization.actions.save.error[Contract.TypeNames.CiIntegrationManagerError][error.error as Contract.CiIntegrationManagerError]()
                            : localization.actions.save.error[Contract.TypeNames.CiIntegrationManagerError][error.error as Contract.CiIntegrationManagerError]()
                        : _.isNil(integrationConfiguration)
                            ? localization.actions.save.error.add()
                            : localization.actions.save.error.edit());
            }
        },
        [endpoint, endpointConnectorEnabled, folderEnabled, name, password, selectedEndpointConnectorConfiguration, serverCertificateValidationEnabled, userName]);

    const existingIntegrationConfigurations =
        useMemo(
            () =>
                _(integrationModels).
                    filter(integrationModel => integrationModel.id !== integrationConfiguration?.id).
                    map(integrationModel => integrationModel.configuration as Contract.CiGeneralIntegrationConfiguration).
                    value(),
            [integrationModels]);

    const [endpointValidationController, endpointValidationMessage] =
        useInputValidation(
            () => {
                if (endpointConnectorEnabled) {
                    if (_.isNil(selectedEndpointConnectorConfiguration?.id)) {
                        return localization.fields.endpoint.error.required();
                    }
                    return undefined;
                }
                const validationEndpoint = endpoint?.trim();
                if (_.isEmpty(validationEndpoint)) {
                    return localization.fields.endpoint.error.required();
                }
                if (_.some(
                    existingIntegrationConfigurations,
                    existingIntegrationConfiguration => existingIntegrationConfiguration.endpoint === validationEndpoint)) {
                    return localization.fields.name.error.exists();
                }

                if (validationEndpoint?.includes("gitlab") ||
                    validationEndpoint?.includes("ghcr")) {
                    return localization.actions.save.error[Contract.TypeNames.CiIntegrationManagerError][Contract.CiIntegrationManagerError.GetRepositoriesFailure]();
                }
                return undefined;
            },
            [endpoint, endpointConnectorEnabled, selectedEndpointConnectorConfiguration]);

    const [usernameValidationController, usernameValidationMessage] =
        useInputValidation(
            () => {
                if (!_.isNil(integrationConfiguration) &&
                    _.isNil(userName)) {
                    return undefined;
                }

                const validationUsername = userName?.trim();
                if (_.isEmpty(validationUsername)) {
                    return localization.fields.username.error.required();
                }

                return undefined;
            },
            [userName]);

    const [passwordValidationController, passwordValidationMessage] =
        useInputValidation(
            () => {
                if (!_.isNil(integrationConfiguration) &&
                    _.isNil(password)) {
                    return undefined;
                }

                const validationPassword = password?.trim();
                if (_.isEmpty(validationPassword)) {
                    return localization.fields.password.error.required();
                }

                return undefined;
            },
            [password]);

    const [nameValidationController, nameValidationMessage] =
        useInputValidation(
            () => {
                const validationName = name.trim();
                if (_.isEmpty(validationName)) {
                    return localization.fields.name.error.required();
                }
                if (_.some(
                    existingIntegrationConfigurations,
                    existingIntegrationConfiguration => existingIntegrationConfiguration.name === validationName)) {
                    return localization.fields.name.error.exists();
                }

                return undefined;
            },
            [name]);


    useEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    integrationEndpoint:
                        endpointConnectorEnabled
                            ? undefined
                            : endpoint,
                    integrationEndpointConnectorId:
                        endpointConnectorEnabled
                            ? selectedEndpointConnectorConfiguration?.id
                            : undefined,
                    integrationFolderEnabled: folderEnabled,
                    integrationName: name.trim(),
                    integrationPassword: password?.trim(),
                    integrationServerCertificateValidationEnabled: serverCertificateValidationEnabled,
                    integrationUserName: userName.trim()

                }));

            const dataChanged =
                _.isNil(integrationConfiguration) ||
                integrationConfiguration.folderEnabled != folderEnabled ||
                integrationConfiguration.userName != userName.trim() ||
                integrationConfiguration.name != name?.trim() ||
                integrationConfiguration.serverCertificateValidationEnabled != serverCertificateValidationEnabled ||
                !_.isNil(password);

            const dataValid =
                usernameValidationController.isValid() &&
                passwordValidationController.isValid() &&
                nameValidationController.isValid() &&
                (_.isNil(integrationConfiguration)
                    ? endpointValidationController.isValid()
                    : true);

            setValid(dataChanged && dataValid);
        },
        [endpoint, endpointConnectorEnabled, folderEnabled, name, password, selectedEndpointConnectorConfiguration, serverCertificateValidationEnabled, userName]);

    const theme = useTheme();
    return (
        <Stack
            spacing={4}
            sx={{
                maxWidth: theme.spacing(70),
                paddingBottom: theme.spacing(3)
            }}>
            <FormControl
                fullWidth={true}
                variant="standard">
                <Tooltip titleOrGetTitle={localization.fields.name.tooltip()}>
                    <TextField
                        disabled={executing}
                        label={localization.fields.name.title()}
                        value={name}
                        variant="outlined"
                        onChange={event => setName(event.target.value)}/>
                </Tooltip>
                {!_.isNil(nameValidationMessage) &&
                    <FormHelperText error={true}>{nameValidationMessage}</FormHelperText>}
            </FormControl>
            <FormControl
                fullWidth={true}
                variant="standard">
                <Stack
                    spacing={2}
                    sx={{
                        border: theme.border.primary,
                        borderRadius: theme.spacing(0.75),
                        padding: theme.spacing(2)
                    }}>
                    <Typography>
                        {localization.fields.endpointConnectorUsed.description.title({
                            addConnectorWizardLink:
                                <Link
                                    target="_blank"
                                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getConfigurationIntegrationsEndpointConnectorRelativeUrl(true)}>
                                    {localization.fields.endpointConnectorUsed.empty.link()}
                                </Link>
                        })}
                    </Typography>
                    <Tooltip
                        disabled={_.isBoolean(addOrEditOpen)}
                        titleOrGetTitle={localization.fields.disabled()}>
                        <Stack
                            spacing={2}
                            sx={{
                                border: theme.border.primary,
                                borderRadius: theme.spacing(0.75),
                                padding: theme.spacing(2)
                            }}>
                            <RadioField
                                checked={!endpointConnectorEnabled}
                                title={localization.fields.endpointConnectorUsed.no()}
                                onSelected={
                                    () =>
                                        _.isBoolean(addOrEditOpen) &&
                                        !executing &&
                                        setEndpointConnectorEnabled(false)}>
                                <FormControl
                                    fullWidth={true}
                                    variant="standard">
                                    <TextField
                                        disabled={executing || !_.isBoolean(addOrEditOpen)}
                                        fullWidth={true}
                                        label={localization.fields.endpoint.title()}
                                        slotProps={{
                                            input: {
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <Tooltip titleOrGetTitle={localization.fields.endpoint.tooltip()}>
                                                            <InfoIcon
                                                                sx={{
                                                                    color: theme.palette.text.secondary,
                                                                    fontSize: "24px"
                                                                }}/>
                                                        </Tooltip>
                                                    </InputAdornment>
                                            }
                                        }}
                                        value={endpoint}
                                        onChange={event => setEndpoint(event.target.value)}/>
                                    {!_.isNil(endpointValidationMessage) &&
                                        <FormHelperText error={true}>{endpointValidationMessage}</FormHelperText>}
                                </FormControl>
                            </RadioField>
                            <RadioField
                                checked={endpointConnectorEnabled}
                                indent={false}
                                title={localization.fields.endpointConnectorUsed.yes()}
                                onSelected={() =>
                                    _.isBoolean(addOrEditOpen) &&
                                    !executing &&
                                    setEndpointConnectorEnabled(true)}>
                                <ItemSelector
                                    disabled={
                                        !_.isBoolean(addOrEditOpen) ||
                                        executing}
                                    emptyMessageText={
                                        new EmptyMessageText(
                                            localization.fields.endpointConnectorUsed.empty.withoutFilter({
                                                addConnectorWizardLink:
                                                    <Link
                                                        target="_blank"
                                                        urlOrGetUrl={CustomerConsoleAppUrlHelper.getConfigurationIntegrationsEndpointConnectorRelativeUrl(true)}>
                                                        {localization.fields.endpointConnectorUsed.empty.link()}
                                                    </Link>
                                            }))}
                                    fullWidth={true}
                                    getItemText={(endpointConnectorConfiguration: Contract.EndpointConnectorConfiguration) => endpointConnectorConfiguration.name}
                                    items={endpointConnectorConfigurations}
                                    placeholder={localization.fields.endpointConnectorUsed.placeholder()}
                                    selectedItem={selectedEndpointConnectorConfiguration}
                                    sorted={true}
                                    onSelectedItemChanged={setSelectedEndpointConnectorConfiguration}>
                                    {(endpointConnectorConfiguration: Contract.EndpointConnectorConfiguration) => endpointConnectorConfiguration.name}
                                </ItemSelector>
                            </RadioField>
                        </Stack>
                    </Tooltip>
                    <CheckboxField
                        checked={!serverCertificateValidationEnabled}
                        disabled={executing}
                        onChange={() => setServerCertificateValidationEnabled(!serverCertificateValidationEnabled)}>
                        {localization.fields.serverCertificateValidationEnabled()}
                    </CheckboxField>
                </Stack>
            </FormControl>
            <FormControl
                fullWidth={true}
                variant="standard">
                <TextField
                    disabled={executing}
                    label={localization.fields.username.title()}
                    value={userName}
                    variant="outlined"
                    onChange={event => setUserName(event.target.value)}/>
                {!_.isNil(usernameValidationMessage) &&
                    <FormHelperText error={true}>{usernameValidationMessage}</FormHelperText>}
            </FormControl>
            <FormControl
                fullWidth={true}
                variant="standard">
                <PasswordTextField
                    disabled={executing}
                    label={localization.fields.password.title()}
                    password={password}
                    variant="outlined"
                    onPasswordChanged={password => setPassword(password)}/>
                {!_.isNil(passwordValidationMessage) &&
                    <FormHelperText error={true}>{passwordValidationMessage}</FormHelperText>}
            </FormControl>
            <FormControl
                fullWidth={true}
                variant="standard">
                <FolderEnabled
                    folderEnabled={folderEnabled}
                    info={localization.fields.settings.folderEnabled.info()}
                    setFolderEnabled={setFolderEnabled}
                    title={localization.fields.settings.folderEnabled.title()}/>
            </FormControl>
        </Stack>);
}