import { TimeSpanHelper, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, EntityModelHelper, InlineEntities, InlineTenants, useEntityTypeNameTranslator, usePermissionEligibilityUpdateAuditEventChangesTranslator, useTenantNameTranslator } from "../../../../../../../../../../../common";
import { AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "../../../useDefinition";

export function usePermissionEligibilityAuditEventCommonDetailItems(context: AuditEventDefinitionContext) {
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.usePermissionEligibilityAuditEventDefinition.hooks.usePermissionEligibilityAuditEventCommonDetailItems",
            () => ({
                approval: {
                    notRequired: "No",
                    required: "Yes",
                    title: "Requires Approval"
                },
                expirationTimeFrame: {
                    text: [
                        "1 hour",
                        "{{count | NumberFormatter.humanize}} hours"
                    ],
                    title: "Maximum Duration"
                },
                granteePrincipalIdReferences: [
                    "Requester",
                    "Requesters"
                ],
                levelToApproverPrincipalIdReferencesMap: {
                    "level0":
                        [
                            "Approver",
                            "Approvers"
                        ],
                    "level1":
                        [
                            "Second Level Approver",
                            "Second Level Approvers"
                        ],
                    "level2":
                        [
                            "Third Level Approver",
                            "Third Level Approvers"
                        ],
                    title: "Approvers"
                },
                name: "Name",
                principalTenantId: "Organization",
                propertyChanges: "Updated properties",
                reasonRequired: {
                    false: "No",
                    title: "Justification Required",
                    true: "Yes"
                }
            }));

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const permissionEligibilityUpdateAuditEventChangesTranslator = usePermissionEligibilityUpdateAuditEventChangesTranslator();
    const tenantNameTranslator = useTenantNameTranslator();

    const permissionEligibilityAuditEventModel = context.auditEventModel as Contract.PermissionEligibilityAuditEventModel;
    const permissionEligibilityAuditEvent = context.auditEventModel.auditEvent as Contract.PermissionEligibilityAuditEvent;
    const granteeCommonTypeName =
        EntityModelHelper.getCommonTypeName(
            _.map(
                permissionEligibilityAuditEventModel.permissionEligibilityData.granteePrincipalIdReferences,
                granteePrincipalIdReference => context.entityModelMap[granteePrincipalIdReference])) ??
        Contract.TypeNames.IPermissionManagementPrincipal;
    const permissionEligibilityUpdateAuditEventChanges =
        permissionEligibilityAuditEvent.typeName === Contract.TypeNames.PermissionEligibilityUpdateAuditEvent
            ? permissionEligibilityUpdateAuditEventChangesTranslator(permissionEligibilityAuditEvent as Contract.PermissionEligibilityUpdateAuditEvent)
            : undefined;

    return {
        approval:
            new AuditEventDefinitionDetailItem(
                localization.approval.title(),
                _.isEmpty(permissionEligibilityAuditEventModel.permissionEligibilityData.levelToApproverPrincipalIdReferencesMap)
                    ? localization.approval.notRequired()
                    : localization.approval.required()),
        approvalLevels:
            _(permissionEligibilityAuditEventModel.permissionEligibilityData.levelToApproverPrincipalIdReferencesMap).
                toPairs().
                orderBy(([level, _]) => level).
                map(
                    ([level, approverPrincipalIdReferences]) => {
                        const approverCommonTypeName =
                            EntityModelHelper.getCommonTypeName(
                                _.map(
                                    approverPrincipalIdReferences,
                                    approverPrincipalIdReference => context.entityModelMap[approverPrincipalIdReference])) ??
                            Contract.TypeNames.IPermissionManagementPrincipal;
                        return approverPrincipalIdReferences.length === 1
                            ? new AuditEventDefinitionDetailItem(
                                localization.levelToApproverPrincipalIdReferencesMap[`level${level}` as "level0" | "level1" | "level2"](approverPrincipalIdReferences.length),
                                context.entityModelMap[approverPrincipalIdReferences[0]].entity.displayName,
                                <Entity
                                    entityIdOrModel={approverPrincipalIdReferences[0]}
                                    variant="iconText"/>)
                            : new AuditEventDefinitionDetailItem(
                                localization.levelToApproverPrincipalIdReferencesMap[`level${level}` as "level0" | "level1" | "level2"](approverPrincipalIdReferences.length),
                                entityTypeNameTranslator(
                                    approverCommonTypeName,
                                    {
                                        count: approverPrincipalIdReferences.length,
                                        includeCount: true
                                    }),
                                <InlineEntities
                                    entityIdsOrModels={approverPrincipalIdReferences!}
                                    entityTypeName={approverCommonTypeName}
                                    variant="itemPlusItemCount"/>);
                    }).
                value(),
        expirationTimeFrame:
            new AuditEventDefinitionDetailItem(
                localization.expirationTimeFrame.title(),
                localization.expirationTimeFrame.text(TimeSpanHelper.getHours(permissionEligibilityAuditEvent.permissionEligibilityData.expirationTimeFrame))),
        granteePrincipalIdReferences:
            permissionEligibilityAuditEventModel.permissionEligibilityData.granteePrincipalIdReferences.length === 1
                ? new AuditEventDefinitionDetailItem(
                    localization.granteePrincipalIdReferences(permissionEligibilityAuditEventModel.permissionEligibilityData.granteePrincipalIdReferences.length),
                    context.entityModelMap[permissionEligibilityAuditEventModel.permissionEligibilityData.granteePrincipalIdReferences[0]].entity.displayName,
                    <Entity
                        entityIdOrModel={permissionEligibilityAuditEventModel.permissionEligibilityData.granteePrincipalIdReferences[0]}
                        variant="iconText"/>)
                : new AuditEventDefinitionDetailItem(
                    localization.granteePrincipalIdReferences(permissionEligibilityAuditEventModel.permissionEligibilityData.granteePrincipalIdReferences.length),
                    entityTypeNameTranslator(
                        granteeCommonTypeName,
                        {
                            count: permissionEligibilityAuditEventModel.permissionEligibilityData.granteePrincipalIdReferences.length,
                            includeCount: true
                        }),
                    <InlineEntities
                        entityIdsOrModels={permissionEligibilityAuditEventModel.permissionEligibilityData.granteePrincipalIdReferences}
                        entityTypeName={granteeCommonTypeName}
                        variant="itemPlusItemCount"/>),
        name:
            new AuditEventDefinitionDetailItem(
                localization.name(),
                permissionEligibilityAuditEvent.permissionEligibilityData.name),
        principalTenantId:
            new AuditEventDefinitionDetailItem(
                localization.principalTenantId(),
                tenantNameTranslator(permissionEligibilityAuditEvent.permissionEligibilityData.principalTenantId),
                <InlineTenants
                    tenantIds={[permissionEligibilityAuditEvent.permissionEligibilityData.principalTenantId]}
                    tenantVariant="iconText"/>),
        reasonRequired:
            new AuditEventDefinitionDetailItem(
                localization.reasonRequired.title(),
                permissionEligibilityAuditEventModel.auditEvent.permissionEligibilityData.reasonRequired
                    ? localization.reasonRequired.true()
                    : localization.reasonRequired.false()),
        updateAuditEventChanges:
            _.isNil(permissionEligibilityUpdateAuditEventChanges)
                ? undefined!
                : new AuditEventDefinitionDetailItem(
                    localization.propertyChanges(),
                    permissionEligibilityUpdateAuditEventChanges!)
    };
}