import { Optional, setRef } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { Ref, useEffect, useMemo, useRef } from "react";
import { useUncaptureValue } from ".";

export function useActions<TActions extends Dictionary<(...args: any[]) => any>>(actionsRef: Optional<Ref<Optional<TActions>>>, actions: TActions): TActions {
    const mountedRef = useRef(true);
    const uncaptureActions = useUncaptureValue(actions);
    const uncapturedActions =
        useMemo(
            () =>
                _.mapValues(
                    actions,
                    (action, actionPropertyName) => {
                        if (!mountedRef.current) {
                            return;
                        }

                        return (...args: any[]) => uncaptureActions(actions => actions[actionPropertyName](...args));
                    }) as any,
            []);

    useEffect(
        () => {
            if (!_.isNil(actionsRef)) {
                setRef(actionsRef, uncapturedActions);
            }

            return () => {
                mountedRef.current = false;
            };
        },
        []);

    return uncapturedActions;
}