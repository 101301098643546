import { Action0, Action1, Filters as BaseFilters, FiltersActions, StorageItem, useChangeEffect, useUniqueKey } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { Contract, RiskType } from "../../../../../../common";
import { useItems } from "./hooks";

type FiltersProps = {
    actionsRef?: React.MutableRefObject<FiltersActions | undefined>;
    initialFilterMap?: Dictionary<any>;
    onFilterChanged: Action1<Dictionary<any>>;
    onInitialized?: Action0;
    onVisibleFiltersChanged?: Action1<string[]>;
    queryParameterName?: string;
    relatedEntityId?: string;
    risksView: Contract.RisksView;
    riskType: RiskType;
    visibilityStorageItem?: StorageItem;
};

export function Filters({ actionsRef, initialFilterMap, onFilterChanged, onInitialized, onVisibleFiltersChanged, queryParameterName, relatedEntityId, risksView, riskType, visibilityStorageItem }: FiltersProps) {
    const filterItems = useItems(riskType, risksView, relatedEntityId);

    const [filtersKey, updateFiltersKey] = useUniqueKey();
    useChangeEffect(
        () => {
            updateFiltersKey();
        },
        [filterItems]);

    return (
        <BaseFilters
            actionsRef={actionsRef}
            filterQueryParameterName={queryParameterName}
            initialFilterMap={initialFilterMap}
            key={filtersKey}
            sx={{ flex: 1 }}
            visibilityStorageItem={visibilityStorageItem}
            onFilterChanged={onFilterChanged}
            onInitialized={onInitialized}
            onVisibleFiltersChanged={onVisibleFiltersChanged}>
            {filterItems}
        </BaseFilters>);
}

export const RisksItemsFilterId = {
    [Contract.RiskFilterId.CodeRiskCloudTenantType]: "codeRiskCloudTenantType",
    [Contract.RiskFilterId.CodeRiskCodeType]: "codeRiskCodeType",
    [Contract.RiskFilterId.CodeRiskFileRelativePath]: "codeRiskFileRelativePath",
    [Contract.RiskFilterId.CodeRiskResourceName]: "codeRiskResourceName",
    [Contract.RiskFilterId.CodeRiskResourceTypeSystemName]: "codeRiskResourceTypeSystemName",
    [Contract.RiskFilterId.ComplianceIdOrSectionType]: "complianceIdOrSectionType",
    [Contract.RiskFilterId.OpenStatusUpdateTime]: "openStatusUpdateTime",
    [Contract.RiskFilterId.RiskedEntityAttribute]: "riskedEntityAttribute",
    [Contract.RiskFilterId.RiskedEntityEnvironment]: "riskedEntityEnvironment",
    [Contract.RiskFilterId.RiskedEntityId]: "riskedEntityId",
    [Contract.RiskFilterId.RiskedEntityOwner]: "riskedEntityOwner",
    [Contract.RiskFilterId.RiskIgnoreExpirationDate]: "ignoreExpirationDate",
    [Contract.RiskFilterId.RiskPolicyCategory]: "riskPolicyCategory",
    [Contract.RiskFilterId.RiskPolicyConfigurationTypeNameOrId]: "riskPolicyConfigurationTypeNameOrId",
    [Contract.RiskFilterId.RiskSeverity]: "riskSeverity",
    [Contract.RiskFilterId.RiskStarred]: "riskStarred",
    [Contract.RiskFilterId.RiskSubStatus]: "riskSubStatus",
    [Contract.RiskFilterId.StatusUpdateTime]: "statusUpdateTime",
    [Contract.RiskFilterId.SystemCreationTime]: "systemCreationTime",
    [Contract.RiskFilterId.TenantId]: "tenantId",
    [Contract.RiskFilterId.TicketingServiceType]: "ticketingServiceType"
};

export type RisksFilterItemPageData = {
    count: number;
    itemNextPageSearchCursor?: Contract.ElasticsearchIndexSearchCursor;
};