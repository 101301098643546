import { useLocalization } from "@infrastructure";
import React from "react";
import { useOciCommonResourceInfoItemElements } from ".";
import { Contract, InfoItem } from "../../../../../../../../../../common";

export function useOciCommonIamResourceInfoItemElements(iamResourceModel: Contract.OciIamResourceModel) {
    const commonResourceInfoItemElements = useOciCommonResourceInfoItemElements(iamResourceModel);
    const iamResource = iamResourceModel.entity as Contract.OciIamResource;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciCommonIamResourceInfoItemElements",
            () => ({
                info: {
                    items: {
                        description: "Description",
                        enabled: {
                            false: "Not active",
                            title: "Status",
                            true: "Active"
                        }
                    }
                }
            }));
    return {
        ...commonResourceInfoItemElements,
        descriptionInfoItemElement:
            <InfoItem
                key="description"
                location="all"
                title={localization.info.items.description()}
                value={iamResource.description}/>,
        enabledInfoItemElement:
            <InfoItem
                key="enabled"
                location="all"
                title={localization.info.items.enabled.title()}
                value={
                    iamResource.enabled
                        ? localization.info.items.enabled.true()
                        : localization.info.items.enabled.false()}/>
    };
}