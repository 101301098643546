﻿import { InlineItems, Step, Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, entityModelStore, InlineEntities, useEntityTypeNameTranslator, useSeverityTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetRiskPolicyConfigurationRotationTimeFrameContextItem } from "../../../useGetRiskPolicyConfigurationRotationTimeFrameContextItem";
import { EntityExternalConsoleLink } from "../../components";
import { useGetAzureKeyVaultCertificateRiskContext, useGetAzureKeyVaultVaultRiskContext } from "../contexts";

export function useAzureKeyVaultVaultCertificateNotRotatedRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureKeyVaultVaultCertificateNotRotatedRisk;
    const certificateModel = entityModelStore.useGet(risk.entityId) as Contract.AzureKeyVaultVaultCertificateModel;
    const certificate = certificateModel.entity as Contract.AzureKeyVaultVaultCertificate;

    const getAzureKeyVaultCertificateRiskContext = useGetAzureKeyVaultCertificateRiskContext();
    const getAzureKeyVaultVaultRiskContext = useGetAzureKeyVaultVaultRiskContext();
    const getRiskPolicyConfigurationRotationTimeFrameContextItem = useGetRiskPolicyConfigurationRotationTimeFrameContextItem();

    const vaultModel = entityModelStore.useGet(certificate.vaultId) as Contract.AzureKeyVaultVaultModel;
    const [criticalSeverityTenantIds, highSeverityTenantIds] =
        useMemo(() => {
            const criticalSeverityTenantIds =
                    _(risk.applicationIdToSeverityToTenantIdsMap).
                        values().
                        flatMap(severityToTenantIdsMap => severityToTenantIdsMap[Contract.Severity.Critical] ?? []).
                        value();
            const highSeverityTenantIds =
                    _(risk.applicationIdToSeverityToTenantIdsMap).
                        values().
                        flatMap(severityToTenantIdsMap => severityToTenantIdsMap[Contract.Severity.High] ?? []).
                        value();
            return [criticalSeverityTenantIds, highSeverityTenantIds];
        },
        [risk]);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const severityTranslator = useSeverityTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureKeyVaultVaultCertificateNotRotatedRiskDefinition",
            () => ({
                contextItems: {
                    applications: {
                        tenants: ", granting it **{{severity}}** severity permissions on {{tenants}}",
                        text: "The {{translatedCertificateTypeName}} is used by {{applications}}{{tenants}}"
                    },
                    versions: {
                        pluralizer: [
                            "1 version",
                            "{{count | NumberFormatter.humanize}} versions"
                        ],
                        title: [
                            "The {{translatedCertificateTypeName}} has {{versions}} that was issued more than {{certificateRotationTimeFrame | TimeSpanFormatter.humanizeDays}} ago",
                            "The {{translatedCertificateTypeName}} has {{versions}} that were issued more than {{certificateRotationTimeFrame | TimeSpanFormatter.humanizeDays}} ago"]
                    }
                },
                description: [
                    "{{certificate}} has {{versions}} that was not been rotated for more than {{certificateRotationTimeFrame | TimeSpanFormatter.humanizeDays}}",
                    "{{certificate}} has {{versions}} that were not been rotated over than {{certificateRotationTimeFrame | TimeSpanFormatter.humanizeDays}}"
                ],
                sections: {
                    resolution: {
                        step1: "Click on the target certificate **{{certificate}}**",
                        step2: "Click **New Version**",
                        step3: {
                            step1: "Set the **Validity Period** field to {{certificateRotationTimeFrame | TimeSpanFormatter.humanizeDays}}",
                            step2: "In the **Lifetime Action Type** field, choose one of the **Automatically renew at...** options to turn on auto rotation for the certificate",
                            step3: "Click **Create** to confirm",
                            title: "Complete the form with the new certificate properties:"
                        },
                        step4: "If any Azure resources aren’t configured to use the latest version, you will need to manually update them to use the new version of the certificate",
                        step5: {
                            applications: {
                                empty: "Before disabling the old {{versions}}, check that the resources use the new version",
                                some: "Before disabling the old {{versions}}, update and verify that {{applications}} and other resources use the new version"
                            }
                        },
                        step6: "Ensure that the old certificates cannot be used by setting the **Enabled** field to **No**"
                    }
                },
                versions: [
                    "1 version",
                    "{{count | NumberFormatter.humanize}} versions"
                ]
            }));
    const translatedCertificateTypeName =
        entityTypeNameTranslator(
            certificate.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return useCommonSectionsAndDescriptionDefinition(
        localization.description(
            risk.notRotatedVersionNames.length,
            {
                certificate:
                    <Entity
                        entityIdOrModel={certificateModel}
                        entityTypeNameTranslatorOptions={{ variant: "title" }}
                        variant="typeText"/>,
                certificateRotationTimeFrame: risk.certificateRotationTimeFrame,
                versions:
                    <InlineItems
                        items={risk.notRotatedVersionNames}
                        namePluralizer={localization.versions}
                        variant="itemCountAndType"/>
            }),
        () => [
            <EntityExternalConsoleLink
                entityId={certificate.vaultId}
                key={certificate.vaultId}
                page={Contract.AzureConsoleEntityPage.Certificates}/>,
            localization.sections.resolution.step1({
                certificate:
                    <Entity
                        entityIdOrModel={certificateModel}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{ disabled: true }}
                        variant="text"/>
            }),
            localization.sections.resolution.step2(),
            new Step(
                localization.sections.resolution.step3.title(),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {localization.sections.resolution.step3.step1()}
                            {localization.sections.resolution.step3.step2({ certificateRotationTimeFrame: risk.certificateRotationTimeFrame })}
                            {localization.sections.resolution.step3.step3()}
                        </Steps>
                }),
            localization.sections.resolution.step4(),
            (_(risk.applicationIdToSeverityToTenantIdsMap).
                keys().
                isEmpty()
                ? localization.sections.resolution.step5.applications.empty
                : localization.sections.resolution.step5.applications.some)({
                applications:
                    <InlineEntities
                        entityIdsOrModels={_.keys(risk.applicationIdToSeverityToTenantIdsMap)}
                        entityTypeName={Contract.TypeNames.AadDirectoryApplication}
                        variant="itemCountAndType"/>,
                versions:
                    <InlineItems
                        items={risk.notRotatedVersionNames}
                        namePluralizer={localization.versions}
                        variant="itemCountAndType"/>
            }),
            localization.sections.resolution.step6()],
        riskModel,
        () => {
            const certificateRiskContext = getAzureKeyVaultCertificateRiskContext(certificateModel);
            const vaultRiskContext = getAzureKeyVaultVaultRiskContext(vaultModel);
            return [
                certificateRiskContext.generalInformation,
                getRiskPolicyConfigurationRotationTimeFrameContextItem(risk.certificateRotationTimeFrame),
                certificateRiskContext.sensitive,
                certificateRiskContext.keyVault,
                vaultRiskContext.sensitive,
                new RiskDefinitionContextItem(
                    localization.contextItems.versions.title(
                        risk.notRotatedVersionNames.length,
                        {
                            certificateRotationTimeFrame: risk.certificateRotationTimeFrame,
                            translatedCertificateTypeName,
                            versions:
                                <InlineItems
                                    items={risk.notRotatedVersionNames}
                                    namePluralizer={localization.contextItems.versions.pluralizer}
                                    variant="itemCountAndType"/>
                        })),
                _(risk.applicationIdToSeverityToTenantIdsMap).
                    keys().
                    isEmpty()
                    ? undefined
                    : new RiskDefinitionContextItem(
                        localization.contextItems.applications.text({
                            applications:
                                <InlineEntities
                                    entityIdsOrModels={_.keys(risk.applicationIdToSeverityToTenantIdsMap)}
                                    entityTypeName={Contract.TypeNames.AadDirectoryApplication}
                                    variant="itemCountAndType"/>,
                            tenants:
                                _.isEmpty(highSeverityTenantIds) &&
                                _.isEmpty(criticalSeverityTenantIds)
                                    ? ""
                                    : localization.contextItems.applications.tenants({
                                        severity:
                                            severityTranslator(
                                                _.isEmpty(highSeverityTenantIds)
                                                    ? Contract.Severity.Critical
                                                    : Contract.Severity.High,
                                                "text"),
                                        tenants:
                                            <InlineEntities
                                                entityIdsOrModels={
                                                    _.concat(
                                                        highSeverityTenantIds,
                                                        criticalSeverityTenantIds)}
                                                entityTypeName={Contract.TypeNames.AzureTenantEntity}
                                                variant="itemCountAndType"/>
                                    }),
                            translatedCertificateTypeName
                        })),
                certificateRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}