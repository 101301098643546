import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function FreshworksIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58343_2670)">
                <path
                    d="M0.150313 13.7787C0.501044 16.334 1.70355 18.6388 3.50731 20.4927L7.56576 16.4342C7.56576 12.1253 9.82046 8.21712 13.5282 6.01253L0.150313 13.7787Z"
                    fill="#9B65C3"/>
                <path
                    d="M7.61587 16.3841C5.51148 16.3841 3.4572 15.833 1.65344 14.7808C1.1524 14.4802 0.651357 14.1294 0.150313 13.7787C0.501044 16.334 1.70355 18.6388 3.50731 20.4927L7.61587 16.3841Z"
                    fill="#9B65C3"/>
                <path
                    d="M7.61587 16.3841L3.50731 20.4927C5.36117 22.3466 7.66597 23.499 10.1712 23.8497L17.9374 10.4217C15.833 14.0793 11.8747 16.3841 7.61587 16.3841Z"
                    fill="#BE63C5"/>
                <path
                    d="M9.21921 22.3967C8.16701 20.5428 7.61587 18.5386 7.61587 16.4342L3.50731 20.4927C5.36117 22.3466 7.66597 23.499 10.1712 23.8497C9.87056 23.3486 9.51983 22.8977 9.21921 22.3967Z"
                    fill="#BF63C6"/>
                <path
                    d="M22.8977 0.651357L13.5783 5.96242C9.87056 8.0668 5.36117 8.0668 1.60334 5.96242C0.551148 7.81628 0 9.92067 0 12.0251C0 12.5762 0.0501044 13.2276 0.150313 13.7787C0.651357 14.1294 1.1524 14.4802 1.65344 14.7808C3.50731 15.833 5.51148 16.3841 7.61587 16.3841L23.0981 0.901879C22.9979 0.851774 22.9478 0.751566 22.8977 0.651357Z"
                    fill="#3278B1"/>
                <path
                    d="M1.60334 14.7808C3.4572 15.833 5.46138 16.3841 7.56576 16.3841C7.56576 12.0752 9.82046 8.16701 13.5282 5.96242L0.150313 13.7286C0.651357 14.1294 1.1023 14.4802 1.60334 14.7808Z"
                    fill="#3278B2"/>
                <path
                    d="M1.60334 5.96242C0.551148 7.81628 0 9.92067 0 12.0251C0 12.5762 0.0501044 13.2276 0.150313 13.7787L13.5783 5.96242C9.92067 8.16701 5.36117 8.16701 1.60334 5.96242Z"
                    fill="#45A4EC"/>
                <path
                    d="M23.0981 0.901879C22.8977 0.701461 22.7474 0.551148 22.547 0.400835C22.096 0.150313 21.5449 0 21.0438 0H11.9749C7.66597 0 3.70772 2.3048 1.60334 5.96242C5.31106 8.06681 9.82046 8.06681 13.5783 5.96242C9.87056 8.06681 7.61587 12.0752 7.61587 16.3841C11.9248 16.3841 15.833 14.1294 18.0376 10.4217L23.3486 1.1023C23.2484 1.05219 23.1482 1.00209 23.0981 0.901879Z"
                    fill="#19BB7D"/>
                <path
                    d="M13.5783 5.96242L22.8977 0.651357C22.7474 0.601253 22.6973 0.450939 22.547 0.350731L1.60334 5.96242C5.36117 8.16701 9.92067 8.16701 13.5783 5.96242Z"
                    fill="#08C7FB"/>
                <path
                    d="M20.9937 0H11.9749C7.66597 0 3.70772 2.3048 1.60334 5.96242L22.547 0.350731C22.0459 0.150313 21.5449 0 20.9937 0Z"
                    fill="#59F2F6"/>
                <path
                    d="M18.0376 10.4217L23.3486 1.1023C23.2985 1.05219 23.1983 0.951983 23.1482 0.80167L7.66597 16.334C7.66597 18.4384 8.21712 20.4927 9.26931 22.2965C9.56994 22.7975 9.92067 23.2985 10.2714 23.7996C10.8225 23.8497 11.3737 23.9499 11.9749 23.9499C14.0793 23.9499 16.1336 23.3987 17.9374 22.3466C15.833 18.6388 15.833 14.0793 18.0376 10.4217Z"
                    fill="#DA3757"/>
                <path
                    d="M7.61587 16.3841C7.61587 18.4885 8.16701 20.5428 9.21921 22.3466C9.51983 22.8476 9.87056 23.3486 10.2213 23.8497L17.9875 10.4217C15.833 14.0793 11.8747 16.3841 7.61587 16.3841Z"
                    fill="#D33C4E"/>
                <path
                    d="M18.0376 10.4217L10.2714 23.8497C10.8225 23.8998 11.3737 24 12.0251 24C14.1294 24 16.1837 23.4489 17.9875 22.3967C15.833 18.6388 15.833 14.0793 18.0376 10.4217Z"
                    fill="#EE5A24"/>
                <path
                    d="M23.5992 1.50313C23.3987 1.1524 23.1983 0.851774 22.8977 0.651357L13.5783 5.96242C9.87056 8.06681 7.61587 12.0752 7.61587 16.3841C11.9248 16.3841 15.833 14.1294 18.0376 10.4217C15.9332 14.1294 15.9332 18.6388 18.0376 22.3967C21.6952 20.2923 24 16.334 24 12.1253V3.00626C24 2.45511 23.8497 1.95407 23.5992 1.50313Z"
                    fill="#8BDF55"/>
                <path
                    d="M22.8977 0.651357L13.5783 5.96242C9.87056 8.06681 7.61587 12.0752 7.61587 16.3841L23.0981 0.901879C22.9979 0.851774 22.9478 0.701461 22.8977 0.651357Z"
                    fill="#25C16F"/>
                <path
                    d="M7.61587 16.3841C11.9248 16.3841 15.833 14.1294 18.0376 10.4217L23.3486 1.1023C23.2985 1.05219 23.1983 0.951983 23.1482 0.80167L7.61587 16.3841Z"
                    fill="#8BDF55"/>
                <path
                    d="M23.3486 1.1023L18.0376 10.4217C15.9332 14.1294 15.9332 18.6388 18.0376 22.3967L23.6493 1.50313C23.499 1.35282 23.4488 1.25261 23.3486 1.1023Z"
                    fill="#FFBB00"/>
                <path
                    d="M18.0376 22.3967C21.7453 20.2923 24 16.334 24 12.0251L24 3.00626C24 2.45511 23.8497 1.95407 23.5992 1.45303L18.0376 22.3967Z"
                    fill="#FFA800"/>
            </g>
            <defs>
                <clipPath id="clip0_58343_2670">
                    <rect
                        fill="white"
                        height="24"
                        width="23.9962"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}