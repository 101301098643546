import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { ReactNode } from "react";
import { Contract, SeveritySquare, useEntityTypeNameTranslator, useSeverityTranslator } from "..";

export function useRiskSeverityReasonTranslator() {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const severityTranslator = useSeverityTranslator();
    const localization =
        useLocalization(
            "common.hooks.useRiskSeverityReasonTranslator",
            () => ({
                [Contract.TypeNames.RiskSeverityReason]: {
                    [Contract.RiskSeverityReason.AwsAccessPermissionSetRiskAssignmentNotExist]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity even though the permission set has {{excessivePermissionActionSeverity}} severity excessive permissions, since the permission set isn't assigned to any principal",
                    [Contract.RiskSeverityReason.AwsAccessPermissionSetRiskAssignmentNotExistAndExcessivePermissionAction]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the permission set has {{excessivePermissionActionSeverity}} severity excessive permissions and isn't assigned to any principal",
                    [Contract.RiskSeverityReason.AwsAccessPermissionSetRiskExcessivePermissionAction]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the permission set has {{excessivePermissionActionSeverity}} severity excessive permissions",
                    [Contract.RiskSeverityReason.AwsAccessPermissionSetRiskManyAssignments]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the permission set has {{excessivePermissionActionSeverity}} severity excessive permissions and is assigned to a large number of principals on a large number of accounts",
                    [Contract.RiskSeverityReason.AwsAccessPermissionSetRiskNonexcessivePermissionAction]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity even though the permission set has {{excessivePermissionActionSeverity}} severity excessive permissions, since removing these permissions will still result in keeping {{nonexcessivePermissionActionSeverity}} severity permissions",
                    [Contract.RiskSeverityReason.AwsAccessPermissionSetRiskNotManyAssignments]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity even though the permission set has {{excessivePermissionActionSeverity}} severity excessive permissions, since the permission set is assigned to a small number of principals on a small number of accounts",
                    [Contract.RiskSeverityReason.AwsAccessRoleRiskAnonymousIdentityWithStatementCondition]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity even though the role has {{excessivePermissionActionSeverity}} severity excessive permissions, since the role trusts a wide range of accounts",
                    [Contract.RiskSeverityReason.AwsAccessRoleRiskAnonymousIdentityWithStatementConditionAndExcessivePermissionAction]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the role has {{excessivePermissionActionSeverity}} severity excessive permissions and trusts a wide range of accounts",
                    [Contract.RiskSeverityReason.AwsAccessRoleRiskAssumeRolePolicyDocumentNonComputeServiceGranteeOnly]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the role can be used by AWS services only",
                    [Contract.RiskSeverityReason.AwsAccessRoleRiskExcessivePermissionAction]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the role has {{excessivePermissionActionSeverity}} severity excessive permissions",
                    [Contract.RiskSeverityReason.AwsAccessRoleRiskInternal]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity even though the role has {{excessivePermissionActionSeverity}} severity excessive permissions, since the role is only used internally and isn't connected to exposed or vulnerable compute instances",
                    [Contract.RiskSeverityReason.AwsAccessRoleRiskPublic]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity even though the role has {{excessivePermissionActionSeverity}} severity excessive permissions, since the role is public (trusts any identity)",
                    [Contract.RiskSeverityReason.AwsAccessRoleRiskPublicAndExcessivePermissionAction]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the role has {{excessivePermissionActionSeverity}} severity excessive permissions and is public (trusts any identity)",
                    [Contract.RiskSeverityReason.AwsAccessRoleRiskPublicOriginatorResource]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity even though the role has {{excessivePermissionActionSeverity}} severity excessive permissions, since the role is linked to internet-facing compute instances",
                    [Contract.RiskSeverityReason.AwsAccessRoleRiskPublicOriginatorResourceAndExcessivePermissionAction]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the role has {{excessivePermissionActionSeverity}} severity excessive permissions and is linked to internet-facing compute instances",
                    [Contract.RiskSeverityReason.AwsAccessRoleRiskPublicOriginatorResourceAndVulnerableOriginatorWorkloadResource]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity even though the role has {{excessivePermissionActionSeverity}} severity excessive permissions, since the role is linked to internet-facing compute instances with critical vulnerabilities",
                    [Contract.RiskSeverityReason.AwsAccessRoleRiskPublicOriginatorResourceAndVulnerableOriginatorWorkloadResourceAndExcessivePermissionAction]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the role has {{excessivePermissionActionSeverity}} severity excessive permissions and is linked to internet-facing compute instances with critical vulnerabilities",
                    [Contract.RiskSeverityReason.AwsAccessRoleRiskUnknownTenant]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity even though the role has {{excessivePermissionActionSeverity}} severity excessive permissions, since the role trusts {{existingPermissionActionUnknownExternalTenants}}",
                    [Contract.RiskSeverityReason.AwsAccessRoleRiskUnknownTenantAndExcessivePermissionAction]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the role has {{excessivePermissionActionSeverity}} severity excessive permissions and trusts {{existingPermissionActionUnknownExternalTenants}}",
                    [Contract.RiskSeverityReason.AwsAccessRoleRiskVendor]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity even though the role has {{excessivePermissionActionSeverity}} severity excessive permissions, since the role is used by a 3rd party application",
                    [Contract.RiskSeverityReason.AwsAccessRoleRiskVendorAndExcessivePermissionAction]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the role has {{excessivePermissionActionSeverity}} severity excessive permissions and is used by a 3rd party application",
                    [Contract.RiskSeverityReason.AwsAccessRoleRiskVulnerableOriginatorWorkloadResource]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity even though the role has {{excessivePermissionActionSeverity}} severity excessive permissions, since the role is linked to compute instances with critical vulnerabilities",
                    [Contract.RiskSeverityReason.AwsAccessRoleRiskVulnerableOriginatorWorkloadResourceAndExcessivePermissionAction]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the role has {{excessivePermissionActionSeverity}} severity excessive permissions and is linked to compute instances with critical vulnerabilities",
                    [Contract.RiskSeverityReason.AwsAccessUserRiskAccessKeyEnabled]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity even though the user has {{excessivePermissionActionSeverity}} severity excessive permissions, since it has active access keys",
                    [Contract.RiskSeverityReason.AwsAccessUserRiskAccessKeyEnabledAndExcessivePermissionAction]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the user has {{excessivePermissionActionSeverity}} severity excessive permissions and has active access keys",
                    [Contract.RiskSeverityReason.AwsAccessUserRiskCredentialsNotExist]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity even though the user has {{excessivePermissionActionSeverity}} severity excessive permissions, since the user has no password or active access keys",
                    [Contract.RiskSeverityReason.AwsAccessUserRiskCredentialsNotExistAndExcessivePermissionAction]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the user has {{excessivePermissionActionSeverity}} severity excessive permissions and has no password or active access keys",
                    [Contract.RiskSeverityReason.AwsAccessUserRiskMfaDisabled]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity even though the user has {{excessivePermissionActionSeverity}} severity excessive permissions, since the user doesn't have MFA enabled",
                    [Contract.RiskSeverityReason.AwsAccessUserRiskMfaDisabledAndAccessKeyEnabled]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity even though the user has {{excessivePermissionActionSeverity}} severity excessive permissions, since the user doesn't have MFA enabled and has active access keys",
                    [Contract.RiskSeverityReason.AwsAccessUserRiskMfaDisabledAndAccessKeyEnabledAndExcessivePermissionAction]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the user has {{excessivePermissionActionSeverity}} severity excessive permissions, doesn't have MFA enabled, and has active access keys",
                    [Contract.RiskSeverityReason.AwsAccessUserRiskMfaDisabledAndExcessivePermissionAction]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the user has {{excessivePermissionActionSeverity}} severity excessive permissions and doesn't have MFA enabled",
                    [Contract.RiskSeverityReason.AwsAccessUserRiskMfaEnabled]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity even though the user has {{excessivePermissionActionSeverity}} severity excessive permissions, since the user has MFA enabled",
                    [Contract.RiskSeverityReason.AwsAccessUserRiskMfaEnabledAndExcessivePermissionAction]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the user has {{excessivePermissionActionSeverity}} severity excessive permissions and has MFA enabled",
                    [Contract.RiskSeverityReason.AwsExcessivePermissionGroupRiskExcessivePermissionAction]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the group has {{excessivePermissionActionSeverity}} severity excessive permissions",
                    [Contract.RiskSeverityReason.AwsExcessivePermissionGroupRiskUserAccessKeyEnabled]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the group has {{excessivePermissionActionSeverity}} severity excessive permissions and some of its users have active access keys",
                    [Contract.RiskSeverityReason.AwsExcessivePermissionGroupRiskUserExists]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity even though the group has {{excessivePermissionActionSeverity}} severity excessive permissions, since none of its users have passwords or active access keys",
                    [Contract.RiskSeverityReason.AwsExcessivePermissionGroupRiskUserExistsAndExcessivePermissionAction]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the group has {{excessivePermissionActionSeverity}} severity excessive permissions and none of its users have passwords or active access keys",
                    [Contract.RiskSeverityReason.AwsExcessivePermissionGroupRiskUserMfaDisabled]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity even though the group has {{excessivePermissionActionSeverity}} severity excessive permissions, since some of its users don't have MFA enabled",
                    [Contract.RiskSeverityReason.AwsExcessivePermissionGroupRiskUserMfaDisabledAndAccessKeyEnabled]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity even though the group has {{excessivePermissionActionSeverity}} severity excessive permissions, since some of its users don't have MFA enabled or have active access keys",
                    [Contract.RiskSeverityReason.AwsExcessivePermissionGroupRiskUserMfaDisabledAndAccessKeyEnabledAndExcessivePermissionAction]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the group has {{excessivePermissionActionSeverity}} severity excessive permissions and some of its users don't have MFA enabled or have active access keys",
                    [Contract.RiskSeverityReason.AwsExcessivePermissionGroupRiskUserMfaDisabledAndExcessivePermissionAction]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the group has {{excessivePermissionActionSeverity}} severity excessive permissions and some of its users don't have MFA enabled",
                    [Contract.RiskSeverityReason.AwsExcessivePermissionGroupRiskUserMfaEnabled]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity even though the group has {{excessivePermissionActionSeverity}} severity excessive permissions, since all of its users have MFA enabled and no active access keys",
                    [Contract.RiskSeverityReason.AwsExcessivePermissionGroupRiskUserMfaEnabledAndExcessivePermissionAction]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the group has {{excessivePermissionActionSeverity}} severity excessive permissions and all of its users have MFA enabled and no active access keys",
                    [Contract.RiskSeverityReason.AwsIamRolePublicAccessExistsRiskRolePermissionNotExist]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the role has no permission",
                    [Contract.RiskSeverityReason.AwsIamRolePublicAccessExistsRiskRolePermissionSeverity]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since it will give an attacker {{severity}} severity permissions if assumed",
                    [Contract.RiskSeverityReason.AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskPermissionSeverity]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity because if the role is compromised, the attacker will have {{riskSeverity}} severity permissions in the environment",
                    [Contract.RiskSeverityReason.AwsIamUserMfaDisabledRiskUserPermissionNotExist]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the user has no permission",
                    [Contract.RiskSeverityReason.AwsIamUserMfaDisabledRiskUserPermissionActionSeverity]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity due to the {{userPermissionActionSeverity}} severity permissions this user has",
                    [Contract.RiskSeverityReason.AwsIamUserUnrotatedAccessKeyRiskUserPermissionNotExist]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the user has no permission",
                    [Contract.RiskSeverityReason.AwsIamUserUnrotatedAccessKeyRiskUserPermissionActionSeverity]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity due to the {{userPermissionActionSeverity}} severity permissions this user has",
                    [Contract.RiskSeverityReason.AzureComputeVirtualMachineUnmanagedDiskExistsRiskNotRunningNotSensitive]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the virtual machine is not running and it is not marked as sensitive",
                    [Contract.RiskSeverityReason.AzureComputeVirtualMachineUnmanagedDiskExistsRiskRunning]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the virtual machine is running",
                    [Contract.RiskSeverityReason.AzureComputeVirtualMachineUnmanagedDiskExistsRiskSensitive]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the virtual machine is marked as sensitive",
                    [Contract.RiskSeverityReason.AzureInboundExternalComputeVirtualMachineRiskManagementPortNotOpenServicePrincipalNone]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since even though the virtual machine is running and directly exposed to {{exposedNetworkScopes}}, it does not have any managed identity attached",
                    [Contract.RiskSeverityReason.AzureInboundExternalComputeVirtualMachineRiskManagementPortNotOpenServicePrincipalPermissionAction]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since even though the virtual machine is running and directly exposed to {{exposedNetworkScopes}}, the attached managed identity has {{servicePrincipalPermissionsSeverity}} severity permissions",
                    [Contract.RiskSeverityReason.AzureInboundExternalComputeVirtualMachineRiskManagementPortNotOpenServicePrincipalPermissionActionNone]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since even though the virtual machine is running and directly exposed to {{exposedNetworkScopes}}, the attached managed identity doesn't have any permissions",
                    [Contract.RiskSeverityReason.AzureInboundExternalComputeVirtualMachineRiskManagementPortNotOpenServicePrincipalPermissionActionSevere]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity even though the virtual machine has a managed identity attached with {{servicePrincipalPermissionsSeverity}} severity permissions, since it is not publicly exposed to any management ports",
                    [Contract.RiskSeverityReason.AzureInboundExternalComputeVirtualMachineRiskManagementPortOpenServicePrincipalNone]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since even though the virtual machine is running and directly exposed to {{exposedManagementNetworkScopes}}, it does not have any managed identity attached",
                    [Contract.RiskSeverityReason.AzureInboundExternalComputeVirtualMachineRiskManagementPortOpenServicePrincipalPermissionAction]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since even though the virtual machine is running and directly exposed to {{exposedManagementNetworkScopes}}, the attached managed identity has {{servicePrincipalPermissionsSeverity}} severity permissions",
                    [Contract.RiskSeverityReason.AzureInboundExternalComputeVirtualMachineRiskManagementPortOpenServicePrincipalPermissionActionNone]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since even though the virtual machine is running and directly exposed to {{exposedManagementNetworkScopes}}, the attached managed identity doesn't have any permissions",
                    [Contract.RiskSeverityReason.AzureInboundExternalComputeVirtualMachineRiskManagementPortOpenServicePrincipalPermissionActionSevere]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the virtual machine is directly exposed to {{exposedManagementNetworkScopes}} and has a managed identity attached with {{servicePrincipalPermissionsSeverity}} severity permissions",
                    [Contract.RiskSeverityReason.AzureInboundExternalComputeVirtualMachineRiskNotRunning]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the virtual machine is not running",
                    [Contract.RiskSeverityReason.AzureInboundExternalComputeVirtualMachineRiskSensitive]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the virtual machine is marked as sensitive",
                    [Contract.RiskSeverityReason.AzureInboundExternalComputeVirtualMachineScaleSetRiskManagementPortNotOpenServicePrincipalNone]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since even though instances are running and directly exposed to {{exposedNetworkScopes}}, the virtual machine scale set does not have any managed identity attached",
                    [Contract.RiskSeverityReason.AzureInboundExternalComputeVirtualMachineScaleSetRiskManagementPortNotOpenServicePrincipalPermissionAction]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since even though instances are running and directly exposed to {{exposedNetworkScopes}}, the attached managed identity has {{servicePrincipalPermissionsSeverity}} severity permissions",
                    [Contract.RiskSeverityReason.AzureInboundExternalComputeVirtualMachineScaleSetRiskManagementPortNotOpenServicePrincipalPermissionActionNone]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since even though instances are running and directly exposed to {{exposedNetworkScopes}}, the attached managed identity doesn't have any permissions",
                    [Contract.RiskSeverityReason.AzureInboundExternalComputeVirtualMachineScaleSetRiskManagementPortNotOpenServicePrincipalPermissionActionSevere]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity even though the virtual machine scale set has a managed identity attached with {{servicePrincipalPermissionsSeverity}} severity permissions, since it is not publicly exposed to any management ports",
                    [Contract.RiskSeverityReason.AzureInboundExternalComputeVirtualMachineScaleSetRiskManagementPortOpenServicePrincipalNone]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since even though instances are running and directly exposed to {{exposedManagementNetworkScopes}}, the virtual machine scale set does not have any managed identity attached",
                    [Contract.RiskSeverityReason.AzureInboundExternalComputeVirtualMachineScaleSetRiskManagementPortOpenServicePrincipalPermissionAction]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since even though instances are running and directly exposed to {{exposedManagementNetworkScopes}}, the attached managed identity has {{servicePrincipalPermissionsSeverity}} severity permissions",
                    [Contract.RiskSeverityReason.AzureInboundExternalComputeVirtualMachineScaleSetRiskManagementPortOpenServicePrincipalPermissionActionNone]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since even though instances are running and directly exposed to {{exposedManagementNetworkScopes}}, the attached managed identity doesn't have any permissions",
                    [Contract.RiskSeverityReason.AzureInboundExternalComputeVirtualMachineScaleSetRiskManagementPortOpenServicePrincipalPermissionActionSevere]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the virtual machine scale set is directly exposed to {{exposedManagementNetworkScopes}} and has a managed identity attached with {{servicePrincipalPermissionsSeverity}} severity permissions",
                    [Contract.RiskSeverityReason.AzureInboundExternalComputeVirtualMachineScaleSetRiskNotRunning]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since no instances are running",
                    [Contract.RiskSeverityReason.AzureInboundExternalComputeVirtualMachineScaleSetRiskSensitive]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the virtual machine scale set is marked as sensitive",
                    [Contract.RiskSeverityReason.GcpExcessivePermissionServiceAccountRiskExcessivePermissionActionSeverity]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the service account has {{excessivePermissionActionSeverity}} severity excessive permissions",
                    [Contract.RiskSeverityReason.GcpExcessivePermissionServiceAccountRiskExcessivePermissionActionSeverityExternal]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the service account has {{excessivePermissionActionSeverity}} severity excessive permissions and is an external service account",
                    [Contract.RiskSeverityReason.GcpExcessivePermissionServiceAccountRiskExcessivePermissionActionSeverityOriginatorPublicAccessExists]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the service account has {{excessivePermissionActionSeverity}} severity excessive permissions and is linked to a public compute resource",
                    [Contract.RiskSeverityReason.GcpExcessivePermissionServiceAccountRiskServiceAccountDisabled]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the service account has {{excessivePermissionActionSeverity}} severity excessive permissions and is disabled",
                    [Contract.RiskSeverityReason.GcpExcessivePermissionUserRiskExcessivePermissionActionSeverity]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the user has {{excessivePermissionActionSeverity}} severity excessive permissions",
                    [Contract.RiskSeverityReason.GcpExcessivePermissionUserRiskExcessivePermissionActionSeverityExternal]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the user has {{excessivePermissionActionSeverity}} severity excessive permissions and is an external user",
                    [Contract.RiskSeverityReason.GcpExcessivePermissionUserRiskUserDisabled]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the user has {{excessivePermissionActionSeverity}} severity excessive permissions and is not active",
                    [Contract.RiskSeverityReason.GcpPrincipalServiceAccountWideScopeAdministratorRoleRiskGroupRoleBindingPermissionSeverity]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity due to the permissions severity granted via the admin roles",
                    [Contract.RiskSeverityReason.GcpPrincipalServiceAccountWideScopeAdministratorRoleRiskPartialServiceAccount]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the service account is an external service account",
                    [Contract.RiskSeverityReason.GcpPrincipalServiceAccountWideScopeAdministratorRoleRiskServiceAccountDisabled]:
                        "This finding has a {{severitySquare}}**{{riskSeverity}}** severity since the service account is disabled",
                    [Contract.RiskSeverityReason.GcpPrincipalServiceAccountWideScopeAdministratorRoleRiskServiceAccountRoleBindingPermissionSeverity]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity due to the permissions severity granted via the admin roles",
                    [Contract.RiskSeverityReason.GcpPrincipalServiceAccountWideScopeAdministratorRoleRiskTenantEntitySensitive]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the project is marked as sensitive",
                    [Contract.RiskSeverityReason.GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskGroupServiceAccountPermissionSeverity]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity due to the permissions the service accounts of the group can act as",
                    [Contract.RiskSeverityReason.GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskPartialServiceAccount]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the service account is an external service account",
                    [Contract.RiskSeverityReason.GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskServiceAccountDisabled]:
                        "This finding has a {{severitySquare}}**{{riskSeverity}}** severity since the service account is disabled",
                    [Contract.RiskSeverityReason.GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskServiceAccountServiceAccountPermissionSeverity]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity due to the permissions the service account can act as",
                    [Contract.RiskSeverityReason.GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskTenantEntitySensitive]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the project is marked as sensitive",
                    [Contract.RiskSeverityReason.GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskGciTenantEntityServiceAccountPermissionSeverity]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity due to the permissions the users of the domain can act as",
                    [Contract.RiskSeverityReason.GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskGroupServiceAccountPermissionSeverity]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity due to the permissions the users of the group can act as",
                    [Contract.RiskSeverityReason.GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskPartialGciTenantEntity]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the domain is an external domain",
                    [Contract.RiskSeverityReason.GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskPartialUser]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the user is an external user",
                    [Contract.RiskSeverityReason.GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskTenantEntitySensitive]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity since the project is marked as sensitive",
                    [Contract.RiskSeverityReason.GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskUserServiceAccountPermissionSeverity]:
                        "This finding has {{severitySquare}}**{{riskSeverity}}** severity due to the permissions the user can act as",
                    [Contract.RiskSeverityReason.GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskUserDisabled]:
                        "This finding has a {{severitySquare}}**{{riskSeverity}}** severity since the user is not active"
                }
            }));
    return (riskSeverity: Contract.Severity, riskSeverityReason: Contract.RiskSeverityReason, options?: RiskSeverityReasonTranslatorOptions) =>
        localization[Contract.TypeNames.RiskSeverityReason][riskSeverityReason]({
            ...options,
            riskSeverity: severityTranslator(riskSeverity, "text"),
            severitySquare: <SeveritySquare severity={riskSeverity}/>,
            translatedResourceTypeName:
                _.isNil(options?.resourceTypeName)
                    ? undefined
                    : entityTypeNameTranslator(
                        options!.resourceTypeName!,
                        {
                            includeServiceName: false,
                            variant: "text"
                        })
        });
}

export type RiskSeverityReasonTranslatorOptions = {
    applications?: ReactNode;
    count?: number;
    excessivePermissionActionSeverity?: string;
    existingPermissionActionUnknownExternalTenants?: ReactNode;
    exposedManagementNetworkScopes?: ReactNode;
    exposedNetworkScopes?: ReactNode;
    externalLoadBalancerIds?: ReactNode;
    highSeverityPermissionActions?: ReactNode;
    identities?: ReactNode;
    instanceIds?: ReactNode;
    instances?: ReactNode;
    nonexcessivePermissionActionSeverity?: string;
    principalIds?: ReactNode;
    publicPorts?: ReactNode;
    repositories?: ReactNode;
    resources?: ReactNode;
    resourceTypeName?: string;
    rotationInterval?: string;
    sensitiveLoadBalancerIds?: ReactNode;
    sensitiveNodePoolIds?: ReactNode;
    serviceAccountPermissionsSeverity?: ReactNode;
    servicePrincipalPermissionsSeverity?: ReactNode;
    severity?: ReactNode;
    tenants?: ReactNode;
    userPermissionActionSeverity?: ReactNode;
};