import { InlineCopyToClipboardText, Link, Message, Steps, useLocalization, useSetOrderedWizardContext } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useAddOrEditContext } from "../..";
import { CustomerConsoleAppUrlHelper } from "../../../../../../../../../../../../../../../../common";
import { InstructionsImageUrl } from "./images";

export function ServiceAccountItem() {
    const { tenantOnboardingInfo } = useAddOrEditContext();
    const setOrderedWizardContext = useSetOrderedWizardContext();
    useEffect(
        () => {
            setOrderedWizardContext(
                wizardContext => ({
                    ...wizardContext,
                    sideElement: <img src={InstructionsImageUrl}/>
                }));
        },
        []);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.gciTenants.addOrEdit.serviceAccountItem",
            () => ({
                steps: {
                    step1: "Create a new project for Tenable Cloud Security",
                    step2: "Within the newly created project, navigate to **IAM & Admin > Service Accounts** and then click **+CREATE SERVICE ACCOUNT**",
                    step3: "Fill a name, ID and a description for the service account. Click **CREATE AND CONTINUE**",
                    step4: {
                        info: {
                            documentationLink: "documentation",
                            text: "The Quota Administrator role is needed for Tenable to query GCP services and automatically support new ones. For more information, refer to {{documentationLink}}"
                        },
                        title: "Select **Quota Administrator** role and click **CONTINUE**"
                    },
                    step5: "Click **DONE** to finish creating the service account",
                    step6: "Click on the service account",
                    step7: "Go to the **PERMISSIONS** tab and click on **GRANT ACCESS**",
                    step8: "Insert **{{applicationServiceAccountMail}}** in the New principals field and choose **Service Account Token Creator** role. Click **SAVE** to finish",
                    step9: "Go to **IAM & Admin > Labels**, click **Add Label** and add Key: **{{tenantExternalIdTagKey}}**, Value: **{{tenantExternalIdTagValue}}**. Click **SAVE** to finish"
                }
            }));

    return (
        <Steps variant="letters">
            {localization.steps.step1()}
            {localization.steps.step2()}
            {localization.steps.step3()}
            <Stack
                direction="row"
                spacing={1}>
                <Typography>
                    {localization.steps.step4.title()}
                </Typography>
                <Message
                    level="info"
                    title={
                        localization.steps.step4.info.text({
                            documentationLink:
                                <Link
                                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsGcpFaqsRelativeUrl()}
                                    variant="external">
                                    {localization.steps.step4.info.documentationLink()}
                                </Link>
                        })}
                    variant="minimal"/>
            </Stack>
            {localization.steps.step5()}
            {localization.steps.step6()}
            {localization.steps.step7()}
            {localization.steps.step8({
                applicationServiceAccountMail:
                    <InlineCopyToClipboardText text={tenantOnboardingInfo.applicationServiceAccountMail}/>
            })}
            {localization.steps.step9({
                tenantExternalIdTagKey: <InlineCopyToClipboardText text={tenantOnboardingInfo.serviceAccountTenantExternalIdTag.key}/>,
                tenantExternalIdTagValue: <InlineCopyToClipboardText text={tenantOnboardingInfo.serviceAccountTenantExternalIdTag.value!}/>
            })}
        </Steps>);
}