import { map, PrivateIcon, PublicIcon, Sx } from "@infrastructure";
import { SxProps } from "@mui/material";
import React from "react";
import { Contract, useTheme } from "../../../../../../common";
import { ExternalIcon } from "./icons";

type AccessLevelIconProps = {
    accessLevel: Contract.GcpScopeResourceAccessLevel;
    sx?: SxProps;
};

export function AccessLevelIcon({ accessLevel, sx }: AccessLevelIconProps) {
    const theme = useTheme();
    return map(
        accessLevel,
        {
            [Contract.GcpScopeResourceAccessLevel.External]: () =>
                <ExternalIcon sx={Sx.combine(sx, { color: theme.palette.warning.main })}/>,
            [Contract.GcpScopeResourceAccessLevel.Internal]: () =>
                <PrivateIcon sx={Sx.combine(sx, { color: theme.palette.text.secondary })}/>,
            [Contract.GcpScopeResourceAccessLevel.Public]: () =>
                <PublicIcon sx={Sx.combine(sx, { color: theme.palette.error.main })}/>
        });
}