﻿import { useMemo } from "react";
import { Contract } from "../../../../../../../../../../../../../../../../../../../../common";
import { useDefaultDefinition } from "../useDefaultDefinition";
import { useAwsEc2InstanceMetadataServiceVersionRiskDefinition, useAwsLambdaFunctionConfigurationPublicAccessExistsRiskDefinition } from "./hooks";

export function useComputeDefinition(riskPolicyConfigurationTypeName: string) {
    const useDefinition =
        useMemo(
            () => {
                switch (riskPolicyConfigurationTypeName) {
                    case Contract.TypeNames.AwsEc2InstanceMetadataServiceVersionRiskPolicyConfiguration:
                        return useAwsEc2InstanceMetadataServiceVersionRiskDefinition;
                    case Contract.TypeNames.AwsLambdaFunctionConfigurationPublicAccessExistsRiskPolicyConfiguration:
                        return useAwsLambdaFunctionConfigurationPublicAccessExistsRiskDefinition;
                    default:
                        return useDefaultDefinition;
                }
            },
            []);

    return useDefinition(riskPolicyConfigurationTypeName);
}