import { CollapsedIcon } from "@infrastructure";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { memo, ReactNode, useEffect, useState } from "react";
import { GroupedTableColumn } from "../../../..";
import { Row } from "..";
import { ElementClass } from "./Item.element";

const ItemMemo = memo(Item);
export { ItemMemo as Item };

type GroupedTableItemProps = {
    children: (item: any) => ReactNode;
    columns: GroupedTableColumn[];
    item: any;
    onLoaded: (setExpanded: (expanded: boolean) => void) => void;
};

function Item({ children, columns, item, onLoaded }: GroupedTableItemProps) {
    const [expanded, setExpanded] = useState(false);
    const [renderItem, setRenderItem] = useState(false);

    useEffect(
        () => {
            onLoaded(setExpanded);
        },
        []);

    return (
        <Accordion
            className={
                expanded
                    ? ElementClass.itemOpened
                    : ElementClass.itemClosed}
            expanded={expanded}
            slotProps={{ transition: { mountOnEnter: true } }}
            onChange={
                (_event, expanded) => {
                    setRenderItem(true);
                    setExpanded(expanded);
                }}>
            <AccordionSummary expandIcon={<CollapsedIcon/>}>
                <Row
                    columns={columns}
                    item={item}/>
            </AccordionSummary>
            <AccordionDetails sx={{ border: 0 }}>
                {renderItem && children(item)}
            </AccordionDetails>
        </Accordion>);
}