import { Action0, Action1, getChildElements, normalizeFilterMap, Optional, useChangeEffect, useQueryParameters, useSetQueryParameters } from "@infrastructure";
import { Box, Grid2 } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Children, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Filter, ToggleFilterConnector } from "./components";

type FiltersProps = {
    children: ReactNode;
    filterQueryParameterName?: string;
    initialFilterMap?: Dictionary<any>;
    onFilterChanged: Action1<Dictionary<any>>;
    onInitialized?: Action0;
};

export function ToggleFilters({ children, filterQueryParameterName, initialFilterMap = {}, onFilterChanged, onInitialized }: FiltersProps) {
    const queryParameters = useQueryParameters<Dictionary<Optional<string>>>();
    const setQueryParameters = useSetQueryParameters();
    const [filterMap, setFilterMap] =
        useState<Dictionary<any>>(
            () => {
                if (_.isNil(filterQueryParameterName)) {
                    return initialFilterMap;
                }

                const filterQueryParameterJson = queryParameters[filterQueryParameterName];
                if (_.isEmpty(filterQueryParameterJson)) {
                    return initialFilterMap;
                }

                const filterMap = normalizeFilterMap(JSON.parse(filterQueryParameterJson!));
                if (_.isEmpty(filterMap)) {
                    return initialFilterMap;
                }

                return filterMap;
            });
    const queryFilterMap =
        useMemo(
            () =>
                filterQueryParameterName && queryParameters?.[filterQueryParameterName]
                    ? normalizeFilterMap(JSON.parse(queryParameters[filterQueryParameterName]!))
                    : {},
            [filterQueryParameterName, queryParameters]);

    useEffect(
        () => {
            if (!_.isEqual(filterMap, initialFilterMap)) {
                onFilterChanged(filterMap);
            } else if (!_.isNil(filterQueryParameterName)) {
                setQueryParameters<Dictionary<Optional<string>>>(
                    {
                        [filterQueryParameterName]:
                            _.isEmpty(filterMap)
                                ? undefined
                                : JSON.stringify(filterMap)
                    },
                    {
                        appendBrowserHistory: false,
                        ignoreUrlMaxLength: true
                    });
            }

            onInitialized?.();
        },
        []);

    useChangeEffect(
        () => {
            setFilterMap(
                filterMap => {
                    if (!_.isEqual(filterMap, queryFilterMap)) {
                        return { ...queryFilterMap };
                    }

                    return filterMap;
                });
        },
        [queryFilterMap]);

    useChangeEffect(
        () => {
            if (!_.isNil(filterQueryParameterName) && !_.isEqual(filterMap, queryFilterMap)) {
                setQueryParameters<Dictionary<Optional<string>>>(
                    {
                        [filterQueryParameterName]:
                            _.isEmpty(filterMap)
                                ? undefined
                                : JSON.stringify(filterMap)
                    },
                    { ignoreUrlMaxLength: true });
            }

            onFilterChanged(filterMap);
        },
        [filterMap]);

    const filterElements = useMemo(() => getChildElements(Children.toArray(children), Filter), [children]);
    const onConnectorFilterChanged =
        useCallback(
            (filterId: string, filter: unknown) => {
                setFilterMap(
                    filterMap =>
                        normalizeFilterMap({
                            ...filterMap,
                            [filterId]: filter
                        }));
            },
            []);

    return (
        <Box>
            <Grid2
                alignItems="center"
                container={true}
                justifyContent="flex-end"
                spacing={1}
                sx={{
                    alignItems: "center",
                    justifyContent: "flex-end"
                }}
                wrap="nowrap">
                {_.map(
                    filterElements,
                    filterElement =>
                        <Grid2 key={filterElement.props.id}>
                            <ToggleFilterConnector
                                filter={filterMap[filterElement.props.id]}
                                id={filterElement.props.id}
                                onFilterChanged={onConnectorFilterChanged}>
                                {filterElement.props.children}
                            </ToggleFilterConnector>
                        </Grid2>)}
            </Grid2>
        </Box>);
}