import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useGcpNotebooksInstanceStatusTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useGcpDefaultResourceInfoItemElements } from "./useGcpDefaultResourceInfoItemElements";
import { useGcpDefaultScopeResourceInfoItemElements } from "./useGcpDefaultScopeResourceInfoItemElements";

export function useGcpNotebooksInstanceDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const defaultScopeResourceInfoItemElements = useGcpDefaultScopeResourceInfoItemElements(scopeResourceModel);
    const instance = scopeResourceModel.entity as Contract.GcpNotebooksInstance;
    const instanceModel = scopeResourceModel as Contract.GcpNotebooksInstanceModel;

    const notebooksInstanceStatusTranslator = useGcpNotebooksInstanceStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpNotebooksInstanceDefinition",
            () => ({
                info: {
                    items: {
                        computeInstanceIdReferences: "VM Instance",
                        rootAccess: {
                            false: "Disabled",
                            title: "Root Access",
                            true: "Enabled"
                        },
                        status: "Status"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...defaultScopeResourceInfoItemElements,
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={notebooksInstanceStatusTranslator(instance.status)}/>,
                    <InfoItem
                        key="rootAccess"
                        title={localization.info.items.rootAccess.title()}
                        value={
                            instance.rootAccess
                                ? localization.info.items.rootAccess.true()
                                : localization.info.items.rootAccess.false()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={instanceModel.computeInstanceIdReference}
                        entityTypeName={Contract.TypeNames.GcpComputeInstance}
                        key="computeInstanceIdReference"
                        title={localization.info.items.computeInstanceIdReferences()}/>
                ]}
                options={options?.infoOptions}/>
    });
}