﻿import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore, InlineEntities } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetCommonKubernetesClusterRiskContext } from "./contexts";

export function useKubernetesClusterDefaultNamespaceUsedRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.KubernetesClusterDefaultNamespaceUsedRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId);

    const getCommonKubernetesClusterRiskContext = useGetCommonKubernetesClusterRiskContext(clusterModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.kubernetes.hooks.clusters.useKubernetesClusterDefaultNamespaceUsedRiskDefinition",
            () => ({
                contextItems: {
                    defaultNamespaceResources: "The cluster has {{defaultNamespaceResources}} in the default namespace"
                },
                description: "{{cluster}} has resources in the default namespace",
                resolutionSection: {
                    step1: "Create a new namespace using kubectl by following the Kubernetes documentation at {{createKubernetesNamespacesDocumentationLink}}",
                    step2: "Recreate your resources (such as pods, deployments, and services) in the new namespace",
                    step3: "Repeat for additional namespaces"
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{
                        includeServiceName: true,
                        variant: "title"
                    }}
                    variant="typeText"/>
        }),
        () => [
            localization.resolutionSection.step1({
                createKubernetesNamespacesDocumentationLink:
                    <Link
                        urlOrGetUrl={(riskModel as Contract.KubernetesClusterDefaultNamespaceUsedRiskModel).createKubernetesNamespacesDocumentationUrl}
                        variant="external"/>
            }),
            localization.resolutionSection.step2(),
            localization.resolutionSection.step3()
        ],
        riskModel,
        () => {
            const kubernetesClusterRiskContext = getCommonKubernetesClusterRiskContext(clusterModel);
            return [
                kubernetesClusterRiskContext.generalInformation,
                kubernetesClusterRiskContext.sensitive,
                kubernetesClusterRiskContext.virtualNetwork,
                new RiskDefinitionContextItem(
                    localization.contextItems.defaultNamespaceResources({
                        defaultNamespaceResources:
                            <InlineEntities
                                entityIdsOrModels={risk.defaultNamespaceResourceIds}
                                entityTypeName={Contract.TypeNames.IKubernetesResource}
                                variant="itemCountAndType"/>
                    })),
                kubernetesClusterRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}