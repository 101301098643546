import { useLocalization } from "@infrastructure";
import _ from "lodash";
import { AuditEventDefinition, AuditEventDefinitionContext, AuditEventDefinitionDetailItem } from "..";
import { Contract } from "../../../../../../../../../common";

export function useAzureTenantAuditEventDefinition(context: AuditEventDefinitionContext) {
    const localization =
        useLocalization(
            "views.customer.auditEvents.profile.hooks.useDefinition.hooks.useAzureTenantAuditEventDefinition",
            () => ({
                propertyChanges: "Updated properties",
                tenantFolder: "Folder",
                tenantName: "Subscription name",
                tenantRawShortId: "Subscription ID"
            }));
    const tenantAuditEvent = context.auditEventModel.auditEvent as Contract.AzureTenantAuditEvent;
    return new AuditEventDefinition(
        _<AuditEventDefinitionDetailItem>([]).
            concat(
                new AuditEventDefinitionDetailItem(
                    localization.tenantRawShortId(),
                    tenantAuditEvent.tenantRawShortId),
                new AuditEventDefinitionDetailItem(
                    localization.tenantName(),
                    tenantAuditEvent.tenantName),
                new AuditEventDefinitionDetailItem(
                    localization.tenantFolder(),
                    tenantAuditEvent.tenantFolderPath)).
            concatIf(
                tenantAuditEvent.typeName === Contract.TypeNames.AzureTenantUpdateAuditEvent,
                new AuditEventDefinitionDetailItem(
                    localization.propertyChanges(),
                    localization.tenantFolder())).
            value());
}