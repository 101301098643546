import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../..";
import { useAwsCommonResourceDefinition } from "..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useAwsSsoPermissionSetDefinition(definitionData: DefinitionData) {
    const commonResourceDefinition = useAwsCommonResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.aws.useAwsSsoPermissionSetDefinition",
            () => ({
                columns: {
                    accountIds: "Assigned Accounts",
                    principalIds: "Principals",
                    provisioned:
                        {
                            false: "No",
                            title: "Provisioned",
                            true: "Yes"
                        }
                }
            }));
    return new EntityTableDefinition(
        [
            commonResourceDefinition.columns.tenantColumn,
            commonResourceDefinition.columns.creationTimeColumn,
            commonResourceDefinition.columns.creatorIdentityCsvColumn,
            commonResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            commonResourceDefinition.columns.regionColumn,
            commonResourceDefinition.columns.regionLocationColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.AwsSsoPermissionSetModel) => ({
                            [localization.columns.provisioned.title()]:
                                (item.entity as Contract.AwsSsoPermissionSet).provisioned
                                    ? localization.columns.provisioned.true()
                                    : localization.columns.provisioned.false()
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.provisioned.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.provisioned.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.provisioned.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.AwsSsoPermissionSetProvisioned}
                itemProperty={
                    (item: Contract.AwsSsoPermissionSetModel) =>
                        (item.entity as Contract.AwsSsoPermissionSet).provisioned
                            ? localization.columns.provisioned.true()
                            : localization.columns.provisioned.false()}
                key={Contract.EntityModelProperty.AwsSsoPermissionSetProvisioned}
                title={localization.columns.provisioned.title()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsSsoPermissionSetModel>(
                        Contract.TypeNames.AwsOrganizationsAccount,
                        item =>
                            _(item.principalIdToAccountIdsMap).
                                values().
                                flatMap().
                                uniq().
                                value(),
                        localization.columns.accountIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsSsoPermissionSetAccounts)}
                                placeholder={localization.columns.accountIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsSsoPermissionSetAccounts}
                key={Contract.EntityModelProperty.AwsSsoPermissionSetAccounts}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsSsoPermissionSetModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={
                                _(item.principalIdToAccountIdsMap).
                                    values().
                                    flatMap().
                                    uniq().
                                    value()}
                            entityTypeName={Contract.TypeNames.AwsOrganizationsAccount}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.accountIds()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.AwsSsoPermissionSetModel>(
                        Contract.TypeNames.AwsSsoPrincipal,
                        item => _.keys(item.principalIdToAccountIdsMap),
                        localization.columns.principalIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.AwsSsoPermissionSetPrincipals)}
                                placeholder={localization.columns.principalIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.AwsSsoPermissionSetPrincipals}
                key={Contract.EntityModelProperty.AwsSsoPermissionSetPrincipals}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.AwsSsoPermissionSetModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={_.keys(item.principalIdToAccountIdsMap)}
                            entityTypeName={Contract.TypeNames.AwsSsoPrincipal}
                            entityVariant="iconText"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.principalIds()}/>,
            commonResourceDefinition.columns.tagsColumn,
            commonResourceDefinition.columns.attributesColumn,
            commonResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageAwsSsoPermissionSetRequest(
                new Contract.EntityControllerGetEntityModelPageAwsSsoPermissionSetRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsSsoPermissionSetAccounts]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsSsoPermissionSetPrincipals]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.AwsSsoPermissionSetProvisioned])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}