﻿import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function BahrainIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_60203_5272)">
                <rect
                    fill="white"
                    height="15.9638"
                    rx="2"
                    width="24"
                    y="4"/>
                <path
                    clipRule="evenodd"
                    d="M22 5H2C1.44772 5 1 5.44772 1 6V18C1 18.5523 1.44772 19 2 19H22C22.5523 19 23 18.5523 23 18V6C23 5.44771 22.5523 5 22 5ZM2 4C0.895431 4 0 4.89543 0 6V18C0 19.1046 0.89543 20 2 20H22C23.1046 20 24 19.1046 24 18V6C24 4.89543 23.1046 4 22 4H2Z"
                    fill="#BAC5D3"
                    fillRule="evenodd"/>
                <path
                    d="M23.9999 4.00012H3.59985L7.7511 5.5965L3.59985 7.19288L7.7511 8.78926L3.59985 10.3856L7.7511 11.982L3.59985 13.5784L7.7511 15.1748L3.59985 16.7712L7.7511 18.3675L3.59985 19.9639H23.9999"
                    fill="#DA291C"/>
            </g>
            <defs>
                <clipPath id="clip0_60203_5272">
                    <rect
                        fill="white"
                        height="15.9638"
                        rx="2"
                        width="24"
                        y="4"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}