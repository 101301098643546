import { Loading, makeContextProvider, useExecuteOperation, useLocalization, WelcomeView } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import _ from "lodash";
import React from "react";
import { AccessController, AnalyzingView, Contract, tenantModelStore, useGetSelectedScopeHasData, useLayoutOptions, useTheme } from "../../../../../../common";
import { AccessView } from "../../hooks";
import { PrincipalsDefinition, useDefinition } from "./hooks";

export class PrincipalsContext {
    constructor(
        public definition: PrincipalsDefinition,
        public executeGetPrincipalSummary: () => Promise<void>,
        public getPrincipalSummaryResponse: Contract.AccessControllerGetPrincipalSummaryResponse,
        public view: AccessView.PrincipalExcessivePermissions | AccessView.PrincipalPermissions) {
    }
}

export const [usePrincipalsContext, useSetPrincipalsContext, usePrincipalsContextProvider] = makeContextProvider<PrincipalsContext>();

export type PrincipalsProps = {
    view: AccessView.PrincipalExcessivePermissions | AccessView.PrincipalPermissions;
};

export function Principals({ view }: PrincipalsProps) {
    const filteredActiveCloudProviderTenantModels = tenantModelStore.useGetFilteredActiveCloudProviderTenants();
    const getSelectedScopeHasData = useGetSelectedScopeHasData();

    const localization =
        useLocalization(
            "views.customer.access.principals",
            () => ({
                empty: "No {{viewTitle}}",
                filtered: "You are currently filtering by identity provider/s.\n Expand the account scope to see relevant data",
                title: {
                    [AccessView.PrincipalExcessivePermissions]: "Excessive Permissions",
                    [AccessView.PrincipalPermissions]: "Identity Intelligence"
                }
            }));

    const viewTitle = localization.title[view]();
    useLayoutOptions({ view: { title: viewTitle } });

    const [getPrincipalSummaryResponse, executeGetPrincipalSummary] =
        useExecuteOperation(
            [Principals, view],
            async () =>
                AccessController.getPrincipalModelSummary(
                    new Contract.AccessControllerGetPrincipalSummaryRequest(
                        view === AccessView.PrincipalExcessivePermissions
                            ? Contract.AccessControllerGetPrincipalSummaryRequestType.ExcessivePermissions
                            : Contract.AccessControllerGetPrincipalSummaryRequestType.Permissions)));
    const definition = useDefinition(view);

    const [, , ContextProvider] =
        usePrincipalsContextProvider(
            () =>
                new PrincipalsContext(
                    definition,
                    executeGetPrincipalSummary,
                    getPrincipalSummaryResponse,
                    view));

    const theme = useTheme();
    return (
        _.isEmpty(filteredActiveCloudProviderTenantModels)
            ? <WelcomeView title={localization.filtered()}/>
            : getPrincipalSummaryResponse.result === Contract.AccessControllerGetPrincipalSummaryResponseResult.Empty
                ? <WelcomeView title={localization.empty({ viewTitle })}/>
                : getPrincipalSummaryResponse.result === Contract.AccessControllerGetPrincipalSummaryResponseResult.Analyzing || !getSelectedScopeHasData(filteredActiveCloudProviderTenantModels)
                    ? <AnalyzingView/>
                    : <ContextProvider>
                        <Stack
                            sx={{
                                height: "100%",
                                overflowX: "hidden"
                            }}>
                            {definition.summaryElement &&
                                <Box
                                    sx={{
                                        overflowX: "auto",
                                        padding: theme.spacing(1, 2)
                                    }}>
                                    {definition.summaryElement}
                                </Box>}
                            <Stack
                                sx={{
                                    background: theme.palette.background.paper,
                                    flex: 1,
                                    overflowY: "hidden"
                                }}>
                                <Loading>
                                    {definition.tableElement}
                                </Loading>
                            </Stack>
                        </Stack>
                    </ContextProvider>);
}