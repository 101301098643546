import { Dropdown, useLocalization } from "@infrastructure";
import { Box, List, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, entityModelStore, EntityPropertyHelper, EntityPropertyValue, useTheme } from "../../../../../../../../../common";
import { RiskDefinitionSection } from "../../../utilities";

export function useResourceOwnerRiskDefinitionSection(riskModel: Contract.RiskModel) {
    const resourceOwnerIdReferences =
        useMemo(
            () => _.map(
                riskModel.riskedEntityOwner?.values,
                principalReferenceValue => principalReferenceValue.principalSearchableIdReference.idReference),
            [riskModel]);
    const resourceOwnerEntityModels = entityModelStore.useGet(resourceOwnerIdReferences);
    const resourceOwnerComponents =
        useMemo(
            () =>
                _.isNil(riskModel.riskedEntityOwner)
                    ? []
                    : _.map(
                        EntityPropertyHelper.getOrderedValues(riskModel.riskedEntityOwner!) as Contract.EntityPropertyPrincipalReferenceValue[],
                        entityPropertyValue =>
                            <EntityPropertyValue
                                key={entityPropertyValue.principalSearchableIdReference.idReference}
                                principalReferencePropertyEntityModels={resourceOwnerEntityModels}
                                showSourceEntity={false}
                                value={entityPropertyValue}/>),
            [resourceOwnerEntityModels, riskModel]);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.useResourceOwnerRiskDefinitionSection",
            () => ({
                additional: "+{{additionalCount | NumberFormatter.humanize}}",
                resourceOwner: "Resource Owner"
            }));

    const theme = useTheme();
    return new RiskDefinitionSection(
        <Stack>
            <Box>
                {_.take(
                    resourceOwnerComponents,
                    limitChildrenCount)}
            </Box>
            {resourceOwnerComponents.length > limitChildrenCount &&
                <Dropdown
                    popoverElement={
                        <List
                            sx={{ maxHeight: theme.spacing(50) }}>
                            {_.drop(
                                resourceOwnerComponents,
                                limitChildrenCount)}
                        </List>}
                    popoverElementContainerSx={{ padding: 0 }}>
                    <Typography sx={{ textDecoration: "underline" }}>
                        {localization.additional({ additionalCount: resourceOwnerComponents.length - limitChildrenCount })}
                    </Typography>
                </Dropdown>}
        </Stack>,
        localization.resourceOwner());
}

const limitChildrenCount = 3;