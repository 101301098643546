﻿import { InfoIcon, Optional, Tooltip, useExecuteOperation, useLocalization, useOrderedWizardContext } from "@infrastructure";
import { Button, InputAdornment, Stack, TextField } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { GitHubServer } from "../../..";
import { useAddOrEditContext, useSetAddOrEditContext } from "..";
import { ConfigurationController, ConsoleApiUrlHelper, Contract, scopeSystemEntityModelStore, useTheme } from "../../../../../../../../../../../../../../../common";

export function RegisterAppItem() {
    const setAddOrEditContext = useSetAddOrEditContext();
    const { serverModel, updatedApplicationOrganizationName, updatedServerEndpoint, updatedServerEndpointConnectorId } = useAddOrEditContext();

    const endpointConnectorModels = scopeSystemEntityModelStore.useGetEndpointConnector();
    const gitHubServerConfiguration = serverModel?.configuration as Optional<Contract.GitHubServerConfiguration>;

    const [applicationOrganizationName, setApplicationOrganizationName] = useState(updatedApplicationOrganizationName ?? "");

    const [{ applicationName }] =
        useExecuteOperation(
            GitHubServer,
            ConfigurationController.getGitHubServerOnboardingApplicationInfo);

    const externalEndpoint =
        useMemo(
            () => {
                if (!_.isNil(updatedServerEndpoint)) {
                    return updatedServerEndpoint;
                }

                return !_.isNil(updatedServerEndpointConnectorId)
                    ? (endpointConnectorModels.find(endpointConnectorModel => endpointConnectorModel.configuration.id == updatedServerEndpointConnectorId)?.configuration as Contract.EndpointConnectorConfiguration)?.endpoint
                    : serverModel!.state!.endpoint!;
            },
            [gitHubServerConfiguration, endpointConnectorModels, updatedServerEndpoint, updatedServerEndpointConnectorId]);
    const { executing, setValid } = useOrderedWizardContext();

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeServerItems.gitHubServer.addOrEditGitHubServer.registerAppItem",
            () => ({
                actions: {
                    registerApp: "Register GitHub App"
                },
                fields: {
                    organizationName: {
                        title: "Application Organization Name",
                        tooltip: "The name of the GitHub organization where you want to host the Tenable Cloud Security GitHub App. Can be different from the organization that you onboard to Tenable."
                    }
                }
            }));

    useEffect(
        () => {
            setAddOrEditContext(
                context => ({
                    ...context,
                    updatedApplicationOrganizationName: applicationOrganizationName
                }));
            setValid(!!applicationOrganizationName);
        },
        [applicationOrganizationName]);

    const theme = useTheme();

    return (
        <Stack
            spacing={4}
            sx={{ maxWidth: theme.spacing(70) }}>
            <TextField
                disabled={executing || !_.isNil(gitHubServerConfiguration)}
                label={localization.fields.organizationName.title()}
                slotProps={{
                    input: {
                        endAdornment:
                            <InputAdornment position="end">
                                <Tooltip titleOrGetTitle={localization.fields.organizationName.tooltip()}>
                                    <InfoIcon
                                        sx={{
                                            color: theme.palette.text.secondary,
                                            fontSize: "24px"
                                        }}/>
                                </Tooltip>
                            </InputAdornment>
                    }
                }}
                value={applicationOrganizationName}
                onChange={event => setApplicationOrganizationName(event.target.value)}/>
            <Button
                component="a"
                disabled={!applicationOrganizationName}
                href={
                    ConsoleApiUrlHelper.getGitHubServerAppRegistrationUrl(
                        applicationName,
                        applicationOrganizationName,
                        externalEndpoint)}
                sx={{ width: theme.spacing(25) }}
                target="_blank"
                variant="outlined">
                {localization.actions.registerApp()}
            </Button>
        </Stack>);
}