import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetCommonKubernetesClusterRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";

export function useKubernetesClusterIdentityProviderAuthenticationDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.KubernetesClusterIdentityProviderAuthenticationDisabledRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId);

    const getCommonKubernetesClusterRiskContext = useGetCommonKubernetesClusterRiskContext(clusterModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.kubernetes.hooks.clusters.useKubernetesClusterIdentityProviderAuthenticationDisabledRiskDefinition",
            () => ({
                description: "{{cluster}} OAuth not configured with an identity provider",
                resolutionSection: {
                    step1: {
                        link: "Understanding identity provider configuration",
                        text: "Configure OAuth to specify an identity provider. See {{openShiftIdentityProviderConfigurationDocumentationLink}} for more information"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{
                        includeServiceName: true,
                        variant: "title"
                    }}
                    variant="typeText"/>
        }),
        () => [
            localization.resolutionSection.step1.text({
                openShiftIdentityProviderConfigurationDocumentationLink:
                    <Link
                        urlOrGetUrl={(riskModel as Contract.KubernetesClusterIdentityProviderAuthenticationDisabledRiskModel).openShiftIdentityProviderConfigurationDocumentationUrl}
                        variant="external">
                        {localization.resolutionSection.step1.link()}
                    </Link>
            })
        ],
        riskModel,
        () => {
            const clusterRiskContext = getCommonKubernetesClusterRiskContext(clusterModel);

            return [
                clusterRiskContext.generalInformation,
                clusterRiskContext.virtualNetwork,
                clusterRiskContext.sensitive,
                clusterRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}