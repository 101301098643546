import _ from "lodash";
import { useMemo } from "react";
import { UnexpectedError, useLocalization } from "@infrastructure";
import { Contract, riskPolicyModelStore, TypeHelper } from "..";

export function useRiskPolicyTitleTranslator() {
    const customRiskPolicyModels = riskPolicyModelStore.useGetCustom();
    const customRiskPolicyModelMap =
        useMemo(
            () =>
                _.keyBy(
                    customRiskPolicyModels,
                    customRiskPolicyModel => customRiskPolicyModel.riskPolicyConfiguration.id),
            [customRiskPolicyModels]);

    const localization =
        useLocalization(
            "common.hooks.useRiskPolicyTitleTranslator",
            () => ({
                [Contract.TypeNames.RiskPolicyConfiguration]: {
                    [Contract.TypeNames.AadDirectoryApplicationCertificateNotRotatedRiskPolicyConfiguration]: "App Registration certificate is not rotated",
                    [Contract.TypeNames.AadDirectoryApplicationSecretNotRotatedRiskPolicyConfiguration]: "App Registration secret is not rotated",
                    [Contract.TypeNames.AadDirectoryApplicationServicePrincipalInactiveRiskPolicyConfiguration]: "Inactive Microsoft Entra ID Application",
                    [Contract.TypeNames.AadDirectoryGroupInactiveRiskPolicyConfiguration]: "Inactive Microsoft Entra ID Group",
                    [Contract.TypeNames.AadDirectoryUserInactiveRiskPolicyConfiguration]: "Inactive Microsoft Entra ID User",
                    [Contract.TypeNames.AadSeverePermissionPrincipalRiskPolicyConfiguration]: "New privileged identity in Microsoft Entra ID",
                    [Contract.TypeNames.AadTenantEntityDefaultUserRoleApplicationCreationEnabledRiskPolicyConfiguration]: "Microsoft Entra ID Tenant 'Users can register application' is enabled",
                    [Contract.TypeNames.AadTenantEntityDefaultUserRoleTenantCreationEnabledRiskPolicyConfiguration]: "Microsoft Entra ID Tenant 'Restrict non-admin users from creating tenants' is not enabled",
                    [Contract.TypeNames.AadTenantEntityUnrestrictedExternalUserDefaultRoleRiskPolicyConfiguration]: "Microsoft Entra ID Tenant 'Guest users access restrictions' does not meet minimum requirements",
                    [Contract.TypeNames.AadTenantEntityUnrestrictedExternalUserInvitationAllowedRolesRiskPolicyConfiguration]: "Microsoft Entra ID Tenant 'Guest invite restrictions' does not meet minimum requirements",
                    [Contract.TypeNames.AwsAccessAnalyzerRegionAnalyzerNotExistRiskPolicyConfiguration]: "IAM access analyzer is not enabled for all regions",
                    [Contract.TypeNames.AwsAcmCertificateKeyMinLengthRiskPolicyConfiguration]: "ACM Certificate key length does not meet minimum requirements",
                    [Contract.TypeNames.AwsApiGatewayApiWebAclNotExistsRiskPolicyConfiguration]: "API Gateway API is not associated with WAF web ACL",
                    [Contract.TypeNames.AwsAthenaWorkgroupEncryptionDisabledRiskPolicyConfiguration]: "Athena workgroup query results are not encrypted",
                    [Contract.TypeNames.AwsAthenaWorkgroupLoggingDisabledRiskPolicyConfiguration]: "Athena workgroup logging is not enabled",
                    [Contract.TypeNames.AwsCloudFormationStackSecretExistsRiskPolicyConfiguration]: "CloudFormation Stack is exposing secrets",
                    [Contract.TypeNames.AwsCloudFrontDistributionDefaultSslCertificateRiskPolicyConfiguration]: "CloudFront Distribution is using the default SSL certificate",
                    [Contract.TypeNames.AwsCloudFrontDistributionHttpsOnlyDisabledRiskPolicyConfiguration]: "Unencrypted CloudFront Distribution viewer traffic",
                    [Contract.TypeNames.AwsCloudFrontDistributionInsecureTlsProtocolRiskPolicyConfiguration]: "CloudFront Distribution insecure communication",
                    [Contract.TypeNames.AwsCloudFrontDistributionLoggingDisabledRiskPolicyConfiguration]: "CloudFront Distribution logging is not enabled",
                    [Contract.TypeNames.AwsCloudFrontDistributionOriginHttpsOnlyDisabledRiskPolicyConfiguration]: "Unencrypted CloudFront Distribution custom origin traffic",
                    [Contract.TypeNames.AwsCloudFrontDistributionRootObjectNotExistsRiskPolicyConfiguration]: "CloudFront Distribution default root object is not set",
                    [Contract.TypeNames.AwsCloudFrontDistributionS3BucketOriginAccessControlDisabledRiskPolicyConfiguration]: "CloudFront Distribution S3 origin access control is not enabled",
                    [Contract.TypeNames.AwsCloudFrontDistributionWebAclNotExistsRiskPolicyConfiguration]: "CloudFront Distribution is not associated with WAF web ACL",
                    [Contract.TypeNames.AwsCloudTrailTrailBucketEventDisabledRiskPolicyConfiguration]: "CloudTrail S3 Bucket logging is not enabled",
                    [Contract.TypeNames.AwsCloudTrailTrailBucketPublicAccessEnabledRiskPolicyConfiguration]: "Public CloudTrail S3 Bucket",
                    [Contract.TypeNames.AwsCloudTrailTrailDigestDisabledRiskPolicyConfiguration]: "CloudTrail S3 Bucket log file validation is not enabled",
                    [Contract.TypeNames.AwsCloudTrailTrailEncryptionDisabledRiskPolicyConfiguration]: "CloudTrail Logs are not encrypted with CMK",
                    [Contract.TypeNames.AwsCloudTrailTrailLogGroupDisabledRiskPolicyConfiguration]: "CloudTrail Logs are not configured with CloudWatch",
                    [Contract.TypeNames.AwsCloudTrailTrailMultiRegionNotExistRiskPolicyConfiguration]: "Missing multi-regional CloudTrail",
                    [Contract.TypeNames.AwsCloudTrailTrailS3ObjectReadEventSelectorNotExistRiskPolicyConfiguration]: "S3 Bucket object-level logging for read events is not enabled",
                    [Contract.TypeNames.AwsCloudTrailTrailS3ObjectWriteEventSelectorNotExistRiskPolicyConfiguration]: "S3 Bucket object-level logging for write events is not enabled",
                    [Contract.TypeNames.AwsCloudWatchAlarmCloudTrailChangesNotExistRiskPolicyConfiguration]: "Missing CloudWatch alarm - CloudTrail configuration changes",
                    [Contract.TypeNames.AwsCloudWatchAlarmConfigChangesNotExistRiskPolicyConfiguration]: "Missing CloudWatch alarm - configuration changes",
                    [Contract.TypeNames.AwsCloudWatchAlarmEc2InternetGatewayChangesNotExistRiskPolicyConfiguration]: "Missing CloudWatch alarm - network gateway changes",
                    [Contract.TypeNames.AwsCloudWatchAlarmEc2NetworkAclChangesNotExistRiskPolicyConfiguration]: "Missing CloudWatch alarm - NACL changes",
                    [Contract.TypeNames.AwsCloudWatchAlarmEc2RouteTableChangesNotExistRiskPolicyConfiguration]: "Missing CloudWatch alarm - route table changes",
                    [Contract.TypeNames.AwsCloudWatchAlarmEc2SecurityGroupChangesNotExistRiskPolicyConfiguration]: "Missing CloudWatch alarm - Security Group changes",
                    [Contract.TypeNames.AwsCloudWatchAlarmEc2VpcChangesNotExistRiskPolicyConfiguration]: "Missing CloudWatch alarm - VPC changes",
                    [Contract.TypeNames.AwsCloudWatchAlarmIamPolicyChangesNotExistRiskPolicyConfiguration]: "Missing CloudWatch alarm - IAM policy changes",
                    [Contract.TypeNames.AwsCloudWatchAlarmKmsCustomerMasterKeyDisabledNotExistRiskPolicyConfiguration]: "Missing CloudWatch alarm - disabled KMS keys",
                    [Contract.TypeNames.AwsCloudWatchAlarmManagementConsoleAuthenticationFailuresNotExistRiskPolicyConfiguration]: "Missing CloudWatch alarm - management console authentication failures",
                    [Contract.TypeNames.AwsCloudWatchAlarmManagementConsoleMfaDisabledNotExistRiskPolicyConfiguration]: "Missing CloudWatch alarm - management console MFA disabled",
                    [Contract.TypeNames.AwsCloudWatchAlarmOrganizationsOrganizationChangesNotExistRiskPolicyConfiguration]: "Missing CloudWatch alarm - Organization changes",
                    [Contract.TypeNames.AwsCloudWatchAlarmRootUsageNotExistRiskPolicyConfiguration]: "Missing CloudWatch alarm - root account usage",
                    [Contract.TypeNames.AwsCloudWatchAlarmS3BucketPolicyChangesNotExistRiskPolicyConfiguration]: "Missing CloudWatch alarm - S3 Bucket policy changes",
                    [Contract.TypeNames.AwsCloudWatchAlarmUnauthorizedApiCallsNotExistRiskPolicyConfiguration]: "Missing CloudWatch alarm - unauthorized API calls",
                    [Contract.TypeNames.AwsConfigRegionConfigurationRecorderNotExistRiskPolicyConfiguration]: "AWS config is not enabled for all regions",
                    [Contract.TypeNames.AwsDataLeakageRiskPolicyConfiguration]: "Unusual Data Access",
                    [Contract.TypeNames.AwsDocDbClusterBackupRetentionTimeFrameRiskPolicyConfiguration]: "DocumentDB Cluster backup retention period does not meet minimum requirements",
                    [Contract.TypeNames.AwsDynamoDbTableDeleteProtectionDisabledRiskPolicyConfiguration]: "DynamoDB Table delete protection is not enabled",
                    [Contract.TypeNames.AwsDynamoDbTableEncryptionDisabledRiskPolicyConfiguration]: "DynamoDB Table is not encrypted with KMS",
                    [Contract.TypeNames.AwsEc2ImagePublicAccessExistsRiskPolicyConfiguration]: "Public EC2 Image",
                    [Contract.TypeNames.AwsEc2InstanceInstanceProfileNotExistRiskPolicyConfiguration]: "EC2 Instance IAM Instance Profile is not set",
                    [Contract.TypeNames.AwsEc2InstanceMetadataServiceTokenHopMaxCountRiskPolicyConfiguration]: "EC2 Instance metadata response hop limit does not meet minimum requirements",
                    [Contract.TypeNames.AwsEc2InstanceMetadataServiceVersionRiskPolicyConfiguration]: "EC2 Instance metadata service supports insecure version",
                    [Contract.TypeNames.AwsEc2InstanceUserDataSecretExistsRiskPolicyConfiguration]: "EC2 Instance is exposing secrets",
                    [Contract.TypeNames.AwsEc2LoadBalancerListenerInsecureSslProtocolExistsRiskPolicyConfiguration]: "Load Balancer insecure communication",
                    [Contract.TypeNames.AwsEc2NetworkAclInboundRuleSubnetAnyExistsRiskPolicyConfiguration]: "Network ACL unrestricted inbound internet access",
                    [Contract.TypeNames.AwsEc2SecurityGroupAllowedInboundRuleRiskPolicyConfiguration]: "Security Groups should allow inbound internet connection on these ports from these sources",
                    [Contract.TypeNames.AwsEc2SecurityGroupInboundRuleSubnetAnyExistsRiskPolicyConfiguration]: "Security Group unrestricted inbound internet access",
                    [Contract.TypeNames.AwsEc2SecurityGroupManagementRiskPolicyConfiguration]: "Monitor changes in security group rules",
                    [Contract.TypeNames.AwsEc2SecurityGroupNotAllowedInboundRuleRiskPolicyConfiguration]: "Security Groups should not allow inbound internet connection on these ports from these sources",
                    [Contract.TypeNames.AwsEc2SecurityGroupUnusedRiskPolicyConfiguration]: "Unused Security Group",
                    [Contract.TypeNames.AwsEc2SnapshotEncryptionDisabledRiskPolicyConfiguration]: "EBS Snapshot is not encrypted with KMS",
                    [Contract.TypeNames.AwsEc2SnapshotPublicAccessExistsRiskPolicyConfiguration]: "Public EBS Snapshot",
                    [Contract.TypeNames.AwsEc2VolumeEncryptionDisabledRiskPolicyConfiguration]: "EBS Volume is not encrypted with KMS",
                    [Contract.TypeNames.AwsEc2VpcDefaultSecurityGroupRuleExistsRiskPolicyConfiguration]: "Default security group has rules configured",
                    [Contract.TypeNames.AwsEc2VpcEndpointUnrestrictedServiceAccessRiskPolicyConfiguration]: "Overpermissive VPC Endpoint",
                    [Contract.TypeNames.AwsEc2VpcRejectFlowLogNotExistRiskPolicyConfiguration]: "VPC Flow Logs is not set",
                    [Contract.TypeNames.AwsEcrRepositoryPublicAccessExistsRiskPolicyConfiguration]: "Public ECR Repository",
                    [Contract.TypeNames.AwsEcsTaskDefinitionEnvironmentVariableSecretExistsRiskPolicyConfiguration]: "ECS Task Definition is exposing secrets",
                    [Contract.TypeNames.AwsEksClusterInboundExternalWideRangeRiskPolicyConfiguration]: "EKS cluster Kubernetes API wide inbound internet access",
                    [Contract.TypeNames.AwsEksClusterInstanceInboundExternalRiskPolicyConfiguration]: "EKS cluster contains nodes with public IPs",
                    [Contract.TypeNames.AwsEksClusterKubernetesSecretEncryptionDisabledRiskPolicyConfiguration]: "Kubernetes secrets are not encrypted with KMS",
                    [Contract.TypeNames.AwsElastiCacheReplicationGroupAutoFailoverDisabledRiskPolicyConfiguration]: "Redis OSS cluster cache automatic failover is not enabled",
                    [Contract.TypeNames.AwsElastiCacheReplicationGroupAutoUpgradeMinorVersionsDisabledRiskPolicyConfiguration]: "Redis OSS cluster cache automatic minor version upgrade is not enabled",
                    [Contract.TypeNames.AwsElastiCacheReplicationGroupAutomaticBackupsDisabledRiskPolicyConfiguration]: "Redis OSS cluster cache automatic backup does not meet minimum requirements",
                    [Contract.TypeNames.AwsElastiCacheReplicationGroupDefaultSubnetGroupRiskPolicyConfiguration]: "Redis OSS cluster cache is using the default subnet group",
                    [Contract.TypeNames.AwsElastiCacheReplicationGroupEncryptionDisabledRiskPolicyConfiguration]: "Redis OSS cluster cache is not encrypted with KMS",
                    [Contract.TypeNames.AwsElastiCacheReplicationGroupTransitEncryptionModeNotRequiredRiskPolicyConfiguration]: "Unencrypted Redis OSS cluster cache traffic",
                    [Contract.TypeNames.AwsElasticBeanstalkEnvironmentCloudWatchLoggingDisabledRiskPolicyConfiguration]: "Elastic Beanstalk does not publish logs to CloudWatch Logs",
                    [Contract.TypeNames.AwsElasticFileSystemFileSystemEncryptionDisabledRiskPolicyConfiguration]: "EFS File System is not encrypted with KMS",
                    [Contract.TypeNames.AwsEncryptedResourceKmsEncryptionNotExistsRiskPolicyConfiguration]: "Only these resources should be encrypted using these keys",
                    [Contract.TypeNames.AwsEsDomainCloudWatchLoggingDisabledRiskPolicyConfiguration]: "OpenSearch domain does not publish logs to CloudWatch Logs",
                    [Contract.TypeNames.AwsEsDomainEncryptionDisabledRiskPolicyConfiguration]: "OpenSearch Domain is not encrypted with KMS",
                    [Contract.TypeNames.AwsEsDomainNodeCommunicationEncryptionDisabledRiskPolicyConfiguration]: "Unencrypted OpenSearch Domain node-to-node communication",
                    [Contract.TypeNames.AwsEsDomainPublicAccessExistsRiskPolicyConfiguration]: "Public OpenSearch Domain",
                    [Contract.TypeNames.AwsExcessivePermissionBucketRiskPolicyConfiguration]: "Overpermissive S3 Bucket",
                    [Contract.TypeNames.AwsExcessivePermissionGroupRiskPolicyConfiguration]: "Overprivileged IAM Group",
                    [Contract.TypeNames.AwsExcessivePermissionPermissionSetRiskPolicyConfiguration]: "Overprivileged SSO Permission Set",
                    [Contract.TypeNames.AwsExcessivePermissionRepositoryRiskPolicyConfiguration]: "Overpermissive ECR Repository",
                    [Contract.TypeNames.AwsExcessivePermissionRoleGroupRiskPolicyConfiguration]: "Overprivileged Organization IAM Role",
                    [Contract.TypeNames.AwsExcessivePermissionRoleRiskPolicyConfiguration]: "Overprivileged IAM Role",
                    [Contract.TypeNames.AwsExcessivePermissionSecretRiskPolicyConfiguration]: "Overpermissive Secrets Manager secret",
                    [Contract.TypeNames.AwsExcessivePermissionUserRiskPolicyConfiguration]: "Overprivileged IAM User",
                    [Contract.TypeNames.AwsFirehoseDeliveryStreamEncryptionDisabledRiskPolicyConfiguration]: "Data Firehose Stream is not encrypted with KMS",
                    [Contract.TypeNames.AwsIamGroupInactiveRiskPolicyConfiguration]: "Inactive IAM group",
                    [Contract.TypeNames.AwsIamPasswordLengthPolicyRiskPolicyConfiguration]: "Password length does not meet minimum requirements",
                    [Contract.TypeNames.AwsIamPasswordReusePolicyRiskPolicyConfiguration]: "Password reuse policy does not meet minimum requirements",
                    [Contract.TypeNames.AwsIamPrincipalAttachedPolicyPermissionScopeAnyRiskPolicyConfiguration]: "IAM policy with full administrative privileges \"*:*\" is attached",
                    [Contract.TypeNames.AwsIamPrincipalCreationRiskPolicyConfiguration]: "New IAM principal created",
                    [Contract.TypeNames.AwsIamRoleInactiveRiskPolicyConfiguration]: "Inactive IAM Role",
                    [Contract.TypeNames.AwsIamRoleManagementRiskPolicyConfiguration]: "Monitor changes in role trust policy",
                    [Contract.TypeNames.AwsIamRolePublicAccessExistsRiskPolicyConfiguration]: "Public IAM Role",
                    [Contract.TypeNames.AwsIamRoleVendorAssumeRolePolicyDocumentExternalIdConditionNotExistRiskPolicyConfiguration]: "3rd party IAM role External ID is not set",
                    [Contract.TypeNames.AwsIamRootUserAccessKeyEnabledRiskPolicyConfiguration]: "Root User has access key",
                    [Contract.TypeNames.AwsIamRootUserActivityRiskPolicyConfiguration]: "Root User activity detected",
                    [Contract.TypeNames.AwsIamRootUserMfaDisabledRiskPolicyConfiguration]: "Root User MFA is not enabled",
                    [Contract.TypeNames.AwsIamServerCertificateExpiredRiskPolicyConfiguration]: "IAM Server Certificate is expired",
                    [Contract.TypeNames.AwsIamSupportRoleNotExistRiskPolicyConfiguration]: "AWS Account support role is not set",
                    [Contract.TypeNames.AwsIamUserAttachedPolicyRiskPolicyConfiguration]: "IAM User has policies attached",
                    [Contract.TypeNames.AwsIamUserInactiveRiskPolicyConfiguration]: "Inactive IAM User",
                    [Contract.TypeNames.AwsIamUserMfaDisabledRiskPolicyConfiguration]: "IAM user MFA is not enabled",
                    [Contract.TypeNames.AwsIamUserMultipleEnabledAccessKeyRiskPolicyConfiguration]: "IAM User has multiple active access keys",
                    [Contract.TypeNames.AwsIamUserUnrotatedAccessKeyRiskPolicyConfiguration]: "IAM user Access Keys are not rotated",
                    [Contract.TypeNames.AwsIamUserUnusedAccessKeysEnabledRiskPolicyConfiguration]: "IAM user unused Access Keys",
                    [Contract.TypeNames.AwsIamUserUnusedPasswordEnabledRiskPolicyConfiguration]: "IAM User unused password",
                    [Contract.TypeNames.AwsIdentityActivityRiskPolicyConfiguration]: "Monitor any activity of identities",
                    [Contract.TypeNames.AwsInboundExternalEc2InstanceRiskPolicyConfiguration]: "Public EC2 Instance",
                    [Contract.TypeNames.AwsInboundExternalElbLoadBalancerRiskPolicyConfiguration]: "Public Load Balancer",
                    [Contract.TypeNames.AwsInboundExternalRdsDatabaseInstanceRiskPolicyConfiguration]: "Public RDS Instance",
                    [Contract.TypeNames.AwsInboundExternalRedshiftClusterRiskPolicyConfiguration]: "Public Redshift Cluster",
                    [Contract.TypeNames.AwsInboundExternalSubnetRiskPolicyConfiguration]: "Subnets should not allow inbound internet connection",
                    [Contract.TypeNames.AwsInboundExternalVpcRiskPolicyConfiguration]: "Vpcs should not have internet gateway attached",
                    [Contract.TypeNames.AwsKinesisStreamEncryptionDisabledRiskPolicyConfiguration]: "Kinesis Data Stream is not encrypted with KMS",
                    [Contract.TypeNames.AwsKmsKeyManagementRiskPolicyConfiguration]: "Monitor changes in KMS Key permissions",
                    [Contract.TypeNames.AwsKmsKeyPublicAccessExistsRiskPolicyConfiguration]: "Public KMS Key",
                    [Contract.TypeNames.AwsKmsKeyRotationDisabledRiskPolicyConfiguration]: "KMS Key automatic key rotation is not enabled",
                    [Contract.TypeNames.AwsLambdaFunctionConfigurationDeprecatedRuntimeRiskPolicyConfiguration]: "Lambda function is using deprecated runtime",
                    [Contract.TypeNames.AwsLambdaFunctionConfigurationEnvironmentVariableSecretExistsRiskPolicyConfiguration]: "Lambda Function is exposing secrets",
                    [Contract.TypeNames.AwsLambdaFunctionConfigurationMultiAvailabilityZoneNotExistRiskPolicyConfiguration]: "Lambda function does not operate in multiple Availability Zones",
                    [Contract.TypeNames.AwsLambdaFunctionConfigurationPublicAccessExistsRiskPolicyConfiguration]: "Public Lambda Function",
                    [Contract.TypeNames.AwsLambdaFunctionConfigurationVpcNotExistsRiskPolicyConfiguration]: "Lambda function is not associated with a VPC",
                    [Contract.TypeNames.AwsNetworkAccessManagementRiskPolicyConfiguration]: "Unusual Network Access Management",
                    [Contract.TypeNames.AwsNetworkedResourceInboundExternalPortRiskPolicyConfiguration]: "Resources should not allow inbound internet connection from these IP address ranges to these ports",
                    [Contract.TypeNames.AwsPermissionManagementRiskPolicyConfiguration]: "Unusual Permission Management",
                    [Contract.TypeNames.AwsPrincipalAllowedPermissionRiskPolicyConfiguration]: "Only these principals should have these permissions",
                    [Contract.TypeNames.AwsPrincipalAllowedResourcePermissionRiskPolicyConfiguration]: "For these resources only these principals should have these permissions",
                    [Contract.TypeNames.AwsPrincipalNotAllowedResourcePermissionRiskPolicyConfiguration]: "These principals should not have these permissions on these resources",
                    [Contract.TypeNames.AwsPrivilegeEscalationRiskPolicyConfiguration]: "Unusual Privilege Escalation",
                    [Contract.TypeNames.AwsRdsClusterBackupRetentionTimeFrameRiskPolicyConfiguration]: "RDS Cluster backup retention period does not meet minimum requirements",
                    [Contract.TypeNames.AwsRdsClusterCloudWatchLoggingDisabledRiskPolicyConfiguration]: "RDS Cluster does not publish logs to CloudWatch Logs",
                    [Contract.TypeNames.AwsRdsClusterDeleteProtectionDisabledRiskPolicyConfiguration]: "RDS Cluster delete protection is not enabled",
                    [Contract.TypeNames.AwsRdsClusterMinorVersionUpgradeDisabledRiskPolicyConfiguration]: "RDS cluster automatic minor version upgrade is not enabled",
                    [Contract.TypeNames.AwsRdsClusterStorageEncryptionDisabledRiskPolicyConfiguration]: "RDS cluster is not encrypted with KMS",
                    [Contract.TypeNames.AwsRdsDatabaseInstanceBackupRetentionTimeFrameRiskPolicyConfiguration]: "RDS Instance backup retention period does not meet minimum requirements",
                    [Contract.TypeNames.AwsRdsDatabaseInstanceCloudWatchLoggingDisabledRiskPolicyConfiguration]: "RDS Instance does not publish logs to CloudWatch Logs",
                    [Contract.TypeNames.AwsRdsDatabaseInstanceDeleteProtectionDisabledRiskPolicyConfiguration]: "RDS Instance delete protection is not enabled",
                    [Contract.TypeNames.AwsRdsDatabaseInstanceMinorVersionUpgradeDisabledRiskPolicyConfiguration]: "RDS Instance automatic minor version upgrade is not enabled",
                    [Contract.TypeNames.AwsRdsDatabaseInstanceStorageEncryptionDisabledRiskPolicyConfiguration]: "RDS Instance is not encrypted with KMS",
                    [Contract.TypeNames.AwsRdsDocDbClusterCloudWatchLoggingDisabledRiskPolicyConfiguration]: "DocumentDB Cluster does not publish logs to CloudWatch Logs",
                    [Contract.TypeNames.AwsRdsDocDbClusterDeleteProtectionDisabledRiskPolicyConfiguration]: "DocumentDB Cluster delete protection is not enabled",
                    [Contract.TypeNames.AwsRdsDocDbClusterSnapshotEncryptionDisabledRiskPolicyConfiguration]: "DocumentDB Cluster Snapshot is not encrypted with KMS",
                    [Contract.TypeNames.AwsRdsDocDbClusterSnapshotPublicAccessExistsRiskPolicyConfiguration]: "Public DocumentDB Cluster Snapshot",
                    [Contract.TypeNames.AwsRdsNeptuneClusterBackupRetentionTimeFrameRiskPolicyConfiguration]: "Neptune Cluster backup retention period does not meet minimum requirements",
                    [Contract.TypeNames.AwsRdsNeptuneClusterCloudWatchLoggingDisabledRiskPolicyConfiguration]: "Neptune DB Cluster does not publish logs to CloudWatch Logs",
                    [Contract.TypeNames.AwsRdsNeptuneClusterDeleteProtectionDisabledRiskPolicyConfiguration]: "Neptune Cluster delete protection is not enabled",
                    [Contract.TypeNames.AwsRdsNeptuneClusterSnapshotEncryptionDisabledRiskPolicyConfiguration]: "Neptune Cluster Snapshot is not encrypted with KMS",
                    [Contract.TypeNames.AwsRdsNeptuneClusterSnapshotPublicAccessExistsRiskPolicyConfiguration]: "Public Neptune Cluster Snapshot",
                    [Contract.TypeNames.AwsRdsNeptuneDatabaseInstanceMinorVersionUpgradeDisabledRiskPolicyConfiguration]: "Neptune Instance automatic minor version upgrade is not enabled",
                    [Contract.TypeNames.AwsRdsSnapshotEncryptionDisabledRiskPolicyConfiguration]: "RDS Snapshot is not encrypted with KMS",
                    [Contract.TypeNames.AwsRdsSnapshotPublicAccessExistsRiskPolicyConfiguration]: "Public RDS Snapshot",
                    [Contract.TypeNames.AwsReconnaissanceRiskPolicyConfiguration]: "Unusual Reconnaissance",
                    [Contract.TypeNames.AwsRedshiftClusterBackupRetentionTimeFrameRiskPolicyConfiguration]: "Redshift Cluster backup retention period does not meet minimum requirements",
                    [Contract.TypeNames.AwsRedshiftClusterEncryptionDisabledRiskPolicyConfiguration]: "Redshift Cluster is not encrypted with KMS",
                    [Contract.TypeNames.AwsResourceCodeResourceNotExistsRiskPolicyConfiguration]: "These resources should be managed as code",
                    [Contract.TypeNames.AwsResourceTagNotExistsRiskPolicyConfiguration]: "Only these resources should have these tags",
                    [Contract.TypeNames.AwsResourceTagSecretExistsRiskPolicyConfiguration]: "AWS resource tags are exposing secrets",
                    [Contract.TypeNames.AwsRoleTemplateMismatchRiskPolicyConfiguration]: "For these roles, policies should match the reference role policies",
                    [Contract.TypeNames.AwsRoute53HostedZoneSigningDisabledRiskPolicyConfiguration]: "Route 53 hosted zone DNSSEC is not enabled",
                    [Contract.TypeNames.AwsS3BucketBlockPublicAccessDisabledRiskPolicyConfiguration]: "S3 Bucket does not block public access",
                    [Contract.TypeNames.AwsS3BucketEncryptionDisabledRiskPolicyConfiguration]: "S3 Bucket is not encrypted with KMS",
                    [Contract.TypeNames.AwsS3BucketHttpsOnlyDisabledRiskPolicyConfiguration]: "Unencrypted S3 Bucket traffic",
                    [Contract.TypeNames.AwsS3BucketManagementRiskPolicyConfiguration]: "Monitor changes in S3 Bucket policies, ACLs and Block Public Access setting",
                    [Contract.TypeNames.AwsS3BucketObjectMfaDeleteDisabledRiskPolicyConfiguration]: "S3 Bucket MFA delete is not enabled",
                    [Contract.TypeNames.AwsS3BucketPublicAccessExistsRiskPolicyConfiguration]: "Public S3 Bucket",
                    [Contract.TypeNames.AwsSageMakerNotebookInstanceDefaultEncryptionRiskPolicyConfiguration]: "SageMaker Notebook instance is not encrypted with CMK",
                    [Contract.TypeNames.AwsSageMakerNotebookInstanceRootAccessEnabledRiskPolicyConfiguration]: "SageMaker Notebook instance root access is enabled",
                    [Contract.TypeNames.AwsSageMakerNotebookInstanceUnrestrictedOutboundAccessRiskPolicyConfiguration]: "SageMaker Notebook instance direct internet access is enabled",
                    [Contract.TypeNames.AwsSecretsManagerSecretManagementRiskPolicyConfiguration]: "Monitor changes in secret policies",
                    [Contract.TypeNames.AwsSecretsManagerSecretPublicAccessExistsRiskPolicyConfiguration]: "Public Secrets Manager secret",
                    [Contract.TypeNames.AwsSecretsManagerSecretRotationDisabledRiskPolicyConfiguration]: "Secrets Manager secret automatic rotation is not enabled",
                    [Contract.TypeNames.AwsSecurityHubRegionHubDisabledRiskPolicyConfiguration]: "AWS security hub is not enabled for all regions",
                    [Contract.TypeNames.AwsSeverePermissionPrincipalRiskPolicyConfiguration]: "New privileged identity",
                    [Contract.TypeNames.AwsSnsTopicEncryptionDisabledRiskPolicyConfiguration]: "SNS Topic is not encrypted with KMS",
                    [Contract.TypeNames.AwsSnsTopicHttpsOnlyDisabledRiskPolicyConfiguration]: "Unencrypted SNS Topic traffic",
                    [Contract.TypeNames.AwsSnsTopicPublicAccessExistsRiskPolicyConfiguration]: "Public SNS Topic",
                    [Contract.TypeNames.AwsSqsQueueEncryptionDisabledRiskPolicyConfiguration]: "SQS Queue is not encrypted with KMS",
                    [Contract.TypeNames.AwsSqsQueuePublicAccessExistsRiskPolicyConfiguration]: "Public SQS Queue",
                    [Contract.TypeNames.AwsSsmParameterSecretExistsRiskPolicyConfiguration]: "Systems Manager Parameter is exposing a secret",
                    [Contract.TypeNames.AwsSsoPermissionSetAssignmentRiskPolicyConfiguration]: "Permission Set assigned to users or groups",
                    [Contract.TypeNames.AwsSsoPermissionSetManagementRiskPolicyConfiguration]: "Permission Set created or updated",
                    [Contract.TypeNames.AwsSsoPermissionSetUnusedRiskPolicyConfiguration]: "Unused SSO Permission Set",
                    [Contract.TypeNames.AwsSsoPrincipalCreationRiskPolicyConfiguration]: "New identity center users or groups created",
                    [Contract.TypeNames.AwsTenantEntityCreationRiskPolicyConfiguration]: "New AWS Account",
                    [Contract.TypeNames.AzureApplicationAppSettingsSecretExistsRiskPolicyConfiguration]: "App Service is exposing secrets",
                    [Contract.TypeNames.AzureBotServiceBotServiceInboundExternalWideRangeRiskPolicyConfiguration]: "Bot Service wide inbound internet access",
                    [Contract.TypeNames.AzureBotServiceBotServiceSharedKeyAccessEnabledRiskPolicyConfiguration]: "Bot Service local authentication is enabled",
                    [Contract.TypeNames.AzureCognitiveServicesAccountInboundExternalWideRangeRiskPolicyConfiguration]: "Azure AI Service wide inbound internet access",
                    [Contract.TypeNames.AzureCognitiveServicesAccountSharedKeyAccessEnabledRiskPolicyConfiguration]: "Azure AI Service local authentication is enabled",
                    [Contract.TypeNames.AzureCognitiveServicesAccountUnrestrictedOutboundAccessRiskPolicyConfiguration]: "Azure AI Service data loss prevention is not enabled",
                    [Contract.TypeNames.AzureComputeVirtualMachineUnmanagedDiskExistsRiskPolicyConfiguration]: "Virtual Machine is using unmanaged disk",
                    [Contract.TypeNames.AzureComputeVirtualMachineUserDataSecretExistsRiskPolicyConfiguration]: "Virtual Machine is exposing secrets",
                    [Contract.TypeNames.AzureContainerRegistryRegistryAccessKeyEnabledRiskPolicyConfiguration]: "Container Registry admin user is enabled",
                    [Contract.TypeNames.AzureContainerRegistryRegistryInboundExternalWideRangeRiskPolicyConfiguration]: "Container Registry wide inbound internet access",
                    [Contract.TypeNames.AzureContainerServiceManagedClusterIdentityAuthenticationDisabledRiskPolicyConfiguration]: "AKS cluster Microsoft Entra ID authentication is not enabled",
                    [Contract.TypeNames.AzureContainerServiceManagedClusterInboundExternalWideRangeRiskPolicyConfiguration]: "AKS cluster Kubernetes API wide inbound internet access",
                    [Contract.TypeNames.AzureContainerServiceManagedClusterKubernetesSecretEncryptionDisabledRiskPolicyConfiguration]: "Kubernetes secrets are not encrypted with CMK",
                    [Contract.TypeNames.AzureContainerServiceManagedClusterVirtualMachineInboundExternalRiskPolicyConfiguration]: "AKS cluster contains nodes with public IPs",
                    [Contract.TypeNames.AzureDocumentDbDatabaseAccountManagementKeyAccessEnabledRiskPolicyConfiguration]: "CosmosDB Account key management access is enabled",
                    [Contract.TypeNames.AzureDocumentDbDatabaseAccountPrivateEndpointNotExistsRiskPolicyConfiguration]: "CosmosDB account is not associated with private endpoint",
                    [Contract.TypeNames.AzureDocumentDbDatabaseAccountWideRangeInboundRiskPolicyConfiguration]: "CosmosDB Account wide inbound internet access",
                    [Contract.TypeNames.AzureEventHubNamespaceInboundExternalWideRangeRiskPolicyConfiguration]: "Event Hubs Namespace wide inbound internet access",
                    [Contract.TypeNames.AzureExcessivePermissionApplicationServicePrincipalRiskPolicyConfiguration]: "Overprivileged Microsoft Entra ID Application in subscription",
                    [Contract.TypeNames.AzureExcessivePermissionGroupRiskPolicyConfiguration]: "Overprivileged Microsoft Entra ID Group in subscription",
                    [Contract.TypeNames.AzureExcessivePermissionManagedIdentityServicePrincipalRiskPolicyConfiguration]: "Overprivileged Managed Identity in subscription",
                    [Contract.TypeNames.AzureExcessivePermissionUserRiskPolicyConfiguration]: "Overprivileged Microsoft Entra ID User in subscription",
                    [Contract.TypeNames.AzureGuestUserAdministratorRiskPolicyConfiguration]: "Microsoft Entra ID external or guest user with admin role in subscription",
                    [Contract.TypeNames.AzureInboundExternalComputeVirtualMachineRiskPolicyConfiguration]: "Public Virtual Machine",
                    [Contract.TypeNames.AzureInboundExternalComputeVirtualMachineScaleSetRiskPolicyConfiguration]: "Public Virtual Machine scale set",
                    [Contract.TypeNames.AzureInsightsDiagnosticSettingsStorageAccountBlobContainerDefaultEncryptionRiskPolicyConfiguration]: "Activity log Blob Container is not encrypted with CMK",
                    [Contract.TypeNames.AzureInsightsDiagnosticSettingsStorageAccountBlobContainerPublicAccessEnabledRiskPolicyConfiguration]: "Public activity log Blob Container",
                    [Contract.TypeNames.AzureInsightsDiagnosticSettingsTenantLogCategoryAdministrativeNotExistsRiskPolicyConfiguration]: "Missing Activity log diagnostic settings",
                    [Contract.TypeNames.AzureKeyVaultVaultCertificateNotRotatedRiskPolicyConfiguration]: "Key Vault certificate is not rotated",
                    [Contract.TypeNames.AzureKeyVaultVaultEventDisabledRiskPolicyConfiguration]: "Key Vault logging is not enabled",
                    [Contract.TypeNames.AzureKeyVaultVaultKeyExpirationDisabledRiskPolicyConfiguration]: "Key Vault key expiration is not set",
                    [Contract.TypeNames.AzureKeyVaultVaultKeyNotRotatedRiskPolicyConfiguration]: "Key Vault key is not rotated",
                    [Contract.TypeNames.AzureKeyVaultVaultKeyRotationDisabledRiskPolicyConfiguration]: "Key vault key automatic rotation is not enabled",
                    [Contract.TypeNames.AzureKeyVaultVaultPrivateEndpointNotExistsRiskPolicyConfiguration]: "Key vault is not associated with private endpoint",
                    [Contract.TypeNames.AzureKeyVaultVaultRbacDisabledRiskPolicyConfiguration]: "Key Vault role-based access control is not enabled",
                    [Contract.TypeNames.AzureKeyVaultVaultSecretExpirationDisabledRiskPolicyConfiguration]: "Key Vault secret expiration is not set",
                    [Contract.TypeNames.AzureKeyVaultVaultSoftDeleteDisabledRiskPolicyConfiguration]: "Key Vault delete protection is not enabled",
                    [Contract.TypeNames.AzureKeyVaultVaultWideRangeInboundRiskPolicyConfiguration]: "Key Vault firewall wide inbound internet access",
                    [Contract.TypeNames.AzureLogicWorkflowInboundExternalWideRangeRiskPolicyConfiguration]: "Logic App wide inbound internet access",
                    [Contract.TypeNames.AzureLogicWorkflowSecretExistsRiskPolicyConfiguration]: "Logic App is exposing secrets",
                    [Contract.TypeNames.AzureMachineLearningWorkspaceDiagnosticSettingsLogCategoryNotEnabledRiskPolicyConfiguration]: "Machine Learning Workspace logging is not enabled",
                    [Contract.TypeNames.AzureMachineLearningWorkspaceInboundExternalWideRangeRiskPolicyConfiguration]: "Machine Learning Workspace wide inbound internet access",
                    [Contract.TypeNames.AzureManagedIdentityManagedIdentityInactiveRiskPolicyConfiguration]: "Inactive Managed Identity",
                    [Contract.TypeNames.AzureManagedIdentityNotAllowedResourcePermissionRiskPolicyConfiguration]: "These managed identities should not have these permissions on these resources",
                    [Contract.TypeNames.AzureMySqlFlexibleServerAadOnlyAuthenticationDisabledRiskPolicyConfiguration]: "MySQL Database Server Microsoft Entra ID-only authentication is not enabled",
                    [Contract.TypeNames.AzureMySqlFlexibleServerAuditLogConnectionEventDisabledRiskPolicyConfiguration]: "MySQL Database Server parameter audit_log_events does not meet minimum requirements",
                    [Contract.TypeNames.AzureMySqlFlexibleServerAuditLogDisabledRiskPolicyConfiguration]: "MySQL Database Server parameter audit_log_enabled is not enabled",
                    [Contract.TypeNames.AzureMySqlFlexibleServerInsecureTlsProtocolRiskPolicyConfiguration]: "MySQL Database Server parameter tls_version does not meet minimum requirements",
                    [Contract.TypeNames.AzureMySqlServerHttpsOnlyDisabledRiskPolicyConfiguration]: "Unencrypted MySQL Database Server traffic",
                    [Contract.TypeNames.AzureMySqlServerWideRangeInboundRiskPolicyConfiguration]: "MySQL Database Server wide inbound internet access",
                    [Contract.TypeNames.AzureNetworkAccessManagementRiskPolicyConfiguration]: "Unusual Network Access Management",
                    [Contract.TypeNames.AzureNetworkNetworkSecurityGroupInboundRuleInsecureServiceTagRiskPolicyConfiguration]: "Network Security Group insecure inbound internet access via service tag",
                    [Contract.TypeNames.AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsRiskPolicyConfiguration]: "Network Security Group unrestricted RDP inbound internet access",
                    [Contract.TypeNames.AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnySshExistsRiskPolicyConfiguration]: "Network Security Group unrestricted SSH inbound internet access",
                    [Contract.TypeNames.AzureNetworkNetworkSecurityGroupInboundRuleSubnetAnyUdpExistsRiskPolicyConfiguration]: "Network Security Group unrestricted UDP inbound internet access",
                    [Contract.TypeNames.AzureNetworkVirtualNetworkSubnetUnrestrictedInboundAccessRiskPolicyConfiguration]: "Subnet unrestricted inbound internet access",
                    [Contract.TypeNames.AzurePermissionManagementRiskPolicyConfiguration]: "Unusual Permission Management",
                    [Contract.TypeNames.AzurePostgreSqlFlexibleServerAadOnlyAuthenticationDisabledRiskPolicyConfiguration]: "PostgreSQL Database Server Microsoft Entra ID-only authentication is not enabled",
                    [Contract.TypeNames.AzurePostgreSqlServerConnectionThrottlingDisabledRiskPolicyConfiguration]: "PostgreSQL Database Server parameter connection throttling is not enabled",
                    [Contract.TypeNames.AzurePostgreSqlServerHttpsOnlyDisabledRiskPolicyConfiguration]: "Unencrypted PostgreSQL Database Server traffic",
                    [Contract.TypeNames.AzurePostgreSqlServerLogCheckpointsDisabledRiskPolicyConfiguration]: "PostgreSQL Database Server parameter log_checkpoints is not enabled",
                    [Contract.TypeNames.AzurePostgreSqlServerLogConnectionsDisabledRiskPolicyConfiguration]: "PostgreSQL Database Server parameter log_connections is not enabled",
                    [Contract.TypeNames.AzurePostgreSqlServerLogDisconnectionsDisabledRiskPolicyConfiguration]: "PostgreSQL Database Server parameter log_disconnections is not enabled",
                    [Contract.TypeNames.AzurePostgreSqlServerLogRetentionRiskPolicyConfiguration]: "PostgreSQL Database Server log_retention_days does not meet minimum requirements",
                    [Contract.TypeNames.AzurePostgreSqlServerWideRangeInboundRiskPolicyConfiguration]: "PostgreSQL Database Server wide inbound internet access",
                    [Contract.TypeNames.AzurePrincipalAllowedResourcePermissionRiskPolicyConfiguration]: "Only these principals should have these permissions on these resources",
                    [Contract.TypeNames.AzurePrincipalNotAllowedResourcePermissionRiskPolicyConfiguration]: "These principals should not have these permissions on these resources",
                    [Contract.TypeNames.AzurePrincipalNotAllowedResourceRoleDefinitionRiskPolicyConfiguration]: "These principals should not have these roles on these resources",
                    [Contract.TypeNames.AzureResourceCodeResourceNotExistsRiskPolicyConfiguration]: "These resources should be managed as code",
                    [Contract.TypeNames.AzureResourceInboundExternalPortRiskPolicyConfiguration]: "Resources should not allow inbound internet connection from these IP address ranges to these ports",
                    [Contract.TypeNames.AzureResourceTagNotExistsRiskPolicyConfiguration]: "Only these resources should have these tags",
                    [Contract.TypeNames.AzureResourceTagSecretExistsRiskPolicyConfiguration]: "Azure resource tags are exposing secrets",
                    [Contract.TypeNames.AzureSeverePermissionPrincipalRiskPolicyConfiguration]: "New privileged identity in subscription",
                    [Contract.TypeNames.AzureSqlServerAadAuthenticationAdministratorPrincipalNotExistsRiskPolicyConfiguration]: "SQL Server Microsoft Entra ID admin is not set",
                    [Contract.TypeNames.AzureSqlServerAadOnlyAuthenticationDisabledRiskPolicyConfiguration]: "SQL Server Microsoft Entra ID-only authentication is not enabled",
                    [Contract.TypeNames.AzureSqlServerDatabaseEncryptionDisabledRiskPolicyConfiguration]: "SQL Database is not encrypted with transparent data encryption",
                    [Contract.TypeNames.AzureSqlServerDefaultEncryptionRiskPolicyConfiguration]: "SQL Server transparent data encryption is not encrypted with CMK",
                    [Contract.TypeNames.AzureSqlServerEventDisabledRiskPolicyConfiguration]: "SQL Server auditing is not enabled",
                    [Contract.TypeNames.AzureSqlServerEventRetentionTimeFrameRiskPolicyConfiguration]: "SQL server audit retention for storage account is not set",
                    [Contract.TypeNames.AzureSqlServerSecurityAlertPolicyDisabledRiskPolicyConfiguration]: "SQL server Microsoft Defender is not enabled",
                    [Contract.TypeNames.AzureSqlServerWideRangeInboundRiskPolicyConfiguration]: "SQL Server wide inbound internet access",
                    [Contract.TypeNames.AzureStorageStorageAccountAllowAnyAzureTrustedServicesNetworkAccessDisabledRiskPolicyConfiguration]: "Storage Account Azure trusted services access is not enabled",
                    [Contract.TypeNames.AzureStorageStorageAccountBlobContainerPublicAccessExistsRiskPolicyConfiguration]: "Public Storage Account Blob Container",
                    [Contract.TypeNames.AzureStorageStorageAccountBlobServiceDiagnosticSettingsLogCategoryNotEnabledRiskPolicyConfiguration]: "Storage Account blob service logging is not enabled",
                    [Contract.TypeNames.AzureStorageStorageAccountDefaultEncryptionRiskPolicyConfiguration]: "Storage Account is not encrypted with CMK",
                    [Contract.TypeNames.AzureStorageStorageAccountHttpsOnlyDisabledRiskPolicyConfiguration]: "Unencrypted Storage Account traffic",
                    [Contract.TypeNames.AzureStorageStorageAccountInfrastructureEncryptionDisabledRiskPolicyConfiguration]: "Storage Account infrastructure encryption is not enabled",
                    [Contract.TypeNames.AzureStorageStorageAccountInsecureTlsProtocolRiskPolicyConfiguration]: "Storage Account insecure communication",
                    [Contract.TypeNames.AzureStorageStorageAccountPrivateEndpointNotExistsRiskPolicyConfiguration]: "Storage account is not associated with private endpoint",
                    [Contract.TypeNames.AzureStorageStorageAccountQueueServiceDiagnosticSettingsLogCategoryNotEnabledRiskPolicyConfiguration]: "Storage Account queue service logging is not enabled",
                    [Contract.TypeNames.AzureStorageStorageAccountSharedKeyAccessEnabledRiskPolicyConfiguration]: "Storage Account Shared Key access is enabled",
                    [Contract.TypeNames.AzureStorageStorageAccountSoftDeleteDisabledRiskPolicyConfiguration]: "Storage Account soft delete protection is not enabled",
                    [Contract.TypeNames.AzureStorageStorageAccountTableServiceDiagnosticSettingsLogCategoryNotEnabledRiskPolicyConfiguration]: "Storage Account table service logging is not enabled",
                    [Contract.TypeNames.AzureStorageStorageAccountWideRangeInboundRiskPolicyConfiguration]: "Storage Account wide inbound internet access",
                    [Contract.TypeNames.AzureTenantEntityCreationRiskPolicyConfiguration]: "New Azure subscription",
                    [Contract.TypeNames.AzureWebApplicationHttpsOnlyDisabledRiskPolicyConfiguration]: "Unencrypted App Service traffic",
                    [Contract.TypeNames.AzureWebApplicationInboundExternalWideRangeRiskPolicyConfiguration]: "Public App Service",
                    [Contract.TypeNames.AzureWebApplicationInsecureTlsProtocolRiskPolicyConfiguration]: "App Service insecure communication",
                    [Contract.TypeNames.AzureWebApplicationScmInboundExternalWideRangeRiskPolicyConfiguration]: "App Service SCM site wide inbound internet access",
                    [Contract.TypeNames.CodeTerraformDnsRecordSetExistsRiskPolicyConfiguration]: "DNS data block in Terraform configuration",
                    [Contract.TypeNames.CodeTerraformExternalExistsRiskPolicyConfiguration]: "External data block in Terraform configuration",
                    [Contract.TypeNames.ContainerImageMaliciousFileRiskPolicyConfiguration]: "Container Image has a suspected malicious file",
                    [Contract.TypeNames.ContainerImageOperatingSystemEndOfLifeRiskPolicyConfiguration]: "Container Image has an operating system which is at or nearing End-of-Life",
                    [Contract.TypeNames.ContainerImageOperatingSystemUnpatchedRiskPolicyConfiguration]: "Container Image has an unpatched operating system",
                    [Contract.TypeNames.ContainerImageRepositoryVulnerabilityRiskPolicyConfiguration]: "Container Image Repository has vulnerabilities that should be addressed",
                    [Contract.TypeNames.ContainerImageVulnerabilityRiskPolicyConfiguration]: "Container Image has vulnerabilities that should be addressed",
                    [Contract.TypeNames.GciDirectoryGroupInactiveRiskPolicyConfiguration]: "Inactive Google Workspace Group",
                    [Contract.TypeNames.GciDirectoryUserInactiveRiskPolicyConfiguration]: "Inactive Google Workspace User",
                    [Contract.TypeNames.GcpApiKeysKeyClientRestrictionNotExistsRiskPolicyConfiguration]: "Project API key application restriction is not set",
                    [Contract.TypeNames.GcpApiKeysKeyExistsRiskPolicyConfiguration]: "Project has API keys",
                    [Contract.TypeNames.GcpApiKeysKeyNotRotatedRiskPolicyConfiguration]: "Project API key is not rotated",
                    [Contract.TypeNames.GcpApiKeysKeyServiceRestrictionNotExistsRiskPolicyConfiguration]: "Project API Key API restriction is not set",
                    [Contract.TypeNames.GcpAppEngineApplicationServiceEnvironmentVariableSecretExistsRiskPolicyConfiguration]: "App Engine Service is exposing secrets",
                    [Contract.TypeNames.GcpAppEngineApplicationServiceUnencryptedTransportExistsRiskPolicyConfiguration]: "Unencrypted App Engine Service traffic",
                    [Contract.TypeNames.GcpArtifactRegistryPublicAccessExistsRiskPolicyConfiguration]: "Public Artifact Registry",
                    [Contract.TypeNames.GcpBigQueryDatasetEncryptionDisabledRiskPolicyConfiguration]: "BigQuery Dataset default encryption is not set with CMEK",
                    [Contract.TypeNames.GcpBigQueryDatasetPublicAccessExistsRiskPolicyConfiguration]: "Public BigQuery Dataset",
                    [Contract.TypeNames.GcpBigQueryDatasetTableEncryptionDisabledRiskPolicyConfiguration]: "BigQuery Dataset table is not encrypted with CMEK",
                    [Contract.TypeNames.GcpCloudBuildBuildTriggerDefaultServiceAccountRiskPolicyConfiguration]: "Cloud Build trigger is using the build default service account",
                    [Contract.TypeNames.GcpCloudRunServiceEnvironmentVariableSecretExistsRiskPolicyConfiguration]: "Cloud Run Service is exposing secrets",
                    [Contract.TypeNames.GcpCloudRunServicePublicAccessExistsRiskPolicyConfiguration]: "Public Cloud Run Service",
                    [Contract.TypeNames.GcpComputeBackendServiceLoggingNotEnabledRiskPolicyConfiguration]: "Load Balancer backend service logging is not enabled",
                    [Contract.TypeNames.GcpComputeImagePublicAccessExistsRiskPolicyConfiguration]: "Public Compute Engine Image",
                    [Contract.TypeNames.GcpComputeInstanceBlockProjectWideSshKeysNotEnabledRiskPolicyConfiguration]: "VM Instance \"Block project wide SSH keys\" is not enabled",
                    [Contract.TypeNames.GcpComputeInstanceDefaultServiceAccountRiskPolicyConfiguration]: "VM Instance is using the default service account",
                    [Contract.TypeNames.GcpComputeInstanceIpForwardingEnabledRiskPolicyConfiguration]: "VM Instance IP forwarding is enabled",
                    [Contract.TypeNames.GcpComputeInstanceMemoryEncryptionNotEnabledRiskPolicyConfiguration]: "VM Instance Confidential Computing is not enabled",
                    [Contract.TypeNames.GcpComputeInstanceShieldedNotEnabledRiskPolicyConfiguration]: "VM Instance Shielded VM is not enabled",
                    [Contract.TypeNames.GcpComputeInstanceSshSerialPortEnabledRiskPolicyConfiguration]: "VM Instance serial port access is enabled",
                    [Contract.TypeNames.GcpComputeInstanceStartupScriptSecretExistsRiskPolicyConfiguration]: "VM Instance is exposing secrets",
                    [Contract.TypeNames.GcpComputeLoadBalancerInsecureSslPolicyRiskPolicyConfiguration]: "Load Balancer SSL policy insecure communication",
                    [Contract.TypeNames.GcpComputeProjectSshIamNotEnabledRiskPolicyConfiguration]: "Project OS Login is not enabled",
                    [Contract.TypeNames.GcpComputeVpcFlowLogsNotEnabledRiskPolicyConfiguration]: "VPC Flow Logs is not enabled",
                    [Contract.TypeNames.GcpComputeVpcLegacyVpcRiskPolicyConfiguration]: "Legacy VPC Network exists",
                    [Contract.TypeNames.GcpComputeVpcUnrestrictedIcmpInboundAccessRiskPolicyConfiguration]: "VPC Network unrestricted ICMP inbound internet access",
                    [Contract.TypeNames.GcpComputeVpcUnrestrictedRdpInboundAccessRiskPolicyConfiguration]: "VPC Network unrestricted RDP inbound internet access",
                    [Contract.TypeNames.GcpComputeVpcUnrestrictedSshInboundAccessRiskPolicyConfiguration]: "VPC Network unrestricted SSH inbound internet access",
                    [Contract.TypeNames.GcpContainerClusterAttributeBasedAuthorizationEnabledRiskPolicyConfiguration]: "GKE legacy authorization (ABAC) is enabled",
                    [Contract.TypeNames.GcpContainerClusterDefaultServiceAccountRiskPolicyConfiguration]: "GKE cluster is using the Compute Engine default service account",
                    [Contract.TypeNames.GcpContainerClusterInboundExternalWideRangeRiskPolicyConfiguration]: "GKE cluster Kubernetes API wide inbound internet access",
                    [Contract.TypeNames.GcpContainerClusterInstanceInboundExternalRiskPolicyConfiguration]: "GKE cluster contains nodes with public IPs",
                    [Contract.TypeNames.GcpContainerClusterKubernetesSecretEncryptionDisabledRiskPolicyConfiguration]: "Kubernetes secrets are not encrypted with CMEK",
                    [Contract.TypeNames.GcpContainerClusterLegacyComputeMetadataServerEnabledRiskPolicyConfiguration]: "GKE legacy Compute Engine Instance metadata APIs are enabled",
                    [Contract.TypeNames.GcpContainerClusterWorkloadIdentityDisabledRiskPolicyConfiguration]: "GKE metadata server is not enabled",
                    [Contract.TypeNames.GcpDnsManagedZoneInsecureDnsSecKeySigningKeyAlgorithmRiskPolicyConfiguration]: "DNS Managed Zone is using RSASHA1 for the key-signing key in DNSSEC",
                    [Contract.TypeNames.GcpDnsManagedZoneInsecureDnsSecZoneSigningKeyAlgorithmRiskPolicyConfiguration]: "DNS Managed Zone is using RSASHA1 for the zone-signing key in DNSSEC",
                    [Contract.TypeNames.GcpDnsManagedZoneDnsSecNotEnabledRiskPolicyConfiguration]: "DNS Managed Zone DNSSEC is not enabled",
                    [Contract.TypeNames.GcpEntityInboundExternalPortRiskPolicyConfiguration]: "Resources should not allow inbound internet connection from these IP address ranges to these ports",
                    [Contract.TypeNames.GcpExcessivePermissionGroupRiskPolicyConfiguration]: "Overprivileged Google Workspace Group in project",
                    [Contract.TypeNames.GcpExcessivePermissionServiceAccountRiskPolicyConfiguration]: "Overprivileged Service Account in project",
                    [Contract.TypeNames.GcpExcessivePermissionUserRiskPolicyConfiguration]: "Overprivileged Google Workspace User in project",
                    [Contract.TypeNames.GcpFunctionsFunctionBuildDefaultServiceAccountRiskPolicyConfiguration]: "Cloud function is using the build default service account",
                    [Contract.TypeNames.GcpFunctionsFunctionEnvironmentVariableSecretExistsRiskPolicyConfiguration]: "Cloud Function is exposing secrets",
                    [Contract.TypeNames.GcpFunctionsFunctionPublicAccessExistsRiskPolicyConfiguration]: "Public Cloud Function",
                    [Contract.TypeNames.GcpIamServiceAccountInactiveRiskPolicyConfiguration]: "Inactive Service Account",
                    [Contract.TypeNames.GcpIamServiceAccountUnrotatedUserManagedKeyRiskPolicyConfiguration]: "Service Account user managed keys are not rotated",
                    [Contract.TypeNames.GcpIamServiceAccountUserManagedKeyExistsRiskPolicyConfiguration]: "Service Account has user managed keys",
                    [Contract.TypeNames.GcpIdentityActivityRiskPolicyConfiguration]: "Monitor any activity of identities",
                    [Contract.TypeNames.GcpInboundExternalComputeInstanceRiskPolicyConfiguration]: "Public VM Instance",
                    [Contract.TypeNames.GcpKmsKeyRingKeyPublicAccessExistsRiskPolicyConfiguration]: "Public Cloud KMS Key",
                    [Contract.TypeNames.GcpKmsKeyRingKeyRotationTimeFrameRiskPolicyConfiguration]: "Cloud KMS Key rotation is not set",
                    [Contract.TypeNames.GcpKmsKeyRingPublicAccessExistsRiskPolicyConfiguration]: "Public Cloud KMS Key ring",
                    [Contract.TypeNames.GcpLoggingAuditLogTenantDefaultNotEnabledRiskPolicyConfiguration]: "Audit logs are not enabled",
                    [Contract.TypeNames.GcpMonitoringAlertPolicyAuditConfigurationChangesNotExistRiskPolicyConfiguration]: "Missing project alarm - audit configuration changes",
                    [Contract.TypeNames.GcpMonitoringAlertPolicyCloudSqlInstanceConfigurationChangesNotExistRiskPolicyConfiguration]: "Missing project alarm - cloud SQL instance configuration changes",
                    [Contract.TypeNames.GcpMonitoringAlertPolicyComputeFirewallRuleChangesNotExistRiskPolicyConfiguration]: "Missing project alarm - VPC network firewall rule changes",
                    [Contract.TypeNames.GcpMonitoringAlertPolicyComputeRouteChangesNotExistRiskPolicyConfiguration]: "Missing project alarm - VPC network route changes",
                    [Contract.TypeNames.GcpMonitoringAlertPolicyComputeVpcChangesNotExistRiskPolicyConfiguration]: "Missing project alarm - VPC network changes",
                    [Contract.TypeNames.GcpMonitoringAlertPolicyIamPolicyChangesNotExistRiskPolicyConfiguration]: "Missing project alarm - cloud storage IAM permission changes",
                    [Contract.TypeNames.GcpMonitoringAlertPolicyIamRoleChangesNotExistRiskPolicyConfiguration]: "Missing project alarm - custom role changes",
                    [Contract.TypeNames.GcpMonitoringAlertPolicyProjectOwnershipChangesNotExistRiskPolicyConfiguration]: "Missing project alarm - project ownership assignments/changes",
                    [Contract.TypeNames.GcpNotebooksInstanceBootIntegrityValidationNotEnabledRiskPolicyConfiguration]: "Vertex AI Workbench instance integrity monitoring is not enabled",
                    [Contract.TypeNames.GcpNotebooksInstanceDefaultEncryptionRiskPolicyConfiguration]: "Vertex AI Workbench Notebook instance disk is not encrypted with CMK",
                    [Contract.TypeNames.GcpNotebooksInstanceRootAccessEnabledRiskPolicyConfiguration]: "Vertex AI Workbench instance root access is enabled",
                    [Contract.TypeNames.GcpNotebooksInstanceSecureBootNotEnabledRiskPolicyConfiguration]: "Vertex AI Workbench instance secure boot is not enabled",
                    [Contract.TypeNames.GcpNotebooksInstanceVirtualTpmNotEnabledRiskPolicyConfiguration]: "Vertex AI Workbench instance vTPM is not enabled",
                    [Contract.TypeNames.GcpPermissionManagementRiskPolicyConfiguration]: "Unusual Permission Management",
                    [Contract.TypeNames.GcpPrincipalAllowedResourcePermissionRiskPolicyConfiguration]: "Only these principals should have these permissions on these resources",
                    [Contract.TypeNames.GcpPrincipalNotAllowedResourcePermissionRiskPolicyConfiguration]: "These principals should not have these permissions on these resources",
                    [Contract.TypeNames.GcpPrincipalNotAllowedResourceRoleRiskPolicyConfiguration]: "These principals should not have these roles on these resources",
                    [Contract.TypeNames.GcpPrincipalServiceAccountWideScopeAdministratorRoleRiskPolicyConfiguration]: "Service Account is privileged in project",
                    [Contract.TypeNames.GcpPrincipalServiceAccountWideScopeImpersonateServiceAccountActionExistsRiskPolicyConfiguration]: "Service Account can impersonate all service accounts in project",
                    [Contract.TypeNames.GcpPrincipalUserWideScopeImpersonateServiceAccountActionExistsRiskPolicyConfiguration]: "User can impersonate all service accounts in project",
                    [Contract.TypeNames.GcpPubSubSubscriptionPublicAccessExistsRiskPolicyConfiguration]: "Public Pub/Sub Subscription",
                    [Contract.TypeNames.GcpPubSubTopicPublicAccessExistsRiskPolicyConfiguration]: "Public Pub/Sub Topic",
                    [Contract.TypeNames.GcpResourceCodeResourceNotExistsRiskPolicyConfiguration]: "These resources should be managed as code",
                    [Contract.TypeNames.GcpResourceTagNotExistsRiskPolicyConfiguration]: "Only these resources should have these tags",
                    [Contract.TypeNames.GcpResourceTagSecretExistsRiskPolicyConfiguration]: "GCP resource labels are exposing secrets",
                    [Contract.TypeNames.GcpSecretManagerSecretRotationTimeFrameRiskPolicyConfiguration]: "Secret Manager secret rotation is not set",
                    [Contract.TypeNames.GcpSeverePermissionPrincipalRiskPolicyConfiguration]: "New privileged identity in project",
                    [Contract.TypeNames.GcpSqlInstanceBackupDisabledRiskPolicyConfiguration]: "Cloud SQL Instance automated backup is not enabled",
                    [Contract.TypeNames.GcpSqlInstanceHttpsOnlyDisabledRiskPolicyConfiguration]: "Unencrypted Cloud SQL Instance traffic",
                    [Contract.TypeNames.GcpSqlInstanceMySqlListDatabasesPermissionRequiredDisabledRiskPolicyConfiguration]: "Cloud SQL MySQL Instance 'skip_show_database' flag is not enabled",
                    [Contract.TypeNames.GcpSqlInstanceMySqlLoadClientFileEnabledRiskPolicyConfiguration]: "Cloud SQL MySQL Instance 'local_infile' flag is enabled",
                    [Contract.TypeNames.GcpSqlInstancePostgreSqlAuditDisabledRiskPolicyConfiguration]: "Cloud SQL PostgreSQL Instance 'cloudsql.enable_pgaudit' flag is not enabled",
                    [Contract.TypeNames.GcpSqlInstancePostgreSqlLogConnectionsDisabledRiskPolicyConfiguration]: "Cloud SQL PostgreSQL Instance 'log_connections' flag is not enabled",
                    [Contract.TypeNames.GcpSqlInstancePostgreSqlLogConnectionsHostNameDisabledRiskPolicyConfiguration]: "Cloud SQL PostgreSQL Instance 'log_hostname' flag is not enabled",
                    [Contract.TypeNames.GcpSqlInstancePostgreSqlLogDisconnectionsDisabledRiskPolicyConfiguration]: "Cloud SQL PostgreSQL Instance 'log_disconnections' flag is not enabled",
                    [Contract.TypeNames.GcpSqlInstancePostgreSqlLogErrorVerbosityRiskPolicyConfiguration]: "Cloud SQL PostgreSQL Instance ‘log_error_verbosity’ flag does not meet minimum requirements",
                    [Contract.TypeNames.GcpSqlInstancePostgreSqlLogMinLevelRiskPolicyConfiguration]: "Cloud SQL PostgreSQL Instance ‘log_min_messages’ flag does not meet minimum requirements",
                    [Contract.TypeNames.GcpSqlInstancePostgreSqlMinDurationLogQueryEnabledRiskPolicyConfiguration]: "Cloud SQL PostgreSQL Instance 'log_min_duration_statement' flag is enabled",
                    [Contract.TypeNames.GcpSqlInstancePostgreSqlMinLogLevelLogQueryRiskPolicyConfiguration]: "Cloud SQL PostgreSQL Instance 'log_min_error_statement' flag does not meet minimum requirements",
                    [Contract.TypeNames.GcpSqlInstancePostgreSqlStatementLogDisabledRiskPolicyConfiguration]: "Cloud SQL PostgreSQL Instance ‘log_statement’ flag does not meet minimum requirements",
                    [Contract.TypeNames.GcpSqlInstanceSqlServerContainedDatabaseAuthenticationEnabledRiskPolicyConfiguration]: "Cloud SQL Instance 'contained database authentication' flag is enabled",
                    [Contract.TypeNames.GcpSqlInstanceSqlServerCrossDatabaseImplicitPermissionsEnabledRiskPolicyConfiguration]: "Cloud SQL Instance 'cross db ownership chaining' flag is enabled",
                    [Contract.TypeNames.GcpSqlInstanceSqlServerCrossInstanceProcedureExecutionEnabledRiskPolicyConfiguration]: "Cloud SQL Instance 'remote access' flag is enabled",
                    [Contract.TypeNames.GcpSqlInstanceSqlServerLogMaskParametersDisabledRiskPolicyConfiguration]: "Cloud SQL Instance '3625 (trace flag)' flag is not enabled",
                    [Contract.TypeNames.GcpSqlInstanceSqlServerScriptExecutionEnabledRiskPolicyConfiguration]: "Cloud SQL Instance 'external scripts enabled' flag is enabled",
                    [Contract.TypeNames.GcpSqlInstanceSqlServerUserMaxConnectionsExistsRiskPolicyConfiguration]: "Cloud SQL Instance 'user connections' flag does not meet minimum requirements",
                    [Contract.TypeNames.GcpSqlInstanceSqlServerUserOptionsEnabledRiskPolicyConfiguration]: "Cloud SQL Instance 'user options' flag is enabled",
                    [Contract.TypeNames.GcpSqlInstanceWideRangeInboundRiskPolicyConfiguration]: "Cloud SQL Instance wide inbound internet access",
                    [Contract.TypeNames.GcpStorageBucketLockedRetentionPolicyNotEnabledRiskPolicyConfiguration]: "Cloud Storage Bucket lock policy is not set",
                    [Contract.TypeNames.GcpStorageBucketPublicAccessExistsRiskPolicyConfiguration]: "Public Cloud Storage Bucket",
                    [Contract.TypeNames.GcpStorageBucketUniformAccessControlDisabledRiskPolicyConfiguration]: "Cloud Storage Bucket uniform access policy is not enabled",
                    [Contract.TypeNames.GcpTenantEntityCloudAssetServiceNotEnabledRiskPolicyConfiguration]: "Project Cloud Asset Inventory is not enabled",
                    [Contract.TypeNames.GcpTenantEntityCreationRiskPolicyConfiguration]: "New GCP project",
                    [Contract.TypeNames.GcpTenantEntityUnusedRiskPolicyConfiguration]: "Unused GCP project",
                    [Contract.TypeNames.KubernetesAdmissionControllerRequiredLabelsRiskPolicyConfiguration]: "General - Required labels",
                    [Contract.TypeNames.KubernetesAdmissionControllerWorkloadResourceContainerCapabilityRiskPolicyConfiguration]: "Pod Security Standards - Containers with disallowed capabilities",
                    [Contract.TypeNames.KubernetesAdmissionControllerWorkloadResourceContainerImageAllowedRepositoriesRiskPolicyConfiguration]: "General - Allowed repositories",
                    [Contract.TypeNames.KubernetesAdmissionControllerWorkloadResourceContainerImageDigestNotExistsRiskPolicyConfiguration]: "General - Container images require digest",
                    [Contract.TypeNames.KubernetesAdmissionControllerWorkloadResourceContainerImageNotAllowedRepositoriesRiskPolicyConfiguration]: "General - Disallowed repositories",
                    [Contract.TypeNames.KubernetesAdmissionControllerWorkloadResourceContainerPrivilegeEscalationRiskPolicyConfiguration]: "Pod Security Standards - Containers with allowPrivilegeEscalation",
                    [Contract.TypeNames.KubernetesAdmissionControllerWorkloadResourceContainerPrivilegedRiskPolicyConfiguration]: "Pod Security Standards - Privileged containers",
                    [Contract.TypeNames.KubernetesAdmissionControllerWorkloadResourceContainerRootUserEnabledRiskPolicyConfiguration]: "Pod Security Standards - Root containers",
                    [Contract.TypeNames.KubernetesAdmissionControllerWorkloadResourceHostNamespaceInterProcessCommunicationEnabledRiskPolicyConfiguration]: "Pod Security Standards - Containers wishing to share the host IPC namespace",
                    [Contract.TypeNames.KubernetesAdmissionControllerWorkloadResourceHostNamespaceNetworkEnabledRiskPolicyConfiguration]: "Pod Security Standards - Containers wishing to share the host network namespace",
                    [Contract.TypeNames.KubernetesAdmissionControllerWorkloadResourceHostNamespaceProcessIdEnabledRiskPolicyConfiguration]: "Pod Security Standards - Containers sharing the host process ID namespace",
                    [Contract.TypeNames.KubernetesClusterApiServerAlwaysAdmitAdmissionControllerPluginEnabledRiskPolicyConfiguration]: "Kubernetes cluster AlwaysAdmit admission control plugin is enabled",
                    [Contract.TypeNames.KubernetesClusterApiServerAuthorizationModeAlwaysAllowRiskPolicyConfiguration]: "Kubernetes cluster allows unrestricted authorization mode (AlwaysAllow)",
                    [Contract.TypeNames.KubernetesClusterApiServerBasicAuthenticationEnabledRiskPolicyConfiguration]: "Kubernetes cluster API server uses --basic-auth-file argument for authentication",
                    [Contract.TypeNames.KubernetesClusterApiServerClientCertificateAuthenticationDisabledRiskPolicyConfiguration]: "Kubernetes cluster API server client CA file is not set",
                    [Contract.TypeNames.KubernetesClusterApiServerEtcdCertificateAuthorityCertificateNotExistRiskPolicyConfiguration]: "Kubernetes cluster etcd communication CA file is not set",
                    [Contract.TypeNames.KubernetesClusterApiServerEtcdClientCertificateAuthenticationDisabledRiskPolicyConfiguration]: "Kubernetes cluster API server is not authenticating to etcd with certificates",
                    [Contract.TypeNames.KubernetesClusterApiServerInsecureListenerEnabledRiskPolicyConfiguration]: "Kubernetes cluster API server is configured with insecure settings",
                    [Contract.TypeNames.KubernetesClusterApiServerInsecureTlsProtocolRiskPolicyConfiguration]: "Kubernetes cluster API server allows weak cryptographic ciphers",
                    [Contract.TypeNames.KubernetesClusterApiServerKubeletCertificateValidationDisabledRiskPolicyConfiguration]: "Kubernetes cluster API server does not verify Kubelet certificate authority",
                    [Contract.TypeNames.KubernetesClusterApiServerKubeletClientCertificateAuthenticationDisabledRiskPolicyConfiguration]: "Kubernetes cluster API server uses insecure or unauthenticated connections to Kubelets",
                    [Contract.TypeNames.KubernetesClusterApiServerNamespaceLifecycleAdmissionControllerPluginDisabledRiskPolicyConfiguration]: "Kubernetes cluster NamespaceLifecycle admission control plugin is not enabled",
                    [Contract.TypeNames.KubernetesClusterApiServerNodeRestrictionAdmissionControllerPluginDisabledRiskPolicyConfiguration]: "Kubernetes cluster NodeRestriction admission control plugin is not enabled",
                    [Contract.TypeNames.KubernetesClusterApiServerRoleBasedAccessControlAuthorizationModeDisabledRiskPolicyConfiguration]: "Kubernetes cluster RBAC authorization is not enabled",
                    [Contract.TypeNames.KubernetesClusterApiServerSecurityContextConstraintAdmissionControllerPluginDisabledRiskPolicyConfiguration]: "Kubernetes cluster SecurityContextConstraint admission control plugin is not enabled",
                    [Contract.TypeNames.KubernetesClusterApiServerServiceAccountAdmissionControllerPluginDisabledRiskPolicyConfiguration]: "Kubernetes cluster ServiceAccount admission control plugin is not enabled",
                    [Contract.TypeNames.KubernetesClusterApiServerServiceAccountLookupDisabledRiskPolicyConfiguration]: "Kubernetes cluster API server service account lookup argument is not enabled",
                    [Contract.TypeNames.KubernetesClusterApiServerServiceAccountTokenKeyNotExistRiskPolicyConfiguration]: "Kubernetes cluster API server service account key file is not set",
                    [Contract.TypeNames.KubernetesClusterApiServerStaticTokenAuthenticationEnabledRiskPolicyConfiguration]: "Kubernetes cluster API server uses static token authentication",
                    [Contract.TypeNames.KubernetesClusterApiServerTlsCertificateNotExistRiskPolicyConfiguration]: "Kubernetes cluster API server TLS certificate and private key are not set",
                    [Contract.TypeNames.KubernetesClusterAuthenticationPrincipalRoleBindingExistsRiskPolicyConfiguration]: "Overprivileged publicly accessible group or user in Kubernetes cluster",
                    [Contract.TypeNames.KubernetesClusterClusterAdminRoleBindingExistsRiskPolicyConfiguration]: "Kubernetes cluster contains role bindings assigning the cluster-admin role",
                    [Contract.TypeNames.KubernetesClusterControllerManagerInsecureListenerIpAddressRiskPolicyConfiguration]: "Kubernetes cluster Controller Manager allows unrestricted communication",
                    [Contract.TypeNames.KubernetesClusterControllerManagerServiceAccountCredentialsDisabledRiskPolicyConfiguration]: "Kubernetes cluster Controller Manager --use-service-account-credentials is not set",
                    [Contract.TypeNames.KubernetesClusterControllerManagerServiceAccountTokenKeyNotExistRiskPolicyConfiguration]: "Kubernetes cluster Controller Manager private key file for service accounts is not set",
                    [Contract.TypeNames.KubernetesClusterControllerManagerServiceAccountTokenSecretCertificateAuthorityCertificateNotExistRiskPolicyConfiguration]: "Kubernetes cluster Controller Manager --root-ca-file is not set",
                    [Contract.TypeNames.KubernetesClusterDefaultNamespaceUsedRiskPolicyConfiguration]: "Kubernetes cluster is using the default namespace",
                    [Contract.TypeNames.KubernetesClusterEndOfLifeRiskPolicyConfiguration]: "Kubernetes cluster version has reached End-of-Life",
                    [Contract.TypeNames.KubernetesClusterEtcdClientCertificateAuthenticationDisabledRiskPolicyConfiguration]: "Kubernetes cluster etcd client certificate authentication is not enabled",
                    [Contract.TypeNames.KubernetesClusterEtcdPeerClientCertificateAuthenticationDisabledRiskPolicyConfiguration]: "Kubernetes cluster etcd peer-client certificate authentication is disabled",
                    [Contract.TypeNames.KubernetesClusterEtcdPeerSelfSignedTlsCertificateGenerationEnabledRiskPolicyConfiguration]: "Kubernetes cluster etcd peer auto TLS is enabled",
                    [Contract.TypeNames.KubernetesClusterEtcdPeerTlsCertificateNotExistRiskPolicyConfiguration]: "Kubernetes cluster etcd peer certificate files are not set",
                    [Contract.TypeNames.KubernetesClusterEtcdSelfSignedTlsCertificateGenerationEnabledRiskPolicyConfiguration]: "Kubernetes cluster etcd auto TLS is enabled",
                    [Contract.TypeNames.KubernetesClusterEtcdTlsCertificateNotExistRiskPolicyConfiguration]: "Kubernetes cluster etcd certificate files not set",
                    [Contract.TypeNames.KubernetesClusterIdentityProviderAuthenticationDisabledRiskPolicyConfiguration]: "OpenShift cluster OAuth not configured with an identity provider",
                    [Contract.TypeNames.KubernetesClusterKubeletAnonymousAuthenticationEnabledRiskPolicyConfiguration]: "Kubernetes cluster allows anonymous requests to Kubelet servers",
                    [Contract.TypeNames.KubernetesClusterKubeletAuthorizationModeAlwaysAllowRiskPolicyConfiguration]: "Kubernetes cluster allows all authenticated requests sent to Kubelet servers",
                    [Contract.TypeNames.KubernetesClusterKubeletClientCertificateAuthenticationDisabledRiskPolicyConfiguration]: "Kubernetes cluster Kubelet certificate authentication is not configured",
                    [Contract.TypeNames.KubernetesClusterKubeletClientCertificateRotationDisabledRiskPolicyConfiguration]: "Kubernetes Cluster Kubelet client certificate rotation is not enabled",
                    [Contract.TypeNames.KubernetesClusterKubeletHostnameOverrideUsedRiskPolicyConfiguration]: "Kubernetes cluster Kubelet is overriding node hostnames",
                    [Contract.TypeNames.KubernetesClusterKubeletInsecureTlsProtocolRiskPolicyConfiguration]: "Kubernetes cluster Kubelet allows weak cryptographic ciphers",
                    [Contract.TypeNames.KubernetesClusterKubeletInsufficientEventsPerSecondMaxCountRiskPolicyConfiguration]: "Kubernetes cluster Kubelet does not have appropriate event capture level",
                    [Contract.TypeNames.KubernetesClusterKubeletManageIpTablesDisabledRiskPolicyConfiguration]: "Kubernetes cluster Kubelet ability to manage iptables is not enabled",
                    [Contract.TypeNames.KubernetesClusterKubeletReadOnlyPortEnabledRiskPolicyConfiguration]: "Kubernetes cluster Kubelet read-only port is enabled",
                    [Contract.TypeNames.KubernetesClusterKubeletServerCertificateRotationDisabledRiskPolicyConfiguration]: "Kubernetes cluster Kubelet server certificate rotation is not enabled",
                    [Contract.TypeNames.KubernetesClusterKubeletStreamingConnectionIdleTimeoutDisabledRiskPolicyConfiguration]: "Kubernetes cluster Kubelet is missing timeouts on streaming connections",
                    [Contract.TypeNames.KubernetesClusterKubeletTlsCertificateNotExistRiskPolicyConfiguration]: "Kubernetes cluster Kubelet TLS certificate and private key are not set",
                    [Contract.TypeNames.KubernetesClusterNetworkPolicyUnsupportedRiskPolicyConfiguration]: "Kubernetes cluster CNI does not support Network Policies",
                    [Contract.TypeNames.KubernetesClusterNodeUnrestrictedAccessFileRiskPolicyConfiguration]: "Kubernetes Cluster worker node configuration files are not restrictive",
                    [Contract.TypeNames.KubernetesClusterPlatformEndOfLifeRiskPolicyConfiguration]: "OpenShift cluster version has reached End-of-Life",
                    [Contract.TypeNames.KubernetesClusterResourceEncryptionDisabledRiskPolicyConfiguration]: "Kubernetes cluster encryption providers are misconfigured",
                    [Contract.TypeNames.KubernetesClusterSchedulerRoleBasedAccessAuthorizationDisabledRiskPolicyConfiguration]: "Kubernetes cluster scheduler healthz endpoint is not protected by RBAC",
                    [Contract.TypeNames.KubernetesNamespaceDefaultServiceAccountRoleBindingExistsRiskPolicyConfiguration]: "Kubernetes namespace default service account is actively used",
                    [Contract.TypeNames.KubernetesNamespaceRestrictedPodSecurityStandardNotExistRiskPolicyConfiguration]: "Kubernetes namespace does not have a Restricted policy configured",
                    [Contract.TypeNames.KubernetesNamespaceWorkloadResourceContainerCapabilityRiskPolicyConfiguration]: "Kubernetes namespace has containers with capabilities assigned",
                    [Contract.TypeNames.KubernetesNamespaceWorkloadResourceContainerPrivilegeEscalationRiskPolicyConfiguration]: "Kubernetes namespace has containers allowing privilege escalation",
                    [Contract.TypeNames.KubernetesNamespaceWorkloadResourceContainerPrivilegedRiskPolicyConfiguration]: "Kubernetes namespace has containers running as privileged",
                    [Contract.TypeNames.KubernetesNamespaceWorkloadResourceContainerRootUserEnabledRiskPolicyConfiguration]: "Kubernetes namespace has containers running as the root user",
                    [Contract.TypeNames.KubernetesNamespaceWorkloadResourceHostNamespaceInterProcessCommunicationEnabledRiskPolicyConfiguration]: "Kubernetes namespace has containers sharing the host IPC namespace",
                    [Contract.TypeNames.KubernetesNamespaceWorkloadResourceHostNamespaceNetworkEnabledRiskPolicyConfiguration]: "Kubernetes namespace has containers sharing the host network namespace",
                    [Contract.TypeNames.KubernetesNamespaceWorkloadResourceHostNamespaceProcessIdEnabledRiskPolicyConfiguration]: "Kubernetes namespace has containers sharing the host process ID namespace",
                    [Contract.TypeNames.KubernetesWorkloadResourceContainerPrivilegedRiskPolicyConfiguration]: "Kubernetes has containers running as privileged",
                    [Contract.TypeNames.KubernetesWorkloadResourceEnvironmentVariableSecretReferenceRiskPolicyConfiguration]: "Kubernetes secrets are used as environment variables",
                    [Contract.TypeNames.OciBlockStorageBlockVolumeDefaultEncryptionRiskPolicyConfiguration]: "Block Volume is not encrypted with CMK",
                    [Contract.TypeNames.OciBlockStorageBootVolumeDefaultEncryptionRiskPolicyConfiguration]: "Boot Volume is not encrypted with CMK",
                    [Contract.TypeNames.OciComputeInstanceMetadataServiceVersionRiskPolicyConfiguration]: "Compute Instance metadata service supports insecure version",
                    [Contract.TypeNames.OciComputeInstanceSecureBootNotEnabledRiskPolicyConfiguration]: "Compute Instance secure boot is not enabled",
                    [Contract.TypeNames.OciComputeInstanceVolumeEncryptionInTransitDisabledRiskPolicyConfiguration]: "Unencrypted Compute Instance volume traffic",
                    [Contract.TypeNames.OciFssFileSystemDefaultEncryptionRiskPolicyConfiguration]: "File System is not encrypted with CMK",
                    [Contract.TypeNames.OciFssFileSystemMountTargetExportOptionIdentitySquashRootNotEnabledRiskPolicyConfiguration]: "File System root user access is not restricted",
                    [Contract.TypeNames.OciIamDomainPasswordExpirationPolicyRiskPolicyConfiguration]: "Password expiration policy does not meet minimum requirements",
                    [Contract.TypeNames.OciIamDomainPasswordLengthPolicyRiskPolicyConfiguration]: "Password length policy does not meet minimum requirements",
                    [Contract.TypeNames.OciIamDomainPasswordReusePolicyRiskPolicyConfiguration]: "Password reuse policy does not meet minimum requirements",
                    [Contract.TypeNames.OciIamUserApiKeyNotRotatedRiskPolicyConfiguration]: "User API key is not rotated",
                    [Contract.TypeNames.OciIamUserAuthTokenNotRotatedRiskPolicyConfiguration]: "User auth token is not rotated",
                    [Contract.TypeNames.OciIamUserDatabasePasswordNotRotatedRiskPolicyConfiguration]: "User database password is not rotated",
                    [Contract.TypeNames.OciIamUserOrganizationAdministratorApiKeyExistsRiskPolicyConfiguration]: "Admin user has API keys",
                    [Contract.TypeNames.OciIamUserSecretKeyNotRotatedRiskPolicyConfiguration]: "User customer secret key is not rotated",
                    [Contract.TypeNames.OciKmsVaultKeyNotRotatedRiskPolicyConfiguration]: "Vault master encryption key is not rotated",
                    [Contract.TypeNames.OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnyRdpExistsRiskPolicyConfiguration]: "Network Security Group unrestricted RDP inbound internet access",
                    [Contract.TypeNames.OciNetworkingNetworkSecurityGroupInboundRuleSubnetAnySshExistsRiskPolicyConfiguration]: "Network Security Group unrestricted SSH inbound internet access",
                    [Contract.TypeNames.OciNetworkingSecurityListInboundRuleSubnetAnyRdpExistsRiskPolicyConfiguration]: "Security List unrestricted RDP inbound internet access",
                    [Contract.TypeNames.OciNetworkingSecurityListInboundRuleSubnetAnySshExistsRiskPolicyConfiguration]: "Security List unrestricted SSH inbound internet access",
                    [Contract.TypeNames.OciNetworkingVcnFlowLogsNotEnabledRiskPolicyConfiguration]: "VCN Flow Logs is not enabled",
                    [Contract.TypeNames.OciObjectStorageBucketDefaultEncryptionRiskPolicyConfiguration]: "Object Storage Bucket is not encrypted with CMK",
                    [Contract.TypeNames.OciObjectStorageBucketLogCategoryWriteNotExistsRiskPolicyConfiguration]: "Object Storage Bucket object logging is not enabled",
                    [Contract.TypeNames.OciObjectStorageBucketObjectEventsDisabledRiskPolicyConfiguration]: "Object Storage Bucket Emit Object Events is not enabled",
                    [Contract.TypeNames.OciObjectStorageBucketObjectVersioningDisabledRiskPolicyConfiguration]: "Object Storage Bucket object versioning is not enabled",
                    [Contract.TypeNames.OciObjectStorageBucketPublicAccessExistsRiskPolicyConfiguration]: "Public Object Storage Bucket",
                    [Contract.TypeNames.VirtualMachineImageOperatingSystemEndOfLifeRiskPolicyConfiguration]: "Virtual Machine Image has an operating system which is at or nearing End-of-Life",
                    [Contract.TypeNames.VirtualMachineImageOperatingSystemUnpatchedRiskPolicyConfiguration]: "Virtual Machine Image has an unpatched operating system",
                    [Contract.TypeNames.VirtualMachineImageVulnerabilityRiskPolicyConfiguration]: "Virtual Machine Image has vulnerabilities that should be addressed",
                    [Contract.TypeNames.VirtualMachineMaliciousFileRiskPolicyConfiguration]: "Virtual Machine has a suspected malicious file",
                    [Contract.TypeNames.VirtualMachineOperatingSystemEndOfLifeRiskPolicyConfiguration]: "Virtual Machine has an operating system which is at or nearing End-of-Life",
                    [Contract.TypeNames.VirtualMachineOperatingSystemUnpatchedRiskPolicyConfiguration]: "Virtual Machine has an unpatched operating system",
                    [Contract.TypeNames.VirtualMachineVulnerabilityRiskPolicyConfiguration]: "Virtual Machine has vulnerabilities that should be addressed"
                },
                deprecatedBuiltInRiskPolicyConfigurations: {
                    AwsInactiveRoleRiskPolicyConfiguration: "Inactive IAM Role (deprecated)",
                    AwsInactiveUserRiskPolicyConfiguration: "Inactive IAM User (deprecated)",
                    AwsUnusedPermissionSetRiskPolicyConfiguration: "Unused SSO Permission Set (deprecated)",
                    AzureInactiveApplicationServicePrincipalRiskPolicyConfiguration: "Inactive Microsoft Entra ID Application in subscription (deprecated)",
                    AzureInactiveManagedIdentityServicePrincipalRiskPolicyConfiguration: "Inactive Managed Identity in subscription (deprecated)",
                    AzureInactiveUserRiskPolicyConfiguration: "Inactive Microsoft Entra ID User in subscription (deprecated)",
                    GcpInactiveServiceAccountRiskPolicyConfiguration: "Inactive Service Account in project (deprecated)",
                    GcpInactiveUserRiskPolicyConfiguration: "Inactive User in project (deprecated)"
                }
            }));

    return (builtInRiskPolicyConfigurationTypeNameOrCustomRiskPolicyId: string) => {
        const customRiskPolicyModel = customRiskPolicyModelMap[builtInRiskPolicyConfigurationTypeNameOrCustomRiskPolicyId];
        if (_.isNil(customRiskPolicyModel)) {
            return builtInRiskPolicyConfigurationTypeNameOrCustomRiskPolicyId in localization.deprecatedBuiltInRiskPolicyConfigurations
                ? localization.deprecatedBuiltInRiskPolicyConfigurations.translate(builtInRiskPolicyConfigurationTypeNameOrCustomRiskPolicyId)
                : localization[Contract.TypeNames.RiskPolicyConfiguration].translate(builtInRiskPolicyConfigurationTypeNameOrCustomRiskPolicyId);
        } else {
            if (TypeHelper.extendOrImplement(customRiskPolicyModel.riskPolicyConfiguration.typeName, Contract.TypeNames.CustomRiskPolicyConfiguration)) {
                return (customRiskPolicyModel.riskPolicyConfiguration as Contract.CustomRiskPolicyConfiguration).name;
            } else {
                throw new UnexpectedError("builtInRiskPolicyConfigurationTypeNameOrCustomRiskPolicyId", builtInRiskPolicyConfigurationTypeNameOrCustomRiskPolicyId);
            }
        }
    };
}