import { ActionMenuItem, MailIcon, MailParser, SeparatorMenuItem, SubMenuItem, useLocalization } from "@infrastructure";
import _, { Dictionary } from "lodash";
import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { CloseDialog, InsertRiskNoteDialog, OpenDialog, SendRisksMailDialog, SendRisksWebhookDialog } from "../../../../";
import { CommentIcon, Contract, JiraAvatar, JiraIcon, OpenIcon, RiskStatusIcon, RiskType, ScopeHelper, scopeSystemEntityModelStore, ServiceNowAvatar, ServiceNowIcon, ticketingServiceTicketStore, UpsertJiraIssueDialog, UpsertServiceNowIncidentDialog, useJiraIssueTranslator, UserHelper, useServiceNowIncidentTranslator, WebhookIcon } from "../../../../../../../../../common";
import { ActionDialogType, useActionsContext } from "../../../Actions";

export function useCommonItems(riskModel: Contract.RiskModel, riskType: RiskType) {
    const { onCloseDialog, reloadRiskModel, setDialogType, triggerAuditEventsChange } = useActionsContext();
    const jiraInstanceConfigurations = scopeSystemEntityModelStore.useGetJira();
    const jiraIssues = ticketingServiceTicketStore.useGet(riskModel.riskState?.integration.jira.issueIds) as Contract.JiraIssue[];
    const serviceNowIncidents = ticketingServiceTicketStore.useGet(riskModel.riskState?.integration.serviceNow.incidentIds) as Contract.ServiceNowIncident[];
    const serviceNowInstanceConfigurations = scopeSystemEntityModelStore.useGetServiceNow();
    const [riskIdToTicketIdsMap, setRiskIdToTicketIdsMap] = useState<Dictionary<string[]>>();
    const [webhookEndpointConfiguration, setWebhookEndpointConfiguration] = useState<Contract.WebhookEndpointConfiguration>();

    const jiraIssueTranslator = useJiraIssueTranslator();
    const serviceNowIncidentTranslator = useServiceNowIncidentTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.actions.hooks.useDefinition.hooks.useCommonItems",
            () => ({
                actions: {
                    insertRiskNote: "Comment",
                    jiraIssue: {
                        create: "Create a Jira issue",
                        selectUpdate: "Select Jira issue to update",
                        update: "Update Jira issue..."
                    },
                    sendRisksMail: "Send via mail",
                    sendRisksMailPerOwner: "Mail per resource owner",
                    sendRiskWebhook: {
                        many: "Send to webhook",
                        single: "Send to webhook **{{endpointName}}**"
                    },
                    serviceNowIncident: {
                        create: "Create a ServiceNow incident",
                        selectUpdate: "Select ServiceNow incident to update",
                        update: "Update ServiceNow incidents..."
                    },
                    updateStatus: {
                        [Contract.RiskStatus.Closed]: "Close",
                        [Contract.RiskStatus.Open]: {
                            disabled: "This finding can't be reopened since it was automatically closed by Tenable Cloud Security",
                            text: "Open"
                        }
                    }
                }
            }));

    const jiraInstanceModels =
        ScopeHelper.getParentScopeSystemEntityModelsUnion(
            riskModel.risk.scopeIds,
            jiraInstanceConfigurations);
    const serviceNowInstanceModels =
        ScopeHelper.getParentScopeSystemEntityModelsUnion(
            riskModel.risk.scopeIds,
            serviceNowInstanceConfigurations);
    const webhookModels =
        ScopeHelper.getParentScopeSystemEntityModelsUnion(
            riskModel.risk.scopeIds,
            scopeSystemEntityModelStore.useGetWebhook());

    const [jiraEnabled, serviceNowEnabled] =
        useMemo(
            () => {
                const jiraEnabled =
                    UserHelper.hasAnyScopePermissions(riskModel.risk.scopeIds, Contract.IdentityPermission.SecurityWrite) &&
                    !_(jiraInstanceModels).
                        flatMap(jiraInstanceModel => (jiraInstanceModel.configuration as Contract.JiraInstanceConfiguration).projects).
                        isEmpty();
                const serviceNowEnabled =
                    UserHelper.hasAnyScopePermissions(riskModel.risk.scopeIds, Contract.IdentityPermission.SecurityWrite) &&
                    !_.isEmpty(serviceNowInstanceModels);
                return [jiraEnabled, serviceNowEnabled];
            },
            [jiraInstanceModels, riskModel, serviceNowInstanceModels]);

    const resourceOwnerEnabled =
        useMemo(
            () =>
                _.some(
                    riskModel.riskedEntityOwner?.values,
                    principalReferenceValue =>
                        !principalReferenceValue.principalSearchableIdReference.idReference.startsWith(Contract.TenantType.Unknown.toLowerCase()) ||
                        MailParser.validate(principalReferenceValue.principalSearchableIdReference.displayName)),
            [riskModel]);

    const webhookEndpointConfigurations =
        useMemo(
            () =>
                _(webhookModels).
                    map(webhookModel => webhookModel.configuration as Contract.WebhookEndpointConfiguration).
                    sortBy(webhookConfiguration => webhookConfiguration.name).
                    value(),
            [webhookModels]);

    const additionalElements =
        useMemo(
            () => _<ReactNode>([]).
                concatIf(
                    !_.isEmpty(jiraIssues),
                    <JiraAvatar
                        issuesOrGetIssues={jiraIssues}
                        key="JiraAvatar"/>).
                concatIf(
                    !_.isEmpty(serviceNowIncidents),
                    <ServiceNowAvatar
                        incidentsOrGetIncidents={serviceNowIncidents}
                        key="ServiceNowAvatar"/>).
                value(),
            [jiraIssues, serviceNowIncidents]);

    const menuItems =
        useMemo(
            () => ({
                close:
                    UserHelper.hasAnyScopePermissions(riskModel.risk.scopeIds, Contract.IdentityPermission.SecurityWrite) &&
                    riskModel.risk.status !== Contract.RiskStatus.Closed
                        ? new ActionMenuItem(
                            () => setDialogType(ActionDialogType.Close),
                            localization.actions.updateStatus[Contract.RiskStatus.Closed](),
                            {
                                icon: <RiskStatusIcon status={Contract.RiskStatus.Closed}/>
                            })
                        : undefined,
                comment:
                    UserHelper.hasAnyScopePermissions(riskModel.risk.scopeIds, Contract.IdentityPermission.SecurityWrite)
                        ? new ActionMenuItem(
                            () => setDialogType(ActionDialogType.InsertRiskNote),
                            localization.actions.insertRiskNote(),
                            { icon: <CommentIcon/> })
                        : undefined,
                jiraCreate:
                    jiraEnabled
                        ? () =>
                            new ActionMenuItem(
                                () => {
                                    setRiskIdToTicketIdsMap({ [riskModel.id]: [] });
                                    setDialogType(ActionDialogType.Jira);
                                },
                                localization.actions.jiraIssue.create(),
                                { icon: <JiraIcon/> })
                        : undefined,
                jiraUpdate:
                    jiraEnabled &&
                    !_.isEmpty(jiraIssues)
                        ? () =>
                            new SubMenuItem(
                                _.map(
                                    jiraIssues,
                                    jiraIssue =>
                                        new ActionMenuItem(
                                            () => {
                                                setRiskIdToTicketIdsMap({ [riskModel.risk.id]: [jiraIssue.id] });
                                                setDialogType(ActionDialogType.Jira);
                                            },
                                            jiraIssueTranslator(jiraIssue, "id"),
                                            { disabled: jiraIssue.multipleRisks })),
                                localization.actions.jiraIssue.update(),
                                {
                                    icon: <JiraIcon/>,
                                    subMenuTitle: localization.actions.jiraIssue.selectUpdate()
                                })
                        : undefined,
                open:
                    UserHelper.hasAnyScopePermissions(riskModel.risk.scopeIds, Contract.IdentityPermission.SecurityWrite) &&
                    riskModel.risk.status === Contract.RiskStatus.Closed
                        ? new ActionMenuItem(
                            () => setDialogType(ActionDialogType.Reopen),
                            localization.actions.updateStatus[Contract.RiskStatus.Open].text(),
                            {
                                disabled: riskModel.risk.subStatus !== Contract.RiskSubStatus.ClosedManually,
                                icon: <OpenIcon/>,
                                tooltip:
                                    riskModel.risk.subStatus !== Contract.RiskSubStatus.ClosedManually
                                        ? localization.actions.updateStatus[Contract.RiskStatus.Open].disabled()
                                        : undefined
                            })
                        : undefined,
                sendRisksMail:
                    new ActionMenuItem(
                        () => setDialogType(ActionDialogType.SendRisksMail),
                        localization.actions.sendRisksMail(),
                        { icon: <MailIcon/> }),
                sendRisksMailPerOwner:
                    resourceOwnerEnabled
                        ? new ActionMenuItem(
                            () => setDialogType(ActionDialogType.SendRisksMailPerOwner),
                            localization.actions.sendRisksMailPerOwner(),
                            { icon: <MailIcon/> })
                        : undefined,
                sendRiskWebhook:
                    UserHelper.hasAnyScopePermissions(riskModel.risk.scopeIds, Contract.IdentityPermission.SecurityWrite) &&
                    !_.isEmpty(webhookModels)
                        ? () =>
                            webhookEndpointConfigurations.length === 1
                                ? new ActionMenuItem(
                                    () => {
                                        setWebhookEndpointConfiguration(webhookEndpointConfigurations[0]);
                                        setDialogType(ActionDialogType.SendRiskWebhook);
                                    },
                                    localization.actions.sendRiskWebhook.single({ endpointName: webhookEndpointConfigurations[0].name }),
                                    { icon: <WebhookIcon/> })
                                : new SubMenuItem(
                                    _.map(
                                        webhookEndpointConfigurations,
                                        webhookEndpointConfiguration =>
                                            new ActionMenuItem(
                                                () => {
                                                    setWebhookEndpointConfiguration(webhookEndpointConfiguration);
                                                    setDialogType(ActionDialogType.SendRiskWebhook);
                                                },
                                                webhookEndpointConfiguration.name)),
                                    localization.actions.sendRiskWebhook.many(),
                                    { icon: <WebhookIcon/> })
                        : undefined,
                separator:
                    UserHelper.hasAnyScopePermissions(riskModel.risk.scopeIds, Contract.IdentityPermission.SecurityWrite)
                        ? new SeparatorMenuItem()
                        : undefined,
                serviceNowCreate:
                    serviceNowEnabled
                        ? () =>
                            new ActionMenuItem(
                                () => {
                                    setRiskIdToTicketIdsMap({ [riskModel.risk.id]: [] });
                                    setDialogType(ActionDialogType.ServiceNow);
                                },
                                localization.actions.serviceNowIncident.create(),
                                {
                                    icon: <ServiceNowIcon/>
                                })
                        : undefined,
                serviceNowUpdate:
                    serviceNowEnabled &&
                    !_.isEmpty(serviceNowIncidents)
                        ? () =>
                            new SubMenuItem(
                                _.map(
                                    serviceNowIncidents,
                                    serviceNowIncident =>
                                        new ActionMenuItem(
                                            () => {
                                                setRiskIdToTicketIdsMap({ [riskModel.risk.id]: [serviceNowIncident.id] });
                                                setDialogType(ActionDialogType.ServiceNow);
                                            },
                                            serviceNowIncidentTranslator(serviceNowIncident, "id"),
                                            { disabled: serviceNowIncident.multipleRisks })),
                                localization.actions.serviceNowIncident.update(),
                                {
                                    icon: <ServiceNowIcon/>,
                                    subMenuTitle: localization.actions.serviceNowIncident.selectUpdate()
                                })
                        : undefined
            }),
            [riskModel, jiraEnabled, jiraIssues, serviceNowEnabled, serviceNowIncidents, setDialogType, webhookEndpointConfigurations]);

    const getItemDialog =
        useCallback(
            (type: ActionDialogType) => {
                switch (type) {
                    case ActionDialogType.Close:
                        return (
                            <CloseDialog
                                riskIds={[riskModel.id]}
                                riskType={riskType}
                                onClose={
                                    canceled =>
                                        onCloseDialog(
                                            riskModel,
                                            Contract.RiskStatus.Closed,
                                            canceled)}/>);
                    case ActionDialogType.InsertRiskNote:
                        return (
                            <InsertRiskNoteDialog
                                riskIds={[riskModel.id]}
                                onClose={
                                    canceled => {
                                        if (!canceled) {
                                            triggerAuditEventsChange?.();
                                        }

                                        setDialogType(undefined);
                                    }}/>);
                    case ActionDialogType.Jira:
                        return (
                            <UpsertJiraIssueDialog
                                instanceModels={jiraInstanceModels}
                                riskIdToIssueIdsMap={riskIdToTicketIdsMap!}
                                riskType={riskType}
                                onClose={async (successRiskIds?: string[]) => {
                                    if (!_.isEmpty(successRiskIds)) {
                                        await reloadRiskModel();
                                    }

                                    setDialogType(undefined);
                                }}/>);
                    case ActionDialogType.Reopen:
                        return (
                            <OpenDialog
                                riskIds={[riskModel.id]}
                                riskType={riskType}
                                onClose={
                                    canceled =>
                                        onCloseDialog(
                                            riskModel,
                                            Contract.RiskStatus.Open,
                                            canceled)}/>);
                    case ActionDialogType.SendRisksMail:
                        return (
                            <SendRisksMailDialog
                                riskIds={[riskModel.id]}
                                typeName={Contract.TypeNames.MailDelivery}
                                onClose={
                                    canceled => {
                                        if (!canceled) {
                                            triggerAuditEventsChange?.();
                                        }

                                        setDialogType(undefined);
                                    }}/>);
                    case ActionDialogType.SendRisksMailPerOwner:
                        return (
                            <SendRisksMailDialog
                                riskIds={[riskModel.id]}
                                typeName={Contract.TypeNames.ResourceOwnerMailDelivery}
                                onClose={
                                    canceled => {
                                        if (!canceled) {
                                            triggerAuditEventsChange?.();
                                        }

                                        setDialogType(undefined);
                                    }}/>);
                    case ActionDialogType.SendRiskWebhook:
                        return (
                            <SendRisksWebhookDialog
                                endpointConfiguration={webhookEndpointConfiguration!}
                                riskIds={[riskModel.id]}
                                onClose={
                                    canceled => {
                                        if (!canceled) {
                                            triggerAuditEventsChange?.();
                                        }

                                        setDialogType(undefined);
                                    }}/>);
                    case ActionDialogType.ServiceNow:
                        return (
                            <UpsertServiceNowIncidentDialog
                                instanceModels={serviceNowInstanceModels}
                                riskIdToIncidentIdsMap={riskIdToTicketIdsMap!}
                                riskType={riskType}
                                onClose={async (successRiskIds?: string[]) => {
                                    if (!_.isEmpty(successRiskIds)) {
                                        await reloadRiskModel();
                                    }

                                    setDialogType(undefined);
                                }}/>);
                }
            },
            [jiraInstanceModels, onCloseDialog, reloadRiskModel, riskModel, riskIdToTicketIdsMap, serviceNowInstanceModels, setDialogType, triggerAuditEventsChange, webhookEndpointConfiguration]);

    return {
        additionalElements,
        getItemDialog,
        menuItems
    };
}