import { DataTableColumnRenderProps, InlineItems, Message, useLocalization } from "@infrastructure";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Scope, scopeNodeModelStore, useScopeNavigationViewContext } from "../../../../../../../common";

export function ScopesCell({ item }: DataTableColumnRenderProps<Contract.AutomationRuleConfiguration>) {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();
    const localization =
        useLocalization(
            "views.customer.configuration.automations.scopesCell",
            () => ({
                all: "All existing and future",
                error: {
                    [ScopeCellError.Inactive]: "This rule has been configured to trigger automations on {{type}} to specific groups that were either deleted or do not contain accounts, so no automation will be triggered. Edit the rule to fix it.",
                    [ScopeCellError.PartialActive]: "This rule has been configured to trigger automations on {{type}} to specific groups that do not contain accounts. Edit the rule to fix it."
                },
                none: "No scopes",
                some: [
                    "{{scopes}} Scope",
                    "{{scopes}} Scopes"
                ],
                types: {
                    auditEvents: "audit events",
                    risks: "findings"
                }
            }));

    const [activeScopeIds, error] =
        useMemo(
            () => {
                const activeScopeIds =
                    _.filter(
                        item.scopeIds,
                        scopeId => !_.isEmpty(scopeNodeMap[scopeId]?.tenantIds));
                const error =
                    _.isEmpty(activeScopeIds)
                        ? ScopeCellError.Inactive
                        : activeScopeIds.length < item.scopeIds.length
                            ? ScopeCellError.PartialActive
                            : undefined;

                return [activeScopeIds, error];
            },
            [item]);

    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1}>
            <Typography
                noWrap={true}
                sx={{ fontWeight: 600 }}>
                {_.isEmpty(item.scopeIds)
                    ? localization.none()
                    : (item.scopeIds.length === 1 && item.scopeIds[0] == scopeNodeModel.configuration.id)
                        ? localization.all()
                        : localization.some(
                            item.scopeIds.length,
                            {
                                scopes:
                                    <InlineItems
                                        items={item.scopeIds}
                                        sx={{
                                            color: "unset",
                                            fontWeight: "unset",
                                            textDecoration: "underline"
                                        }}
                                        variant="itemCount">
                                        {scopeId =>
                                            <Stack
                                                alignItems="center"
                                                direction="row"
                                                spacing={1}>
                                                <Scope
                                                    scopeId={scopeId}
                                                    scopeNameTranslatorOptions={{ path: true }}
                                                    variant="text"/>
                                                {!_.includes(activeScopeIds, scopeId) && (
                                                    <Message
                                                        level="infoWarning"
                                                        title={
                                                            localization.error[ScopeCellError.Inactive]({
                                                                type:
                                                                    item.typeName === Contract.TypeNames.AuditEventAutomationRuleConfiguration
                                                                        ? localization.types.auditEvents()
                                                                        : localization.types.risks()
                                                            })}
                                                        variant="minimal"/>)}
                                            </Stack>}
                                    </InlineItems>
                            })}
            </Typography>
            {!_.isNil(error) && (
                <Message
                    level="error"
                    title={localization.error[error]({
                        type:
                            item.typeName === Contract.TypeNames.AuditEventAutomationRuleConfiguration
                                ? localization.types.auditEvents()
                                : localization.types.risks()
                    })}
                    variant="minimal"/>)}
        </Stack>);
}

enum ScopeCellError {
    Inactive = "inactive",
    PartialActive = "partialActive"
}