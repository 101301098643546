import { useLocalization } from "@infrastructure";
import React from "react";
import { useGetGcpScopeResourcePublicAccessExistsRiskContext, useGetGcpStorageBucketRiskContext } from "../../../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../../common";
import { RiskDefinitionSection } from "../../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../../../components";
import { ScopeResourceRoleBindingTableSection } from "../components";

export function useGcpStorageBucketPublicAccessExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const storageBucketPublicAccessExistsRiskModel = riskModel as Contract.GcpStorageBucketPublicAccessExistsRiskModel;
    const bucketModel = entityModelStore.useGet(storageBucketPublicAccessExistsRiskModel.risk.entityId) as Contract.GcpStorageBucketModel;

    const getGcpStorageBucketRiskContext = useGetGcpStorageBucketRiskContext();
    const getGcpScopeResourcePublicAccessExistsRiskContext = useGetGcpScopeResourcePublicAccessExistsRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.compliance.publicAccess.hooks.useGcpStorageBucketPublicAccessExistsRiskDefinition",
            () => ({
                description: {
                    [Contract.TypeNames.GcpStorageBucketPublicAccessExistsRiskModelDescriptionType]: {
                        [Contract.GcpStorageBucketPublicAccessExistsRiskModelDescriptionType.Acl]: "{{bucket}} is publicly accessible via ACLs",
                        [Contract.GcpStorageBucketPublicAccessExistsRiskModelDescriptionType.AclRoleBinding]: "{{bucket}} is publicly accessible via IAM roles and ACLs",
                        [Contract.GcpStorageBucketPublicAccessExistsRiskModelDescriptionType.RoleBinding]: "{{bucket}} is publicly accessible via IAM roles"
                    }
                },
                sections: {
                    resolution: {
                        step1: "Before changing the permissions, verify that applications can access the bucket without public access",
                        step2: "Delete the role bindings to allUsers / allAuthenticatedUsers"
                    },
                    roleBindingIds: "Role Bindings"
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description[Contract.TypeNames.GcpStorageBucketPublicAccessExistsRiskModelDescriptionType][storageBucketPublicAccessExistsRiskModel.descriptionType]({
            bucket:
                <Entity
                    entityIdOrModel={bucketModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={storageBucketPublicAccessExistsRiskModel.risk.entityId}
                key={storageBucketPublicAccessExistsRiskModel.risk.entityId}
                page={Contract.GcpConsolePage.Permissions}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2()
        ],
        storageBucketPublicAccessExistsRiskModel,
        () => {
            const bucketRiskContext = getGcpStorageBucketRiskContext(bucketModel);
            const scopeResourcePublicAccessExistsRiskContext = getGcpScopeResourcePublicAccessExistsRiskContext(storageBucketPublicAccessExistsRiskModel.risk);
            return [
                bucketRiskContext.generalInformation,
                bucketRiskContext.sensitive,
                bucketRiskContext.encryption,
                scopeResourcePublicAccessExistsRiskContext.permissionActions,
                bucketRiskContext.highestDataSensitivity,
                bucketRiskContext.getOpenRiskedEntityRisksContextItem(storageBucketPublicAccessExistsRiskModel.risk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <ScopeResourceRoleBindingTableSection
                        csvExportFilePrefixes={[localization.sections.roleBindingIds()]}
                        risk={storageBucketPublicAccessExistsRiskModel.risk}/>,
                    localization.sections.roleBindingIds())
            ]
        });
}