import { getUrlQueryParameters, Optional } from "@infrastructure";
import _, { Dictionary } from "lodash";
import { AuthenticationController, Contract } from "..";

export class UserHelper {
    private static _applicationSupport = false;
    private static _customer: Optional<Contract.AuthenticationControllerGetUserCustomerDataResponseCustomer>;
    private static _customerPermissions?: Contract.IdentityPermission[];
    private static _enabledConsoleAppTypes: Contract.ConsoleAppType[];
    private static _mail: string;
    private static _mailHash: string;
    private static _multipleCustomers: boolean;
    private static _nameValue: string;
    private static _permissionToActiveCloudProviderTenantIdsMap?: Dictionary<string[]>;
    private static _permissionToActiveCodeTenantIdsMap?: Dictionary<string[]>;
    private static _permissionToActiveIdentityProviderTenantIdsMap?: Dictionary<string[]>;
    private static _permissionToFolderIdsMap?: Dictionary<string[]>;
    private static _permissionToProjectIdsMap?: Dictionary<string[]>;
    private static _scopeIdToPermissionsMap?: Dictionary<Contract.IdentityPermission[]>;
    private static _securityAdministrationWritePermissionExists?: boolean;
    private static _support = false;
    private static _tenableId: Optional<string>;

    public static get applicationSupport() {
        return UserHelper._applicationSupport;
    }

    public static get customer() {
        return UserHelper._customer;
    }

    public static get enabledConsoleAppTypes() {
        return UserHelper._enabledConsoleAppTypes;
    }

    public static get mail() {
        return UserHelper._mail;
    }

    public static get mailHash() {
        return UserHelper._mailHash;
    }

    public static get multipleCustomers() {
        return UserHelper._multipleCustomers;
    }

    public static get nameValue() {
        return UserHelper._nameValue;
    }

    public static get securityAdministrationWritePermissionExists() {
        return UserHelper._securityAdministrationWritePermissionExists;
    }

    public static get support() {
        return UserHelper._support;
    }

    public static get tenable() {
        const { tenable } = getUrlQueryParameters<{ tenable?: string }>();
        return !_.isNil(UserHelper._tenableId) || tenable === "true";
    }

    public static get tenableId() {
        return UserHelper._tenableId;
    }

    public static async initialize() {
        const { customer } = await AuthenticationController.getUserCustomerData();
        const user = await AuthenticationController.getUser();
        UserHelper._applicationSupport = user.applicationSupport;
        UserHelper._customer = customer;
        UserHelper._customerPermissions = user.customerPermissions;
        UserHelper._enabledConsoleAppTypes = user.enabledConsoleAppTypes;
        UserHelper._mail = user.mail;
        UserHelper._mailHash = user.mailHash;
        UserHelper._multipleCustomers = user.multipleCustomers;
        UserHelper._nameValue = user.name;
        UserHelper._permissionToActiveCloudProviderTenantIdsMap = user.permissionToActiveCloudProviderTenantIdsMap;
        UserHelper._permissionToActiveCodeTenantIdsMap = user.permissionToActiveCodeTenantIdsMap;
        UserHelper._permissionToActiveIdentityProviderTenantIdsMap = user.permissionToActiveIdentityProviderTenantIdsMap;
        UserHelper._permissionToFolderIdsMap = user.permissionToFolderIdsMap;
        UserHelper._permissionToProjectIdsMap = user.permissionToProjectIdsMap;
        UserHelper._scopeIdToPermissionsMap = user.scopeIdToPermissionsMap;
        UserHelper._securityAdministrationWritePermissionExists = user.securityAdministrationWritePermissionExists;
        UserHelper._support = user.support;
        UserHelper._tenableId = user.tenableId;
    }

    public static hasAnyCloudProviderTenantPermissions(...permissions: Contract.IdentityPermission[]): boolean {
        return (
            this.hasAnyCustomerPermissions(...permissions) ||
            _(UserHelper._permissionToActiveCloudProviderTenantIdsMap).
                pick(permissions).
                values().
                some(activeCloudProviderTenantIds => !_.isEmpty(activeCloudProviderTenantIds)));
    }

    public static hasAnyCodeTenantPermissions(...permissions: Contract.IdentityPermission[]): boolean {
        return (
            this.hasAnyCustomerPermissions(...permissions) ||
            _(UserHelper._permissionToActiveCodeTenantIdsMap).
                pick(permissions).
                values().
                some(activeCodeTenantIds => !_.isEmpty(activeCodeTenantIds)));
    }

    public static hasAnyCustomerPermissions(...permissions: Contract.IdentityPermission[]): boolean {
        return (
            !_(UserHelper._customerPermissions).
                intersection(permissions).
                isEmpty());
    }

    public static hasAnyIdentityProviderTenantPermissions(...permissions: Contract.IdentityPermission[]): boolean {
        return (
            this.hasAnyCustomerPermissions(...permissions) ||
            _(UserHelper._permissionToActiveIdentityProviderTenantIdsMap).
                pick(permissions).
                values().
                some(activeIdentityProviderTenantIds => !_.isEmpty(activeIdentityProviderTenantIds)));
    }

    public static hasAnyFolderPermissions(...permissions: Contract.IdentityPermission[]): boolean {
        return (
            this.hasAnyCustomerPermissions(...permissions) ||
            _(UserHelper._permissionToFolderIdsMap).
                pick(permissions).
                values().
                some(folderIds => !_.isEmpty(folderIds)));
    }

    public static hasAnyProjectPermissions(...permissions: Contract.IdentityPermission[]): boolean {
        return (
            this.hasAnyCustomerPermissions(...permissions) ||
            _(UserHelper._permissionToProjectIdsMap).
                pick(permissions).
                values().
                some(projectIds => !_.isEmpty(projectIds)));
    }

    public static hasAnyScopePermissions(
        scopeIds?: string[],
        ...permissions: Contract.IdentityPermission[]): boolean {
        return _.isNil(scopeIds)
            ? this.hasAnyCustomerPermissions(...permissions) ||
                this.hasAnyCloudProviderTenantPermissions(...permissions) ||
                this.hasAnyCodeTenantPermissions(...permissions) ||
                this.hasAnyIdentityProviderTenantPermissions(...permissions) ||
                this.hasAnyFolderPermissions(...permissions) ||
                this.hasAnyProjectPermissions(...permissions)
            : _.some(
                scopeIds,
                scopeId => this.hasScopePermissions(scopeId, ...permissions));
    }

    public static hasScopePermissions(
        scopeId: string,
        ...permissions: Contract.IdentityPermission[]): boolean {
        return _.isNil(UserHelper._scopeIdToPermissionsMap)
            ? false
            : !_(UserHelper._scopeIdToPermissionsMap[scopeId]).
                intersection(permissions).
                isEmpty();
    }
}