import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useAadTenantEntityExternalUserDefaultRoleTranslator, useAadTenantEntityExternalUserInvitationAllowedRolesTranslator } from "../../../../../../hooks";
import { Info } from "../../../../components";
import { useAadDefaultDirectoryEntityInfoItemElements } from "./useAadDefaultDirectoryEntityInfoItemElements";

export function useAadTenantEntityDefinition(directoryEntityModel: Contract.AadDirectoryEntityModel, options?: DefinitionOptions) {
    const tenantEntity = directoryEntityModel.entity as Contract.AadTenantEntity;
    const defaultDirectoryEntityInfoItemElements = useAadDefaultDirectoryEntityInfoItemElements(directoryEntityModel as Contract.AadDirectoryPrincipalModel);

    const externalUserDefaultRoleTranslator = useAadTenantEntityExternalUserDefaultRoleTranslator();
    const externalUserInvitationAllowedRolesTranslator = useAadTenantEntityExternalUserInvitationAllowedRolesTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aad.useAadTenantEntityDefinition",
            () => ({
                info: {
                    items: {
                        defaultUserRoleApplicationCreationEnabled: {
                            false: "Disabled",
                            title: "User Can Register Applications",
                            true: "Enabled"
                        },
                        defaultUserRoleTenantCreationEnabled: {
                            false: "Yes",
                            title: "Restrict Non-admin Users From Creating Tenants",
                            true: "No"
                        },
                        externalUserDefaultRole: "Guest Users Access Restrictions",
                        externalUserInvitationAllowedRoles: "Guest Invite Restrictions",
                        primaryDomainName: "Primary Domain Name"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultDirectoryEntityInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="primaryDomainName"
                        title={localization.info.items.primaryDomainName()}
                        value={tenantEntity.primaryDomainName}/>,
                    <InfoItem
                        key="guestUserRoleAccessType"
                        title={localization.info.items.externalUserDefaultRole()}
                        value={
                            _.isNil(tenantEntity.externalUserDefaultRole)
                                ? undefined
                                : externalUserDefaultRoleTranslator(tenantEntity.externalUserDefaultRole)}/>,
                    <InfoItem
                        key="guestInviteRestrictionType"
                        title={localization.info.items.externalUserInvitationAllowedRoles()}
                        value={
                            _.isNil(tenantEntity.externalUserInvitationAllowedRoles)
                                ? undefined
                                : externalUserInvitationAllowedRolesTranslator(tenantEntity.externalUserInvitationAllowedRoles)}/>,
                    <InfoItem
                        key="defaultUserRoleApplicationCreationEnabled"
                        title={localization.info.items.defaultUserRoleApplicationCreationEnabled.title()}
                        value={
                            _.isNil(tenantEntity.defaultUserRoleApplicationCreationEnabled)
                                ? undefined
                                : tenantEntity.defaultUserRoleApplicationCreationEnabled
                                    ? localization.info.items.defaultUserRoleApplicationCreationEnabled.true()
                                    : localization.info.items.defaultUserRoleApplicationCreationEnabled.false()}/>,
                    <InfoItem
                        key="defaultUserRoleTenantCreationEnabled"
                        title={localization.info.items.defaultUserRoleTenantCreationEnabled.title()}
                        value={
                            _.isNil(tenantEntity.defaultUserRoleTenantCreationEnabled)
                                ? undefined
                                : tenantEntity.defaultUserRoleTenantCreationEnabled
                                    ? localization.info.items.defaultUserRoleTenantCreationEnabled.true()
                                    : localization.info.items.defaultUserRoleTenantCreationEnabled.false()}/>
                ]}
                options={options?.infoOptions}>
            </Info>,
        sensitive: false
    });
}