import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useGcpCommonScopeResourceInfoItemElements, useGcpDefaultResourceInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../../common";
import { useGcpComputeFirewallPolicyScopeTranslator } from "../../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../../components";
import { GcpScopeResourceRoleBindings } from "../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { GcpComputeFirewallPolicyRulesTable } from "./components";

export function useGcpComputeFirewallPolicyDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const commonScopeResourcesInfoElements = useGcpCommonScopeResourceInfoItemElements(scopeResourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const firewallPolicyModel = scopeResourceModel as Contract.GcpComputeFirewallPolicyModel;
    const firewallPolicy = firewallPolicyModel.entity as Contract.GcpComputeFirewallPolicy;

    const computeFirewallPolicyScopeTranslator = useGcpComputeFirewallPolicyScopeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpComputeFirewallPolicyDefinition",
            () => ({
                info: {
                    items: {
                        attachedResourceIdReferences: "Associations",
                        rawId: "Id",
                        rulesCount: "Firewall Rules Count",
                        scope: "Type",
                        vpcIds: "VPC Networks"
                    }
                },
                tabs: {
                    resourceRoleBindings: "Resource Role Bindings"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <GcpScopeResourceRoleBindings
                    csvExportFilePrefix={localization.tabs.resourceRoleBindings()}
                    scopeResourceModel={firewallPolicyModel}/>,
                localization.tabs.resourceRoleBindings(),
                "resourceRoleBindings")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    commonScopeResourcesInfoElements.accessLevelInfoItemElement,
                    <InfoItem
                        key="rawId"
                        location="all"
                        title={localization.info.items.rawId()}
                        value={firewallPolicy.rawId}/>,
                    <InfoItem
                        key="scope"
                        title={localization.info.items.scope()}
                        value={computeFirewallPolicyScopeTranslator(firewallPolicy.scope)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={firewallPolicyModel.attachedResourceIdReferences}
                        entityTypeName={Contract.TypeNames.GcpScopeResource}
                        key="attachedResourceIdReferences"
                        location="all"
                        title={localization.info.items.attachedResourceIdReferences()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={firewallPolicyModel.vpcIds}
                        entityTypeName={Contract.TypeNames.GcpComputeVpc}
                        key="vpcIds"
                        location="all"
                        title={localization.info.items.vpcIds()}/>,
                    <InfoItem
                        key="rulesCount"
                        location="all"
                        title={localization.info.items.rulesCount()}
                        value={firewallPolicy.rules.length}/>
                ]}
                options={options?.infoOptions}>
                <GcpComputeFirewallPolicyRulesTable
                    rules={firewallPolicy.rules}
                    targetServiceAccountMailToIdReferenceMap={firewallPolicyModel.targetServiceAccountMailToIdReferenceMap}
                    targetVpcUrlToIdReferenceMap={firewallPolicyModel.targetVpcUrlToIdReferenceMap}/>
            </Info>
    });
}