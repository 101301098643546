import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function KochavaIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g clipPath="url(#clip0_58343_2034)">
                <path
                    d="M9.2535 9.08096L0.33872 9.02726C0.17761 9.02726 0.0701961 9.24207 0.231307 9.34948L7.42759 14.5587L5.49426 23.0439C5.44055 23.205 5.65537 23.3124 5.76278 23.205L12.0998 17.942L18.759 23.205C18.9201 23.3124 19.0812 23.205 19.0275 22.9902L16.772 14.5587L23.9683 9.34948C24.1294 9.24207 24.022 9.02726 23.8608 9.02726L14.9461 9.08096L12.2609 0.595808C12.2072 0.434698 11.9924 0.434698 11.9387 0.595808L9.2535 9.08096Z"
                    fill="#BC2330"/>
                <path
                    d="M12.1535 17.942L5.81649 23.205C5.76278 23.2587 5.65536 23.2587 5.60166 23.205C5.54796 23.1513 12.0998 13.2698 12.0998 13.2698L12.1535 17.942Z"
                    fill="#BC2330"/>
                <path
                    d="M12.1535 17.942L5.81649 23.205C5.76278 23.2587 5.65536 23.2587 5.60166 23.205C5.54796 23.1513 12.0998 13.2698 12.0998 13.2698L12.1535 17.942Z"
                    fill="#6E1710"/>
                <path
                    d="M12.1535 17.942L18.8127 23.205C18.8664 23.2587 18.9738 23.2587 19.0812 23.205L12.1535 13.3235L12.1535 17.942Z"
                    fill="#981C20"/>
                <path
                    d="M10.8646 3.97913L11.9387 0.649512C11.9924 0.542105 12.0461 0.488401 12.0998 0.488401V6.07356L10.8646 3.97913Z"
                    fill="#6E1710"/>
                <path
                    d="M12.0998 13.2698L0.177621 9.13466C0.231324 9.08096 0.285017 9.02726 0.33872 9.02726L9.2535 9.08096L12.0998 13.2698Z"
                    fill="#ED3B78"/>
                <path
                    d="M7.48129 14.5587L0.285022 9.34948C0.231318 9.29577 0.177612 9.18837 0.231315 9.13466L12.1535 13.2698L7.48129 14.5587Z"
                    fill="#670B26"/>
                <path
                    d="M16.8257 14.5587L24.0757 9.34948C24.1294 9.29577 24.1831 9.18837 24.1294 9.13466L12.1535 13.2698L16.8257 14.5587Z"
                    fill="#BC2330"/>
                <path
                    d="M16.8257 14.5587L15.4294 17.9957L12.0998 13.2698L16.8257 14.5587Z"
                    fill="#891732"/>
                <path
                    d="M15.4294 17.9957L18.0072 19.0161L16.8257 14.5587L15.4294 17.9957Z"
                    fill="#ED3B78"/>
                <path
                    d="M7.96463 19.4994L12.1535 17.942L12.0998 13.2698L7.96463 19.4994Z"
                    fill="#E71B52"/>
                <path
                    d="M7.48129 14.5587L6.94425 16.8143L12.0998 13.2698L7.48129 14.5587Z"
                    fill="#891732"/>
                <path
                    d="M14.0331 19.392L12.0998 13.2698L12.1535 17.942L14.0331 19.392Z"
                    fill="#670B26"/>
                <path
                    d="M22.1961 9.7791L14.9998 9.08096L16.9868 11.5513L22.1961 9.7791Z"
                    fill="#ED3B78"/>
                <path
                    d="M6.40721 11.2828L12.0998 13.2698L7.48129 14.5587L6.40721 11.2828Z"
                    fill="#ED3B78"/>
                <path
                    d="M9.2535 9.08096L12.0998 6.07356L12.0998 13.2698L9.2535 9.08096Z"
                    fill="#C61F46"/>
                <path
                    d="M12.0998 6.07356L10.8646 3.97913L9.2535 9.08096L12.0998 6.07356Z"
                    fill="#E71B52"/>
                <path
                    d="M6.40721 11.2828L3.66835 11.8198L7.48129 14.5587L6.40721 11.2828Z"
                    fill="#891732"/>
                <path
                    d="M14.3016 6.77171L14.9998 9.08096L12.0998 13.2698L14.3016 6.77171Z"
                    fill="#ED3B78"/>
                <path
                    d="M12.0998 13.2698L7.48128 9.02726L9.2535 9.08096L12.0998 13.2698Z"
                    fill="#670B26"/>
                <path
                    d="M12.0998 13.2698H18.5442L16.8257 14.5587L12.0998 13.2698Z"
                    fill="#ED3B78"/>
            </g>
            <defs>
                <clipPath id="clip0_58343_2034">
                    <rect
                        fill="white"
                        height="24"
                        width="23.9962"/>
                </clipPath>
            </defs>
        </SvgIcon>);
}