import { UnexpectedError } from "@infrastructure";
import React, { MutableRefObject, useMemo } from "react";
import { Contract } from "../../../../../../../../common";
import { AadSeverePermissionDirectoryRoleDefinitionValue, DayValue } from "./components";

export type ValueProps = {
    variant: "edit" | "view";
} & (ValueEditProps | ValueViewProps);

export type ValueEditProps = {
    derivedTypeName: string;
    disabled: boolean;
    settingConfiguration?: never;
    setValidation: (valid: boolean) => void;
    valueRef: MutableRefObject<unknown | undefined>;
    variant: "edit";
};

export type ValueViewProps = {
    derivedTypeName?: never;
    settingConfiguration: Contract.ScopeSettingConfiguration;
    setValidation?: never;
    valueRef?: never;
    variant: "view";
};

export function Value(props: ValueProps) {
    return useMemo(
        () => {
            switch (props.settingConfiguration?.typeName ?? props.derivedTypeName) {
                case Contract.ScopeSettingConfigurationDerivedTypeNames.AadSeverePermissionDirectoryRoleDefinitionsConfiguration:
                    return <AadSeverePermissionDirectoryRoleDefinitionValue {...props}/>;
                case Contract.ScopeSettingConfigurationDerivedTypeNames.ServiceIdentityExcessivePermissionEvaluationTimeFrameConfiguration:
                case Contract.ScopeSettingConfigurationDerivedTypeNames.UserExcessivePermissionEvaluationTimeFrameConfiguration:
                    return <DayValue {...props}/>;
                default:
                    throw new UnexpectedError("settingValue.typeName", props.derivedTypeName);
            }
        },
        [props]);
}