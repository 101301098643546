import { useChangeEffect, useLocalization } from "@infrastructure";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { Contract } from "../../../../../../../../../../../../../common";
import { EntityTypeNamePropertyPatternItem } from "../../../EntityTypeNamePropertyPattern";


type EntityNamePatternProps = {
    duplicate: boolean;
    onItemChanged: (propertyPatternItem: EntityTypeNamePropertyPatternItem) => void;
    propertyPatternItem: EntityTypeNamePropertyPatternItem;
    readOnly: boolean;
};

export function EntityNamePattern({ duplicate, onItemChanged, propertyPatternItem, readOnly }: EntityNamePatternProps) {
    const [propertyPatternValue, setPropertyPatternValue] = useState(() => (propertyPatternItem.propertyPattern as Contract.RiskPolicyConfigurationEntityExclusionEntityNamePattern).namePattern);

    const localization =
        useLocalization(
            "views.customer.riskPolicies.configuration.entityExclusion.entityTypeNamePropertyPattern.entityPropertyPattern.entityNamePattern",
            () => ({
                duplicate: "Duplicate value is not allowed",
                fields: "Pattern"
            }));
    useChangeEffect(
        () => {
            const newPropertyPatternItem = _.cloneDeep(propertyPatternItem.propertyPattern)!;
            (newPropertyPatternItem as Contract.RiskPolicyConfigurationEntityExclusionEntityNamePattern).namePattern = propertyPatternValue.trim();

            onItemChanged(
                new EntityTypeNamePropertyPatternItem(
                    newPropertyPatternItem,
                    true,
                    propertyPatternItem.id));
        },
        [propertyPatternValue]);

    return (
        <FormControl
            fullWidth={true}
            variant="standard">
            <TextField
                disabled={readOnly}
                label={localization.fields()}
                value={propertyPatternValue}
                variant="outlined"
                onChange={event => setPropertyPatternValue(event.target.value)}/>
            {!readOnly &&
                propertyPatternItem.isValid() &&
                duplicate &&
                <FormHelperText error={true}>{localization.duplicate()}</FormHelperText>}
        </FormControl>);
}