import { useLocalization } from "@infrastructure";
import { useMemo } from "react";
import { Contract, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { useAwsSageMakerNotebookInstanceStatusTranslator } from "../../../../../../../../../Entities/hooks";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetAwsResourceRiskContext } from "./useGetAwsResourceRiskContext";

export function useGetAwsSageMakerNotebookInstanceRiskContext() {
    return useMemo(
        () => useAwsSageMakerNotebookInstanceRiskContext,
        []);
}

function useAwsSageMakerNotebookInstanceRiskContext(notebookInstanceModel: Contract.AwsSageMakerNotebookInstanceModel) {
    const notebookInstance = notebookInstanceModel.entity as Contract.AwsSageMakerNotebookInstance;
    const resourceRiskContext = useGetAwsResourceRiskContext()(notebookInstanceModel);

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const notebookInstanceStatusTranslator = useAwsSageMakerNotebookInstanceStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.contexts.useGetAwsSageMakerNotebookInstanceRiskContext",
            () => ({
                status: "The {{translatedNotebookInstanceTypeName}} status is **{{status}}**"
            }));

    const translatedNotebookInstanceTypeName =
        entityTypeNameTranslator(
            notebookInstance.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });
    return {
        ...resourceRiskContext,
        status:
            new RiskDefinitionContextItem(
                localization.status({
                    status: notebookInstanceStatusTranslator(notebookInstance.status),
                    translatedNotebookInstanceTypeName
                }))
    };
}