import { Action0, Optional, useChangeEffect, useUncaptureValue } from "@infrastructure";
import _, { Function0 } from "lodash";
import { useMemo, useRef, useState } from "react";

type InputValidationController = {
    clear: Action0;
    isValid: Function0<boolean>;
};

export function useInputValidation(
    validate: Function0<Optional<string>>,
    dependencies: any[]):
    [InputValidationController, Optional<string>] {
    const [message, setMessage] = useState<string>();
    const uncaptureValidate = useUncaptureValue(validate);
    const controller =
        useMemo(
            () => ({
                clear: () => {
                    changedRef.current = false;
                    setMessage(undefined);
                },
                isValid: () => !uncaptureValidate(validate => validate())
            }),
            []);

    const changedRef = useRef(false);
    useChangeEffect(
        () => {
            if (!_(dependencies).
                filter(dependency => !_.isNil(dependency)).
                isEmpty()) {
                changedRef.current = true;
            }

            if (changedRef.current) {
                setMessage(validate());
            }
        },
        dependencies);

    return [controller, message];
}