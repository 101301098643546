﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, Entity, InlineEntities, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useGetGcpEntityRiskContext } from "./useGetGcpEntityRiskContext";

export function useGetGcpKmsKeyRingKeyRiskContext() {
    return useMemo(
        () => useGcpKmsKeyRingKeyRiskContext,
        []);
}

function useGcpKmsKeyRingKeyRiskContext(keyModel: Contract.GcpKmsKeyRingKeyModel) {
    const entityRiskContext = useGetGcpEntityRiskContext()(keyModel);
    const key = keyModel.entity as Contract.GcpKmsKeyRingKey;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.gcp.hooks.contexts.useGetGcpKmsKeyRingKeyRiskContext",
            () => ({
                encryptedResourceIds: {
                    empty: "The {{translatedKeyTypeName}} is not used to encrypt any resources",
                    text: "The {{translatedKeyTypeName}} is used to encrypt {{encryptedResourceIds}}"
                },
                keyRing: "The {{translatedKeyTypeName}} is part of {{translatedKeyRingTypeName}} {{keyRing}}",
                rotationTime: {
                    empty: "The {{translatedKeyTypeName}} rotation period is not set",
                    text: "The {{translatedKeyTypeName}} rotation period is currently set to {{rotationTimeFrame | TimeSpanFormatter.humanizeDays}}"
                }
            }));
    const translatedKeyTypeName =
        entityTypeNameTranslator(
            key.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return {
        ...entityRiskContext,
        encryptedResources:
            new RiskDefinitionContextItem(
                (_.isEmpty(keyModel.encryptedResourceIds)
                    ? localization.encryptedResourceIds.empty
                    : localization.encryptedResourceIds.text)({
                    encryptedResourceIds:
                        <InlineEntities
                            entityIdsOrModels={keyModel.encryptedResourceIds}
                            entityTypeName={Contract.TypeNames.GcpResource}
                            variant="itemCountAndType"/>,
                    translatedKeyTypeName
                })),
        keyRing:
            new RiskDefinitionContextItem(
                localization.keyRing({
                    keyRing:
                        <Entity
                            entityIdOrModel={(keyModel.entity as Contract.GcpKmsKeyRingKey).keyRingId}
                            variant="text"/>,
                    translatedKeyRingTypeName:
                        entityTypeNameTranslator(
                            Contract.TypeNames.GcpKmsKeyRing,
                            { variant: "text" }),
                    translatedKeyTypeName
                })),
        rotationTime:
            _.isNil(key.rotationTimeFrame)
                ? new RiskDefinitionContextItem(
                    localization.rotationTime.empty({ translatedKeyTypeName }))
                : new RiskDefinitionContextItem(
                    localization.rotationTime.text({
                        rotationTimeFrame: key.rotationTimeFrame,
                        translatedKeyTypeName
                    }))
    };
}