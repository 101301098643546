import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract } from "../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../components";

export function useGcpCommonLoggingLogSinkDestinationResourceInfoItemElements(scopeResourceModel: Contract.GcpScopeResourceModel) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpCommonLoggingLogSinkDestinationResourceInfoItemElements",
            () => ({
                loggingLogSinks: "Log Sinks"
            }));

    return {
        loggingLogSinks:
            _.isEmpty(_.as<Contract.IGcpLoggingLogSinkDestinationResourceModel>(scopeResourceModel).loggingLogSinkIds)
                ? undefined
                : <EntitiesInfoItem
                    entityIdsOrModels={_.as<Contract.IGcpLoggingLogSinkDestinationResourceModel>(scopeResourceModel).loggingLogSinkIds}
                    entityTypeName={Contract.TypeNames.GcpLoggingLogSink}
                    key="loggingLogSinks"
                    title={localization.loggingLogSinks()}/>

    };
}