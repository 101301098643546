import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../common";
import { EntitiesInfoItem } from "../../../../components";
import { useGcpCommonScopeResourceInfoItemElements } from "./useGcpCommonScopeResourceInfoItemElements";

export function useGcpCommonComputeLoadBalancerResourceInfoItemElements(loadBalancerResourceModel: Contract.GcpComputeLoadBalancerResourceModel) {
    const commonScopeResourcesInfoElements = useGcpCommonScopeResourceInfoItemElements(loadBalancerResourceModel);
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpCommonComputeLoadBalancerResourceInfoItemElements",
            () => ({
                loadBalancerIds: "Load Balancers"
            }));

    return {
        ...commonScopeResourcesInfoElements,
        loadBalancerIdsInfoItemElement:
            <EntitiesInfoItem
                entityIdsOrModels={loadBalancerResourceModel.loadBalancerIds}
                entityTypeName={Contract.TypeNames.GcpComputeLoadBalancer}
                key="loadBalancerIds"
                location="all"
                title={localization.loadBalancerIds()}/>
    };
}