﻿import { useLocalization, useOrderedWizardContext, WizardFinishItem } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCloudsmithIntegrationContext } from "../../..";
import { useAddOrEditContext } from "..";
import { scopeNodeModelStore } from "../../../../../../../../../../../../../../../common";

export function FinishItem() {
    const { useNextEffect } = useOrderedWizardContext();
    const { addOrEditOpen } = useCloudsmithIntegrationContext();
    const { integrationName, updatedFolderScopeNodeModel } = useAddOrEditContext();

    useNextEffect(
        async () => {
            if (updatedFolderScopeNodeModel) {
                await scopeNodeModelStore.notify(updatedFolderScopeNodeModel);
            }

            return undefined;
        },
        [updatedFolderScopeNodeModel]);

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCiIntegrationItems.cloudsmithIntegration.addOrEdit.finishItem",
            () => ({
                title: {
                    add: "Cloudsmith organization {{integrationName}} was added successfully. \nIt may take several hours for repositories to be visible in the Inventory",
                    edit: "Cloudsmith organization {{integrationName}} was changed successfully. \nIt may take several hours for repositories to be visible in the Inventory"
                }
            }));

    return (
        <WizardFinishItem
            title={
                _.isBoolean(addOrEditOpen)
                    ? localization.title.add({ integrationName })
                    : localization.title.edit({ integrationName })}/>);
}