import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAwsDocDbClusterRiskContext } from "../contexts";

export function useAwsRdsDocDbClusterCloudWatchLoggingDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsRdsDocDbClusterCloudWatchLoggingDisabledRisk;
    const docDbClusterModel = entityModelStore.useGet(risk.entityId) as Contract.AwsRdsDocDbClusterModel;
    const docDbCluster = docDbClusterModel.entity as Contract.AwsRdsDocDbCluster;

    const getAwsRdsDocDbClusterRiskContext = useGetAwsDocDbClusterRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsRdsDocDbClusterCloudWatchLoggingDisabledRiskDefinition",
            () => ({
                description: "{{docDbCluster}} does not publish logs to CloudWatch Logs",
                sections: {
                    resolution: {
                        step1: "Click **Modify**",
                        step2: "Under **Log exports**, select **Audit logs**",
                        step3: "Continue and save changes"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            docDbCluster:
                <Entity
                    entityIdOrModel={docDbClusterModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.DocDb,
                AwsConsoleUrlBuilder.getRdsDocDbClusterUrl(docDbCluster)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3()
        ],
        riskModel,
        () => {
            const docDbClusterRiskContext = getAwsRdsDocDbClusterRiskContext(docDbClusterModel);
            return [
                docDbClusterRiskContext.generalInformation,
                docDbClusterRiskContext.size,
                docDbClusterRiskContext.instances,
                docDbClusterRiskContext.sensitive,
                docDbClusterRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}