import { DataTable, DataTableColumn, EmptyMessageText, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, InfoCard } from "../../../../../../../../../../../../common";

type RoutesInfoCardProps = {
    routes: Contract.AzureNetworkRouteTableRoute[];
};

export function RoutesInfoCard({ routes }: RoutesInfoCardProps) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureNetworkRouteTableDefinition.routesInfoCard",
            () => ({
                columns: {
                    destinationIpAddress: "Next Hop IP Address",
                    name: "Name",
                    subnet: "Address Prefix",
                    type: {
                        title: "Next Hop Type",
                        [Contract.TypeNames.AzureNetworkRouteTableRouteType]: {
                            [Contract.AzureNetworkRouteTableRouteType.Internet]: "Internet",
                            [Contract.AzureNetworkRouteTableRouteType.None]: "None",
                            [Contract.AzureNetworkRouteTableRouteType.VirtualAppliance]: "Virtual Appliance",
                            [Contract.AzureNetworkRouteTableRouteType.VirtualNetwork]: "Virtual Network",
                            [Contract.AzureNetworkRouteTableRouteType.VirtualNetworkGateway]: "Virtual Network Gateway"
                        }
                    }
                },
                empty: "No Routes",
                title: "Routes"
            }));
    return (
        <InfoCard title={localization.title()}>
            <DataTable
                emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                fetchItems={() => routes}
                getItemId={(item: Contract.AzureNetworkRouteTableRoute) => _.indexOf(routes, item).toString()}
                sortOptions={{ enabled: false }}
                variant="card">
                <DataTableColumn
                    id={RouteColumnId.Name}
                    itemProperty={(item: Contract.AzureNetworkRouteTableRoute) => item.name}
                    title={localization.columns.name()}/>
                <DataTableColumn
                    id={RouteColumnId.Subnet}
                    itemProperty={
                        (item: Contract.AzureNetworkRouteTableRoute) =>
                            _.isNil(item.subnet)
                                ? item.serviceTag
                                : item.subnet}
                    title={localization.columns.subnet()}/>
                <DataTableColumn
                    id={RouteColumnId.Type}
                    itemProperty={
                        (item: Contract.AzureNetworkRouteTableRoute) =>
                            _.isNil(item.type)
                                ? undefined
                                : localization.columns.type[Contract.TypeNames.AzureNetworkRouteTableRouteType][item.type]()}
                    title={localization.columns.type.title()}/>
                <DataTableColumn
                    id={RouteColumnId.DestinationIpAddress}
                    itemProperty={(item: Contract.AzureNetworkRouteTableRoute) => item.destinationIpAddress}
                    title={localization.columns.destinationIpAddress()}/>
            </DataTable>
        </InfoCard>);
}

enum RouteColumnId {
    DestinationIpAddress = "destinationIpAddress",
    Name = "name",
    Subnet = "subnet",
    Type = "type"
}