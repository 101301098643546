import { AddIcon, DataTable, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableSortDirection, EmptyMessageText, map, StringHelper, useChangeEffect, useLocalization } from "@infrastructure";
import { Button } from "@mui/material";
import _ from "lodash";
import React, { useRef } from "react";
import { OciContext, useSetOciContext } from "../..";
import { Contract, scopeSystemEntityModelStore, StatusCell } from "../../../../../../../../../../../../../../common";
import { useOciOrganizationModelStatusTranslator } from "../../hooks";
import { ActionsCell } from "./components";

export function Table() {
    const organizationModels = scopeSystemEntityModelStore.useGetOciOrganization();
    const setOciContext = useSetOciContext();

    const organizationModelStatusTranslator = useOciOrganizationModelStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.oci.table",
            () => ({
                actions: {
                    add: "Add OCI Tenancy"
                },
                columns: {
                    name: "Name",
                    ocid: "OCID",
                    status: "Status"
                },
                empty: "No OCI tenancy"
            }));

    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => dataTableActionsRef.current!.reset(),
        [organizationModels]);

    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={
                (_filterMap, sort) =>
                    _.orderBy(
                        organizationModels,
                        organizationModel =>
                            map<string, any>(
                                sort?.columnId ?? TableColumnId.Name,
                                {
                                    [TableColumnId.Name]: () => StringHelper.getSortValue(organizationModel.configuration.name),
                                    [TableColumnId.Ocid]: () => StringHelper.getSortValue(organizationModel.configuration.ocid),
                                    [TableColumnId.Status]: () => StringHelper.getSortValue(organizationModelStatusTranslator(organizationModel.status))
                                }),
                        sort?.direction === DataTableSortDirection.Descending
                            ? "desc"
                            : "asc")}
            getItemId={(item: Contract.OciOrganizationModel) => item.configuration.id}>
            <DataTableAction>
                <Button
                    size="small"
                    startIcon={<AddIcon/>}
                    onClick={() => setOciContext(new OciContext(true))}>
                    {localization.actions.add()}
                </Button>
            </DataTableAction>
            <DataTableColumn
                id={TableColumnId.Name}
                itemProperty={(item: Contract.OciOrganizationModel) => item.configuration.name}
                title={localization.columns.name()}/>
            <DataTableColumn
                id={TableColumnId.Ocid}
                itemProperty={(item: Contract.OciOrganizationModel) => item.configuration.ocid}
                title={localization.columns.ocid()}/>
            <DataTableColumn
                id={TableColumnId.Status}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.OciOrganizationModel>) =>
                        <StatusCell
                            statusSeverity={item.statusSeverity}
                            title={organizationModelStatusTranslator(item.status)}/>}
                title={localization.columns.status()}/>
            <DataTableColumn
                id={TableColumnId.Actions}
                render={ActionsCell}/>
        </DataTable>);
}

enum TableColumnId {
    Actions = "actions",
    Name = "name",
    Ocid = "ocid",
    Status = "status"
}