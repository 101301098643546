﻿import { Action0, Dialog, ExecuteOperationCache } from "@infrastructure";
import React from "react";
import { RiskPoliciesType, RiskPolicyItem } from "../../../../../common";
import { Configuration } from "./Configuration";

type ConfigurationDialogProps = {
    item: RiskPolicyItem;
    onClose: Action0;
    riskPoliciesType: RiskPoliciesType;
    scopeId: string;
};

export function ConfigurationDialog({ item, onClose, riskPoliciesType, scopeId }: ConfigurationDialogProps) {
    return (
        <Dialog
            size="large"
            variant="editor"
            onClose={onClose}>
            <ExecuteOperationCache>
                <Configuration
                    item={item}
                    riskPoliciesType={riskPoliciesType}
                    scopeId={scopeId}
                    onClose={onClose}/>
            </ExecuteOperationCache>
        </Dialog>);
}