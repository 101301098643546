import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { useTheme } from "../../../../../../../common/themes";

export function DisableIcon(props: SvgIconProps) {
    const theme = useTheme();
    return (
        <SvgIcon fill="none" height="14" viewBox="0 0 14 14" width="14" {...props}>
            <path
                clipRule="evenodd"
                d="M2.625 3.5C2.625 2.5335 3.4085 1.75 4.375 1.75C5.3415 1.75 6.125 2.5335 6.125 3.5V10.5C6.125 11.4665 5.3415 12.25 4.375 12.25C3.4085 12.25 2.625 11.4665 2.625 10.5V3.5ZM4.375 2.91667C4.05283 2.91667 3.79167 3.17783 3.79167 3.5V10.5C3.79167 10.8222 4.05283 11.0833 4.375 11.0833C4.69717 11.0833 4.95833 10.8222 4.95833 10.5V3.5C4.95833 3.17783 4.69717 2.91667 4.375 2.91667Z"
                fill={theme.palette.text.primary}
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M7.875 3.5C7.875 2.5335 8.6585 1.75 9.625 1.75C10.5915 1.75 11.375 2.5335 11.375 3.5V10.5C11.375 11.4665 10.5915 12.25 9.625 12.25C8.6585 12.25 7.875 11.4665 7.875 10.5V3.5ZM9.625 2.91667C9.30283 2.91667 9.04167 3.17783 9.04167 3.5V10.5C9.04167 10.8222 9.30283 11.0833 9.625 11.0833C9.94717 11.0833 10.2083 10.8222 10.2083 10.5V3.5C10.2083 3.17783 9.94717 2.91667 9.625 2.91667Z"
                fill={theme.palette.text.primary}
                fillRule="evenodd"/>
        </SvgIcon>);
}