import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../../../../../../../../../../../common";

export function useAadTenantModelStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.aadTenants.table.hooks.useAadTenantModelStatusTranslator",
            () => ({
                [Contract.TypeNames.AadTenantModelStatus]: {
                    [Contract.AadTenantModelStatus.ApplicationServicePrincipalDisabled]: "Tenable cloud security connector app is disabled",
                    [Contract.AadTenantModelStatus.ApplicationUnauthorized]: "Missing permissions",
                    [Contract.AadTenantModelStatus.Deleting]: "Deleting, this may take a while...",
                    [Contract.AadTenantModelStatus.TenantNotExist]: "Failed to connect",
                    [Contract.AadTenantModelStatus.Valid]: "Connected"
                }
            }));
    return (status: Contract.AadTenantModelStatus) => localization[Contract.TypeNames.AadTenantModelStatus][status]();
}