import { ActionMenuItem, DeleteIcon, Menu, Message, useLocalization } from "@infrastructure";
import { CircularProgress, Stack } from "@mui/material";
import React, { useState } from "react";
import { useApiKeysContext } from "..";
import { ConfigurationController, Contract, useTheme } from "../../../../../../../common";

export type ActionsCellProps = {
    apiKeyData: Contract.ConfigurationControllerGetApiKeysResponseApiKeyData;
};

export function ActionsCell({ apiKeyData }: ActionsCellProps) {
    const { executeGetApiKeys } = useApiKeysContext();

    const [deleteApiKeyExecuting, setDeleteApiKeyExecuting] = useState(false);
    const [deleteApiKeyError, setDeleteApiKeyError] = useState(false);

    async function deleteApiKey() {
        setDeleteApiKeyExecuting(true);
        setDeleteApiKeyError(false);

        try {
            await ConfigurationController.deleteApiKey(
                new Contract.ConfigurationControllerDeleteApiKeyRequest(
                    apiKeyData.apiKey.id,
                    apiKeyData.scopeId));
            await executeGetApiKeys();
        } catch {
            setDeleteApiKeyError(true);
        }

        setDeleteApiKeyExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.configuration.apiKeys.actionsCell",
            () => ({
                actions: {
                    delete: {
                        error: "Failed to remove {{name}}",
                        prompt: "Are you sure you want to remove {{name}}?",
                        title: "Delete"
                    }
                }
            }));
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}>
            {deleteApiKeyExecuting && (
                <CircularProgress
                    size={theme.spacing(2)}
                    variant="indeterminate"/>)}
            {deleteApiKeyError && (
                <Message
                    level="error"
                    title={localization.actions.delete.error({ name: apiKeyData.apiKey.name })}
                    variant="minimal"/>)}
            <Menu
                itemsOrGetItems={[
                    new ActionMenuItem(
                        () => deleteApiKey(),
                        localization.actions.delete.title(),
                        {
                            confirmOptions: {
                                message: localization.actions.delete.prompt({ name: apiKeyData.apiKey.name })
                            },
                            disabled: deleteApiKeyExecuting,
                            icon: <DeleteIcon/>
                        })
                ]}/>
        </Stack>);
}