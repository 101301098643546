import { useLocalization } from "@infrastructure";
import React from "react";
import { useGcpDefaultResourceInfoItemElements, useGcpDefaultScopeResourceInfoItemElements } from ".";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { GcpSpannerInstanceDatabasesItem } from "../../../../../../../../../../tenants";
import { Info } from "../../../../components";
import { DefinitionOptions, EntityProfileDefinition } from "../../useDefinition";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useGcpSpannerInstanceDefinition(scopeResourceModel: Contract.GcpScopeResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(scopeResourceModel);
    const instanceModel = scopeResourceModel as Contract.GcpSpannerInstanceModel;
    const defaultResourceInfoItemElements = useGcpDefaultResourceInfoItemElements(scopeResourceModel);
    const defaultScopeResourceInfoItemElements = useGcpDefaultScopeResourceInfoItemElements(scopeResourceModel);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpSpannerInstanceDefinition",
            () => ({
                info: {
                    items: {
                        databases: "Spanner Databases"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...defaultScopeResourceInfoItemElements,
                    <InfoItem
                        key="databases"
                        title={localization.info.items.databases()}
                        value={
                            <GcpSpannerInstanceDatabasesItem
                                databasesCount={instanceModel.databaseCount}
                                instanceId={instanceModel.id}/>}/>]}
                options={options?.infoOptions}>
            </Info>
    });
}