import { Link, NumberFormatter, useLocalization } from "@infrastructure";
import { Stack, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { scopeNodeModelStore, UrlHelper, useScopeNavigationViewContext } from "../../../../../../../../../../common";

export function ContainerImage() {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const scopeNodeMap = scopeNodeModelStore.useGetActiveScopeNodeMap();

    const localization =
        useLocalization(
            "views.customer.configuration.workloadAnalysis.containerImageRepositories.containerImage",
            () => ({
                contactSupport: "contact support",
                description: "Workload Protection is enabled for container images whose size is up to {{containerImageSize}}. For more information, or to change this value, {{contactSupportLink}}.",
                title: "Maximum Container Image Size"
            }));

    const inheritedContainerImageMaxSize =
        useMemo(
            () => {
                const parentScopeIds = scopeNodeMap[scopeNodeModel.configuration.id].parentScopeIds;

                for (const scopeId of [scopeNodeModel.configuration.id, ...parentScopeIds]) {
                    if (!_.isNil(scopeNodeMap[scopeId].scopeNodeModel.configuration.scopeSections.workloadAnalysis.containerImageMaxSize)) {
                        return scopeNodeMap[scopeId].scopeNodeModel.configuration.scopeSections.workloadAnalysis.containerImageMaxSize;
                    }
                }

                return undefined;
            },
            [scopeNodeModel]);

    const theme = useTheme();
    return (
        <Stack
            sx={{ height: theme.spacing(14) }}>
            <Typography
                sx={{ marginBottom: theme.spacing(1) }}
                variant="h4">
                {localization.title()}
            </Typography>
            <Typography
                sx={{ marginBottom: theme.spacing(2) }}>
                {localization.description({
                    contactSupportLink:
                        <Link
                            urlOrGetUrl={UrlHelper.supportUrl}
                            variant="external">
                            {localization.contactSupport()}
                        </Link>,
                    containerImageSize: NumberFormatter.storage(inheritedContainerImageMaxSize!)
                })}
            </Typography>
        </Stack>);
}