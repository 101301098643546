import { useExecuteOperation, useLocalization } from "@infrastructure";
import { Grid } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { ContentPage, ContentPages, ContentPagesItem, CoverPage, PageData } from "..";
import { Contract, LicensingHelper, ReportController, TenantHelper, tenantModelStore } from "../../../../../../common";
import { ComplianceContentPages, EntityPublicDataAndSecretsContentPages, IdentityDistributionSection, OpenBehaviorRiskSection, PrincipalExcessivePermissionDistributionSection, RiskCombinationsPages, RiskSection, RiskTrendsSection, TenantSection } from "./components";

type DashboardReportProps = {
    scopeId: string;
};

export function DashboardReport({ scopeId }: DashboardReportProps) {
    const [{
        entityEntitiesViewTypeNameToPublicRiskSeverityStatsMap,
        identityExcessivePermissionActionStats,
        identityPermissionActionSeverityToSummaryMap,
        principalRiskCategoryToSummaryMap,
        publicEntityRiskSeverityStats,
        riskCombinationToDataMap,
        secretExistsRiskPolicyConfigurationTypeNameToEntityTypeNameToDataMap,
        severityToRiskPolicyCategoryToCountMap,
        timeFrameToCreatedBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap,
        timeFrameToRiskTrendsDataMap
    }] =
        useExecuteOperation(
            DashboardReport,
            () => ReportController.getDashboardReport(new Contract.ReportControllerGetDashboardReportRequest(scopeId)));

    const behaviorRiskCount =
        _(severityToRiskPolicyCategoryToCountMap).
            map(riskPolicyCategoryToCountMap => riskPolicyCategoryToCountMap[Contract.RiskPolicyCategory.Behavior] ?? 0).
            sum();

    const excessivePermissionsEnabled = tenantModelStore.useGetFilteredActiveCloudProviderTenantsExcessivePermissionsEnabled();
    const tenantTypeToCloudProviderTenantModelsMap =
        _(tenantModelStore.useGetFilteredCloudProviderTenants(TenantHelper.DashboardTenantTypes)).
            filter(tenantModel => !_.isNil(tenantModel.state.orchestration.orchestrationTypeToDataMap[Contract.OrchestrationType.Analysis]?.previousTimeRange?.endTime)).
            groupBy(tenantModel => tenantModel.tenantType).
            value();

    const localization =
        useLocalization(
            "views.system.exportReport.dashboardReport",
            () => ({
                subtitle: "This report provides a comprehensive overview of your cloud environment, giving you a snapshot of your accounts and overall security posture. Gain insight into common security concerns such as excessive permissions, public resources, and compliance. Track important metrics over time to see what progress has been made, or if there are areas that need remediation.",
                title: "Management Report"
            }));

    const [entityPublicDataAndSecretsContentPageCount, setEntityPublicDataAndSecretsContentPageCount] = useState(0);
    const [complianceContentPageCount, setComplianceContentPageCount] = useState(0);
    const [riskCombinationSectionPageCount, setRiskCombinationSectionPageCount] = useState(0);
    const [riskTrendsSectionPageCount, setRiskTrendsSectionPageCount] = useState(0);

    const riskTrendsSectionPageNumber = 2 + riskCombinationSectionPageCount;
    const complianceContentPageNumber = riskTrendsSectionPageNumber + riskTrendsSectionPageCount;
    const principalAccessPageNumber = complianceContentPageNumber + complianceContentPageCount;
    const entityPublicDataAndSecretsContentPageNumber = principalAccessPageNumber + 1;
    const openBehaviorRiskSectionPageNumber = entityPublicDataAndSecretsContentPageNumber + entityPublicDataAndSecretsContentPageCount;
    return (
        <PageData reportTitle={localization.title()}>
            <CoverPage
                reportType={Contract.ReportType.Dashboard}
                scopeId={scopeId}
                subtitle={localization.subtitle()}/>
            <ContentPage number={1}>
                <Grid
                    container={true}
                    direction="column"
                    spacing={1}>
                    <Grid item={true}>
                        <TenantSection tenantTypeToTenantModelsMap={tenantTypeToCloudProviderTenantModelsMap}/>
                    </Grid>
                    <Grid item={true}>
                        <RiskSection severityToRiskPolicyCategoryToCountMap={severityToRiskPolicyCategoryToCountMap}/>
                    </Grid>
                </Grid>
            </ContentPage>
            <RiskCombinationsPages
                number={2}
                riskCombinationToDataMap={riskCombinationToDataMap}
                onPageCountChanged={pageCount => setRiskCombinationSectionPageCount(pageCount)}/>
            <ContentPages
                number={riskTrendsSectionPageNumber}
                onPageCountChanged={pageCount => setRiskTrendsSectionPageCount(pageCount)}>
                <ContentPagesItem>
                    <RiskTrendsSection timeFrameToRiskTrendsDataMap={timeFrameToRiskTrendsDataMap}/>
                </ContentPagesItem>
            </ContentPages>
            {LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm) &&
                <ComplianceContentPages
                    number={complianceContentPageNumber}
                    scopeId={scopeId}
                    onPageCountChanged={pageCount => setComplianceContentPageCount(pageCount)}/>}
            <ContentPage number={principalAccessPageNumber}>
                <IdentityDistributionSection identityPermissionActionSeverityToSummaryMap={identityPermissionActionSeverityToSummaryMap}/>
                {excessivePermissionsEnabled &&
                    <PrincipalExcessivePermissionDistributionSection
                        identityExcessivePermissionActionStats={identityExcessivePermissionActionStats}
                        principalRiskCategoryToSummaryMap={principalRiskCategoryToSummaryMap}/>}
            </ContentPage>
            <EntityPublicDataAndSecretsContentPages
                entityEntitiesViewTypeNameToPublicRiskSeverityStatsMap={entityEntitiesViewTypeNameToPublicRiskSeverityStatsMap}
                number={entityPublicDataAndSecretsContentPageNumber}
                publicEntityRiskSeverityStats={publicEntityRiskSeverityStats}
                secretExistsRiskPolicyConfigurationTypeNameToEntityTypeNameToDataMap={secretExistsRiskPolicyConfigurationTypeNameToEntityTypeNameToDataMap}
                onPageCountChanged={pageCount => setEntityPublicDataAndSecretsContentPageCount(pageCount)}/>
            <ContentPage number={openBehaviorRiskSectionPageNumber}>
                <OpenBehaviorRiskSection
                    riskCount={behaviorRiskCount}
                    timeFrameToCreatedBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap={timeFrameToCreatedBehaviorRiskPolicyConfigurationTypeNameToRiskCountMap}
                    timeFrameToRiskTrendsDataMap={timeFrameToRiskTrendsDataMap}/>
            </ContentPage>
        </PageData>);
}