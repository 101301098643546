﻿import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useGcpCommonKubernetesNamespaceResourceInfoItemElements, useGcpDefaultResourceInfoItemElements } from "..";
import { Contract } from "../../../../../../../../../../common";
import { KubernetesResourceDataHelper } from "../../../../../../utilities";
import { EntitiesInfoItem, Info } from "../../../../components";
import { KubernetesYamlInfoCard } from "../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useGcpKubernetesNamespaceRoleBindingDefinition(resourceModel: Contract.GcpResourceModel, options?: DefinitionOptions) {
    const commonKubernetesNamespaceResourceInfoItemElements = useGcpCommonKubernetesNamespaceResourceInfoItemElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultPropertyInfoElements = useGcpDefaultResourceInfoItemElements(resourceModel);
    const namespaceRoleBinding = resourceModel.entity as Contract.GcpKubernetesNamespaceRoleBinding;
    const namespaceRoleBindingModel = resourceModel as Contract.GcpKubernetesNamespaceRoleBindingModel;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpKubernetesNamespaceRoleBindingDefinition",
            () => ({
                info: {
                    principalIdReferences: "Principals",
                    roleIdReference: "Role"
                },
                tabs: {
                    yaml: "YAML"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <KubernetesYamlInfoCard rawYaml={namespaceRoleBinding.data.rawYaml!}/>,
                localization.tabs.yaml(),
                "yaml")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultPropertyInfoElements}
                entityPropertyInfoItemElements={[
                    commonKubernetesNamespaceResourceInfoItemElements.annotationsInfoItemElement,
                    commonKubernetesNamespaceResourceInfoItemElements.clusterInfoItemElement,
                    commonKubernetesNamespaceResourceInfoItemElements.namespaceInfoItemElement,
                    <EntitiesInfoItem
                        entityIdsOrModels={namespaceRoleBindingModel.principalIdReferences}
                        entityTypeName={Contract.TypeNames.IKubernetesPrincipal}
                        key="principalIdReferences"
                        title={localization.info.principalIdReferences()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={namespaceRoleBindingModel.roleIdReference}
                        entityTypeName={Contract.TypeNames.IKubernetesRole}
                        key="roleIdReference"
                        title={localization.info.roleIdReference()}/>
                ]}
                options={options?.infoOptions}>
            </Info>,
        sensitive: KubernetesResourceDataHelper.entitySensitiveToggle(namespaceRoleBinding.data)
    });
}