import { useLocalization } from "@infrastructure";
import { Contract } from "../../../common";

export function useGcpScopeResourceAccessLevelTranslator() {
    const localization =
        useLocalization(
            "tenants.gcp.hooks.useGcpScopeResourceAccessLevelTranslator",
            () => ({
                [Contract.TypeNames.GcpScopeResourceAccessLevel]: {
                    [Contract.GcpScopeResourceAccessLevel.External]: "External",
                    [Contract.GcpScopeResourceAccessLevel.Internal]: "Internal",
                    [Contract.GcpScopeResourceAccessLevel.Public]: "Public"
                }
            }));

    return (accessLevel: Contract.GcpScopeResourceAccessLevel) =>
        localization[Contract.TypeNames.GcpScopeResourceAccessLevel][accessLevel]();
}