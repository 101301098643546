import { Action1, Optional } from "@infrastructure";
import _ from "lodash";
import React, { ReactNode } from "react";
import { Contract } from "../../../..";
import { EntityIds, EntityModels } from "./components";

type ListProps = {
    entityIdsOrModels: string[] | Contract.EntityModel[];
    entityTypeName: string;
    onSelectionChanged?: Action1<string>;
    renderEntityStats?: (entityId: string) => ReactNode;
    searchText: Optional<string>;
    selectedEntityId: Optional<string>;
};

export function List({ entityIdsOrModels, ...props }: ListProps) {
    return _.isString(entityIdsOrModels[0])
        ? <EntityIds
            entityIds={entityIdsOrModels as string[]}
            {...props}/>
        : <EntityModels
            entityModels={entityIdsOrModels as Contract.EntityModel[]}
            {...props}/>;
}