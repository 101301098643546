﻿import { TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, entityModelStore, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { useAwsAcmCertificateKeyTypeTranslator } from "../../../../../../../../../../tenants";
import { useAwsAcmCertificateStatusTranslator, useAwsAcmCertificateTypeTranslator } from "../../../../../../hooks";
import { EntityTypeNameGroupsInfoCard, Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";
import { useAwsDefaultResourceInfoItemElements } from "./useAwsDefaultResourceInfoItemElements";

export function useAwsAcmCertificateDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const certificate = resourceModel.entity as Contract.AwsAcmCertificate;
    const certificateModel = resourceModel as Contract.AwsAcmCertificateModel;
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);

    const acmCertificateKeyTypeTranslator = useAwsAcmCertificateKeyTypeTranslator();
    const acmCertificateStatusTranslator = useAwsAcmCertificateStatusTranslator();
    const acmCertificateTypeTranslator = useAwsAcmCertificateTypeTranslator();
    const attachedResourceModels = entityModelStore.useGet(certificateModel.attachedResourceIdReferences);

    const resourceTypeNameToIdsMap =
        useMemo(
            () =>
                _(attachedResourceModels).
                    groupBy(attachedResourceModel => attachedResourceModel.entity.typeName).
                    mapValues(
                        resourceModels =>
                            _.map(
                                resourceModels,
                                resourceModel => resourceModel.entity.id)).
                    value(),
            [attachedResourceModels]);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsAcmCertificateDefinition",
            () => ({
                info: {
                    cards: {
                        resources: {
                            emptyMessage: "No resources",
                            title: "Resources"
                        }
                    },
                    items: {
                        alternativeDomainNames: "Alternative Domain Names",
                        attachedResourceExists: {
                            false: "No",
                            title: "In Use",
                            true: "Yes"
                        },
                        domainName: "Domain Name",
                        expirationTime: "Expiration Time",
                        importedAt: "Imported",
                        issuedTime: "Issued",
                        keyType: "Key Algorithm",
                        renewalEligibility: {
                            title: "Renewal Eligibility",
                            [Contract.TypeNames.AwsAcmCertificateRenewalEligibility]: {
                                [Contract.AwsAcmCertificateRenewalEligibility.Eligible]: "Eligible",
                                [Contract.AwsAcmCertificateRenewalEligibility.Ineligible]: "Ineligible"
                            }
                        },
                        revokedAt: "Revoked",
                        status: "Status",
                        type: "Certificate Type"
                    }
                },
                tabs: {
                    resources: "Resources"
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="certificateType"
                        location="all"
                        title={localization.info.items.type()}
                        value={acmCertificateTypeTranslator(certificate.type)}/>,
                    <InfoItem
                        key="certificateKeyType"
                        title={localization.info.items.keyType()}
                        value={acmCertificateKeyTypeTranslator(certificate.keyType)}/>,
                    <InfoItem
                        key="certificateStatus"
                        location="all"
                        title={localization.info.items.status()}
                        value={acmCertificateStatusTranslator(certificate.status)}/>,
                    <InfoItem
                        key="certificateIssuedTime"
                        title={localization.info.items.issuedTime()}
                        value={
                            _.isNil(certificate.issuedTime)
                                ? undefined
                                : TimeFormatter.profileFormatDateTime(certificate.issuedTime)}/>,
                    <InfoItem
                        key="certificateExpirationTime"
                        title={localization.info.items.expirationTime()}
                        value={
                            _.isNil(certificate.expirationTime)
                                ? undefined
                                : TimeFormatter.profileFormatDateTime(certificate.expirationTime)}/>,
                    <InfoItem
                        key="certificateDomainName"
                        title={localization.info.items.domainName()}
                        value={certificate.domainName}/>,
                    <VerticalTopItemsInfoItem
                        items={certificate.alternativeDomainNames}
                        key="certificateAlternativeDomainNames"
                        title={localization.info.items.alternativeDomainNames()}/>,
                    <InfoItem
                        key="certificateRevokedAt"
                        title={localization.info.items.revokedAt()}
                        value={
                            _.isNil(certificate.revokedTime)
                                ? undefined
                                : TimeFormatter.profileFormatDateTime(certificate.revokedTime)}/>,
                    <InfoItem
                        key="certificateImportedAt"
                        title={localization.info.items.importedAt()}
                        value={
                            _.isNil(certificate.importTime)
                                ? undefined
                                : TimeFormatter.profileFormatDateTime(certificate.importTime)}/>,
                    <InfoItem
                        key="certificateRenewalEligibility"
                        title={localization.info.items.renewalEligibility.title()}
                        value={localization.info.items.renewalEligibility[Contract.TypeNames.AwsAcmCertificateRenewalEligibility][certificate.renewalEligibility]()}/>,
                    <InfoItem
                        key="attachedResourceExists"
                        location="all"
                        title={localization.info.items.attachedResourceExists.title()}
                        value={
                            certificateModel.attachedResourceExists
                                ? localization.info.items.attachedResourceExists.true()
                                : localization.info.items.attachedResourceExists.false()}/>
                ]}
                options={options?.infoOptions}>
                <EntityTypeNameGroupsInfoCard
                    emptyMessage={localization.info.cards.resources.emptyMessage()}
                    entityTypeNameToIdsMap={resourceTypeNameToIdsMap}
                    title={localization.info.cards.resources.title()}/>
            </Info>
    });
}