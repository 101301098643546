﻿import { ItemSelector, StringHelper, TextViewer, useLocalization } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { useState } from "react";
import { AwsLambdaFunctionConfigurationAlias } from "../../../../../../../../../../../../../../common/controllers/types.generated";

export type AliasPoliciesSectionProps = {
    aliasMap: Dictionary<AwsLambdaFunctionConfigurationAlias>;
};

export function AliasPolicySection({ aliasMap }: AliasPoliciesSectionProps) {
    const aliasNames =
        _(aliasMap).
            keys().
            orderBy(aliasName => StringHelper.getSortValue(aliasName)).
            value();
    const [selectedAlias, setSelectedAlias] = useState(aliasNames[0]);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsLambdaFunctionConfigurationPublicAccessExistsRiskDefinition.aliasPolicySection",
            () => ({
                policyDocument: "Policy"
            }));

    return (
        <Stack
            spacing={4}
            sx={{ height: "100%" }}>
            <ItemSelector
                dense={true}
                fullWidth={true}
                items={aliasNames}
                selectedItem={selectedAlias}
                onSelectedItemChanged={publicPolicyAliasName => setSelectedAlias(publicPolicyAliasName)}>
                {publicAccessLevelAliasName => publicAccessLevelAliasName}
            </ItemSelector>
            <Stack
                spacing={1}
                sx={{ flex: 1 }}>
                <Typography variant="h5">
                    {localization.policyDocument()}
                </Typography>
                <Box sx={{ flex: 1 }}>
                    <TextViewer
                        format="json"
                        height="100%"
                        text={aliasMap[selectedAlias].policyDocument!.raw}/>
                </Box>
            </Stack>
        </Stack>);
}