import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoCard, InfoItem, useEntityTypeNameTranslator, VerticalTopItemsInfoItem } from "../../../../../../../../../../common";
import { OciLoadBalancingLoadBalancerBackendsTable, OciLoadBalancingLoadBalancerListenersTable } from "../../../../../../../../../../tenants";
import { useOciLoadBalancingLoadBalancerStatusTranslator } from "../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements, useOciDefaultResourceInfoItemElements } from "../.";

export function useOciLoadBalancingLoadBalancerDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);
    const loadBalancerModel = resourceModel as Contract.OciLoadBalancingLoadBalancerModel;
    const loadBalancer = loadBalancerModel.entity as Contract.OciLoadBalancingLoadBalancer;

    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const loadBalancerStatusTranslator = useOciLoadBalancingLoadBalancerStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciLoadBalancingLoadBalancerDefinition",
            () => ({
                info: {
                    items: {
                        cards: {
                            backends: "Backends",
                            listeners: "Listeners"
                        },
                        external: {
                            false: "Private",
                            title: "Visibility Type",
                            true: "Public"
                        },
                        ipAddresses: {
                            private: "Private IP",
                            public: "Public IP"
                        },
                        logsEnabled: {
                            false: "Disabled",
                            title: {
                                access: "Access Logs",
                                error: "Error Logs"
                            },
                            true: "Enabled"
                        },
                        status: "State",
                        webApplicationFirewall: "Web Application Firewall"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={loadBalancerStatusTranslator(loadBalancer.status)}/>,
                    <InfoItem
                        key="external"
                        title={localization.info.items.external.title()}
                        value={
                            loadBalancer.external
                                ? localization.info.items.external.true()
                                : localization.info.items.external.false()}/>,
                    <VerticalTopItemsInfoItem
                        items={loadBalancer.publicIpAddresses}
                        key="publicIpAddress"
                        title={localization.info.items.ipAddresses.public()}/>,
                    <VerticalTopItemsInfoItem
                        items={loadBalancer.privateIpAddresses}
                        key="privateIpAddress"
                        title={localization.info.items.ipAddresses.private()}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={loadBalancerModel.vcnIdReference}
                        entityTypeName={Contract.TypeNames.OciNetworkingVcn}
                        key="vcn"
                        title={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingVcn)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={loadBalancerModel.subnetIdReferences}
                        entityTypeName={Contract.TypeNames.OciNetworkingSubnet}
                        key="subnets"
                        title={entityTypeNameTranslator(Contract.TypeNames.OciNetworkingSubnet)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={loadBalancerModel.securityGroupIdReferences}
                        entityTypeName={Contract.TypeNames.OciNetworkingNetworkSecurityGroup}
                        key="securityGroups"
                        title={
                            entityTypeNameTranslator(
                                Contract.TypeNames.OciNetworkingNetworkSecurityGroup,
                                { count: 0 })}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={loadBalancerModel.webApplicationFirewallIdReference}
                        entityTypeName={Contract.TypeNames.OciWebAppFirewallGenericResource}
                        key="webApplicationFirewall"
                        title={localization.info.items.webApplicationFirewall()}/>,
                    <InfoItem
                        key="accessLogs"
                        title={localization.info.items.logsEnabled.title.access()}
                        value={
                            loadBalancerModel.accessLogsEnabled
                                ? localization.info.items.logsEnabled.true()
                                : localization.info.items.logsEnabled.false()}/>,
                    <InfoItem
                        key="errorLogs"
                        title={localization.info.items.logsEnabled.title.error()}
                        value={
                            loadBalancerModel.errorLogsEnabled
                                ? localization.info.items.logsEnabled.true()
                                : localization.info.items.logsEnabled.false()}/>
                ]}
                options={options?.infoOptions}>
                <InfoCard title={localization.info.items.cards.backends()}>
                    <OciLoadBalancingLoadBalancerBackendsTable
                        backendIpAddressToInstanceIdMap={loadBalancerModel.backendIpAddressToInstanceIdMap}
                        backendSets={_.values(loadBalancer.backendSetNameToBackendSetMap)}/>
                </InfoCard>
                <InfoCard title={localization.info.items.cards.listeners()}>
                    <OciLoadBalancingLoadBalancerListenersTable
                        backendIpAddressToInstanceIdMap={loadBalancerModel.backendIpAddressToInstanceIdMap}
                        backendSetNameToBackendSetMap={loadBalancer.backendSetNameToBackendSetMap}
                        listeners={loadBalancer.listeners}/>
                </InfoCard>
            </Info>
    });
}