import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { RiskPoliciesView, RiskPolicyItem } from "../../../../../../../../../../common";
import { useCodeRiskPolicyBuiltInDefinition } from "./hooks";


export function useCodeRiskPolicyDefinition(view: RiskPoliciesView, item: RiskPolicyItem, scopeId: string) {
    const useDefinition =
        useMemo(
            () => {
                switch (view) {
                    case RiskPoliciesView.BuiltIn:
                        return useCodeRiskPolicyBuiltInDefinition;
                    default:
                        throw new UnexpectedError("useCodeRiskPolicyDefinition.view", view);
                }
            },
            [view]);

    return useDefinition(item, scopeId);
}