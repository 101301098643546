import { AddIcon, DataTable, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableSortDirection, EmptyMessageText, InlineItems, Link, map, StringHelper, useChangeEffect, useLocalization } from "@infrastructure";
import { Button, Typography } from "@mui/material";
import _ from "lodash";
import React, { useRef } from "react";
import { Contract, scopeSystemEntityModelStore, StatusCell } from "../../../../../../../../../../../../../../common";
import { CodeConsoleUrlHelper } from "../../../../../../../../../../../../../../tenants";
import { useCodeOrganizationModelStatusTranslator } from "../../../../hooks";
import { AzureDevOpsContext, useSetAzureDevOpsContext } from "../../AzureDevOps";
import { ActionsCell } from "./components";

export function Table() {
    const organizationModels = scopeSystemEntityModelStore.useGetCodeOrganization(Contract.CodeTenantType.AzureDevOps);
    const setAzureDevOpsContext = useSetAzureDevOpsContext();

    const codeOrganizationModelStatusTranslator = useCodeOrganizationModelStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeOrganizationItems.azureDevOps.table",
            () => ({
                actions: {
                    add: "Add Azure DevOps Organization"
                },
                columns: {
                    name: "Name",
                    status: "Status",
                    tenants: {
                        all: "All",
                        title: "Repositories",
                        value: [
                            "1 repository",
                            "{{count | NumberFormatter.humanize}} repositories"
                        ]
                    },
                    url: "URL"
                },
                empty: "No Azure DevOps organizations"
            }));

    const dataTableActionsRef = useRef<DataTableActions>();
    useChangeEffect(
        () => {
            dataTableActionsRef.current!.reset();
        },
        [organizationModels]);

    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
            fetchItems={
                (_filterMap, sort) =>
                    _.orderBy(
                        organizationModels,
                        organizationModel =>
                            map<string, any>(
                                sort?.columnId ?? TableColumnId.Name,
                                {
                                    [TableColumnId.Name]: () => StringHelper.getSortValue(organizationModel.configuration.name),
                                    [TableColumnId.Status]: () => StringHelper.getSortValue(codeOrganizationModelStatusTranslator(organizationModel.status).title)
                                }),
                        sort?.direction === DataTableSortDirection.Descending
                            ? "desc"
                            : "asc")}
            getItemId={(item: Contract.CodeOrganizationModel) => item.configuration.id}>
            <DataTableAction>
                <Button
                    size="small"
                    startIcon={<AddIcon/>}
                    onClick={() => setAzureDevOpsContext(new AzureDevOpsContext(true))}>
                    {localization.actions.add()}
                </Button>
            </DataTableAction>
            <DataTableColumn
                id={TableColumnId.Name}
                itemProperty={(item: Contract.CodeOrganizationModel) => item.configuration.name}
                title={localization.columns.name()}/>
            <DataTableColumn
                id={TableColumnId.Url}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.CodeOrganizationModel>) =>
                        <Link
                            urlOrGetUrl={
                                CodeConsoleUrlHelper.getCodeOrganizationUrl(
                                    Contract.CodeTenantType.AzureDevOps,
                                    item.configuration.name,
                                    item.endpoint)}
                            variant="external"
                            onClick={event => event.stopPropagation()}/>}
                sortOptions={{ enabled: false }}
                title={localization.columns.url()}/>
            <DataTableColumn
                id={TableColumnId.Tenants}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.CodeOrganizationModel>) => {
                        const tenantPaths = item.configuration.memberSelection?.rawIds;
                        return !_.isNil(tenantPaths)
                            ? <InlineItems
                                items={tenantPaths}
                                namePluralizer={localization.columns.tenants.value}
                                variant="itemCountAndType"/>
                            : <Typography>
                                {localization.columns.tenants.all()}
                            </Typography>;
                    }}
                sortOptions={{ enabled: false }}
                title={localization.columns.tenants.title()}/>
            <DataTableColumn
                id={TableColumnId.Status}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.CodeOrganizationModel>) =>
                        <StatusCell
                            statusSeverity={item.statusSeverity}
                            title={codeOrganizationModelStatusTranslator(item.status).title}
                            tooltip={codeOrganizationModelStatusTranslator(item.status).tooltip}/>}
                title={localization.columns.status()}/>
            <DataTableColumn
                id={TableColumnId.Actions}
                render={ActionsCell}/>
        </DataTable>);
}

enum TableColumnId {
    Actions = "actions",
    Name = "name",
    Status = "status",
    Tenants = "tenants",
    Url = "url"
}