import { InlineCopyToClipboardText, Link, Step, Steps, TextSnippet, TextSnippetItem, useLocalization } from "@infrastructure";
import React from "react";
import { useTheme } from "../../../../../../../../../../../../../common";
import { useAddOrEditContext } from "../../../index";
import { defaultSinkPubSubRawShortId, defaultSinkPubSubSubscriptionRawShortId, useSinkPubSubSubscriptionRawIdStep } from "../hooks";

export function Manual() {
    const { gciTenantModel, organizationRawShortId, rawShortNameId, tenantOnboardingInfo } = useAddOrEditContext();

    const localization =
        useLocalization(
            "views.customer.scopes.hooks.useDefinition.hooks.useGcpDefinition.addOrEdit.sinkPubSubSubscriptionItem.manual",
            () => ({
                steps: {
                    step1: "Log into your **Google Cloud Console**.",
                    step2: {
                        steps: {
                            step1: {
                                consoleLink: "Pub/Sub console",
                                text: "Navigate to the {{consoleLink}} of any project, and then click Topics on the left hand menu."
                            },
                            step2: "Click **+ CREATE TOPIC**.",
                            step3: "Enter the following **Topic ID**: {{topicRawShortName}}",
                            step4: "Clear the **Add a default subscription** checkbox.",
                            step5: "Select the **Enable message retention** checkbox, and then set the duration to **{{pubSubMessageRetentionDayCount}} days** (at least).",
                            step6: "Click **CREATE**."
                        },
                        text: "Create a **Pub/Sub topic** by following these steps:"
                    },
                    step3: {
                        steps: {
                            step1: {
                                consoleLink: "Logging console",
                                text: "Navigate to the {{consoleLink}} of the **project you want to onboard**, and then click **Log Router** on the left hand menu."
                            },
                            step2: "Click **CREATE SINK**.",
                            step3: "Enter the following **Sink Name** and then click **NEXT**: {{sinkRawShortName}}",
                            step4: "For the **sink service**, select **Cloud Pub/Sub topic**.",
                            step5: "For the **Cloud Pub/Sub topic**, select the topic you created in the previous step (step B), and then click **NEXT**.",
                            step6: {
                                snippet: "Inclusion filter",
                                text: "Copy and paste the following into the **inclusion filter**, and then click **NEXT**:"
                            },
                            step7: "Click **+ ADD EXCLUSION**.",
                            step8: {
                                snippet: "Exclusion filter",
                                text: "Enter {{sinkExclusionFilterName}} as the **Exclusion filter name**, and then copy and paste the following into the **exclusion filter**:"
                            },
                            step9: "Click **CREATE SINK**."
                        },
                        text: "**Create a log sink** by following these steps:"
                    },
                    step4: {
                        steps: {
                            step1: {
                                consoleLink: "Pub/Sub console",
                                text: "Navigate to the {{consoleLink}}, and click on the topic you created earlier in Step B (e.g. **{{defaultSinkPubSubRawShortId}}**)"
                            },
                            step2: "In the **SUBSCRIPTIONS** tab, click **CREATE SUBSCRIPTION**.",
                            step3: "Enter the following **Subscription ID**: {{sinkPubSubSubscriptionRawShortId}}.",
                            step4: "Set the **Delivery type** to **Pull**.",
                            step5: "Set the **Message retention duration** to **{{pubSubMessageRetentionDayCount}} days** (at least).",
                            step6: "Set the **Expiration period** to **Never expire**.",
                            step7: "Set the **Retry policy** to **Retry after exponential backoff delay** (Keep the default min/max values).",
                            step8: "Click **CREATE**.",
                            step9: "In the Info Panel of the newly created subscription, under **PERMISSIONS**, click **+ADD PRINCIPAL**, and then enter the following New principals: {{serviceAccountMail}}.",
                            step10: "Assign the following role: {{roleName}}.",
                            step11: "Click **SAVE**."
                        },
                        text: "**Create a Pub/Sub subscription** by following these steps:"
                    }
                }
            }));

    const sinkPubSubSubscriptionRawIdStep = useSinkPubSubSubscriptionRawIdStep();
    const theme = useTheme();
    return (
        <Steps variant="letters">
            {localization.steps.step1()}
            {new Step(
                localization.steps.step2.text(),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {localization.steps.step2.steps.step1.text({
                                consoleLink:
                                    <Link
                                        urlOrGetUrl={`https://console.cloud.google.com/projectselector2/cloudpubsub/topic/list?organizationId=${organizationRawShortId}`}
                                        variant="external">
                                        {localization.steps.step2.steps.step1.consoleLink()}
                                    </Link>
                            })}
                            {localization.steps.step2.steps.step2()}
                            {localization.steps.step2.steps.step3({
                                topicRawShortName: <InlineCopyToClipboardText text={defaultSinkPubSubRawShortId}/>
                            })}
                            {localization.steps.step2.steps.step4()}
                            {localization.steps.step2.steps.step5({ pubSubMessageRetentionDayCount: tenantOnboardingInfo.pubSubMessageRetentionDayCount })}
                            {localization.steps.step2.steps.step6()}
                        </Steps>
                })}
            {new Step(
                localization.steps.step3.text(),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {localization.steps.step3.steps.step1.text({
                                consoleLink:
                                    <Link
                                        urlOrGetUrl={`https://console.cloud.google.com/logs/router?project=${rawShortNameId}`}
                                        variant="external">
                                        {localization.steps.step3.steps.step1.consoleLink()}
                                    </Link>
                            })}
                            {localization.steps.step3.steps.step2()}
                            {localization.steps.step3.steps.step3({
                                sinkRawShortName: <InlineCopyToClipboardText text={defaultSinkPubSubRawShortId}/>
                            })}
                            {localization.steps.step3.steps.step4()}
                            {localization.steps.step3.steps.step5()}
                            {new Step(
                                localization.steps.step3.steps.step6.text(),
                                {
                                    contentElement:
                                        <TextSnippet
                                            height={theme.spacing(15)}
                                            items={[
                                                new TextSnippetItem(
                                                    tenantOnboardingInfo.sinkInclusionFilter,
                                                    localization.steps.step3.steps.step6.snippet())
                                            ]}/>
                                })}
                            {localization.steps.step3.steps.step7()}
                            {new Step(
                                localization.steps.step3.steps.step8.text({
                                    sinkExclusionFilterName: <InlineCopyToClipboardText text="exclude-k8s-internal"/>
                                }),
                                {
                                    contentElement:
                                        <TextSnippet
                                            height={theme.spacing(15)}
                                            items={[
                                                new TextSnippetItem(
                                                    tenantOnboardingInfo.sinkExclusionFilter,
                                                    localization.steps.step3.steps.step8.snippet())
                                            ]}/>
                                })}
                            {localization.steps.step3.steps.step9()}
                        </Steps>
                })}
            {new Step(
                localization.steps.step4.text(),
                {
                    contentElement:
                        <Steps variant="plainNumbers">
                            {localization.steps.step4.steps.step1.text({
                                consoleLink:
                                    <Link
                                        urlOrGetUrl={`https://console.cloud.google.com/cloudpubsub/topic/list?project=${rawShortNameId}`}
                                        variant="external">
                                        {localization.steps.step4.steps.step1.consoleLink()}
                                    </Link>,
                                defaultSinkPubSubRawShortId
                            })}
                            {localization.steps.step4.steps.step2()}
                            {localization.steps.step4.steps.step3({
                                sinkPubSubSubscriptionRawShortId:
                                    <InlineCopyToClipboardText text={defaultSinkPubSubSubscriptionRawShortId}/>
                            })}
                            {localization.steps.step4.steps.step4()}
                            {localization.steps.step4.steps.step5({ pubSubMessageRetentionDayCount: tenantOnboardingInfo.pubSubMessageRetentionDayCount })}
                            {localization.steps.step4.steps.step6()}
                            {localization.steps.step4.steps.step7()}
                            {localization.steps.step4.steps.step8()}
                            {localization.steps.step4.steps.step9({
                                serviceAccountMail:
                                    <InlineCopyToClipboardText text={gciTenantModel!.configuration.serviceAccountMail}/>
                            })}
                            {localization.steps.step4.steps.step10({
                                roleName: <InlineCopyToClipboardText text="Pub/Sub Subscriber"/>
                            })}
                            {localization.steps.step4.steps.step11()}
                        </Steps>
                })}
            {sinkPubSubSubscriptionRawIdStep}
        </Steps>);
}