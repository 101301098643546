import React from "react";

export function SignInErrorImage() {
    return (
        <svg
            fill="none"
            height="268"
            viewBox="0 0 390 268"
            width="390"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M373.005 267.253C348.655 267.253 19.4725 266.546 19.4725 266.497C19.341 266.185 -22.31 215.135 30.1358 161.753C56.1287 135.3 6.27886 101.043 30.1358 71.6325C71.0146 21.2404 111.466 49.6157 145.165 35.4527C151.113 32.9553 157.093 28.9299 163.074 22.752C166.442 19.2852 170.123 16.2456 174.082 13.6168C174.69 13.206 175.298 12.8117 175.923 12.4338C178.289 10.9715 180.737 9.6406 183.267 8.44118C184.959 7.63609 186.685 6.89672 188.459 6.22307C191.252 5.13867 194.128 4.21857 197.052 3.42991C201.209 2.31264 205.497 1.47469 209.868 0.916058C211.774 0.669602 213.696 0.472439 215.635 0.324566C253.293 -2.50146 295.651 13.4196 317.52 36.685C321.923 41.3677 341.935 65.6189 326.162 98.9398C316.205 119.987 357.938 140.081 372.775 164.103C410.466 225.174 373.35 266.448 373.005 267.253Z"
                fill="#C3D9FF"
                fillOpacity="0.2"/>
            <path
                d="M16.6641 267.2C16.6641 267.2 52.8641 198.6 95.9641 199.3C157.964 200.3 196.664 265.7 196.664 265.7L16.6641 267.2Z"
                fill="#9FB9DE"/>
            <path
                d="M107.164 267.2C107.164 267.2 158.264 179.9 201.364 180.6C263.364 181.6 295.564 267.2 295.564 267.2H107.164Z"
                fill="#CDDDF8"/>
            <path
                d="M311.864 150.099C311.464 146.599 308.664 143.799 305.264 143.399C303.764 143.199 302.264 143.499 301.064 144.099C301.064 143.899 301.064 143.599 301.064 143.399C301.064 134.999 294.264 128.099 285.764 128.099C282.564 128.099 279.564 129.099 277.064 130.799C274.564 120.399 264.864 112.899 253.464 113.699C242.664 114.499 233.864 123.099 232.864 133.899C232.564 137.199 232.964 140.499 233.964 143.399C233.664 143.399 233.364 143.299 232.964 143.299C231.464 143.299 230.064 143.699 228.964 144.499C228.964 144.399 228.964 144.199 228.964 144.099C228.964 136.099 222.464 129.599 214.464 129.599C206.464 129.599 199.964 136.099 199.964 144.099C199.964 144.899 200.064 145.599 200.164 146.399H175.764C172.364 146.399 169.664 149.099 169.664 152.499C169.664 155.899 172.364 158.599 175.764 158.599H306.864V158.099C310.064 156.899 312.164 153.699 311.864 150.099Z"
                fill="#CDDDF8"
                fillOpacity="0.7"/>
            <path
                d="M182.365 100.9H158.765C159.365 98.3996 159.365 95.6996 157.865 92.7996C156.265 89.5996 153.365 87.1996 149.865 86.4996C147.665 85.9996 145.665 86.1996 143.765 86.7996C143.765 86.6996 143.765 86.4996 143.765 86.3996C143.765 74.8996 134.465 65.5996 122.965 65.5996C112.765 65.5996 104.365 72.8996 102.565 82.4996C101.765 82.2996 100.865 82.1996 99.9649 82.1996C92.6649 82.1996 86.7649 87.8996 86.3649 95.0996H54.2649C50.2649 95.0996 46.8649 98.1996 46.7649 102.1C46.6649 106.2 49.9649 109.6 53.9649 109.6H182.365C184.765 109.6 186.765 107.6 186.765 105.2C186.765 102.8 184.765 100.9 182.365 100.9Z"
                fill="#CDDDF8"
                fillOpacity="0.7"/>
            <path
                d="M270.065 74.9H256.065C256.465 73.4 256.465 71.8 255.565 70.1C254.565 68.2 252.865 66.8 250.765 66.4C249.465 66.1 248.265 66.2 247.165 66.6C247.165 66.5 247.165 66.5 247.165 66.4C247.165 59.6 241.665 54 234.765 54C228.765 54 223.665 58.3 222.565 64.1C222.065 64 221.565 64 221.065 64C216.765 64 213.165 67.4 212.965 71.7H193.865C191.465 71.7 189.465 73.5 189.365 75.9C189.265 78.3 191.265 80.3 193.665 80.3H270.065C271.465 80.3 272.665 79.1 272.665 77.7C272.665 76.1 271.465 74.9 270.065 74.9Z"
                fill="#CDDDF8"/>
            <path
                d="M381.399 267.629H0.47648C0.213594 267.629 0 267.456 0 267.242V267.016C0 266.802 0.213594 266.629 0.47648 266.629H381.382C381.645 266.629 381.859 266.802 381.859 267.016V267.242C381.875 267.456 381.661 267.629 381.399 267.629Z"
                fill="#9E9E9E"/>
            <path
                d="M178.664 68C178.664 65.2386 180.903 63 183.664 63C186.425 63 188.664 65.2386 188.664 68V267H178.664V68Z"
                fill="#EEF4FF"/>
            <path
                d="M178.664 221.828V78C178.664 75.2386 180.903 73 183.664 73V73C186.425 73 188.664 75.2386 188.664 78V267H178.664V227.059"
                stroke="#37474F"
                strokeLinecap="round"/>
            <circle
                cx="183.664"
                cy="125"
                fill="#CDDDF8"
                r="44"/>
            <path
                d="M216.756 96C208.691 86.8051 196.856 81 183.664 81C159.364 81 139.664 100.699 139.664 125C139.664 149.301 159.364 169 183.664 169C207.965 169 227.664 149.301 227.664 125C227.664 115.495 224.65 106.694 219.526 99.5"
                stroke="#37474F"
                strokeLinecap="round"/>
            <circle
                cx="183.641"
                cy="125"
                fill="#9FB9DE"
                r="34.8966"/>
            <path
                clipRule="evenodd"
                d="M183.354 98.2456C181.137 98.2456 179.34 100.043 179.34 102.26V104.174C178.523 103.18 177.284 102.546 175.898 102.546C173.439 102.546 171.445 104.54 171.445 106.999V129.948V131.443L171.445 132.565L168.115 129.365L168.099 129.349L168.081 129.335C165.851 127.547 162.608 128.024 160.942 130.342C159.28 132.656 159.751 135.482 161.747 137.303C162.895 138.352 164.215 139.703 165.553 141.072L165.661 141.182C167.03 142.582 168.413 143.993 169.618 145.08C174.88 149.874 182.612 152.049 189.468 150.247C195.557 148.642 201.403 143.907 201.403 136.778V130.697L201.403 126.877V114.726C201.403 112.751 199.802 111.15 197.827 111.15C196.822 111.15 195.913 111.565 195.263 112.234V104.421C195.263 101.962 193.269 99.9683 190.81 99.9683C189.397 99.9683 188.138 100.626 187.323 101.652C187.03 99.7234 185.364 98.2456 183.354 98.2456Z"
                fill="#F7F9FB"
                fillRule="evenodd"/>
            <path
                d="M200.909 130.698V136.779C200.909 143.586 195.321 148.185 189.35 149.758C182.674 151.513 175.116 149.395 169.969 144.706C167.586 142.558 163.47 138.098 161.088 135.919C159.265 134.255 158.853 131.714 160.353 129.626C161.853 127.539 164.765 127.115 166.764 128.719L171.962 133.754V130.698"
                stroke="#37474F"/>
            <path
                d="M194.769 131.446V129.816V104.422C194.769 102.242 193.001 100.475 190.821 100.475V100.475C188.641 100.475 186.874 102.242 186.874 104.422V129.816V131.446"
                stroke="#37474F"/>
            <path
                d="M186.874 131.444V129.723V102.261C186.874 100.323 185.303 98.752 183.365 98.752V98.752C181.427 98.752 179.856 100.323 179.856 102.261V129.723V131.444"
                stroke="#37474F"/>
            <path
                d="M179.857 131.443V129.949V107C179.857 104.82 178.089 103.053 175.909 103.053V103.053C173.729 103.053 171.962 104.82 171.962 107V114V129.949V131.443"
                stroke="#37474F"/>
            <path
                d="M200.909 131.444V126.877V114.726C200.909 113.031 199.534 111.656 197.839 111.656V111.656C196.143 111.656 194.769 113.031 194.769 114.726V126.877V131.444"
                stroke="#37474F"/>
            <path
                clipRule="evenodd"
                d="M164.072 154.489C166.856 156.338 169.892 157.788 173.094 158.79C177.531 160.178 182.198 160.678 186.828 160.263C191.459 159.847 195.962 158.524 200.081 156.368C204.2 154.212 207.854 151.266 210.834 147.698C213.815 144.13 216.063 140.009 217.451 135.572C218.84 131.135 219.34 126.468 218.925 121.838C218.509 117.207 217.186 112.704 215.03 108.585C213.505 105.672 211.585 102.991 209.329 100.617C209.095 100.371 208.858 100.129 208.618 99.8899L163.234 153.915C163.511 154.11 163.79 154.302 164.072 154.489ZM173.396 157.824C177.706 159.173 182.24 159.659 186.738 159.255C191.236 158.852 195.611 157.566 199.612 155.472C203.613 153.377 207.163 150.515 210.058 147.049C212.953 143.583 215.138 139.581 216.486 135.27C217.835 130.96 218.321 126.427 217.917 121.928C217.514 117.43 216.228 113.055 214.134 109.054C212.67 106.258 210.831 103.682 208.674 101.396L164.727 153.71C167.405 155.476 170.322 156.863 173.396 157.824Z"
                fill="#37474F"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M194.234 91.212C196.414 91.8939 198.517 92.7834 200.516 93.8653C200.818 94.0287 201.117 94.1965 201.414 94.3686L156.494 147.695C156.273 147.432 156.057 147.165 155.845 146.895C154.5 145.186 153.312 143.353 152.298 141.416C150.142 137.297 148.819 132.794 148.403 128.164C147.988 123.533 148.488 118.866 149.877 114.429C151.265 109.992 153.513 105.872 156.494 102.304C159.474 98.7358 163.128 95.7897 167.247 93.6338C171.366 91.4779 175.869 90.1544 180.5 89.7389C185.13 89.3233 189.797 89.8239 194.234 91.212ZM180.59 90.7463C185.088 90.3427 189.622 90.8289 193.932 92.1774C195.983 92.8189 197.963 93.6494 199.85 94.6557L156.511 146.105C155.257 144.492 154.146 142.766 153.195 140.947C151.1 136.946 149.815 132.571 149.411 128.073C149.007 123.575 149.493 119.041 150.842 114.731C152.19 110.421 154.375 106.418 157.27 102.952C160.165 99.4862 163.715 96.6243 167.716 94.5299C171.717 92.4356 176.092 91.15 180.59 90.7463Z"
                fill="#37474F"
                fillRule="evenodd"/>
            <path
                d="M202.932 92.6816L208.991 99.2678L162.229 155.242L155.661 148.66L202.932 92.6816Z"
                fill="#CDDDF8"/>
        </svg>);
}