import _ from "lodash";
import { ReactNode, useCallback } from "react";
import { useLocalization } from ".";
import { Optional } from "../types";

export function useLocalizeList() {
    const localization =
        useLocalization(
            "infrastructure.hooks.useLocalizeList",
            () => ({
                prefix: "{{localizedValues}}, {{value}}",
                separator: "{{localizedValues}}, {{value}}",
                suffix: "{{localizedValues}} and {{value}}"
            }));
    return useCallback(
        (values: ReactNode[] | string[], localizeSuffix = true): Optional<string> => {
            let [localizedValues, value, ...otherValues] = values;
            if (_.isNil(value)) {
                return localizedValues as any;
            } else if (_.isEmpty(otherValues)) {
                return localizeSuffix
                    ? localization.suffix({ localizedValues, value })
                    : localization.prefix({ localizedValues, value });
            }

            localizedValues = localization.prefix({ localizedValues, value });

            while (!_.isEmpty(otherValues)) {
                [value, ...otherValues] = otherValues;

                localizedValues =
                    localizeSuffix && _.isEmpty(otherValues)
                        ? localization.suffix({ localizedValues, value })
                        : localization.separator({ localizedValues, value });
            }

            return localizedValues as string;
        },
        []);
}