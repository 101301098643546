import { useLocalization } from "@infrastructure";
import { Contract } from "..";
import { useEntityTypeNameTranslator } from "./useEntityTypeNameTranslator";

export function useRiskResolutionReasonTranslator() {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.hooks.useRiskResolutionReasonTranslator",
            () => ({
                [Contract.TypeNames.RiskResolutionReason]: {
                    [Contract.RiskResolutionReason.ActiveGcpTenantEntity]: "Project is now used",
                    [Contract.RiskResolutionReason.ActivePrincipal]: {
                        entity: {
                            [Contract.TypeNames.AwsSsoPermissionSet]: "{{translatedRiskedEntityTypeName}} is now used"
                        },
                        text: "{{translatedRiskedEntityTypeName}} is now active"
                    },
                    [Contract.RiskResolutionReason.DeletedEntity]: "{{translatedRiskedEntityTypeName}} deleted",
                    [Contract.RiskResolutionReason.EmptyGroup]: "All members were removed from group",
                    [Contract.RiskResolutionReason.EntitiesExcluded]: "{{translatedRiskedEntityTypeName}} is excluded",
                    [Contract.RiskResolutionReason.FalsePositive]: "Finding was marked as a false positive by Tenable",
                    [Contract.RiskResolutionReason.InactivePrincipal]: {
                        entity: {
                            [Contract.TypeNames.AwsSsoPermissionSet]: "{{translatedRiskedEntityTypeName}} is unused"
                        },
                        text: "{{translatedRiskedEntityTypeName}} is inactive"
                    },
                    [Contract.RiskResolutionReason.LifecycleRuleExistsAwsBucket]: "Lifecycle rules were added",
                    [Contract.RiskResolutionReason.Manual]: "Finding was manually resolved",
                    [Contract.RiskResolutionReason.ManualResolution]: "Finding was closed by automatically remediation",
                    [Contract.RiskResolutionReason.NewMemberGroup]: "Reanalyzing due to newly created member added to the group",
                    [Contract.RiskResolutionReason.NoMaliciousFiles]: "No malicious files detected",
                    [Contract.RiskResolutionReason.OperatingSystemUpdated]: "Operating system was updated",
                    [Contract.RiskResolutionReason.RecentUpdateAssignmentAwsPermissionSet]: "Reanalyzing due to new permission set assignment",
                    [Contract.RiskResolutionReason.RecentUpdatePermissionActionEntity]: {
                        entity: {
                            [Contract.TypeNames.AwsS3Bucket]: "Reanalyzing due to recent policy changes"
                        },
                        text: "Reanalyzing due to recent permission changes"
                    },
                    [Contract.RiskResolutionReason.Resolution]: "Finding is fixed",
                    [Contract.RiskResolutionReason.RiskPolicyDisabled]: "Policy disabled",
                    [Contract.RiskResolutionReason.UnknownTenantAwsPermissionSet]: "Permission set is assigned to accounts which are not configured in Tenable Cloud Security",
                    [Contract.RiskResolutionReason.UnusedAccessKeyAwsUser]: "Access keys are unused",
                    [Contract.RiskResolutionReason.VulnerablePackageVersionRemoved]: "Relevant packages upgraded or removed"
                }
            }));
    return (riskResolutionReason: Contract.RiskResolutionReason, riskedEntityTypeName: string) => {
        switch (riskResolutionReason) {
            case Contract.RiskResolutionReason.ActivePrincipal:
            case Contract.RiskResolutionReason.InactivePrincipal:
                return riskedEntityTypeName in localization[Contract.TypeNames.RiskResolutionReason][riskResolutionReason].entity
                    ? localization[Contract.TypeNames.RiskResolutionReason][riskResolutionReason].entity.translate(
                        riskedEntityTypeName,
                        {
                            translatedRiskedEntityTypeName:
                                entityTypeNameTranslator(
                                    riskedEntityTypeName,
                                    {
                                        includeServiceName: false
                                    })
                        })
                    : localization[Contract.TypeNames.RiskResolutionReason][riskResolutionReason].text({
                        translatedRiskedEntityTypeName:
                            entityTypeNameTranslator(
                                riskedEntityTypeName,
                                {
                                    includeServiceName: false
                                })
                    });
            case Contract.RiskResolutionReason.RecentUpdatePermissionActionEntity:
                return riskedEntityTypeName in localization[Contract.TypeNames.RiskResolutionReason][riskResolutionReason].entity
                    ? localization[Contract.TypeNames.RiskResolutionReason][riskResolutionReason].entity.translate(riskedEntityTypeName)
                    : localization[Contract.TypeNames.RiskResolutionReason][riskResolutionReason].text();
            default:
                return localization[Contract.TypeNames.RiskResolutionReason][riskResolutionReason]({
                    translatedRiskedEntityTypeName:
                        entityTypeNameTranslator(
                            riskedEntityTypeName,
                            {
                                includeServiceName: false
                            })
                });
        }
    };
}