import { Loading, useLocalization } from "@infrastructure";
import { Box, useTheme } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, CustomerConsoleAppUrlHelper, Entity, entityModelStore, Network } from "../../../../../../../../../../../../common";
import { ToolbarToggleFilterId } from "../../../../../../../../../../../../common/components/Network/components";
import { RiskDefinitionSection } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../components";
import { useGetAzureDocumentDbDatabaseAccountRiskContext } from "../contexts";

export function useAzureDocumentDbDatabaseAccountWideRangeInboundRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureDocumentDbDatabaseAccountWideRangeInboundRisk;
    const databaseAccountModel = entityModelStore.useGet(risk.entityId) as Contract.AzureDocumentDbDatabaseAccountModel;
    const databaseAccount = databaseAccountModel.entity as Contract.AzureDocumentDbDatabaseAccount;

    const getAzureDocumentDbDatabaseAccountRiskContext = useGetAzureDocumentDbDatabaseAccountRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureDocumentDbDatabaseAccountWideRangeInboundRiskDefinition",
            () => ({
                description: "{{databaseAccount}} network configuration allows wide network inbound access",
                networkGraph: "Network",
                sections: {
                    resolution: {
                        step1: "Switch the **All networks** toggle to **Selected networks**",
                        step2: "Uncheck the **Accept connections from within public Azure datacenters** checkbox",
                        step3: "If any Azure resources require access to the database account, add the virtual network of the resource to the firewall rules",
                        step4: "For each public IP address rule, edit the address range to restrict it only to the required IP addresses or delete it",
                        step5: "Click **Save** button on top to confirm"
                    }
                }
            }));
    const theme = useTheme();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            databaseAccount:
                <Entity
                    entityIdOrModel={databaseAccountModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={Contract.AzureConsoleEntityPage.Firewall}/>,
            databaseAccount.allNetworkAccess
                ? localization.sections.resolution.step1()
                : undefined,
            databaseAccount.allowAnyAzureInternalIpAddress
                ? localization.sections.resolution.step2()
                : undefined,
            localization.sections.resolution.step3(),
            _.isEmpty(databaseAccount.firewallRuleSubnets)
                ? undefined
                : localization.sections.resolution.step4(),
            localization.sections.resolution.step5()
        ],
        riskModel,
        () => {
            const databaseAccountRiskContext = getAzureDocumentDbDatabaseAccountRiskContext(databaseAccountModel);
            return [
                databaseAccountRiskContext.generalInformation,
                databaseAccountRiskContext.sensitive,
                databaseAccountRiskContext.wideRangeInboundSubnets,
                databaseAccountRiskContext.allowAnyAzureInternalIpAddress,
                databaseAccountRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections:
                !databaseAccount.systemDeleted
                    ? [
                        new RiskDefinitionSection(
                            <Box sx={{ minHeight: theme.spacing(20) }}>
                                <Loading>
                                    <Network
                                        baseUrl={CustomerConsoleAppUrlHelper.getRiskProfileRelativeUrl(riskModel.id)}
                                        entityId={risk.entityId}
                                        initialFilterMap={{ [ToolbarToggleFilterId.WideRangeSourceSubnet]: true }}
                                        tenantType={Contract.TenantType.Azure}
                                        variant="risk"/>
                                </Loading>
                            </Box>,
                            localization.networkGraph(),
                            {
                                profilePageOnly: true
                            })
                    ]
                    : undefined
        });
}