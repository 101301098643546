import { AnalyticsEventActionType, Dialog, EventHandlerRegister, Loading, makeContextProvider, useEvent, useLocalization, useTrackAnalyticsEvent, VerticalFillGrid } from "@infrastructure";
import { Stack, Tab, Tabs } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useMemo } from "react";
import { Contract, CustomerConsoleAppUrlHelper, ReportDeliveryType, ScopeNavigationView, TenantHelper, useLayoutOptions, useScopeNavigationViewContext, useTheme } from "../../../../common";
import { CustomerView } from "../../hooks";
import { AddOrEdit, History, Schedule } from "./components";

export class ReportsContext {
    public reportScheduleConfiguration?: Contract.ReportScheduleConfiguration;

    constructor(
        public addOrEditOpen: boolean,
        public registerReportChange: EventHandlerRegister<() => Promise<void>>,
        public triggerReportChange: () => Promise<void>,
        public reportDeliveryType?: ReportDeliveryType) {
    }
}

export const [useReportsContext, useSetReportsContext, useReportsContextProvider] = makeContextProvider<ReportsContext>();

export function Reports() {
    const localization =
        useLocalization(
            "views.customer.reports",
            () => ({
                title: "Reports"
            }));
    useLayoutOptions({ view: { title: localization.title() } });
    useTrackAnalyticsEvent(AnalyticsEventActionType.PageView);

    const [registerReportChange, triggerReportChange] = useEvent<() => Promise<void>>();
    const [, , ContextProvider] =
        useReportsContextProvider(
            () =>
                new ReportsContext(
                    false,
                    registerReportChange,
                    triggerReportChange));

    const reportsTenantTypes =
        useMemo(
            () => TenantHelper.ReportsTenantTypes,
            []);
    return (
        <ContextProvider>
            <ScopeNavigationView
                layout={"global"}
                templatePath={CustomerConsoleAppUrlHelper.getTemplatePath(CustomerView.Reports)}
                tenantTypes={reportsTenantTypes}>
                <View/>
            </ScopeNavigationView>
        </ContextProvider>);
}

function View() {
    const { addOrEditOpen } = useReportsContext();
    const setContext = useSetReportsContext();
    const { scopeNodeModel, useViewRoute } = useScopeNavigationViewContext();
    const [view, setView] = useViewRoute("{view}", _.values(ReportsView));

    const localization =
        useLocalization(
            "views.customer.reports.view",
            () => ({
                tabs: {
                    view: {
                        [ReportsView.History]: "Reports History",
                        [ReportsView.Schedule]: "Scheduled Reports"
                    }
                },
                title: "Reports"
            }));

    const theme = useTheme();
    return (
        <Fragment>
            {addOrEditOpen &&
                <Dialog
                    variant="editor"
                    onClose={
                        () =>
                            setContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: false,
                                    reportDeliveryType: undefined,
                                    reportScheduleConfiguration: undefined
                                }))}>
                    <AddOrEdit/>
                </Dialog>}
            <VerticalFillGrid key={scopeNodeModel.configuration.id}>
                <Stack
                    alignItems="stretch"
                    direction="row"
                    sx={{ backgroundColor: theme.palette.background.paper }}>
                    <Tabs
                        indicatorColor="primary"
                        sx={{
                            border: "unset",
                            padding: theme.spacing(0, 2),
                            width: "100%"
                        }}
                        value={view}
                        variant="scrollable"
                        onChange={(event, view) => setView(view)}>
                        <Tab
                            label={localization.tabs.view[ReportsView.History]()}
                            sx={{
                                marginRight: theme.spacing(3),
                                padding: 0
                            }}
                            value={ReportsView.History}/>
                        <Tab
                            label={localization.tabs.view[ReportsView.Schedule]()}
                            sx={{
                                marginRight: theme.spacing(3),
                                padding: 0
                            }}
                            value={ReportsView.Schedule}/>
                    </Tabs>
                </Stack>
                <Stack
                    sx={{
                        height: "100%",
                        width: "100%"
                    }}>
                    <Loading>
                        {view === ReportsView.History && (
                            <History/>)}
                        {view === ReportsView.Schedule && (
                            <Schedule/>)}
                    </Loading>
                </Stack>
            </VerticalFillGrid>
        </Fragment>);
}

export enum ReportsView {
    History = "history",
    Schedule = "schedule"
}