import React, { Dispatch, SetStateAction, useMemo } from "react";
import { Report as CommonReport, ReportDeliveryType, ReportOptions, ScopeHelper, useSelectedScopeId } from "../../../../../../common";
import { ItemOptions, useItems } from "./hooks";

type ReportProps = {
    getReportData: () => ItemOptions;
    setReportDialogOpen: Dispatch<SetStateAction<boolean>>;
};

export function Report({ getReportData, setReportDialogOpen: setReportDialogOpen }: ReportProps) {
    const reportData = getReportData();
    const items = useItems(reportData);
    const { selectedScopeId } = useSelectedScopeId();

    const reportOptions =
        useMemo(
            (): ReportOptions =>
                ({
                    reportType: reportData.reportType,
                    scheduleReportType: ReportDeliveryType.Schedule,
                    selectedScopeId
                }),
            [reportData.reportType, selectedScopeId]);

    return (
        <CommonReport
            items={items}
            options={reportOptions}
            rootScopeId={ScopeHelper.customerId}
            scopeSelectorDisabled={true}
            onGenerate={() => setReportDialogOpen(false)}/>);
}