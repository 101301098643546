import { useLocalization } from "@infrastructure";
import React from "react";
import { useAwsCommonEncryptedResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { useAwsFirehoseDeliveryStreamStatusTranslator } from "../../../../../../hooks/useAwsFirehoseDeliveryStreamStatusTranslator";
import { Info } from "../../../../components";

export function useAwsFirehoseDeliveryStreamDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const deliveryStream = resourceModel.entity as Contract.AwsFirehoseDeliveryStream;

    const firehoseDeliveryStreamStatusTranslator = useAwsFirehoseDeliveryStreamStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsFirehoseDeliveryStreamDefinition",
            () => ({
                info: {
                    items: {
                        status: "Status"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={firehoseDeliveryStreamStatusTranslator(deliveryStream.status)}/>,
                    ...commonEncryptedResourceInfoElements
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}