import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../../../../../../../../../common";

export function useOktaTenantModelStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useIdentityProviderTenantItems.oktaTenants.hooks.useOktaTenantModelStatusTranslator",
            () => ({
                [Contract.TypeNames.OktaTenantModelStatus]: {
                    [Contract.OktaTenantModelStatus.AccessDenied]: "Access denied",
                    [Contract.OktaTenantModelStatus.Deleting]: "Deleting, this may take a while...",
                    [Contract.OktaTenantModelStatus.UserAuthenticationFailure]: "Disconnected",
                    [Contract.OktaTenantModelStatus.Valid]: "Connected"
                }
            }));
    return (status: Contract.OktaTenantModelStatus) =>
        localization[Contract.TypeNames.OktaTenantModelStatus][status]();
}