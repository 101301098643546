﻿import { DropdownIcon, Menu, Optional, useLocalization } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, useEntityExcessivePermissionCustomEvaluationMenuItems, useTheme } from "../../common";

type NonexcessivePermitterDateSelectorFieldProps = {
    disabled: boolean;
    entityExcessivePermissionCustomEvaluationData: Optional<Contract.EntityControllerGetEntityExcessivePermissionCustomEvaluationDataResponse>;
    onSelectedDateChanged: (selectedDate: Optional<string>) => void;
    selectedDate: Optional<string>;
};

export function NonexcessivePermitterDateSelectorField({ disabled, entityExcessivePermissionCustomEvaluationData, onSelectedDateChanged, selectedDate }: NonexcessivePermitterDateSelectorFieldProps) {
    const { dateInfos, maxTime, nonexcessivePermitterCustomGenerationEnabled } =
    entityExcessivePermissionCustomEvaluationData ??
    {
        dateInfos: [],
        maxTime: undefined,
        nonexcessivePermitterCustomGenerationEnabled: false
    };
    const menuItems =
        useEntityExcessivePermissionCustomEvaluationMenuItems(
            selectedDate,
            dateInfos,
            maxTime,
            date => onSelectedDateChanged(date),
            "bottomLeft");

    return (
        nonexcessivePermitterCustomGenerationEnabled
            ? <Menu
                automaticPopupWidth={true}
                disabled={disabled}
                itemsOrGetItems={menuItems}
                variant="bottomLeft">
                <DateField
                    disabled={disabled}
                    selectedDate={selectedDate}/>
            </Menu>
            : <DateField
                disabled={disabled}
                selectedDate={selectedDate}/>);

}

type DateFieldProps = {
    disabled: boolean;
    selectedDate: Optional<string>;
};

function DateField({ disabled, selectedDate }: DateFieldProps) {
    const localization =
        useLocalization(
            "common.nonexcessivePermitterDateSelectorField.dateField",
            () => ({
                placeholder: "Time Frame",
                selection: "Last {{date | TimeFormatter.duration}}"
            }));
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            direction="row"
            sx={{
                "&:hover": {
                    border: theme.border.hoverFocus
                },
                backgroundColor: theme.palette.background.paper,
                border: theme.border.primary,
                borderRadius: theme.spacing(0.75),
                color: theme.palette.text.secondary,
                flex: 1,
                maxHeight: theme.spacing(6.25),
                overflow: "hidden",
                width: "100%",
                ...(!_.isNil(selectedDate) && {
                    color: theme.palette.text.primary,
                    fontWeight: 500
                }),
                ...(disabled && {
                    color: theme.palette.text.disabled,
                    cursor: "unset"
                })
            }}>
            <Stack
                alignItems="center"
                direction="row"
                sx={{
                    borderRight: "none",
                    flex: 1,
                    padding: theme.spacing(2)
                }}>
                <Typography
                    noWrap={true}
                    sx={{
                        fontSize: "inherit",
                        width: "100%"
                    }}>
                    {_.isNil(selectedDate)
                        ? localization.placeholder()
                        : localization.selection({ date: selectedDate })}
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "flex-end",
                        marginLeft: theme.spacing(0.5)
                    }}>
                    {<DropdownIcon sx={{ fontSize: "18px" }}/>}
                </Box>
            </Stack>
        </Stack>);
}