import { useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../../common";
import { useOciConsoleSignInStepTranslator } from "../../../../../../../../../../../../../../tenants";
import { useGetOciComputeInstancesRiskContext, useGetOciContainerEngineClusterNodePoolRiskContext } from "../../../contexts";

export function useOciComputeInstanceVolumeEncryptionInTransitDisabledRiskClusterNodePoolDefinition(riskModel: Contract.OciComputeInstanceVolumeEncryptionInTransitDisabledRiskModel) {
    const risk = riskModel.risk as Contract.OciComputeInstanceVolumeEncryptionInTransitDisabledRisk;
    const clusterNodePoolModel = entityModelStore.useGet(risk.entityId) as Contract.OciContainerEngineClusterNodePoolModel;

    const getOciContainerEngineClusterNodePoolRiskContext = useGetOciContainerEngineClusterNodePoolRiskContext();
    const getOciComputeInstancesRiskContext = useGetOciComputeInstancesRiskContext();

    const consoleSignInStepTranslator = useOciConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.oci.hooks.compliance.useOciComputeInstanceVolumeEncryptionInTransitDisabledRiskDefinition.hooks.useOciComputeInstanceVolumeEncryptionInTransitDisabledRiskClusterNodePoolDefinition",
            () => ({
                description: "{{clusterNodePool}} volume in-transit encryption is not enabled",
                sections: {
                    resolution: {
                        step1: "Click **Edit**",
                        step2: "In the **Boot volume** section, select the **Use in-transit encryption** option",
                        step3: "Click **Save changes**",
                        step4: "Updates to node pool properties apply only to worker nodes created after you save the changes. Click **cycle nodes** on the node pool details page to replace existing nodes with nodes with updated properties"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            clusterNodePool:
                <Entity
                    entityIdOrModel={clusterNodePoolModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.OciConsoleView.ContainerEngine,
                clusterNodePoolModel.consoleUrl!),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        riskModel,
        () => {
            const clusterNodePoolContextItems = getOciContainerEngineClusterNodePoolRiskContext(clusterNodePoolModel);
            const instancesContextItems = getOciComputeInstancesRiskContext(clusterNodePoolModel.instanceIdReferences);
            return [
                clusterNodePoolContextItems.generalInformation,
                clusterNodePoolContextItems.instances,
                instancesContextItems.runningInstances,
                instancesContextItems.sensitive,
                clusterNodePoolContextItems.sensitive,
                clusterNodePoolContextItems.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}