﻿import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { Link, Optional, Shadows } from "@infrastructure";
import { Contract, useTheme } from "../../../../../../..";

export class WorkloadAnalysisWidgetItem {
    constructor(
        public count: number,
        public percentage: string,
        public severity: Contract.Severity | "None",
        public title: string,
        public url: Optional<string>) {
    }
}

type WorkloadAnalysisItemProps = {
    highlight: boolean;
    item: WorkloadAnalysisWidgetItem;
};

export function WorkloadAnalysisItem({ highlight, item }: WorkloadAnalysisItemProps) {
    const theme = useTheme();
    return (
        <Link
            urlOrGetUrl={item.url}
            variant="text">
            <Stack
                className="item"
                sx={{
                    background:
                        highlight
                            ? theme.palette.background.alternate
                            : undefined,
                    border:
                        highlight
                            ? theme.border.alternate
                            : theme.border.transparent,
                    borderBottomRightRadius: theme.spacing(1),
                    borderTopRightRadius: theme.spacing(1),
                    boxShadow:
                        highlight
                            ? theme.shadows[Shadows.Highlight]
                            : undefined,
                    padding: theme.spacing(1)
                }}>
                <Typography
                    noWrap={true}
                    sx={{ fontWeight: 500 }}>
                    {item.title}
                </Typography>
                <Stack
                    direction="row"
                    spacing={0.5}
                    sx={{ padding: theme.spacing(0.25) }}>
                    <Box
                        style={{
                            alignSelf: "center",
                            backgroundColor:
                                item.severity === "None"
                                    ? "#40B99E"
                                    : theme.palette.severity(item.severity),
                            display: "inline-flex",
                            height: "6px",
                            width: "6px"
                        }}/>
                    <Typography>
                        {item.percentage}
                    </Typography>
                </Stack>
            </Stack>
        </Link>);
}