import { InlineItems, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetRiskPolicyConfigurationRotationTimeFrameContextItem } from "../../../useGetRiskPolicyConfigurationRotationTimeFrameContextItem";
import { EntityExternalConsoleLink } from "../../components";
import { useGetAzureKeyVaultKeyRiskContext } from "../contexts";

export function useAzureKeyVaultVaultKeyNotRotatedRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureKeyVaultVaultKeyNotRotatedRisk;
    const vaultKeyModel = entityModelStore.useGet(risk.entityId) as Contract.AzureKeyVaultVaultKeyModel;
    const vaultKey = vaultKeyModel.entity as Contract.AzureKeyVaultVaultKey;

    const getAzureKeyVaultVaultKeyRiskContext = useGetAzureKeyVaultKeyRiskContext();
    const getRiskPolicyConfigurationRotationTimeFrameContextItem = useGetRiskPolicyConfigurationRotationTimeFrameContextItem();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureKeyVaultVaultKeyNotRotatedRiskDefinition",
            () => ({
                contextItems: {
                    versions: {
                        pluralizer: [
                            "1 version",
                            "{{count | NumberFormatter.humanize}} versions"
                        ],
                        title: [
                            "The {{vaultKey}} has {{versions}} that was issued more than {{keyRotationTimeFrame | TimeSpanFormatter.humanizeDays}} ago",
                            "The {{vaultKey}} has {{versions}} that were issued more than {{keyRotationTimeFrame | TimeSpanFormatter.humanizeDays}} ago"]
                    }
                },
                description: "{{vaultKey}} was not rotated for more than {{rotationTimeFrame | TimeSpanFormatter.humanizeDays}}",
                sections: {
                    resolution: {
                        step1: "Click **New Version**",
                        step2: "It is recommended to set a key expiration date and rotation policy to ensure periodically key rotation",
                        step3: "Click **Create**",
                        step4: "Disable any previous key versions by setting **Enabled** to **No**"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            rotationTimeFrame: risk.keyRotationTimeFrame,
            vaultKey:
                <Entity
                    entityIdOrModel={vaultKeyModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={vaultKey.vaultId}
                key={vaultKey.vaultId}
                page={Contract.AzureConsoleEntityPage.Keys}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4()
        ],
        riskModel,


        () => {
            const vaultKeyRiskContext = getAzureKeyVaultVaultKeyRiskContext(vaultKeyModel);
            return [
                vaultKeyRiskContext.generalInformation,
                getRiskPolicyConfigurationRotationTimeFrameContextItem(risk.keyRotationTimeFrame),
                new RiskDefinitionContextItem(
                    localization.contextItems.versions.title(
                        risk.notRotatedVersionNames.length,
                        {
                            keyRotationTimeFrame: risk.keyRotationTimeFrame,
                            vaultKey:
                                <Entity
                                    entityIdOrModel={vaultKeyModel}
                                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                                    variant="typeText"/>,
                            versions:
                                <InlineItems
                                    items={risk.notRotatedVersionNames}
                                    namePluralizer={localization.contextItems.versions.pluralizer}
                                    variant="itemCountAndType"/>
                        })),
                vaultKeyRiskContext.sensitive,
                vaultKeyRiskContext.keyVault,
                vaultKeyRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        }
    );
}