﻿import { map } from "@infrastructure";
import { Contract, tenantModelStore } from "../../../../../../../../../../../../../../../common";
import { AzureConsoleUrlBuilder } from "../../../../../../../../../../../../../../../tenants";

export function useAzureStorageStorageAccountDefinition(entityModel: Contract.EntityModel, page: Contract.AzureConsoleEntityPage) {
    const tenantModel = tenantModelStore.useGet(entityModel.entity.tenantId);
    const aadTenantModel = tenantModelStore.useGet((tenantModel.configuration as Contract.AzureTenantConfiguration).aadTenantId);
    const aadTenantConfiguration = aadTenantModel.configuration as Contract.AadTenantConfiguration;

    const getUrlFunction =
        map(
            page,
            {
                [Contract.AzureConsoleEntityPage.Configuration]: () => AzureConsoleUrlBuilder.GetStorageStorageAccountConfigurationUrl,
                [Contract.AzureConsoleEntityPage.DataProtection]: () => AzureConsoleUrlBuilder.GetStorageStorageAccountDataProtectionUrl,
                [Contract.AzureConsoleEntityPage.Diagnostics]: () => AzureConsoleUrlBuilder.GetEntityDiagnosticsUrl,
                [Contract.AzureConsoleEntityPage.Encryption]: () => AzureConsoleUrlBuilder.GetStorageStorageAccountEncryptionUrl,
                [Contract.AzureConsoleEntityPage.Networking]: () => AzureConsoleUrlBuilder.GetEntityNetworkingUrl,
                [Contract.AzureConsoleEntityPage.Overview]: () => AzureConsoleUrlBuilder.GetEntityOverviewUrl,
                [Contract.AzureConsoleEntityPage.RoleAssignments]: () => AzureConsoleUrlBuilder.GetStorageStorageAccountRoleAssignmentsUrl
            });

    return {
        url:
            getUrlFunction(
                aadTenantConfiguration.primaryDomainName,
                aadTenantConfiguration.partitionType,
                (entityModel.entity as Contract.AzureStorageStorageAccount).path!)
    };
}