import { useLocalization } from "@infrastructure";
import React from "react";
import { useGetAwsRdsDatabaseInstanceRiskContext } from "../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";

export function useAwsRdsDatabaseInstanceStorageEncryptionDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AwsRdsDatabaseInstanceStorageEncryptionDisabledRisk;
    const databaseInstanceModel = entityModelStore.useGet(risk.entityId) as Contract.AwsRdsDatabaseInstanceModel;
    const databaseInstance = databaseInstanceModel.entity as Contract.AwsRdsDatabaseInstance;

    const getAwsRdsDatabaseInstanceRiskContext = useGetAwsRdsDatabaseInstanceRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsRdsDatabaseInstanceStorageEncryptionDisabledRiskDefinition",
            () => ({
                description: "{{databaseInstance}} is not configured with storage encryption using KMS",
                sections: {
                    resolution: {
                        step1: "RDS instances can only be encrypted at the time of creation. It is not possible to enable encryption for an instance after it has been created. To address this, you must create a new snapshot, encrypt it, and then restore the instance with encryption enabled",
                        step2: "Click **Actions** and then **Take snapshot**",
                        step3: "Click **Take snapshot**",
                        step4: "In the **Snapshots** page, select the unencrypted snapshot and click **Actions**, and then **Copy snapshot**",
                        step5: "In the **Encryption** section, select **Enable Encryption** and select the encryption key",
                        step6: "Click **Copy snapshot**",
                        step7: "In the **Snapshots** page, select the copy of the snapshot and click **Actions**, and then **Restore snapshot**",
                        step8: "Click **Restore DB instance**",
                        step9: "In the **Databases** page, select the newly restored instance and click **Actions**, and then **Take snapshot**",
                        step10: "Before deleting any instance or snapshot, ensure that your new resource is properly configured to avoid downtime",
                        step11: "Delete the old unencrypted RDS instance"
                    }
                }
            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            databaseInstance:
                <Entity
                    entityIdOrModel={databaseInstanceModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            consoleSignInStepTranslator(
                Contract.AwsConsoleView.Rds,
                AwsConsoleUrlBuilder.getRdsDatabaseInstanceUrl(databaseInstance)),
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2(),
            localization.sections.resolution.step3(),
            localization.sections.resolution.step4(),
            localization.sections.resolution.step5(),
            localization.sections.resolution.step6(),
            localization.sections.resolution.step7(),
            localization.sections.resolution.step8(),
            localization.sections.resolution.step9(),
            localization.sections.resolution.step10(),
            localization.sections.resolution.step11()
        ],
        riskModel,
        () => {
            const databaseInstanceRiskContext = getAwsRdsDatabaseInstanceRiskContext(databaseInstanceModel);
            return [
                databaseInstanceRiskContext.generalInformation,
                databaseInstanceRiskContext.sensitive,
                databaseInstanceRiskContext.engine,
                databaseInstanceRiskContext.storageSize,
                databaseInstanceRiskContext.hasInboundExternalWideRange,
                databaseInstanceRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        });
}