import { TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../../common";
import { useOciKmsVaultSecretStatusTranslator } from "../../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { useOciCommonEncryptedResourceInfoElements } from "../useOciCommonEncryptedResourceInfoElements";
import { useOciDefaultResourceInfoItemElements } from "../useOciDefaultResourceInfoItemElements";
import { Versions } from "./components";

export function useOciKmsVaultSecretDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const commonEncryptedResourceInfoElements = useOciCommonEncryptedResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);
    const secretModel = resourceModel as Contract.OciKmsVaultSecretModel;
    const secret = secretModel.entity as Contract.OciKmsVaultSecret;

    const secretStatusTranslator = useOciKmsVaultSecretStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciKmsVaultSecretDefinition",
            () => ({
                info: {
                    items: {
                        autoGenerationEnabled: {
                            false: "Disabled",
                            title: "Auto Generation",
                            true: "Enabled"
                        },
                        description: "Description",
                        nextRotationTime: "Next Rotation Time",
                        rotationEnabled: {
                            false: "Disabled",
                            title: "Auto Rotation",
                            true: "Enabled"
                        },
                        rotationTimeFrame: {
                            text: "Every {{rotationTimeFrame | TimeSpanFormatter.humanizeDays}}",
                            title: "Rotation Interval"
                        },
                        status: "Status",
                        vault: "Vault"
                    }
                },
                tabs: {
                    versions: "Versions ({{versionCount | NumberFormatter.humanize}})"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <Versions secretModel={secretModel}/>,
                localization.tabs.versions({ versionCount: _.size(secret.versionNumberToVersionMap) }),
                "versions")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={secretStatusTranslator(secret.status)}/>,
                    <InfoItem
                        key="description"
                        title={localization.info.items.description()}
                        value={
                            _.isEmpty(secret.description)
                                ? undefined
                                : secret.description}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={secretModel.vaultIdReference}
                        entityTypeName={Contract.TypeNames.OciKmsVault}
                        key="vault"
                        title={localization.info.items.vault()}/>,
                    commonEncryptedResourceInfoElements.encryptionVaultKeyIdReference,
                    <InfoItem
                        key="autoGenerationEnabled"
                        title={localization.info.items.autoGenerationEnabled.title()}
                        value={
                            secret.autoGenerationEnabled
                                ? localization.info.items.autoGenerationEnabled.true()
                                : localization.info.items.autoGenerationEnabled.false()}/>,
                    <InfoItem
                        key="rotationEnabled"
                        title={localization.info.items.rotationEnabled.title()}
                        value={
                            secret.rotationEnabled
                                ? localization.info.items.rotationEnabled.true()
                                : localization.info.items.rotationEnabled.false()}/>,
                    <InfoItem
                        key="rotationTimeFrame"
                        title={localization.info.items.rotationTimeFrame.title()}
                        value={
                            _.isNil(secret.rotationTimeFrame)
                                ? undefined
                                : localization.info.items.rotationTimeFrame.text({ rotationTimeFrame: secret.rotationTimeFrame })}/>,
                    <InfoItem
                        key="nextRotationTime"
                        title={localization.info.items.nextRotationTime()}
                        value={
                            _.isNil(secret.nextRotationTime)
                                ? undefined
                                : TimeFormatter.profileFormatDateTime(secret.nextRotationTime)}/>

                ]}
                options={options?.infoOptions}>
            </Info>
    });
}