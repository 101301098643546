import { Grid, Typography } from "@mui/material";
import React from "react";
import { ComplianceSecuredBar, Contract, useComplianceTranslator, useTheme } from "../../../../../../../common";

type PoliciesProps = {
    borderBottom: boolean;
    borderTop: boolean;
    complianceSectionData: Contract.ComplianceSectionData;
};

export function Policies({ borderBottom, borderTop, complianceSectionData }: PoliciesProps) {
    const theme = useTheme();
    const complianceTranslator = useComplianceTranslator();
    return (
        <Grid
            alignItems="center"
            container={true}
            style={{
                border: theme.border.primary,
                borderRadius:
                    borderTop
                        ? theme.spacing(0.75, 0.75, 0, 0)
                        : borderBottom
                            ? theme.spacing(0, 0, 0.75, 0.75)
                            : undefined,
                borderTop:
                    borderTop
                        ? theme.border.primary
                        : "none",
                minHeight: theme.spacing(8.75),
                padding: theme.spacing(1, 2.5)
            }}>
            <Grid
                item={true}
                style={{ alignSelf: "flex-start" }}
                xs={true}>
                <Grid
                    container={true}
                    direction="column"
                    style={{
                        color: theme.palette.text.primary,
                        justifyContent: "center",
                        minHeight: theme.spacing(4.5)
                    }}>
                    <Grid item={true}>
                        <Typography
                            style={{ fontWeight: 600 }}>
                            {complianceTranslator(complianceSectionData.identifier).title}
                        </Typography>
                    </Grid>
                    <Grid item={true}>
                        <Typography>
                            {complianceTranslator(complianceSectionData.identifier).description}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item={true}
                style={{
                    paddingLeft: theme.spacing(1),
                    width: theme.spacing(29)
                }}>
                <ComplianceSecuredBar data={complianceSectionData}/>
            </Grid>
        </Grid>);
}