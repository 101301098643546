﻿import { ActionMenuItem, Menu, useLocalization, useSelectionActionsContext } from "@infrastructure";
import { Box } from "@mui/material";
import _, { Dictionary } from "lodash";
import React, { Fragment, useMemo, useState } from "react";
import { Contract, JiraIcon, JiraProjectReference, RiskController, RiskType, ScopeHelper, scopeSystemEntityModelStore, SelectionActionButton, ticketingServiceTicketStore, UpsertJiraIssueDialog } from "..";

export type UpdateJiraIssueSelectionActionProps = {
    getItemIssueUpdateCount: (item: any) => number;
    getSelectedRiskIdToScopeIdsMap: () => Dictionary<string[]>;
    getSelectionRiskIdToJiraIssueIdMap: (item: any) => Dictionary<string[]>;
    reloadItems: () => Promise<void>;
    riskType: RiskType;
};

export function UpdateJiraIssuesSelectionAction({ getItemIssueUpdateCount, getSelectedRiskIdToScopeIdsMap, getSelectionRiskIdToJiraIssueIdMap, reloadItems, riskType }: UpdateJiraIssueSelectionActionProps) {
    const { actionEnded, actionExecuting, actionStarted, itemIds, loadedItems } = useSelectionActionsContext();
    const selectedRiskIdToScopeIdsMap = getSelectedRiskIdToScopeIdsMap();
    const instanceModels =
        ScopeHelper.getScopesParentScopeSystemEntityModelsIntersection(
            _.values(selectedRiskIdToScopeIdsMap),
            scopeSystemEntityModelStore.useGetJira());

    const [projectMap, updateCount] =
        useMemo(
            () => {
                const projectMap =
                    _(instanceModels).
                        flatMap(instanceModel => (instanceModel.configuration as Contract.JiraInstanceConfiguration).projects).
                        keyBy(project => project.rawId).
                        value();
                if (loadedItems.length !== itemIds.length) {
                    return [projectMap, undefined];
                }

                const updateCount =
                    _(loadedItems).
                        map(item => getItemIssueUpdateCount(item)).
                        sum();
                return [projectMap, updateCount];
            },
            [itemIds, loadedItems]);

    const localization =
        useLocalization(
            "common.updateJiraIssuesSelectionAction",
            () => ({
                title: {
                    empty: "There are no issues to update",
                    selectUpdate: "Update Jira issues of project:"
                },
                update: [
                    "Update Jira issue",
                    "Update Jira issues"
                ]
            }));
    const jiraIssueMap =
        ticketingServiceTicketStore.useGetJiraIssueMap(
            _(getSelectionRiskIdToJiraIssueIdMap(loadedItems)).
                values().
                flatMap().
                uniq().
                value());

    const [riskIdToJiraIssuesMap, singleSelectedJiraProjectReference] =
        useMemo(
            () => {
                if (loadedItems.length !== itemIds.length) {
                    return [undefined, undefined];
                }

                const riskIdToJiraIssuesMap =
                    _(getSelectionRiskIdToJiraIssueIdMap(loadedItems)).
                        mapValues(
                            jiraIssueIds =>
                                _(jiraIssueIds).
                                    map(jiraIssueId => jiraIssueMap![jiraIssueId]).
                                    filter(jiraIssue => !jiraIssue.multipleRisks).
                                    value()).
                        pickBy(jiraIssues => !_.isEmpty(jiraIssues)).
                        value();
                if (_(riskIdToJiraIssuesMap).
                    values().
                    flatMap().
                    uniqBy(jiraIssue => `${jiraIssue.instanceId}-${jiraIssue.projectRawId}`).
                    size() !== 1) {
                    return [riskIdToJiraIssuesMap, undefined];
                }

                const singleSelectedJiraProjectReference = {
                    instanceId: (_.values(riskIdToJiraIssuesMap))[0][0].instanceId,
                    projectRawId: _.values(riskIdToJiraIssuesMap)[0][0].projectRawId
                };

                return [riskIdToJiraIssuesMap, singleSelectedJiraProjectReference];
            },
            [itemIds, loadedItems]);

    const [open, setOpen] = useState(false);
    const [projectReference, setProjectReference] = useState<JiraProjectReference>();
    const [riskIdToIssueIdsMap, setRiskIdToIssueIdsMap] = useState<Dictionary<string[]>>({});
    return (
        !_.isEmpty(instanceModels) &&
        (loadedItems.length !== itemIds.length ||
            !_.isEmpty(riskIdToJiraIssuesMap))
            ? <Box sx={{ height: "100%" }}>
                {open &&
                    <UpsertJiraIssueDialog
                        instanceModels={instanceModels}
                        projectReference={projectReference}
                        riskIdToIssueIdsMap={riskIdToIssueIdsMap}
                        riskType={riskType}
                        onClose={
                            async successRiskIds => {
                                if (!_.isEmpty(successRiskIds)) {
                                    await reloadItems();
                                }

                                actionEnded();
                                setOpen(false);
                            }}/>}
                <Menu
                    disabled={!_.isNil(singleSelectedJiraProjectReference)}
                    itemsOrGetItems={
                        async () => {
                            let multiSelectedRiskIdToJiraIssuesMap = riskIdToJiraIssuesMap;
                            if (_.isNil(multiSelectedRiskIdToJiraIssuesMap)) {
                                const getRiskIdToJiraIssuesMapResponse = await RiskController.getRiskIdToJiraIssuesMap(new Contract.RiskControllerGetRiskIdToJiraIssuesMapRequest(_.keys(selectedRiskIdToScopeIdsMap)));
                                multiSelectedRiskIdToJiraIssuesMap =
                                    _.mapValues(
                                        getRiskIdToJiraIssuesMapResponse.riskIdToJiraIssuesMap,
                                        jiraIssues =>
                                            _.filter(
                                                jiraIssues,
                                                jiraIssue => !jiraIssue.multipleRisks));
                            }

                            const instanceIdToProjectRawIdToRiskIdToIssueIdsMap =
                                _(multiSelectedRiskIdToJiraIssuesMap).
                                    flatMap(
                                        (jiraIssues, riskId) =>
                                            _.map(
                                                jiraIssues,
                                                jiraIssue => ({
                                                    id: jiraIssue.id,
                                                    instanceId: jiraIssue.instanceId,
                                                    projectRawId: jiraIssue.projectRawId,
                                                    riskId
                                                })
                                            )).
                                    groupBy(jiraIssueData => jiraIssueData.instanceId).
                                    mapValues(
                                        jiraIssueDatas =>
                                            _(jiraIssueDatas).
                                                groupBy(jiraIssueData => jiraIssueData.projectRawId).
                                                mapValues(
                                                    jiraIssueDatas =>
                                                        _(jiraIssueDatas).
                                                            groupBy(jiraIssueData => jiraIssueData.riskId).
                                                            mapValues(
                                                                jiraIssueDatas =>
                                                                    _.map(
                                                                        jiraIssueDatas,
                                                                        jiraIssueData => jiraIssueData.id)).
                                                            value()).
                                                value()).
                                    value();

                            return _.flatMap(
                                instanceIdToProjectRawIdToRiskIdToIssueIdsMap,
                                (projectIdToRiskIdToIssueIdsMap, instanceId) =>
                                    _.map(
                                        projectIdToRiskIdToIssueIdsMap,
                                        (riskIdToIssueIdsMap, projectRawId) =>
                                            new ActionMenuItem(
                                                () => {
                                                    setRiskIdToIssueIdsMap(riskIdToIssueIdsMap);
                                                    setProjectReference(
                                                        {
                                                            instanceId,
                                                            projectRawId
                                                        });
                                                    setOpen(true);
                                                },
                                                projectMap![projectRawId].rawId)));
                        }}
                    sx={{ height: "100%" }}
                    titleOptions={{
                        titleOrGetTitle:
                            (items: any[]) =>
                                _.isEmpty(items)
                                    ? localization.title.empty()
                                    : localization.title.selectUpdate()
                    }}
                    variant="topCenter">
                    <SelectionActionButton
                        startIcon={
                            <JiraIcon
                                sx={{
                                    opacity:
                                        actionExecuting
                                            ? 0.4
                                            : 1
                                }}/>}
                        onClick={
                            () => {
                                if (!_.isNil(singleSelectedJiraProjectReference)) {
                                    setRiskIdToIssueIdsMap(
                                        _(riskIdToJiraIssuesMap!).
                                            mapValues(
                                                riskJiraIssues =>
                                                    _.map(
                                                        riskJiraIssues,
                                                        riskJiraIssue => riskJiraIssue.id)).
                                            value());
                                    setProjectReference(
                                        {
                                            instanceId: singleSelectedJiraProjectReference.instanceId,
                                            projectRawId: singleSelectedJiraProjectReference.projectRawId
                                        });
                                    setOpen(true);
                                    actionStarted(true);
                                }
                            }}>
                        {localization.update(updateCount ?? 0)}
                    </SelectionActionButton>
                </Menu>
            </Box>
            : <Fragment/>);
}