import { InlineItems, TextViewer, useExecuteOperation, useLocalization } from "@infrastructure";
import { Box } from "@mui/material";
import _, { Dictionary } from "lodash";
import React from "react";
import { Contract, EntityController, useTheme } from "../../../../../../..";

export type InlinePermitterConditionsProps = {
    conditionTypeToConditionsConsistentHashesMap: Dictionary<string[]>;
};

export function InlinePermitterConditions({ conditionTypeToConditionsConsistentHashesMap }: InlinePermitterConditionsProps) {
    const localization =
        useLocalization(
            "common.access.hooks.useDefinition.hooks.useAwsDefinition.inlinePermitterConditions",
            () => ({
                conditions: [
                    "1 condition",
                    "{{count | NumberFormatter.humanize}} conditions"
                ]
            }));

    return (
        <InlineItems
            items={
                _(conditionTypeToConditionsConsistentHashesMap).
                    values().
                    flatMap().
                    value()}
            itemsPopover={
                items =>
                    <ConditionsViewer
                        conditionsConsistentHashes={items}
                        conditionTypeToConditionsConsistentHashesMap={conditionTypeToConditionsConsistentHashesMap}/>}
            itemsPopoverTransition={false}
            namePluralizer={localization.conditions}
            variant="itemCountAndType"/>);
}

type ConditionsViewerProps = {
    conditionsConsistentHashes: string[];
    conditionTypeToConditionsConsistentHashesMap: Dictionary<string[]>;
};

function ConditionsViewer({ conditionsConsistentHashes, conditionTypeToConditionsConsistentHashesMap }: ConditionsViewerProps) {
    const localization =
        useLocalization(
            "common.access.hooks.useDefinition.hooks.useAwsDefinition.inlinePermitterConditions.conditionsViewer",
            () => ({
                [Contract.TypeNames.AwsPermissionEdgeActionConditionType]: {
                    [Contract.AwsPermissionEdgeActionConditionType.PermissionBoundaryPolicyAllow]: "Allow conditions through permission boundary",
                    [Contract.AwsPermissionEdgeActionConditionType.PermissionBoundaryPolicyDeny]: "Deny conditions through permission boundary",
                    [Contract.AwsPermissionEdgeActionConditionType.PrincipalPolicyAllow]: "Allow conditions through principal policies",
                    [Contract.AwsPermissionEdgeActionConditionType.PrincipalPolicyDeny]: "Deny conditions through principal policies",
                    [Contract.AwsPermissionEdgeActionConditionType.ResourceControlPolicyDeny]: "Deny conditions through resource control policies",
                    [Contract.AwsPermissionEdgeActionConditionType.ResourcePolicyAllow]: "Allow conditions through resource policy",
                    [Contract.AwsPermissionEdgeActionConditionType.ResourcePolicyDeny]: "Deny conditions through resource policy",
                    [Contract.AwsPermissionEdgeActionConditionType.ServiceControlPolicyAllow]: "Allow conditions through service control policies",
                    [Contract.AwsPermissionEdgeActionConditionType.ServiceControlPolicyDeny]: "Deny conditions through service control policies"
                }
            }));

    const [{ conditionsConsistentHashToJsonMap }] =
        useExecuteOperation(
            ConditionsViewer,
            () => EntityController.getAwsAccessListPermissionActionConditionJsons(new Contract.EntityControllerGetAwsAccessListPermissionActionConditionJsonsRequest(conditionsConsistentHashes)));

    const conditionTypes = [
        Contract.AwsPermissionEdgeActionConditionType.PrincipalPolicyAllow,
        Contract.AwsPermissionEdgeActionConditionType.PrincipalPolicyDeny,
        Contract.AwsPermissionEdgeActionConditionType.ResourceControlPolicyDeny,
        Contract.AwsPermissionEdgeActionConditionType.ResourcePolicyAllow,
        Contract.AwsPermissionEdgeActionConditionType.ResourcePolicyDeny,
        Contract.AwsPermissionEdgeActionConditionType.PermissionBoundaryPolicyAllow,
        Contract.AwsPermissionEdgeActionConditionType.PermissionBoundaryPolicyDeny,
        Contract.AwsPermissionEdgeActionConditionType.ServiceControlPolicyAllow,
        Contract.AwsPermissionEdgeActionConditionType.ServiceControlPolicyDeny
    ];

    const conditionStringParts = [];
    for (const conditionType of conditionTypes) {
        if (conditionTypeToConditionsConsistentHashesMap[conditionType]) {
            const conditionTypeStringParts = [];
            for (const conditionsConsistentHash of conditionTypeToConditionsConsistentHashesMap[conditionType]) {
                conditionTypeStringParts.push(conditionsConsistentHashToJsonMap[conditionsConsistentHash]);
            }

            const translatedConditionType = localization[Contract.TypeNames.AwsPermissionEdgeActionConditionType][conditionType]();
            conditionStringParts.push(`// ${translatedConditionType}\n ${conditionTypeStringParts.join("\n\n")}`);
        }
    }

    const theme = useTheme();
    return (
        <Box
            sx={{
                height: theme.spacing(60),
                padding: theme.spacing(0.25),
                width: theme.spacing(60)
            }}>
            <TextViewer
                format="json"
                text={conditionStringParts.join("\n\n\n")}/>
        </Box>);
}