import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function RevealIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M3.18488 11.5C4.78877 14.7615 8.13541 17 12 17C15.8646 17 19.2112 14.7615 20.8151 11.5C19.2112 8.23854 15.8646 6 12 6C8.13541 6 4.78877 8.23854 3.18488 11.5ZM23 11.5C21.27 15.89 17 19 12 19C7 19 2.73 15.89 1 11.5C2.73 7.11 7 4 12 4C17 4 21.27 7.11 23 11.5Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M9.17157 8.67157C9.92172 7.92143 10.9391 7.5 12 7.5C13.0609 7.5 14.0783 7.92143 14.8284 8.67157C15.5786 9.42172 16 10.4391 16 11.5C16 12.5609 15.5786 13.5783 14.8284 14.3284C14.0783 15.0786 13.0609 15.5 12 15.5C10.9391 15.5 9.92172 15.0786 9.17157 14.3284C8.42143 13.5783 8 12.5609 8 11.5C8 10.4391 8.42143 9.42172 9.17157 8.67157ZM12 9.5C11.4696 9.5 10.9609 9.71071 10.5858 10.0858C10.2107 10.4609 10 10.9696 10 11.5C10 12.0304 10.2107 12.5391 10.5858 12.9142C10.9609 13.2893 11.4696 13.5 12 13.5C12.5304 13.5 13.0391 13.2893 13.4142 12.9142C13.7893 12.5391 14 12.0304 14 11.5C14 10.9696 13.7893 10.4609 13.4142 10.0858C13.0391 9.71071 12.5304 9.5 12 9.5Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}