﻿import { BulletIcon, DeleteIcon, Link, Mails, Message, StringHelper, useChangeEffect, useLocalization, useSyncContext } from "@infrastructure";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useEffect, useMemo } from "react";
import { Contract, CustomerConsoleAppUrlHelper, ElasticsearchItemPageHelper, EntityController, InlineEntities, PagedEntityMultiSelect, PermissionManagementController, PermissionManagementHelper, RadioGroup, ScopeHelper, scopeSystemEntityModelStore, SlackChannelReferenceMultiSelect, slackWorkspaceChannelOperationStore, TeamsChannelReferenceHelper, TeamsChannelReferenceMultiSelect, useScopeNavigationViewContext, useTheme } from "../../../../../../../../../common";
import { useEligibilitiesContext } from "../../../../../PermissionEligibilities";
import { useAddOrEditContext, useSetAddOrEditContext } from "../../../AddOrEdit";

export function Approval() {
    const workspaceIdToChannelRawIdToNameMap = slackWorkspaceChannelOperationStore.useGet();
    const { permissionEligibilityData: { approval: { auto: autoApproval, levelToApproverPrincipalIdsMap, mails, slackChannelReferences: selectedSlackChannelReferences, teamsChannelReferences: selectedTeamsChannelReferences }, granteePrincipalIds, principalTenantId }, upsertPermissionEligibilityExecuting } = useAddOrEditContext();
    const setAddOrEditContext = useSetAddOrEditContext();
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const { editMode } = useEligibilitiesContext();

    const allSlackWorkspaceModels = scopeSystemEntityModelStore.useGetSlack();
    const slackWorkspaceModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeNodeModel.configuration.id],
            allSlackWorkspaceModels);
    const slackChannelReferences =
        useMemo(
            () =>
                _.flatMap(
                    slackWorkspaceModels,
                    slackWorkspaceModel =>
                        _(workspaceIdToChannelRawIdToNameMap[slackWorkspaceModel.id]).
                            keys().
                            map(
                                slackChannelRawId =>
                                    new Contract.SlackConversationReference(
                                        slackChannelRawId,
                                        slackWorkspaceModel.configuration.id)).
                            value()),
            [slackWorkspaceModels]);
    const allTeamsOrganizationModels = scopeSystemEntityModelStore.useGetTeams();
    const teamsOrganizationModels =
        ScopeHelper.getParentScopeSystemEntityModelsIntersection(
            [scopeNodeModel.configuration.id],
            allTeamsOrganizationModels);
    const teamsChannelReferences =
        useMemo(
            () =>
                _.flatMap(
                    teamsOrganizationModels,
                    teamsOrganizationModel => _.flatMap((teamsOrganizationModel.state as Contract.TeamsOrganizationState).teamRawIdToChannelReferencesMap)),
            [teamsOrganizationModels]);

    useEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => {
                    addOrEditContext.permissionEligibilityData.approval.teamsChannelReferences =
                        _.intersectionBy(
                            selectedTeamsChannelReferences,
                            teamsChannelReferences,
                            teamsChannelReference => TeamsChannelReferenceHelper.getId(teamsChannelReference));
                    return { ...addOrEditContext };
                });

            if (!autoApproval && !editMode) {
                checkPermissionEligibility();
            }
        },
        []);

    const localization =
        useLocalization(
            "views.user.permissionEligibilities.addOrEdit.hooks.useDefinition.approval",
            () => ({
                auto: "No approval required",
                confirmOverlap: {
                    approverGranteeUserOverlap: [
                        "User {{usersElement}} will not be able to approve their own access requests.",
                        "{{usersElement}} will not be able to approve their own access requests."
                    ],
                    approverUserLevelOverlap: [
                        "User {{usersElement}} appears in more than one approval level.",
                        "{{usersElement}} appear in more than one approval level."
                    ],
                    continue: "Are you sure you want to continue?"
                },
                manual: {
                    addLevel: {
                        info: {
                            links: {
                                learnMore: "Learn more"
                            },
                            text: "Create a multi-level approval workflow requiring extra approval before granting access. Assign approvers for each level. {{documentationLink}}"
                        },
                        title: "+ Add approval level"
                    },
                    levelToApproverPrincipalIdsMap: {
                        "level0": "Approvers",
                        "level1": "Second level approvers",
                        "level2": "Third level approvers"
                    },
                    notifications: {
                        info: {
                            slackEnabled: {
                                false: "Requests are automatically sent via email for approval. To increase collaboration, you can also notify other users via email, or via Teams/Slack (if configured). Only approvers can approve/deny requests.",
                                true: "Users can request/approve access natively within Slack. Requests are automatically sent to approvers via email and private Slack message for approval. To increase collaboration, you can also notify others by adding additional destinations below. Only approvers can approve/deny requests."
                            }
                        },
                        title: "Additional notifications"
                    },
                    title: "Approval required"
                },
                title: "Approval workflow"
            }));

    useEffect(
        () => {
            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    fieldNameToValidMap: {
                        ...addOrEditContext.fieldNameToValidMap,
                        approval:
                            autoApproval ||
                            _(levelToApproverPrincipalIdsMap).
                                values().
                                every(approverPrincipalIds => !_.isEmpty(approverPrincipalIds))
                    }
                }));
        },
        [autoApproval, levelToApproverPrincipalIdsMap]);

    function shouldCheckOverlap() {
        return !autoApproval &&
            !_.isEmpty(granteePrincipalIds) &&
            !_.some(
                levelToApproverPrincipalIdsMap,
                approverPrincipalIds => _.isEmpty(approverPrincipalIds));
    }

    function setValidationEnded() {
        setAddOrEditContext(
            addOrEditContext => ({
                ...addOrEditContext,
                fieldNameToValidationExecutingMap: {
                    ...addOrEditContext.fieldNameToValidationExecutingMap,
                    approval: false
                }
            }));
    }

    function setValidationStarted() {
        setAddOrEditContext(
            addOrEditContext => ({
                ...addOrEditContext,
                fieldNameToValidationExecutingMap: {
                    ...addOrEditContext.fieldNameToValidationExecutingMap,
                    approval: true
                }
            }));
    }

    useChangeEffect(
        () => {
            if (shouldCheckOverlap()) {
                setValidationStarted();
            }
        },
        [autoApproval, granteePrincipalIds, levelToApproverPrincipalIdsMap]);

    const getPrincipalOverlapSyncContext = useSyncContext();

    const theme = useTheme();
    async function checkPermissionEligibility() {
        if (!shouldCheckOverlap()) {
            updateUpsertButtonConfirmData([], []);
            setValidationEnded();
            return;
        }

        setValidationStarted();
        const syncContext = getPrincipalOverlapSyncContext.create();
        let granteeApproverOverlapUserIds: string[];
        let levelOverlapApproverUserIds: string[];
        try {
            const getPermissionEligibilityPrincipalOverlapResponse =
                await PermissionManagementController.getPermissionEligibilityPrincipalOverlap(
                    new Contract.PermissionManagementControllerGetPermissionEligibilityPrincipalOverlapRequest(
                        granteePrincipalIds,
                        levelToApproverPrincipalIdsMap));
            granteeApproverOverlapUserIds = getPermissionEligibilityPrincipalOverlapResponse.granteeApproverOverlapUserIds;
            levelOverlapApproverUserIds = getPermissionEligibilityPrincipalOverlapResponse.levelOverlapApproverUserIds;
        } catch {
            granteeApproverOverlapUserIds = [];
            levelOverlapApproverUserIds = [];
        }

        if (!getPrincipalOverlapSyncContext.isActive(syncContext)) {
            return;
        }

        updateUpsertButtonConfirmData(
            granteeApproverOverlapUserIds,
            levelOverlapApproverUserIds);
        setValidationEnded();
    }

    useChangeEffect(
        checkPermissionEligibility,
        [autoApproval, granteePrincipalIds, levelToApproverPrincipalIdsMap],
        500);

    function updateUpsertButtonConfirmData(granteeApproverOverlapUserIds: string[], levelOverlapApproverUserIds: string[]) {
        if (_.isEmpty(granteeApproverOverlapUserIds) && _.isEmpty(levelOverlapApproverUserIds)) {
            setAddOrEditContext(
                addOrEditContext => ({
                    ...addOrEditContext,
                    upsertButtonConfirmDisabled: true,
                    upsertButtonConfirmMessage: undefined
                }));
            return;
        }

        const multiOverlapApproverUserExists =
            !_.isEmpty(granteeApproverOverlapUserIds) &&
            !_.isEmpty(levelOverlapApproverUserIds);
        const overlapApproverUserExists =
            !_.isEmpty(granteeApproverOverlapUserIds) ||
            !_.isEmpty(levelOverlapApproverUserIds);
        setAddOrEditContext(
            addOrEditContext => ({
                ...addOrEditContext,
                upsertButtonConfirmDisabled: !overlapApproverUserExists,
                upsertButtonConfirmMessage:
                    <Stack
                        spacing={3}
                        sx={{ padding: theme.spacing(0, 5) }}>
                        <Stack spacing={2}>
                            {_<string>([]).
                                concatIf(
                                    !_.isEmpty(granteeApproverOverlapUserIds),
                                    () =>
                                        localization.confirmOverlap.approverGranteeUserOverlap(
                                            granteeApproverOverlapUserIds.length,
                                            {
                                                usersElement:
                                                    <InlineEntities
                                                        entityIdsOrModels={granteeApproverOverlapUserIds}
                                                        entityTypeName={Contract.TypeNames.IPermissionManagementUser}
                                                        variant="itemOrItemCountAndType"/>
                                            })).
                                concatIf(
                                    !_.isEmpty(levelOverlapApproverUserIds),
                                    () =>
                                        localization.confirmOverlap.approverUserLevelOverlap(
                                            levelOverlapApproverUserIds.length,
                                            {
                                                usersElement:
                                                    <InlineEntities
                                                        entityIdsOrModels={levelOverlapApproverUserIds}
                                                        entityTypeName={Contract.TypeNames.IPermissionManagementUser}
                                                        variant="itemOrItemCountAndType"/>
                                            })).
                                map(
                                    (overlapMessage, messageIndex) =>
                                        <Stack
                                            alignItems="baseline"
                                            direction="row"
                                            key={messageIndex}
                                            spacing={1}>
                                            {multiOverlapApproverUserExists &&
                                                <BulletIcon
                                                    sx={{
                                                        color: theme.palette.text.primary,
                                                        fontSize: "9px",
                                                        height: "100%"
                                                    }}/>}
                                            <Typography
                                                sx={{ wordBreak: "break-word" }}
                                                variant="h3">
                                                {overlapMessage}
                                            </Typography>
                                        </Stack>).
                                value()}
                        </Stack>
                        <Typography
                            align="center"
                            sx={{ wordBreak: "break-word" }}
                            variant="h1">
                            {localization.confirmOverlap.continue()}
                        </Typography>
                    </Stack>
            }));
    }

    return (
        <Stack spacing={2}>
            <Typography variant="h4">
                {localization.title()}
            </Typography>
            <Stack>
                <RadioGroup
                    items={[
                        {
                            disabled: upsertPermissionEligibilityExecuting,
                            label: localization.auto(),
                            value: true
                        },
                        {
                            disabled: upsertPermissionEligibilityExecuting,
                            label: localization.manual.title(),
                            value: false
                        }
                    ]}
                    selectedValue={autoApproval}
                    sx={{ marginBottom: 0 }}
                    onChange={
                        value =>
                            setAddOrEditContext(
                                addOrEditContext => {
                                    addOrEditContext.permissionEligibilityData.approval.auto = StringHelper.isTrue(value);
                                    return { ...addOrEditContext };
                                })}/>
                {!autoApproval && (
                    <Fragment>
                        {_(levelToApproverPrincipalIdsMap).
                            map((approverPrincipalIds, level) => ({
                                approverPrincipalIds,
                                level: parseInt(level)
                            })).
                            orderBy(approvalData => approvalData.level).
                            map(
                                approvalData => {
                                    const levelDeletable = approvalData.level > 0;
                                    return (
                                        <Stack
                                            alignItems="center"
                                            direction="row"
                                            key={`level${approvalData.level}`}
                                            spacing={2}
                                            sx={{
                                                marginLeft: theme.spacing(3),
                                                marginTop: theme.spacing(1.5)
                                            }}>
                                            <Box sx={{ flex: 1 }}>
                                                <PagedEntityMultiSelect
                                                    disabled={
                                                        _.isNil(principalTenantId) ||
                                                        upsertPermissionEligibilityExecuting}
                                                    fullWidth={true}
                                                    getEntityModelPage={
                                                        ElasticsearchItemPageHelper.makePagedEntityMultiSelect(
                                                            async (itemNextPageSearchCursor, searchText) => {
                                                                const { entityModelPage } =
                                                                    await EntityController.searchEntityModels(
                                                                        new Contract.EntityControllerSearchEntityModelsPermissionManagementPrincipalRequest(
                                                                            false,
                                                                            15,
                                                                            itemNextPageSearchCursor,
                                                                            undefined,
                                                                            searchText,
                                                                            principalTenantId!));
                                                                return entityModelPage;
                                                            })}
                                                    placeholder={localization.manual.levelToApproverPrincipalIdsMap.translate(`level${approvalData.level}`)}
                                                    selectedEntityIds={approvalData.approverPrincipalIds}
                                                    onSelectedEntityIdsChanged={
                                                        approverPrincipalIds =>
                                                            setAddOrEditContext(
                                                                addOrEditContext => {
                                                                    addOrEditContext.permissionEligibilityData.approval.levelToApproverPrincipalIdsMap = {
                                                                        ...addOrEditContext.permissionEligibilityData.approval.levelToApproverPrincipalIdsMap,
                                                                        [approvalData.level]: approverPrincipalIds
                                                                    };
                                                                    return { ...addOrEditContext };
                                                                })}/>
                                            </Box>
                                            {levelDeletable &&
                                                <IconButton
                                                    disabled={upsertPermissionEligibilityExecuting}
                                                    onClick={
                                                        () =>
                                                            setAddOrEditContext(
                                                                addOrEditContext => {
                                                                    addOrEditContext.permissionEligibilityData.approval.levelToApproverPrincipalIdsMap =
                                                                        _(addOrEditContext.permissionEligibilityData.approval.levelToApproverPrincipalIdsMap).
                                                                            omit(approvalData.level).
                                                                            toPairs().
                                                                            orderBy(([level, _]) => level).
                                                                            map(
                                                                                ([_, approverPrincipalIds], levelIndex) => ({
                                                                                    approverPrincipalIds,
                                                                                    level: levelIndex
                                                                                })).
                                                                            keyBy(approvalData => approvalData.level).
                                                                            mapValues(approvalData => approvalData.approverPrincipalIds).
                                                                            value();
                                                                    return { ...addOrEditContext };
                                                                })}>
                                                    <DeleteIcon/>
                                                </IconButton>}
                                        </Stack>);
                                }).
                            value()}
                        {_.size(levelToApproverPrincipalIdsMap) < 3 &&
                            <Stack
                                alignItems="center"
                                direction="row"
                                justifyContent="flex-end"
                                spacing={1}
                                sx={{ marginTop: theme.spacing(2) }}>
                                <Typography
                                    sx={{
                                        cursor:
                                            upsertPermissionEligibilityExecuting
                                                ? "default"
                                                : "pointer",
                                        fontSize: "14px"
                                    }}
                                    onClick={
                                        () => {
                                            if (!upsertPermissionEligibilityExecuting) {
                                                setAddOrEditContext(
                                                    addOrEditContext => {
                                                        addOrEditContext.permissionEligibilityData.approval.levelToApproverPrincipalIdsMap = {
                                                            ...addOrEditContext.permissionEligibilityData.approval.levelToApproverPrincipalIdsMap,
                                                            [_.size(addOrEditContext.permissionEligibilityData.approval.levelToApproverPrincipalIdsMap)]: []
                                                        };
                                                        return { ...addOrEditContext };
                                                    });
                                            }
                                        }}>
                                    {localization.manual.addLevel.title()}
                                </Typography>
                                <Message
                                    level="info"
                                    title={
                                        localization.manual.addLevel.info.text({
                                            documentationLink:
                                                <Link
                                                    urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsAdministerJitAccessMultiLevelApprovalWorkflowRelativeUrl()}
                                                    variant="external">
                                                    {localization.manual.addLevel.info.links.learnMore()}
                                                </Link>
                                        })}
                                    variant="minimal"/>
                            </Stack>}
                        <Stack
                            alignItems="center"
                            direction="row"
                            spacing={1}
                            sx={{
                                marginBottom: theme.spacing(1.5),
                                marginTop: theme.spacing(3.5)
                            }}>
                            <Typography variant="h4">
                                {localization.manual.notifications.title()}
                            </Typography>
                            <Message
                                level="info"
                                title={
                                    PermissionManagementHelper.slackEnabled
                                        ? localization.manual.notifications.info.slackEnabled.true()
                                        : localization.manual.notifications.info.slackEnabled.false()}
                                variant="minimal"/>
                        </Stack>
                        <Stack spacing={1.5}>
                            <Mails
                                disabled={upsertPermissionEligibilityExecuting}
                                mails={mails}
                                variant="large"
                                onChange={
                                    mails =>
                                        setAddOrEditContext(
                                            addOrEditContext => {
                                                addOrEditContext.permissionEligibilityData.approval.mails = mails;
                                                return { ...addOrEditContext };
                                            })}/>
                            {(!_.isEmpty(selectedSlackChannelReferences) ||
                                !_.isEmpty(slackChannelReferences)) && (
                                <SlackChannelReferenceMultiSelect
                                    disabled={upsertPermissionEligibilityExecuting}
                                    scopeId={scopeNodeModel.configuration.id}
                                    selectedChannelReferences={selectedSlackChannelReferences}
                                    onSelectedChannelReferencesChanged={
                                        selectedSlackChannelReferences =>
                                            setAddOrEditContext(
                                                addOrEditContext => {
                                                    addOrEditContext.permissionEligibilityData.approval.slackChannelReferences = selectedSlackChannelReferences;
                                                    return { ...addOrEditContext };
                                                })}/>)}
                            {!_.isEmpty(teamsChannelReferences) && (
                                <TeamsChannelReferenceMultiSelect
                                    disabled={upsertPermissionEligibilityExecuting}
                                    scopeId={scopeNodeModel.configuration.id}
                                    selectedChannelReferences={selectedTeamsChannelReferences}
                                    onSelectedChannelReferencesChanged={
                                        selectedTeamsChannelReferences =>
                                            setAddOrEditContext(
                                                addOrEditContext => {
                                                    addOrEditContext.permissionEligibilityData.approval.teamsChannelReferences = selectedTeamsChannelReferences;
                                                    return { ...addOrEditContext };
                                                })}/>)}
                        </Stack>
                    </Fragment>)}
            </Stack>
        </Stack>);
}