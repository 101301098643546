import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, InlineItems, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import _, { Dictionary } from "lodash";
import React from "react";
import { Contract, Entity, InfoCard, IntRangeHelper, NetworkScopeFormatter, NetworkScopeHelper } from "../../../../../../../../../../../../common";

type GcpComputeFirewallPolicyRulesTableProps = {
    rules: Contract.GcpComputeFirewallPolicyRule[];
    targetServiceAccountMailToIdReferenceMap: Dictionary<string>;
    targetVpcUrlToIdReferenceMap: Dictionary<string>;
};

export function GcpComputeFirewallPolicyRulesTable({ rules, targetServiceAccountMailToIdReferenceMap, targetVpcUrlToIdReferenceMap }: GcpComputeFirewallPolicyRulesTableProps) {
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.gcp.useGcpComputeFirewallPolicyDefinition.rulesInfoCard",
            () => ({
                columns: {
                    action: {
                        title: "Action",
                        [Contract.TypeNames.GcpComputeFirewallPolicyRuleAction]: {
                            [Contract.GcpComputeFirewallPolicyRuleAction.Allow]: "Allow",
                            [Contract.GcpComputeFirewallPolicyRuleAction.Deny]: "Deny",
                            [Contract.GcpComputeFirewallPolicyRuleAction.GoToNext]: "Goto Next"
                        }
                    },
                    description: "Description",
                    direction: {
                        title: "Direction",
                        [Contract.TypeNames.GcpComputeFirewallPolicyRuleDirection]: {
                            [Contract.GcpComputeFirewallPolicyRuleDirection.Inbound]: "Ingress",
                            [Contract.GcpComputeFirewallPolicyRuleDirection.Outbound]: "Egress"
                        }
                    },
                    filters: "Filters",
                    priority: "Priority",
                    scopes: "Protocol / Ports",
                    target: {
                        empty: "Apply to all",
                        title: "Targets"
                    }
                },
                empty: "No {{translatedEntityTypeName}}",
                title: "Firewall Rules"
            }));

    return (
        <InfoCard title={localization.title()}>
            <DataTable
                emptyMessageOptions={{
                    emptyMessageText:
                        new EmptyMessageText(
                            localization.empty({
                                translatedEntityTypeName: "Rule"
                            }))
                }}
                fetchItems={
                    () =>
                        _.orderBy(
                            rules,
                            rule => rule.priority)}
                getItemId={(item: Contract.GcpComputeFirewallPolicyRule) => item.description}
                sortOptions={{ enabled: false }}
                variant="card">
                <DataTableColumn
                    id={FirewallPolicyRuleColumnId.Priority}
                    itemProperty={(firewallRule: Contract.GcpComputeFirewallPolicyRule) => firewallRule.priority}
                    title={localization.columns.priority()}/>
                <DataTableColumn
                    id={FirewallPolicyRuleColumnId.Description}
                    itemProperty={
                        (firewallRule: Contract.GcpComputeFirewallPolicyRule) =>
                            _.isEmpty(firewallRule.description)
                                ? "-"
                                : firewallRule.description}
                    title={localization.columns.description()}/>
                <DataTableColumn
                    id={FirewallPolicyRuleColumnId.Direction}
                    itemProperty={(firewallRule: Contract.GcpComputeFirewallPolicyRule) => localization.columns.direction[Contract.TypeNames.GcpComputeFirewallPolicyRuleDirection][firewallRule.direction]()}
                    title={localization.columns.direction.title()}/>
                <DataTableColumn
                    id={FirewallPolicyRuleColumnId.Target}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.GcpComputeFirewallPolicyRule>) => {
                            const firewallRuleTargets =
                                _.concat(
                                    _.map(
                                        item.target.secureTags,
                                        secureTag => secureTag.valueRawId),
                                    _.map(
                                        item.target.serviceAccountMails,
                                        serviceAccountMail => targetServiceAccountMailToIdReferenceMap[serviceAccountMail]),
                                    _.map(
                                        item.target.vpcUrls,
                                        vpcUrl => targetVpcUrlToIdReferenceMap[vpcUrl]));
                            return item.target.any
                                ? <Typography noWrap={true}> {localization.columns.target.empty()} </Typography>
                                : <InlineItems
                                    items={firewallRuleTargets}
                                    variant="itemPlusItemCount">
                                    {target =>
                                        <Entity
                                            entityIdOrModel={target}
                                            variant="iconTextTenant"/>}
                                </InlineItems>;
                        }}
                    title={localization.columns.target.title()}/>
                <DataTableColumn
                    id={FirewallPolicyRuleColumnId.Filter}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.GcpComputeFirewallPolicyRule>) => (
                            <InlineItems
                                items={_<string>([]).
                                    concatIf(
                                        item.direction === Contract.GcpComputeFirewallPolicyRuleDirection.Inbound,
                                        [
                                            ...item.filter.sourceSubnets,
                                            ..._.map(
                                                item.filter.inboundVpcSecureTags,
                                                secureTag => secureTag.valueRawId)
                                        ]).
                                    concatIf(
                                        item.direction === Contract.GcpComputeFirewallPolicyRuleDirection.Outbound,
                                        item.filter.destinationSubnets).
                                    value()}
                                variant="itemPlusItemCount">
                                {filter =>
                                    <Typography noWrap={true}>
                                        {filter}
                                    </Typography>}
                            </InlineItems>)}
                    title={localization.columns.filters()}/>
                <DataTableColumn
                    id={FirewallPolicyRuleColumnId.Scopes}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.GcpComputeFirewallPolicyRule>) =>
                            <InlineItems
                                items={
                                    _.map(
                                        item.scopes,
                                        ({ portRanges, protocolRange }) => {
                                            const formattedPortRanges =
                                                _.map(
                                                    portRanges,
                                                    destinationPortRange =>
                                                        NetworkScopeHelper.isAll(destinationPortRange)
                                                            ? "ALL"
                                                            : IntRangeHelper.format(destinationPortRange));
                                            const protocolRanges = NetworkScopeFormatter.protocolRange(protocolRange, Contract.TenantType.Gcp);
                                            return _.isEmpty(portRanges)
                                                ? protocolRanges
                                                : `${protocolRanges}: ${_.join(formattedPortRanges, ", ")}`;
                                        })}
                                variant="itemPlusItemCount"/>}
                    title={localization.columns.scopes()}/>
                <DataTableColumn
                    id={FirewallPolicyRuleColumnId.Action}
                    itemProperty={(firewallRule: Contract.GcpComputeFirewallPolicyRule) => localization.columns.action[Contract.TypeNames.GcpComputeFirewallPolicyRuleAction][firewallRule.action]()}
                    title={localization.columns.action.title()}/>
            </DataTable>
        </InfoCard>);
}

enum FirewallPolicyRuleColumnId {
    Action = "action",
    Description = "description",
    Direction = "direction",
    Filter = "filter",
    Priority = "priority",
    Scopes = "scopes",
    Target = "target"
}