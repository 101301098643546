import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAadDirectoryUserTypeTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAadDirectoryUserTypeTranslator",
            () => ({
                [Contract.TypeNames.AadDirectoryUserType]: {
                    [Contract.AadDirectoryUserType.Guest]: "Guest",
                    [Contract.AadDirectoryUserType.Member]: "Member",
                    [Contract.AadDirectoryUserType.SelfServiceSignup]: "Self-service signup"
                }
            }));
    return (type: Contract.AadDirectoryUserType) =>
        localization[Contract.TypeNames.AadDirectoryUserType][type]();
}