import { Action0, FoldIcon, useLocalization } from "@infrastructure";
import { Box, Button, Stack, SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactElement } from "react";
import { useTheme } from "../themes";

type InfiniteScrollPagerProps = {
    children: ReactElement;
    fetchItems: Action0;
    footerSx?: SxProps;
    itemCount?: number;
    loadedItemCount: number;
    onClose?: Action0;
    pageSize?: number;
    showEmpty?: boolean;
    sx?: SxProps;
};

export function InfiniteScrollPager({ children, fetchItems, footerSx, itemCount, loadedItemCount, onClose, pageSize, showEmpty = true, sx }: InfiniteScrollPagerProps) {
    const localization =
        useLocalization(
            "common.infiniteScrollPager",
            () => ({
                actions: {
                    close: "Close"
                },
                fetchTitle: "View more",
                summary: {
                    withFetchItems: "{{fetchItemsElement}} *bold*|** {{loadedRiskModelCount | NumberFormatter.humanize}} of {{riskModelCount | NumberFormatter.humanize}}",
                    withoutFetchItems: "{{loadedRiskModelCount | NumberFormatter.humanize}} of {{riskModelCount | NumberFormatter.humanize}}"
                }
            }));
    const theme = useTheme();
    return (
        <Stack sx={sx}>
            <Stack>
                {children}
            </Stack>
            {(!showEmpty && (_.isNil(itemCount) || itemCount === 0)) ||
                (!_.isNil(pageSize) && !_.isNil(itemCount) && pageSize > itemCount)
                ? undefined
                : <Stack
                    alignItems="center"
                    direction="row"
                    sx={{
                        borderTop: theme.border.primary,
                        padding: theme.spacing(1, 1.5),
                        width: "100%",
                        ...footerSx
                    }}>
                    <Box sx={{ flex: 1 }}>
                        {!_.isNil(itemCount) &&
                            <Typography noWrap={true}>
                                {localization.summary[
                                    loadedItemCount === itemCount
                                        ? "withoutFetchItems"
                                        : "withFetchItems"]({
                                    fetchItemsElement:
                                                <Typography
                                                    component="span"
                                                    noWrap={true}
                                                    sx={{
                                                        cursor: "pointer",
                                                        textDecoration: "underline"
                                                    }}
                                                    variant="h5"
                                                    onClick={() => fetchItems()}>
                                                    {localization.fetchTitle()}
                                                </Typography>,
                                    loadedRiskModelCount: loadedItemCount,
                                    riskModelCount: itemCount
                                })}
                            </Typography>}
                    </Box>
                    {!_.isNil(onClose) &&
                        <Button
                            variant="text"
                            onClick={onClose}>
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={0.5}>
                                <FoldIcon sx={{ fontSize: "18px" }}/>
                                <Typography variant="h5">
                                    {localization.actions.close()}
                                </Typography>
                            </Stack>
                        </Button>}
                </Stack>}
        </Stack>);
}