import { map, useLocalization } from "@infrastructure";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetCommonKubernetesClusterRiskContext } from "../../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../../common";

export function useKubernetesClusterControllerManagerInsecureListenerIpAddressRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.KubernetesClusterControllerManagerInsecureListenerIpAddressRisk;
    const clusterModel = entityModelStore.useGet(risk.entityId);
    const getCommonKubernetesClusterRiskContext = useGetCommonKubernetesClusterRiskContext(clusterModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.kubernetes.hooks.clusters.controllerManagers.useKubernetesClusterControllerManagerInsecureListenerIpAddressRiskDefinition",
            () => ({
                description: "{{cluster}} Controller Manager is configured to allow external access instead of being restricted to local communication",
                resolutionSection: {
                    platformKubernetes: {
                        step1: "Edit the Controller Manager configuration file",
                        step2: "Ensure the **--bind-address** argument is set to **127.0.0.1**",
                        step3: "Restart the Controller Manager to apply the changes"
                    },
                    platformOpenShift: {
                        step1: "Edit the Controller Manager configuration file",
                        step2: "Ensure the **--secure-port** argument is set to **10257**",
                        step3: "Ensure the **--insecure-port** or the **--port** argument is set to **0**",
                        step4: "Restart the Controller Manager to apply the changes"
                    }
                }

            }));

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            cluster:
                <Entity
                    entityIdOrModel={clusterModel}
                    entityTypeNameTranslatorOptions={{
                        includeServiceName: true,
                        variant: "title"
                    }}
                    variant="typeText"/>
        }),
        () =>
            map(
                risk.clusterPlatform,
                {
                    [Contract.KubernetesClusterPlatform.OpenShift]: () =>
                        [
                            localization.resolutionSection.platformOpenShift.step1(),
                            localization.resolutionSection.platformOpenShift.step2(),
                            localization.resolutionSection.platformOpenShift.step3(),
                            localization.resolutionSection.platformOpenShift.step4()
                        ],
                    [Contract.KubernetesClusterPlatform.Kubernetes]: () =>
                        [
                            localization.resolutionSection.platformKubernetes.step1(),
                            localization.resolutionSection.platformKubernetes.step2(),
                            localization.resolutionSection.platformKubernetes.step3()
                        ]
                }
            ),
        riskModel,
        () => {
            const clusterRiskContext = getCommonKubernetesClusterRiskContext(clusterModel);

            return [
                clusterRiskContext.generalInformation,
                clusterRiskContext.virtualNetwork,
                clusterRiskContext.sensitive,
                clusterRiskContext.getOpenRiskedEntityRisksContextItem(riskModel.id)
            ];
        }
    )
    ;
}