﻿import _ from "lodash";
import React from "react";
import { FormLayout, makeContextProvider, OrderedWizard, OrderedWizardItem, useLocalization } from "@infrastructure";
import { Contract } from "../../../../../../../../../../../../../../common";
import { useAzureDevOpsContext, useSetAzureDevOpsContext } from "../../AzureDevOps";
import { AllowAppAccessItem, ChooseOrganizationItem, ConfigureOrganizationItem, FinishItem, OrganizationMemberSelectionItem } from "./components";

export class AddOrEditContext {
    public memberSelection?: Contract.OrganizationMemberSelection;
    public tenantPaths?: string[];
    public updatedFolderScopeNodeModel?: Contract.ScopeNodeModel;
    public updatedFolderEnabled?: boolean;
    public updatedOrganizationName?: string;
    public updatedSyncEnabled?: boolean;

    constructor(
        public aadTenantRawId?: string,
        public callbackError?: Contract.CustomerConsoleAppUrlBuilderGetConfigurationIntegrationsAzureDevOpsOrganizationsRelativeUrlError,
        public oAuthAccessToken?: string,
        public organizationModel?: Contract.CodeOrganizationModel) {
        this.memberSelection = organizationModel?.configuration.memberSelection;
        this.updatedFolderEnabled = organizationModel?.configuration.folderEnabled;
    }
}

export const [useAddOrEditContext, useSetAddOrEditContext, useAddOrEditContextProvider] = makeContextProvider<AddOrEditContext>();

export function AddOrEditAzureDevOps() {
    const { aadTenantRawId, addOrEditOpen, callbackError, oAuthAccessToken } = useAzureDevOpsContext();
    const setAzureDevOpsContext = useSetAzureDevOpsContext();

    const [, , ContextProvider] =
        useAddOrEditContextProvider(
            () =>
                new AddOrEditContext(
                    _.isBoolean(addOrEditOpen)
                        ? aadTenantRawId
                        : (addOrEditOpen.configuration as Contract.AzureDevOpsOrganizationConfiguration).aadTenantRawId,
                    callbackError,
                    oAuthAccessToken,
                    _.isBoolean(addOrEditOpen)
                        ? undefined
                        : addOrEditOpen));

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCodeOrganizationItems.azureDevOps.addOrEditAzureDevOps",
            () => ({
                steps: {
                    allowAppAccess: "Grant Admin Consent",
                    chooseOrganization: "Select Organization",
                    configureOrganization: "Grant Organization Permissions",
                    tenants: "Select Repositories"
                },
                title: {
                    add: "Add Azure DevOps Organization",
                    edit: "Edit Azure DevOps Organization"
                }
            }));

    return (
        <ContextProvider>
            <FormLayout
                disableContentPadding={true}
                titleOptions={{
                    text:
                        _.isBoolean(addOrEditOpen)
                            ? localization.title.add()
                            : localization.title.edit()
                }}>
                <OrderedWizard
                    finishItemElement={<FinishItem/>}
                    startItemIndex={
                        !_.isBoolean(addOrEditOpen) ||
                        aadTenantRawId
                            ? 1
                            : 0}
                    onClose={
                        () =>
                            setAzureDevOpsContext(
                                context => ({
                                    ...context,
                                    addOrEditOpen: false
                                }))}>
                    <OrderedWizardItem title={localization.steps.allowAppAccess()}>
                        <AllowAppAccessItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem title={localization.steps.chooseOrganization()}>
                        <ChooseOrganizationItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem title={localization.steps.configureOrganization()}>
                        <ConfigureOrganizationItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem
                        deferredLoading={true}
                        title={localization.steps.tenants()}>
                        <OrganizationMemberSelectionItem/>
                    </OrderedWizardItem>
                </OrderedWizard>
            </FormLayout>
        </ContextProvider>);
}