import { Link, Step, Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { useCommonSectionsAndDescriptionDefinition, useGetAwsTenantEntityRiskContext } from "../../../";
import { Contract, Entity, entityModelStore, InlineEntities, tenantModelStore, useEntityTypeNameTranslator } from "../../../../../../../../../../../../common";
import { AwsConsoleUrlBuilder, useAwsConsoleSignInStepTranslator } from "../../../../../../../../../../../../tenants";
import { RiskDefinitionContextItem } from "../../../../../../utilities";

export function useAwsCloudTrailTrailMultiRegionNotExistRiskDefinition(riskModel: Contract.RiskModel) {
    const cloudTrailTrailMultiRegionNotExistRiskModel = riskModel as Contract.AwsCloudTrailTrailMultiRegionNotExistRiskModel;
    const risk = riskModel.risk as Contract.AwsCloudTrailTrailMultiRegionNotExistRisk;
    const tenantConfiguration = tenantModelStore.useGet(riskModel.tenantId).configuration as Contract.AwsTenantConfiguration;
    const tenantEntityModel = entityModelStore.useGet(risk.entityId) as Contract.AwsTenantEntityModel;

    const getAwsTenantEntityRiskContext = useGetAwsTenantEntityRiskContext();

    const consoleSignInStepTranslator = useAwsConsoleSignInStepTranslator();
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsCloudTrailTrailMultiRegionNotExistRiskDefinition",
            () => ({
                description: "{{tenantEntity}} is not configured with a multi-region trail with read and write management events",
                sections: {
                    contextItems: {
                        trails: {
                            any: "The {{translatedTenantEntityTypeName}} has {{trails}} configured",
                            empty: "The {{translatedTenantEntityTypeName}} has no trails configured"
                        }
                    },
                    resolution: {
                        trailExists: {
                            step1: [
                                "The {{trails}} should be configured using the following steps",
                                "At least one of the {{trails}} should be configured using the following steps"
                            ],
                            step2: "Converting the trail from one region to apply on all regions is not supported via the AWS console. Run the following command using AWS CLI:\naws cloudtrail update-trail --name {fill_trail} --is-multi-region-trail",
                            step3: {
                                step1: "Under the **Management events** section, click **Edit**",
                                step2: "Under **Events**, check the **Management events** option",
                                step3: "Under the **Management events**, for **API activity** check **Read** and **Write**",
                                step4: "Click **Save changes**",
                                title: "Verify that read and write management events are enabled"
                            }
                        },
                        trailNotExists: {
                            step1: {
                                link: "AWS documentation",
                                text: "Create a trail that's multi-region in the console. By default, new trails created in the condole are multi-region. For more information, see {{awsDocumentationLink}}"
                            },
                            step2: "Click **Create trail**",
                            step3: "Fill in the trail details and click **Next**",
                            step4: "Under **Events**, check the **Management events** option",
                            step5: "Under **Management events**, for **API activity** check **Read** and **Write**",
                            step6: "Click **Next** and then **Create trail**"
                        }
                    }
                }
            }));

    const translatedTenantEntityTypeName =
        entityTypeNameTranslator(
            tenantEntityModel.entity.typeName,
            {
                includeServiceName: false,
                variant: "text"
            });

    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            tenantEntity:
                <Entity
                    entityIdOrModel={tenantEntityModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () =>
            _<string | Step>([
                consoleSignInStepTranslator(
                    Contract.AwsConsoleView.CloudTrail,
                    AwsConsoleUrlBuilder.getCloudTrailUrl(tenantConfiguration.partitionType))]).
                concatIf(
                    !_.isEmpty(risk.trailIds),
                    localization.sections.resolution.trailExists.step1(
                        risk.trailIds.length,
                        {
                            trails:
                                <InlineEntities
                                    entityIdsOrModels={risk.trailIds}
                                    entityTypeName={Contract.TypeNames.AwsCloudTrailTrail}
                                    variant="itemAndTypeOrItemCountAndType"/>
                        }),
                    localization.sections.resolution.trailExists.step2(),
                    new Step(
                        localization.sections.resolution.trailExists.step3.title(),
                        {
                            contentElement:
                                <Steps variant="plainNumbers">
                                    {localization.sections.resolution.trailExists.step3.step1()}
                                    {localization.sections.resolution.trailExists.step3.step2()}
                                    {localization.sections.resolution.trailExists.step3.step3()}
                                    {localization.sections.resolution.trailExists.step3.step4()}
                                </Steps>
                        })).
                concatIf(
                    _.isEmpty(risk.trailIds),
                    localization.sections.resolution.trailNotExists.step1.text({
                        awsDocumentationLink:
                            <Link
                                urlOrGetUrl={cloudTrailTrailMultiRegionNotExistRiskModel.createCloudTrailDocumentationUrl}
                                variant="external">
                                {localization.sections.resolution.trailNotExists.step1.link()}
                            </Link>
                    }),
                    localization.sections.resolution.trailNotExists.step2,
                    localization.sections.resolution.trailNotExists.step3,
                    localization.sections.resolution.trailNotExists.step4,
                    localization.sections.resolution.trailNotExists.step5,
                    localization.sections.resolution.trailNotExists.step6).
                value(),
        riskModel,
        () => {
            const tenantEntityRiskContext = getAwsTenantEntityRiskContext(tenantEntityModel);
            return [
                tenantEntityRiskContext.generalInformation,
                tenantEntityRiskContext.sensitive,
                _.isEmpty(risk.trailIds)
                    ? new RiskDefinitionContextItem(localization.sections.contextItems.trails.empty({ translatedTenantEntityTypeName }))
                    : new RiskDefinitionContextItem(
                        localization.sections.contextItems.trails.any({
                            trails:
                                <InlineEntities
                                    entityIdsOrModels={risk.trailIds}
                                    entityTypeName={Contract.TypeNames.AwsCloudTrailTrail}
                                    variant="itemAndTypeOrItemCountAndType"/>,
                            translatedTenantEntityTypeName
                        })),
                tenantEntityRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}