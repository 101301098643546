import { UnexpectedError } from "@infrastructure";
import { useMemo } from "react";
import { Contract, entityModelStore } from "../../../../../../../../../../../../../../common";
import { useAadDirectoryApplicationServicePrincipalDefinition, useAadDirectoryGroupDefinition, useAadDirectoryManagedIdentityServicePrincipalDefinition, useAadDirectoryUserDefinition } from "./hooks";

export class AzureAccessPrincipalRiskDefinition {
    constructor(
        public additionalSeverityReasons: (string | undefined)[]) {
    }
}

export function useDefinition(riskModel: Contract.AzureAccessPrincipalRiskModel): AzureAccessPrincipalRiskDefinition {
    const principalModel = entityModelStore.useGet(riskModel.risk.entityId);

    const useDefinition =
        useMemo(
            () => {
                switch (principalModel.entity.typeName) {
                    case Contract.TypeNames.AadDirectoryUser:
                        return useAadDirectoryUserDefinition;
                    case Contract.TypeNames.AadDirectoryApplicationServicePrincipal:
                        return useAadDirectoryApplicationServicePrincipalDefinition;
                    case Contract.TypeNames.AadDirectoryManagedIdentityServicePrincipal:
                        return useAadDirectoryManagedIdentityServicePrincipalDefinition;
                    case Contract.TypeNames.AadDirectoryGroup:
                        return useAadDirectoryGroupDefinition;
                    default:
                        throw new UnexpectedError("principalTypeName", principalModel.entity.typeName);
                }
            },
            []);

    return useDefinition(riskModel);
}