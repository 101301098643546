import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, entityModelStore, InlineWorkloadAnalysisPackages } from "../../../../../../../../../../../../../common";
import { Vulnerabilities } from "../../../../../../../../../../WorkloadAnalysis";
import { RiskDefinition, RiskDefinitionSection } from "../../../../../../../utilities";
import { useCommonSectionsAndDescriptionDefinition } from "../../../../useCommonSectionsAndDescriptionDefinition";
import { useGetVirtualMachineImageWorkloadAnalysisRiskContext } from "../../contexts";
import { useGetVirtualMachineImageResolutionSteps } from "./useGetVirtualMachineImageResolutionSteps";
import { useGetWorkloadAnalysisRiskResolutionPatches } from "./useWorkloadAnalysisRiskResolutionPatches";
import { useWorkloadResourceOperatingSystemRiskRemediationStep } from "./useWorkloadResourceOperatingSystemRiskRemediationStep";

export function useVirtualMachineImageOperatingSystemUnpatchedRiskDefinition(riskModel: Contract.RiskModel): RiskDefinition {
    const risk = riskModel.risk as Contract.VirtualMachineImageOperatingSystemUnpatchedRisk;
    const workloadResourceOperatingSystemRiskRemediationStep = useWorkloadResourceOperatingSystemRiskRemediationStep();
    const getVirtualMachineImageResolutionSteps = useGetVirtualMachineImageResolutionSteps();
    const workloadEntityModel = _.as<Contract.IWorkloadResourceModel>(entityModelStore.useGet(risk.entityId));

    const getVirtualMachineImageWorkloadAnalysisRiskContext = useGetVirtualMachineImageWorkloadAnalysisRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.workloadAnalysis.hooks.useWorkloadAnalysisDefinition.hooks.useVirtualMachineImageOperatingSystemUnpatchedRiskDefinition",
            () => ({
                description: "{{riskedEntity}} is based on operating system {{operatingSystem}} and contains {{packages}} with critical or high vulnerabilities with known fixes",
                sections: {
                    vulnerabilities: "Vulnerabilities"
                }
            }));

    const descriptionProps =
        {
            operatingSystem: risk.operatingSystemDisplayName,
            packages:
                <InlineWorkloadAnalysisPackages
                    packageNames={risk.vulnerabilityPackageNames}
                    packageScope={risk.packageScope}/>,
            riskedEntity:
                <Entity
                    entityIdOrModel={risk.entityId}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>

        };
    const getWorkloadAnalysisRiskResolutionPatches = useGetWorkloadAnalysisRiskResolutionPatches();
    return useCommonSectionsAndDescriptionDefinition(
        localization.description(descriptionProps),
        () =>
            getVirtualMachineImageResolutionSteps(
                workloadResourceOperatingSystemRiskRemediationStep(
                    workloadEntityModel.operatingSystemReleaseNotesUrl,
                    risk.operatingSystemType,
                    risk.windowsUpdateDocumentationUrl,
                    getWorkloadAnalysisRiskResolutionPatches(risk))),
        riskModel,
        () => {
            const virtualMachineImageWorkloadAnalysisRiskContext = getVirtualMachineImageWorkloadAnalysisRiskContext(risk);

            return [
                virtualMachineImageWorkloadAnalysisRiskContext.generalInformation,
                virtualMachineImageWorkloadAnalysisRiskContext.virtualMachineUsage,
                ...virtualMachineImageWorkloadAnalysisRiskContext.severityVulnerabilities,
                virtualMachineImageWorkloadAnalysisRiskContext.sensitive,
                virtualMachineImageWorkloadAnalysisRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        },
        {
            sections: [
                new RiskDefinitionSection(
                    <Vulnerabilities
                        entityIds={[risk.entityId]}
                        severityFilterValues={[
                            Contract.Severity.Critical,
                            Contract.Severity.High]}
                        variant="risk"/>,
                    localization.sections.vulnerabilities(),
                    {
                        expandable: true
                    })
            ]
        });
}