import { ActionButton, DeleteIcon, Dialog, EditableTextField, EditableTextFieldActions, FormLayout, Message, ToastHelper, ToastLevel, useExecuteOperation, useLocalization } from "@infrastructure";
import { Box, Button, CircularProgress, Divider, FormControl, InputBase, Stack } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { AdministrationController, Contract, CustomerIcon, InfoCard, InfoItem, ScopeHelper, UserHelper, useTheme } from "../../../../../common";

export function Information() {
    const [{ orchestrationTypeToPreviousTimeRangeMap }] =
        useExecuteOperation(
            Information,
            AdministrationController.getInformation);

    const localization =
        useLocalization(
            "views.customer.administration.information",
            () => ({
                id: "ID",
                orchestration: {
                    endTime: {
                        ever: "{{orchestrationPreviousEndTime | TimeFormatter.longDateTime}} ({{orchestrationPreviousEndTime | TimeFormatter.humanizePastDuration}})",
                        never: "Never",
                        title: "Orchestration End Times"
                    },
                    type: {
                        [Contract.OrchestrationType.AadEventAnalysis]: "Microsoft Entra ID Event Analysis",
                        [Contract.OrchestrationType.Analysis]: "Analysis",
                        [Contract.OrchestrationType.AwsEc2Analysis]: "AWS Workload Protection Analysis",
                        [Contract.OrchestrationType.AwsEventAnalysis]: "AWS Event Analysis",
                        [Contract.OrchestrationType.AzureComputeVirtualMachineAnalysis]: "Azure Workload Protection Analysis",
                        [Contract.OrchestrationType.AzureEventAnalysis]: "Azure Event Analysis",
                        [Contract.OrchestrationType.CodeAnalysis]: "IaC Analysis",
                        [Contract.OrchestrationType.ContainerImageRepositoryAnalysis]: "Container Image Repository Analysis",
                        [Contract.OrchestrationType.EventAnalysis]: "Event Filters Analysis",
                        [Contract.OrchestrationType.GciEventAnalysis]: "Google Workspace Event Analysis",
                        [Contract.OrchestrationType.GcpComputeInstanceAnalysis]: "GCP Workload Protection Analysis",
                        [Contract.OrchestrationType.GcpEventAnalysis]: "GCP Event Analysis",
                        [Contract.OrchestrationType.ManagedDatabaseAnalysis]: "Managed Database Analysis",
                        [Contract.OrchestrationType.ObjectStoreAnalysis]: "Object Store Analysis",
                        [Contract.OrchestrationType.ProvisionedManagedDatabaseAnalysis]: "Provisioned Managed Database Analysis"
                    }
                },
                supportId: "Support ID",
                tenableId: "Container ID",
                update: {
                    error: "Error while updating ID",
                    success: "Successfully changed ID",
                    title: "Updating ID"
                }
            }));

    const orderedOrchestrationTypes =
        useMemo(
            (): Contract.OrchestrationType[] => {
                const orchestrationTypeKeys = _.keys(orchestrationTypeToPreviousTimeRangeMap) as Contract.OrchestrationType[];
                return _.intersection(
                    [
                        Contract.OrchestrationType.Analysis,
                        Contract.OrchestrationType.EventAnalysis,
                        Contract.OrchestrationType.AwsEventAnalysis,
                        Contract.OrchestrationType.AwsEc2Analysis,
                        Contract.OrchestrationType.AadEventAnalysis,
                        Contract.OrchestrationType.AzureEventAnalysis,
                        Contract.OrchestrationType.AzureComputeVirtualMachineAnalysis,
                        Contract.OrchestrationType.GciEventAnalysis,
                        Contract.OrchestrationType.GcpEventAnalysis,
                        Contract.OrchestrationType.GcpComputeInstanceAnalysis,
                        Contract.OrchestrationType.ContainerImageRepositoryAnalysis,
                        Contract.OrchestrationType.CodeAnalysis,
                        Contract.OrchestrationType.ManagedDatabaseAnalysis,
                        Contract.OrchestrationType.ObjectStoreAnalysis,
                        Contract.OrchestrationType.ProvisionedManagedDatabaseAnalysis
                    ],
                    orchestrationTypeKeys);
            },
            []);

    const supportIdEditableTextFieldActions = useRef<EditableTextFieldActions>();
    const tenableIdEditableTextFieldActions = useRef<EditableTextFieldActions>();

    async function updateCustomer(supportId: string, tenableId: string) {
        try {
            await ToastHelper.toastPromise(
                { level: ToastLevel.Error, title: localization.update.error() },
                { level: ToastLevel.Success, title: localization.update.success() },
                { title: localization.update.title() },
                async () => {
                    if (!/^\d+$/.test(supportId)) {
                        throw new Error("Support ID must contain digits only");
                    }

                    await AdministrationController.updateCustomer({ supportId: Number(supportId), tenableId });
                    await UserHelper.initialize();
                });
        } catch {
            if (!_.isNil(UserHelper.customer?.tenableId)) {
                tenableIdEditableTextFieldActions.current?.setValue(UserHelper.customer?.tenableId ?? "");
            }

            if (!_.isNil(UserHelper.customer?.supportId)) {
                supportIdEditableTextFieldActions.current?.setValue(String(UserHelper.customer?.supportId));
            }
        }
    }

    const theme = useTheme();
    return (
        <Stack
            sx={{
                padding: theme.spacing(2),
                width: "fit-content"
            }}>
            <CustomerLogoUploader/>
            <Stack
                spacing={0.5}
                sx={{ padding: theme.spacing(1, 0.5) }}>
                <InfoItem
                    title={localization.id()}
                    value={ScopeHelper.customerId}/>
                {!_.isNil(UserHelper.customer?.tenableId) &&
                    <InfoItem
                        containerSx={{ width: "100%" }}
                        title={localization.tenableId()}
                        value={
                            <EditableTextField
                                actionsRef={tenableIdEditableTextFieldActions}
                                title={localization.tenableId()}
                                value={UserHelper.customer?.tenableId ?? ""}
                                onSave={
                                    tenableId =>
                                        updateCustomer(
                                            String(UserHelper.customer?.supportId),
                                            tenableId)}/>}/>}
                {!_.isNil(UserHelper.customer?.supportId) &&
                    <InfoItem
                        containerSx={{ width: "100%" }}
                        title={localization.supportId()}
                        value={
                            <EditableTextField
                                actionsRef={supportIdEditableTextFieldActions}
                                title={localization.supportId()}
                                value={String(UserHelper.customer?.supportId)}
                                onSave={
                                    supportId =>
                                        updateCustomer(
                                            supportId,
                                            UserHelper.customer?.tenableId ?? "")}/>}/>}
            </Stack>
            <Divider/>
            <InfoCard
                columns={true}
                itemContainerSx={{ padding: theme.spacing(1, 0.5) }}
                title={localization.orchestration.endTime.title()}>
                {_.map(
                    orderedOrchestrationTypes,
                    orchestrationType =>
                        <InfoItem
                            key={orchestrationType}
                            title={localization.orchestration.type[orchestrationType]()}
                            value={
                                _.isNil(orchestrationTypeToPreviousTimeRangeMap[orchestrationType])
                                    ? localization.orchestration.endTime.never()
                                    : localization.orchestration.endTime.ever({ orchestrationPreviousEndTime: orchestrationTypeToPreviousTimeRangeMap[orchestrationType]!.endTime })}
                            vertical={true}/>)}
            </InfoCard>
        </Stack>);
}

function CustomerLogoUploader() {
    const [selectedCustomer, setSelectedCustomer] = useState<Contract.AuthenticationControllerGetUserCustomerDataResponseCustomer>(() => _.cloneDeep(UserHelper.customer!));
    const [invalidLogo, setInvalidLogo] = useState(false);

    function setLogo(event: React.ChangeEvent<HTMLInputElement>) {
        setInvalidLogo(false);

        const newLogoFilePath = event.target.files?.item(0);
        if (newLogoFilePath) {
            const logoFileReader = new FileReader();
            logoFileReader.readAsBinaryString(newLogoFilePath);
            logoFileReader.onloadend =
                () => {
                    event.target.value = "";

                    const divElement = document.createElement("div");
                    divElement.innerHTML = logoFileReader.result as string;
                    const newLogoSvgElement = divElement.firstChild as any;
                    if (newLogoSvgElement.height.baseVal.value !== 80 ||
                        newLogoSvgElement.width.baseVal.value !== 80) {
                        setInvalidLogo(true);
                    }

                    setSelectedCustomer({
                        ...selectedCustomer,
                        logoConfiguration:
                            new Contract.CustomerLogoConfiguration(
                                ScopeHelper.customerId,
                                "",
                                false,
                                undefined,
                                "",
                                btoa(logoFileReader.result as string),
                                "image/svg+xml")
                    });
                };
        }
    }

    const [open, setOpen] = useState(false);
    const [updateExecuting, setUpdateExecuting] = useState(false);
    const [updateError, setUpdateError] = useState(false);

    async function update() {
        setUpdateExecuting(true);
        setUpdateError(false);

        try {
            if (_.isNil(selectedCustomer.logoConfiguration)) {
                await AdministrationController.deleteLogo();
            } else {
                await AdministrationController.updateLogo(new Contract.AdministrationControllerUpdateLogoRequest(selectedCustomer.logoConfiguration.contentBytes));
            }

            UserHelper.customer!.logoConfiguration = selectedCustomer.logoConfiguration;
            setOpen(false);
        } catch {
            setUpdateError(true);
        }

        setUpdateExecuting(false);
    }

    const localization =
        useLocalization(
            "views.customer.administration.information.customerLogoUploader",
            () => ({
                dialog: {
                    actions: {
                        setLogo: {
                            error: "Image should be 80x80 pixels, you can still update the logo if it looks OK. But, in case of disaster you will have to answer to 😈 Elad 😈",
                            title: "Browse..."
                        },
                        update: {
                            error: "Failed to update logo",
                            title: "Update"
                        }
                    },
                    title: "Update Customer Logo"
                }
            }));

    const uploadButtonRef = useRef<HTMLInputElement>(null);
    const theme = useTheme();
    return (
        <Stack alignItems="flex-start">
            {open &&
                <Dialog
                    size="small"
                    variant="editor"
                    onClose={() => setOpen(false)}>
                    <FormLayout
                        footerOptions={{
                            contentElement:
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    justifyContent="space-between">
                                    <Button
                                        variant="outlined"
                                        onClick={() => uploadButtonRef.current!.click()}>
                                        {localization.dialog.actions.setLogo.title()}
                                    </Button>
                                    <FormControl>
                                        <InputBase
                                            inputRef={uploadButtonRef}
                                            slotProps={{ input: { accept: ".svg" } }}
                                            sx={{ display: "none" }}
                                            type="file"
                                            onChange={setLogo}/>
                                    </FormControl>
                                    <Stack
                                        alignItems="center"
                                        direction="row"
                                        spacing={1}>
                                        {updateExecuting && (
                                            <CircularProgress
                                                size={theme.spacing(2)}
                                                variant="indeterminate"/>)}
                                        {updateError && (
                                            <Message
                                                level="error"
                                                title={localization.dialog.actions.update.error()}/>)}
                                        <Button
                                            variant="contained"
                                            onClick={update}>
                                            {localization.dialog.actions.update.title()}
                                        </Button>
                                    </Stack>
                                </Stack>
                        }}
                        titleOptions={{ text: localization.dialog.title() }}>
                        <Stack
                            alignItems="center"
                            spacing={2}
                            sx={{ position: "relative" }}>
                            {!_.isNil(selectedCustomer.logoConfiguration) &&
                                <ActionButton
                                    size="medium"
                                    sx={{
                                        fontSize: "24px",
                                        position: "absolute",
                                        right: "116px",
                                        top: "10px"
                                    }}
                                    onClick={
                                        () => {
                                            setInvalidLogo(false);
                                            setSelectedCustomer({
                                                ...selectedCustomer,
                                                logoConfiguration: undefined
                                            });
                                        }}>
                                    <DeleteIcon/>
                                </ActionButton>}
                            <Box
                                sx={{
                                    backgroundImage: "linear-gradient(to right, #d2cfcf 1px, transparent 1px), linear-gradient(to bottom, #e3e3e3 1px, transparent 1px)",
                                    backgroundSize: "10px 10px",
                                    border: theme.border.primary,
                                    height: "203px",
                                    overflow: "hidden",
                                    width: "203px"
                                }}>
                                <CustomerIcon
                                    customer={selectedCustomer}
                                    size={theme.spacing(25)}/>
                            </Box>
                            {invalidLogo &&
                                <Message
                                    level="warning"
                                    title={localization.dialog.actions.setLogo.error()}/>}
                        </Stack>
                    </FormLayout>
                </Dialog>}
            <Box
                sx={{
                    border: theme.border.primary,
                    borderRadius: theme.spacing(0.75),
                    cursor: "pointer",
                    padding: theme.spacing(1)
                }}
                onClick={() => setOpen(true)}>
                <CustomerIcon size={theme.spacing(10)}/>
            </Box>
        </Stack>);
}