import { useLocalization } from "@infrastructure";
import React from "react";
import { useAwsCommonAccessResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition } from "../../..";
import { Contract, InfoCard, InfoItem } from "../../../../../../../../../../common";
import { AwsApiGatewayV2ApiRoutesTable } from "../../../../../../../../../../tenants";
import { useAwsApiGatewayApiEndpointTypeTranslator, useAwsApiGatewayApiTypeTranslator } from "../../../../../../hooks";
import { Info } from "../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../useCustomEntityPropertyInfoItemElements";

export function useAwsApiGatewayV2ApiDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const api = resourceModel.entity as Contract.AwsApiGatewayV2Api;
    const apiModel = resourceModel as Contract.AwsApiGatewayV2ApiModel;
    const commonAccessResourceInfoElements = useAwsCommonAccessResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);

    const apiGatewayApiEndpointTypeTranslator = useAwsApiGatewayApiEndpointTypeTranslator();
    const apiGatewayApiProtocolTranslator = useAwsApiGatewayApiTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsApiGatewayV2ApiDefinition",
            () => ({
                info: {
                    cards: {
                        resources: "Resources"
                    },
                    items: {
                        endpointType: "Endpoint",
                        rawId: "Id",
                        type: "Protocol"
                    }
                }
            }));

    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonAccessResourceInfoElements,
                    <InfoItem
                        key="rawId"
                        title={localization.info.items.rawId()}
                        value={api.rawId}/>,
                    <InfoItem
                        key="type"
                        title={localization.info.items.type()}
                        value={apiGatewayApiProtocolTranslator(api.type)}/>,
                    <InfoItem
                        key="endpointType"
                        location="all"
                        title={localization.info.items.endpointType()}
                        value={apiGatewayApiEndpointTypeTranslator(api.endpointType)}/>
                ]}
                options={options?.infoOptions}>
                <InfoCard title={localization.info.cards.resources()}>
                    <AwsApiGatewayV2ApiRoutesTable apiModel={apiModel}/>
                </InfoCard>
            </Info>
    });
}