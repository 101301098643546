﻿import { map } from "@infrastructure";
import { Contract } from "../../../../../../../../../../../../../../../common";
import { EntityExternalConsoleLinkDefinition } from "../useDefinition";

export function useGcpApiKeysKeyDefinition(entityModel: Contract.EntityModel, page: Contract.GcpConsolePage) {

    const getUrl =
        map(
            page,
            {
                [Contract.GcpConsolePage.Permissions]: () => undefined,
                [Contract.GcpConsolePage.Resource]: () => () => entityModel.consoleUrl
            });
    return new EntityExternalConsoleLinkDefinition(getUrl?.());
}