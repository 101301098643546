import _ from "lodash";
import { EffectCallback, useEffect, useRef } from "react";

export function useDebouncedEffect(
    effect: EffectCallback,
    timeSpan?: number,
    dependencies?: any[]) {
    const timeoutIdRef = useRef(0);

    useEffect(
        () => {
            if (timeoutIdRef.current !== 0) {
                clearTimeout(timeoutIdRef.current);
            }

            if (_.isNil(timeSpan)) {
                effect();
            } else {
                timeoutIdRef.current = window.setTimeout(() => effect(), timeSpan);
            }
        },
        dependencies);
}