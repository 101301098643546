import { useLocalization, useQueryParameters } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { NotificationMail } from "..";
import { Contract, UserConsoleAppUrlHelper } from "../../../../common";
import { useResourcesTranslator } from "./hooks";

type PendingApprovalPermissionAssignmentRequestMailQueryParameters = {
    additionalGroupCount?: string;
    additionalRoleCount?: string;
    additionalTenantCount?: string;
    approverUserMailOrName: string;
    granteeUserMail?: string;
    granteeUserName: string;
    groupNames?: string | string[];
    permissionRequestType: Contract.PermissionRequestMailPermissionRequestType;
    roleNames?: string | string[];
    tenantDisplayReferences?: string | string[];
    tenantNames?: string | string[];
};

export function ExportPendingApprovalPermissionAssignmentRequestMail() {
    const { additionalGroupCount, additionalRoleCount, additionalTenantCount, approverUserMailOrName, granteeUserMail, granteeUserName, groupNames, permissionRequestType, roleNames, tenantDisplayReferences, tenantNames } = useQueryParameters<PendingApprovalPermissionAssignmentRequestMailQueryParameters>();
    const localization =
        useLocalization(
            "views.system.exportPermissionRequestMail.exportPendingApprovalPermissionAssignmentRequestMail",
            () => ({
                link: "Go to Tenable Cloud Security JIT Portal",
                mailTitle: "{{granteeUserName}} has requested access",
                subtitle: {
                    granteeUserDisplayName: "**{{granteeUserName}} ({{granteeUserMail}})**",
                    text: "{{granteeUserDisplayName}} has requested access to {{resources}}. Go to the Tenable Cloud Security JIT portal to review the request and approve/deny the access."
                },
                title: "Hi {{approverUserMailOrName}},"
            }));
    const resourcesTranslator = useResourcesTranslator();

    const granteeUserDisplayName =
        _.isNil(granteeUserMail)
            ? granteeUserName
            : localization.subtitle.granteeUserDisplayName({ granteeUserMail, granteeUserName });
    return (
        <NotificationMail
            link={{
                text: localization.link(),
                value: UserConsoleAppUrlHelper.getApproverUserPermissionRequestsRelativeUrl()
            }}
            mailTitle={localization.mailTitle({ granteeUserName })}
            subtitle={
                localization.subtitle.text(
                    {
                        granteeUserDisplayName,
                        resources:
                            resourcesTranslator(
                                permissionRequestType,
                                additionalGroupCount,
                                additionalRoleCount,
                                additionalTenantCount,
                                groupNames,
                                roleNames,
                                tenantDisplayReferences,
                                tenantNames)
                    })}
            title={localization.title({ approverUserMailOrName })}/>);
}