import { Box, SxProps, Typography } from "@mui/material";
import _ from "lodash";
import React, { ReactNode } from "react";
import { useTheme } from "../themes";

export type AttributeProps = {
    backgroundColor: string;
    borderColor: string;
    item: string | ReactNode;
    sx?: SxProps;
};

export function Attribute({ backgroundColor, borderColor, item, sx }: AttributeProps) {
    const theme = useTheme();
    return (
        <Box
            sx={{
                backgroundColor,
                border: theme.border.primary,
                borderColor,
                borderRadius: theme.spacing(0.75),
                height: theme.px(22),
                maxWidth: theme.spacing(23),
                overflow: "hidden",
                padding: theme.spacing(0, 1),
                ...sx
            }}>
            {_.isString(item)
                ? <Typography
                    noWrap={true}
                    sx={{ lineHeight: 1 }}>
                    {item}
                </Typography>
                : item}
        </Box>);
}