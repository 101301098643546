import { Box, Stack, SxProps } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useMemo, useState } from "react";
import { EmptyMessage, Optional, SearchTextField, StringHelper, useChangeEffect, useLocalization } from "@infrastructure";
import { useTheme } from "../..";
import { Item } from "./components";

export type TreeProps<T> = {
    children: (treeItem: TreeItem<T>) => ReactNode;
    containerSx?: SxProps;
    expandOnItemClick?: boolean;
    getItemSx?: (treeItem: TreeItem<T>) => Optional<SxProps>;
    isExpanded: (treeItem: TreeItem<T>) => boolean;
    itemHover?: boolean;
    onFilteredItemsChanged?: (filteredItems?: TreeItem<T>[]) => void;
    onTreeItemClick?: (treeItem: TreeItem<T>) => void;
    orderBy?: (((treeItem: TreeItem<T>) => boolean) | ((treeItem: TreeItem<T>) => (string | undefined)))[];
    rootItem: TreeItem<T>;
    spacing?: number;
    topElement?: ReactNode;
    treeSx?: SxProps;
} & ({
    getSearchableTexts: (treeItem: TreeItem<T>) => string[];
    searchEnabled?: true;
} | {
    getSearchableTexts?: never;
    searchEnabled: false;
});


export function Tree<T>({ children, containerSx, expandOnItemClick = true, getItemSx, getSearchableTexts, isExpanded, itemHover = true, onFilteredItemsChanged, onTreeItemClick, orderBy = undefined, rootItem, searchEnabled = true, spacing = 1, topElement, treeSx }: TreeProps<T>) {
    const [searchText, setSearchText] = useState<string>();
    const [filteredItems, filteredRootItem] =
        useMemo(
            () => {
                const normalizedSearchText = StringHelper.normalize(searchText);
                const filteredItems: TreeItem<T>[] = [];

                function createItem(item: TreeItem<T>, parentSearchMatched?: boolean): Optional<TreeItem<T>> {
                    const searching = !_.isNil(normalizedSearchText);
                    const searchMatched =
                        searching &&
                        _<string>([]).
                            concat(getSearchableTexts?.(item) ?? []).
                            some(matchText => StringHelper.search(matchText, normalizedSearchText));

                    const childTreeItems =
                        _(item.items).
                            orderBy(orderBy).
                            map(
                                item =>
                                    createItem(
                                        item,
                                        searchMatched || parentSearchMatched)).
                            filter().
                            as<TreeItem<T>>().
                            value();
                    if (searching) {
                        if (!parentSearchMatched && !searchMatched && _.isEmpty(childTreeItems)) {
                            return undefined;
                        }
                        filteredItems.push(item);
                    }

                    return new TreeItem(
                        item.value,
                        childTreeItems);
                }

                const filteredRootItem = createItem(rootItem);

                return [filteredItems, filteredRootItem];
            },
            [searchText]);

    useChangeEffect(
        () => {
            onFilteredItemsChanged?.(filteredItems);
        },
        [filteredItems]);

    const localization =
        useLocalization(
            "common.tree",
            () => ({
                empty: "No results",
                search: "Search"
            }));
    const theme = useTheme();
    return (
        <Stack
            spacing={spacing}
            sx={{
                ".MuiListItemButton-root:hover": {
                    backgroundColor:
                        itemHover
                            ? undefined
                            : "unset"
                },
                ...containerSx
            }}>
            {searchEnabled &&
                rootItem.size() > 10 &&
                <SearchTextField
                    placeholder={localization.search()}
                    searchText={searchText}
                    onSearchTextChanged={searchText => setSearchText(searchText)}/>}
            {topElement}
            <Box
                sx={{
                    flex: 1,
                    height: "100%",
                    maxHeight: theme.spacing(50),
                    overflow: "hidden auto",
                    padding: theme.spacing(0, 0.5, 0.5),
                    ...treeSx
                }}>
                {_.isNil(filteredRootItem)
                    ? <EmptyMessage message={localization.empty()}/>
                    : <Item<T>
                        expandOnTreeItemClick={expandOnItemClick}
                        getSx={getItemSx}
                        isExpanded={item => isExpanded(item)}
                        item={filteredRootItem}
                        render={children}
                        searchMode={!_.isEmpty(searchText)}
                        onClick={item => onTreeItemClick?.(item)}/>}
            </Box>
        </Stack>);
}

export class TreeItem<T> {
    constructor(
        public value: T,
        public items?: TreeItem<T>[]) {
    }

    size =
        (): number =>
            _.sum(
                _.map(
                    this.items,
                    item => item.size())) + 1;

    leaves =
        (): TreeItem<T>[] =>
            _.isEmpty(this.items)
                ? [this]
                : _.flatMap(this.items, item => item.leaves());

    nodes =
        (): TreeItem<T>[] =>
            [
                this,
                ...(_.isNil(this.items)
                    ? []
                    : _.flatMap(this.items, item => item.nodes()))
            ];
}