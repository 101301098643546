import { NumberFormatter, useLocalization } from "@infrastructure";
import React, { useCallback } from "react";
import { RiskPolicyConfigurationDefinitionData } from "..";
import { Contract, CustomerConsoleAppUrlHelper, RiskPoliciesType } from "../../../../../../../common";
import { Numeric, ViewOnlyNumeric } from "../components";

export function useOciIamDomainPasswordExpirationPolicyRiskPolicyConfigurationDefinition(riskPoliciesType: RiskPoliciesType): RiskPolicyConfigurationDefinitionData {
    const passwordPolicyExpirationIntervalDaysMaxValue = 500;
    const passwordPolicyExpirationIntervalDaysMinValue = 0;

    const localization =
        useLocalization(
            "views.customer.riskPolicies.hooks.useConfigurationDefinition.hooks.useOciIamDomainPasswordExpirationPolicyRiskPolicyConfigurationDefinition",
            () => ({
                description: "By default, Tenable Cloud Security uses a minimum password requirement of 365 days to analyze this policy before opening findings.",
                invalidValue: "The value should be between {{minValue}} and {{maxValue}} characters",
                label: "Days",
                title: "Password Requirements"
            }));

    const getPasswordPolicyExpirationIntervalDays =
        useCallback(
            (riskPolicyConfiguration: Contract.RiskPolicyConfiguration) =>
                (riskPolicyConfiguration as Contract.OciIamDomainPasswordExpirationPolicyRiskPolicyConfiguration).passwordPolicyExpirationIntervalDays,
            []);

    return {
        generalAdditionalItems: [{
            configurationElement:
                <Numeric
                    description={localization.description()}
                    documentationUrl={CustomerConsoleAppUrlHelper.getDocsViewAndManagePoliciesRelativeUrl()}
                    fromNumber={passwordPolicyExpirationIntervalDays => passwordPolicyExpirationIntervalDays}
                    getValue={getPasswordPolicyExpirationIntervalDays}
                    key={`${Contract.TypeNames.OciIamDomainPasswordExpirationPolicyRiskPolicyConfiguration}-passwordPolicyExpirationIntervalDays`}
                    label={localization.label()}
                    maxValue={passwordPolicyExpirationIntervalDaysMaxValue}
                    minValue={passwordPolicyExpirationIntervalDaysMinValue}
                    riskPoliciesType={riskPoliciesType}
                    setValue={
                        (riskPolicyConfiguration, passwordPolicyExpirationIntervalDays) => {
                            (riskPolicyConfiguration as Contract.OciIamDomainPasswordExpirationPolicyRiskPolicyConfiguration).passwordPolicyExpirationIntervalDays = passwordPolicyExpirationIntervalDays;
                            return undefined;
                        }}
                    title={localization.title()}
                    toNumber={passwordPolicyExpirationIntervalDays => passwordPolicyExpirationIntervalDays}
                    translateValue={NumberFormatter.humanize}
                    valueOutOfRangeErrorMessage={
                        localization.invalidValue({
                            maxValue: passwordPolicyExpirationIntervalDaysMaxValue,
                            minValue: passwordPolicyExpirationIntervalDaysMinValue
                        })}/>,
            viewItem: {
                getValue:
                    (_, item, scopeId) =>
                        <ViewOnlyNumeric
                            fromNumber={passwordPolicyExpirationIntervalDays => passwordPolicyExpirationIntervalDays}
                            getValue={getPasswordPolicyExpirationIntervalDays}
                            item={item}
                            riskPoliciesType={riskPoliciesType}
                            scopeId={scopeId}
                            toNumber={passwordPolicyExpirationIntervalDays => passwordPolicyExpirationIntervalDays}
                            translateValue={NumberFormatter.humanize}/>,
                title: localization.title()
            }
        }]
    };
}