﻿import { DataTableColumnRenderProps } from "@infrastructure";
import React from "react";
import { Contract, EntityAttributes } from "../../../../../../../../../common";

export function RiskedEntityAttributesCell({ item }: DataTableColumnRenderProps<Contract.RiskModel>) {
    return (
        <EntityAttributes
            entityAttributes={item.entityAttributes}
            entityCount={item.risk.riskedEntityIds.length}
            entityTypeName={item.riskedEntityTypeName}
            orderVariant="leftToRight"
            variant="dynamic"/>);
}