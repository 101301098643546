import { DataTableColumn, DataTableColumnRenderProps, PagedValuesFilter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, PagedEntityFilter, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";
import { useAzureCommonResourceGroupResourceDefinition } from "./useAzureCommonResourceGroupResourceDefinition";

export function useAzureComputeVirtualMachineResourceDefinition(definitionData: DefinitionData) {
    const entitiesExportOptions = useEntitiesExportOptions();
    const commonResourceGroupResourceDefinition = useAzureCommonResourceGroupResourceDefinition(definitionData);

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.azure.useAzureComputeVirtualMachineResourceDefinition",
            () => ({
                columns: {
                    applicationGateways: "Application Gateways",
                    loadBalancers: "Load Balancers",
                    privateIpAddresses: "Private IP Addresses",
                    publicIpAddresses: "Public IP Addresses",
                    rawId: "ID",
                    virtualNetwork: "Virtual Network"
                }
            }));

    return {
        columns: {
            ...commonResourceGroupResourceDefinition.columns,
            applicationGatewaysColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AzureComputeVirtualMachineResourceModel>(
                            Contract.TypeNames.AzureNetworkApplicationGateway,
                            item => (item as Contract.AzureComputeVirtualMachineResourceModel).applicationGatewayIds,
                            localization.columns.applicationGateways())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AzureComputeVirtualMachineResourceApplicationGateways)}
                                    placeholder={localization.columns.applicationGateways()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AzureComputeVirtualMachineResourceApplicationGateways}
                    key={Contract.EntityModelProperty.AzureComputeVirtualMachineResourceApplicationGateways}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AzureComputeVirtualMachineResourceModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.applicationGatewayIds}
                                entityTypeName={Contract.TypeNames.AzureNetworkApplicationGateway}
                                entityVariant="iconText"/>}
                    selectorOptions={{ default: false }}
                    title={localization.columns.applicationGateways()}/>,
            loadBalancersColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AzureComputeVirtualMachineResourceModel>(
                            Contract.TypeNames.AzureNetworkLoadBalancer,
                            item => (item as Contract.AzureComputeVirtualMachineResourceModel).loadBalancerIds,
                            localization.columns.loadBalancers())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AzureComputeVirtualMachineResourceLoadBalancers)}
                                    placeholder={localization.columns.loadBalancers()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AzureComputeVirtualMachineResourceLoadBalancers}
                    key={Contract.EntityModelProperty.AzureComputeVirtualMachineResourceLoadBalancers}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AzureComputeVirtualMachineResourceModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={item.loadBalancerIds}
                                entityTypeName={Contract.TypeNames.AzureNetworkLoadBalancer}
                                entityVariant="iconText"/>}
                    selectorOptions={{ default: false }}
                    title={localization.columns.loadBalancers()}/>,
            privateIpAddressesColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AzureComputeVirtualMachineResourceModel) => ({
                                [localization.columns.privateIpAddresses()]: _.join((item.entity as Contract.AzureComputeVirtualMachineResource).privateIpAddresses, "\n")
                            })
                    }}
                    id="privateIpAddresses"
                    key="privateIpAddresses"/>,
            publicIpAddressesColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AzureComputeVirtualMachineResourceModel>(
                            Contract.TypeNames.AzureNetworkPublicIpAddress,
                            item => (item.entity as Contract.AzureComputeVirtualMachineResource).publicIpAddressIds,
                            localization.columns.publicIpAddresses(),
                            { getValue: entityModel => (entityModel.entity as Contract.AzureNetworkPublicIpAddress).ipAddress ?? "" })
                    }
                    id="publicIpAddresses"
                    key="publicIpAddresses"/>,
            rawIdColumn:
                <DataTableColumn
                    exportOptions={{
                        getItem:
                            (item: Contract.AzureComputeVirtualMachineResourceModel) => ({
                                [localization.columns.rawId()]: (item.entity as Contract.AzureComputeVirtualMachineResource).rawId
                            })
                    }}
                    filterOptions={{
                        itemOrItems: {
                            default: true,
                            element:
                                <PagedValuesFilter
                                    getValuePage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterValueItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AzureComputeVirtualMachineResourceRawId)}
                                    placeholder={localization.columns.rawId()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AzureComputeVirtualMachineResourceRawId}
                    itemProperty={item => (item.entity as Contract.AzureComputeVirtualMachineResource).rawId}
                    key={Contract.EntityModelProperty.AzureComputeVirtualMachineResourceRawId}
                    selectorOptions={{ default: false }}
                    title={localization.columns.rawId()}/>,
            virtualNetworkColumn:
                <DataTableColumn
                    exportOptions={
                        entitiesExportOptions<Contract.AzureComputeVirtualMachineResourceModel>(
                            Contract.TypeNames.AzureNetworkVirtualNetwork,
                            item => [(item.entity as Contract.AzureComputeVirtualMachineResource).virtualNetworkId],
                            localization.columns.virtualNetwork())}
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <PagedEntityFilter
                                    getEntityIdPage={
                                        ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                            definitionData.entityTypeEntitiesViewName,
                                            Contract.EntityModelProperty.AzureComputeVirtualMachineResourceVirtualNetwork)}
                                    placeholder={localization.columns.virtualNetwork()}/>
                        }
                    }}
                    id={Contract.EntityModelProperty.AzureComputeVirtualMachineResourceVirtualNetwork}
                    key={Contract.EntityModelProperty.AzureComputeVirtualMachineResourceVirtualNetwork}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AzureComputeVirtualMachineResourceModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={(item.entity as Contract.AzureComputeVirtualMachineResource).virtualNetworkId}
                                entityTypeName={Contract.TypeNames.AzureNetworkVirtualNetwork}/>}
                    title={localization.columns.virtualNetwork()}/>
        }
    };
}