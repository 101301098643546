import { useLocalization } from "@infrastructure";
import React from "react";
import { useGetAzurePostgreSqlServerRiskContext } from "../../..";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { EntityExternalConsoleLink } from "../../components";

export function useAzurePostgreSqlServerHttpsOnlyDisabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzurePostgreSqlServerHttpsOnlyDisabledRisk;
    const serverModel = entityModelStore.useGet(risk.entityId) as Contract.AzurePostgreSqlServerModel;

    const getAzurePostgreSqlServerRiskContext = useGetAzurePostgreSqlServerRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzurePostgreSqlServerHttpsOnlyDisabledRiskDefinition",
            () => ({
                description: "{{server}} transport encryption is not enabled",
                sections: {
                    resolution: {
                        step1: "Before enforcing transport encryption, verify that applications use only HTTPS to access the database",
                        step2: {
                            [Contract.TypeNames.AzurePostgreSqlSingleServer]: "Switch **Enforce SSL connection** to enabled and click **Save** to confirm",
                            [Contract.TypeNames.AzurePostgreSqlFlexibleServer]: "Set the **require_secure_transport** server to **ON** and click **Save** to confirm"
                        }
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            server:
                <Entity
                    entityIdOrModel={serverModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            <EntityExternalConsoleLink
                entityId={risk.entityId}
                key={risk.entityId}
                page={
                    serverModel.entity.typeName === Contract.TypeNames.AzurePostgreSqlSingleServer
                        ? Contract.AzureConsoleEntityPage.ConnectionSecurity
                        : Contract.AzureConsoleEntityPage.ServerConfigurations}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2.translate(serverModel.entity.typeName)
        ],
        riskModel,
        () => {
            const serverRiskContext = getAzurePostgreSqlServerRiskContext(serverModel);
            return [
                serverRiskContext.generalInformation,
                serverRiskContext.sensitive,
                serverRiskContext.inboundExternalAccessScope,
                serverRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}