import { Avatar, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { NotValidIcon } from "../../../assets";

type ItemProps = {
    error?: boolean;
    index: number;
    selected: boolean;
    title: string;
};

export function Item({ error, index, selected, title }: ItemProps) {
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            sx={{
                alignItems: "center",
                position: "relative"
            }}>
            <Typography
                sx={{
                    backgroundColor:
                        selected
                            ? theme.palette.action.selected
                            : undefined,
                    color:
                        selected
                            ? theme.palette.text.primary
                            : theme.palette.text.secondary,
                    flex: 1,
                    fontSize: "13px",
                    padding: theme.spacing(2, 7, 2, 3)
                }}>
                {title}
            </Typography>
            {error &&
                <NotValidIcon
                    sx={{
                        color: theme.palette.error.main,
                        fontSize: "16px"
                    }}/>}
            <Avatar
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    border: theme.border.primary,
                    bottom: 0,
                    color:
                        selected
                            ? theme.palette.text.primary
                            : theme.palette.text.secondary,
                    margin: theme.spacing("auto", 0),
                    position: "absolute",
                    right: theme.px(-22),
                    top: 0
                }}>
                {index}
            </Avatar>
        </Stack>);
}