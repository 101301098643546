import { TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { Contract, InfoItem } from "../../../../../../../../../../../common";
import { useOciKmsVaultKeyAlgorithmTranslator, useOciKmsVaultKeyProtectionModeTranslator, useOciKmsVaultKeyStatusTranslator } from "../../../../../../../hooks";
import { EntitiesInfoItem, Info } from "../../../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { useOciDefaultResourceInfoItemElements } from "../useOciDefaultResourceInfoItemElements";
import { Versions } from "./components";

export function useOciKmsVaultKeyDefinition(resourceModel: Contract.OciResourceModel, options?: DefinitionOptions) {
    const keyModel = resourceModel as Contract.OciKmsVaultKeyModel;
    const key = keyModel.entity as Contract.OciKmsVaultKey;
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useOciDefaultResourceInfoItemElements(resourceModel);

    const keyAlgorithmTranslator = useOciKmsVaultKeyAlgorithmTranslator();
    const keyProtectionModeTranslator = useOciKmsVaultKeyProtectionModeTranslator();
    const keyStatusTranslator = useOciKmsVaultKeyStatusTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oci.useOciKmsVaultKeyDefinition",
            () => ({
                info: {
                    items: {
                        key: {
                            algorithm: "Key Algorithm",
                            curveType: {
                                title: "Elliptic Curve ID",
                                [Contract.TypeNames.OciKmsVaultKeyCurveType]: {
                                    [Contract.OciKmsVaultKeyCurveType.NistP256]: "NIST P-256",
                                    [Contract.OciKmsVaultKeyCurveType.NistP384]: "NIST P-384",
                                    [Contract.OciKmsVaultKeyCurveType.NistP521]: "NIST P-521"
                                }
                            },
                            length: {
                                text: "{{keyLength}} bits",
                                title: "Key Length"
                            },
                            protectionMode: "Protection Mode"
                        },
                        nextRotationTime: "Next Rotation Time",
                        rotationEnabled: {
                            false: "Disabled",
                            title: "Auto Rotation",
                            true: "Enabled"
                        },
                        rotationTimeFrame: {
                            text: "Every {{rotationTimeFrame | TimeSpanFormatter.humanizeDays}}",
                            title: "Rotation Interval"
                        },
                        status: "Status",
                        vault: "Vault"
                    }
                },
                tabs: {
                    versions: "Versions ({{versionCount | NumberFormatter.humanize}})"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Overview,
                <Versions keyModel={keyModel}/>,
                localization.tabs.versions({ versionCount: _.size(key.versionRawOcidToVersionMap) }),
                "versions")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <InfoItem
                        key="status"
                        title={localization.info.items.status()}
                        value={keyStatusTranslator(key.status)}/>,
                    <EntitiesInfoItem
                        entityIdsOrModels={keyModel.vaultIdReference}
                        entityTypeName={Contract.TypeNames.OciKmsVault}
                        key="vault"
                        title={localization.info.items.vault()}/>,
                    <InfoItem
                        key="protectionMode"
                        title={localization.info.items.key.protectionMode()}
                        value={keyProtectionModeTranslator(key.protectionMode)}/>,
                    <InfoItem
                        key="algorithm"
                        title={localization.info.items.key.algorithm()}
                        value={keyAlgorithmTranslator(key.algorithm)}/>,
                    key.algorithm == Contract.OciKmsVaultKeyAlgorithm.Ecdsa
                        ? undefined
                        : <InfoItem
                            key="length"
                            title={localization.info.items.key.length.title()}
                            value={localization.info.items.key.length.text({ keyLength: key.keyLength! * 8 })}/>,
                    _.isNil(key.curveType)
                        ? undefined
                        : <InfoItem
                            key="curveType"
                            title={localization.info.items.key.curveType.title()}
                            value={localization.info.items.key.curveType[Contract.TypeNames.OciKmsVaultKeyCurveType][key.curveType]()}/>,
                    <InfoItem
                        key="rotationEnabled"
                        title={localization.info.items.rotationEnabled.title()}
                        value={
                            key.rotationEnabled
                                ? localization.info.items.rotationEnabled.true()
                                : localization.info.items.rotationEnabled.false()}/>,
                    <InfoItem
                        key="rotationTimeFrame"
                        title={localization.info.items.rotationTimeFrame.title()}
                        value={
                            _.isNil(key.rotationTimeFrame)
                                ? undefined
                                : localization.info.items.rotationTimeFrame.text({ rotationTimeFrame: key.rotationTimeFrame })}/>,
                    <InfoItem
                        key="nextRotationTime"
                        title={localization.info.items.nextRotationTime()}
                        value={
                            _.isNil(key.nextRotationTime)
                                ? undefined
                                : TimeFormatter.profileFormatDateTime(key.nextRotationTime)}/>

                ]}
                options={options?.infoOptions}>
            </Info>
    });
}