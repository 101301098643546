﻿import _ from "lodash";
import React, { useState } from "react";
import { useUpdateConfiguration } from "../../../../hooks";
import { ActionsCell, ExclusionTagsTableActionsCellProps } from "../../../ExclusionTags";

export function VirtualMachineImageActionsCell({ exclusionTagToDelete, onDelete, useScopeResourceTags }: ExclusionTagsTableActionsCellProps) {
    const [deleteVirtualMachineImageExclusionTagExecuting, setDeleteVirtualMachineImageExclusionTagExecuting] = useState(false);
    const [deleteVirtualMachineImageExclusionTagError, setDeleteVirtualMachineImageExclusionTagError] = useState(false);
    const updateConfiguration = useUpdateConfiguration();

    const scopeResourceTags = useScopeResourceTags();
    async function deleteVirtualMachineImageExclusionTag() {
        setDeleteVirtualMachineImageExclusionTagExecuting(true);
        setDeleteVirtualMachineImageExclusionTagError(false);

        try {
            const updateWorkloadAnalysisResponse =
                await updateConfiguration({
                    virtualMachineImageExclusionTags: _.filter(
                        scopeResourceTags,
                        existingExclusionTag =>
                            !_.isEqual(existingExclusionTag.key, exclusionTagToDelete.key) ||
                            !_.isEqual(existingExclusionTag.value, exclusionTagToDelete.value))
                });

            onDelete(updateWorkloadAnalysisResponse.virtualMachineImageExclusionTags);
        } catch {
            setDeleteVirtualMachineImageExclusionTagError(true);
        }

        setDeleteVirtualMachineImageExclusionTagExecuting(false);
    }

    return (
        <ActionsCell
            deleteExclusionTag={deleteVirtualMachineImageExclusionTag}
            deleteExclusionTagError={deleteVirtualMachineImageExclusionTagError}
            deleteExclusionTagExecuting={deleteVirtualMachineImageExclusionTagExecuting}
            exclusionTag={exclusionTagToDelete}/>);
}