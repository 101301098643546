import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, Entity, useEntityTypeNameTranslator, usePrincipalModelAccess } from "../../../../../../..";

export function useGcpDisableServiceAccountChangeDefinition(change: Contract.GcpChange, entityLinkDisabled?: boolean) {
    const disableServiceAccountChange = change as Contract.GcpDisableServiceAccountChange;
    const serviceAccountModelAccess = usePrincipalModelAccess<Contract.ServiceIdentityModelAccess>(disableServiceAccountChange.resourceId);
    const entityTypeNameTranslator = useEntityTypeNameTranslator();

    const localization =
        useLocalization(
            "common.change.hooks.useDefinition.hooks.useGcpDefinition.hooks.useGcpDisableServiceAccountChangeDefinition",
            () => ({
                title: {
                    withoutWarning: "Delete or disable {{serviceAccount}}",
                    withWarning: "Delete or disable {{serviceAccount}} (Delete may prevent linked {{originatorEntityTypeName}} from restarting)"
                }
            }));

    return {
        title:
            (_.isEmpty(serviceAccountModelAccess.originatorEntityIds)
                ? localization.title.withoutWarning
                : localization.title.withWarning)(
                {
                    originatorEntityTypeName:
                        entityTypeNameTranslator(
                            Contract.TypeNames.IGcpServiceAccountOriginatorScopeResource,
                            {
                                count: serviceAccountModelAccess.originatorEntityIds.length,
                                includeServiceName: false,
                                variant: "text"
                            }),
                    serviceAccount:
                        <Entity
                            entityIdOrModel={disableServiceAccountChange.resourceId}
                            entityTypeNameTranslatorOptions={{ variant: "text" }}
                            glanceOptions={{ disabled: true }}
                            linkOptions={{ disabled: entityLinkDisabled }}
                            variant="typeText"/>
                })
    };
}