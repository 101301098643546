import _ from "lodash";
import React from "react";
import { FormLayout, makeContextProvider, Optional, OrderedWizard, OrderedWizardItem, useExecuteOperation, useLocalization } from "@infrastructure";
import { useGcpContext, useSetGcpContext } from "../..";
import { ConfigurationController, Contract, LicensingHelper, tenantModelStore } from "../../../../../../../../../../../../../../common";
import { FinishItem, GciTenantItem, OrganizationDetailsItem, OrganizationMemberSelectionItem, PermissionsItem, RolesItem, SinkPubSubSubscriptionItem } from "./components";

export class AddOrEditContext {
    public enabled?: boolean;
    public folderEnabled?: boolean;
    public memberSelection?: Contract.OrganizationMemberSelection;
    public name?: string;
    public organizationMemberTenantNames?: string[];
    public permissionTypes?: Contract.CloudProviderTenantPermissionType[];
    public serviceAccountAssignedRoleNames?: string[];
    public serviceAccountMissingOrganizationRoleNames?: string[];
    public serviceAccountMissingTenantRoleNames?: string[];
    public sinkPubSubSubscriptionEnabled: boolean;
    public sinkPubSubSubscriptionRawId?: string;

    constructor(
        public gciTenantModel: Optional<Contract.GciTenantModel>,
        public organizationConfiguration: Optional<Contract.GcpOrganizationConfiguration>,
        public organizationOnboardingInfo: Contract.ConfigurationControllerGetGcpOrganizationOnboardingInfoResponse) {
        this.enabled = this.organizationConfiguration?.enabled;
        this.folderEnabled = this.organizationConfiguration?.folderEnabled;
        this.memberSelection = this.organizationConfiguration?.memberSelection;
        this.name = this.organizationConfiguration?.name;
        this.permissionTypes =
            this.organizationConfiguration?.permissionTypes ??
            _<Contract.CloudProviderTenantPermissionType>([]).
                concat(Contract.CloudProviderTenantPermissionType.Read).
                concatIf(
                    LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.PermissionManagement),
                    Contract.CloudProviderTenantPermissionType.PermissionManagement).
                concatIf(
                    LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cnapp),
                    Contract.CloudProviderTenantPermissionType.WorkloadAnalysis).
                value();
        this.sinkPubSubSubscriptionEnabled =
            _.isNil(organizationConfiguration) ||
            !_.isNil(organizationConfiguration.sink?.pubSubSubscriptionRawId);
        this.sinkPubSubSubscriptionRawId = organizationConfiguration?.sink?.pubSubSubscriptionRawId;
    }
}

export const [useAddOrEditContext, useSetAddOrEditContext, useAddOrEditContextProvider] = makeContextProvider<AddOrEditContext>();

export function AddOrEdit() {
    const { addOrEditOpen } = useGcpContext();
    const setGcpContext = useSetGcpContext();

    const [organizationOnboardingInfo] =
        useExecuteOperation(
            AddOrEdit,
            ConfigurationController.getGcpOrganizationOnboardingInfo);

    const gciTenantModel =
        tenantModelStore.useGet(
            _.isBoolean(addOrEditOpen)
                ? undefined
                : addOrEditOpen.gciTenantId);

    const [context, , ContextProvider] =
        useAddOrEditContextProvider(
            () =>
                new AddOrEditContext(
                    gciTenantModel as Contract.GciTenantModel,
                    _.isBoolean(addOrEditOpen)
                        ? undefined
                        : addOrEditOpen,
                    organizationOnboardingInfo));

    const localization =
        useLocalization(
            "views.customer.configuration.integrations.hooks.useItems.hooks.useCloudProviderTenantOrganizationItems.gcp.addOrEdit",
            () => ({
                steps: {
                    configureScopes: "Configure Scopes",
                    gciTenantItem: "Select Google Workspace",
                    organizationDetailsItem: "Organization Details",
                    permissionsItem: "Permissions",
                    rolesItem: "Assign Roles",
                    sinkPubSubSubscriptionItem: "Audit Logs"
                },
                title: {
                    add: "Add GCP Organization",
                    edit: "Edit GCP Organization - {{name}}"
                }
            }));

    return (
        <ContextProvider>
            <FormLayout
                disableContentPadding={true}
                titleOptions={{
                    text:
                        _.isNil(context.organizationConfiguration)
                            ? localization.title.add()
                            : localization.title.edit({ name: context.name })
                }}>
                <OrderedWizard
                    finishItemElement={<FinishItem/>}
                    startItemIndex={
                        _.isNil(context.organizationConfiguration)
                            ? undefined
                            : 1}
                    onClose={
                        () =>
                            setGcpContext(
                                gcpContext => ({
                                    ...gcpContext,
                                    addOrEditOpen: false
                                }))}>
                    <OrderedWizardItem
                        deferredLoading={true}
                        title={localization.steps.gciTenantItem()}>
                        <GciTenantItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem title={localization.steps.organizationDetailsItem()}>
                        <OrganizationDetailsItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem title={localization.steps.permissionsItem()}>
                        <PermissionsItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem title={localization.steps.rolesItem()}>
                        <RolesItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem title={localization.steps.sinkPubSubSubscriptionItem()}>
                        <SinkPubSubSubscriptionItem/>
                    </OrderedWizardItem>
                    <OrderedWizardItem
                        deferredLoading={true}
                        title={localization.steps.configureScopes()}>
                        <OrganizationMemberSelectionItem/>
                    </OrderedWizardItem>
                </OrderedWizard>
            </FormLayout>
        </ContextProvider>);
}