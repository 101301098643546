﻿import { DataTableColumn, DataTableColumnRenderProps, EnumValuesFilter, useLocalization } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { EntityTableDefinition } from "../../..";
import { Contract, ElasticsearchItemPageHelper, EntitiesCell, ItemSelectionHelper, PagedEntityFilter, TimeRangeHelper, useEntitiesExportOptions } from "../../../../../../../../../../common";
import { useGcpComputeTargetProxyTypeTranslator } from "../../../../../../hooks";
import { DefinitionData } from "../../../../Table";
import { useGcpCommonComputeLoadBalancerResourceDefinition } from "./useGcpCommonComputeLoadBalancerResourceDefinition";

export function useGcpComputeTargetProxyDefinition(definitionData: DefinitionData) {
    const commonComputeLoadBalancerResourceDefinition = useGcpCommonComputeLoadBalancerResourceDefinition(definitionData);
    const entitiesExportOptions = useEntitiesExportOptions();

    const computeTargetProxyTypeTranslator = useGcpComputeTargetProxyTypeTranslator();
    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpComputeTargetProxyDefinition",
            () => ({
                columns: {
                    forwardingRuleIds: "Forwarding Rules",
                    targetResourceIdReference: "Target",
                    type: "Proxy Type"
                }
            }));

    return new EntityTableDefinition(
        [
            commonComputeLoadBalancerResourceDefinition.columns.tenantColumn,
            commonComputeLoadBalancerResourceDefinition.columns.creationTimeColumn,
            commonComputeLoadBalancerResourceDefinition.columns.creatorIdentityCsvColumn,
            commonComputeLoadBalancerResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            commonComputeLoadBalancerResourceDefinition.columns.accessLevelColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpComputeTargetProxyModel) => ({
                            [localization.columns.type()]: computeTargetProxyTypeTranslator((item.entity as Contract.GcpComputeTargetProxy).type)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EnumValuesFilter
                                enumType={Contract.GcpComputeTargetProxyType}
                                enumTypeTranslator={computeTargetProxyTypeTranslator}
                                placeholder={localization.columns.type()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeTargetProxyType}
                itemProperty={(item: Contract.EntityModel) => computeTargetProxyTypeTranslator((item.entity as Contract.GcpComputeTargetProxy).type)}
                key={Contract.EntityModelProperty.GcpComputeTargetProxyType}
                title={localization.columns.type()}/>,
            commonComputeLoadBalancerResourceDefinition.columns.loadBalancerIdsColumn,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpComputeTargetProxyModel>(
                        Contract.TypeNames.GcpComputeForwardingRule,
                        item => item.forwardingRuleIds,
                        localization.columns.forwardingRuleIds())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpComputeTargetProxyForwardingRules)}
                                placeholder={localization.columns.forwardingRuleIds()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeTargetProxyForwardingRules}
                key={Contract.EntityModelProperty.GcpComputeTargetProxyForwardingRules}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpComputeTargetProxyModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.forwardingRuleIds}
                            entityTypeName={Contract.TypeNames.GcpComputeForwardingRule}
                            entityVariant="iconText"/>}
                title={localization.columns.forwardingRuleIds()}/>,
            <DataTableColumn
                exportOptions={
                    entitiesExportOptions<Contract.GcpComputeTargetProxyModel>(
                        Contract.TypeNames.GcpScopeResource,
                        item => [item.targetResourceIdReference],
                        localization.columns.targetResourceIdReference())}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <PagedEntityFilter
                                getEntityIdPage={
                                    ElasticsearchItemPageHelper.makePagedEntityFilterEntityItem(
                                        definitionData.entityTypeEntitiesViewName,
                                        Contract.EntityModelProperty.GcpComputeTargetProxyTargetResource)}
                                placeholder={localization.columns.targetResourceIdReference()}/>
                    }
                }}
                id={Contract.EntityModelProperty.GcpComputeTargetProxyTargetResource}
                key={Contract.EntityModelProperty.GcpComputeTargetProxyTargetResource}
                render={
                    ({ item }: DataTableColumnRenderProps<Contract.GcpComputeTargetProxyModel>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.targetResourceIdReference}
                            entityTypeName={Contract.TypeNames.GcpScopeResource}
                            entityVariant="iconText"/>}
                title={localization.columns.targetResourceIdReference()}/>,
            commonComputeLoadBalancerResourceDefinition.columns.regionColumn,
            commonComputeLoadBalancerResourceDefinition.columns.regionLocationColumn,
            commonComputeLoadBalancerResourceDefinition.columns.attributesColumn,
            commonComputeLoadBalancerResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonComputeLoadBalancerResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonComputeLoadBalancerResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpComputeTargetProxyRequest(
                new Contract.EntityControllerGetEntityModelPageGcpComputeTargetProxyRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceAccessLevel]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceRawId]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeLoadBalancerResourceLoadBalancers]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeTargetProxyForwardingRules]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeTargetProxyTargetResource]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpComputeTargetProxyType])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags]))));
}