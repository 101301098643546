import { useLocalization, useRoute, useSetRoute } from "@infrastructure";
import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { Contract, CustomerConsoleAppUrlHelper, RiskType, TypeHelper, useLayoutOptions } from "../../../../../../common";
import { RisksItems } from "../../../Risks";
import { useViewTranslator } from "../../../Risks/components/Items/hooks";
import { RiskDefinitionSectionCategory } from "../../../Risks/hooks/useDefinition/utilities";

export function Risks() {
    const localization =
        useLocalization(
            "views.customer.code.risks",
            () => ({
                title: "{{view}} IaC Findings"
            }));

    const { match, viewOrId } = useRoute(`${CustomerConsoleAppUrlHelper.getCodeRisksRelativeUrl()}/{viewOrId}`);
    const setRoute = useSetRoute();

    const viewTranslator = useViewTranslator();
    const risksView =
        useMemo(
            () =>
                TypeHelper.tryParseEnum<Contract.RisksView>(
                    Contract.TypeNames.RisksView,
                    _(Contract.RisksView).
                        values().
                        find(risksViewValue => risksViewValue.toLowerCase() === viewOrId?.toLowerCase())) ?? Contract.RisksView.Open,
            [viewOrId]);

    useLayoutOptions({ view: { title: localization.title({ view: viewTranslator(risksView) }) } });

    useEffect(
        () => {
            if (viewOrId &&
                !_(Contract.RisksView).
                    values().
                    includes(viewOrId as Contract.RisksView) &&
                match) {
                setRoute(
                    `${CustomerConsoleAppUrlHelper.getCodeRisksRelativeUrl()}${CustomerConsoleAppUrlHelper.getCodeRiskProfileHashUrl(viewOrId, { category: RiskDefinitionSectionCategory.Overview })}`,
                    undefined,
                    { appendBrowserHistory: false });
            }
        },
        [viewOrId]);

    return (
        <RisksItems
            baseUrl={CustomerConsoleAppUrlHelper.getCodeRisksRelativeUrl()}
            riskType={RiskType.Code}
            variant="view"/>);
}