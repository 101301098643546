import { UnexpectedError } from "@infrastructure";
import { Contract } from "../../../../../../../common";
import { IntegrationItemCategory } from "./IntegrationItemCategory";

export class IntegrationItem {
    public viewName: string;

    constructor(
        public category: IntegrationItemCategory,
        public configurations: IntegrationConfigurationType[],
        public descriptions: string[],
        public iconElement: JSX.Element,
        public title: string,
        public type: IntegrationItemType,
        public viewElement: JSX.Element) {

        this.viewName =
            getIntegrationItemViewName(
                type,
                category);
    }
}

export type IntegrationConfigurationType =
    Contract.ScopeSystemEntityModel |
    Contract.TenantModel;

export type IntegrationItemType =
    Contract.CiTenantType |
    Contract.CodePipelineType |
    Contract.CodeTenantType |
    Contract.IntegrationType |
    Contract.TenantType;

export function getIntegrationItemViewName(
    type: IntegrationItemType,
    category?: IntegrationItemCategory): string {
    switch (category) {
        case IntegrationItemCategory.CiIntegration:
            switch (type) {
                case Contract.CiTenantType.Cloudsmith:
                    return "cloudsmithRegistries";
                case Contract.CiTenantType.General:
                    return "generalRegistries";
                case Contract.CiTenantType.Jfrog:
                    return "jfrogOrganizations";
                default:
                    throw new UnexpectedError("type", type);
            }
        case IntegrationItemCategory.CodePipelines:
            switch (type) {
                case Contract.CodePipelineType.AzurePipeline:
                    return "azurePipelines";
                case Contract.CodePipelineType.General:
                    return "generalPipelines";
                case Contract.CodePipelineType.GitHubWorkflow:
                    return "gitHubWorkflows";
                case Contract.CodePipelineType.GitLabPipeline:
                    return "gitLabPipelines";
                case Contract.CodePipelineType.JenkinsPipeline:
                    return "jenkinsPipelines";
                case Contract.CodePipelineType.TerraformCloudRunTask:
                    return "terraformCloudRunTasks";
                default:
                    throw new UnexpectedError("type", type);
            }
        case IntegrationItemCategory.CodeOrganizations:
            switch (type) {
                case Contract.CodeTenantType.AzureDevOps:
                    return "azureDevOpsOrganizations";
                case Contract.CodeTenantType.Bitbucket:
                    return "bitbucketOrganizations";
                case Contract.CodeTenantType.GitHub:
                    return "gitHubOrganizations";
                case Contract.CodeTenantType.GitLab:
                    return "gitLabOrganizations";
                default:
                    throw new UnexpectedError("type", type);
            }
        default:
            switch (type) {
                case Contract.IntegrationType.Ci:
                    return "containerImageRegistries";
                case Contract.IntegrationType.Datadog:
                    return "datadogOrganizations";
                case Contract.IntegrationType.EndpointConnector:
                    return "endpointConnectors";
                case Contract.IntegrationType.GitHubServer:
                    return "gitHubServers";
                case Contract.IntegrationType.GitLabServer:
                    return "gitLabServers";
                case Contract.IntegrationType.Jira:
                    return "jiraSites";
                case Contract.IntegrationType.Kubernetes:
                    return "kubernetes";
                case Contract.IntegrationType.QRadar:
                    return "qRadarServers";
                case Contract.IntegrationType.ServiceNow:
                    return "serviceNowInstances";
                case Contract.IntegrationType.Slack:
                    return "slackWorkspaces";
                case Contract.IntegrationType.Splunk:
                    return "splunkInstances";
                case Contract.IntegrationType.Sqs:
                    return "sqsQueues";
                case Contract.IntegrationType.Teams:
                    return "teamsDirectories";
                case Contract.IntegrationType.Webhook:
                    return "webhookEndpoints";
                case Contract.TenantType.Aad:
                    return "aadTenants";
                case Contract.TenantType.Aws:
                    return "awsOrganizations";
                case Contract.TenantType.Azure:
                    return "azureOrganizations";
                case Contract.TenantType.Gci:
                    return "gciTenants";
                case Contract.TenantType.Gcp:
                    return "gcpOrganizations";
                case Contract.TenantType.Oci:
                    return "ociOrganizations";
                case Contract.TenantType.Okta:
                    return "oktaTenants";
                case Contract.TenantType.OneLogin:
                    return "oneLoginTenants";
                case Contract.TenantType.PingIdentity:
                    return "pingIdentityTenants";
                default:
                    throw new UnexpectedError("type", type);
            }
    }
}