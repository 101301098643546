import { useLocalization } from "@infrastructure";
import React from "react";
import { Contract } from "../../../../../../../../../../common";
import { EntitiesInfoCard, EntitiesInfoItem, Info } from "../../../../components";
import { DefinitionOptions, EntityProfileDefinition } from "../../useDefinition";
import { useOneLoginCommonDirectoryEntityInfoItemElements } from "./useOneLoginCommonDirectoryEntityInfoItemElements";

export function useOneLoginDirectoryRoleDefinition(entityModel: Contract.OneLoginDirectoryEntityModel, options?: DefinitionOptions) {
    const defaultResourceInfoItemElements = useOneLoginCommonDirectoryEntityInfoItemElements(entityModel);
    const roleModel = entityModel as Contract.OneLoginDirectoryRoleModel;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.oneLogin.useOneLoginDirectoryRoleDefinition",
            () => ({
                info: {
                    users: "Users"
                }
            }));
    return new EntityProfileDefinition({
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={[]}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <EntitiesInfoItem
                        entityIdsOrModels={roleModel.userIds}
                        entityTypeName={Contract.TypeNames.OneLoginDirectoryUser}
                        key="users"
                        location="miniGlance"
                        title={localization.info.users()}/>
                ]}
                options={options?.infoOptions}>
                <EntitiesInfoCard
                    entityIdsOrModels={roleModel.userIds}
                    entityTypeName={Contract.TypeNames.OneLoginDirectoryUser}
                    title={localization.info.users()}/>
            </Info>,
        sensitive: false
    });
}