import { useExecuteOperation } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { ComplianceHelper, Contract, LicensingHelper, RiskController, TenantHelper, useComplianceTranslator } from "..";

export function useBuiltInComplianceTypes(initialTenantType?: Contract.TenantType[]): [Contract.BuiltInComplianceSectionType[], Contract.BuiltInComplianceSectionType[]] {
    const tenantTypes =
        useMemo(
            () => initialTenantType ?? TenantHelper.ComplianceTenantTypes,
            []);

    const [{ builtInComplianceDatas }] =
        useExecuteOperation(
            useBuiltInComplianceTypes,
            async () =>
                LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.Cspm)
                    ? await RiskController.getBuiltInComplianceDatas()
                    : { builtInComplianceDatas: [] as Contract.RiskControllerGetBuiltInComplianceDatasResponseComplianceData[] });

    const complianceTranslator = useComplianceTranslator();
    const [enabledBuiltInComplianceTypes, disabledBuiltInComplianceTypes] =
        useMemo(
            () =>
                _(builtInComplianceDatas).
                    filter(
                        builtInComplianceData =>
                            _(ComplianceHelper.getBuiltInComplianceTypeTenantTypes(builtInComplianceData.compliance.type)).
                                intersection(tenantTypes).
                                some()).
                    partition(builtInComplianceData => builtInComplianceData.enabled).
                    value().
                    map(
                        builtInComplianceDatas =>
                            _(builtInComplianceDatas).
                                map(builtInComplianceData => builtInComplianceData.compliance.type).
                                orderBy(builtInComplianceType => complianceTranslator(builtInComplianceType)).
                                value()),
            [builtInComplianceDatas, complianceTranslator, tenantTypes]);

    return [disabledBuiltInComplianceTypes, enabledBuiltInComplianceTypes];
}