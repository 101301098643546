﻿import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useAadDirectoryDirectoryRoleAssignmentResourceScopeInfoTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useAadDirectoryDirectoryRoleAssignmentResourceScopeInfoTranslator",
            () => ({
                [Contract.AadDirectoryDirectoryRoleAssignmentResourceScopeType.AdministrativeUnit]: "{{displayName}} (Administrative Unit)",
                [Contract.AadDirectoryDirectoryRoleAssignmentResourceScopeType.Application]: "{{displayName}} (Application)",
                [Contract.AadDirectoryDirectoryRoleAssignmentResourceScopeType.Directory]: "Directory",
                [Contract.AadDirectoryDirectoryRoleAssignmentResourceScopeType.ServicePrincipal]: "{{displayName}} (Service Principal)",
                [Contract.AadDirectoryDirectoryRoleAssignmentResourceScopeType.Unknown]: "{{rawId}}"
            }));
    return (scopeInfo: Contract.AadDirectoryDirectoryRoleAssignmentResourceScopeInfo) =>
        localization[scopeInfo.type]({
            displayName: scopeInfo.displayName,
            rawId: scopeInfo.rawId
        });
}