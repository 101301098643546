import { Link, Message, useLocalization } from "@infrastructure";
import { Box } from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";
import { useRiskContext } from "../../../..";
import { Contract, CustomerConsoleAppUrlHelper, riskPolicyModelStore, TypeHelper, UserHelper, useTheme } from "../../../../../../../../../common";

export function EntityExclusionMessage() {
    const { riskModel } = useRiskContext();
    const { riskPolicyConfiguration } = riskPolicyModelStore.useGet(riskModel.risk.policyId);
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.entityExclusionMessage",
            () => ({
                links: {
                    riskPoliciesLink: "policies"
                },
                title: "To manage exclusions for this finding, go to the {{riskPoliciesLink}} page"
            }));
    const theme = useTheme();
    return _.some(
        riskPolicyConfiguration.entityTypeNameToExclusionsMap,
        entityExclusion => !_.isEmpty(entityExclusion.entityIdToDataMap) || !_.isEmpty(entityExclusion.entityPropertyPatterns)) ||
    !UserHelper.hasScopePermissions(riskPolicyConfiguration.scopeId, Contract.IdentityPermission.SecurityWrite)
        ? <Fragment/>
        : <Box
            sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: theme.spacing(2)
            }}>
            <Message
                level="info"
                title={
                    localization.title({
                        riskPoliciesLink:
                            <Link
                                urlOrGetUrl={
                                    CustomerConsoleAppUrlHelper.getRiskPoliciesRelativeUrl(
                                        riskPolicyConfiguration.scopeId,
                                        {
                                            id:
                                                TypeHelper.extendOrImplement(riskPolicyConfiguration.typeName, Contract.TypeNames.CustomRiskPolicyConfiguration)
                                                    ? (riskPolicyConfiguration as Contract.CustomRiskPolicyConfiguration).id
                                                    : riskPolicyConfiguration.typeName
                                        })}>
                                {localization.links.riskPoliciesLink()}
                            </Link>
                    })}/>
        </Box>;
}