﻿import { DataTable, DataTableColumn, DataTableColumnRenderProps, EmptyMessageText, useLocalization } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, EntitiesCell, Entity, entityModelStore, InfoCard } from "../../../../../../../../../../../../common";

type AutoScalingGroupsInfoCardProps = {
    autoScalingGroupIds: string[];
};

export function AutoScalingGroupsInfoCard({ autoScalingGroupIds }: AutoScalingGroupsInfoCardProps) {
    const autoScalingGroupModels = entityModelStore.useGet(autoScalingGroupIds);

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsEksClusterNodeGroupDefinition.autoScalingGroupsInfoCard",
            () => ({
                columns: {
                    autoScalingGroup: "Auto Scaling Group",
                    instanceReferences: "EC2 Instances"
                },
                empty: "No Auto Scaling Groups",
                title: "Auto Scaling Groups"
            }));

    return (
        <InfoCard title={localization.title()}>
            <DataTable
                emptyMessageOptions={{ emptyMessageText: new EmptyMessageText(localization.empty()) }}
                fetchItems={() => autoScalingGroupModels}
                getItemId={item => item.id}
                sortOptions={{ enabled: false }}
                variant="card">
                <DataTableColumn
                    id="autoScalingGroup"
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsAutoScalingAutoScalingGroupModel>) =>
                            <Entity
                                entityIdOrModel={item.id}
                                variant="iconTextTenant"/>}
                    title={localization.columns.autoScalingGroup()}/>
                <DataTableColumn
                    id="instanceReferences"
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsAutoScalingAutoScalingGroupModel>) =>
                            <EntitiesCell
                                entityIdsOrModels={
                                    _.map(
                                        (item.entity as Contract.AwsAutoScalingAutoScalingGroup).instanceReferences,
                                        instanceReference => instanceReference.idReference)}
                                entityTypeName={Contract.TypeNames.AwsEc2Instance}
                                entityVariant="iconTextTenant"/>}
                    title={localization.columns.instanceReferences()}/>
            </DataTable>
        </InfoCard>);
}