﻿import { AnalyticsContext, AnalyticsEventActionType, map, useLocalization, useTrackAnalyticsEvent } from "@infrastructure";
import _ from "lodash";
import React, { Fragment } from "react";
import { CustomerConsoleAppUrlHelper, useApplicationViewRoute, useLayoutOptions } from "../../../../common";
import { CustomerView } from "../../hooks";
import { KubernetesAdmissionControllerRiskPolicies, KubernetesClusterAdmissionControllerEvents, SystemKubernetesClusters } from "./components";

export enum KubernetesView {
    KubernetesAdmissionControllerRiskPolicies = "kubernetesAdmissionControllerRiskPolicies",
    KubernetesClusterAdmissionControllerEvents = "kubernetesClusterAdmissionControllerEvents",
    SystemKubernetesClusters = "systemKubernetesClusters"
}

export function Kubernetes() {
    const [{ view }] =
        useApplicationViewRoute(
            CustomerConsoleAppUrlHelper.getTemplatePath(CustomerView.Kubernetes),
            _.values(KubernetesView),
            KubernetesView.SystemKubernetesClusters);

    return (
        <AnalyticsContext context={view}>
            <View view={view}/>
        </AnalyticsContext>);
}

type ViewProps = {
    view: KubernetesView;
};

function View({ view }: ViewProps) {
    const localization =
        useLocalization(
            "views.customer.kubernetes.view",
            () => ({
                title: {
                    [KubernetesView.KubernetesAdmissionControllerRiskPolicies]: "Admission Controller Policies",
                    [KubernetesView.KubernetesClusterAdmissionControllerEvents]: "Admission Controller Logs",
                    [KubernetesView.SystemKubernetesClusters]: "Clusters"
                }
            }));
    useLayoutOptions({ view: { title: localization.title[view]() } });
    useTrackAnalyticsEvent(AnalyticsEventActionType.PageView);

    return (
        <Fragment>
            {map(
                view,
                {
                    [KubernetesView.KubernetesAdmissionControllerRiskPolicies]: () => <KubernetesAdmissionControllerRiskPolicies/>,
                    [KubernetesView.KubernetesClusterAdmissionControllerEvents]: () => <KubernetesClusterAdmissionControllerEvents/>,
                    [KubernetesView.SystemKubernetesClusters]: () => <SystemKubernetesClusters/>
                })}
        </Fragment>);
}