import { DataTableColumn, DataTableColumnRenderProps, optionalTableCell, TimeFormatter, useLocalization } from "@infrastructure";
import _ from "lodash";
import { useMemo } from "react";
import { TableColumnId } from "../../../..";
import { Contract, EntityFilter } from "../../../../../../../../../../common";
import { GranteeUserPermissionRequestsViewType, useGranteeUserPermissionRequestsContext } from "../../../../../../GranteeUserPermissionRequests";
import { TableDefinition } from "../../useDefinition";
import { useCommonColumns } from "../useCommonColumns";
import { ActiveActionsCell, ApprovalLevelApproverUsersCell, PendingActionsCell } from "./components";

export function useActiveDefinition(): TableDefinition {
    const { getFiltersResponse } = useGranteeUserPermissionRequestsContext();
    const commonColumns = useCommonColumns(GranteeUserPermissionRequestsViewType.Active);

    const localization =
        useLocalization(
            "views.user.granteeUserPermissionRequests.table.hooks.useDefinition.hooks.useActiveDefinition",
            () => ({
                columns: {
                    approverUserIdReferences: "Approvers",
                    levelToPotentialApproverUserIdReferencesMap: {
                        "level0": "Approvers",
                        "level1": "Second Level Approvers",
                        "level2": "Third Level Approvers"
                    },
                    permissionRequestStatus: {
                        active: "Active",
                        pendingActivation: "Pending activation",
                        pendingApproval: "Pending approval",
                        title: "Status"
                    },
                    startTime: "Scheduled Time"
                },
                filters: { approverUserSearchableReference: "Approvers" }
            }));

    return useMemo(
        () =>
            new TableDefinition(
                maxLevel =>
                    [
                        commonColumns.systemCreationTime,
                        commonColumns.permissionEligibility,
                        commonColumns.permissionAssignmentRequestTenantIds,
                        ..._(maxLevel).
                            range().
                            map(
                                level =>
                                    <DataTableColumn
                                        id={`${TableColumnId.ApproverUserSearchableReference}-${level}`}
                                        key={`${TableColumnId.ApproverUserSearchableReference}-${level}`}
                                        render={
                                            ({ item }: DataTableColumnRenderProps<Contract.PermissionRequestModel>) =>
                                                <ApprovalLevelApproverUsersCell
                                                    level={level}
                                                    permissionRequestModel={item}/>}
                                        sortOptions={{ enabled: false }}
                                        title={localization.columns.levelToPotentialApproverUserIdReferencesMap.translate(`level${level}`)}/>).
                            value(),
                        <DataTableColumn
                            filterOptions={{
                                itemOrItems: {
                                    default: true,
                                    element:
                                        <EntityFilter
                                            emptyValue={getFiltersResponse.approverUserSearchableReferenceItems.emptyValue}
                                            entityIdsOrSearchableReferences={getFiltersResponse.approverUserSearchableReferenceItems.items}
                                            placeholder={localization.filters.approverUserSearchableReference()}/>,
                                    title: localization.filters.approverUserSearchableReference()
                                }
                            }}
                            id={TableColumnId.ApproverUserSearchableReference}
                            key={`${TableColumnId.ApproverUserSearchableReference}`}/>,
                        commonColumns.status,
                        <DataTableColumn
                            id={TableColumnId.StartTime}
                            key={TableColumnId.StartTime}
                            render={
                                optionalTableCell<Contract.PermissionRequestModel>(
                                    item =>
                                        _.isNil(item.permissionRequest.startTime)
                                            ? undefined
                                            : TimeFormatter.longDateTime(item.permissionRequest.startTime))}
                            sortOptions={{ enabled: false }}
                            title={localization.columns.startTime()}/>,
                        commonColumns.expirationTimeFrame,
                        commonColumns.reason,
                        <DataTableColumn
                            id={TableColumnId.Actions}
                            key={TableColumnId.Actions}
                            render={
                                optionalTableCell<Contract.PermissionRequestModel>(
                                    (item: Contract.PermissionRequestModel) =>
                                        item.permissionRequest.status === Contract.PermissionRequestStatus.Active
                                            ? <ActiveActionsCell item={item}/>
                                            : <PendingActionsCell item={item}/>)}
                            selectorOptions={{ disabled: true }}/>
                    ],
                [Contract.PermissionRequestStatus.Active, Contract.PermissionRequestStatus.PendingActivation, Contract.PermissionRequestStatus.PendingApproval]),
        [commonColumns, getFiltersResponse, localization]);
}