import { Function0 } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Action0, Optional } from "../types";
import { Cache, CacheKey } from "../utilities";

const operationKeyToPromiseCache = new Cache<Promise<any>>();

export function useOperation<TResult>(
    key: CacheKey,
    operation: Function0<Promise<TResult>>):
    [Promise<TResult>, Action0] {
    const mountedRef = useRef(false);
    if (!mountedRef.current &&
        !operationKeyToPromiseCache.has(key)) {
        operationKeyToPromiseCache.set(key, operation());
    }

    const [operationPromise, setOperationPromise] = useState<Optional<Promise<TResult>>>(() => operationKeyToPromiseCache.get(key));
    useEffect(
        () => {
            mountedRef.current = true;
            operationKeyToPromiseCache.remove(key);
        },
        []);

    const refreshOperation =
        () => {
            setOperationPromise(operation());
        };

    return [operationPromise!, refreshOperation];
}