import { AnalyticsEventActionType, useLocalization, useTrackAnalytics } from "@infrastructure";
import { Box, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { StorageHelper, useLayoutOptions } from "../../../../common";
import { Table } from "./components";

type EventsProps = {
    entityId?: string;
    riskId?: string;
    variant?: "card" | "tab" | "view";
};

export function Events({ entityId, riskId, variant = "card" }: EventsProps) {
    const localization =
        useLocalization(
            "views.customer.events",
            () => ({
                title: "Activity Log"
            }));
    useLayoutOptions({
        view:
            variant === "view"
                ? { title: localization.title() }
                : undefined
    });
    const trackAnalytics = useTrackAnalytics();
    useEffect(
        () => {
            if (variant === "view") {
                trackAnalytics(AnalyticsEventActionType.PageView);
            }
        },
        []);

    const theme = useTheme();
    return (
        <Box sx={{ height: "100%" }}>
            <Table
                dataTableVariant={
                    variant === "card"
                        ? "card"
                        : undefined}
                enableColumnSelector={variant === "view"}
                entityId={entityId}
                filtersOptions={{
                    sx:
                        variant === "tab"
                            ? { padding: theme.spacing(0, 0, 1.5) }
                            : undefined,
                    visibilityStorageItem: StorageHelper.customerEventsFilters(variant)
                }}
                riskId={riskId}/>
        </Box>);
}