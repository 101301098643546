﻿export class IpAddressHelper {

    public static getIpAddressCount(startIpAddress: string, endIpAddress: string): number {
        const startIp = this.toNumber(startIpAddress);
        const endIp = this.toNumber(endIpAddress);
        return endIp - startIp + 1;
    }

    private static toNumber(ipAddress: string): number {
        const ipAddressParts = ipAddress.split(".");
        return (((((Number(ipAddressParts[0]) * 256) + Number(ipAddressParts[1])) * 256) + Number(ipAddressParts[2])) * 256) + Number(ipAddressParts[3]);
    }
}