import { Action0, DiffDialog, ItemSelector, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import React, { useMemo, useState } from "react";

export class RiskPolicyDiffPolicyItem {
    constructor(
        public name: string,
        public rawDocument: string) {
    }
}

type PrincipalRiskPolicyDiffProps = {
    existingPolicyItems: RiskPolicyDiffPolicyItem[];
    newPolicyItem?: RiskPolicyDiffPolicyItem;
    onClose: Action0;
    policyFileName: string;
};

export function PrincipalRiskPolicyDiffDialog({ existingPolicyItems, newPolicyItem, onClose, policyFileName }: PrincipalRiskPolicyDiffProps) {
    const [selectedExistingPolicyItem, setSelectedExistingPolicyItem] = useState(existingPolicyItems[0]);
    const policyJsonFileName = `${policyFileName}.json`;
    const policyFileUrl =
        useMemo(
            () =>
                window.URL.createObjectURL(
                    new Blob(
                        [newPolicyItem?.rawDocument ?? ""],
                        { type: "application/json" })),
            []);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.principalRiskPolicyDiffDialog",
            () => ({
                title: "Policy difference"
            }));
    return (
        <DiffDialog
            downloadOptions={{
                data: policyFileUrl,
                fileName: policyJsonFileName
            }}
            format="json"
            modified={newPolicyItem?.rawDocument}
            modifiedTitle={newPolicyItem?.name ?? existingPolicyItems[0].name}
            original={selectedExistingPolicyItem.rawDocument}
            originalTitle={
                existingPolicyItems.length === 1
                    ? existingPolicyItems[0].name
                    : <ItemSelector
                        dense={true}
                        fieldSx={{ border: "none" }}
                        fullWidth={true}
                        items={existingPolicyItems}
                        selectedItem={selectedExistingPolicyItem}
                        sorted={false}
                        onSelectedItemChanged={setSelectedExistingPolicyItem}>
                        {existingPolicyItem =>
                            <Typography
                                noWrap={true}
                                variant="h3">
                                {existingPolicyItem.name}
                            </Typography>}
                    </ItemSelector>}
            title={localization.title()}
            onClose={onClose}/>);
}