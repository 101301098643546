import { DataTableColumn, DataTableColumnRenderProps, InlineItems, Optional, Steps, useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, EntitiesCell, Entity, entityModelStore, Region, tenantModelStore, useRiskSeverityReasonTranslator } from "../../../../../../../../../../../../common";
import { RiskDefinitionSection } from "../../../../../../utilities";
import { Table } from "../../../../components";
import { RiskContentProps } from "../../../../useCloudDefinition";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useResourceGeneralInformationStep } from "../../../useResourceGeneralInformationStep";
import { EntityExternalConsoleLink, ResourcesExternalConsoleLink } from "../../components";

export function useAzureComputeVirtualMachineUnmanagedDiskExistsRiskDefinition(riskModel: Contract.RiskModel) {
    const computeVirtualMachineUnmanagedDiskExistsRiskModel = riskModel as Contract.AzureComputeVirtualMachineUnmanagedDiskExistsRiskModel;
    const tenantConfiguration = tenantModelStore.useGet(riskModel.tenantId).configuration as Contract.AzureTenantConfiguration;
    const aadTenantConfiguration = tenantModelStore.useGet(tenantConfiguration.aadTenantId).configuration as Contract.AadTenantConfiguration;
    const virtualMachineModel = entityModelStore.useGet(computeVirtualMachineUnmanagedDiskExistsRiskModel.risk.entityId) as Contract.AzureComputeVirtualMachineModel;
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureComputeVirtualMachineUnmanagedDiskExistsRiskDefinition",
            () => ({
                description: "{{virtualMachine}} is using {{unmanagedDiskNames}}",
                sections: {
                    disks: "Disks",
                    resolution: {
                        step1: "Click on **Migrate to managed disks** button on the top",
                        step2: "Click **Migrate** to confirm"
                    }
                },
                unmanagedDisks: [
                    "unmanaged disk",
                    "{{count | NumberFormatter.humanize}} unmanaged disks"
                ]
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            unmanagedDiskNames:
                <InlineItems
                    items={computeVirtualMachineUnmanagedDiskExistsRiskModel.unmanagedDiskNames}
                    namePluralizer={localization.unmanagedDisks}
                    variant="itemCountAndType"/>,
            virtualMachine:
                <Entity
                    entityIdOrModel={computeVirtualMachineUnmanagedDiskExistsRiskModel.risk.entityId}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            virtualMachineModel.unknown
                ? <ResourcesExternalConsoleLink
                    partitionType={aadTenantConfiguration.partitionType}
                    typeName={Contract.TypeNames.AzureComputeVirtualMachine}/>
                : <EntityExternalConsoleLink
                    entityId={computeVirtualMachineUnmanagedDiskExistsRiskModel.risk.entityId}
                    page={Contract.AzureConsoleEntityPage.Disks}/>,
            localization.sections.resolution.step1(),
            localization.sections.resolution.step2()
        ],
        riskModel,
        undefined,
        {
            contextSectionElement:
                <ContextSection
                    risk={computeVirtualMachineUnmanagedDiskExistsRiskModel.risk}
                    virtualMachineModel={virtualMachineModel}/>,
            sections: [
                new RiskDefinitionSection(
                    <DetailsSection riskModel={riskModel}/>,
                    localization.sections.disks())
            ]
        });
}

function DetailsSection({ riskModel }: RiskContentProps) {
    const risk = riskModel.risk as Contract.AzureComputeVirtualMachineUnmanagedDiskExistsRisk;
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureComputeVirtualMachineUnmanagedDiskExistsRiskDefinition.detailsSection",
            () => ({
                columns: {
                    name: "Name",
                    role: {
                        title: "Disk Role",
                        [Contract.TypeNames.AzureComputeResourceDiskRole]: {
                            [Contract.AzureComputeResourceDiskRole.Data]: "Data",
                            [Contract.AzureComputeResourceDiskRole.OperatingSystem]: "OS"
                        }
                    },
                    storageAccount: "Storage Account",
                    storageAccountRegion: "Location",
                    url: "VHD"
                }
            }));
    const storageAccountModels =
        entityModelStore.useGet(
            _(risk.unmanagedDisks).
                map(unmanagedDisk => unmanagedDisk.storageAccountId).
                filter().
                as<string>().
                value());
    const storageAccountModelMap =
        useMemo(
            () =>
                _.keyBy(
                    storageAccountModels,
                    storageAccountModel => storageAccountModel.entity.id),
            [storageAccountModels]);
    return (
        <Table
            fetchItems={
                () =>
                    _.map(
                        risk.unmanagedDisks,
                        unmanagedDisk =>
                            new TableItem(
                                _.isNil(unmanagedDisk.storageAccountId)
                                    ? undefined
                                    : storageAccountModelMap[unmanagedDisk.storageAccountId],
                                unmanagedDisk))}
            getItemId={
                (item: TableItem) =>
                    _.indexOf(risk.unmanagedDisks, item.unmanagedDisk).
                        toString()}>
            <DataTableColumn
                id={UnmanagedDiskColumnId.Name}
                itemProperty={(item: TableItem) => item.unmanagedDisk.name}
                title={localization.columns.name()}/>
            <DataTableColumn
                id={UnmanagedDiskColumnId.Role}
                itemProperty={(item: TableItem) => localization.columns.role[Contract.TypeNames.AzureComputeResourceDiskRole][item.unmanagedDisk.role]()}
                title={localization.columns.role.title()}/>
            <DataTableColumn
                id={UnmanagedDiskColumnId.Url}
                itemProperty={(item: TableItem) => item.unmanagedDisk.url}
                title={localization.columns.url()}/>
            <DataTableColumn
                id={UnmanagedDiskColumnId.StorageAccount}
                render={
                    ({ item }: DataTableColumnRenderProps<TableItem>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.storageAccountModel}
                            entityTypeName={Contract.TypeNames.AzureStorageStorageAccount}
                            entityVariant="iconTextTypeTenant"/>}
                title={localization.columns.storageAccount()}/>;
            <DataTableColumn
                id={UnmanagedDiskColumnId.StorageAccountRegion}
                render={
                    ({ item }: DataTableColumnRenderProps<TableItem>) =>
                        <Region regionId={item.storageAccountModel?.entity.regionId}/>}
                title={localization.columns.storageAccountRegion()}/>,
        </Table>);
}

type ContextSectionProps = {
    risk: Contract.AzureComputeVirtualMachineUnmanagedDiskExistsRisk;
    virtualMachineModel: Contract.AzureComputeVirtualMachineModel;
};

function ContextSection({ risk, virtualMachineModel }: ContextSectionProps) {
    const severityReasonTranslator = useRiskSeverityReasonTranslator();
    const resourceGeneralInformationStep = useResourceGeneralInformationStep(virtualMachineModel);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureComputeVirtualMachineUnmanagedDiskExistsRiskDefinition.contextSection",
            () => ({
                status: "The virtual machine is in **{{status}}** status"
            }));
    return (
        <Steps variant="bullets">
            {_.filter([
                resourceGeneralInformationStep,
                localization.status({ status: risk.virtualMachineStatus }),
                severityReasonTranslator(
                    risk.severity,
                    risk.severityReason!)
            ])}
        </Steps>);
}

enum UnmanagedDiskColumnId {
    Name = "name",
    Role = "role",
    StorageAccount = "storageAccount",
    StorageAccountRegion = "storageAccountRegion",
    Url = "url"
}

class TableItem {
    constructor(
        public storageAccountModel: Optional<Contract.EntityModel>,
        public unmanagedDisk: Contract.AzureComputeVirtualMachineUnmanagedDisk) {
    }
}