﻿import _, { Dictionary } from "lodash";
import React, { useRef, useState } from "react";
import { CsvExportButton, DataTable, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DataTableSort, EmptyMessageText, Optional, TimeHelper, useGetExportFileName, useLocalization } from "@infrastructure";
import { Contract, ExportReportHelper, LicensingHelper, StorageHelper, useLayoutOptions } from "../../../../../../common";
import { useCommonVirtualMachineDefinition, useFetchWorkloadResourceModelPage, useGetWorkloadResourceModelsRequestSort } from "../../hooks";
import { WorkloadResourceModelRequestFiltersHelper } from "../../utilities";
import { PriorityScanActionCell } from "../PriorityScanActionCell";

type VirtualMachinesProps = {
    vulnerabilityRawId?: string;
};

export function VirtualMachines({ vulnerabilityRawId }: VirtualMachinesProps) {
    const dataTableActionsRef = useRef<DataTableActions>();
    const commonVirtualMachineDefinition = useCommonVirtualMachineDefinition(vulnerabilityRawId);
    const getWorkloadResourceModelsRequestSort = useGetWorkloadResourceModelsRequestSort();
    const localization =
        useLocalization(
            "views.customer.workloadAnalysis.virtualMachines",
            () => ({
                empty: {
                    workloads: {
                        withFilter: "No Matching Virtual Machines",
                        withoutFilter: "No Virtual Machines"
                    }
                },
                title: "Virtual Machines"
            }));

    useLayoutOptions({
        view:
            _.isNil(vulnerabilityRawId)
                ? { title: localization.title() }
                : undefined
    });

    const fetchWorkloadResourceModelPage =
        useFetchWorkloadResourceModelPage(
            dataTableActionsRef,
            WorkloadResourceModelRequestFiltersHelper.getVirtualMachineFilters,
            Contract.WorkloadControllerGetWorkloadResourceModelPageVirtualMachineRequest,
            vulnerabilityRawId);
    const filterMapRef = useRef<Dictionary<any>>();
    const sortRef = useRef<Optional<DataTableSort>>();
    const [workloadResourcesCount, setWorkloadResourcesCount] = useState<number>();

    const getExportFileName = useGetExportFileName(Contract.ReportContentType.Csv);

    return (
        <DataTable
            actionsRef={dataTableActionsRef}
            columnOptions={{
                orderOptions: {
                    enabled: true,
                    persistenceStorageItem:
                        vulnerabilityRawId
                            ? StorageHelper.customerWorkloadAnalysisVirtualMachinesProfileTableColumnOrder
                            : StorageHelper.customerWorkloadAnalysisVirtualMachinesTableColumnOrder
                },
                resizable: true,
                selectorOptions: {
                    enabled: true,
                    persistenceStorageItem:
                        vulnerabilityRawId
                            ? StorageHelper.customerWorkloadAnalysisVirtualMachinesProfileTableColumnSelector
                            : StorageHelper.customerWorkloadAnalysisVirtualMachinesTableColumnSelector
                },
                stickyColumnId: Contract.WorkloadControllerRequestProperty.VirtualMachineId
            }}
            emptyMessageOptions={{
                emptyMessageText:
                    new EmptyMessageText(
                        localization.empty.workloads.withoutFilter(),
                        localization.empty.workloads.withFilter())
            }}
            fetchItems={fetchWorkloadResourceModelPage}
            filtersOptions={{
                onChanged: filterMap => filterMapRef.current = filterMap,
                persist: {
                    visibilityStorageItem:
                        vulnerabilityRawId
                            ? StorageHelper.customerWorkloadAnalysisVirtualMachinesProfileTableColumnTableFilters
                            : StorageHelper.customerWorkloadAnalysisVirtualMachinesTableColumnTableFilters
                },
                ...(vulnerabilityRawId && {
                    sx: {
                        paddingLeft: 0,
                        paddingTop: 0
                    }
                })
            }}
            getItemId={(workloadResourceModel: Contract.WorkloadResourceModel) => workloadResourceModel.id}
            virtualizationEnabled={true}
            onItemCountChanged={workloadResourcesCount => setWorkloadResourcesCount(workloadResourcesCount)}
            onSortChanged={sort => sortRef.current = sort}>
            {commonVirtualMachineDefinition.columns.virtualMachineId}
            {commonVirtualMachineDefinition.columns.tenantId}
            {commonVirtualMachineDefinition.columns.operatingSystemDisplayName}
            {commonVirtualMachineDefinition.columns.operatingSystemType}
            {commonVirtualMachineDefinition.columns.packageDisplayName}
            {commonVirtualMachineDefinition.columns.vulnerabilities}
            {commonVirtualMachineDefinition.columns.risksSeverity}
            {commonVirtualMachineDefinition.columns.vulnerabilityResolutionPatches}
            {commonVirtualMachineDefinition.columns.networkInboundAccessType}
            {commonVirtualMachineDefinition.columns.networkInboundExternalAccessScope}
            {commonVirtualMachineDefinition.columns.inboundExternalDestinationNetworkScopes}
            {commonVirtualMachineDefinition.columns.identityPermissionActionSeverity}
            {commonVirtualMachineDefinition.columns.vulnerabilityAttackVector}
            {commonVirtualMachineDefinition.columns.virtualMachineStatus}
            {commonVirtualMachineDefinition.columns.workloadAnalysisStatus}
            {commonVirtualMachineDefinition.columns.scanTime}
            {LicensingHelper.isActiveLicenseType(Contract.ApplicationCustomerConfigurationLicensingLicenseType.CnappEnterprise) &&
                commonVirtualMachineDefinition.columns.kubernetesClusterSensor}
            {commonVirtualMachineDefinition.columns.attributes}
            {commonVirtualMachineDefinition.columns.workloadResourceTypeName}
            {_.isNil(vulnerabilityRawId) &&
                <DataTableColumn
                    id="Actions"
                    key="Actions"
                    orderable={false}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.WorkloadResourceModel>) =>
                            <PriorityScanActionCell virtualMachineId={item.id}/>}
                    resizable={false}
                    selectorOptions={{ disabled: true }}/>}
            <DataTableAction>
                <CsvExportButton
                    fileNameOptions={{
                        filtered: !_.isEmpty(filterMapRef.current),
                        prefix: localization.title()
                    }}
                    itemCount={workloadResourcesCount}
                    onClick={
                        async fileNameOptions => {
                            const reportRequestDefinition =
                                new Contract.ReportControllerVirtualMachinesReportRequestDefinition(
                                    getExportFileName(fileNameOptions),
                                    TimeHelper.timeZoneId(),
                                    Contract.TypeNames.ReportControllerVirtualMachinesReportRequestDefinition,
                                    true,
                                    WorkloadResourceModelRequestFiltersHelper.getVirtualMachineFilters(
                                        filterMapRef.current ?? {},
                                        dataTableActionsRef,
                                        vulnerabilityRawId),
                                    getWorkloadResourceModelsRequestSort(sortRef.current));
                            await ExportReportHelper.downloadRemote(reportRequestDefinition);
                        }}/>
            </DataTableAction>
        </DataTable>);
}