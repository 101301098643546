import { Link, Optional, useLocalization, useLocalizeList } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, VulnerabilityResolutionVersion } from "../../../../../../../../../../../../../common";
import { useGetWorkloadResourceOperatingSystemReleaseHistory } from "./useGetWorkloadResourceOperatingSystemReleaseHistory";

export function useWorkloadResourceOperatingSystemRiskRemediationStep() {
    const localizeList = useLocalizeList();
    const getWorkloadResourceOperatingSystemReleaseHistory = useGetWorkloadResourceOperatingSystemReleaseHistory();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.workloadAnalysis.hooks.useWorkloadAnalysisDefinition.hooks.useWorkloadResourceOperatingSystemRiskRemediationStep",
            () => ({
                linux: {
                    withOperatingSystemReleaseNotesLink: "Update the operating system to the latest version. {{historyLink}}",
                    withoutOperatingSystemReleaseNotesLink: "Update the operating system to the latest version"
                },
                windows: {
                    windowsUpdateLink: "Windows Update",
                    withoutResolutionPatches: "Run {{windowsUpdateLink}} to get the latest version of the operating system",
                    withResolutionPatches: "Run {{windowsUpdateLink}} to get the latest version of the operating system or install the following KBs: {{resolutionPatches}}"
                }
            }));

    return (operatingSystemReleaseNotesUrl: Optional<string>, operatingSystemType: Contract.OperatingSystemType, windowsUpdateUrl: string, resolutionPatches?: string[]) => {
        if (operatingSystemType == Contract.OperatingSystemType.Linux) {
            return _.isNil(operatingSystemReleaseNotesUrl)
                ? localization.linux.withoutOperatingSystemReleaseNotesLink()
                : localization.linux.withOperatingSystemReleaseNotesLink({
                    historyLink: getWorkloadResourceOperatingSystemReleaseHistory(operatingSystemReleaseNotesUrl)
                });
        } else {
            const windowsUpdateLink =
                <Link
                    urlOrGetUrl={windowsUpdateUrl}
                    variant="external">
                    {localization.windows.windowsUpdateLink()}
                </Link>;

            if (_.isEmpty(resolutionPatches)) {
                return localization.windows.withoutResolutionPatches({ windowsUpdateLink });
            } else {
                return localization.windows.withResolutionPatches({
                    resolutionPatches:
                        localizeList(
                            _.map(
                                resolutionPatches,
                                resolutionPatch =>
                                    <VulnerabilityResolutionVersion resolutionVersion={resolutionPatch}/>)),
                    windowsUpdateLink
                });
            }
        }
    };
}