import { Link, useExecuteOperation, useLocalization } from "@infrastructure";
import { Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { ConfigurationController, Contract, CustomerConsoleAppUrlHelper, RadioGroup, vulnerabilityModelStore, workloadAnalysisVulnerabilityScoreTypeStore } from "../../../../../../../../../../common";

export function VulnerabilityScoreType() {
    const [{ vulnerabilityScoreType: initialScoreType }] =
        useExecuteOperation(
            VulnerabilityScoreType,
            ConfigurationController.getWorkloadAnalysisVulnerabilityScoreType);

    const [scoreType, setScoreType] = useState(initialScoreType);

    const [loading, setLoading] = useState(false);

    async function updateScoreType(scoreType: Contract.VulnerabilityScoreType) {
        setLoading(true);
        await ConfigurationController.updateWorkloadAnalysisVulnerabilityScoreType(new Contract.ConfigurationControllerUpdateWorkloadAnalysisVulnerabilityScoreTypeRequest(scoreType));
        setScoreType(scoreType);
        vulnerabilityModelStore.clear();
        await workloadAnalysisVulnerabilityScoreTypeStore.notifyAll();
        setLoading(false);
    }

    const localization =
        useLocalization(
            "views.customer.configuration.workloadAnalysis.vulnerabilities.vulnerabilityScoreType.vulnerabilityScoreType",
            () => ({
                [Contract.TypeNames.VulnerabilityScoreType]: {
                    [Contract.VulnerabilityScoreType.Cvss]: "CVSS",
                    [Contract.VulnerabilityScoreType.Vpr]: "VPR"
                },
                description: {
                    documentationLink: "data synchronization",
                    text: "Configure the metric used to calculate the severity of vulnerability-related findings. Changes are reflected after the next {{documentationLink}} in your environment."
                },
                title: "Vulnerability Severity Metrics"
            }));

    const theme = useTheme();
    return (
        <RadioGroup
            description={
                <Typography
                    sx={{
                        paddingTop: theme.spacing(1),
                        whiteSpace: "pre-wrap"
                    }}>
                    {localization.description.text({
                        documentationLink:
                            <Link
                                urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsArchitectureAndDataHandlingHowOftenIsDataSyncedRelativeUrl()}
                                variant="external">
                                {localization.description.documentationLink()}
                            </Link>
                    })}
                </Typography>}
            items={[
                {
                    label: localization[Contract.TypeNames.VulnerabilityScoreType][Contract.VulnerabilityScoreType.Vpr](),
                    value: Contract.VulnerabilityScoreType.Vpr
                },
                {
                    label: localization[Contract.TypeNames.VulnerabilityScoreType][Contract.VulnerabilityScoreType.Cvss](),
                    value: Contract.VulnerabilityScoreType.Cvss
                }
            ]}
            loading={loading}
            selectedValue={scoreType}
            title={localization.title()}
            onChange={updateScoreType}/>);
}