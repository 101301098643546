import { useLocalization } from "@infrastructure";
import { Contract } from "../../../../../common";

export function useGcpAppEngineApplicationResourceStatusTranslator() {
    const localization =
        useLocalization(
            "views.customer.entities.hooks.useGcpAppEngineApplicationResourceStatusTranslator",
            () => ({
                [Contract.TypeNames.GcpAppEngineApplicationResourceStatus]: {
                    [Contract.GcpAppEngineApplicationResourceStatus.Running]: "Serving",
                    [Contract.GcpAppEngineApplicationResourceStatus.Stopped]: "Stopped"
                }
            }));
    return (status: Contract.GcpAppEngineApplicationResourceStatus) =>
        localization[Contract.TypeNames.GcpAppEngineApplicationResourceStatus][status]();
}