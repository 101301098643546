﻿import { useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { useEntityTypeNameTranslator } from "../../../../../../../hooks";

type SensitiveProps = {
    entityTypeName: string;
    includeServiceName: boolean;
};

export function Sensitive({ entityTypeName, includeServiceName }: SensitiveProps) {
    const entityTypeNameTranslator = useEntityTypeNameTranslator();
    const localization =
        useLocalization(
            "common.customRiskPolicy.entityMultiSelect.condition.sensitive",
            () => ({
                title: "Sensitive {{translatedEntityTypeName}}"
            }));

    return (
        <Typography>
            {localization.title({
                translatedEntityTypeName:
                    entityTypeNameTranslator(
                        entityTypeName,
                        {
                            count: 0,
                            includeServiceName,
                            variant: "text"
                        })
            })}
        </Typography>);
}