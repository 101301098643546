import { DataTableColumn, DataTableColumnRenderProps, DataTableSortType, useExecuteOperation, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { useCommonCustomSectionsAndDescriptionDefinition } from "../../..";
import { ActionHelper, Contract, EntitiesCell, Entity, EntityController, EntityFilter, entityModelStore, InlineEntities, InlinePermissionActions, TypeHelper, useTableDefinition } from "../../../../../../../../../../../../common";
import { Table } from "../../../../components";

export function useAzurePrincipalResourcePermissionRiskDefinition(riskModel: Contract.RiskModel) {
    const principalResourcePermissionRiskModel = riskModel as Contract.AzurePrincipalResourcePermissionRiskModel;
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.custom.useAzurePrincipalResourcePermissionRiskDefinition",
            () => ({
                violation: [
                    "{{principals}} has {{permissionActions}} on {{resources}}",
                    "{{principals}} have {{permissionActions}} on {{resources}}"
                ]
            }));
    return useCommonCustomSectionsAndDescriptionDefinition(
        localization.violation(
            principalResourcePermissionRiskModel.principalIds.length,
            {
                permissionActions:
                    <InlinePermissionActions
                        permissionActions={principalResourcePermissionRiskModel.permissionActions}
                        variant="itemOrItemCountAndType"/>,
                principals:
                    <InlineEntities
                        entityIdsOrModels={principalResourcePermissionRiskModel.principalIds}
                        entityTypeName={Contract.TypeNames.AadDirectoryPrincipal}
                        variant="itemOrItemCountAndType"/>,
                resources:
                    <InlineEntities
                        entityIdsOrModels={principalResourcePermissionRiskModel.resourceIds}
                        entityTypeName={Contract.TypeNames.AzureResource}
                        variant="itemOrItemCountAndType"/>
            }),
        riskModel,
        "violations",
        <ViolationTable risk={principalResourcePermissionRiskModel.risk}/>);
}

type ViolationTableProps = {
    risk: Contract.AzurePrincipalResourcePermissionRisk;
};

function ViolationTable({ risk }: ViolationTableProps) {
    const principalModels =
        entityModelStore.useGet(
            _.map(
                risk.principalResourcePermissionItems,
                riskItem => riskItem.principalId));
    const [{ principalIdToModelAccessMap: serviceIdentityIdToModelAccessMap }] =
        useExecuteOperation(
            [ViolationTable, risk.id],
            async () =>
                await EntityController.getPrincipalIdToModelAccessMap(
                    new Contract.EntityControllerGetPrincipalIdToAccessMapRequest(
                        _(principalModels).
                            filter(
                                principalModel =>
                                    TypeHelper.extendOrImplement(
                                        principalModel.entity.typeName,
                                        Contract.TypeNames.IServiceIdentity)).
                            map(serviceIdentityModel => serviceIdentityModel.id).
                            value())));

    const serviceIdentityIdToOriginatorEntityIdsMap =
        useMemo(
            () =>
                _.mapValues(
                    serviceIdentityIdToModelAccessMap,
                    serviceIdentityModelAccess => _.as<Contract.ServiceIdentityModelAccess>(serviceIdentityModelAccess).originatorEntityIds),
            [serviceIdentityIdToModelAccessMap]);
    const originatorEntityModels =
        entityModelStore.useGet(
            _(serviceIdentityIdToOriginatorEntityIdsMap).
                values().
                flatMap().
                value());
    const resourceModels =
        entityModelStore.useGet(
            _.map(
                risk.principalResourcePermissionItems,
                riskItem => riskItem.resourceId));
    const roleAssignmentResourceModels =
        entityModelStore.useGet(
            _.flatMap(
                risk.principalResourcePermissionItems,
                riskItem => riskItem.roleAssignmentResourceIds));
    const items =
        useMemo(
            () => {
                const originatorEntityModelMap =
                    _.keyBy(
                        originatorEntityModels,
                        originatorEntityModel => originatorEntityModel.id);
                const principalModelMap =
                    _.keyBy(
                        principalModels,
                        principalModel => principalModel.id);
                const resourceModelMap =
                    _.keyBy(
                        resourceModels,
                        resourceModel => resourceModel.id);
                const roleAssignmentResourceModelMap =
                    _.keyBy(
                        roleAssignmentResourceModels,
                        roleAssignmentResourceModel => roleAssignmentResourceModel.id);

                return _.map(
                    risk.principalResourcePermissionItems,
                    riskItem => {
                        const principalModel = principalModelMap[riskItem.principalId];
                        const originatorEntityIds = serviceIdentityIdToOriginatorEntityIdsMap[principalModel.id] ?? [];
                        return new ViolationTableItem(
                            riskItem,
                            originatorEntityIds,
                            _.map(
                                originatorEntityIds ?? [],
                                originatorEntityId => originatorEntityModelMap[originatorEntityId]),
                            principalModel,
                            resourceModelMap[riskItem.resourceId],
                            _.map(
                                riskItem.roleAssignmentResourceIds,
                                roleAssignmentResourceId => roleAssignmentResourceModelMap[roleAssignmentResourceId]));
                    });
            },
            []);

    const tableDefinition =
        useTableDefinition(
            items,
            {
                [ViolationTableColumnId.Originators]: {
                    getFilterValue: item => item.originatorEntityIds,
                    getSortValue: item => item.originatorEntityIds.length
                },
                [ViolationTableColumnId.PermissionActions]: {
                    getFilterValue:
                        item =>
                            _(item.riskItem.permissionActions).
                                map(ActionHelper.getRawValue).
                                uniq().
                                value(),
                    getSortValue: item => item.riskItem.permissionActions.length
                },
                [ViolationTableColumnId.Principal]: {
                    getFilterValue: item => item.principalModel.entity.id,
                    getSortValue: item => item.principalModel.entity.displayName
                },
                [ViolationTableColumnId.Resource]: {
                    getFilterValue: item => item.resourceModel.entity.id,
                    getSortValue: item => item.resourceModel.entity.displayName
                },
                [ViolationTableColumnId.RoleAssignmentResources]: {
                    getFilterValue: item => item.riskItem.roleAssignmentResourceIds,
                    getSortValue: item => item.riskItem.roleAssignmentResourceIds.length
                }
            },
            ViolationTableColumnId.Principal);

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.custom.useAzurePrincipalResourcePermissionRiskDefinition.violationTable",
            () => ({
                columns: {
                    originators: "Originators",
                    permissionActions: "Permissions",
                    principal: "Principal",
                    resource: "Resource",
                    roleAssignmentResource: "Role Assignments"
                }
            }));

    return (
        <Table
            fetchItems={tableDefinition.filterAndSortItems}
            getItemId={(item: ViolationTableItem) => item.id}
            sortEnabled={true}>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.Principal]}
                                placeholder={localization.columns.principal()}/>
                    }
                }}
                id={ViolationTableColumnId.Principal}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <Entity
                            entityIdOrModel={item.principalModel}
                            variant="iconTextTypeTenantTags"/>}
                title={localization.columns.principal()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.Originators]}
                                placeholder={localization.columns.originators()}/>
                    }
                }}
                id={ViolationTableColumnId.Originators}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.originatorEntityModels}
                            entityTypeName={Contract.TypeNames.AzureResource}
                            entityVariant="iconTextTypeTenant"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.originators()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.RoleAssignmentResources]}
                                placeholder={localization.columns.roleAssignmentResource()}/>
                    }
                }}
                id={ViolationTableColumnId.RoleAssignmentResources}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <EntitiesCell
                            entityIdsOrModels={item.roleAssignmentResourceModels}
                            entityTypeName={Contract.TypeNames.AzureAuthorizationRoleAssignmentResource}
                            entityVariant="iconTextTypeTenant"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.roleAssignmentResource()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                groupItemTitle={true}
                                placeholder={localization.columns.permissionActions()}>
                                {_.map(
                                    tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.PermissionActions],
                                    permissionAction =>
                                        <ValuesFilterItem
                                            key={permissionAction}
                                            title={ActionHelper.getRawValue(permissionAction)}
                                            value={permissionAction}/>)}
                            </ValuesFilter>
                    }
                }}
                id={ViolationTableColumnId.PermissionActions}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <InlinePermissionActions
                            permissionActions={item.riskItem.permissionActions}
                            variant="itemOrItemCountAndType"/>}
                sortOptions={{ type: DataTableSortType.Numeric }}
                title={localization.columns.permissionActions()}/>
            <DataTableColumn
                filterOptions={{
                    itemOrItems: {
                        element:
                            <EntityFilter
                                entityIdsOrSearchableReferences={tableDefinition.columnIdToItemValuesMap[ViolationTableColumnId.Resource]}
                                placeholder={localization.columns.resource()}/>
                    }
                }}
                id={ViolationTableColumnId.Resource}
                render={
                    ({ item }: DataTableColumnRenderProps<ViolationTableItem>) =>
                        <Entity
                            entityIdOrModel={item.resourceModel}
                            variant="iconTextTypeTenantTags"/>}
                title={localization.columns.resource()}/>
        </Table>);
}

enum ViolationTableColumnId {
    Originators = "originators",
    PermissionActions = "permissionActions",
    Principal = "principal",
    Resource = "resource",
    RoleAssignmentResources = "roleAssignmentResources"
}

class ViolationTableItem {
    public id: string;

    constructor(
        public riskItem: Contract.AzurePrincipalResourcePermissionRiskItem,
        public originatorEntityIds: string[],
        public originatorEntityModels: Contract.EntityModel[],
        public principalModel: Contract.EntityModel,
        public resourceModel: Contract.EntityModel,
        public roleAssignmentResourceModels: Contract.EntityModel[]) {
        this.id = `${principalModel.id}-${resourceModel.id}`;
    }
}