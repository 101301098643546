import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function InboundIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                clipRule="evenodd"
                d="M11.4281 15.0007L14.5311 15.6737C14.6579 15.7012 14.7337 15.8236 14.7006 15.947C14.6946 15.9692 14.6852 15.9906 14.6728 16.0104L12.9852 18.7C12.9162 18.8099 12.77 18.8483 12.6586 18.7857C12.6154 18.7615 12.582 18.7243 12.5631 18.6795L11.1477 15.3169C11.0991 15.2013 11.1582 15.066 11.2799 15.0148C11.327 14.995 11.379 14.99 11.4281 15.0007Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M12.7773 16.3562C12.9712 16.7866 12.7795 17.2926 12.3491 17.4865L4.1504 21.1798C3.72001 21.3737 3.21394 21.182 3.02006 20.7516C2.82618 20.3212 3.01791 19.8151 3.4483 19.6212L11.647 15.9279C12.0774 15.7341 12.5834 15.9258 12.7773 16.3562Z"
                fillRule="evenodd"/>
            <path d="M18.5812 15.5947C20.4693 15.5947 22 14.064 22 12.1759C22 10.2877 20.4693 8.75705 18.5812 8.75705C16.693 8.75705 15.1624 10.2877 15.1624 12.1759C15.1624 14.064 16.693 15.5947 18.5812 15.5947Z"/>
            <path
                clipRule="evenodd"
                d="M12.2565 12.0038C12.2565 12.4758 11.8738 12.8585 11.4017 12.8585H2.8547C2.38266 12.8585 2 12.4758 2 12.0038C2 11.5317 2.38266 11.1491 2.8547 11.1491H11.4017C11.8738 11.1491 12.2565 11.5317 12.2565 12.0038Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M11.6119 10.1679L14.2107 11.992C14.3169 12.0666 14.3394 12.2088 14.261 12.3096C14.2468 12.3278 14.2299 12.3439 14.2107 12.3573L11.6119 14.1815C11.5057 14.2561 11.356 14.2347 11.2776 14.1338C11.2472 14.0948 11.2308 14.0475 11.2308 13.9989V10.3505C11.2308 10.2251 11.3378 10.1234 11.4698 10.1234C11.521 10.1234 11.5707 10.139 11.6119 10.1679Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M12.7775 7.89927C12.9713 7.46888 12.7796 6.96281 12.3492 6.76893L4.15054 3.07563C3.72015 2.88175 3.21409 3.07348 3.02021 3.50387C2.82633 3.93425 3.01806 4.44032 3.44844 4.6342L11.6471 8.3275C12.0775 8.52138 12.5836 8.32965 12.7775 7.89927Z"
                fillRule="evenodd"/>
            <path
                clipRule="evenodd"
                d="M11.4284 9.25641L14.5314 8.58333C14.6582 8.55583 14.734 8.4335 14.7009 8.31009C14.6949 8.28783 14.6855 8.26645 14.6731 8.24663L12.9855 5.55708C12.9165 5.4472 12.7703 5.40882 12.6589 5.47137C12.6157 5.49559 12.5823 5.53282 12.5634 5.57759L11.148 8.94022C11.0994 9.05582 11.1585 9.19105 11.2802 9.24226C11.3273 9.2621 11.3793 9.26706 11.4284 9.25641Z"
                fillRule="evenodd"/>
        </SvgIcon>);
}