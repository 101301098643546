﻿import { useLocalization } from "@infrastructure";
import _ from "lodash";
import React, { useMemo } from "react";
import { Contract, entityModelStore, InlineEntities } from "../../../../../../../../../common";
import { RiskDefinitionContextItem } from "../../../utilities";

export function useGetEntitiesRiskContext() {
    return useMemo(
        () => useEntitiesRiskContext,
        []);
}

function useEntitiesRiskContext(entityIdsOrModels: string[] | Contract.EntityModel[], entityTypeName: string) {
    const entityModels =
        entityModelStore.useGet(
            _.isString(entityIdsOrModels[0])
                ? entityIdsOrModels as string[]
                : undefined) ?? entityIdsOrModels as Contract.EntityModel[];

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.useGetEntitiesRiskContext",
            () => ({
                sensitive: [
                    "{{resources}} is marked as sensitive",
                    "{{resources}} are marked as sensitive"]
            }));
    const sensitiveEntityModels =
        useMemo(
            () =>
                _.filter(
                    entityModels,
                    entityModel => entityModel.entityConfiguration?.sensitive === true),
            [entityModels]);

    return {
        sensitive:
            _.isEmpty(sensitiveEntityModels)
                ? undefined
                : new RiskDefinitionContextItem(
                    localization.sensitive(
                        _.size(sensitiveEntityModels),
                        {
                            resources:
                                <InlineEntities
                                    entityIdsOrModels={sensitiveEntityModels}
                                    entityTypeName={entityTypeName}
                                    variant="itemCountAndType"/>
                        }))
    };
}