import _ from "lodash";
import React, { memo, useEffect, useMemo } from "react";
import { AnalyticsContext, browserHistory, clearOperations, getUrlQueryParameters, Loading, makeContextProvider, map, MouseClickArea, MouseClickAreaScope, removeUrlQueryParameters, setUrlRoute, useEvent, useExecuteOperation, useRoute, useSetApplicationContext, useUniqueKey, VerticalFillGrid } from "@infrastructure";
import { ApiController, ApplicationHelper, ApplicationView, Contract, CustomerConsoleAppUrlHelper, Customers, Layout, scopeNodeModelStore, SignIn, SupportFrame, tenantModelStore, useApplicationViewRoute, useGetSelectedScopeHasData, useSelectedScopeId } from "../../common";
import { Access, Administration, AuditEvents, Code, Compliance, Configuration, Dashboard, DataAnalysis, Entities, Events, GraphApiExplorer, Kubernetes, Reports, RiskPolicies, Risks, Scopes, Sidebar, SideView, SignInError, Topbar, UdmObjects, useSideViewContextProvider, WorkloadAnalysis } from "./components";
import { CustomerView } from "./hooks";

class CustomerContext {
    constructor(
        public reloadView: () => void,
        public scopeIdInitialized: boolean) {
    }
}

export const [useCustomerContext, useSetCustomerContext, useCustomerContextProvider] = makeContextProvider<CustomerContext>();

export function Customer() {
    ApiController.initialize(Contract.ConsoleAppType.Customer);
    const { view } = useRoute(`/${ApplicationView.Customer}/{view}`);

    return map(
        view,
        {
            [CustomerView.Customers]: () => <Customers consoleAppType={Contract.ConsoleAppType.Customer}/>,
            [CustomerView.SignIn]: () => <SignIn consoleAppType={Contract.ConsoleAppType.Customer}/>,
            [CustomerView.SignInError]: () => <SignInError/>
        },
        () => <CoreMemo/>);
}

const CoreMemo = memo(Core);

function Core() {
    useExecuteOperation(
        Customer,
        () => ApplicationHelper.initialize(Contract.ConsoleAppType.Customer));

    const scopeNodeMap =
        scopeNodeModelStore.useGetScopeNodeMap(
            undefined,
            true);

    const getSelectedScopeHasData = useGetSelectedScopeHasData();
    const filteredActiveTenants = tenantModelStore.useGetFilteredActiveTenants();
    const { selectedScopeId } = useSelectedScopeId();

    const [viewRoute, setViewRoute] =
        useApplicationViewRoute(
            CustomerConsoleAppUrlHelper.getTemplatePath(),
            _.values(CustomerView),
            CustomerView.Dashboard,
            parameters => !_.isNil(scopeNodeMap[parameters.scopeId]),
            () => {
                const parts = window.location.pathname.split("/");
                const queryParams = window.location.search;
                const hash = window.location.hash;
                for (const part of parts) {
                    const decodedPart = decodeURIComponent(part);
                    if (!_.isNil(scopeNodeMap[decodedPart])) {
                        const newUrl =
                            _(window.location.pathname).
                                replace(`/${part}`, "").
                                replace(`/${ApplicationView.Customer}/`, `/${ApplicationView.Customer}/${part}/`);
                        if (newUrl !== window.location.pathname) {
                            browserHistory.replace(newUrl + queryParams + hash);
                        }
                        return;
                    }
                }

                browserHistory.replace(
                    _.replace(
                        window.location.pathname,
                        `/${ApplicationView.Customer}/`,
                        `/${ApplicationView.Customer}/${encodeURIComponent(selectedScopeId)}/`) + queryParams + hash);
            });

    const { view } = viewRoute;

    const setApplicationContext = useSetApplicationContext();
    const [viewKey, updateViewKey] = useUniqueKey();

    useEffect(
        () => {
            const { graphApiExplorer } = getUrlQueryParameters<{ graphApiExplorer?: string }>();
            if (!_.isNil(graphApiExplorer)) {
                removeUrlQueryParameters("graphApiExplorer");
                setUrlRoute(CustomerConsoleAppUrlHelper.getGraphqlRelativeUrl());
            }

            setApplicationContext(
                context => ({
                    ...context,
                    customerActionAvailable: true
                }));
        },
        []);

    useEffect(
        () => {
            setApplicationContext(
                context => ({
                    ...context,
                    permittedContent: !_.isEmpty(filteredActiveTenants),
                    selectedScopeHasData: getSelectedScopeHasData(filteredActiveTenants)
                }));
        },
        [filteredActiveTenants]);

    const [{ scopeIdInitialized }, , ContextProvider] =
        useCustomerContextProvider(
            () =>
                new CustomerContext(
                    () => {
                        clearOperations();
                        updateViewKey();
                    },
                    false),
            []);

    const [registerDataChange, triggerDataChange] = useEvent<(type: Contract.CustomerConsoleAppSideViewType | Contract.TypeNames) => void>();
    const [, , SideViewContextProvider] =
        useSideViewContextProvider(
            () => (
                {
                    items: [],
                    registerDataChange,
                    triggerDataChange
                }
            ));

    return (
        <ContextProvider>
            <MouseClickArea onClick={() => window.location.hash = ""}>
                <SupportFrame>
                    <Layout
                        sidebar={
                            <Sidebar
                                setViewRoute={setViewRoute}
                                viewRoute={viewRoute}/>}
                        topbar={<Topbar consoleAppType={Contract.ConsoleAppType.Customer}/>}>
                        <SideViewContextProvider>
                            <AnalyticsContext context="sideView">
                                <MouseClickAreaScope disabled={true}>
                                    <SideView/>
                                </MouseClickAreaScope>
                            </AnalyticsContext>
                            <VerticalFillGrid>
                                <AnalyticsContext context={view}>
                                    {useMemo(
                                        () =>
                                            <Loading
                                                key={viewKey}
                                                loading={!scopeIdInitialized}>
                                                {map(
                                                    view,
                                                    {
                                                        [CustomerView.Access]: () => <Access/>,
                                                        [CustomerView.Administration]: () => <Administration/>,
                                                        [CustomerView.AuditEvents]: () => <AuditEvents/>,
                                                        [CustomerView.Code]: () => <Code/>,
                                                        [CustomerView.Compliance]: () => <Compliance/>,
                                                        [CustomerView.Configuration]: () => <Configuration/>,
                                                        [CustomerView.Dashboard]: () => <Dashboard/>,
                                                        [CustomerView.DataAnalysis]: () => <DataAnalysis/>,
                                                        [CustomerView.Entities]: () => <Entities/>,
                                                        [CustomerView.Events]: () => <Events variant="view"/>,
                                                        [CustomerView.Graphql]: () => <GraphApiExplorer/>,
                                                        [CustomerView.Kubernetes]: () => <Kubernetes/>,
                                                        [CustomerView.Reports]: () => <Reports/>,
                                                        [CustomerView.RiskPolicies]: () => <RiskPolicies/>,
                                                        [CustomerView.Risks]: () => <Risks/>,
                                                        [CustomerView.Scopes]: () => <Scopes/>,
                                                        [CustomerView.UdmObjects]: () => <UdmObjects/>,
                                                        [CustomerView.WorkloadAnalysis]: () => <WorkloadAnalysis/>
                                                    })}
                                            </Loading>,
                                        [view, viewKey, scopeIdInitialized])}
                                </AnalyticsContext>
                            </VerticalFillGrid>
                        </SideViewContextProvider>
                    </Layout>
                </SupportFrame>
            </MouseClickArea>
        </ContextProvider>);
}