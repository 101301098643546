﻿import { DataTableColumn, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import { Dictionary } from "lodash";
import React from "react";
import { useGcpCommonScopeResourceDefinition, useGcpCommonServiceAccountOriginatorScopeResourceDefinition } from ".";
import { EntityTableDefinition } from "../../..";
import { Contract, ItemSelectionHelper, TimeRangeHelper } from "../../../../../../../../../../common";
import { DefinitionData } from "../../../../Table";

export function useGcpCloudBuildBuildTriggerDefinition(definitionData: DefinitionData) {
    const commonServiceAccountOriginatorScopeResourceDefinition = useGcpCommonServiceAccountOriginatorScopeResourceDefinition(definitionData);
    const commonScopeResourceDefinition = useGcpCommonScopeResourceDefinition(definitionData);

    function getEnabled(buildTriggerModel: Contract.GcpCloudBuildBuildTriggerModel) {
        return (buildTriggerModel.entity as Contract.GcpCloudBuildBuildTrigger).enabled
            ? localization.columns.enabled.true()
            : localization.columns.enabled.false();
    }

    const localization =
        useLocalization(
            "views.customer.entities.table.hooks.useDefinition.hooks.gcp.useGcpCloudBuildBuildTriggerDefinition",
            () => ({
                columns: {
                    enabled: {
                        false: "Disabled",
                        title: "Status",
                        true: "Enabled"
                    }
                }
            }));
    return new EntityTableDefinition(
        [
            commonScopeResourceDefinition.columns.tenantColumn,
            commonScopeResourceDefinition.columns.creationTimeColumn,
            commonScopeResourceDefinition.columns.creatorIdentityCsvColumn,
            commonScopeResourceDefinition.columns.creatorOriginatorEntityCsvColumn,
            commonScopeResourceDefinition.columns.regionColumn,
            commonScopeResourceDefinition.columns.regionLocationColumn,
            <DataTableColumn
                exportOptions={{
                    getItem:
                        (item: Contract.GcpCloudBuildBuildTriggerModel) => ({
                            [localization.columns.enabled.title()]: getEnabled(item)
                        })
                }}
                filterOptions={{
                    itemOrItems: {
                        element:
                            <ValuesFilter
                                placeholder={localization.columns.enabled.title()}
                                sorted={false}>
                                <ValuesFilterItem
                                    title={localization.columns.enabled.true()}
                                    value={true}/>
                                <ValuesFilterItem
                                    title={localization.columns.enabled.false()}
                                    value={false}/>
                            </ValuesFilter>
                    }
                }}
                id={Contract.EntityModelProperty.GcpCloudBuildBuildTriggerEnabled}
                itemProperty={getEnabled}
                key={Contract.EntityModelProperty.GcpCloudBuildBuildTriggerEnabled}
                title={localization.columns.enabled.title()}/>,
            commonServiceAccountOriginatorScopeResourceDefinition.columns.serviceAccountColumn,
            commonScopeResourceDefinition.columns.tagsColumn,
            commonScopeResourceDefinition.columns.attributesColumn,
            commonScopeResourceDefinition.columns.openRiskedEntityRisksCsvColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskHighestSeverityColumn,
            commonScopeResourceDefinition.columns.openRelatedEntityRiskTypeIdentifiersColumn
        ],
        definitionData.entityModelFiltersPromise,
        (filterMap: Dictionary<any>, limit: number, propertyFilterMap: Dictionary<any>, requestSort: Contract.EntityControllerGetEntityModelPageRequestSort, skip: number) =>
            new Contract.EntityControllerGetEntityModelPageGcpCloudBuildBuildTriggerRequest(
                new Contract.EntityControllerGetEntityModelPageGcpCloudBuildBuildTriggerRequestFilters(
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityAttributes]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityId]),
                    ItemSelectionHelper.toItemSelectionFromPropertyFilterMap(propertyFilterMap),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegion]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.EntityRegionLocation]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskHighestSeverity]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.OpenRelatedEntityRiskTypeIdentifiers]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.TenantId]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.ResourceCreationTime]),
                    TimeRangeHelper.toTimeRangeSelectionFromTimeRangeFilterSelection(definitionData.getFiltersTime(), filterMap[Contract.EntityModelProperty.GcpResourceUpdateTime]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceAccessLevel]),
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpScopeResourceRawId]),
                    ItemSelectionHelper.toBooleanFromValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpCloudBuildBuildTriggerEnabled])),
                limit,
                skip,
                requestSort,
                new Contract.EntityControllerGetEntityModelPageEntityRequestTag(ItemSelectionHelper.toResourceTagItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.ResourceTags])),
                new Contract.EntityControllerGetEntityModelPageGcpScopeResourceRequestServiceAccount(ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[Contract.EntityModelProperty.GcpServiceAccountOriginatorScopeResourceServiceAccount]))));
}