import { AnalyticsContext, AnalyticsEventActionType, browserHashHistory, createAdoptedStyleSheet, EventHandlerRegister, getHashQueryParameters, getRouteResult, HashRouteFreezeProvider, KeyCodes, Loading, makeContextProvider, Optional, RouteContext, RouteType, Shadows, StringHelper, useEffectEvent, useRouteContextProvider, useTrackAnalytics, useWindowEventEffect } from "@infrastructure";
import { Box, Slide, Stack } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Contract, StorageHelper, useTheme } from "../../../../common";
import { SideViewActions, ViewItem } from "./components";

interface SideViewContext {
    items: SideViewItem[];
    registerDataChange: EventHandlerRegister<(type: Contract.CustomerConsoleAppSideViewType | Contract.TypeNames) => void>;
    triggerDataChange: (type: Contract.CustomerConsoleAppSideViewType | Contract.TypeNames) => void;
}

export class SideViewItem {
    constructor(
        public additionalParts: Optional<string[]>,
        public category: Optional<string>,
        public id: string,
        public type: ItemType,
        public view: Optional<string>,
        public queryParameters: Optional<Record<string, string>>) {
    }
}

type ItemType = "auditEvent" | "codeScan" | "codeScanRisk" | "compliance" | "dataAnalysisResourceDataSegment" | "documentation" | "entity" | "event" | "kubernetesClusterAdmissionControllerEvent" | "maliciousFile" | "risk" | "riskPolicies" | "scope" | "vulnerability" | "workloadAnalysisCodeScan";

export const CustomerSideViewItemType = {
    [Contract.CustomerConsoleAppSideViewType.AuditEvent]: "auditEvent",
    [Contract.CustomerConsoleAppSideViewType.CodeScan]: "codeScan",
    [Contract.CustomerConsoleAppSideViewType.CodeScanRisk]: "codeScanRisk",
    [Contract.CustomerConsoleAppSideViewType.Compliance]: "compliance",
    [Contract.CustomerConsoleAppSideViewType.DataAnalysisResourceDataSegment]: "dataAnalysisResourceDataSegment",
    [Contract.CustomerConsoleAppSideViewType.Documentation]: "documentation",
    [Contract.CustomerConsoleAppSideViewType.Entity]: "entity",
    [Contract.CustomerConsoleAppSideViewType.Event]: "event",
    [Contract.CustomerConsoleAppSideViewType.KubernetesClusterAdmissionControllerEvent]: "kubernetesClusterAdmissionControllerEvent",
    [Contract.CustomerConsoleAppSideViewType.MaliciousFile]: "maliciousFile",
    [Contract.CustomerConsoleAppSideViewType.Risk]: "risk",
    [Contract.CustomerConsoleAppSideViewType.RiskPolicies]: "riskPolicies",
    [Contract.CustomerConsoleAppSideViewType.Scope]: "scope",
    [Contract.CustomerConsoleAppSideViewType.Vulnerability]: "vulnerability",
    [Contract.CustomerConsoleAppSideViewType.WorkloadAnalysisCodeScan]: "workloadAnalysisCodeScan"
} satisfies { [key in Contract.CustomerConsoleAppSideViewType]: ItemType };

export const [useSideViewContext, useSetSideViewContext, useSideViewContextProvider] = makeContextProvider<SideViewContext>();

const [styleSheet] = createAdoptedStyleSheet();
const getSideViewWidth = () => Number(getComputedStyle(document.documentElement).getPropertyValue("--side-view-width").slice(0, -2));
const resetSideViewWidth = () => styleSheet.replace("");
const setSideViewWidth = (width: number) => styleSheet.replace(`:root { --side-view-width: ${width}px }`);

export function SideView() {
    const setSideViewContext = useSetSideViewContext();
    const { items } = useSideViewContext();
    const [fullScreen, setFullScreen] = useState(StringHelper.isTrue(StorageHelper.customerSideViewFullWidth.getValue()));
    const pointerOutside = useRef(true);
    function setPointerOutside(_pointerOutside: boolean) {
        pointerOutside.current = _pointerOutside;
    }
    const open = !_.isEmpty(items);

    const urlChanged =
        useEffectEvent(
            (reset: boolean = false) => {
                const { additionalParts, category, itemId, itemType, match, view } = getRouteResult(RouteType.Hash, "{itemType}/{itemId}/{category}/{view}");
                const queryParameters = getHashQueryParameters() as Record<string, string>;

                if (!match) {
                    resetSideView();
                    return;
                }

                if (!_(CustomerSideViewItemType).
                    values().
                    includes(itemType as ItemType)) {
                    window.location.hash = "";
                    return;
                }

                const lastItem = _.last(items);
                if (lastItem?.id === itemId) {
                    setSideViewContext(
                        context => ({
                            ...context,
                            items:
                                _(items).
                                    slice(0, -1).
                                    concat(
                                        {
                                            ...lastItem,
                                            additionalParts,
                                            category,
                                            queryParameters,
                                            view
                                        }).
                                    value()
                        }));
                    return;
                }

                const item =
                    new SideViewItem(
                        additionalParts,
                        category,
                        itemId,
                        itemType as ItemType,
                        view,
                        queryParameters);

                const newItems =
                    pointerOutside.current || reset
                        ? [item]
                        : [...items, item];

                setSideViewContext(
                    context => ({
                        ...context,
                        items: newItems
                    }));
            });

    useEffect(
        () => {
            urlChanged();
            return browserHashHistory.listen(update => urlChanged(update.action === "POP"));
        },
        [urlChanged]);

    const trackAnalytics = useTrackAnalytics();
    const [fullScreenExit, setFullScreenExit] = useState(false);
    function resetSideView() {
        trackAnalytics(AnalyticsEventActionType.SideViewClose);

        window.location.hash = "";
        setSideViewContext(
            context => ({
                ...context,
                items: []
            }));
        setFullScreenExit(false);
    }

    const handleMouseUp =
        () => {
            document.removeEventListener("mouseup", handleMouseUp, true);
            document.removeEventListener("mousemove", handleMouseMove, true);
        };

    const handleMouseMove =
        (event: MouseEvent) => {
            event.preventDefault();

            const { offsetWidth } = document.body;
            const newWidth = offsetWidth - event.clientX - 18;
            const maxWidth = offsetWidth - 250;

            if (newWidth > maxWidth) {
                setFullScreen(true);
                return;
            }

            const width = getSideViewWidth();
            if (newWidth > 700 || newWidth > width) {
                requestAnimationFrame(() => setSideViewWidth(newWidth));
            }
        };

    useWindowEventEffect(
        "resize",
        () => { resetSideViewWidth(); });

    useWindowEventEffect(
        "keydown",
        (keyboardEvent: KeyboardEvent) => {
            if (open && keyboardEvent.keyCode === KeyCodes.Escape) {
                resetSideView();
            }
        });

    const [, , ContextProvider] = useRouteContextProvider(() => new RouteContext(RouteType.Hash));
    const theme = useTheme();
    return (
        <ContextProvider>
            <Slide
                direction="left"
                in={open}
                mountOnEnter={true}
                unmountOnExit={true}>
                <Stack
                    sx={[
                        {
                            background: theme.palette.background.paper,
                            borderLeft:
                                fullScreen
                                    ? 0
                                    : theme.border.primary,
                            bottom: 0,
                            boxShadow: theme.shadows[Shadows.SideNavigation],
                            contain: "layout size",
                            height: "100%",
                            minWidth: "30%",
                            position: "absolute",
                            right: 0,
                            width: "var(--side-view-width)",
                            willChange: "width",
                            zIndex: theme.zIndex.drawer
                        },
                        fullScreen && {
                            "@keyframes enter-full-screen": { to: { width: "100%" } },
                            animation: `enter-full-screen ${theme.transitions.duration.enteringScreen}ms ${theme.transitions.easing.easeOut} forwards`
                        },
                        fullScreenExit && !fullScreen && {
                            "@keyframes exit-full-screen": { from: { width: "100%" } },
                            animation: `exit-full-screen ${theme.transitions.duration.leavingScreen}ms ${theme.transitions.easing.sharp}`
                        }
                    ]}
                    onMouseEnter={() => setPointerOutside(false)}
                    onMouseLeave={() => setPointerOutside(true)}>
                    <Stack
                        direction="row"
                        gap={0.75}
                        sx={{
                            ":after, :before": {
                                borderLeft: `1px solid ${theme.palette.text.secondary}`,
                                content: "''",
                                height: theme.px(35)
                            },
                            alignItems: "center",
                            backgroundColor: theme.palette.background.paper,
                            border: theme.border.primary,
                            borderRadius: "100px",
                            bottom: 0,
                            boxShadow: theme.shadows[Shadows.SideNavigation],
                            cursor: "ew-resize",
                            height: "60px",
                            justifyContent: "center",
                            left: "-30px",
                            position: "absolute",
                            top: "calc(50% - 30px)",
                            width: "22px",
                            zIndex: theme.zIndex.drawer
                        }}
                        onMouseDown={
                            event => {
                                document.addEventListener("mouseup", handleMouseUp, true);
                                document.addEventListener("mousemove", handleMouseMove, true);
                                event.preventDefault();
                            }}/>
                    {_.map(
                        items,
                        (item, index) =>
                            <Box
                                key={item.id}
                                sx={{
                                    backgroundColor: theme.palette.background.paper,
                                    contentVisibility:
                                        index < items.length - 2
                                            ? "hidden"
                                            : undefined,
                                    height: "100%",
                                    inset: 0,
                                    position: "absolute"
                                }}>
                                <HashRouteFreezeProvider freeze={index < _.size(items) - 1}>
                                    <Container
                                        backButton={_.size(items) > 1}
                                        fullScreen={fullScreen}
                                        item={item}
                                        resetSideView={resetSideView}
                                        setFullScreen={
                                            nextFullScreen => {
                                                setFullScreenExit(!nextFullScreen);
                                                setFullScreen(nextFullScreen);
                                            }}
                                        setPointerOutside={setPointerOutside}/>
                                </HashRouteFreezeProvider>
                            </Box>)}
                </Stack>
            </Slide>
        </ContextProvider>);
}

type ContainerProps = {
    backButton: boolean;
    fullScreen: boolean;
    item: SideViewItem;
    resetSideView: () => void;
    setFullScreen: (fullScreen: boolean) => void;
    setPointerOutside: (pointerOutside: boolean) => void;
};

export function Container({ backButton, fullScreen, item, resetSideView, setFullScreen, setPointerOutside }: ContainerProps) {
    const theme = useTheme();
    const [actionsContainer, setActionsContainer] = useState<HTMLDivElement | null>(null);
    return (
        <Stack
            sx={{
                contain: "layout size",
                height: "100%"
            }}>
            <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                    height: theme.spacing(2.25),
                    margin: theme.spacing(2, 2, 2.5)
                }}>
                <SideViewActions
                    backButton={backButton}
                    fullScreen={fullScreen}
                    resetSideView={resetSideView}
                    setFullScreen={setFullScreen}
                    setPointerOutside={setPointerOutside}/>
                <Box ref={setActionsContainer}/>
            </Stack>
            <Stack
                sx={{
                    height: "100%",
                    overflowY: "auto"
                }}>
                <AnalyticsContext context={item.type}>
                    <Loading>
                        <ViewItem
                            actionsContainer={actionsContainer}
                            item={item}/>
                    </Loading>
                </AnalyticsContext>
            </Stack>
        </Stack>);
}