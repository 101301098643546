﻿import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { useGetResolutionSteps } from "../..";
import { Contract } from "../../../../../../../../../../../../../../../../common";

export function useSpotInstanceGroupResolutionSteps(riskModel: Contract.AwsEc2InstanceMetadataServiceVersionRiskModel, resourceGroupModel: Contract.AwsResourceGroupModel) {
    const getResolutionSteps = useGetResolutionSteps();
    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.aws.hooks.compliance.useAwsEc2InstanceMetadataServiceVersionRiskDefinition.hooks.useAwsEc2InstanceMetadataServiceVersionRiskTagResourceGroupDefinition.hooks.useSpotInstanceGroupResolutionSteps",
            () => ({
                resolution: {
                    documentation: "documentation",
                    step1: "In Spot, update the Elastigroup configuration using the following **{{documentationLink}}**",
                    step2: "Use the **Update Elastigroup** endpoint and provide the **launchSpecification.metadataOptions** object.By setting **httpTokens** to **required** you'll enforce the use of V2 metadata"
                }
            }));

    return [
        ...getResolutionSteps(
            resourceGroupModel.entity.typeName,
            riskModel),
        localization.resolution.step1({
            documentationLink:
                <Link
                    sx={{ textDecorationLine: "underline" }}
                    urlOrGetUrl={riskModel.spotDocumentationUrl}
                    variant="external">
                    {localization.resolution.documentation()}
                </Link>
        }),
        localization.resolution.step2()
    ];
}