import { useLocalization } from "@infrastructure";
import { Box, Stack } from "@mui/material";
import { Dictionary } from "lodash";
import React from "react";
import { SectionHeader } from "../";
import { Contract, useTheme } from "../../../../../../../../common";
import { PrincipalExcessivePermissionDistribution } from "./components";

type PrincipalExcessivePermissionDistributionSectionProps = {
    identityExcessivePermissionActionStats: Contract.SecurityStats;
    principalRiskCategoryToSummaryMap: Dictionary<Contract.ReportControllerGetDashboardReportResponseRiskCategorySummary>;
};

export function PrincipalExcessivePermissionDistributionSection({ identityExcessivePermissionActionStats, principalRiskCategoryToSummaryMap }: PrincipalExcessivePermissionDistributionSectionProps) {
    const localization =
        useLocalization(
            "views.system.exportReport.dashboardReport.principalExcessivePermissionDistributionSection",
            () => ({
                subtitle: "View a breakdown of excessive permissions to enforce least privilege in your environment. View permissions by category type and severity level, and see what percentage of all permissions in your environment are excessive. The percentage includes permissions with any severity level, and excludes used permissions (green), or permissions that are excluded from policies (gray).",
                title: "Excessive Permissions"
            }));

    const theme = useTheme();
    return (
        <Stack spacing={2}>
            <SectionHeader
                subtitle={localization.subtitle()}
                title={localization.title()}
                variant="sub"/>
            <Box
                style={{
                    border: theme.border.primary,
                    borderRadius: theme.spacing(0.75)
                }}>
                <PrincipalExcessivePermissionDistribution
                    identityExcessivePermissionActionStats={identityExcessivePermissionActionStats}
                    principalRiskCategoryToSummaryMap={principalRiskCategoryToSummaryMap}
                    report={true}/>
            </Box>
        </Stack>);
}