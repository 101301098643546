import { ActionMenuItem, AddIcon, CheckButton, ContentMenuItem, DataTableAction, DataTableActions, DataTableColumn, DataTableColumnRenderProps, DuplicateIcon, Loading, Menu, setUrlQueryParameters, StringHelper, useLocalization, useQueryParameters, useSetRoute } from "@infrastructure";
import { Box, Button, Stack } from "@mui/material";
import _ from "lodash";
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Contract, CustomerConsoleAppUrlHelper, Scope, ScopeHelper, scopeNodeModelStore, scopeSystemEntityModelStore, StorageHelper, UserHelper, useTheme } from "../../../../../../../../../../common";
import { ComplianceQueryParameters, ComplianceView, useSetComplianceContext } from "../../../../../../Compliance";
import { ItemTableColumnId } from "../../../../Items";
import { ComplianceDefinition } from "../../useDefinition";
import { GroupedSearchList } from "./components";
import { NewIcon } from "./icons";

export function useCustomDefinition(scopeId: string) {
    const dataTableActionsRef = useRef<DataTableActions>();
    const setComplianceContext = useSetComplianceContext();
    const customComplianceModels = scopeSystemEntityModelStore.useGetCustomCompliance();
    const scopeNodeModel = scopeNodeModelStore.useGet(scopeId);
    const customComplianceModelMap =
        useMemo(
            () =>
                _.keyBy(
                    customComplianceModels,
                    customComplianceModel => customComplianceModel.configuration.id),
            [customComplianceModels]);
    const setRoute = useSetRoute();
    const { toggleFilterMap } = useQueryParameters<ComplianceQueryParameters>();

    const [flatView, setFlatView] =
        useState(
            () =>
                _.isNil(toggleFilterMap)
                    ? !StringHelper.isFalse(StorageHelper.customerComplianceCustomTableFlatView.getValue())
                    : !StringHelper.isFalse(JSON.parse(toggleFilterMap).childScopeCustomCompliancesEnabled));

    useEffect(
        () => {
            setComplianceContext(
                context => ({
                    ...context,
                    childScopeCustomCompliance: flatView
                }));
        },
        [flatView]);

    const localization =
        useLocalization(
            "views.customer.compliance.items.hooks.useDefinition.hooks.useCustomDefinition",
            () => ({
                action: {
                    add: "Add Custom Compliance",
                    duplicate: "From existing",
                    flatView: "Flat View",
                    new: "New"
                },
                columns: {
                    scope: "Scope"
                }
            }));

    const getAdditionalColumn =
        useCallback(
            () =>
                <DataTableColumn
                    id={ItemTableColumnId.Scope}
                    key={ItemTableColumnId.Scope}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.ComplianceSectionData>) =>
                            <Scope scopeId={customComplianceModelMap[item.identifier].scopeId}/>}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.scope()}/>,
            [localization, customComplianceModelMap]);

    const theme = useTheme();
    const actions =
        useMemo(
            () =>
                _<ReactNode>([]).
                    concat(
                        <DataTableAction key="flatView">
                            <Stack
                                alignSelf="center"
                                direction="row"
                                spacing={1}>
                                <CheckButton
                                    checked={flatView}
                                    title={localization.action.flatView()}
                                    onCheckedChanged={
                                        checked => {
                                            setUrlQueryParameters<ComplianceQueryParameters>(
                                                { toggleFilterMap: JSON.stringify({ childScopeCustomCompliancesEnabled: checked }) },
                                                { ignoreUrlMaxLength: true });
                                            StorageHelper.customerComplianceCustomTableFlatView.setValue(checked);
                                            setFlatView(checked);
                                        }}/>
                            </Stack>
                        </DataTableAction>).
                    concatIf(
                        UserHelper.hasScopePermissions(
                            scopeId,
                            Contract.IdentityPermission.SecurityWrite),
                        <DataTableAction key="add">
                            <Menu
                                disabled={ScopeHelper.isProjectScope(scopeNodeModel)}
                                itemsOrGetItems={[
                                    new ActionMenuItem(
                                        () =>
                                            setRoute(
                                                CustomerConsoleAppUrlHelper.getComplianceRelativeUrl(
                                                    scopeId,
                                                    ComplianceView.Create)),
                                        localization.action.new(),
                                        { icon: <NewIcon sx={{ fontSize: "16px" }}/> }),
                                    new ContentMenuItem(
                                        () =>
                                            <Box sx={{ width: theme.spacing(50) }}>
                                                <Loading container="popup">
                                                    <GroupedSearchList/>
                                                </Loading>
                                            </Box>,
                                        localization.action.duplicate(),
                                        {
                                            icon: <DuplicateIcon sx={{ fontSize: "16px" }}/>,
                                            placement: "topRight",
                                            variant: "subMenu"
                                        })
                                ]}
                                variant="bottomCenter">
                                <Button
                                    disabled={ScopeHelper.isProjectScope(scopeNodeModel)}
                                    size="small"
                                    startIcon={<AddIcon sx={{ fontSize: "18px" }}/>}
                                    sx={{ marginLeft: "auto" }}
                                    variant="contained">
                                    {localization.action.add()}
                                </Button>
                            </Menu>
                        </DataTableAction>).
                    value(),
            [scopeId, flatView]);

    return useMemo(
        (): ComplianceDefinition => ({
            actions,
            actionsRef: dataTableActionsRef,
            getAdditionalColumn
        }),
        [actions, getAdditionalColumn]);
}