import { DashboardWidget } from "../../GridStackWrapper";
import { DashboardWidgetType } from "../Widget";

export const dashboardWidgetMap: { [key in DashboardWidgetType]: DashboardWidget } = {
    [DashboardWidgetType.AccessPrincipals]: {
        h: 4,
        maxH: 5,
        minH: 4,
        minW: 12,
        smallScreenSize: {
            maxH: 8,
            minH: 8
        },
        type: DashboardWidgetType.AccessPrincipals,
        w: 12
    },
    [DashboardWidgetType.AccessExcessivePermissionPrincipalTrends]: {
        h: 12,
        maxH: 12,
        minH: 12,
        minW: 7,
        smallScreenSize: {
            maxH: 16,
            minH: 16
        },
        type: DashboardWidgetType.AccessExcessivePermissionPrincipalTrends,
        w: 12
    },
    [DashboardWidgetType.AccessRiskCombinations]: {
        h: 8,
        minH: 3,
        minW: 2,
        type: DashboardWidgetType.AccessRiskCombinations,
        w: 4
    },
    [DashboardWidgetType.AccessRisks]: {
        h: 8,
        minH: 3,
        minW: 3,
        type: DashboardWidgetType.AccessRisks,
        w: 4
    },
    [DashboardWidgetType.AccessVendors]: {
        h: 8,
        minH: 3,
        minW: 3,
        type: DashboardWidgetType.AccessVendors,
        w: 4
    },
    [DashboardWidgetType.CodeEntities]: {
        h: 5,
        maxH: 8,
        minH: 5,
        minW: 4,
        type: DashboardWidgetType.CodeEntities,
        w: 6
    },
    [DashboardWidgetType.CodeRisks]: {
        h: 8,
        maxH: 8,
        maxW: 8,
        minH: 8,
        minW: 5,
        type: DashboardWidgetType.CodeRisks,
        w: 6
    },
    [DashboardWidgetType.CodeTenants]: {
        h: 5,
        maxH: 8,
        minH: 5,
        minW: 2,
        type: DashboardWidgetType.CodeTenants,
        w: 6
    },
    [DashboardWidgetType.CodeTopRiskTenants]: {
        h: 8,
        maxH: 8,
        minH: 3,
        minW: 3,
        type: DashboardWidgetType.CodeTopRiskTenants,
        w: 6
    },
    [DashboardWidgetType.Compliance]: {
        h: 8,
        minH: 3,
        minW: 3,
        type: DashboardWidgetType.Compliance,
        w: 4
    },
    [DashboardWidgetType.DataAnalysis]: {
        h: 8,
        maxH: 16,
        maxW: 6,
        minH: 8,
        minW: 3,
        type: DashboardWidgetType.DataAnalysis,
        w: 4
    },
    [DashboardWidgetType.Entities]: {
        h: 5,
        maxH: 8,
        minH: 5,
        minW: 4,
        type: DashboardWidgetType.Entities,
        w: 6
    },
    [DashboardWidgetType.EntityPublicData]: {
        h: 8,
        maxH: 8,
        minH: 8,
        minW: 4,
        type: DashboardWidgetType.EntityPublicData,
        w: 4
    },
    [DashboardWidgetType.OpenBehaviorRisk]: {
        h: 8,
        minH: 4,
        minW: 3,
        type: DashboardWidgetType.OpenBehaviorRisk,
        w: 12
    },
    [DashboardWidgetType.RiskCombinations]: {
        h: 8,
        minH: 3,
        minW: 2,
        type: DashboardWidgetType.RiskCombinations,
        w: 4
    },
    [DashboardWidgetType.Risks]: {
        h: 8,
        maxH: 8,
        maxW: 8,
        minH: 8,
        minW: 5,
        type: DashboardWidgetType.Risks,
        w: 6
    },
    [DashboardWidgetType.RiskTrends]: {
        h: 8,
        maxH: 8,
        minH: 8,
        minW: 4,
        type: DashboardWidgetType.RiskTrends,
        w: 6
    },
    [DashboardWidgetType.Secrets]: {
        h: 8,
        minH: 3,
        minW: 2,
        type: DashboardWidgetType.Secrets,
        w: 4
    },
    [DashboardWidgetType.Tenants]: {
        h: 5,
        maxH: 8,
        minH: 5,
        minW: 2,
        type: DashboardWidgetType.Tenants,
        w: 6
    },
    [DashboardWidgetType.TopRiskTenants]: {
        h: 8,
        maxH: 8,
        minH: 2,
        minW: 3,
        type: DashboardWidgetType.TopRiskTenants,
        w: 6
    },
    [DashboardWidgetType.TopRiskTypes]: {
        h: 8,
        maxH: 8,
        minH: 2,
        minW: 3,
        type: DashboardWidgetType.TopRiskTypes,
        w: 6
    },
    [DashboardWidgetType.TopRisks]: {
        h: 8,
        maxH: 8,
        minH: 3,
        minW: 2,
        type: DashboardWidgetType.TopRisks,
        w: 4
    },
    [DashboardWidgetType.WorkloadAnalysisCriticalVulnerabilities]: {
        h: 8,
        maxH: 8,
        minH: 3,
        minW: 2,
        type: DashboardWidgetType.WorkloadAnalysisCriticalVulnerabilities,
        w: 4
    },
    [DashboardWidgetType.WorkloadAnalysisRiskDistribution]: {
        h: 8,
        maxH: 8,
        maxW: 6,
        minH: 7,
        minW: 3,
        type: DashboardWidgetType.WorkloadAnalysisRiskDistribution,
        w: 4
    },
    [DashboardWidgetType.WorkloadAnalysisVulnerabilityStatus]: {
        h: 8,
        maxH: 8,
        maxW: 6,
        minH: 7,
        minW: 3,
        type: DashboardWidgetType.WorkloadAnalysisVulnerabilityStatus,
        w: 4
    }
};