import { ActionButton, CheckboxField, DateField, DeleteIcon, ItemSelector, useLocalization } from "@infrastructure";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Contract, LicensingHelper, useTheme } from "../../../../../../../common";

type LicenseProps = {
    disabled: boolean;
    license: Contract.ApplicationCustomerConfigurationLicensingLicense;
    onLicenseChanged: (license: Contract.ApplicationCustomerConfigurationLicensingLicense) => void;
    variant: "evaluation" | "subscription";
};

export function License({ disabled, license, onLicenseChanged, variant }: LicenseProps) {
    const localization =
        useLocalization(
            "views.customer.administration.licensing.license",
            () => ({
                addOn: "Add-ons",
                expirationTime: {
                    evaluation: "Expiration Time",
                    subscription: "Renewal Time"
                },
                types: "License"
            }));

    const theme = useTheme();
    return (
        <Stack spacing={2}>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}>
                <Typography
                    sx={{ width: theme.spacing(20) }}
                    variant="h5">
                    {localization.addOn()}
                </Typography>
                {_(Contract.ApplicationCustomerConfigurationLicensingLicenseType).
                    values().
                    difference(LicensingHelper.standardLicenseTypes).
                    map(
                        addonType =>
                            <CheckboxField
                                checked={_.includes(license.types, addonType)}
                                disabled={disabled}
                                key={addonType}
                                onChange={
                                    (event, checked) =>
                                        onLicenseChanged({
                                            ...license,
                                            types:
                                                checked
                                                    ? _(license.types).
                                                        concat(LicensingHelper.getIncludedLicenseTypes(addonType)).
                                                        value()
                                                    : _.without(license.types, addonType)
                                        })}>
                                <Typography
                                    sx={{ fontWeight: 500 }}
                                    variant="h4">
                                    {LicensingHelper.getLicenseData(addonType).name}
                                </Typography>
                            </CheckboxField>).
                    value()}
            </Stack>
            <Stack
                alignItems="center"
                direction="row"
                spacing={1}>
                <Typography
                    sx={{ width: theme.spacing(20) }}
                    variant="h5">
                    {localization.types()}
                </Typography>
                <ItemSelector
                    disabled={disabled}
                    fieldSx={{ width: theme.spacing(20) }}
                    items={LicensingHelper.standardLicenseTypes}
                    selectedItem={
                        _(LicensingHelper.orderedLicenseTypes).
                            without(Contract.ApplicationCustomerConfigurationLicensingLicenseType.PermissionManagement).
                            intersection(license.types).
                            head()}
                    sorted={false}
                    onSelectedItemChanged={
                        type =>
                            onLicenseChanged({
                                ...license,
                                types:
                                    _(license.types).
                                        difference(LicensingHelper.standardLicenseTypes).
                                        concat(LicensingHelper.getIncludedLicenseTypes(type)).
                                        uniq().
                                        value()
                            })}>
                    {(type: Contract.ApplicationCustomerConfigurationLicensingLicenseType) => LicensingHelper.getLicenseData(type).name}
                </ItemSelector>
            </Stack>
            {variant == "subscription" &&
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}>
                    <Typography
                        sx={{ width: theme.spacing(20) }}
                        variant="h5">
                        {localization.expirationTime[variant]()}
                    </Typography>
                    <Box sx={{ width: "200px" }}>
                        <DateField
                            date={license.expirationTime}
                            disabled={disabled}
                            title={localization.expirationTime[variant]()}
                            onChange={
                                expirationTime =>
                                    onLicenseChanged({
                                        ...license,
                                        expirationTime: expirationTime ?? undefined
                                    })}/>
                    </Box>
                    <ActionButton
                        disabled={disabled || _.isNil(license.expirationTime ) }
                        onClick={
                            () =>
                                onLicenseChanged({
                                    ...license,
                                    expirationTime: undefined
                                })}>
                        <DeleteIcon/>
                    </ActionButton>
                </Stack>}
        </Stack>);
}