import { useLocalization } from "@infrastructure";
import React from "react";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../../..";
import { Contract, InfoItem, VerticalTopItemsInfoItem } from "../../../../../../../../../../../common";
import { EntityTypeNameGroupsInfoCard, Info } from "../../../../../components";
import { AzureResourceRoleAssignmentResources } from "../../../components";
import { useCustomEntityPropertyInfoItemElements } from "../../useCustomEntityPropertyInfoItemElements";
import { useAzureDefaultResourceInfoItemElements } from "../useAzureDefaultResourceInfoItemElements";
import { AzureNetworkVirtualNetworkSubnetsInfoCard } from "./components";

export function useAzureNetworkVirtualNetworkDefinition(resourceGroupResourceModel: Contract.AzureResourceGroupResourceModel, options?: DefinitionOptions) {
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceGroupResourceModel);
    const defaultResourceInfoItemElements = useAzureDefaultResourceInfoItemElements(resourceGroupResourceModel);
    const virtualNetwork = resourceGroupResourceModel.entity as Contract.AzureNetworkVirtualNetwork;
    const virtualNetworkModel = resourceGroupResourceModel as Contract.AzureNetworkVirtualNetworkModel;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.azure.useAzureNetworkVirtualNetworkDefinition",
            () => ({
                info: {
                    cards: {
                        resources: {
                            emptyMessage: "No resources",
                            title: "Resources"
                        }
                    },
                    items: {
                        hasNetworkedResources: {
                            false: "No",
                            title: "Used",
                            true: "Yes"
                        },
                        subnets: "Address Space"
                    }
                },
                tabs: {
                    resourceRoleAssignments: "Resource Role Assignments"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AzureResourceRoleAssignmentResources
                    csvExportFilePrefix={localization.tabs.resourceRoleAssignments()}
                    scopeResourceModel={resourceGroupResourceModel}/>,
                localization.tabs.resourceRoleAssignments(),
                "resourceRoleAssignments")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    <VerticalTopItemsInfoItem
                        items={virtualNetwork.subnets}
                        key="subnets"
                        title={localization.info.items.subnets()}/>,
                    <InfoItem
                        key="hasNetworkedResources"
                        title={localization.info.items.hasNetworkedResources.title()}
                        value={
                            virtualNetwork.hasNetworkedResources
                                ? localization.info.items.hasNetworkedResources.true()
                                : localization.info.items.hasNetworkedResources.false()}/>
                ]}
                options={options?.infoOptions}>
                <EntityTypeNameGroupsInfoCard
                    emptyMessage={localization.info.cards.resources.emptyMessage()}
                    entityTypeNameToIdsMap={virtualNetworkModel.networkedResourceTypeNameToIdsMap}
                    title={localization.info.cards.resources.title()}/>
                <AzureNetworkVirtualNetworkSubnetsInfoCard subnetIds={virtualNetwork.subnetIds}/>
            </Info>
    });
}