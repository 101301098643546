import { Link, useLocalization } from "@infrastructure";
import React from "react";
import { Contract, Entity, entityModelStore } from "../../../../../../../../../../../../common";
import { useCommonSectionsAndDescriptionDefinition } from "../../../useCommonSectionsAndDescriptionDefinition";
import { useGetAzureDocumentDbDatabaseAccountRiskContext } from "../contexts";

export function useAzureDocumentDbDatabaseAccountManagementKeyAccessEnabledRiskDefinition(riskModel: Contract.RiskModel) {
    const risk = riskModel.risk as Contract.AzureDocumentDbDatabaseAccountManagementKeyAccessEnabledRisk;
    const documentDbDatabaseAccountManagementKeyAccessEnabledRiskModel = riskModel as Contract.AzureDocumentDbDatabaseAccountManagementKeyAccessEnabledRiskModel;
    const databaseAccountModel = entityModelStore.useGet(risk.entityId) as Contract.AzureDocumentDbDatabaseAccountModel;
    const databaseAccount = databaseAccountModel.entity as Contract.AzureDocumentDbDatabaseAccount;

    const getAzureDocumentDbDatabaseAccountRiskContext = useGetAzureDocumentDbDatabaseAccountRiskContext();

    const localization =
        useLocalization(
            "views.customer.risks.hooks.useDefinition.hooks.useCloudDefinition.hooks.azure.hooks.compliance.useAzureDocumentDbDatabaseAccountManagementKeyAccessEnabledRiskDefinition",
            () => ({
                description: "{{databaseAccount}} key is allowing management changes through access keys.",
                sections: {
                    resolution: {
                        step1:
                            {
                                link: "Azure role-based access control in Azure Cosmos DB",
                                text: "Before disabling management key access, verify it will not break existing identities and applications using the CosmosDB account: {{documentDbRbacLink}}"
                            },
                        step2: "Run the following command from Azure CLI: az cosmosdb update --name {{databaseAccountName}} --resource-group {{resourceGroupName}} --disable-key-based-metadata-write-access true"
                    }
                }
            }));
    return useCommonSectionsAndDescriptionDefinition(
        localization.description({
            databaseAccount:
                <Entity
                    entityIdOrModel={databaseAccountModel}
                    entityTypeNameTranslatorOptions={{ variant: "title" }}
                    variant="typeText"/>
        }),
        () => [
            localization.sections.resolution.step1.text({
                documentDbRbacLink:
                    <Link
                        urlOrGetUrl={documentDbDatabaseAccountManagementKeyAccessEnabledRiskModel.rbacDocumentationUrl}
                        variant="external">
                        {localization.sections.resolution.step1.link()}
                    </Link>
            }),
            localization.sections.resolution.step2({
                databaseAccountName: databaseAccount.name,
                resourceGroupName:
                    <Entity
                        entityIdOrModel={databaseAccount.resourceGroupId}
                        glanceOptions={{ disabled: true }}
                        linkOptions={{
                            disabled: true,
                            disabledHighlight: false
                        }}
                        variant="text"/>
            })
        ],
        riskModel,
        () => {
            const databaseAccountRiskContext = getAzureDocumentDbDatabaseAccountRiskContext(databaseAccountModel);
            return [
                databaseAccountRiskContext.generalInformation,
                databaseAccountRiskContext.sensitive,
                databaseAccountRiskContext.inboundExternalAccessScope,
                databaseAccountRiskContext.getOpenRiskedEntityRisksContextItem(risk.id)
            ];
        });
}