import { useLocalization } from "@infrastructure";
import React from "react";
import { useAwsCommonAccessResourceInfoElements, useAwsCommonEncryptedResourceInfoElements, useAwsDefaultResourceInfoItemElements } from ".";
import { DefinitionOptions, EntityProfileDefinition, EntityProfileDefinitionTab, ProfileCategory } from "../../..";
import { useCustomEntityPropertyInfoItemElements } from "..";
import { Contract, InfoItem } from "../../../../../../../../../../common";
import { Info } from "../../../../components";
import { AwsIamPolicyDocument } from "../../components";

export function useAwsEcrRepositoryDefinition(resourceModel: Contract.AwsResourceModel, options?: DefinitionOptions) {
    const commonAccessResourceInfoElements = useAwsCommonAccessResourceInfoElements(resourceModel);
    const commonEncryptedResourceInfoElements = useAwsCommonEncryptedResourceInfoElements(resourceModel);
    const customEntityPropertyInfoItemElements = useCustomEntityPropertyInfoItemElements(resourceModel);
    const defaultResourceInfoItemElements = useAwsDefaultResourceInfoItemElements(resourceModel);
    const repository = resourceModel.entity as Contract.AwsEcrRepository;

    const localization =
        useLocalization(
            "views.customer.entities.profile.hooks.useDefinition.hooks.aws.useAwsEcrRepositoryDefinition",
            () => ({
                info: {
                    items: {
                        access: "Access",
                        containerImageCount: "Image Count",
                        encryptionType: {
                            [Contract.TypeNames.AwsEcrRepositoryEncryptionType]: {
                                [Contract.AwsEcrRepositoryEncryptionType.Aes256]: "AES-256",
                                [Contract.AwsEcrRepositoryEncryptionType.Kms]: "KMS Key (SSE-KMS)",
                                [Contract.AwsEcrRepositoryEncryptionType.KmsDualLayer]: "KMS Key (DSSE-KMS)"
                            },
                            title: "Encryption Type"
                        },
                        rawUrl: "URI",
                        registryVisibility: {
                            false: "Private",
                            title: "Registry Visibility",
                            true: "Public"
                        },
                        scanOnPushEnabled: {
                            false: "Not Enabled",
                            title: "Scan on Push",
                            true: "Enabled"
                        },
                        scanType: {
                            [Contract.TypeNames.AwsEcrRepositoryScanningConfiguration]: {
                                [Contract.AwsEcrRepositoryScanningConfiguration.Basic]: "Basic scanning",
                                [Contract.AwsEcrRepositoryScanningConfiguration.Enhanced]: "Enhanced scanning",
                                [Contract.AwsEcrRepositoryScanningConfiguration.Native]: "Basic Improved scanning (AWS Native)",
                                [Contract.AwsEcrRepositoryScanningConfiguration.Unknown]: "Unknown"
                            },
                            title: "Scan Type"
                        }
                    }
                },
                tabs: {
                    policyDocument: "Policy"
                }
            }));

    return new EntityProfileDefinition({
        additionalTabs: [
            new EntityProfileDefinitionTab(
                ProfileCategory.Iam,
                <AwsIamPolicyDocument policyDocument={repository.policyDocument}/>,
                localization.tabs.policyDocument(),
                "policyDocument")
        ],
        infoElement:
            <Info
                customEntityPropertyInfoItemElements={customEntityPropertyInfoItemElements}
                defaultTenantInfoItemElements={defaultResourceInfoItemElements}
                entityPropertyInfoItemElements={[
                    ...commonAccessResourceInfoElements,
                    ...commonEncryptedResourceInfoElements,
                    <InfoItem
                        key="publicRegistry"
                        location="all"
                        title={localization.info.items.registryVisibility.title()}
                        value={
                            repository.publicRegistry
                                ? localization.info.items.registryVisibility.true()
                                : localization.info.items.registryVisibility.false()}/>,
                    <InfoItem
                        key="rawUri"
                        location="all"
                        title={localization.info.items.rawUrl()}
                        value={repository.rawUrl}/>,
                    <InfoItem
                        key="artifactCount"
                        location="all"
                        title={localization.info.items.containerImageCount()}
                        value={repository.containerImageCount}/>,
                    <InfoItem
                        key="scanType"
                        title={localization.info.items.scanType.title()}
                        value={localization.info.items.scanType[Contract.TypeNames.AwsEcrRepositoryScanningConfiguration][repository.scanningConfiguration]()}/>,
                    <InfoItem
                        key="scanOnPushEnabled"
                        title={localization.info.items.scanOnPushEnabled.title()}
                        value={
                            repository.scanOnPushEnabled
                                ? localization.info.items.scanOnPushEnabled.true()
                                : localization.info.items.scanOnPushEnabled.false()}/>,
                    <InfoItem
                        key="encryptionType"
                        title={localization.info.items.encryptionType.title()}
                        value={localization.info.items.encryptionType[Contract.TypeNames.AwsEcrRepositoryEncryptionType][repository.encryptionType]()}/>
                ]}
                options={options?.infoOptions}>
            </Info>
    });
}