﻿import { Link, useLocalization } from "@infrastructure";
import { Typography } from "@mui/material";
import React from "react";
import { CustomerConsoleAppUrlHelper, ScopeHelper, useScopeNavigationViewContext, useTenantTypeTranslator } from "../../../../../../../../../common";

type NoticeProps = {
    permissionEligibilityType: PermissionEligibilityType;
};

export enum PermissionEligibilityType {
    GroupMembership = "groupMembership",
    OneLoginDirectoryRoleAssignment = "oneLoginDirectoryRoleAssignment"
}

export function Notice({ permissionEligibilityType }: NoticeProps) {
    const { scopeNodeModel } = useScopeNavigationViewContext();
    const tenantType = ScopeHelper.getTenantType(scopeNodeModel)!;

    const localization =
        useLocalization(
            "views.user.permissionEligibilities.addOrEdit.hooks.useDefinition.notice",
            () => ({
                title: {
                    documentationLink: "Learn more",
                    text: {
                        [PermissionEligibilityType.GroupMembership]: "To enable requesters to join {{translatedTenantType}} groups, a Tenable Cloud Security administrator must first grant the necessary permissions. {{documentationLink}}",
                        [PermissionEligibilityType.OneLoginDirectoryRoleAssignment]: "To enable requesters to obtain {{translatedTenantType}} roles, a Tenable Cloud Security administrator must first grant the necessary permissions. {{documentationLink}}"
                    }
                }
            }));
    const tenantTypeTranslator = useTenantTypeTranslator();
    return (
        <Typography>
            {localization.title.text[permissionEligibilityType]({
                documentationLink:
                    <Link
                        urlOrGetUrl={CustomerConsoleAppUrlHelper.getDocsGrantTenableJitPermissionsRelativeUrl(tenantType)}
                        variant="external">
                        {localization.title.documentationLink()}
                    </Link>,
                translatedTenantType: tenantTypeTranslator(ScopeHelper.getTenantType(scopeNodeModel)!)
            })}
        </Typography>);
}