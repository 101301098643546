import { DataTableColumn, DataTableColumnRenderProps, InlineItems, useExecuteOperation, useLocalization, ValuesFilter, ValuesFilterItem } from "@infrastructure";
import _ from "lodash";
import React from "react";
import { Contract, ItemSelectionHelper, NetworkScopeFormatter, RiskController, RiskType } from "../../../../../../../../../../../../../../../../../../../../../common";
import { useItemsContext, useTableCommonCloudColumns } from "../../../../../../../../../../../../../../../hooks";
import { TableColumnId } from "../../../../../../../../../../../../../../Table";
import { useCommonColumns } from "../../../../../../useCommonColumns";
import { useCommonCloudRiskPolicyTableDefinition } from "../../useCommonCloudRiskPolicyTableDefinition";

export function useAwsEc2NetworkResourceInboundRuleSubnetAnyExistsRiskDefinition(riskPolicyConfigurationTypeName: string) {
    const { requestFilters } = useItemsContext();
    const [{ filters }] =
        useExecuteOperation(
            [useAwsEc2NetworkResourceInboundRuleSubnetAnyExistsRiskDefinition, riskPolicyConfigurationTypeName],
            () =>
                RiskController.getRiskPolicyTypeGroupFilters(
                    new Contract.RiskControllerGetRiskPolicyTypeGroupCloudRiskFiltersRequest(
                        requestFilters!,
                        riskPolicyConfigurationTypeName)));

    const localization =
        useLocalization(
            "views.customer.risks.items.grouped.item.table.hooks.useDefinition.hooks.useCloudDefinition.hooks.useRiskPolicyTypeDefinition.hooks.useNetworkDefinition.hooks.useAwsEc2NetworkResourceInboundRuleSubnetAnyExistsRiskDefinition",
            () => ({
                columns: {
                    ports: "Exposed Ports"
                }
            }));

    const awsEc2NetworkResourceInboundRuleSubnetAnyExistsRiskPolicyGroupFilters = filters as Contract.AwsEc2NetworkResourceInboundRuleSubnetAnyExistsRiskPolicyGroupFilters;
    const commonColumns = useCommonColumns();
    const commonCloudColumns = useTableCommonCloudColumns();

    return useCommonCloudRiskPolicyTableDefinition({
        columns:
            _.filter([
                commonColumns.statusUpdateTimeColumn,
                commonColumns.severityColumn,
                commonColumns.riskPolicyIdColumn,
                commonCloudColumns.descriptionColumn,
                commonColumns.tenantIdColumn,
                commonColumns.riskedEntityIdColumn(
                    riskPolicyConfigurationTypeName,
                    requestFilters as Contract.RiskControllerCloudRiskModelFilters),
                commonColumns.resourceOwnerColumn,
                commonColumns.resourceEnvironmentColumn,
                <DataTableColumn
                    cellMaxWidth="medium"
                    filterOptions={{
                        itemOrItems: {
                            element:
                                <ValuesFilter
                                    groupItemTitle={true}
                                    placeholder={localization.columns.ports()}>
                                    {_.map(
                                        awsEc2NetworkResourceInboundRuleSubnetAnyExistsRiskPolicyGroupFilters.unrestrictedDestinationNetworkScopeFilterIdToDestinationNetworkScopeMap,
                                        (unrestrictedDestinationNetworkScope, filterId) =>
                                            <ValuesFilterItem
                                                key={filterId}
                                                title={NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(unrestrictedDestinationNetworkScope)}
                                                value={filterId}/>)}
                                </ValuesFilter>
                        }
                    }}
                    id={TableColumnId.AwsEc2NetworkResourceInboundRuleSubnetAnyExistsRiskUnrestrictedDestinationNetworkScopes}
                    key={TableColumnId.AwsEc2NetworkResourceInboundRuleSubnetAnyExistsRiskUnrestrictedDestinationNetworkScopes}
                    render={
                        ({ item }: DataTableColumnRenderProps<Contract.AwsEc2NetworkResourceInboundRuleSubnetAnyExistsRiskModel>) =>
                            <InlineItems
                                items={
                                    _(item.unrestrictedDestinationNetworkScopes).
                                        map(
                                            unrestrictedNetworkScope => NetworkScopeFormatter.networkScopeFromDestinationNetworkScope(unrestrictedNetworkScope)).
                                        uniq().
                                        sort().
                                        value()}
                                variant="itemPlusItemCount"/>}
                    sortOptions={{ enabled: false }}
                    title={localization.columns.ports()}/>,
                commonColumns.statusColumn(true),
                commonColumns.subStatusColumn(true),
                commonColumns.ignoreExpirationDateColumn,
                commonColumns.openStatusUpdateTimeColumn,
                commonColumns.systemCreationTimeColumn,
                commonColumns.riskedEntityAttributesColumn,
                commonColumns.actionsCell(RiskType.Cloud)
            ]),
        getGroupFilters:
            filterMap =>
                new Contract.RiskControllerAwsEc2NetworkResourceInboundRuleSubnetAnyExistsRiskPolicyTypeFilters(
                    riskPolicyConfigurationTypeName,
                    ItemSelectionHelper.toItemSelectionFromPagedValuesFilterSelection(filterMap[TableColumnId.RiskedEntityIds]),
                    ItemSelectionHelper.toItemSelectionFromValuesFilterSelection(filterMap[TableColumnId.AwsEc2NetworkResourceInboundRuleSubnetAnyExistsRiskUnrestrictedDestinationNetworkScopes])),
        getRiskIdToScopesIdentityPermissionsMapRequest:
            requestFilters =>
                new Contract.RiskControllerGetRiskPolicyTypeGroupCloudRiskIdToScopeIdentityPermissionsMapRequest(
                    requestFilters,
                    riskPolicyConfigurationTypeName)
    });
}