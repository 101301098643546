import { Link, LinkProps } from "@infrastructure";
import { Stack, SxProps, Typography } from "@mui/material";
import React from "react";
import { Contract, useVulnerabilitySourceTypeTranslator } from "../..";
import { VulnerabilitySourceTypeIcon } from "./components";

type VulnerabilitySourceProps = Pick<LinkProps, "urlOrGetUrl"> & {
    iconSx?: SxProps;
    type: Contract.VulnerabilitySourceType;
};

export function VulnerabilitySourceType({ iconSx, type, urlOrGetUrl }: VulnerabilitySourceProps) {
    const vulnerabilitySourceTypeTranslator = useVulnerabilitySourceTypeTranslator();
    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={1.5}
            sx={{ width: "100%" }}>
            <VulnerabilitySourceTypeIcon
                sx={{
                    fontSize: "18px",
                    ...iconSx
                }}
                type={type}/>
            <Stack
                sx={{
                    overflow: "hidden",
                    width: "100%"
                }}>
                <Typography>
                    {vulnerabilitySourceTypeTranslator(type)}
                </Typography>
                {urlOrGetUrl &&
                    <Link
                        urlOrGetUrl={urlOrGetUrl}
                        variant="external"/>}
            </Stack>
        </Stack>);
}